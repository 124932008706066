import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { useWindowSize } from '@f_hooks';
import { SearchBase } from '@f_general/SearchBase';
import { PageNoData } from '@f_qwep/general/PageNoData';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import _ from 'lodash';

import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { OrderTable } from '@f_qwep/components/orders/table';
import { OrdersContext, UpdateItemOrderVendorProps } from '@f_qwep/context/Orders_context';
import { ModalChangeItem } from '@f_qwep/components/orders/table/ModalChangeItem';
import { ModalChangeStatusItem } from '@f_qwep/components/orders/table/ModalChangeStatusItem';
dayjs.extend(isBetween);

export function OrderVendor() {
    const { t } = useTranslation();
    const { ordersVendor, updateItemOrderVendor, setStatusOrderItemVendor } = useContext(OrdersContext);

    const [mobile, setMobile] = useState(false);
    const [ordersTable, setOrdersTable] = useState(ordersVendor);

    //change item
    const [isOpenItemChangeModal, setIsOpenItemChangeModal] = useState(false);
    const [dataItemChangeModal, setDataOpenItemChangeModal] = useState<UpdateItemOrderVendorProps | object>({});
    // change status item
    const [isOpenItemChangeStatusModal, setIsOpenItemChangeStatusModal] = useState(false);
    const [dataItemChangeStatusModal, setDataOpenItemChangeStatusModal] = useState<UpdateItemOrderVendorProps | object>(
        {},
    );

    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 950);
    }, [width]);

    useEffect(() => {
        setOrdersTable(ordersVendor);
    }, [ordersVendor]);

    const itemChangeOpenHandler = (value: any) => {
        setIsOpenItemChangeModal(value.open);
        setDataOpenItemChangeModal(value.data);
    };

    const itemChangeCloseHandler = () => {
        setIsOpenItemChangeModal(false);
        setDataOpenItemChangeModal({});
    };

    //change item
    const itemChangeStatusOpenHandler = (value: any) => {
        setIsOpenItemChangeStatusModal(value.open);
        setDataOpenItemChangeStatusModal(value.data);
    };

    const itemChangeStatusCloseHandler = () => {
        setIsOpenItemChangeStatusModal(false);
        setDataOpenItemChangeStatusModal({});
    };

    return (
        <LayoutPage classNames={stls.container}>
            {/* {width > 950 && <OrdersPanel items={filterCalendar(orders)} isVendorOrder />}e */}
            <div style={{ width: '100%' }}>
                {ordersVendor?.length ? (
                    <>
                        <div className={stls.appbarGroup}>
                            <SearchBase
                                data={ordersVendor}
                                placeholder={t('qwep.orders.searchOrders')}
                                setData={setOrdersTable}
                                type={'ordersList'}
                            />
                        </div>
                        <OrderTable
                            orders={ordersTable}
                            isOrderVendor={true}
                            itemChangeOpenHandler={itemChangeOpenHandler}
                            itemChangeStatusOpenHandler={itemChangeStatusOpenHandler}
                        />
                    </>
                ) : (
                    <PageNoData type={'orders'} />
                )}
            </div>

            <ModalChangeItem
                open={isOpenItemChangeModal}
                data={dataItemChangeModal}
                closeHandler={itemChangeCloseHandler}
                updateItemOrderVendor={updateItemOrderVendor}
            />
            <ModalChangeStatusItem
                open={isOpenItemChangeStatusModal}
                data={dataItemChangeStatusModal}
                closeHandler={itemChangeStatusCloseHandler}
                updateItemStatusOrderVendor={setStatusOrderItemVendor}
            />
        </LayoutPage>
    );
}
