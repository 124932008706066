import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/price/ConfigurationModule.module.sass';
import { CardContent, Modal } from '@mui/material';
import { MyModal } from '@f_general/MyModal';
import { IconAddPrice } from '../icons/IconAddPrice';
import { UpdateConfiguration } from './UpdateConfiguration';

interface TypeConfigurationModuleProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    adminCode: string;
    vendor: string;
}

export const ConfigurationModule = ({ open, setOpen, adminCode, vendor }: TypeConfigurationModuleProps) => {
    const { t } = useTranslation();

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <div className={stls.modalTitle}>
                        <IconAddPrice />
                        <p>Редактировать конфигурацию</p>
                    </div>

                    <UpdateConfiguration
                        vendor={vendor}
                        adminCode={adminCode}
                        handleCloseModal={handleCloseModal}
                        type={'modal'}
                        open={open}
                    />
                </CardContent>
            </MyModal>
        </Modal>
    );
};
