import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useTranslation } from 'react-i18next';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import { useContext, useRef, useState } from 'react';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { Alert } from '@f_general/alert';
import { Button, ButtonGroup, ClickAwayListener, Grow, List, ListItem, Popper, Tooltip } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import { IconStop } from '../icons/IconStop';
import { IconUpdatePrice } from '../icons/IconUpdatePrice';
import { IconStart } from '../icons/IconStart';
import { IconNewSearch } from '../icons/IconNewSearch';
import { IconFilterTable } from '../icons/IconFilterTable';
import { CellStrict } from './tableTaskList/CellStrict';
import { CellInStock } from './tableTaskList/CellInStock';
import { useValidAccounts } from '@f_qwep/hooks/useValidAccounts';

export function ControlPanel({ taskId, resultsId, info, icons = true }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { stopTask, startTask, restartTask, finishTask } = useContext(BatchPricerContext);
    const validAccounts = useValidAccounts();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };

    const clickHandler = async (type: string) => {
        switch (type) {
            case 'stop':
                const resultStop = await stopTask(taskId);
                if (resultStop.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'play':
                if (!validAccounts) {
                    return;
                }
                const resultPlay = await startTask(
                    taskId,
                    validAccounts.map((item: any) => item.id.toString()),
                );
                if (resultPlay.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'replay':
                if (!validAccounts) {
                    return;
                }
                const resultReplay = await restartTask(
                    taskId,
                    validAccounts.map((item: any) => item.id.toString()),
                );
                if (resultReplay.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'finish':
                if (!validAccounts) {
                    return;
                }
                const resultFinish = await finishTask(
                    taskId,
                    validAccounts.map((item: any) => item.id.toString()),
                );
                if (resultFinish.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    return (
        <>
            {icons ? (
                <div style={{ display: 'flex' }} className={stls.actions}>
                    {info !== 'warn: Проценка списка остановлена!' && (
                        <Tooltip title={t('qwep.batchPricer.stopMarkup')}>
                            <button className={stls.icon} onClick={() => clickHandler('stop')}>
                                <IconStop />
                            </button>
                        </Tooltip>
                    )}
                    <Tooltip title={t('qwep.batchPricer.StartAssessmentAgain')}>
                        <button className={stls.icon} onClick={() => clickHandler('replay')}>
                            <IconUpdatePrice />
                        </button>
                    </Tooltip>
                    {info !== 'info: Проценка списка выполняется.' && (
                        <>
                            <Tooltip title={t('qwep.batchPricer.play')}>
                                <button className={stls.icon} onClick={() => clickHandler('play')}>
                                    <IconStart />
                                </button>
                            </Tooltip>
                            <Tooltip title={t('qwep.batchPricer.restart')}>
                                <button className={stls.icon} onClick={() => clickHandler('finish')}>
                                    <IconNewSearch />
                                </button>
                            </Tooltip>
                            <Tooltip placement="top" ref={anchorRef} title={t('qwep.batchPricer.options')}>
                                <button onClick={handleToggle} className={stls.icon}>
                                    <IconFilterTable />
                                </button>
                            </Tooltip>

                            <Popper
                                style={{ zIndex: 1000 }}
                                onClick={() => setOpen(false)}
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}>
                                        <div>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <List
                                                    sx={{
                                                        background: colors.psi,
                                                        padding: '12px',
                                                        boxShadow: '0px 3px 10px 0px #002B4D2E',
                                                    }}>
                                                    <CellStrict resultsId={resultsId} />
                                                    <CellInStock resultsId={resultsId} />
                                                </List>
                                            </ClickAwayListener>
                                        </div>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    )}
                </div>
            ) : (
                <ButtonGroup className={stls.actions}>
                    {info && (
                        <Tooltip title={t('qwep.batchPricer.stopMarkup')}>
                            <Button variant="contained" onClick={() => clickHandler('stop')}>
                                <IconStop />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title={t('qwep.batchPricer.StartAssessmentAgain')}>
                        <Button id="startBatchPricer" variant="contained" onClick={() => clickHandler('replay')}>
                            <ReplayCircleFilledIcon className={stls.icon} style={{ color: colors.tau }} />
                            {/* <IconUpdatePrice /> */}
                        </Button>
                    </Tooltip>
                    {!info && (
                        <>
                            <Tooltip title={t('qwep.batchPricer.play')}>
                                <Button variant="contained" onClick={() => clickHandler('play')}>
                                    <PlayCircleFilledWhiteIcon className={stls.icon} style={{ color: colors.tau }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title={t('qwep.batchPricer.restart')}>
                                <Button variant="contained" onClick={() => clickHandler('finish')}>
                                    <NotStartedIcon className={stls.icon} style={{ color: colors.tau }} />
                                </Button>
                            </Tooltip>
                        </>
                    )}
                </ButtonGroup>
            )}
        </>
    );
}
