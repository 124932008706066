import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconNewSearchProps = {
    classNames?: string[];
    color?: string;
};

export const IconNewSearch = ({ classNames, color }: TypeIconNewSearchProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5819_15007)">
                    <rect x="0.75" y="0.5" width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                    <path d="M18.7719 17.5788L21.6253 20.4328L20.6826 21.3762L17.8293 18.5222C16.7665 19.3733 15.4449 19.836 14.0833 19.8335C12.9655 19.834 11.8698 19.5222 10.9198 18.9332C9.96979 18.3442 9.20323 17.5015 8.70659 16.5002H12.7499L11.8806 17.9488C12.5579 18.3119 13.3147 18.5013 14.0833 18.5002C15.2969 18.5021 16.4632 18.0296 17.3333 17.1835L17.4333 17.0835C18.2793 16.2134 18.7518 15.0471 18.7499 13.8335C18.7499 13.3702 18.6826 12.9228 18.5566 12.5002H19.9346C20.0319 12.9288 20.0833 13.3755 20.0833 13.8335C20.0856 15.1949 19.6229 16.5162 18.7719 17.5788ZM19.4599 11.1668H15.4166L16.2859 9.71816C15.6086 9.35511 14.8518 9.16568 14.0833 9.16683C11.5046 9.16683 9.41659 11.2548 9.41659 13.8335C9.41659 14.2968 9.48392 14.7442 9.60992 15.1668H8.23192C8.13288 14.7293 8.08302 14.2821 8.08325 13.8335C8.08325 10.5182 10.7679 7.8335 14.0833 7.8335C15.201 7.833 16.2967 8.14482 17.2467 8.73379C18.1967 9.32277 18.9633 10.1655 19.4599 11.1668Z" />
                </g>
                <defs>
                    <clipPath id="clip0_5819_15007">
                        <rect x="0.75" y="0.5" width="28" height="28" rx="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
