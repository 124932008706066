import { OrderStatusColorEnum } from '@f_qwep/components/orders/types/statusColor.enum';

enum StatusItem {
    NEW = 'NEW', // НОВЫЙ
    PREPAYMENT_REQUIRED = 'PREPAYMENT_REQUIRED', // ТРЕБУЕТСЯ ПРЕДОПЛАТА
    RESERVED = 'RESERVED', // ЗАРЕЗЕРВИРОВАН
    DELIVERY = 'DELIVERY', // ДОСТАВКА
    COMPLETED = 'COMPLETED', // ЗАВЕРШЕННЫЙ
    CANCELED = 'CANCELED', // ОТМЕНЕНО
    TRASH = 'TRASH',
}

export const OrderStatuses = {
    opened: [
        'Создано',
        'Подтверждено',
        'Рекламация',
        'В работе',
        'Запрос поставщику',
        'Ждет оплаты',
        'Приостановлен',
        'Резерв',
        'Неизвестный',
        'Корректировка',
        'Доставка',
        'Сборка',
        'Возврат',
        'Готов к выдаче',
        'Ошибка',
    ],
    closed: ['Снят', 'Выдано'],
};

export const normalizedOrderStatusColorsObject = {
    NEW: OrderStatusColorEnum.ORANGE,
    PREPAYMENT_REQUIRED: OrderStatusColorEnum.YELLOW,
    RESERVED: OrderStatusColorEnum.BLACK,
    DELIVERY: OrderStatusColorEnum.BLUE,
    COMPLETED: OrderStatusColorEnum.GREEN,
    CANCELED: OrderStatusColorEnum.RED,
    TRASH: OrderStatusColorEnum.RED,
};

export const normalizedOrderStatusesObject = {
    NEW: 'Создано',
    PREPAYMENT_REQUIRED: 'Ждет оплаты',
    RESERVED: 'Резерв',
    DELIVERY: 'Доставка',
    COMPLETED: 'Выдано',
    CANCELED: 'Снят',
    TRASH: null,
};

export const OrderStatusesColor = {
    [StatusItem.NEW]: ['Создано', 'Подтверждено', 'Рекламация', 'В работе'],
    [StatusItem.PREPAYMENT_REQUIRED]: ['Запрос поставщику', 'Ждет оплаты'],
    [StatusItem.RESERVED]: ['Приостановлен', 'Резерв', 'Неизвестный', 'Корректировка'],
    [StatusItem.DELIVERY]: ['Доставка', 'Сборка', 'Возврат', 'Готов к выдаче'],
    [StatusItem.COMPLETED]: ['Выдано'],
    [StatusItem.CANCELED]: ['Снят', 'Ошибка'],
};

export function getOrderStatusItem(value: string): StatusItem | null {
    for (const [key, values] of Object.entries(OrderStatusesColor)) {
        if (values.includes(value)) {
            return key as StatusItem;
        }
    }
    return null;
}

export function getNormalizedOrderStatusColor(value: string): OrderStatusColorEnum | null {
    const statusItem = getOrderStatusItem(value);
    if (statusItem) {
        return normalizedOrderStatusColorsObject[statusItem];
    }
    return null;
}
