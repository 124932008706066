export enum QwepPackageName {
    'A1 1000' = 'A1',
    'A5 5000' = 'A5',
    'A30 30000' = 'A30',
    'A100 100000' = 'A100',
    'Demo 300' = 'Demo',
    'Internal 1000' = 'Internal',
    'NETIX_DEMO 300' = 'NETIX_DEMO',
    'NETIX3000 3000' = 'NETIX3000',
    'NETIX10000 10000' = 'NETIX10000',
    'Promo 1000000' = 'Promo',
    'Testing 100000' = 'Testing',
    'Unlimit 1000000' = 'Unlimit',
    'W1 10000' = 'W1',
}
