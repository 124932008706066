import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import { useWindowSize } from '@f_hooks';
import { ThemeContext } from '@f_context/Theme_context';

export function CellTime({ info }: any) {
    const { width, height } = useWindowSize();
    const { colors, currentTheme } = useContext(ThemeContext);

    return (
        <div>
            {/*{info.row.original.commentOrder?.includes('Доставка') ? (
                info.row.original.datetimeOrder.length > 10 ? (
                    <Tooltip title={info.row.original.datetimeOrder}>
                        <p
                            style={{
                                background: colors.zeta,
                                color: currentTheme == 'light' ? colors.omicron : colors.eta,
                            }}
                            className={stls.cellDelivery}>
                            {info.row.original.datetimeOrder}
                        </p>
                    </Tooltip>
                ) : (
                    <p
                        style={{
                            background: colors.zeta,
                            color: currentTheme == 'light' ? colors.omicron : colors.eta,
                        }}
                        className={stls.cellDelivery}>
                        {info.row.original.datetimeOrder}
                    </p>
                )
            ) : (
                <></>
            )}*/}
        </div>
    );
}
