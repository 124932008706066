import { useContext } from 'react';
import { IconPageError } from '@f_components/icons/IconPageError';
import { Card, CardContent, Button, Link, Typography } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import ButtonsQwep from './components/ButtonsQwep';
import { Wrapper } from '@f_general/Wrapper';
import stls from '@f_styles/general/PageError.module.sass';

export const ErrorBoundaryComponent = ({ app }: any) => {
    const { colors } = useContext(ThemeContext);

    const renderButtons = (app: string) => {
        switch (app) {
            case 'Qwep':
                return <ButtonsQwep />;
            default:
                return (
                    <Button variant="outlined">
                        <Link underline="none" href="/">
                            Главная
                        </Link>
                    </Button>
                );
        }
    };

    return (
        <Wrapper>
            <CardContent className={stls.pageContent}>
                <IconPageError classNames={[stls.pageIcon]} />
                <div className={stls.contentContainer}>
                    <Typography variant="h6" sx={{ color: colors.phi }}>
                        Упс, потерялась страница!
                    </Typography>
                    <span>Похоже, нужная страница не найдена. Вот несколько полезных ссылок:</span>
                    {renderButtons(app)}
                </div>
            </CardContent>
        </Wrapper>
    );
};
