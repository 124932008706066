export const globalSort = (data: any[], searchArticle: string) => {
    return data.sort((a, b) => {
        // сортируем по артикулу
        if (a.article === searchArticle && b.article !== searchArticle) {
            return -1;
        }
        if (a.article !== searchArticle && b.article === searchArticle) {
            return 1;
        }

        // сортируем по статусу внутри каждой группы артикулов
        if (a.status === 'В наличии' && b.status !== 'В наличии') {
            return -1;
        }
        if (a.status !== 'В наличии' && b.status === 'В наличии') {
            return 1;
        }

        // сортируем по цене внутри каждой группы статусов
        if (a.price < b.price) {
            return -1;
        }
        if (a.price > b.price) {
            return 1;
        }

        return 0;
    });
};
