import { action, makeObservable, observable } from 'mobx';

// Глобальный стор модального окна - можно только экстендить или через композицию

export class ModalStore {
    isOpen: boolean = false;

    constructor() {
        makeObservable(this, {
            isOpen: observable,
            handleOpen: action.bound,
            handleClose: action.bound,
        });
    }

    handleOpen(): void {
        this.isOpen = true;
    }

    handleClose(): void {
        this.isOpen = false;
    }
}
