import { Card } from '@mui/material';
import stls from '@f_styles/components/my-company/MyCompany.module.sass';
import { IconCompany } from '@f_components/icons/IconCompany';
import { UserTable } from '@f_components/user-table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@f_context/User_context';
import { SearchBase } from '@f_general/SearchBase';
import { ThemeContext } from '@f_context/Theme_context';

type TypeUserListProps = {
    workspace: any;
};

export function UserList({ workspace }: TypeUserListProps) {
    const { users } = useContext(UserContext);
    const { t } = useTranslation();
    const [userTable, setUserTable] = useState(users);
    const { colors } = useContext(ThemeContext);

    useEffect(() => {
        setUserTable(users);
    }, [users]);

    return (
        <>
            {workspace?.workspaceName && (
                <Card className={stls.userList}>
                    <div className={stls.userListContent}>
                        <div className={stls.titleBox}>
                            <IconCompany classNames={[stls.iconCompanyUserList]} />
                            <p style={{ color: colors.omicron }} className={stls.title}>
                                {workspace.workspaceName}
                            </p>
                        </div>

                        <SearchBase
                            data={users}
                            placeholder={t('table.tableUser.userSearch')}
                            setData={setUserTable}
                            type={'users'}
                        />
                    </div>
                    {userTable.length ? <UserTable users={userTable} workspace={workspace} /> : <></>}
                </Card>
            )}
        </>
    );
}
