import { callSendFormData, callPlatformAPI } from '@f_utils/callPlatformAPI';
import React, { ChangeEvent, createContext, useContext, useEffect, useMemo, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { GeneralContext } from './General_context';
import { UserContext } from './User_context';

type TypeSendSupportChat = {
    phone: string;
    name: string;
    description: string;
    title: string;
    product: string;
    files: any;
    type: string;
};

const SupportContext = createContext<any>({});

function SupportProvider(props: any) {
    const { updateUserProperty } = useContext(GeneralContext);
    const { userInfo } = useContext(UserContext);
    const [currentTask, setCurrentTask] = useState({});
    const [currentChat, setCurrentChat] = useState([]);
    const [socket, setSocket] = useState<Socket>();
    const [socketMessages, setSocketMessages] = useState<any>();
    const [files, setFiles] = useState<any>([]);
    const [loadingFile, setLoadingFile] = useState(false);
    const [supportTasks, setSupportTasks] = useState([]);

    const [countNewMessage, setCountNewMessage] = useState(0);

    const types = [
        { value: '298f2a8bc52e', key: 'Лицензии', name: 'licenses', smile: '🏷' },
        { value: 'f00302b9a4c5', key: 'Функционал', name: 'functional', smile: '🚀' },
        { value: 'b46e44500cf0', key: 'Техподдержка', name: 'support', smile: '🦸' },
        { value: '602be00a5677', key: 'Другое', name: 'another', smile: '🗿' },
    ];

    const title: any = {
        '298f2a8bc52e': [
            { value: 'Не хватает лицензий', key: 'Не хватает лицензий', name: 'notLicenses' },
            { value: 'Продлить лицензию', key: 'Продлить лицензию', name: 'renewLicenses' },
            { value: 'Сменить тариф', key: 'Сменить тариф', name: 'changeTariff' },
            { value: 'Другое', key: 'Другое', name: 'another' },
        ],
        f00302b9a4c5: [
            { value: 'Добавление поставщика', key: 'Добавление поставщика', name: 'addVendor' },
            { value: 'Результаты поиска', key: 'Результаты поиска', name: 'searchResult' },
            { value: 'Нет брендов', key: 'Нет брендов', name: 'notBrands' },
            { value: 'Другое', key: 'Другое', name: 'another' },
        ],
        b46e44500cf0: [
            { value: 'Нужно обучение', key: 'Нужно обучение', name: 'needTraining' },
            { value: 'Не хватает функционала', key: 'Не хватает функционала', name: 'notFunctional' },
            { value: 'Некорректно работает', key: 'Некорректно работает', name: 'notWork' },
            { value: 'Другое', key: 'Другое', name: 'another' },
        ],
        '602be00a5677': [{ value: 'Другое', key: 'Другое', name: 'another' }],
    };

    useEffect(() => {
        getSupportProperty();
    }, []);

    useEffect(() => {
        const newSocket = io('https://web.qwep.ru');
        // const newSocket = io('http://localhost:3037')

        console.log('conection', newSocket);
        setSocket(newSocket);
    }, []);

    const messageListener = async (message: any) => {
        // console.log('message', message, supportTasks);
        if (message.userId === userInfo.userId) {
            if (message.data.payload.completed && supportTasks.length) {
                const date = new Date().getTime();
                await updateUserProperty({
                    sysName: 'support_id',
                    value: supportTasks?.map((el: any) =>
                        el.id === message.data.payload.id ? { ...el, date: date, status: 'close' } : el,
                    ),
                });
            } else if (message.data.event == 'chat_message-created') {
                const date = new Date().getTime();
                await updateUserProperty({
                    sysName: 'support_id',
                    value: supportTasks?.map((el: any) =>
                        el.id === message.data.payload.chatId ? { ...el, date: date } : el,
                    ),
                });
                setSocketMessages(message.data);
            }
            await getSupportProperty();
        } else {
            return socket?.disconnect();
        }
    };

    useMemo(() => {
        if (userInfo?.userId && supportTasks.length) {
            socket?.on('message', messageListener);
            return () => {
                socket?.off();
            };
        }
    }, [socket, userInfo, supportTasks]);

    useEffect(() => {
        if (socketMessages) {
            setCountNewMessage((prev: number) => (prev += 1));
        }
    }, [socketMessages]);

    const getSupportProperty = async () => {
        const result = await callPlatformAPI('support/get/property', 'get');
        // console.log('getSupportProperty', result);
        if (result.data.isOk) {
            setSupportTasks(result.data.result);
        }
    };

    const sendSupportChat = async (data: TypeSendSupportChat) => {
        const result = await callPlatformAPI('support/send/support', 'post', data);
        console.log('sendSupportChat', result);
        return result.data;
    };

    const getLinkToFile = async (file: File) => {
        let formdata = new FormData();
        const encodedFileName = encodeURIComponent(file.name);
        formdata.append('file', new File([file], encodedFileName, { type: file.type }));
        const result = await callSendFormData('support/get/link/file', 'post', formdata);
        return result.data;
    };

    const getSupportTask = async (id: any) => {
        const result = await callPlatformAPI(`support/get/task/${id}`, 'get');
        console.log('getSupportTask', result);
        return result.data;
    };

    const getSupportChat = async (id: string) => {
        const result = await callPlatformAPI(`support/get/chat/${id}`, 'get');
        console.log('getSupportChat', result);
        return result.data;
    };

    const sendSupportMessageChat = async (data: any) => {
        const result = await callPlatformAPI('support/send/chat', 'post', data);
        return result.data;
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setLoadingFile(true);
        if (e.target.files) {
            const result = await getLinkToFile(e.target.files[0]);
            if (result.isOk) {
                let reader = new FileReader();
                let title = e.target.files[0].name;
                let preview = e.target.files[0].type.includes('image');
                reader.onloadend = () => {
                    setFiles((prev: any) => [
                        {
                            imagePreviewUrl: preview ? reader.result : '/1200x600wa.png',
                            url: result.result,
                            title: title,
                        },
                        ...prev,
                    ]);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        }
        setLoadingFile(false);
    };

    return (
        <SupportContext.Provider
            value={{
                currentTask,
                currentChat,
                types,
                title,
                socketMessages,
                setSocketMessages,
                setCurrentChat,
                setCurrentTask,
                sendSupportChat,
                getSupportTask,
                getSupportChat,
                sendSupportMessageChat,
                getLinkToFile,
                files,
                setFiles,
                handleFileChange,
                loadingFile,
                getSupportProperty,
                supportTasks,
                countNewMessage,
                setCountNewMessage,
            }}
            {...props}
        />
    );
}

export { SupportProvider, SupportContext };
