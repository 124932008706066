import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

type TypeIconBasketProps = TypeClassNames & {
    width?: string;
    height?: string;
    color?: string;
};

export const IconBasket = ({ classNames, width, height, color }: TypeIconBasketProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg
                width={width ? width : '20'}
                height={height ? height : '20'}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.9881 4.74898L18.8586 11.1803C18.7654 11.7116 18.4957 12.1921 18.0965 12.5381C17.6972 12.8842 17.1938 13.0738 16.6737 13.0741H5.93439L6.34175 15.3813H16.2941C16.7336 15.3813 17.1632 15.5166 17.5286 15.7701C17.894 16.0237 18.1787 16.384 18.3469 16.8056C18.5151 17.2272 18.5591 17.6911 18.4734 18.1386C18.3876 18.5862 18.176 18.9973 17.8653 19.3199C17.5545 19.6426 17.1586 19.8623 16.7276 19.9514C16.2966 20.0404 15.8498 19.9947 15.4438 19.8201C15.0378 19.6454 14.6908 19.3497 14.4467 18.9703C14.2025 18.5909 14.0722 18.1448 14.0722 17.6885C14.0723 17.4263 14.1161 17.1661 14.2018 16.9194H8.75809C8.84377 17.1661 8.88762 17.4263 8.8877 17.6885C8.88837 18.0608 8.80229 18.4276 8.6368 18.7579C8.47131 19.0881 8.23133 19.3718 7.93735 19.5848C7.64337 19.7979 7.30411 19.9339 6.94853 19.9812C6.59295 20.0286 6.23161 19.9859 5.89536 19.8569C5.55911 19.7278 5.25793 19.5162 5.01752 19.24C4.77711 18.9639 4.60462 18.6314 4.51477 18.2711C4.42492 17.9107 4.42038 17.5331 4.50154 17.1705C4.58269 16.8079 4.74713 16.4711 4.98082 16.1888L2.41634 1.53813H0.740642C0.544211 1.53813 0.355826 1.4571 0.216929 1.31288C0.0780316 1.16865 0 0.973034 0 0.769065C0 0.565096 0.0780316 0.369482 0.216929 0.225254C0.355826 0.0810263 0.544211 0 0.740642 0H2.41634C2.76207 0.00078531 3.09665 0.12712 3.36211 0.357119C3.62757 0.587118 3.80719 0.906291 3.86985 1.25934L4.3235 3.84533H19.2567C19.3648 3.84556 19.4715 3.87068 19.5692 3.91889C19.6669 3.9671 19.753 4.03719 19.8214 4.12411C19.893 4.20831 19.9453 4.30833 19.9741 4.41659C20.003 4.52485 20.0078 4.63852 19.9881 4.74898Z"
                    fill={color ? color : colors.phi}
                />
            </svg>
        </div>
    );
};
