import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconTimeProps = TypeClassNames;

export const IconTime = ({ classNames }: TypeIconTimeProps) => {
    return (
        <svg width="15" height="14" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.75496 16.8337C4.15246 16.8337 0.421631 13.1028 0.421631 8.50033C0.421631 3.89783 4.15246 0.166992 8.75496 0.166992C13.3575 0.166992 17.0883 3.89783 17.0883 8.50033C17.0883 13.1028 13.3575 16.8337 8.75496 16.8337ZM8.75496 15.167C10.5231 15.167 12.2188 14.4646 13.469 13.2144C14.7193 11.9641 15.4216 10.2684 15.4216 8.50033C15.4216 6.73222 14.7193 5.03652 13.469 3.78628C12.2188 2.53604 10.5231 1.83366 8.75496 1.83366C6.98685 1.83366 5.29116 2.53604 4.04092 3.78628C2.79068 5.03652 2.0883 6.73222 2.0883 8.50033C2.0883 10.2684 2.79068 11.9641 4.04092 13.2144C5.29116 14.4646 6.98685 15.167 8.75496 15.167ZM9.5883 8.50033H12.9216V10.167H7.92163V4.33366H9.5883V8.50033Z"
                fill="#EDF0F5"
            />
        </svg>
    );
};
