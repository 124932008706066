import stls from '@f_qwep/styles/general/LayoutPage.module.sass';
import { Wrapper } from '@f_general/Wrapper';

type TypeLayoutPageProps = {
    children: any;
    classNames?: any;
};

export function LayoutPage({ children, classNames }: TypeLayoutPageProps) {
    return (
        <div className={stls.container}>
            <Wrapper classNames={[classNames, stls.wrapper]}>{children}</Wrapper>
        </div>
    );
}
