import { action, makeObservable, observable } from 'mobx';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';

export class AssistantModalControlStore {
    currentTypeModal: ConfigPanelModalType = ConfigPanelModalType.NULL;
    isModalOpen: boolean = false;

    constructor() {
        makeObservable(this, {
            currentTypeModal: observable,
            isModalOpen: observable,
            openModal: action.bound,
            closeModal: action.bound,
            setCurrentTypeModal: action.bound,
        });
    }

    openModal(typeConfigModal: ConfigPanelModalType): void {
        this.isModalOpen = true;
        this.currentTypeModal = typeConfigModal;
    }

    closeModal(): void {
        this.isModalOpen = false;
        this.currentTypeModal = ConfigPanelModalType.NULL;
    }

    setCurrentTypeModal(type: ConfigPanelModalType) {
        this.currentTypeModal = type;
    }
}
