import { Tooltip } from '@mui/material';
import { OrderStatus } from '@f_qwep/context/Orders_context';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';




export function CellOrderStatus({ info }: any) {
    const orderStatus = {
        [OrderStatus.CLOSED]: <Tooltip title={'Закрытый заказ'}><LockIcon /></Tooltip>,
        [OrderStatus.OPENED]: <Tooltip title={'Открытый заказ'}><LockOpenIcon /></Tooltip>,

    }
    return orderStatus[info.row.original.orderStatus];
}
