import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconMaskotWaitProps = TypeClassNames;

export const IconMaskotWait = ({ classNames }: TypeIconMaskotWaitProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div style={{ position: 'fixed', marginLeft: '-200px' }} className={cn(classNames)}>
            <svg width="200" height="200" viewBox="0 0 454 461" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask
                    id="path-1-outside-1_5819_12658"
                    maskUnits="userSpaceOnUse"
                    x="211.285"
                    y="285.343"
                    width="38"
                    height="48"
                    fill="black">
                    <rect fill="white" x="211.285" y="285.343" width="38" height="48" />
                    <path d="M215.285 285.343H246.075V332.712H215.285V285.343Z" />
                </mask>
                <path d="M215.285 285.343H246.075V332.712H215.285V285.343Z" fill="#A2DC05" />
                <path
                    d="M242.955 285.343V332.712H249.194V285.343H242.955ZM218.404 332.712V285.343H212.165V332.712H218.404Z"
                    fill="black"
                    mask="url(#path-1-outside-1_5819_12658)"
                />
                <path d="M215.285 285.343H244.89V332.712H215.285V285.343Z" fill="#51C407" />
                <path
                    d="M215.285 286.383C215.285 285.808 215.75 285.343 216.324 285.343H243.851C244.425 285.343 244.89 285.808 244.89 286.383V288.475C244.89 288.996 244.504 289.437 243.986 289.505L223.845 292.156C223.328 292.224 222.941 292.665 222.941 293.187V321.159V331.672C222.941 332.246 222.476 332.712 221.902 332.712H216.324C215.75 332.712 215.285 332.246 215.285 331.672V286.383Z"
                    fill="#17A61A"
                />
                <path
                    d="M317.567 341.734L316.465 341.06L315.484 341.902L295.579 358.997C294.421 359.992 294.209 361.705 295.09 362.952L337.113 422.432L337.178 422.525L337.255 422.608L340.562 426.213L340.731 426.397L340.945 426.528L344.518 428.71L344.668 428.802L344.833 428.862L346.838 429.597L346.941 429.635L347.049 429.659L349.63 430.255L349.82 430.298L350.015 430.3L352.456 430.319L353.728 430.33L354.147 429.128L362.786 404.365L363.132 403.372L362.428 402.591L351.706 390.691L336.198 366.587L327.995 352.762L327.947 352.681L327.89 352.605L322.491 345.381L322.363 345.211L322.199 345.075L319.916 343.19L319.819 343.11L319.711 343.044L317.567 341.734Z"
                    fill="#02A608"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M302.035 358.268L316.588 342.75L319.539 345.104L321.788 347.011L327.171 354.247L334.952 368.011L350.686 392.175L361.061 404.481L351.341 425.314L347.376 422.142L346.813 417.117L302.035 358.268Z"
                    fill="#8CEC40"
                />
                <path
                    d="M302.234 359.796L306.253 361.562L309.128 362.018C309.279 362.042 309.433 362.036 309.581 362.002L312.172 361.401L314.282 360.306L315.828 359.341L317.374 358.377L318.776 356.84L320.035 354.731C320.459 353.99 321.508 353.938 322.002 354.634L323.309 356.474L331.801 370.142L347.593 394.267L357.767 406.708L350.374 425.968L346.587 422.676L343.433 418.786L342.937 411.962L337.24 406.028L327.434 393.168L302.234 359.796Z"
                    fill="#63D116"
                />
                <circle
                    cx="13.4166"
                    cy="13.4166"
                    r="15.193"
                    transform="matrix(0.620475 0.784227 0.784227 -0.620475 283.626 341.767)"
                    fill="url(#paint0_linear_5819_12658)"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M308.25 339.393C312.792 345.134 313.335 353.317 308.137 357.43C302.939 361.543 295.1 359.132 290.558 353.391C286.016 347.65 285.473 339.467 290.671 335.354C295.869 331.242 303.708 333.653 308.25 339.393Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M305.432 356.943C298.899 352.823 300.104 354.346 295.632 348.694C292.493 342.034 292.928 344.26 292.624 336.229C287.725 338.262 287.153 346.716 291.767 352.057C296.239 357.709 302.529 358.715 305.432 356.943Z"
                    fill="#A8A7A8"
                />
                <path
                    d="M241.41 388.936L241.41 388.936C242.157 389.502 242.534 390.301 242.715 391.055C242.894 391.802 242.914 392.628 242.851 393.461C242.725 395.127 242.236 397.179 241.531 399.445C240.117 403.986 237.704 409.777 234.97 415.856C231.178 426.02 228.243 431.543 225.765 434.361C224.501 435.799 223.226 436.684 221.871 437.003C220.482 437.33 219.303 436.993 218.408 436.59C217.153 436.026 216.323 434.953 215.918 433.543C215.533 432.206 215.512 430.534 215.78 428.534C216.316 424.524 218.103 418.584 221.557 409.969L221.58 409.912L221.606 409.857C224.821 403.202 228.553 397.517 231.85 393.705C233.488 391.813 235.087 390.3 236.525 389.379C237.239 388.921 238.013 388.543 238.803 388.393C239.624 388.237 240.584 388.311 241.41 388.936Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M233.225 415.135C238.703 402.955 243.577 392.464 240.154 390.148C238.61 391.641 230.564 396.758 224.128 409.79C217.463 427.066 213.956 432.64 217.638 434.296C221.32 435.952 226.042 435.699 233.225 415.135Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M233.359 415.196C238.837 403.015 242.989 393.118 240.746 391.333C239.433 392.929 229.858 397.18 223.574 410.28C216.617 427.425 217.101 434.055 219.614 435.185C222.126 436.315 225.631 435.514 233.359 415.196Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M221.289 411.419L221.335 411.299L221.398 411.187C222.066 409.999 222.579 408.706 223.121 407.342L223.151 407.266C223.681 405.932 224.262 404.472 225.034 403.247C225.827 401.99 226.914 400.826 228.534 400.232C230.142 399.643 231.998 399.717 234.135 400.362C235.791 400.863 236.949 401.907 237.441 403.431C237.884 404.801 237.704 406.318 237.357 407.698C236.804 409.898 235.607 412.505 234.472 414.979C234.192 415.591 233.915 416.194 233.652 416.781C232.203 420.022 230.388 422.817 228.568 424.706C227.663 425.645 226.687 426.433 225.684 426.91C224.689 427.383 223.437 427.648 222.222 427.104C220.151 426.179 219.319 423.83 219.221 421.351C219.118 418.759 219.757 415.402 221.289 411.419Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M229.54 412.513C230.762 413.139 231.724 413.897 232.352 414.851C233.002 415.84 233.197 416.892 233.127 417.905C233.06 418.871 232.753 419.798 232.424 420.613C232.206 421.154 231.935 421.742 231.68 422.296C231.562 422.554 231.447 422.804 231.341 423.039C230.481 424.964 229.14 426.542 227.582 427.5C226.033 428.453 224.092 428.878 222.278 428.067C220.464 427.256 219.486 425.525 219.163 423.736C218.839 421.937 219.12 419.885 219.981 417.96C220.099 417.694 220.242 417.357 220.393 417.001C220.612 416.482 220.849 415.922 221.054 415.484C221.44 414.66 221.935 413.745 222.641 413.009C223.389 412.228 224.383 411.64 225.67 411.543C226.895 411.45 228.183 411.817 229.54 412.513Z"
                    fill="#A1A1A1"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M153.28 337.934L154.559 337.747L155.13 338.907L166.721 362.446C167.396 363.816 166.918 365.474 165.618 366.275L103.61 404.471L103.514 404.531L103.411 404.577L98.9539 406.593L98.7259 406.696L98.4782 406.732L94.3352 407.336L94.1611 407.362L93.9855 407.352L91.8535 407.24L91.7431 407.234L91.6342 407.215L89.0275 406.749L88.8356 406.714L88.6557 406.639L86.4029 405.698L85.2288 405.208L85.3152 403.939L87.0968 377.772L87.1683 376.723L88.1225 376.281L102.657 369.549L126.386 353.473L139.359 343.981L139.435 343.925L139.517 343.877L147.32 339.355L147.504 339.248L147.708 339.188L150.548 338.35L150.669 338.315L150.794 338.296L153.28 337.934Z"
                    fill="#02A608"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M161.071 359.24L153.781 339.253L150.144 340.259L147.326 341.13L139.534 345.67L126.971 355.272L103.012 371.315L88.6368 378.557L89.3936 401.533L94.2859 400.173L96.7776 395.774L161.071 359.24Z"
                    fill="#8CEC40"
                />
                <path
                    d="M160.287 360.568L155.897 360.613L153.074 359.903C152.926 359.866 152.787 359.8 152.664 359.71L150.517 358.14L149.008 356.304L147.964 354.81L146.921 353.316L146.235 351.352L145.906 348.917C145.807 348.07 144.863 347.61 144.135 348.056L142.21 349.235L129.033 358.469L105.035 374.453L90.7914 381.899L90.0262 402.515L94.802 400.975L99.23 398.636L102.366 392.555L109.936 389.335L124.006 381.36L160.287 360.568Z"
                    fill="#63D116"
                />
                <circle
                    cx="166.79"
                    cy="346.739"
                    r="15.193"
                    transform="rotate(-171.025 166.79 346.739)"
                    fill="url(#paint1_linear_5819_12658)"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M167.939 339.463C160.709 338.321 153.311 341.861 152.277 348.409C151.243 354.956 157.189 360.604 164.42 361.746C171.651 362.888 179.048 359.347 180.082 352.8C181.116 346.253 175.17 340.604 167.939 339.463Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M154.029 350.527C160.824 354.198 158.906 353.896 166.024 355.02C173.368 354.488 171.216 355.201 178.363 351.527C178.994 356.793 171.908 361.439 164.99 360.038C157.872 358.913 153.909 353.926 154.029 350.527Z"
                    fill="#A8A7A8"
                />
                <path
                    d="M169.577 346.06C168.769 335.954 176.721 327.302 186.844 327.241L230.316 331.143C233.411 331.421 236.525 331.419 239.62 331.137L282.475 327.241C293.128 327.304 301.411 336.557 300.28 347.166L297.775 370.651L293.056 405.251C291.655 415.529 283.312 423.459 272.978 424.339L238.849 427.243C236.262 427.463 233.662 427.468 231.075 427.257L195.248 424.332C184.603 423.463 176.092 415.124 175.005 404.499L171.551 370.725L169.577 346.06Z"
                    fill="#65D018"
                    stroke="black"
                    stroke-width="4.92473"
                />
                <path
                    d="M174.023 377.104L175.754 401.686C176.531 412.727 185.168 421.592 196.184 422.658L217.114 423.889C217.326 423.889 217.423 423.626 217.262 423.488L200.709 409.3C197.784 406.793 195.823 403.345 195.163 399.549L191.259 377.104L188.181 330.319C188.181 329.979 187.906 329.704 187.566 329.704H185.368C185.192 329.704 185.016 329.72 184.844 329.753L183.566 329.995C182.43 330.21 181.324 330.559 180.271 331.035C174.67 333.563 171.245 339.32 171.695 345.449L174.023 377.104Z"
                    fill="#00A708"
                />
                <path
                    d="M284.233 329.728L283.068 329.82C279.446 330.104 279.331 335.371 282.938 335.813C283.187 335.844 283.425 335.936 283.63 336.082L286.57 338.182C287.274 338.685 287.935 339.245 288.546 339.856L288.667 339.977C290.017 341.327 291.141 342.886 291.995 344.594L292.054 344.714C292.793 346.191 293.332 347.761 293.656 349.381L294.018 351.192C294.272 352.459 294.425 353.745 294.477 355.037L295.014 368.486L295.63 362.33L297.229 351.135C297.394 349.981 297.477 348.818 297.477 347.653V345.093L297.167 342.611C296.964 340.995 296.489 339.424 295.76 337.967C295.265 336.976 294.656 336.045 293.947 335.194L293.91 335.149C292.608 333.588 290.973 332.3 289.155 331.391C288.553 331.09 287.923 330.828 287.284 330.615L284.85 329.804C284.652 329.738 284.442 329.712 284.233 329.728Z"
                    fill="#8CEC40"
                />
                <path
                    d="M236.653 364.672C237.261 362.058 237.177 364.296 237.917 355.139C235.11 351.422 225.624 343.925 223.962 345.422C222.706 347.331 225.145 349.86 227.078 352.694C231.503 359.182 234.197 364.555 236.653 364.672Z"
                    fill="black"
                />
                <path d="M237.259 380.582L217.092 380.585L237.173 360.129L237.259 380.582Z" fill="black" />
                <path
                    d="M220.081 380.658C218.406 381.646 217.096 380.913 210.918 380.558C207.617 377.636 201.115 368.431 202.584 366.996C204.423 365.969 206.673 368.437 209.253 370.426C214.955 374.823 220.035 378.37 220.081 380.658Z"
                    fill="black"
                />
                <path
                    d="M213.164 373.445L230.02 356.064L235.106 362.27L219.065 378.586L213.164 373.445Z"
                    fill="black"
                />
                <path
                    d="M206.105 368.052L224.962 348.948L230.05 356.136L213.153 373.436L206.105 368.052Z"
                    fill="black"
                />
                <path
                    d="M202.607 366.978L223.997 345.376L224.004 346.607L224.172 347.387L224.547 348.291L224.961 348.948L206.185 368.091L205.362 367.721L203.84 367.185L202.607 366.978Z"
                    fill="black"
                />
                <path
                    d="M238.018 396.107C237.375 398.713 238.019 396.408 236.668 405.634C239.474 409.332 248.808 417.014 250.49 415.54C251.772 413.648 249.367 411.086 247.473 408.226C243.136 401.679 240.473 396.258 238.018 396.107Z"
                    fill="black"
                />
                <path d="M237.26 380.581L257.713 380.536L237.632 400.706L237.26 380.581Z" fill="black" />
                <path
                    d="M254.765 380.542C257.24 380.202 255.214 380.377 263.723 380.317C266.984 383.285 273.646 392.843 272.157 394.258C270.305 395.259 268.127 392.988 265.587 390.949C259.77 386.281 254.739 382.833 254.765 380.542Z"
                    fill="black"
                />
                <rect
                    x="239.342"
                    y="398.705"
                    width="2.94547"
                    height="2.94547"
                    transform="rotate(134.212 239.342 398.705)"
                    fill="black"
                />
                <path
                    d="M261.799 387.882L244.669 404.91L239.629 398.656L255.67 382.545L261.799 387.882Z"
                    fill="black"
                />
                <path
                    d="M268.652 393.154L249.578 412.042L244.548 404.744L261.604 387.703L268.652 393.154Z"
                    fill="black"
                />
                <path
                    d="M272.158 394.258L250.479 415.57L250.493 414.343L250.339 413.564L249.981 412.66L249.579 412.001L268.578 393.11L269.426 393.49L270.933 394.039L272.158 394.258Z"
                    fill="black"
                />
                <path
                    d="M221.645 380.699C218.936 380.498 219.019 380.907 210.919 380.515C207.257 383.369 200.904 392.247 202.399 393.91C204.307 395.167 207.044 392.719 209.879 390.789C216.371 386.368 221.526 383.156 221.645 380.699Z"
                    fill="black"
                />
                <path
                    d="M219.349 382.543L235.296 398.257L234.205 399.258L219.145 382.789L219.349 382.543Z"
                    fill="black"
                />
                <path d="M237.302 380.573L237.594 400.83L217.22 380.625L237.302 380.573Z" fill="black" />
                <path
                    d="M237.217 397.718C237.588 400.188 237.668 398.208 237.679 406.847C234.753 410.145 225.424 416.802 223.99 415.331C222.965 413.492 225.294 411.064 227.301 408.498C231.894 402.623 235.214 398.172 237.217 397.718Z"
                    fill="black"
                />
                <path
                    d="M230.233 404.851L212.988 387.941L219.26 382.637L235.542 398.704L230.233 404.851Z"
                    fill="black"
                />
                <path
                    d="M225.048 411.812L205.919 392.982L213.152 387.858L230.409 404.695L225.048 411.812Z"
                    fill="black"
                />
                <path
                    d="M223.99 415.331L202.402 393.927L203.629 393.925L204.406 393.76L205.306 393.391L205.959 392.981L225.099 411.775L224.722 412.589L224.193 414.103L223.99 415.331Z"
                    fill="black"
                />
                <rect
                    x="239.665"
                    y="398.668"
                    width="2.94547"
                    height="2.94547"
                    transform="rotate(134.212 239.665 398.668)"
                    fill="black"
                />
                <path
                    d="M253.661 380.341C256.756 380.335 254.81 380.641 263.723 380.319C267.383 377.463 273.879 368.573 272.489 367.046C270.683 365.922 268.182 368.172 265.359 370.118C258.895 374.574 253.866 377.998 253.661 380.341Z"
                    fill="#000400"
                />
                <path d="M237.259 380.58L237.173 360.004L257.712 380.535L237.259 380.58Z" fill="#000400" />
                <path
                    d="M237.49 363.049C237.174 360.581 237.093 362.711 237.078 354.118C240.001 350.817 249.303 344.108 250.738 345.577C251.765 347.415 249.219 349.607 247.308 352.245C243.712 357.212 240.001 362.743 237.49 363.049Z"
                    fill="#000400"
                />
                <path
                    d="M244.503 356.021L261.762 372.918L255.622 378.457L239.264 362.171L244.503 356.021Z"
                    fill="#000400"
                />
                <path
                    d="M249.666 349.1L268.827 367.913L261.598 373.042L244.327 356.219L249.666 349.1Z"
                    fill="#000400"
                />
                <path
                    d="M250.738 345.577L272.343 366.964L271.116 366.967L270.339 367.132L269.44 367.502L268.786 367.913L249.625 349.141L250.008 348.319L250.536 346.805L250.738 345.577Z"
                    fill="#000400"
                />
                <rect
                    x="257.786"
                    y="380.468"
                    width="2.94547"
                    height="2.94547"
                    transform="rotate(134.212 257.786 380.468)"
                    fill="#000400"
                />
                <path
                    d="M217.046 380.715L219.071 378.576L221.21 380.601L219.185 382.74L217.046 380.715Z"
                    fill="#000400"
                />
                <rect
                    x="239.257"
                    y="362.183"
                    width="2.94547"
                    height="2.94547"
                    transform="rotate(134.212 239.257 362.183)"
                    fill="#000400"
                />
                <path
                    d="M236.676 366.273C237.22 363.935 237.145 365.936 237.807 357.745C235.296 354.42 226.81 347.713 225.323 349.052C224.199 350.76 226.382 353.022 228.111 355.557C232.07 361.361 234.479 366.168 236.676 366.273Z"
                    fill="#0069A6"
                />
                <path d="M237.219 380.507L219.177 380.51L237.142 362.209L237.219 380.507Z" fill="#00BAF2" />
                <path
                    d="M221.851 380.575C220.352 381.459 219.18 380.803 213.653 380.485C210.7 377.871 204.883 369.636 206.198 368.352C207.843 367.434 209.855 369.641 212.163 371.421C217.265 375.354 221.81 378.528 221.851 380.575Z"
                    fill="#0069A6"
                />
                <path
                    d="M215.661 374.122L230.741 358.572L235.291 364.125L220.941 378.721L215.661 374.122Z"
                    fill="#00AEEF"
                />
                <path
                    d="M209.347 369.297L226.216 352.206L230.768 358.637L215.652 374.113L209.347 369.297Z"
                    fill="#0095DA"
                />
                <path
                    d="M206.218 368.336L225.354 349.011L225.36 350.112L225.511 350.81L225.846 351.618L226.217 352.206L209.419 369.332L208.683 369.001L207.321 368.521L206.218 368.336Z"
                    fill="#00AEEF"
                />
                <path
                    d="M237.899 394.396C237.323 396.727 237.899 394.666 236.691 402.92C239.201 406.228 247.551 413.1 249.056 411.781C250.202 410.089 248.051 407.796 246.356 405.238C242.477 399.381 240.095 394.531 237.899 394.396Z"
                    fill="#0069A6"
                />
                <path d="M237.22 380.506L255.517 380.466L237.552 398.51L237.22 380.506Z" fill="#00BAF2" />
                <path
                    d="M252.881 380.471C255.095 380.168 253.283 380.324 260.894 380.27C263.812 382.925 269.772 391.476 268.44 392.742C266.783 393.638 264.835 391.605 262.562 389.781C257.359 385.606 252.858 382.521 252.881 380.471Z"
                    fill="#0069A6"
                />
                <rect
                    x="239.082"
                    y="396.72"
                    width="2.63483"
                    height="2.63483"
                    transform="rotate(134.212 239.082 396.72)"
                    fill="#F5F5F5"
                />
                <path
                    d="M259.173 387.037L243.849 402.27L239.34 396.676L253.69 382.263L259.173 387.037Z"
                    fill="#00AEEF"
                />
                <path
                    d="M265.304 391.754L248.241 408.651L243.74 402.123L258.999 386.877L265.304 391.754Z"
                    fill="#0095DA"
                />
                <path
                    d="M268.44 392.742L249.046 411.808L249.059 410.71L248.92 410.013L248.6 409.205L248.241 408.615L265.237 391.715L265.996 392.055L267.344 392.546L268.44 392.742Z"
                    fill="#00AEEF"
                />
                <path
                    d="M223.249 380.612C220.826 380.432 220.9 380.798 213.653 380.448C210.378 383.001 204.694 390.943 206.031 392.431C207.738 393.556 210.187 391.366 212.723 389.639C218.53 385.684 223.142 382.81 223.249 380.612Z"
                    fill="#0F9647"
                />
                <path
                    d="M221.195 382.261L235.461 396.319L234.485 397.215L221.012 382.481L221.195 382.261Z"
                    fill="#DFE9A6"
                />
                <path d="M237.256 380.499L237.517 398.62L219.291 380.545L237.256 380.499Z" fill="#9EDD00" />
                <path
                    d="M237.18 395.839C237.512 398.049 237.584 396.277 237.593 404.005C234.976 406.956 226.63 412.911 225.347 411.595C224.43 409.95 226.514 407.778 228.309 405.483C232.418 400.226 235.388 396.245 237.18 395.839Z"
                    fill="#0B9444"
                />
                <path
                    d="M230.932 402.219L215.504 387.091L221.115 382.347L235.681 396.72L230.932 402.219Z"
                    fill="#8CC63F"
                />
                <path
                    d="M226.294 408.447L209.18 391.601L215.651 387.018L231.09 402.08L226.294 408.447Z"
                    fill="#39B54A"
                />
                <path
                    d="M225.347 411.595L206.035 392.447L207.132 392.445L207.828 392.298L208.632 391.967L209.217 391.601L226.339 408.414L226.002 409.142L225.529 410.497L225.347 411.595Z"
                    fill="#9EDD00"
                />
                <rect
                    x="239.37"
                    y="396.688"
                    width="2.63483"
                    height="2.63483"
                    transform="rotate(134.212 239.37 396.688)"
                    fill="#F5F5F5"
                />
                <path
                    d="M251.891 380.292C254.66 380.286 252.92 380.559 260.893 380.272C264.167 377.717 269.979 369.764 268.735 368.398C267.119 367.392 264.882 369.405 262.357 371.146C256.574 375.132 252.075 378.196 251.891 380.292Z"
                    fill="#0B9444"
                />
                <path d="M237.218 380.506L237.141 362.099L255.515 380.466L237.218 380.506Z" fill="#9EDD01" />
                <path
                    d="M237.425 364.821C237.142 362.612 237.07 364.518 237.056 356.831C239.671 353.878 247.993 347.875 249.277 349.19C250.195 350.835 247.918 352.795 246.208 355.155C242.991 359.599 239.672 364.546 237.425 364.821Z"
                    fill="#0B9444"
                />
                <path
                    d="M243.699 358.534L259.138 373.65L253.645 378.605L239.012 364.036L243.699 358.534Z"
                    fill="#8CC63F"
                />
                <path
                    d="M248.318 352.341L265.459 369.172L258.992 373.76L243.542 358.71L248.318 352.341Z"
                    fill="#39B54A"
                />
                <path
                    d="M249.277 349.19L268.605 368.323L267.507 368.326L266.812 368.473L266.008 368.805L265.423 369.172L248.282 352.378L248.624 351.643L249.097 350.288L249.277 349.19Z"
                    fill="#9EDD01"
                />
                <rect
                    x="255.582"
                    y="380.405"
                    width="2.63483"
                    height="2.63483"
                    transform="rotate(134.212 255.582 380.405)"
                    fill="#F5F5F5"
                />
                <path
                    d="M219.134 380.626L220.946 378.713L222.859 380.524L221.048 382.438L219.134 380.626Z"
                    fill="#F5F5F5"
                />
                <rect
                    x="239.005"
                    y="364.047"
                    width="2.63483"
                    height="2.63483"
                    transform="rotate(134.212 239.005 364.047)"
                    fill="#F5F5F5"
                />
                <path
                    d="M256.697 439.883C250.977 443.537 245.512 445.31 241.235 445.883C239.103 446.168 237.217 446.162 235.716 445.911C234.966 445.785 234.264 445.591 233.659 445.309C233.075 445.036 232.444 444.618 232.027 443.964C231.532 443.189 231.503 442.33 231.751 441.579C231.973 440.907 232.404 440.336 232.838 439.872C233.71 438.938 235.059 437.955 236.645 436.936C239.125 435.342 242.541 433.44 246.475 431.249C247.606 430.619 248.781 429.965 249.989 429.287C252.709 427.549 255.331 425.786 257.77 424.141C257.935 424.03 258.1 423.919 258.264 423.808C260.519 422.287 262.619 420.87 264.457 419.702C266.406 418.463 268.164 417.435 269.562 416.858C270.246 416.576 270.994 416.337 271.713 416.307C272.47 416.277 273.513 416.489 274.148 417.484C274.679 418.316 274.625 419.27 274.509 419.922C274.38 420.639 274.098 421.416 273.732 422.2C272.995 423.78 271.789 425.665 270.228 427.654C267.101 431.64 262.394 436.244 256.697 439.883Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M233.03 439.871C234.246 441.774 244.716 443.059 255.74 436.018C266.764 428.976 274.001 420.316 272.577 418.333C271.154 416.35 261.935 422.125 251.338 429.127C240.422 435.326 233.443 438.336 233.03 439.871Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M233.472 439.767C234.354 441.149 244.547 441.982 255.583 434.933C266.581 428.2 270.784 422.602 272.577 420.109C268.558 416.704 260.295 426.537 249.092 433.342C238.424 439.822 234.866 439.135 233.472 439.767Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M257.122 428.027L257.21 428.065L257.294 428.113C259.096 429.145 260.542 430.235 261.222 431.529C261.601 432.249 261.754 433.054 261.578 433.885C261.412 434.675 260.984 435.32 260.514 435.831C259.612 436.811 258.204 437.666 256.689 438.471C255.518 439.093 254.136 439.759 252.643 440.479C252.166 440.709 251.677 440.944 251.181 441.185C247.145 443.385 242.949 444.647 239.614 445.16C237.947 445.416 236.43 445.496 235.218 445.383C234.617 445.327 234.018 445.218 233.491 445.017C233.005 444.832 232.314 444.469 231.911 443.732C231.503 442.986 231.568 442.22 231.748 441.65C231.921 441.101 232.234 440.606 232.558 440.188C233.21 439.346 234.196 438.448 235.355 437.542C237.699 435.71 241.098 433.575 244.996 431.444C248.838 429.344 251.226 428.223 252.917 427.758C253.801 427.515 254.576 427.427 255.321 427.507C256.063 427.587 256.653 427.822 257.122 428.027Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M247.398 433.77L247.453 433.812L247.505 433.859C248.91 435.134 249.949 436.28 250.527 437.361C251.153 438.532 251.331 439.839 250.614 441.062C250.023 442.07 248.974 442.712 248.087 443.172C247.382 443.539 246.527 443.91 245.655 444.289C245.402 444.399 245.147 444.51 244.895 444.621C242.384 445.973 239.614 446.357 237.349 446.198C236.204 446.118 235.127 445.896 234.23 445.545C233.413 445.226 232.428 444.677 231.911 443.731C231.347 442.7 231.519 441.629 231.877 440.813C232.225 440.019 232.817 439.281 233.477 438.623C234.81 437.293 236.79 435.93 239.045 434.697C241.21 433.513 242.785 432.825 244.083 432.69C244.805 432.615 245.454 432.705 246.062 432.953C246.623 433.182 247.071 433.517 247.398 433.77Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M221.909 426.355L221.931 426.353L221.953 426.352C232.746 425.424 238.599 426.968 241.89 429.381C245.116 431.747 246.254 435.222 246.881 439.278C247.182 441.232 246.668 442.82 245.493 444.202C244.265 445.647 242.257 446.924 239.521 447.979C234.05 450.088 226.26 451.088 218.002 451.195C209.855 451.301 202.546 449.909 197.329 447.647C191.943 445.312 189.464 442.387 189.43 439.778C189.415 438.644 190.074 437.272 191.801 435.731C193.505 434.21 196.03 432.728 199.17 431.398C205.439 428.745 213.799 426.851 221.909 426.355Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M245.07 438.089C244.901 446.053 237.696 448.46 222.078 449.062C204.338 449.747 190.561 448.508 191.193 438.79C194.219 433.493 203.46 430.008 219.851 428.996C239.35 428.743 245.167 433.546 245.07 438.089Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M242.972 434.912C248.618 430.378 228.685 423.789 212.182 426.623C196.427 430.035 190.039 436.112 191.18 440.852C192.516 443.786 209.222 429.505 209.222 437.281C209.222 451.492 237.051 439.667 242.972 434.912Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M269.417 437.464C263.215 440.219 257.546 441.153 253.231 441.078C251.081 441.041 249.217 440.752 247.77 440.278C247.048 440.042 246.383 439.745 245.827 439.375C245.29 439.018 244.729 438.509 244.415 437.801C244.041 436.961 244.142 436.107 244.5 435.401C244.819 434.771 245.332 434.27 245.829 433.876C246.832 433.083 248.313 432.314 250.034 431.544C252.725 430.34 256.387 428.971 260.605 427.395C261.818 426.942 263.078 426.471 264.374 425.982C270.403 423.307 276.109 421.328 280.482 420.302C282.66 419.791 284.597 419.494 286.114 419.5C286.865 419.503 287.621 419.579 288.286 419.806C288.948 420.033 289.739 420.492 290.141 421.398C290.544 422.305 290.354 423.2 290.078 423.843C289.802 424.488 289.351 425.1 288.849 425.66C287.837 426.788 286.318 428.027 284.479 429.3C280.776 431.863 275.464 434.778 269.417 437.464Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M245.666 436.247C246.583 438.312 255.957 439.062 268.869 435.488C280.824 430.178 288.155 424.227 288.715 422.166C287.604 419.989 276.903 423.034 265.377 428.368C253.655 432.861 246.303 434.789 245.666 436.247Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M245.223 436.386C244.491 434.919 251.539 434.246 263.256 428.399C273.096 422.834 286.208 418.089 288.682 422.096C280.355 423.619 271.135 432.688 263.256 435.297C251.474 438.565 246.482 435.515 245.223 436.386Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M271.615 425.804L271.696 425.856L271.772 425.916C273.399 427.206 274.665 428.5 275.144 429.882C275.41 430.651 275.44 431.469 275.143 432.264C274.86 433.021 274.34 433.594 273.799 434.029C272.76 434.863 271.24 435.497 269.621 436.066C268.37 436.505 266.903 436.957 265.32 437.444C264.814 437.6 264.296 437.76 263.769 437.924C259.448 439.494 255.111 440.113 251.736 440.12C250.05 440.124 248.538 439.975 247.357 439.682C246.771 439.537 246.195 439.339 245.704 439.061C245.251 438.805 244.622 438.343 244.335 437.554C244.043 436.755 244.222 436.007 244.486 435.471C244.739 434.954 245.122 434.512 245.505 434.147C246.276 433.412 247.386 432.672 248.667 431.95C251.259 430.49 254.94 428.888 259.113 427.366C263.227 425.865 265.755 425.114 267.498 424.909C268.408 424.801 269.187 424.83 269.912 425.021C270.634 425.211 271.181 425.531 271.615 425.804Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M261.14 430.025L261.188 430.076L261.232 430.13C262.431 431.601 263.286 432.889 263.695 434.045C264.139 435.296 264.119 436.616 263.227 437.717C262.491 438.625 261.358 439.102 260.412 439.425C259.659 439.682 258.759 439.921 257.84 440.165C257.573 440.235 257.305 440.307 257.038 440.379C254.353 441.339 251.557 441.304 249.342 440.808C248.222 440.557 247.19 440.175 246.356 439.694C245.596 439.256 244.704 438.565 244.335 437.553C243.932 436.45 244.263 435.416 244.738 434.663C245.201 433.93 245.897 433.289 246.648 432.737C248.166 431.622 250.328 430.571 252.742 429.69C255.061 428.844 256.721 428.4 258.024 428.461C258.749 428.495 259.378 428.682 259.941 429.018C260.461 429.328 260.855 429.727 261.14 430.025Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M262.525 444.489C255.99 446.323 250.246 446.432 245.987 445.737C243.865 445.391 242.062 444.837 240.698 444.16C240.017 443.823 239.402 443.432 238.905 442.987C238.425 442.556 237.943 441.972 237.734 441.226C237.485 440.34 237.708 439.51 238.163 438.863C238.57 438.285 239.15 437.864 239.699 437.546C240.805 436.905 242.381 436.357 244.195 435.843C247.031 435.038 250.852 434.211 255.253 433.258C256.519 432.983 257.833 432.699 259.186 432.401C265.538 430.622 271.469 429.484 275.944 429.098C278.173 428.906 280.132 428.891 281.632 429.115C282.376 429.226 283.113 429.41 283.738 429.73C284.36 430.05 285.077 430.618 285.345 431.573C285.613 432.528 285.296 433.386 284.931 433.983C284.564 434.582 284.03 435.122 283.453 435.604C282.289 436.575 280.608 437.582 278.605 438.578C274.571 440.582 268.896 442.702 262.525 444.489Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M239.195 439.867C239.186 442.971 249.552 444.691 262.146 441.156C274.19 439.166 282.971 434.08 283.822 432.122C283.036 429.811 272.009 431.285 259.836 434.906C247.589 437.666 240.036 438.517 239.195 439.867Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M239.478 440.856C238.95 439.305 238.855 436.83 259.551 434.32C272.577 429.583 282.488 429.524 283.739 431.857C280.914 432.466 263.222 438.155 256.194 439.841C244.151 441.831 240.843 440.163 239.478 440.856Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M266.378 433.268L266.451 433.33L266.517 433.4C267.941 434.912 269.009 436.374 269.283 437.81C269.436 438.609 269.349 439.423 268.94 440.168C268.551 440.876 267.954 441.369 267.356 441.721C266.208 442.397 264.612 442.805 262.928 443.135C261.627 443.391 260.111 443.626 258.474 443.881C257.95 443.962 257.415 444.046 256.87 444.132C252.368 445.064 247.987 445.053 244.646 444.575C242.977 444.335 241.503 443.971 240.375 443.511C239.816 443.282 239.276 443.004 238.829 442.658C238.418 442.34 237.862 441.792 237.691 440.97C237.518 440.138 237.803 439.423 238.14 438.93C238.465 438.455 238.908 438.073 239.339 437.767C240.208 437.15 241.413 436.578 242.785 436.048C245.56 434.975 249.433 433.92 253.781 433.014C258.068 432.121 260.679 431.742 262.433 431.789C263.349 431.814 264.116 431.954 264.806 432.247C265.492 432.539 265.988 432.935 266.378 433.268Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M255.404 435.937L255.445 435.994L255.481 436.054C256.455 437.682 257.116 439.08 257.355 440.283C257.613 441.585 257.404 442.888 256.363 443.849C255.504 444.642 254.314 444.951 253.332 445.135C252.55 445.281 251.624 445.388 250.68 445.497C250.406 445.528 250.13 445.56 249.856 445.593C247.06 446.157 244.299 445.72 242.178 444.91C241.105 444.501 240.139 443.975 239.383 443.379C238.694 442.836 237.91 442.024 237.691 440.969C237.451 439.819 237.927 438.844 238.506 438.167C239.07 437.508 239.851 436.974 240.673 436.536C242.336 435.651 244.626 434.922 247.142 434.398C249.558 433.894 251.265 433.693 252.546 433.941C253.259 434.079 253.854 434.354 254.363 434.768C254.833 435.15 255.165 435.601 255.404 435.937Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <rect
                    x="32.088"
                    y="64.8931"
                    width="381.165"
                    height="253.578"
                    rx="24.6624"
                    transform="rotate(-7.2586 32.088 64.8931)"
                    fill="#00A806"
                    stroke="black"
                    stroke-width="5.48053"
                />
                <rect
                    x="11.7667"
                    y="68.0508"
                    width="381.165"
                    height="253.696"
                    rx="30.1429"
                    transform="rotate(-7.2586 11.7667 68.0508)"
                    fill="url(#paint2_linear_5819_12658)"
                    stroke="black"
                    stroke-width="5.48053"
                />
                <rect
                    x="24.3131"
                    y="75.6318"
                    width="356.064"
                    height="233.709"
                    rx="24.6624"
                    transform="rotate(-7.2586 24.3131 75.6318)"
                    fill="url(#paint3_linear_5819_12658)"
                    stroke="black"
                    stroke-width="5.48053"
                />
                <mask id="path-106-inside-2_5819_12658" fill="white">
                    <path d="M29.9226 99.6593C28.3929 87.6491 36.889 76.6728 48.8993 75.143L353.749 36.3147C365.759 34.785 376.735 43.2811 378.265 55.2914L401.634 238.766C403.164 250.776 394.668 261.753 382.657 263.282L77.8079 302.111C65.7977 303.64 54.8213 295.144 53.2916 283.134L29.9226 99.6593Z" />
                </mask>
                <path
                    d="M27.1528 77.9128L375.495 33.5449L27.1528 77.9128ZM405.035 239.438C406.794 253.25 397.023 265.873 383.211 267.632L82.7111 305.906C68.2988 307.742 55.1273 297.546 53.2916 283.134C54.5154 292.742 65.2436 299.291 77.2539 297.761L382.104 258.933C392.312 257.633 399.596 248.79 398.372 239.182L405.035 239.438ZM56.0614 304.881L27.1528 77.9128L56.0614 304.881ZM353.749 36.3147C367.561 34.5556 380.183 44.3261 381.943 58.1378L405.035 239.438C406.794 253.25 397.023 265.873 383.211 267.632L382.104 258.933C392.312 257.633 399.596 248.79 398.372 239.182L375.003 55.7068C373.473 43.6966 363.958 35.0145 353.749 36.3147Z"
                    fill="#0092AD"
                    mask="url(#path-106-inside-2_5819_12658)"
                />
                <path
                    d="M230.972 322.7C236.446 319.228 235.705 311.022 229.698 308.587L191.892 293.261C185.781 290.784 179.478 296.378 181.211 302.74L186.586 322.473C186.701 322.896 186.751 323.333 186.734 323.77L186.24 336.271C185.986 342.691 193.044 346.755 198.469 343.314L230.972 322.7Z"
                    stroke="black"
                    stroke-width="3.6775"
                />
                <path
                    d="M226.967 322.68C231.941 319.527 231.376 312.097 225.982 309.732L193.415 295.455C187.507 292.864 181.364 298.594 183.537 304.668L188.375 318.195C188.558 318.705 188.654 319.241 188.662 319.782L188.774 328.227L188.547 334.415C187.884 339.871 193.868 343.665 198.525 340.712L226.967 322.68Z"
                    fill="#A2DC05"
                    stroke="#02AA09"
                    stroke-width="2.45167"
                />
                <path
                    d="M275.759 306.912L278.528 308.468L276.073 316.38C275.93 316.84 275.877 317.324 275.915 317.804L276.591 326.273C277.006 331.477 271.81 335.306 266.963 333.368L255.481 328.777C254.915 328.55 254.381 328.251 253.892 327.887L242.616 319.495C242.098 319.109 241.636 318.654 241.242 318.142L238.485 314.561C235.681 310.917 237.066 305.607 241.294 303.798L247.552 301.12L261.482 294.242L270.543 289.265C276.569 285.955 283.278 292.363 280.248 298.535L278.705 301.68C278.529 302.037 278.324 302.378 278.092 302.7L273.578 308.949L273.511 309.459L275.759 306.912ZM273.48 309.084C273.48 309.084 273.48 309.083 273.481 309.083L273.48 309.084Z"
                    fill="url(#paint4_linear_5819_12658)"
                    stroke="#0482B2"
                    stroke-width="3.24811"
                />
                <path
                    d="M255.152 330.893L255.729 329.375L255.152 330.893C254.651 330.702 254.173 330.455 253.728 330.156L235.796 318.113C231.35 315.127 231.736 308.466 236.498 306.014L272.149 287.659C277.194 285.061 283.081 289.196 282.349 294.824L280.822 306.557C280.731 307.257 280.536 307.939 280.242 308.58L277.641 314.257C277.366 314.857 277.253 315.517 277.312 316.174L278.362 327.905C278.763 332.39 274.909 336.093 270.444 335.512L266.493 334.998C265.949 334.927 265.415 334.793 264.902 334.598L255.152 330.893Z"
                    stroke="black"
                    stroke-width="3.24811"
                />
                <path d="M251.735 326.3L242.935 321.486L241.922 315.083L251.735 326.3Z" fill="#0086B8" />
                <path d="M210.982 329.305L225.276 314.069L228.428 317.811L210.982 329.305Z" fill="#159C00" />
                <path d="M214.44 324.737L224.643 314.937L225.832 316.35L214.44 324.737Z" fill="black" />
                <path
                    d="M255.858 320.752L249.078 315.681L241.77 313.801L240.918 315.897L246.428 316.223L255.858 320.752Z"
                    fill="#0086B8"
                />
                <path
                    d="M254.66 319.576L248.483 315.176L241.932 313.402L241.237 315.113L246.144 315.546L254.66 319.576Z"
                    fill="black"
                />
                <path
                    d="M224.173 313.669L225.443 315.121L225.771 313.22C225.897 312.489 225.668 311.836 225.133 311.329C224.661 310.881 223.977 310.571 223.204 310.316C221.643 309.803 219.212 309.369 215.909 308.799C212.762 308.256 209.33 306.828 206.522 305.661C206.465 305.637 206.408 305.613 206.351 305.589C204.964 305.013 203.699 304.491 202.76 304.227C202.307 304.099 201.816 303.997 201.386 304.035C201.162 304.055 200.875 304.12 200.617 304.312C200.334 304.522 200.174 304.819 200.12 305.131C200.03 305.654 200.224 306.137 200.436 306.496C200.659 306.874 200.981 307.251 201.355 307.613C202.107 308.343 203.18 309.123 204.442 309.868C206.969 311.358 210.391 312.783 213.851 313.38L213.948 313.397L214.047 313.393C216.644 313.28 218.515 313.13 219.888 313.001C220.41 312.952 220.849 312.908 221.224 312.87C221.859 312.805 222.31 312.759 222.669 312.743C223.214 312.717 223.312 312.779 223.364 312.812L223.364 312.812C223.416 312.845 223.493 312.907 223.631 313.055C223.734 313.164 223.843 313.29 223.985 313.453C224.042 313.518 224.104 313.59 224.173 313.669Z"
                    fill="#1F920C"
                    stroke="#07A506"
                    stroke-width="1.83875"
                />
                <path
                    d="M223.98 314.423L225.26 316.181L225.63 314.039C225.759 313.29 225.596 312.588 225.078 312.004C224.611 311.478 223.915 311.12 223.129 310.837C221.554 310.268 219.095 309.828 215.781 309.255C212.638 308.713 209.229 307.123 206.424 305.815C206.37 305.79 206.316 305.765 206.262 305.74C204.883 305.097 203.612 304.508 202.66 304.22C202.202 304.08 201.684 303.966 201.218 304.031C200.966 304.066 200.674 304.16 200.423 304.384C200.164 304.615 200.03 304.913 199.98 305.204C199.891 305.718 200.035 306.218 200.235 306.638C200.441 307.068 200.748 307.502 201.116 307.926C201.851 308.774 202.912 309.676 204.168 310.53C206.68 312.236 210.109 313.839 213.612 314.443L213.728 314.464L213.846 314.454C216.477 314.232 218.375 313.99 219.77 313.789C220.304 313.712 220.751 313.644 221.132 313.586C221.771 313.488 222.223 313.419 222.582 313.387C223.096 313.342 223.17 313.405 223.192 313.423C223.193 313.424 223.194 313.424 223.195 313.425C223.24 313.458 223.314 313.528 223.452 313.704C223.553 313.832 223.66 313.98 223.798 314.171C223.853 314.248 223.913 314.331 223.98 314.423Z"
                    fill="#1F920C"
                    stroke="black"
                    stroke-width="1.83875"
                />
                <path
                    d="M237.181 310.076C237.2 310.069 237.268 310.041 237.341 309.961C237.434 309.857 237.468 309.742 237.476 309.656L236.793 310.015L236.075 310.393C235.976 310.206 235.881 309.976 235.859 309.724C235.835 309.444 235.903 309.13 236.135 308.873C236.342 308.643 236.605 308.545 236.797 308.498C236.988 308.451 237.191 308.435 237.382 308.432C238.035 308.174 238.941 307.912 239.938 307.623L239.949 307.62L239.968 307.615C242.172 306.977 244.998 306.16 247.497 304.845C250.085 303.482 252.667 302.164 254.682 301.324C255.682 300.907 256.589 300.588 257.308 300.451C257.663 300.384 258.033 300.348 258.37 300.401C258.716 300.455 259.143 300.628 259.379 301.075C259.703 301.691 259.531 302.428 259.288 303.009C259.021 303.645 258.561 304.355 257.938 305.087C256.689 306.556 254.709 308.202 252.038 309.609L251.972 309.643L251.901 309.665C247.494 311.041 243.336 310.731 240.497 310.391C240.122 310.346 239.727 310.295 239.368 310.249C239.093 310.213 238.84 310.18 238.633 310.155C238.254 310.109 237.945 310.077 237.699 310.064C237.645 310.089 237.598 310.114 237.56 310.136C237.482 310.18 237.455 310.204 237.456 310.204C237.457 310.203 237.472 310.188 237.491 310.159C237.506 310.136 237.525 310.102 237.54 310.057C237.498 310.056 237.459 310.056 237.422 310.056C237.283 310.057 237.211 310.068 237.184 310.075C237.183 310.075 237.182 310.076 237.181 310.076ZM237.181 310.076C237.178 310.077 237.176 310.077 237.176 310.077C237.176 310.077 237.178 310.077 237.181 310.076Z"
                    fill="#0085B7"
                    stroke="black"
                    stroke-width="1.62405"
                />
                <path
                    d="M226.35 308.276C226.44 307.266 227.162 306.425 228.147 306.184L232.327 305.162C232.449 305.132 232.573 305.112 232.698 305.102L237.198 304.737C238.348 304.644 239.398 305.393 239.684 306.511L241.795 314.769C241.836 314.93 241.86 315.095 241.867 315.261L242.126 321.71C242.169 322.776 241.494 323.739 240.478 324.062L236.247 325.408C235.948 325.504 235.633 325.538 235.321 325.511L230.678 325.1C229.872 325.029 229.157 324.551 228.784 323.832L225.848 318.171C225.64 317.771 225.551 317.32 225.591 316.872L226.35 308.276Z"
                    fill="url(#paint5_linear_5819_12658)"
                    stroke="#35A716"
                    stroke-width="2.36846"
                />
                <path
                    d="M224.55 308.448C224.415 307.193 225.288 306.053 226.535 305.856L231.928 305.003L237.185 304.553C238.333 304.454 239.386 305.197 239.678 306.312L241.837 314.543C241.882 314.714 241.908 314.89 241.913 315.067L242.128 321.688C242.161 322.695 241.553 323.612 240.613 323.975L235.744 325.851L236.17 326.956L235.744 325.851C235.442 325.968 235.12 326.021 234.796 326.008L229.221 325.782C228.145 325.738 227.234 324.974 227.004 323.922L225.447 316.813L224.55 308.448Z"
                    stroke="black"
                    stroke-width="2.36846"
                />
                <path
                    d="M185.58 308.216L185.102 305.189L193.261 317.55L195.08 329.053L191.989 325.198L189.026 330.01L189.311 320.036L185.58 308.216Z"
                    fill="url(#paint6_linear_5819_12658)"
                />
                <path
                    d="M227.18 316.838L237.95 305.809L238.725 306.538L240.01 310.831L240.702 315.207L241.165 321.939L240.605 322.622L227.18 316.838Z"
                    fill="#8CE2D5"
                />
                <path
                    d="M227.152 316.868L238.871 307.703L239.105 308.658L240.184 311.798L240.67 315.028L240.819 320.023L240.998 321.155L227.152 316.868Z"
                    fill="url(#paint7_linear_5819_12658)"
                />
                <path
                    d="M235.569 315.096L241.035 321.149L227.151 316.75L235.569 315.096Z"
                    fill="url(#paint8_linear_5819_12658)"
                />
                <path
                    d="M235.925 315.363L238.936 307.694L239.218 308.75L239.956 310.53L240.789 315.793L241.243 320.71L241.071 321.194L235.925 315.363Z"
                    fill="url(#paint9_linear_5819_12658)"
                />
                <path
                    d="M274.255 303.277L274.258 303.27L274.262 303.263L276.208 298.511C276.322 298.233 276.392 297.939 276.418 297.639L276.65 294.894C276.71 294.188 276.515 293.484 276.1 292.909L275.567 292.172C275.12 291.554 275.161 290.708 275.666 290.136C275.854 289.924 276.176 289.901 276.392 290.084L277.001 290.601C277.284 290.841 277.485 291.164 277.575 291.524L278.335 294.555C278.379 294.731 278.396 294.913 278.385 295.094L278.313 296.296C278.298 296.547 278.23 296.792 278.113 297.015L276.641 299.818L276.624 299.85L276.611 299.885L275.424 303.035L272.241 309.531C272.086 309.846 271.989 310.187 271.954 310.537L271.871 311.359L271.876 309.031C271.877 308.785 271.929 308.542 272.028 308.317L274.255 303.277Z"
                    fill="black"
                    stroke="black"
                    stroke-width="1.18423"
                />
                <path
                    d="M96.4894 414.75L96.4696 414.744L96.4498 414.737L92.5264 413.385C92.3381 413.32 92.1553 413.236 91.9809 413.134L89.3024 411.567C89.1234 411.462 88.9541 411.339 88.7974 411.2L89.8796 409.857L88.7973 411.2L84.2169 407.127C84.0312 406.962 83.8642 406.775 83.7198 406.571L85.1014 405.561L83.7198 406.571L80.8886 402.561L79.9539 401.238L81.0439 400.188L100.551 381.408L101.648 382.627L100.551 381.408C101.366 380.624 102.579 380.442 103.648 380.943L126.149 391.492L126.178 391.506L126.208 391.521L157.913 407.789L176.682 414.943C176.973 415.054 177.249 415.211 177.498 415.408L180.259 417.586L180.337 417.648L180.409 417.718L183.203 420.435C184.2 421.405 184.48 422.914 183.883 424.102L175.876 440.039C175.24 441.304 173.792 441.852 172.442 441.34L133.344 426.498L96.4894 414.75Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M182.446 421.998L175.453 436.328C175.194 436.858 174.583 437.078 174.028 436.842L134.922 420.158L96.9604 406.228L88.351 399.061L83.5214 400.325L103.88 383.041L125.626 392.683L157.606 408.758L167.125 412.387L176.644 416.015L182.086 420.47C182.543 420.844 182.695 421.489 182.446 421.998Z"
                    fill="#8CEC40"
                />
                <path
                    d="M175.052 438.757L174.374 439.845C174.095 440.293 173.548 440.469 173.043 440.273L133.811 425.102L96.4226 413.394L90.8475 410.935L83.7426 404.839L101.665 403.161L114.828 410.274L145.321 422.088L167.049 432.605L170.663 434.72L174.585 437.014C175.196 437.371 175.412 438.178 175.052 438.757Z"
                    fill="#02A608"
                />
                <path
                    d="M112.365 394.174L112.321 391.698C112.306 390.86 113.098 390.315 113.861 390.638L124.847 395.286L156.928 411.039L172.956 416.856C173.933 417.211 174.104 418.582 173.232 419.066L171.311 420.131L169.91 421.658L169.232 423.6L168.709 426.098L169.065 429.567L171.536 436.894L133.237 421.097L98.3185 406.741L101.152 406.383L104.988 404.857L106.96 403.193L109.347 400.834L111.427 397.365L112.365 394.174Z"
                    fill="#63D116"
                />
                <circle
                    cx="90.8045"
                    cy="392.266"
                    r="15.138"
                    transform="rotate(75.3283 90.8045 392.266)"
                    fill="url(#paint10_linear_5819_12658)"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M88.1227 400.832C94.7467 403.241 102.34 400.995 104.574 394.855C106.807 388.714 102.432 382.114 95.808 379.704C89.184 377.295 81.5906 379.541 79.357 385.682C77.1233 391.822 81.4987 398.422 88.1227 400.832Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <circle
                    cx="181.975"
                    cy="430.807"
                    r="10.6042"
                    transform="rotate(-138.108 181.975 430.807)"
                    fill="url(#paint11_linear_5819_12658)"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M183.517 427.483C181.366 426.332 179.03 425.854 176.948 426.091C174.873 426.328 172.868 427.316 171.822 429.271C170.776 431.225 171.066 433.441 172.02 435.299C172.977 437.164 174.671 438.842 176.821 439.993C178.971 441.144 181.308 441.622 183.39 441.384C185.465 441.148 187.469 440.16 188.516 438.205C189.562 436.25 189.272 434.034 188.318 432.177C187.361 430.312 185.667 428.633 183.517 427.483Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M384.542 411.635L384.539 411.655L384.535 411.676L383.864 415.771C383.831 415.968 383.779 416.162 383.708 416.351L382.615 419.255C382.542 419.449 382.449 419.637 382.338 419.815L380.832 418.975L382.338 419.815L379.096 425.016C378.965 425.227 378.809 425.423 378.631 425.6L377.403 424.408L378.631 425.6L375.156 429.067L374.009 430.211L372.791 429.313L350.992 413.25L352.008 411.964L350.992 413.25C350.082 412.58 349.698 411.414 350.011 410.276L356.618 386.319L356.626 386.288L356.636 386.256L367.327 352.263L371.216 332.556C371.276 332.251 371.384 331.953 371.536 331.674L373.219 328.585L373.266 328.498L373.323 328.415L375.531 325.204C376.319 324.057 377.759 323.527 379.03 323.915L396.089 329.122C397.443 329.535 398.228 330.87 397.95 332.287L389.909 373.327L384.542 411.635Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M377.199 325.686L392.502 330.164C393.068 330.329 393.389 330.895 393.249 331.481L383.395 372.84L376.062 412.606L370.448 422.301L372.509 426.848L352.041 409.694L357.88 386.634L368.335 352.402L370.308 342.408L372.28 332.413L375.753 326.298C376.045 325.785 376.655 325.527 377.199 325.686Z"
                    fill="#8CEC40"
                />
                <path
                    d="M394.964 330.15L396.151 330.635C396.639 330.834 396.905 331.344 396.797 331.874L388.455 373.102L383.216 411.929L381.732 417.839L376.92 425.869L372.245 408.486L377.038 394.313L383.545 362.264L390.249 339.075L391.724 335.156L393.325 330.904C393.574 330.241 394.333 329.893 394.964 330.15Z"
                    fill="#02A608"
                />
                <path
                    d="M361.584 399.454L359.151 399.914C358.328 400.07 357.657 399.381 357.847 398.575L360.577 386.963L370.698 352.686L373.73 335.907C373.915 334.884 375.237 334.485 375.861 335.263L377.236 336.976L378.976 338.1L381.005 338.441L383.555 338.535L386.915 337.6L393.72 333.93L384.605 374.343L376.339 411.182L375.509 408.449L373.358 404.925L371.385 403.262L368.658 401.306L364.888 399.841L361.584 399.454Z"
                    fill="#63D116"
                />
                <circle
                    cx="361.714"
                    cy="421.645"
                    r="15.138"
                    transform="rotate(44.7154 361.714 421.645)"
                    fill="url(#paint12_linear_5819_12658)"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M358.149 428.2C364.193 431.828 372.075 431.07 375.438 425.468C378.801 419.866 375.765 412.553 369.721 408.925C363.678 405.297 355.796 406.055 352.433 411.657C349.069 417.259 352.106 424.572 358.149 428.2Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <circle
                    cx="384.779"
                    cy="324.627"
                    r="10.6042"
                    transform="rotate(122.189 384.779 324.627)"
                    fill="url(#paint13_linear_5819_12658)"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M381.243 323.668C380.471 325.981 380.393 328.364 380.978 330.377C381.561 332.382 382.873 334.192 384.976 334.893C387.079 335.595 389.215 334.936 390.885 333.683C392.561 332.425 393.93 330.472 394.702 328.159C395.474 325.845 395.552 323.462 394.967 321.449C394.384 319.445 393.072 317.635 390.969 316.933C388.866 316.231 386.73 316.891 385.06 318.144C383.384 319.402 382.015 321.354 381.243 323.668Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    opacity="0.75"
                    d="M193.559 346.733L193.512 346.715L193.465 346.694C193.367 346.65 193.263 346.604 193.152 346.555C186.183 343.466 154.9 329.601 154.9 287.932V287.902L154.901 287.872L155.144 280.693L155.202 278.979L156.917 278.976L247.391 278.857L249.132 278.855L249.17 280.595L249.332 288.147L249.332 288.166V288.186C249.332 329.237 217.2 343.761 210.897 346.571C210.6 346.707 210.483 346.83 210.429 346.908C210.382 346.977 210.295 347.139 210.295 347.529C210.295 347.986 210.384 348.132 210.396 348.151C210.403 348.161 210.413 348.175 210.442 348.196C210.474 348.219 210.541 348.261 210.665 348.307L210.717 348.326L210.767 348.349C210.865 348.392 210.969 348.438 211.08 348.487C218.049 351.576 249.332 365.442 249.332 407.11V407.141L249.331 407.171C249.242 409.779 248.38 412.303 246.853 414.353C245.324 416.405 243.202 417.88 240.792 418.511L240.79 418.511C233.796 420.332 221.022 422.397 199.104 422.397C180.239 422.397 169.55 420.888 163.497 419.316L163.494 419.315C158.474 418.003 155.036 413.205 154.927 407.801L154.927 407.792L154.914 406.883L154.914 406.871L154.914 406.858C154.9 365.81 187.013 351.285 193.334 348.472C193.499 348.396 193.562 348.318 193.604 348.239C193.661 348.133 193.734 347.915 193.734 347.514C193.734 347.049 193.665 346.844 193.631 346.777C193.628 346.77 193.625 346.766 193.624 346.763C193.616 346.758 193.596 346.747 193.559 346.733Z"
                    fill="#81D4FA"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M195.187 341.347C195.187 341.347 198.895 343.134 198.895 352.016V364.329C198.895 366.953 201.787 368.549 204.007 367.15C205.393 366.277 205.914 364.53 205.483 362.95C202.875 353.367 206.771 342.285 208.843 341.347C218.393 337.087 232.644 328.165 239.722 311.079C239.614 310.335 220.581 315.742 201.482 311.631C191.148 309.411 161.107 294.009 161.188 295.752C161.999 313.254 176.06 332.857 195.187 341.347Z"
                    fill="#F6E1AC"
                    stroke="black"
                    stroke-width="2.96057"
                />
                <path
                    d="M167.277 304.764L163.252 300.502L164.221 305.831L165.405 308.792L167.277 312.645L169.55 316.489L172.511 321.119L176.063 325.371L179.024 328.442L181.392 330.7L186.129 334.582L189.682 337.034L193.235 338.989L196.787 340.766L198.564 343.134L199.748 350.24L200.34 353.792L200.751 355.642C200.951 356.54 202.22 356.57 202.462 355.683C202.483 355.607 202.494 355.529 202.494 355.451L202.494 346.687L202.443 343.759C202.423 342.58 202.05 341.434 201.374 340.467L200.571 339.32C200.418 339.1 200.245 338.894 200.055 338.704C199.851 338.5 199.628 338.316 199.389 338.153L198.112 337.284C197.867 337.118 197.611 336.97 197.345 336.842L195.172 335.798C194.672 335.558 194.209 335.25 193.796 334.881L191.353 332.704L187.742 329.854C187.457 329.629 187.193 329.378 186.954 329.105L183.169 324.779L179.839 321.119L176.406 316.873L173.566 312.645L170.656 307.996C170.332 307.479 169.931 307.016 169.467 306.622L167.277 304.764Z"
                    fill="#D8B27C"
                />
                <path
                    d="M180.208 318.858C180.208 319.839 178.82 318.858 177.839 318.858C176.858 318.858 176.063 318.062 176.063 317.081C176.063 316.1 176.858 315.305 177.839 315.305C178.82 315.305 180.208 317.877 180.208 318.858Z"
                    fill="black"
                />
                <path
                    d="M187.707 326.606C187.142 327.003 187.137 325.796 186.733 325.223C186.33 324.649 186.461 323.863 187.026 323.466C187.591 323.069 188.375 323.211 188.778 323.784C189.181 324.358 188.271 326.209 187.707 326.606Z"
                    fill="black"
                />
                <path
                    d="M199.333 335.744C198.769 336.141 198.763 334.933 198.36 334.36C197.957 333.787 198.088 333 198.652 332.603C199.217 332.206 200.002 332.349 200.405 332.922C200.808 333.495 199.898 335.347 199.333 335.744Z"
                    fill="black"
                />
                <path
                    d="M213.836 332.459C212.908 332.78 213.381 331.148 213.06 330.221C212.738 329.294 213.23 328.282 214.157 327.961C215.085 327.639 216.097 328.13 216.418 329.057C216.74 329.984 214.763 332.137 213.836 332.459Z"
                    fill="black"
                />
                <path
                    d="M221.683 326.93C221.306 327.061 221.488 326.369 221.35 325.971C221.213 325.574 221.407 325.146 221.784 325.015C222.161 324.885 222.578 325.101 222.716 325.498C222.853 325.895 222.06 326.8 221.683 326.93Z"
                    fill="black"
                />
                <path
                    d="M257.211 414.473L259.084 414.654V416.535V424.23C259.084 425.13 258.654 425.815 258.24 426.256C257.835 426.688 257.336 427.012 256.865 427.261C255.918 427.762 254.654 428.183 253.21 428.553C250.29 429.301 246.155 429.954 241.153 430.495C231.124 431.581 217.322 432.248 202.116 432.248C186.911 432.248 173.109 431.581 163.079 430.495C158.077 429.954 153.943 429.301 151.022 428.553C149.578 428.183 148.314 427.762 147.367 427.261C146.897 427.012 146.398 426.688 145.993 426.256C145.579 425.815 145.149 425.13 145.149 424.23V416.535V414.463H147.221C150.583 414.463 158.095 414.409 167.711 414.34C178.116 414.265 190.985 414.172 203.722 414.111C228.116 413.994 252.225 413.99 257.211 414.473Z"
                    fill="#8B5738"
                    stroke="black"
                    stroke-width="4.1448"
                />
                <path
                    d="M247.22 423.669L249.905 416.535C241.851 416.535 161.229 416.535 147.221 416.535V424.23C147.221 427.516 174.49 426.389 204.801 426.389C235.112 426.389 243.998 426.994 247.22 423.669Z"
                    fill="#AB7E5F"
                />
                <path
                    d="M175.293 423.606L175.293 416.284C173.061 416.284 151.23 416.672 147.348 416.672V424.057C146.274 427.944 184.446 426.778 175.293 426.778C175.293 426.778 175.293 426.546 175.293 423.606Z"
                    fill="#825130"
                />
                <path d="M177.954 426.778H184.397V416.284H177.954V426.778Z" fill="#825130" />
                <path
                    d="M202.116 383.99C202.71 383.99 203.157 383.685 203.438 383.387C203.717 383.092 203.913 382.729 204.053 382.379C204.336 381.671 204.485 380.775 204.485 379.846C204.485 378.916 204.336 378.02 204.053 377.312C203.913 376.962 203.717 376.599 203.438 376.304C203.157 376.007 202.71 375.701 202.116 375.701C201.522 375.701 201.076 376.007 200.795 376.304C200.516 376.599 200.32 376.962 200.179 377.312C199.896 378.02 199.748 378.916 199.748 379.846C199.748 380.775 199.896 381.671 200.179 382.379C200.32 382.729 200.516 383.092 200.795 383.387C201.076 383.685 201.522 383.99 202.116 383.99Z"
                    fill="#F6E1AC"
                    stroke="black"
                    stroke-width="2.36846"
                />
                <path
                    d="M231.333 317.023C224.093 321.149 208.208 327.032 200.374 324.053C192.539 321.074 162.242 300.206 161.202 295.752C159.905 290.241 196.322 304.257 209.14 307.534C217.826 309.754 234.413 308.607 238.141 309.471C241.856 310.32 237.993 313.224 231.333 317.023Z"
                    fill="#D8B27C"
                    stroke="black"
                    stroke-width="2.96057"
                />
                <path
                    opacity="0.6"
                    d="M226.714 308.637C225.012 313.016 221.905 322.951 225.957 324.65C230.01 326.363 235.94 320.017 238.749 313.642C241.559 307.267 241.302 298.806 237.061 298.672C232.819 298.538 228.97 302.843 226.714 308.637ZM230.618 372.598C234.845 378.05 238.02 385.378 236.818 392.453C235.548 399.916 229.253 397.86 227.065 395.969C220.095 389.936 222 382.205 216.61 373.521C215.692 372.032 211.855 367.37 212.396 364.435C212.72 362.692 216.394 361.799 223.08 365.493C226.025 367.131 228.443 369.812 230.618 372.598Z"
                    fill="white"
                />
                <path
                    d="M257.25 286.326L259.084 286.114V284.268V275.054C259.084 273.42 257.845 272.401 257.005 271.868C256.044 271.259 254.76 270.747 253.304 270.301C250.36 269.398 246.206 268.614 241.197 267.965C231.149 266.662 217.332 265.863 202.116 265.863C186.9 265.863 173.084 266.662 163.036 267.965C158.027 268.614 153.872 269.398 150.929 270.301C149.472 270.747 148.189 271.259 147.227 271.868C146.387 272.401 145.149 273.42 145.149 275.054V284.268V286.34H147.221C150.583 286.34 158.092 286.405 167.705 286.488C178.11 286.577 190.979 286.688 203.72 286.761C215.964 286.832 228.081 286.867 237.746 286.814C247.337 286.762 254.682 286.624 257.25 286.326Z"
                    fill="#8B5738"
                    stroke="black"
                    stroke-width="4.1448"
                />
                <path
                    d="M247.22 275.727L249.905 284.268C241.851 284.268 161.229 284.268 147.221 284.268V275.054C147.221 271.12 174.49 272.469 204.801 272.469C235.112 272.469 243.998 271.745 247.22 275.727Z"
                    fill="#AB7E5F"
                />
                <path
                    d="M175.293 275.801L175.293 284.569C173.061 284.569 151.23 284.103 147.348 284.103V275.261C146.274 270.607 184.446 272.003 175.293 272.003C175.293 272.003 175.293 272.282 175.293 275.801Z"
                    fill="#825130"
                />
                <path d="M177.954 272.003H184.397V284.569H177.954V272.003Z" fill="#825130" />
                <path
                    d="M201.702 315.612C201.137 316.009 201.132 314.801 200.729 314.228C200.325 313.655 200.456 312.868 201.021 312.471C201.586 312.074 202.37 312.217 202.773 312.79C203.176 313.363 202.266 315.215 201.702 315.612Z"
                    fill="black"
                />
                <path
                    d="M181.207 143.15C182.498 142.97 183.696 143.871 183.815 145.169C184.306 150.548 183.921 155.968 182.67 161.192C181.237 167.176 178.697 172.786 175.194 177.701C171.691 182.616 167.294 186.74 162.255 189.838C157.215 192.936 151.631 194.947 145.822 195.756C140.013 196.565 134.092 196.157 128.397 194.554C122.703 192.951 117.346 190.185 112.634 186.414C107.921 182.643 103.944 177.941 100.931 172.576C98.3003 167.893 96.4487 162.785 95.4516 157.477C95.2109 156.195 96.1175 155.001 97.4088 154.821L139.308 148.986L181.207 143.15Z"
                    fill="#074955"
                />
                <path
                    d="M248.505 167.406C245.928 167.366 243.47 165.705 243.184 163.143C242.951 161.065 243.368 159.514 244.028 156.018C245.044 150.641 247.36 145.468 250.822 140.844C254.284 136.22 258.812 132.253 264.102 129.208C269.393 126.163 275.324 124.11 281.498 123.186C287.672 122.263 293.947 122.491 299.903 123.855C305.859 125.219 311.358 127.687 316.031 131.094C320.705 134.502 324.446 138.77 327.004 143.612C328.399 146.252 329.185 147.917 329.609 149.762C330.31 152.817 327.496 155.388 324.363 155.34C321.284 155.293 318.403 152.884 316.835 150.235C316.665 149.947 316.481 149.62 316.284 149.246C314.721 146.286 312.737 142.927 309.881 140.844C307.024 138.762 300.618 135.739 296.978 134.905C293.338 134.071 287.953 135.271 284.179 135.835C280.405 136.399 274.346 138.034 271.113 139.895C267.879 141.756 263.228 145.574 261.112 148.4C258.996 151.226 257.245 155.32 256.624 158.606C256.568 158.904 256.515 159.187 256.465 159.456C255.729 163.451 252.568 167.468 248.505 167.406Z"
                    fill="#094C57"
                />
                <path
                    d="M155.095 110.322C156.105 109.18 156.209 107.475 155.194 106.338C152.637 103.476 149.431 101.262 145.746 99.8288C141.232 98.0729 136.13 97.5406 130.867 98.2764C125.604 99.0121 120.332 100.994 115.49 104.058C111.797 106.395 108.436 109.31 105.564 112.652C104.118 114.335 104.655 116.866 106.556 118.009C108.264 119.036 110.458 118.557 111.778 117.064C113.845 114.726 116.237 112.682 118.852 111.027C122.455 108.747 126.378 107.272 130.293 106.725C134.209 106.177 138.953 105.418 142.312 106.724C144.497 107.574 147.23 108.926 149.438 110.558C151.127 111.805 153.704 111.894 155.095 110.322Z"
                    fill="#094C57"
                />
                <path
                    d="M255.679 95.7409C252.748 95.7409 250.882 92.785 252.462 90.3164C254.864 86.5622 258.035 82.5961 260.673 80.8429C264.707 78.1614 269.576 76.5496 274.874 76.1418C280.173 75.734 285.747 76.542 291.133 78.4985C295.24 79.9909 299.148 82.1183 302.668 84.7683C304.44 86.1028 304.458 88.6899 302.846 90.2139C301.397 91.5826 299.152 91.5841 297.543 90.4083C295.023 88.5672 292.249 87.0819 289.34 86.0249C285.333 84.5692 281.849 82.8925 277.906 83.1959C273.964 83.4993 269.066 84.0298 266.064 86.0249C263.752 87.562 261.261 90.5723 259.602 93.3567C258.767 94.7576 257.31 95.7409 255.679 95.7409Z"
                    fill="#094C57"
                />
                <path
                    d="M200.965 214.452C200.274 214.545 199.787 215.181 199.909 215.868C200.452 218.919 201.461 221.861 202.892 224.564C204.518 227.633 206.658 230.331 209.189 232.506C211.72 234.681 214.594 236.289 217.645 237.238C220.697 238.188 223.867 238.46 226.974 238.04C230.081 237.62 233.064 236.516 235.754 234.79C238.444 233.064 240.787 230.75 242.65 227.982C244.513 225.213 245.859 222.043 246.611 218.652C247.274 215.667 247.464 212.562 247.178 209.476C247.113 208.782 246.475 208.298 245.784 208.391L223.374 211.422L200.965 214.452Z"
                    fill="#074955"
                />
                <path
                    d="M236.035 427.137C231.725 432.38 227.076 435.755 223.186 437.622C221.247 438.553 219.451 439.13 217.945 439.355C217.194 439.468 216.466 439.5 215.804 439.419C215.164 439.34 214.434 439.137 213.836 438.645C213.125 438.061 212.832 437.252 212.836 436.461C212.839 435.754 213.073 435.077 213.341 434.502C213.882 433.344 214.861 431.993 216.055 430.533C217.92 428.25 220.581 425.385 223.645 422.086C224.526 421.137 225.441 420.151 226.38 419.133C230.571 414.039 234.785 409.713 238.235 406.836C239.952 405.403 241.551 404.27 242.909 403.595C243.582 403.261 244.292 402.99 244.988 402.895C245.681 402.801 246.594 402.857 247.361 403.487C248.127 404.117 248.358 405.002 248.4 405.7C248.442 406.401 248.313 407.15 248.115 407.875C247.716 409.338 246.914 411.126 245.84 413.088C243.679 417.04 240.237 422.026 236.035 427.137Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M213.765 436.29C215.632 437.825 226.337 436.135 234.614 426.067C242.891 415.999 246.94 405.705 246.083 404.736C243.983 403.18 235.723 410.598 227.85 420.506C219.378 429.713 213.621 434.657 213.765 436.29Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M214.853 437.703C213.365 435.144 218.428 429.871 228.301 419.605C239.951 406.664 245.208 402.511 246.108 405.001C243.411 407.666 235.677 412.399 228.208 423.171C219.797 435.301 219.696 437.078 214.853 437.703Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M232.774 415.729L232.87 415.738L232.964 415.758C234.997 416.183 236.709 416.771 237.756 417.792C238.339 418.36 238.733 419.078 238.823 419.922C238.909 420.726 238.702 421.471 238.413 422.103C237.858 423.313 236.783 424.561 235.591 425.796C234.67 426.749 233.561 427.81 232.364 428.956C231.981 429.322 231.59 429.697 231.192 430.08C228.034 433.42 224.434 435.917 221.42 437.436C219.915 438.195 218.497 438.74 217.309 439.007C216.72 439.14 216.117 439.221 215.553 439.193C215.033 439.167 214.264 439.035 213.654 438.459C213.035 437.876 212.86 437.127 212.855 436.529C212.85 435.954 212.994 435.387 213.173 434.889C213.533 433.886 214.193 432.728 215.015 431.508C216.677 429.041 219.251 425.959 222.298 422.728C225.303 419.543 227.227 417.738 228.692 416.774C229.458 416.269 230.167 415.946 230.901 415.792C231.631 415.638 232.264 415.679 232.774 415.729Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M225.302 424.197L225.368 424.221L225.431 424.249C227.162 425.027 228.504 425.796 229.388 426.645C230.345 427.565 230.919 428.753 230.615 430.138C230.364 431.279 229.565 432.214 228.864 432.926C228.306 433.493 227.609 434.111 226.896 434.74C226.69 434.923 226.482 435.107 226.276 435.291C224.306 437.353 221.791 438.574 219.588 439.124C218.474 439.402 217.38 439.523 216.419 439.467C215.544 439.416 214.437 439.198 213.653 438.459C212.798 437.653 212.631 436.58 212.718 435.694C212.804 434.831 213.139 433.946 213.563 433.116C214.42 431.439 215.881 429.531 217.644 427.662C219.338 425.866 220.623 424.725 221.815 424.195C222.479 423.9 223.124 423.786 223.779 423.834C224.383 423.878 224.913 424.058 225.302 424.197Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M369.594 283.926C370.257 284.616 371.022 285.159 371.903 285.407C372.822 285.667 373.718 285.562 374.517 285.2C375.972 284.54 377.027 283.065 377.805 281.65C379.34 278.856 380.558 274.815 381.48 271.586C384.179 269.072 385.886 265.872 386.535 263.072C386.873 261.614 386.954 260.132 386.623 258.857C386.283 257.547 385.435 256.272 383.865 255.826C382.619 255.472 381.043 255.655 379.566 256.037C378.017 256.437 376.298 257.13 374.637 258.068C371.377 259.909 367.991 262.905 366.856 266.901C365.909 270.237 365.222 273.153 365.491 275.911C365.772 278.791 367.065 281.298 369.594 283.926Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M372.272 278.382L372.278 278.35L372.282 278.318C373.674 267.701 377.293 262.727 381.586 260.428C386.025 258.051 391.685 258.231 397.858 259.122C401.2 259.604 403.697 260.873 405.552 262.671C407.421 264.482 408.734 266.922 409.536 269.88C411.154 275.842 410.624 283.641 408.69 291.613C406.795 299.424 402.984 305.904 398.447 310.087C393.898 314.28 388.796 316.033 384.15 314.905C379.686 313.822 375.977 309.08 373.733 301.987C371.526 295.011 370.937 286.316 372.272 278.382Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M406.693 259.741C406.084 265.108 412.908 286.883 402.842 301.094C396.094 314.786 387.99 313.5 382.71 310.568C380.342 304.055 382.71 301.264 384.671 296.57C390.88 281.711 409.343 236.352 406.693 259.741Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M393.849 259.59C402.071 262.117 402.504 270.583 400.598 286.103C398.174 301.122 391.199 313.742 385.262 312.784C378.052 308.485 374.602 298.522 376.047 282.14C379.155 262.889 387.405 257.61 393.849 259.59Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M397.331 242.748C405.553 245.275 409.142 252.921 407.237 268.441C403.275 280.808 388.112 283.719 386.535 280.322C373.281 289.571 377.452 276.092 378.896 259.71C382.004 240.459 390.887 240.768 397.331 242.748Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M383.581 243.902L383.675 243.816L383.777 243.742C384.755 243.027 385.668 242.45 386.517 242.048C387.347 241.655 388.262 241.358 389.195 241.373C390.217 241.39 391.123 241.783 391.795 242.516C392.407 243.184 392.721 244.017 392.899 244.776C393.251 246.27 393.245 248.244 393.134 250.367C393.05 252 392.891 253.886 392.719 255.934C392.663 256.594 392.606 257.271 392.55 257.961C392.307 263.605 391.176 268.876 389.856 272.779C389.197 274.727 388.467 276.406 387.733 277.646C387.371 278.257 386.957 278.849 386.489 279.319C386.096 279.713 385.277 280.417 384.137 280.422C382.97 280.426 382.229 279.725 381.852 279.219C381.469 278.704 381.216 278.084 381.034 277.509C380.665 276.336 380.418 274.766 380.252 272.984C379.919 269.381 379.882 264.444 380.113 258.954C380.342 253.527 380.643 250.312 381.129 248.247C381.38 247.18 381.703 246.318 382.157 245.573C382.617 244.817 383.14 244.305 383.581 243.902Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="4.33115"
                />
                <path
                    d="M384.229 278.257C386.017 278.251 385.941 269.392 386.4 258.504C387.341 246.95 392.31 240.127 385.004 245.466C383.468 246.87 382.738 248.058 382.279 258.946C381.82 269.833 382.44 278.264 384.229 278.257Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M386.332 254.46L386.406 254.408L386.486 254.364C387.225 253.96 387.911 253.646 388.539 253.448C389.154 253.253 389.823 253.134 390.483 253.235C391.204 253.345 391.826 253.707 392.261 254.296C392.658 254.832 392.827 255.457 392.904 256.012C393.054 257.101 392.915 258.487 392.695 259.953C392.526 261.081 392.288 262.38 392.029 263.788C391.946 264.24 391.861 264.704 391.775 265.177C391.225 269.088 390.072 272.653 388.876 275.248C388.279 276.542 387.648 277.647 387.042 278.445C386.743 278.838 386.407 279.216 386.039 279.502C385.727 279.745 385.093 280.16 384.282 280.052C383.452 279.942 382.967 279.376 382.727 278.975C382.484 278.569 382.344 278.103 382.254 277.681C382.069 276.819 382 275.695 382.002 274.438C382.008 271.894 382.313 268.452 382.843 264.655C383.368 260.905 383.796 258.688 384.281 257.288C384.532 256.563 384.822 255.984 385.199 255.5C385.582 255.009 385.992 254.698 386.332 254.46Z"
                    stroke="black"
                    stroke-width="3.17694"
                />
                <path
                    d="M386.476 265.118L386.552 265.093L386.63 265.075C387.726 264.828 388.798 264.702 389.696 264.967C390.196 265.113 390.686 265.393 391.062 265.864C391.43 266.323 391.589 266.839 391.648 267.293C391.757 268.125 391.567 269.049 391.359 269.85C391.194 270.488 390.968 271.219 390.732 271.985C390.66 272.217 390.588 272.453 390.515 272.69C389.957 274.946 388.933 276.904 387.897 278.288C387.379 278.981 386.822 279.576 386.275 279.997C386.003 280.206 385.69 280.406 385.347 280.541C385.026 280.666 384.513 280.801 383.934 280.64C383.368 280.482 382.984 280.111 382.759 279.84C382.519 279.551 382.331 279.219 382.184 278.898C381.886 278.252 381.659 277.449 381.51 276.578C381.21 274.829 381.189 272.574 381.748 270.29C382.301 268.028 383.155 266.671 384.283 265.912C384.832 265.542 385.365 265.371 385.78 265.27C385.935 265.233 386.093 265.201 386.207 265.179C386.234 265.173 386.258 265.168 386.279 265.164C386.416 265.136 386.461 265.124 386.476 265.118Z"
                    stroke="black"
                    stroke-width="3.17694"
                />
                <path
                    d="M397.746 243.609L397.835 243.544L397.933 243.49C399.675 242.537 401.39 241.862 402.949 242.061C403.842 242.174 404.723 242.579 405.435 243.353C406.099 244.075 406.451 244.937 406.649 245.71C407.032 247.203 407.02 249.094 406.881 251.113C406.738 253.186 406.433 255.68 406.08 258.495C405.579 264.112 404.101 269.397 402.531 273.252C401.747 275.179 400.915 276.809 400.132 277.965C399.746 278.534 399.325 279.06 398.877 279.447C398.652 279.641 398.368 279.847 398.026 279.991C397.692 280.132 397.174 280.268 396.543 280.125C395.408 279.866 394.755 278.964 394.452 278.409C394.133 277.825 393.937 277.167 393.802 276.563C393.526 275.329 393.377 273.72 393.314 271.905C393.187 268.239 393.395 263.297 393.876 257.851C394.352 252.463 394.784 249.325 395.324 247.38C395.603 246.379 395.934 245.601 396.375 244.967C396.825 244.319 397.321 243.921 397.746 243.609Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="4.13457"
                />
                <path
                    d="M396.725 278.066C398.361 278.438 398.811 267.774 399.767 256.966C401.195 245.593 405.991 241.586 399.046 245.387C397.573 246.467 396.847 247.503 395.891 258.31C394.936 269.118 395.089 277.694 396.725 278.066Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M397.059 252.661L397.138 252.621L397.223 252.59C398.972 251.958 400.54 251.604 401.881 251.772C402.59 251.862 403.311 252.106 403.961 252.601C404.617 253.101 405.064 253.754 405.354 254.438C405.891 255.705 405.961 257.242 405.897 258.76C405.83 260.332 405.599 262.205 405.309 264.302C404.922 268.591 403.542 272.436 402.037 275.179C401.283 276.553 400.469 277.703 399.687 278.508C399.299 278.907 398.877 279.265 398.432 279.515C398.035 279.738 397.37 280.018 396.57 279.835C395.771 279.654 395.195 279.119 394.832 278.614C394.483 278.131 394.255 277.583 394.095 277.082C393.772 276.074 393.584 274.799 393.48 273.409C393.269 270.596 393.371 266.852 393.732 262.766C394.083 258.79 394.361 256.542 394.789 255.212C395.024 254.484 395.339 253.906 395.82 253.453C396.212 253.083 396.67 252.855 396.972 252.704C397.003 252.689 397.032 252.674 397.059 252.661Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M396.044 264.4L396.11 264.385L396.178 264.376C397.598 264.185 398.822 264.131 399.839 264.3C400.875 264.472 401.965 264.927 402.713 265.99C403.404 266.971 403.515 268.084 403.505 268.95C403.494 269.829 403.346 270.821 403.173 271.829C402.94 274.245 401.793 276.252 400.564 277.603C399.938 278.292 399.248 278.859 398.57 279.241C397.984 279.571 397.082 279.952 396.115 279.732C395.039 279.487 394.342 278.673 393.948 277.959C393.563 277.261 393.34 276.451 393.202 275.671C392.922 274.089 392.906 272.098 393.09 270.022C393.179 269.014 393.268 268.212 393.374 267.578C393.477 266.962 393.614 266.391 393.848 265.915C394.112 265.376 394.486 264.986 394.982 264.735C395.34 264.554 395.745 264.465 395.975 264.415C396 264.409 396.023 264.404 396.044 264.4Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M380.69 258.949L380.618 259.033L380.536 259.107C379.672 259.881 378.857 260.506 378.088 260.945C377.344 261.37 376.488 261.71 375.557 261.705C374.505 261.7 373.5 261.25 372.72 260.398C372.036 259.648 371.651 258.727 371.409 257.901C370.924 256.247 370.744 254.012 370.678 251.458C370.61 248.857 370.661 245.694 370.728 242.085C370.656 235.044 371.58 228.25 372.739 223.222C373.318 220.71 373.968 218.583 374.608 217.063C374.923 216.315 375.268 215.634 375.645 215.119C375.832 214.864 376.078 214.578 376.395 214.35C376.691 214.137 377.255 213.827 378.04 213.928C379.423 214.105 380.16 215.275 380.415 215.738C380.744 216.336 380.98 217.045 381.164 217.738C381.542 219.159 381.845 221.086 382.09 223.337C382.583 227.872 382.877 234.075 382.95 240.98C383.022 247.839 382.908 251.77 382.579 254.204C382.412 255.442 382.179 256.38 381.843 257.158C381.497 257.958 381.08 258.494 380.69 258.949Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="4.13457"
                />
                <path
                    d="M378.061 215.995C376.393 215.781 377.132 238.571 375.179 244.6C374.908 258.929 372.52 262.664 379.06 256.897C380.42 255.333 381.038 253.938 380.896 240.383C380.753 226.828 379.73 216.209 378.061 215.995Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M380.23 244.031L380.155 244.078L380.074 244.117C378.334 244.941 376.628 245.348 375.047 244.88C373.271 244.354 372.233 242.925 371.671 241.451C371.129 240.031 370.908 238.301 370.822 236.512C370.735 234.702 370.78 232.659 370.863 230.529C370.824 226.219 371.822 222.256 373.053 219.38C373.67 217.939 374.368 216.715 375.068 215.839C375.416 215.404 375.802 215.006 376.221 214.715C376.595 214.455 377.229 214.114 378.042 214.219C378.854 214.323 379.48 214.799 379.892 215.266C380.287 215.714 380.569 216.238 380.779 216.722C381.2 217.695 381.513 218.947 381.755 220.322C382.243 223.105 382.512 226.845 382.555 230.95C382.598 235 382.535 237.841 382.267 239.797C382.007 241.696 381.503 243.226 380.23 244.031Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M380.283 229.538L380.19 229.573L380.093 229.596C378.376 229.998 377.017 230.22 375.977 230.179C375.451 230.159 374.851 230.067 374.274 229.777C373.628 229.451 373.124 228.93 372.823 228.283C372.556 227.711 372.5 227.151 372.492 226.738C372.484 226.311 372.527 225.873 372.576 225.477C372.608 225.216 372.643 224.963 372.678 224.709C372.757 224.143 372.837 223.574 372.882 222.912C372.873 220.525 373.714 218.423 374.703 216.944C375.205 216.193 375.78 215.551 376.367 215.091C376.66 214.861 376.987 214.652 377.339 214.505C377.672 214.366 378.147 214.232 378.706 214.303C379.846 214.45 380.597 215.281 381 215.922C381.408 216.571 381.68 217.343 381.872 218.092C382.263 219.618 382.457 221.582 382.479 223.659C382.489 224.671 382.482 225.474 382.444 226.109C382.406 226.728 382.336 227.295 382.173 227.778C381.99 228.321 381.693 228.755 381.247 229.072C380.924 229.303 380.544 229.442 380.342 229.517C380.32 229.525 380.3 229.532 380.283 229.538Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M416.056 250.788L415.83 250.504L415.524 250.311C414.544 249.695 413.377 249.202 412.128 249.312C410.796 249.43 409.795 250.189 409.08 251.194C408.394 252.156 407.893 253.442 407.494 254.941C407.091 256.453 406.759 258.312 406.476 260.529C405.74 264.824 405.822 269.004 406.294 272.197C406.53 273.791 406.874 275.213 407.302 276.311C407.513 276.854 407.777 277.4 408.113 277.864C408.409 278.274 408.992 278.939 409.916 279.146C410.739 279.331 411.441 279.079 411.894 278.833C412.364 278.578 412.777 278.218 413.131 277.845C413.842 277.093 414.542 276.038 415.192 274.792C416.502 272.281 417.756 268.707 418.492 264.441C419.233 260.147 419.023 257.143 418.359 254.934C417.737 252.862 416.748 251.642 416.191 250.954C416.142 250.895 416.097 250.84 416.056 250.788Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="4.00513"
                />
                <path
                    d="M410.256 277.173C408.755 276.836 411.855 268.302 413.265 260.128C414.38 251.37 412.237 251.088 415.013 252.668C416.072 253.997 417.948 255.829 416.538 264.003C415.128 272.176 411.757 277.51 410.256 277.173Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M410.896 262.535C410.877 262.538 410.859 262.541 410.843 262.544C409.496 262.697 408.474 262.82 407.786 262.993C407.437 263.081 406.994 263.224 406.604 263.51C406.136 263.855 405.825 264.35 405.723 264.944C405.641 265.423 405.716 265.869 405.759 266.101C405.783 266.233 405.813 266.37 405.837 266.486L405.839 266.495C405.866 266.621 405.891 266.739 405.916 266.865C406.005 267.325 406.088 267.913 406.039 268.722C405.684 270.855 405.771 273.027 406.201 274.784C406.418 275.671 406.743 276.538 407.21 277.255C407.664 277.955 408.392 278.715 409.449 278.952C411.453 279.403 412.727 277.874 413.42 276.825C414.112 275.778 414.79 274.33 415.49 272.836C415.578 272.649 415.666 272.461 415.754 272.274C416.807 270.037 417.269 268.192 417.136 266.661C416.99 264.977 416.131 263.798 414.976 263.135C413.952 262.548 412.841 262.446 412.128 262.443C411.748 262.441 411.42 262.468 411.185 262.495C411.066 262.509 410.969 262.524 410.896 262.535Z"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M374.576 273.749L374.151 273.827C372.243 274.179 370.555 274.054 369.205 273.376C367.805 272.674 366.959 271.468 366.66 270.101C366.094 267.505 367.484 264.582 369.836 262.787C372.117 261.047 373.977 260.055 375.558 259.491C377.159 258.92 378.393 258.817 379.314 258.759L374.576 273.749ZM374.576 273.749L374.917 273.484M374.576 273.749L374.917 273.484M374.917 273.484C377.623 271.387 381.769 269.635 385.558 268.033C385.713 267.967 385.867 267.902 386.021 267.837C387.905 267.04 389.716 266.262 391.026 265.514C391.673 265.145 392.32 264.716 392.805 264.216C393.265 263.742 393.889 262.873 393.677 261.726C393.449 260.488 392.671 259.634 391.659 259.133M374.917 273.484L391.659 259.133M391.659 259.133C390.748 258.682 389.637 258.504 388.513 258.431M391.659 259.133L388.513 258.431M388.513 258.431C386.506 258.3 383.771 258.475 380.535 258.681M388.513 258.431L380.535 258.681M380.535 258.681C380.135 258.707 379.728 258.733 379.314 258.759L380.535 258.681Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <path
                    d="M392.534 262.035L392.521 261.964L392.502 261.894C392.459 261.735 392.426 261.582 392.392 261.415C392.387 261.39 392.382 261.364 392.376 261.336C392.348 261.195 392.312 261.015 392.271 260.851C392.166 260.429 391.959 259.79 391.371 259.254C390.823 258.753 390.144 258.526 389.51 258.393C388.863 258.256 388.047 258.176 387.035 258.118C385.513 258.032 383.723 258.031 382.147 258.433C380.558 258.839 378.851 259.754 378.146 261.73C377.647 263.129 377.434 264.466 377.749 265.645C378.1 266.958 379.018 267.774 380.087 268.174C381.065 268.539 382.159 268.563 383.132 268.476C384.134 268.387 385.179 268.164 386.15 267.902C386.272 267.869 386.392 267.837 386.511 267.805C387.349 267.579 388.116 267.372 388.77 267.166C389.505 266.934 390.235 266.662 390.848 266.277C391.499 265.869 392.089 265.29 392.405 264.443C392.705 263.637 392.675 262.804 392.534 262.035Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.55269"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_5819_12658"
                        x1="26.8333"
                        y1="17.3298"
                        x2="-1.78845"
                        y2="27.7017"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_5819_12658"
                        x1="180.207"
                        y1="350.652"
                        x2="151.585"
                        y2="361.024"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_5819_12658"
                        x1="18.4331"
                        y1="76.5099"
                        x2="383.831"
                        y2="308.967"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A4ED6B" />
                        <stop offset="1" stop-color="#53C201" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_5819_12658"
                        x1="33.4115"
                        y1="85.0148"
                        x2="369.796"
                        y2="295.996"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#32E1FC" />
                        <stop offset="0.618395" stop-color="#00B7D6" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_5819_12658"
                        x1="261.703"
                        y1="298.236"
                        x2="267.628"
                        y2="312.45"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#88DDF0" />
                        <stop offset="1" stop-color="#01B5D1" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_5819_12658"
                        x1="228.441"
                        y1="302.159"
                        x2="236.882"
                        y2="323.721"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D5FE73" />
                        <stop offset="1" stop-color="#9DE00A" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_5819_12658"
                        x1="187.748"
                        y1="314.078"
                        x2="206.166"
                        y2="316.751"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#02AA09" />
                        <stop offset="1" stop-color="#149A00" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_5819_12658"
                        x1="233.308"
                        y1="313.978"
                        x2="236.295"
                        y2="317.702"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6DE1D5" />
                        <stop offset="1" stop-color="#10BAD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_5819_12658"
                        x1="231.245"
                        y1="316.103"
                        x2="240.543"
                        y2="321.827"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#25C0C9" />
                        <stop offset="1" stop-color="#8CE2D5" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_5819_12658"
                        x1="238.879"
                        y1="311.299"
                        x2="241.313"
                        y2="322.904"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#028AB5" />
                        <stop offset="1" stop-color="#12ADC4" />
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_5819_12658"
                        x1="104.166"
                        y1="396.163"
                        x2="93.5882"
                        y2="403.401"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_5819_12658"
                        x1="190.803"
                        y1="433.382"
                        x2="183.815"
                        y2="438.164"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_5819_12658"
                        x1="375.076"
                        y1="425.542"
                        x2="364.498"
                        y2="432.78"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_5819_12658"
                        x1="393.607"
                        y1="327.201"
                        x2="386.619"
                        y2="331.983"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
