import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React, { useEffect, useState } from 'react';
import { Role } from '@f_types/TypeRole';

type TypeIconCompanyProps = {
    classNames?: any;
    role?: string[];
    color?: string;
};

export const IconCompany = ({ classNames, role, color }: TypeIconCompanyProps) => {
    const { colors } = React.useContext(ThemeContext);
    const [colorsIcon, setColorsIcon] = useState({
        fillRect: colors.epsilon,
        fillPath: '#A9C1CF',
        fillOpacity: 0.2,
    });

    useEffect(() => {
        role &&
            role.map((item) => {
                if (item === Role.User) {
                    setColorsIcon({ fillRect: '#FECD52', fillPath: '#FECD52', fillOpacity: 0.2 });
                } else if (item === Role.Admin) {
                    setColorsIcon({ fillRect: '#A9C1CF', fillPath: '#A9C1CF', fillOpacity: 0.2 });
                } else if (item === Role.Manager) {
                    setColorsIcon({ fillRect: '#6EBA6E', fillPath: '#6EBA6E', fillOpacity: 0.2 });
                } else if (item === Role.SUPER_ADMIN) {
                    setColorsIcon({
                        fillRect: color ? color : '#EF907F',
                        fillPath: color ? color : '#EF907F',
                        fillOpacity: 0.2,
                    });
                }
            });
    }, []);

    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill={colorsIcon.fillPath} fillOpacity={colorsIcon.fillOpacity} />
                <path
                    d="M16.5 16H17.5V17H6.5V16H7.5V7.5C7.5 7.36739 7.55268 7.24021 7.64645 7.14645C7.74021 7.05268 7.86739 7 8 7H16C16.1326 7 16.2598 7.05268 16.3536 7.14645C16.4473 7.24021 16.5 7.36739 16.5 7.5V16ZM15.5 16V8H8.5V16H15.5ZM10 11.5H11.5V12.5H10V11.5ZM10 9.5H11.5V10.5H10V9.5ZM10 13.5H11.5V14.5H10V13.5ZM12.5 13.5H14V14.5H12.5V13.5ZM12.5 11.5H14V12.5H12.5V11.5ZM12.5 9.5H14V10.5H12.5V9.5Z"
                    fill={colorsIcon.fillRect}
                />
            </svg>
        </div>
    );
};
