import stls from '@f_styles/general/Universal.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export const IconAnalysis = () => {
    const { colors } = useContext(ThemeContext);
    return (
        <div className={stls.iconClose}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="18" fill={colors.epsilon} fillOpacity={0.2} />
                <path d="M9.6665 18.8333H14.6665V25.5H9.6665V18.8333ZM21.3332 14.6667H26.3332V25.5H21.3332V14.6667ZM15.4998 10.5H20.4998V25.5H15.4998V10.5ZM11.3332 20.5V23.8333H12.9998V20.5H11.3332ZM17.1665 12.1667V23.8333H18.8332V12.1667H17.1665ZM22.9998 16.3333V23.8333H24.6665V16.3333H22.9998Z" />
            </svg>
        </div>
    );
};
