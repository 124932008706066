import React from 'react';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';

interface IAssistantBrandColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    foundItem: any;
    isPromo: any;
    brand: any;
}

export const AssistantBrandColumn = (props: IAssistantBrandColumnProps) => {
    const { foundItem, colors, styles, isPromo, brand } = props;

    return (
        <p
            style={{
                color: foundItem ? colors.alpha : colors.sigma,
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
            }}
            className={cn(styles.cellRow, styles.fwBold)}>
            <p>
                {isPromo && (
                    <Tooltip title={'Промо поставщик'}>
                        <StarIcon className={styles.iconPromo} />
                    </Tooltip>
                )}
            </p>
            {brand}
        </p>
    );
};
