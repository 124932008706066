import { Button, Card, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, SyntheticEvent, useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/settings/Transfer1c.module.sass';
import { IconAttach } from '@f_components/icons/IconAttach';
import { QwepContext, TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';
import { Alert } from '@f_general/alert';
import { UserContext } from '@f_context/User_context';
import CircularProgress from '@mui/material/CircularProgress';
import { IconAlertWarning } from '@f_components/icons/alert/IconWarning';
import { IconAlertError } from '@f_components/icons/alert/IconError';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconAlertInfo } from '@f_components/icons/alert/IconInfo';
import { Tariff } from '@f_qwep/types';
import { IconOneS } from '../../icons/IconOneS';
import { IconOneSClassic } from '../../icons/IconOneSClassic';
import { IconAddPrice } from '../../icons/IconAddPrice';
import { IconUploadLine } from '../../icons/IconUploadLine';
import { IconUser } from '../../icons/IconUser';

type TypeMessage = {
    accountId: any;
    code: number;
    details: string;
    message: string;
};

export function Transfer1c() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { create1cScan, assistantCustomList, getProperty, tariff } = useContext(QwepContext);
    const { userInfo, users } = useContext(UserContext);

    const [titleFile, setTitleFile] = useState('');
    const [file, setFile] = useState<File>();
    const [title, setTitle] = useState('');
    const [createGroup, setCreateGroup] = useState(true);

    //
    const [messages, setMessages] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            setTitleFile(e.target.files[0].name);
        }
    };

    const submitHandler = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();
        if (!title.trim()) {
            return Alert({
                type: 'warning',
                text: 'Введите название конфигурации',
            });
        }
        if (!file) {
            return Alert({
                type: 'warning',
                text: 'Прикрепите файл',
            });
        } else if (file.name.split('.')[1] !== 'qwep') {
            return Alert({
                type: 'warning',
                text: 'Некорректный формат файла!\nПоддерживаемое расширение - .qwep',
            });
        }

        setLoading(true);
        const result = await create1cScan({
            file: file,
            title: title,
            createGroup: createGroup,
        });
        if (result.isOk) {
            await getProperty();
            setMessages([...result.result.errors, ...result.result.warnings]);
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            if (result.statusNumber === 1) {
                Alert({
                    type: 'warning',
                    text: 'Нельзя загрузить конфигурацию с демо аккаунта',
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        }
        setLoading(false);
    };

    const deleteMessageHandler = () => {
        setMessages([]);
    };

    return (
        <div className={stls.page}>
            <div className={stls.content}>
                <Card className={stls.transferCard}>
                    <div className={stls.transferCardContent}>
                        <IconOneS />
                        <Typography sx={{ marginLeft: '16px', display: 'flex', alignItems: 'center' }} variant="h6">
                            Перенос поставщиков из&nbsp;
                            <IconOneSClassic />
                        </Typography>
                    </div>
                    <form className={stls.form} onSubmit={submitHandler}>
                        <div
                            className={stls.info}
                            style={{
                                background: colors.upsilon,
                                border: `1px solid ${colors.beta}`,
                            }}>
                            <IconAlertInfo classNames={[stls.icon]} />{' '}
                            <p style={{ color: colors.omicron }}>
                                Для переноса поставщиков необходимо загрузить backup 1c аккаунта
                            </p>
                            <p style={{ color: colors.omicron }}>Нельзя загрузить backup с демо аккаунта</p>
                        </div>
                        {(tariff === Tariff.Business || tariff === Tariff.PROPlus) && (
                            <div
                                className={stls.info}
                                style={{
                                    background: colors.upsilon,
                                    border: `1px solid ${colors.beta}`,
                                }}>
                                <IconAlertInfo classNames={[stls.icon]} />{' '}
                                <p style={{ color: colors.omicron }}>
                                    Информация из 1С backup будет добавлена в текуший пользовательский портрет
                                    приложения Ассистент
                                </p>
                            </div>
                        )}
                        <TextField
                            type="text"
                            name="title"
                            placeholder="Введите название"
                            className={stls.input}
                            value={title}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                        />
                        <div className={stls.row}>
                            <Typography>
                                <label className={stls.inputAddFile} htmlFor="file">
                                    <IconAttach />
                                    <p id="inputAddFile" style={{ color: colors.alpha }} className={stls.text}>
                                        {t('qwep.price.modal.updateOrAddPriceModal.labelFile')}
                                    </p>{' '}
                                    <input id="file" type="file" onChange={handleFileChange} />
                                </label>
                                <p className={stls.fileName}>{titleFile}</p>
                            </Typography>
                            <FormControlLabel
                                className={stls.checkbox}
                                control={
                                    <Checkbox
                                        checked={createGroup}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            setCreateGroup(e.target.checked)
                                        }
                                    />
                                }
                                label="Создать группу"
                            />
                        </div>
                        <Button
                            sx={{ float: 'right', width: '240px' }}
                            type={'submit'}
                            disabled={loading}
                            className={stls.btn}
                            variant="contained">
                            <p className={stls.text}>Подтвердить</p>
                            {loading && <CircularProgress size={20} sx={{ color: colors.tau }} />}
                        </Button>
                    </form>
                    {messages.length ? (
                        <div className={stls.message} style={{ background: colors.beta }}>
                            <button className={stls.iconClose} onClick={deleteMessageHandler}>
                                <HighlightOffIcon className={stls.icon} />
                            </button>
                            {messages.map((item) => {
                                if (item.includes('error:')) {
                                    return (
                                        <div style={{ background: '#ffdcdc' }} className={stls.item}>
                                            <IconAlertError classNames={[stls.icon]} />
                                            {item.replace('error: ', '')}
                                        </div>
                                    );
                                } else if (item.includes('warn:')) {
                                    return (
                                        <div style={{ background: '#FFF6DD' }} className={stls.item}>
                                            <IconAlertWarning classNames={[stls.icon]} />
                                            {item.replace('warn: ', '')}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                </Card>

                <Card className={stls.transferCard}>
                    <div className={stls.transferCardContent}>
                        <IconAddPrice />
                        <Typography sx={{ marginLeft: '16px', alignItems: 'center' }} variant="h6">
                            Список ваших бэкапов
                        </Typography>
                    </div>
                    <div className={stls.list}>
                        {assistantCustomList.map((item: TypeAssistantCustomList) => {
                            let user = users.find((usr: any) => usr.userId === item.parentId);
                            //   let creator =
                            //     user?.userId === userInfo?.userId
                            //       ? 'Вы'
                            //       : `${user?.lastName} ${user?.firstName}`;
                            if (item.key1c) {
                                return (
                                    <div
                                        className={stls.item}
                                        style={{
                                            justifyContent: 'space-between',
                                            background: colors.upsilon,
                                            border: `1px solid ${colors.beta}`,
                                        }}>
                                        <div style={{ display: 'flex' }}>
                                            <IconUploadLine />
                                            <p
                                                style={{ color: colors.alpha, marginLeft: '16px' }}
                                                className={stls.title}>
                                                {item.title}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <IconUser />
                                            <p style={{ color: colors.omicron, marginLeft: '16px' }}>
                                                {' '}
                                                {user?.lastName} {user?.firstName}
                                            </p>
                                        </div>
                                        {/* <Tooltip
                      title={
                        <div>
                          <p>{`Создал: ${creator}`}</p>
                          <p>{`Доступ: ${
                            item.private ? 'приватный' : 'общий'
                          }`}</p>
                        </div>
                      }
                    >
                      <PersonIcon
                        sx={{ color: colors.tau }}
                        className={stls.icon}
                      />
                    </Tooltip> */}
                                    </div>
                                );
                            }
                        })}
                    </div>
                </Card>
            </div>
        </div>
    );
}
