import { ThemeContext } from '@f_context/Theme_context';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext } from 'react';

export function CastomCircularProgress({ progress }: any) {
    const { colors } = useContext(ThemeContext);
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft: '30px' }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    style={{ color: colors.omicron }}>{`${Math.floor(progress)}%`}</Typography>
            </Box>
        </Box>
    );
}
