import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { AuthLoading } from '@f_components/loadings/AuthLoading';
import { Alert } from '@f_general/alert';

export function ActivationPage() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.search || !location.hash) {
            return navigate('/');
        } else {
            activate();
        }
    }, []);

    const activate = async () => {
        const email = location.search.replace('?email=', '').trim();
        const key = location.hash.replace('#', '').trim();

        const activate = await callPlatformAPI(
            'auth/register/confirm',
            'post',
            {
                email,
                key,
            },
            { guard: false },
        );
        if (activate.data.isOk) {
            Alert({
                type: 'success',
                text: 'Успешно',
            });
        } else {
            Alert({
                type: 'error',
                text: 'Пользователь не был активирован',
            });
        }
        return navigate('/');
    };

    return <AuthLoading />;
}
