import { Tooltip } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import stls from '@f_qwep/styles/component/price/Price.module.sass';
import cn from 'classnames';

export function CellPrices({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <Tooltip placement="top" title={info.row.original.priceName}>
            <p className={cn(stls.cellPriceList)}>{info.row.original.priceName}</p>
        </Tooltip>
    );
}
