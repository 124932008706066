import { ThemeContext } from '@f_context/Theme_context';
import React, { useContext } from 'react';
import Joyride from 'react-joyride';

export default function GeneralJoyride({ steps, run, callback, stepIndex, disabledButton, styles }: any) {
    const { colors } = useContext(ThemeContext);

    const options = {
        arrowColor: colors.psi,
        showSteps: true,
        locale: {
            back: 'Назад',
            close: 'Закрыть',
            last: 'Закрыть',
            next: 'Далее',
            skip: 'Пропустить',
        },
    };

    return (
        <div>
            <Joyride
                steps={steps}
                run={run}
                continuous={true}
                showProgress={true}
                showSkipButton={true}
                disableOverlayClose={false}
                disableScrolling={true}
                disableScrollParentFix={false}
                styles={
                    styles
                        ? styles
                        : {
                              options: {
                                  zIndex: 10000,
                              },
                              tooltipContainer: {
                                  textAlign: 'left',
                              },
                              // buttonBack: {
                              //     backgroundColor: '#ccc',
                              //     color: '#000',
                              //     borderRadius: '4px',
                              //     marginRight: '10px',
                              //   },
                              buttonNext: {
                                  backgroundColor: disabledButton ? colors.gamma : colors.alpha,
                                  borderRadius: '12px',
                                  padding: '13px',
                                  opacity: disabledButton ? '0.5' : '1',
                                  pointerEvents: disabledButton ? 'none' : 'auto',
                              },
                              tooltip: {
                                  backgroundColor: colors.psi,
                                  color: colors.omicron,
                                  whiteSpace: 'pre-wrap',
                                  borderRadius: '12px',
                                  boxShadow:
                                      '0px 2.00345px 10.0172px rgba(156, 172, 185, 0.03), 0px 0.724608px 3.62304px rgba(156, 172, 185, 0.0209047)',
                              },
                              tooltipTitle: {
                                  padding: '0 10px',
                              },
                              //   buttonSkip: {
                              //     backgroundColor: '#dc3545',
                              //     color: '#fff',
                              //     borderRadius: '4px',
                              //   },
                              buttonClose: {
                                  color: colors.epsilon,
                              },
                          }
                }
                callback={callback}
                stepIndex={stepIndex}
                {...options}
            />
        </div>
    );
}
