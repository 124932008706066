export const IconSaveFilter = (color: any) => {
    return (
        <div>
            <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="11" r="9" stroke={color.color} strokeWidth="2" />
                <path
                    d="M12.6667 15V11.4444H7.33333V15H6.44444C6.32657 15 6.21352 14.9532 6.13017 14.8698C6.04683 14.7865 6 14.6734 6 14.5556V7.44444C6 7.32657 6.04683 7.21352 6.13017 7.13017C6.21352 7.04683 6.32657 7 6.44444 7H12.2222L14 8.77778V14.5556C14 14.6734 13.9532 14.7865 13.8698 14.8698C13.7865 14.9532 13.6734 15 13.5556 15H12.6667ZM11.7778 15H8.22222V12.3333H11.7778V15Z"
                    fill={color.color}
                />
            </svg>
        </div>
    );
};
