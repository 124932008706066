import { Grid, Box } from '@mui/material';
import { CellArticle } from './CellArticle';
import { CellBrand } from './CellBrand';
import { CellComment } from './CellComment';
import { CellDate } from './CellDate';
import { CellPrice } from './CellPrice';
import { CellStatus } from './CellStatus';
import { CellTitle } from './CellTitle';
import { CellQuantity } from './CellQuantity';
import { CellVendor } from './CellVendor';
import { CellWarehouse } from './CellWarehouse';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { useTranslation } from 'react-i18next';

export function CellMobile({ info }: any) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', padding: '12px' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <CellDate info={info} />
                    <CellComment info={info} />
                </Grid>
                <Grid item xs={3.5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.vendor')}:</p>
                        <CellVendor info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.title')}:</p>
                        <CellTitle info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.status')}:</p>
                        <CellStatus info={info} />
                    </div>
                </Grid>
                <Grid item xs={3.5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>Бренд:</p>
                        <CellBrand info={info} />
                    </div>
                    {/* <div>
                <CellInfo info={info} />
            </div> */}
                    <div>
                        <p className={stls.title}>{t('qwep.orders.total')}</p>
                        <CellQuantity info={info} />
                    </div>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.article')}:</p>
                        <CellArticle info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.price')}:</p>
                        <CellPrice info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.warehouse')}</p>
                        <div style={{ height: '20px' }}>
                            <CellWarehouse info={info} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
