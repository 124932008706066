import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { ColumnDef } from '@tanstack/react-table';
import { CircularProgress, Tooltip } from '@mui/material';
import { ClickAwayListener } from '@mui/material';


export function SuitItems({ suitItems, setIsOpenDetails }: any) {
    const { colors } = useContext(ThemeContext);
    const [mobile] = useState(false);
    const [loading, setLoading] = useState(true);

    const sortedSuitItems = useMemo(() => {
        return [...suitItems].sort((a, b) => a.price - b.price);
    }, [suitItems]);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'Vendor',
                accessorKey: 'Vendor',
                header: `Поставщик`,
                cell: (info) =>
                    info.row.original.vendor.length < 11 ? (
                        <div className={stls.cellVendor}>{info.row.original.vendor}</div>
                    ) : (
                        <Tooltip title={`${info.row.original.vendor}`}>
                            <div className={stls.cellVendor}>{info.row.original.vendor}</div>
                        </Tooltip>
                    ),
                size: 60,
            },
            {
                id: 'Brand',
                accessorKey: 'Brand',
                header: 'Бренд',
                cell: (info) => (
                    <div style={{ color: colors.alpha, fontWeight: 'bold' }} className={stls.cell}>
                        {info.row.original?.brand}
                    </div>
                ),
                size: 60,
            },
            {
                id: 'Article',
                accessorKey: 'Article',
                header: `Артикул`,
                cell: (info) => (
                    <div style={{ color: colors.alpha, fontWeight: 'bold' }} className={stls.cell}>
                        {info.row.original?.article}
                    </div>
                ),
                size: 60,
            },
            {
                id: 'Title',
                accessorKey: 'Title',
                header: `Название`,
                cell: (info) => (
                    <Tooltip title={info.row.original.partname}>
                        <div className={stls.cellTitle}>{info.row.original.partname}</div>
                    </Tooltip>
                ),
                size: 100,
            },
            {
                id: 'Price',
                accessorKey: 'Price',
                header: `Цена`,
                cell: (info) => (
                    <div
                        style={{ border: `0.5px solid ${colors.sea}`, background: 'rgba(33, 142, 225, 0.2)' }}
                        className={stls.cellPrice}>
                        {info.row.original.price.toFixed(2) + ' ₽'}
                    </div>
                ),
                footer: (props) => props.column.id,
                size: 60,
                sortingFn: (a, b) => {
                    const priceA = a.original.price;
                    const priceB = b.original.price;
                    return priceA - priceB;
                },
            },
            {
                id: 'Aviability',
                accessorKey: 'Aviability',
                header: `Количество`,
                cell: (info) => (
                    <div
                        style={{ border: `0.5px solid ${colors.lightgreen}`, background: 'rgba(105, 222, 148, 0.2)' }}
                        className={stls.cellPrice}>
                        {info.row.original.aviability + ' шт.'}
                    </div>
                ),
                size: 60,
            },
            {
                id: 'Delivery',
                accessorKey: 'Delivery',
                header: 'Срок',
                cell: (info) =>
                    info.row.original.delivery?.length > 15 ? (
                        <Tooltip title={info.row.original.delivery}>
                            <div
                                style={{
                                    overflow: 'hidden',
                                    textWrap: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    border: `0.5px solid ${colors.sea}`,
                                    background: 'rgba(33, 142, 225, 0.2)',
                                    justifyContent: 'flex-start',
                                }}
                                className={stls.cellPrice}>
                                {info.row.original.delivery}
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            style={{
                                border: info.row.original.delivery?.length > 0 ? `0.5px solid ${colors.sea}` : '',
                                background: info.row.original.delivery?.length > 0 ? 'rgba(33, 142, 225, 0.2)' : '',
                                justifyContent: 'center',
                            }}
                            className={stls.cellPrice}>
                            {info.row.original.delivery}
                        </div>
                    ),
                size: 60,
            },
            {
                id: 'Warehouse',
                accessorKey: 'Warehouse',
                header: `Склад`,
                cell: (info) => (
                    <div className={stls.cell}>
                        {
                            // info.row.original.status !== '' ?
                            // <>
                            //   <IconCheck />
                            //   <div style={{marginLeft: '5px'}}>
                            //     {info.row.original.warehouse}
                            //   </div>
                            // </>
                            // :
                            info.row.original.warehouse
                        }
                    </div>
                ),
                size: 60,
            },
        ],
        [suitItems.length, suitItems],
    );

    const tableStyles = {
        head: {
            container: {
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // background: colors.deltaBg,
            },
            base: true,
            title: {
                fontSize: '14px',
                textWrap: 'balance',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // background: colors.deltaBg,
            },
            inputFilter: {
                // borderRadius: '7px'
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '40px',
                maxHeight: '40px',
                borderRight: '0.5px solid rgba(71, 85, 105, 1)',
                fontSize: '16px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {suitItems.length && loading ? (
                <div>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <ClickAwayListener onClickAway={() => setIsOpenDetails(false)}>
                        {suitItems.length ? (
                            <div
                                style={{
                                    background: colors.deltaBg,
                                    width: '96%',
                                    margin: '4px 0 4px 2.52%',
                                    padding: '0.5%',
                                    borderRadius: '12px',
                                }}>
                                <VirtualTable
                                    data={sortedSuitItems}
                                    columnsTable={columns}
                                    topFilterPanel={false}
                                    showColumnSearchAction={false}
                                    configHeader={{
                                        fullScrean: true,
                                        exportExcel: false,
                                        resize: true,
                                        grouping: false,
                                        columnFilter: false,
                                        sorting: false,
                                        columnSearch: false,
                                        dragging: true,
                                    }}
                                    styles={tableStyles}
                                    overscan={2}
                                    estimateSize={mobile ? 191 : 39}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </ClickAwayListener>
                </>
            )}
        </>
    );
}
