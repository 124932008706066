import React, { useContext, useState } from 'react';
import Modal from '@mui/material/Modal';
import { MyModal } from '@f_general/MyModal';
import { Button, CardContent, Typography } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/licenses/Licenses.module.sass';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { FormikController } from '@f_general/formik/FormikController';
import { useTranslation } from 'react-i18next';
import { IconFeather } from '@f_components/icons/IconFeather';
import { UserContext } from '@f_context/User_context';
import { choosenTariffQwepCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Alert } from '@f_general/alert';
import CircularProgress from '@mui/material/CircularProgress';

type TypeChoosenProps = {
    open: boolean;
    setOpen?: any;
    defaultOpt: any;
};

export function ChoosenTariff({ open, setOpen, defaultOpt }: TypeChoosenProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { changeTariff } = useContext(QwepContext);
    const { userInfo } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setOpen(false);
    const data = {
        phone: userInfo.phoneNumber ?? '',
        name: userInfo.firstName ?? '',
        tariff: defaultOpt,
        comment: '',
    };

    const sendDataModalHandler = async (values: any) => {
        setLoading(true);
        const result = await changeTariff(values);

        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoading(false);
    };

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <MyModal className={stls.modal}>
                    <CardContent className={stls.modalContent}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconFeather />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {t('licenses.confirmDetails')}
                            </Typography>
                        </div>
                        <Typography sx={{ margin: '16px 0 28px 0' }}>{t('licenses.enterDetails')}</Typography>
                        <FormikWrapper
                            onSubmit={sendDataModalHandler}
                            initialValues={data}
                            validShema={choosenTariffQwepCustomerSchema}>
                            <div className={stls.row}>
                                <div>
                                    <Typography>{t('auth.phone')}*</Typography>
                                    <FormikController
                                        className={stls.modalInput}
                                        type="tel"
                                        control="input"
                                        name="phone"
                                        placeholder="+7 (999) 000-00-00"
                                    />
                                </div>
                                <div>
                                    <Typography>{t('auth.name')}*</Typography>
                                    <FormikController
                                        className={stls.modalInput}
                                        type="text"
                                        control="input"
                                        name="name"
                                        placeholder={t('auth.placeholderName')}
                                    />
                                </div>
                            </div>
                            <div className={stls.row}>
                                <div>
                                    <Typography>{t('licenses.selectedTariff')}*</Typography>
                                    <FormikController
                                        className={stls.modalInput}
                                        control="select"
                                        name="tariff"
                                        defaultValue={defaultOpt}
                                        castomStyle={{
                                            '& .MuiSelect-select': {
                                                padding: '12px',
                                            },
                                        }}
                                        options={[
                                            { key: 'WEB-версия LITE', value: 'WEB-версия LITE' },
                                            { key: 'WEB-версия PRO', value: 'WEB-версия PRO' },
                                            { key: 'WEB-версия PRO+', value: 'WEB-версия PRO+' },
                                        ]}
                                    />
                                </div>
                                <div>
                                    <Typography>{t('licenses.comment')}</Typography>
                                    <FormikController
                                        className={stls.modalInput}
                                        type="text"
                                        control="input"
                                        name="comment"
                                        placeholder={t('licenses.enterComment')}
                                    />
                                </div>
                            </div>
                            <div className={stls.row}>
                                <Button
                                    className={stls.modalInput}
                                    onClick={handleClose}
                                    size="large"
                                    sx={{
                                        padding: '13px',
                                        background: colors.delta,
                                        color: colors.phi,
                                        '&:hover': { color: colors.alpha },
                                    }}>
                                    {t('licenses.cancel')}
                                </Button>
                                <Button
                                    className={stls.modalInput}
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}>
                                    {loading && <CircularProgress size={20} style={{ marginRight: '8px' }} />}
                                    {t('licenses.confirm')}
                                </Button>
                            </div>
                        </FormikWrapper>
                    </CardContent>
                </MyModal>
            </Modal>
        </>
    );
}
