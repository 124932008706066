export enum Field {
    InputField = 'InputField',
    TextField = 'TextField',
    TextareaField = 'TextareaField',
    SelectField = 'SelectField',
    EmailField = 'EmailField',
    NumberField = 'NumberField',
    DateField = 'DateField',
    DatetimeField = 'DatetimeField',
    TimeField = 'TimeField',
    CheckboxField = 'CheckboxField',
    BasketItemCheckboxField = 'BasketItemCheckboxField',
}
