import { useNavigate } from 'react-router-dom';

const useQwepNavigate = () => {
    const navigate = useNavigate();
    return (url = '') => {
        navigate(`/app-qwep/${url}`);
    };
};

export default useQwepNavigate;
