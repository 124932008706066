import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconOneSProps = TypeClassNames;

export const IconOneS = ({ classNames }: TypeIconOneSProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.1} />
                <path
                    d="M16.1454 6.45452H19.5273C18.9818 3.4 16.3636 1 13.0909 1C9.49088 1 6.54544 3.94544 6.54544 7.54544C6.54544 11.0724 9.3727 13.9747 12.8727 14.0909H24V10.8182H13.0909C9.98438 10.7485 8.95064 7.33005 10.6031 5.39739C12.2383 3.49863 15.3295 4.10055 16.1539 6.47819M5.45456 1V14.0909H2.1818V5.36364H0V2.09088H2.1818V1H5.45456Z"
                    stroke={colors.alpha}
                    stroke-width="1.5"
                    transform="translate(13 16)"
                />
            </svg>
        </div>
    );
};
