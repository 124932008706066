import { ThemeContext } from '@f_context/Theme_context';
import { Tooltip } from '@mui/material';
import stls from '@f_qwep/styles/component/settings/Accounts.module.sass';
import React from 'react';

export function CellLogin({ info }: any) {
    const { colors, vinpin } = React.useContext(ThemeContext);

    return (
        <Tooltip title={info.row.original.login}>
            <p style={{ color: colors.alpha }} className={stls.cellLogin}>
                {info.row.original.login == 'qwep' && vinpin ? '***' : info.row.original.login}
            </p>
        </Tooltip>
    );
}
