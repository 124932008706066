import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Property } from '@f_qwep/types';
import { Alert } from '@f_general/alert';
import { Checkbox } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function CellInStock({ resultsId }: any) {
    const { t } = useTranslation();
    const { updateProperty, workspaceApplication, batchPricerInStock } = useContext(QwepContext);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(batchPricerInStock[resultsId] ?? false);
    }, [batchPricerInStock]);

    const handleChange = async (checked: boolean) => {
        let content;
        if (checked) {
            content = {
                value: {
                    ...batchPricerInStock,
                    [resultsId]: checked,
                },
            };
        } else {
            delete batchPricerInStock[resultsId];
            content = {
                value: batchPricerInStock,
            };
        }

        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.BatchPricerInStock,
            value: content,
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        } else {
            Alert({
                type: 'success',
                text: 'Успешно!',
            });
        }
    };

    return (
        <div>
            <Checkbox onChange={(e) => handleChange(e.target.checked)} checked={checked} /> В наличии
        </div>
    );
}
