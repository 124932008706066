import { Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import normaiCSS from '../../styles/component/orders/OrdersPanel.module.sass';
import miniCSS from '../../styles/component/orders/MiniOrdersPanel.module.sass';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useWindowSize } from '@f_hooks';
import { useTranslation } from 'react-i18next';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { ThemeContext } from '@f_context/Theme_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { customSort, CustomSortEnum } from '@f_utils/customSort';
import { IOrderFilterItems } from '@f_qwep/components/orders/types/orderFilters.interface';

interface OrdersPanelProps {
    isVendorOrder?: boolean;
    items: any;
}

export function OrdersPanel({ items, isVendorOrder }: OrdersPanelProps) {
    const { t } = useTranslation();

    const { colors } = useContext(ThemeContext);
    const { miniTableRes } = useContext(QwepContext);

    const stls = miniTableRes ? miniCSS : normaiCSS;

    const { width } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const { orderFiltersReducer, filterOrder, isActiveOrderStatus, isFirstLoadOrder, setisFirstLoadOrder } =
        useContext(FilterContext);

    // Переписал управление фильтрами и сортировку

    const defaultSortEnum = CustomSortEnum.ABC;

    const [brands, setBrands] = useState<string[]>([]);
    const [vendors, setVendors] = useState<string[]>([]);
    const [statuses, setStatuses] = useState<string[]>([]);

    const [sortBrands, setSortBrands] = useState<CustomSortEnum>(defaultSortEnum);
    const [sortVendors, setSortVendors] = useState<CustomSortEnum>(defaultSortEnum);
    const [sortStatuses, setSortStatuses] = useState<CustomSortEnum>(defaultSortEnum);

    useEffect(() => {
        if (isFirstLoadOrder && statuses.length) {
            orderFiltersReducer({
                type: 'status_toggle',
                payload: { triggerActive: true, statuses },
            });
        }
    }, [isFirstLoadOrder]);

    useEffect(() => {
        getFilterItems(items);
    }, [items]);

    const handleSortBrands = () => {
        setSortBrands((prev) => {
            const newSortType = prev === defaultSortEnum ? CustomSortEnum.DESC : defaultSortEnum;
            setBrands((prevBrands) =>
                customSort({
                    list: prevBrands,
                    sortType: newSortType,
                }),
            );
            return newSortType;
        });
    };

    const handleSortVendors = () => {
        setSortVendors((prev) => {
            const newSortType = prev === defaultSortEnum ? CustomSortEnum.DESC : defaultSortEnum;
            setVendors((prevVendors) =>
                customSort({
                    list: prevVendors,
                    sortType: newSortType,
                }),
            );
            return newSortType;
        });
    };

    const handleSortStatuses = () => {
        setSortStatuses((prev) => {
            const newSortType = prev === defaultSortEnum ? CustomSortEnum.DESC : defaultSortEnum;
            setStatuses((prevStatuses) =>
                customSort({
                    list: prevStatuses,
                    sortType: newSortType,
                }),
            );
            return newSortType;
        });
    };

    const getFilterItems = (items: any[]) => {
        if (items.length) {
            const filterItems = items.reduce(
                (result: IOrderFilterItems, item: any) => {
                    result.brands.push(item.brand);
                    result.vendors.push(item.vendorTitle);
                    result.statuses.push(item.status);
                    return result;
                },
                { brands: [], vendors: [], statuses: [] },
            );

            const uniqFilterItems: IOrderFilterItems = {
                brands: Array.from(new Set(filterItems.brands)),
                vendors: Array.from(new Set(filterItems.vendors)),
                statuses: Array.from(new Set(filterItems.statuses)),
            };

            setBrands(
                customSort({
                    list: uniqFilterItems.brands,
                    sortType: sortBrands,
                }),
            );

            setVendors((prev) =>
                customSort({
                    list: uniqFilterItems.vendors,
                    sortType: sortVendors,
                }),
            );

            setStatuses((prev) =>
                customSort({
                    list: uniqFilterItems.statuses,
                    sortType: sortStatuses,
                }),
            );

            setisFirstLoadOrder(true);
        }
    };

    const Brands = () => {
        return (
            <>
                {brands.map((brand: string, index) => (
                    <div key={index}>
                        <input
                            onChange={() => {
                                orderFiltersReducer({ type: 'brands_toggle', payload: { brand } });
                            }}
                            className={stls.checkbox}
                            type="checkbox"
                            checked={filterOrder?.brands?.includes(brand)}
                            id={brand}
                            name={brand}
                        />
                        <label htmlFor={brand} className={stls.checkboxLabel}>
                            {brand?.length < 10 ? (
                                brand
                            ) : (
                                <Tooltip placement="top" title={brand}>
                                    <span className={stls.lable}>{brand}</span>
                                </Tooltip>
                            )}
                        </label>
                    </div>
                ))}
            </>
        );
    };

    const Vendors = () => {
        return (
            <>
                {vendors.map((vendor: string, index) => (
                    <div key={index}>
                        <input
                            onChange={() => {
                                orderFiltersReducer({ type: 'vendors_toggle', payload: { vendor } });
                            }}
                            className={stls.checkbox}
                            checked={filterOrder?.vendorTitle?.includes(vendor)}
                            type="checkbox"
                            id={vendor}
                            name={vendor}
                        />
                        <label htmlFor={vendor} className={stls.checkboxLabel}>
                            {vendor?.length < 10 ? (
                                vendor
                            ) : (
                                <Tooltip placement="top" title={vendor}>
                                    <span className={stls.lable}>{vendor}</span>
                                </Tooltip>
                            )}
                        </label>
                    </div>
                ))}
            </>
        );
    };

    const Statuses = () => {
        return (
            <>
                {statuses.map((status: string, index) => (
                    <div key={index}>
                        <input
                            onChange={() => {
                                orderFiltersReducer({
                                    type: 'status_toggle',
                                    payload: { status, triggerActive: false },
                                });
                            }}
                            className={stls.checkbox}
                            checked={filterOrder.status.includes(status)}
                            type="checkbox"
                            id={status}
                            name={status}
                        />
                        <label htmlFor={status} className={stls.checkboxLabel}>
                            {status?.length < 10 ? (
                                status
                            ) : (
                                <Tooltip placement="top" title={status}>
                                    <span className={stls.lable}>{status}</span>
                                </Tooltip>
                            )}
                        </label>
                    </div>
                ))}
            </>
        );
    };

    return (
        <div id="filterOrderPanel" className={stls.listNav}>
            <div className={stls.listItemNav}>
                <h3> {t('qwep.orders.orders')} </h3>
            </div>

            {!isVendorOrder && (
                <>
                    <div className={stls.listItemNav}>
                        <h4>{t('qwep.orders.brands')}</h4>
                        <div className={stls.icon} onClick={handleSortBrands}>
                            {sortBrands === CustomSortEnum.ABC ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </div>
                    </div>
                    <div id="ordersBrands" className={stls.listItemCheck}>
                        <Brands />
                    </div>
                </>
            )}

            <div className={stls.listItemNav}>
                <h4>{t('qwep.orders.vendors')}</h4>
                <div className={stls.icon} onClick={handleSortVendors}>
                    {sortVendors === CustomSortEnum.ABC ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </div>
            </div>
            <div id="ordersVendors" className={stls.listItemCheck}>
                <Vendors />
            </div>

            <div className={stls.listItemNav}>
                <h4>{t('qwep.orders.status')}</h4>
                <div className={stls.icon} onClick={handleSortStatuses}>
                    {sortStatuses === CustomSortEnum.ABC ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </div>
            </div>
            <div id="activeOrder" className={stls.listItemCheck}>
                <input
                    onChange={() => {
                        orderFiltersReducer({
                            type: 'status_toggle',
                            payload: { triggerActive: true, statuses },
                        });
                    }}
                    id="activestatus"
                    name="activestatus"
                    className={stls.checkbox}
                    checked={isActiveOrderStatus}
                    type="checkbox"
                />
                {items?.length > 0 && (
                    <label
                        htmlFor="activestatus"
                        className={stls.checkboxLabel}
                        style={{ fontWeight: '900', fontSize: '16px' }}>
                        Активные
                    </label>
                )}
            </div>
            <div id="ordersStatus" className={stls.listItemCheck}>
                <Statuses />
            </div>

            <div className={stls.listItemNav}>
                <h4>{t('qwep.orders.date')}</h4>
            </div>
            <div id="ordersDate" className={stls.listItemCheck}>
                <input
                    style={{ color: colors.omicron }}
                    type="date"
                    value={filterOrder.minDate}
                    onChange={({ target }) => {
                        orderFiltersReducer({ type: 'minDate_input', payload: { minDate: target.value } });
                    }}
                />
                <input
                    style={{ color: colors.omicron }}
                    type="date"
                    value={filterOrder.maxDate}
                    onChange={({ target }) => {
                        orderFiltersReducer({ type: 'maxDate_input', payload: { maxDate: target.value } });
                    }}
                />
            </div>
        </div>
    );
}
