import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import { useContext } from 'react';
import { QwepContext } from '../../../context/Qwep_context';
import { IconPrice } from '../../icons/IconPrice';
import { IconApi } from '../../icons/IconApi';
import { IconPromo } from '../../icons/IconPromo';
import { AccountsContext } from '@f_qwep/context/Accounts_context';

export function CellVendor({ info }: any) {
    const { hideVendorIsActive, priceMarginIsActive } = useContext(QwepContext);
    const { checkForPromoMatchById } = useContext(AccountsContext);

    const vendorStyle = hideVendorIsActive && priceMarginIsActive ? stls.cellHideVendor : stls.cellVendor;

    const convertData = () => {
        if (hideVendorIsActive && priceMarginIsActive) {
            return { name: '—', icon: '' };
        } else if (info.row.original.vendorTitle.includes('(price)')) {
            return { name: info.row.original.vendorTitle.replace('(price)', ''), icon: <IconPrice /> };
        } else if (info.row.original.vendorTitle.includes('(API)')) {
            return { name: info.row.original.vendorTitle.replace('(API)', ''), icon: <IconApi /> };
        } else {
            return { name: info.row.original.vendorTitle, icon: '' };
        }
    };

    const getIconVendorPromoClass = () => {
        return convertData().icon === '' ? stls.iconVendorPromoNonCombo : stls.iconVendorPromo;
    };

    return (
        <div className={stls.vendorContainer}>
            <p
                className={vendorStyle}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {convertData().name}{' '}
            </p>
            <span className={stls.iconVendor}>
                {checkForPromoMatchById(info.row.original.accountId) && (
                    <span className={getIconVendorPromoClass()}>
                        <IconPromo />
                    </span>
                )}
                {convertData().icon}
            </span>
        </div>
    );
}
