import { useContext, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import stls from '@f_styles/components/drawer/GeneralDrawer.module.sass';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { DrawerList } from '@f_components/drawer/DrawerList';
import { IconCloseSettings } from '@f_components/icons/IconCloseSettings';
import { IconOpenettings } from '@f_components/icons/IconOpenSettings';
import { GeneralContext } from '@f_context/General_context';
import { SupportContext } from '@f_context/Support_context';
import { ThemeContext } from '@f_context/Theme_context';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {},
});

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    top: 10,
    left: -3,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export function GeneralDrawer() {
    const { colors } = useContext(ThemeContext);
    const { active, setActive } = useContext(GeneralContext);
    const { countNewMessage } = useContext(SupportContext);

    const handleDrawerActive = () => {
        setActive(!active);
    };

    return (
        <Box className={stls.container}>
            <AppBar onClick={handleDrawerActive} position="fixed" open={active}>
                <button id="drawerButton" style={{ position: 'fixed' }}>
                    {countNewMessage !== 0 && (
                        <span className={stls.countNewMessage} style={{ background: colors.lambda }}>
                            <span className={stls.count}>{countNewMessage}</span>
                        </span>
                    )}
                    {active ? <IconCloseSettings /> : <IconOpenettings />}
                </button>
            </AppBar>
            <Drawer variant="permanent" open={active}>
                <DrawerList />
            </Drawer>
        </Box>
    );
}
