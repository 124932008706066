import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconEditProps = TypeClassNames;

export const IconEdit = ({ classNames }: TypeIconEditProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1286_4576)">
                    <rect width="36" height="36" rx="18" fill={colors.delta} />
                    <path
                        d="M21.728 15.6861L20.314 14.2721L11 23.5861V25.0001H12.414L21.728 15.6861ZM23.142 14.2721L24.556 12.8581L23.142 11.4441L21.728 12.8581L23.142 14.2721ZM13.242 27.0001H9V22.7571L22.435 9.32208C22.6225 9.13461 22.8768 9.0293 23.142 9.0293C23.4072 9.0293 23.6615 9.13461 23.849 9.32208L26.678 12.1511C26.8655 12.3386 26.9708 12.5929 26.9708 12.8581C26.9708 13.1232 26.8655 13.3776 26.678 13.5651L13.243 27.0001H13.242Z"
                        fill={colors.phi}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1286_4576">
                        <rect width="36" height="36" rx="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
