import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconResultsSearchProps = TypeClassNames;

export const IconResultsSearch = ({ classNames }: TypeIconResultsSearchProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.033 14.618L20.313 18.899L18.899 20.314L14.619 16.033C13.0248 17.3097 11.0424 18.0037 9 18C7.32335 18.0007 5.67984 17.533 4.25482 16.6496C2.82981 15.7661 1.67997 14.5021 0.935001 13H7L5.696 15.173C6.71203 15.7176 7.84723 16.0017 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 8.305 15.899 7.634 15.71 7H17.777C17.923 7.643 18 8.313 18 9C18.0035 11.0421 17.3095 13.0241 16.033 14.618ZM17.065 5H11L12.304 2.827C11.288 2.28243 10.1528 1.99828 9 2C5.132 2 2 5.132 2 9C2 9.695 2.101 10.366 2.29 11H0.223001C0.0744486 10.3437 -0.000348357 9.67289 1.21968e-06 9C1.21968e-06 4.027 4.027 8.81538e-07 9 8.81538e-07C10.6767 -0.000741157 12.3202 0.466991 13.7452 1.35045C15.1702 2.23391 16.32 3.49794 17.065 5Z"
                fill={colors.alpha}
            />
        </svg>
        // </div>
    );
};
