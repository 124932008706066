import { useLocalStorage } from '@f_hooks';
import { itemLanguage } from '@f_utils/language';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './Auth_context';
import { UserContext } from './User_context';
import { WorkspaceContext } from './Workspace_context';
import { ThemeContext } from './Theme_context';
import { boolean } from 'yup';

type TypeUserProperty = {
    propertyName: string;
    sysName: string;
    userPropertyId: number;
    userPropertyValueId: number;
    userPropertyValueIsActive: boolean;
    value: {
        value?: any;
    };
};

type TypeGetCallMe = {
    tel: string;
    name: string;
    comment: string;
};

const GeneralContext = createContext<any>({});

function GeneralProvider(props: any) {
    // const { vinpin } = useContext(ThemeContext)
    const { checkAuth, changeWorkspace, accountInfo } = useContext(AuthContext);
    const { getUsersToWorkspace, setUserRole } = useContext(UserContext);
    const { getInfoWorkspaces, setIndexWorkspace } = useContext(WorkspaceContext);
    const [drawerPanels, setDrawerPanels] = useState([]);
    const [currentPanel, setCurrentPanel] = useLocalStorage('curentPanel', '');
    const [drawerWorkspaces, setDrawerWorkspaces] = useState([]);
    const [currentDrawerWorkspace, setCurrentDrawerWorkspace] = useState({});

    // drawer panel
    const [active, setActive] = useState(false);

    //searchBase
    const [searchBaseContent, setSearchBaseContent] = useState('');

    const [reloadQwep, setReloadQwep] = useState(false);

    const [language, setLanguage] = useLocalStorage('language', '');
    const [colorTheme, setColorTheme] = useLocalStorage('colorTheme', '');

    //Поиск неавторизованных поставщиков
    const [isRunAssistantVendorSearch, seIsRunAssistantVendorSearch] = useState<boolean>(false);
    const [assistantVendor, setAssistantVendor] = useState<string>('');

    useEffect(() => {
        const userLang = getBrowserLanguage();
        itemLanguage(language ? language : userLang);
    }, []);

    const getBrowserLanguage = () => {
        const cisLanguages = ['ru', 'be', 'uk', 'kk', 'az', 'ky', 'tg', 'hy', 'tt'];
        let lang = (navigator.languages && navigator.languages[0]) || navigator.language;
        lang = lang.split('-')[0];
        if (cisLanguages.indexOf(lang) !== -1) {
            lang = 'ru';
        } else {
            lang = 'en';
        }
        return String(lang);
    };

    useEffect(() => {
        if (checkAuth && accountInfo) {
            getDrawerPanels();
        }

        if ([language, colorTheme].some((item: any) => !item)) {
            getUserProperty();
        }

        if (checkAuth && accountInfo?.drawerWorkspaces) {
            setDrawerWorkspaces(accountInfo.drawerWorkspaces);
            setCurrentDrawerWorkspace(accountInfo.currentDrawerWorkspace);
        }
    }, [checkAuth, accountInfo]);

    const getCallMe = async (data: TypeGetCallMe) => {
        const result = await callPlatformAPI('support/callme', 'post', data);
        return result.data;
    };

    const getDrawerPanels = async () => {
        if (checkAuth) {
            const result = await callPlatformAPI('apps/panels/get', 'get');

            if (result.data.isOk) {
                setDrawerPanels(result.data.result);
            }
            console.log('getDrawerPanels', result);
        }
    };

    const getUserProperty = async () => {
        if (checkAuth) {
            const result = await callPlatformAPI('user/property/get', 'get');
            if (result.data.isOk) {
                result.data.result.map((item: TypeUserProperty) => {
                    switch (item.sysName) {
                        case 'lang':
                            itemLanguage(item.value.value);
                            setLanguage(item.value.value);
                            break;
                        case 'platform_theme':
                            if (window.location.hostname.includes('vinpin')) {
                                setColorTheme('vinpin');
                            } else {
                                setColorTheme(item.value.value);
                            }
                            break;
                    }
                });
            }
        }
    };

    const updateUserProperty = async (data: any) => {
        const result = await callPlatformAPI('user/property/update', 'put', data);
        if (result.data.isOk) {
            getUserProperty();
        }
    };

    const changeCompany = async (workspaceId: number) => {
        setReloadQwep(true);
        const result = await changeWorkspace(workspaceId);
        if (result.isOk) {
            getDrawerPanels();
            setDrawerWorkspaces(result.result.account.drawerWorkspaces);
            setCurrentDrawerWorkspace(result.result.account.currentDrawerWorkspace);
            setUserRole(result.result.account.role);
            getUsersToWorkspace();
            getInfoWorkspaces();
            setIndexWorkspace(workspaceId);
        }
        setReloadQwep(false);
    };

    return (
        <GeneralContext.Provider
            value={{
                drawerPanels,
                currentPanel,
                language,
                colorTheme,
                drawerWorkspaces,
                currentDrawerWorkspace,
                active,
                searchBaseContent,

                //methods
                setActive,
                updateUserProperty,
                setCurrentPanel,
                changeCompany,
                getCallMe,
                reloadQwep,
                getDrawerPanels,
                getUserProperty,
                setSearchBaseContent,

                // Поиск неавторизованных поставщиков
                isRunAssistantVendorSearch,
                seIsRunAssistantVendorSearch,
                assistantVendor,
                setAssistantVendor,
            }}
            {...props}
        />
    );
}

export { GeneralProvider, GeneralContext };
