import { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '@f_context/General_context';
import { Route, Routes } from 'react-router-dom';
import { CrossdataProviders } from './context';
import { Home } from './components/home';
import { Report } from './components/report';
import { ExportExcelModal } from './general/ExportExcelModal';
import { HeaderComponent } from './components/header';
import { Subscription } from './components/licenses';

export function AppCrossdata() {
    const { reloadQwep } = useContext(GeneralContext);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (reloadQwep) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [reloadQwep]);

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <CrossdataProviders>
                    <HeaderComponent />
                    <Routes>
                        <Route path={'/'} element={<Home />} />
                        <Route path={'/report'} element={<Report />} />
                        <Route path={'/subscription'} element={<Subscription />} />
                    </Routes>
                    <ExportExcelModal />
                </CrossdataProviders>
            )}
        </>
    );
}
