export const IconCheck = () => {
    //   const { colors } = useContext(ThemeContext)
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V14C20 14.2652 19.8946 14.5196 19.7071 14.7071C19.5196 14.8946 19.2652 15 19 15H15V18.993C15 19.549 14.551 20 13.993 20H1.007C0.874722 20.0001 0.743717 19.9742 0.621483 19.9236C0.499248 19.8731 0.388185 19.7989 0.294651 19.7053C0.201116 19.6118 0.126946 19.5008 0.0763858 19.3785C0.0258259 19.2563 -0.000131057 19.1253 4.9756e-07 18.993L0.00300052 6.007C0.00300052 5.451 0.452 5 1.01 5H5ZM7 5H13.993C14.549 5 15 5.449 15 6.007V13H18V2H7V5ZM13 7H2.003L2 18H13V7ZM6.503 16L2.967 12.464L4.381 11.05L6.503 13.172L10.745 8.929L12.159 10.343L6.503 16Z"
                fill="#3B82F6"
            />
        </svg>
    );
};
