import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTasks.module.sass';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import { InputGetShortNumbers } from '../InputGetShortNumbers';

export function HeadTitle() {
    const { t } = useTranslation();

    return (
        <div className={stls.headTitle}>
            <span>{t('qwep.batchPricer.article')}</span>
            <span style={{ width: '580px' }}>{t('qwep.batchPricer.brand')}</span>
        </div>
    );
}

export function CellTitle({ info, openEdit, setEditTask }: any) {
    const { colors } = useContext(ThemeContext);
    const [openEditItem, setOpenEditItem] = useState(false);

    const clickHandler = (option: any) => {
        setEditTask((prev: any) => [
            ...prev,
            {
                ...info.row.original,
                article: option.article,
                brand: option.brand,
            },
        ]);
    };

    return (
        <div className={stls.cellTitle}>
            {openEditItem ? (
                <div style={{ display: 'flex' }}>
                    <InputGetShortNumbers
                        styles={{
                            width: 570,
                            '& .MuiInputBase-root': {
                                padding: '5px 9px 5px 9px !important',
                            },
                        }}
                        actionHandler={clickHandler}
                        defaultValue={`${info.row.original.article} ${info.row.original.brand}`}
                        inputVal={info.row.original.article}
                    />
                    <button onClick={() => setOpenEditItem(false)}>
                        <CloseIcon />
                    </button>
                </div>
            ) : (
                <>
                    <p className={stls.cellArticle}>{info.row.original.article}</p>
                    <p className={stls.cellBrand} style={{ color: colors.alpha }}>
                        {info.row.original.brand}
                    </p>
                    {openEdit && (
                        <button className={stls.editBtn} onClick={() => setOpenEditItem(true)}>
                            <BorderColorIcon />
                        </button>
                    )}
                </>
            )}
        </div>
    );
}
