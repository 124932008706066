import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconSearchProps = TypeClassNames;

export const IconSearch = ({ classNames }: TypeIconSearchProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <path
                    d="M30.031 28.617L34.314 32.899L32.899 34.314L28.617 30.031C27.0237 31.3082 25.042 32.0029 23 32C18.032 32 14 27.968 14 23C14 18.032 18.032 14 23 14C27.968 14 32 18.032 32 23C32.0029 25.042 31.3082 27.0237 30.031 28.617ZM28.025 27.875C29.2941 26.5699 30.0029 24.8204 30 23C30 19.132 26.867 16 23 16C19.132 16 16 19.132 16 23C16 26.867 19.132 30 23 30C24.8204 30.0029 26.5699 29.2941 27.875 28.025L28.025 27.875Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
