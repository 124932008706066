import {
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Checkbox,
    FormGroup,
    Typography,
} from '@mui/material';
import { ErrorMessage, useField } from 'formik';

import React from 'react';
import { ThemeContext } from '@f_context/Theme_context';

type TypeElementProps = {
    variant?: any;
    name: string;
    label?: string;
    options?: any;
    option?: any;
    disabled?: boolean;
    helperText?: string;
    defaultValue?: any;
    error?: boolean;
    multiline?: boolean;
    maxRows?: number;
    rows?: number;
    size?: any;
    fullWidth?: boolean;
    displayEmpty?: boolean;
    required?: boolean;
    row?: boolean;
    color?: any;
    labelPlacement?: any;
    className?: any;
    InputProps?: any;
    inputProps?: any;
    placeholder?: any;
    onChange?: any;
    checked?: any;
    value?: any;
    castomStyle?: any;
    castomFormControl?: any;
    validationWarningIndent?: string;
};

export function Input(props: TypeElementProps) {
    const [field, meta] = useField(props);

    const { colors } = React.useContext(ThemeContext);

    const {
        name,
        label,
        variant,
        disabled,
        helperText,
        defaultValue,
        error,
        multiline,
        maxRows,
        rows,
        size,
        fullWidth,
        required,
        className,
        castomStyle,
        InputProps,
        inputProps,
        placeholder,
        validationWarningIndent,
        ...rest
    } = props;

    const warningIndent = validationWarningIndent ?? '-13px';

    return (
        <div style={{ position: 'relative' }}>
            <TextField
                required={required}
                variant={variant}
                label={label}
                disabled={disabled}
                helperText={helperText}
                defaultValue={defaultValue}
                error={error}
                multiline={multiline}
                maxRows={maxRows}
                rows={rows}
                size={size}
                fullWidth={fullWidth}
                className={className}
                InputProps={InputProps}
                inputProps={inputProps}
                placeholder={placeholder}
                sx={{
                    input: {
                        '&:-webkit-autofill': {
                            '-webkit-text-fill-color': `${colors.omicron}`,
                        },
                    },
                    '& .MuiInputBase-root': {
                        '& fieldset': {
                            borderColor: meta.touched ? (meta.error ? colors.lambda : colors.gamma) : colors.alpha,
                            borderRadius: '12px',
                        },
                        '&:hover fieldset': {
                            border: `1px solid ${colors.xi} !important`,
                        },
                        '&:Mui-hover + fieldset': {
                            border: `1px solid ${colors.xi} !important`,
                        },
                        '&.Mui-focused fieldset': {
                            border: `1px solid ${colors.xi}`,
                        },
                        '&.Mui-disabled fieldset': {
                            border: `1px solid ${colors.pi}`,
                        },
                    },
                    '& .MuiInputBase-input': {
                        fontFamily: 'Inter-Regular',
                        fontSize: 14,
                        padding: '0px 15px',
                        height: '50px',
                        width: '100%',
                        color: colors.omicron,
                    },
                    ...castomStyle,
                }}
                {...rest}
                {...field}
            />
            <Typography
                variant="body2"
                style={{ color: colors.lambda, position: 'absolute', bottom: warningIndent, fontSize: '10px' }}>
                <ErrorMessage name={name} />
            </Typography>
        </div>
    );
}

export function Selecting(props: TypeElementProps) {
    const { colors, CustomSelect } = React.useContext(ThemeContext);
    const [field, meta] = useField(props);

    const {
        label,
        name,
        options,
        fullWidth,
        variant,
        displayEmpty,
        size,
        disabled,
        error,
        required,
        color,
        castomStyle,
        castomFormControl,
        defaultValue,
        className,
        onChange,
        value,
        ...rest
    } = props;

    return (
        <FormControl sx={props.castomFormControl}>
            <InputLabel>{label}</InputLabel>
            <Select
                id={name}
                displayEmpty={displayEmpty}
                input={<CustomSelect />}
                className={className}
                defaultValue={defaultValue}
                sx={props.castomStyle}
                // value={value}
                {...field}
                {...rest}
                MenuProps={{
                    sx: {
                        '&& .MuiMenuItem-root': {
                            fontSize: 14,
                            fontFamily: 'Inter',
                            color: colors.omicron,
                            '&:hover': {
                                background: colors.beta,
                            },
                        },
                    },
                }}>
                {options?.map((option: any) => {
                    return (
                        <MenuItem id={option.name ?? option.value} key={option.key ?? option.text} value={option.value}>
                            {option.key ?? option.text}
                        </MenuItem>
                    );
                })}
            </Select>
            <Typography variant="body2" style={{ color: colors.lambda }}>
                <ErrorMessage name={name} />
            </Typography>
        </FormControl>
    );
}

export function RadioButtons(props: TypeElementProps) {
    const { label, name, options, row, size, color, labelPlacement, value, className, ...rest } = props;
    return (
        <div>
            <FormControl>
                <FormLabel>{label}</FormLabel>
                <RadioGroup row={row} name={name} className={className} value={value} {...rest}>
                    {options.map((option: any) => {
                        return (
                            <FormControlLabel
                                labelPlacement={labelPlacement}
                                key={option.key}
                                control={<Radio color={color} size={size} />}
                                label={option.key}
                                checked={option.checked}
                                defaultValue={option.defaultValue}
                                defaultChecked={option.defaultChecked}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
            <div>
                <ErrorMessage name={name} />
            </div>
        </div>
    );
}

export function CheckBoxes(props: TypeElementProps) {
    const [field] = useField(props);
    const { label, name, options, className, checked, defaultValue, castomStyle, ...rest } = props;

    return (
        <div style={castomStyle}>
            <FormGroup>
                <FormLabel> {label} </FormLabel>
                {options.map((option: any) => {
                    return (
                        <FormControlLabel
                            color={option.color}
                            size={option.size ?? 'smail'}
                            key={option.key}
                            label={option.key}
                            value={option.value}
                            disabled={option.disabled}
                            labelPlacement={option.labelPlacement}
                            control={<Checkbox disableRipple {...field} size={option.size ?? 'small'} />}
                            className={className}
                            {...rest}
                        />
                    );
                })}
            </FormGroup>
            <div>
                <ErrorMessage name={name} />
            </div>
        </div>
    );
}
