import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricer.module.sass';
import { IconAddPrice } from '@f_qwep/components/icons/IconAddPrice';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TableProps {
    maxRows: number;
    colors: any;
    headers: string[];
    rows: object[];
}

const StyledModalTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 28px;
`;

const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
`;

const StyledTable = styled.table`
    border-collapse: separate;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
`;

const StyledThead = styled.thead`
    border-radius: 12px;
`;

const StyledTh = styled.th<{ colors: any }>`
    background-color: ${(props) => props.colors.beta};
    color: ${(props) => props.colors.omicron};
    padding: 5px;
    text-align: start;
    font-size: 1em;
    font-weight: bolder;
`;

const StyledTr = styled.tr<{ colors: any }>`
    background-color: ${(props) => props.colors.psi};
`;

const StyledTd = styled.td<{ colors: any }>`
    border: 4px solid ${(props) => props.colors.beta};
    border-color: ${(props) => props.colors.beta};
    padding: 5px;
    text-align: start;
    font-size: 0.9em;
    font-weight: bold;
    overflow: hidden;
    color: ${(props) => props.colors.alpha};
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
`;

export const PreviewDownloadedFileTable: FC<TableProps> = observer(({ maxRows, colors, headers, rows }) => {
    const { t } = useTranslation();

    const limitedRows = maxRows ? rows.slice(0, maxRows) : rows;

    return (
        <>
            <StyledModalTitle className={stls.modalTitle}>
                <IconAddPrice />
                <Typography variant="h6" sx={{ marginLeft: '12px' }}>
                    {t('qwep.previewTable.title')}
                </Typography>
            </StyledModalTitle>
            <StyledWrapper>
                <StyledTable>
                    <StyledThead>
                        <tr>
                            {headers.map((header) => (
                                <StyledTh key={header} colors={colors}>
                                    {header}
                                </StyledTh>
                            ))}
                        </tr>
                    </StyledThead>
                    <tbody>
                        {limitedRows.map((row: Array<string | number>, rowIndex) => (
                            <StyledTr key={rowIndex} colors={colors}>
                                {row.map((cell, cellIndex) => (
                                    <StyledTd key={cellIndex} colors={colors}>
                                        {cell}
                                    </StyledTd>
                                ))}
                            </StyledTr>
                        ))}
                    </tbody>
                </StyledTable>
            </StyledWrapper>
        </>
    );
});
