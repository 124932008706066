import GeneralModal from '@f_general/GeneralModal';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { IconClose } from '../../../../../components/icons/IconClose';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { IconDelete } from '@f_components/icons/IconDelete';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { Alert } from '@f_general/alert';
import { Property } from '@f_qwep/types';
import { QwepContext } from '@f_qwep/context/Qwep_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function CellDelete({ info }: any) {
    const { t } = useTranslation();
    const { deleteTask } = useContext(BatchPricerContext);
    const { updateProperty, workspaceApplication, batchPricerStrictTasks } = useContext(QwepContext);
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    const handleModalClose = () => {
        setOpen(false);
        setDataModal({});
    };

    const clickModalHandler = () => {
        setDataModal({
            icon: <IconDelete />,
            title: `${t('qwep.batchPricer.wantDelete')} ${info.row.original.taskName}`,
            props: [],
            shema: null,
            type: 'deleteTasks',
            data: {
                taskId: info.row.original.taskId,
            },
        } as TypeDataModal);
        setOpen(true);
    };

    const sendDataModalHandler = async (values: any) => {
        if (values.taskId) {
            const result = await deleteTask(values.taskId, info.row.original.resultsId);
            if (result.isOk) {
                handleModalClose();
                delete batchPricerStrictTasks[info.row.original.resultsId];
                let content = {
                    value: batchPricerStrictTasks,
                };
                updateProperty({
                    applicationWorkspaceId: workspaceApplication,
                    propertyName: Property.BatchPricerStrictMode,
                    value: content,
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        }
    };

    return (
        <>
            <button className={stls.cellDelete} onClick={clickModalHandler}>
                <IconClose />
            </button>
            <GeneralModal
                openModal={open}
                closeModal={handleModalClose}
                dataModal={dataModal}
                sendDataModalHandler={sendDataModalHandler}
            />
        </>
    );
}
