import { Formik, Form, FormikHelpers } from 'formik';
import { Typography } from '@mui/material';
import { TypeChildren } from '@f_types';

type TypeFormikWrapperProps = {
    title?: any;
    icon?: any;
    onSubmit?: any;
    initialValues?: any;
    validShema?: any;
    handleOnChange?: any;
} & TypeChildren;

export function FormikWrapper(props: TypeFormikWrapperProps) {
    const { icon, title, children, onSubmit, initialValues, validShema, handleOnChange } = props;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validShema}
            onSubmit={(values, formikHelpers) => {
                onSubmit(values, formikHelpers);
            }}>
            {(formik) => (
                <Form
                    onChange={handleOnChange}
                    noValidate
                    style={{ display: 'flex', flexDirection: 'column' }}
                    onSubmit={formik.handleSubmit}>
                    {(icon || title) && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {icon && <div style={{ marginRight: '16px' }}>{icon}</div>}
                            <Typography gutterBottom variant="h6" component="div">
                                {title}
                            </Typography>
                        </div>
                    )}
                    {children}
                </Form>
            )}
        </Formik>
    );
}
