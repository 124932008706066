import { GeneralContext } from '@f_context/General_context';
import { useLocalStorage } from '@f_hooks';
import { exportExcelFetch } from '@f_utils/exportExcelFetch';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Property, Tariff } from '../types';
import { UserContext } from '@f_context/User_context';
import { Apps } from '@f_types';
import useQwepNavigate from '../hooks/useQwepNavigate';
import { assistantListStore } from '@f_qwep/components/purchasingAssistant/model/stores/index.store';

type TypeUpdateProperty = {
    applicationWorkspaceId: number;
    propertyName: string;
    value: object;
};

type TypeChangeTariff = {
    phone: string;
    name: string;
    tariff: string;
    comment: string;
};

type TypeExportExcel = {
    type: string;
    data: any;
    title: string;
};

export type TypeAssistantConfigList = {
    id: number;
    name: string;
    locations: string[];
    withPriceDeviation: number;
    withSearchPatternMonthsDepth: number;
    withApprovalDaysDepth: number;
    active: boolean;
};

export type TypeAssistantCustomList = {
    refKey: string;
    title: string;
    private: boolean;
    parentId: number;
    key1c: string[] | false;
};

export type TypeCreate1cScanScan = {
    file: File;
    title: string;
    createGroup: boolean;
};

export type TypePromoNotification = {
    title: string;
    active: boolean;
};

const QwepContext = createContext<any>({});

function QwepProvider(props: any) {
    const navigate = useQwepNavigate();
    const { drawerPanels } = useContext(GeneralContext);
    const { users } = useContext(UserContext);
    const currentLocation = useLocation();
    const [workspaceApplication, setWorkspaceApplication] = useState(-1);
    const [tariff, setTariff] = useState<string>('');
    const [excludePromo, setExcludePromo] = useLocalStorage('excludePromo', false);
    const [manualState, setManualState] = useState<any>();
    const [priceMarginState, setPriceMarginState] = useState<any>();
    const [groupsAccountsState, setGroupsAccountsState] = useState<any>();
    const [groupsAccountsIsActive, setGroupsAccountsIsActive] = useState(false);
    const [priceMarginIsActive, setPriceMarginIsActive] = useState(false);
    const [hideVendorIsActive, setHideVendorIsActive] = useState(false);
    const [batchPricerStrictTasks, setBatchPricerStrictTasks] = useState({});
    const [batchPricerInStock, setBatchPricerInStock] = useState({});
    const [assistantConfigList, setAssistantConfigList] = useState<TypeAssistantConfigList[]>([]);
    const [assistantCustomList, setAssistantCustomList] = useState<TypeAssistantCustomList[]>([]);
    const [assistantEmailList, setAssistantEmailList] = useState<string[]>([]);
    const [priceCrossesCheck, setPriceCrossesCheck] = useState<boolean>(true);
    const [columnVisibility, setColumnVisibility] = useState<any>({ 'Склад Поставщика': false });
    const [promoNotification, setPromoNotification] = useState<TypePromoNotification[] | null>(null);

    //add settings
    const [miniTableRes, setMiniTableRes] = useState(false);
    //Фильтр Вид настроек
    const [filter1C, setFilter1C] = useState(false);
    //licenses
    const [workPeriod, setWorkPeriod] = useState('');
    const [activeApp, setActiveApp] = useState(true);
    const [blocked, setBlocked] = useState(false);
    const [applicationInfoLoading, setApplicationInfoLoading] = useState(false);
    const [receivedResponse, setReceivedResponse] = useState(false);
    //export excel
    const [openExportModal, setOpenExportModal] = useState(false);
    const [typeExport, setTypeExport] = useState();
    const [exportData, setExportData] = useState();
    //notifications
    const [notifications, setNotifications] = useState({});
    //basket order draft
    const [orderDraft, setOrderDraft] = useState<any>();
    const [orderDraftIsActive, setOrderDraftIsActive] = useState(false);
    // filters draft search
    const [draftFilterSearch, setDraftFilterSearch] = useState<any>([]);
    const [draftFilterIsActive, setdraftFilterIsActive] = useState<boolean>(false);
    // qwep crosses
    const [qwepCrosses, setQwepCrosses] = useState<boolean>(false);
    // congradulations for ded
    const [congratulationOpenModal, setCongratulationOpenModal] = useState(false);

    const { setAssistantLists } = assistantListStore;

    type GetFiltersBatchPricerTaskReturn = {
        withCrosses: boolean;
        inStock: boolean;
    };

    const getFiltersBatchPricerTask = (resultsId: string): GetFiltersBatchPricerTaskReturn => {
        return {
            withCrosses: !Boolean(batchPricerStrictTasks[resultsId]),
            inStock: Boolean(batchPricerInStock[resultsId]),
        };
    };

    useEffect(() => {
        applicationInfo();
        if (drawerPanels?.length) {
            const workspaceApp = drawerPanels?.find((item: any) => item.applicationSysName === 'qwep');
            setWorkspaceApplication(workspaceApp?.applicationWorkspaceId);
            setTariff(workspaceApp.tariff);
            setActiveApp(workspaceApp.applicationPanelIsActive);
            setBlocked(!workspaceApp.applicationPanelIsActive);
        }
    }, [drawerPanels]);

    useEffect(() => {
        getProperty();
    }, []);

    useEffect(() => {
        if (tariff !== Tariff.LITE) {
            if (priceMarginState) {
                setPriceMarginIsActive(priceMarginState?.filter((el: any) => el.flag)?.length > 0 ?? false);
                setHideVendorIsActive(priceMarginState?.filter((el: any) => el.flag)[0]?.hideVendor ?? false);
            }
        }
    }, [JSON.stringify(priceMarginState)]);

    useEffect(() => {
        if (groupsAccountsState?.length) {
            setGroupsAccountsIsActive(groupsAccountsState?.filter((el: any) => el.flag)?.length > 0 ?? false);
        }
    }, [JSON.stringify(groupsAccountsState)]);

    useEffect(() => {
        if (orderDraft?.length) {
            setOrderDraftIsActive(orderDraft?.filter((el: any) => el?.flag)?.length > 0 ?? false);
        }
    }, [JSON.stringify(orderDraft)]);

    useEffect(() => {
        if (draftFilterSearch?.length) {
            setdraftFilterIsActive(draftFilterSearch?.filter((el: any) => el.flag)?.length > 0 ?? false);
        }
    }, [JSON.stringify(draftFilterSearch)]);

    useEffect(() => {
        if (!activeApp) {
            navigate('subscription');
        }
    }, [activeApp]);

    const getProperty = async () => {
        const result = await callPlatformAPI('qwep/get/propertys', 'get');
        console.log('getProperty', result);
        if (result.data.isOk) {
            setReceivedResponse(true);
            result.data.result.map((item: any) => {
                switch (item.sysName) {
                    case Property.ExcludePromo:
                        setExcludePromo(item.value.value);
                        break;
                    case Property.Margin:
                        const marginArr = item.value.value;
                        const newId = 1;
                        if (!marginArr.some((item: any) => item.id === newId)) {
                            marginArr.forEach((item: any, index: any) => {
                                item.id = index + 1;
                            });
                        }
                        setPriceMarginState(item.value.value);
                        break;
                    case Property.MiniTable:
                        setMiniTableRes(item.value.value);
                        break;
                    case Property.GroupsAccounts:
                        setGroupsAccountsState(item.value.value);
                        setGroupsAccountsIsActive(item.value.value?.filter((el: any) => el.flag)?.length > 0 ?? false);
                        break;
                    case Property.BatchPricerStrictMode:
                        setBatchPricerStrictTasks(item.value.value);
                        break;
                    case Property.BatchPricerInStock:
                        setBatchPricerInStock(item.value.value);
                        break;
                    case Property.QwepManual:
                        setManualState(item.value.value);
                        break;
                    case Property.AssistantConfigList:
                        setAssistantConfigList(item.value.value);
                        break;
                    case Property.AssistantCustomList:
                        setAssistantCustomList(item.value);
                        setAssistantLists(item.value);
                        break;
                    case Property.AssistantEmailList:
                        setAssistantEmailList(item.value);
                        break;
                    case Property.OrderDraft:
                        setOrderDraft(item.value.value);
                        setOrderDraftIsActive(item.value.value?.filter((el: any) => el?.flag)?.length > 0 ?? false);
                        break;
                    case Property.Filter1C:
                        setFilter1C(item.value.value);
                        break;
                    case Property.DraftFilterSearch:
                        setDraftFilterSearch(item.value.value);
                        setdraftFilterIsActive(item.value.value?.filter((el: any) => el?.flag)?.length > 0 ?? false);
                        break;
                    case Property.QwepCrosses:
                        setQwepCrosses(item.value.value);
                        break;
                    case Property.CrossesPrice:
                        setPriceCrossesCheck(item.value.value);
                        break;
                    case Property.ColumnVisibility:
                        setColumnVisibility(item.value.value);
                        break;
                    case Property.PromoNotification:
                        setPromoNotification(item.value.value);
                        break;
                }
            });
        }
    };

    const updateProperty = async (data: TypeUpdateProperty) => {
        const result = await callPlatformAPI('qwep/update/property', 'put', data);
        console.log('updateProperty', result);
        if (result.data.isOk) {
            switch (data.propertyName) {
                case Property.ExcludePromo:
                    setExcludePromo(result.data.result[0].value.value);
                    break;
                case Property.Margin:
                    setPriceMarginState(result.data.result[0].value.value);
                    break;
                case Property.MiniTable:
                    setMiniTableRes(result.data.result[0].value.value);
                    break;
                case Property.GroupsAccounts:
                    setGroupsAccountsState(result.data.result[0].value.value);
                    break;
                case Property.QwepManual:
                    setManualState(result.data.result[0].value.value);
                    break;
                case Property.BatchPricerStrictMode:
                    setBatchPricerStrictTasks(result.data.result[0].value.value);
                    break;
                case Property.BatchPricerInStock:
                    setBatchPricerInStock(result.data.result[0].value.value);
                    break;
                case Property.AssistantConfigList:
                    setAssistantConfigList(result.data.result[0].value.value);
                    break;
                case Property.AssistantEmailList:
                    setAssistantEmailList(result.data.result[0].value);
                    break;
                case Property.OrderDraft:
                    setOrderDraft(result.data.result[0].value.value);
                    break;
                case Property.Filter1C:
                    setFilter1C(result.data.result[0].value.value);
                    break;
                case Property.DraftFilterSearch:
                    setDraftFilterSearch(result.data.result[0].value.value);
                    break;
                case Property.QwepCrosses:
                    setQwepCrosses(result.data.result[0].value.value);
                    break;
                case Property.CrossesPrice:
                    setPriceCrossesCheck(result.data.result[0].value.value);
                    break;
                case Property.ColumnVisibility:
                    setColumnVisibility(result.data.result[0].value.value);
                    break;
                case Property.PromoNotification:
                    setPromoNotification(result.data.result[0].value.value);
                    break;
            }
        }
        return result.data;
    };

    const applicationInfo = async () => {
        setApplicationInfoLoading(true);
        const result = await callPlatformAPI('qwep/get/info', 'get');
        console.log('applicationInfo', result);
        if (result.data.isOk) {
            setWorkPeriod(result.data.result.entity?.packageStatus?.paidTill);
            if (result.data.result.errors) {
                if (result.data.result.errors[0].code === 1) {
                    navigate('subscription');
                    setActiveApp(false);
                } else if (result.data.result.errors[0].code === 2) {
                    alert(result.data.result.errors[0].message);
                } else {
                    setActiveApp(true);
                }
            }
        }
        setApplicationInfoLoading(false);
    };

    const changeTariff = async (data: TypeChangeTariff) => {
        const result = await callPlatformAPI('qwep/change/tariff', 'post', data);
        return result.data;
    };

    const exportExcel = async (data: TypeExportExcel) => {
        const lang = localStorage.getItem('language');
        const result = await exportExcelFetch('qwep/export/excel', {
            ...data,
            lang,
        });
        return result;
    };

    const getShortNumbers = async (str: string) => {
        const result = await callPlatformAPI(`qwep/presearch/${str}`, 'get');
        return result.data;
    };

    const getUsersId = () => {
        const usersId: number[] = [];
        users?.length &&
            users.filter((item: any) => {
                if (item?.panels?.some((i: any) => i.applicationSysName === Apps.Qwep)) {
                    usersId.push(item.userId);
                }
            });
        return usersId;
    };

    const create1cScan = async (data: TypeCreate1cScanScan) => {
        const usersId: number[] = getUsersId();

        let formdata = new FormData();
        formdata.append('backup-file', data.file, data.file.name);
        formdata.append('users', JSON.stringify(usersId));
        formdata.append('title', data.title);
        formdata.append('workspaceApplicationId', `${workspaceApplication}`);
        formdata.append('createGroup', data.createGroup ? '1' : '0');

        const result = await callPlatformAPI('qwep/assistant/create/1c/scan', 'post', formdata);
        console.log('create1cScan', result.data);
        return result.data;
    };

    return (
        <QwepContext.Provider
            value={{
                workspaceApplication,
                excludePromo,
                currentLocation,
                workPeriod,
                priceMarginState,
                activeApp,
                priceMarginIsActive,
                hideVendorIsActive,
                miniTableRes,
                applicationInfoLoading,
                openExportModal,
                exportData,
                typeExport,
                notifications,
                groupsAccountsState,
                groupsAccountsIsActive,
                receivedResponse,
                batchPricerStrictTasks,
                tariff,
                blocked,
                manualState,
                assistantConfigList,
                assistantCustomList,
                congratulationOpenModal,
                assistantEmailList,
                orderDraft,
                orderDraftIsActive,
                filter1C,
                draftFilterIsActive,
                draftFilterSearch,
                qwepCrosses,
                priceCrossesCheck,
                batchPricerInStock,
                columnVisibility,
                promoNotification,

                //methods
                getProperty,
                create1cScan,
                setCongratulationOpenModal,
                getUsersId,
                setNotifications,
                setTypeExport,
                setExportData,
                setOpenExportModal,
                setMiniTableRes,
                changeTariff,
                updateProperty,
                setPriceMarginIsActive,
                setHideVendorIsActive,
                exportExcel,
                setGroupsAccountsState,
                setGroupsAccountsIsActive,
                setAssistantCustomList,
                getShortNumbers,
                setAssistantEmailList,
                setOrderDraft,
                setOrderDraftIsActive,
                setFilter1C,
                setDraftFilterSearch,
                setdraftFilterIsActive,
                setQwepCrosses,
                setBatchPricerInStock,
                setColumnVisibility,
                //для получения фильтров для скачки экселя
                getFiltersBatchPricerTask,
            }}
            {...props}
        />
    );
}

export { QwepProvider, QwepContext };
