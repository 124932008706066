import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import { CardContent, MenuItem, Modal, Select, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricer.module.sass';
import { IconAddPrice } from '@f_qwep/components/icons/IconAddPrice';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { Alert } from '@f_general/alert';
import GeneralForm from '@f_general/GeneralForm';
import { QwepPackageName } from '@f_admin/type';
import { IconAddModal } from '../icons/IconAddModal';

type TypeEditProps = {
    open: boolean;
    handleClose: any;
    qwepAppInfo: any;
    currentApplication: any;
    userInfo: any;
    currentWorkspace: any;
};

export function EditApplicationQwepModal({
    open,
    handleClose,
    qwepAppInfo,
    currentApplication,
    userInfo,
    currentWorkspace,
}: TypeEditProps) {
    const { t } = useTranslation();
    const { colors, CustomSelect } = useContext(ThemeContext);
    const { updateQwepApp } = useContext(UserAccountContext);
    const [options, setOptions] = useState([
        {
            value: 1,
            key: 'Продлить ключ',
        },
        {
            value: 2,
            key: 'Сменить токен',
        },
        {
            value: 3,
            key: 'Сменить ключ и создать новый токен',
        },
        {
            value: 4,
            key: 'Сменить ключ и токен',
        },
    ]);

    const [form, setForm] = useState<any>();

    const closeModal = () => {
        setForm({});
        handleClose();
    };

    useEffect(() => {
        if (open) {
            changeHandler(1);
        }
    }, [open]);

    const changeHandler = (value: any) => {
        switch (value) {
            case 1:
                setForm({
                    icon: '',
                    title: t('adminPanel.changePeriod'),
                    props: [
                        {
                            title: t('adminPanel.periodEnd'),
                            control: 'inputDate',
                            name: 'paidTill',
                            type: 'data',
                            defaultValue: qwepAppInfo.paidTill,
                        },
                        {
                            control: 'select',
                            name: 'packageName',
                            title: t('adminPanel.packadeName'),
                            defaultValue: qwepAppInfo.packageName,
                            options: [
                                {
                                    value: QwepPackageName['A1 1000'],
                                    key: QwepPackageName['A1 1000'],
                                },
                                {
                                    value: QwepPackageName['A100 100000'],
                                    key: QwepPackageName['A100 100000'],
                                },
                                {
                                    value: QwepPackageName['A30 30000'],
                                    key: QwepPackageName['A30 30000'],
                                },
                                {
                                    value: QwepPackageName['A5 5000'],
                                    key: QwepPackageName['A5 5000'],
                                },
                                {
                                    value: QwepPackageName['Demo 300'],
                                    key: QwepPackageName['Demo 300'],
                                },
                                {
                                    value: QwepPackageName['Internal 1000'],
                                    key: QwepPackageName['Internal 1000'],
                                },
                                {
                                    value: QwepPackageName['NETIX10000 10000'],
                                    key: QwepPackageName['NETIX10000 10000'],
                                },
                                {
                                    value: QwepPackageName['NETIX3000 3000'],
                                    key: QwepPackageName['NETIX3000 3000'],
                                },
                                {
                                    value: QwepPackageName['NETIX_DEMO 300'],
                                    key: QwepPackageName['NETIX_DEMO 300'],
                                },
                                {
                                    value: QwepPackageName['Promo 1000000'],
                                    key: QwepPackageName['Promo 1000000'],
                                },
                                {
                                    value: QwepPackageName['Testing 100000'],
                                    key: QwepPackageName['Testing 100000'],
                                },
                                {
                                    value: QwepPackageName['Unlimit 1000000'],
                                    key: QwepPackageName['Unlimit 1000000'],
                                },
                            ],
                            castomStyle: {
                                width: '472px',
                            },
                        },
                    ],
                    shema: null,
                    type: 'editPeriod',
                    data: {
                        packageName: qwepAppInfo.packageName,
                        paidTill: qwepAppInfo?.paidTill,
                        type: 1,
                    },
                });
                break;
            case 2:
                let defaultValue = currentApplication?.applicationProps?.find(
                    (item: any) => item.applicationProperty.sysName,
                )?.value?.qwepToken;
                setForm({
                    icon: '',
                    title: t('adminPanel.changeQwepToken'),
                    props: [
                        {
                            control: 'input',
                            name: 'qwepToken',
                            title: t('adminPanel.tokenCurrentUser'),
                            type: 'text',
                            defaultValue: defaultValue,
                        },
                    ],
                    shema: null,
                    type: 'changeToken',
                    data: {
                        qwepToken: defaultValue,
                        type: 2,
                    },
                });

                break;
            case 3:
                setForm({
                    icon: '',
                    title: t('adminPanel.changeAuthCode'),
                    props: [
                        {
                            control: 'input',
                            name: 'authorizationCode',
                            title: t('adminPanel.authorizationCode'),
                            type: 'text',
                            defaultValue: qwepAppInfo.authorizationCode,
                        },
                    ],
                    shema: null,
                    type: 'changeAuthCode',
                    data: {
                        authorizationCode: qwepAppInfo.authorizationCode,
                        type: 3,
                    },
                });

                break;
            case 4:
                let defaultValueToken = currentApplication?.applicationProps?.find(
                    (item: any) => item.applicationProperty.sysName,
                )?.value?.qwepToken;
                setForm({
                    icon: '',
                    title: t('adminPanel.changeAuthCodeAndToken'),
                    props: [
                        {
                            control: 'input',
                            name: 'authorizationCode',
                            title: t('adminPanel.authorizationCode'),
                            type: 'text',
                            defaultValue: qwepAppInfo.authorizationCode,
                        },
                        {
                            control: 'input',
                            name: 'qwepToken',
                            title: t('adminPanel.tokenCurrentUser'),
                            type: 'text',
                            defaultValue: defaultValueToken,
                        },
                    ],
                    shema: null,
                    type: 'changeAuthCodeAndToken',
                    data: {
                        authorizationCode: qwepAppInfo.authorizationCode,
                        qwepToken: defaultValueToken,
                        type: 4,
                    },
                });

                break;
        }
    };

    const sendDataHandler = async (values: number) => {
        console.log('VVV', values);
        const resultEditQwepApp = await updateQwepApp({
            content: values,
            authorizationCode: currentApplication.propertyApplication.property.authorizationCode,
            applicationWorkspaceId: currentApplication.applicationWorkspaceId,
            applicationPropertyValueId: currentApplication.applicationProps.find(
                (app: any) => app.applicationProperty.sysName === 'qwep_token',
            ).applicationPropertyValueId,
            userId: userInfo.userId,
            workspaceId: currentWorkspace.workspaceId,
            email: userInfo.email,
        });
        if (resultEditQwepApp.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });

            closeModal();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <div className={stls.modalTitle}>
                            <IconAddModal />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {t('adminPanel.changeTariff')}
                            </Typography>
                        </div>
                    </div>

                    <div className={stls.content}>
                        <Select
                            input={<CustomSelect />}
                            className={stls.select}
                            autoWidth
                            defaultValue={options[0].value}
                            onChange={(e: any) => changeHandler(e.target.value)}
                            MenuProps={{
                                sx: {
                                    '&& .MuiMenuItem-root': {
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        color: colors.omicron,
                                        '&:hover': {
                                            background: colors.beta,
                                        },
                                    },
                                },
                            }}>
                            {options.map((option: any) => {
                                return (
                                    <MenuItem id={option.value} key={option.key} value={option.value}>
                                        {option.key}
                                    </MenuItem>
                                );
                            })}
                        </Select>

                        <div>
                            {form?.type === 'editPeriod' && (
                                <GeneralForm
                                    sendDataHandler={sendDataHandler}
                                    close={closeModal}
                                    mainData={form}
                                    loading={false}
                                />
                            )}
                            {form?.type === 'changeToken' && (
                                <GeneralForm
                                    sendDataHandler={sendDataHandler}
                                    close={closeModal}
                                    mainData={form}
                                    loading={false}
                                />
                            )}
                            {form?.type === 'changeAuthCode' && (
                                <GeneralForm
                                    sendDataHandler={sendDataHandler}
                                    close={closeModal}
                                    mainData={form}
                                    loading={false}
                                />
                            )}
                            {form?.type === 'changeAuthCodeAndToken' && (
                                <GeneralForm
                                    sendDataHandler={sendDataHandler}
                                    close={closeModal}
                                    mainData={form}
                                    loading={false}
                                />
                            )}
                        </div>
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
}
