import { useContext, useEffect } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';
import stls from '@f_styles/general/SearchBase.module.sass';
import { GeneralContext } from '@f_context/General_context';
import { observer } from 'mobx-react-lite';

type TypeSearchBase = {
    data: any;
    setData: any;
    placeholder: string;
    type: string;
    id?: any;
};

export const SearchBase = observer(({ data, setData, placeholder, type, id }: TypeSearchBase) => {
    const { Search, SearchIconWrapper, StyledInputBase } = useContext(ThemeContext);

    // Поиск поставщика ассистента
    const { isRunAssistantVendorSearch, assistantVendor } = useContext(GeneralContext);

    const autoLayoutKeyboard = (str: string): string => {
        const replacer: { [key: string]: string } = {
            q: 'й',
            w: 'ц',
            e: 'у',
            r: 'к',
            t: 'е',
            y: 'н',
            u: 'г',
            i: 'ш',
            o: 'щ',
            p: 'з',
            '[': 'х',
            ']': 'ъ',
            a: 'ф',
            s: 'ы',
            d: 'в',
            f: 'а',
            g: 'п',
            h: 'р',
            j: 'о',
            k: 'л',
            l: 'д',
            ';': 'ж',
            "'": 'э',
            z: 'я',
            x: 'ч',
            c: 'с',
            v: 'м',
            b: 'и',
            n: 'т',
            m: 'ь',
            ',': 'б',
            '.': 'ю',
            '/': '.',
        };

        const isRussian: boolean = /[а-яА-ЯЁё]/.test(str);

        const layout: { [key: string]: string } = Object.entries(replacer).reduce(
            (acc: { [key: string]: string }, [key, value]) => {
                acc[value] = key;
                return acc;
            },
            {},
        );

        return isRussian
            ? str.replace(/[А-я/,.;\'\]\[]/g, function (x: string): string {
                  return x == x.toLowerCase() ? layout[x] : layout[x.toLowerCase()].toUpperCase();
              })
            : str.replace(/[A-z/,.;\'\]\[]/g, function (x: string): string {
                  return x == x.toLowerCase() ? replacer[x] : replacer[x.toLowerCase()].toUpperCase();
              });
    };

    // Поиск поставщика ассистента
    useEffect(() => {
        if (isRunAssistantVendorSearch) {
            setTimeout(() => {
                handler(assistantVendor);
            }, 500);
        }
    }, []);

    const handler = (e: string, cleanContent: boolean = true) => {
        if (type === 'users') {
            setData(
                data.filter((item: any) => {
                    if (
                        item.firstName.toLocaleLowerCase().includes(e) ||
                        item.lastName.toLocaleLowerCase().includes(e) ||
                        item.email.toLocaleLowerCase().includes(e)
                    ) {
                        return item;
                    }
                }),
            );
        } else if (type === 'vendors') {
            const options = {
                keys: ['title', 'site'],
                shouldSort: true,
                isCaseSensitive: false,
                minMatchCharLength: 0,
                threshold: 0.3,
                findAllMatches: true,
            };
            const fuse = new Fuse(data, options);
            const changeKeyboard = autoLayoutKeyboard(e);
            const searchData = fuse.search(e).map((r) => r.item);
            const changeKeyboardData = fuse.search(changeKeyboard).map((r) => r.item);
            setData(e.length > 0 ? searchData.concat(changeKeyboardData) : data);
        } else if (type === 'accounts') {
            const options = {
                keys: ['title', 'login'],
                shouldSort: true,
                isCaseSensitive: false,
                minMatchCharLength: 0,
                threshold: 0.3,
                findAllMatches: true,
            };
            const fuse = new Fuse(data, options);
            const changeKeyboard = autoLayoutKeyboard(e);
            const searchData = fuse.search(e).map((r) => r.item);
            const changeKeyboardData = fuse.search(changeKeyboard).map((r) => r.item);
            setData(e.length > 0 ? searchData.concat(changeKeyboardData) : data);
        } else if (type === 'account') {
            const options = {
                keys: ['title', 'login'],
                shouldSort: true,
                isCaseSensitive: false,
                minMatchCharLength: 0,
                threshold: 0.3,
                findAllMatches: true,
            };
            const fuse = new Fuse(data, options);
            // const changeKeyboard = autoLayoutKeyboard(e)
            const searchData = fuse.search(e).map((r) => r.item);
            // const changeKeyboardData = fuse.search(changeKeyboard).map((r) => r.item)
            setData(e.length > 0 ? searchData : data);
        } else if (type === 'searchItems') {
            setData(
                data.filter((item: any) => {
                    if (item.brand.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
        } else if (type === 'search') {
            setData(
                data.filter((item: any) => {
                    if (
                        item.brand.toLocaleLowerCase().includes(e) ||
                        item.article.toLocaleLowerCase().includes(e) ||
                        item.vendorTitle.toLocaleLowerCase().includes(e) ||
                        item.title.toLocaleLowerCase().includes(e)
                    ) {
                        return item;
                    }
                }),
            );
        } else if (type === 'basket') {
            setData(
                data.filter((item: any) => {
                    if (item.vendorTitle.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
        } else if (type === 'dashboardList') {
            setData(
                data.filter((item: any) => {
                    if (item.taskName.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
        } else if (type === 'taskList') {
            setData(
                data.filter((item: any) => {
                    if (item.brand.toLocaleLowerCase().includes(e) || item.article.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
        } else if (type === 'ordersList') {
            setData(
                data.filter((item: any) => {
                    if (
                        item.article?.toLocaleLowerCase().includes(e) ||
                        item.status?.toLocaleLowerCase().includes(e) ||
                        item.statusOrder?.toLocaleLowerCase().includes(e) ||
                        item.warehouse?.toLocaleLowerCase().includes(e) ||
                        item.vendorTitle?.toLocaleLowerCase().includes(e) ||
                        item.partname?.toLocaleLowerCase().includes(e) ||
                        item.brand?.toLocaleLowerCase().includes(e)
                    ) {
                        return item;
                    }
                }),
            );
        } else if (type === 'price') {
            setData(
                data.filter((item: any) => {
                    if (
                        item.status?.toLocaleLowerCase().includes(e) ||
                        item.priceName?.toLocaleLowerCase().includes(e) ||
                        item.vendorName?.toLocaleLowerCase().includes(e)
                    ) {
                        return item;
                    }
                }),
            );
        } else if (type === 'groups') {
            setData(
                data.filter((item: any) => {
                    if (item.name.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
        } else if (type === 'accountsGroup') {
            setData(
                data.filter((item: any) => {
                    if (item.login.toLocaleLowerCase().includes(e) || item.title.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
        } else if (type === 'qwepManual') {
            setData(
                data.filter((item: any) => {
                    if (item.toLocaleLowerCase().includes(e)) {
                        return item;
                    }
                }),
            );
            const options = {
                keys: [],
                shouldSort: true,
                isCaseSensitive: false,
                minMatchCharLength: 0,
                threshold: 0.3,
                findAllMatches: true,
            };
            const fuse = new Fuse(data, options);
            const changeKeyboard = autoLayoutKeyboard(e);
            const searchData = fuse.search(e).map((r) => r.item);
            const changeKeyboardData = fuse.search(changeKeyboard).map((r) => r.item);
            setData(e.length > 0 ? searchData.concat(changeKeyboardData) : []);
        }
    };

    return (
        <Search className={stls.search}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                id={id}
                placeholder={placeholder}
                onChange={(e: any) => handler(e.target.value.toLocaleLowerCase())}
                // value={value}
            />
        </Search>
    );
});
