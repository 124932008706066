import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_admin/styles/components/userInfo/TableUsersToWorkspace.module.sass';
import { useContext } from 'react';
import { Avatar } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserRoleChip } from '@f_general/UserRoleChip';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { useTranslation } from 'react-i18next';
import { IconStatusOk } from '../../icons/IconStatusOk';
import { IconEmail } from '../../icons/IconEmail';
import { IconPhone } from '../../icons/IconPhone';
import { IconSettings } from '../../icons/IconSettings';
import { IconStatusNotOk } from '../../icons/IconStatusNotOk';

export function CellInfo({ info }: any) {
    const { t } = useTranslation();

    const { colors } = useContext(ThemeContext);
    return (
        <div className={stls.cellInfo}>
            <div className={stls.mainBlock}>
                <p className={stls.title}>
                    <span className={stls.ava}>
                        <Avatar
                            alt={info.row.original.firstName}
                            className={stls.avatar}
                            src={info.row.original.photo ? info.row.original.photo : '/static/images/avatar/1.jpg'}
                            sx={{ width: 40, height: 40 }}
                        />
                        {`${info.row.original.firstName} ${info.row.original.lastName}`}
                        <div className={stls.isActive}>
                            {info.row.original.isActive ? <IconStatusOk /> : <IconStatusNotOk />}
                        </div>
                    </span>
                    <UserRoleChip
                        classNames={[stls.roleBox]}
                        sysName={info.row.original.role.map((item: any) => item.sysName)}
                    />
                </p>
                <div className={stls.userInfoContainer}>
                    <div className={stls.infoBlock}>
                        <p style={{ color: colors.omicronTitle }}>{t('adminPanel.createDate')}: </p>
                        {new Date(info.row.original.createDate).toLocaleDateString()}
                    </div>
                    <div className={stls.infoBlock}>
                        <p style={{ color: colors.omicronTitle }}>{t('adminPanel.lastOnlineDate')}: </p>
                        {new Date(info.row.original.lastOnlineDate).toLocaleDateString()}
                    </div>
                    <div className={stls.infoBlock}>
                        <p style={{ color: colors.omicronTitle }}>{t('adminPanel.position')}: </p>
                        {info.row.original.position}
                    </div>
                    <div className={stls.infoBlock}>
                        <p style={{ color: colors.omicronTitle }}>{t('adminPanel.parentCompany')}: </p>
                        {info.row.original.createBy}
                    </div>
                </div>

                <div className={stls.activeBlock}>
                    <a
                        href={`mailto: ${info.row.original.email}`}
                        style={{ color: colors.alpha }}
                        className={stls.textActive}>
                        <IconEmail />
                        <span style={{ marginLeft: '10px' }}>{info.row.original.email}</span>
                    </a>
                    <a
                        href={`tel: ${info.row.original.phoneNumber}`}
                        style={{ color: colors.alpha }}
                        className={stls.textActive}>
                        <IconPhone />
                        <span style={{ marginLeft: '10px' }}>{info.row.original.phoneNumber}</span>
                    </a>
                </div>
            </div>
            <div className={stls.techBlock}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div className={stls.text}>
                        <p style={{ color: colors.omicronTitle }}>{t('adminPanel.userId')}: </p>
                        {info.row.original.userId}
                    </div>
                    <IconSettings />
                </div>
                <div className={stls.text}>
                    <p style={{ color: colors.omicronTitle }}>{t('adminPanel.status')}: </p>
                    {info.row.original.statusUser}
                </div>
                <div className={stls.text}>
                    <p style={{ color: colors.omicronTitle }}>{t('adminPanel.updated')}: </p>
                    {new Date(info.row.original.updated).toLocaleDateString()}
                </div>
                <div className={stls.text}>
                    <p style={{ color: colors.omicronTitle }}>{t('adminPanel.key')}: </p>
                    {info.row.original.activationKey}
                </div>
            </div>
        </div>
    );
}
