import { Column, RowData, Table } from '@tanstack/react-table';
import stls from '@f_styles/general/table/TableFilters.module.sass';
import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import { DebouncedInput } from '@f_general/stackTable/DebouncedInput';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';

type NumberInputProps = {
    columnFilterValue: [number, number];
    getFacetedMinMaxValues: () => [number, number] | undefined;
    setFilterValue: (updater: any) => void;
};

const NumberInput: React.FC<NumberInputProps> = ({ columnFilterValue, getFacetedMinMaxValues, setFilterValue }) => {
    const minOpt = getFacetedMinMaxValues()?.[0];
    const min = Number(minOpt ?? '');

    const maxOpt = getFacetedMinMaxValues()?.[1];
    const max = Number(maxOpt);

    return (
        <div className={stls.container}>
            <div className={stls.row}>
                <DebouncedInput
                    min={min}
                    max={max}
                    value={columnFilterValue?.[0] ?? ''}
                    onChange={(value: any) => setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${minOpt ? `(${min})` : ''}`}
                    className={cn(stls.input, stls.number)}
                />
                <DebouncedInput
                    min={min}
                    max={max}
                    value={columnFilterValue?.[1] ?? ''}
                    onChange={(value: any) => setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${maxOpt ? `(${max})` : ''}`}
                    className={cn(stls.input, stls.number)}
                />
            </div>
            <div />
        </div>
    );
};

type TextInputProps = {
    columnId: string;
    columnFilterValue: string;
    columnSize: number;
    setFilterValue: (updater: any) => void;
    sortedUniqueValues: any[];
    searchId?: any;
    styles?: any;
};

const TextInput: React.FC<TextInputProps> = ({
    columnId,
    columnFilterValue,
    columnSize,
    searchId,
    setFilterValue,
    sortedUniqueValues,
    styles,
}) => {
    const { t } = useTranslation();
    const { currentTheme, colors } = useContext(ThemeContext);

    const dataListId = columnId + 'list';

    useEffect(() => {
        setFilterValue('');
    }, [searchId]);

    return (
        <div className={stls.row}>
            <datalist id={dataListId}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={columnFilterValue ?? ''}
                onChange={(value: any) => setFilterValue(value.replace(/[\./"]/g, ''))}
                placeholder={`${t('general.table.search')}... `}
                className={cn(
                    stls.text,
                    styles.head.miniFilterInput
                        ? currentTheme === 'vinpin'
                            ? stls.input_vinpin
                            : stls.input
                        : currentTheme === 'vinpin'
                        ? stls.input_r_vinpin
                        : stls.input_r,
                )}
                list={dataListId}
                style={{
                    ...styles.head.inputFilter,
                    background: colors.crossdata,
                    borderColor: currentTheme === 'dark' ? '#475569' : 'fff',
                    color: colors.omicron,
                }}
            />
            <div />
        </div>
    );
};

type Props<T extends RowData> = {
    column: Column<T, unknown>;
    table: Table<T>;
    searchId?: any;
    styles?: any;
};

export function TableFilter<T extends RowData>({ column, table, searchId, styles }: Props<T>) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();
    const uniqueValues = column.getFacetedUniqueValues();

    const sortedUniqueValues = React.useMemo(
        () => (typeof firstValue === 'number' ? [] : Array.from(uniqueValues.keys()).sort()),
        [uniqueValues],
    );

    return typeof firstValue === 'number' ? (
        <NumberInput
            columnFilterValue={columnFilterValue as [number, number]}
            getFacetedMinMaxValues={column.getFacetedMinMaxValues}
            setFilterValue={column.setFilterValue}
        />
    ) : (
        <TextInput
            searchId={searchId}
            columnId={column.id}
            columnFilterValue={columnFilterValue as string}
            columnSize={uniqueValues.size}
            setFilterValue={column.setFilterValue}
            sortedUniqueValues={sortedUniqueValues}
            styles={styles}
        />
    );
}
