import React, { FC, useEffect } from 'react';
import { Slider } from '@mui/material';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import { FilterLeftStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/FilterLeft.store';

interface IFilterLeftPriceProps {
    filter: any;
    currentTheme: any;
    styles: any;
    t: TFunction<'translation', undefined>;
    colors: any
    store: FilterLeftStore;
    filtersReducer: any;
}

export const FilterLeftPrice: FC<IFilterLeftPriceProps> = observer(({styles, t, filter, colors, currentTheme, store, filtersReducer}) => {
    const {setMinPrice, setMaxPrice, maxPrice, minPrice, handleChangePrice} = store;

    const sliderMinCount = 0;
    const sliderMaxCount = 50000;


    useEffect(() => {
        filtersReducer({ type: 'minPrice_input', payload: { min: minPrice } });
    }, [minPrice]);

    useEffect(() => {
        filtersReducer({ type: 'maxPrice_input', payload: { max: maxPrice } });
    }, [maxPrice]);

    return (
        <>
            <div className={styles.listItemNav}>
                <h5 className={styles.subtitle}>
                    {t('qwep.search.price')} ({t('qwep.search.rub')})
                </h5>
            </div>
            <div id="searchPrice" className={styles.listItemCheck}>
                <Slider
                    className={styles.slider}
                    size="small"
                    value={[filter?.minPrice ?? 0, filter?.maxPrice ?? 50000]}
                    min={sliderMinCount}
                    max={sliderMaxCount}
                    onChange={(_, value) => handleChangePrice(value)}
                />
                <div className={styles.listItemDiv}>
                    <input
                        style={{ color: colors.omicron }}
                        placeholder={`${t('qwep.search.from')}`}
                        className={currentTheme === 'vinpin' ? styles.textvinpin : styles.text}
                        value={filter?.minPrice}
                        onChange={setMinPrice}
                        type="number"
                        min="0"
                    />
                    <input
                        style={{ color: colors.omicron }}
                        placeholder={`${t('qwep.search.to')}`}
                        className={currentTheme === 'vinpin' ? styles.textvinpin : styles.text}
                        value={filter?.maxPrice}
                        onChange={setMaxPrice}
                        type="number"
                        min="0"
                    />
                </div>
            </div>
        </>
    );
});