import stls from '@f_qwep/styles/component/settings/Suppliers.module.sass';
import { CellAdd } from './CellAdd';
import { CellInstruction } from './CellInstruction';
import { CellSite } from './CellSite';

export function MobileCart({ info }: any) {
    return (
        <div className={stls.mobileCart}>
            <div className={stls.title}>
                <p className={stls.titleText}>{info.row.original.title}</p>
                <CellSite info={info} />
            </div>

            <div className={stls.actions}>
                <div className={stls.item}>
                    <CellInstruction info={info} />
                </div>
                <div>
                    <CellAdd info={info} />
                </div>
            </div>
        </div>
    );
}
