import { JSXElementConstructor, ReactElement, useContext, useEffect, useState } from 'react';
import { GeneralContext } from '@f_context/General_context';
import { Route, Routes } from 'react-router-dom';
import { UCSProviders } from './context';
import { Report } from './components/report';
import { HeaderComponent } from './components/header';
import { Subscription } from './components/licenses';
import { ExportExcelModal } from './general/ExportExcelModal';
import { Home } from '@f_ucs/general/Home';
import { VersionPanel } from '@f_ucs/components/versionPanel/VersionPanel';
import { ActionPanel } from '@f_ucs/components/actionPanel/ActionPanel';

export function AppUCS() {
    const { reloadQwep } = useContext(GeneralContext);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (reloadQwep) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [reloadQwep]);

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <UCSProviders>
                    <HeaderComponent />
                    <Routes>
                        <Route path={'/'} element={<Home />} />
                        <Route path={'/subscription'} element={<Subscription />} />
                    </Routes>
                    <ExportExcelModal />
                </UCSProviders>
            )}
        </>
    );
}
