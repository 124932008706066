export const IconHighAssistant = (color: any) => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5408_28948)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.25 5L8.72 3.965L9.75 3.5L8.72 3.035L8.25 2L7.79 3.035L6.75 3.5L7.79 3.965L8.25 5ZM11.25 4.5C10.525 4.5 10.12 5.22 10.285 5.755L8.51 7.535C8.36 7.49 8.14 7.49 7.99 7.535L6.715 6.26C6.885 5.725 6.48 5 5.75 5C5.025 5 4.615 5.72 4.785 6.26L2.505 8.535C1.97 8.37 1.25 8.775 1.25 9.5C1.25 10.05 1.7 10.5 2.25 10.5C2.975 10.5 3.38 9.78 3.215 9.245L5.49 6.965C5.64 7.01 5.86 7.01 6.01 6.965L7.285 8.24C7.115 8.775 7.52 9.5 8.25 9.5C8.975 9.5 9.385 8.78 9.215 8.24L10.995 6.465C11.53 6.63 12.25 6.225 12.25 5.5C12.25 4.95 11.8 4.5 11.25 4.5ZM2.75 5L2.5 6L2.25 5L1.25 4.75L2.25 4.5L2.5 3.5L2.75 4.5L3.75 4.75L2.75 5Z"
                    fill={color.color}
                />
            </g>
            <defs>
                <clipPath id="clip0_5408_28948">
                    <rect width="12" height="12" fill="white" transform="translate(0.75 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
