import stls from '@f_crossdata/styles/components/home/TableCross.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useMemo, useState } from 'react';
import { Checkbox } from '@mui/material';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { useTranslation } from 'react-i18next';
import { ConfigReportContext } from '@f_crossdata/context/ConfigReport_context';

export function HeadBrand({ data }: any) {
    const { t } = useTranslation();

    const { colors, currentTheme } = useContext(ThemeContext);
    const { setCrossesPartname } = useContext(ConfigReportContext);
    const [checked, setChecked] = useState(true);

    useMemo(() => {
        setChecked(!data.some((item: any) => !item.active));
    }, [data]);

    const changeHandler = (checked: boolean) => {
        setCrossesPartname((prev: any) => prev.map((item: any) => ({ ...item, active: checked })));
    };

    return (
        <p className={stls.headBrand}>
            <Checkbox checked={checked} onChange={({ target }) => changeHandler(target.checked)} />
            {t('crossdata.crossTable.article')}
        </p>
    );
}

export function CellBrand({ info, data }: any) {
    const { setCrossesPartname } = useContext(ConfigReportContext);

    const changeHandler = (checked: boolean, brand: string, article: string) => {
        setCrossesPartname((prev: any) =>
            prev.map((item: any) => {
                if (item.brand === brand && item.article === article) {
                    return {
                        ...item,
                        active: checked,
                    };
                } else {
                    return item;
                }
            }),
        );
    };
    return (
        <p className={stls.cell}>
            <Checkbox
                checked={info.row.original.active}
                onChange={({ target }) =>
                    changeHandler(target.checked, info.row.original.brand, info.row.original.article)
                }
            />
            {info.row.original.brand}
        </p>
    );
}
