import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Tooltip } from '@mui/material';

export function ColumnVendor ({ vendorLength, index, vendor }:any, idRow:any, isOpenDetails:any)  {
    const { colors } = useContext(ThemeContext);

    return (
        vendorLength ? (
            <div
                className={stls.cellVendor}
                style={{
                    color:
                        idRow !== index && isOpenDetails
                            ? colors.blurColor
                            : colors.omicron,
                }}>
                {vendor}
            </div>
        ) : (
            <Tooltip title={`${vendor}`}>
                <div
                    className={stls.cellVendor}
                    style={{
                        color:
                            idRow !== index && isOpenDetails
                                ? colors.blurColor
                                : colors.omicron,
                    }}>
                    {vendor}
                </div>
            </Tooltip>
        )
    )
}