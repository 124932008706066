import { Checkbox, Tooltip } from '@mui/material';
import cn from 'classnames';
import stls from '../../../styles/component/basket/Basket.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useState } from 'react';
import { BasketContext } from '@f_qwep/context/Basket_context';

export function CellManufacturer({ info, accountId, basketId, hasPartOrder, basketForm }: any) {
    const { colors } = useContext(ThemeContext);
    const { checkedBasketItems, setCheckedBasketItems } = useContext(BasketContext);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (checkedBasketItems[accountId]?.check) {
            setCheckedBasketItems((prev: any) =>
                prev[accountId]?.items?.filter((el: any) => +el === +info.row.original.basketItemId).length === 0
                    ? {
                          ...prev,
                          [accountId]: {
                              basketId: basketId,
                              check: checkedBasketItems[accountId]?.check,
                              hasPartOrder: hasPartOrder,
                              basketForm: basketForm,
                              items:
                                  prev[accountId]?.items?.length > 0
                                      ? [...prev[accountId]?.items, +info.row.original.basketItemId]
                                      : [+info.row.original.basketItemId],
                          },
                      }
                    : { ...prev },
            );
        }

        setChecked(
            checkedBasketItems[accountId]?.check
                ? true
                : checkedBasketItems[accountId]?.items?.filter((el: any) => el === +info.row.original.basketItemId)
                      .length > 0
                ? true
                : false,
        );
    }, [checkedBasketItems[accountId]?.check, checkedBasketItems[accountId]?.items]);

    const handleCheckbox = () => {
        setChecked((prev: boolean) => !prev);
        setCheckedBasketItems((prev: any) => ({
            ...prev,
            [accountId]: {
                basketId: basketId,
                check: false,
                hasPartOrder: hasPartOrder,
                basketForm: basketForm,
                items: checked
                    ? prev[accountId]?.items?.filter((el: any) => +el != +info.row.original.basketItemId)
                    : prev[accountId]?.items
                    ? [...prev[accountId]?.items, +info.row.original.basketItemId]
                    : [+info.row.original.basketItemId],
            },
        }));
    };

    return (
        <div style={{ display: 'flex' }}>
            <Checkbox disabled={!hasPartOrder} checked={checked} onChange={handleCheckbox} />
            <Tooltip placement="top" title={info.row.original.brand}>
                <p style={{ color: colors.alpha }} className={cn(stls.cellBrand)}>
                    {info.row.original.brand}
                </p>
            </Tooltip>
        </div>
    );
}
