import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ConfigPanel } from '@f_qwep/components/purchasingAssistant/ui/configPanel/ConfigPanel';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { TableAssistant } from '@f_qwep/components/purchasingAssistant/ui/table';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from 'react-i18next';

export const PurchasingAssistant = observer(() => {
    const { colors } = useContext(ThemeContext);
    const store = useAssistantStore();
    const { filteredDeals, isDataLoading, isHasAssistantData } = store.assistantDeals;
    const { currentAssistantList } = store.assistantList;
    const { t } = useTranslation();

    // isHasAssistantData - если данные существуют в списке но по каким-то причинам бекенд нам их не возвращает

    return (
        <LayoutPage classNames={[styles.container]}>
            {isDataLoading && (
                <div className={styles.loading} style={{ color: colors.alpha }}>
                    <CircularProgress size={20} className={styles.icon} />
                    Загрузка...
                </div>
            )}
            <ConfigPanel />
            {!isHasAssistantData ? (
                <TableAssistant items={filteredDeals} />
            ) : (
                <div style={{ color: colors.alpha }} className={styles.assistantWarningWrapper}>
                    <div className={styles.titleWrapper}>
                        <ReportIcon sx={{ color: colors.alpha }} fontSize={'large'} />
                        {currentAssistantList.refKey === '0' ? (
                            <h1 style={{ color: colors.alpha }}>{t('qwep.assistant.warning.titleMainPortrait')}</h1>
                        ) : (
                            <h1 style={{ color: colors.alpha }}>{t('qwep.assistant.warning.titleCustomPortrait')}</h1>
                        )}
                    </div>
                    {currentAssistantList.refKey === '0' ? (
                        <h2>{t('qwep.assistant.warning.descriptionMainPortrait')}</h2>
                    ) : (
                        <h2>{t('qwep.assistant.warning.descriptionCustomPortrait')}</h2>
                    )}
                </div>
            )}
        </LayoutPage>
    );
});
