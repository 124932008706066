import { IStandardPayload } from '@f_qwep/types/StandardPayload.interface';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import {
    IFetchEmailListArgs,
    IFetchRemoveListArgs,
    IFetchRemoveListItemArgs,
    IReturnDataCreateCustomList,
} from '@f_qwep/components/purchasingAssistant/types/assistantApi.type';
import { TDeals } from '@f_qwep/components/purchasingAssistant/types/Deals.type';
import { ScanEnum } from '@f_qwep/components/purchasingAssistant/types/Scan.enum';
import { TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';

const fetchEmailList = async ({
    list,
    usersId,
    workspaceApplication,
}: Omit<IFetchEmailListArgs, 'store'>): Promise<IStandardPayload<Pick<IFetchEmailListArgs, 'list'>>> => {
    const response = await callPlatformAPI('qwep/assistant/change/email/list', 'put', {
        list: list,
        userIds: usersId,
        workspaceApplicationId: workspaceApplication,
    });
    return response.data;
};

const fetchDeals = async (usersId: number[], currentRefKey: string): Promise<IStandardPayload<TDeals[]>> => {
    const requestBody = {
        default: {
            users: usersId,
            type: ScanEnum.DEFAULT,
            IsTemplateShow: true,
        },
        custom: {
            users: usersId,
            refKey: currentRefKey,
            type: ScanEnum.CUSTOM,
            IsTemplateShow: true,
        },
    };

    const currentReqBody = currentRefKey === '0' ? requestBody.default : requestBody.custom;
    const response = await callPlatformAPI('qwep/assistant/get/scan', 'post', currentReqBody);
    return response.data;
};

const fetchCreateCustomList = async <T>(reqBody: T): Promise<IStandardPayload<IReturnDataCreateCustomList>> => {
    const result = await callPlatformAPI('qwep/assistant/create/custom/scan', 'post', reqBody as object);
    return result.data;
};

const fetchRemoveList = async (reqBody: IFetchRemoveListArgs): Promise<IStandardPayload<TypeAssistantCustomList[]>> => {
    const result = await callPlatformAPI('qwep/assistant/remove/custom/list', 'post', reqBody);
    return result.data;
};

const fetchRemoveListItem = async (reqBody: IFetchRemoveListItemArgs): Promise<IStandardPayload<undefined>> => {
    const result = await callPlatformAPI('qwep/assistant/remove/custom/list/item', 'post', reqBody);
    return result.data;
};

export const assistantApi = {
    fetchEmail: fetchEmailList,
    fetchDeals: fetchDeals,
    fetchCreateList: fetchCreateCustomList,
    fetchRemoveList: fetchRemoveList,
    fetchRemoveListItem: fetchRemoveListItem,
};
