import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { useWindowSize } from '@f_hooks';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import _ from 'lodash';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { CellMobile } from './CellMobile';
import { CellDate } from './CellDate';
import { CellVendor } from './CellVendor';
import { CellBrand } from './CellBrand';
import { CellArticle } from './CellArticle';
import { CellTitle } from './CellTitle';
import { CellPrice } from './CellPrice';
import { CellStatus } from './CellStatus';
import { CellQuantity } from './CellQuantity';
import { CellWarehouse } from './CellWarehouse';
import { IconMessage } from '../../icons/IconMessage';
import { CellComment } from './CellComment';
import { CellTime } from './CellTime';
import { CellSettings } from './CellSettings';
import { CellOrderStatus } from '@f_qwep/components/orders/table/CellOrderStatus';
import { CellOrderNumber } from '@f_qwep/components/orders/table/CellOrderNumber';
import { PageNoData } from '@f_qwep/general/PageNoData';

dayjs.extend(isBetween);

interface OrdersTableProps {
    orders: any;
    isOrderVendor?: boolean;
    itemChangeOpenHandler?: any;
    itemChangeStatusOpenHandler?: any;
}

export function OrderTable({
    orders,
    isOrderVendor,
    itemChangeOpenHandler,
    itemChangeStatusOpenHandler,
}: OrdersTableProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const { setOpenExportModal, setExportData, setTypeExport } = useContext(QwepContext);

    const { filterOrder } = useContext(FilterContext);

    const [mobile, setMobile] = useState(false);
    const [ordersTable, setOrdersTable] = useState(orders);

    const { width, height } = useWindowSize();

    useEffect(() => {
        setMobile(width < 950);
    }, [width]);

    useEffect(() => {
        setOrdersTable(orders);
    }, [orders]);

    const getFiltredResultsByDate = (items: any, filter: any) => {
        let result = [...(items || [])];
        if (!items || !filter) {
            return [];
        }

        // Дата
        if (filter?.minDate !== '' && filterOrder.maxDate !== '') {
            const { minDate, maxDate } = filter;
            result = result.filter((row) => dayjs(row.datetimeOrder).isBetween(minDate, maxDate, 'day', '[]'));
        }

        return result;
    };

    const getFiltredResults = () => {
        let result = [...(ordersTable || [])];
        if (!ordersTable || !filterOrder) {
            return [];
        }

        // Поставщики
        if (filterOrder?.vendorTitle.length > 0) {
            result = result.filter((row) => filterOrder.vendorTitle.includes(row.vendorTitle));
        }

        // Производители
        if (filterOrder?.brands.length > 0) {
            result = result.filter((row) => filterOrder.brands.includes(row.brand));
        }

        // Статусы
        if (filterOrder?.status.length > 0) {
            result = result.filter((row) => {
                return filterOrder.status.includes(row.status) || filterOrder.status.includes(row.statusItem);
            });
        }

        result = getFiltredResultsByDate(result, filterOrder);

        return result;
    };

    const data = useMemo(() => (isOrderVendor ? orders : getFiltredResults() ?? []), [ordersTable, filterOrder]);

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          cell: (info) => <CellMobile info={info} />,
                          footer: (props) => props.column.id,
                      },
                  ]
                : [
                      {
                          id: 'orderStatus',
                          accessorKey: '',
                          header: '',
                          cell: (info: any) => <CellOrderStatus info={info} />,
                          footer: (props: any) => props.column.id,
                          size: 2,
                      },
                    {
                        id: 'numberOrder',
                        accessorKey: '',
                        header: 'Номер заказа',
                        cell: (info: any) => <CellOrderNumber info={info} />,
                        footer: (props: any) => props.column.id,
                        size: 5,
                    },

                      {
                          id: 'date',
                          accessorKey: '',
                          header: `${t('qwep.orders.date')}`,
                          cell: (info: any) => <CellDate info={info} />,
                          footer: (props: any) => props.column.id,
                          size: 15,
                      },
                      {
                          id: 'vendor',
                          accessorKey: 'vendorTitle',
                          header: `${t('qwep.orders.vendor')}`,
                          cell: (info: any) => <CellVendor info={info} />,
                          footer: (props: any) => props.column.id,
                          size: 10,
                      },
                      {
                          id: 'brand',
                          accessorKey: 'brand',
                          header: `${t('qwep.orders.brand')}`,
                          cell: (info) => <CellBrand info={info} />,
                          footer: (props) => props.column.id,
                          size: 10,
                      },
                      {
                          id: 'article',
                          accessorKey: 'article',
                          header: `${t('qwep.orders.article')}`,
                          cell: (info) => <CellArticle info={info} />,
                          footer: (props) => props.column.id,
                          size: 10,
                      },
                      {
                          id: 'title',
                          accessorKey: 'description',
                          header: `${t('qwep.orders.title')}`,
                          cell: (info) => <CellTitle info={info} />,
                          footer: (props) => props.column.id,
                          size: 140,
                      },
                      {
                          id: 'price',
                          accessorKey: 'price',
                          header: `${t('qwep.orders.price')}`,
                          cell: (info) => <CellPrice info={info} />,
                          footer: (props) => props.column.id,
                          aggregationFn: 'sum',
                          size: 10,
                      },
                      {
                          id: 'status',
                          accessorKey: 'status',
                          header: `${t('qwep.orders.status')}`,
                          cell: (info) => <CellStatus info={info} />,
                          footer: (props) => props.column.id,
                          size: 80,
                      },
                      {
                          id: 'total',
                          accessorKey: 'total',
                          header: `${t('qwep.orders.total')}`,
                          cell: (info) => <CellQuantity info={info} />,
                          footer: (props) => props.column.id,
                          maxSize: 10,
                          size: 1,
                      },
                      {
                          id: 'Склад',
                          accessorKey: 'warehouse',
                          header: `${t('qwep.orders.warehouse')}`,
                          cell: (info) => <CellWarehouse info={info} />,
                          footer: (props) => props.column.id,
                          size: 10,
                      },
                      {
                          id: 'comment',
                          accessorKey: 'notes',
                          header: () => <IconMessage />,
                          cell: (info: any) => <CellComment info={info} />,
                          footer: (props: any) => props.column.id,
                          size: 5,
                      },

                      ...(!isOrderVendor
                          ? [
                                {
                                    id: 'Срок',
                                    accessorKey: 'time',
                                    header: `${t('qwep.search.deliveryTime')}`,
                                    cell: (info: any) => <CellTime info={info} />,
                                    footer: (props: any) => props.column.id,
                                    sortingFn: (a: any, b: any) => {
                                        const dateA = new Date(b.original.deliveryStart);
                                        const dateB = new Date(a.original.deliveryStart);
                                        return +dateA - +dateB;
                                    },
                                    size: 110,
                                },
                            ]
                          : [
                                {
                                    id: 'settings',
                                    accessorKey: 'settings',
                                    header: () => `Настройки`,
                                    cell: (info: any) => (
                                        <CellSettings
                                            info={info}
                                            itemChangeOpenHandler={itemChangeOpenHandler}
                                            itemChangeStatusOpenHandler={itemChangeStatusOpenHandler}
                                        />
                                    ),
                                    footer: (props: any) => props.column.id,
                                    size: 10,
                                },
                            ]),
                  ],

        [mobile, isOrderVendor, orders],
    );

    const tableStyles = {
        head: {
            // background: colors.beta,
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '40px',
            },
            marginRow: {
                margin: '6px 0',
            },
            cellRadius: true,
        },
    };

    const sortResult = (data: any) => {
        const dateSorter = (data: any) => new Date(data.datetimeOrder);
        return _.orderBy(data, dateSorter, 'desc');
    };

    const exportExcelHandler = () => {
        setTypeExport('orders');
        setExportData({ filter: sortResult(data), all: orders });
        setOpenExportModal(true);
    };

    return (
        <>
            {sortResult(data)?.length ?
                <VirtualTable
                    data={isOrderVendor ? orders : sortResult(data)}
                    columnsTable={columns}
                    topFilterPanel={true}
                    heightTable={height - 150}
                    showColumnSearchAction={false}
                    configHeader={{
                        fullScrean: !isOrderVendor && true,
                        exportExcel: !isOrderVendor && true,
                        resize: false,
                        grouping: false,
                        columnFilter: false,
                        sorting: false,
                        columnSearch: false,
                        dragging: false,
                    }}
                    styles={tableStyles}
                    exportExcelHandler={exportExcelHandler}
                    estimateSize={mobile ? 166.5 : 46}
                /> :
                <PageNoData type={'ordersFiltered'} />
            }
        </>
    );
}
