import { FileValidationComponentEnum } from '@f_qwep/types/file/validation/FileValidationComponent.enum';
import { IFileValidatorOptions } from '@f_qwep/types/file/validation/FileValidation.interface';
import { FileExtension } from '@f_types/FileExtension.enum';
import { ConvertBytes } from '@f_utils/convert/convertBytes.util';

export const fileValidationDefaultOptions: Record<FileValidationComponentEnum, IFileValidatorOptions> = {
    [FileValidationComponentEnum.PRICE]: {
        allowedExtensions: [FileExtension.XLSX, FileExtension.XLS, FileExtension.CSV],
        maxSize: ConvertBytes.megabytesToBytes(50),
        requiredHeaders: [],
        limitJsonSize: null,
    },
    [FileValidationComponentEnum.ASSISTANT]: {
        allowedExtensions: [FileExtension.XLSX, FileExtension.XLS],
        maxSize: ConvertBytes.megabytesToBytes(1),
        requiredHeaders: ['Артикул', 'Бренд'],
        limitJsonSize: 500,
        exceptionsLimitJsonSize: 5000,
    },
    [FileValidationComponentEnum.BATCH_PRICER]: {
        allowedExtensions: [FileExtension.XLSX, FileExtension.XLS],
        maxSize: ConvertBytes.megabytesToBytes(1),
        requiredHeaders: ['Бренд', 'Артикул'],
        limitJsonSize: 500,
    },
};
