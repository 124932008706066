import { SupportContext } from '@f_context/Support_context';
import { ThemeContext } from '@f_context/Theme_context';
import { UserContext } from '@f_context/User_context';
import { Alert } from '@f_general/alert';
import { useTranslation } from 'react-i18next';
import stls from '@f_styles/components/support/Chat.module.sass';
import { useContext, useEffect, useState } from 'react';
import { Chat } from './chat/Chat';

export function DrawerChat({ currentTask, currentChat, handleFileChange }: any) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);

    const { sendSupportMessageChat, getSupportChat, setCurrentChat, socketMessages, files, setFiles, loadingFile } =
        useContext(SupportContext);

    const [message, setMessage] = useState('');

    useEffect(() => {
        if (socketMessages?.payload?.chatId == currentTask.id) handleGetChat(currentTask.id);
    }, [socketMessages]);

    const handleGetChat = async (id: string) => {
        if (id) {
            const result = await getSupportChat(id);
            if (result.isOk) {
                setCurrentChat(result.result?.content);
            }
        }
    };

    const handleSendChatSubmit = async () => {
        let content = message;
        if (files?.length) {
            content += ` ${files?.map((item: any) => `/root/#file:${item.url}`).join('  ')}`;
        }
        const result = await sendSupportMessageChat({
            chatId: currentTask.id,
            text: content,
            name: userInfo.firstName,
        });
        if (result.result) {
            Alert({
                type: 'success',
                text: 'Сообщение успешно отправлено',
            });
            setFiles([]);
            setMessage('');
        } else {
            Alert({
                type: 'error',
                text: 'Сервис временно недоступен',
            });
        }
    };

    return (
        <div
            className={stls.drawerChat}
            style={{
                background: currentTheme == 'light' ? colors.epsilonHover : colors.psi,
                height: 'calc(100vh)',
            }}>
            <Chat
                chatId={currentTask?.id}
                currentChat={currentChat}
                userInfo={userInfo}
                messageInChat={message}
                setMessageInChat={setMessage}
                handleSendChatMessageSubmit={handleSendChatSubmit}
                currentTitle={currentTask.title}
                handleSendChatSubmit={handleSendChatSubmit}
                handleFileChange={handleFileChange}
                files={files}
                setFiles={setFiles}
                loadingFile={loadingFile}
                styles={{
                    chatHeight: '100%',
                    background: colors.phiBg,
                }}
                completed={currentTask.completed}
            />
        </div>
    );
}
