import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterLeftPanelMarginPrice } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/FilterLeftPanelMarginPrice';
import { FilterLeftPanelCrosses } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/FilterLeftPanelCrosses';
import { FilterLeftPanelTemplate } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/FilterLeftPanelTemplate';

interface IFilterLeftPanelActionsProps {
    styles: any;
    colors: any;
}

export const FilterLeftPanelActions: FC<IFilterLeftPanelActionsProps> = ({ styles, colors }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.listItemNav}>
            <h5 className={styles.subtitle}>{t('qwep.search.filters')}</h5>
            <FilterLeftPanelMarginPrice colors={colors} tooltipTitle={t('qwep.settings.margin.margin')} />
            <FilterLeftPanelCrosses
                colors={colors}
                tooltipTitle={t('qwep.settings.cross.cross')}
                tooltipCrossTurnOn={t('qwep.settings.cross.crossTurnOn')}
                tooltipCrossSwitchError={t('qwep.settings.cross.crossSwitchError')}
                tooltipCrossTurnOff={t('qwep.settings.cross.crossTurnOff')}
            />
            <FilterLeftPanelTemplate
                styles={styles}
                colors={colors}
                t={t}
                tooltipTitle={t('qwep.settings.template.template')}
            />
        </div>
    );
};
