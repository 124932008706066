import stls from '@f_admin/styles/general/LayoutPage.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { Wrapper } from '@f_general/Wrapper';

import cn from 'classnames';
import { useContext } from 'react';

type TypeLayoutPageProps = {
    children: any;
    classNames?: any;
    header?: any;
    leftPanel?: any;
};

export function LayoutPage({ children, classNames, header, leftPanel }: TypeLayoutPageProps) {
    const { colors } = useContext(ThemeContext);
    return (
        <div className={stls.container}>
            <Wrapper classNames={[classNames, stls.wrapper]}>
                <div className={stls.row}>{header}</div>

                <div className={cn(stls.leftPanel, stls.block)} style={{ background: colors.iota }}>
                    {leftPanel}
                </div>
                <div className={cn(stls.content, stls.block)}>{children}</div>
            </Wrapper>
        </div>
    );
}
