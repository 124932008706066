import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';

const AdminContext = createContext<any>({});

function AdminProvider(props: any) {
    const [allUserInfo, setAllUserInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getAllInfo();
    }, []);

    const getAllInfo = async () => {
        setIsLoading(true);
        const result = await callPlatformAPI('admin/get/all/info', 'get');
        console.log('getAppInfo', result);
        if (result.data.isOk) {
            setAllUserInfo(result.data.result);
        }
        setIsLoading(false);
    };


    return (
        <AdminContext.Provider
            value={{
                allUserInfo,
                isLoading,
                //methods
                getAllInfo,
        
            }}
            {...props}
        />
    );
}

export { AdminProvider, AdminContext };
