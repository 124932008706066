import styles from '@f_qwep/styles/component/purchasingAssistant/TablePurchasingAssistant.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

type TypeConfirmedPriceProps = {
    bestPrice: string;
    authVendor: boolean;
    confirmedPrice: string;
    foundItem: boolean;
    isTemplateItem: boolean;
};

export function AssistantConfirmedPriceColumn({
    bestPrice,
    authVendor,
    confirmedPrice,
    foundItem,
    isTemplateItem,
}: TypeConfirmedPriceProps) {
    const { colors } = useContext(ThemeContext);
    const color = bestPrice ? colors.lightgreen : authVendor ? colors.lambda : colors.sigma;
    const priceFormated = (price: string) => {
        return price
            ? price
                  .split('')
                  .reverse()
                  .map((el: any, idx: number) => (!(idx % 3 || idx === 0) ? el + ' ' : el))
                  .reverse()
                  .join('') + ' ₽'
            : '-';
    };

    return (
        <>
            {foundItem && !isTemplateItem ? (
                <div className={styles.cellPrice} style={{ borderColor: color }}>
                    <p style={{ color: color }} className={styles.price}>
                        {authVendor ? priceFormated(bestPrice) : `~${priceFormated(confirmedPrice)}`}
                    </p>
                </div>
            ) : (
                <p style={{ color: colors.sigma }}></p>
            )}
        </>
    );
}
