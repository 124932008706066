import { PriceContext } from '@f_qwep/context/Price_context';
import { QwepContext } from '../../context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/price/UpdateConfiguration.module.sass';
import { Alert } from '@f_general/alert';
import { Button, MenuItem, Select, TextField, Checkbox } from '@mui/material';
import { Classifications } from '@f_qwep/types';
import { randomKey } from '@f_utils/randomKey';
import { TypeClassificationsList, classificationsList } from './classificationsList';
import AddIcon from '@mui/icons-material/Add';
import { IconClose } from '@f_components/icons/IconClose';

interface TypeUpdateConfigurationProps {
    adminCode: string;
    handleCloseModal: () => void;
    type: 'modal' | 'block';
    open: boolean;
    vendor: string;
}

type TypeConfigPrice = {
    value: string;
    type: string;
    category: string;
};

type TypeValueList = {
    type: 'AddValueIfEmpty' | 'AddValue';
    value: string;
    key: string;
    category: string;
    id: number;
};
type TypePriceTop = {
    vendor: string;
    flag: boolean;
};
export const UpdateConfiguration = ({
    adminCode,
    handleCloseModal,
    type,
    open,
    vendor,
}: TypeUpdateConfigurationProps) => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { updateConfigPrice, getConfigPrice } = useContext(PriceContext);
    // const {priceSearchTop, setPriceSearchTop} = useContext(QwepContext);
    // const [prePriceTop, setPrePriceTop] = useState<TypePriceTop[]>([]);
    const [configPrice, setConfigPrice] = useState<TypeConfigPrice[]>([]);

    const [cleanPeriod, setCleanPeriod] = useState<string | number>('3');
    const [loading, setLoading] = useState(false);
    //values
    const classifications = classificationsList(t);
    const [valuePopup, setValuePopup] = useState(true);
    const [valueEmptyPopup, setValueEmptyPopup] = useState(true);

    const [valueList, setValueList] = useState<TypeValueList[]>([
        {
            type: 'AddValue',
            value: '',
            key: t('qwep.price.modal.classificationModal.trash'),
            category: Classifications.TRASH,
            id: randomKey(),
        },
    ]);

    const [valueEmptyList, setValueEmptyList] = useState<TypeValueList[]>([
        {
            type: 'AddValueIfEmpty',
            value: '',
            key: t('qwep.price.modal.classificationModal.trash'),
            category: Classifications.TRASH,
            id: randomKey(),
        },
    ]);

    const [usedCategory, setUsedCategory] = useState<TypeClassificationsList[]>(classifications);

    useEffect(() => {
        setUsedCategory(
            usedCategory.map((item) => {
                if (
                    [...valueList, ...valueEmptyList].some((v) => v.category === item.value) &&
                    item.value !== Classifications.TRASH
                ) {
                    return { ...item, disabled: true };
                }
                return { ...item, disabled: false };
            }),
        );
    }, [valueList, valueEmptyList]);

    useEffect(() => {
        if (configPrice.length) {
            const list: TypeValueList[] = [];
            const emptyList: TypeValueList[] = [];
            configPrice.map((item) => {
                switch (item.type) {
                    case 'CleanPeriod':
                        setCleanPeriod(item.value);
                        break;
                    case 'AddValue':
                        usedCategory.map((c) => {
                            if (c.value === item.category && c.value !== Classifications.TRASH) {
                                list.push({
                                    type: 'AddValue',
                                    value: item.value,
                                    key: c.value,
                                    category: item.category,
                                    id: randomKey(),
                                });
                                return { ...c, disabled: true };
                            }
                            return { ...item, disabled: false };
                        });

                        break;
                    case 'AddValueIfEmpty':
                        usedCategory.map((c) => {
                            if (c.value === item.category && c.value !== Classifications.TRASH) {
                                emptyList.push({
                                    type: 'AddValueIfEmpty',
                                    value: item.value,
                                    key: c.value,
                                    category: item.category,
                                    id: randomKey(),
                                });
                                return { ...c, disabled: true };
                            }
                            return { ...item, disabled: false };
                        });
                        break;
                }
            });

            if (list.length) {
                setValueList(list);
            }
            if (emptyList.length) {
                setValueEmptyList(emptyList);
            }
        }
    }, [configPrice, valuePopup, valueEmptyPopup]);

    useEffect(() => {
        if (adminCode) {
            configPriceModalHandler(adminCode);
        }
    }, [adminCode]);

    const configPriceModalHandler = async (adminCode: string) => {
        setLoading(true);
        const result = await getConfigPrice(adminCode);
        if (result.isOk) {
            setConfigPrice(result.result);
        } else {
            setConfigPrice([]);
            Alert({
                type: 'info',
                text: 'Конфигурация этого прайса установлена по умолчанию',
            });
        }
        setLoading(false);
    };

    const validationValues = (list: TypeValueList[]) => {
        const content: TypeConfigPrice[] = [];
        list.map((item) => {
            if (item.value.trim()) {
                content.push({
                    type: item.type,
                    value: item.value,
                    category: item.category,
                });
            } else {
                Alert({
                    type: 'warning',
                    text: `Не выбрано значение для категории: ${item.category}`,
                });
            }
        });
        return content;
    };

    const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const content = [];
        //тут отфильтровать или поменять флаг если он уже есть в списке
        // setPriceSearchTop([...priceSearchTop, prePriceTop]);
        if (+cleanPeriod > 0) {
            content.push({
                type: 'CleanPeriod',
                value: cleanPeriod,
            });
        } else {
            return Alert({
                type: 'warning',
                text: 'Срок жизни прайса должен быть больше 0',
            });
        }

        if (valuePopup) {
            const list = validationValues(valueList);
            if (list?.length) {
                content.push(...list);
            }
        }

        if (valueEmptyPopup) {
            const list = validationValues(valueEmptyList);
            if (list?.length) {
                content.push(...list);
            }
        }

        const result = await updateConfigPrice({
            content: content,
            adminCode: adminCode,
        });

        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const handleClose = () => {
        handleCloseModal();
    };

    const showConfigHandler = (empty: boolean, value: boolean) => {
        if (empty) {
            setValueEmptyPopup(value);
        } else {
            setValuePopup(value);
        }
    };

    const addRowHandler = (empty: boolean) => {
        if (empty) {
            setValueEmptyList([
                ...valueEmptyList,
                {
                    type: 'AddValueIfEmpty',
                    value: '',
                    key: t('qwep.price.modal.classificationModal.trash'),
                    category: Classifications.TRASH,
                    id: randomKey(),
                },
            ]);
        } else {
            setValueList([
                ...valueList,
                {
                    type: 'AddValue',
                    value: '',
                    key: t('qwep.price.modal.classificationModal.trash'),
                    category: Classifications.TRASH,
                    id: randomKey(),
                },
            ]);
        }
    };

    const deleteRowHandler = (empty: boolean, id: number) => {
        if (empty) {
            setValueEmptyList(valueEmptyList.filter((item) => item.id !== id));
        } else {
            setValueList(valueList.filter((item) => item.id !== id));
        }
    };

    const changeSelectHandler = (empty: boolean, key: string, category: string, id: number) => {
        if (empty) {
            setValueEmptyList(valueEmptyList.map((item) => (item.id === id ? { ...item, category, key } : item)));
        } else {
            setValueList(valueList.map((item) => (item.id === id ? { ...item, category, key } : item)));
        }
    };

    const changeInputHandler = (empty: boolean, value: string, id: number) => {
        if (empty) {
            setValueEmptyList(valueEmptyList.map((item) => (item.id === id ? { ...item, value } : item)));
        } else {
            setValueList(valueList.map((item) => (item.id === id ? { ...item, value } : item)));
        }
    };

    return (
        <form onSubmit={submitHandler} style={{ width: '500px', height: '100%' }}>
            {/* <div className={stls.item} style={{marginBottom: '10px'}}>
                <Checkbox
                    checked={false}
                    onChange={() => setPrePriceTop([{vendor: vendor, flag: true}])}
                />
                <label className={stls.label} htmlFor="cleanPeriod">Отображать всегда сверху при поиске</label>
            </div> */}
            <div className={stls.item}>
                <label className={stls.label} htmlFor="cleanPeriod">
                    Срок жизни прайса (в днях)
                </label>
                <TextField
                    placeholder="Введите количество дней существования прайса"
                    className={stls.input}
                    type={loading ? 'text' : 'number'}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '43px',
                        },
                    }}
                    id="cleanPeriod"
                    value={loading ? 'Загрузка...' : cleanPeriod}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCleanPeriod(+e.target.value > 365 ? '365' : e.target.value)
                    }
                />
            </div>
            <button
                style={{ color: colors.omicron }}
                type="button"
                onClick={() => showConfigHandler(false, !valuePopup)}
                className={stls.btnPopup}>
                {valuePopup ? 'Очистить значения для категорий' : 'Установить значение для категорий'}
            </button>
            <div className={stls.itemValues}>
                {valuePopup && (
                    <CreateValuesList
                        list={valueList}
                        empty={false}
                        usedCategory={usedCategory}
                        changeSelectHandler={changeSelectHandler}
                        changeInputHandler={changeInputHandler}
                        deleteRowHandler={deleteRowHandler}
                        addRowHandler={addRowHandler}
                    />
                )}
            </div>
            <button
                style={{ color: colors.omicron }}
                type="button"
                onClick={() => showConfigHandler(true, !valueEmptyPopup)}
                className={stls.btnPopup}>
                {valueEmptyPopup
                    ? 'Очистить значения для отсутствующих категорий'
                    : 'Установить значение для отсутствующих категорий'}
            </button>
            <div className={stls.itemValues}>
                {valueEmptyPopup && (
                    <CreateValuesList
                        list={valueEmptyList}
                        empty={true}
                        usedCategory={usedCategory}
                        changeSelectHandler={changeSelectHandler}
                        changeInputHandler={changeInputHandler}
                        deleteRowHandler={deleteRowHandler}
                        addRowHandler={addRowHandler}
                    />
                )}
            </div>

            <div className={stls.actions}>
                {type === 'modal' && (
                    <Button
                        className={stls.btnClose}
                        onClick={handleClose}
                        size="large"
                        sx={{
                            padding: '13px',
                            background: colors.delta,
                            color: colors.phi,
                            '&:hover': { color: colors.alpha },
                        }}>
                        {t('qwep.settings.suppliers.canceled')}
                    </Button>
                )}

                <Button
                    className={stls.btnSuccess}
                    size="large"
                    variant="contained"
                    type="submit"
                    sx={{
                        padding: '11px',
                    }}>
                    {t('qwep.price.modal.classificationModal.confirm')}
                </Button>
            </div>
        </form>
    );
};

type CreateValuesList = {
    empty: boolean;
    list: TypeValueList[];
    usedCategory: TypeClassificationsList[];
    changeSelectHandler: (empty: boolean, key: string, category: string, id: number) => void;
    changeInputHandler: (empty: boolean, value: string, id: number) => void;
    deleteRowHandler: (empty: boolean, id: number) => void;
    addRowHandler: (empty: boolean) => void;
};

const CreateValuesList = ({
    list,
    empty,
    usedCategory,
    changeSelectHandler,
    changeInputHandler,
    deleteRowHandler,
    addRowHandler,
}: CreateValuesList) => {
    const { colors, CustomSelect } = useContext(ThemeContext);
    return (
        <div className={stls.item}>
            {list.map((item) => {
                return (
                    <div key={item.id} className={stls.configItem}>
                        <div className={stls.row}>
                            <Select
                                input={<CustomSelect />}
                                className={stls.select}
                                defaultValue={item.category}
                                sx={{
                                    '& .MuiSelect-select': {
                                        padding: '8px',
                                        width: '100px',
                                        display: 'flex',
                                    },
                                }}>
                                {usedCategory.map((option) => {
                                    return (
                                        <MenuItem
                                            onClick={() =>
                                                changeSelectHandler(empty, option.key, option.value, item.id)
                                            }
                                            id={option.key}
                                            key={option.key}
                                            value={option.value}
                                            disabled={option.disabled}>
                                            {option.key}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                            <TextField
                                sx={{
                                    minWidth: '245px',
                                    WebkitTextFillColor: colors.omicron,
                                    '& .MuiOutlinedInput-root': {
                                        width: '245px',
                                        color: `${colors.omicron} !important`,
                                    },
                                }}
                                placeholder={'Значение'}
                                className={stls.input}
                                type="text"
                                name="article"
                                value={item.value}
                                focused
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    changeInputHandler(empty, e.target.value, item.id)
                                }
                            />

                            <button
                                className={stls.btnDelItem}
                                type="button"
                                disabled={list.length <= 1}
                                onClick={() => deleteRowHandler(empty, item.id)}>
                                <IconClose background={list.length <= 1 ? colors.sigma : colors.epsilon} />
                            </button>
                        </div>
                    </div>
                );
            })}
            <button style={{ color: colors.alpha }} className={stls.addBtn} onClick={() => addRowHandler(empty)}>
                <AddIcon sx={{ color: colors.alpha }} />
                Добавить значение
            </button>
        </div>
    );
};
