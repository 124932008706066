import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/userInfo/TableUsersToWorkspace.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { ThemeContext } from '@f_context/Theme_context';
import { CellInfo } from './tableUsersToWorkspace/ColumnInfo';
import { CellApplications } from './tableUsersToWorkspace/ColumnApplications';
import { IconUsers } from '../icons/IconUsers';

export function TableUsersToWorkspace({ users, appsToWorkspace, getUsers }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'info',
                accessorKey: 'info',
                header: () => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <IconUsers /> <p style={{ width: '400px', textAlign: 'start' }}>{t('adminPanel.info')}</p>
                    </div>
                ),
                cell: (info) => <CellInfo info={info} />,
                footer: (props) => props.column.id,
                size: 250,
            },
            {
                id: 'applications',
                accessorKey: 'applications',
                header: () => <div style={{ textAlign: 'start' }}>{t('adminPanel.apps')}</div>,
                cell: (info) => <CellApplications info={info} appsToWorkspace={appsToWorkspace} getUsers={getUsers} />,
                footer: (props) => props.column.id,
                size: 130,
            },
            // {
            //   id: 'settings',
            //   accessorKey: 'settings',
            //   header: 'Settings',
            //   cell: (info) => <CellSettings info={info} />,
            //   footer: (props) => props.column.id,
            //   size: 100,
            // },
        ],
        [users],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '170',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div className={stls.container}>
            <VirtualTable
                data={users}
                columnsTable={columns}
                topFilterPanel={true}
                showColumnSearchAction={false}
                heightTable={400}
                configHeader={{
                    fullScrean: true,
                    exportExcel: false,
                    resize: false,
                    grouping: false,
                    columnFilter: false,
                    sorting: false,
                    columnSearch: true,
                    dragging: false,
                }}
                styles={tableStyles}
                overscan={50}
            />
        </div>
    );
}
