import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconWebProps = {
    color?: string;
} & TypeClassNames;

export const IconChein = ({ classNames, color }: TypeIconWebProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 12C9.42945 12.5741 9.97736 13.0491 10.6066 13.3929C11.2357 13.7367 11.9315 13.9411 12.6467 13.9923C13.3618 14.0435 14.0796 13.9403 14.7513 13.6897C15.4231 13.4392 16.0331 13.047 16.54 12.54L19.54 9.53997C20.4508 8.59695 20.9548 7.33394 20.9434 6.02296C20.932 4.71198 20.4061 3.45791 19.4791 2.53087C18.5521 1.60383 17.298 1.07799 15.987 1.0666C14.676 1.0552 13.413 1.55918 12.47 2.46997L10.75 4.17997"
                    stroke="#3B82F6"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13 9.99974C12.5705 9.42561 12.0226 8.95055 11.3934 8.6068C10.7642 8.26304 10.0684 8.05862 9.3533 8.0074C8.63816 7.95619 7.92037 8.05937 7.24861 8.30996C6.57685 8.56054 5.96684 8.95267 5.45996 9.45974L2.45996 12.4597C1.54917 13.4027 1.04519 14.6658 1.05659 15.9767C1.06798 17.2877 1.59382 18.5418 2.52086 19.4688C3.4479 20.3959 4.70197 20.9217 6.01295 20.9331C7.32393 20.9445 8.58694 20.4405 9.52995 19.5297L11.24 17.8197"
                    stroke="#3B82F6"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    );
};
