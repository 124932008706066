import { action, makeObservable, observable } from 'mobx';
import { ModalStore } from '@f_store/Modal.store';

export class PreviewTableStore extends ModalStore {
    data: object[] = [];
    headers: Array<string> = [];

    constructor() {
        super();
        makeObservable(this, {
            data: observable,
            headers: observable,
            setTableData: action.bound,
            getTableData: action.bound,
            setHeaders: action.bound,
            getHeaders: action.bound,
            resetStore: action.bound,
        });
    }

    setTableData(data: object[]) {
        this.data = data;
    }

    getTableData(): object[] {
        return this.data;
    }

    setHeaders(headers: Array<string>) {
        this.headers = headers;
    }

    getHeaders() {
        return this.headers;
    }

    resetStore(): void {
        this.data = [];
        this.headers = [];
    }
}
