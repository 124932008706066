import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconUploadLineProps = TypeClassNames;

export const IconUploadLine = ({ classNames }: TypeIconUploadLineProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 17H18V19H0V17ZM10 3.828V15H8V3.828L1.929 9.9L0.515 8.486L9 0L17.485 8.485L16.071 9.899L10 3.83V3.828Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
