import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconAttachProps = {
    color?: string;
} & TypeClassNames;

export const IconAttach = ({ classNames, color }: TypeIconAttachProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 12V6.5C10 5.43913 9.57857 4.42172 8.82843 3.67157C8.07828 2.92143 7.06087 2.5 6 2.5C4.93913 2.5 3.92172 2.92143 3.17157 3.67157C2.42143 4.42172 2 5.43913 2 6.5V12C2 13.7239 2.68482 15.3772 3.90381 16.5962C5.12279 17.8152 6.77609 18.5 8.5 18.5C10.2239 18.5 11.8772 17.8152 13.0962 16.5962C14.3152 15.3772 15 13.7239 15 12V2.5H17V12C17 14.2543 16.1045 16.4163 14.5104 18.0104C12.9163 19.6045 10.7543 20.5 8.5 20.5C6.24566 20.5 4.08365 19.6045 2.48959 18.0104C0.895533 16.4163 0 14.2543 0 12V6.5C0 4.9087 0.632141 3.38258 1.75736 2.25736C2.88258 1.13214 4.4087 0.5 6 0.5C7.5913 0.5 9.11742 1.13214 10.2426 2.25736C11.3679 3.38258 12 4.9087 12 6.5V12C12 12.9283 11.6313 13.8185 10.9749 14.4749C10.3185 15.1313 9.42826 15.5 8.5 15.5C7.57174 15.5 6.6815 15.1313 6.02513 14.4749C5.36875 13.8185 5 12.9283 5 12V6.5H7V12C7 12.3978 7.15804 12.7794 7.43934 13.0607C7.72064 13.342 8.10218 13.5 8.5 13.5C8.89782 13.5 9.27936 13.342 9.56066 13.0607C9.84196 12.7794 10 12.3978 10 12Z"
                    fill={color ? color : colors.epsilon}
                />
            </svg>
        </div>
    );
};
