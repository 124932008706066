import { Box, Grid } from '@mui/material';
import { CellStatus } from './CellStatus';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { useTranslation } from 'react-i18next';
import { CellTitle } from './CellTitle';

export function CellMobile({ info, showResultItem, setShownItem, shownItem, statusItems }: any) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ display: 'flex' }}>
                        <p style={{ width: '200px' }} className={stls.title}>
                            {t('qwep.batchPricer.article')}:
                        </p>
                        <p style={{ width: '350px', marginLeft: '20px' }} className={stls.title}>
                            {t('qwep.batchPricer.brand')}:
                        </p>
                    </div>
                    <CellTitle info={info} />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <p className={stls.title}>{t('qwep.batchPricer.status')}:</p>
                    <CellStatus
                        showResultItem={showResultItem}
                        setShownItem={setShownItem}
                        shownItem={shownItem}
                        statusItems={statusItems}
                        info={info}
                    />
                </div>
            </div>
        </Box>
    );
}
