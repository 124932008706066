import React, { useContext } from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { IconVendorForAssistant } from '@f_qwep/components/icons/IconVendorsforAssistant';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ThemeContext } from '@f_context/Theme_context';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import cn from 'classnames';

export const FilterVendorsForDating = observer(() => {
    const { colors } = useContext(ThemeContext);
    const store = useAssistantStore();
    const { isVendorsForDating, switchIsVendorsForDating } = store.assistantFilters;

    return (
        <Button
            className={cn(styles.btn, styles.filterAction)}
            style={{
                borderColor: isVendorsForDating ? colors.alpha : colors.epsilon,
                borderRadius: '4px',
                height: '41px',
                width: '230px',
                display: 'flex',
                flexShrink: 0,
            }}
            onClick={switchIsVendorsForDating}>
            <IconVendorForAssistant style={{ color: isVendorsForDating ? colors.alpha : colors.epsilon }} />
            <p style={{ color: isVendorsForDating ? colors.alpha : colors.epsilon }}>Поставщики для знакомства</p>
        </Button>
    );
});
