import {
    Avatar,
    Card,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/licenses/Licenses.module.sass';
import { useContext, useEffect, useState } from 'react';
import { IconLicense } from '@f_components/icons/IconLicense';
import { useNavigate } from 'react-router-dom';
import { Tariff } from '@f_qwep/types';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { UserContext } from '@f_context/User_context';
import React from 'react';
import { IconUsersTariff } from '@f_components/icons/IconUsersTariff';
import { IconUsersCurrentTariff } from '@f_components/icons/IconUsersCurrentTariff';
import { IconEmail } from '@f_admin/components/icons/IconEmail';

type TypeCompanyProps = {
    application: any;
};

export function LicensesPage({ application }: TypeCompanyProps) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { users } = useContext(UserContext);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tariff, setTariff] = useState('');

    const [appInfo, setAppInfo] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        switch (application.tariff) {
            case Tariff.LITE:
                setTariff('Lite');
                break;
            case Tariff.PRO:
                setTariff('PRO');
                break;
            case Tariff.PROPlus:
                setTariff('PRO+');
                break;
            case Tariff.UNLIMIT:
                setTariff('Unlimit');
                break;
        }
        if (application.applicationSysName === 'qwep') {
            getInfoQwepApp();
        } else if (application.applicationSysName === 'admin_panel')
            setAppInfo({
                users:
                    users?.filter((item: any) =>
                        item.panels.some(
                            (p: any) => p.applicationSysName === 'admin_panel' && p.applicationPanelIsActive,
                        ),
                    ) ?? 0,
            });
    }, [application, users]);

    const allUsers =
        (appInfo?.users ? appInfo?.users.length : 0) +
        (application.maxLimit !== '-' || application.currentLimit !== '-'
            ? application.maxLimit - application.currentLimit
            : 0);

    const getInfoQwepApp = async () => {
        setLoading(true);
        const result = await callPlatformAPI('qwep/get/info', 'get');
        console.log('getInfoQwepApp', result);
        if (result.data.isOk) {
            setAppInfo({
                paidTill: result.data.result.entity.packageStatus.paidTill.slice(9).replace(/-/g, '.'),
                periodEnd: result.data.result.entity.packageStatus.periodEnd.slice(9).replace(/-/g, '.'),
                link: '/app-qwep/subscription',
                users:
                    users?.filter((item: any) =>
                        item.panels.some((p: any) => p.applicationSysName === 'qwep' && p.applicationPanelIsActive),
                    ) ?? 0,
            });
        }
        setLoading(false);
    };

    return (
        <>
            {loading ? (
                <CircularProgress />
            ) : (
                <Card sx={{ background: colors.theta }} className={stls.licenses}>
                    <div className={stls.content}>
                        <div className={stls.info}>
                            <div
                                className={stls.licensesTitle}
                                // style={{ background: colors.kappa }}
                            >
                                <IconLicense />
                                <div className={stls.title}>
                                    <p className={stls.subtitle} style={{ color: colors.omicron }}>
                                        {t('licenses.tariff')}
                                    </p>
                                    <p className={stls.tariff} style={{ color: colors.omicron }}>
                                        {tariff}
                                    </p>
                                </div>
                            </div>

                            <div className={stls.licensesInfo}>
                                <Typography className={stls.text}>
                                    <p className={stls.titleText}>{t('licenses.licensed')} </p>
                                    <span className={stls.value}>{appInfo?.paidTill ? appInfo.paidTill : '-'}</span>
                                </Typography>
                                <Typography className={stls.text}>
                                    <p className={stls.titleText}>{t('licenses.allUsers')} </p>
                                    <span className={stls.value}>{allUsers}</span>
                                </Typography>
                                <Typography className={stls.text}>
                                    <p className={stls.titleText}>{t('licenses.activeUsers')} </p>
                                    <span className={stls.value}>{appInfo?.users ? appInfo?.users.length : '-'}</span>
                                </Typography>
                                {application.tariff !== Tariff.UNLIMIT && (
                                    <Typography className={stls.text}>
                                        <p className={stls.titleText}>{t('licenses.activeLicensed')} </p>
                                        <span className={stls.value}>
                                            {application.maxLimit - application.currentLimit}
                                        </span>
                                    </Typography>
                                )}
                            </div>
                        </div>
                        <div>
                            <div
                                className={stls.licensesTitle}
                                // style={{ background: colors.kappa }}
                            >
                                <IconUsersCurrentTariff />
                                <div className={stls.title}>
                                    <p className={stls.subtitle} style={{ color: colors.omicron }}>
                                        {t('licenses.numberActiveUsers')}
                                    </p>
                                    <p className={stls.tariff} style={{ color: colors.omicron }}>
                                        {t('licenses.currentUsers')}
                                    </p>
                                </div>
                            </div>
                            <List className={stls.users}>
                                {appInfo?.users.map((item: any) => {
                                    return (
                                        <ListItem
                                            className={stls.listCurrentUsers}
                                            sx={{ background: colors.omega }}
                                            alignItems="flex-start"
                                            key={item.userId}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={item?.firstName}
                                                    src={item?.photo ? item?.photo : '/static/images/avatar/1.jpg'}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${item.firstName} ${item.lastName}`}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                marginTop: '13px',
                                                            }}
                                                            component="span"
                                                            variant="body2">
                                                            <IconEmail />
                                                            <span
                                                                style={{
                                                                    color: colors.alpha,
                                                                    marginLeft: '10px',
                                                                    fontWeight: 400,
                                                                }}>
                                                                {item.email}
                                                            </span>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                    </div>

                    {appInfo?.link && (
                        <div
                            onClick={() => navigate(appInfo?.link)}
                            className={stls.button}
                            style={{ color: colors.alpha }}>
                            <div onClick={() => navigate(appInfo?.link)}>{t('licenses.prolong')}</div>
                            <div onClick={() => navigate(appInfo?.link)}>{t('licenses.buyMore')}</div>
                            <div onClick={() => navigate(appInfo?.link)}>{t('licenses.changeTarif')}</div>
                        </div>
                    )}
                </Card>
            )}
        </>
    );
}
