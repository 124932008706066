import { action, makeObservable, observable } from 'mobx';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';
import { randomKey } from '@f_utils/randomKey';
import { CustomListModalStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/CustomListModal.store';

export class AssistantAddPositionItemStore {
    customListModalStore: CustomListModalStore;
    customLists: TypeAssistantCustomList[];

    constructor(customListModalStore: CustomListModalStore) {
        this.customListModalStore = customListModalStore;
        this.customLists = [];

        makeObservable(this, {
            customLists: observable,
            handleAddPositionInCustomList: action.bound,
            setCustomLists: action.bound,
        });
    }

    setCustomLists(customLists: TypeAssistantCustomList[]) {
        this.customLists = customLists.filter((list) => list.refKey !== '0');
    }

    handleAddPositionInCustomList({
        item,
        brand,
        article,
        setCurrentAssistantList,
        fetchAddPositionItem,
    }: {
        item: TypeAssistantCustomList;
        brand: string;
        article: string;
        setCurrentAssistantList: (item: TypeAssistantCustomList) => void;
        fetchAddPositionItem: () => void;
    }) {
        setCurrentAssistantList(item);
        this.customListModalStore.setTitle(item.title);
        this.customListModalStore.setCurrentTypeModal(ConfigPanelModalType.ADD);
        this.customListModalStore.setCurrentPositionLoadingOptions(PositionLoadingOptionsEnum.MANUAL_INPUT);
        this.customListModalStore.inputPositionStore.setPosition([
            {
                id: randomKey(),
                autoInput: true,
                brand: brand,
                article: article,
            },
        ]);
        fetchAddPositionItem();
    }
}
