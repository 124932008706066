import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { ControlPanel } from '../СontrolPanel';

export function CellSettings({ info }: any) {
    return (
        <div className={stls.cellName}>
            <ControlPanel
                resultsId={info.row.original.resultsId}
                taskId={info.row.original.taskId}
                info={info.row.original.info}
            />
        </div>
    );
}
