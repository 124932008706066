import { Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/home/Home.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import RefreshIcon from '@mui/icons-material/Refresh';



ChartJS.register(ArcElement, Tooltip, Legend);

interface ContentfProps {
    allUserInfo: any
    getAllInfo: () => Promise<void>
}

export const  Content: React.FC<ContentfProps> = ({allUserInfo, getAllInfo }) => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);


    const [userActive, setUserActive] = useState(0);
    const [userNotActive, setUserNotActive] = useState(0);
    const [userOther, setUserOther] = useState(0);

    useEffect(() => {
        setUserActive(allUserInfo?.filter((user: any) => user?.isActive)?.length);
        setUserNotActive(
            allUserInfo?.filter((user: any) => !user?.isActive && user?.statusUser === 'retair_webka_user')?.length,
        );
        setUserOther(
            allUserInfo?.filter((user: any) => !user?.isActive && user?.statusUser !== 'retair_webka_user')?.length,
        );
    }, [allUserInfo]);

    const data1 = {
        labels: ['Активированные', 'Не активированные', 'Другие не активные'],
        datasets: [
            {
                label: 'Количество',
                data: [userActive, userNotActive, userOther],
                backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
                borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
                borderWidth: 2,
            },
        ],
    };
    

    

    return (
        <div className={stls.container} style={{ display: 'flex', flexWrap: 'wrap' }}>

        <div style={{ width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h3>Пользователи <IconButton onClick={getAllInfo}><RefreshIcon/></IconButton></h3>
            <Doughnut data={data1} />
        </div>
        
    </div>
    );
}
