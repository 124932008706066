import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconExportExcelProps = TypeClassNames;

export const IconExportExcel = ({ classNames }: TypeIconExportExcelProps) => {
    const { colors } = React.useContext(ThemeContext);
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.793 7.5L2.146 5.854L2.854 5.146L4.5 6.793L6.146 5.146L6.854 5.854L5.207 7.5L6.854 9.146L6.146 9.854L4.5 8.207L2.854 9.854L2.146 9.146L3.793 7.5Z"
                    fill={colors.epsilon}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.5 0C3.10218 0 2.72064 0.158035 2.43934 0.43934C2.15804 0.720644 2 1.10218 2 1.5V3H1.5C1.10218 3 0.720644 3.15804 0.43934 3.43934C0.158035 3.72064 0 4.10218 0 4.5L0 10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H2V13.5C2 13.8978 2.15804 14.2794 2.43934 14.5607C2.72064 14.842 3.10218 15 3.5 15H13.5C13.8978 15 14.2794 14.842 14.5607 14.5607C14.842 14.2794 15 13.8978 15 13.5V1.5C15 1.10218 14.842 0.720644 14.5607 0.43934C14.2794 0.158035 13.8978 0 13.5 0L3.5 0ZM1.5 4C1.36739 4 1.24021 4.05268 1.14645 4.14645C1.05268 4.24021 1 4.36739 1 4.5V10.5C1 10.6326 1.05268 10.7598 1.14645 10.8536C1.24021 10.9473 1.36739 11 1.5 11H7.5C7.63261 11 7.75979 10.9473 7.85355 10.8536C7.94732 10.7598 8 10.6326 8 10.5V4.5C8 4.36739 7.94732 4.24021 7.85355 4.14645C7.75979 4.05268 7.63261 4 7.5 4H1.5Z"
                    fill={colors.epsilon}
                />
            </svg>
        </div>
    );
};
