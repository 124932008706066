import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/navbar/Navbar.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Autocomplete, ListItemText, TextField } from '@mui/material';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { ConfigReportContext } from '@f_crossdata/context/ConfigReport_context';

export function Navbar() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { setEnteredData } = useContext(CrossdataContext);
    const { setOpenSettings, getBrands } = useContext(ConfigReportContext);

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [article, setArticle] = useState('');

    const clickHandler = (brand: string) => {
        setOpenSettings(-1);
        setEnteredData((prev: any) => [
            ...prev,
            {
                brand: brand,
                article: article,
            },
        ]);
    };

    useEffect(() => {
        if (inputValue.length >= 3) {
            setTimeout(() => {
                setArticle(inputValue);
            }, 300);
        }
    }, [inputValue]);

    useEffect(() => {
        if (inputValue.length >= 3) {
            if (inputValue === article) {
                getOptions();
            }
        }
    }, [article]);

    const getOptions = async () => {
        const result = await getBrands(article);
        if (result.isOk) {
            setOptions(result.result ?? []);
        }
    };

    const getHighlightedText = (info: any) => {
        const renderArticle = (info: any) => {
            return <span style={{ color: colors.omicron }}>{`${info?.article ? info?.article : article}\u00A0`}</span>;
        };
        const renderBrand = (info: any) => {
            if (!info?.brand) return null;
            return <span style={{ color: colors.alpha }}>{`– ${info?.brand}\u00A0`}</span>;
        };
        const renderPartname = (info: any) => {
            if (!info?.partname) return null;
            return <span style={{ color: colors.omicron }}>{`– ${info?.partname}`}</span>;
        };
        return (
            <span className={stls.item}>
                {renderArticle(info)}
                {renderBrand(info)}
                {renderPartname(info)}
            </span>
        );
    };

    return (
        <div className={stls.container}>
            <Autocomplete
                sx={{
                    width: '400px',
                }}
                options={options}
                autoHighlight
                freeSolo
                filterSelectedOptions={false}
                filterOptions={(x) => x}
                getOptionLabel={(option: any) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option?.inputValue) {
                        return option?.inputValue;
                    }
                    return `${option?.article ? option.article : article} ${option.brand} ${
                        option?.partname ? option?.partname : ''
                    }`;
                }}
                onInputChange={(event, value, reason) => {
                    if (reason === 'input') {
                        setInputValue(value);
                        if (value.length < 3 || !value) {
                            setInputValue('');
                        }
                    } else if (reason === 'clear') {
                        setInputValue('');
                        setOptions([]);
                    }
                }}
                renderOption={(props: any, option: any) => (
                    <span onClick={() => clickHandler(option?.brand)}>
                        <li {...props} key={option?.brand}>
                            <ListItemText secondary={getHighlightedText(option)} />
                        </li>
                    </span>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('crossdata.sendArticle')}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                    />
                )}
            />
        </div>
    );
}
