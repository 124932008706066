import React from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { observer } from 'mobx-react-lite';
import { FilterDeviations } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelFilters/filters/FilterDeviations';
import { FilterVendorsForDating } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelFilters/filters/FilterVendorsForDating';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const ConfigPanelFilters = observer(() => {
    const store = useAssistantStore();
    const { isAllDealsIsTemplate } = store.assistantDeals;
    return !isAllDealsIsTemplate ? (
        <div className={styles.filter}>
            <div className={styles.filtersLeft}>
                <FilterDeviations />
                <FilterVendorsForDating />
            </div>
        </div>
    ) : null;
});
