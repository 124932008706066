import stls from '@f_styles/general/layout/LayoutMenuPage.module.sass';
import { Wrapper } from '@f_general/Wrapper';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';

type TypeLayoutMenuPageProps = {
    children: any;
    title: string;
};

export function LayoutMenuPage({ children, title }: TypeLayoutMenuPageProps) {
    const { colors } = useContext(ThemeContext);

    return (
        <div style={{ backgroundColor: colors.bg }} className={stls.container}>
            <Wrapper>
                <p className={stls.title} style={{ color: colors.omicron }}>
                    {title}
                </p>
                <>{children}</>
            </Wrapper>
        </div>
    );
}
