import React, { useContext, useEffect, useState } from 'react';

const ManualContext = React.createContext<any>({});

function ManualProvider(props: any) {
    const [library, setLibrary] = useState<any>([
        {
            id: 1,
            name: 'Компания и пользователи',
            content: [
                'Добавить коллегу/пользователя в приложение',
                'Удалить пользователя',
                'Сменить Роль пользователя',
                'Восстановить пароль пользователя',
                'Добавить дочернюю компанию',
            ],
        },
    ]);

    return (
        <ManualContext.Provider
            value={{
                library,
            }}
            {...props}
        />
    );
}

export { ManualProvider, ManualContext };
