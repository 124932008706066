import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { getBestPrice } from '@f_ucs/utils/getBestPrice'

export function ColumnPerfectPrice ({ original, index }:any, idRow:any, isOpenDetails:any,)  {
    const { colors } = useContext(ThemeContext);

    return (
        <div
            style={{
                border: `0.5px solid ${colors.lightgreen}`,
                background: 'rgba(105, 222, 148, 0.2)',
                color:
                    idRow !== index && isOpenDetails ? colors.blurColor : colors.omicron,
            }}
            className={stls.cellPrice}>
            {getBestPrice(original)}
        </div>
    )
}