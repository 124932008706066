import React from 'react';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';

interface IAssistantAvailabilityColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    isTemplateItem: any;
    availability: any;
    foundItem: any;
}

export const AssistantAvailabilityColumn = (props: IAssistantAvailabilityColumnProps) => {
    const { foundItem, colors, availability, isTemplateItem } = props;
    return (
        <p
            style={{
                color: foundItem ? colors.omicron : colors.sigma,
                display: 'flex',
                justifyContent: 'center',
            }}>
            {availability && !isTemplateItem ? availability : ''}
        </p>
    );
};
