import cn from 'classnames';
import React from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';

type TypeIconInfoTableProps = TypeClassNames;

export const IconInfoTable = ({ classNames }: TypeIconInfoTableProps) => {
    const { colors } = React.useContext(ThemeContext);
    return (
        <div className={cn(classNames)}>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.14502 16.25C3.72662 16.25 0.14502 12.6684 0.14502 8.25C0.14502 3.8316 3.72662 0.25 8.14502 0.25C12.5634 0.25 16.145 3.8316 16.145 8.25C16.145 12.6684 12.5634 16.25 8.14502 16.25ZM7.34502 7.45V12.25H8.94502V7.45H7.34502ZM7.34502 4.25V5.85H8.94502V4.25H7.34502Z"
                    fill={colors.phi}
                />
            </svg>
        </div>
    );
};
