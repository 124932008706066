import { Checkbox, CircularProgress } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { BasketContext } from '@f_qwep/context/Basket_context';
import stls from '../../../styles/component/basket/MiniBasket.module.sass';
import { IconUpdatePrice } from '../../icons/IconUpdatePrice';
import { Alert } from '@f_general/alert';
import { IconClose } from '@f_components/icons/IconClose';
import GeneralModal from '@f_general/GeneralModal';
import { QwepContext } from '../../../context/Qwep_context';
import { inRange } from 'lodash';

export function CellDateGeneralBasket({ info, checkedItems, setCheckedItems }: any) {
    const handleChange = (e: any) => {
        if (e) {
            setCheckedItems((prev: number[]) => ({
                ...prev,
                [info.row.original.accountId]: info.row.original.basketItems.map((item: any) => +item.basketItemId),
            }));
        } else {
            setCheckedItems((prev: number[]) => ({
                ...prev,
                [info.row.original.accountId]: [],
            }));
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {info.row.original.hasPartOrder && (
                <Checkbox
                    onChange={({ target }) => handleChange(target.checked)}
                    checked={checkedItems[info.row.original.accountId].length === info.row.original.basketItems?.length}
                />
            )}
            {/* <button {...{
                onClick: info.row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
            }} 
            style={{ width: '100%', display: 'flex', justifyContent: 'center', zIndex: 1000}}>
                {info.row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
            </button> */}
        </div>
    );
}

export function CellArticleGeneralBasket({ info, mobile, cartTimes }: any) {
    const { getCartVendor } = useContext(BasketContext);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const clickHandler = async () => {
        setLoading(true);
        await getCartVendor(info.row.original.accountId);
        setLoading(false);
    };

    return (
        <div className={stls.updateButton}>
            {mobile ? (
                <IconUpdatePrice />
            ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button style={{ color: colors.alpha, background: colors.upsilon }} onClick={clickHandler}>
                        {t('qwep.basket.update')}
                    </button>
                    {loading ? (
                        <CircularProgress size={17} />
                    ) : (
                        <p className={stls.cellUpdate}>{cartTimes[info.row.original.accountId]}</p>
                    )}
                </div>
            )}
        </div>
    );
}

export function CellGeneralBasketTotal({ info }: any) {
    const { t } = useTranslation();

    const { colors, currentTheme } = useContext(ThemeContext);
    const { priceMarginIsActive, priceMarginState } = useContext(QwepContext);

    const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

    const getPriceWithMargin = (price: any) => {
        const currentPrice = Math.ceil(parseFloat(price));
        const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule.to));
        if (currentRules?.length > 0) {
            const rule = currentRules?.[0];
            return currentPrice + (currentPrice / 100) * rule.addPercent;
        }
        return price;
    };

    const basketSum = info.row.original.basketItems.reduce(
        (ac: any, cur: any) => (priceMarginIsActive ? ac + getPriceWithMargin(+cur.priceTotal) : ac + +cur.priceTotal),
        0,
    );

    return (
        <div className={stls.cellDiv} style={{ display: 'flex', alignItems: 'center' }}>
            <p
                style={{
                    background: colors.pi,
                    border: `0.5px solid ${colors.gamma}`,
                    color: currentTheme == 'light' ? colors.omicron : colors.gamma,
                }}
                className={stls.cellPrice}>
                {basketSum.toLocaleString()} ₽
            </p>
        </div>
    );
}

export function CellTotal({ info }: any) {
    const { priceMarginIsActive } = useContext(QwepContext);
    const { colors, currentTheme } = useContext(ThemeContext);
    const price = priceMarginIsActive
        ? Number(info.row.original.priceWithMargin) * Number(info.row.original.quantity)
        : +info.row.original.priceTotal;

    return (
        <div className={stls.cellDiv}>
            <p
                style={{
                    background: colors.pi,
                    border: `0.5px solid ${colors.gamma}`,
                    color: currentTheme == 'light' ? colors.omicron : colors.gamma,
                }}
                className={stls.cellPrice}>
                {(+price).toLocaleString()} ₽
            </p>
        </div>
    );
}

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function CellGeneralBasketDelete({ info }: any) {
    const { t } = useTranslation();
    const { deleteCart } = useContext(BasketContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [dataDeleteModal, setDataDeleteModal] = useState({});

    const [loadingDelete, setLoadingDelete] = useState(false);

    const deleteHandler = () => {
        setDataDeleteModal({
            icon: '',
            title: `${'Удалить корзину'}: ${info.row.original.vendorTitle}`,
            props: [],
            shema: null,
            type: 'deleteAccount',
            data: {
                accountId: info.row.original.accountId,
                basketId: +info.row.original.basketId,
            },
        } as TypeDataModal);
        setOpenDelete(true);
    };

    const sendDataModalHandler = async (values: any) => {
        setLoadingDelete(true);
        const result = await deleteCart({
            accountId: values.accountId,
            basketId: values.basketId,
        });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            closeDeleteHandler();
        } else {
            if (result.result.errors?.length) {
                result.result.errors.map((item: any) => {
                    Alert({
                        type: 'warning',
                        text: item.message,
                        time: 8000,
                    });
                });
                closeDeleteHandler();
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        }
        setLoadingDelete(false);
    };

    const closeDeleteHandler = async () => {
        setOpenDelete(false);
        setDataDeleteModal({});
    };

    return (
        <div style={{ display: 'flex' }}>
            <button onClick={deleteHandler}>
                <IconClose />
            </button>

            <GeneralModal
                openModal={openDelete}
                closeModal={closeDeleteHandler}
                sendDataModalHandler={sendDataModalHandler}
                dataModal={dataDeleteModal}
                loading={loadingDelete}
            />
        </div>
    );
}
