import { CardMedia } from '@mui/material';
import stls from '@f_qwep/styles/component/info/Info.module.sass';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { IconStartJob } from '../icons/IconStartJob';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { videoContent } from '@f_utils/content/video.content';

export function Info() {
    const { colors, currentTheme } = useContext(ThemeContext);

    const mainInfo = [
        'Пройти в "Настройки" и открыть раздел "Мои аккаунты"',
        'Через кнопку "Добавить поставщика" найти нужного поставщика',
        'Ввести логин/пароль согласно инструкции и авторизироваться',
        'Поставщик готов для поиска',
        '3-4 шаг проделать единоразово со всеми необходимыми для вас поставщиками.',
    ];

    const qwepInstruction = videoContent.newMainInstructions.rutube;
    const vinPinInstruction = videoContent.vinPinInstructions.rutube;

    return (
        <LayoutPage classNames={[stls.container]}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconStartJob />
                <h2 style={{ marginLeft: '16px' }}>Для начала работы вам необходимо:</h2>
            </div>
            <div style={{ display: 'flex', maxWidth: '800px', margin: '30px auto' }}>
                {mainInfo.map((el: any, idx: any) => (
                    <div key={idx} style={{ width: '20%', marginRight: '20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p
                                style={{
                                    minWidth: '32px',
                                    height: '32px',
                                    background:
                                        currentTheme == 'dark'
                                            ? 'rgb(105, 222, 148, 0.2)'
                                            : currentTheme == 'light'
                                            ? '#E5F8EB'
                                            : 'white',
                                    borderRadius: '50px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '8px',
                                    color: colors.gamma,
                                    fontWeight: 'bold',
                                }}>
                                {idx + 1}
                            </p>
                            <hr style={{ width: '100%', border: `1px dashed ${colors.gamma}` }} />
                        </div>
                        <p style={{ fontSize: '14px', margin: '12px 0' }}>{el}</p>
                    </div>
                ))}
            </div>
            <div className={stls.vidio}>
                <CardMedia
                    className={stls.media}
                    component="iframe"
                    allow={'fullscreen'}
                    image={currentTheme == 'vinpin' ? vinPinInstruction : qwepInstruction}
                    src={currentTheme == 'vinpin' ? vinPinInstruction : qwepInstruction}
                />
            </div>
        </LayoutPage>
    );
}
