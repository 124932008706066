import stls from '@f_qwep/styles/component/tables/miniSearchResultTable/ColumnStyle.module.sass';
import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';

export function CellAvailability({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);

    const quantity = info.row.original.quantity;
    const status = info.row.original.status;
    const quantityStyle = status === 'В наличии' ? stls.many : stls.few;
    const quantityColor = status === 'В наличии' ? colors.pi : colors.delta;
    const textColor = status === 'В наличии' ? colors.gamma : colors.epsilon;

    return (
        <p
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
            className={cn(stls.cellAvailability, quantityStyle)}>
            {quantity.formatted}
        </p>
    );
}
