import stls from '@f_styles/general/layout/LayoutMenuPage.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { Wrapper } from '@f_general/Wrapper';
import { useContext } from 'react';

type TypeLayoutPageProps = {
    children: any;
    title: string;
};

export function LayoutPage({ children, title }: TypeLayoutPageProps) {
    const { colors, currentTheme } = useContext(ThemeContext);
    return (
        <div
            style={{ backgroundColor: currentTheme == 'dark' ? colors.bg : colors.deltaBg }}
            className={stls.container}>
            <Wrapper>
                <p className={stls.title} style={{ color: colors.omicron }}>
                    {title}
                </p>
                <>{children}</>
            </Wrapper>
        </div>
    );
}
