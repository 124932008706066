import { useContext, useEffect, useState } from 'react';
import { QwepContext, TypePromoNotification } from '@f_qwep/context/Qwep_context';
import { Property, Tariff } from '@f_qwep/types';
import { isEmpty } from 'lodash';
import { Content } from './promoAssistant/Content';

enum NotificationTitle {
    PromoAssistant = 'promo_assistant',
}

type TypeContent = {
    title: string;
    content: React.ReactNode;
};

export function ModalPromoNotification() {
    const { tariff, promoNotification, updateProperty, workspaceApplication } = useContext(QwepContext);
    const [content, setContent] = useState<TypeContent[]>([]);

    useEffect(() => {
        if (promoNotification && tariff) {
            promoNotification.map((item: TypePromoNotification) => {
                switch (item.title) {
                    case NotificationTitle.PromoAssistant:
                        createPromoAssistantHandler(item, tariff);
                        break;
                }
            });
        }
    }, [promoNotification, tariff]);

    const closeNotificationHandler = async (title: string) => {
        await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.PromoNotification,
            value: {
                value: promoNotification.map((item: TypePromoNotification) => {
                    if (item.title === title) {
                        return { ...item, active: false };
                    }
                }),
            },
        });
    };

    const createPromoAssistantHandler = (item: TypePromoNotification, tariff: Tariff) => {
        if (!item.active) return;
        if (![Tariff.PROPlus].some((t) => t === tariff)) return;

        setContent((prev) => [
            ...prev,
            {
                title: item.title,
                content: <Content active={item.active} inactiveHandler={() => closeNotificationHandler(item.title)} />,
            },
        ]);
    };

    return (
        <>
            {!isEmpty(promoNotification) &&
                content.map((item) => {
                    return item.content;
                })}
        </>
    );
}
