import { makeAutoObservable } from 'mobx';
import { randomKey } from '@f_utils/randomKey';

export interface IPosition {
    id: number;
    article: string;
    brand: string;
    autoInput: boolean;
}

const defaultPosition: IPosition = {
    id: randomKey(),
    article: '',
    brand: '',
    autoInput: true,
};

export class CustomListInputPositionsModalStore {
    position: IPosition[];
    constructor() {
        this.position = [defaultPosition];
        makeAutoObservable(this, {}, { autoBind: true });
    }

    addPosition() {
        this.setPosition([...this.position, { ...defaultPosition, id: randomKey() }]);
    }

    addPositionItem(option: any, id: number) {
        this.setPosition(
            this.position.map((item) =>
                item.id === id ? { ...item, article: option.article, brand: option.brand } : item,
            ),
        );
    }

    removePosition(id: Pick<IPosition, 'id'>['id']) {
        this.setPosition(this.position.filter((item) => item.id !== id));
    }

    changeInputPositionDouble(value: string, id: Pick<IPosition, 'id'>['id'], type: 'brand' | 'article') {
        this.setPosition(this.position.map((item) => (item.id === id ? { ...item, [type]: value } : item)));
    }

    changeInputPosition(id: Pick<IPosition, 'id'>['id'], value: boolean) {
        this.setPosition(this.position.map((item) => (item.id === id ? { ...item, autoInput: value } : item)));
    }

    setPosition(position: IPosition[]) {
        this.position = position;
    }

    resetPosition() {
        this.setPosition([defaultPosition]);
    }
}
