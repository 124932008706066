import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TFunction } from 'i18next';
import { IconDealerStock } from '@f_qwep/components/icons/IconDealerStock';

interface IFilterLeftGlobalFilterProps {
    filtersReducer: any;
    filter: any;
    currentTheme: any;
    stls: any;
    t: TFunction<'translation', undefined>;
    colors: any;
}

export const FilterLeftGlobalFilter: FC<IFilterLeftGlobalFilterProps> = observer(
    ({ filtersReducer, currentTheme, stls, filter, t, colors }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                <span id="searchArticle" style={{ display: ' flex' }}>
                    <input
                        onChange={() => {
                            filtersReducer({ type: 'search_article_toggle' });
                        }}
                        className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                        checked={filter?.searchArticle}
                        type="checkbox"
                        id="partname"
                        name="partname"
                    />
                    <label htmlFor="partname" className={stls.checkboxLabel}>
                        {t('qwep.search.searchArticle')}
                    </label>
                </span>
                <span id="searchInStock" style={{ display: 'flex' }}>
                    <input
                        onChange={() => {
                            filtersReducer({ type: 'in_stock_toggle' });
                        }}
                        className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                        checked={filter?.inStock}
                        type="checkbox"
                        id="in_stock"
                        name="in_stock"
                    />
                    <label htmlFor="in_stock" className={stls.checkboxLabel}>
                        {t('qwep.search.inStockFilter')}
                    </label>
                </span>
                <span id="searchApprovedCross" style={{ display: ' flex' }}>
                    <input
                        onChange={() => {
                            filtersReducer({ type: 'approved_cross_vendor_toggle' });
                        }}
                        className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                        checked={filter?.approvedCross}
                        type="checkbox"
                        id="approved_cross"
                        name="approved_cross"
                    />
                    <label htmlFor="approved_cross" className={stls.checkboxLabel}>
                        {t('qwep.search.approvedCross')}
                    </label>
                </span>
                <span id="isDealerWarehouse" style={{ display: ' flex' }}>
                    <input
                        onChange={() => {
                            filtersReducer({ type: 'isDealer_toggle' });
                        }}
                        className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                        checked={filter?.isDealer}
                        type="checkbox"
                        id="dealer_warehouse"
                        name="dealer_warehouse"
                    />
                    <label htmlFor="dealer_warehouse" className={stls.checkboxLabel}>
                        <span style={{ marginRight: '3px' }}>
                            <IconDealerStock color={colors.green} />
                            &nbsp;
                            {t('qwep.search.dealer_warehouse')}
                        </span>
                    </label>
                </span>
            </div>
        );
    },
);
