import { Box, Button, CardContent, TextField, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/general/GeneraCreateUserModal.module.sass';
import { useTranslation } from 'react-i18next';
import { FormikWrapper } from './formik/FormikWrapper';
import { FormikController } from './formik/FormikController';
import { UserContext } from '@f_context/User_context';
import { Alert } from './alert';
import GeneralForm from './GeneralForm';
import { Role } from '@f_types/TypeRole';
import { IconUsersTariff } from '@f_components/icons/IconUsersTariff';
import { addUserToCompanyModalCustomerSchema } from './formik/helpers/ValidationSchema';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { IconAddUserModal } from '@f_admin/components/icons/IconAddUserModal';

type TypeDataProps = {
    open: boolean;
    closeModal: any;
    type: string;
    workspaceId: string;
    workspaceName: string;
    applications: any;
    addExistingUser: any;
    addNewUser: any;
    role?: any;
};

export default function GeneraCreateUserModal({
    open,
    applications,
    closeModal,
    addExistingUser,
    workspaceId,
    addNewUser,
    type,
    role,
    workspaceName,
}: TypeDataProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const [formIndex, setFormIndex] = useState(0);
    const [userInfo, setUserInfo] = useState<any>({});
    const { findUser } = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const startIndex = () => {
        setFormIndex(0);
        setUserInfo({});
    };

    const closeHandler = () => {
        closeModal();
        setFormIndex(0);
        setUserInfo({});
        setLoading(false);
    };

    const sendEmailHandler = async (values: any) => {
        const result = await findUser(values.email, workspaceId);
        if (result.isOk) {
            if (result.statusNumber === 1) {
                Alert({
                    type: 'info',
                    text: 'Пользователь уже зарегестрирован в приложении',
                    time: 8000,
                });
                setUserInfo(result.result);
                setFormIndex(result.statusNumber);
            }
            if (result.statusNumber === 2) {
                Alert({
                    type: 'info',
                    text: 'Такаго пользователя нет',
                    time: 8000,
                });
            }
            setUserInfo({ email: values.email });
            setFormIndex(result.statusNumber);
        } else {
            if (result.statusNumber === 3) {
                Alert({
                    type: 'warning',
                    text: 'Пользователь уже есть в этой компании',
                    time: 8000,
                });
            }
        }
    };

    const sendExistingUserHandler = async (values: any) => {
        setLoading(true);
        let apps: any = [];

        if (type === 'admin') {
            Object.keys(values).map((key: any) => {
                applications.map((app: any) => {
                    if (app.propertyApplication.sysNameApplication === key && values[key]) {
                        apps.push(app.application.applicationId);
                    }
                });
            });
        } else {
            Object.keys(values).map((key: any) => {
                applications.map((app: any) => {
                    if (app.applicationSysName === key && values[key]) {
                        apps.push(app.applicationId);
                    }
                });
            });
        }
        if (apps.length) {
            const result = await addExistingUser({
                email: userInfo.email,
                role: values.role,
                workspaceId: workspaceId,
                applications: apps,
            });
            if (result.isOk) {
                closeHandler();
                Alert({
                    type: 'success',
                    text: `${t('myCompany.alert.addUsersSuccess')} ${result.result}`,
                    time: 6000,
                });
            } else {
                if (result.statusNumber === 1) {
                    Alert({
                        type: 'error',
                        text: t('myCompany.alert.addExistingUserBlockedError'),
                        time: 6000,
                    });
                } else if (result.statusNumber === 2) {
                    Alert({
                        type: 'error',
                        text: 'Письмо не было отправлено',
                        time: 6000,
                    });
                } else if (result.statusNumber === 3) {
                    Alert({
                        type: 'error',
                        text: t('myCompany.alert.addUsersEmailError'),
                        time: 6000,
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
            }
        } else {
            Alert({
                type: 'warning',
                text: 'Нельзя создать пользователя без приложений',
                time: 6000,
            });
        }
        setLoading(false);
    };

    const sendAddUserHandler = async (values: any) => {
        setLoading(true);
        let apps: any = [];
        if (type === 'admin') {
            Object.keys(values).map((key: any) => {
                applications.map((app: any) => {
                    if (app.propertyApplication.sysNameApplication === key && values[key]) {
                        apps.push(app.application.applicationId);
                    }
                });
            });
        } else {
            Object.keys(values).map((key: any) => {
                applications.map((app: any) => {
                    if (app.applicationSysName === key && values[key]) {
                        apps.push(app.applicationId);
                    }
                });
            });
        }

        if (apps.length) {
            const result = await addNewUser({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                position: values.position,
                role: values.role,
                password: values.password,
                applications: apps,
                workspaceId: workspaceId,
                type: type,
            });
            if (result.isOk) {
                closeHandler();
                Alert({
                    type: 'success',
                    text: `${t('myCompany.alert.addUsersSuccess')} ${result.result}`,
                    time: 6000,
                });
            } else {
                if (result.statusNumber === 1) {
                    Alert({
                        type: 'error',
                        text: 'Пользователь не был создан',
                        time: 6000,
                    });
                } else if (result.statusNumber === 3) {
                    Alert({
                        type: 'error',
                        text: t('myCompany.alert.addUsersEmailError'),
                        time: 6000,
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
            }
        } else {
            Alert({
                type: 'warning',
                text: 'Нельзя создать пользователя без приложений',
                time: 6000,
            });
        }
        setLoading(false);
    };

    return (
        <>
            {open && (
                <Modal open={open} onClose={closeHandler}>
                    <Box className={stls.modal} style={{ background: colors.theta }}>
                        <CardContent className={stls.modalContent}>
                            {formIndex === 0 ? (
                                <div className={stls.form}>
                                    <FormikWrapper
                                        icon={<IconAddUserModal />}
                                        title={`Добавление пользователя в компанию ${workspaceName}`}
                                        onSubmit={sendEmailHandler}
                                        initialValues={{ email: '' }}
                                        validShema={null}>
                                        <div>
                                            <Typography className={stls.modalTypography}>
                                                Введите Email для поиска пользователя на платформе QWEP
                                            </Typography>
                                            <FormikController
                                                className={stls.inputModal}
                                                control="input"
                                                type={'text'}
                                                name={'email'}
                                                placeholder={'Email'}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                required
                                            />
                                        </div>
                                    </FormikWrapper>
                                </div>
                            ) : formIndex === 1 ? (
                                <div className={stls.form}>
                                    <GeneralForm
                                        sendDataHandler={sendExistingUserHandler}
                                        close={closeModal}
                                        mainData={{
                                            icon: <IconAddUserModal />,
                                            title: (
                                                <div className={stls.title}>
                                                    Добавить существующего пользователя
                                                    <Button
                                                        onClick={startIndex}
                                                        type={'button'}
                                                        size={'small'}
                                                        variant={'contained'}
                                                        className={stls.btn}>
                                                        <ArrowLeftIcon style={{ color: '#fff' }} />
                                                        Назад
                                                    </Button>
                                                </div>
                                            ),
                                            props: [
                                                {
                                                    title: `Найден пользователь ${userInfo.email}`,
                                                    control: 'text',
                                                    content: 'Вы можете пригласить пользователя в текущую компанию',
                                                },
                                                (() => {
                                                    let apps = '';
                                                    if (type === 'admin') {
                                                        applications.map((item: any) => {
                                                            if (
                                                                item.tariff &&
                                                                !(
                                                                    item.propertyApplication.maxLimit -
                                                                        item.propertyApplication.currentLimit >
                                                                    0
                                                                )
                                                            ) {
                                                                apps += `${item.applicationName} `;
                                                            }
                                                        });
                                                    } else {
                                                        applications.map((item: any) => {
                                                            if (
                                                                item.tariff !== 'UNLIMIT' &&
                                                                !(item.maxLimit - item.currentLimit > 0)
                                                            ) {
                                                                apps += `${item.applicationName} `;
                                                            }
                                                        });
                                                    }

                                                    if (apps) {
                                                        return {
                                                            title: `Внимание!`,
                                                            control: 'text',
                                                            content: `Нет свободных лицензии на приложения: ${apps}Вы можете докупить свободные лицензии в разделе «Лицензии и оплата»`,
                                                        };
                                                    }
                                                })(),
                                                {
                                                    control: 'select',
                                                    name: 'role',
                                                    title: 'Выберите роль пользователя',
                                                    defaultValue: Role.User,
                                                    options:
                                                        type === 'admin'
                                                            ? [
                                                                  {
                                                                      value: Role.User,
                                                                      key: t('userRole.user'),
                                                                  },
                                                                  {
                                                                      value: Role.Admin,
                                                                      key: t('userRole.admin'),
                                                                  },
                                                                  {
                                                                      value: Role.Manager,
                                                                      key: t('userRole.manager'),
                                                                  },
                                                                  {
                                                                      value: Role.SUPER_ADMIN,
                                                                      key: t('userRole.superAdmin'),
                                                                  },
                                                              ]
                                                            : (() => {
                                                                  let opt: any = [];
                                                                  if (
                                                                      role.some((item: string) => item === Role.Admin)
                                                                  ) {
                                                                      opt = [
                                                                          { key: t('userRole.user'), value: Role.User },
                                                                          {
                                                                              key: t('userRole.admin'),
                                                                              value: Role.Admin,
                                                                          },
                                                                      ];
                                                                  }
                                                                  if (
                                                                      role.some((item: string) => item === Role.Manager)
                                                                  ) {
                                                                      opt = [
                                                                          { key: t('userRole.user'), value: Role.User },
                                                                          {
                                                                              key: t('userRole.admin'),
                                                                              value: Role.Admin,
                                                                          },
                                                                          {
                                                                              key: t('userRole.manager'),
                                                                              value: Role.Manager,
                                                                          },
                                                                      ];
                                                                  }
                                                                  if (
                                                                      role.some(
                                                                          (item: string) => item === Role.SUPER_ADMIN,
                                                                      )
                                                                  ) {
                                                                      opt = [
                                                                          { key: t('userRole.user'), value: Role.User },
                                                                          {
                                                                              key: t('userRole.admin'),
                                                                              value: Role.Admin,
                                                                          },
                                                                          {
                                                                              key: t('userRole.manager'),
                                                                              value: Role.Manager,
                                                                          },
                                                                          {
                                                                              key: t('userRole.superAdmin'),
                                                                              value: Role.SUPER_ADMIN,
                                                                          },
                                                                      ];
                                                                  }
                                                                  return opt;
                                                              })(),
                                                },
                                                ...applications.map((item: any) => ({
                                                    control: 'checkbox',
                                                    title: 'Application',
                                                    name:
                                                        type === 'admin'
                                                            ? item.propertyApplication.sysNameApplication
                                                            : item.applicationSysName,
                                                    type: 'checkbox',
                                                    disabled: (() => {
                                                        if (type === 'admin') {
                                                            return item.propertyApplication.tariff
                                                                ? !(
                                                                      item.propertyApplication.maxLimit -
                                                                          item.propertyApplication.currentLimit >
                                                                      0
                                                                  )
                                                                : false;
                                                        } else {
                                                            return item.tariff !== 'UNLIMIT'
                                                                ? !(item.maxLimit - item.currentLimit > 0)
                                                                : false;
                                                        }
                                                    })(),
                                                    options: [
                                                        {
                                                            key:
                                                                type === 'admin'
                                                                    ? item.application.applicationName
                                                                    : item.applicationName,
                                                            value: false,
                                                        },
                                                    ],
                                                })),
                                            ],
                                            shema: null,
                                            type: 'addExistingUser',
                                            data: {
                                                email: userInfo.email,
                                                userId: userInfo.userId,
                                                role: Role.User,
                                                ...(() => {
                                                    let apps = {};
                                                    applications.map((item: any) => {
                                                        apps = {
                                                            ...apps,
                                                            [type === 'admin'
                                                                ? item.propertyApplication.sysNameApplication
                                                                : item.applicationSysName]: false,
                                                        };
                                                    });
                                                    return apps;
                                                })(),
                                            },
                                        }}
                                        loading={loading}
                                    />
                                </div>
                            ) : formIndex === 2 ? (
                                <div>
                                    <div className={stls.form}>
                                        <GeneralForm
                                            sendDataHandler={sendAddUserHandler}
                                            close={closeModal}
                                            mainData={{
                                                icon: <IconAddUserModal />,
                                                title: (
                                                    <div className={stls.title}>
                                                        {t('modal.addUser.title')}
                                                        <Button
                                                            onClick={startIndex}
                                                            type={'button'}
                                                            size={'small'}
                                                            variant={'contained'}
                                                            className={stls.btn}>
                                                            <ArrowLeftIcon style={{ color: '#fff' }} />
                                                            Назад
                                                        </Button>
                                                    </div>
                                                ),
                                                props: [
                                                    {
                                                        title: `Пользователь ${userInfo.email} не найден`,
                                                        control: 'text',
                                                        content:
                                                            'Вы можете добавить нового пользователя в текущую компанию',
                                                    },
                                                    (() => {
                                                        let apps = '';
                                                        if (type === 'admin') {
                                                            applications.map((item: any) => {
                                                                if (
                                                                    item.tariff &&
                                                                    !(
                                                                        item.propertyApplication.maxLimit -
                                                                            item.propertyApplication.currentLimit >
                                                                        0
                                                                    )
                                                                ) {
                                                                    apps += `${item.applicationName} `;
                                                                }
                                                            });
                                                        } else {
                                                            applications.map((item: any) => {
                                                                if (
                                                                    item.tariff !== 'UNLIMIT' &&
                                                                    !(item.maxLimit - item.currentLimit > 0)
                                                                ) {
                                                                    apps += `${item.applicationName} `;
                                                                }
                                                            });
                                                        }

                                                        if (apps) {
                                                            return {
                                                                title: `Внимание!`,
                                                                control: 'text',
                                                                content: `Нет свободных лицензии на приложения: ${apps}Вы можете докупить свободные лицензии в разделе «Лицензии и оплата»`,
                                                            };
                                                        }
                                                    })(),
                                                    {
                                                        title: t('modal.addUser.firstName'),
                                                        control: 'input',
                                                        name: 'firstName',
                                                        type: 'text',
                                                        placeholder: t('modal.addUser.placeholderFirstName'),
                                                    },
                                                    {
                                                        title: t('modal.addUser.lastName'),
                                                        control: 'input',
                                                        name: 'lastName',
                                                        type: 'text',
                                                        placeholder: t('modal.addUser.placeholderLastName'),
                                                    },
                                                    {
                                                        title: 'E-mail*',
                                                        control: 'input',
                                                        name: 'email',
                                                        type: 'text',
                                                        defaultValue: userInfo.email,
                                                        placeholder: 'E-mail',
                                                    },
                                                    type === 'admin' && {
                                                        title: 'Password*',
                                                        control: 'input',
                                                        name: 'password',
                                                        type: 'text',
                                                        placeholder: 'password',
                                                    },
                                                    {
                                                        title: t('modal.addUser.phoneNumber'),
                                                        control: 'input',
                                                        name: 'phoneNumber',
                                                        type: 'text',
                                                        placeholder: '+7 (999) 000-00-00',
                                                    },
                                                    {
                                                        title: t('modal.addUser.position'),
                                                        control: 'input',
                                                        name: 'position',
                                                        type: 'text',
                                                        placeholder: t('modal.addUser.placeholderPosition'),
                                                    },
                                                    {
                                                        control: 'select',
                                                        name: 'role',
                                                        title: t('modal.userTableModal.userRole'),
                                                        defaultValue: 'user',
                                                        options:
                                                            type === 'admin'
                                                                ? [
                                                                      {
                                                                          value: Role.User,
                                                                          key: t('userRole.user'),
                                                                      },
                                                                      {
                                                                          value: Role.Admin,
                                                                          key: t('userRole.admin'),
                                                                      },
                                                                      {
                                                                          value: Role.Manager,
                                                                          key: t('userRole.manager'),
                                                                      },
                                                                      {
                                                                          value: Role.SUPER_ADMIN,
                                                                          key: t('userRole.superAdmin'),
                                                                      },
                                                                  ]
                                                                : (() => {
                                                                      let opt: any = [];
                                                                      if (
                                                                          role.some(
                                                                              (item: string) => item === Role.Admin,
                                                                          )
                                                                      ) {
                                                                          opt = [
                                                                              {
                                                                                  key: t('userRole.user'),
                                                                                  value: Role.User,
                                                                              },
                                                                              {
                                                                                  key: t('userRole.admin'),
                                                                                  value: Role.Admin,
                                                                              },
                                                                          ];
                                                                      }
                                                                      if (
                                                                          role.some(
                                                                              (item: string) => item === Role.Manager,
                                                                          )
                                                                      ) {
                                                                          opt = [
                                                                              {
                                                                                  key: t('userRole.user'),
                                                                                  value: Role.User,
                                                                              },
                                                                              {
                                                                                  key: t('userRole.admin'),
                                                                                  value: Role.Admin,
                                                                              },
                                                                              {
                                                                                  key: t('userRole.manager'),
                                                                                  value: Role.Manager,
                                                                              },
                                                                          ];
                                                                      }
                                                                      if (
                                                                          role.some(
                                                                              (item: string) =>
                                                                                  item === Role.SUPER_ADMIN,
                                                                          )
                                                                      ) {
                                                                          opt = [
                                                                              {
                                                                                  key: t('userRole.user'),
                                                                                  value: Role.User,
                                                                              },
                                                                              {
                                                                                  key: t('userRole.admin'),
                                                                                  value: Role.Admin,
                                                                              },
                                                                              {
                                                                                  key: t('userRole.manager'),
                                                                                  value: Role.Manager,
                                                                              },
                                                                              {
                                                                                  key: t('userRole.superAdmin'),
                                                                                  value: Role.SUPER_ADMIN,
                                                                              },
                                                                          ];
                                                                      }
                                                                      return opt;
                                                                  })(),
                                                    },
                                                    ...applications.map((item: any) => ({
                                                        control: 'checkbox',
                                                        title: t('adminPanel.application'),
                                                        name:
                                                            type === 'admin'
                                                                ? item.propertyApplication.sysNameApplication
                                                                : item.applicationSysName,
                                                        type: 'checkbox',
                                                        disabled: (() => {
                                                            if (type === 'admin') {
                                                                return item.propertyApplication.tariff
                                                                    ? !(
                                                                          item.propertyApplication.maxLimit -
                                                                              item.propertyApplication.currentLimit >
                                                                          0
                                                                      )
                                                                    : false;
                                                            } else {
                                                                return item.tariff !== 'UNLIMIT'
                                                                    ? !(item.maxLimit - item.currentLimit > 0)
                                                                    : false;
                                                            }
                                                        })(),
                                                        options: [
                                                            {
                                                                key:
                                                                    type === 'admin'
                                                                        ? item.application.applicationName
                                                                        : item.applicationName,
                                                                value: false,
                                                            },
                                                        ],
                                                    })),
                                                ],
                                                shema: addUserToCompanyModalCustomerSchema,
                                                type: 'addUser',
                                                data: {
                                                    firstName: '',
                                                    lastName: '',
                                                    email: userInfo.email,
                                                    phoneNumber: '',
                                                    position: '',
                                                    role: Role.User,
                                                    password: '',
                                                    ...(() => {
                                                        let apps = {};
                                                        applications.map((item: any) => {
                                                            apps = {
                                                                ...apps,
                                                                [type === 'admin'
                                                                    ? item.propertyApplication.sysNameApplication
                                                                    : item.applicationSysName]: false,
                                                            };
                                                        });
                                                        return apps;
                                                    })(),
                                                },
                                            }}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </CardContent>
                    </Box>
                </Modal>
            )}
        </>
    );
}
