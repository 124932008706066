import { useLocalStorage } from '@f_hooks';
import { createContext, useContext, useEffect, useState } from 'react';
import { UserContext } from './User_context';

const ApplicationContext = createContext({});

function ApplicationProvider(props: any) {
    return <ApplicationContext.Provider value={{}} {...props} />;
}

export { ApplicationProvider, ApplicationContext };
