import React from 'react';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';
import cn from 'classnames';

interface IAssistantArticleColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    foundItem: any;
    article: any;
}

export const AssistantArticleColumn = (props: IAssistantArticleColumnProps) => {
    const { foundItem, colors, styles, article } = props;

    return (
        <p style={{ color: foundItem ? colors.alpha : colors.sigma }} className={cn(styles.cellRow, styles.fwBold)}>
            {article}
        </p>
    );
};
