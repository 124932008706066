import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconNotResultProps = TypeClassNames;

export const IconNotResult = ({ classNames }: TypeIconNotResultProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill={colors.iota} />
                <path
                    d="M14.0167 16.6667C13.7501 16.6667 13.5252 16.5722 13.342 16.3833C13.1589 16.1944 13.0783 15.9722 13.1 15.7167C13.1334 15.25 13.2445 14.8444 13.4334 14.5C13.6223 14.1556 13.9223 13.8 14.3334 13.4333C14.7889 13.0333 15.1363 12.686 15.3754 12.3913C15.6145 12.0967 15.7338 11.7607 15.7334 11.3833C15.7334 10.9278 15.5807 10.55 15.2754 10.25C14.9701 9.95 14.5449 9.8 14.0001 9.8C13.6445 9.8 13.3416 9.87511 13.0914 10.0253C12.8412 10.1756 12.6385 10.3616 12.4834 10.5833C12.3278 10.8056 12.1363 10.9556 11.9087 11.0333C11.6812 11.1111 11.4616 11.1056 11.2501 11.0167C10.9945 10.9056 10.8196 10.7222 10.7254 10.4667C10.6312 10.2111 10.6505 9.97222 10.7834 9.75C11.1056 9.25 11.5389 8.83333 12.0834 8.5C12.6278 8.16667 13.2667 8 14.0001 8C15.1667 8 16.0641 8.32489 16.6921 8.97467C17.3201 9.62444 17.6338 10.4051 17.6334 11.3167C17.6334 11.8722 17.5141 12.3473 17.2754 12.742C17.0367 13.1367 16.6616 13.5838 16.1501 14.0833C15.7723 14.4389 15.5056 14.7278 15.3501 14.95C15.1945 15.1722 15.0889 15.4278 15.0334 15.7167C14.9889 15.9944 14.8723 16.2222 14.6834 16.4C14.4945 16.5778 14.2723 16.6667 14.0167 16.6667ZM14.0001 20.6667C13.6334 20.6667 13.3194 20.536 13.0581 20.2747C12.7967 20.0133 12.6663 19.6996 12.6667 19.3333C12.6667 18.9667 12.7974 18.6527 13.0587 18.3913C13.3201 18.13 13.6338 17.9996 14.0001 18C14.3667 18 14.6807 18.1307 14.9421 18.392C15.2034 18.6533 15.3338 18.9671 15.3334 19.3333C15.3334 19.7 15.2027 20.014 14.9414 20.2753C14.6801 20.5367 14.3663 20.6671 14.0001 20.6667Z"
                    fill="#218EE1"
                />
            </svg>
        </div>
    );
};
