import { ThemeContext } from '@f_context/Theme_context';
import { IconResults } from '../../icons/IconResults';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export function CellResults({ info, showTask }: any) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);

    return (
        <button
            id="cellResults"
            style={{ color: colors.alpha }}
            className={currentTheme === 'vinpin' ? stls.cellResultsList_vinpin : stls.cellResultsList}
            onClick={() => showTask(info.row.original)}>
            <IconResults />
            <p>{t('qwep.batchPricer.results')}</p>
        </button>
    );
}
