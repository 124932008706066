import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconExitProps = TypeClassNames;

export const IconExit = ({ classNames }: TypeIconExitProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.16683 17.3333C0.945816 17.3333 0.733854 17.2455 0.577574 17.0892C0.421294 16.933 0.333496 16.721 0.333496 16.5V1.49999C0.333496 1.27898 0.421294 1.06701 0.577574 0.910734C0.733854 0.754454 0.945816 0.666656 1.16683 0.666656H12.8335C13.0545 0.666656 13.2665 0.754454 13.4228 0.910734C13.579 1.06701 13.6668 1.27898 13.6668 1.49999V3.99999H12.0002V2.33332H2.00016V15.6667H12.0002V14H13.6668V16.5C13.6668 16.721 13.579 16.933 13.4228 17.0892C13.2665 17.2455 13.0545 17.3333 12.8335 17.3333H1.16683ZM12.0002 12.3333V9.83332H6.16683V8.16666H12.0002V5.66666L16.1668 8.99999L12.0002 12.3333Z"
                fill={colors.alpha}
            />
        </svg>
        // </div>
    );
};
