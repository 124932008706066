import { useContext, useEffect, useMemo, useState } from 'react';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import cn from 'classnames';
import stls from '@f_qwep/styles/component/tables/miniResultTableBatchPricer/ColumnStyle.module.sass';
import { ColumnDef } from '@tanstack/react-table';
import { useWindowSize } from '@f_hooks';
import { CellVendor } from './ColumnVendor';
import { CellTitle } from './ColumnTitle';
import { CellPrice } from './ColumnPrice';
import { CellAvailability } from './ColumnAvailability';
import { CellQuantity } from './ColumnQuantity';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import _ from 'lodash';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { FilterReset } from '../../searchResult/FilterReset';
import { useMaskCrossTransformItems } from '@f_qwep/components/filters/hooks/useMaskCrossTransformItems';
import { useFilteredResults } from '@f_qwep/components/filters/hooks/useFilteredResults';

export function MiniResultTableBatchPricer({ items, filter, filtersReducer }: any) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    const { task, itemId } = useContext(BatchPricerContext);
    const { priceMarginIsActive, priceMarginState, setOpenExportModal, setExportData, setTypeExport } =
        useContext(QwepContext);

    const searchItem = task.filter((el: any) => el.id == itemId)[0];

    items = useMaskCrossTransformItems(items);

    useEffect(() => {
        setMobile(width < 900);
    }, [width]);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'Поставщик',
                accessorKey: 'vendorTitle',
                header: `${t('qwep.search.vendor')}`,
                cell: (info) => <CellVendor info={info} />,
                footer: (props) => props.column.id,
                size: 65,
            },
            {
                id: 'Производитель',
                accessorKey: 'brand',
                header: `${t('qwep.search.brand')}`,
                cell: (info) => (
                    <div style={{ color: colors.alpha }} className={cn(stls.cellBrand)}>
                        {info.row.original.brand}
                    </div>
                ),
                footer: (props) => props.column.id,
                size: 60,
            },
            {
                id: 'Артикул',
                accessorKey: 'article',
                header: `${t('qwep.search.article')}`,
                cell: (info) => (
                    <div style={{ color: colors.alpha }} className={cn(stls.cellPin)}>
                        {info.row.original.article}
                    </div>
                ),
                footer: (props) => props.column.id,
                size: 60,
            },
            {
                id: 'Название',
                accessorKey: 'title',
                header: `${t('qwep.search.partname')}`,
                cell: (info) => <CellTitle info={info} />,
                footer: (props) => props.column.id,
                minSize: 200,
            },
            {
                id: 'Цена',
                accessorKey: 'price',
                header: `${t('qwep.search.price')}`,
                cell: (info) => <CellPrice info={info} />,
                footer: (props) => props.column.id,
                maxSize: 50,
                sortingFn: (a: any, b: any) =>
                    priceMarginIsActive
                        ? +b.original.priceWithMargin - +a.original.priceWithMargin
                        : b.original.price.value - a.original.price.value,
            },
            {
                id: 'Срок',
                accessorKey: 'time',
                header: `${t('qwep.search.deliveryTime')}`,
                cell: (info) => (
                    <>
                        {info.row.original.delivery && (
                            <div
                                style={{ color: currentTheme == 'light' ? colors.omicron : colors.eta }}
                                className={stls.cellDelivery}>
                                {info.row.original.delivery}
                            </div>
                        )}
                    </>
                ),
                footer: (props) => props.column.id,
                sortingFn: (a: any, b: any) => {
                    const dateA = new Date(b.original.deliveryStart);
                    const dateB = new Date(a.original.deliveryStart);
                    return +dateA - +dateB;
                },

                size: 40,
                maxSize: 40,
            },
            {
                id: 'Наличие',
                accessorKey: 'quantity.formatted',
                header: `${t('qwep.search.availability')}`,
                cell: (info) => <CellAvailability info={info} />,
                footer: (props) => props.column.id,
                size: 5,
                minSize: 5,
            },
            {
                id: 'Склад',
                accessorKey: 'warehouse',
                header: `${t('qwep.search.warehouse')}`,
                cell: (info) => <div className={stls.cellWarehouse}>{info.row.original.warehouse}</div>,
                footer: (props) => props.column.id,
                size: 10,
            },
            {
                id: 'Корзина',
                accessorKey: `${t('qwep.search.basket')}`,
                header: '',
                cell: (info) => <CellQuantity info={info} />,
                footer: (props) => props.column.id,
                size: 80,
                minSize: 80,
            },
        ],
        [items, mobile],
    );

    const data = useFilteredResults(items, filter, priceMarginIsActive, priceMarginState, searchItem);

    const sortResult = (data: any) => {
        const priceSorter = (data: any) => +data.price.value;
        const priceWithMarginSorter = (data: any) => +data.priceWithMargin;

        return _.orderBy(data, priceMarginIsActive ? priceWithMarginSorter : priceSorter);
    };

    const exportExcelHandler = () => {
        setTypeExport('searchResult');
        setExportData({ filter: sortResult(data), all: items });
        setOpenExportModal(true);
    };

    const tableStyles = {
        head: {
            base: false,
            miniFilterInput: true,
            background: colors.beta,
            container: {
                height: '40px',
            },
            title: {
                fontSize: '10px',
            },
        },
        body: {
            tableBody: {
                borderLeft: '1px solid #c2c2c2',
                borderRight: '1px solid #c2c2c2',
            },
            row: {
                background: colors.psi,
                height: '20px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: false,
        },
    };

    return (
        <VirtualTable
            data={sortResult(data)}
            columnsTable={columns}
            topFilterPanel={true}
            showColumnSearchAction={true}
            heightTable={height - 100}
            configHeader={{
                chipFilters: <FilterReset filter={filter} filtersReducer={filtersReducer} />,
                fullScrean: true,
                exportExcel: true,
                resize: true,
                grouping: false,
                columnFilter: false,
                sorting: true,
                columnSearch: true,
                dragging: true,
            }}
            styles={tableStyles}
            overscan={5}
            estimateSize={20}
            exportExcelHandler={exportExcelHandler}
        />
    );
}
