import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { CellAvatar } from './CellAvatar';
import { useWindowSize } from '@f_hooks';
import { ThemeContext } from '@f_context/Theme_context';
import { CellSettings } from './CellSettings';
import { UserContext } from '@f_context/User_context';
import { Role } from '@f_types/TypeRole';

export function UserTable({ users, workspace }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);
    const { width } = useWindowSize();
    const [mobile, setMobile] = useState(false);
    const [currentRole, setCurrentRole] = useState([{ sysName: Role.User }]);

    useEffect(() => {
        users.map((item: any) => {
            if (item.userId === userInfo.userId) {
                item.role.map((i: any) => {
                    if (i.sysName === Role.Admin || i.sysName === Role.Manager || i.sysName === Role.SUPER_ADMIN) {
                        setCurrentRole([{ sysName: i.sysName }]);
                    } else {
                        setCurrentRole([{ sysName: Role.User }]);
                    }
                });
            }
        });
    }, [users]);

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'fullInfo',
                          accessorKey: 'name',
                          cell: (info: any) => (
                              <CellSettings
                                  info={info}
                                  mobile={mobile}
                                  workspace={workspace}
                                  currentRole={currentRole}
                                  setCurrentRole={setCurrentRole}
                              />
                          ),
                          header: `${t('table.tableUser.name')}`,
                          size: 1,
                      },
                  ]
                : [
                      {
                          id: 'name',
                          accessorKey: 'name',
                          header: `${t('table.tableUser.name')}`,
                          cell: (info: any) => <CellAvatar info={info} />,
                          size: 70,
                      },
                      {
                          id: 'email',
                          accessorKey: 'email',
                          header: 'E-mail',
                          size: 90,
                      },
                      {
                          id: 'position',
                          accessorKey: 'position',
                          header: `${t('table.tableUser.position')}`,
                          size: 30,
                      },
                      {
                          id: 'role',
                          accessorKey: 'role',
                          header: `${t('table.tableUser.userRole')}`,
                          cell: (info: any) => (
                              <CellSettings
                                  info={info}
                                  mobile={mobile}
                                  workspace={workspace}
                                  currentRole={currentRole}
                                  setCurrentRole={setCurrentRole}
                              />
                          ),
                          size: 80,
                      },
                  ],
        [users, mobile, currentRole],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
                color: colors.omicron,
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '48px',
                color: colors.omicron,
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <VirtualTable
            data={users}
            columnsTable={columns}
            topFilterPanel={false}
            showColumnSearchAction={false}
            heightTable={400}
            configHeader={{
                fullScrean: false,
                exportExcel: false,
                resize: false,
                grouping: false,
                columnFilter: false,
                sorting: false,
                columnSearch: false,
                dragging: false,
            }}
            styles={tableStyles}
        />
    );
}
