import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';

type TypeIconBasketProps = TypeClassNames & {
    color?: any;
};

export const IconDiscont = ({ classNames, color }: TypeIconBasketProps) => {
    return (
        <div className={cn(classNames)}>
            <svg width="12" height="13" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="12" height="12" rx="6" fill={color} />
                <path
                    d="M9.6731 5.93779L10.3058 7.02575C10.7055 7.7125 10.4683 8.59082 9.77683 8.98712C9.55655 9.11306 9.30733 9.17952 9.05357 9.17952H8.20095V10L6.13449 8.56416L8.20095 7.12831V7.94879H9.05316C9.08953 7.94879 9.12507 7.93936 9.15648 7.92131C9.24451 7.87085 9.28088 7.76624 9.24782 7.67475L9.23211 7.64111L8.59936 6.55315L9.6731 5.93779ZM4.78592 4.8244L5.00496 7.31867L4.28997 6.90842L3.86345 7.64111C3.84527 7.67229 3.83576 7.70757 3.83576 7.74367C3.83576 7.84418 3.90891 7.92828 4.00521 7.94551L4.04241 7.94879H5.30791V9.17952H4.04241C3.24351 9.17952 2.59588 8.53667 2.59588 7.74367C2.59588 7.49179 2.66284 7.244 2.78972 7.02575L3.21582 6.29306L2.5 5.88282L4.7855 4.8244H4.78592ZM7.27104 2.1927C7.49091 2.31865 7.67359 2.49998 7.80047 2.71822L8.22616 3.45091L8.9424 3.04067L8.72335 5.53494L6.43702 4.47652L7.15284 4.06628L6.72674 3.33358C6.70855 3.30241 6.68252 3.27615 6.65111 3.25851C6.56307 3.20805 6.45355 3.22897 6.39032 3.30364L6.36883 3.33358L5.73649 4.42154L4.66234 3.80618L5.29468 2.71822C5.69433 2.03148 6.57919 1.796 7.27104 2.1927Z"
                    fill="white"
                />
                <defs>
                    <clipPath id="clip0_4336_8902">
                        <rect width="12" height="12" fill="white" transform="matrix(1 0 0 -1 0.5 12)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
