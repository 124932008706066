import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconLoadingProps = TypeClassNames;

export const IconLoading = ({ classNames }: TypeIconLoadingProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid">
                <circle
                    cx="50"
                    cy="50"
                    r="25"
                    strokeWidth="9"
                    stroke={colors.alpha}
                    stroke-dasharray="39.269908169872416 39.269908169872416"
                    fill="none"
                    stroke-linecap="round">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1.5384615384615383s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"></animateTransform>
                </circle>
                <circle
                    cx="50"
                    cy="50"
                    r="15"
                    strokeWidth="9"
                    stroke={colors.gamma}
                    stroke-dasharray="23.561944901923447 23.561944901923447"
                    stroke-dashoffset="23.561944901923447"
                    fill="none"
                    stroke-linecap="round">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1.5384615384615383s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 50;-360 50 50"></animateTransform>
                </circle>
            </svg>
        </div>
    );
};
