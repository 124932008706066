import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Tooltip } from '@mui/material';

export function ColumnTitle ({ partName, index } : any, idRow:any, isOpenDetails:any)  {
    const { colors } = useContext(ThemeContext);

    return (
        <Tooltip title={partName}>
            <div
                className={stls.cellTitle}
                style={{
                    color:
                        idRow !== index && isOpenDetails
                            ? colors.blurColor
                            : colors.omicron,
                }}>
                {partName}
            </div>
        </Tooltip>
    )
}