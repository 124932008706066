import { MyModal } from '@f_general/MyModal';
import { Box, Button, CardContent, Modal, TextField, Typography } from '@mui/material';
import stls from '@f_styles/components/header/HeaderModal.module.sass';
import { useContext, useState } from 'react';
import stl from '@f_styles/general/Universal.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { IconPhone } from '@f_components/icons/IconPhone';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { FormikController } from '@f_general/formik/FormikController';
import { Alert } from '@f_general/alert';
import { CallMeModalCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@f_context/User_context';
import { GeneralContext } from '@f_context/General_context';
import { ImportantMessage } from '@f_general/ImportantMessage';

type TypeData = {
    phone: string;
    name: string;
    comment: string;
};

export function CallMeModal({ open, handleClose }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);
    const { getCallMe } = useContext(GeneralContext);
    const [disable, setDisable] = useState(false);

    const data: TypeData = {
        phone: userInfo.phoneNumber ?? '',
        name: userInfo.firstName ?? '',
        comment: '',
    };

    const sendDataHandler = async (values: TypeData) => {
        const result = await getCallMe(values);
        if (result.isOk) {
            Alert({
                type: 'success',
                text: 'Сообщение успешно отправлено',
                time: 6000,
            });
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: 'Сервис временно недоступен',
                time: 6000,
            });
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box>
                <MyModal className={stls.modal}>
                    <CardContent className={stls.modalContent}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <div className={stls.modalTitle}>
                                <IconPhone />
                                <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                    {t('common.header.modal.confirmInfoTitle')}
                                </Typography>
                            </div>
                        </div>

                        <Typography sx={{ margin: '16px 0 28px 0' }}>
                            {t('common.header.modal.confirmInfoBody')}
                        </Typography>
                        <FormikWrapper
                            onSubmit={sendDataHandler}
                            initialValues={data}
                            validShema={CallMeModalCustomerSchema}>
                            <div>
                                <Typography>{t('common.header.modal.phoneNumber')}</Typography>
                                <FormikController
                                    className={stls.modalInput}
                                    type="tel"
                                    control="input"
                                    name="phone"
                                    placeholder="Введите номер телефона"
                                />
                            </div>
                            <div>
                                <Typography>{t('common.header.modal.name')}</Typography>
                                <FormikController
                                    control="input"
                                    className={stls.modalInput}
                                    type="text"
                                    name="name"
                                    placeholder={t('common.header.modal.enterName')}
                                />
                            </div>
                            <div>
                                <Typography>{t('common.header.modal.comment')}</Typography>
                                <FormikController
                                    className={stls.modalInput}
                                    control="input"
                                    type="text"
                                    name="comment"
                                    placeholder={t('common.header.modal.text')}
                                />
                            </div>
                            <div className={stl.flexEnd}>
                                <Button
                                    className={stls.modalDeleteButton}
                                    onClick={handleClose}
                                    size="large"
                                    sx={{
                                        padding: '13px',
                                        background: colors.delta,
                                        color: colors.phi,
                                        '&:hover': { color: colors.alpha },
                                    }}>
                                    {t('common.header.modal.cancel')}
                                </Button>
                                <Button className={stls.modalButton} variant="contained" type="submit">
                                    {t('common.header.modal.confirm')}
                                </Button>
                            </div>
                        </FormikWrapper>
                    </CardContent>
                </MyModal>
            </Box>
        </Modal>
    );
}
