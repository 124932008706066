import * as yup from 'yup';

const RequiredValidation = (schema: any, validator: any) => {
    if (validator.required === true) {
        if (validator.customMessage) {
            return schema.required(validator.customMessage);
        } else {
            return schema.required(`${validator.fieldName} не заполнен`);
        }
    } else {
        return schema;
    }
};

// const azString = (value: any) => {
//   let schema = yup
//     .string()
//     .min(2, `${value.fieldName} too Short!`)
//     .max(50, `${value.fieldName} too Long!`);
//   return RequiredValidation(schema, value);
// };

const azString = (value: any) => {
    let schema = yup.string();
    return RequiredValidation(schema, value);
};

const azEmail = (value: any) => {
    let schema = yup.string().email(`Пожалуйста, введите ${value.fieldName} в правильном формате`);
    return RequiredValidation(schema, value);
};

const azPhoneNumber = (value: any) => {
    const phoneRegExp = /^\+?\d{1,3}\s?\(?\d{2,3}\)?[-.\s]?\d{3}[-.\s]?\d{2}[-.\s]?\d{2}$/;
    let schema = yup.string().matches(phoneRegExp, ` Пожалуйста, введите ${value.fieldName} в правильном формате`);
    return RequiredValidation(schema, value);
};

// const azAddressLine = (value: any) => {
//   let schema = yup.string().min(5, `${value.fieldName} is too short`);
//   return RequiredValidation(schema, value);
// };

// const azLocation = (value: any) => {
//   let schema = yup.string().min(2, `${value.fieldName} is too short`);
//   return RequiredValidation(schema, value);
// };

// const azCity = (value: any) => {
//   let schema = yup.string().min(2, `${value.fieldName} is too short`);
//   return RequiredValidation(schema, value);
// };

// const azPostalCode = (value: any) => {
//   const postalCodeRegExp = /^[0-9]{5}$/;
//   let schema = yup
//     .string()
//     .matches(postalCodeRegExp, `${value.fieldName} is not valid`);
//   return RequiredValidation(schema, value);
// };

// const azReactSelect = (value: any) => {
//   let schema = yup.string().ensure();
//   return RequiredValidation(schema, value);
// };

// const azReactMultiSelect = (value: any) => {
//   let schema = yup
//     .array()
//     .of(
//       yup.object().shape({
//         label: yup.string().required(),
//         value: yup.string().required()
//       })
//     )
//     .nullable();
//   return RequiredValidation(schema, value);
// };

export {
    azString,
    azEmail,
    azPhoneNumber,
    // azAddressLine,
    // azLocation,
    // azCity,
    // azPostalCode,
    // azReactSelect,
    // azReactMultiSelect
};
