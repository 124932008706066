// Утилита для изменения всего стора фильтров

export const changeFieldFilterStorage = (data: object, fields: { [key: string]: any }) =>
    Object.entries(data).reduce((acc, [tab, value]) => {
        const updatedValue = Object.entries(fields).reduce((val, [field, fieldValue]) => {
            return {
                ...val,
                [field]: fieldValue,
            };
        }, value as object);

        return {
            ...acc,
            [tab]: updatedValue,
        };
    }, {});
