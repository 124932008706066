import { Tooltip } from '@mui/material';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';




export function CellOrderNumber({ info }: any) {
    return (
        <Tooltip title={info.row.original.numberOrder}>
            <p className={stls.cellDate}>
                {info.row.original.numberOrder}
            </p>
        </Tooltip>

    )
}