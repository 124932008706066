import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';

import { useTranslation } from 'react-i18next';

import { ThemeContext } from '@f_context/Theme_context';
import { Button } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

export function AnalogChart({ analogData }: any) {
    const { t } = useTranslation();
    const { reportDate, reportFilter, setReportFilter } = useContext(CrossdataContext);
    const { colors } = useContext(ThemeContext);
    const [filterAnalogChart, setFilterAnalogChart] = useState<any>([]);
    const options: any = {
        onClick: (event: any, elements: any, chart: any) => {
            if (elements[0]) {
                const i = elements[0].index;
                setReportFilter(
                    JSON.stringify(reportFilter) == JSON.stringify(chart.data.labels[i].slice(0, -1).split('('))
                        ? []
                        : chart.data.labels[i].slice(0, -1).split('('),
                );
            }
        },
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: `Период: ${reportDate.periodStart} — ${reportDate.periodEnd}`,
                color: colors.omicron,
                font: {
                    weight: 'bold',
                },
                filler: {
                    propagate: true,
                },
            },
            legend: {
                display: true,
                position: 'top' as const,
                maxWidth: 217 as const,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle' as const,
                    boxHeight: 8,
                    pointStyleWidth: 11,
                },
            },
        },
        scales: {
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                ticks: {
                    callback: function (value: any) {
                        return reportDate.currency == 'RUB' ? `${value} ₽` : `${value} €`;
                    },
                },
                grid: {
                    display: true,
                    color: '#94A3B8',
                    lineWidth: 0.5,
                },
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                ticks: {
                    callback: function (value: any) {
                        return `${value} %`;
                    },
                },
                grid: {
                    drawOnChartArea: false,
                    display: true,
                    color: '#94A3B8',
                },
            },
        },
    };

    const [chartData, setChartData] = useState({
        labels: filterAnalogChart?.map((item: any) => `${item.brand} (${item.article})`),
        datasets: [
            {
                label: `${t('crossdata.minPrice')}`,
                data: filterAnalogChart?.map((item: any) => Math.round(item.minprice)),
                borderColor: colors.lightgreen,
                backgroundColor: colors.lightgreen,
                borderWidth: 2,
                yAxisID: 'y1',
                fill: 'origin',
            },
            {
                label: `${t('crossdata.avgPrice')}`,
                data: filterAnalogChart?.map((item: any) => Math.round(item.avgprice)),
                borderColor: colors.eta,
                backgroundColor: colors.eta,
                borderWidth: 2,
                yAxisID: 'y1',
                fill: true,
            },
            {
                label: `${t('crossdata.maxPrice')}`,
                data: filterAnalogChart?.map((item: any) => Math.round(item.maxprice)),
                borderColor: colors.nu,
                backgroundColor: colors.nu,
                borderWidth: 2,
                yAxisID: 'y1',
                fill: true,
            },
            {
                label: `${t('crossdata.listItem.minAvgBuy')}`,
                data: filterAnalogChart?.map((item: any) => {
                    const filter = analogData.filter(
                        (el: any) => el.brand === item.brand && el.article === item.article,
                    );
                    return filter[0].analyticCountBuy;
                }),
                borderColor: colors.red,
                borderWidth: 0.75,
                yAxisID: 'y',
            },
            {
                label: `${t('crossdata.listItem.minAvgReq')}`,
                data: filterAnalogChart?.map((item: any) => {
                    const filter = analogData.filter(
                        (el: any) => el.brand === item.brand && el.article === item.article,
                    );
                    return filter[0].analyticCountReq;
                }),
                borderColor: colors.grey,
                borderWidth: 0.75,
                yAxisID: 'y',
            },
        ],
    });

    useMemo(() => {
        setChartData({
            labels: filterAnalogChart?.map((item: any) => `${item.brand} (${item.article})`),
            datasets: [
                {
                    label: `${t('crossdata.minPrice')}`,
                    data: filterAnalogChart?.map((item: any) => Math.round(item.minprice)),
                    borderColor: colors.lightgreen,
                    backgroundColor: colors.lightgreen,
                    borderWidth: 2,
                    yAxisID: 'y1',
                    fill: true,
                },
                {
                    label: `${t('crossdata.avgPrice')}`,
                    data: filterAnalogChart?.map((item: any) => Math.round(item.avgprice)),
                    borderColor: colors.eta,
                    backgroundColor: colors.eta,
                    borderWidth: 2,
                    yAxisID: 'y1',
                    fill: true,
                },
                {
                    label: `${t('crossdata.maxPrice')}`,
                    data: filterAnalogChart?.map((item: any) => Math.round(item.maxprice)),
                    borderColor: colors.nu,
                    backgroundColor: colors.nu,
                    borderWidth: 2,
                    yAxisID: 'y1',
                    fill: true,
                },
                {
                    label: `${t('crossdata.listItem.minAvgBuy')}`,
                    data: filterAnalogChart?.map((item: any) => {
                        const filter = analogData.filter((el: any) => el.brand === item.brand);
                        return filter[0].analyticCountBuy;
                    }),
                    borderColor: colors.red,
                    borderWidth: 0.75,
                    yAxisID: 'y',
                },
                {
                    label: `${t('crossdata.listItem.minAvgReq')}`,
                    data: filterAnalogChart?.map((item: any) => {
                        const filter = analogData.filter((el: any) => el.brand === item.brand);
                        return filter[0].analyticCountReq;
                    }),
                    borderColor: colors.sigma,
                    borderWidth: 0.75,
                    yAxisID: 'y',
                },
            ],
        });
    }, [filterAnalogChart]);

    useEffect(() => {
        const groupedData = analogData?.reduce((acc: any, obj: any) => {
            const key = obj.article;
            if (!acc[key]) {
                acc[key] = {
                    vendor: obj.vendor,
                    brand: obj.brand,
                    article: obj.article,
                    avgprice: obj.avgprice,
                    maxprice: obj.maxprice,
                    minprice: obj.minprice,
                };
            } else {
                acc[key].avgprice = (acc[key].avgprice + obj.avgprice) / 2;
                acc[key].maxprice = (acc[key].maxprice + obj.maxprice) / 2;
                acc[key].minprice = (acc[key].minprice + obj.minprice) / 2;
            }
            return acc;
        }, {});

        const sortedData = Object.values(groupedData).sort((a: any, b: any) => a.avgprice - b.avgprice);
        setFilterAnalogChart(sortedData);
    }, [analogData]);

    return (
        <div style={{ height: '82vh' }}>
            {filterAnalogChart.length > 1 ? (
                <Line options={options} data={chartData} />
            ) : (
                <Bar options={options} data={chartData} />
            )}
            {reportFilter.length > 0 && <Button onClick={(e) => setReportFilter([])}>Сбросить фильтр</Button>}
        </div>
    );
}
