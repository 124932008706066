import { Classifications } from '@f_qwep/types';
import { randomKey } from '@f_utils/randomKey';

export type TypeClassificationsList = {
    key: string;
    value: string;
    disabled: boolean;
    id: number;
    main: boolean;
};
export const classificationsList = (t: any) => {
    return [
        {
            key: t('qwep.price.modal.classificationModal.brand'),
            value: Classifications.BRAND,
            disabled: false,
            id: randomKey(),
            main: true,
        },
        {
            key: t('qwep.price.modal.classificationModal.article'),
            value: Classifications.ARTICLE,
            disabled: false,
            id: randomKey(),
            main: true,
        },
        {
            key: t('qwep.price.modal.classificationModal.partname'),
            value: Classifications.PARTNAME,
            disabled: false,
            id: randomKey(),
            main: true,
        },
        {
            key: t('qwep.price.modal.classificationModal.quantity'),
            value: Classifications.QUANTITY,
            disabled: false,
            id: randomKey(),
            main: true,
        },
        {
            key: t('qwep.price.modal.classificationModal.multiplicity'),
            value: Classifications.MULTIPLICITY,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.delivery'),
            value: Classifications.DELIVERY,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.status'),
            value: Classifications.STATUS,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.warehouse'),
            value: Classifications.WAREHOUSE,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.price'),
            value: Classifications.PRICE,
            disabled: false,
            id: randomKey(),
            main: true,
        },
        {
            key: t('qwep.price.modal.classificationModal.notes'),
            value: Classifications.NOTES,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.photo'),
            value: Classifications.PHOTO,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.currency'),
            value: Classifications.CURENCY,
            disabled: false,
            id: randomKey(),
            main: false,
        },
        {
            key: t('qwep.price.modal.classificationModal.trash'),
            value: Classifications.TRASH,
            disabled: false,
            id: randomKey(),
            main: false,
        },
    ] as TypeClassificationsList[];
};
