import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconWarehouseProps = TypeClassNames;

export const IconWarehouse = ({ classNames }: TypeIconWarehouseProps) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '2.5px' }}>
            <svg width="13" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.58822 14.3336H13.5882V6.81523L7.75488 2.27856L1.92155 6.81523V14.3336H6.92155V9.33356H8.58822V14.3336ZM15.2549 15.1669C15.2549 15.3879 15.1671 15.5999 15.0108 15.7562C14.8545 15.9124 14.6426 16.0002 14.4215 16.0002H1.08822C0.867203 16.0002 0.655241 15.9124 0.498961 15.7562C0.34268 15.5999 0.254883 15.3879 0.254883 15.1669V6.40856C0.254795 6.28157 0.28373 6.15624 0.339479 6.04214C0.395229 5.92804 0.476316 5.8282 0.57655 5.75023L7.24322 0.565227C7.3895 0.451431 7.56955 0.389648 7.75488 0.389648C7.94022 0.389648 8.12026 0.451431 8.26655 0.565227L14.9332 5.75023C15.0334 5.8282 15.1145 5.92804 15.1703 6.04214C15.226 6.15624 15.255 6.28157 15.2549 6.40856V15.1669Z"
                    fill="#EDF0F5"
                />
            </svg>
        </div>
    );
};
