import React, { useContext, useEffect } from 'react';

const GroupContext = React.createContext<any>({});

function GroupProvider(props: any) {
    const [newGroup, setNewGroup] = React.useState<any>([]);
    const [openEditId, setOpenEditId] = React.useState<any>(null);
    const [openId, setOpenId] = React.useState<any>(null);

    return (
        <GroupContext.Provider
            value={{
                newGroup,
                openEditId,
                openId,
                setOpenId,
                setOpenEditId,
                setNewGroup,
            }}
            {...props}
        />
    );
}

export { GroupProvider, GroupContext };
