import dayjs from 'dayjs';
import { inRange } from 'lodash';

interface IFilteredResultArgs {
    items: any;
    filter: any;
    searchItem: any;
    priceMarginIsActive: boolean;
    priceMarginState: any;
}

export const getFilteredResults = ({
    items,
    filter,
    searchItem,
    priceMarginIsActive,
    priceMarginState,
}: IFilteredResultArgs) => {
    let result: any[] = [];

    if (items) {
        result = [...(items.filter((el: any) => el.price?.value > 0) ?? [])];
    }

    // АРТИКУЛ ПОИСКА
    if (filter?.searchArticle) {
        result = result.filter((row) => {
            const rowArticle = row?.article
                ?.toString()
                ?.toLowerCase()
                ?.replaceAll(/[^0-9a-zA-Zа-яА-Я]/gi, '');
            const searchHintArticle = searchItem?.article
                ?.toString()
                ?.toLowerCase()
                ?.replaceAll(/[^0-9a-zA-Zа-яА-Я]/gi, '');

            const rowBrand = row.brand?.toString()?.toLowerCase().trim();
            const searchHintBrand = searchItem.brand?.toString()?.toLowerCase().trim();
            return rowArticle === searchHintArticle && rowBrand === searchHintBrand;
        });
    }

    // ПОДТВЕРЖДЕННЫЕ КРОССЫ
    if (filter?.approvedCross) {
        result = result.filter((row) => row.qwepCross === '1' || row.isOriginPosition);
    }

    // В НАЛИЧИИ
    if (filter?.inStock) {
        result = result.filter((row) => row.status === 'В наличии');
    }

    // БРЕНДЫ
    if (filter?.brands?.length > 0) {
        result = result.filter((row) => filter?.brands.includes(row.brand));
    }

    // ПОСТАВЩИКИ
    if (filter?.vendorTitle?.length > 0) {
        result = result.filter((row) => filter?.vendorTitle.includes(row.vendorTitle));
    }

    // СРОК ПОСТАВКИ
    if (filter?.minShipmentDays > 0) {
        const dateNow = dayjs().startOf('day');
        result = result.filter((row) => {
            const inputDate = dateNow.add(filter?.minShipmentDays, 'day');
            return dayjs(row.deliveryStart) >= inputDate;
        });
    }
    if (filter?.maxShipmentDays > 0) {
        const dateNow = dayjs().startOf('day');
        result = result.filter((row) => {
            const inputDate = dateNow.add(filter?.maxShipmentDays, 'day');
            return dayjs(row.deliveryStart) <= inputDate && dayjs(row.deliveryEnd) <= inputDate;
        });
    }

    // СКЛАДЫ
    if (filter?.isDealer) {
        result = result.filter((row) => row.info?.isDealerWarehouse);
    }
    if (filter?.warehouses?.length > 0) {
        result = result.filter((row) => filter?.warehouses.includes(row.warehouse));
    }

    //  НАЦЕНКА ВКЛЮЧЕНА
    if (priceMarginIsActive) {
        const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

        const getPriceWithMargin = (price: any) => {
            const currentPrice = parseFloat(Number(price).toFixed(2));
            const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule.to));
            if (currentRules?.length > 0) {
                const rule = currentRules?.[0];
                return currentPrice + (currentPrice / 100) * rule.addPercent;
            }
            return price;
        };

        result = result.map((row) => ({
            ...row,
            priceWithMargin: getPriceWithMargin(row.price.value),
        }));
    }
    // КОЛИЧЕСТВО
    if (filter?.minQuantity > 0) {
        result = result.filter((row) => row.quantity.value >= filter.minQuantity);
    }
    if (filter?.maxQuantity > 0) {
        result = result.filter((row) => row.quantity.value <= filter.maxQuantity);
    }

    // ЦЕНА
    if (filter?.minPrice > 0) {
        if (priceMarginIsActive) {
            result = result.filter((row) => row.priceWithMargin >= filter?.minPrice);
        } else {
            result = result.filter((row) => row?.price?.value >= filter?.minPrice);
        }
    }
    if (filter?.maxPrice > 0) {
        if (priceMarginIsActive) {
            result = result.filter((row) => row.priceWithMargin <= filter?.maxPrice);
        } else {
            result = result?.filter((row) => row?.price?.value <= filter?.maxPrice);
        }
    }

    return result;
};
