import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/searchResult/SearchResult.module.sass';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks';
import { SearchBase } from '@f_general/SearchBase';
import { SearchContext } from '../../context/Search_context';
import { Alert } from '@f_general/alert';
import { IconSearch } from '../icons/IconSearch';
import { IconResultsSearch } from '../icons/IconResultsSearch';
import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';

interface TypeOption {
    options: {
        inputValue?: string | undefined;
        article: string;
        brand: string;
        id: string;
        partname: string;
    }[];
    setOptions: any;
}

export function SearchItems() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { width } = useWindowSize();
    const navigate = useQwepNavigate();
    const [mobile, setMobile] = useState(false);
    const { searchResults, getSearchId, optionsPriSearch, inputSearch, getClarifications, setOptionsPreSearch } =
        useContext(SearchContext);
    const [searchItemTable, setSearchItemTable] = useState(optionsPriSearch);

    const [loadingClarifications, setLoadingClarifications] = useState(false);

    useEffect(() => {
        if (inputSearch.length < 3) {
            navigate();
        }
    }, [inputSearch]);

    useEffect(() => {
        if (optionsPriSearch?.length) {
            setSearchItemTable(optionsPriSearch);
        }
        if (!optionsPriSearch?.length && inputSearch.length >= 3) {
            clarificationsArray();
        }
    }, [optionsPriSearch]);

    useEffect(() => {
        setMobile(width < 500);
    }, [width]);

    const clarificationsArray = async () => {
        setLoadingClarifications(true);
        const result = await getClarifications(inputSearch);
        console.log('clarificationsArray', result);
        if (result.isOk) {
            setOptionsPreSearch(result.result);
        } else {
            if (result.statusNumber === 1) {
                Alert({
                    type: 'warning',
                    text: 'Уточнений по этому артикулу нет',
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        }
        setLoadingClarifications(false);
    };

    const startSearch = async (item: any) => {
        const brand = item.brand;
        const article = inputSearch;
        const resultSearchId = await getSearchId({
            brand,
            article,
        });

        if (resultSearchId?.result?.entity?.searchId) {
            navigate('search');
            await searchResults(resultSearchId.tabId, resultSearchId.result.entity.searchId, item.article, item.brand);
        } else if (resultSearchId?.status === 'not vendors') {
            Alert({
                type: 'warning',
                text: 'Нет активных поставщиков!',
                time: 8000,
            });
        } else {
            Alert({
                type: 'warning',
                text: resultSearchId?.result?.errors[0]?.message,
            });
        }
    };

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            // mobile
            //   ? [
            //       {
            //         id: 'brand',
            //         accessorKey: 'brand',
            //         header: 'Бренд',
            //         cell: (info) => <p>{}</p>,
            //         footer: (props) => props.column.id,
            //         size: 1,
            //       },
            //     ]
            //   :
            [
                {
                    id: 'brand',
                    accessorKey: 'brand',
                    header: 'Бренд',
                    cell: (info) => (
                        <p
                            className={stls.text}
                            style={{ cursor: 'pointer' }}
                            onClick={() => startSearch(info.row.original)}>
                            {info.row.original.brand}
                        </p>
                    ),
                    footer: (props) => props.column.id,
                    size: 40,
                },
                {
                    id: 'article',
                    accessorKey: 'article',
                    header: 'Артикул',
                    cell: (info) => (
                        <p
                            className={cn(stls.text, stls.article)}
                            style={{ cursor: 'pointer' }}
                            onClick={() => startSearch(info.row.original)}>
                            {inputSearch}
                        </p>
                    ),
                    footer: (props) => props.column.id,
                    size: 40,
                },

                {
                    id: 'partname',
                    accessorKey: 'partname',
                    header: 'Описание',
                    cell: (info) => (
                        <p
                            className={stls.text}
                            style={{ cursor: 'pointer' }}
                            onClick={() => startSearch(info.row.original)}>
                            {info.row.original.partname}
                        </p>
                    ),
                    footer: (props) => props.column.id,
                    size: 100,
                },
                {
                    id: 'search',
                    accessorKey: '',
                    header: '',
                    cell: (info) => (
                        <div>
                            <button
                                className={cn(stls.text, stls.searchButton)}
                                onClick={() => startSearch(info.row.original)}>
                                <IconResultsSearch /> Поиск
                            </button>
                        </div>
                    ),
                    footer: (props) => props.column.id,
                    size: 40,
                },
            ],

        [mobile, searchItemTable],
    );

    const tableStyles = {
        head: {
            // background: colors.beta,
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '40px',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <LayoutPage classNames={[stls.container]}>
            {loadingClarifications ? (
                <div className={stls.loading}>
                    <CircularProgress size={25} />
                    <p className={stls.text} style={{ color: colors.alpha }}>
                        Проводится поиск уточнений...
                    </p>
                </div>
            ) : (
                <div style={{ margin: '0 auto' }}>
                    <div className={stls.search}>
                        <SearchBase
                            data={optionsPriSearch}
                            placeholder="Поиск по результатам"
                            setData={setSearchItemTable}
                            type={'searchItems'}
                        />
                        <div className={stls.resultsInfoDiv}>
                            <IconSearch />
                            <p className={stls.resultsInfo}>
                                {searchItemTable?.length
                                    ? 'По вашему запросу найдено несколько продуктов, выберите нужный и нажмите поиск'
                                    : 'По вашему запросу ничего не удалось найти'}
                            </p>
                        </div>
                    </div>
                    <VirtualTable
                        data={searchItemTable}
                        columnsTable={columns}
                        topFilterPanel={false}
                        showColumnSearchAction={true}
                        heightTable={800}
                        configHeader={{
                            fullScrean: false,
                            exportExcel: false,
                            resize: false,
                            grouping: false,
                            columnFilter: false,
                            sorting: false,
                            columnSearch: true,
                            dragging: false,
                        }}
                        styles={tableStyles}
                    />
                </div>
            )}
        </LayoutPage>
    );
}
