import { Box, Grid } from '@mui/material';
import { CellDate } from './CellDate';
import { CellStatus } from './CellStatus';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { CellName } from './CellName';
import { CellProducts } from './CellProducts';
import { CellList } from './CellList';
import { CellResults } from './CellResults';
import { CellDelete } from './CellDelete';
import { useTranslation } from 'react-i18next';

export function CellMobile({ info, showTask }: any) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', padding: '10px 5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '70%' }}>
                    <CellName info={info} />
                </div>
                <div style={{ width: '10%' }}>
                    <CellDelete info={info} />
                </div>
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.batchPricer.date')}:</p>
                        <CellDate info={info} />
                    </div>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.batchPricer.products')}:</p>
                        <CellProducts info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.batchPricer.status')}:</p>
                        <CellStatus info={info} />
                    </div>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.batchPricer.results')}:</p>
                        <CellResults showTask={showTask} info={info} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
