import { useLocation } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { AuthLoading } from '@f_components/loadings/AuthLoading';
import { Alert } from '@f_general/alert';
import { removeTokens } from '@f_utils/removeTokens';
import { AuthContext } from '@f_context/Auth_context';

export function EmailReset() {
    const { logout } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.search || !location.hash) {
            return navigate('/');
        } else {
            activate();
        }
    }, []);

    const activate = async () => {
        const sep = location.search.split('&');
        const oldEmail = sep[0].replace('?old-email=', '').trim();
        const newEmail = sep[1].replace('new-email=', '').trim();
        const key = location.hash.replace('#', '').trim();

        const activate = await callPlatformAPI(
            'auth/email/reset',
            'post',
            {
                oldEmail,
                newEmail,
                key,
            },
            { guard: false },
        );
        if (activate.data.isOk) {
            await logout();
            window.location.reload();
            navigate('/');
        } else {
            navigate('/settings');
        }
    };

    return <AuthLoading />;
}
