import { IconCheck } from '../icons/IconCheck';
import stls from '@f_qwep/styles/component/price/Price.module.sass';
import { IconFail } from '../icons/IconFail';

export function CellUpdate({ info }: any) {
    return (
        <div id="updateIconPrice" className={stls.cellUpdate}>
            {info.row.original.lastUpdated ? <IconCheck /> : <IconFail />}
        </div>
    );
}
