import stls from '../../../styles/component/basket/Basket.module.sass';
import React, { useContext, useState } from 'react';
import { BasketContext } from '@f_qwep/context/Basket_context';
import { Tooltip } from '@mui/material';
import { Alert } from '@f_general/alert';
import { IconButtonSave } from '../../icons/IconButtonSave';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';

export function CellQuantity({ info, accountId, basketId }: any) {
    const { t } = useTranslation();

    const { updateCart } = useContext(BasketContext);
    const [quantity, setQuantity] = useState<number>(info.row.original.quantity);
    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const { colors } = useContext(ThemeContext);

    const handleClick = async (type: string) => {
        setShowButton(true);
        if (type === 'increment') {
            info.row.original?.available == null
                ? setQuantity((prev: number) => prev + 1)
                : setQuantity((prev: number) =>
                      prev + 1 > info.row.original?.available ? +info.row.original.available : prev + 1,
                  );
        }

        if (type === 'decrement') {
            setQuantity((prev: number) => (prev >= 1 ? prev - 1 : 1));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowButton(true);
        const value = event.target.value;

        console.log(value, typeof value);
        info.row.original?.available == null
            ? setQuantity(+value <= 0 ? 0 : +value)
            : setQuantity(
                  +value <= 0 ? 0 : +value > info.row.original?.available ? +info.row.original.available : +value,
              );
    };

    const handlesubmit = async (e: any) => {
        setLoading(true);
        e.preventDefault();

        if (!quantity) {
            setQuantity(1);
        }

        const result = await updateCart({
            accountId: accountId,
            basketId: basketId,
            basketItemId: info.row.original.basketItemId,
            quantity: quantity ? quantity : 1,
        });
        if (result.isOk) {
            setLoading(false);
        } else {
            result.result.errors.map((err: any) => {
                Alert({
                    type: 'warning',
                    text: err.message,
                    time: 5000,
                });
            });
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip
                title={`Max ${
                    info.row.original.available ? info.row.original.available : `${t('qwep.basket.notLimited')}`
                }`}>
                <form className={stls.cellBasket} onSubmit={handlesubmit}>
                    <div
                        style={{ background: colors.iota }}
                        className={stls.cellBasketButton}
                        onClick={() => handleClick('decrement')}>
                        -
                    </div>
                    <input
                        style={{ background: colors.psi, color: colors.omicron }}
                        type="text"
                        className={stls.cellBasketInput}
                        value={quantity}
                        onChange={handleChange}
                    />
                    <div
                        style={{ background: colors.iota }}
                        className={stls.cellBasketButton}
                        onClick={() => handleClick('increment')}>
                        +
                    </div>
                    {showButton && (
                        <button className={stls.saveButton} disabled={loading}>
                            <IconButtonSave />
                        </button>
                    )}
                </form>
            </Tooltip>
        </div>
    );
}
