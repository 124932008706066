import React from 'react';
import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';

type TypeIconTariffLiteProps = TypeClassNames;

export const IconTariffLite = ({ classNames }: TypeIconTariffLiteProps) => {
    const { colors } = React.useContext(ThemeContext);
    return (
        <div className={cn(classNames)}>
            <svg width="50" height="50" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.8781 31.575V46.8898C17.8781 47.4904 18.0283 47.9408 18.3285 48.2411C18.6288 48.5226 19.0887 48.6634 19.708 48.6634H26.6616L26.8023 50.8311C25.5449 50.9249 24.2687 50.9812 22.9737 51C21.6787 51.0188 20.3837 51.0282 19.0887 51.0282C17.6623 51.0282 16.6394 50.6809 16.0201 49.9865C15.4007 49.2733 15.091 48.3818 15.091 47.3121V31.575H17.8781ZM32.5335 31.575V51H29.7183V31.575H32.5335ZM44.5145 31.575V51H41.6992V31.575H44.5145ZM50.7079 31.575V33.9961H35.5339V31.575H50.7079ZM57.3789 31.5468C58.3173 31.5468 59.2745 31.5468 60.2504 31.5468C61.2264 31.5468 62.1836 31.5656 63.122 31.6032C64.0791 31.6219 64.9894 31.6595 65.8527 31.7158L65.7401 33.8835H57.9983C57.4352 33.8835 57.0223 34.0242 56.7596 34.3058C56.4968 34.5873 56.3654 35.0377 56.3654 35.6571V46.9179C56.3654 47.5373 56.4968 47.9971 56.7596 48.2974C57.0223 48.5789 57.4352 48.7197 57.9983 48.7197H65.7401L65.8527 50.8592C64.9894 50.9155 64.0791 50.9531 63.122 50.9718C62.1836 50.9906 61.2264 51 60.2504 51C59.2745 51.0188 58.3173 51.0282 57.3789 51.0282C56.2153 51.0282 55.2863 50.7185 54.5918 50.0991C53.9162 49.461 53.569 48.6164 53.5502 47.5654V35.0096C53.569 33.9398 53.9162 33.0952 54.5918 32.4759C55.2863 31.8565 56.2153 31.5468 57.3789 31.5468ZM54.057 39.6828H64.6422V41.8505H54.057V39.6828Z"
                    fill={colors.gamma}
                />
            </svg>
        </div>
    );
};
