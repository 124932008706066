import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { AccountsContext } from './Accounts_context';
import { QwepContext } from './Qwep_context';
import { OrdersContext } from './Orders_context';
import { inRange } from 'lodash';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';

type TypeAddCart = {
    itemId: string;
    searchId: string;
    quantity: string;
    comment: string;
    accountId: number;
};

type TypeUpdateCart = {
    accountId: number;
    basketId: number;
    basketItemId: number;
    quantity: number;
};

type TypeDeleteItemCart = {
    accountId: number;
    basketId: number;
    basketItemId: number;
};

type TypeDeleteCart = {
    accountId: number;
    basketId: number;
};

type TypeCartOrder = {
    accountId: string;
    formId: number;
    fieldValues: {
        fieldName: string;
        value: number;
    }[];
    onlyItems: number[];
};

type TypeSendPromoRequest = {
    fio: string;
    email: string;
    phone: string;
    vendorId: string;
    article: string;
    brand: string;
    partName: string;
    price: string;
    warehouse: string;
    accountId: string;
};

const BasketContext = createContext<any>({});

function BasketProvider(props: any) {
    const { accounts } = useContext(AccountsContext);
    const { receivedResponse, priceMarginIsActive, priceMarginState } = useContext(QwepContext);
    const { getOrders } = useContext(OrdersContext);
    const { isAccess: access } = useAccess(QwepProductEnum.BASKET);
    const [basket, setBasket] = useState<any>([]);

    //form
    const [basketForm, setBasketForm] = useState({});
    const [openModalForm, setOpenModalForm] = useState(false);
    const [checkedItems, setCheckedItems] = useState<any>({});
    const [checkedBasketItems, setCheckedBasketItems] = useState({});
    const [checkedOnlyItem, setCheckedOnlyItem] = useState([]);
    //time
    const [cartTimes, setCartTimes] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    //search
    const [searchWord, setSearchWord] = useState('');

    const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

    const findSiteByVendorId = (vendors: any[], cardItem: any) => {
        return cardItem && cardItem.vendorTitle && cardItem.vendorTitle.endsWith('(price)')
            ? ''
            : vendors.find((vendor: any) => vendor.id === cardItem.vendorId) &&
              vendors.find((vendor: any) => vendor.id === cardItem.vendorId).site
            ? vendors.find((vendor: any) => vendor.id === cardItem.vendorId).site
            : '';
    };

    const getPriceWithMargin = (price: any) => {
        const currentPrice = Math.ceil(parseFloat(price));
        const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule?.to));
        if (currentRules?.length > 0) {
            const rule = currentRules?.[0];
            return currentPrice + (currentPrice / 100) * rule?.addPercent;
        }
        return price;
    };

    const basketSum = basket?.reduce(
        (acc: any, curr: any) =>
            acc +
            curr.basketItems.reduce(
                (ac: any, cur: any) =>
                    priceMarginIsActive ? ac + getPriceWithMargin(+cur.priceTotal) : ac + +cur.priceTotal,
                0,
            ),
        0,
    );

    const basketSumItems = basket?.reduce((acc: any, curr: any) => acc + curr.basketItems.length, 0);

    useEffect(() => {
        if (receivedResponse && accounts.length && access) {
            getCart(true);
        }
    }, [accounts, access]);

    const sendPromoRequest = async (data: TypeSendPromoRequest) => {
        const result = await callPlatformAPI('qwep/send/promo/request', 'post', data);
        return result.data;
    };

    const addCart = async (data: TypeAddCart) => {
        const result = await callPlatformAPI('qwep/add/cart', 'post', data);

        console.log('addCart', result);
        if (result.data.isOk && result.data.result) {
            createCheckedItems([...basket, result.data.result]);
            if (basket.some((item: any) => item.accountId === result.data.result?.accountId)) {
                setBasket((prev: any) => {
                    return prev.map((item: any) => {
                        if (item.accountId === result.data.result?.accountId) {
                            return result.data.result;
                        } else {
                            return item;
                        }
                    });
                });
            } else {
                setBasket([...basket, result.data.result]);
            }
        }
        return result.data;
    };

    const createCheckedItems = (basket: any) => {
        if (basket.length) {
            const res: any = {};
            basket.map((item: any) => {
                res[item.accountId] = [];
            });
            setCheckedItems(res);
        }
    };

    const createTimes = (basket: any) => {
        if (basket.length) {
            const res: any = {};
            basket.map((item: any) => {
                res[item.accountId] = new Date().toLocaleTimeString();
            });
            setCartTimes(res);
        }
    };

    const getCart = async (loading: boolean) => {
        loading && setIsLoading(true);
        const result = await callPlatformAPI('qwep/get/cart', 'post', {
            accounts: accounts.filter((item: any) => item.enabled && { id: item.id }),
        });

        console.log('getCart', result);
        if (result.data.isOk) {
            setBasket(result.data.result.entity ?? []);
            createCheckedItems(result.data.result.entity ?? []);
            createTimes(result.data.result.entity ?? []);
        }
        loading && setIsLoading(false);
    };

    const getCartVendor = async (accountId: number) => {
        const result = await callPlatformAPI('qwep/get/cart', 'post', { accounts: [{ id: accountId }] });

        console.log('getCartVendor', result);
        if (result.data.isOk && result.data.result?.entity?.length) {
            setBasket((prev: any) => {
                return prev.map((item: any) => {
                    if (item.accountId === result.data.result?.entity[0].accountId) {
                        return result.data.result.entity[0];
                    } else {
                        return item;
                    }
                });
            });
            setCartTimes((prev: any) => ({
                ...prev,
                [accountId]: new Date().toLocaleTimeString(),
            }));
        }
        return result.data;
    };

    const updateCart = async (data: TypeUpdateCart) => {
        const result = await callPlatformAPI('qwep/change/item/cart', 'post', data);
        console.log('updateCart', result);
        if (result.data.isOk) {
            setBasket((prev: any) => {
                return prev.map((item: any) => {
                    if (item.accountId === result.data.result?.entity?.baskets[0]?.accountId) {
                        return result.data.result.entity.baskets[0];
                    } else {
                        return item;
                    }
                });
            });
        } else {
            await getCartVendor(data.accountId);
        }
        return result.data;
    };

    const deleteCartItem = async (data: TypeDeleteItemCart) => {
        const result = await callPlatformAPI('qwep/delete/item/cart', 'post', data);

        console.log('deleteCartItem', result);
        if (result.data.isOk) {
            if (result.data.result?.entity?.baskets) {
                setBasket((prev: any) => {
                    return prev.map((item: any) => {
                        if (item.accountId === data.accountId) {
                            return result.data.result.entity.baskets[0];
                        } else {
                            return item;
                        }
                    });
                });
            } else {
                setBasket((prev: any) => {
                    return prev
                        .map((item: any) => {
                            if (item.basketId === data.basketId) {
                                if (item.basketItems.length > 1) {
                                    return (item = {
                                        ...item,
                                        basketItems: item.basketItems.filter(
                                            (el: any) => el.basketItemId !== data.basketItemId,
                                        ),
                                    });
                                } else {
                                    return null;
                                }
                            } else {
                                return item;
                            }
                        })
                        .filter((el: any) => el);
                });
            }
            setCartTimes((prev: any) => ({
                ...prev,
                [data.accountId]: new Date().toLocaleTimeString(),
            }));
        } else {
            await getCartVendor(data.accountId);
        }
        return result.data;
    };

    const deleteCart = async (data: TypeDeleteCart) => {
        const result = await callPlatformAPI('qwep/clear/cart', 'post', data);
        console.log('deleteCart', result);
        if (result.data.isOk) {
            setBasket((prev: any) => {
                return prev.filter((item: any) => {
                    if (item.accountId !== data.accountId) {
                        return item;
                    }
                });
            });
            delete checkedItems[data.accountId];
        } else {
            await getCartVendor(data.accountId);
        }
        return result.data;
    };

    const cartOrder = async (data: TypeCartOrder) => {
        const result = await callPlatformAPI('qwep/basket/order', 'post', data);
        console.log('cartOrder', result);
        if (result.data.isOk) {
            getOrders();
            if (result.data.result.entity.status) {
                if (result.data.result.entity.baskets) {
                    setBasket((prev: any) => {
                        return (
                            prev?.map((item: any) => {
                                if (item.accountId === result.data.result.entity.baskets[0].accountId) {
                                    return result.data.result.entity.baskets[0];
                                } else {
                                    return item;
                                }
                            }) ?? []
                        );
                    });
                } else {
                    setBasket((prev: any) => {
                        return (
                            prev?.filter((item: any) => {
                                if (item.accountId !== +data.accountId) {
                                    return item;
                                }
                            }) ?? []
                        );
                    });
                    delete checkedItems[data.accountId];
                }
            }
        } else {
            await getCartVendor(+data.accountId);
        }
        return result.data;
    };

    return (
        <BasketContext.Provider
            value={{
                basket,
                isLoading,
                basketForm,
                openModalForm,
                checkedItems,
                checkedOnlyItem,
                cartTimes,
                basketSum,
                basketSumItems,
                searchWord,
                checkedBasketItems,

                //methods
                findSiteByVendorId,
                setSearchWord,
                addCart,
                getCart,
                updateCart,
                deleteCartItem,
                deleteCart,
                cartOrder,
                setBasketForm,
                setOpenModalForm,
                setCheckedItems,
                setCheckedOnlyItem,
                setCartTimes,
                getCartVendor,
                sendPromoRequest,
                setCheckedBasketItems,
            }}
            {...props}
        />
    );
}

export { BasketProvider, BasketContext };
