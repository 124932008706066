export const IconBattery = () => {
    //   const { colors } = useContext(ThemeContext)
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6124 3.40906H19.1655C19.439 3.40906 19.7014 3.51881 19.8949 3.71416C20.0883 3.90951 20.197 4.17446 20.197 4.45073V21.6383C20.197 21.9146 20.0883 22.1796 19.8949 22.3749C19.7014 22.5703 19.439 22.68 19.1655 22.68H4.72392C4.45034 22.68 4.18796 22.5703 3.99451 22.3749C3.80106 22.1796 3.69238 21.9146 3.69238 21.6383V4.45073C3.69238 4.17446 3.80106 3.90951 3.99451 3.71416C4.18796 3.51881 4.45034 3.40906 4.72392 3.40906H8.33431V4.97157H15.5551V3.40906H16.6124Z"
                stroke="#5E5CE6"
                stroke-width="2"
                stroke-linejoin="round"
            />
            <path
                d="M13.4924 9.65871L9.36626 13.8259H14.526L10.3978 17.9926M8.33472 1.84616H15.5555V4.97118H8.33472V1.84616Z"
                stroke="#5E5CE6"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
