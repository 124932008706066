import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconCloseSettingsProps = TypeClassNames;

export const IconCloseSettings = ({ classNames }: TypeIconCloseSettingsProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_298_1290" fill={colors.psi}>
                    <path d="M0 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H0V0Z" />
                </mask>
                <path d="M0 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H0V0Z" fill={colors.psi} />
                <path
                    d="M11 12H29V14H11V12ZM11 27H29V29H11V27ZM19 22H29V24H19V22ZM19 17H29V19H19V17ZM11 20.5L15 17V24L11 20.5Z"
                    fill={colors.epsilon}
                />
                <path
                    d="M0 -2H32C37.5228 -2 42 2.47715 42 8H38C38 4.68629 35.3137 2 32 2H0V-2ZM42 32C42 37.5228 37.5228 42 32 42H0V38H32C35.3137 38 38 35.3137 38 32H42ZM0 40V0V40ZM32 -2C37.5228 -2 42 2.47715 42 8V32C42 37.5228 37.5228 42 32 42V38C35.3137 38 38 35.3137 38 32V8C38 4.68629 35.3137 2 32 2V-2Z"
                    fill={colors.epsilon}
                    mask="url(#path-1-inside-1_298_1290)"
                />
            </svg>
        </div>
    );
};
