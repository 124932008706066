import { useState } from 'react';

type TypeCustomTooltipProps = {
    children: any;
    componetTooltip: any;
    customStyle?: any;
    wrapperStyle?: any;
};

export function CustomTooltip({ children, componetTooltip, customStyle, wrapperStyle = '' }: TypeCustomTooltipProps) {
    const [active, setActive] = useState(false);

    return (
        <div
            style={{ position: 'relative' }}
            className={wrapperStyle}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}>
            {children}
            {active ? (
                <div
                    style={{
                        bottom: '-30px',
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        fontSize: '10px',
                        fontWeight: 400,
                        zIndex: 2000,

                        position: 'absolute',
                        background: '#616161ea',
                        padding: '3px 7px',
                        borderRadius: '3px',
                        whiteSpace: 'nowrap',
                        color: '#fff',
                        ...customStyle,
                    }}>
                    {componetTooltip}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
