import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { Property, TypeDataList } from '../type';
import { CrossdataContext } from './Crossdata_context';

const ReportListContext = createContext<any>({});

type TypeSendList = {
    data: TypeDataList;
    title: string;
    cross: boolean;
    applicationWorkspaceId: number;
    status: string;
    currency: string;
    periodStart: string;
    periodEnd: string;
};

function ReportListProvider(props: any) {
    const { workspaceApplicationId } = useContext(CrossdataContext);
    const [taskList, setTaskList] = useState<any>([]);
    const [configList, setConfigList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getTaskList();
        getConfigList();
    }, []);

    const getConfigList = async () => {
        const result = await callPlatformAPI(`crossdata/get/property/${Property.ListSettingReport}`, 'get');
        console.log('getConfigList', result);
        if (result.data.isOk) {
            setConfigList(result.data.result.value);
        }
        return result.data;
    };

    const sendList = async (data: TypeSendList) => {
        const result = await callPlatformAPI('crossdata/send/list', 'post', data);
        console.log('sendList', result);
        if (result.data.isOk) {
            setTaskList([result.data.result, ...taskList]);
            getTaskList();
        }
        return result.data;
    };

    const getTaskList = async () => {
        setIsLoading(true);
        const result = await callPlatformAPI(`crossdata/get/tasks/${workspaceApplicationId}`, 'get');
        console.log('getTaskList', result);
        if (result.data.isOk) {
            setTaskList(result.data.result);
            if (result.data.result.some((item: any) => !item.finishReport)) {
                setTimeout(async () => {
                    await getTaskList();
                }, 5000);
            }
        }
        setIsLoading(false);
    };

    const deleteTask = async (refKey: string) => {
        const result = await callPlatformAPI(`crossdata/delete/task/${workspaceApplicationId}/${refKey}`, 'delete');
        console.log('getTaskList', result);
        if (result.data.isOk) {
            await getTaskList();
        }
        return result.data;
    };

    return (
        <ReportListContext.Provider
            value={{
                taskList,
                isLoading,
                configList,

                //methods
                sendList,
                deleteTask,
            }}
            {...props}
        />
    );
}

export { ReportListProvider, ReportListContext };
