import { SearchResultsContext } from '../../context/SearchResult_context';
import { MyModal } from '@f_general/MyModal';
import { useTranslation } from 'react-i18next';
import { Modal, Button, CardContent, TextField, CircularProgress, Link, Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/searchResult/AddBasketModal.module.sass';
import { BasketContext } from '@f_qwep/context/Basket_context';
import { Alert } from '@f_general/alert';
import cn from 'classnames';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { FormikController } from '@f_general/formik/FormikController';
import { UserContext } from '@f_context/User_context';
import { QwepContext } from '../../context/Qwep_context';
import { inRange } from 'lodash';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { getCurrencySymbol } from '@f_utils/formatPriceCurrency';

export default function AddBasketModal() {
    const { t } = useTranslation();
    const { priceMarginIsActive, priceMarginState } = useContext(QwepContext);
    const { openAddBasket, setOpenAddBasket, cardItem, quantityCart, setQuantityCart } =
        useContext(SearchResultsContext);
    const { colors } = useContext(ThemeContext);
    const { addCart, sendPromoRequest, findSiteByVendorId } = useContext(BasketContext);
    const { userInfo } = useContext(UserContext);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const { vendors } = useContext(AccountsContext);

    // if (priceMarginIsActive) {
    const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

    const getPriceWithMargin = (price: any) => {
        const currentPrice = Math.ceil(parseFloat(price));
        const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule.to));
        if (currentRules?.length > 0) {
            const rule = currentRules?.[0];
            return currentPrice + (currentPrice / 100) * rule.addPercent;
        }
        return price;
    };
    // }

    const handleClose = () => setOpenAddBasket(false);
    

    const sendItemToCart = async () => {
        setLoading(true);
        const result = await addCart({
            itemId: cardItem.itemId,
            searchId: cardItem.searchId,
            quantity: quantityCart[cardItem.itemId],
            comment: comment,
            accountId: cardItem.accountId,
        });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: result.result.errors[0].message,
            });
        }
        setLoading(false);
    };

    const sendItemPromo = async (values: any) => {
        console.log('cardItem', cardItem);

        const result = await sendPromoRequest({
            accountId: cardItem.accountId,

            fio: values.fio,
            email: values.email,
            phone: values.phone,

            vendorId: cardItem.vendorId,
            article: cardItem.article,
            brand: cardItem.brand,
            partName: cardItem.title,
            price: cardItem.price.value,
            warehouse: cardItem.warehouse,
            quantity: cardItem.quantity.value,
            multiplicity: cardItem.quantity.multiplicity,
            delivery: cardItem.delivery,
            status: cardItem.status,
            notes: cardItem.notes,
            photo: cardItem.photo,
            currency: cardItem.price.currency,
        });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('qwep.search.successMessageSendPromo'),
            });
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const addBasket = () => {
        cardItem.quantity.sign == '='
            ? setQuantityCart((prev: any) => ({
                  ...prev,
                  [cardItem.itemId]: prev[cardItem.itemId]
                      ? prev[cardItem.itemId] < cardItem.quantity.value
                          ? prev[cardItem.itemId] + +cardItem.quantity.multiplicity
                          : prev[cardItem.itemId]
                      : +cardItem.quantity.multiplicity,
              }))
            : setQuantityCart((prev: any) => ({
                  ...prev,
                  [cardItem.itemId]: prev[cardItem.itemId]
                      ? prev[cardItem.itemId] < 0
                          ? 0
                          : prev[cardItem.itemId] + +cardItem.quantity.multiplicity
                      : +cardItem.quantity.multiplicity,
              }));
    };

    const deleteBasket = () => {
        setQuantityCart((prev: any) => ({
            ...prev,
            [cardItem.itemId]: prev[cardItem.itemId]
                ? prev[cardItem.itemId] <= 0
                    ? 0
                    : prev[cardItem.itemId] - +cardItem.quantity.multiplicity
                : 0,
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        cardItem.quantity.sign == '='
            ? setQuantityCart((prev: any) => ({
                  ...prev,
                  [cardItem.itemId]:
                      prev[cardItem.itemId] < 0
                          ? 0
                          : +event.target.value > +cardItem.quantity.value
                          ? +cardItem.quantity.value
                          : +event.target.value,
              }))
            : setQuantityCart((prev: any) => ({
                  ...prev,
                  [cardItem.itemId]: prev[cardItem.itemId] < 0 ? 0 : +event.target.value,
              }));
    };

    const renderDialogContent = () => (
        <div>
            <div className={stls.addBasketAllElems}>
                <div className={stls.elemAddBasketLarge}>
                    <p className={stls.productName}>
                        {t('qwep.search.vendor')}{' '}
                        {cardItem.promo && (
                            <span
                                style={{
                                    marginLeft: '10px',
                                    fontWeight: 800,
                                    color: colors.gamma,
                                }}>
                                Promo
                            </span>
                        )}
                    </p>
                    <h4 className={stls.productInfo}>{cardItem?.vendorTitle}</h4>
                </div>
                <div className={stls.elemAddBasketLarge}>
                    <p className={stls.productName}>{t('qwep.search.product')}</p>
                    <h4 className={stls.productInfo}>{`${cardItem?.brand} ${cardItem?.article} ${cardItem?.title}`}</h4>
                </div>
                <div className={stls.elemAddBasketSmall}>
                    <p className={stls.productName}>{t('qwep.search.warehouse')}</p>
                    <h4 className={stls.productInfo}>{cardItem?.warehouse}</h4>
                </div>
                <div style={{ width: '10%' }} className={stls.elemAddBasketSmall}>
                    <p className={stls.productName}>{t('qwep.search.price')}</p>
                    <h4 className={stls.productInfo}>
                        {priceMarginIsActive
                            ? Number(getPriceWithMargin(cardItem?.price?.value?.toLocaleString()))?.toFixed(2)
                            //@ts-ignore
                            : +Number(cardItem?.price?.value?.toLocaleString())?.toFixed(2) ?? 0}{' '}
                        {getCurrencySymbol(cardItem?.price?.currency)}
                    </h4>
                </div>
                <div style={{ width: '10%' }} className={stls.elemAddBasketSmall}>
                    <p className={stls.productName}>{t('qwep.search.quantity')}</p>
                    <div className={stls.quantityCart}>
                        <button onClick={deleteBasket} className={stls.cellBasketButton}>
                            -
                        </button>
                        <input
                            className={stls.cellBasketInput}
                            onChange={handleChange}
                            value={quantityCart[cardItem.itemId] || 0}
                        />
                        <button onClick={addBasket} className={stls.cellBasketButton}>
                            +
                        </button>
                    </div>
                </div>
            </div>

            {cardItem?.quantity?.multiplicity > 1 && (
                <>
                    <div>
                        <h3>{t('qwep.search.note')}:</h3>
                    </div>
                    <div>
                        {t('qwep.search.multiple')} {cardItem?.quantity?.multiplicity}
                    </div>
                </>
            )}
            <div className={stls.totalBasket}>
                <div className={stls.totalBasketTitle}>
                    <h3>{t('qwep.search.total')}:</h3>
                </div>
                <div style={{ width: '10%', textAlign: 'center' }}>
                    <h3 style={{ color: colors.gamma }}>
                        {(
                            (priceMarginIsActive
                                ? Math.round(getPriceWithMargin(cardItem?.price?.value))
                                : Math.round(+cardItem?.price?.value)) * (quantityCart[cardItem.itemId] ?? 0)
                        ).toLocaleString()}
                        {getCurrencySymbol(cardItem?.price?.currency)}
                    </h3>
                </div>
            </div>
            {cardItem?.promo ? (
                <FormikWrapper
                    icon={''}
                    title={''}
                    onSubmit={sendItemPromo}
                    initialValues={{
                        fio: `${userInfo.lastName} ${userInfo.firstName}`,
                        email: userInfo.email,
                        phone: userInfo.phoneNumber,
                    }}
                    validShema={null}>
                    <p className={stls.promoTitle}>{t('qwep.search.contactDetails')}:</p>
                    <div className={stls.promoInfo}>
                        <div>
                            <p className={stls.modalTypography}>{t('auth.fio')}</p>
                            <FormikController className={stls.inputModal} control="input" type={'text'} name={'fio'} />
                        </div>
                        <div>
                            <p className={stls.modalTypography}>{t('auth.email')}</p>
                            <FormikController
                                className={stls.inputModal}
                                control="input"
                                type={'text'}
                                name={'email'}
                            />
                        </div>
                        <div>
                            <p className={stls.modalTypography}>{t('auth.phone')}</p>
                            <FormikController className={stls.inputModal} control="input" type={'tel'} name={'phone'} />
                        </div>
                    </div>
                    <div className={stls.addBasketAllElems} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            className={cn(stls.modalButtonSite, stls.elemAddBasketSmall)}
                            size="large"
                            sx={{
                                background: colors.delta,
                                '&:hover': {
                                    '& a': {
                                        color: colors.alpha,
                                    },
                                },
                            }}>
                            <Link
                                href={findSiteByVendorId(vendors, cardItem)}
                                target="_blank"
                                sx={{
                                    color: colors.phi,
                                }}
                                underline="none">
                                {t('qwep.search.site')}
                            </Link>
                        </Button>
                        <Button
                            className={cn(stls.modalButton, stls.elemAddBasketSmall)}
                            onClick={handleClose}
                            size="large"
                            sx={{
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {t('qwep.search.cancel')}
                        </Button>
                        <Button
                            className={cn(stls.modalButton, stls.elemAddBasketLarge)}
                            disabled={loading || !quantityCart[cardItem.itemId]}
                            size="large"
                            variant="contained"
                            type="submit">
                            {t('qwep.search.confirm')}
                            {loading && (
                                <CircularProgress
                                    style={{ color: colors.psi }}
                                    size={'20px'}
                                    className={stls.loading}
                                />
                            )}
                        </Button>
                    </div>
                </FormikWrapper>
            ) : (
                <>
                    <h4 className={stls.comment}>{t('qwep.search.comment')}:</h4>
                    <TextField
                        value={comment}
                        onChange={({ target }) => setComment(target.value)}
                        fullWidth
                        id="addToCart-comment"
                        multiline
                        rows={4}
                        placeholder={`${t('qwep.search.placeholderComment')}`}
                        variant="outlined"
                    />
                    <div className={stls.addBasketAllElems} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            className={cn(stls.modalButtonSite, stls.elemAddBasketSmall)}
                            size="large"
                            sx={{
                                background: colors.delta,
                                '&:hover': {
                                    '& a': {
                                        color: colors.alpha,
                                    },
                                },
                            }}>
                            <Link
                                href={findSiteByVendorId(vendors, cardItem)}
                                target="_blank"
                                sx={{
                                    color: colors.phi,
                                }}
                                underline="none">
                                {t('qwep.search.site')}
                            </Link>
                        </Button>
                        <Button
                            className={cn(stls.modalButton, stls.elemAddBasketSmall)}
                            onClick={handleClose}
                            size="large"
                            sx={{
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {t('qwep.search.cancel')}
                        </Button>
                        <Button
                            className={cn(stls.modalButton, stls.elemAddBasketLarge)}
                            onClick={sendItemToCart}
                            disabled={loading || !quantityCart[cardItem.itemId]}
                            size="large"
                            variant="contained"
                            type="submit">
                            {t('qwep.search.confirm')}
                            {loading && (
                                <CircularProgress
                                    style={{ color: colors.psi }}
                                    size={'20px'}
                                    className={stls.loading}
                                />
                            )}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );

    const renderContent = (item: any) => (
        <div>
            <div className={stls.addBasketAllElems}>
                <div className={stls.elemAddBasketLarge}>
                    <p>{t('qwep.search.vendor')}</p>
                    <h4 className={stls.productInfo}>{item?.vendorTitle}</h4>
                </div>
                <div className={stls.elemAddBasketLarge}>
                    <p>{t('qwep.search.product')}</p>
                    <h4 className={stls.productInfo}>{`${item?.brand} ${item?.article} ${item?.title}`}</h4>
                </div>
                <div className={stls.elemAddBasketSmall}>
                    <p>{t('qwep.search.warehouse')}</p>
                    <h4 className={stls.productInfo}>{item?.warehouse}</h4>
                </div>
                <div className={stls.elemAddBasketSmall}>
                    <p>{t('qwep.search.price')}</p>
                    {/* @ts-ignore */}
                    <h4 className={stls.productInfo}>{+item?.price?.value?.toLocaleString() ?? 0}</h4>
                </div>
                <div className={stls.elemAddBasketSmall}>
                    <p>{t('qwep.search.quantity')}</p>
                    <div className={stls.quantityCart}>
                        <button onClick={deleteBasket} className={stls.cellBasketButton}>
                            -
                        </button>
                        <input
                            className={stls.cellBasketInput}
                            onChange={handleChange}
                            value={quantityCart[item.itemId] || 0}
                        />
                        <button onClick={addBasket} className={stls.cellBasketButton}>
                            +
                        </button>
                    </div>
                </div>
            </div>

            {item?.quantity?.multiplicity > 1 && (
                <>
                    <div>
                        <h3>{t('qwep.search.note')}:</h3>
                    </div>
                    <div>
                        {t('qwep.search.multiple')} {item?.quantity?.multiplicity}
                    </div>
                </>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <h3>{t('qwep.search.total')}:</h3>
                </div>
                <div>
                    <h3 style={{ color: colors.gamma }}>
                        {(
                            (priceMarginIsActive
                                ? Math.round(getPriceWithMargin(item?.price?.value))
                                : Math.round(+item?.price?.value)) * (quantityCart[item.itemId] ?? 0)
                        ).toLocaleString()}
                        {item?.price?.currency}
                    </h3>
                </div>
            </div>
            <h4 className={stls.comment}>{t('qwep.search.comment')}:</h4>
            <TextField
                value={comment}
                onChange={({ target }) => setComment(target.value)}
                fullWidth
                id="addToCart-comment"
                multiline
                rows={4}
                placeholder="Текст комментария"
                variant="outlined"
            />
        </div>
    );

    return (
        <Modal open={openAddBasket} onClose={handleClose}>
            <Box>
                <MyModal className={stls.modalAddBasket}>
                    <CardContent className={stls.modalAddContent}>
                        <h2 style={{ marginBottom: '40px' }}>
                            {cardItem?.promo ? t('qwep.search.sendPromo') : t('qwep.search.addBasket')}
                        </h2>
                        {/* {
                    cardItems.map((item: any) => {
                        return renderContent(item)
                    })
                } */}
                        {renderDialogContent()}
                    </CardContent>
                </MyModal>
            </Box>
        </Modal>
    );
}
