import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
export const IconChart_3 = () => {
    const { colors } = useContext(ThemeContext);
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4167 6.41664L12.2083 0.999969L3 6.41664V17.25L12.2083 22.6666L21.4167 17.25V6.41664Z"
                stroke={colors.eta}
                stroke-width="2"
                stroke-linejoin="round"
            />
            <path
                d="M7.875 12.9166V15.0833M12.2083 10.75V15.0833V10.75ZM16.5417 8.58331V15.0833V8.58331Z"
                stroke={colors.eta}
                stroke-width="1.95"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
