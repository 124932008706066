import { Link } from '@mui/material';
import stls from '@f_qwep/styles/component/settings/Suppliers.module.sass';

export function CellSite({ info }: any) {
    return (
        <Link className={stls.cellSite} href={info.row.original.site} underline="always">
            <p className={stls.text}>{info.row.original.site}</p>
        </Link>
    );
}
