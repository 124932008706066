import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Tooltip } from '@mui/material';

export function ColumnDelivery({ deliveryLength, delivery, index }:any, idRow: any, isOpenDetails: any) {
    const { colors } = useContext(ThemeContext);

    return (
        deliveryLength > 15 ? (
            <Tooltip title={delivery}>
                <div
                    style={{
                        color:
                            idRow !== index && isOpenDetails
                                ? colors.blurColor
                                : colors.omicron,
                        overflow: 'hidden',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        border: `0.5px solid ${colors.sea}`,
                        background: 'rgba(33, 142, 225, 0.2)',
                        justifyContent: 'flex-start',
                    }}
                    className={stls.cellPrice}>
                    {delivery}
                </div>
            </Tooltip>
        ) : (
            <div
                style={{
                    color:
                        idRow !== index && isOpenDetails
                            ? colors.blurColor
                            : colors.omicron,
                    border:
                        deliveryLength> 0 ? `0.5px solid ${colors.sea}` : '',
                    background:
                        deliveryLength > 0 ? 'rgba(33, 142, 225, 0.2)' : '',
                    justifyContent: 'center',
                }}
                className={stls.cellPrice}>
                {delivery}
            </div>
        )
    );
}