import { UserRoleChip } from '@f_general/UserRoleChip';
import stls from '@f_styles/components/user-table/UserTable.module.sass';
import { Avatar, IconButton } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { Role } from '@f_types/TypeRole';
import { UserContext } from '@f_context/User_context';
import { IconClose } from '@f_components/icons/IconClose';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';

export const CellCard = ({ info, clickModalHandler, currentRole }: any) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(UserContext);
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.cellCard}>
            <div className={stls.header}>
                <div className={stls.avaBox}>
                    <Avatar
                        alt={info.row.original.firstName}
                        src={info.row.original.photo ? info.row.original.photo : '/static/images/avatar/1.jpg'}
                        className={stls.avatar}
                    />
                    <p className={stls.title}>{`${info.row.original.firstName} ${info.row.original.lastName}`}</p>
                </div>
                {currentRole.some(
                    (item: any) =>
                        item.sysName === Role.Admin ||
                        item.sysName === Role.Manager ||
                        item.sysName === Role.SUPER_ADMIN,
                ) && (
                    <div className={stls.actions}>
                        <button onClick={() => clickModalHandler('changeUser')}>
                            <IconEditSmall />
                        </button>
                        {info.row.original.userId !== userInfo.userId && (
                            <button onClick={() => clickModalHandler('deleteUser')} className={stls.button}>
                                <IconClose />
                            </button>
                        )}
                    </div>
                )}
            </div>
            <p className={stls.item}>{info.row.original.email}</p>
            <p className={stls.item}>{info.row.original.position}</p>
            <UserRoleChip
                classNames={[stls.roleBox]}
                sysName={info.row.original.role.map((item: any) => item.sysName)}
            />
        </div>
    );
};
