import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { ThemeContext } from '@f_context/Theme_context';

export function HeadDate() {
    const { t } = useTranslation();
    return <p className={stls.headDate}>{t('qwep.batchPricer.date')}</p>;
}

export function CellDate({ info }: any) {
    const { progressList } = useContext(BatchPricerContext);
    const { colors } = useContext(ThemeContext);

    return (
        <p className={stls.cellDate}>
            {info.row.original.lastUpdated}
            <span
                style={{
                    background: colors.gamma,
                    width: progressList[info.row.original.taskId] ? progressList[info.row.original.taskId] : `0%`,
                }}
                className={stls.progress}></span>
        </p>
    );
}
