import { Link } from 'react-router-dom';

interface HeaderAssistantPromoProps {
    width: number;
}

export const HeaderAssistantPromo: React.FC<HeaderAssistantPromoProps> = ({ width }) => {
    return (
        <div style={{ position: 'absolute', width: '640px', left: '-660px', display: width > 1400 ? 'block' : 'none' }}>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: '#6EBA6E',
                    borderRadius: '12px',
                    alignItems: 'center',
                    height: '36px',
                    overflow: 'hidden',
                }}>
                <div style={{ display: 'flex', marginLeft: '12px', alignItems: 'center' }}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.4399 22C6.91694 22 2.43994 17.523 2.43994 12C2.43994 6.477 6.91694 2 12.4399 2C17.9629 2 22.4399 6.477 22.4399 12C22.4399 17.523 17.9629 22 12.4399 22ZM12.4399 20C14.5617 20 16.5965 19.1571 18.0968 17.6569C19.5971 16.1566 20.4399 14.1217 20.4399 12C20.4399 9.87827 19.5971 7.84344 18.0968 6.34315C16.5965 4.84285 14.5617 4 12.4399 4C10.3182 4 8.28338 4.84285 6.78309 6.34315C5.2828 7.84344 4.43994 9.87827 4.43994 12C4.43994 14.1217 5.2828 16.1566 6.78309 17.6569C8.28338 19.1571 10.3182 20 12.4399 20ZM11.4399 7H13.4399V9H11.4399V7ZM11.4399 11H13.4399V17H11.4399V11Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div style={{ color: 'white', marginLeft: '12px' }}>Вам доступен новый функционал до 15 декабря!</div>
                <Link
                    to="purchasing-assistant"
                    style={{ display: 'flex', color: 'white', marginLeft: '12px', marginRight: '12px' }}>
                    Узнайте подробнее
                    <div style={{ marginLeft: '3px' }}>
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ paddingTop: '4px' }}>
                            <path
                                d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </Link>
            </div>
        </div>
    );
};
