import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/home/SettingsPanel.module.sass';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { Button, CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import { ReportListContext } from '@f_crossdata/context/ReportList_context';
import { IconAttach } from '@f_components/icons/IconAttach';
import * as xlsx from 'xlsx';
import { TypeDataList } from '@f_crossdata/type';
import { Alert } from '@f_general/alert';
import { TaskList } from './TaskList';
import GeneralForm from '@f_general/GeneralForm';
import { IconChart } from '../../../icons/IconChart';
import { IconChart_3 } from '../../../icons/IconChart_3';
import { IconBattery } from '../../../icons/IconBattery';
import { IconList } from '../../../icons/IconList';
import { IconCheck } from '../../../icons/IconCheck';

export function SettingsPanel() {
    const { t } = useTranslation();
    const { colors, CustomSelect } = useContext(ThemeContext);
    const { sendList, configList } = useContext(ReportListContext);
    const { workspaceApplicationId, appInfo } = useContext(CrossdataContext);
    const [titleFile, setTitleFile] = useState('');
    const [sendLoading, setSendLoading] = useState(false);
    const [fileChangeLoading, setFileChangeLoading] = useState(false);
    const [fileJson, setFileJson] = useState<TypeDataList[]>([]);
    const [currentConfig, setCurrentConfig] = useState({
        periodStart: '',
        periodEnd: '',
        status: 'In stock',
        currency: 'RUB',
        cross: true,
        minAvgReq: false,
        minAvgBuy: false,
        refKey: 0,
    });
    const [formConfig, setFormConfig] = useState<any>();
    //save
    const [checkedSave, setCheckedSave] = useState(false);
    const [titleConfig, setTitleConfig] = useState('');

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setFileChangeLoading(true);
        if (e.target.files) {
            setTitleFile(e.target.files[0].name.split('.')[0]);

            const reader = new FileReader();
            reader.onload = async (e: any) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json: TypeDataList[] = xlsx.utils.sheet_to_json(worksheet);
                if (json.length > 1000) {
                    Alert({
                        type: 'warning',
                        text: 'Список должен содержать не более 1000 позиций',
                    });
                    setTitleFile('');
                } else {
                    setFileJson(json);
                }
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
        setFileChangeLoading(false);
    };

    const sendHandler = async (values: any) => {
        setSendLoading(true);
        if (values.periodEnd !== values.periodStart) {
            let save = checkedSave;
            if (
                (!save && currentConfig.periodEnd !== values.periodEnd) ||
                currentConfig.periodStart !== values.periodStart ||
                currentConfig.status !== values.status ||
                currentConfig.currency !== values.currency ||
                currentConfig.minAvgReq !== values.minAvgReq ||
                currentConfig.minAvgBuy !== values.minAvgBuy ||
                currentConfig.cross !== values.cross
            ) {
                save = true;
            }

            if (fileJson.length) {
                if (values.periodStart !== values.periodEnd) {
                    const result = await sendList({
                        title: titleFile,
                        data: fileJson,
                        cross: appInfo?.tariff?.cross ? values.cross : false,
                        status: values.status,
                        currency: values.currency,
                        periodStart: values.periodStart,
                        periodEnd: values.periodEnd,
                        minAvgReq: values.minAvgReq,
                        minAvgBuy: values.minAvgBuy,
                        workspaceApplicationId: workspaceApplicationId,
                        save: save,
                        refKey: values.refKey,
                        titleConfig: titleConfig.trim() ? titleConfig : titleFile,
                    });
                    if (!result.isOk) {
                        if (result.statusNumber === 1) {
                            Alert({
                                type: 'error',
                                text: 'Список не прошел валидацию',
                            });
                        } else {
                            Alert({
                                type: 'error',
                                text: t('general.error'),
                            });
                        }
                    }
                } else {
                    Alert({
                        type: 'warning',
                        text: t('crossdata.listItem.configWarning'),
                    });
                }
            }
        } else {
            Alert({
                type: 'warning',
                text: t('crossdata.listItem.configWarning'),
            });
        }
        setSendLoading(false);
    };

    const selectConfigHandler = (opt: any) => {
        setCheckedSave(false);
        setFormConfig(<div>Loading</div>);
        if (opt?.refKey) {
            setCurrentConfig({
                periodStart: opt.periodStart,
                periodEnd: opt.periodEnd,
                status: opt.status,
                currency: opt.currency,
                cross: opt.cross,
                minAvgReq: opt.minAvgReq,
                minAvgBuy: opt.minAvgBuy,
                refKey: opt.refKey,
            });
        } else {
            setCurrentConfig({
                periodStart: '',
                periodEnd: '',
                status: 'In stock',
                currency: 'RUB',
                cross: true,
                minAvgReq: false,
                minAvgBuy: false,
                refKey: 0,
            });
        }
    };

    useEffect(() => {
        setFormConfig(
            <GeneralForm
                sendDataHandler={sendHandler}
                close={() => {}}
                classNames={[stls.form]}
                actions={
                    fileJson?.length ? (
                        <Button disabled={sendLoading} type={'submit'} variant="contained" className={stls.formBtn}>
                            {t('crossdata.listItem.goToAnalysis')}
                            {sendLoading && (
                                <CircularProgress className={stls.loading} size={20} sx={{ color: colors.omicron }} />
                            )}
                        </Button>
                    ) : (
                        <></>
                    )
                }
                mainData={{
                    icon: '',
                    title: '',
                    props: [
                        {
                            title: t('crossdata.listItem.periodStart'),
                            control: 'inputDate',
                            name: 'periodStart',
                            castomDivStyle: {
                                width: '24%',
                                marginRight: '16px',
                            },
                            castomStyle: {
                                '& .MuiInputBase-input': {
                                    color: colors.epsilon,
                                    padding: '13px',
                                },
                            },
                        },
                        {
                            title: t('crossdata.listItem.periodEnd'),
                            control: 'inputDate',
                            name: 'periodEnd',
                            castomDivStyle: {
                                width: '24%',
                                marginRight: '16px',
                            },
                            castomStyle: {
                                '& .MuiInputBase-input': {
                                    color: colors.epsilon,
                                    padding: '13px',
                                },
                            },
                        },
                        {
                            control: 'select',
                            name: 'status',
                            title: t('crossdata.listItem.status'),
                            options: [
                                {
                                    value: 'In stock',
                                    key: t('crossdata.listItem.statusInStock'),
                                },
                                {
                                    value: 'Order',
                                    key: t('crossdata.listItem.statusOrder'),
                                },
                            ],
                            castomStyle: {
                                minWidth: '100% !important',
                                '.MuiSelect-select': {
                                    padding: '13px',
                                    color: colors.epsilon,
                                },
                            },
                            castomDivStyle: {
                                width: '23%',
                            },
                            castomFormControl: {
                                width: '100%',
                            },
                        },
                        {
                            control: 'select',
                            name: 'currency',
                            title: t('crossdata.listItem.currency'),
                            options: [
                                {
                                    value: 'RUB',
                                    key: 'RUB',
                                },
                                {
                                    value: 'EUR',
                                    key: 'EUR',
                                },
                            ],
                            castomStyle: {
                                minWidth: '100% !important',
                                '.MuiSelect-select': {
                                    padding: '13px',
                                    color: colors.epsilon,
                                    width: '100%',
                                },
                            },
                            castomDivStyle: {
                                width: '23%',
                                marginLeft: '16px',
                            },
                            castomFormControl: {
                                width: '100%',
                            },
                        },
                        appInfo?.tariff?.cross && {
                            control: 'checkbox',
                            title: '',
                            name: 'cross',
                            type: 'checkbox',
                            castomStyle: {
                                position: 'relative',
                                paddingTop: '10px',
                            },
                            options: [
                                {
                                    key: 'Аналоги',
                                    value: false,
                                },
                            ],
                        },
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'minAvgReq',
                            type: 'checkbox',
                            castomStyle: {
                                paddingTop: '10px',
                            },
                            options: [
                                {
                                    key: 'Доля запросов',
                                    value: false,
                                },
                            ],
                        },
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'minAvgBuy',
                            type: 'checkbox',
                            castomStyle: {
                                paddingTop: '10px',
                            },
                            options: [
                                {
                                    key: 'Доля заказов',
                                    value: false,
                                },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'report',
                    data: {
                        status: currentConfig.status,
                        currency: currentConfig.currency,
                        periodStart: currentConfig.periodStart,
                        periodEnd: currentConfig.periodEnd,
                        minAvgBuy: currentConfig.minAvgBuy,
                        minAvgReq: currentConfig.minAvgReq,
                        cross: currentConfig.cross,
                        refKey: currentConfig.refKey,
                    },
                }}
                loading={false}
            />,
        );
    }, [currentConfig, fileJson]);

    return (
        <div className={stls.container}>
            <div className={stls.settings} style={{ borderColor: colors.beta }}>
                <div className={stls.description}>
                    <div
                        style={{
                            border: `0.5px solid ${colors.epsilon}`,
                            background: colors.crossdata,
                        }}
                        className={stls.textBlock}>
                        <div>
                            <div className={stls.step}>
                                <IconChart /> {t('crossdata.listPanel.step1')}
                            </div>
                            <p>{t('crossdata.listPanel.infoAddList')}</p>
                            <p>
                                <a
                                    style={{ color: colors.alpha }}
                                    id="downloadFile"
                                    className={stls.downloadFile}
                                    href="/files/crossdata_list.xlsx"
                                    download>
                                    {t('crossdata.listPanel.download')}
                                </a>
                                {''} {t('crossdata.listPanel.exsampleFile')}
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: '20px' }}>
                            <Button variant="contained" style={{ width: '300px' }}>
                                <label className={stls.inputAddFile} htmlFor="file">
                                    <IconAttach color={colors.tau} classNames={[stls.icon]} />
                                    <p id="inputAddFile">{t('crossdata.listPanel.addFile')}</p>{' '}
                                    <input id="file" type="file" onChange={handleFileChange} />
                                    {fileChangeLoading && (
                                        <CircularProgress
                                            className={stls.loading}
                                            size={20}
                                            sx={{ color: colors.omicron }}
                                        />
                                    )}
                                </label>
                            </Button>
                            {titleFile && (
                                <div className={stls.info}>
                                    <p className={stls.textInfo}>
                                        {t('crossdata.listPanel.file')}
                                        <span> {titleFile}</span>
                                    </p>
                                    <span className={stls.delimiter} style={{ background: colors.beta }} />
                                    <p className={stls.textInfo}>
                                        {t('crossdata.listPanel.string')}
                                        <span> {fileJson.length}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={stls.listInfo} style={{ borderColor: colors.beta }}>
                        <div
                            style={{
                                border: `0.5px solid ${colors.epsilon}`,
                                background: colors.crossdata,
                            }}
                            className={stls.text}>
                            <IconChart_3 />
                            <p className={stls.title}>{t('crossdata.listPanel.notmany2List')}</p>
                            <p style={{ color: colors.eta }}>{t('crossdata.listPanel.max2List')}</p>
                        </div>
                        <div
                            style={{
                                border: `0.5px solid ${colors.epsilon}`,
                                background: colors.crossdata,
                            }}
                            className={stls.text}>
                            <IconBattery />
                            <p className={stls.title}>{t('crossdata.listPanel.notmanyString')}</p>
                            <p style={{ color: colors.nu }}>{t('crossdata.listPanel.maxLimit')}</p>
                        </div>
                        <div
                            style={{
                                border: `0.5px solid ${colors.epsilon}`,
                                background: colors.crossdata,
                            }}
                            className={stls.text}>
                            <IconList />
                            <p className={stls.title}>{t('crossdata.listPanel.titleColums')}</p>
                            <p style={{ color: colors.gamma }}>{t('crossdata.listPanel.oneRow')}</p>
                        </div>
                    </div>
                </div>

                <div
                    className={stls.panel}
                    style={{
                        border: `0.5px solid ${colors.epsilon}`,
                        background: colors.crossdata,
                    }}>
                    <div className={stls.header}>
                        <div className={stls.step}>
                            <IconCheck /> {t('crossdata.listPanel.step2')}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '-10px 0 0 0',
                        }}>
                        {configList?.length ? (
                            <Select
                                input={<CustomSelect />}
                                className={stls.select}
                                autoWidth
                                sx={{
                                    marginRight: '15px',
                                    width: '50%',
                                    '& .MuiInputBase-input': {
                                        padding: '13px',
                                        color: colors.epsilon,
                                    },
                                }}
                                defaultValue={0}
                                MenuProps={{
                                    sx: {
                                        '&& .MuiMenuItem-root': {
                                            fontSize: 14,
                                            fontFamily: 'Inter',
                                            color: colors.omicron,
                                            '&:hover': {
                                                background: colors.beta,
                                            },
                                        },
                                    },
                                }}>
                                {[{ refKey: 0, titleConfig: 'Сохраненные параметры аналитики' }, ...configList]?.map(
                                    (option: any) => {
                                        return (
                                            <MenuItem
                                                onClick={() => selectConfigHandler(option)}
                                                id={option.refKey}
                                                key={option.refKey}
                                                value={option.refKey}>
                                                {option.titleConfig}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        {formConfig && formConfig}
                        <div style={{ display: 'flex' }}>
                            {!currentConfig.refKey && (
                                <Button
                                    style={{
                                        minWidth: '110px',
                                        border: `2px solid ${colors.alpha}`,
                                        marginTop: '10px',
                                        padding: '8px 16px',
                                    }}
                                    onClick={() => setCheckedSave(!checkedSave)}>
                                    {t('qwep.settings.suppliers.save')}
                                </Button>
                            )}

                            {checkedSave && (
                                <TextField
                                    style={{
                                        display: 'inline',
                                        marginTop: '10px',
                                        width: '100px',
                                        marginLeft: '20px',
                                        height: '48px',
                                    }}
                                    id="titleConfig"
                                    type="text"
                                    placeholder="Введите название"
                                    required
                                    value={titleConfig}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setTitleConfig(e.target.value)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <TaskList />
        </div>
    );
}
