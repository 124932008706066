import stls from '@f_qwep/styles/component/loaders/LoadingSettings.module.sass';
import { TypeClassNames } from '@f_types';
import cn from 'classnames';
import { IconLoadingSettings } from '../icons/IconLoadingSettings';

type LoadingSettingsProps = TypeClassNames & {
    id?: any;
};

export function LoadingSettings({ classNames, id }: LoadingSettingsProps) {
    return (
        <div className={cn(stls.container, classNames)}>
            <IconLoadingSettings id={id} classNames={[stls.icon]} />
        </div>
    );
}
