import { IconMargin } from '@f_qwep/components/icons/IconMargin';
import { FC, useContext } from 'react';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { Alert } from '@f_general/alert';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import Tooltip from '@mui/material/Tooltip';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';

interface IFilterLeftPanelMarginPriceProps {
    colors: any;
    tooltipTitle: string;
}

export const FilterLeftPanelMarginPrice: FC<IFilterLeftPanelMarginPriceProps> = ({ colors, tooltipTitle }) => {
    const { isLoadedAndIsAccess } = useAccess(QwepProductEnum.WITHOUT_LITE);

    const { priceMarginIsActive, setPriceMarginIsActive, priceMarginState } = useContext(QwepContext);

    // TODO добавить название аллерто в словарь

    const handleMarginChange = async () => {
        if (!priceMarginState || !(priceMarginState.filter((el: any) => el.flag).length > 0)) {
            Alert({
                type: 'warning',
                text: 'Наценка не настроена\nПройдите в настройки и укажите конфигурацию наценки',
            });
        } else {
            setPriceMarginIsActive((prev: any) => !prev);
            Alert({
                type: 'success',
                text: priceMarginIsActive ? 'Вы выключили режим наценки!' : 'Вы включили режим наценки!',
            });
        }
    };

    return isLoadedAndIsAccess ? (
        <Tooltip className={styles.cursorPointer} title={tooltipTitle}>
            <div className={styles.actionIconWrapper} onClick={handleMarginChange}>
                <IconMargin color={priceMarginIsActive ? colors.alpha : colors.sigma} />
            </div>
        </Tooltip>
    ) : null;
};
