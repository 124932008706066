import { QwepContext } from '../../../context/Qwep_context';
import stls from '@f_qwep/styles/component/tables/resultTableBatchPricer/ColumnStyle.module.sass';
import { useContext } from 'react';
import { formatToCurrency } from '@f_utils/formatPriceCurrency';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks';

export function CellPrice({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { width } = useWindowSize();

    const { priceMarginIsActive } = useContext(QwepContext);
    const price = priceMarginIsActive ? +info.row.original.priceWithMargin : +info.row.original.price.value;
    const currency = info.row.original.price.currency

    return (
        <p
            style={{
                border: `0.5px solid ${colors.alpha}`,
                background: currentTheme == 'light' ? '#E4F0FF' : 'rgba(59, 130, 246, 0.25)',
                // color: colors.alpha,
                display: 'flex',
                justifyContent: width < 900 ? 'left' : 'center',
            }}
            className={stls.cellPrice}>
            {formatToCurrency(price, currency)}
        </p>
    );
}
