import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';
import { TypeClassNames } from '@f_types/index';

type TypeIconFilterProps = TypeClassNames;

export const IconFilter = ({ classNames }: TypeIconFilterProps) => {
    const { colors } = React.useContext(ThemeContext);
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.98201 16C7.6994 16 7.46268 15.904 7.27184 15.712C7.08033 15.5207 6.98458 15.2833 6.98458 15V9L1.19946 1.6C0.950106 1.26667 0.912868 0.916667 1.08775 0.55C1.26197 0.183334 1.56519 0 1.99741 0H15.9615C16.3937 0 16.6972 0.183334 16.8721 0.55C17.0463 0.916667 17.0088 1.26667 16.7594 1.6L10.9743 9V15C10.9743 15.2833 10.8789 15.5207 10.688 15.712C10.4965 15.904 10.2595 16 9.97688 16H7.98201Z"
                    fill={colors.epsilon}
                />
            </svg>
        </div>
    );
};
