import { inRange, minBy, sortBy } from 'lodash';
import { Tooltip } from '@mui/material';
import { IUseRendersFilterLeftArgs } from '@f_qwep/components/filters/filterLeftPanel/types/useRedersFilterLeftArgs.interface';
import { IUseFilterFieldRender } from '@f_qwep/components/filters/filterLeftPanel/types/useFilterFieldRender.interface';

interface IUseRendersBrandsArgs extends IUseRendersFilterLeftArgs {
    priceMarginState: any;
    priceMarginIsActive: boolean;
    colors: any;
}

export const useRenderBrands = ({
    items,
    filtersReducer,
    currentTheme,
    stls,
    filter,
    isSort,
    priceMarginState,
    priceMarginIsActive,
    colors,
    searchedWord,
    tooltipLaunchSize,
}: IUseRendersBrandsArgs): IUseFilterFieldRender => {
    const sortByCondition = (item: any) => (isSort ? +item.minPriceValue : item.brand);

    const getBrandsMinPrices = (brands: any) =>
        brands.map((brand: any) => {
            const brandData = items.filter((item: any) => item.brand === brand && item.price.value > 0);
            const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

            const getPriceWithMargin = (price: any) => {
                const currentPrice = Math.ceil(parseFloat(price));
                const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule.to));
                if (currentRules.length > 0) {
                    const rule = currentRules?.[0];
                    return currentPrice + (currentPrice / 100) * rule.addPercent;
                }
                return price;
            };

            const minPriceValue = priceMarginIsActive
                ? getPriceWithMargin(minBy(brandData, (i: any) => Number(i.price.value))?.price.value)
                : minBy(brandData, (i: any) => Number(i.price.value))?.price.value;
            const roundUp = Math.ceil(minPriceValue).toString().length - 2;
            const minPriceFormatted = (Math.ceil(minPriceValue / Math.ceil(10 ** roundUp)) * Math.ceil(10 ** roundUp))
                .toString()
                .split('')
                .reverse();
            const minPrice =
                minPriceFormatted
                    .map((el, idx) => (!(idx % 3 || idx === 0) ? el + ' ' : el))
                    .reverse()
                    .join('') + ' ₽';
            return { brand, minPrice, minPriceValue };
        });

    const allBrands = items.map((item: any) => item.brand);
    const uniqBrands = Array.from(new Set(allBrands));
    const brandsWithPrices = getBrandsMinPrices(uniqBrands);
    const sortBrands = sortBy(brandsWithPrices, sortByCondition);

    const searchBrands = () => {
        return searchedWord === ''
            ? sortBrands
            : sortBrands.filter((brand: any) => {
                  return brand.brand.toLowerCase().indexOf(searchedWord.toLowerCase()) === 0;
              });
    };
    const searchedBrands = searchBrands();

    const renderListItem = ({ brand, minPrice }: any, index: number) => (
        <span
            key={index}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <input
                onChange={() => {
                    filtersReducer({ type: 'brands_toggle', payload: { brand } });
                }}
                className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                type="checkbox"
                checked={filter?.brands?.includes(brand)}
                id={brand}
                name={brand}
            />
            <label htmlFor={brand} className={stls.checkboxLabelVendor}>
                {brand?.length < tooltipLaunchSize ? (
                    brand
                ) : (
                    <Tooltip placement="top" title={brand}>
                        <span className={stls.lable}>{brand}</span>
                    </Tooltip>
                )}
            </label>
            <span style={{ color: colors.gamma }} className={stls.textMinPrice}>
                {minPrice}
            </span>
        </span>
    );

    return {
        items: searchedBrands.map((item, index) => renderListItem(item, index)),
        itemsLength: uniqBrands.length,
    };
};
