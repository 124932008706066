import React, { useContext, useEffect } from 'react';
import colorsLight from '@f_styles/config/Colors.module.sass';
import colorsDark from '@f_styles/config/DarkColors.module.sass';
import colorsOneC from '@f_styles/config/OneCColor.module.sass';
import colorsVinPin from '@f_styles/config/VinPinColors.module.sass';
import { themeLightMui, themeDarkMui } from '../themes/FirstTheme';
import { createTheme, styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import { GeneralContext } from './General_context';
import Badge from '@mui/material/Badge';
import AvatarGroup from '@mui/material/AvatarGroup';

const ThemeContext = React.createContext<any>(null);

function ThemeProvider(props: any) {
    const { colorTheme } = useContext(GeneralContext);
    const [currentTheme, setCurrentTheme] = React.useState(colorTheme);
    const [colors, setColors] = React.useState(colorsLight);
    const themeMui = currentTheme === 'light' || currentTheme == '' ? themeLightMui : themeDarkMui;

    const [vinpin, setVinPin] = React.useState(false);
    useEffect(() => {
        if (
            window.location.hostname === 'vinpin.qwep.ru' ||
            window.location.hostname === 'online.procenka.vinpin.ru'
            // || window.location.hostname === 'localhost'
        ) {
            setVinPin(true);
        }
    }, []);

    useEffect(() => {
        if (vinpin) {
            setColors(colorsVinPin);
            setCurrentTheme('vinpin');
        }
    }, [vinpin]);

    useEffect(() => {
        setColors(
            currentTheme == 'light' || currentTheme == ''
                ? colorsLight
                : currentTheme == 'oneC'
                ? colorsOneC
                : currentTheme == 'vinpin'
                ? colorsVinPin
                : colorsDark,
        );
    }, [currentTheme]);

    useEffect(() => {
        setCurrentTheme(colorTheme);
    }, [colorTheme]);

    const CustomSelect = styled(InputBase)(({ theme }: any) => ({
        '.MuiSvgIcon-root ': {
            fill: colors.epsilon,
        },
        '& .MuiInputBase-input': {
            borderRadius: 12,
            border: `1px solid ${colors.alpha}`,
            padding: '10px 26px 10px 12px',
            fontFamily: 'Inter-Regular',
            fontSize: 14,
            color: colors.omicron,
            '&:focus': {
                borderRadius: 12,
                border: `1px solid ${colors.xi}`,
            },
        },
        ...props.castomStyle,
    }));

    const Search = styled('div')(({ theme }: any) => ({
        borderRadius: '12px',
        border: `1px solid ${colors.alpha}`,
        '&:hover': {
            border: `1px solid ${colors.xi}`,
            '& .MuiSvgIcon-root': {
                color: colors.xi,
            },
        },
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& .MuiSvgIcon-root': {
            color: colors.alpha,
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }: any) => ({
        padding: '16px 0 16px 16px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    }));

    const StyledInputBase = styled(InputBase)(({ theme }: any) => ({
        '& .MuiInputBase-input': {
            color: colors.xi,
        },
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: colors.epsilon,
        },
    }));

    const StyledBadge = styled(Badge)(({ theme }: any) => ({
        '& .MuiBadge-badge': {
            backgroundColor: colors.gamma,
            color: colors.gamma,
            border: '1px solid #E5F1FF',
            right: 0,
        },
    }));

    const StyledAvatarGroup = styled(AvatarGroup)(({ theme }: any) => ({
        '& .MuiAvatarGroup-avatar': {
            maxWidth: '22px',
            maxHeight: '22px',
            fontSize: '.8em',
            fontFamily: 'sans-serif',
            textTransform: 'capitalize',
        },
        '& .MuiAvatar-root': {
            border: 'none',
            marginLeft: 0,
        },
    }));

    const theme = createTheme({
        palette: {
            background: {
                default: colors.psi,
            },
            primary: {
                main: colors.alpha,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    '*::-webkit-scrollbar-track': {
                        backgroundColor: currentTheme !== 'vinpin' && colors.beta,
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: colors.alpha,
                    },
                    // '.Universal_iconHover__h2sCH:hover svg': {
                    //   fill: colors.xi,
                    // },
                    '.Universal_iconHover__h2sCH svg': {
                        fill: colors.alpha,
                    },
                    '.Universal_iconClose__WE8Ui:hover svg': {
                        fill: colors.alpha,
                    },
                    '.Universal_iconClose__WE8Ui svg': {
                        fill: colors.phi,
                    },
                    '.VirtualTable_b_row_r__nlGlL:hover': {
                        boxShadow: `0 0 0 1px ${colors.epsilonHover}`,
                    },
                    // '.VirtualTable_b_row__pnmN6:hover': {
                    //   boxShadow: `0 0 0 1px ${colors.epsilonHover}`
                    // }
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                            borderRadius: '12px !important',
                            textTransform: 'none',
                            fontFamily: 'Inter',
                            fontWeight: 'bold',
                        },
                        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                            borderRadius: '12px !important',
                            textTransform: 'none',
                            fontFamily: 'Inter',
                            fontWeight: 'bold',
                        },
                        '&.Mui-selected': {
                            color: 'white',
                            backgroundColor: colors.alpha,
                            borderRadius: 11,
                        },
                        '&.Mui-selected:hover': {
                            color: 'white',
                            backgroundColor: colors.alpha,
                            borderRadius: 11,
                        },
                        '&': {
                            backgroundColor: currentTheme === 'vinpin' ? 'white' : colors.iota,
                            color: colors.alpha,
                            border: 0,
                            borderRadius: 11,
                        },
                        '&:hover': {
                            backgroundColor: currentTheme === 'vinpin' ? colors.alpha : colors.iota,
                            color: currentTheme === 'vinpin' ? colors.theta : colors.alpha,
                            borderRadius: 11,
                        },
                    },
                },
            },
            MuiToggleButtonGroup: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        backgroundColor: currentTheme === 'vinpin' ? 'none' : colors.iota,
                        borderRadius: 11,
                    },
                },
            },
            MuiButtonGroup: {
                styleOverrides: {
                    root: {
                        borderRadius: 12,
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableRipple: true,
                    disableElevation: true,
                    disableFocusRipple: true,
                },
                styleOverrides: {
                    root: {
                        borderRadius: 12,
                        textTransform: 'none',
                        fontFamily: 'Inter',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        '&: hover': {
                            color: colors.xi,
                            background: 'transparent',
                        },
                        '&.MuiButton-outlined': {
                            border: `1px solid ${colors.alpha}`,
                            svg: {
                                color: colors.alpha,
                            },
                        },
                        '&.MuiButton-outlined:hover': {
                            border: `1px solid ${colors.xi}`,
                            color: colors.xi,
                            svg: {
                                color: colors.xi,
                            },
                        },
                        '&:pressed': {
                            background: colors.alpha,
                        },
                        '&.MuiButton-contained': {
                            background: colors.alpha,
                            color: 'white',
                        },
                        '&.MuiButton-contained:hover': {
                            color: 'white',
                            background: colors.xi,
                        },
                        '&.MuiButton-contained:disabled': {
                            color: 'white',
                            background: colors.pi,
                        },
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                },
                styleOverrides: {
                    root: {
                        height: '100%',
                        minWidth: '100%',
                        '& .MuiInputBase-input': {
                            color: colors.omicron,
                        },
                        '& .MuiOutlinedInput-root': {
                            '& ::-webkit-input-placeholder': {
                                color: colors.epsilon,
                            },
                            '& fieldset': {
                                border: `1px solid ${colors.alpha}`,
                                borderRadius: '12px',
                            },
                            '&:hover:not(.Mui-disabled) fieldset': {
                                border: `1px solid ${colors.xi} !important`,
                            },
                            '&:Mui-hover + fieldset': {
                                border: `1px solid ${colors.xi} !important`,
                            },
                            '&.Mui-focused fieldset': {
                                border: `1px solid ${colors.xi}`,
                            },
                            '&.Mui-disabled fieldset': {
                                border: `1px solid ${colors.pi}`,
                            },
                        },
                    },
                },
            },
            MuiRadio: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        '&:hover': {},
                        '&:disabled': {
                            '& .MuiSvgIcon-root': {
                                strokeWidth: '1px',
                                border: `2px solid ${colors.pi}`,
                                color: `${colors.pi} !important`,
                            },
                        },
                        '&': {
                            '& .MuiSvgIcon-root': {
                                strokeWidth: '1px',
                                color: `${colors.alpha} !important`,
                            },
                            '&.Mui-checked': {
                                '& .MuiSvgIcon-root': {
                                    strokeWidth: '1px',
                                    color: `${colors.alpha} !important`,
                                },
                            },
                        },
                    },
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        '&:hover': {
                            '& .MuiSvgIcon-root': {
                                borderRadius: '4px',
                                width: '20px',
                                height: '20px',
                                border: `1px solid ${colors.xi}`,
                                color: 'transparent',
                            },
                        },
                        '&:disabled': {
                            '& .MuiSvgIcon-root': {
                                borderRadius: '4px',
                                width: '20px',
                                height: '20px',
                                border: `1px solid ${colors.psi}`,
                                color: `${colors.psi} !important`,
                            },
                        },
                        '&': {
                            height: '20px',
                            '& .MuiSvgIcon-root': {
                                borderRadius: '4px',
                                width: '20px',
                                height: '20px',
                                border: `1px solid ${colors.alpha}`,
                                color: 'transparent',
                                background: 'transparent',
                            },
                            '&.Mui-checked': {
                                '& .MuiSvgIcon-root': {
                                    border: `1px solid ${colors.alpha}`,
                                    width: '20px',
                                    height: '20px',
                                    color: `${colors.alpha} !important`,
                                },
                            },
                        },
                    },
                },
            },

            MuiSwitch: {
                defaultProps: {
                    inputProps: {
                        'aria-label': 'castom_small',
                    },
                },
                styleOverrides: {
                    root: {
                        width: 48,
                        height: 24,
                        padding: 0,
                        borderRadius: 16,
                        marginRight: 5,
                        '& .MuiSwitch-switchBase': {
                            padding: 0,
                            margin: 1,
                            '&.Mui-checked': {
                                transform: 'translateX(24px)',
                                color: 'white',

                                '& + .MuiSwitch-track': {
                                    backgroundColor: colors.alpha,
                                    opacity: 1,
                                    border: 0,
                                },
                            },
                        },
                        '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 22,
                            height: 22,
                        },
                        '& .MuiSwitch-track': {
                            borderRadius: 22 / 2,
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontSize: 14,
                        color: colors.epsilon,
                    },
                },
            },
            // MuiTypography: {
            //   defaultProps: {
            //     fontFamily: 'Inter',
            //     color: colors.omicron
            //   },
            //   styleOverrides: {
            //     root: {
            //       '&.Universal_alphaTypography__q9Wpp': {
            //         color: colors.alpha,
            //         '&: hover': {
            //           color: colors.xi
            //         }
            //       },
            //       "&.Settings_buttonProfileDisable__dU54T": {
            //         color: colors.alpha,
            //       }
            //     }
            //   }
            // },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        background: currentTheme === 'vinpin' ? '#EF907F' : '#8CBCE1',
                    },
                },
            },
            MuiSvgIcon: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        color: colors.epsilon,
                    },
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        background: colors.theta,
                        borderRadius: '12px',
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'red !important',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: colors.alpha,
                            height: '60px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: colors.alpha,
                            height: '60px',
                        },
                    },
                },
            },
            MuiListSubheader: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        color: colors.sigma,
                        textTransform: 'uppercase',
                        fontSize: '12px',
                        fontFamily: 'Inter',
                        background: colors.theta,
                    },
                },
            },
            MuiListItemButton: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        '&': {
                            color: colors.omicron,
                            borderRadius: '12px',
                            '&: hover': {
                                background: colors.beta,
                            },
                            '&: active': {
                                background: colors.beta,
                                color: colors.tau,
                            },
                            '&: focus': {
                                background: colors.beta,
                                '& .MuiSvgIcon-root': {
                                    color: colors.alpha,
                                },
                            },
                        },
                    },
                },
            },
            MuiCard: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        background: colors.psi,
                        borderRadius: '12px',
                        boxShadow:
                            '0px 4.82353px 24.1177px rgba(156, 172, 185, 0.0390953), 0px 2.00345px 10.0172px rgba(156, 172, 185, 0.03), 0px 0.724608px 3.62304px rgba(156, 172, 185, 0.0209047)',
                        '.MuiCardHeader-subheader': {
                            color: colors.sigma,
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        borderRadius: '11px',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        background: colors.theta,
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginLeft: 0,
                        '&	.Mui-disabled': {
                            borderRadius: '4px',
                            width: '20px',
                            height: '20px',
                            marginLeft: '10px',
                            whiteSpace: 'nowrap',
                            color: `gray !important`,
                        },
                    },
                },
            },
            MuiIconButton: {
                defaultProps: {
                    disableRipple: true,
                },
            },
        },
        typography: {
            body1: {
                color: colors.omicron,
            },
        },
    });

    theme.typography.h5 = {
        color: colors.alpha,
        fontSize: 24,
    };

    theme.typography.h6 = {
        color: colors.omicron,
        fontSize: 20,
    };

    return (
        <ThemeContext.Provider
            value={{
                currentTheme,
                setCurrentTheme,
                themeMui,
                theme,
                colors,
                vinpin,
                Search,
                SearchIconWrapper,
                StyledInputBase,
                StyledBadge,
                StyledAvatarGroup,
                CustomSelect,
            }}
            {...props}
        />
    );
}

export { ThemeProvider, ThemeContext };
