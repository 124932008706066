import React, { FC, RefObject } from 'react';
import { Button, CardContent, Popover, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

interface IFilterLeftPanelTemplatePopperSaveProps {
    isOpen: boolean;
    anchorRef: RefObject<HTMLButtonElement>;
    templateCancelSaving: () => void;
    templateName: string;
    setTemplateName: (value: string) => void;
    colors: any;
    handleTemplateSave: (value: any) => void;
    workspaceApplication: any;
    updateProperty: any;
    setFilter: (value: any) => void;
    filter: any;
}

export const FilterLeftPanelTemplatePopoverSave: FC<IFilterLeftPanelTemplatePopperSaveProps> = observer(
    ({
        isOpen,
        anchorRef,
        templateCancelSaving,
        templateName,
        setTemplateName,
        colors,
        handleTemplateSave,
        workspaceApplication,
        updateProperty,
        setFilter,
        filter,
    }) => {
        const { t } = useTranslation();

        return (
            <Popover
                id={'saveTemplate'}
                sx={{
                    '& .MuiPopover-paper': {
                        borderRadius: '12px',
                        minWidth: 'fit-content',
                    },
                }}
                open={isOpen}
                anchorEl={anchorRef.current}
                onClose={templateCancelSaving}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <CardContent className={styles.popoverSaveWrapper}>
                    <Typography className={styles.popoverTitle}>Сохранить шаблон фильтра</Typography>
                    <TextField
                        type="text"
                        name="groupName"
                        placeholder="Введите название шаблона"
                        onChange={(event) => setTemplateName(event.target.value)}
                        value={templateName}
                        autoFocus={true}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            className={cn(styles.popoverButton, styles.modalButton)}
                            onClick={templateCancelSaving}
                            size="small"
                            sx={{
                                padding: '4px 10px',
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {t('groups.cancel')}
                        </Button>
                        <Button
                            className={cn(styles.popoverButton, styles.modalButton)}
                            onClick={() => {
                                handleTemplateSave({ workspaceApplication, updateProperty, setFilter, filter });
                            }}
                            size="small"
                            variant="contained"
                            type="submit">
                            {t('groups.save')}
                        </Button>
                    </div>
                </CardContent>
            </Popover>
        );
    },
);
