import React, { useState, useRef, useEffect, useContext, Fragment } from 'react';
import stls from '@f_styles/general/table/VirtualTable.module.sass';
import { useTranslation } from 'react-i18next';
import {
    ColumnDef,
    GroupingState,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    Row,
    ColumnOrderState,
    SortingState,
    useReactTable,
    ColumnResizeMode,
    getFilteredRowModel,
} from '@tanstack/react-table';

import { useVirtual } from '@tanstack/react-virtual';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TypeTables } from '@f_types/index';
import { TableFiltersTopPanel } from '@f_general/stackTable/TableFiltersTopPanel';
import { TableHeader } from '@f_general/stackTable/TableHeader';
import { TableRow } from '@f_general/stackTable/TableRow';
import { windowSize } from '@f_utils/windowSize';
import { ThemeContext } from '@f_context/Theme_context';
import { SearchContext } from '@f_qwep/context/Search_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { globalSort } from '@f_utils/globalSort';

type TypeVirtualTableProps = {
    data: any;
    topFilterPanel: boolean;
    configHeader: {
        chipFilters?: any;
        resize: boolean;
        grouping: boolean;
        fullScrean: boolean;
        sorting: boolean;
        dragging: boolean;
        columnFilter: boolean;
        columnSearch: boolean;
        exportExcel: boolean;
    };
    heightTable?: number;
    columnsTable: any;
    searchId?: any;
    showColumnSearchAction: boolean;
    styles?: any;
    getRowCanExpand?: any;
    renderSubComponent?: any;
    renderCustomSubComponent?: any;
    overscan?: number;
    expanded?: any;
    setExpanded?: any;
    estimateSize?: any;
    exportExcelHandler?: any;
    tableBottomIndent?: number | null;
    isTableAssistant?: boolean;
};

export function VirtualTable({
    configHeader,
    data,
    columnsTable,
    searchId,
    topFilterPanel,
    showColumnSearchAction,
    getRowCanExpand,
    renderSubComponent,
    renderCustomSubComponent,
    heightTable,
    styles,
    overscan,
    estimateSize,
    exportExcelHandler,
    tableBottomIndent = null,
    isTableAssistant = false,
}: TypeVirtualTableProps) {
    const { chipFilters = <div></div> } = configHeader; // установка значения по умолчанию для chipFilters
    const { currentTheme, colors } = useContext(ThemeContext);
    const { t } = useTranslation();
    //header panel
    const [sorting, setSorting] = useState<SortingState>([]);
    // const [columnVisibility, setColumnVisibility] = useState<any>({ 'Склад Поставщика': false });
    const [grouping, setGrouping] = useState<GroupingState>([]);
    //top panel
    const [columnSearchAction, setColumnSearchAction] = useState(showColumnSearchAction);
    const [fullScreanAction, setFullScreanAction] = useState(false);
    const [exportExcelAction, setExportExcelAction] = useState(false);

    const [expanded, setExpanded] = useState(true);

    const columns: ColumnDef<TypeTables>[] = columnsTable;

    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(columns.map((column) => column.id as string));
    const { filter1C, columnVisibility, setColumnVisibility, updateProperty, workspaceApplication } =
        useContext(QwepContext);

    // TODO Функционал Вид Результатов (по хорошему перенести на уровень выше)
    // Костыль, чтобы не ломать mobx и ассистентов
    const { searchArticle } = useContext(SearchContext);
    if (!isTableAssistant) {
        data = filter1C ? globalSort(data, searchArticle) : data;
    }

    // TODO пока не разобрался зачем нужен update
    // исправил зависимость useEffect и стало понятно что цикл запускается бесконечно
    /*useEffect(() => {
        const update = async () => {
            const result = await updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.ColumnVisibility,
                value: { value: columnVisibility },
            });
            if (!result?.isOk) {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        };
        if (columnVisibility) {
            if (Object?.keys(columnVisibility)?.length !== 1 || columnVisibility['Склад Поставщика'] !== false) {
                update();
            }
        }
    }, [columnVisibility]);*/

    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange');
    const table = useReactTable({
        data,
        columns,
        columnResizeMode,
        getRowCanExpand,
        state: {
            columnVisibility,
            sorting,
            // grouping, //
            columnOrder,
            // expanded, //
        },
        onColumnOrderChange: setColumnOrder,
        // onGroupingChange: setGrouping, //
        // getGroupedRowModel: getGroupedRowModel(), //
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: setSorting,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        getFilteredRowModel: getFilteredRowModel(),
    });

    useEffect(() => {
        setColumnSearchAction(showColumnSearchAction);
    }, [showColumnSearchAction]);

    //export excel
    useEffect(() => {
        if (exportExcelAction && exportExcelHandler) {
            setExportExcelAction(!exportExcelAction);
            exportExcelHandler();
        }
    }, [exportExcelAction]);

    //rows
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        estimateSize: React.useCallback(() => (estimateSize ? estimateSize : 40), [estimateSize]),
        overscan: overscan ? overscan : 5,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    const useSafari = navigator.userAgent.indexOf('Safari') != -1 && !navigator.userAgent.includes('Chrome');

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                style={fullScreanAction ? { background: colors.psi } : {}}
                className={fullScreanAction ? stls.fullScrean : ''}>
                {topFilterPanel && (
                    <TableFiltersTopPanel
                        table={table}
                        columnFilter={configHeader.columnFilter}
                        fullScrean={configHeader.fullScrean}
                        exportExcel={configHeader.exportExcel}
                        columnSearch={configHeader.columnSearch}
                        chipFilters={chipFilters}
                        fullScreanAction={fullScreanAction}
                        setFullScreanAction={setFullScreanAction}
                        columnSearchAction={columnSearchAction}
                        setColumnSearchAction={setColumnSearchAction}
                        exportExcelAction={exportExcelAction}
                        setExportExcelAction={setExportExcelAction}
                        columnVisibility={columnVisibility}
                        setColumnVisibility={setColumnVisibility}
                    />
                )}

                <div
                    ref={tableContainerRef}
                    className={stls.container}
                    style={fullScreanAction ? { height: windowSize('height') - 160 } : { height: heightTable }}>
                    <table style={styles.body.tableBody} className={useSafari ? stls.tableSafari : stls.table}>
                        <thead style={{ border: '1px solid #8d8d8d' }}>
                            {table.getHeaderGroups().map((headerGroup, indexGroup) => (
                                <tr className={stls.tableHead} key={`hgroup-${indexGroup}`} style={styles.body.thead}>
                                    {headerGroup.headers.map((header, index) => {
                                        return (
                                            <TableHeader
                                                searchId={searchId}
                                                key={`h-${index}`}
                                                header={header}
                                                table={table}
                                                columnResizeMode={columnResizeMode}
                                                configHeader={configHeader}
                                                columnSearchAction={columnSearchAction}
                                                styles={styles}
                                            />
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody className={stls.tableBody}>
                            {paddingTop > 0 && (
                                <tr>
                                    <td style={{ height: `${paddingTop}px` }} />
                                </tr>
                            )}
                            {virtualRows.map((virtualRow, indexBody) => {
                                const row = rows[virtualRow.index] as Row<TypeTables>;
                                let inStock = false;
                                if (row?.original?.status === 'В наличии') {
                                    inStock = true;
                                }
                                let newItem = false;
                                if (row?.original?.newItem) {
                                    newItem = true;
                                }

                                return (
                                    <Fragment key={indexBody}>
                                        <tr
                                            className={styles.body.rowRadius ? stls.b_row_r : stls.b_row}
                                            style={{
                                                ...styles.body.row,
                                                ...((inStock || newItem) && {
                                                    background:
                                                        currentTheme === 'dark'
                                                            ? useSafari
                                                                ? 'rgb(110, 186, 110, 0.2)'
                                                                : 'linear-gradient(270deg, rgba(105, 222, 148, 0.12) -0.48%, rgba(33, 34, 45, 0.6) 52.38%, rgba(33, 34, 45, 0.6) 100%)'
                                                            : useSafari
                                                            ? '#E5F8EB'
                                                            : 'linear-gradient(270deg, #C2FFC2 -0.02%, #FBFBFB 99.98%)',
                                                }),
                                            }}
                                            key={row.id}>
                                            {row.getVisibleCells().map((cell) => {
                                                return <TableRow key={cell.id} cell={cell} row={row} styles={styles} />;
                                            })}
                                        </tr>
                                        {row.getIsExpanded() && (
                                            <tr>
                                                {/* 2nd row is a custom 1 cell row */}
                                                <td colSpan={row.getVisibleCells().length}>
                                                    {renderSubComponent({ row })}
                                                </td>
                                            </tr>
                                        )}
                                        {expanded && (
                                            <tr>
                                                {/* 2nd row is a custom 1 cell row */}
                                                <td colSpan={row.getVisibleCells().length}>
                                                    {renderCustomSubComponent && renderCustomSubComponent({ row })}
                                                </td>
                                            </tr>
                                        )}
                                        <tr style={{ display: 'block', ...styles.body.marginRow }}>
                                            <td></td>
                                        </tr>
                                    </Fragment>
                                );
                            })}
                            {paddingBottom > 0 ? (
                                <tr>
                                    <td style={{ height: `${paddingBottom}px` }} />
                                </tr>
                            ) : (
                                <tr>
                                    <td style={{ height: `0px` }} />
                                </tr>
                            )}
                            {tableBottomIndent && (
                                <tr role="presentation">
                                    <td style={{ height: `${tableBottomIndent}px` }} />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </DndProvider>
    );
}
