import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { Tooltip } from '@mui/material';
import cn from 'classnames';
import { useContext } from 'react';

export function CellVendor({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <p style={{ color: colors.alpha }} className={cn(stls.cellVendor)}>
            {info.row.original.vendorTitle}
        </p>
    );
}
