export const formatDateFromString = (dateString?: string): string | undefined => {
    if (dateString) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) { // Проверяем, что дата валидна
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому прибавляем 1
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }
    }
    return undefined;
}