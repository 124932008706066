import React, { FC } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { Alert } from '@f_general/alert';
import { TFunction } from 'i18next';

interface ICopyButtonProps {
    stylesWrapper: string;
    textCopy: string;
    t: TFunction<'translation', undefined>;
    copyObjectTitle: string;
    iconSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
}

export const CopyButton: FC<ICopyButtonProps> = ({ textCopy, stylesWrapper, t, copyObjectTitle, iconSize }) => {
    return (
        <Tooltip title={t('general.copy')} className={stylesWrapper}>
            <button
                onClick={() => {
                    navigator.clipboard.writeText(textCopy);
                    Alert({
                        type: 'success',
                        text: t('general.copySuccess', { copyObjectTitle }),
                    });
                }}>
                <ContentCopyIcon fontSize={iconSize} />
            </button>
        </Tooltip>
    );
};
