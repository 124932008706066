import stls from '@f_qwep/styles/component/tables/miniSearchResultTable/ColumnStyle.module.sass';
import { formatToCurrency } from '@f_utils/formatPriceCurrency';
import React, { useContext } from 'react';
import { QwepContext } from '../../../context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';

export function CellPrice({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);

    const { priceMarginIsActive } = useContext(QwepContext);
    const price = priceMarginIsActive ? +info.row.original.priceWithMargin : +info.row.original.price.value;
    const currency = info.row.original.price.currency

    return (
        <p
            style={{
                color: colors.alpha,
                display: 'flex',
                justifyContent: 'center',
            }}
            className={stls.cellPrice}>
            {formatToCurrency(price, currency)}
        </p>
    );
}
