import { Button, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/settings/Settings.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { CellSettings } from './CellSettings';
import { CellPromo, HeadPromo } from './CellPromo';
import { CellLogin } from './CellLogin';
import { CellVendor, HeadVendor } from './CellVendor';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { SearchBase } from '@f_general/SearchBase';
import { useWindowSize } from '@f_hooks';
import { MobileCart, MobileHeader } from './MobileCart';
import { LoadingSettings } from '../../loaders/LoadingSettings';
import { MobileButtonSettings } from '../MobileButtonSettings';
import { PageNoData } from '@f_qwep/general/PageNoData';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { UserContext } from '@f_context/User_context';
import { Alert } from '@f_general/alert';
import { Property } from '@f_qwep/types';
import { boolean, number } from 'yup';
import isDifferenceLessThan24Hours from '@f_utils/DifferenseLess24hours';

export function Accounts({ navigatinHandler }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const [mobile, setMobile] = useState(false);
    const { width, height } = useWindowSize();

    const { fullAccounts, isLoadingAccounts } = useContext(AccountsContext);
    const [accountsTable, setAccauntsTable] = useState(fullAccounts);
    const { userInfo } = useContext(UserContext);
    const { updateProperty, excludePromo, workspaceApplication } = useContext(QwepContext);

    useEffect(() => {
        setAccauntsTable(fullAccounts);
    }, [fullAccounts]);

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const checkedHandler = async (checked: boolean) => {
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.ExcludePromo,
            value: { value: !checked },
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const [newAcc, setNewAcc] = useState(true);

    useEffect(() => {
        if (!isDifferenceLessThan24Hours(new Date(), new Date(userInfo.createDate))) {
            setNewAcc(false);
        }
    }, [userInfo.createDate]);

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          header: () => <MobileHeader />,
                          cell: (info) => <MobileCart info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                  ]
                : [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          header: () => <HeadVendor />,
                          cell: (info) => <CellVendor info={info} />,
                          footer: (props) => props.column.id,
                          size: 150,
                      },
                      {
                          id: 'login',
                          accessorKey: 'login',
                          header: `${t('qwep.settings.suppliers.login')}`,
                          cell: (info) => <CellLogin info={info} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'promo',
                          accessorKey: 'promo',
                          header: () => <HeadPromo checkedHandler={checkedHandler} excludePromo={excludePromo} />,
                          cell: (info) => <CellPromo info={info} />,
                          footer: (props) => props.column.id,
                          size: 50,
                      },
                      {
                          id: 'settings',
                          accessorKey: '',
                          header: '',
                          cell: (info) => <CellSettings info={info} />,
                          footer: (props) => props.column.id,
                          size: 50,
                      },
                  ],

        [mobile, excludePromo],
    );

    const tableStyles = {
        head: {
            // background: colors.beta,
            container: {
                padding: '5px 0',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '40px',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div className={stls.page}>
            {isLoadingAccounts ? (
                <LoadingSettings />
            ) : (
                <>
                    <div className={stls.appbarGroup}>
                        <SearchBase
                            data={fullAccounts}
                            placeholder={t('qwep.settings.suppliers.searchVendor')}
                            setData={setAccauntsTable}
                            type={'accounts'}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                id="addSupplier"
                                onClick={() => navigatinHandler('suppliers')}
                                className={stls.buttonGroup}
                                variant="contained"
                                startIcon={<AddIcon sx={{ color: 'white' }} />}>
                                {!mobile ? `${t('qwep.settings.suppliers.addSupplier')}` : 'Добавить'}
                            </Button>
                            {mobile && <MobileButtonSettings />}
                        </div>
                    </div>

                    {accountsTable?.length ? (
                        <VirtualTable
                            data={accountsTable}
                            columnsTable={columns}
                            topFilterPanel={false}
                            showColumnSearchAction={false}
                            heightTable={800}
                            configHeader={{
                                fullScrean: false,
                                exportExcel: false,
                                resize: false,
                                grouping: false,
                                columnFilter: false,
                                sorting: false,
                                columnSearch: false,
                                dragging: false,
                            }}
                            styles={tableStyles}
                            estimateSize={mobile ? 126 : 48}
                        />
                    ) : (
                        <PageNoData
                            type={'accounts'}
                            content={
                                newAcc ? (
                                    <div style={{ color: colors.beta }}>{t('qwep.settings.suppliers.infoPromo')}</div>
                                ) : (
                                    <div className={stls.promo}>
                                        {excludePromo && (
                                            <Button onClick={() => checkedHandler(true)}>
                                                {t('qwep.settings.suppliers.addPromo')}
                                            </Button>
                                        )}
                                    </div>
                                )
                            }
                        />
                    )}
                </>
            )}
        </div>
    );
}
