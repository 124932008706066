import { useNavigate } from 'react-router-dom';

const useAdminNavigate = () => {
    const navigate = useNavigate();
    return (url = '') => {
        navigate(`/app-admin/${url}`);
    };
};

export default useAdminNavigate;
