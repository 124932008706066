import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { Button, CircularProgress, InputAdornment, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/general/GeneralForm.module.sass';
import { useTranslation } from 'react-i18next';
import { IconCloseEye } from '@f_components/icons/IconCloseEye';
import { IconEye } from '@f_components/icons/IconEye';
import { IconClose } from '@f_components/icons/IconClose';
import cn from 'classnames';

type TypeDataProps = {
    close?: any;
    sendDataHandler: any;
    mainData: any;
    loading?: any;
    actions?: any;
    classNames?: string[];
};

export default function GeneralForm({ sendDataHandler, close, mainData, loading, actions, classNames }: TypeDataProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    let data = mainData.data;
    const [castom, setCastom] = useState<any>({});
    const [showPass, setShowPass] = useState(false);

    const handleClick = (index: any) => {
        setShowPass((prev) => !prev);
    };

    useEffect(() => {
        if (mainData.type === 'deleteUser') {
            setCastom({
                buttonTrue: {
                    background: colors.lambda,
                    color: '#fff',
                    '&:hover': { background: '#ee654d', color: '#fff' },
                },
                buttonFalse: {
                    background: '#ffd9d9',
                    color: colors.lambda,
                    '&:hover': { color: colors.lambda },
                },
            });
        } else if (
            mainData.type === 'deleteAccount' ||
            mainData.type === 'deleteTasks' ||
            mainData.type === 'deletePrice' ||
            mainData.type === 'deleteMargin' ||
            mainData.type === 'deleteGroup'
        ) {
            setCastom({
                textButtonTrue: t('general.yes'),
                textButtonFalse: t('general.no'),
                closeIcon: (
                    <button
                        style={{
                            marginTop: '-15px',
                            marginRight: '-5px',
                            float: 'right',
                        }}
                        onClick={() => {
                            close();
                            setShowPass(false);
                        }}>
                        <IconClose />
                    </button>
                ),

                buttonTrue: {
                    background: colors.lambda,
                    maxWidth: '82px',
                    color: '#fff',
                    '&:hover': { background: '#ee654d', color: '#fff' },
                },
                buttonFalse: {
                    maxWidth: '82px',
                    marginLeft: '12px',
                },
                buttonActions: {
                    justifyContent: 'flex-start',
                    flexDirection: 'row-reverse',
                },
                card: {
                    padding: '50px 40px 16px 40px',
                },
            });
        } else {
            setCastom({});
        }
    }, [mainData.type]);

    return (
        <FormikWrapper
            icon={mainData.icon}
            title={
                <div className={stls.genetalTitle}>
                    {mainData.title}
                    {loading && <CircularProgress sx={{ marginLeft: '10px' }} size={'20px'} />}
                </div>
            }
            onSubmit={sendDataHandler}
            initialValues={data}
            validShema={mainData?.shema}>
            <div className={cn(classNames)}>
                {mainData?.props.map((item: any) => {
                    if (item?.control === 'select') {
                        return (
                            <div style={item.castomDivStyle} key={item.name}>
                                <Typography className={stls.modalTypography}>{item.title}</Typography>
                                <FormikController
                                    className={stls.select}
                                    name={item.name}
                                    control="select"
                                    castomStyle={item.castomStyle}
                                    castomFormControl={item.castomFormControl}
                                    defaultValue={item.defaultValue}
                                    options={item.options}
                                />
                            </div>
                        );
                    }
                    if (item?.control === 'text') {
                        return (
                            <div key={item.name}>
                                <Typography className={stls.modalTypography}>{item.title}</Typography>
                                <p>{item.content}</p>
                            </div>
                        );
                    }
                    if (item?.control === 'input') {
                        return (
                            <div key={item.name}>
                                <Typography className={stls.modalTypography}>{item.title}</Typography>
                                <FormikController
                                    className={stls.inputModal}
                                    control="input"
                                    type={showPass ? 'text' : item.type}
                                    name={item.name}
                                    placeholder={item.placeholder}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                    castomStyle={item.castomStyle}
                                    defaultValue={item.defaultValue}
                                    required
                                    InputProps={
                                        item.type === 'password' && {
                                            endAdornment: (
                                                <InputAdornment onClick={handleClick} position="start">
                                                    {showPass ? <IconEye /> : <IconCloseEye />}
                                                </InputAdornment>
                                            ),
                                        }
                                    }
                                />
                            </div>
                        );
                    }

                    if (item?.control === 'inputDate') {
                        console.log(item, 'it');
                        return (
                            <div style={item.castomDivStyle} key={item.name}>
                                <Typography className={stls.modalTypography}>{item.title}</Typography>
                                <FormikController
                                    className={stls.inputModal}
                                    control="input"
                                    type={'date'}
                                    name={item.name}
                                    castomStyle={item.castomStyle}
                                />
                            </div>
                        );
                    }

                    if (item?.control === 'checkbox') {
                        return (
                            <div key={item.name}>
                                <Typography className={stls.modalTypography}>{item.title}</Typography>
                                <FormikController
                                    className={stls.checkbox}
                                    type="checkbox"
                                    control="checkbox"
                                    castomStyle={item.castomStyle}
                                    disabled={item.disabled}
                                    name={item.name}
                                    options={item.options}
                                />
                            </div>
                        );
                    }
                })}
                {actions ? (
                    actions
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            ...castom.buttonActions,
                        }}>
                        <Button
                            className={stls.modalButton}
                            onClick={() => {
                                close();
                                setShowPass(false);
                            }}
                            sx={{
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                                ...castom.buttonFalse,
                            }}>
                            {castom.textButtonFalse ? castom.textButtonFalse : t('modal.cancel')}
                        </Button>
                        <Button
                            disabled={loading}
                            className={stls.modalButton}
                            {...{
                                variant: !castom?.buttonTrue ? 'contained' : 'text',
                            }}
                            type="submit"
                            sx={{
                                ...castom.buttonTrue,
                            }}>
                            {castom.textButtonTrue ? castom.textButtonTrue : t('modal.success')}
                        </Button>
                    </div>
                )}
            </div>
        </FormikWrapper>
    );
}
