import { Input, Selecting, RadioButtons, CheckBoxes } from './FormikElement';

type TypeControllerProps = {
    control: string;
    name: string;
    label?: string;
    type?: string;
    options?: any;
    option?: any;
    variant?: any;
    disabled?: boolean;
    helperText?: string;
    defaultValue?: any;
    error?: boolean;
    multiline?: boolean;
    maxRows?: number;
    rows?: number;
    size?: any;
    fullWidth?: boolean;
    displayEmpty?: boolean;
    required?: boolean;
    row?: boolean;
    color?: any;
    labelPlacement?: any;
    className?: any;
    InputProps?: any;
    inputProps?: any;
    placeholder?: any;
    onChange?: any;
    checked?: any;
    castomStyle?: any;
    castomFormControl?: any;
    value?: any;
    validationWarningIndent?: string;
};

export function FormikController(props: TypeControllerProps) {
    const { control, ...rest } = props;
    switch (control) {
        case 'input':
            return <Input {...rest} />;
        case 'select':
            return <Selecting {...rest} />;
        case 'radio':
            return <RadioButtons {...rest} />;
        case 'checkbox':
            return <CheckBoxes {...rest} />;
        default:
            return null;
    }
}
