import { FC, useContext, useState } from 'react';
import styles from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import Tooltip from '@mui/material/Tooltip';
import { SearchContext } from '@f_qwep/context/Search_context';
import cn from 'classnames';

interface ICrossVoteButtonProps extends IChangeCrossArgs {
    colors: any;
    setItemsTable: any;
}

interface IChangeCrossArgs {
    approvedVoted: boolean;
    articleCross: string;
    brandCross: string;
    setItemsTable: any;
    itemId: number;
}

export const CrossVoteButton: FC<ICrossVoteButtonProps> = ({
    approvedVoted,
    colors,
    articleCross,
    brandCross,
    setItemsTable,
    itemId,
}) => {
    const { changeCrosses, searchArticle, searchBrand } = useContext(SearchContext);
    const [_, setIsApprovedVoted] = useState<boolean>(approvedVoted);
    const [isDisable, setIsDisable] = useState<boolean>(false);

    const changeCross = async ({
        approvedVoted,
        articleCross,
        brandCross,
        setItemsTable,
        itemId,
    }: IChangeCrossArgs) => {
        setIsDisable(true);
        const { changedIsSuccess } = await changeCrosses({
            rate: approvedVoted ? '+1' : '-1',
            articleSearch: searchArticle,
            brandSearch: searchBrand,
            articleCross: articleCross,
            brandCross: brandCross,
        });
        //Костыль - нужно запускать перерендр таблицы при голосовании
        if (changedIsSuccess) {
            setIsApprovedVoted((prevVoted) => {
                const newVoted = !prevVoted;
                setItemsTable((prevItemsTable) => {
                    return {
                        ...prevItemsTable,
                        items: prevItemsTable.items.map((item) => {
                            return item.itemId === itemId
                                ? {
                                      ...item,
                                      qwepCross: newVoted ? '2' : '1',
                                  }
                                : item;
                        }),
                    };
                });
                return newVoted;
            });
        }
        setIsDisable(false);
    };

    return (
        <Tooltip
            title={
                approvedVoted ? 'Добавить аналог в подтвержденные кросс' : 'Убрать аналог из подтвержденных кроссов'
            }>
            <div className={styles.btnCrossWrapper}>
                <button
                    disabled={isDisable}
                    onClick={() => {
                        changeCross({
                            approvedVoted: approvedVoted,
                            articleCross,
                            brandCross,
                            setItemsTable,
                            itemId,
                        });
                    }}
                    style={{
                        border: `0.5px solid ${approvedVoted ? colors.gamma : colors.lambda}`,
                        color: `${approvedVoted ? colors.gamma : colors.lambda}`,
                    }}
                    className={cn([styles.btnCross, approvedVoted ? styles.plus : styles.minus])}></button>
            </div>
        </Tooltip>
    );
};
