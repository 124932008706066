type CurrencyMap = {
    [key: string]: {
        code: string;
        symbol?: string;
    };
};

const currencyMap: CurrencyMap = {
    // Рубль (RUB)
    'р': { code: 'RUB', symbol: '₽' },
    'руб': { code: 'RUB', symbol: '₽' },
    'рубль': { code: 'RUB', symbol: '₽' },
    'ру': { code: 'RUB', symbol: '₽' },
    '₽': { code: 'RUB', symbol: '₽' },
    'rub': { code: 'RUB', symbol: '₽' },
    'рус': { code: 'RUB', symbol: '₽' },
    'рос': { code: 'RUB', symbol: '₽' },

    // Доллар США (USD)
    'us': { code: 'USD', symbol: '$' },
    'usa': { code: 'USD', symbol: '$' },
    'usd': { code: 'USD', symbol: '$' },
    'dollar': { code: 'USD', symbol: '$' },
    'доллар': { code: 'USD', symbol: '$' },
    '$': { code: 'USD', symbol: '$' },
    'us$': { code: 'USD', symbol: '$' },
    'us dollar': { code: 'USD', symbol: '$' },
    'us dollars': { code: 'USD', symbol: '$' },
    'доллары': { code: 'USD', symbol: '$' },

    // Евро (EUR)
    'eu': { code: 'EUR', symbol: '€' },
    'eur': { code: 'EUR', symbol: '€' },
    'euro': { code: 'EUR', symbol: '€' },
    'евро': { code: 'EUR', symbol: '€' },
    '€': { code: 'EUR', symbol: '€' },
    'euros': { code: 'EUR', symbol: '€' },

    // Тенге (KZT)
    'kz': { code: 'KZT', symbol: '₸' },
    'тг': { code: 'KZT', symbol: '₸' },
    'kzt': { code: 'KZT', symbol: '₸' },
    'тенге': { code: 'KZT', symbol: '₸' },
    'tng': { code: 'KZT', symbol: '₸' },
    '₸': { code: 'KZT', symbol: '₸' },
    'tenge': { code: 'KZT', symbol: '₸' },
    'kazakhstani tenge': { code: 'KZT', symbol: '₸' },
    'kazakh tenge': { code: 'KZT', symbol: '₸' },
    'казахстанские тенге': { code: 'KZT', symbol: '₸' }
};

export const formatToCurrency = (number: number | string, currency: string) => {
    if (!currency) {
        return number.toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB',
        });
    }

    const cleanCurrency = currency.replace(/[^a-zа-яё\s]/gi, '').toLowerCase();
    const currencyInfo = currencyMap[cleanCurrency];

    if (!currencyInfo) {
        return formatToRoubles(number);
    }

    if (currencyInfo.code === 'KZT') {
        return `${number.toLocaleString()} ${currencyInfo.symbol} `;
    }

    return number.toLocaleString(currencyInfo.code, {
        style: 'currency',
        currency: currencyInfo.code,
    });
};

export const getCurrencySymbol = (currency: string) => {
    if (!currency) {
        return '₽'
    }
    const cleanCurrency = currency.replace(/[^a-zа-яё\s]/gi, '').toLowerCase();
    const currencyInfo = currencyMap[cleanCurrency];
    return currencyInfo?.symbol || '₽';
};

export const formatToRoubles = (number: any) => {
    return number.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' });
};
