import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { AuthLoading } from '@f_components/loadings/AuthLoading';
import { AuthContext } from '@f_context/Auth_context';

export function PiercedCompany() {
    const { logout } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.search || !location.hash) {
            return navigate('/');
        } else {
            activate();
        }
    }, []);

    const activate = async () => {
        const sep = location.search.split('&');
        const email = sep[0].replace('?email=', '').trim();
        const key = location.hash.replace('#', '').trim();

        const activate = await callPlatformAPI(
            'auth/add/company',
            'post',
            {
                email,
                key,
            },
            { guard: false },
        );

        if (activate.data.isOk) {
            await logout();
        }
        navigate('/');
    };

    return <AuthLoading />;
}
