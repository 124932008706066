import { Checkbox } from '@mui/material';
import { useContext } from 'react';
import stls from '@f_qwep/styles/component/settings/Accounts.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { IconCheck } from '../../icons/IconCheck';
import { useTranslation } from 'react-i18next';

export function CellPromo({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return <div className={stls.cellPromo}>{info.row.original.promo && <IconCheck />}</div>;
}

export function HeadPromo({ checkedHandler, excludePromo }: any) {
    const { t } = useTranslation();
    return (
        <div className={stls.headPromo}>
            <Checkbox checked={!excludePromo} onChange={(e: any) => checkedHandler(e.target.checked)} />
            {t('qwep.settings.suppliers.promo')}
        </div>
    );
}
