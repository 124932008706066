import { Tooltip } from '@mui/material';
import stls from '../../../styles/component/basket/Basket.module.sass';

export function CellTitle({ info }: any) {
    const name = info.row.original.title;

    return (
        <Tooltip placement="top" title={name}>
            <p className={stls.cellTitle}>{name}</p>
        </Tooltip>
    );
}
