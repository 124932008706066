import { ThemeContext } from '@f_context/Theme_context';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconMessage } from '../../icons/IconMessage';
import stls from '../../../styles/component/basket/MiniBasket.module.sass';
import { BasketContext } from '@f_qwep/context/Basket_context';
import { Alert } from '@f_general/alert';
import { IconClose } from '../../../../../components/icons/IconClose';

export function CellCommentBasket({ info }: any) {
    const { colors } = React.useContext(ThemeContext);

    const comment = info.row.original.fields?.find((item: any) => item.fieldName === 'Комментарий')?.defaultValue;

    return (
        <Tooltip title={comment}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconMessage color={comment?.length > 0 ? colors.phi : colors.tau} />
            </div>
        </Tooltip>
    );
}

export function CellCheckoutBasket({ info, basketForm, accountId, basketId, hasPartOrder }: any) {
    const { t } = useTranslation();

    const { setBasketForm, setOpenModalForm, setCheckedOnlyItem } = useContext(BasketContext);

    const openHandler = () => {
        setBasketForm({ ...basketForm, accountId, basketId, hasPartOrder });
        setCheckedOnlyItem([+info.row.original.basketItemId]);
        setOpenModalForm(true);
    };

    return (
        <div className={stls.cellDiv}>
            {hasPartOrder && (
                <Button className={stls.cellButtonDiv} onClick={openHandler} variant="outlined">
                    {t('qwep.basket.checkout')}
                </Button>
            )}
        </div>
    );
}

export function CellDeleteBasket({ info, accountId, basketId }: any) {
    const { t } = useTranslation();
    const { deleteCartItem } = useContext(BasketContext);
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        const result = await deleteCartItem({
            accountId: accountId,
            basketId: basketId,
            basketItemId: info.row.original.basketItemId,
        });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoading(false);
    };

    return (
        <button onClick={handleDelete} disabled={loading}>
            {loading ? <CircularProgress size={17} /> : <IconClose background="transparent" />}
        </button>
    );
}
