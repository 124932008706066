import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconEditSmallProps = TypeClassNames;

export const IconEditSmall = ({ classNames }: TypeIconEditSmallProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                <path
                    d="M16.4853 12.4573L15.5427 11.5147L9.33333 17.724V18.6667H10.276L16.4853 12.4573ZM17.428 11.5147L18.3707 10.572L17.428 9.62934L16.4853 10.572L17.428 11.5147ZM10.828 20H8V17.1713L16.9567 8.21467C17.0817 8.08969 17.2512 8.01948 17.428 8.01948C17.6048 8.01948 17.7743 8.08969 17.8993 8.21467L19.7853 10.1007C19.9103 10.2257 19.9805 10.3952 19.9805 10.572C19.9805 10.7488 19.9103 10.9183 19.7853 11.0433L10.8287 20H10.828Z"
                    fill={colors.phi}
                />
            </svg>
        </div>
    );
};
