import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconEditUserProps = TypeClassNames;

export const IconEditUser = ({ classNames }: TypeIconEditUserProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 13V21H0C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM10.595 17.812C10.4682 17.2784 10.4682 16.7226 10.595 16.189L9.603 15.616L10.603 13.884L11.595 14.457C11.9932 14.0799 12.4745 13.8017 13 13.645V12.5H15V13.645C15.532 13.803 16.012 14.085 16.405 14.457L17.397 13.884L18.397 15.616L17.405 16.189C17.5316 16.7222 17.5316 17.2778 17.405 17.811L18.397 18.384L17.397 20.116L16.405 19.543C16.0068 19.9201 15.5255 20.1983 15 20.355V21.5H13V20.355C12.4745 20.1983 11.9932 19.9201 11.595 19.543L10.603 20.116L9.603 18.384L10.595 17.812ZM14 16C13.7348 16 13.4804 16.1054 13.2929 16.2929C13.1054 16.4804 13 16.7348 13 17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17C15 16.7348 14.8946 16.4804 14.7071 16.2929C14.5196 16.1054 14.2652 16 14 16Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
