import React, { useCallback } from 'react';
import { sortBy } from 'lodash';
import { Tooltip } from '@mui/material';
import { IUseRendersFilterLeftArgs } from '@f_qwep/components/filters/filterLeftPanel/types/useRedersFilterLeftArgs.interface';
import { IUseFilterFieldRender } from '@f_qwep/components/filters/filterLeftPanel/types/useFilterFieldRender.interface';

export const useRenderVendors = ({
    items,
    filtersReducer,
    currentTheme,
    stls,
    filter,
    isSort,
    searchedWord,
    tooltipLaunchSize,
}: IUseRendersFilterLeftArgs): IUseFilterFieldRender => {
    const allVendors = items.map((item: any) => item.vendorTitle);
    const uniqVendors: string[] = Array.from(new Set(allVendors));
    const sortVendors: string[] = sortBy(uniqVendors);

    const currentVendors: string[] = isSort ? sortVendors : uniqVendors;

    const searchVendors = (): string[] => {
        return searchedWord === ''
            ? currentVendors
            : currentVendors.filter((vendor: string) => {
                  return vendor.toLowerCase().indexOf(searchedWord.toLowerCase()) === 0;
              });
    };

    const searchedVendors = searchVendors();

    const renderListItem = (vendor: string, index: number) => (
        <span key={index} style={{ display: 'flex' }}>
            <input
                onChange={(e) => {
                    filtersReducer({ type: 'vendors_toggle', payload: { vendor } });
                }}
                className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                checked={filter?.vendorTitle?.includes(vendor)}
                type="checkbox"
                id={vendor}
                name={vendor}
            />
            <label htmlFor={vendor} className={stls.checkboxLabel}>
                {vendor?.length < tooltipLaunchSize ? (
                    vendor
                ) : (
                    <Tooltip placement="top" title={vendor}>
                        <span className={stls.lable}>{vendor}</span>
                    </Tooltip>
                )}
            </label>
        </span>
    );

    return {
        items: searchedVendors.map((item, index) => renderListItem(item, index)),
        itemsLength: uniqVendors.length,
    };
};
