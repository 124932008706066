import { sortBy } from 'lodash';
import { Tooltip } from '@mui/material';
import { IUseRendersFilterLeftArgs } from '@f_qwep/components/filters/filterLeftPanel/types/useRedersFilterLeftArgs.interface';

export const useRenderWarehouse = ({
    items,
    filtersReducer,
    currentTheme,
    stls,
    filter,
    isSort,
    searchedWord,
    tooltipLaunchSize,
}: IUseRendersFilterLeftArgs) => {
    const allWarehouse = items.map((item: any) => item.warehouse);
    const dealerWarehouse = items.map((item: any) => item?.info?.isDealerWarehouse);
    const uniqWarehouse: string[] = Array.from(new Set(allWarehouse));
    const sortWarehouse: string[] = sortBy(uniqWarehouse);

    const currentWarehouse: string[] = isSort ? sortWarehouse : uniqWarehouse;

    const searchWarehouse = (): string[] => {
        return searchedWord === ''
            ? currentWarehouse
            : currentWarehouse.filter((warehouse: string) => {
                  return warehouse.toLowerCase().indexOf(searchedWord.toLowerCase()) === 0;
              });
    };

    const searchedWarehouses = searchWarehouse();

    const renderListItem = (warehouse: any, index: number) => (
        <span key={index} style={{ display: 'flex' }}>
            <input
                onChange={() => {
                    filtersReducer({
                        type: 'warehouses_toggle',
                        payload: { warehouse },
                    });
                }}
                className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                checked={filter?.warehouses?.includes(warehouse)}
                type="checkbox"
                id={warehouse}
                name={warehouse}
            />
            <label htmlFor={warehouse} className={stls.checkboxLabel}>
                {warehouse?.length < tooltipLaunchSize ? (
                    warehouse
                ) : (
                    <Tooltip placement="top" title={warehouse}>
                        <span className={stls.lable}>{warehouse}</span>
                    </Tooltip>
                )}
            </label>
        </span>
    );

    return {
        items: searchedWarehouses.map((item, index) => renderListItem(item, index)),
        itemsLength: uniqWarehouse.length,
    };
};
