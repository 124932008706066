import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconGroupsTableProps = TypeClassNames;

export const IconGroupsTable = ({ classNames }: TypeIconGroupsTableProps) => {
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_213_1324" fill="white">
                    <path d="M5 5H9V6H6.71L13.42 12.72L12.72 13.42L6 6.71V9H5V5ZM0 0H12V9L11 8V1H1V11H8L9 12H0V0ZM17 5V17H5V14H6V16H16V6H14V5H17Z" />
                </mask>
                <path
                    d="M5 5H9V6H6.71L13.42 12.72L12.72 13.42L6 6.71V9H5V5ZM0 0H12V9L11 8V1H1V11H8L9 12H0V0ZM17 5V17H5V14H6V16H16V6H14V5H17Z"
                    fill={colors.epsilon}
                />
                <path
                    d="M5 5V3H3V5H5ZM9 5H11V3H9V5ZM9 6V8H11V6H9ZM6.71 6V4H1.88665L5.29473 7.41316L6.71 6ZM13.42 12.72L14.8342 14.1342L16.2474 12.7211L14.8353 11.3068L13.42 12.72ZM12.72 13.42L11.3068 14.8353L12.7211 16.2474L14.1342 14.8342L12.72 13.42ZM6 6.71L7.41316 5.29473L4 1.88665V6.71H6ZM6 9V11H8V9H6ZM5 9H3V11H5V9ZM0 0V-2H-2V0H0ZM12 0H14V-2H12V0ZM12 9L10.5858 10.4142L14 13.8284V9H12ZM11 8H9V8.82843L9.58579 9.41421L11 8ZM11 1H13V-1H11V1ZM1 1V-1H-1V1H1ZM1 11H-1V13H1V11ZM8 11L9.41421 9.58579L8.82843 9H8V11ZM9 12V14H13.8284L10.4142 10.5858L9 12ZM0 12H-2V14H0V12ZM17 5H19V3H17V5ZM17 17V19H19V17H17ZM5 17H3V19H5V17ZM5 14V12H3V14H5ZM6 14H8V12H6V14ZM6 16H4V18H6V16ZM16 16V18H18V16H16ZM16 6H18V4H16V6ZM14 6H12V8H14V6ZM14 5V3H12V5H14ZM5 7H9V3H5V7ZM7 5V6H11V5H7ZM9 4H6.71V8H9V4ZM5.29473 7.41316L12.0047 14.1332L14.8353 11.3068L8.12527 4.58684L5.29473 7.41316ZM12.0058 11.3058L11.3058 12.0058L14.1342 14.8342L14.8342 14.1342L12.0058 11.3058ZM14.1332 12.0047L7.41316 5.29473L4.58684 8.12527L11.3068 14.8353L14.1332 12.0047ZM4 6.71V9H8V6.71H4ZM6 7H5V11H6V7ZM7 9V5H3V9H7ZM0 2H12V-2H0V2ZM10 0V9H14V0H10ZM13.4142 7.58579L12.4142 6.58579L9.58579 9.41421L10.5858 10.4142L13.4142 7.58579ZM13 8V1H9V8H13ZM11 -1H1V3H11V-1ZM-1 1V11H3V1H-1ZM1 13H8V9H1V13ZM6.58579 12.4142L7.58579 13.4142L10.4142 10.5858L9.41421 9.58579L6.58579 12.4142ZM9 10H0V14H9V10ZM2 12V0H-2V12H2ZM15 5V17H19V5H15ZM17 15H5V19H17V15ZM7 17V14H3V17H7ZM5 16H6V12H5V16ZM4 14V16H8V14H4ZM6 18H16V14H6V18ZM18 16V6H14V16H18ZM16 4H14V8H16V4ZM16 6V5H12V6H16ZM14 7H17V3H14V7Z"
                    fill={colors.epsilon}
                    mask="url(#path-1-inside-1_213_1324)"
                />
            </svg>
        </div>
    );
};
