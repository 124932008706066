import { UserContext } from '@f_context/User_context';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { TypeKeyAliasConfiguration, useUCSContext } from './UCS_context';
import { useLocalStorage } from '@f_hooks';
import { TypeCurrentUsers } from '../context/doughnut_context';
import { TypeActivityReport } from '@f_ucs/context/report_context';

export type TypeConfiguration = {
    key: string;
    collectors: TypeCollector[];
    type: 'web' | '1c';
};

export type TypeSuspiciousBuys = {}

export type TypeCurrentReport = {
    reportUUID: string;
    timeStart: string;
    timeFinish: string;
    sameItem: boolean;
    buysAmount: number;
    reportActivity: null | TypeActivityReport[]
    suspiciousBuys: null | TypeSuspiciousBuys[]
    time: string
};

export type TypeCollector = {
    authorizationCode: string;
    name: string;
    sameItem: boolean;
    sameQuantity: boolean;
    sameStatus: boolean;
    sameWarehouse: boolean;
    tokens: {
        key?: string;
        alias?: string;
        userId?: number;
        firstName?: string;
        lastName?: string;
        email?: string;
        phoneNumber?: string;
    }[];
    type: 'web' | '1c';
};

export type TypeCurrentType = 'web' | '1c';

export type TypeCreateCollector = {
    sameWarehouse: boolean;
    sameStatus: boolean;
    sameQuantity: boolean;
    tokens: { key: string; alias: string }[] | number[];
    type: 'web' | '1c';
    authKey: string;
    title: string;
    webKeyInfo?: {
        webKeyWorkspaceId: number;
        webKeyApplicationWorkspaceId: number;
    };
};

type TypeCollectorContext = {
    currentReports: TypeCurrentReport[]
    configurations: TypeConfiguration[]
    currentCollector: TypeCollector
    currentType: TypeCurrentType
    loading: boolean
    currentConfigurations: TypeConfiguration[]

    //methods
    setCurrentCollectorWeb: (value: TypeCollector) => void
    setCurrentCollector1c: (value: TypeCollector) => void
    setCurrentCollector: (value: TypeCollector) => void
    updateNameToken: (data: any) => Promise<any>
    setCurrentType: (value: TypeCurrentType) => void
    changeCurrentCollector: (type: TypeCurrentType) => void
    checkCollectorsAliasByKey: (key: string, type: TypeCurrentType) => string
    createCollector: (data: TypeCreateCollector) => Promise<any>
    setCurrentReports: (value: TypeCurrentReport[]) => void
    deleteCollector: (collectorName: string, authKey: string) => Promise<any>
    getCollectorReport: (collectorName: string, authKey: string, type: string) => Promise<any>
}

const CollectorContext = createContext<TypeCollectorContext | null>(null);

function CollectorProvider(props: any) {
    const { users } = useContext(UserContext);
    const {
        workspaceApplicationId,
        key1cAliasConfigurationList,
        keyWebAliasConfigurationList,
    } = useUCSContext();

    const [loading, setLoading] = useState(true);

    //report
    const [currentReports, setCurrentReports] = useState<TypeCurrentReport[]>([]);

    //configuration
    const [configurations, setConfigurations] = useState<TypeConfiguration[]>([]);

    //collector
    const [currentCollector, setCurrentCollector] = useLocalStorage('ucs_currentCollector', null);
    const [currentCollectorWeb, setCurrentCollectorWeb] = useLocalStorage('ucs_currentCollectorWeb', null);
    const [currentCollector1c, setCurrentCollector1c] = useLocalStorage('ucs_currentCollector1c', null);
    const [currentType, setCurrentType] = useLocalStorage('ucs_currentType', 'web');


    useEffect(() => {
        if (users?.length && workspaceApplicationId) {
            getConfigurations();
        }
    }, [users, workspaceApplicationId]);

    // Основная функция
    const getConfigurations = async () => {
        setLoading(true);
        const result = await callPlatformAPI(`ucs/get/collectors`, 'get');
        if (result.data.isOk) {
            setConfigurations(result.data.result);
            if (!currentCollector?.authorizationCode) {
                const collectorsWebAnd1c = getInitialCollector(result.data.result);
                setCurrentCollectorWeb(collectorsWebAnd1c.web);
                setCurrentCollector1c(collectorsWebAnd1c['1c']);
                const collector = collectorsWebAnd1c[currentType as TypeCurrentType];
                setCurrentCollector(collector);
                await getCollectorReport(collector?.name, collector?.authorizationCode, collector?.type);
            } else {
                await getCollectorReport(currentCollector.name, currentCollector.authorizationCode, currentCollector.type);
            }
        }
        setLoading(false);
        return result.data;
    };

    const changeCurrentCollector = async (type: TypeCurrentType) => {
        const collectors = {
            'web': currentCollectorWeb,
            '1c': currentCollector1c,
        };
        const collector = collectors[type as TypeCurrentType];
        setCurrentCollector(collector);
        await getCollectorReport(collector?.name, collector?.authorizationCode, collector?.type);
    };

    const getInitialCollector = (configurations: TypeConfiguration[]) => {
        const collectorWeb = configurations?.filter((el) => el.type === 'web')[0]?.collectors[0] ?? null;
        const collector1c = configurations?.filter((el) => el.type === '1c')[0]?.collectors[0] ?? null;
        return { 'web': collectorWeb, '1c': collector1c };
    };

    // отчет по коллектору
    const getCollectorReport = async (collectorName: string, authKey: string, type: string) => {
        setLoading(true);
        const result = await callPlatformAPI(
            `ucs/get/collector/report?collectorName=${collectorName}&type=${type}`,
            'get',
            {},
            {
                headers: { 'x-auth-key': authKey },
            },
        );
        if (result.data.isOk) {
            setCurrentReports(result.data.result);
        }
        setLoading(false);
        return result.data;
    };

    // создание коллектора
    const createCollector = async (data: TypeCreateCollector) => {
        const result = await callPlatformAPI('ucs/register/collector', 'post', {
            ...data,
            workspaceApplicationId: workspaceApplicationId,
        });
        if (result.data.isOk && result.data.status === 'success') {
            setConfigurations(result.data.result);
        }
        return result.data;
    };

    // удаление коллектора
    const deleteCollector = async (collectorName: string, authKey: string) => {
        const result = await callPlatformAPI(
            `ucs/delete/collector?collectorName=${collectorName}`,
            'delete',
            {},
            {
                headers: { 'x-auth-key': authKey },
            },
        );
        if (result.data.isOk) {
            setConfigurations(result.data.result);
        }
        return result.data;
    };

    const checkCollectorsAliasByKey = (key: string, type: TypeCurrentType) => {
        const aliasConfigurations = {
            'web': keyWebAliasConfigurationList,
            '1c': key1cAliasConfigurationList,
        };
        const foundKey = aliasConfigurations[type as TypeCurrentType].find((keyItem: TypeKeyAliasConfiguration) => keyItem.key === key);
        return foundKey ? foundKey.alias : `Неизвестный коллектор`;
    };

    const currentConfigurationsByCurrentType = () => {
        return configurations?.filter((item: TypeConfiguration) => item.type === currentType);
    };

    // указать псевдоним токена 1С
    const updateNameToken = async (data: any) => {
        const result = await callPlatformAPI(`ucs/update/token/1c`, 'put', {
            ...data,
        });
        if (result.data.isOk) {
            await updateCollectorTokensInLocalStorage(data.tokens);
        }
        return result.data.result;
    };

    const updateCollectorTokensInLocalStorage = async (tokens: TypeCurrentUsers) => {
        const updatedCollector: TypeCollector = {
            ...currentCollector,
            tokens: tokens,
        };
        setCurrentCollector(updatedCollector);
        currentType === '1c' ? setCurrentCollector1c(updatedCollector) : setCurrentCollectorWeb(updatedCollector);
        await getCollectorReport(currentCollector.name, currentCollector.authorizationCode, currentCollector.type);
    };

    return (
        <CollectorContext.Provider
            value={{
                currentReports,
                configurations,
                currentCollector,
                currentType,
                loading,
                currentConfigurations: currentConfigurationsByCurrentType(),

                //methods
                setCurrentCollectorWeb,
                setCurrentCollector1c,
                setCurrentCollector,
                updateNameToken,
                setCurrentType,
                changeCurrentCollector,
                checkCollectorsAliasByKey,
                createCollector,
                setCurrentReports,
                deleteCollector,
                getCollectorReport,
            } as TypeCollectorContext}
            {...props}
        />
    );
}


const useCollector = (): TypeCollectorContext => {
    const context = useContext(CollectorContext);
    if (!context) {
        console.error('CollectorContext');
    }
    return context as TypeCollectorContext;
};

export { CollectorProvider, useCollector };
