import stls from '@f_ucs/styles/general/Home.module.sass';
import { Wrapper } from '@f_general/Wrapper';
import { Report } from '../components/report/index';
import { VersionPanel } from '@f_ucs/components/versionPanel/VersionPanel';
import { ActionPanel } from '@f_ucs/components/actionPanel/ActionPanel';
import { DateSelectorPanel } from '@f_ucs/components/dataSelectorPanel/DateSelectorPanel';

type TypeLayoutPageProps = {
    classNames?: any;
};

export function Home({ classNames }: TypeLayoutPageProps) {

    return (
        <div className={stls.container}>
            <Wrapper classNames={[classNames, stls.wrapper]}>
                <VersionPanel/>
                <ActionPanel/>
                <DateSelectorPanel />
                <Report/>
            </Wrapper>
        </div>
    );
}
