import { PurchaseReportDoughnut } from './PurchaseReport/Doughnut/PurchaseReportDoughnut';
import stls from '@f_ucs/styles/components/report/violationStatisticsModule/violationStats.module.sass';



export function ViolationStatisticModule() {
    return (
        <div className={stls.violation}>
            <PurchaseReportDoughnut />
        </div>
    );
}
