import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconNotifProps = TypeClassNames;

export const IconNotif = ({ classNames }: TypeIconNotifProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.00011 18V12C2.00011 9.87827 2.84296 7.84344 4.34325 6.34315C5.84354 4.84285 7.87837 4 10.0001 4C12.1218 4 14.1567 4.84285 15.657 6.34315C17.1573 7.84344 18.0001 9.87827 18.0001 12V18H19.0001V20H1.00011V18H2.00011ZM4.00011 18H16.0001V12C16.0001 10.4087 15.368 8.88258 14.2427 7.75736C13.1175 6.63214 11.5914 6 10.0001 6C8.40881 6 6.88268 6.63214 5.75746 7.75736C4.63225 8.88258 4.00011 10.4087 4.00011 12V18ZM9.0001 0H11.0001V3H9.0001V0ZM17.7781 2.808L19.1921 4.222L17.0721 6.343L15.6571 4.929L17.7781 2.808ZM0.808105 4.222L2.22211 2.808L4.34311 4.928L2.93011 6.344L0.808105 4.222ZM5.00011 12C5.00011 10.6739 5.52689 9.40215 6.46457 8.46447C7.40225 7.52678 8.67402 7 10.0001 7V9C9.20446 9 8.44139 9.31607 7.87878 9.87868C7.31618 10.4413 7.00011 11.2044 7.00011 12H5.00011Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
