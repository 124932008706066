export const IconLowAssistant = (color: any) => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5408_28932)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.25 8L8.72 9.035L9.75 9.5L8.72 9.965L8.25 11L7.79 9.965L6.75 9.5L7.79 9.035L8.25 8ZM11.25 8.5C10.525 8.5 10.12 7.78 10.285 7.245L8.51 5.465C8.36 5.51 8.14 5.51 7.99 5.465L6.715 6.74C6.885 7.275 6.48 8 5.75 8C5.025 8 4.615 7.28 4.785 6.74L2.505 4.465C1.97 4.63 1.25 4.225 1.25 3.5C1.25 2.95 1.7 2.5 2.25 2.5C2.975 2.5 3.38 3.22 3.215 3.755L5.49 6.035C5.64 5.99 5.86 5.99 6.01 6.035L7.285 4.76C7.115 4.225 7.52 3.5 8.25 3.5C8.975 3.5 9.385 4.22 9.215 4.76L10.995 6.535C11.53 6.37 12.25 6.775 12.25 7.5C12.25 8.05 11.8 8.5 11.25 8.5ZM2.75 8L2.5 7L2.25 8L1.25 8.25L2.25 8.5L2.5 9.5L2.75 8.5L3.75 8.25L2.75 8Z"
                    fill={color.color}
                />
            </g>
            <defs>
                <clipPath id="clip0_5408_28932">
                    <rect width="12" height="12" fill="white" transform="matrix(1 0 0 -1 0.75 12.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
