export const formatPercentageLessBetter = (value: number | string) => {
    if (typeof value !== 'number') {
        return value;
    }
    return value <= 0 ? `+${Math.ceil(value) * -1}%` : `-${Math.ceil(value)}%`;
};

export const formatPercentageMoreBetter = (value: number | string) => {
    if (typeof value !== 'number') {
        return value;
    }
    return value < 0 ? `-${Math.ceil(value) * -1}%` : `+${Math.ceil(value)}%`;
};