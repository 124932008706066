import { BasketContext } from '@f_qwep/context/Basket_context';
import { ThemeContext } from '@f_context/Theme_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Alert } from '@f_general/alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Button,
    CardContent,
    InputBase,
    MenuItem,
    Modal,
    Select,
    styled,
    TextField,
    Typography,
    Grow,
    ButtonGroup,
    Popper,
    List,
    Grid,
    ListItemButton,
    Tooltip,
} from '@mui/material';
import { FormEvent, useContext, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '../../styles/component/basket/Basket.module.sass';
import { MyModal } from '@f_general/MyModal';
import { Field } from '@f_qwep/types';
import { Property } from '@f_qwep/types';
import { render } from 'react-dom';
import { IconOrder } from '../icons/IconOrder';
import { IconClose } from '@f_components/icons/IconClose';
import { isObject } from 'lodash';

export function OrderModal() {
    const { t } = useTranslation();
    const { colors, CustomSelect } = useContext(ThemeContext);
    const { updateProperty, orderDraft, orderDraftIsActive, workspaceApplication, setOrderDraftIsActive } =
        useContext(QwepContext);
    const [openAdd, setOpenAdd] = useState(false);

    const [loading, setLoading] = useState(false);

    const {
        cartOrder,
        basket,
        basketForm,
        setBasketForm,
        openModalForm,
        setOpenModalForm,
        checkedItems,
        setCheckedItems,
        checkedOnlyItem,
        setCheckedOnlyItem,
        setCheckedBasketItems,
    } = useContext(BasketContext);
    const handleOpenAdd = () => setOpenAdd(true);
    const [values, setValues] = useState<any>({});

    const anchorRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleCheckbox = async (id: any, flag: any) => {
        const newValue = orderDraft.map((item: any) => {
            if (item.id === id) {
                return {
                    ...item,
                    flag: !flag,
                };
            }
            return {
                ...item,
                flag: false,
            };
        });
        await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.OrderDraft,
            value: { value: newValue },
        });
    };

    const handleClose = async (resetChecked = false) => {
        setValues({});
        setBasketForm({});
        setOpenModalForm(false);
        setCheckedOnlyItem([]);
        setOrderDraftIsActive(false);
        if (resetChecked) {
            setCheckedBasketItems({});
            setCheckedItems((prev: any) => ({
                ...prev,
                [basketForm.accountId]: [],
            }));
        }
        const newValue = orderDraft?.map((item: any) => {
            return {
                ...item,
                flag: false,
            };
        });
        await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.OrderDraft,
            value: { value: newValue },
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        const fieldValues = Object.keys(values).map((fieldName: string) => {
            return {
                fieldName: values[fieldName].fieldName,
                value: values[fieldName].value,
            };
        });
        let items = [];
        if (checkedOnlyItem.length) {
            items = checkedOnlyItem;
        } else {
            items = checkedItems[basketForm.accountId];
        }

        const result = await cartOrder({
            accountId: basketForm.accountId.toString(),
            formId: +basketForm.formId,
            fieldValues: fieldValues,
            ...(basketForm.hasPartOrder && { onlyItems: items }),
        });
        if (result.isOk) {
            if (result.result?.warnings?.length) {
                result.result?.warnings?.map((item: any) => {
                    Alert({
                        type: 'warning',
                        text: item.message,
                        time: 10000,
                    });
                });
            }
            if (isObject(result.result?.warnings)) {
                Alert({
                    type: 'success',
                    text: result.result?.warnings.message,
                    time: 10000,
                });
            }

            Alert({
                type: 'success',
                text: t('general.success'),
            });
            await handleClose(true);
        } else {
            if (result.result?.errors) {
                result.result.errors?.map((item: any) => {
                    if (item.code == '18010') {
                        Alert({
                            type: 'warning',
                            text: item.message,
                            time: 10000,
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: item.message,
                            time: 10000,
                        });
                    }
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
            await handleClose();
        }
        setLoading(false);
    };

    // в каком виде данные с шаблонами хранятся в БД =>

    const [templateValue, setTemplateValue]: any = useState({
        id: 1,
        name: '',
        flag: false,
        draft: [],
    });

    // что происходит по нажатию кнопки сохранить шаблон =>

    const handleAddOrderDraft = async () => {
        const names = orderDraft?.map((el: any) => el?.name);

        // проверка если шаблоны для данного поставщика уже существуют, то мы их обновляем, а не сохраняем новую ячейку =>

        if (templateValue && names?.filter((name: any) => name == templateValue.name).length > 0) {
            let newValue: any = [];

            orderDraft.map((el: any) => {
                if (el.name == templateValue.name) {
                    newValue.push({ ...el, draft: [...templateValue.draft] });
                } else {
                    newValue.push(el);
                }
            });

            if (newValue) {
                const result = await updateProperty({
                    applicationWorkspaceId: workspaceApplication,
                    propertyName: Property.OrderDraft,
                    value: { value: newValue },
                });
                if (!result?.isOk) {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                } else {
                    Alert({
                        type: 'success',
                        text: 'Шаблон для поставщика успешно обновлен',
                    });
                }
            }

            //  если шаблоны для данного поставщика еще не были сохранены =>
        } else {
            const newValue = orderDraft ? [...orderDraft, templateValue] : [templateValue];

            const result = await updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.OrderDraft,
                value: { value: newValue },
            });
            if (!result?.isOk) {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            } else {
                Alert({
                    type: 'success',
                    text: 'Успешно!',
                });
            }
        }
    };

    // создаем коллекцию с value и соотвествующим текстом, т к  value каждую загрузку новый
    const collection: any = [];
    const rec = (fields: any) => {
        fields?.forEach((c: any) => {
            if (c?.options) {
                rec(c?.options);
            } else if (c?.fields) {
                rec(c?.fields);
            }
            const newObj: any = {};
            if (c.value != undefined) {
                newObj[c?.value] = c?.text;
                collection.push(newObj);
            }
        });
    };
    rec(basketForm.fields);

    const colOptions: any = [];
    const recurv = (field: any) => {
        field?.forEach((el: any) => {
            if (el.options) {
                const newObj: any = {};
                newObj[el.fieldName] = el.options;
                colOptions.push(newObj);
                el.options.map((c: any) => {
                    if (c.fields) {
                        recurv(c.fields);
                    }
                });
            }
        });
    };

    recurv(basketForm.fields);

    const findOptions = (opt: any, value: string) => {
        if (opt.length === 1) {
            return opt[0][0][1];
        } else {
            let result: any;
            opt.forEach((c: any) => {
                if (c[0][1].filter((el: any) => el.value == value).length > 0) {
                    result = c[0][1];
                    return c[0][1];
                }
            });
            return result;
        }
    };

    const uniqValueText = Array.from(new Set(collection));

    // в каком виде хранится поле draft =>
    const newDraft = Object.keys(values).map((fieldName: string) => {
        const actualOpt = findOptions(
            colOptions
                .map((el: any) => Object.entries(el))
                .filter((el: any) => el[0][0] == values[fieldName]?.fieldName),
            values[fieldName]?.value,
        );
        const newObj: any = {};
        const defaultText = [];
        if (
            uniqValueText
                ?.map((el: any) => Object.entries(el))
                .filter((el: any) => el[0][0] == values[fieldName]?.value)[0]
        ) {
            newObj.value = uniqValueText
                ?.map((el: any) => Object.entries(el))
                .filter((el: any) => el[0][0] == values[fieldName]?.value)[0][0][0];
            newObj.text = uniqValueText
                ?.map((el: any) => Object.entries(el))
                .filter((el: any) => el[0][0] == values[fieldName]?.value)[0][0][1];
            defaultText.push(newObj);
        }

        return {
            fieldName: values[fieldName].fieldName,
            defaultText: defaultText,
            type: values[fieldName].type,
            value: values[fieldName].value,
            options: actualOpt,
        };
    });

    // эффект когда создали новый драфт - создается новый template, который в свою очередь по нажатию кнопки используется и сохранится в БД =>

    useEffect(() => {
        if (newDraft?.length > 0) {
            const currentcell = basket.filter((el: any) => el.accountId === basketForm.accountId);
            setTemplateValue({
                id: basketForm.accountId,
                flag: false,
                name: `Шаблон для ${currentcell[0].vendorTitle}`,
                draft: [...newDraft],
            });
        }
    }, [JSON.stringify(newDraft)]);

    // фильрация orderDraft  по имени поставщика, показываются только параметры для текущего поставщика =>

    function isActualProvider(el: any) {
        const currentcell = basket.filter((bas: any) => bas?.accountId === basketForm?.accountId);
        return el?.name.substr(11) == currentcell[0]?.vendorTitle;
    }

    // структуры для передачи на отрисовку в RenderField =>

    const [draftToRender, setDraftToRender] = useState([]);
    const [valueTorender, setValueToRender] = useState([]);

    // эффект для создания структур при нажатии галочки выбора параметров =>

    useEffect(() => {
        const values = orderDraft?.filter(isActualProvider)[0]?.draft?.map((el: any) => {
            const newObj: any = {};
            newObj[el.fieldName] = el;
            newObj[el.fieldName].defaultValue = el.value;
            return newObj;
        });
        setValueToRender(values);

        const formingResult = (obj: any) => {
            const newObj: any = {};
            for (const key in obj) {
                if (key == 'defaultValue') {
                    orderDraft?.filter(isActualProvider)[0]?.draft?.map((el: any) => {
                        if (obj.fieldName == el.fieldName) {
                            if (el.options?.length > 0) {
                                newObj[key] = obj.options?.filter(
                                    (f: any) => f?.text == el.defaultText[0]?.text,
                                )[0]?.value;
                            } else if (obj.options?.length > 0) {
                                newObj[key] = obj.options?.filter(
                                    (f: any) => f?.text == el.defaultText[0]?.text,
                                )[0]?.value;
                            } else {
                                newObj[key] = el.value;
                            }
                        }
                    });
                } else if (key == 'options' && obj.options !== null) {
                    const newArray: any = [];
                    obj.options.map((opt: any, ind: number) => {
                        const newOptions: any = {};
                        newOptions.text = obj.options[ind].text;
                        newOptions.value = obj.options[ind].value;
                        newOptions.fields = obj.options[ind].fields?.map((el: any) => {
                            return formingResult(el);
                        });
                        newArray.push(newOptions);
                    });
                    newObj.options = newArray;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        };

        const result = basketForm.fields?.map((obj: any) => {
            return formingResult(obj);
        });

        setDraftToRender(result);
    }, [orderDraftIsActive]);

    const handleDelete = async (id: number) => {
        const newDraft = orderDraft.filter((el: any) => el.id !== id);
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.OrderDraft,
            value: { value: newDraft },
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        } else {
            Alert({
                type: 'success',
                text: 'Шаблон удален!',
            });
        }
    };

    return (
        <Modal open={openModalForm} onClose={handleClose} style={{ zIndex: 1000 }}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <div className={stls.modalTitle}>
                        <div className={stls.modalTitleIcon}>
                            <IconOrder />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {t('qwep.basket.ordering')}
                            </Typography>
                        </div>

                        <>
                            <div className={stls.groupBtn}>
                                <ButtonGroup variant="text" ref={anchorRef}>
                                    <Button
                                        aria-controls={open ? 'split-button-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleToggle}>
                                        <p style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {orderDraftIsActive
                                                ? orderDraft?.filter((el: any) => el.flag)[0]?.name
                                                : 'Сохраненные шаблоны'}
                                        </p>
                                        <ExpandMoreIcon sx={{ color: colors.alpha }} />
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <Popper
                                style={{ zIndex: 10000 }}
                                onClick={() => setOpen(false)}
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}>
                                        <List
                                            style={{ padding: '12px' }}
                                            sx={{
                                                background: colors.psi,
                                                boxShadow: '0px 3px 10px 0px #002B4D2E',
                                            }}>
                                            <div className={stls.listContent}>
                                                {orderDraft
                                                    ?.filter((il: any) => isActualProvider(il))
                                                    .map((item: any) => (
                                                        <ListItemButton
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                            key={item.id}
                                                            className={stls.listItem}>
                                                            <p
                                                                onClick={() => {
                                                                    handleCheckbox(item.id, item.flag);
                                                                }}>
                                                                {item?.name}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <Tooltip placement="right-start" title="Удалить шаблон">
                                                                    <button onClick={() => handleDelete(item.id)}>
                                                                        <IconClose
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                marginLeft: '8px',
                                                                            }}
                                                                            width="16"
                                                                            height="16"
                                                                        />
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                        </ListItemButton>
                                                    ))}
                                            </div>
                                        </List>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    </div>
                    <form className={stls.form} onSubmit={handleSubmit}>
                        <div className={stls.modalBasketContent}>
                            {basketForm.fields &&
                                (orderDraftIsActive ? (
                                    <RenderFields fields={draftToRender} setValues={setValues} values={valueTorender} />
                                ) : (
                                    <RenderFields fields={basketForm.fields} setValues={setValues} values={values} />
                                ))}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '36px',
                            }}>
                            <Button
                                className={stls.modalButton}
                                onClick={() => {
                                    handleClose();
                                }}
                                sx={{
                                    padding: '13px',
                                    background: colors.delta,
                                    color: colors.phi,
                                    '&:hover': { color: colors.alpha },
                                }}>
                                {t('qwep.settings.suppliers.canceled')}
                            </Button>
                            <Button
                                id="saveNewOrderDraft"
                                onClick={() => {
                                    handleAddOrderDraft();
                                }}
                                style={{
                                    maxWidth: '200px',
                                    border: `2px solid ${colors.alpha}`,
                                    marginTop: '10px',
                                    padding: '8px 16px',
                                    height: '53px',
                                }}>
                                Сохранить шаблон
                            </Button>
                            <Button disabled={loading} className={stls.modalButton} variant="contained" type={'submit'}>
                                {t('qwep.basket.confirm')}
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </MyModal>
        </Modal>
    );
}

const RenderFields = ({ fields, setValues, values }: any) => {
    // const [fieldsItems, setFieldsItems] = useState(fields);

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ md: 3 }}>
                {fields?.map((item: any) => {
                    return (
                        <Grid item xs={6}>
                            <ItemField key={item.fieldId} field={item} setValues={setValues} values={values} />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
    // return (
    //   <>
    //   {
    //      fieldsItems?.map((item: any) => {
    //       return <ItemField key={item.fieldId} field={item} setValues={setValues} values={values}/>;
    //     })
    //   }
    //   </>
    // )
};

type TypeField = {
    defaultValue: string;
    error: any;
    fieldId: string;
    fieldName: string;
    fields: any;
    options: TypeOption[] | null;
    required: boolean;
    title: string;
    type: number;
    typeName: string;
    validation: any;
};

type TypeOption = {
    fields: TypeField[] | null;
    text: string;
    value: string;
};

type TypeFieldItems = {
    defaultValue: string | null;
    typeName: string;
    fieldName: string;
    fieldId: string;
    title: string;
    options: TypeOption[] | null;
    parentName: string;
    level: number;
};

const ItemField = ({ field, setValues, values }: any) => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const CustomSelect = styled(InputBase)(({ theme }) => ({
        '.MuiSvgIcon-root ': {
            fill: colors.epsilon,
        },
        '& .MuiInputBase-input': {
            borderRadius: 12,
            border: `1px solid ${colors.alpha}`,
            padding: '10px 26px 10px 12px',
            fontFamily: 'Inter',
            fontSize: 14,
            color: colors.omicron,
            '&:focus': {
                borderRadius: 12,
                border: `1px solid ${colors.xi}`,
            },
        },
    }));

    const [fields, setFields] = useState<any>({});
    const [currentField, setCurrentField] = useState<any>({});

    useEffect(() => {
        if (currentField?.depth === 1) {
            let result: any = {};
            Object.keys(fields).map((fieldName: string) => {
                if (currentField.group === fields[fieldName].group) {
                    if (currentField?.depth === fields[fieldName].depth) {
                        result[fieldName] = fields[fieldName];
                    } else {
                        if (
                            fields[fieldName].parentValue === currentField.value ||
                            fields[fieldName].type === Field.TextareaField ||
                            fields[fieldName].type === Field.InputField
                        ) {
                            result[fieldName] = fields[fieldName];
                        } else {
                            delete values[fieldName];
                        }
                    }
                } else {
                    result[fieldName] = fields[fieldName];
                }
            });

            setFields(result);
        }
    }, [currentField]);

    useEffect(() => {
        setValues((prev: any) => ({
            ...prev,
            [field.fieldName]: {
                fieldName: field.fieldName,
                value: field.defaultValue,
                type: field.typeName,
            },
        }));
        renderField(field, field.fieldName, 1, '');

        if (field.defaultValue && field.options?.length) {
            field.options.map((item: any) => {
                if (item.value === field.defaultValue && item.fields) {
                    item.fields.map((f: any) => {
                        renderField(f, field.fieldName, 2, '');
                        console.log('AAAAAAAAAAA', f);

                        setValues((prev: any) => ({
                            ...prev,
                            [f.fieldName]: {
                                fieldName: f.fieldName,
                                value: f.defaultValue,
                                type: f.typeName,
                            },
                        }));
                        // console.log('||', values);
                    });
                }
            });
        }
    }, [field]);

    const selectHandler = (option: any, depth: number, fieldName: string, group: string) => {
        setValues((prev: any) => ({
            ...prev,
            [fieldName]: {
                fieldName: fieldName,
                value: option.value,
                type: field.typeName,
            },
        }));

        setCurrentField({
            depth: depth,
            fieldName: fieldName,
            group: group,
            value: option.value,
        });
        if (option.fields?.length) {
            depth += 1;
            option.fields.map((field: any) => {
                renderField(field, group, depth, option.value);
                if (field.defaultValue) {
                    setValues((prev: any) => ({
                        ...prev,
                        [field.fieldName]: {
                            fieldName: field.fieldName,
                            value: field.defaultValue,
                            type: field.typeName,
                        },
                    }));
                }
            });
        }
    };

    const inputHandler = (e: string, fieldName: string) => {
        setValues((prev: any) => ({
            ...prev,
            [fieldName]: {
                fieldName: fieldName,
                value: e,
                type: field.typeName,
            },
        }));
    };

    const renderField = (field: any, group: string, depth: number, parentValue: string) => {
        switch (field.typeName) {
            case Field.TextField:
                setFields((prev: any) => ({
                    ...prev,
                    [field.fieldName]: {
                        title: field.title,
                        group: group,
                        depth: depth,
                        type: Field.TextField,
                        options: field.options,
                        component: (
                            <div>
                                <p>
                                    <span>{field.title}:</span> {field.defaultValue ? field.defaultValue : '-'}
                                </p>
                            </div>
                        ),
                    },
                }));
                break;
            case Field.InputField:
                setFields((prev: any) => ({
                    ...prev,
                    [field.fieldName]: {
                        title: field.title,
                        group: group,
                        depth: depth,
                        type: Field.InputField,
                        options: field.options,
                        component: field.defaultValue ? (
                            <div>
                                <Typography>{field.title}</Typography>
                                <TextField
                                    onChange={({ target }) => inputHandler(target.value, field.fieldName)}
                                    placeholder={field.defaultValue}
                                    // value={field.defaultValue}
                                />
                            </div>
                        ) : (
                            <div>
                                <Typography>{field.title}</Typography>
                                <TextField onChange={({ target }) => inputHandler(target.value, field.fieldName)} />
                            </div>
                        ),
                    },
                }));
                break;
            case Field.SelectField:
                setFields((prev: any) => ({
                    ...prev,
                    [field.fieldName]: {
                        title: field.title,
                        group: group,
                        depth: depth,
                        type: Field.SelectField,
                        options: field.options,
                        parentValue: parentValue,
                        component: (
                            <div className={stls.modalInput}>
                                <Typography>{field.title}</Typography>
                                <div className={stls.selectBox}>
                                    <Select
                                        id={field.fieldId}
                                        input={<CustomSelect />}
                                        className={stls.select}
                                        style={{ maxWidth: '320px', overflowX: 'auto' }}
                                        autoWidth
                                        defaultValue={field.defaultValue}
                                        MenuProps={{
                                            sx: {
                                                '&& .MuiMenuItem-root': {
                                                    fontSize: 14,
                                                    fontFamily: 'Inter',
                                                    color: colors.omicron,
                                                    maxWidth: '100%',
                                                    '&:hover': {
                                                        background: colors.beta,
                                                    },
                                                },
                                            },
                                        }}>
                                        {field.options?.length ? (
                                            field.options.map((option: any) => {
                                                return (
                                                    <MenuItem
                                                        style={{ maxWidth: '400px' }}
                                                        onClick={() =>
                                                            selectHandler(option, depth, field.fieldName, group)
                                                        }
                                                        id={option.value}
                                                        key={option.text}
                                                        value={option.value}>
                                                        {option.text.length > 30 ? (
                                                            <Tooltip title={option.text}>
                                                                <p
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                    }}>
                                                                    {option.text}
                                                                </p>
                                                            </Tooltip>
                                                        ) : (
                                                            <p>{option.text}</p>
                                                        )}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <></>
                                        )}
                                    </Select>
                                </div>
                            </div>
                        ),
                    },
                }));
                break;
            case Field.DateField:
                setFields((prev: any) => ({
                    ...prev,
                    [field.fieldName]: {
                        title: field.title,
                        group: group,
                        depth: depth,
                        type: Field.DateField,
                        options: field.options,
                        field,
                        component: field.defaultValue ? (
                            <div className={stls.modalInput}>
                                <Typography>Date: {field.title}</Typography>
                                <TextField
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="date"
                                    onChange={({ target }) => inputHandler(target.value, field.fieldName)}
                                />
                            </div>
                        ) : (
                            <div className={stls.modalInput}>
                                <Typography>Date: {field.title}</Typography>
                                <TextField
                                    InputLabelProps={{ shrink: true, required: true }}
                                    type="date"
                                    onChange={({ target }) => inputHandler(target.value, field.fieldName)}
                                />
                            </div>
                        ),
                    },
                }));
                break;

            case Field.TextareaField:
                setFields((prev: any) => ({
                    ...prev,
                    [field.fieldName]: {
                        title: field.title,
                        group: group,
                        depth: depth,
                        type: Field.TextareaField,
                        options: field.options,
                        component: field.defaultValue ? (
                            <div className={stls.modalInput}>
                                <Typography className={stls.modalTypography}> {field.title}:</Typography>
                                <TextField
                                    onChange={({ target }) => inputHandler(target.value, field.fieldName)}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    defaultValue={field.defaultValue}
                                />
                            </div>
                        ) : (
                            <div className={stls.modalInput}>
                                <Typography className={stls.modalTypography}> {field.title}:</Typography>
                                <TextField
                                    onChange={({ target }) => inputHandler(target.value, field.fieldName)}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                />
                            </div>
                        ),
                    },
                }));
                break;
        }
    };

    return (
        <div>
            {Object.keys(fields).map((fieldName: string) => {
                return (
                    <div className={stls.modalInput} key={fieldName}>
                        {fields[fieldName].component}
                    </div>
                );
            })}
        </div>
    );
};
