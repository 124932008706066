import { createContext, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { PreviewTableStore } from '@f_qwep/general/previewTable/model/store/PreviewTable.store';

export const PreviewTableContext = createContext<PreviewTableStore | null>(null);

export const PreviewTableProvider = observer(({ children }: { children: ReactNode }) => {
    // инициализация стора
    const store = new PreviewTableStore();

    return <PreviewTableContext.Provider value={store}>{children}</PreviewTableContext.Provider>;
});
