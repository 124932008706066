import { Tooltip } from '@mui/material';
import cn from 'classnames';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export function CellBrand({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <p style={{ color: colors.alpha }} className={cn(stls.cellBrand)}>
            {info.row.original.brand}
        </p>
    );
}
