import { useNavigate } from 'react-router-dom';

const useCrossdataNavigate = () => {
    const navigate = useNavigate();
    return (url = '') => {
        navigate(`/app-crossdata/${url}`);
    };
};

export default useCrossdataNavigate;
