import { Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import normaiCSS from '../../styles/component/basket/BasketPanel.module.sass';
import miniCSS from '../../styles/component/basket/MiniBasketPanel.module.sass';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { sortBy } from 'lodash';
import { BasketContext } from '../../context/Basket_context';
import { useTranslation } from 'react-i18next';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { ThemeContext } from '@f_context/Theme_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Alert } from '@f_general/alert';
import { Tariff } from '@f_qwep/types';
import { IconMargin } from '../icons/IconMargin';

export function BasketPanel({ items }: any) {
    const [sortBrand, setSortBrand] = React.useState(false);
    const [sortVendor, setSortVendor] = React.useState(false);
    const { t } = useTranslation();

    const { colors, currentTheme } = useContext(ThemeContext);
    const { basketSum, basketSumItems } = useContext(BasketContext);
    const { basketFiltersReducer, filterBasket } = useContext(FilterContext);
    const { miniTableRes, priceMarginIsActive, setPriceMarginIsActive, priceMarginState, tariff } =
        useContext(QwepContext);
    const stls = miniTableRes ? miniCSS : normaiCSS;

    const handleMarginChange = async () => {
        if (!priceMarginState || !(priceMarginState.filter((el: any) => el.flag).length > 0)) {
            Alert({
                type: 'warning',
                text: 'Наценка не настроена\nПройдите в настройки и укажите конфигурацию наценки',
            });
            return;
        } else {
            setPriceMarginIsActive((prev: any) => !prev);
            Alert({
                type: 'success',
                text: priceMarginIsActive ? 'Вы выключили режим наценки!' : 'Вы включили режим наценки!',
            });
        }
    };

    // производители
    const renderBrands = () => {
        const allBrands = items
            .map((el: any) => el.basketItems)
            .flat()
            .map((el: any) => el.brand);
        const uniqBrands = Array.from(new Set(allBrands));
        const sortBrands = sortBy(uniqBrands);

        const renderListItem = (brand: any) => (
            <span style={{ display: 'flex' }}>
                <input
                    onClick={() => {
                        basketFiltersReducer({ type: 'brands_toggle', payload: { brand } });
                    }}
                    className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                    type="checkbox"
                    checked={filterBasket?.brands?.includes(brand)}
                    id={brand}
                    name={brand}
                />
                <label htmlFor={brand} className={stls.checkboxLabel}>
                    {brand?.length < 10 ? (
                        brand
                    ) : (
                        <Tooltip placement="top" title={brand}>
                            <span className={stls.lable}>{brand}</span>
                        </Tooltip>
                    )}
                </label>
            </span>
        );

        return sortBrand ? sortBrands.map(renderListItem) : uniqBrands.map(renderListItem);
    };

    // поставщики
    const renderVendors = () => {
        const allVendors = items.map((item: any) => item.vendorTitle);
        const uniqVendors = Array.from(new Set(allVendors));
        const sortVendors = sortBy(uniqVendors);

        const renderListItem = (vendor: any) => (
            <span style={{ display: 'flex' }}>
                <input
                    onClick={() => {
                        basketFiltersReducer({ type: 'vendors_toggle', payload: { vendor } });
                    }}
                    className={currentTheme === 'vinpin' ? stls.checkboxvinpin : stls.checkbox}
                    checked={filterBasket?.vendorTitle?.includes(vendor)}
                    type="checkbox"
                    id={vendor}
                    name={vendor}
                />
                <label htmlFor={vendor} className={stls.checkboxLabel}>
                    {vendor?.length < 10 ? (
                        vendor
                    ) : (
                        <Tooltip placement="top" title={vendor}>
                            <span className={stls.lable}>{vendor}</span>
                        </Tooltip>
                    )}
                </label>
            </span>
        );

        return sortVendor ? sortVendors.map(renderListItem) : uniqVendors.map(renderListItem);
    };

    const handleClickBrand = () => {
        setSortBrand(!sortBrand);
    };

    const handleClickVendor = () => {
        setSortVendor(!sortVendor);
    };

    return (
        <div id="basket-panel" className={stls.listNav}>
            <div className={stls.listItemNav}>
                <h5 className={stls.subtitle}>
                    {' '}
                    {t('qwep.basket.basket')} <sup style={{ color: colors.gamma }}>{basketSumItems}</sup>
                </h5>
                {Tariff.LITE !== tariff && (
                    <div onClick={handleMarginChange}>
                        <IconMargin color={priceMarginIsActive ? colors.alpha : colors.sigma} />
                    </div>
                )}
            </div>
            <div className={stls.listItemNav}>
                <h5 className={stls.subtitle}>{t('qwep.basket.brands')}</h5>
                <div className={stls.icon} onClick={handleClickBrand}>
                    {sortBrand ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </div>
            </div>
            <div className={stls.listItemCheck}>{renderBrands()}</div>

            <div className={stls.listItemNav}>
                <h5 className={stls.subtitle}>{t('qwep.basket.vendors')}</h5>
                <div className={stls.icon} onClick={handleClickVendor}>
                    {sortVendor ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </div>
            </div>
            <div className={stls.listItemCheck}>{renderVendors()}</div>

            <div className={stls.listItemNav}>
                <h5 className={stls.subtitle}>{t('qwep.basket.products')}</h5>
            </div>
            <div className={stls.listItemCheck}>
                <h5 className={stls.subtitle} style={{ color: colors.gamma }}>
                    {basketSumItems} {t('qwep.basket.pieces')}{' '}
                </h5>
            </div>

            <div className={stls.listItemNav}>
                <h5 className={stls.subtitle}>{t('qwep.basket.total')}</h5>
            </div>
            <div className={stls.listItemCheck}>
                <h5 className={stls.subtitle} style={{ color: colors.gamma }}>
                    {(+basketSum.toFixed(2)).toLocaleString()} ₽{' '}
                </h5>
            </div>
        </div>
    );
}
