import axios, { AxiosResponse } from 'axios';
import { getToken } from './getToken';

type TypeMethod = 'post' | 'get' | 'put' | 'delete';
type TypeParam = {
    headers?: object;
    guard?: boolean;
    params?: any;
};

export const callPlatformAPI = async (
    url: string,
    method: TypeMethod,
    body?: object | [],
    { headers, guard = true, params }: TypeParam = {},
) => {
    try {
        const token = guard && (await getToken());

        const instance = axios.create({
            baseURL: '/api',
            timeout: 500000,
            withCredentials: true,
            headers: {
                Authorization: token ? `Bearer ${token}` : null,
                ...headers,
            },
        });

        const res = instance({
            method: method,
            url: url,
            ...{ data: body },
            ...{ params: params },
        });

        return await res;
    } catch (err) {
        console.log('Err: callPlatformAPI', err);
        return {
            data: {
                isOk: false,
                status: err,
                result: null,
            },
        };
    }
};

export const callSendFormData = async (url: string, method: TypeMethod, data: object) => {
    try {
        const token = await getToken();

        const instance = axios.create({
            baseURL: '/api',
            timeout: 500000,
            headers: {
                Authorization: token ? `Bearer ${token}` : null,
                'Content-Type': 'multipart/form-data; charset=utf-8',
            },
        });

        const res = instance({
            method: method,
            url: url,
            data: data,
            params: {},
        });

        return await res;
    } catch (err) {
        console.log('callSendFormData', err);
        return {
            data: {
                isOk: false,
                status: err,
            },
        };
    }
};
