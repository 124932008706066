//Бек пока не готов

import { action, makeObservable, observable } from 'mobx';
import { AssistantModalControlStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/AssistantModalControl.store';
import { User } from '@f_types/User.interface';

export class ConfigEmailModalStore extends AssistantModalControlStore {
    isLoading: boolean = false;
    activeEmails: string[] = [];

    constructor() {
        super();
        makeObservable(this, {
            isLoading: observable,
            activeEmails: observable,
            setIsLoading: action.bound,
            setActiveEmails: action.bound,
        });
    }

    setActiveEmails(
        isNeedsTransformed: boolean,
        usersEmailActivity: Record<number, boolean>[] | string[],
        users?: User[],
    ) {
        this.activeEmails =
            isNeedsTransformed && users
                ? this.transformUsersEmail(usersEmailActivity as Record<number, boolean>[], users)
                : (usersEmailActivity as string[]);

        this.closeModal();
        this.setIsLoading(false);
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    private transformUsersEmail(usersEmailActivity: Record<number, boolean>[], users: any): string[] {
        return users.reduce((acc: string[], user: { userId: number; email: string }) => {
            if (usersEmailActivity[user.userId]) acc.push(user.email);
            return acc;
        }, []);
    }
}
