import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';
import { color } from '@mui/system';

type TypeIconNotGroupProps = TypeClassNames;

export const IconNotGroup = ({ classNames }: TypeIconNotGroupProps) => {
    const { colors, currentTheme } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="350" height="350" viewBox="0 0 350 350" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M127.238 303.107C145.809 314.3 159.058 309.653 164.13 300.677C173.938 283.319 206.551 247.823 258.233 235.825C326.684 219.935 312.389 177.745 270.31 155.605C228.23 133.466 196.767 102.831 201.529 69.5222C206.291 36.2128 159.109 24.73 138.127 88.8059C124.567 130.214 100.268 141.308 84.7728 143.974C70.1689 146.488 54.194 149.641 46.4039 162.246C36.9511 177.541 40.2497 193.013 79.8265 203.625C135.911 218.662 85.613 278.016 127.238 303.107Z"
                    fill={currentTheme === 'dark' ? '#2E303F' : '#E5F1FF'}
                />
                <path
                    d="M78.9865 230.53C79.7724 230.08 88.6428 226.325 132.771 246.898C132.643 247.073 132.588 247.155 132.588 247.155C132.588 247.155 86.7122 262.605 77.7237 243.572C75.0395 237.906 75.8086 234.004 78.9098 230.574L78.9865 230.53Z"
                    fill={currentTheme === 'dark' ? '#94A3B8' : '#8CBCE1'}
                />
                <path
                    d="M227.306 238.315C217.718 246.375 202.683 252.225 188.176 244.446C190.468 242.981 192.836 241.472 195.251 239.911C209.82 230.55 222.654 236.814 227.306 238.315Z"
                    fill={currentTheme === 'dark' ? '#94A3B8' : '#8CBCE1'}
                />
                <path
                    d="M244.479 139.186C254.275 220.751 230.996 235.554 228.405 237.803C227.655 238.454 227.351 238.406 226.216 238.114L226.197 238.125C221.396 236.852 209.821 230.55 195.251 239.91C192.836 241.471 190.468 242.98 188.176 244.445C173.313 253.871 161.311 260.514 152.664 256.658C145.33 252.917 138.717 249.679 132.771 246.897C88.6426 226.325 79.7723 230.08 78.9863 230.53C79.8983 230.033 88.2638 225.396 99.5278 217.242C99.5662 217.22 99.6045 217.198 99.6127 217.168C114.669 206.256 134.832 189.062 149.325 166.943C159.191 151.902 168.843 113.771 187.467 106.29C225.741 92.0115 242.89 122.595 244.479 139.186Z"
                    fill={currentTheme === 'dark' ? '#A9C1CF' : '#ABD0ED'}
                />
                <path
                    d="M202.158 137.755C203.347 134.618 203.231 131.666 201.9 131.162C200.569 130.658 198.527 132.793 197.339 135.93C196.151 139.067 196.266 142.019 197.597 142.523C198.928 143.027 200.97 140.892 202.158 137.755Z"
                    fill={currentTheme === 'dark' ? 'black' : 'white'}
                />
                <path
                    d="M226.529 141.758C227.371 138.511 226.937 135.589 225.559 135.231C224.182 134.874 222.382 137.217 221.54 140.464C220.698 143.712 221.132 146.634 222.51 146.991C223.888 147.348 225.687 145.005 226.529 141.758Z"
                    fill={currentTheme === 'dark' ? 'black' : 'white'}
                />
                <path
                    d="M211.694 153.681C211.495 153.681 211.301 153.612 211.146 153.487C210.99 153.361 210.883 153.187 210.84 152.992C210.684 152.285 209.99 150.195 208.89 149.885C207.789 149.574 205.631 150.78 204.758 151.413C204.57 151.549 204.335 151.605 204.106 151.568C203.877 151.531 203.672 151.405 203.535 151.217C203.399 151.029 203.343 150.795 203.38 150.566C203.417 150.337 203.543 150.131 203.731 149.995C204.075 149.746 207.158 147.578 209.365 148.2C211.667 148.85 212.467 152.235 212.55 152.619C212.578 152.747 212.577 152.88 212.547 153.007C212.516 153.134 212.458 153.253 212.376 153.355C212.294 153.457 212.19 153.539 212.072 153.595C211.954 153.652 211.825 153.681 211.694 153.681H211.694Z"
                    fill={currentTheme === 'dark' ? 'black' : 'white'}
                />
            </svg>
        </div>
    );
};
