import { Checkbox, Tooltip } from '@mui/material';
import stls from '../../../styles/component/basket/Basket.module.sass';
import { AccountsContext } from '../../../../qwep/context/Accounts_context';
import { useContext, useState, useEffect } from 'react';
import { BasketContext } from '@f_qwep/context/Basket_context';
import { IconBrand } from '../../icons/IconBrand';

export function CellBrand({ info }: any) {
    const { accounts } = useContext(AccountsContext);
    const { checkedBasketItems, setCheckedBasketItems } = useContext(BasketContext);

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (checkedBasketItems[info.row.original.accountId]?.items?.length === info.row.original.basketItems.length) {
            setChecked(true);
            setCheckedBasketItems((prev: any) => ({
                ...prev,
                [info.row.original.accountId]: { ...prev[info.row.original.accountId], check: true },
            }));
        }
    }, [JSON.stringify(checkedBasketItems)]);

    useEffect(() => {
        setChecked(checkedBasketItems[info.row.original.accountId]?.check ? true : false);
    }, [checkedBasketItems[info.row.original.accountId]?.check]);

    const name = info.row.original.vendorTitle;
    const newName = accounts.filter(
        (el: any) => el.vid == info.row.original.vendorId && el.id == info.row.original.accountId,
    );

    const handleCheckbox = () => {
        if (checked) {
            let newCheckBasketItems = { ...checkedBasketItems };
            delete newCheckBasketItems[info.row.original.accountId];
            setCheckedBasketItems(newCheckBasketItems);
            setChecked(false);
        } else {
            setCheckedBasketItems((prev: any) => ({
                ...prev,
                [info.row.original.accountId]: {
                    check: true,
                    basketId: info.row.original.basketId,
                    hasPartOrder: info.row.original.hasPartOrder,
                    basketForm: info.row.original.basketForm,
                    items: info.row.original.basketItems.map((el: any) => +el.basketItemId),
                },
            }));
            setChecked(true);
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={checked} onChange={handleCheckbox} />
            <IconBrand /> <span style={{ margin: '0 8px' }}>Поставщик:</span>
            {newName.length > 0 && newName[0].title !== name ? (
                <Tooltip placement="top" title={newName[0].title}>
                    <p className={stls.cellTitleGeneral}>{newName[0].title}</p>
                </Tooltip>
            ) : (
                <Tooltip placement="top" title={name}>
                    <p className={stls.cellTitleGeneral}>{name}</p>
                </Tooltip>
            )}
        </div>
    );
}
