import { IconSaveFilter } from '@f_qwep/components/icons/IconSaveFilter';
import Tooltip from '@mui/material/Tooltip';
import { FC, RefObject } from 'react';
import { observer } from 'mobx-react-lite';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';

interface IFilterLeftPanelTemplateButtonProps {
    colors: any;
    tooltipTitle: string;
    anchorRef: RefObject<HTMLDivElement>;
    draftFilterSearch: [];
    handleOpen: () => void;
}

export const FilterLeftPanelTemplateButton: FC<IFilterLeftPanelTemplateButtonProps> = observer(
    ({ colors, tooltipTitle, anchorRef, draftFilterSearch, handleOpen }) => {
        return (
            <Tooltip className={styles.cursorPointer} title={tooltipTitle}>
                <div ref={anchorRef} onClick={handleOpen} className={styles.actionIconWrapper}>
                    <IconSaveFilter color={draftFilterSearch?.length ? colors.alpha : colors.sigma} />
                </div>
            </Tooltip>
        );
    },
);
