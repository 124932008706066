import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/general/MessageInfo.module.sass';
import { QwepContext } from '../context/Qwep_context';
import { CustomTooltip } from '@f_general/CustomTooltip';

type TypeMessageInfoProps = {
    type: string;
};

export function MessageInfo({ type }: TypeMessageInfoProps) {
    const { colors } = useContext(ThemeContext);
    const { notifications } = useContext(QwepContext);

    const [messages, setMessages] = useState<string[]>([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        switch (type) {
            case 'purchasing-assistant':
                setCount(notifications?.assistant?.length);
                setMessages(notifications?.assistant);
                break;
            // case 'prices':
            //     setCount(notifications?.price?.length)
            //     setMessages(notifications?.price)
            // break;
            // case 'batch-pricer':
            //     setCount(notifications?.batchPricer?.length)
            //     setMessages(notifications?.batchPricer)
            // break;
        }
    }, [notifications]);

    return (
        <>
            {count ? (
                <CustomTooltip
                    componetTooltip={
                        <div className={stls.list}>
                            <div className={stls.contentList}>
                                {messages.map((item) => {
                                    if (item.includes('info:')) {
                                        return (
                                            <div key={item} style={{ background: '#E5F1FF' }} className={stls.item}>
                                                {item.replace('info: ', '')}
                                            </div>
                                        );
                                    } else if (item.includes('warn:')) {
                                        return (
                                            <div style={{ background: '#FFF6DD' }} className={stls.item}>
                                                {item.replace('warn: ', '')}
                                            </div>
                                        );
                                    } else if (item.includes('error:')) {
                                        return (
                                            <div style={{ background: '#ffdcdc' }} className={stls.item}>
                                                {item.replace('error: ', '')}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    }
                    customStyle={{
                        top: '-10px',
                        background: '#00000000',
                    }}>
                    <div className={stls.countWidget} style={{ color: colors.lambda, background: colors.lambda }}>
                        <span style={{ color: colors.tau }}>{count}</span>
                    </div>
                </CustomTooltip>
            ) : (
                <></>
            )}
        </>
    );
}
