import { TypeCurrentReport } from '@f_ucs/context/collector_context';

export const getSusBuys = (reports: TypeCurrentReport[]) => {

    return reports
        ?.filter((item: any) => item.suspiciousBuys.length)
        .map((item: any) =>
            item.suspiciousBuys.map((obj: any) => {
                return { ...obj, time: item.time };
            }),
        )
        .flat(2) ?? null;

}