import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/user-table/UserTable.module.sass';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralModal from '@f_general/GeneralModal';
import { UserContext } from '@f_context/User_context';
import { CellRole } from './CellRole';
import { CellCard } from './CellCard';
import { Alert } from '@f_general/alert';
import { Role } from '@f_types/TypeRole';
import { IconDelete } from '@f_components/icons/IconDelete';
import { IconEdit } from '@f_components/icons/IconEdit';
import { WorkspaceContext } from '@f_context/Workspace_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export const CellSettings = ({ info, mobile, workspace, currentRole }: any) => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { getInfoWorkspaces } = useContext(WorkspaceContext);
    const { deleteUser, updateUserInCompany, userInfo } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    const [loading, setLoading] = useState(false);

    const clickModalHandler = (type: string) => {
        switch (type) {
            case 'changeUser':
                setDataModal({
                    icon: <IconEdit classNames={[stls.iconEdit]} />,
                    title: `${t('modal.userTableModal.changeUser')} ${info.row.original.firstName}`,
                    props: [
                        (() => {
                            let apps = '';
                            workspace.applications.map((item: any) => {
                                if (item.tariff !== 'UNLIMIT' && item.maxLimit - item.currentLimit <= 0) {
                                    apps += `${item.applicationName}`;
                                }
                            });
                            if (apps) {
                                return {
                                    title: `Внимание!`,
                                    control: 'text',
                                    content: `Нет свободных лицензии на приложения: ${apps} Вы можете докупить свободные лицензии в разделе «Лицензии и оплата»`,
                                };
                            }
                        })(),
                        {
                            control: 'select',
                            name: 'role',
                            title: t('modal.userTableModal.userRole'),
                            defaultValue: info.row.original.role[0].sysName,
                            options: (() => {
                                let opt: any = [];
                                if (currentRole.some((item: any) => item.sysName === Role.Admin)) {
                                    if (userInfo.userId === info.row.original.userId) {
                                        opt = [{ key: t('userRole.admin'), value: Role.Admin }];
                                    } else {
                                        opt = [
                                            { key: t('userRole.user'), value: Role.User },
                                            { key: t('userRole.admin'), value: Role.Admin },
                                        ];
                                    }
                                }
                                if (currentRole.some((item: any) => item.sysName === Role.Manager)) {
                                    if (userInfo.userId === info.row.original.userId) {
                                        opt = [{ key: t('userRole.manager'), value: Role.Manager }];
                                    } else {
                                        opt = [
                                            { key: t('userRole.user'), value: Role.User },
                                            { key: t('userRole.admin'), value: Role.Admin },
                                            { key: t('userRole.manager'), value: Role.Manager },
                                        ];
                                    }
                                }
                                if (currentRole.some((item: any) => item.sysName === Role.SUPER_ADMIN)) {
                                    if (userInfo.userId === info.row.original.userId) {
                                        opt = [{ key: t('userRole.superAdmin'), value: Role.SUPER_ADMIN }];
                                    } else {
                                        opt = [
                                            { key: t('userRole.user'), value: Role.User },
                                            { key: t('userRole.admin'), value: Role.Admin },
                                            { key: t('userRole.manager'), value: Role.Manager },
                                            { key: t('userRole.superAdmin'), value: Role.SUPER_ADMIN },
                                        ];
                                    }
                                }
                                return opt;
                            })(),
                        },
                        ...workspace.applications.map((item: any) => ({
                            control: 'checkbox',
                            title: t('applications.apps'),
                            name: item.applicationSysName,
                            type: 'checkbox',
                            disabled: (() => {
                                let app = info.row.original.panels?.some((p: any) => {
                                    if (item.applicationWorkspaceId === p.applicationWorkspaceId) {
                                        if (item.tariff === 'UNLIMIT') {
                                            return false;
                                        } else {
                                            return (
                                                item.maxLimit - item.currentLimit <= 0 && !p.applicationPanelIsActive
                                            );
                                        }
                                    }
                                });
                                if (
                                    !info.row.original.panels?.some(
                                        (p: any) => p.applicationSysName === item.applicationSysName,
                                    ) &&
                                    item.maxLimit - item.currentLimit <= 0
                                ) {
                                    return true;
                                }
                                return app;
                            })(),
                            options: [
                                {
                                    key: item.applicationName,
                                    value: false,
                                },
                            ],
                        })),
                    ],
                    shema: null,
                    type: 'changeUser',
                    data: {
                        role: info.row.original.role[0].sysName,
                        ...(() => {
                            let apps = {};
                            info.row.original.panels?.map((item: any) => {
                                apps = {
                                    ...apps,
                                    [`${item.applicationSysName}`]: item.applicationPanelIsActive,
                                };
                            });
                            return apps;
                        })(),
                    },
                } as TypeDataModal);
                setOpen(true);
                break;

            case 'deleteUser':
                setDataModal({
                    icon: <IconDelete classNames={[stls.iconDel]} />,
                    title: `${t('modal.userTableModal.deleteUser')} ${info.row.original.firstName}`,
                    props: [
                        {
                            control: 'select',
                            name: 'completeRemoval',
                            title: t('modal.userTableModal.deleteUser'),
                            defaultValue: false,
                            options: [
                                { key: t('modal.userTableModal.onlyThisCompany'), value: false },
                                { key: t('modal.userTableModal.fullyDelUser'), value: true },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'deleteUser',
                    data: {
                        completeRemoval: false,
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
        }
    };

    const sendDataModalHandler = async (values: any) => {
        switch (dataModal.type) {
            case 'changeUser':
                setLoading(true);
                let apps: any = [];
                Object.keys(values).map((key: any) => {
                    workspace.applications.map((app: any) => {
                        if (app.applicationSysName === key) {
                            apps.push({
                                applicationId: app.applicationId,
                                isActive: values[key],
                                applicationWorkspaceId: app.applicationWorkspaceId,
                            });
                        }
                    });
                });

                let appFilters: any = [];
                apps.map((item: any) => {
                    let app = info.row.original.panels?.find((a: any) => a.applicationId === item.applicationId);
                    if (app) {
                        if (app.applicationPanelIsActive !== item.isActive) {
                            appFilters.push(item);
                        }
                    } else {
                        appFilters.push(item);
                    }
                });

                if (apps.some((app: any) => app.isActive)) {
                    const resultUpdateUserInCompany = await updateUserInCompany({
                        userId: info.row.original.userId,
                        workspaceId: workspace.workspaceId,
                        role: {
                            sysName: values.role,
                            roleValueId: info.row.original.role[0].roleValueId,
                        },
                        applications: appFilters,
                    });
                    if (resultUpdateUserInCompany.isOk) {
                        await getInfoWorkspaces();
                        handleModalClose();
                        Alert({
                            type: 'success',
                            text: t('general.success'),
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                } else {
                    Alert({
                        type: 'warning',
                        text: 'У пользователя должно быть приложение',
                        time: 6000,
                    });
                }

                setLoading(false);
                break;
            case 'deleteUser':
                setLoading(true);
                const resultDeleteUser = await deleteUser({
                    userId: info.row.original.userId,
                    workspaceId: workspace.workspaceId,
                    onlyWorkspace: values.completeRemoval,
                });
                if (resultDeleteUser.isOk) {
                    handleModalClose();
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                setLoading(false);
                break;
        }
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataModal({});
    };

    return (
        <>
            {mobile ? (
                <CellCard info={info} clickModalHandler={clickModalHandler} currentRole={currentRole} />
            ) : (
                <CellRole info={info} clickModalHandler={clickModalHandler} currentRole={currentRole} />
            )}
            <GeneralModal
                openModal={open}
                closeModal={handleModalClose}
                dataModal={dataModal}
                sendDataModalHandler={sendDataModalHandler}
                loading={loading}
            />
        </>
    );
};
