import { Button, Card, dividerClasses, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_styles/components/my-company/MyCompany.module.sass';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useState } from 'react';
import { IconUseCompany } from '@f_components/icons/IconUseCompany';
import { WorkspaceContext } from '@f_context/Workspace_context';
import { UserContext } from '@f_context/User_context';
import { UserRoleChip } from '@f_general/UserRoleChip';
import { ChildCompanyList } from './ChildCompanyList';
import { CompanyList } from './CompanyList';
import GeneralModal from '@f_general/GeneralModal';
import { addCompanyModalCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { GeneralContext } from '@f_context/General_context';
import { Alert } from '@f_general/alert';
import { Role } from '@f_types/TypeRole';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import GeneraCreateUserModal from '@f_general/GeneraCreateUserModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function Company({ setDataUserList, getUsersHandler, currentWorkspace }: any) {
    const { t } = useTranslation();
    const { colors, currentTheme, StyledBadge, StyledAvatarGroup } = useContext(ThemeContext);
    const StyledAvatarGroupCastom = styled(StyledAvatarGroup)(({ theme }: any) => ({
        '& .MuiAvatar-root': {
            backgroundColor: '#EFEFFD',
            color: colors.nu,
        },
    }));

    const { childWorkspaces, workspaces, addWorkspace, getInfoWorkspaces } = useContext(WorkspaceContext);
    const { userRole, addUser, addExistingUser } = useContext(UserContext);
    const { drawerPanels } = useContext(GeneralContext);
    const [openCompanies, setOpenCompanies] = useState(false);

    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    const [openAddUser, setOpenAddUser] = useState(false);

    const handleToggle = () => {
        setOpenCompanies((prevOpen) => !prevOpen);
    };

    const clickModalHandler = (type: string, workspaceId?: number) => {
        switch (type) {
            case 'addCompany':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: t('modal.addCompany.title'),
                    props: [
                        {
                            title: t('modal.addCompany.companyName'),
                            control: 'input',
                            name: 'organization',
                            type: 'text',
                            placeholder: t('modal.addCompany.placeholderCompanyName'),
                        },
                        {
                            title: t('modal.addCompany.telegramNickname'),
                            control: 'input',
                            name: 'telegramNickname',
                            type: 'text',
                            placeholder: t('modal.addCompany.placeholderTelegram'),
                        },
                        ...(() => {
                            const apps: any = [];
                            drawerPanels.map((item: any) => {
                                if (item.applicationWorkspaceIsActive) {
                                    let key = '';
                                    switch (item.applicationSysName) {
                                        case 'qwep':
                                            key = t('applications.qwep');
                                            break;
                                        case 'admin_panel':
                                            key = t('applications.adminPanel');
                                            break;
                                    }

                                    apps.push({
                                        control: 'checkbox',
                                        name: item.applicationSysName,
                                        type: 'checkbox',
                                        options: [
                                            {
                                                key: key,
                                                value: true,
                                            },
                                        ],
                                    });
                                }
                            });
                            return apps;
                        })(),
                    ],
                    shema: addCompanyModalCustomerSchema,
                    type: 'addCompany',
                    data: {
                        organization: '',
                        telegramNickname: '',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
        }
    };

    const sendDataModalHandler = async (values: any) => {
        switch (dataModal.type) {
            case 'addCompany':
                const appsId: { sysName: string; applicationId: number }[] = [];
                drawerPanels.filter((item: any) => {
                    switch (item.applicationSysName) {
                        case 'admin_panel':
                            values.admin_panel &&
                                appsId.push({
                                    sysName: item.applicationSysName,
                                    applicationId: item.applicationId,
                                });
                            break;
                        case 'qwep':
                            values.qwep &&
                                appsId.push({
                                    sysName: item.applicationSysName,
                                    applicationId: item.applicationId,
                                });
                            break;
                    }
                });
                const resultAddCompany = await addWorkspace({
                    organization: values.organization,
                    telegramNickname: values.telegramNickname,
                    applications: appsId.length ? appsId : [{ sysName: 'qwep', applicationId: 1 }],
                });

                if (resultAddCompany.isOk) {
                    handleModalClose();
                    getInfoWorkspaces();
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataModal({});
    };

    return (
        <div className={stls.container}>
            <div className={stls.generalCart}>
                <Card
                    sx={{
                        background:
                            currentTheme == 'dark'
                                ? 'linear-gradient(230.04deg, #5E5CE6 2.11%, rgba(46, 46, 72, 0) 102.63%);'
                                : colors.phiBg,
                    }}>
                    <div className={stls.useCompanyCardContent}>
                        <button className={stls.cardContentDiv} onClick={() => getUsersHandler()}>
                            <div className={stls.titleBox}>
                                <IconUseCompany />
                                <Typography className={stls.title}>{currentWorkspace.workspaceName}</Typography>
                            </div>
                            <UserRoleChip color="#8CDCE1" classNames={[stls.roleBox]} sysName={userRole} />
                        </button>
                        <div className={stls.users}>
                            <p className={stls.countUsers}>
                                {currentWorkspace.users.length} {t('myCompany.users')}
                            </p>
                            <div className={stls.apps}>
                                {currentWorkspace.applications.map((item: any) => {
                                    return (
                                        <Tooltip
                                            title={
                                                <div>
                                                    <p className={stls.tariff}>
                                                        {t('myCompany.tariff')}: {item.tariff}
                                                    </p>
                                                    <p>
                                                        {t('myCompany.currentUsers')}: {item.currentLimit}
                                                    </p>
                                                    <p>
                                                        {t('myCompany.maxUsers')}: {item.maxLimit}
                                                    </p>
                                                </div>
                                            }>
                                            <div
                                                key={item.applicationWorkspaceId}
                                                className={stls.itemApp}
                                                style={{ color: colors.tau }}>
                                                {item.applicationName}
                                            </div>
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={stls.divider} style={{ background: colors.delta }} />
                    </div>
                    <div className={stls.companyActions}>
                        <div className={stls.buttons}>
                            {childWorkspaces.length > 0 && (
                                <Button onClick={handleToggle} className={stls.button} size="small">
                                    {t('myCompany.companies')}
                                    {/* {openCompanies ? <ArrowUpwardIcon fontSize='small' /> : <ArrowDownwardIcon fontSize='small' /> } */}
                                    <p className={stls.childWorkspaces}>{childWorkspaces.length}</p>
                                </Button>
                            )}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <StyledAvatarGroupCastom
                                style={{ marginRight: '5px' }}
                                total={currentWorkspace.users.length}
                                id="basic-button">
                                {currentWorkspace.users.map((item: any) => {
                                    if (item.online) {
                                        return (
                                            <Tooltip key={item.userId} title={`${item.firstName} ${item.lastName}`}>
                                                <StyledBadge
                                                    sx={{ marginRight: '3px' }}
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    variant="dot">
                                                    <Avatar
                                                        className={stls.smallCardCardContentavatar}
                                                        alt={item.firstName}
                                                        style={{ width: '23px', height: '23px' }}
                                                        src={item.photo ? item.photo : '/static/images/avatar/1.jpg'}
                                                    />
                                                </StyledBadge>
                                            </Tooltip>
                                        );
                                    } else {
                                        return (
                                            <Tooltip key={item.userId} title={`${item.firstName} ${item.lastName}`}>
                                                <Avatar
                                                    className={stls.avatar}
                                                    alt={item.firstName}
                                                    style={{ width: '23px', height: '23px' }}
                                                    src={item.photo ? item.photo : '/static/images/avatar/1.jpg'}
                                                />
                                            </Tooltip>
                                        );
                                    }
                                })}
                            </StyledAvatarGroupCastom>
                            {userRole.some(
                                (item: any) =>
                                    item === Role.Admin || item === Role.Manager || item === Role.SUPER_ADMIN,
                            ) && (
                                <>
                                    <div
                                        id="addNewUser"
                                        className={stls.buttonAdd}
                                        style={{ border: `1px solid ${colors.alpha}` }}
                                        onClick={() => setOpenAddUser(true)}>
                                        <AddCircleIcon style={{ width: '14px', height: '14px', color: colors.alpha }} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Card>

                {/* {userRole.some(
          (item) =>
            item === Role.Admin ||
            item === Role.Manager ||
            item === Role.SUPER_ADMIN,
        ) && (
          <Card
            onClick={() => clickModalHandler('addCompany')}
            className={stls.addCompanyCard}
            sx={{
              border: `1px solid transparent`,
              ':hover': {
                border: `1px solid ${colors.alpha}`,
              },
            }}
          >
            <AddIcon
              fontSize="large"
              className={stls.icon}
              sx={{ color: colors.alpha }}
            />
            <div
              className={stls.divider}
              style={{ background: colors.alpha }}
            />
            <p className={stls.textAddComapny} style={{ color: colors.alpha }}>
              {t('myCompany.addCompany')}
            </p>
          </Card>
        )} */}
            </div>

            <GeneraCreateUserModal
                open={openAddUser}
                closeModal={() => setOpenAddUser(false)}
                type={'user'}
                applications={currentWorkspace.applications}
                workspaceId={currentWorkspace.workspaceId}
                addExistingUser={addExistingUser}
                addNewUser={addUser}
                role={currentWorkspace.mainUserRole}
                workspaceName={currentWorkspace.workspaceName}
            />

            <ChildCompanyList open={openCompanies} getUsersHandler={getUsersHandler} />
            <CompanyList getUsersHandler={getUsersHandler} />
            <GeneralModal
                openModal={open}
                closeModal={handleModalClose}
                dataModal={dataModal}
                sendDataModalHandler={sendDataModalHandler}
            />
        </div>
    );
}
