import { useContext, useEffect, useMemo, useState } from 'react';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { ColumnDef } from '@tanstack/react-table';
import { useWindowSize } from '@f_hooks';
import { SearchContext } from '@f_qwep/context/Search_context';
import { FilterReset } from '../../searchResult/FilterReset';
import stls from '@f_qwep/styles/component/tables/miniSearchResultTable/ColumnStyle.module.sass';
import _ from 'lodash';
import cn from 'classnames';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { CellVendor } from './columnVendor';
import { CellTitle } from './columnTitle';
import { CellPrice } from './columnPrice';
import { CellAvailability } from './columnAvailability';
import { CellQuantity } from './columnQuantity';
import { CellMobile } from '../searchResultTable/mobileColumns';
import { CellArticle } from './columnArticle';
import { useMaskCrossTransformItems } from '@f_qwep/components/filters/hooks/useMaskCrossTransformItems';
import { useFilteredResults } from '@f_qwep/components/filters/hooks/useFilteredResults';

export function MiniSearchResultTable({ items, filter, filtersReducer, setItemsTable }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    const { tabId, arrSearchResults } = useContext(SearchContext);
    const { priceMarginIsActive, priceMarginState, setOpenExportModal, setExportData, setTypeExport } =
        useContext(QwepContext);

    const searchItem = arrSearchResults.filter((el: any) => el.tabId == tabId)[0];

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    items = useMaskCrossTransformItems(items);

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          header: `${t('qwep.settings.suppliers.supplier')}`,
                          cell: (info) => <CellMobile info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                  ]
                : [
                      {
                          id: 'Поставщик',
                          accessorKey: 'vendorTitle',
                          header: `${t('qwep.search.vendor')}`,
                          cell: (info) => <CellVendor info={info} />,
                          footer: (props) => props.column.id,
                          size: 70,
                      },
                      {
                          id: 'Производитель',
                          accessorKey: 'brand',
                          header: `${t('qwep.search.brand')}`,
                          cell: (info) => (
                              <div style={{ color: colors.alpha }} className={cn(stls.cellBrand)}>
                                  {info.row.original.brand}
                              </div>
                          ),
                          footer: (props) => props.column.id,
                          size: 60,
                      },
                      {
                          id: 'Артикул',
                          accessorKey: 'article',
                          header: `${t('qwep.search.article')}`,
                          cell: (info) => <CellArticle info={info} setItemsTable={setItemsTable} />,
                          footer: (props) => props.column.id,
                          minSize: 70,
                      },
                      {
                          id: 'НазваниеТаблРезульт',
                          accessorKey: 'title',
                          header: `${t('qwep.search.partname')}`,
                          cell: (info) => <CellTitle info={info} />,
                          footer: (props) => props.column.id,
                          minSize: 190,
                      },
                      {
                          id: 'Цена',
                          accessorKey: 'price',
                          header: `${t('qwep.search.price')}`,
                          cell: (info) => <CellPrice info={info} />,
                          footer: (props) => props.column.id,
                          minSize: 30,
                          size: 50,
                          sortingFn: (a: any, b: any) =>
                              priceMarginIsActive
                                  ? +b.original.priceWithMargin - +a.original.priceWithMargin
                                  : b.original.price.value - a.original.price.value,
                      },
                      {
                          id: 'Срок',
                          accessorKey: 'time',
                          header: `${t('qwep.search.deliveryTime')}`,
                          cell: (info) => {
                              return (
                                  <>
                                      {info.row.original.delivery && (
                                          <p
                                              style={{ display: 'flex', justifyContent: 'center' }}
                                              className={stls.cellDelivery}>
                                              {info.row.original.delivery}
                                          </p>
                                      )}
                                  </>
                              );
                          },
                          footer: (props) => props.column.id,
                          sortingFn: (a: any, b: any) => {
                              const dateA = new Date(b.original.deliveryStart);
                              const dateB = new Date(a.original.deliveryStart);
                              return +dateA - +dateB;
                          },

                          size: 50,
                      },
                      {
                          id: 'Наличие',
                          accessorKey: 'quantity.formatted',
                          header: `${t('qwep.search.availability')}`,
                          cell: (info) => <CellAvailability info={info} />,
                          footer: (props) => props.column.id,
                          size: 50,
                      },
                      {
                          id: 'Склад',
                          accessorKey: 'warehouse',
                          header: `${t('qwep.search.warehouse')}`,
                          cell: (info) => <p className={stls.cellWarehouse}>{info.row.original.warehouse}</p>,
                          footer: (props) => props.column.id,
                          size: 10,
                      },
                      {
                          id: 'Склад Поставщика',
                          accessorKey: 'origWarehouse',
                          header: `${t('qwep.search.vendorsWarehouse')}`,
                          // cell: (info) => <CellWarehouseVendor info={info}/>,
                          footer: (props) => props.column.id,
                          size: 10,
                          minSize: 10,
                      },
                      {
                          id: 'Корзина',
                          accessorKey: `${t('qwep.search.basket')}`,
                          header: '',
                          cell: (info) => <CellQuantity info={info} />,
                          footer: (props) => props.column.id,
                          size: 75,
                          minSize: 75,
                      },
                  ],
        [items, mobile],
    );

    const data = useFilteredResults(items, filter, priceMarginIsActive, priceMarginState, searchItem);

    const sortResult = (data: any) => {
        const priceSorter = (data: any) => +data.price.value;
        const priceWithMarginSorter = (data: any) => +data.priceWithMargin;

        return _.orderBy(data, priceMarginIsActive ? priceWithMarginSorter : priceSorter);
    };

    const tableStyles = {
        head: {
            base: false,
            miniFilterInput: true,
            background: colors.beta,
            container: {
                height: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: mobile ? 'center' : null,
            },
            title: {
                fontSize: '11px',
            },
        },
        body: {
            tableBody: {
                borderLeft: '1px solid #c2c2c2',
                borderRight: '1px solid #c2c2c2',
            },
            row: {
                background: colors.psi,
                height: mobile ? '192px' : '20px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: false,
        },
    };

    const exportExcelHandler = () => {
        setTypeExport('searchResult');
        setExportData({ filter: sortResult(data), all: items });
        setOpenExportModal(true);
    };

    return (
        <VirtualTable
            searchId={tabId}
            data={sortResult(data)}
            columnsTable={columns}
            topFilterPanel={width > 700}
            showColumnSearchAction={width > 700}
            heightTable={height - 100}
            configHeader={{
                chipFilters: <FilterReset filter={filter} filtersReducer={filtersReducer} />,
                fullScrean: true,
                exportExcel: true,
                resize: true,
                grouping: false,
                columnFilter: true,
                sorting: width > 700,
                columnSearch: true,
                dragging: true,
            }}
            styles={tableStyles}
            overscan={mobile ? 6 : 5}
            estimateSize={mobile ? 193 : 20}
            exportExcelHandler={exportExcelHandler}
        />
    );
}
