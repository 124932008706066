export enum CustomSortEnum {
    ABC = 'ABC',
    DESC = 'DESC',
}

interface ICustomSortArgs<T> {
    list: Array<T>;
    sortType: CustomSortEnum;
}

export const customSort = <T extends string | number>({ list, sortType }: ICustomSortArgs<T>): Array<T> =>
    list.sort((a, b) => {
        if (typeof a === 'number' && typeof b === 'number') {
            return sortType === CustomSortEnum.ABC ? a - b : b - a;
        } else if (typeof a === 'string' && typeof b === 'string') {
            return sortType === CustomSortEnum.ABC ? a.localeCompare(b) : b.localeCompare(a);
        } else {
            throw new Error('Invalid types for sorting');
        }
    });
