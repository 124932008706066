import { BasketContext } from '@f_qwep/context/Basket_context';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconClose } from '../../../../../components/icons/IconClose';
import { Alert } from '@f_general/alert';
import { CircularProgress } from '@mui/material';

export function CellDelete({ info, accountId, basketId }: any) {
    const { t } = useTranslation();
    const { deleteCartItem } = useContext(BasketContext);
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        const result = await deleteCartItem({
            accountId: accountId,
            basketId: basketId,
            basketItemId: info.row.original.basketItemId,
        });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoading(false);
    };

    return (
        <button onClick={handleDelete} disabled={loading}>
            {loading ? <CircularProgress size={17} /> : <IconClose />}
        </button>
    );
}
