import { UpdateItemOrderVendorProps } from '@f_qwep/context/Orders_context';
import GeneralModal from '@f_general/GeneralModal';
import { isEmpty } from 'lodash';
import { OrderItemStatusEnum } from '@f_qwep/types/OrderItemStatus.enum';
import { normalizedOrderStatusesObject } from '@f_qwep/components/orders/utils/orderStatuses.util';

interface ModalChangeStatusItemProps {
    open: boolean;
    data: any;
    closeHandler: any;
    updateItemStatusOrderVendor: any;
}

export function ModalChangeStatusItem({
    open,
    data,
    closeHandler,
    updateItemStatusOrderVendor,
}: ModalChangeStatusItemProps) {
    const submitHandler = async (values: UpdateItemOrderVendorProps) => {
        console.log('Values', values);

        const res = await updateItemStatusOrderVendor(values);
        if (res.isOk) {
            closeHandler();
        }
    };

    return (
        <GeneralModal
            openModal={open}
            closeModal={closeHandler}
            sendDataModalHandler={submitHandler}
            dataModal={
                !isEmpty(data)
                    ? {
                          title: `Изменить статус`,
                          props: [
                              {
                                  control: 'select',
                                  name: 'status',
                                  title: '',
                                  defaultValue: false,
                                  options: [
                                      { key: normalizedOrderStatusesObject.NEW, value: OrderItemStatusEnum.NEW },
                                      {
                                          key: normalizedOrderStatusesObject.PREPAYMENT_REQUIRED,
                                          value: OrderItemStatusEnum.PREPAYMENT_REQUIRED,
                                      },
                                      {
                                          key: normalizedOrderStatusesObject.RESERVED,
                                          value: OrderItemStatusEnum.RESERVED,
                                      },
                                      {
                                          key: normalizedOrderStatusesObject.DELIVERY,
                                          value: OrderItemStatusEnum.DELIVERY,
                                      },
                                      {
                                          key: normalizedOrderStatusesObject.COMPLETED,
                                          value: OrderItemStatusEnum.COMPLETED,
                                      },
                                      {
                                          key: normalizedOrderStatusesObject.CANCELED,
                                          value: OrderItemStatusEnum.CANCELED,
                                      },
                                  ],
                              },
                          ],
                          shema: null,
                          type: 'changeItem',
                          data: {
                              orderId: data.orderId,
                              orderItemId: data.orderItemId,
                              status: data.status,
                          },
                      }
                    : {}
            }
        />
    );
}
