import React from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { IconAddPrice } from '@f_qwep/components/icons/IconAddPrice';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const CustomListModalTitle = () => {
    const store = useAssistantStore();
    const { currentTypeModal: type } = store.assistantModalList;

    return (
        <div className={styles.modalTitle}>
            <IconAddPrice />
            <p className={styles.title}>
                {type === ConfigPanelModalType.CREATE
                    ? 'Создать пользовательский список'
                    : type === ConfigPanelModalType.UPDATE
                    ? 'Редактировать пользовательский список'
                    : type === ConfigPanelModalType.ADD && 'Добавить позицию в список'}
            </p>
        </div>
    );
};
