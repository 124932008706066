import { createTheme } from '@mui/material/styles';

export const themeLightMui = createTheme({
    palette: {
        mode: 'light',
    },
});

export const themeDarkMui = createTheme({
    palette: {
        mode: 'dark',
    },
});
