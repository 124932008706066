import React, { useContext, useEffect, useState } from 'react';
import { ArcElement, Chart, Legend, Tooltip as ChartTooltip } from 'chart.js';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';
import { ThemeContext } from '@f_context/Theme_context';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';
import { CardContent, Typography, Button } from '@mui/material';
import { useCollector } from '@f_ucs/context/collector_context';
import { useReport } from '@f_ucs/context/report_context';
import { ReportTable } from '../ReportTable/ReportTable';
import stls
    from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/doughnut/doughnut.module.sass';
import { useDoughnut } from '@f_ucs/context/doughnut_context';
import { TokenEditor } from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/Doughnut/TokenEditor';
import { formatPercentageLessBetter } from '@f_ucs/utils/formatPercentage';
import { getColorLessBetter } from '@f_ucs/utils/getColor';
import { useDatePicker } from '@f_ucs/context/date_picker_context';
import { IconInfoTable } from '@f_qwep/components/icons/IconInfoTable';
import Tooltip from '@mui/material/Tooltip';

Chart.register(ArcElement, ChartTooltip, Legend, ChartDataLabels);


export const PurchaseReportDoughnut: React.FC = () => {
    const {
        data1,
        data2,
        options,
    } = useDoughnut();

    const {
        percentDifferenceViolations,
        percentDifferenceLostProfit,
        timeCutReportViolations,
        timeCutReportLostProfit,
        timeCutReports,
    } = useReport();

    const {
        currentTime,
    } = useDatePicker();

    const {
        currentType,
    } = useCollector();

    const { colors, currentTheme } = useContext(ThemeContext);
    const [isOpenEditor, setIsOpenEditor] = useState<boolean>(false);
    const [isOpenTable, setIsOpenTable] = useState<boolean>(false);

    useEffect(() => {
        setIsOpenTable(false);
    }, [timeCutReports]);

    const toggleTableHandler = () => {
        setIsOpenTable(!isOpenTable);
    };

    const editorHandleOpen = () => {
        setIsOpenEditor(true);
    };

    const editorHandleClose = () => {
        setIsOpenEditor(false);
    };

    return (
        <div className={stls.container}>
            <div className={stls.cardsContainer}>
                <div className={stls.cardsTitle}>
                    Статистика нарушений
                </div>
                <div className={stls.cards}>
                    <CardContent
                        className={stls.card}
                        style={{ background: currentTheme === 'dark' ? colors.iota : colors.white }}>
                        <div className={stls.content}>
                            <h3>Количество нарушений, шт.</h3>
                            <Doughnut data={data1} options={options}
                                      className={stls.doughnut} />
                            {currentType === '1c' &&
                                <Button className={stls.btn} onClick={editorHandleOpen}>
                                    <IconEditSmall />
                                </Button>}
                            <h3 style={{ marginTop: 'auto' }}>Количество заказов с
                                нарушениями: {timeCutReportViolations}</h3>
                            {currentTime !== '4' &&
                                <div style={{
                                    display: 'flex',
                                    gap: '8px',
                                    justifyContent: 'center',
                                }}>
                                    <Tooltip
                                        title={`Разница нарушений с предыдущим периодом`}
                                        arrow
                                    >
                                        <div style={{ height: '20px' }}><IconInfoTable /></div>
                                    </Tooltip>
                                    <Typography style={{ color: getColorLessBetter(percentDifferenceViolations) }}
                                                sx={{ textAlign: 'center', maxWidth: '80%' }}>
                                        {formatPercentageLessBetter(percentDifferenceViolations)}
                                    </Typography>
                                </div>}
                            <Button
                                className={stls.moreBtn}
                                style={{ border: `2px solid ${colors.alpha}` }}
                                onClick={toggleTableHandler}>
                                Подробнее
                            </Button>
                        </div>
                    </CardContent>
                    <CardContent
                        className={stls.card}
                        style={{ background: currentTheme === 'dark' ? colors.iota : colors.white }}>
                        <div className={stls.content}>
                            <h3>Объем упущенной выгоды, ₽</h3>
                            <Doughnut data={data2} options={options}
                                      className={stls.doughnut} />
                            {currentType === '1c' &&
                                <Button className={stls.btn} onClick={editorHandleOpen}>
                                    <IconEditSmall />
                                </Button>}
                            <h3 style={{ marginTop: 'auto' }}>Упущенная
                                выгода: {Math.ceil(timeCutReportLostProfit)}</h3>
                            {currentTime !== '4' &&
                                <div style={{
                                    display: 'flex',
                                    gap: '8px',
                                    justifyContent: 'center',
                                }}>
                                    <Tooltip
                                        title={`Разница упущенной выгоды с предыдущим периодом`}
                                        arrow
                                    >
                                        <div style={{ height: '20px' }}><IconInfoTable /></div>
                                    </Tooltip>
                                    <Typography style={{ color: getColorLessBetter(percentDifferenceLostProfit) }}
                                                sx={{ textAlign: 'center', maxWidth: '80%'  }}>
                                        {formatPercentageLessBetter(percentDifferenceLostProfit)}
                                    </Typography>
                                </div>}
                            <Button
                                className={stls.moreBtn}
                                style={{ border: `2px solid ${colors.alpha}` }}
                                onClick={toggleTableHandler}>
                                Подробнее
                            </Button>
                        </div>
                    </CardContent>
                </div>
            </div>

            {isOpenTable && (
                <ReportTable />
            )}
            {isOpenEditor &&
                <TokenEditor isOpenEditor={isOpenEditor} editorHandleClose={editorHandleClose} />}
        </div>
    );
};