import React, { useState, useContext } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';

interface OrderChatPopupProps {
    children: React.ReactNode
}

export const OrderChatPopup = ({ children }: OrderChatPopupProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'chat-order-item-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <CommentIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                    {children}
            </Popover>
        </>
    );
};