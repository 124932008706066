import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { useAccess } from '@f_qwep/hooks/useAccess';

interface IProtectedRouteProps {
    children: ReactElement<any, any>;
    product: QwepProductEnum;
    redirectPath: string;
}

export const ProtectedRoute: FC<IProtectedRouteProps> = ({
    children,
    product,
    redirectPath,
}): ReactElement<any, any> | null => {
    const { isAccess: access } = useAccess(product);
    const navigate = useNavigate();

    if (!access) {
        navigate(redirectPath, { replace: true });
        return null;
    }

    return children;
};
