import { ViolationStatisticModule } from '@f_ucs/components/report/ViolationStatisticsModule/ViolationStatisticsModule';
import stls from '../../styles/components/report/allReport.module.sass';
import { useContext } from 'react';
import { useCollector } from '@f_ucs/context/collector_context';
import { ThemeContext } from '@f_context/Theme_context';
import { CircularProgress } from '@mui/material';
import { useReport } from '@f_ucs/context/report_context';
import { EmployeeStatisticsModule } from '@f_ucs/components/report/EmployeeStatisticsModule/EmployeeStatisticsModule';
import { getSusBuys } from '@f_ucs/utils/getSusBuys';
import { mergeActivityReports } from '@f_ucs/utils/mergeActivityReports';
import { getReportActivity } from '@f_ucs/utils/getReportActivity';


const NoDataMessage = () => {
    const { colors, currentTheme } = useContext(ThemeContext);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ background: currentTheme === 'dark' ? colors.iota : colors.white }} className={stls.noData}>
                <div>Нет заказов с нарушениями!</div>
                <div>Выберите другой отчет или измените параметры</div>
            </div>
        </div>
    );
};


export function Report() {
    const { loading, currentType } = useCollector();
    const { timeCutReports } = useReport();
    return (
        <>
            {loading ? (<div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>)
                : (
                    !getSusBuys(timeCutReports).length && !mergeActivityReports(getReportActivity(timeCutReports), currentType).length ?
                        (
                            <NoDataMessage />
                        ) :
                        (<div className={stls.allReport}>
                            {!mergeActivityReports(getReportActivity(timeCutReports), currentType).length ?
                                (
                                    <NoDataMessage />
                                ) :
                                (<div>
                                    <EmployeeStatisticsModule />
                                </div>)}
                            {!getSusBuys(timeCutReports).length ? (
                                    <NoDataMessage />
                                ) :
                                (<div className={stls.allReport}>
                                    <ViolationStatisticModule />
                                </div>)}
                        </div>)
                )
            }
        </>
    );

}
