import { createContext, ReactNode, useContext } from 'react';
import { RootAssistantStore } from '@f_qwep/components/purchasingAssistant/model/stores/index.store';
import { observer } from 'mobx-react-lite';

const AssistantStoreContext = createContext<RootAssistantStore | null>(null);

const useAssistantStore = () => {
    const context = useContext(AssistantStoreContext);
    if (context === null) {
        throw new Error('You have forgotten to wrap your root component with RootStoreProvider');
    }
    return context;
};

const AssistantStoreProvider = observer(({ children }: { children: ReactNode }) => {
    // инициализация стора
    const store = new RootAssistantStore();

    return <AssistantStoreContext.Provider value={store}>{children}</AssistantStoreContext.Provider>;
});

export { AssistantStoreProvider, AssistantStoreContext, useAssistantStore };
