import { Button, Checkbox, List, ListItem } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeContext } from '@f_context/Theme_context';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import stls from '@f_qwep/styles/component/group/Group.module.sass';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Property } from '@f_qwep/types';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';

export function Group() {
    const { colors } = useContext(ThemeContext);
    const { groupsAccountsState, updateProperty, workspaceApplication, groupsAccountsIsActive } =
        useContext(QwepContext);
    const [mobile, setMobile] = useState(false);

    const { t } = useTranslation();

    const navigate = useQwepNavigate();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };

    const handleCheckbox = async (id: any, flag: any) => {
        const newValue = groupsAccountsState.map((item: any) => {
            if (item.id === id && !flag) {
                return {
                    ...item,
                    flag: true,
                };
            }
            return {
                ...item,
                flag: false,
            };
        });
        await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.GroupsAccounts,
            value: { value: newValue },
        });
    };

    const handleAllGroupChange = async () => {
        const newValue = groupsAccountsState.map((item: any) => {
            return {
                ...item,
                flag: false,
            };
        });
        await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.GroupsAccounts,
            value: { value: newValue },
        });
    };

    return (
        <>
            <div className={stls.groupBtn}>
                <ButtonGroup variant="outlined" ref={anchorRef}>
                    <Button
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleToggle}>
                        <p style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {groupsAccountsIsActive
                                ? groupsAccountsState.filter((el: any) => el.flag)[0]?.name
                                : t('groups.myGroups')}
                        </p>
                        <ExpandMoreIcon sx={{ color: colors.alpha }} />
                    </Button>
                </ButtonGroup>
            </div>
            <Popper
                style={{ zIndex: 1000 }}
                onClick={() => setOpen(false)}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List style={{ paddingTop: '24px' }} className={stls.listGroup} sx={{ background: colors.psi }}>
                            <div className={stls.listContent}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <ListItem className={stls.listItem} disablePadding>
                                        <Checkbox onChange={handleAllGroupChange} checked={!groupsAccountsIsActive} />
                                        <p>{t('groups.allGroups')}</p>
                                    </ListItem>
                                </ClickAwayListener>
                                {groupsAccountsState?.map((item: any, idx: any) => (
                                    <ListItem key={item.id} className={stls.listItem} disablePadding>
                                        <Checkbox
                                            onChange={() => {
                                                handleCheckbox(item.id, item.flag);
                                            }}
                                            checked={item.flag}
                                        />
                                        <p>{item.name}</p>
                                    </ListItem>
                                ))}
                            </div>
                            <ListItem className={stls.listItem} disablePadding>
                                <Button
                                    onClick={() => navigate('settings/accounts')}
                                    variant="contained"
                                    className={stls.listItemButton}>
                                    {t('groups.createGroup')}
                                </Button>
                            </ListItem>
                        </List>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
