import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { QwepContext } from './Qwep_context';
import { ErrorsContext } from './Errors_context';
import { Property } from '../types';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';

type TypeParametrs = {
    accountId: string | number;
    formId: string | number;
    fieldName: string;
    value: string;
};

type TypeUpdateAccount = {
    id: string;
    enabled?: boolean;
    title?: string;
};

export interface Account {
    id: number;
    vid: string;
    bid: string | null;
    login: string;
    password: string | null;
    parameters: any[];
    promo: boolean;
    enabled: boolean;
    title: string;
    hasAuthParameters: boolean;
}

const AccountsContext = createContext<any>({});

function AccountsProvider(props: any) {
    const {
        excludePromo,
        groupsAccountsIsActive,
        groupsAccountsState,
        receivedResponse,
        updateProperty,
        workspaceApplication,
    } = useContext(QwepContext);
    const { setAllErrors } = useContext(ErrorsContext);
    const { t } = useTranslation();
    const [vendors, setVendors] = useState([]);
    const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [fullAccounts, setFullAccounts] = useState([]);
    const [addCurrentAcc, setAddCurrentAcc] = useState([]);
    const [promoAccounts, setPromoAccounts] = useState([]);

    useEffect(() => {
        getVendors();
    }, []);

    useEffect(() => {
        if (receivedResponse) {
            getAccounts(true);
        }
    }, [excludePromo, receivedResponse, JSON.stringify(groupsAccountsState), groupsAccountsIsActive]);

    useEffect(() => {
        const filteredAccounts = fullAccounts.filter((account: Account) => account.promo);
        setPromoAccounts(filteredAccounts);
    }, [fullAccounts]);

    const getVendors = async () => {
        setIsLoadingSuppliers(true);
        const result = await callPlatformAPI('qwep/get/vendors', 'get');
        if (result.data.isOk) {
            console.log('getVendors', result.data);

            setVendors(result.data.result.entity ?? []);
        }
        setIsLoadingSuppliers(false);
    };

    const getAccounts = async (loading: boolean = false) => {
        loading && setIsLoadingAccounts(true);
        const result = await callPlatformAPI('qwep/get/accounts', 'post', {
            excludePromo: excludePromo ?? false,
        });

        console.log('getAccounts', result);
        if (result.data.isOk) {
            if (groupsAccountsIsActive) {
                let groupAccounts =
                    groupsAccountsState
                        ?.filter((el: any) => el.flag)[0]
                        ?.group?.filter((group: any) => group.flag)
                        ?.map((item: any) => item) ?? [];

                if (!excludePromo) {
                    groupAccounts = [
                        ...groupAccounts,
                        ...result.data.result.entity.accounts.filter((acc: any) => acc.promo),
                    ];
                }

                setAccounts(groupAccounts);
                setFullAccounts(result.data.result.entity.accounts ?? []);
            } else {
                setAccounts(result.data.result.entity.accounts ?? []);
                setFullAccounts(result.data.result.entity.accounts ?? []);
            }
        }
        loading && setIsLoadingAccounts(false);
    };

    const addVendor = async (data: any) => {
        const result = await callPlatformAPI('qwep/add/vendor', 'post', {
            vid: data.vid,
            login: data.login,
            title: data.title,
            password: data.password,
            bid: data.bid,
        });
        console.log('addVendor', result);

        if (result.data.isOk) {
            await getAccounts();
            setAddCurrentAcc(result.data.result.entity.accounts[0]);
        } else {
            setAllErrors([
                {
                    date: new Date(),
                    error: `Авторизация: ${result.data.errors}`,
                    title: data.title,
                    position: 'bottom-left',
                },
            ]);
        }
        return result.data;
    };

    const updateAccount = async (data: TypeUpdateAccount) => {
        console.log('DATA', data);

        const result = await callPlatformAPI('qwep/update/accounts', 'put', data);
        console.log('updateAccount', result);

        if (result.data.isOk) {
            await getAccounts();
        }
        return result.data;
    };

    const deleteAccount = async (data: { id: number }) => {
        console.log('DATA', data);
        if (groupsAccountsState?.length > 0) {
            const res = groupsAccountsState?.map((gr: any) => {
                return {
                    id: gr.id,
                    flag: gr.flag,
                    name: gr.name,
                    group: gr.group.filter((el: any) => el?.id !== data.id),
                };
            });
            const updateGroups = await updateProperty({
                applicationWorkSpaceId: workspaceApplication,
                propertyName: Property.GroupsAccounts,
                value: { value: [...res] },
            });
            if (!updateGroups?.isOk) {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            } else {
                Alert({
                    type: 'success',
                    text: 'Успешно!',
                });
            }
        }

        const result = await callPlatformAPI('qwep/delete/accounts', 'post', data);
        console.log('updateAccount', result);

        if (result.data.isOk) {
            await getAccounts();
        }
        return result.data;
    };

    const getAccountParametrs = async (data: { accountId: string }) => {
        const result = await callPlatformAPI('qwep/get/accounts/params', 'post', data);
        console.log('getAccountParametrs', result);
        return result.data;
    };

    const setAccountParametrs = async (data: TypeParametrs) => {
        const result = await callPlatformAPI('qwep/set/accounts/params', 'post', {
            accountId: data.accountId,
            formId: data.formId,
            fieldValues: [
                {
                    fieldName: data.fieldName,
                    value: data.value,
                },
            ],
        });
        console.log('setAccountParametrs', result);
        return result.data;
    };

    const checkForPromoMatchById = (infoId: number) => {
        return promoAccounts.some((account: Account) => infoId === account.id);
    };

    return (
        <AccountsContext.Provider
            value={{
                vendors,
                accounts,
                isLoadingSuppliers,
                isLoadingAccounts,
                addCurrentAcc,
                fullAccounts,
                promoAccounts,

                //methods
                getVendors,
                getAccounts,
                addVendor,
                getAccountParametrs,
                setAccountParametrs,
                updateAccount,
                deleteAccount,
                setAddCurrentAcc,
                checkForPromoMatchById,
                setVendors,
            }}
            {...props}
        />
    );
}

export { AccountsProvider, AccountsContext };
