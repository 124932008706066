import { useContext } from 'react';
import stls from '@f_styles/components/support/Support.module.sass';
import { Card, CardContent, Typography } from '@mui/material';
import { IconSupportTasks } from '@f_components/icons/IconSupportTasks';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';
import { ThemeContext } from '@f_context/Theme_context';
import { SupportContext } from '@f_context/Support_context';

export function AllMessages() {
    const {
        getSupportTask,
        getSupportChat,
        setCurrentTask,
        setCurrentChat,
        socketMessages,
        supportTasks,
        countNewMessage,
    } = useContext(SupportContext);

    const { colors } = useContext(ThemeContext);

    const openChatHandle = async (id: string) => {
        if (id) {
            await handleGetTask(id);
            await handleGetChat(id);
        }
    };

    const handleGetTask = async (id: string) => {
        const result = await getSupportTask(id);
        if (result.isOk) {
            setCurrentTask(result.result);
        }
    };

    const handleGetChat = async (id: string) => {
        const result = await getSupportChat(id);
        if (result.isOk) {
            setCurrentChat(result.result.content);
        }
    };

    return (
        <Card className={stls.support} style={{ background: colors.chi }}>
            <CardContent className={stls.supportContent}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                    <IconSupportTasks />
                    <div style={{ margin: '0 0 0 20px' }}>
                        <Typography variant="h5" style={{ marginBottom: '8px', color: colors.omicron }}>
                            Список обращений
                        </Typography>
                    </div>
                </div>

                {supportTasks
                    ?.sort((a: any, b: any) => {
                        if (a.date < b.date) return 1;
                        if (a.date > b.date) return -1;
                        return 0;
                    })
                    .map((el: any) => (
                        <Card
                            onClick={() => openChatHandle(el.id)}
                            className={stls.tasks}
                            style={{ background: colors.omega }}>
                            <CardContent
                                style={{
                                    borderLeft: `4px solid ${
                                        el.status == 'active' ? colors.lambda : colors.lightgreen
                                    }`,
                                    padding: '0 20px',
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                    <IconEditSmall />
                                    <div style={{ margin: '0 0 0 10px' }}>
                                        <h4 style={{ color: colors.omicron }}>{el.title}</h4>
                                    </div>
                                </div>

                                {socketMessages?.payload?.chatId == el.id && (
                                    <p style={{ color: colors.lambda, fontWeight: 'bold' }}>
                                        {socketMessages?.payload?.text}
                                        {countNewMessage !== 0 && (
                                            <span style={{ color: colors.lambda }} className={stls.countNewMessage}>
                                                +{countNewMessage}
                                            </span>
                                        )}
                                    </p>
                                )}
                                <p style={{ color: colors.sigma }}>
                                    Статус: {el.status === 'active' ? 'Активная' : 'Завершенная'}
                                </p>
                            </CardContent>
                        </Card>
                    ))}
            </CardContent>
        </Card>
    );
}
