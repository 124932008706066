import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/home/TableCross.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { ThemeContext } from '@f_context/Theme_context';
import { Checkbox } from '@mui/material';
import { CellBrand, HeadBrand } from './tableCross/ColumnBrand';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { CustomAutocomplete } from '@f_general/CustomAutocomplete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ConfigReportContext } from '@f_crossdata/context/ConfigReport_context';

export function TableCross({ data, currentArticleBrand }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { setTypeExport, setExportData, setOpenExportModal, appInfo } = useContext(CrossdataContext);
    const { getBrands, setCrossesPartname } = useContext(ConfigReportContext);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'brand',
                accessorKey: 'brand',
                header: () => <HeadBrand data={data} />,
                cell: (info) => <CellBrand info={info} />,
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'article',
                accessorKey: 'article',
                header: `${t('crossdata.crossTable.article')}`,
                cell: (info) => (
                    <p className={stls.cell} style={{ color: colors.alpha, fontWeight: 800 }}>
                        {info.row.original.article}
                    </p>
                ),
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'partname',
                accessorKey: 'partname',
                header: `${t('crossdata.crossTable.partname')}`,
                cell: (info) => <p className={stls.cell}>{info.row.original.partname}</p>,
                footer: (props) => props.column.id,
                size: 200,
            },
            {
                id: 'custom',
                accessorKey: 'custom',
                header: '',
                cell: (info) => (
                    <>
                        {info.row.original.custom && (
                            <p className={stls.cell}>
                                <button
                                    onClick={() =>
                                        deleteCrossHandler(info.row.original.article, info.row.original.brand)
                                    }>
                                    <HighlightOffIcon className={stls.iconDel} />
                                </button>
                            </p>
                        )}
                    </>
                ),
                footer: (props) => props.column.id,
                size: 5,
            },
        ],
        [data],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '30px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    const exportExcelHandler = () => {
        setTypeExport('crosses');
        setExportData({ filter: data.map((item: any) => item.active && item), all: data });
        setOpenExportModal(true);
    };

    const setCrossHandler = ({ inputValue, option }: any) => {
        setCrossesPartname([
            {
                article: inputValue,
                brand: option.brand,
                partname: '',
                custom: true,
                active: true,
            },
            ...data,
        ]);
    };

    const deleteCrossHandler = (article: string, brand: string) => {
        setCrossesPartname(data.filter((item: any) => item.article !== article || item.brand !== brand));
    };

    return (
        <div className={stls.container}>
            <p className={stls.lable} style={{ color: colors.gamma }}>
                {currentArticleBrand.brand}
                <span style={{ color: colors.alpha, textTransform: 'uppercase', marginLeft: '10px' }}>
                    {currentArticleBrand.article}
                </span>
            </p>
            <div className={stls.autocomplete}>
                <CustomAutocomplete
                    getShortNumbers={getBrands}
                    actionHandler={setCrossHandler}
                    label={'Добавте аналог'}
                />
            </div>

            <VirtualTable
                data={data}
                columnsTable={columns}
                topFilterPanel={true}
                showColumnSearchAction={false}
                heightTable={800}
                configHeader={{
                    fullScrean: true,
                    exportExcel: appInfo?.tariff?.workWithFiles,
                    resize: false,
                    grouping: false,
                    columnFilter: false,
                    sorting: true,
                    columnSearch: true,
                    dragging: false,
                }}
                styles={tableStyles}
                overscan={5}
                estimateSize={25}
                exportExcelHandler={exportExcelHandler}
            />
        </div>
    );
}
