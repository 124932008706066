import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconSupportTasksProps = TypeClassNames;

export const IconSupportTasks = ({ classNames }: TypeIconSupportTasksProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill="#6eba6e25" />
                <g clipPath="url(#clip0_3751_18806)">
                    <path
                        d="M24 34C18.477 34 14 29.523 14 24C14 18.477 18.477 14 24 14C29.523 14 34 18.477 34 24C34 29.523 29.523 34 24 34ZM24 32C26.1217 32 28.1566 31.1571 29.6569 29.6569C31.1571 28.1566 32 26.1217 32 24C32 21.8783 31.1571 19.8434 29.6569 18.3431C28.1566 16.8429 26.1217 16 24 16C21.8783 16 19.8434 16.8429 18.3431 18.3431C16.8429 19.8434 16 21.8783 16 24C16 26.1217 16.8429 28.1566 18.3431 29.6569C19.8434 31.1571 21.8783 32 24 32ZM23.003 28L18.76 23.757L20.174 22.343L23.003 25.172L28.659 19.515L30.074 20.929L23.003 28Z"
                        fill={colors.lightgreen}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3751_18806">
                        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
