import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import { QwepContext } from '../../../context/Qwep_context';

export function CellAvailability({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { qwepCrosses } = useContext(QwepContext);

    const quantity = info.row.original.quantity;
    const status = info.row.original.status;

    const quantityColor =
        status === 'В наличии'
            ? currentTheme === 'dark'
                ? colors.ucsGamma
                : colors.pi
            : currentTheme === 'dark'
            ? '#47556933'
            : colors.delta;
    const textColor = status === 'В наличии' ? colors.lightgreen : colors.epsilon;

    return (
        <p
            style={{
                background: quantityColor,
                border: `0.5px solid ${textColor}`,
                color:
                    qwepCrosses && info.row.original?.qwepCross != '1'
                        ? colors.blurColor
                        : // (info.row.original.status === 'В наличии'  ? colors.gamma :
                          colors.omicron,
                // )
                display: 'flex',
                justifyContent: 'center',
                width: '75%',
            }}
            className={cn(stls.cellAvailability)}>
            {quantity.formatted}
        </p>
    );
}
