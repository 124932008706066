import { FilterLeftTemplateStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftTemplate.store';
import { FilterLeftPanelTemplateCreateStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftPanelTemplateCreate.store';
import { FilterLeftStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/FilterLeft.store';
import { FilterLeftTemplateRemoveStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftTemplateRemove.store';
import { FilterLeftTemplateUpdateStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftTemplateUpdate.store';

const filterLeftStore = new FilterLeftStore();
export const filterLeftTemplateStore = new FilterLeftTemplateStore();
const filterLeftPanelTemplateCreateStore = new FilterLeftPanelTemplateCreateStore(filterLeftTemplateStore);
const filterLeftTemplateRemoveStore = new FilterLeftTemplateRemoveStore(filterLeftTemplateStore);
const filterLeftTemplateUpdateStore = new FilterLeftTemplateUpdateStore(filterLeftTemplateStore);

export class RootFilterLeftPanelStore {
    filterLeftTemplate = filterLeftTemplateStore;
    filterLeftPanelTemplateCreate = filterLeftPanelTemplateCreateStore;
    filterLeft = filterLeftStore;
    filterLeftTemplateRemove = filterLeftTemplateRemoveStore;
    filterLeftTemplateUpdate = filterLeftTemplateUpdateStore;
}
