import { action, makeObservable, observable } from 'mobx';
import { ModalStore } from '@f_store/Modal.store';
import { FilterLeftTemplateStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftTemplate.store';
import { Alert } from '@f_general/alert';
import { InputControlStore } from '@f_store/InputControl.store';
import { Property } from '@f_qwep/types';
import { IStandardPayload } from '@f_qwep/types/StandardPayload.interface';
import {
    IReqBodyTemplate,
    IResponseResultTemplate,
} from '@f_qwep/components/filters/filterLeftPanel/types/template.interface';
import _ from 'lodash';
import { changeFieldFilterStorage } from '@f_qwep/utils/changeFieldFilterStorage';

// TODO не забыть добавить перевод для алертов
// TODO В принципе как минимум флаг можно удалить из объекта в будущем

export class FilterLeftPanelTemplateCreateStore {
    filterTemplateStore: FilterLeftTemplateStore;
    modalStore: ModalStore;
    inputControlStore: InputControlStore;

    constructor(filterLeftTemplateStore: FilterLeftTemplateStore) {
        this.filterTemplateStore = filterLeftTemplateStore;
        this.modalStore = new ModalStore();
        this.inputControlStore = new InputControlStore();
        makeObservable(this, {
            templateCancelSaving: action.bound,
            handleTemplateSave: action.bound,
        });
    }

    async handleTemplateSave({ workspaceApplication, updateProperty, filter }: any) {
        const isValid = this.validateTemplateName();
        let reqBody: IReqBodyTemplate | null = null;
        let response: IStandardPayload<IResponseResultTemplate> | null = null;

        if (isValid) {
            reqBody = this.generateReqBody({ workspaceApplication });
        }

        if (reqBody) {
            response = await updateProperty(reqBody);

            if (response?.isOk) {
                Alert({
                    type: 'success',
                    text: `Шаблон ${this.inputControlStore.input} успешно сохранен.`,
                });

                if (this.filterTemplateStore.currentFilter) {
                    changeFieldFilterStorage(filter, this.filterTemplateStore.currentFilter);
                    this.filterTemplateStore.setCurrentTemplateName(this.inputControlStore.input);
                }
                this.inputControlStore.resetInput();
                this.modalStore.handleClose();
            } else {
                Alert({
                    type: 'error',
                    text: `Ошибка сохранения шаблона.`,
                });
            }
        }
    }

    templateCancelSaving() {
        this.inputControlStore.resetInput();
        this.modalStore.handleClose();
    }

    private validateTemplateName = (): boolean => {
        const { input } = this.inputControlStore;

        if (!input.length) {
            Alert({
                type: 'warning',
                text: `Название шаблона не может быть пустым`,
            });
            return false;
        }

        const hasNameInSavedTemplates = this.filterTemplateStore.currentTemplateFilters.some((template) => {
            return template.name === input;
        });

        if (hasNameInSavedTemplates) {
            Alert({
                type: 'warning',
                text: 'Шаблон с таким названием уже существует',
            });
            return false;
        }

        return true;
    };

    private generateReqBody = ({ workspaceApplication }: any): IReqBodyTemplate | null => {
        const { currentFilter, currentTemplateFilters } = this.filterTemplateStore;
        if (currentFilter) {
            const currentTemplateId = parseInt(_.uniqueId());
            this.filterTemplateStore.setActiveTemplateId({ id: currentTemplateId, name: this.inputControlStore.input });
            const reqBodyValue = [
                ...currentTemplateFilters,
                {
                    filters: [
                        {
                            minPrice: currentFilter.minPrice,
                            maxPrice: currentFilter.maxPrice,
                            brands: currentFilter.brands,
                            minShipmentDays: currentFilter.minShipmentDays,
                            maxShipmentDays: currentFilter.maxShipmentDays,
                            vendorTitle: currentFilter.vendorTitle,
                            warehouses: currentFilter.warehouses,
                            minQuantity: currentFilter.minQuantity,
                            maxQuantity: currentFilter.maxQuantity,
                            inStock: currentFilter.inStock,
                            isDealer: currentFilter.isDealer,
                            approvedCross: currentFilter.approvedCross,
                            searchArticle: currentFilter.searchArticle,
                        },
                    ],
                    flag: false,
                    name: this.inputControlStore.input,
                    id: currentTemplateId,
                },
            ];

            return {
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.DraftFilterSearch,
                value: { value: reqBodyValue },
            };
        }

        return null;
    };
}
