// import cn from 'classnames';
// import { ThemeContext } from "@f_context/Theme_context";
// import { TypeClassNames } from '@f_types/index';
// import { useContext } from 'react';

// type TypeIconAddAccProps = TypeClassNames;

export const IconData = () => {
    //   const { colors } = useContext(ThemeContext)
    return (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 18H11V31.5H5V18ZM26 12H32V31.5H26V12ZM15.5 3H21.5V31.5H15.5V3Z" fill="white" />
        </svg>
    );
};
