import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconImageProps = TypeClassNames;

export const IconImage = ({ classNames }: TypeIconImageProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="118" height="72" viewBox="0 0 118 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="118" height="72.001" rx="12" fill="#A9C1CF" fill-opacity="0.5" />
                <circle cx="24" cy="24" r="8" fill="#EDF0F5" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M77.486 16.8852C76.6892 15.6984 74.9408 15.7064 74.155 16.9004L60.924 37.0013L54.5109 28.9368C53.7101 27.9299 52.1808 27.9299 51.3801 28.9368L32.4384 52.7561C31.3963 54.0666 32.3294 56.001 34.0037 56.001H71.8872C71.8899 56.001 71.8926 56.001 71.8954 56.0009H99.9963C101.597 56.0009 102.549 54.2148 101.657 52.8861L77.486 16.8852Z"
                    fill="#EDF0F5"
                />
            </svg>
        </div>
    );
};
