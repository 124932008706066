import { GeneralContext } from '@f_context/General_context';
import { exportExcelFetch } from '@f_utils/exportExcelFetch';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Property } from '../type';
import { Apps } from '@f_types';
import useCrossdataNavigate from '../hooks/useCrossdataNavigate';

const CrossdataContext = createContext<any>({});

type TypeEnteredData = {
    article: string;
    brand: string;
}[];

type TypeGetReport = {
    minAvgReq: boolean;
    minAvgBuy: boolean;
    currency: string;
    periodEnd: string;
    periodStart: string;
    save: string;
    status: string;
    workspaceApplicationId: number;
    article: string;
    brand: string;
    refKey: string;
    cross: boolean;
    data: {
        article: string;
        brand: string;
    }[];
};

type TypeUpdateProperty = {
    applicationWorkspaceId: number;
    propertyName: string;
    value: object;
};

type TypeExportExcel = {
    type: string;
    data: any;
    title: string;
};

type TypeChangeTariff = {
    phone: string;
    name: string;
    comment: string;
};

function CrossdataProvider(props: any) {
    const navigate = useCrossdataNavigate();
    const location = useLocation();
    const { drawerPanels } = useContext(GeneralContext);
    const [workspaceApplicationId, setWorkspaceApplicationId] = useState(-1);
    const [enteredData, setEnteredData] = useState<TypeEnteredData>([]);
    const [report, setReport] = useState([]);
    const [reportType, setReportType] = useState('');
    const [reportDate, setReportDate] = useState({ periodStart: '', periodEnd: '', currency: '' });
    const [reportFilter, setReportFilter] = useState('');
    //export excel
    const [openExportModal, setOpenExportModal] = useState(false);
    const [typeExport, setTypeExport] = useState();
    const [exportData, setExportData] = useState();
    //app info
    const [appInfo, setAppInfo] = useState();
    const [activeApp, setActiveApp] = useState(true);
    const [blocked, setBlocked] = useState(false);
    const [applicationInfoLoading, setApplicationInfoLoading] = useState(false);

    useEffect(() => {
        if (drawerPanels?.length) {
            const workspaceApp = drawerPanels?.find((item: any) => item.applicationSysName === Apps.Data);
            setWorkspaceApplicationId(workspaceApp?.applicationWorkspaceId);
            applicationInfo(workspaceApp?.applicationWorkspaceId);
            setBlocked(!workspaceApp.applicationPanelIsActive);
        }
    }, [drawerPanels]);

    const applicationInfo = async (applicationWorkspaceId: number) => {
        setApplicationInfoLoading(true);
        if (applicationWorkspaceId) {
            const result = await callPlatformAPI(`crossdata/auth/${applicationWorkspaceId}`, 'get');
            console.log('applicationInfo', result);
            if (result.data.isOk) {
                const date = new Date();
                const expire = new Date(result.data.result.expire);
                if (date < expire) {
                    setActiveApp(true);
                } else {
                    navigate('subscription');
                    setActiveApp(false);
                }
                setAppInfo(result.data.result);
            }
        }
        setApplicationInfoLoading(false);
    };

    useEffect(() => {
        if (blocked) {
            navigate('subscription');
        }
    }, [blocked]);

    useEffect(() => {
        if (location.pathname.includes('app-crossdata')) {
            getConfigItem();
        }
    }, [location]);

    const getConfigItem = async () => {
        const result = await callPlatformAPI(`crossdata/get/property/${Property.ItemSettingReport}`, 'get');
        console.log('getConfigItem', result);
        if (result.data.isOk) {
            setEnteredData(result.data.result.value);
        }
    };

    const updateProperty = async (data: TypeUpdateProperty) => {
        const result = await callPlatformAPI('crossdata/update/property', 'put', data);
        console.log('updateProperty', result);
        if (result.data.isOk) {
            switch (data.propertyName) {
                case Property.ItemSettingReport:
                    setEnteredData(result.data.result[0].value.value);
                    break;
            }
        }
        return result.data;
    };

    const getReport = async (data: TypeGetReport) => {
        setReportDate({ periodStart: data.periodStart, periodEnd: data.periodEnd, currency: data.currency });
        const result = await callPlatformAPI('crossdata/get/report', 'post', data);
        console.log('getReport', result);
        if (result.data.isOk) {
            if (result.data.result.content?.length) {
                setReport(result.data.result.content);
                setReportType(result.data.result.type);
                navigate('report');
            }
        }
        return result.data;
    };

    const exportExcel = async (data: TypeExportExcel) => {
        const lang = localStorage.getItem('language');
        const result = await exportExcelFetch('crossdata/export/excel', {
            ...data,
            lang,
        });
        return result;
    };

    const changeTariff = async (data: TypeChangeTariff) => {
        const result = await callPlatformAPI('crossdata/change/tariff', 'post', data);
        return result.data;
    };

    return (
        <CrossdataContext.Provider
            value={{
                activeApp,
                blocked,
                enteredData,
                report,
                workspaceApplicationId,
                reportDate,
                reportFilter,
                //info
                applicationInfoLoading,
                appInfo,
                //export
                openExportModal,
                typeExport,
                exportData,

                //methods
                setEnteredData,
                updateProperty,
                getReport,
                setReportFilter,
                reportType,
                changeTariff,
                //export
                setOpenExportModal,
                setTypeExport,
                setExportData,
                exportExcel,
            }}
            {...props}
        />
    );
}

export { CrossdataProvider, CrossdataContext };
