import stls from '../../../styles/component/basket/Basket.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import { QwepContext } from '../../../context/Qwep_context';

export function CellTotal({ info }: any) {
    const { priceMarginIsActive } = useContext(QwepContext);
    const { colors, currentTheme } = useContext(ThemeContext);
    const price = priceMarginIsActive
        ? Number(info.row.original.priceWithMargin) * Number(info.row.original.quantity)
        : +info.row.original.priceTotal;

    return (
        <div style={{ justifyContent: 'flex-start' }} className={stls.cellDiv}>
            <p
                style={{
                    background: colors.pi,
                    border: `0.5px solid ${colors.gamma}`,
                    color: currentTheme == 'light' ? colors.omicron : colors.gamma,
                }}
                className={stls.cellPrice}>
                {(+price).toLocaleString()} ₽
            </p>
        </div>
    );
}
