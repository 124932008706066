import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconOneSClassicProps = TypeClassNames;

export const IconOneSClassic = ({ classNames }: TypeIconOneSClassicProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.1454 6.90862H20.5273C19.9818 3.8541 17.3636 1.4541 14.0909 1.4541C10.4909 1.4541 7.54544 4.39954 7.54544 7.99954C7.54544 11.5265 10.3727 14.4288 13.8727 14.545H25V11.2723H14.0909C10.9844 11.2026 9.95064 7.78415 11.6031 5.85149C13.2383 3.95273 16.3295 4.55465 17.1539 6.93229M6.45456 1.4541V14.545H3.1818V5.81774H1V2.54498H3.1818V1.4541H6.45456Z"
                    stroke={colors.omicron}
                    stroke-width="1.5"
                />
            </svg>
        </div>
    );
};
