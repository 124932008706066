import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useMemo, useState } from 'react';
import GeneralModal from '@f_general/GeneralModal';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { CrossdataContext } from '../context/Crossdata_context';
import { ConfigReportContext } from '../context/ConfigReport_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function ExportExcelModal() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { openExportModal, setOpenExportModal, typeExport, setTypeExport, exportData, setExportData, exportExcel } =
        useContext(CrossdataContext);
    const { currentArticleBrand } = useContext(ConfigReportContext);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    useEffect(() => {
        switch (typeExport) {
            case 'crosses':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [
                                { key: 'Только отфильтрованные', value: false },
                                { key: 'Скачать Полностью', value: true },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'crosses',
                    data: {
                        all: false,
                        type: 'crosses',
                    },
                } as TypeDataModal);
                break;
            case 'report':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [
                                { key: 'Только отфильтрованные', value: false },
                                { key: 'Скачать Полностью', value: true },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'report',
                    data: {
                        all: false,
                        type: 'report',
                    },
                } as TypeDataModal);
                break;
        }
    }, [openExportModal]);

    const sendDataModalHandler = async (values: any) => {
        switch (values.type) {
            case 'crosses':
                const resultCrosses = await exportExcel({
                    type: values.type,
                    data: values.all ? exportData.all : exportData.filter,
                    title: `Аналоги (${currentArticleBrand.brand} ${currentArticleBrand.article})`,
                });
                if (resultCrosses.isOk) {
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'report':
                const resultReport = await exportExcel({
                    type: values.type,
                    data: values.all ? exportData.all : exportData.filter,
                    title: `Отчет (${currentArticleBrand.brand} ${currentArticleBrand.article})`,
                });
                if (resultReport.isOk) {
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    const closeHandler = () => {
        setDataModal({});
        setTypeExport();
        setExportData();
        setOpenExportModal(false);
    };

    return (
        <>
            {dataModal.type && (
                <GeneralModal
                    openModal={openExportModal}
                    closeModal={closeHandler}
                    sendDataModalHandler={sendDataModalHandler}
                    dataModal={dataModal}
                />
            )}
        </>
    );
}
