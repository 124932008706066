import stls from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/createCollectorModal/CreateCollectorModal.module.sass';
import {
    Button,
    Checkbox,
    colors,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Tooltip,
} from '@mui/material';

import { ChangeEvent, useContext } from 'react';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import AddIcon from '@mui/icons-material/Add';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeAppInfo } from '@f_ucs/context/UCS_context';

export type TypeKeyItem = {
    key: string;
    alias: string;
};

export type TypeUsersKey = {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
};

export type TypeTokens1c = {
    id: number,
    key: string,
    alias: string
}

export interface CompanyPartOfModalProps {
    selectKeyHandler: (e: string) => void;
    changeWebUserHandler: (checked: boolean, userId: number) => void;
    usersKey: TypeUsersKey[];
    userIds: number[];
    type: 'web' | '1c';
    appInfo: TypeAppInfo;
    tokensHandler: (value: boolean) => void;
    isOpenKeys: boolean;
    tokens1c: TypeTokens1c[];
    changeRowHandle: (e: string, id: number, alias: boolean) => void;
    deleteRowHandler: (id: number) => void;
    addRowHandler: () => void;
}

export function CompanyPartOfModal({
                                       selectKeyHandler,
                                       changeWebUserHandler,
                                       usersKey,
                                       userIds,
                                       type,
                                       appInfo,
                                       tokensHandler,
                                       isOpenKeys,
                                       tokens1c,
                                       changeRowHandle,
                                       deleteRowHandler,
                                       addRowHandler,
                                   }: CompanyPartOfModalProps) {

    const { colors, CustomSelect } = useContext(ThemeContext);

    return (
        <div className={stls.subSettings}>
            <div className={stls.inputKey}>
                {type === 'web' ? (
                    <p style={{ fontWeight: '500', marginBottom: '5px' }}>
                        Выберите компанию и сотрудников:
                    </p>
                ) : (
                    <p style={{ fontWeight: '500', marginBottom: '5px' }}>Выберите компанию:</p>
                )}
                <Select
                    input={<CustomSelect />}
                    className={stls.select}
                    title="Выберите компанию и сотрудников:"
                    autoWidth
                    defaultValue={type === 'web' ? appInfo?.keysWeb[0]?.key : appInfo?.keys1c[0]?.key}
                    onChange={({ target }) => selectKeyHandler(target.value)}
                    sx={{
                        '& .MuiInputBase-input': {
                            padding: '10px 26px 9px 12px',
                        },
                    }}
                    MenuProps={{
                        sx: {
                            '&& .MuiMenuItem-root': {
                                fontSize: 13,
                                fontFamily: 'Inter',
                                color: colors.omicron,
                                '&:hover': {
                                    background: colors.beta,
                                },
                            },
                        },
                    }}>
                    {(type === 'web' ? appInfo?.keysWeb : appInfo?.keys1c)?.map(
                        (option: TypeKeyItem) => {
                            return (
                                <MenuItem key={option.key} value={option.key}>
                                    <Tooltip title={`Ключ: ${option.key}`}>
                                        <p>{option.alias}</p>
                                    </Tooltip>
                                </MenuItem>
                            );
                        },
                    )}
                </Select>
            </div>
            {type === 'web' ? (
                usersKey?.length ? (
                    <div className={stls.users}>
                        {usersKey.map((item) => {
                            return (
                                <div key={item.userId} className={stls.userItem}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={userIds.some((i) => i === item.userId)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    changeWebUserHandler(e.target.checked, item.userId)
                                                }
                                            />
                                        }
                                        label={`${item.firstName} ${item.lastName}`}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )
            ) : (
                <div className={stls.tokensList}>
                    <Button
                        variant={'contained'}
                        className={stls.addKeyBtn}
                        onClick={() => tokensHandler(!isOpenKeys)}>
                        {isOpenKeys ? 'Все 1С токены' : 'Указать токены 1С'}
                    </Button>
                    {isOpenKeys && (
                        <div className={stls.list}>
                            {tokens1c.map((item, idx) => {
                                return (
                                    <div key={item.id} className={stls.rowAddKey}>
                                        <TextField
                                            label={'Токен'}
                                            className={stls.input}
                                            type="text"
                                            name="token"
                                            sx={{
                                                minWidth: '0px',
                                            }}
                                            value={item.key}
                                            focused
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                changeRowHandle(e.target.value, item.id, false)
                                            }
                                        />
                                        <TextField
                                            label={'Псевдоним'}
                                            className={stls.inputAlias}
                                            type="text"
                                            name="alias"
                                            sx={{
                                                minWidth: '0px',
                                            }}
                                            value={item.alias}
                                            focused
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                changeRowHandle(e.target.value, item.id, true)
                                            }
                                        />
                                        <IconButton
                                            className={stls.deleteRowBtn}
                                            onClick={() => deleteRowHandler(item.id)}
                                            disabled={tokens1c.length === 1}>
                                            <DoDisturbOnIcon />
                                        </IconButton>
                                    </div>
                                );
                            })}
                            <Button
                                className={stls.addBtn}
                                sx={{ background: colors.gamma }}
                                onClick={addRowHandler}>
                                <AddIcon sx={{ color: colors.tau }} />
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}