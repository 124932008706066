import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconLicenseProps = TypeClassNames;

export const IconLicense = ({ classNames }: TypeIconLicenseProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fillOpacity="0.2" fill="#6EBA6E" />
                <path
                    d="M29 27.245V34.117C29 34.2054 28.9766 34.2923 28.932 34.3688C28.8875 34.4452 28.8235 34.5085 28.7465 34.5521C28.6696 34.5957 28.5824 34.6181 28.4939 34.617C28.4055 34.616 28.3189 34.5914 28.243 34.546L24 32L19.757 34.546C19.681 34.5915 19.5944 34.616 19.5058 34.617C19.4173 34.6181 19.33 34.5956 19.253 34.5518C19.176 34.5081 19.112 34.4447 19.0676 34.3681C19.0231 34.2915 18.9998 34.2045 19 34.116V27.246C17.7061 26.2101 16.766 24.7979 16.3095 23.2045C15.8529 21.6112 15.9026 19.9154 16.4515 18.3514C17.0004 16.7875 18.0216 15.4327 19.3738 14.4743C20.726 13.5158 22.3425 13.001 24 13.001C25.6575 13.001 27.274 13.5158 28.6262 14.4743C29.9784 15.4327 30.9996 16.7875 31.5485 18.3514C32.0974 19.9154 32.1471 21.6112 31.6905 23.2045C31.234 24.7979 30.2939 26.2101 29 27.246V27.245ZM21 28.418V31.468L24 29.668L27 31.468V28.418C26.0468 28.8035 25.0282 29.0011 24 29C22.9718 29.0011 21.9532 28.8035 21 28.418ZM24 27C25.5913 27 27.1174 26.3678 28.2426 25.2426C29.3679 24.1174 30 22.5913 30 21C30 19.4087 29.3679 17.8826 28.2426 16.7573C27.1174 15.6321 25.5913 15 24 15C22.4087 15 20.8826 15.6321 19.7574 16.7573C18.6321 17.8826 18 19.4087 18 21C18 22.5913 18.6321 24.1174 19.7574 25.2426C20.8826 26.3678 22.4087 27 24 27Z"
                    fill="#6EBA6E"
                />
                <path
                    d="M21 28.418V31.468L24 29.668L27 31.468V28.418C26.0468 28.8035 25.0282 29.0011 24 29C22.9718 29.0011 21.9532 28.8035 21 28.418Z"
                    fill="#6EBA6E"
                />
            </svg>
        </div>
    );
};
