import { Typography, Modal, Box, Button } from '@mui/material';
import { useState } from 'react';
import { MyModal } from '@f_general/MyModal';
import stls from './Content.module.sass';
import { Link } from 'react-router-dom';

interface ContentProps {
    active: boolean;
    inactiveHandler: () => Promise<void>;
}

export const Content: React.FC<ContentProps> = ({ active, inactiveHandler }) => {
    const [open, setOpen] = useState(active);
    const handleClose = async () => {
        setOpen(false);
        await inactiveHandler();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={stls.modal}
            sx={{
                padding: '0 !important',
            }}>
            <MyModal className={stls.modal}>
                <Box className={stls.youtube}>
                    <Button onClick={handleClose} className={stls.close}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_10543_74262)">
                                <rect width="32" height="32" rx="16" fill="#EDF0F5" />
                                <path
                                    d="M15.2929 14.2327C15.6834 14.6233 16.3166 14.6233 16.7071 14.2327L19.0305 11.9094C19.3652 11.5747 19.9079 11.5747 20.2426 11.9094C20.5774 12.2441 20.5774 12.7868 20.2426 13.1216L17.9193 15.4449C17.5288 15.8354 17.5288 16.4686 17.9193 16.8591L20.2426 19.1825C20.5774 19.5172 20.5774 20.0599 20.2426 20.3947C19.9079 20.7294 19.3652 20.7294 19.0305 20.3947L16.7071 18.0713C16.3166 17.6808 15.6834 17.6808 15.2929 18.0713L12.9695 20.3947C12.6348 20.7294 12.0921 20.7294 11.7574 20.3947C11.4226 20.0599 11.4226 19.5172 11.7574 19.1825L14.0807 16.8591C14.4712 16.4686 14.4712 15.8354 14.0807 15.4449L11.7574 13.1216C11.4226 12.7868 11.4226 12.2441 11.7574 11.9094C12.0921 11.5747 12.6348 11.5747 12.9695 11.9094L15.2929 14.2327Z"
                                    fill="#8CBCE1"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_10543_74262">
                                    <rect width="32" height="32" rx="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Button>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className={stls.title}>
                        Ассистент QWEP
                    </Typography>
                    <div className={stls.scndLay}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className={stls.descr}>
                            Новый функционал бесплатно <br /> до 15 декабря!
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className={stls.link}>
                            <Link to="purchasing-assistant" className={stls.link} onClick={handleClose}>
                                Узнайте подробнее {'\u00A0'}
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ paddingTop: '4px' }}>
                                    <path
                                        d="M12.172 6.99992L6.808 1.63592L8.222 0.221924L16 7.99992L8.222 15.7779L6.808 14.3639L12.172 8.99992H0V6.99992H12.172Z"
                                        fill="#218EE1"
                                    />
                                </svg>
                            </Link>
                        </Typography>
                    </div>
                </Box>
            </MyModal>
        </Modal>
    );
};
