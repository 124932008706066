import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import { Tooltip } from '@mui/material';
import { CellInfo } from './columnInfo';
import { useEffect, useState } from 'react';
import { useWindowSize } from '@f_hooks';

export function CellTitle({ info }: any) {
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    return (
        <div className={stls.cellTitle}>
            {info.row.original.title?.length > 30 ? (
                <Tooltip title={info.row.original.title}>
                    <p className={stls.text}>{info.row.original.title}</p>
                </Tooltip>
            ) : (
                <p className={stls.text}>{info.row.original.title}</p>
            )}
            {!mobile ? <CellInfo info={info} /> : <></>}
        </div>
    );
}
