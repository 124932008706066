import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconPriceChipProps = TypeClassNames;

export const IconPriceChip = ({ classNames }: TypeIconPriceChipProps) => {
    return (
        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="7" stroke="#EDF0F5" stroke-width="1.7" />

            <path
                d="M4.95739 5.58212H0.453125V4.46564H4.94034C5.3267 4.46564 5.6463 4.40172 5.89919 4.27388C6.15199 4.1432 6.33949 3.96422 6.46169 3.73695C6.58379 3.50683 6.64489 3.24263 6.64489 2.94433C6.64489 2.64888 6.58379 2.38467 6.46169 2.15172C6.33949 1.91876 6.15339 1.73553 5.90339 1.602C5.65341 1.46564 5.33807 1.39746 4.95739 1.39746H3.09943V8.99973H1.78693V0.272461H4.95739C5.61932 0.272461 6.1719 0.391781 6.6151 0.630411C7.0611 0.869051 7.3949 1.19007 7.6165 1.59348C7.8409 1.99689 7.9531 2.44575 7.9531 2.94007C7.9531 3.44007 7.8395 3.89035 7.6122 4.29092C7.38779 4.68865 7.0526 5.00399 6.60649 5.23695C6.16339 5.46706 5.61364 5.58212 4.95739 5.58212ZM5.02131 6.31503V7.43583H0.453125V6.31503H5.02131Z"
                fill="#EDF0F5"
                transform="translate(5 5)"
            />
        </svg>
    );
};
