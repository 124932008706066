import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/report/Report.module.sass';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { useNavigate } from 'react-router-dom';
import { LayoutPage } from '@f_crossdata/general/LayoutPage';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TableReport } from './TableReport';
import { AnalogChart } from './AnalogChart';
import useCrossdataNavigate from '@f_crossdata/hooks/useCrossdataNavigate';

export function Report() {
    const { t } = useTranslation();
    const navigate = useCrossdataNavigate();
    const { colors } = useContext(ThemeContext);
    const { report } = useContext(CrossdataContext);

    useEffect(() => {
        if (!report?.length) {
            navigate();
        }
    }, [report]);

    return (
        <LayoutPage classNames={stls.container}>
            <Tooltip className={stls.btn} title={t('crossdata.back')} onClick={() => navigate()}>
                <IconButton sx={{ background: colors.gamma }}>
                    <ArrowBackIosIcon sx={{ color: colors.tau }} className={stls.icon} />
                </IconButton>
            </Tooltip>
            <AnalogChart analogData={report} />
            <TableReport data={report} />
        </LayoutPage>
    );
}
