import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { useContext } from 'react';
import { Tooltip } from '@mui/material';
import { IconInfoTable } from '../../icons/IconInfoTable';
import { OrdersContext } from '../../../context/Orders_context';

export function CellTitle({ info }: any) {
    const { setOpenCardResult, setCardItem } = useContext(OrdersContext);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tooltip title={info.row.original.partname}>
                <p className={stls.cellTitle}>{info.row.original.partname}</p>
            </Tooltip>
            <div className={stls.cellAdditionalContent}>
                <button
                    onClick={(e) => {
                        setOpenCardResult(true);
                        setCardItem(info.row.original);
                    }}
                    className={stls.btn}>
                    <IconInfoTable classNames={[stls.icon]} />
                </button>
            </div>
        </div>
    );
}
