import stls from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/createCollectorModal/CreateCollectorModal.module.sass';
import { IconAddModal } from '@f_admin/components/icons/IconAddModal';
import { Typography } from '@mui/material';
import { TypeCurrentType } from '@f_ucs/context/collector_context';


export function CollectorModalTitle ({ type }: { type: TypeCurrentType }) {

    return(
        <div className={stls.modalTitle}>
            <IconAddModal />
            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                {'Создать сборщика ежедневного отчета:'} {type === 'web' ? 'Для WEB' : 'Для 1С'}
            </Typography>
        </div>
    )
}