import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { OrdersContext } from '../../context/Orders_context';
import { ErrorsContext } from '../../context/Errors_context';
import { Order } from './Order';
import { OrderVendor } from './OrderVendor';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { Tab } from '@mui/material';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';

export function Orders() {
    const { t } = useTranslation();
    const { isAccess } = useAccess(QwepProductEnum.PRICE);
    const { colors } = useContext(ThemeContext);
    const { orders, excludedVendors } = useContext(OrdersContext);
    const { setAllErrors } = useContext(ErrorsContext);
    const [panel, setPanel] = useState(1);

    const handleChangePanel = (event: React.SyntheticEvent, newValue: number) => {
        setPanel(newValue);
    };

    useEffect(() => {
        if (excludedVendors?.length) {
            setAllErrors([
                {
                    date: new Date(),
                    error: excludedVendors
                        .filter((item: any, id: number) => id < 50)
                        .map((item: any) => `Поставщик ${item.vendorTitle} не ответил!\n`),
                    position: 'bottom-left',
                },
            ]);
        }
    }, [excludedVendors]);

    if (isAccess) {
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={panel} onChange={handleChangePanel}>
                        <Tab sx={{ color: '#3B82F6 !important' }} value={1} label="Заказы" />
                        <Tab sx={{ color: '#3B82F6 !important' }} value={2} label="Ваши заказы" />
                    </Tabs>
                </Box>
                <div role="tabpanel" hidden={panel !== 1}>
                    <Order orders={orders} />
                </div>
                <div role="tabpanel" hidden={panel !== 2}>
                    <OrderVendor />
                </div>
            </Box>
        )
    } else {
        return  <Order orders={orders} />
    }
}
