import { ThemeContext } from '@f_context/Theme_context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchContext } from '@f_qwep/context/Search_context';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';

export function WorkWithSearchResultManual() {
    const { colors } = useContext(ThemeContext);
    const { arrSearchResults, inputSearch, preSearchLoading } = useContext(SearchContext);
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const [targetLoaded, setTargetLoaded] = useState(false);

    const [run, setRun] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);

    const [steps, setSteps] = useState<any>([
        {
            target: '#searchResultInput',
            title: 'Как выполнить поиск',
            content: 'Чтобы начать поиск введите артикул и выберите бренд из подсказок, либо нажмите enter',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#helpBrand',
            content: 'Это подсказка, нажмите на искомый бренд, чтобы совершить поиск по нему',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#searchItem',
            title: 'Результаты поиска',
            content: 'Дождитесь результатов поиска',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#Название',
            title: 'Сортировка результатов',
            content:
                'По умолчанию таблица результатов отсортирована по наименьшей стоимости товара, вы можете настроить свою сортировку, нажав на заголовок необходимой к сортировке колонки',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#НазваниеТаблРезульт',
            content: 'По каждой колонке в отдельности доступен уточняющий поиск',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#tableSettings',
            content: 'По умолчанию колонка со складом Поставщика скрыта, но вы можете самостоятельно настроить колонки',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
    ]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            setRun(true);
            if (!newManualValue.search?.flag) {
                setNewManualValue({ ...newManualValue, search: { flag: true } });
            }
        }
    }, []);

    useEffect(() => {
        const target = document.querySelector('#helpBrand');
        if (target) {
            setTargetLoaded(true);
        }
    }, [preSearchLoading]);

    useEffect(() => {
        if (inputSearch.length > 2) {
            if (!preSearchLoading && targetLoaded) {
                setCurrentStep((prevStep) => prevStep + 1);
            }
        }
    }, [inputSearch, preSearchLoading, targetLoaded]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/search') {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [window.location.pathname]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setChooseManual();
            setRun(false);
        } else if (type === 'step:after' && (action === 'back' || action === 'prev')) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    return (
        <div>
            <GeneralJoyride
                steps={steps}
                run={run}
                callback={handleJoyrideCallback}
                stepIndex={currentStep}
                disabledButton={!arrSearchResults[0]?.finished}
            />
        </div>
    );
}
