import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import styles from '@f_qwep/styles/component/price/PriceModal.module.sass';
import { PriceContext } from '@f_qwep/context/Price_context';

type TypeProps = {
    open: boolean;
    handleClose: any;
    xml: string;
    setIsOpenExampleXMLModal: (open: boolean) => void;
};

//Вернул обратно компонент так как он необходимо для передачи offsting при загрузке xml
export function XmlModalContentPrice({ open, handleClose, xml, setIsOpenExampleXMLModal }: TypeProps) {
    const { colors } = useContext(ThemeContext);
    const [loading, setLoading] = useState(false);
    const { offsting, setOffsting } = useContext(PriceContext);
    const { t } = useTranslation();
    const [id, setId] = useState<string | null>('');

    function splitXmlString(xmlString: string) {
        const tags = xmlString?.split('<').filter((tag) => tag.trim() !== '');
        const tagArray = tags.map((tag) => '<' + tag);
        return tagArray;
    }
    const tagsArray = splitXmlString(xml);

    const sendData = () => {
        setId('');
        handleClose();
    };

    id && setOffsting(`[${id?.slice(4)}]`);

    function mergeTags(tags: any) {
        let mergedTags = [];
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].startsWith('<')) {
                let openTag = `${tags[i].split('>')[0]}>`;
                if (i < tags.length - 1 && tags[i + 1] === `</${openTag.substring(1)}`) {
                    let closedTag = tags[i + 1];
                    mergedTags.push(`${tags[i]}<${closedTag.substring(1)}`);
                    i++;
                } else {
                    mergedTags.push(openTag);
                }
            } else {
                mergedTags.push(tags[i]);
            }
        }
        return mergedTags;
    }

    const result = mergeTags(tagsArray);

    // внешний счетчик прибавляет цифру к последнему разряду
    let counter = 0;
    // внешний счетчик прибавляет разряд
    let spaces: number[] = [];
    const vlog: number[] = [0];

    return (
        <>
            {open ? (
                <>
                    <div className={styles.header}>
                        <div className={styles.modalTitle}>
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                Выберите ПЕРВЫЙ xml-тег с которого начинаются позции Вашего прайса
                                {<Button onClick={() => setIsOpenExampleXMLModal(true)}>Например</Button>}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ margin: 16, overflowY: 'scroll', height: '35rem', width: '100%' }}>
                        {result?.map((tag: string, idx: number) => {
                            if (tag.includes('/') && tag.includes('<') && !tag.includes('<', tag.indexOf('<') + 2)) {
                                // тег закрывающий
                                vlog.push(vlog[vlog.length - 1] - 1);
                                spaces.pop();
                                return (
                                    <>
                                        <Button
                                            data-id={spaces.toString()}
                                            onClick={(e) => setId(e?.currentTarget?.getAttribute('data-id'))}
                                            sx={{ color: colors.omicron, cursor: 'pointer' }}>
                                            {Array(spaces.length * 2)
                                                .fill('.')
                                                .join('')}
                                            {tag}
                                        </Button>
                                        <br></br>
                                    </>
                                );
                            } else if (
                                tag.includes('/') &&
                                tag.includes('<') &&
                                tag.includes('<', tag.indexOf('<') + 2)
                            ) {
                                // тег открыт и закрыт на одной строке
                                if (counter === 0) {
                                    vlog.push(vlog[vlog.length - 1] + 1, vlog[vlog.length - 1] + 1);
                                    spaces.push(0);
                                    counter++;
                                    return (
                                        <>
                                            <Button
                                                data-id={spaces.toString()}
                                                onClick={(e) => setId(e?.currentTarget?.getAttribute('data-id'))}
                                                sx={{ color: colors.omicron, cursor: 'pointer' }}>
                                                {Array(spaces.length * 2)
                                                    .fill('.')
                                                    .join('')}
                                                {tag}
                                            </Button>
                                            <br></br>
                                        </>
                                    );
                                } else {
                                    vlog.push(vlog[vlog.length - 1], vlog[vlog.length - 1]);
                                    spaces = spaces.map((el: number, idx: number) => {
                                        if (idx === spaces.length - 1) {
                                            el = el + 1;
                                        }
                                        return el;
                                    });
                                    return (
                                        <>
                                            <Button
                                                data-id={spaces.toString()}
                                                onClick={(e) => setId(e?.currentTarget?.getAttribute('data-id'))}
                                                sx={{ color: colors.omicron, cursor: 'pointer' }}>
                                                {Array(spaces.length * 2)
                                                    .fill('.')
                                                    .join('')}
                                                {tag}
                                            </Button>
                                            <br></br>
                                        </>
                                    );
                                }
                            } else {
                                //тег открыт а пердыдущий закрыт
                                if (idx !== 0 && result[idx - 1]?.includes('/')) {
                                    const num = vlog.filter((el: number) => el === vlog[vlog.length - 1])?.length / 2;
                                    num === 0
                                        ? spaces.push(0)
                                        : (spaces = spaces.map((el: number, idx: number) => {
                                              if (idx === spaces.length - 1) {
                                                  el = el + num;
                                              }
                                              return el;
                                          }));
                                    vlog.push(vlog[vlog.length - 1]);
                                } else {
                                    spaces.push(0);
                                    vlog.push(vlog[vlog.length - 1] + 1);
                                }
                                counter = 0;
                                return (
                                    <>
                                        <Button
                                            data-id={spaces.toString()}
                                            onClick={(e) => setId(e?.currentTarget?.getAttribute('data-id'))}
                                            sx={{ color: colors.omicron, cursor: 'pointer' }}>
                                            {Array(spaces.length * 2)
                                                .fill('.')
                                                .join('')}
                                            {tag}
                                        </Button>
                                        <br></br>
                                    </>
                                );
                            }
                        })}
                    </div>
                    <div className={styles.buttonsContainer}>
                        <Button
                            className={styles.modalDeleteButton}
                            onClick={handleClose}
                            size="large"
                            sx={{
                                padding: '13px',
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {t('qwep.settings.suppliers.canceled')}
                        </Button>
                        <Button
                            disabled={offsting === '[0,0]'}
                            className={styles.modalButton}
                            size="large"
                            variant="contained"
                            type="submit"
                            onClick={() => sendData()}>
                            {t('general.confirm')}
                            {loading && (
                                <CircularProgress className={styles.loading} size={'25px'} sx={{ color: colors.psi }} />
                            )}
                        </Button>
                    </div>
                </>
            ) : null}
        </>
    );
}
