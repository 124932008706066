import React from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useDatePicker} from '@f_ucs/context/date_picker_context';
import stls from '@f_ucs/styles/components/dataSelectorPanel/dateRangeModule.module.sass';
import 'react-date-range/dist/styles.css';
import {startOfToday} from 'date-fns';
import { ru } from 'date-fns/locale';

export function DateRangePicker() {
    const {dateRange, handleDateCustomRangeChange} = useDatePicker();

    const today = startOfToday();

    const ranges = {
        startDate: new Date(dateRange[0].startDate),
        endDate: new Date(dateRange[0].endDate),
        key: "selection"
    }

    return (
        <div className={stls.calendar}>
            <DateRange
                editableDateInputs={true}
                ranges={[ranges]}
                onChange={handleDateCustomRangeChange}
                maxDate={today}
                locale={ru}
                dateDisplayFormat={'dd.MM.yyyy'}
                startDatePlaceholder={`Начальная дата`}
                endDatePlaceholder={`Конечная дата`}
            />
        </div>
    );
}
