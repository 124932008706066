export function filterUniqueUsers(buys: any[], currentType: 'web' | '1c'): any[] {
    const uniqueUsersMap = new Map<string | number, any>();

    buys.forEach((buy) => {
        const user = buy.user;
        const uniqueKey = currentType === 'web' ? user.userId : user.key;

        if (uniqueKey != null && !uniqueUsersMap.has(uniqueKey)) {
            uniqueUsersMap.set(uniqueKey, user);
        }
    });

    return Array.from(uniqueUsersMap.values());
}