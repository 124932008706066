import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {VirtualTable} from '@f_general/stackTable/VirtualTable';
import {useContext, useEffect, useMemo, useState} from 'react';
import {ThemeContext} from '@f_context/Theme_context';
import {ColumnDef} from '@tanstack/react-table';
import {useWindowSize} from '@f_hooks';
import stls from '@f_ucs/styles/components/report/employeeStatisticsModule/activityReportTable/TableActive.module.sass';
import {useReport} from '@f_ucs/context/report_context';
import {mergeActivityReports} from '@f_ucs/utils/mergeActivityReports';
import {getReportActivity} from '@f_ucs/utils/getReportActivity';
import {useCollector} from '@f_ucs/context/collector_context';
import {mergeSusBuys} from '@f_ucs/utils/mergeSusBuys';
import {getSusBuys} from '@f_ucs/utils/getSusBuys';
import {Type1cUser, TypeWebUser} from '@f_ucs/context/doughnut_context';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Legend);

export function ActivityReportTable() {
    const {colors} = useContext(ThemeContext);

    const {width} = useWindowSize();
    const [mobile, setMobile] = useState(false);
    const [data, setData] = useState<object>({})
    useEffect(() => {
        setMobile(width < 700);
    }, [width]);
    const {timeCutReports, exportExcelHandler} = useReport();
    const {currentType} = useCollector();

    const items = mergeActivityReports(getReportActivity(timeCutReports), currentType);
    const itemsViolationArray = mergeSusBuys(getSusBuys(timeCutReports), currentType);

    const mergedItems = items.map(item => {
        let userKeyOrId: string | number;
        if (currentType === 'web') {
            const user = item.user as TypeWebUser;
            userKeyOrId = user.userId;
        }
        if (currentType === '1c') {
            const user = item.user as Type1cUser;
            userKeyOrId = user.key;
        }

        // Находим количество нарушений для данного пользователя
        const violation = itemsViolationArray.find(violationItem =>
            (currentType === 'web' && violationItem.user === userKeyOrId) ||
            (currentType === '1c' && violationItem.user === userKeyOrId),
        );

        // Возвращаем новый объект с добавленным полем count
        return {
            ...item,
            violations: violation ? violation.count : 0,
        };
    });

    const normalizeData = (data: any[]) => {
        return data.map((item) => {

            const newItem = {
                ...item,
                ...item.user,
            };


            delete newItem.user;

            if (item.user?.firstName && item.user?.lastName) {
                newItem.employee = `${item.user.firstName} ${item.user.lastName}`;
            } else if (item.user?.alias) {
                newItem.employee = `${item.user.alias}`;
            } else if (item.user?.key) {
                newItem.employee = `${item.user.key}`;
            } else {
                newItem.employee = `Неизвестный пользователь`;
            }

            return newItem;
        });
    };

    const sortedAndMergedItems = mergedItems.sort((a, b) => b.violations - a.violations);
    useEffect(() => {
        console.log(sortedAndMergedItems,`sss`)
        if (sortedAndMergedItems) setData(normalizeData(sortedAndMergedItems))
    }, [sortedAndMergedItems]);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'User',
                accessorKey: 'employee',
                header: `Сотрудник`,
                cell: (info) => {
                    return <div className={stls.cellUser}>{info.row.original.employee}</div>;
                },
                size: 50,
                footer: (props) => props.column.id,
            },
            {
                id: 'Searches',
                accessorKey: 'searches',
                header: `Запросы/поиски`,
                cell: (info) =>
                    <div className={stls.cellInfo} style={{color: colors.alpha, fontWeight: 'bold'}}>
                        {info.row.original.searches}
                    </div>,
                sortingFn: (a: any, b: any) => b.original.searches - a.original.searches,
                size: 50,
                footer: (props) => props.column.id,
            },
            {
                id: 'AddBasket',
                accessorKey: 'addBasket',
                header: `Добавления в корзину`,
                cell: (info) => (
                    <div className={stls.cellInfo} style={{color: colors.alpha, fontWeight: 'bold'}}>
                        {info.row.original.addBasket}
                    </div>
                ),
                sortingFn: (a: any, b: any) => b.original.addBasket - a.original.addBasket,
                size: 70,
                footer: (props) => props.column.id,
            },
            {
                id: 'Violations',
                accessorKey: 'violations',
                header: `Количество нарушений`,
                cell: (info) => {
                    return <div className={stls.cellInfo}>{info.row.original.violations}</div>;
                },
                sortingFn: (a: any, b: any) => b.original.violations - a.original.violations,
                size: 70,
                footer: (props) => props.column.id,
            },
            {
                id: 'Orders',
                accessorKey: 'orders',
                header: `Заказы`,
                cell: (info) => (
                    <div className={stls.cellInfo} style={{color: colors.alpha, fontWeight: 'bold'}}>
                        {info.row.original.orders}
                    </div>
                ),
                sortingFn: (a: any, b: any) => b.original.orders - a.original.orders,
                size: 50,
                footer: (props) => props.column.id,
            },
            // {
            //     id: 'Activity',
            //     accessorKey: 'Activity',
            //     header: `График активности`,
            //     cell: (info) => {
            //         const { user, orders, violations, addBasket } = info.row.original;
            //         const total = addBasket + violations + orders;
            //         const percentages = [addBasket, violations, orders].map(v => Math.round((v / total) * 100));
            //         percentages[3] += 100 - percentages.reduce((sum, v) => sum + v, 0);
            //         let labelName;
            //         if (currentType === 'web') {
            //             labelName = `${user.firstName}${user.lastName ? ' ' + user.lastName : ''}`;
            //         } else if (currentType === '1c') {
            //             labelName = user.alias || user.key;
            //         }
            //
            //         const chartData = {
            //             labels: [labelName],
            //             datasets: ['#6EBA6E', '#DC2626', '#F08E13'].map((color, i) => ({
            //                 label: [
            //                     'Добавления в корзину',
            //                     'Нарушения',
            //                     'Заказы',
            //                 ][i],
            //                 data: [percentages[i]],
            //                 backgroundColor: color,
            //                 // borderRadius: {
            //                 //     topLeft: 5,
            //                 //     topRight: 5,
            //                 //     bottomLeft: 5,
            //                 //     bottomRight: 5,
            //                 // },
            //                 barThickness: 20,
            //                 // inflateAmount: 4,
            //                 // minBarLength:0,
            //                 // borderSkipped: false,
            //             })),
            //         };
            //         return (
            //             <div className={stls.cellData}>
            //                 <Bar options={options} data={chartData} />
            //             </div>
            //         );
            //     },
            //     size: 100,
            // },
        ],
        [data],
    );

    //стили таблицы
    const tableStyles = {
        head: {
            tableHead: {
                display: 'flex',
                justifyContent: 'start',
            },
            container: {
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
                flexDirection: 'column'
            },
            base: true,
            title: {
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '33px',
                maxHeight: '35px',
                borderRight: '0.5px solid rgba(71, 85, 105, 1)',
                fontSize: '16px',

            },

            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    // стили для графика
    // const options: any = {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     indexAxis: 'y',
    //
    //     borderSkipped: false,
    //     scales: {
    //         y: {
    //             stacked: true,
    //             display: false,
    //             grid: {
    //                 display: false,
    //             },
    //             offset: true,
    //
    //         },
    //         x: {
    //             // borderSkipped: false,
    //             stacked: true,
    //             display: false,
    //             grid: {
    //                 display: false,
    //             },
    //             offset: true,
    //         },
    //     },
    //     plugins: {
    //         legend: {
    //             display: false,
    //         },
    //         datalabels: {
    //             display: false,
    //         },
    //         tooltip: {
    //             label: false,
    //             callbacks: {
    //                 label: (context: any) => {
    //                     const label = context.dataset.label || '';
    //                     const value = context.raw as number;
    //                     return `${label}: ${value}%`;
    //                 },
    //                 title: () => '',
    //             },
    //         },
    //     },
    //     animation: {
    //         duration: 1400,
    //         easing: 'easeOutBounce',
    //     },
    //     devicePixelRatio: 3,
    // };

    return (
        <div className={stls.container} style={{background: colors.theta, marginBottom: '50px'}}>
            <VirtualTable
                data={data}
                columnsTable={columns}
                topFilterPanel={true}
                showColumnSearchAction={true}
                configHeader={{
                    fullScrean: true,
                    exportExcel: true,
                    resize: true,
                    grouping: false,
                    columnFilter: false,
                    sorting: true,
                    columnSearch: true,
                    dragging: false,
                }}
                styles={tableStyles}
                overscan={2}
                exportExcelHandler={() => exportExcelHandler('activityEmployees')}

                estimateSize={mobile ? 191 : 39}
            />
        </div>
    );
}
