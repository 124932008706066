import Card from '@mui/material/Card';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Stack } from '@mui/system';
import { Avatar, Box } from '@mui/material';
import stls from '@f_styles/components/settings/Settings.module.sass';
import stl from '@f_styles/general/Universal.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import cn from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { IconExit } from '@f_components/icons/IconExit';
import { UserContext } from '@f_context/User_context';
import { AuthContext } from '@f_context/Auth_context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconKey } from '@f_components/icons/IconKey';
import { IconEmail } from '@f_components/icons/IconEmail';
import { IconPhone } from '@f_components/icons/IconPhone';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { FormikController } from '@f_general/formik/FormikController';
import ProfileModal from './ProfileModal';
import {
    ChangeEmailUserProfileCustomerSchema,
    ChangePasswordUserProfileCustomerSchema,
    ChangePhoneUserProfileCustomerSchema,
    UpdateUserProfileCustomerSchema,
} from '@f_general/formik/helpers/ValidationSchema';
import { useFormikContext } from 'formik';
import { Alert } from '@f_general/alert';
import { TypeChangeModalData, TypeProfileModalData, TypeUserProfile } from '@f_types';
import { UserRoleChip } from '@f_general/UserRoleChip';

type TypeFormObserverProps = {
    data: TypeUserProfile;
    revertData: boolean;
    setRevertData: (value: boolean) => void;
    checkChange: (value: boolean) => void;
};

const FormObserver = ({ data, checkChange, revertData, setRevertData }: TypeFormObserverProps) => {
    const { values, setValues } = useFormikContext<TypeUserProfile>();

    useEffect(() => {
        if (
            values.firstName !== data.firstName ||
            values.lastName !== data.lastName ||
            values.position !== data.position
        ) {
            checkChange(true);
        } else {
            checkChange(false);
        }
    }, [values]);

    useEffect(() => {
        if (revertData) {
            setValues({
                firstName: data.firstName,
                lastName: data.lastName,
                position: data.position,
            });
            setRevertData(false);
        }
    }, [revertData]);
    return null;
};

export function Profile() {
    const { userInfo, updateUser, userRole } = useContext(UserContext);
    const navigate = useNavigate();
    const { colors } = useContext(ThemeContext);
    const { logout, setAccountInfo, setCheckAuth } = useContext(AuthContext);
    const { t } = useTranslation();
    const [dataChange, setDataChange] = useState<TypeProfileModalData>({});
    const [open, setOpen] = useState(false);

    const [changeFields, setChangeFields] = useState(false);
    const [revertData, setRevertData] = useState(false);

    const logoutHandler = async () => {
        const result = await logout();
        if (result.data.isOk) {
            navigate('/');
        }
    };

    let data: TypeUserProfile = {
        firstName: userInfo.firstName ?? '',
        lastName: userInfo.lastName ?? '',
        position: userInfo.position ?? '',
    };

    const handleChange = (click: string) => {
        switch (click) {
            case 'pass':
                setDataChange({
                    icon: <IconKey />,
                    title: 'Смена пароля',
                    name: 'пароль',
                    type: 'password',
                    shema: ChangePasswordUserProfileCustomerSchema,
                });
                setOpen(true);
                break;
            case 'email':
                setDataChange({
                    icon: <IconEmail />,
                    title: 'Смена E-mail',
                    name: 'E-mail',
                    type: 'email',
                    shema: ChangeEmailUserProfileCustomerSchema,
                });
                setOpen(true);
                break;
            case 'phone':
                setDataChange({
                    icon: <IconPhone />,
                    title: 'Смена номера телефона',
                    name: 'номер телефона',
                    type: 'tel',
                    shema: ChangePhoneUserProfileCustomerSchema,
                });
                setOpen(true);
                break;
        }
    };

    const sendDataHandler = async (values: TypeUserProfile) => {
        if (revertData) {
            return;
        }
        const result = await updateUser(values);
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            setChangeFields(false);
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const cancelHandler = () => {
        setRevertData(true);
    };

    const handleModalClose = () => {
        setOpen(false);
        setDataChange({});
    };

    const uploadPhoto = (e: any) => {
        const files = e.target.files;
        const file = files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const result = await updateUser({ photo: reader.result });
            if (result.isOk) {
                Alert({
                    type: 'success',
                    text: t('general.success'),
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('settings.messageChangeAvatarError'),
                    time: 8000,
                });
            }
        };
    };

    const deletePhotoHandler = async () => {
        await updateUser({ photo: '' });
    };

    const sendDataModalHandler = async (values: TypeChangeModalData) => {
        switch (dataChange.type) {
            case 'password':
                if (values.new_password !== values.repeatNew_password) {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                const resultPassword = await updateUser({
                    currentPassword: values.password,
                    newPassword: values.new_password,
                });
                if (resultPassword.isOk) {
                    handleModalClose();
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    setCheckAuth(false);
                    // setAccountInfo({})
                    await logout();
                    navigate('/');
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'email':
                const resultEmail = await updateUser({
                    currentEmail: values.email,
                    newEmail: values.new_email,
                });
                if (resultEmail.isOk) {
                    handleModalClose();
                    Alert({
                        type: 'success',
                        text: t('settings.messageChangeEmailSuccess'),
                        time: 8000,
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'tel':
                const resultPhone = await updateUser({
                    currentPhoneNumber: values.tel,
                    newPhoneNumber: values.new_tel,
                });
                if (resultPhone.isOk) {
                    handleModalClose();
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    return (
        <>
            {data.firstName && (
                <Card className={stls.gridItem}>
                    <Box>
                        <div className={stls.header}>
                            <Typography variant="h6" className={stls.title}>
                                {t('settings.profile')}: {userInfo?.firstName} {userInfo?.lastName}
                            </Typography>

                            <button className={stls.profileExit} onClick={logoutHandler}>
                                {' '}
                                <Typography
                                    variant="body2"
                                    className={cn(stls.buttonExit, stl.alphaTypography)}
                                    style={{ color: colors.alpha }}>
                                    {t('settings.logout')}
                                </Typography>
                                <IconExit />
                            </button>
                        </div>
                        <div className={stl.flexAlignCenter}>
                            <Stack direction="row" spacing={2}>
                                <Avatar
                                    alt={userInfo?.firstName}
                                    src={userInfo?.photo ? userInfo?.photo : '/static/images/avatar/1.jpg'}
                                    className={stls.avatar}
                                />
                            </Stack>

                            <div className={cn(stl.flexColumn, stls.actions)}>
                                <label className={stls.itemBtn}>
                                    <input
                                        className={stls.inputFile}
                                        onChange={(e) => uploadPhoto(e)}
                                        type="file"
                                        name="avatar"
                                    />
                                    <AddCircleOutlinedIcon className={stls.iconPhoto} style={{ color: colors.gamma }} />
                                    <Typography
                                        variant="body2"
                                        className={cn(stls.buttonProfile, stl.alphaTypography)}
                                        style={{ color: colors.alpha }}>
                                        {t('settings.uploadPhoto')}
                                    </Typography>
                                    <UserRoleChip classNames={[stls.roleBox]} sysName={userRole} />
                                </label>

                                {userInfo?.photo && (
                                    <button className={stls.itemBtn} onClick={deletePhotoHandler}>
                                        <CancelIcon className={stls.icon} style={{ color: colors.lambda }} />
                                        <Typography
                                            variant="body2"
                                            className={cn(stls.buttonProfile, stl.alphaTypography)}>
                                            {t('settings.deletePhoto')}
                                        </Typography>
                                    </button>
                                )}
                            </div>
                        </div>
                    </Box>
                    <Box>
                        <div className={stls.formSetting}>
                            <FormikWrapper
                                onSubmit={sendDataHandler}
                                initialValues={data}
                                validShema={UpdateUserProfileCustomerSchema}>
                                <FormObserver
                                    data={data}
                                    checkChange={setChangeFields}
                                    setRevertData={setRevertData}
                                    revertData={revertData}
                                />
                                <div className={stls.form}>
                                    <FormikController
                                        className={stls.inputSetting}
                                        control="input"
                                        type="text"
                                        name="firstName"
                                        inputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                    <FormikController
                                        className={stls.inputSetting}
                                        control="input"
                                        type="text"
                                        name="lastName"
                                        inputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                    <FormikController
                                        className={stls.inputSetting}
                                        control="input"
                                        type="text"
                                        name="position"
                                        inputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                    {changeFields && (
                                        <div className={stls.actions}>
                                            <button className={stls.itemBtn}>
                                                <AddCircleOutlinedIcon
                                                    className={stls.icon}
                                                    style={{ color: colors.gamma }}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    className={cn(stls.buttonProfile, stl.alphaTypography)}>
                                                    {t('settings.save')}
                                                </Typography>
                                            </button>

                                            <button className={stls.itemBtn} onClick={cancelHandler}>
                                                <CancelIcon className={stls.icon} style={{ color: colors.lambda }} />
                                                <Typography
                                                    variant="body2"
                                                    className={cn(stls.buttonProfile, stl.alphaTypography)}>
                                                    {t('settings.cancel')}
                                                </Typography>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </FormikWrapper>
                        </div>
                        <button className={cn(stls.itemBtn, stls.btn)} onClick={() => handleChange('pass')}>
                            <LockOutlinedIcon fontSize="small" className={stls.icon} />
                            <Typography
                                variant="body2"
                                className={cn(stls.buttonProfile, stl.alphaTypography)}
                                style={{ color: colors.alpha }}>
                                {t('settings.changePassword')}
                            </Typography>
                        </button>

                        <button className={cn(stls.itemBtn, stls.btn)} onClick={() => handleChange('email')}>
                            <EmailOutlinedIcon fontSize="small" className={stls.icon} />
                            <Typography
                                variant="body2"
                                className={cn(stls.buttonProfile, stl.alphaTypography)}
                                style={{ color: colors.alpha }}>
                                {t('settings.changeEmail')}
                            </Typography>
                        </button>

                        <button className={cn(stls.itemBtn, stls.btn)} onClick={() => handleChange('phone')}>
                            <LocalPhoneOutlinedIcon fontSize="small" className={stls.icon} />
                            <Typography
                                variant="body2"
                                className={cn(stls.buttonProfile, stl.alphaTypography)}
                                style={{ color: colors.alpha }}>
                                {t('settings.changePhone')}
                            </Typography>
                        </button>
                    </Box>

                    <ProfileModal
                        dataChange={dataChange}
                        setDataChange={setDataChange}
                        setOpen={setOpen}
                        open={open}
                        sendDataModalHandler={sendDataModalHandler}
                        handleModalClose={handleModalClose}
                    />
                </Card>
            )}
        </>
    );
}
