import React, { FC } from 'react';
import styles from '@f_qwep/styles/component/inputSearch/InputSearch.module.sass';
import { Box, Chip, ListItemButton, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import dayjs from 'dayjs';
import { CopyButton } from '@f_general/button/CopyButton';
import { TFunction } from 'i18next';

interface IHistorySearchListItemProps {
    item: any;
    startSearch: (item: any) => Promise<void>;
    colors: any;
    disabled: boolean;
    t: TFunction<'translation', undefined>;
}

export const HistorySearchListItem: FC<IHistorySearchListItemProps> = ({ item, startSearch, colors, disabled, t }) => {
    return (
        <div className={styles.listItemWrapper}>
            <ListItemButton
                className={styles.listItemHistory}
                disabled={disabled}
                key={`${uniqueId()}`}
                onClick={(e) => {
                    startSearch(item);
                }}
                title={`${dayjs(item.dateTime).format('DD.MM.YY HH:mm')} – ${item.article} – ${item.brand} – ${
                    item.partname
                }`}>
                <Chip
                    sx={{ color: colors.omicron }}
                    className={styles.chipTime}
                    size="small"
                    label={dayjs(item.dateTime).format('HH:mm')}
                    variant="outlined"
                />
                <Box pr={1}>
                    <Typography variant="body2" noWrap width={200}>
                        {`${item.article} – ${item.brand}`}
                    </Typography>
                </Box>
            </ListItemButton>
            <CopyButton
                stylesWrapper={styles.copyIconWrapper}
                textCopy={`${item.article} – ${item.brand}`}
                t={t}
                copyObjectTitle="артикул и бренд"
                iconSize={'small'}
            />
        </div>
    );
};
