import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconWebProps = {
    color?: string;
} & TypeClassNames;

export const IconWeb = ({ classNames, color }: TypeIconWebProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.68132 19.7628L9.89602 19.8271L9.80045 19.6244C8.81938 17.5434 8.25103 15.2919 8.12685 12.9946L8.12174 12.9H8.027H4.062H3.94856L3.96279 13.0125C4.15981 14.5707 4.8051 16.038 5.82027 17.2363C6.83543 18.4347 8.17678 19.3124 9.68132 19.7628ZM10.03 12.9H9.92362L9.93019 13.0062C10.0821 15.4604 10.7835 17.7658 11.9126 19.8005L12 19.9581L12.0874 19.8005C13.247 17.712 13.9243 15.3905 14.0698 13.0061L14.0763 12.9H13.97H10.03ZM20.0372 13.0125L20.0514 12.9H19.938H15.973H15.8783L15.8731 12.9946C15.749 15.2919 15.1806 17.5434 14.1995 19.6244L14.104 19.8271L14.3187 19.7628C15.8232 19.3124 17.1646 18.4347 18.1797 17.2363C19.1949 16.038 19.8402 14.5707 20.0372 13.0125ZM3.96279 10.9875L3.94856 11.1H4.062H8.027H8.12174L8.12685 11.0054C8.25103 8.70809 8.81938 6.45664 9.80045 4.37564L9.89602 4.17292L9.68132 4.2372C8.17678 4.68764 6.83543 5.56533 5.82027 6.76366C4.8051 7.96198 4.15981 9.42934 3.96279 10.9875ZM13.969 11.1H14.0753L14.0688 10.9939C13.9237 8.60962 13.2466 6.28805 12.0874 4.19947L12 4.04196L11.9126 4.19946C10.753 6.28796 10.0757 8.60953 9.93019 10.9939L9.92371 11.1H10.03H10.031H13.969ZM14.3187 4.2372L14.104 4.17292L14.1995 4.37564C15.1806 6.45664 15.749 8.70809 15.8731 11.0054L15.8783 11.1H15.973H19.938H20.0514L20.0372 10.9875C19.8402 9.42934 19.1949 7.96198 18.1797 6.76366C17.1646 5.56533 15.8232 4.68764 14.3187 4.2372ZM12 21.9C6.53223 21.9 2.1 17.4678 2.1 12C2.1 6.53223 6.53223 2.1 12 2.1C17.4678 2.1 21.9 6.53223 21.9 12C21.9 17.4678 17.4678 21.9 12 21.9Z"
                    fill={color ? color : colors.tau}
                    stroke="#3B82F6"
                    stroke-width="0.2"
                />
            </svg>
        </div>
    );
};
