import { useState, useEffect } from 'react';
import stls from '@f_qwep/styles/component/tables/searchResultTable/TooltipStyle.module.sass';
import { useTranslation } from 'react-i18next';
import { IconStatusModal } from '@f_qwep/components/icons/IconStatusModal';
import { useWindowSize } from '@f_hooks';

const Tooltip = (props: any) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    const showTip = () => {
        setActive(true);
    };

    const hideTip = () => {
        setActive(false);
    };
    const roundProbability: number = Math.round(Number(props.info.row.original.info.supplyPercent));
    const countFails: number = Math.round(100 - roundProbability);

    return (
        <div className={stls.tooltipAndButton} onMouseEnter={showTip} onMouseLeave={hideTip}>
            {active && (
                <div
                    className={mobile ? stls.tooltipMob : props.numberCell < 4 ? stls.tooltipDown : stls.tooltipUp}
                    style={{ background: props.colorBackground }}>
                    <h5 className={stls.title} style={{ background: props.colorBackground }}>
                        {t('qwep.search.chanceDelivery.title')}
                    </h5>
                    <div className={stls.iconDescription}>
                        <IconStatusModal
                            classNames={[stls.icon]}
                            colorGreen={props.colorGreen}
                            colorRed={props.colorRed}
                            probability={roundProbability}></IconStatusModal>
                        <div className={stls.description}>
                            <div className={stls.success}>
                                <div style={{ backgroundColor: props.colorGreen }} className={stls.circleSuccess}></div>
                                <div className={stls.text}>
                                    {t('qwep.search.chanceDelivery.successChance')}{' '}
                                    {props.info.row.original.info.supplyPercent} %
                                </div>
                            </div>
                            <div className={stls.fail}>
                                <div style={{ backgroundColor: props.colorRed }} className={stls.circleFail}></div>
                                <div className={stls.text}>
                                    {t('qwep.search.chanceDelivery.failChance')} {countFails} %
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={stls.text}>
                        {t('qwep.search.chanceDelivery.expectedDelivery')} {props.info.row.original.delivery}
                    </div>
                    {props.content}
                </div>
            )}
            {props.children}
        </div>
    );
};

export default Tooltip;
