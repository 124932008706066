import { IconBasket } from '../icons/IconBasket';
import { InputSearch } from './InputSerarch';
import stls from '@f_qwep/styles/component/inputSearch/InputSearch.module.sass';
import { Group } from '../group/IndexGroup';
import { useContext, useEffect, useState } from 'react';
import { BasketContext } from '../../context/Basket_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';
import { Tariff } from '@f_qwep/types';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import { Alert } from '@f_general/alert';

export function Search() {
    const navigate = useQwepNavigate();
    const { activeApp, tariff } = useContext(QwepContext);
    const { basketSum } = useContext(BasketContext);
    const { colors } = useContext(ThemeContext);
    const [access, setAccess] = useState(false);

    useEffect(() => {
        setAccess([Tariff.UNLIMIT, Tariff.PRO, Tariff.PROPlus, Tariff.Business].some((t: string) => t === tariff));
    }, [tariff]);

    return (
        <>
            {activeApp ? (
                <div className={stls.container}>
                    <div className={stls.content}>
                        <div className={stls.search}>
                            {access && <Group />}
                            <InputSearch />
                            {access ? (
                                <button id="basket" className={stls.basket} onClick={() => navigate('basket')}>
                                    <IconBasket height="24" width="24" />
                                    {basketSum > 0 && (
                                        <div
                                            style={{
                                                background: colors.pi,
                                                color: colors.gamma,
                                                border: `1px solid ${colors.gamma}`,
                                            }}
                                            className={stls.basketTotal}>
                                            {(+basketSum.toFixed(2)).toLocaleString()} ₽
                                        </div>
                                    )}
                                </button>
                            ) : (
                                <button
                                    className={stls.basket}
                                    onClick={() =>
                                        Alert({
                                            type: 'info',
                                            text: 'Этот функционал недоступен на вашем тарифе',
                                        })
                                    }>
                                    <IconBasket height="24" width="24" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
