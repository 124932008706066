import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconUpdatePriceProps = TypeClassNames;

export const IconUpdatePrice = ({ classNames }: TypeIconUpdatePriceProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                <g clipPath="url(#clip0_1481_6573)">
                    <path d="M8.91994 8.14563C10.2862 6.96175 12.0341 6.31121 13.8419 6.31373C18.0004 6.31373 21.3713 9.68465 21.3713 13.8431C21.3713 15.4514 20.8669 16.9422 20.0085 18.165L17.6066 13.8431H19.8654C19.8655 12.6622 19.5185 11.5074 18.8675 10.5221C18.2166 9.53685 17.2903 8.76467 16.204 8.30159C15.1177 7.8385 13.9192 7.70494 12.7576 7.91751C11.596 8.13007 10.5225 8.67939 9.67062 9.49716L8.91994 8.14563ZM18.7639 19.5406C17.3976 20.7245 15.6497 21.3751 13.8419 21.3725C9.68342 21.3725 6.3125 18.0016 6.3125 13.8431C6.3125 12.2349 6.81697 10.744 7.67532 9.52126L10.0772 13.8431H7.81838C7.81829 15.024 8.1653 16.1789 8.81628 17.1642C9.46726 18.1494 10.3935 18.9216 11.4798 19.3847C12.5661 19.8478 13.7646 19.9813 14.9262 19.7688C16.0878 19.5562 17.1613 19.0069 18.0132 18.1891L18.7639 19.5406Z" />
                </g>
                <defs>
                    <clipPath id="clip0_1481_6573">
                        <rect width="16" height="16" fill="white" transform="translate(6 6)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
