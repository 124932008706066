import { useContext, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { isEmpty, uniqueId } from 'lodash';
import { Box, Chip, Divider, List, Popover, Typography } from '@mui/material';
import stls from '@f_qwep/styles/component/inputSearch/InputSearch.module.sass';
import { IconMenu } from '../icons/IconMenu';
import { SearchContext } from '../../context/Search_context';
import { Alert } from '@f_general/alert';
import { ThemeContext } from '@f_context/Theme_context';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import { HistorySearchListItem } from '@f_qwep/components/search/HistorySearchListItem';
import { useTranslation } from 'react-i18next';

const HistorySearch = () => {
    const { preSearchHistory, searchResults, getSearchId } = useContext(SearchContext);
    const { colors, currentTheme } = useContext(ThemeContext);
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const anchorRef = useRef(null);

    const navigate = useQwepNavigate();

    const id = open ? 'history-menu' : undefined;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => {
        setOpen(false);
        setDisabled(false);
    };

    const startSearch = async (item: any) => {
        setDisabled(true);
        const brand = item.brand;
        const article = item.article;
        const resultSearchId = await getSearchId({
            brand,
            article,
        });
        if (resultSearchId?.result?.entity?.searchId) {
            navigate('search');
            await searchResults(resultSearchId.tabId, resultSearchId.result.entity.searchId, item.article, item.brand);
        } else if (resultSearchId?.status === 'not vendors') {
            Alert({
                type: 'warning',
                text: 'Нет активных поставщиков!',
                time: 8000,
            });
        } else {
            Alert({
                type: 'warning',
                text: resultSearchId?.result?.errors[0]?.message,
            });
        }

        setDisabled(false);
        handleClose();
    };

    const getFiltredHistoryByDate = (historyItems: any, date: any) =>
        historyItems.filter((item: any) => dayjs(item.dateTime).format('DD.MM.YY') === date);

    const renderHistoryItems = (historyItems: any) => {
        if (isEmpty(historyItems)) {
            return (
                <Typography variant="body2" className={stls.contentHistory}>
                    История запросов пуста
                </Typography>
            );
        }

        const allDates = preSearchHistory.reduce((acc: any, item: any) => {
            const currentDate = dayjs(item.dateTime).format('DD.MM.YY');
            if (acc.includes(currentDate)) return acc;
            return [...acc, currentDate];
        }, []);

        return (
            <div className={stls.contentHistory}>
                {allDates.map((date: any) => (
                    <li key={`section-${uniqueId()}`}>
                        <ul>
                            <Divider>
                                <Box>
                                    <Chip className={stls.chipDate} size="small" label={date} />
                                </Box>
                            </Divider>
                            {getFiltredHistoryByDate(historyItems, date).map((item: any, index: number) => (
                                <HistorySearchListItem
                                    key={index}
                                    item={item}
                                    startSearch={startSearch}
                                    colors={colors}
                                    disabled={disabled}
                                    t={t}
                                />
                            ))}
                        </ul>
                    </li>
                ))}
            </div>
        );
    };

    return (
        <>
            <button
                className={stls.menuIcon}
                style={{ height: '100%' }}
                // disabled={!activeLicenses}
                aria-describedby={id}
                aria-controls={id}
                aria-haspopup="true"
                title="История запросов"
                onClick={handleToggle}
                ref={anchorRef}>
                <IconMenu
                    id="historySearch"
                    classNames={[stls.iconMenu]}
                    background={currentTheme === 'vinpin' ? colors.alpha : colors.iota}
                />
            </button>
            <Popover
                className={stls.popperHistory}
                id={id}
                sx={{
                    zIndex: 1000,
                    '& .MuiPopover-paper': {
                        borderRadius: '12px',
                    },
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <List className={stls.listHistory} aria-label="История запросов" subheader={<li />}>
                    {renderHistoryItems(preSearchHistory)}
                </List>
            </Popover>
        </>
    );
};

export default HistorySearch;
