import React from 'react';
import cn from 'classnames';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';

interface IAssistantPartNameColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    isTemplateItem: any;
    isTemplateItemText: string;
    partname: any;
    foundItem: any;
}

export const AssistantPartNameColumn = (props: IAssistantPartNameColumnProps) => {
    const { foundItem, colors, styles, partname, isTemplateItemText, isTemplateItem } = props;
    return (
        <p
            style={{ color: foundItem ? colors.omicron : colors.sigma }}
            className={cn(styles.cellRow, styles.indentLeft)}>
            {!isTemplateItem ? partname : isTemplateItemText}
        </p>
    );
};
