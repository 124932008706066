import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import { OrderChat } from '@f_qwep/components/orders/orderChat';
import { isObject } from 'lodash';
import { IconMessage } from '@f_qwep/components/icons/IconMessage';
import Tooltip from '@mui/material/Tooltip';

export function CellComment({ info }: any) {
    const { colors } = useContext(ThemeContext);
    if (info.row.original.isOrderManager) {
        return (
            <OrderChat
                commentOrder={info.row.original.commentOrder.comments}
                commentItem={info.row.original.commentItem.comments}
                itemOrderId={info.row.original.itemOrderId}
                orderId={info.row.original.orderId}
                type={'SELLER'}
            />
        );
    }
    if (isObject(info.row.original.commentOrder) && info.row.original.commentOrder?.isOMBuyer) {
        return (
            <OrderChat
                commentOrder={info.row.original.commentOrder.comments}
                commentItem={info.row.original.commentItem.comments}
                itemOrderId={info.row.original.commentItem.id}
                orderId={info.row.original.commentOrder.id}
                type={'BUYER'}
            />
        );
    }

        let check = info.row.original.comment || info.row.original.commentOrder

        return (
            <>
                {check ? (
                    <Tooltip title={`${info.row.original.comment ?? ''}\n${ info.row.original.commentOrder ?? ''}`}>
                        <div style={{ textAlign: 'center' }}>
                            <IconMessage color={colors.epsilon} />
                        </div>
                    </Tooltip>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <IconMessage color={colors.tau} />
                    </div>
                )}
            </>
        );

}
