import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import colors from '@f_styles/config/Colors.module.sass';
import stls from '@f_styles/components/auth/Authorization.module.sass';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import cn from 'classnames';
import { AuthContext } from '@f_context/Auth_context';
import { forgetPasswordUserCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { Alert } from '@f_general/alert';
import { useTranslation } from 'react-i18next';

type TypeData = {
    email: string;
};

type TypePasswordForgotProps = {
    setAlignment: (value: string) => void;
};
export function PasswordForgot({ setAlignment }: TypePasswordForgotProps) {
    const { t } = useTranslation();
    const { forgotPassword } = useContext(AuthContext);
    const data: TypeData = {
        email: '',
    };
    const [isLoading, setIsLoadind] = useState(false);

    const sendDataHandler = async (values: TypeData) => {
        setIsLoadind(true);
        const result = await forgotPassword(values);
        if (result.data.isOk) {
            setAlignment('auth');
            Alert({
                type: 'success',
                text: t('auth.alert.letterSentMail'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('auth.alert.emailNotSent'),
            });
        }
        setIsLoadind(false);
    };

    return (
        <Box className={stls.auth}>
            <FormikWrapper
                onSubmit={sendDataHandler}
                initialValues={data}
                validShema={forgetPasswordUserCustomerSchema}>
                <Typography className={stls.authTypography}>{t('auth.email')}</Typography>
                <FormikController
                    className={stls.inputAuth}
                    control="input"
                    type="email"
                    placeholder={t('auth.placeholderEmail')}
                    name="email"
                    required
                />
                <Button type="submit" variant="contained" className={cn(stls.inputAuth, stls.btn)} disabled={isLoading}>
                    {isLoading && (
                        <CircularProgress style={{ color: colors.psi }} size={'20px'} className={stls.loading} />
                    )}{' '}
                    {t('auth.resetPassword')}
                </Button>
            </FormikWrapper>
        </Box>
    );
}
