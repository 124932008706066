import { AssistantConfirmationColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantConfirmationColumn';
import { AssistantConfirmedPriceColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantConfirmedPriceColumn';
import { TFunction } from 'i18next';
import { ColumnDef } from '@tanstack/react-table';
import { AssistantBrandColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantBrandColumn';
import { AssistantArticleColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantArticleColumn';
import { AssistantPartNameColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantPartNameColumn';
import { AssistantVendorColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantVendorColumn';
import { AssistantAvailabilityColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantAvailabilityColumn';
import { AssistantWarehouseColumn } from '@f_qwep/components/purchasingAssistant/ui/table/columns/AssistantWarehouseColumn';

export interface IGetAssistantTableConfigArgs {
    t: TFunction<'translation', undefined>;
    colors: any;
    styles: any;
}

export const getAssistantTableConfig = (args: IGetAssistantTableConfigArgs): ColumnDef<any>[] => {
    const { t, colors, styles } = args;
    return [
        {
            id: 'Производитель',
            accessorKey: 'brand',
            header: `${t('qwep.search.brand')}`,
            cell: (info) => {
                const { foundItem, isPromo, brand } = info.row.original;
                const props = { foundItem, isPromo, brand, styles, colors };
                return <AssistantBrandColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 70,
        },
        {
            id: 'Артикул',
            accessorKey: 'article',
            header: `${t('qwep.search.article')}`,
            cell: (info) => {
                const { foundItem, article } = info.row.original;
                const props = { foundItem, article, styles, colors };
                return <AssistantArticleColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 70,
        },
        {
            id: 'Наименование',
            accessorKey: 'partname',
            header: `${t('qwep.search.partname')}`,
            cell: (info) => {
                const { foundItem, partname, isTemplateItem } = info.row.original;
                const props = {
                    foundItem,
                    partname,
                    styles,
                    colors,
                    isTemplateItem,
                    isTemplateItemText: t('qwep.assistant.table.isTemplateItem'),
                };
                return <AssistantPartNameColumn {...props} />;
            },
            footer: (props) => props.column.id,
            minSize: 170,
        },
        {
            id: 'Поставщик',
            accessorKey: 'vendor',
            header: `${t('qwep.search.vendor')}`,
            cell: (info) => {
                const { isTemplateItem, foundItem, vendor } = info.row.original;
                const props = {
                    foundItem,
                    styles,
                    colors,
                    isTemplateItem,
                    vendor,
                };
                return <AssistantVendorColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 70,
        },
        {
            id: 'Подтвержденная цена',
            accessorKey: 'confirmedPrice',
            header: `${t('qwep.search.confirmedPrice')}`,
            cell: (info) => (
                <AssistantConfirmedPriceColumn
                    bestPrice={info.row.original.bestPrice}
                    authVendor={Boolean(+info.row.original.vendorAuthorized)}
                    confirmedPrice={info.row.original.confirmedPrice}
                    foundItem={info.row.original.foundItem}
                    isTemplateItem={info.row.original.isTemplateItem}
                />
            ),
            footer: (props) => props.column.id,
            maxSize: 70,
            sortingFn: (a: any, b: any) => b.original.bestPrice - a.original.bestPrice,
        },
        {
            id: 'Наличие',
            accessorKey: 'availability',
            header: `${t('qwep.search.availability')}`,
            cell: (info) => {
                const { isTemplateItem, foundItem, availability } = info.row.original;
                const props = {
                    foundItem,
                    styles,
                    colors,
                    isTemplateItem,
                    availability,
                };

                return <AssistantAvailabilityColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 30,
        },
        {
            id: 'Склад',
            accessorKey: 'warehouse',
            header: `${t('qwep.search.warehouse')}`,
            cell: (info) => {
                const { isTemplateItem, warehouse, foundItem } = info.row.original;
                const props = {
                    warehouse,
                    styles,
                    colors,
                    isTemplateItem,
                    foundItem,
                };

                return <AssistantWarehouseColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 70,
        },
        {
            id: 'Подтверждение',
            accessorKey: 'confirmation',
            header: `${t('qwep.search.confirmation')}`,
            cell: (info) => (
                <AssistantConfirmationColumn
                    article={info.row.original.article}
                    brand={info.row.original.brand}
                    confirmedVendor={info.row.original.confirmedVendor}
                    confirmedWarehouse={info.row.original.confirmedWarehouse}
                    confirmedAvailability={info.row.original.confirmedAvailability}
                    authVendor={Boolean(+info.row.original.vendorAuthorized)}
                    vendor={info.row.original.vendor}
                    confirmed={info.row.original.confirmed}
                    type={info.row.original.type}
                    foundItem={info.row.original.foundItem}
                    isTemplateItem={info.row.original.isTemplateItem}
                />
            ),
            footer: (props) => props.column.id,
            size: 80,
        },
    ];
};
