import { ThemeContext } from '@f_context/Theme_context';
import { colors } from '@mui/material';
import { useContext } from 'react';
import stls from '../../../styles/component/basket/Basket.module.sass';

export function CellQuantity({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.cellDiv}>
            <p style={{ background: colors.upsilon }} className={stls.cellBasketButton}>
                {info.row.original.basketItems?.length}
            </p>
        </div>
    );
}
