import stls from '@f_ucs/styles/components/header/HeaderComponent.module.sass';
import { WorkPeriod } from '@f_general/WorkPeriod';
import { useUCSContext } from '@f_ucs/context/UCS_context';

export function HeaderComponent() {
    const { isApplicationInfoLoading, appInfo } = useUCSContext();

    return (
        <div className={stls.container}>
            <div className={stls.item}>
                <WorkPeriod loading={isApplicationInfoLoading} workPeriod={appInfo?.paidTime} />
            </div>
        </div>
    )
}
