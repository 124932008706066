import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '@f_qwep/components/manual/GeneralJoyride';

export function EditUsersRoleManual() {
    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<any>([
        {
            target: '#editRoleUser',
            content: 'Нажмите, чтобы сменить роль пользователя',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
    ]);

    useEffect(() => {
        setRun(true);
    }, []);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
