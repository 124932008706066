import { useFilterLeftPanelStore } from '@f_qwep/components/filters/filterLeftPanel/context/FilterLeftStore.context';
import { FilterLeftPanelTemplateSubmit } from '@f_qwep/components/filters/filterLeftPanel/ui/template/current/FilterLeftPanelTemplateSubmit';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { FilterLeftPanelTemplatePopoverSave } from '@f_qwep/components/filters/filterLeftPanel/ui/template/current/FilterLeftPanelTemplatePopoverSave';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { SearchContext } from '@f_qwep/context/Search_context';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { FilterStatusEnum } from '@f_qwep/components/filters/filterLeftPanel/types/filterStatus.enum';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

export const FilterLeftPanelCurrentTemplate = observer(() => {
    const anchorRef = useRef<HTMLButtonElement>(null);
    const store = useFilterLeftPanelStore();
    const { filterType } = store.filterLeft;
    const { handleTemplateUpdate } = store.filterLeftTemplateUpdate;
    const { currentTemplateName, resetCurrentTemplateFilter, filterStatus, activeTemplateId } =
        store.filterLeftTemplate;
    const { modalStore, templateCancelSaving, inputControlStore, handleTemplateSave } =
        store.filterLeftPanelTemplateCreate;
    const { colors } = useContext(ThemeContext);
    const { workspaceApplication, updateProperty } = useContext(QwepContext);
    const { setFilter, filter } = useContext(SearchContext);
    const { searchFiltersReducer, batchPricerFiltersReducer } = useContext(FilterContext);
    const { filterBatchPricer, setFilterBatchPricer } = useContext(FilterContext);

    return activeTemplateId ? (
        <div className={styles.currentTemplateWrapper}>
            <div className={styles.currentTemplateContainer}>
                <h5>Текущий шаблон:</h5>
                <Tooltip placement="right-start" title="Сбросить текущий шаблон">
                    <button
                        onClick={() =>
                            resetCurrentTemplateFilter(
                                filterType === FilterTypesEnum.SEARCH_PRICER
                                    ? searchFiltersReducer
                                    : batchPricerFiltersReducer,
                            )
                        }>
                        <CancelIcon
                            sx={{
                                height: '18px',
                                width: 'auto',
                            }}
                        />
                    </button>
                </Tooltip>
            </div>
            <FilterLeftPanelTemplateSubmit
                disabled={filterStatus !== FilterStatusEnum.CHANGED_TEMPLATE}
                anchorRef={anchorRef}
                handleUpdate={handleTemplateUpdate}
                title={`${filterStatus === FilterStatusEnum.CHANGED_TEMPLATE ? 'Обновить ' : ''}${currentTemplateName}`}
                workspaceApplication={workspaceApplication}
                updateProperty={updateProperty}
                type={'update'}
                filter={filterType === FilterTypesEnum.SEARCH_PRICER ? filter : filterBatchPricer}
                filterType={filterType}
                setFilter={filterType === FilterTypesEnum.SEARCH_PRICER ? setFilter : setFilterBatchPricer}
            />
        </div>
    ) : (
        <>
            {filterStatus === FilterStatusEnum.CHANGED ? (
                <>
                    <FilterLeftPanelTemplateSubmit
                        anchorRef={anchorRef}
                        handleOpen={modalStore.handleOpen}
                        title={'Сохранить фильтр'}
                        workspaceApplication={workspaceApplication}
                        updateProperty={updateProperty}
                        type={'create'}
                        filter={filterType === FilterTypesEnum.SEARCH_PRICER ? filter : filterBatchPricer}
                        filterType={filterType}
                        setFilter={filterType === FilterTypesEnum.SEARCH_PRICER ? setFilter : setFilterBatchPricer}
                    />
                    <FilterLeftPanelTemplatePopoverSave
                        anchorRef={anchorRef}
                        isOpen={modalStore.isOpen}
                        templateCancelSaving={templateCancelSaving}
                        templateName={inputControlStore.input}
                        setTemplateName={inputControlStore.setInput}
                        colors={colors}
                        handleTemplateSave={handleTemplateSave}
                        workspaceApplication={workspaceApplication}
                        updateProperty={updateProperty}
                        setFilter={filterType === FilterTypesEnum.SEARCH_PRICER ? setFilter : setFilterBatchPricer}
                        filter={filterType === FilterTypesEnum.SEARCH_PRICER ? filter : filterBatchPricer}
                    />
                </>
            ) : null}
        </>
    );
});
