import { action, makeObservable, observable } from 'mobx';
import React from 'react';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

// TODO Часть логики можно вынести в один класс и через enum управлять записью значений

export class FilterLeftStore {
    minQuantity: number = 0;
    maxQuantity: number = 0;
    minShipmentDays: number = 0;
    maxShipmentDays: number = 0;
    minPrice: number = 0;
    maxPrice: number = 0;
    filterType: FilterTypesEnum = FilterTypesEnum.SEARCH_PRICER;

    constructor() {
        makeObservable(this, {
            filterType: observable,
            minQuantity: observable,
            maxQuantity: observable,
            minShipmentDays: observable,
            maxShipmentDays: observable,
            minPrice: observable,
            maxPrice: observable,
            setMinQuantity: action.bound,
            setMaxQuantity: action.bound,
            setMinPrice: action.bound,
            setMaxPrice: action.bound,
            setMinShipmentDays: action.bound,
            setMaxShipmentDays: action.bound,
            handleChangeQuantity: action.bound,
            handleChangeShipmentDays: action.bound,
            handleChangePrice: action.bound,
            setFilterType: action.bound,
        });
    }

    // Фильтр количества
    setMinQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        this.minQuantity = +event.target.value;
    }

    setMaxQuantity(event: React.ChangeEvent<HTMLInputElement>) {
        this.maxQuantity = +event.target.value;
    }

    handleChangeQuantity(value: number | number[]) {
        const [minQuantity, maxQuantity] = value as number[];
        this.minQuantity = minQuantity;
        this.maxQuantity = maxQuantity;
    }

    // Фильтр цены
    setMinPrice(event: React.ChangeEvent<HTMLInputElement>) {
        this.minPrice = +event.target.value;
    }

    setMaxPrice(event: React.ChangeEvent<HTMLInputElement>) {
        this.maxPrice = +event.target.value;
    }

    handleChangePrice(value: number | number[]) {
        const [minPrice, maxPrice] = value as number[];
        this.minPrice = minPrice;
        this.maxPrice = maxPrice;
    }

    // Фильтр доставки
    setMinShipmentDays(event: React.ChangeEvent<HTMLInputElement>) {
        this.minShipmentDays = +event.target.value;
    }

    setMaxShipmentDays(event: React.ChangeEvent<HTMLInputElement>) {
        this.maxShipmentDays = +event.target.value;
    }

    handleChangeShipmentDays(value: number | number[]) {
        const [minShipmentDays, maxShipmentDays] = value as number[];
        this.minShipmentDays = minShipmentDays;
        this.maxShipmentDays = maxShipmentDays;
    }

    //Для отслеживания в каком продукте находится фильтр
    setFilterType(type: FilterTypesEnum) {
        this.filterType = type;
    }
}
