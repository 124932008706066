import { IconDealerStock } from '../../icons/IconDealerStock';
import { Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import styles from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';

export function CellWarehouse({ info }: any) {
    const { colors } = useContext(ThemeContext);
    const isDealer = info.row.original.info?.isDealerWarehouse;
    const warehouse = info.row.original.warehouse;

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {isDealer ? (
                <div style={{ marginRight: '3px' }}>
                    <Tooltip title={'Официальный дилер'}>
                        <div>
                            <IconDealerStock classNames={[styles.iconDealerStock]} color={colors.green} />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            <Tooltip title={warehouse}>
                <p className={styles.cellWarehouse}>{warehouse}</p>
            </Tooltip>
        </div>
    );
}
