import { Grid, Box } from '@mui/material';
import { useContext } from 'react';
import { CellBrand } from './CellBrand';
import { CellTotal } from './CellTotal';
import { CellQuantity } from './CellQuantity';
import { BasketContext } from '../../../context/Basket_context';

export function CellMobile({ info, mobile }: any) {
    const { checkedItems, setCheckedItems, cartTimes } = useContext(BasketContext);

    return (
        <Box sx={{ width: '100%' }}>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={7}>
                    <CellBrand info={info} />
                </Grid>
                <Grid item xs={2}>
                    <CellQuantity info={info} />
                </Grid>
                <Grid item xs={3}>
                    <CellTotal
                        info={info}
                        mobile={mobile}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
