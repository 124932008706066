import React from 'react';
import cn from 'classnames';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';

interface IAssistantVendorColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    isTemplateItem: any;
    vendor: any;
    foundItem: any;
}

export const AssistantVendorColumn = (props: IAssistantVendorColumnProps) => {
    const { foundItem, colors, styles, vendor, isTemplateItem } = props;
    return (
        <p
            style={{
                color: foundItem ? colors.omicron : colors.sigma,
            }}
            className={cn(styles.cellRow)}>
            {vendor && !isTemplateItem ? vendor : ''}
        </p>
    );
};
