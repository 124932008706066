import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';

type TypeIconStatusTableProps = TypeClassNames & {
    color: string;
};

export const IconStatusTable = ({ classNames, color }: TypeIconStatusTableProps) => {
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.76085e-08 8.99039C-0.000208565 6.70473 0.867384 4.50442 2.42705 2.83513C3.98671 1.16585 6.12186 0.152378 8.4 0V4.82915C7.76673 4.92063 7.16269 5.15573 6.63402 5.51653C6.10535 5.87732 5.66601 6.35427 5.34953 6.91095C5.03306 7.46763 4.84783 8.08934 4.80797 8.72862C4.76811 9.3679 4.87468 10.0078 5.11954 10.5996C5.36441 11.1914 5.74109 11.7193 6.22085 12.1431C6.7006 12.5669 7.27074 12.8754 7.88774 13.045C8.50474 13.2146 9.15229 13.2408 9.78096 13.1217C10.4096 13.0025 11.0028 12.7412 11.5152 12.3576L14.9256 15.7716C13.6243 16.9112 12.0226 17.6514 10.3122 17.9037C8.60173 18.1561 6.85487 17.9099 5.28049 17.1946C3.70612 16.4793 2.37088 15.3252 1.43444 13.8702C0.498 12.4153 2.71242e-06 10.7212 3.76085e-08 8.99039Z"
                    fill={color}
                />
                <path
                    d="M15.7741 14.9223C17.2119 13.2817 18.0033 11.1729 18.0001 8.99039C18.0001 7.74827 17.7481 6.56621 17.2957 5.48986L12.9061 7.44074C13.1704 8.10844 13.2592 8.8328 13.1643 9.54467C13.0693 10.2566 12.7937 10.9322 12.3637 11.5071L15.7741 14.9223ZM9.6001 4.82795V0C11.0618 0.0974105 12.4776 0.550576 13.7246 1.32014C14.9716 2.0897 16.0121 3.15239 16.7557 4.41591L12.3049 6.39563C11.6386 5.54391 10.6697 4.98237 9.6001 4.82795Z"
                    fill={color}
                />
            </svg>
        </div>
    );
};
