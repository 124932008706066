import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { CellManufacturer } from './BasketTable/CellManufacturer';
import { CellArticle } from './BasketTable/CellArticle';
import { CellTitle } from './BasketTable/CellTitle';
import { CellPrice } from './BasketTable/CellPrice';
import { CellWarehouse } from './BasketTable/CellWarehouse';
import { CellTotal } from './BasketTable/CellTotal';
import { CellDelete } from './BasketTable/CellDelete';
import { useWindowSize } from '@f_hooks';
import { CellMobile } from './BasketTable/CellMobile';
import { CellQuantity } from './BasketTable/CellQuantity';
import { CellComment } from './BasketTable/CellComment';
import { BasketContext } from '../../context/Basket_context';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { inRange } from 'lodash';

export function Basket({ basket, accountId, basketId, basketForm, checkedItems, setCheckedItems, hasPartOrder }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const [mobile, setMobile] = useState(false);
    const { width } = useWindowSize();

    const { searchWord } = useContext(BasketContext);
    const { filterBasket } = useContext(FilterContext);
    const { priceMarginIsActive, priceMarginState } = useContext(QwepContext);

    const [basketTable, setBasketTable] = useState(basket);

    useEffect(() => {
        setBasketTable(basket);
    }, [basket]);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    useEffect(() => {
        setBasketTable(
            basket.filter((item: any) => {
                if (
                    item?.brand?.toLocaleLowerCase().includes(searchWord) ||
                    item?.article?.toLocaleLowerCase().includes(searchWord) ||
                    item?.warehouse?.toLocaleLowerCase().includes(searchWord) ||
                    item?.title?.toLocaleLowerCase().includes(searchWord)
                ) {
                    return item;
                }
            }),
        );
    }, [searchWord]);

    // Фильтруем результаты
    const getFiltredResults = () => {
        let result: any[] = [];

        if (basketTable) {
            result = basketTable;
        }

        // БРЕНДЫ
        if (filterBasket?.brands.length > 0) {
            result = result.filter((row) => filterBasket?.brands.includes(row.brand));
        }
        if (priceMarginIsActive) {
            const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

            const getPriceWithMargin = (price: any) => {
                const currentPrice = Math.ceil(parseFloat(price));
                const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule.to));
                if (currentRules?.length > 0) {
                    const rule = currentRules?.[0];
                    return currentPrice + (currentPrice / 100) * rule.addPercent;
                }
                return price;
            };

            result = result.map((row) => ({
                ...row,
                priceWithMargin: getPriceWithMargin(row.price),
            }));
        }

        return result;
    };

    const data = getFiltredResults() ?? [];

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          cell: (info) => (
                              <CellMobile
                                  info={info}
                                  accountId={accountId}
                                  basketId={basketId}
                                  basketForm={basketForm}
                                  hasPartOrder={hasPartOrder}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                              />
                          ),
                          footer: (props) => props.column.id,
                      },
                  ]
                : [
                      // {
                      //   id: 'date',
                      //   accessorKey: '',
                      //   cell: (info) => <CellDate info={info} accountId={accountId} basketId={basketId} checkedItems={checkedItems} setCheckedItems={setCheckedItems} hasPartOrder={hasPartOrder}/>,
                      //   footer: (props) => props.column.id,
                      //   size: 1,
                      // },
                      {
                          id: 'Производитель',
                          accessorKey: 'manufacturer',
                          cell: (info) => (
                              <CellManufacturer
                                  info={info}
                                  accountId={accountId}
                                  basketId={basketId}
                                  hasPartOrder={hasPartOrder}
                                  basketForm={basketForm}
                              />
                          ),
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'Артикул',
                          accessorKey: 'pin',
                          cell: (info) => <CellArticle info={info} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'Наименование',
                          accessorKey: 'description',
                          cell: (info) => <CellTitle info={info} />,
                          footer: (props) => props.column.id,
                          size: 180,
                      },
                      {
                          id: 'Количество',
                          accessorKey: 'quantity',
                          cell: (info) => <CellQuantity info={info} accountId={accountId} basketId={basketId} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'Цена',
                          accessorKey: 'price',
                          cell: (info) => <CellPrice info={info} />,
                          footer: (props) => props.column.id,
                          aggregationFn: 'sum',
                          size: 80,
                      },
                      {
                          id: 'Склад',
                          accessorKey: 'warehouse',
                          cell: (info) => <CellWarehouse info={info} />,
                          footer: (props) => props.column.id,
                          size: 5,
                      },
                      {
                          id: 'comment',
                          accessorKey: 'notes',
                          cell: (info) => <CellComment info={info} />,
                          footer: (props) => props.column.id,
                          size: 60,
                      },
                      {
                          id: 'Итого',
                          accessorKey: '',
                          cell: (info) => <CellTotal info={info} />,
                          footer: (props) => props.column.id,
                          aggregationFn: 'mean',
                          size: 80,
                      },
                      // {
                      //   id: 'Оформить',
                      //   accessorKey: '',
                      //   cell: (info) => <CellCheckout info={info} basketForm={basketForm} accountId={accountId} basketId={basketId} hasPartOrder={hasPartOrder}/>,
                      //   footer: (props) => props.column.id,
                      //   aggregationFn: 'mean',
                      //   size: 85,
                      // },
                      {
                          id: 'Удалить',
                          accessorKey: '',
                          cell: (info) => <CellDelete info={info} accountId={accountId} basketId={basketId} />,
                          footer: (props) => props.column.id,
                          aggregationFn: 'mean',
                          size: 1,
                      },
                  ],

        [mobile, basketForm, basketTable, checkedItems],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '10px',
                height: '50px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
        },
        body: {
            thead: {
                visibility: 'collapse',
            },
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '40px',
            },
            marginRow: {
                margin: '6px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <>
            {basket?.length ? (
                <VirtualTable
                    data={data}
                    columnsTable={columns}
                    topFilterPanel={false}
                    showColumnSearchAction={false}
                    configHeader={{
                        fullScrean: false,
                        exportExcel: false,
                        resize: false,
                        grouping: false,
                        columnFilter: false,
                        sorting: false,
                        columnSearch: false,
                        dragging: false,
                    }}
                    styles={tableStyles}
                />
            ) : (
                <></>
            )}
        </>
    );
}
