import { AdminProvider } from './Admin_context';
import { UserAccountProvider } from './UserAccount_context';

export function AdminProviders({ children }: any) {
    return (
        <AdminProvider>
            <UserAccountProvider>{children}</UserAccountProvider>
        </AdminProvider>
    );
}
