import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconInStockProps = TypeClassNames;

export const IconInStock = ({ classNames }: TypeIconInStockProps) => {
    return (
        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.99999 17.3334C4.39749 17.3334 0.666656 13.6026 0.666656 9.00008C0.666656 4.39758 4.39749 0.666748 8.99999 0.666748C13.6025 0.666748 17.3333 4.39758 17.3333 9.00008C17.3333 13.6026 13.6025 17.3334 8.99999 17.3334ZM8.99999 15.6667C10.7681 15.6667 12.4638 14.9644 13.714 13.7141C14.9643 12.4639 15.6667 10.7682 15.6667 9.00008C15.6667 7.23197 14.9643 5.53628 13.714 4.28604C12.4638 3.03579 10.7681 2.33341 8.99999 2.33341C7.23188 2.33341 5.53619 3.03579 4.28594 4.28604C3.0357 5.53628 2.33332 7.23197 2.33332 9.00008C2.33332 10.7682 3.0357 12.4639 4.28594 13.7141C5.53619 14.9644 7.23188 15.6667 8.99999 15.6667ZM8.16916 12.3334L4.63332 8.79758L5.81166 7.61925L8.16916 9.97675L12.8825 5.26258L14.0617 6.44092L8.16916 12.3334Z"
                fill="#EDF0F5"
            />
        </svg>
    );
};
