import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { TypeComment } from '@f_qwep/components/orders/orderChat/OrderChat';
import { IconButton, TextareaAutosize } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';
import { dateNormalizer } from '@f_utils/dateNormalizer';

type CommentItemProps = {
    type: 'BUYER' | 'SELLER'
}

const CommentItem = styled(ListItem)<CommentItemProps>`
    display: block;
    margin-left: ${props => props.type === 'BUYER' ? 0 : 'auto'};
    background-color: ${props => props.type === 'BUYER' ? 'rgba(151,212,255,0.5)' : 'rgba(241,204,167,0.5)'};
    width: 80%;
    margin-bottom: 4px;
    margin-top: 4px;
    padding: 4px 8px;
    border-radius: 12px;
`

const HeaderItem = styled.div`
    font-size: 13px;
    display: flex;

    align-items: center;
    justify-content: space-between;
`

const ItemSpan = styled.span`
    font-size: 10px;
    opacity: .5;
    display: flex;
    justify-content: end;
`

const NotMessageList = styled.div`
    padding: 12px;
    border-radius: 12px;
    background-color: rgba(151,212,255,0.5);
    text-align: center;
    margin-top: 12px;
`

enum TypeUser {
    SELLER = 'Поставщик',
    BUYER = 'Клиент'
}





interface OrderChatTabProps {
    value: string
    setValue: (value: string) => void
    handleSubmit: () => Promise<void>
    commentList: TypeComment[]
}

export const OrderChatTab = ({value, setValue, handleSubmit, commentList}: OrderChatTabProps) => {

    const pressHandler = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '380px', justifyContent: 'space-between'}}>

            <List style={{  overflowY: 'auto', display: 'flex', flexDirection: 'column', padding: '0 6px' }}>
                {
                    commentList.length ? (
                        commentList?.map(item => (
                            <CommentItem key={item.date} type={item.type}>
                                <HeaderItem>{item?.firstName} {item?.lastName}<ItemSpan>{TypeUser[item.type]}</ItemSpan></HeaderItem>
                                <p style={{fontSize: '13px', fontWeight: '800'}}>{item.message}</p>
                                <ItemSpan>{dateNormalizer({
                                    date: item.date,
                                    format: 'locale',
                                    lang: 'ru'
                                })}</ItemSpan>
                            </CommentItem>
                        ))
                    )
                        : <NotMessageList>
                        Сообщений нет!
                        </NotMessageList>

                }

            </List>
            <div style={{display: 'flex', position: 'relative'}}>
                <TextField
                    minRows={3}
                    maxRows={3}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyPress={pressHandler}
                    placeholder={'Текст...'}
                    variant="outlined"
                    style={{ marginTop: '8px',   }}
                />
                <IconButton
                    onClick={handleSubmit}
                    sx={{position: 'absolute', top: '6px', right: 0 }}
                >
                    <SendIcon/>
                </IconButton>
            </div>

        </div>
    );
};