import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export function ColumnQuantity ({ index, quantity }:any, idRow:any, isOpenDetails:any)  {
    const { colors } = useContext(ThemeContext);

    return (
        <div
            style={{
                color:
                    idRow !== index && isOpenDetails ? colors.blurColor : colors.omicron,
            }}
            className={stls.cellData}>
            {quantity}
        </div>
    )
}