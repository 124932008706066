import { Button, Link } from '@mui/material';

export default function ButtonsQwep() {
    const buttons = [
        { id: 1, name: 'Главная', path: '/app-qwep' },
        { id: 2, name: 'Проценка', path: '/app-qwep/batch-pricer' },
        { id: 3, name: 'Прайсы', path: '/app-qwep/prices' },
        { id: 4, name: 'Заказы', path: '/app-qwep/orders' },
    ];
    return (
        <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
            {buttons.map((el) => (
                <Button variant="outlined">
                    <Link underline="none" href={el.path}>
                        {el.name}
                    </Link>
                </Button>
            ))}
        </div>
    );
}
