import { Tooltip } from '@mui/material';
import stls from '../../../styles/component/basket/Basket.module.sass';

export function CellWarehouse({ info }: any) {
    const wHouse = info.row.original?.warehouse;

    return (
        <Tooltip placement="top" title={wHouse}>
            <p className={stls.cellWarehouse}> {wHouse}</p>
        </Tooltip>
    );
}
