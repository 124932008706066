import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import AddIcon from '@mui/icons-material/Add';
import { ThemeContext } from '@f_context/Theme_context';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { useWindowSize } from '@f_hooks';
import { CellMobile } from './tableTaskList/CellMobile';
import { CellDate, HeadDate } from './tableTaskList/CellDate';
import { CellProducts, HeadProducts } from './tableTaskList/CellProducts';
import { CellName } from './tableTaskList/CellName';
import { CellStatus, HeadStatus } from './tableTaskList/CellStatus';
import { CellResults } from './tableTaskList/CellResults';
import { CellDelete } from './tableTaskList/CellDelete';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { AddTaskModal } from './AddTaskModal';
import { SearchBase } from '@f_general/SearchBase';
import { Alert } from '@f_general/alert';
import { PageNoData } from '@f_qwep/general/PageNoData';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { CellSettings } from './tableTaskList/CellSettings';
import { useValidAccounts } from '@f_qwep/hooks/useValidAccounts';

export function BatchPricerTaskList({ showTask, dashboardList }: any) {
    const { t } = useTranslation();
    const { batchPricerStrictTasks } = useContext(QwepContext);
    const { createTask, openAdd, setOpenAdd, progressList } = useContext(BatchPricerContext);
    const { colors } = useContext(ThemeContext);
    const validAccounts = useValidAccounts();
    const [mobile, setMobile] = useState(false);
    const { width } = useWindowSize();
    const [dashboardListTable, setDashboardListTable] = useState(dashboardList);

    useEffect(() => {
        setDashboardListTable(dashboardList);
    }, [dashboardList]);

    useEffect(() => {
        setMobile(width < 900);
    }, [width]);

    const handleStart = async (title: string, file: File) => {
        if (Object.keys(progressList)?.length > 2) {
            return Alert({
                type: 'warning',
                text: 'Допустим одновременный запуск только двух списков.',
            });
        }
        if (!validAccounts) {
            return;
        }

        if (title.trim() && file) {
            const result = await createTask({
                accounts: validAccounts.map((item: any) => item.id.toString()),
                title,
                file,
            });
            if (result.isOk) {
                Alert({
                    type: 'success',
                    text: `${t('qwep.batchPricer.startBatchPricer1')} ${title.trim()} ${t(
                        'qwep.batchPricer.startBatchPricer2',
                    )}`,
                });
                setOpenAdd(false);
            }
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'price',
                          accessorKey: 'price',
                          header: () => 'Проценка',
                          cell: (info) => <CellMobile info={info} showTask={showTask} />,
                          footer: (props) => props.column.id,
                      },
                  ]
                : [
                      {
                          id: 'date',
                          accessorKey: 'lastUpdated',
                          header: () => <HeadDate />,
                          cell: (info) => <CellDate info={info} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'taskSize',
                          accessorKey: 'taskSize',
                          header: () => <HeadProducts />,
                          cell: (info) => <CellProducts info={info} />,
                          footer: (props) => props.column.id,
                          size: 80,
                      },
                      {
                          id: 'name',
                          accessorKey: 'taskName',
                          header: () => `${t('qwep.batchPricer.name')}`,
                          cell: (info) => <CellName info={info} />,
                          footer: (props) => props.column.id,
                          size: 200,
                      },
                      {
                          id: 'settings',
                          accessorKey: 'taskSettings',
                          header: () => `${t('qwep.batchPricer.settings')}`,
                          cell: (info) => <CellSettings info={info} />,
                          footer: (props) => props.column.id,
                          size: 200,
                      },
                      {
                          id: 'status',
                          accessorKey: 'status',
                          header: () => <HeadStatus />,
                          cell: (info) => <CellStatus info={info} />,
                          footer: (props) => props.column.id,
                          size: 80,
                      },
                      {
                          id: 'results',
                          accessorKey: 'results',
                          header: `${t('qwep.batchPricer.result')}`,
                          cell: (info) => <CellResults info={info} showTask={showTask} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      // {
                      //     id: 'list',
                      //     accessorKey: 'list',
                      //     header: 'Список',
                      //     cell: (info) => <CellList info={info} />,
                      //     footer: (props) => props.column.id,
                      //     size: 100,
                      // },
                      {
                          id: 'delete',
                          accessorKey: '',
                          header: '',
                          cell: (info) => <CellDelete info={info} />,
                          footer: (props) => props.column.id,
                          size: 30,
                      },
                  ],

        [mobile, batchPricerStrictTasks],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '45px',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div className={stls.components}>
            {dashboardList.length > 0 ? (
                <>
                    <div className={stls.appbarPrices}>
                        <SearchBase
                            data={dashboardList}
                            placeholder={t('qwep.batchPricer.searchBatchPricer')}
                            setData={setDashboardListTable}
                            type={'dashboardList'}
                        />
                        <Button
                            id="createBatchPricer"
                            onClick={() => setOpenAdd(true)}
                            variant="contained"
                            startIcon={<AddIcon sx={{ color: 'white' }} />}>
                            <p className={stls.textButton}>
                                {mobile ? t('qwep.batchPricer.create') : t('qwep.batchPricer.createBatchPricer')}
                            </p>
                        </Button>
                    </div>
                    <VirtualTable
                        data={dashboardListTable}
                        columnsTable={columns}
                        topFilterPanel={true}
                        showColumnSearchAction={false}
                        heightTable={700}
                        configHeader={{
                            fullScrean: false,
                            exportExcel: false,
                            resize: false,
                            grouping: false,
                            columnFilter: false,
                            sorting: false,
                            columnSearch: false,
                            dragging: false,
                        }}
                        styles={tableStyles}
                        estimateSize={53}
                    />
                </>
            ) : (
                <>
                    <div style={{ justifyContent: 'end' }} className={stls.appbarPrices}>
                        <Button
                            id="createBatchPricer"
                            className={stls.buttonGroup}
                            variant="contained"
                            startIcon={<AddIcon sx={{ color: 'white' }} />}
                            onClick={() => setOpenAdd(true)}>
                            <p>{t('qwep.batchPricer.createBatchPricer')}</p>
                        </Button>
                    </div>
                    <PageNoData type={'batchPricer'} />
                </>
            )}
            <AddTaskModal open={openAdd} handleClose={() => setOpenAdd(false)} sendData={handleStart} />
        </div>
    );
}
