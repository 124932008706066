import { TypeClassNames } from '@f_types/index';

type TypeIconPriceProps = TypeClassNames;

export const IconPrice = ({ classNames }: TypeIconPriceProps) => {
    return (
        <svg width="18" height="21" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="9" r="7" stroke="#8CBCE1" strokeWidth="1" />
            <path
                d="M0.00532961 0.65H3.36533C3.92533 0.65 4.33066 0.778 4.58133 1.034C4.83733 1.28467 4.96533 1.69 4.96533 2.25V3.05C4.96533 3.61 4.83733 4.018 4.58133 4.274C4.33066 4.52467 3.92533 4.65 3.36533 4.65H1.44533V6.25H0.00532961V0.65ZM3.52533 2.25C3.52533 1.93 3.36533 1.77 3.04533 1.77H1.44533V3.53H3.04533C3.36533 3.53 3.52533 3.37 3.52533 3.05V2.25ZM8.04502 3.17C7.70902 3.17 7.36235 3.25 7.00502 3.41V6.25H5.60502V2.01H6.84502L6.92502 2.53C7.38368 2.13 7.86368 1.93 8.36502 1.93H8.72502V3.17H8.04502Z"
                fill="#8CBCE1"
                transform="translate(4 5.5)"
            />
        </svg>
    );
};
