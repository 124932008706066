import { TypeClassNames } from '@f_types/index';

type TypeIconApiProps = TypeClassNames;

export const IconApi = ({ classNames }: TypeIconApiProps) => {
    return (
        <svg width="18" height="21" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="9" r="7" stroke="#8CBCE1" strokeWidth="1" />
            <path
                d="M1.79549 4.75H0.625486L2.21549 0.55H3.41549L5.00549 4.75H3.83549L3.56549 4H2.06549L1.79549 4.75ZM2.33549 3.22H3.29549L2.81549 1.81L2.33549 3.22ZM5.30525 0.55H7.82525C8.24525 0.55 8.54925 0.646 8.73725 0.838C8.92925 1.026 9.02525 1.33 9.02525 1.75V2.35C9.02525 2.77 8.92925 3.076 8.73725 3.268C8.54925 3.456 8.24525 3.55 7.82525 3.55H6.38525V4.75H5.30525V0.55ZM7.94525 1.75C7.94525 1.51 7.82525 1.39 7.58525 1.39H6.38525V2.71H7.58525C7.82525 2.71 7.94525 2.59 7.94525 2.35V1.75ZM11.545 4.75H9.38502V3.91H9.92502V1.39H9.38502V0.55H11.545V1.39H11.005V3.91H11.545V4.75Z"
                fill="#8CBCE1"
                transform="translate(2 6)"
            />
        </svg>
    );
};
