import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconCaseCompanyProps = TypeClassNames;

export const IconCaseCompany = ({ classNames }: TypeIconCaseCompanyProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5 4V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H5ZM2 14V18H18V14H2ZM9 10V12H11V10H9ZM7 2V4H13V2H7Z"
                    fill="#94A3B8"
                />
            </svg>
        </div>
    );
};
