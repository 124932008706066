import { useContext } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import stls from '@f_styles/components/settings/Settings.module.sass';
import { ThemeContext } from '../../context/Theme_context';
import stl from '@f_styles/general/Universal.module.sass';
import cn from 'classnames';
import { IconImage } from '@f_components/icons/IconImage';
import { useTranslation } from 'react-i18next';
import { GeneralContext } from '@f_context/General_context';
import { InterfaceTheme } from './InterfaceTheme';
import { TypeLoginData } from '@f_types';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';

const data: TypeLoginData = {
    email: '',
    password: '',
};

export function Interface() {
    const { language, colorTheme, updateUserProperty } = useContext(GeneralContext);
    const { colors, CustomSelect, currentTheme } = useContext(ThemeContext);
    const { t } = useTranslation();

    const handleLangChange = (event: SelectChangeEvent) => {
        console.log(event, 'event');
        updateUserProperty({ sysName: 'lang', value: event.target.value });
    };

    const handleThemeChange = (thame: string) => {
        updateUserProperty({ sysName: 'platform_theme', value: thame });
    };

    return (
        <Card className={stls.gridItem}>
            <Box>
                <div className={stls.header}>
                    <Typography variant="h6" className={stls.title}>
                        {t('settings.appearance')}
                    </Typography>
                </div>
                <div>
                    <div className={stls.interface}>
                        <div>
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <LanguageIcon fontSize="small" />
                                    <Typography
                                        variant="body2"
                                        className={cn(stls.buttonProfileDisable, stl.alphaTypographyDisable)}>
                                        {t('settings.language')}
                                    </Typography>
                                </div>
                                <Select
                                    input={<CustomSelect />}
                                    className={stls.select}
                                    value={language}
                                    defaultValue={language}
                                    onChange={handleLangChange}
                                    MenuProps={{
                                        sx: {
                                            '&& .MuiMenuItem-root': {
                                                fontSize: 14,
                                                fontFamily: 'Inter',
                                                color: colors.omicron,
                                                '&:hover': {
                                                    background: colors.beta,
                                                },
                                            },
                                        },
                                    }}>
                                    <MenuItem value="ru">Русский</MenuItem>
                                    <MenuItem value="en">English</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={stls.color} style={{ marginBottom: 24 }}>
                        {currentTheme == 'vinpin' ? (
                            <></>
                        ) : (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <PhotoOutlinedIcon fontSize="small" />
                                    <Typography
                                        variant="body2"
                                        style={{ marginBottom: 20, marginLeft: 10 }}
                                        className={cn(stls.buttonProfileDisable, stl.alphaTypographyDisable)}>
                                        {t('settings.interfaceTheme')}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div onClick={() => handleThemeChange('light')}>
                                        <InterfaceTheme style={'light'} />
                                    </div>
                                    <div onClick={() => handleThemeChange('dark')}>
                                        <InterfaceTheme style={'dark'} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Box>
        </Card>
    );
}
