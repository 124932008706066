import { TypeUserReport } from '@f_ucs/context/report_context';
import { TypeCurrentType } from '@f_ucs/context/collector_context';
import { Type1cUser, TypeWebUser } from '@f_ucs/context/doughnut_context';


interface TypeMergedSusBuy {
    user: string|number;
    count: number;
}

export const mergeSusBuys = (susBuysArray: TypeUserReport[], type: TypeCurrentType): TypeMergedSusBuy[] => {
    const userCounts: { [key: string]: TypeMergedSusBuy } = {};

    susBuysArray.forEach(buy => {
        let userKey: string | number | undefined;

        // Определяем ключ пользователя в зависимости от типа
        if (type === "1c") {
            const user = buy.user as Type1cUser;
            if ('key' in user) {
                userKey = user.key;
            }
        } else if (type === "web") {
            const user = buy.user as TypeWebUser;
            if ('userId' in user) {
                userKey = user.userId;
            }
        }

        // Если ключ пользователя определен, обновляем счетчик
        if (userKey !== undefined) {
            if (userCounts[userKey]) {
                userCounts[userKey].count++;
            } else {
                userCounts[userKey] = {
                    user: userKey,
                    count: 1
                };
            }
        }
    });

    // Преобразуем объект в массив и возвращаем результат
    return Object.values(userCounts);
}