import React, { FC } from 'react';
import stls from '@f_qwep/styles/component/navbar/Navbar.module.sass';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Alert } from '@f_general/alert';
import { MessageInfo } from '@f_qwep/general/MessageInfo';
import { Tariff } from '@f_qwep/types';

export interface IDefaultPage {
    name: string;
    url: string;
    access?: boolean;
}

interface NavBarViewProps {
    activeApp: any;
    navigate: (url?: string) => void;
    alignment: string;
    handleChange: (event: any, newAlignment: string) => void;
    currentTheme: string;
    pages: IDefaultPage[];
    mobile: boolean;
    needAccessTariffPro: Tariff | string;
    needAccessTariffProPlus: Tariff | string;
}

export const NavbarView: FC<NavBarViewProps> = ({
    activeApp,
    navigate,
    alignment,
    handleChange,
    currentTheme,
    mobile,
    pages,
    needAccessTariffPro,
    needAccessTariffProPlus,
}) => {
    return (
        <>
            {activeApp ? (
                <div className={stls.container}>
                    <ToggleButtonGroup
                        className={stls.toggleGroup}
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        style={currentTheme === 'vinpin' ? { background: 'none' } : {}}>
                        {pages
                            .filter((item: any) =>
                                mobile ? item.url != 'batch-pricer' && item.url != 'prices' : item.url,
                            )
                            .map((el, idx) => {
                                return !el.access ? (
                                    <div
                                        key={idx}
                                        onClick={() => {
                                            Alert({
                                                type: 'info',
                                                text: 'Этот функционал недоступен на вашем тарифе',
                                            });
                                        }}>
                                        <ToggleButton disabled className={stls.toggle} value={el.name}>
                                            {el.name}{' '}
                                            {el.name === 'Прайсы' ? needAccessTariffProPlus : needAccessTariffPro}
                                        </ToggleButton>
                                    </div>
                                ) : (
                                    <ToggleButton
                                        id={el.url}
                                        onClick={() => {
                                            navigate(el.url);
                                        }}
                                        className={stls.toggle}
                                        value={el.name}
                                        key={el.url}>
                                        {el.name} <MessageInfo type={el.url} />
                                    </ToggleButton>
                                );
                            })}
                    </ToggleButtonGroup>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
