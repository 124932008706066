import { QwepContext } from '@f_qwep/context/Qwep_context';
import { BasketContext } from '@f_qwep/context/Basket_context';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { useContext } from 'react';
import { LoadingSettings } from '../loaders/LoadingSettings';
import { GeneralBasket } from './GeneralBasket';
import { GeneralMiniBasket } from './MiniBasketTable/GeneralMiniBasket';
import { OrderModal } from './OrderModal';

export function IndexBasket() {
    const { isLoading } = useContext(BasketContext);
    const { miniTableRes } = useContext(QwepContext);

    return (
        <LayoutPage>
            {isLoading ? <LoadingSettings /> : miniTableRes ? <GeneralMiniBasket /> : <GeneralBasket />}
            <OrderModal />
        </LayoutPage>
    );
}
