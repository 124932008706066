import { useContext } from 'react';
import stls from '@f_styles/components/drawer/DrawerList.module.sass';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListSubheader, Tooltip, Typography } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconQWEPDrawer } from '@f_components/icons/IconQWEPDrawer';
import { ThemeContext } from '@f_context/Theme_context';
import { IconUCS } from '@f_components/icons/iconUCS';
import { TypePanels } from '@f_types/Panels.type';

type TypeApplicationListProps = {
    navigatinHandler: (url: string, panelName: string) => void;
    panels: TypePanels[];
    currentPanel: string;
    setCurentPanel: (value: string) => void;
};

export function ApplicationList({ navigatinHandler, panels, currentPanel, setCurentPanel }: TypeApplicationListProps) {
    const { t } = useTranslation();
    const [panelsList, setPanelsList] = useState<TypePanels[]>([]);
    const { currentTheme } = useContext(ThemeContext);

    useEffect(() => {
        if (panels) {
            const appPanel: TypePanels[] = [];
            panels.filter((item: any) => {
                switch (item.applicationSysName) {
                    case 'qwep':
                        appPanel.push({
                            ...item,
                            title: 'QWEP',
                            icon:
                                currentTheme === 'vinpin' ? (
                                    <AppsIcon fontSize={'small'} />
                                ) : (
                                    <IconQWEPDrawer classNames={[stls.iconQwep]} />
                                ),
                            url: '/app-qwep',
                            disabled: !item.applicationPanelIsActive,
                        });
                        break;
                    case 'admin_panel':
                        appPanel.push({
                            ...item,
                            title: t('drawer.applications.adminPanel'),
                            icon: <AppsIcon fontSize={'small'} />,
                            url: '/app-admin',
                            disabled: !item.applicationPanelIsActive,
                        });
                        break;
                    case 'crossdata':
                        appPanel.push({
                            ...item,
                            title: 'DATA',
                            icon: <LeaderboardIcon fontSize={'small'} />,
                            url: '/app-crossdata',
                            disabled: !item.applicationPanelIsActive,
                        });
                        break;
                    case 'ucs':
                        appPanel.push({
                            ...item,
                            title: 'UCS',
                            icon: <IconUCS />,
                            url: '/app-ucs',
                            disabled: !item.applicationPanelIsActive,
                        });
                        break;
                }
            });
            setPanelsList(appPanel);
        }
    }, [JSON.stringify(panels)]);

    return (
        <List subheader={<ListSubheader className={stls.title}>{t('drawer.generelMenu')}</ListSubheader>}>
            {panelsList.length ? (
                panelsList.map((item) => (
                    <ListItem
                        key={item.applicationPanelId}
                        className={stls.listItem}
                        disablePadding
                        selected={currentPanel === item.applicationSysName}>
                        <ListItemButton
                            className={stls.listItemButton}
                            disabled={item.disabled}
                            onClick={() => navigatinHandler(item.url, item.applicationSysName)}>
                            <ListItemIcon className={stls.btnIcon} sx={{ minWidth: 40 }}>
                                {item.icon}
                            </ListItemIcon>
                            <Typography variant="body2">{item.title}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))
            ) : (
                <p>{t('general.isLoading')}</p>
            )}
        </List>
    );
}
