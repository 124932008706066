import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconLibraryProps = TypeClassNames;

export const IconLibrary = ({ classNames }: TypeIconLibraryProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="24" height="24" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13 18V20H17V22H7V20H11V18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V17C22 17.2652 21.8946 17.5196 21.7071 17.7071C21.5196 17.8946 21.2652 18 21 18H13ZM4 5V16H20V5H4Z"
                    fill={colors.alpha}
                />
                <circle cx="8" cy="9" r="1" fill={colors.alpha} />
                <circle cx="16" cy="9" r="1" fill={colors.alpha} />
                <path
                    d="M14.5875 11.5C14.8153 11.5 15.004 11.6864 14.9594 11.9098C14.9221 12.097 14.8592 12.2805 14.7716 12.4567C14.6209 12.76 14.3999 13.0356 14.1213 13.2678C13.8427 13.4999 13.512 13.6841 13.1481 13.8097C12.7841 13.9353 12.394 14 12 14C11.606 14 11.2159 13.9353 10.8519 13.8097C10.488 13.6841 10.1573 13.4999 9.87868 13.2678C9.6001 13.0356 9.37913 12.76 9.22836 12.4567C9.1408 12.2805 9.07792 12.097 9.04058 11.9098C8.99603 11.6864 9.18468 11.5 9.4125 11.5V11.5C9.64032 11.5 9.81989 11.6879 9.88067 11.9075C9.90767 12.005 9.94438 12.1007 9.99056 12.1936C10.0999 12.4135 10.2601 12.6133 10.462 12.7816C10.664 12.9499 10.9038 13.0834 11.1677 13.1745C11.4315 13.2656 11.7144 13.3125 12 13.3125C12.2856 13.3125 12.5685 13.2656 12.8323 13.1745C13.0962 13.0834 13.336 12.9499 13.538 12.7816C13.7399 12.6133 13.9001 12.4135 14.0094 12.1936C14.0556 12.1007 14.0923 12.005 14.1193 11.9075C14.1801 11.6879 14.3597 11.5 14.5875 11.5V11.5Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
