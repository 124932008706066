import { Button } from '@mui/material';
import { FC, RefObject } from 'react';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';
import { observer } from 'mobx-react-lite';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

interface IFilterLeftPanelTemplateSubmitProps {
    handleOpen?: () => void;
    anchorRef?: RefObject<HTMLButtonElement>;
    title: string;
    handleUpdate?: (object: any) => Promise<void>;
    type: 'create' | 'update';
    workspaceApplication: any;
    updateProperty: any;
    filter: any;
    disabled?: boolean;
    filterType: FilterTypesEnum;
    setFilter: any;
}

export const FilterLeftPanelTemplateSubmit: FC<IFilterLeftPanelTemplateSubmitProps> = observer(
    ({
        handleOpen,
        anchorRef,
        title,
        handleUpdate,
        type,
        workspaceApplication,
        updateProperty,
        filter,
        disabled = false,
        filterType,
        setFilter,
    }) => {
        return (
            <Button
                disabled={disabled}
                ref={anchorRef}
                id={type === 'create' ? 'saveFilterTemplate' : 'updateFilterTemplate'}
                onClick={() => {
                    if (type === 'create' && handleOpen) {
                        handleOpen();
                    }
                    if (type === 'update' && handleUpdate) {
                        handleUpdate({ workspaceApplication, updateProperty, filter, filterType, setFilter });
                    }
                }}
                className={styles.templateButton}>
                <p className={styles.templateButtonTitle}>{title}</p>
            </Button>
        );
    },
);
