import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconMailProps = TypeClassNames;

export const IconMail = ({ classNames }: TypeIconMailProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20 10.341C18.9317 9.9626 17.7781 9.8945 16.6727 10.1446C15.5674 10.3946 14.5554 10.9527 13.754 11.754C12.9527 12.5554 12.3946 13.5674 12.1446 14.6727C11.8945 15.7781 11.9626 16.9317 12.341 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V10.341ZM10.06 8.683L3.648 3.238L2.353 4.762L10.073 11.317L17.654 4.757L16.346 3.244L10.061 8.683H10.06ZM19 15H22V17H19V20H17V17H14V15H17V12H19V15Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
