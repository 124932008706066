import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export function ColumnTime ({ buyTime, index }:any, idRow:any, isOpenDetails:any)  {
    const { colors } = useContext(ThemeContext);

    return (
        <div
            className={stls.cellData}
            style={{
                maxHeight: '50px',
                color:
                    idRow !== index && isOpenDetails ? colors.blurColor : colors.omicron,
            }}>
            {dayjs(buyTime).format('DD.MM') || ''}
        </div>

    )
}
