import { Alert } from '@f_general/alert';
import {
    IFileValidatorArgs,
    IFileValidatorOptions,
    IValidationFileResult,
    IValidationFileRule,
} from '@f_qwep/types/file/validation/FileValidation.interface';
import { FileExtension } from '@f_types/FileExtension.enum';
import { ConvertBytes } from '@f_utils/convert/convertBytes.util';

export class FileValidator {
    private options: IFileValidatorOptions;
    private isValid: boolean = true;
    private readonly extension: FileExtension | null;
    private readonly size: number | null;

    constructor({ options, extension, size }: IFileValidatorArgs) {
        this.options = options;
        this.extension = extension;
        this.size = size;
    }

    public validateFile(): IValidationFileResult {
        if (this.validateFileExtension()) {
            this.validateFileSize();
        }
        return this.getValidationResult();
    }

    public validateJson(json: object[], isExceptionLimitJsonSize: boolean): IValidationFileResult {
        this.validateJsonNullable(json) && this.validateJsonLength(json, isExceptionLimitJsonSize);

        return this.getValidationResult();
    }

    private validateFileExtension(): boolean {
        return this.validateProperty({
            actualValue: this.extension,
            expectedValue: this.options.allowedExtensions,
            comparisonRule: (actualValue, expectedValue) => expectedValue.includes(actualValue),
            errorMessage: `Недопустимое расширение файла. Разрешены только файлы ${this.options.allowedExtensions.join(
                ', ',
            )}.`,
        });
    }

    private validateFileSize(): boolean {
        return this.validateProperty({
            actualValue: this.size,
            expectedValue: this.options.maxSize,
            comparisonRule: (actualValue, expectedValue) => actualValue <= expectedValue,
            errorMessage: `Размер файла превышает максимальный предел в ${ConvertBytes.bytesToMegabytes(
                this.options.maxSize,
            )} МБ.`,
        });
    }

    private validateJsonNullable(json: object[] | null): boolean {
        return this.validateProperty({
            actualValue: json,
            expectedValue: null,
            comparisonRule: (actualValue, expectedValue) => actualValue !== expectedValue,
            errorMessage: `Не удалось распарсить файл, возможно, структура файла некорректная. Проверьте файл или обратитесь в поддержку`,
        });
    }

    private validateJsonLength(json: object[], isExceptionLimitJsonSize: boolean): boolean {
        return this.validateProperty({
            actualValue: json?.length,
            expectedValue:
                isExceptionLimitJsonSize && this.options?.exceptionsLimitJsonSize
                    ? this.options.exceptionsLimitJsonSize
                    : this.options.limitJsonSize,
            comparisonRule: (actualValue, expectedValue) => {
                if (!this.options.limitJsonSize) {
                    return true;
                }
                return actualValue < expectedValue;
            },
            errorMessage: `Максимально количество строк не может превышать ${this.options.limitJsonSize}`,
        });
    }

    private validateProperty<T, U>(validationRule: IValidationFileRule<T, U>): boolean {
        if (!validationRule.comparisonRule(validationRule.actualValue, validationRule.expectedValue)) {
            Alert({
                type: 'error',
                text: validationRule.errorMessage,
            });
            this.isValid = false;
            return this.isValid;
        }
        this.isValid = true;
        return this.isValid;
    }

    private getValidationResult(): IValidationFileResult {
        return {
            isValid: this.isValid,
        };
    }
}
