import React from 'react';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';

interface IAssistantWarehouseColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    isTemplateItem: any;
    warehouse: any;
    foundItem: any;
}

export const AssistantWarehouseColumn = (props: IAssistantWarehouseColumnProps) => {
    const { foundItem, colors, styles, warehouse, isTemplateItem } = props;
    return (
        <p className={styles.cellRow} style={{ color: foundItem ? colors.omicron : colors.sigma }}>
            {warehouse && !isTemplateItem ? warehouse : ''}
        </p>
    );
};
