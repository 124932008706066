import { QwepContext } from '../../../context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '../../../styles/component/basket/Basket.module.sass';
import { inRange } from 'lodash';

export function CellTotal({ info }: any) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);

    const { priceMarginIsActive, priceMarginState } = useContext(QwepContext);

    const rules = priceMarginState?.length && priceMarginState?.filter((el: any) => el.flag)[0]?.content;

    const getPriceWithMargin = (price: any) => {
        const currentPrice = Math.ceil(parseFloat(price));
        const currentRules = rules?.filter((rule: any) => inRange(currentPrice, rule?.from, rule.to));
        if (currentRules?.length > 0) {
            const rule = currentRules?.[0];
            return currentPrice + (currentPrice / 100) * rule.addPercent;
        }

        return price;
    };

    const basketSum = info.row.original.basketItems.reduce(
        (ac: any, cur: any) => (priceMarginIsActive ? ac + getPriceWithMargin(+cur.priceTotal) : ac + +cur.priceTotal),
        0,
    );

    return (
        <div className={stls.cellDiv} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <p
                style={{
                    background: colors.pi,
                    border: `0.5px solid ${colors.gamma}`,
                    color: currentTheme == 'light' ? colors.omicron : colors.gamma,
                }}
                className={stls.cellPrice}>
                {basketSum.toLocaleString()} ₽
            </p>
        </div>
    );
}
