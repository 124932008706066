import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconLibraryNewProps = TypeClassNames;

export const IconLibraryNew = ({ classNames }: TypeIconLibraryNewProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="16" height="18" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H18V20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H18V16ZM2 14.05C2.162 14.017 2.329 14 2.5 14H16V2H2V14.05ZM13 7H5V5H13V7Z"
                fill={colors.alpha}
            />
        </svg>
        // </div>
    );
};
