export enum Classifications {
    BRAND = 'brand',
    ARTICLE = 'article',
    PARTNAME = 'partname',
    QUANTITY = 'quantity',
    MULTIPLICITY = 'multiplicity',
    DELIVERY = 'delivery',
    STATUS = 'status',
    WAREHOUSE = 'warehouse',
    PRICE = 'price',
    NOTES = 'notes',
    PHOTO = 'photo',
    CURENCY = 'curency',
    TRASH = 'trash',
}
