import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_admin/styles/components/userInfo/TableUsersToWorkspace.module.sass';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Apps } from '@f_types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@mui/material';
import GeneralModal from '@f_general/GeneralModal';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { Alert } from '@f_general/alert';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function CellApplications({ info, appsToWorkspace, getUsers }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { createUserPanel, currentWorkspace, checkedUserPanel } = useContext(UserAccountContext);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});
    const [applications, setApplications] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let apps: any = [];
        appsToWorkspace?.map((item: any) => {
            if (
                !info.row.original.applications.some(
                    (app: any) => item.application.applicationSysName === app.applicationSysName,
                )
            ) {
                apps.push(item);
            }
        });
        setApplications(apps);
    }, []);

    const closeHandler = () => {
        setDataModal({});
        setOpen(false);
    };

    const clickModalHandler = () => {
        setDataModal({
            icon: <IconModalGroup />,
            title: 'Create App',
            props: [
                ...applications.map((item: any) => ({
                    control: 'checkbox',
                    title: 'Application',
                    name: item.propertyApplication.sysNameApplication,
                    type: 'checkbox',
                    options: [
                        {
                            key: item.application.applicationName,
                            value: false,
                        },
                    ],
                })),
            ],
            shema: null,
            type: 'createApp',
            data: {
                ...(() => {
                    let apps = {};
                    applications.map((item: any) => {
                        apps = {
                            ...apps,
                            [item.propertyApplication.sysNameApplication]: false,
                        };
                    });
                    return apps;
                })(),
                type: 'createApp',
            },
        } as TypeDataModal);
        setOpen(true);
    };

    const sendDataModalHandler = async (values: any) => {
        setLoading(true);
        let apps: any = [];
        Object.keys(values).map((key: any) => {
            applications.map((app: any) => {
                if (app.propertyApplication.sysNameApplication === key && values[key]) {
                    apps.push({
                        applicationWorkspaceId: app.applicationWorkspaceId,
                        propertyApplication: app.propertyApplication,
                        applicationId: app.application.applicationId,
                    });
                }
            });
        });

        if (apps.length) {
            const result = await createUserPanel({
                applications: apps,
                userId: info.row.original.userId,
                email: info.row.original.email,
                workspaceId: currentWorkspace.workspaceId,
            });
            if (result.isOk) {
                closeHandler();
                getUsers(currentWorkspace.workspaceId);
                Alert({
                    type: 'success',
                    text: t('general.success'),
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        } else {
            closeHandler();
        }
        setLoading(false);
    };

    const changeCheckedHandler = async (checked: boolean, panelId: number, sysName: string) => {
        setLoading(true);
        const app = currentWorkspace.applicationWorkspce?.find(
            (item: any) => item.application.applicationSysName === sysName,
        );
        const result = await checkedUserPanel({
            panelId: panelId,
            isActive: checked,
            workspaceId: currentWorkspace.workspaceId,
            applicationWorkspaceId: app.applicationWorkspaceId,
            applicationId: app.application.applicationId,
            propertyApplication: app.propertyApplication,
        });
        if (result.isOk) {
            getUsers(currentWorkspace.workspaceId);
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            if (result.statusNumber === 1) {
                Alert({
                    type: 'warning',
                    text: 'Нет доступных лицензий',
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        }
        setLoading(false);
    };

    return (
        <div className={stls.cellApplications}>
            {info.row.original.applications?.map((item: any) => {
                let color;
                let background;
                switch (item.applicationSysName) {
                    case Apps.Qwep:
                        color = currentTheme == 'light' ? '#6EBA6E' : '#69DE94';
                        background = currentTheme == 'light' ? '#6EBA6E20' : '#69DE9420';

                        break;
                    case Apps['Admin Panel']:
                        color = colors.eta;
                        background = '#ffee5115';
                        break;
                    default:
                        color = colors.alpha;
                        background = '#218ee11e';
                        break;
                }
                return (
                    <div className={stls.item}>
                        <p
                            className={stls.lable}
                            style={{
                                color: color,
                                background: background,
                            }}>
                            {item.applicationName}
                        </p>
                        <Checkbox
                            sx={{ marginTop: '-10px' }}
                            checked={item.applicationPanelIsActive}
                            disabled={loading}
                            onChange={(e) =>
                                changeCheckedHandler(e.target.checked, item.applicationPanelId, item.applicationSysName)
                            }
                        />
                    </div>
                );
            })}
            <div>
                {applications?.length ? (
                    <>
                        <Button variant={'contained'} onClick={clickModalHandler} size={'small'}>
                            Добавить
                        </Button>
                        <GeneralModal
                            openModal={open}
                            closeModal={closeHandler}
                            sendDataModalHandler={sendDataModalHandler}
                            dataModal={dataModal}
                            loading={loading}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}
