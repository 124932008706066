import { Box, Button, Card, CardContent, Checkbox, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/group/Group.module.sass';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconNotGroup } from '@f_components/icons/IconNotGroup';
import { GroupContext } from '@f_qwep/context/Group_context';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';
import { IconClose } from '@f_components/icons/IconClose';
import { QwepContext } from '../../context/Qwep_context';
import { IconDelete } from '@f_components/icons/IconDelete';
import GeneralModal from '@f_general/GeneralModal';
import { Alert } from '@f_general/alert';
import { Property } from '@f_qwep/types';
import { SearchBase } from '@f_general/SearchBase';
import { AccountsContext } from '../../context/Accounts_context';
import { IconCloseNotBG } from '../icons/IconCloseNotBG';
import { useWindowSize } from '@f_hooks';
import { MobileButtonSettings } from '../settings/MobileButtonSettings';
import { GeneralContext } from '@f_context/General_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function MyGroup() {
    const { t } = useTranslation();
    const { colors, currentTheme } = React.useContext(ThemeContext);

    const navigate = useNavigate();

    const [openDelete, setOpenDelete] = React.useState(false);
    const { newGroup, setNewGroup, openEditId, setOpenEditId, openId, setOpenId } = React.useContext(GroupContext);
    const { groupsAccountsState, updateProperty, workspaceApplication } = React.useContext(QwepContext);
    const [groupsTable, setGroupsTable] = React.useState(groupsAccountsState);
    const { fullAccounts } = React.useContext(AccountsContext);
    const [addNewVendor, setAddNewVendor] = React.useState<any>(null);
    const [accontTable, setAccountTable] = React.useState(fullAccounts);
    const [accountSearchTable, setAccountSearchTable] = React.useState([]);
    const [mobile, setMobile] = React.useState(false);
    const { width } = useWindowSize();

    const [dataModal, setDataModal] = React.useState<TypeDataModal>({});

    React.useEffect(() => {
        setGroupsTable(groupsAccountsState);
    }, [groupsAccountsState]);

    React.useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const handleOpenEdit = (id: number, group: any) => {
        if (openEditId == id) {
            setOpenEditId(null);
            setNewGroup([]);
        } else {
            setOpenEditId(id);
            setAccountSearchTable(group);
            setNewGroup(groupsTable?.filter((el: any) => el.id == id)[0]?.group);
        }
    };

    const handleCancel = () => {
        setOpenEditId(null);
        setNewGroup([]);
        setOpenId(null);
        setAccountTable(fullAccounts);
        setAccountSearchTable([]);
    };

    const handleChange = (group: any) => {
        if (newGroup.some((el: any) => el.id == group.id)) {
            return setNewGroup(newGroup.filter((item: any) => item.id !== group.id));
        }
        return setNewGroup([...newGroup, group]);
    };

    const handleChangeAdd = (group: any) => {
        if (newGroup.some((el: any) => el.id == group.id)) {
            return setNewGroup(newGroup.filter((item: any) => item.id !== group.id));
        }
        return setNewGroup([...newGroup, { ...group, flag: true }]);
    };

    const handleChangeFlag = (group: any) => {
        setNewGroup(
            newGroup.map((item: any) => {
                if (item.id === group.id) {
                    return { ...item, flag: !item.flag };
                }
                return item;
            }),
        );
    };

    const handleClick = (id: number, group: any) => {
        setOpenId(openId == id || openEditId == id ? null : id);
        setAccountSearchTable(group);
        setOpenEditId(null);
        setAddNewVendor(null);
    };

    const handleModalClose = () => {
        setOpenDelete(false);
        setDataModal({});
    };

    const clickModalHandler = (name: any, id: any) => {
        setDataModal({
            icon: <IconDelete />,
            title: `${t('qwep.batchPricer.wantDelete')} ${name}`,
            props: [],
            shema: null,
            type: 'deleteGroup',
            data: {
                groupId: id,
            },
        } as TypeDataModal);
        setOpenDelete(true);
    };

    const handleDeleteGroup = async (values: any) => {
        const groupsValue = groupsAccountsState.filter((el: any) => el.id != values.groupId);

        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.GroupsAccounts,
            value: { value: groupsValue },
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        } else {
            handleModalClose();
            Alert({
                type: 'success',
                text: 'Группа аккаунтов удалена!',
            });
        }
    };

    const handleEditSubmit = async (id: number) => {
        const groupsValue = groupsAccountsState.map((el: any) => {
            if (el.id == id) {
                return (el = { ...groupsTable.filter((el: any) => el.id == id)[0], group: newGroup });
            } else {
                return el;
            }
        });

        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.GroupsAccounts,
            value: { value: groupsValue },
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        } else {
            Alert({
                type: 'success',
                text: 'Успешно!',
            });
            setOpenEditId(null);
            setOpenId(null);
            setNewGroup([]);
            setAccountTable(fullAccounts);
            setAccountSearchTable([]);
        }
    };

    const handleAddSubmit = async (id: number) => {
        const groupsValue = groupsAccountsState.map((el: any) => {
            if (el.id == id) {
                return (el = {
                    ...groupsTable.filter((el: any) => el.id == id)[0],
                    group: [...groupsTable.filter((el: any) => el.id == id)[0].group, ...newGroup],
                });
            } else {
                return el;
            }
        });
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.GroupsAccounts,
            value: { value: groupsValue },
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        } else {
            Alert({
                type: 'success',
                text: 'Успешно!',
            });
            setOpenEditId(null);
            setOpenId(null);
            setAddNewVendor(null);
            setNewGroup([]);
            setAccountTable(fullAccounts);
            setAccountSearchTable([]);
        }
    };

    const handleAddNewVendor = (id: number) => {
        setAddNewVendor(id);
    };

    const handleCheckbox = async (id: any, flag: any) => {
        const newValue = groupsAccountsState.map((item: any) => {
            if (item.id === id && !flag) {
                return {
                    ...item,
                    flag: true,
                };
            }
            return {
                ...item,
                flag: false,
            };
        });
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.GroupsAccounts,
            value: { value: newValue },
        });
    };

    return (
        <Box className={stls.page}>
            {groupsAccountsState?.length > 0 ? (
                <>
                    <div className={stls.appbarGroup}>
                        <SearchBase
                            data={groupsAccountsState}
                            placeholder="Найти группу"
                            setData={setGroupsTable}
                            type={'groups'}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button
                                id="addGroupsAccounts"
                                onClick={() => navigate('accounts')}
                                className={stls.buttonGroups}
                                variant="contained"
                                startIcon={<AddIcon sx={{ color: 'white' }} />}>
                                {!mobile ? t('groups.createGroup') : 'Создать'}
                            </Button>
                            {mobile && <MobileButtonSettings />}
                        </div>
                    </div>
                    <Card className={stls.listItemHeader} sx={{ background: colors.beta }}>
                        <p style={{ color: colors.omicron }}> {t('groups.myGroups')} </p>
                    </Card>

                    {groupsTable?.map((item: any) => (
                        <div key={item.id}>
                            <Card className={stls.listItemGroups}>
                                <Grid
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                    key={item.id}
                                    spacing={2}
                                    container>
                                    <Grid sx={{ display: 'flex', alignItems: 'center', gap: '12px' }} item xs={9}>
                                        <Checkbox
                                            onChange={() => {
                                                handleCheckbox(item.id, item.flag);
                                            }}
                                            checked={item.flag}
                                        />
                                        <IconButton
                                            id="expandGroupAccount"
                                            sx={{
                                                backgroundColor: currentTheme === 'dark' ? '#69DE9420' : '#E5F8EB',
                                                width: 28,
                                                height: 28,
                                            }}
                                            className={stls.groupIcon}
                                            onClick={() => {
                                                //Todo сюда нужно повесить все настройки по группе
                                                handleClick(item.id, item.group);
                                            }}>
                                            {openId == item.id ? (
                                                <ExpandLess
                                                    sx={{ color: currentTheme === 'dark' ? '#69DE94' : colors.gamma }}
                                                />
                                            ) : (
                                                <ExpandMore
                                                    sx={{ color: currentTheme === 'dark' ? '#69DE94' : colors.gamma }}
                                                />
                                            )}
                                        </IconButton>
                                        <p style={{ color: colors.omicron }}>{item.name}</p>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div
                                                id="editGroupsAccounts"
                                                onClick={() => {
                                                    //Todo это эдитор, функционал которого нужно перенести выше
                                                    handleOpenEdit(item.id, item.group);
                                                }}>
                                                <IconEditSmall />
                                            </div>
                                            <div
                                                id="deleteGroupsAccounts"
                                                style={{ margin: '0 16px' }}
                                                onClick={() => {
                                                    clickModalHandler(item.name, item.id);
                                                }}>
                                                <IconClose />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>

                            <Collapse in={openId == item.id || openEditId == item.id} timeout="auto" unmountOnExit>
                                <div className={stls.groupsContainer}>
                                    <div className={stls.myGroupsGrid}>
                                        {accountSearchTable?.map((elem: any, idx: any) => (
                                            <Grid key={elem.id} spacing={2} container className={stls.listItemVendor}>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={1}>
                                                    <Checkbox
                                                        id={'checkboxEdit' + idx}
                                                        checked={
                                                            openEditId
                                                                ? newGroup.filter((gr: any) => gr.id == elem.id)[0]
                                                                      ?.flag
                                                                : elem.flag
                                                        }
                                                        disabled={openEditId == item.id ? false : true}
                                                        onChange={(e) => {
                                                            handleChangeFlag(elem);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <p
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            color: colors.omicron,
                                                            overflow: 'hidden',
                                                            textDecoration: openEditId
                                                                ? newGroup.some((group: any) => group.id == elem.id)
                                                                    ? 'none'
                                                                    : 'line-through'
                                                                : 'none',
                                                        }}>
                                                        {elem.login}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <p
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            color: colors.omicron,
                                                            overflow: 'hidden',
                                                        }}>
                                                        {elem.title}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {openEditId == item.id && (
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <button
                                                                id="closeIconEdit"
                                                                style={{ margin: '0 16px' }}
                                                                onClick={(e) => {
                                                                    handleChange(elem);
                                                                }}>
                                                                <IconCloseNotBG />
                                                            </button>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                    <div className={stls.myGroupsGrid}>
                                        {addNewVendor == item.id &&
                                            accontTable
                                                .filter(
                                                    (it: any) => !item.group?.some((group: any) => group.id == it.id),
                                                )
                                                .map((acc: any) => (
                                                    <Grid
                                                        key={acc.id}
                                                        spacing={2}
                                                        container
                                                        className={stls.listItemVendor}>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid item xs={1}>
                                                            <Checkbox
                                                                checked={newGroup.some(
                                                                    (group: any) => group.id == acc.id,
                                                                )}
                                                                onChange={(e) => {
                                                                    handleChangeAdd(acc);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <p
                                                                style={{
                                                                    color: colors.omicron,
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                }}>
                                                                {acc.login}
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <p
                                                                style={{
                                                                    color: colors.omicron,
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                }}>
                                                                {acc.title}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                    </div>
                                    <Grid container className={stls.listItemButton}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={7}>
                                            {openEditId == item.id ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        gap: '20px',
                                                    }}>
                                                    <SearchBase
                                                        data={item.group}
                                                        placeholder="Найти поставщика"
                                                        setData={setAccountSearchTable}
                                                        type={'account'}
                                                    />
                                                    <Button
                                                        disabled={!newGroup.length}
                                                        onClick={() => handleEditSubmit(item.id)}
                                                        className={stls.buttonGroup}
                                                        size="small"
                                                        variant="contained">
                                                        {t('groups.saveChanges')}
                                                    </Button>
                                                    <Button
                                                        onClick={handleCancel}
                                                        className={stls.buttonGroup}
                                                        variant="outlined"
                                                        sx={{ border: '2px solid !important' }}
                                                        size="small">
                                                        Отмена
                                                    </Button>
                                                </div>
                                            ) : addNewVendor == item.id ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        gap: '20px',
                                                    }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <SearchBase
                                                            data={fullAccounts}
                                                            placeholder="Найти поставщика"
                                                            setData={setAccountTable}
                                                            type={'accountsGroup'}
                                                        />
                                                    </div>
                                                    <Button
                                                        disabled={!newGroup.length}
                                                        onClick={() => handleAddSubmit(item.id)}
                                                        className={stls.buttonGroup}
                                                        size="small"
                                                        variant="contained">
                                                        {t('groups.saveChanges')}
                                                    </Button>
                                                    <Button
                                                        onClick={handleCancel}
                                                        className={stls.buttonGroup}
                                                        size="small"
                                                        variant="outlined"
                                                        sx={{ border: '2px solid !important' }}>
                                                        Отмена
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Button
                                                    id="addNewAccounts"
                                                    className={stls.buttonGroup}
                                                    size="small"
                                                    variant="outlined"
                                                    startIcon={<AddIcon sx={{ color: colors.alpha }} />}
                                                    onClick={() => handleAddNewVendor(item.id)}>
                                                    {t('groups.addVendors')}
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </>
            ) : (
                <>
                    <div className={stls.appbarAccounts}>
                        <Button
                            id="addGroupsAccounts"
                            onClick={() => navigate('accounts')}
                            className={stls.buttonGroup}
                            variant="contained"
                            startIcon={<AddIcon sx={{ color: 'white' }} />}>
                            {t('groups.createGroup')}
                        </Button>
                        {mobile && <MobileButtonSettings />}
                    </div>
                    <Card>
                        <CardContent className={stls.cardGroup}>
                            <IconNotGroup />
                            <div style={{ display: 'flex' }}>
                                <Typography variant="h6" sx={{ color: colors.phi, marginRight: '8px' }}>
                                    {t('groups.notGroups')}
                                </Typography>
                                <ErrorOutlineOutlinedIcon fontSize="medium" sx={{ color: colors.phi }} />
                            </div>
                        </CardContent>
                    </Card>
                </>
            )}
            <GeneralModal
                openModal={openDelete}
                closeModal={handleModalClose}
                dataModal={dataModal}
                sendDataModalHandler={handleDeleteGroup}
            />
        </Box>
    );
}
