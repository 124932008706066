import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconKeyProps = TypeClassNames;

export const IconKey = ({ classNames }: TypeIconKeyProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <path
                    d="M22.7578 23.828L30.6068 15.979L32.0208 17.393L30.6068 18.808L33.0808 21.282L31.6668 22.697L29.1918 20.222L27.7778 21.636L29.8988 23.757L28.4848 25.172L26.3638 23.05L24.1718 25.242C24.8522 26.276 25.1251 27.5256 24.9377 28.7491C24.7503 29.9727 24.1158 31.0832 23.1571 31.8661C22.1983 32.649 20.9833 33.0486 19.747 32.9876C18.5107 32.9267 17.3409 32.4094 16.4638 31.536C15.5848 30.6603 15.0628 29.4891 14.9992 28.25C14.9357 27.0109 15.3351 25.7925 16.1198 24.8314C16.9045 23.8704 18.0186 23.2354 19.2454 23.05C20.4721 22.8646 21.7241 23.1419 22.7578 23.828ZM22.1208 30.121C22.4121 29.8455 22.6452 29.5143 22.8064 29.1471C22.9675 28.7799 23.0534 28.3842 23.059 27.9832C23.0646 27.5823 22.9897 27.1843 22.8388 26.8128C22.688 26.4412 22.4641 26.1037 22.1806 25.8202C21.8971 25.5367 21.5596 25.3128 21.188 25.162C20.8165 25.0111 20.4185 24.9362 20.0176 24.9418C19.6166 24.9474 19.2209 25.0333 18.8537 25.1944C18.4865 25.3556 18.1553 25.5887 17.8798 25.88C17.3333 26.4458 17.0309 27.2036 17.0378 27.9902C17.0446 28.7768 17.3601 29.5292 17.9163 30.0855C18.4726 30.6417 19.225 30.9572 20.0116 30.964C20.7982 30.9709 21.556 30.6685 22.1218 30.122L22.1208 30.121Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
