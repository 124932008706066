import stls from '@f_styles/components/user-table/UserTable.module.sass';
import { Avatar } from '@mui/material';

export const CellAvatar = ({ info }: any) => {
    return (
        <div className={stls.cellAvatar}>
            <Avatar
                alt={info.row.original.firstName}
                src={info.row.original.photo ? info.row.original.photo : '/static/images/avatar/1.jpg'}
                className={stls.avatar}
            />
            <p className={stls.name}>{`${info.row.original.firstName} ${info.row.original.lastName}`}</p>
        </div>
    );
};
