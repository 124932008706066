import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconAlertWarningProps = TypeClassNames;

export const IconAlertWarning = ({ classNames }: TypeIconAlertWarningProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 13V15H11V13H9ZM9 5V11H11V5H9Z"
                    fill="#F08E13"
                />
            </svg>
        </div>
    );
};
