import { useContext } from 'react';
import { QwepContext } from '../context/Qwep_context';
import { AccountsContext } from '../context/Accounts_context';
import { Alert } from '@f_general/alert';

export const useValidAccounts = () => {
    const { excludePromo } = useContext(QwepContext);
    const { accounts: data } = useContext(AccountsContext);
    const accounts = data.filter((item: any) => item.enabled && !item.promo && item.id.toString());
    if (accounts.length >= 50) {
        Alert({
            type: 'warning',
            text: `Превышен лимит аккаунтов в одном запросе (${accounts.length} из 50)`,
            singleShow: true,
        });
        return false;
    }
    if (!accounts.length && excludePromo) {
        Alert({
            type: 'warning',
            text: `Нет активных аккаунтов для начала поиска: (${accounts.length} из 50)`,
            singleShow: true,
        });
        return false;
    }

    return accounts ?? [];
};
