import React from 'react';
import { Button } from '@mui/material';

import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const CreateList = () => {
    const store = useAssistantStore();
    const { openModal } = store.assistantModalList;

    return (
        <Button
            variant="contained"
            onClick={() => {
                openModal(ConfigPanelModalType.CREATE);
            }}>
            + Создать список
        </Button>
    );
};
