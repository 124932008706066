import { SearchResultsContext } from '../../context/SearchResult_context';
import { MyModal } from '@f_general/MyModal';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, Box, Button, CardContent } from '@mui/material';
import React, { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/searchResult/SearchResult.module.sass';
import { isEmpty } from 'lodash';
import { IconImage } from '../icons/IconImage';
import { QwepContext } from '../../context/Qwep_context';
import { formatToCurrency } from '@f_utils/formatPriceCurrency';
import useWindowSize from '@f_hooks/useWindowSize';
import { IconClose } from '@f_components/icons/IconClose';
import { IconBigMarkdown } from '@f_qwep/components/icons/IconBigMarkdown';
import { IconBigDiscont } from '@f_qwep/components/icons/IconBigDiscont';

export default function CardResult() {
    const { t } = useTranslation();
    const { openCardResult, setOpenCardResult, setOpenAddBasket, cardItem } = useContext(SearchResultsContext);
    const { colors } = React.useContext(ThemeContext);
    const { hideVendorIsActive, priceMarginIsActive } = useContext(QwepContext);

    const { width, height } = useWindowSize();
    const [mobile, setMobile] = React.useState(false);

    React.useEffect(() => {
        setMobile(width < 750);
    }, [width]);

    const handleClose = () => setOpenCardResult(false);
    const handleAddBasket = () => setOpenAddBasket(true);

    const generateFields = (additionalFieldsInput = []) => {
        const fields = {
            brand: t('qwep.search.brand'),
            article: t('qwep.search.article'),
            originalArticle: t('qwep.search.originalArticle'),
            notes: t('qwep.search.notes'),
            warehouse: t('qwep.search.warehouse'),
            origWarehouse: t('qwep.search.origWarehouse'),
            delivery: t('qwep.search.delivery'),
            status: t('qwep.search.status'),
            minQuantity: t('qwep.search.minQuantity'),
            branchTitle: t('qwep.search.branch'),
        };
        const additionalFields = [
            { fieldName: t('qwep.search.vendor'), fieldValue: hideVendorIsActive ? '—' : cardItem?.vendorTitle },
            { fieldName: t('qwep.search.quantity'), fieldValue: cardItem?.quantity?.formatted },
            {
                fieldName: t('qwep.search.price'),
                fieldValue: formatToCurrency((priceMarginIsActive ? +cardItem?.priceWithMargin : +cardItem?.price?.value), cardItem?.price?.currency),
            },
            ...additionalFieldsInput,
        ];

        const renderMainFields = () =>
            Object.keys(fields).map((key: string) => {
                const fieldValue = cardItem[key as keyof typeof fields];
                const fieldName = fields[key as keyof typeof fields];
                if (isEmpty(fieldValue)) return null;
                return (
                    <Grid container key={fieldName} className={stls.cardItem}>
                        <Grid item xs={6} sm={6}>
                            <p>{`${fieldName}:`}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <p style={{ fontWeight: 'bold' }}>{`${fieldValue}`}</p>
                        </Grid>
                    </Grid>
                );
            });
        const renderAdditionalFields = () =>
            additionalFields.map(({ fieldName, fieldValue }) => {
                if (isEmpty(fieldValue)) return null;
                return (
                    <Grid container key={fieldName} className={stls.cardItem}>
                        <Grid item xs={6} sm={6}>
                            <p>{`${fieldName}:`}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <p style={{ fontWeight: 'bold' }}>{`${fieldValue}`}</p>
                        </Grid>
                    </Grid>
                );
            });
        return (
            <>
                {renderMainFields()}
                {renderAdditionalFields()}
            </>
        );
    };

    return (
        <Modal open={openCardResult} onClose={handleClose}>
            <Box>
                <MyModal className={stls.modalCardResult}>
                    <div onClick={handleClose} className={stls.modalClose}>
                        <IconClose />
                    </div>
                    <CardContent>
                        <Grid container direction={mobile ? 'column' : 'row'} spacing={2}>
                            {!(mobile && !cardItem.photo) && (
                                <Grid item xs={5} container direction="row" justifyContent="center" alignItems="center">
                                    <div style={{ display: 'flex', justifyContent: 'left', width: '400px' }}>
                                        {cardItem.info?.isUsed ? <IconBigDiscont color={colors.mu} /> : <></>}
                                        {cardItem.info?.isMarkdown ? (
                                            <IconBigMarkdown classNames={[stls.imgBigMarkdown]} color={colors.green} />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <Box className={stls.image} sx={{ background: colors.delta }}>
                                        {cardItem?.photo ? (
                                            <img src={cardItem.photo} alt={cardItem?.title} />
                                        ) : (
                                            <IconImage />
                                        )}
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={7}>
                                <Typography
                                    style={{ fontSize: '26px' }}
                                    variant={cardItem?.title?.length > 50 ? 'h6' : 'h5'}>
                                    {cardItem?.title}
                                </Typography>
                                <Grid className={stls.cardData}>{generateFields()}</Grid>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center">
                                        <Button
                                            className={stls.modalButton}
                                            onClick={handleClose}
                                            size="large"
                                            sx={{
                                                background: colors.delta,
                                                color: colors.phi,
                                                '&:hover': { color: colors.alpha },
                                            }}>
                                            {t('qwep.search.close')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Button
                                            className={stls.modalButton}
                                            onClick={(e) => {
                                                handleClose();
                                                handleAddBasket();
                                            }}
                                            size="large"
                                            variant="contained"
                                            type="submit">
                                            {t('qwep.search.addBasket')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MyModal>
            </Box>
        </Modal>
    );
}
