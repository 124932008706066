import { Limits } from '@f_crossdata/type';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from './Admin_context';
import useAdminNavigate from '../hooks/useAdminNavigate';

type TypeUpdateQwepApp = {
    content: any;
    authorizationCode: string;
    applicationWorkspaceId: number;
    applicationPropertyValueId: number;
    userId: number;
    workspaceId: number;
    email: string;
};

type TypeAddApps = {
    userId: number;
    workspace: {
        workspaceName: string;
        workspaceId: number;
    };
    type: string;
};

type TypeAddWorkspace = {
    userId: number;
    email: string;
    data: any;
};

type TypeEditWorkspace = {
    isActive: boolean;
    apps: any;
    type: string;
    workspaceName: string;
    email: string;
    workspaceId: number;
    telegramNickname: string;
    createBy: number;
};

type TypeDeleteApplications = {
    workspaceId: number;
    applicationSysName: string;
    applicationWorkspaceId: number;
    email: string;
};

type TypeAddNewUser = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    position: string;
    role: string;
    password: string;
    applications: number[];
    workspaceId: number;
    type: string;
};

type TypeUpdateUser = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
    position: string;
    isActive: boolean;
    userId: number;
    newEmail: string;
};

type TypeSendMessageUser = {
    message: number;
    email: string;
    userId: number;
};

type TypeСreateUserPanel = {
    applications: {
        applicationWorkspaceId: number;
        propertyApplication: object;
        applicationId: number;
    }[];
    userId: number;
    workspaceId: number;
};

type TypeCheckedUserPanel = {
    panelId: number;
    isActive: boolean;
    workspaceId: number;
    applicationWorkspaceId: number;
    applicationId: number;
    propertyApplication: any;
};

type TypeUpdateTariff = {
    applicationWorkspaceId: number;
    workspaceId: number;
    applicationId: number;
    property: any;
    usersToApplication: object[];
};

type TypeUpdateCrossdataApp = {
    applicationWorkspaceId: number;
    authToken: string;
    content?: {
        name: string;
        expire: string;
        limits?: {
            interval: string;
            limit: number;
        }[];
    };
    type: number;
    userId: number;
    workspaceId: number;
};

type TypeUpdateUCSApp = {
    applicationWorkspaceId: number;
    workspaceId: number;
    workspaceName: string;
    paidTime: string;
    authorizationCode: string;
    keys1c: { key: string; alias: string }[];
    keysWeb: { key: string; alias: string }[];
};

const UserAccountContext = createContext<any>({});

function UserAccountProvider(props: any) {
    const navigate = useAdminNavigate();
    const { getAllInfo } = useContext(AdminContext);
    const [userInfo, setUserInfo] = useState<any>();
    const [workspacesInfo, setWorkspacesInfo] = useState<any>();
    const [currentApplication, setCurrentApplication] = useState<any>();
    const [currentWorkspace, setCurrentWorkspace] = useState<any>();
    //info qwep
    const [qwepAppInfo, setQwepAppInfo] = useState<any>();
    const [crossdataAppInfo, setCrossdataAppInfo] = useState<any>();
    const [UCSAppInfo, setUCSAppInfo] = useState<any>();

    const getUserInfo = async (email: string) => {
        const result = await callPlatformAPI(`admin/get/info/${email}`, 'get');
        console.log('getUserInfo', result);
        if (result.data.isOk) {
            setUserInfo(result.data.result.userInfo);
            setWorkspacesInfo(result.data.result.workspacesInfo);
        }
        return result.data;
    };

    const getCrossdataAppInfo = async (authToken: string) => {
        const result = await callPlatformAPI(`admin/get/crossdata/info`, 'post', { authToken });
        console.log('getCrossdataAppInfo', result);
        if (result.data.isOk) {
            setCrossdataAppInfo(result.data.result);
        }
        return result.data;
    };

    const getUCSAppInfo = async (authCode: string) => {
        const result = await callPlatformAPI(`admin/get/ucs/info`, 'post', {
            authCode,
            applicationWorkspaceId: currentApplication.applicationWorkspaceId,
        });
        console.log('getUCSAppInfo', result);
        if (result.data.isOk) {
            setUCSAppInfo(result.data.result);
        }
        return result.data;
    };

    const getQwepAppInfo = async (authorizationCode: string) => {
        const result = await callPlatformAPI(`admin/get/qwep/info`, 'post', { authorizationCode });
        console.log('getQwepAppInfo', result);
        if (result.data.isOk) {
            setQwepAppInfo(result.data.result.entity);
        }
        return result.data;
    };

    const updateQwepApp = async (data: TypeUpdateQwepApp) => {
        const result = await callPlatformAPI(`admin/update/qwep/app`, 'put', data);
        console.log('updateQwepApp', result);
        if (result.data.isOk) {
            if (data.content.type === 1) {
                await getQwepAppInfo(result.data.result.authorizationCode);
            } else if (data.content.type === 2) {
                await getUserInfo(data.email);
            } else if (data.content.type === 3) {
                await getQwepAppInfo(result.data.result.authorizationCode);
                await getUserInfo(data.email);
            } else if (data.content.type === 4) {
                await getQwepAppInfo(result.data.result.authorizationCode);
                await getUserInfo(data.email);
            }
        }
        return result.data;
    };

    const updateCrossdataApp = async (data: TypeUpdateCrossdataApp) => {
        const result = await callPlatformAPI('admin/update/crossdata/app', 'put', data);
        console.log('updateCrossdataApp', result);
        if (result.data.isOk) {
            if (data.type === 1) {
                await getCrossdataAppInfo(result.data.result);
            } else if (data.type === 2) {
                await getUserInfo(userInfo.email);
            }
        }
        return result.data;
    };

    const updateUCSApp = async (data: TypeUpdateUCSApp) => {
        const result = await callPlatformAPI('admin/update/ucs/app', 'put', data);
        console.log('updateUCSApp', result);
        if (result.data.isOk) {
            await getUCSAppInfo(result.data.result);
        }
        return result.data;
    };

    const getUsersToWorkspace = async (workspaceId: number) => {
        const result = await callPlatformAPI(`admin/get/user/workspace/${workspaceId}`, 'get');
        console.log('getUsersOnWorkspace', result);
        return result.data;
    };

    const addAppsToWorkspace = async (data: TypeAddApps) => {
        const result = await callPlatformAPI(`admin/add/apps`, 'post', data);
        console.log('addAppsToWorkspace', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const addWorkspace = async (data: TypeAddWorkspace) => {
        const result = await callPlatformAPI(`admin/add/workspace`, 'post', data);
        console.log('addWorkspace', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const updateWorkspace = async (data: TypeEditWorkspace) => {
        const result = await callPlatformAPI(`admin/update/workspace`, 'put', data);
        console.log('updateWorkspace', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const deleteWorkspace = async (workspaceId: number, email: string) => {
        const result = await callPlatformAPI(`admin/delete/workspace/${workspaceId}`, 'delete');
        console.log('deleteWorkspace', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const deleteApplications = async (data: TypeDeleteApplications) => {
        const result = await callPlatformAPI('admin/delete/application/', 'delete', data);
        console.log('deleteWorkspace', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const addExistingUser = async (data: any) => {
        const result = await callPlatformAPI(`admin/add/existing/user`, 'post', data);
        console.log('addExistingUser', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const addNewUser = async (data: TypeAddNewUser) => {
        const result = await callPlatformAPI(`admin/add/new/user`, 'post', data);
        console.log('addNewUser', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const deleteUser = async (userId: number) => {
        const result = await callPlatformAPI(`admin/delete/user/${userId}`, 'delete');
        console.log('deleteUser', result);
        if (result.data.isOk) {
            setUserInfo(null);
            setWorkspacesInfo(null);
            setQwepAppInfo(null);
            setCurrentApplication(null);
            setCurrentWorkspace(null);
            navigate();
            await getAllInfo();
        }
        return result.data;
    };

    const updateUser = async (data: TypeUpdateUser) => {
        const result = await callPlatformAPI(`admin/update/user`, 'put', data);
        console.log('updateUser', result);
        if (result.data.isOk) {
            await getUserInfo(data.newEmail);
        }
        return result.data;
    };

    const sendMessageUser = async (data: TypeSendMessageUser) => {
        const result = await callPlatformAPI(`admin/send/message/user`, 'post', data);
        console.log('sendMessageUser', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const createUserPanel = async (data: TypeСreateUserPanel) => {
        const result = await callPlatformAPI(`admin/add/panel/user`, 'post', data);
        console.log('createUserPanel', result);
        return result.data;
    };

    const checkedUserPanel = async (data: TypeCheckedUserPanel) => {
        const result = await callPlatformAPI(`admin/checked/panel/user`, 'put', data);
        console.log('checkedUserPanel', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    const updateTariff = async (data: TypeUpdateTariff) => {
        const result = await callPlatformAPI(`admin/apps/update/tariff`, 'put', data);
        console.log('updateTariff', result);
        if (result.data.isOk) {
            await getUserInfo(userInfo.email);
        }
        return result.data;
    };

    return (
        <UserAccountContext.Provider
            value={{
                userInfo,
                workspacesInfo,
                currentApplication,
                currentWorkspace,
                qwepAppInfo,
                crossdataAppInfo,
                UCSAppInfo,

                //methods
                getUCSAppInfo,
                getUserInfo,
                getQwepAppInfo,
                updateUCSApp,
                setCurrentApplication,
                updateQwepApp,
                addAppsToWorkspace,
                getUsersToWorkspace,
                addWorkspace,
                setCurrentWorkspace,
                updateWorkspace,
                deleteWorkspace,
                deleteApplications,
                setQwepAppInfo,
                addExistingUser,
                addNewUser,
                deleteUser,
                updateUser,
                sendMessageUser,
                createUserPanel,
                checkedUserPanel,
                updateTariff,
                getCrossdataAppInfo,
                setCrossdataAppInfo,
                updateCrossdataApp,
                setUCSAppInfo,
            }}
            {...props}
        />
    );
}

export { UserAccountProvider, UserAccountContext };
