import { AssistantDealsStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantDeals.store';
import { AssistantFiltersStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantFilters.store';
import { CustomListModalStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/CustomListModal.store';
import { CustomListInputPositionsModalStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/CustomListInputPositionsModal.store';
import { AssistantListStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantList.store';
import { ConfigEmailModalStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/ConfigEmaiModal.store';
import { AssistantRemoveListStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantRemoveList.store';
import { AssistantAddPositionItemStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantAddPositionItem.store';
import { PreviewTableStore } from '@f_qwep/general/previewTable/model/store/PreviewTable.store';

// preview table store
const previewTableStore = new PreviewTableStore();

// list store
const customListInputPositionsModalStore = new CustomListInputPositionsModalStore();
const assistantModalListStore = new CustomListModalStore(customListInputPositionsModalStore, previewTableStore);
const assistantAddPositionItemStore = new AssistantAddPositionItemStore(assistantModalListStore);

const assistantModalEmailStore = new ConfigEmailModalStore();
const assistantFiltersStore = new AssistantFiltersStore();
const assistantDealsStore = new AssistantDealsStore(assistantFiltersStore);
const assistantListStore = new AssistantListStore(assistantFiltersStore);

const assistantRemoveListStore = new AssistantRemoveListStore(assistantDealsStore);

export class RootAssistantStore {
    assistantList = assistantListStore;
    assistantDeals = assistantDealsStore;
    assistantFilters = assistantFiltersStore;
    assistantModalEmail = assistantModalEmailStore;
    assistantModalList = assistantModalListStore;
    assistantRemoveList = assistantRemoveListStore;
    assistantAddPositionItem = assistantAddPositionItemStore;
}

// TODO Рефакторинг
// Оставил - так как необходимо использовать на уровне выше в контексте
export { assistantListStore };
