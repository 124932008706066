import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export function ColumnArticle({ index, article }:any, idRow: any, isOpenDetails: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <div
            style={{
                color: idRow !== index && isOpenDetails ? colors.blurColor : colors.alpha,
                fontWeight: 'bold',
            }}
            className={stls.cell}>
            {article}
        </div>
    );
}