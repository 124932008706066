import cn from 'classnames';

type TypeIconSuccessAssistantTableProps = {
    classNames?: string[];
    color: string;
};

export const IconSuccessAssistantTable = ({ classNames, color }: TypeIconSuccessAssistantTableProps) => {
    return (
        <span className={cn(classNames)}>
            <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.4998 22C6.20696 22 1.9165 17.523 1.9165 12C1.9165 6.477 6.20696 2 11.4998 2C16.7927 2 21.0832 6.477 21.0832 12C21.0832 17.523 16.7927 22 11.4998 22ZM10.5444 16L17.3198 8.929L15.9647 7.515L10.5444 13.172L7.83325 10.343L6.47817 11.757L10.5444 16Z"
                    fill={color}
                />
            </svg>
        </span>
    );
};
