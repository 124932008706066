import React, { useContext } from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { Button, Tooltip } from '@mui/material';
import cn from 'classnames';
import { IconLowAssistant } from '@f_qwep/components/icons/IconLowAssistant';
import { IconMediumAssistant } from '@f_qwep/components/icons/IconMediumAssistant';
import { IconHighAssistant } from '@f_qwep/components/icons/IconHighAssistant';
import { observer } from 'mobx-react-lite';
import { ThemeContext } from '@f_context/Theme_context';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const FilterDeviations = observer(() => {
    const store = useAssistantStore();
    const { isActiveLow, isActiveMedium, isActiveHard, setIsActiveLow, setIsActiveMedium, setIsActiveHard } =
        store.assistantFilters;
    const { colors } = useContext(ThemeContext);

    return (
        <div className={styles.filterDeviation}>
            <Tooltip title={'Малое ценовое отклонение'}>
                <Button
                    className={cn(styles.btn, styles.filterAction)}
                    onClick={setIsActiveLow}
                    style={{
                        borderColor: isActiveLow ? colors.lightgreen : colors.epsilon,
                        color: isActiveLow ? colors.lightgreen : colors.epsilon,
                    }}>
                    <IconLowAssistant color={isActiveLow ? colors.lightgreen : colors.epsilon} />
                    <div className={styles.text}>Low</div>
                </Button>
            </Tooltip>
            <Tooltip title={'Среднее ценовое отклонение'}>
                <Button
                    className={cn(styles.btn, isActiveMedium && styles.activeBtn)}
                    onClick={setIsActiveMedium}
                    style={{
                        borderColor: isActiveMedium ? colors.eta : colors.epsilon,
                        color: isActiveMedium ? colors.eta : colors.epsilon,
                    }}>
                    <IconMediumAssistant color={isActiveMedium ? colors.eta : colors.epsilon} />
                    <div className={styles.text}>Medium</div>
                </Button>
            </Tooltip>
            <Tooltip title={'Высокое ценовое отклонение'}>
                <Button
                    className={cn(styles.btn, isActiveHard && styles.activeBtn)}
                    onClick={setIsActiveHard}
                    style={{
                        borderColor: isActiveHard ? colors.lambda : colors.epsilon,
                        color: isActiveHard ? colors.lambda : colors.epsilon,
                    }}>
                    <IconHighAssistant color={isActiveHard ? colors.lambda : colors.epsilon} />
                    <div className={styles.text}>High</div>
                </Button>
            </Tooltip>
        </div>
    );
});
