import { ThemeContext } from '@f_context/Theme_context';
import { MyModal } from '@f_general/MyModal';
import { CardContent, Modal, TextField, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import styles from '@f_qwep/styles/component/price/PriceModal.module.sass';
import { Alert } from '@f_general/alert';
import { PriceContext } from '@f_qwep/context/Price_context';
import { FileValidationComponentEnum } from '@f_qwep/types/file/validation/FileValidationComponent.enum';
import { FileUtil } from '@f_qwep/utils/file/file.util';
import { usePreviewTableStore } from '@f_qwep/general/previewTable/hook/usePreviewTable.store';
import { observer } from 'mobx-react-lite';
import { XmlModalContentPrice } from '@f_qwep/components/prices/priceModal/updateOrAdd/content/XmlModalContentPrice';
import { IconSimpleXML } from '@f_qwep/components/icons/IconSimpoleXML';
import { FormModalContentPrice } from '@f_qwep/components/prices/priceModal/updateOrAdd/content/FormModalContentPrice';
import { useTranslation } from 'react-i18next';

type TypeProps = {
    open: boolean;
    handleClose: any;
    adminCode?: string;
    email?: string;
    type: 'add' | 'update';
};

export const UpdateOrAddPriceModal = observer(({ open, handleClose, adminCode, email, type }: TypeProps) => {
    const { colors } = useContext(ThemeContext);
    const { t } = useTranslation();
    const { updatePrice, createPrice, offsting, xmlParse, setOffsting, resetOffsting } = useContext(PriceContext);
    const [checkXLS, setCheckXLS] = useState(true);
    const [data, setData] = useState({});
    const [file, setFile] = useState<File | null>();
    const [link, setLink] = useState<string>('');
    const [xml, setXML] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [errorSendFile, setErrorSendFile] = useState<boolean>(false);
    const [titleFile, setTitleFile] = useState<string>('');
    const [openXML, setOpenXML] = useState<boolean>(false);
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [isOpenExampleXMLModal, setIsOpenExampleXMLModal] = useState<boolean>(false);

    const {
        handleClose: closePreviewTable,
        handleOpen: showPreviewTable,
        setTableData,
        setHeaders,
        isOpen: isShowPreview,
        resetStore,
    } = usePreviewTableStore();

    useEffect(() => {
        checkXLS ? setLink('') : resetFileData();
    }, [checkXLS]);

    useEffect(() => {
        setIsDisable(offsting === '[0,0]');
    }, [offsting]);

    const resetFileData = () => {
        setFile(null);
        setTitleFile('');
        setIsDisable(true);
        resetStore();
    };

    const handleChange = () => {
        if (!checkXLS) {
            resetFileData();
        }

        setCheckXLS((prev) => !prev);
        resetOffsting();
    };

    useEffect(() => {
        if (type === 'add') {
            setData({
                vendor: '',
                email: '',
                currency: 'RUB',
                link: '',
            });
        }
        if (type === 'update') {
            setData({
                email: email,
                currency: 'RUB',
                link: '',
            });
        }
    }, []);

    useEffect(() => {
        resetOffsting();
    }, [link]);

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsDisable(true);
        if (e.target.files) {
            const file = e.target.files[0];
            const fileUtil = new FileUtil(file, FileValidationComponentEnum.PRICE);
            const { title, dataJSON, isValid, tableHeaders } = await fileUtil.getFileData();
            if (isValid) {
                setFile(file);
                setTitleFile(title);
                setErrorSendFile(false);
                setHeaders(tableHeaders);
                setTableData(dataJSON);
                setIsDisable(false);
            } else {
                resetFileData();
            }
        }
    };

    const handleLinkChange = (e: any) => {
        setLink(e.target.value);
        resetOffsting();
    };

    const handleNavigateBack = () => {
        closePreviewTable();
    };

    const closeModal = () => {
        setData({});
        setCheckXLS(true);
        handleClose();
        setLink('');
        setOffsting('[0,0]');
        closePreviewTable();
        resetFileData();
    };

    useEffect(() => {
        setLink('');
    }, [open]);

    const handleLoadXml = async (values: any) => {
        setLoading(true);
        const xml = await xmlParse({ link: link });
        if (xml?.result !== 'parsing is not finished') {
            setXML(xml?.result);
            setOpenXML(true);
            setLoading(false);
        }
    };

    const sendDataModalHandler = async (values: any) => {
        if (!isShowPreview && offsting === '[0,0]') {
            showPreviewTable();
            return;
        }

        setLoading(true);
        let data;
        if (checkXLS) {
            if (!file) {
                setErrorSendFile(true);
                setLoading(false);
                return;
            } else {
                setErrorSendFile(false);
            }
            data = {
                ...(values.vendor && { vendor: values.vendor }),
                email: values.email,
                currency: values.currency ?? 'RUB',
                file: file,
            };
        } else {
            data = {
                ...(values.vendor && { vendor: values.vendor }),
                email: values.email,
                currency: values.currency ?? 'RUB',
                link: link,
                offsting: offsting,
            };
        }

        let result;
        if (type === 'update') {
            result = await updatePrice({ ...data, adminCode });
        }
        if (type === 'add') {
            result = await createPrice(data);
            console.log(result, 'result');
            setOffsting('[0,0]');
        }

        if (result.isOk) {
            Alert({
                type: 'success',
                text: type === 'update' ? 'Прайс успешно обновлен' : 'Прайс успешно зарегестрирован',
                time: 8000,
            });
            closeModal();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoading(false);
    };

    return (
        <>
            <Modal
                style={{ zIndex: '100000' }}
                open={isOpenExampleXMLModal}
                onClose={() => setIsOpenExampleXMLModal(false)}>
                <MyModal>
                    <IconSimpleXML />
                </MyModal>
            </Modal>
            <Modal open={open} onClose={closeModal}>
                <MyModal className={styles.modal}>
                    <CardContent className={styles.modalContent}>
                        <XmlModalContentPrice
                            open={openXML}
                            handleClose={() => setOpenXML(false)}
                            xml={xml}
                            setIsOpenExampleXMLModal={setIsOpenExampleXMLModal}
                        />
                        <FormModalContentPrice
                            openXML={openXML}
                            isShowPreview={isShowPreview}
                            type={type}
                            checkXLS={checkXLS}
                            isDisable={isDisable}
                            data={data}
                            handleChange={handleChange}
                            handleNavigateBack={handleNavigateBack}
                            colors={colors}
                            handleLoadXml={handleLoadXml}
                            loading={loading}
                            sendDataModalHandler={sendDataModalHandler}
                            closeModal={closeModal}
                            errorSendFile={errorSendFile}
                            handleLinkChange={handleLinkChange}
                            link={link}
                            handleFileChange={handleFileChange}
                            offsting={offsting}
                            titleFile={titleFile}
                        />
                    </CardContent>
                </MyModal>
            </Modal>
        </>
    );
});
