import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIcon1cProps = {
    classNames?: string[];
    color?: string;
};

export const Icon1c = ({ classNames, color = colors.tau }: TypeIcon1cProps) => {
    return (
        <div className={cn(classNames)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <path fill={color} d="M0 7L1 7 1 12 2 12 2 6 0 6z" />
                <path
                    fill={color}
                    d="M1 5L3 5 3 12 4 12 4 4 1 4zM6 8c0-1.654 1.346-3 3-3s3 1.346 3 3h1c0-2.206-1.794-4-4-4S5 5.794 5 8s1.794 4 4 4h7v-1H9C7.346 11 6 9.654 6 8z"
                />
                <path
                    fill={color}
                    d="M9,9C8.449,9,8,8.552,8,8s0.449-1,1-1s1,0.448,1,1h1c0-1.103-0.897-2-2-2S7,6.897,7,8s0.897,2,2,2h7 V9H9z"
                />
            </svg>
        </div>
    );
};
