import { observer } from 'mobx-react-lite';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { InputGetShortNumbers } from '@f_qwep/components/batchPricer/InputGetShortNumbers';
import { Button, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import AddIcon from '@mui/icons-material/Add';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';
import { useTranslation } from 'react-i18next';
import { IColorsProps } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingProps.interface';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const PositionLoadingManual: FC<IColorsProps> = observer(({ colors }) => {
    const { t } = useTranslation();
    const store = useAssistantStore();
    const { currentPositionLoadingOption: currentOption, inputPositionStore } = store.assistantModalList;
    const { position, addPosition, removePosition, changeInputPositionDouble, changeInputPosition, addPositionItem } =
        inputPositionStore;
    const { MANUAL_INPUT } = PositionLoadingOptionsEnum;

    return currentOption === MANUAL_INPUT ? (
        <>
            <div className={styles.items}>
                {position.map((item) => {
                    return (
                        <div className={styles.row} key={item.id}>
                            {item.autoInput ? (
                                <InputGetShortNumbers
                                    styles={{
                                        width: 420,
                                        '& .MuiInputBase-root': {
                                            padding: '10px 10px 9px 10px !important',
                                        },
                                    }}
                                    itemId={item.id}
                                    defaultValue={`${item.article} ${item.brand}`}
                                    actionHandler={addPositionItem}
                                    label={t('qwep.batchPricer.enterArticle')}
                                />
                            ) : (
                                <>
                                    <div>
                                        <TextField
                                            label={'Артикул'}
                                            className={styles.input}
                                            type="text"
                                            name="article"
                                            value={item.article}
                                            focused
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                changeInputPositionDouble(e.target.value, item.id, 'article')
                                            }
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            className={styles.input}
                                            type="text"
                                            name="brand"
                                            label={'Бренд'}
                                            value={item.brand}
                                            focused
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                changeInputPositionDouble(e.target.value, item.id, 'brand')
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            <button
                                className={styles.btnChangeItem}
                                type="button"
                                onClick={() => changeInputPosition(item.id, !item.autoInput)}>
                                <ChangeCircleIcon className={styles.icon} sx={{ color: colors.phi }} />
                            </button>

                            <button
                                className={styles.btnDelItem}
                                type="button"
                                disabled={position.length <= 1}
                                onClick={() => removePosition(item.id)}>
                                <DoNotDisturbOnIcon
                                    className={styles.icon}
                                    sx={{
                                        color: position.length <= 1 ? colors.sigma : colors.phi,
                                    }}
                                />
                            </button>
                        </div>
                    );
                })}
            </div>
            <Button className={styles.addBtn} sx={{ background: colors.gamma }} onClick={addPosition}>
                <AddIcon sx={{ color: colors.tau }} />
            </Button>
        </>
    ) : null;
});
