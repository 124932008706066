import { Button, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/price/Price.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { CellVendor } from './CellVendor';
import { CellPrices } from './CellPrices';
import { CellDate } from './CellDate';
import { CellStatus } from './CellStatus';
import { CellSettings } from './CellSettings';
import { CellUpdate } from './CellUpdate';
import { CellType } from './CellType';
import { LayoutPage } from '../../general/layout/LayoutPage';
import { useWindowSize } from '@f_hooks';
import { PriceContext } from '@f_qwep/context/Price_context';
import { SearchBase } from '@f_general/SearchBase';
import { LoadingSettings } from '../loaders/LoadingSettings';
import { UpdateOrAddPriceModal } from './priceModal/updateOrAdd/UpdateOrAddPriceModal';
import { PageNoData } from '@f_qwep/general/PageNoData';
import { CellMobileCart } from './CellMobileCart';
import { CellAdminCode } from './CellAdminCode';

export function Prices() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { prices, isLoading, openAdd, setOpenAdd, setOffsting } = useContext(PriceContext);
    const [mobile, setMobile] = useState(false);
    const { width } = useWindowSize();
    const [priceTable, setPriceTable] = useState(prices);

    useEffect(() => {
        setPriceTable(prices);
    }, [prices]);

    useEffect(() => {
        setMobile(width < 900);
    }, [width]);

    const handleCloseAdd = () => {
        setOpenAdd(false);
        setOffsting('[0,0]');
    };

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'price',
                          accessorKey: 'price',
                          header: () => (
                              <div style={{ display: 'flex' }}>
                                  <Checkbox />
                                  <p>{t('qwep.prices.pricelist')}</p>
                              </div>
                          ),
                          cell: (info) => <CellMobileCart info={info} />,
                          footer: (props) => props.column.id,
                      },
                  ]
                : [
                      {
                          id: 'vendor',
                          accessorKey: 'vendor',
                          header: () => (
                              <div style={{ display: 'flex' }}>
                                  {/* <Checkbox /> */}
                                  <p>{t('qwep.prices.vendor')}</p>
                              </div>
                          ),
                          cell: (info) => <CellVendor info={info} />,
                          footer: (props) => props.column.id,
                          size: 110,
                      },
                      {
                          id: 'price',
                          accessorKey: 'price',
                          header: () => <p>{t('qwep.prices.pricelist')}</p>,
                          cell: (info) => <CellPrices info={info} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'adminCode',
                          accessorKey: 'adminCode',
                          header: () => `${t('qwep.prices.adminCode')}`,
                          cell: (info) => <CellAdminCode info={info} />,
                          footer: (props) => props.column.id,
                          size: 90,
                      },
                      {
                          id: 'viewCode',
                          accessorKey: 'viewCode',
                          header: () => `${t('qwep.prices.viewCode')}`,
                          cell: (info) => <p className={stls.cellStatus}>{info.row.original.viewCode}</p>,
                          footer: (props) => props.column.id,
                          size: 90,
                      },
                      {
                          id: 'date',
                          accessorKey: 'date',
                          header: () => `${t('qwep.prices.date')}`,
                          cell: (info) => <CellDate info={info} />,
                          footer: (props) => props.column.id,
                          size: 80,
                          sortingFn: (a: any, b: any) => {
                              const dateA = new Date(b.original.lastUpdated);
                              const dateB = new Date(a.original.lastUpdated);
                              return +dateA - +dateB;
                          },
                      },
                      {
                          id: 'status',
                          accessorKey: 'status',
                          header: `${t('qwep.prices.status')}`,
                          cell: (info) => <CellStatus info={info} />,
                          footer: (props) => props.column.id,
                          size: 120,
                          sortingFn: (a: any, b: any) => {
                              function sortresult(i: any) {
                                  if (i === 'PUBLISHED') {
                                      return 1;
                                  } else if (i === 'EMPTY') {
                                      return 2;
                                  } else if (i === 'UNCLASSIFIED') {
                                      return 3;
                                  } else {
                                      return 4;
                                  }
                              }
                              return sortresult(a.original.status) - sortresult(b.original.status);
                          },
                      },
                      {
                          id: 'update',
                          accessorKey: 'update',
                          header: `${t('qwep.prices.update')}`,
                          cell: (info) => <CellUpdate info={info} />,
                          footer: (props) => props.column.id,
                          size: 60,
                      },
                      {
                          id: 'type',
                          accessorKey: 'type',
                          header: `${t('qwep.prices.type')}`,
                          cell: (info) => <CellType info={info} />,
                          footer: (props) => props.column.id,
                          size: 60,
                      },
                      {
                          id: 'settings',
                          accessorKey: '',
                          header: `${t('qwep.prices.setting')}`,
                          cell: (info) => <CellSettings info={info} />,
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                  ],

        [prices, mobile],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '48px',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <LayoutPage>
            {isLoading ? (
                <LoadingSettings />
            ) : (
                <>
                    {prices.length > 0 ? (
                        <>
                            <div className={stls.appbarPrices}>
                                <div style={{ display: 'flex' }}>
                                    <SearchBase
                                        data={prices}
                                        placeholder={t('qwep.prices.searchPrice')}
                                        setData={setPriceTable}
                                        type={'price'}
                                    />
                                </div>
                                <Button
                                    id="addNewPrice"
                                    onClick={() => setOpenAdd(true)}
                                    variant="contained"
                                    startIcon={<AddIcon sx={{ color: 'white' }} />}>
                                    <p className={stls.textButton}>
                                        {mobile ? t('qwep.batchPricer.create') : t('qwep.prices.createPrice')}
                                    </p>
                                </Button>
                            </div>
                            <VirtualTable
                                data={priceTable}
                                columnsTable={columns}
                                topFilterPanel={true}
                                showColumnSearchAction={false}
                                heightTable={700}
                                configHeader={{
                                    fullScrean: false,
                                    exportExcel: false,
                                    resize: false,
                                    grouping: false,
                                    columnFilter: false,
                                    sorting: true,
                                    columnSearch: true,
                                    dragging: false,
                                }}
                                styles={tableStyles}
                            />
                        </>
                    ) : (
                        <>
                            <div style={{ justifyContent: 'end' }} className={stls.appbarPrices}>
                                <Button
                                    id="addNewPrice"
                                    onClick={() => setOpenAdd(true)}
                                    variant="contained"
                                    startIcon={<AddIcon sx={{ color: 'white' }} />}>
                                    {t('qwep.prices.createPrice')}
                                </Button>
                            </div>
                            <PageNoData type={'price'} />
                        </>
                    )}
                    <UpdateOrAddPriceModal open={openAdd} handleClose={handleCloseAdd} type={'add'} />
                </>
            )}
        </LayoutPage>
    );
}
