export enum Property {
    ExcludePromo = 'qwep_exclude_promo',
    Margin = 'qwep_margin',
    MiniTable = 'qwep_mini_table',
    GroupsAccounts = 'qwep_groups_accounts',
    BatchPricerStrictMode = 'qwep_bp_strict',
    QwepManual = 'qwep_manual',
    AssistantConfigList = 'qwep_assistant_config_list',
    AssistantCustomList = 'qwep_custom_list',
    AssistantEmailList = 'qwep_assistant_email_list',
    OrderDraft = 'qwep_order_draft',
    Filter1C = 'filter_1C',
    DraftFilterSearch = 'qwep_draft_filters',
    QwepCrosses = 'qwep_crosses',
    CrossesPrice = 'qwep_crosses_price',
    BatchPricerInStock = 'qwep_bp_instock',
    ColumnVisibility = 'qwep_column_visibility',
    PromoNotification = 'qwep_notification'
}
