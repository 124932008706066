import React, { useContext, useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ThemeContext } from '@f_context/Theme_context';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { observer } from 'mobx-react-lite';
import { useRemoveCustomList } from '@f_qwep/components/purchasingAssistant/model/hooks/useRemoveCustomList';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { UserContext } from '@f_context/User_context';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const CurrentListActions = observer(() => {
    const limitAddPositions = 500;
    const store = useAssistantStore();
    const { mutate: removeList } = useRemoveCustomList({ store });
    const { colors } = useContext(ThemeContext);
    const { openModal } = store.assistantModalList;
    const { isShowCurrentListActions, setIsShowCurrentListActions } = store.assistantRemoveList;

    const { deals } = store.assistantDeals;

    const { userInfo } = useContext(UserContext);
    const { currentAssistantList } = store.assistantList;
    const { parentId } = currentAssistantList;

    useEffect(() => {
        setIsShowCurrentListActions(currentAssistantList && parentId === userInfo.userId);
    }, [currentAssistantList, userInfo]);

    return (
        <div className={styles.portraitActionsWrapper}>
            {isShowCurrentListActions && (
                <>
                    {deals.length <= limitAddPositions && (
                        <Tooltip title={'Добавить позицию'}>
                            <IconButton
                                className={styles.paramBtn}
                                onClick={() => {
                                    openModal(ConfigPanelModalType.ADD);
                                }}>
                                <AddCircleIcon style={{ color: colors.gamma, width: '35px' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={'Редактировать'}>
                        <IconButton className={styles.paramBtn} onClick={() => openModal(ConfigPanelModalType.UPDATE)}>
                            <BorderColorIcon style={{ color: colors.alpha, width: '35px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Удалить'}>
                        <IconButton className={styles.paramBtn} onClick={() => removeList()}>
                            <DeleteForeverIcon style={{ color: colors.lambda, width: '35px' }} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </div>
    );
});
