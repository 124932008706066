import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from '@f_qwep/styles/component/price/PriceModal.module.sass';
import { IconAddPrice } from '@f_qwep/components/icons/IconAddPrice';
import { Button, CircularProgress, Switch, Typography } from '@mui/material';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import {
    addPriceModalCustomerSchema,
    updatePriceModalCustomerSchema,
} from '@f_general/formik/helpers/ValidationSchema';
import { PreviewDownloadedFileTable } from '@f_qwep/general/previewTable/ui/PreviewDownloadedFileTable';
import { FormikController } from '@f_general/formik/FormikController';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AddFileOrLinkPrice } from '@f_qwep/components/prices/priceModal/updateOrAdd/content/AddFileOrLinkPrice';
import { usePreviewTableStore } from '@f_qwep/general/previewTable/hook/usePreviewTable.store';

interface IPriceFormModalContentProps {
    openXML: boolean;
    isShowPreview: boolean;
    type: string;
    checkXLS: boolean;
    isDisable: boolean;
    data: any;
    colors: any;
    handleChange: () => void;
    handleNavigateBack: () => void;
    handleLoadXml: (values: any) => Promise<void>;
    loading: boolean;
    offsting: string;
    sendDataModalHandler: (values: any) => Promise<void>;
    closeModal: () => void;
    errorSendFile: boolean;
    handleLinkChange: (e: ChangeEvent<HTMLInputElement>) => void;
    link: string;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    titleFile: string;
}

export const FormModalContentPrice: FC<IPriceFormModalContentProps> = observer(
    ({
        openXML,
        isShowPreview,
        type,
        checkXLS,
        isDisable,
        data,
        handleChange,
        handleNavigateBack,
        colors,
        handleLoadXml,
        loading,
        offsting,
        sendDataModalHandler,
        closeModal,
        errorSendFile,
        handleLinkChange,
        link,
        handleFileChange,
        titleFile,
    }) => {
        const { t } = useTranslation();
        const [isDisableXMLBtn, setIsDisableXMLBtn] = useState(false);

        const { getHeaders, getTableData } = usePreviewTableStore();

        useEffect(() => {
            setIsDisableXMLBtn(loading || offsting !== '[0,0]' || !link);
        }, [loading, offsting, link]);

        // добавление display none - костыль,чтобы форма не сбрасывалась при смене контента на xml
        return (
            <div style={{ display: openXML ? 'none' : 'block', overflowX: 'hidden' }}>
                {isShowPreview ? null : (
                    <div className={styles.header}>
                        <div className={styles.modalTitle}>
                            <IconAddPrice />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {type === 'add'
                                    ? t('qwep.price.modal.updateOrAddPriceModal.titleAdd')
                                    : t('qwep.price.modal.updateOrAddPriceModal.titleUpdate')}
                            </Typography>
                        </div>
                        <div className={styles.checkbox}>
                            <Typography>XML</Typography>
                            <Switch id="switchAddPrice" checked={checkXLS} onChange={handleChange} />
                            <Typography>XLS/CSV</Typography>
                        </div>
                    </div>
                )}
                <FormikWrapper
                    onSubmit={sendDataModalHandler}
                    initialValues={data}
                    validShema={type === 'add' ? addPriceModalCustomerSchema : updatePriceModalCustomerSchema}>
                    {isShowPreview && (
                        <PreviewDownloadedFileTable
                            maxRows={6}
                            colors={colors}
                            headers={getHeaders()}
                            rows={getTableData()}
                        />
                    )}
                    {type === 'add' && !isShowPreview && (
                        <>
                            <Typography>{t('qwep.price.modal.updateOrAddPriceModal.vendor')}</Typography>
                            <FormikController
                                className={styles.modalInput}
                                type="text"
                                control="input"
                                name="vendor"
                                placeholder={t('qwep.price.modal.updateOrAddPriceModal.placeholderVendor')}
                                validationWarningIndent="-3px"
                                required
                            />
                        </>
                    )}
                    {!isShowPreview && (
                        <div className={styles.formContent}>
                            <div>
                                <Typography>{t('qwep.price.modal.updateOrAddPriceModal.email')}</Typography>
                                <FormikController
                                    className={styles.modalInput}
                                    control="input"
                                    type="email"
                                    placeholder={t('qwep.price.modal.updateOrAddPriceModal.placeholderEmail')}
                                    name="email"
                                    validationWarningIndent="-3px"
                                    required
                                />
                            </div>
                            <div>
                                <Typography>{t('qwep.price.modal.updateOrAddPriceModal.currency')}</Typography>
                                <FormikController
                                    className={styles.modalInput}
                                    control="select"
                                    name="currency"
                                    defaultValue={'RUB'}
                                    castomStyle={{
                                        maxWidth: '150px',
                                        '.MuiSelect-select': {
                                            padding: '13px',
                                        },
                                    }}
                                    options={[
                                        { key: 'RUB', value: 'RUB' },
                                        { key: 'EUR', value: 'EUR' },
                                    ]}
                                />
                            </div>
                        </div>
                    )}
                    <AddFileOrLinkPrice
                        checkXLS={checkXLS}
                        isShowPreview={isShowPreview}
                        colors={colors}
                        errorSendFile={errorSendFile}
                        link={link}
                        handleLinkChange={handleLinkChange}
                        handleFileChange={handleFileChange}
                        titleFile={titleFile}
                    />
                    <div className={styles.buttonsContainer}>
                        <Button
                            className={styles.modalDeleteButton}
                            onClick={isShowPreview ? handleNavigateBack : closeModal}
                            size="large"
                            sx={{
                                padding: '13px',
                                background: colors.delta,
                                width: !checkXLS ? '70%' : '100%',
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {isShowPreview ? t('general.back') : t('general.cancel')}
                        </Button>
                        {!checkXLS ? (
                            <Button
                                disabled={isDisableXMLBtn}
                                className={styles.modalButton}
                                size="medium"
                                variant="contained"
                                onClick={handleLoadXml}
                                type="button">
                                Загрузить XML
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button
                            disabled={isDisable}
                            className={styles.modalButton}
                            size={!checkXLS ? 'small' : 'large'}
                            variant="contained"
                            type={'submit'}>
                            {isShowPreview ? t('general.confirm') : t('general.next')}
                            {loading && (
                                <CircularProgress
                                    className={styles.loading}
                                    size={'25px'}
                                    sx={{ color: colors.omicron }}
                                />
                            )}
                        </Button>
                    </div>
                </FormikWrapper>
            </div>
        );
    },
);
