import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { FC } from 'react';

interface IFilterLeftPanelTemplateRemoveProps {
    handleTemplateRemove: (object: any) => void;
    id: number;
    updateProperty: any;
    workspaceApplication: any;
    searchFiltersReducer: any;
}

export const FilterLeftPanelTemplateRemove: FC<IFilterLeftPanelTemplateRemoveProps> = ({
    handleTemplateRemove,
    id,
    updateProperty,
    workspaceApplication,
    searchFiltersReducer,
}) => {
    return (
        <Tooltip placement="right-start" title="Удалить шаблон">
            <button
                onClick={(event) => {
                    event.stopPropagation();
                    handleTemplateRemove({ id, updateProperty, workspaceApplication, searchFiltersReducer });
                }}
                style={{ height: '24px' }}>
                <CancelIcon />
            </button>
        </Tooltip>
    );
};
