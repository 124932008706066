import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useMemo, useState } from 'react';
import GeneralModal from '@f_general/GeneralModal';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { QwepContext } from '../context/Qwep_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function ExportExcelModal() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { openExportModal, setOpenExportModal, typeExport, setTypeExport, exportData, setExportData, exportExcel } =
        useContext(QwepContext);

    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    useEffect(() => {
        switch (typeExport) {
            case 'searchResult':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [
                                { key: 'Только отфильтрованные', value: false },
                                { key: 'Скачать Полностью', value: true },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'searchResult',
                    data: {
                        all: false,
                        type: 'searchResult',
                    },
                } as TypeDataModal);
                break;
            case 'orders':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [
                                { key: 'Только отфильтрованные', value: false },
                                { key: 'Скачать Полностью', value: true },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'orders',
                    data: {
                        all: false,
                        type: 'orders',
                    },
                } as TypeDataModal);
                break;

            case 'basket':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [
                                { key: 'Только отфильтрованные', value: false },
                                { key: 'Скачать Полностью', value: true },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'basket',
                    data: {
                        all: false,
                        type: 'basket',
                    },
                } as TypeDataModal);
                break;
            case 'assistant':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [{ key: 'Скачать Полностью', value: true }],
                        },
                    ],
                    shema: null,
                    type: 'assistant',
                    data: {
                        all: false,
                        type: 'assistant',
                    },
                } as TypeDataModal);
                break;
            case 'UCS':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel`,
                    props: [
                        {
                            control: 'select',
                            name: 'all',
                            title: '',
                            defaultValue: false,
                            options: [{ key: 'Скачать Полностью', value: true }],
                        },
                    ],
                    shema: null,
                    type: 'UCS',
                    data: {
                        all: false,
                        type: 'UCS',
                    },
                } as TypeDataModal);
                break;
        }
    }, [openExportModal]);

    const sendDataModalHandler = async (values: any) => {
        switch (values.type) {
            case 'searchResult':
                const resultSearchResult = await exportExcel({
                    type: values.type,
                    data: values.all ? exportData.all : exportData.filter,
                    title: 'Результаты поиска',
                });
                if (resultSearchResult.isOk) {
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'orders':
                const resultOrders = await exportExcel({
                    type: values.type,
                    data: values.all ? exportData.all : exportData.filter,
                    title: 'Заказы',
                });
                if (resultOrders.isOk) {
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'basket':
                const resultBasket = await exportExcel({
                    type: values.type,
                    data: values.all ? exportData.all : exportData.filter,
                    title: 'Корзина',
                });
                if (resultBasket.isOk) {
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'assistant':
                const resultAsisstant = await exportExcel({
                    type: values.type,
                    data: exportData.all,
                    title: 'Ассистент',
                });
                if (resultAsisstant.isOk) {
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    const closeHandler = () => {
        setDataModal({});
        setTypeExport();
        setExportData();
        setOpenExportModal(false);
    };

    return (
        <>
            {dataModal.type && (
                <GeneralModal
                    openModal={openExportModal}
                    closeModal={closeHandler}
                    sendDataModalHandler={sendDataModalHandler}
                    dataModal={dataModal}
                />
            )}
        </>
    );
}
