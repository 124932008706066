import Grid from '@mui/material/Unstable_Grid2';
import { Profile } from './Profile';
import { Interface } from './Interface';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutMenuPage } from '@f_general/layout/LayoutMenuPage';
import stls from '@f_styles/components/settings/Settings.module.sass';
import { UserContext } from '@f_context/User_context';

export function SettingsPage() {
    const { userInfo } = useContext(UserContext);
    const { t } = useTranslation();

    return (
        <LayoutMenuPage title={t('settings.settings')}>
            {userInfo && (
                <>
                    <Grid className={stls.grid}>
                        <Profile />
                    </Grid>
                    <Grid className={stls.grid}>
                        <Interface />
                    </Grid>
                </>
            )}
        </LayoutMenuPage>
    );
}
