import { createContext, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootFilterLeftPanelStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/index.store';

const FilterLeftPanelContext = createContext<RootFilterLeftPanelStore | null>(null);

const useFilterLeftPanelStore = () => {
    const context = useContext(FilterLeftPanelContext);
    if (context === null) {
        throw new Error('You have forgotten to wrap your root component with RootStoreProvider');
    }
    return context;
};

const FilterLeftPanelProvider = observer(({ children }: { children: ReactNode }) => {
    // инициализация стора
    const store = new RootFilterLeftPanelStore();

    return <FilterLeftPanelContext.Provider value={store}>{children}</FilterLeftPanelContext.Provider>;
});

export { FilterLeftPanelProvider, useFilterLeftPanelStore };
