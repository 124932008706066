import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconUseCompanyProps = TypeClassNames;

export const IconUseCompany = ({ classNames }: TypeIconUseCompanyProps) => {
    const { colors, currentTheme } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect
                    width="28"
                    height="28"
                    rx="14"
                    fill={currentTheme == 'dark' ? '#8CDCE1' : '#53A3A8'}
                    fillOpacity={0.2}
                />
                <path
                    d="M20.0001 19.3333H21.3334V20.6666H6.66675V19.3333H8.00008V7.99992C8.00008 7.82311 8.07032 7.65354 8.19534 7.52851C8.32037 7.40349 8.48994 7.33325 8.66675 7.33325H19.3334C19.5102 7.33325 19.6798 7.40349 19.8048 7.52851C19.9298 7.65354 20.0001 7.82311 20.0001 7.99992V19.3333ZM11.3334 13.3333V14.6666H13.3334V13.3333H11.3334ZM11.3334 10.6666V11.9999H13.3334V10.6666H11.3334ZM11.3334 15.9999V17.3333H13.3334V15.9999H11.3334ZM14.6667 15.9999V17.3333H16.6667V15.9999H14.6667ZM14.6667 13.3333V14.6666H16.6667V13.3333H14.6667ZM14.6667 10.6666V11.9999H16.6667V10.6666H14.6667Z"
                    fill={currentTheme == 'dark' ? '#8CDCE1' : '#53A3A8'}
                />
            </svg>
        </div>
    );
};
