import { SupportContext } from '@f_context/Support_context';
import { ThemeContext } from '@f_context/Theme_context';
import { Popover } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_styles/components/support/Chat.module.sass';
import { UserContext } from '@f_context/User_context';
import { GeneralContext } from '@f_context/General_context';
import { Alert } from '@f_general/alert';
import { IconChat } from '@f_qwep/components/icons/IconChat';
import { Chat } from './Chat';

export function ChatPopper() {
    const { t } = useTranslation();
    const {
        types,
        title,
        sendSupportChat,
        getSupportChat,
        sendSupportMessageChat,
        socketMessages,
        setSocketMessages,
        files,
        handleFileChange,
        setFiles,
        loadingFile,
        supportTasks,
        getSupportProperty,
        countNewMessage,
        setCountNewMessage,
    } = useContext(SupportContext);
    const { colors, currentTheme } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);
    const { updateUserProperty } = useContext(GeneralContext);

    const [chatId, setChatId] = useState('');
    const [currentType, setCurrentType] = useState<any>('602be00a5677');
    const [currentTitle, setCurrentTitle] = useState<any>('Другое');
    const [currentChat, setCurrentChat] = useState<any>([]);

    const [message, setMessage] = useState('');
    const [messageInChat, setMessageInChat] = useState('');

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'chat-popover' : undefined;

    const handleGetChat = async (id: string) => {
        if (id) {
            const result = await getSupportChat(id);
            if (result.isOk) {
                setCurrentChat(result.result.content);
            }
        }
    };

    useEffect(() => {
        if (open) {
            setSocketMessages('');
            handleGetChat(socketMessages?.payload?.chatId);
            setCountNewMessage(0);
        }
    }, [open, socketMessages]);

    useEffect(() => {
        if (socketMessages) {
            setChatId(socketMessages?.payload?.chatId);
        }
    }, [socketMessages]);

    const handleChange = (type: any) => {
        setCurrentType(type);
    };

    const handleChangeTitle = (title: any) => {
        setCurrentTitle(title);
    };

    const handleSendChatSubmit = async () => {
        const result = await sendSupportChat({
            phone: userInfo.phoneNumber ?? '',
            name: userInfo.firstName ?? '',
            description: message,
            title: currentTitle,
            product: 'ce81540c8f76',
            type: currentType,
            files: files,
        });
        if (result.result) {
            setFiles([]);
            await updateUserProperty({ sysName: 'support_id', value: [...supportTasks, result.result] });
            await getSupportProperty();
            setMessage('');
            setCurrentTitle('');
            setCurrentType('');
            setChatId(result.result.id);
            Alert({
                type: 'success',
                text: 'Сообщение успешно отправлено',
            });
        } else {
            Alert({
                type: 'error',
                text: 'Сервис временно недоступен',
            });
        }
    };

    const handleSendChatMessageSubmit = async () => {
        let content = messageInChat;
        if (files?.length) {
            content += ` ${files?.map((item: any) => `/root/#file:${item.url}`).join('  ')}`;
        } else if (!messageInChat.trim()) {
            return;
        }

        const result = await sendSupportMessageChat({ chatId: chatId, text: content, name: userInfo.firstName });
        if (result.result) {
            setCurrentChat((prev: any) => [
                ...prev,
                {
                    email: 'a.nemtsov@qwep.ru',
                    fromUserId: '440307f4-065f-4db9-aa45-bb079afaa203',
                    id: '440307f4-065f-4db9-aa45-bb079afaa203',
                    isAdmin: false,
                    lastActivity: 0,
                    realName: 'Ассистент',
                    status: 'offline',
                    text: content,
                    userId: '440307f4-065f-4db9-aa45-bb079afaa203',
                },
            ]);
            Alert({
                type: 'success',
                text: 'Сообщение успешно отправлено',
            });
            setFiles([]);
            setMessageInChat('');
        } else {
            Alert({
                type: 'error',
                text: 'Сервис временно недоступен',
            });
        }
    };

    return (
        <>
            <button id="chatBot" aria-describedby={id} onClick={handleClick} className={stls.iconChat}>
                {socketMessages && (
                    <div className={stls.icon} style={{ background: colors.lambda }}>
                        <span className={stls.countNewMessage}>{countNewMessage}</span>
                    </div>
                )}
                <IconChat />
            </button>
            <Popover
                sx={{
                    zIndex: 1000,
                    '& .MuiPopover-paper': {
                        borderRadius: '12px',
                    },
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <div style={{ height: '50px', backgroundColor: colors.psi, padding: '20px' }}>
                    <p
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: colors.gamma,
                        }}></p>
                </div>
                <div
                    className={stls.content}
                    style={{
                        background: currentTheme == 'dark' ? colors.theta : colors.iota,
                    }}>
                    <Chat
                        chatId={chatId}
                        currentChat={currentChat}
                        types={types}
                        title={title}
                        currentType={currentType}
                        userInfo={userInfo}
                        messageInChat={messageInChat}
                        message={message}
                        setMessageInChat={setMessageInChat}
                        setMessage={setMessage}
                        handleSendChatMessageSubmit={handleSendChatMessageSubmit}
                        handleChange={handleChange}
                        handleChangeTitle={handleChangeTitle}
                        currentTitle={currentTitle}
                        handleSendChatSubmit={handleSendChatSubmit}
                        handleFileChange={handleFileChange}
                        files={files}
                        setFiles={setFiles}
                        loadingFile={loadingFile}
                        styles={{
                            chatHeight: '670px',
                        }}
                    />
                </div>
            </Popover>
        </>
    );
}
