import { makeAutoObservable } from 'mobx';
import { FilterDeviationEnum } from '@f_qwep/components/purchasingAssistant/types/FilterDeviationEnum';

export class AssistantFiltersStore {
    isActiveLow: boolean;
    isActiveMedium: boolean;
    isActiveHard: boolean;
    currentDeviation: FilterDeviationEnum;
    isVendorsForDating: boolean;

    constructor() {
        this.isActiveLow = false;
        this.isActiveMedium = false;
        this.isActiveHard = false;
        this.currentDeviation = FilterDeviationEnum.NULL;
        this.isVendorsForDating = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setIsActiveLow() {
        if (this.currentDeviation === FilterDeviationEnum.LOW) {
            this.resetDeviation();
        } else {
            this.currentDeviation = FilterDeviationEnum.LOW;
            this.isActiveLow = true;
            this.isActiveMedium = false;
            this.isActiveHard = false;
        }
    }

    setIsActiveMedium(): void {
        if (this.currentDeviation === FilterDeviationEnum.MEDIUM) {
            this.resetDeviation();
        } else {
            this.currentDeviation = FilterDeviationEnum.MEDIUM;
            this.isActiveLow = false;
            this.isActiveMedium = true;
            this.isActiveHard = false;
        }
    }

    setIsActiveHard(): void {
        if (this.currentDeviation === FilterDeviationEnum.HARD) {
            this.resetDeviation();
        } else {
            this.currentDeviation = FilterDeviationEnum.HARD;
            this.isActiveLow = false;
            this.isActiveMedium = false;
            this.isActiveHard = true;
        }
    }

    switchIsVendorsForDating() {
        this.isVendorsForDating = !this.isVendorsForDating;
    }

    resetAllFilters(): void {
        this.resetDeviation();
        this.isVendorsForDating = false;
    }

    private resetDeviation(): void {
        this.isActiveLow = false;
        this.isActiveMedium = false;
        this.isActiveHard = false;
        this.currentDeviation = FilterDeviationEnum.NULL;
    }
}
