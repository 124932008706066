import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconMaskotBookProps = TypeClassNames;

export const IconMaskotBook = ({ classNames }: TypeIconMaskotBookProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div style={{ position: 'fixed', marginLeft: '-200px' }} className={cn(classNames)}>
            <svg width="200" height="200" viewBox="0 0 290 305" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5819_11976)">
                    <rect
                        x="42.9456"
                        y="48.1817"
                        width="222.454"
                        height="148.061"
                        rx="17.5919"
                        transform="rotate(0.241293 42.9456 48.1817)"
                        fill="url(#paint0_linear_5819_11976)"
                        stroke="black"
                        stroke-width="3.19853"
                    />
                    <rect
                        x="49.6279"
                        y="53.5242"
                        width="207.805"
                        height="136.397"
                        rx="14.3934"
                        transform="rotate(0.241293 49.6279 53.5242)"
                        fill="url(#paint1_linear_5819_11976)"
                        stroke="black"
                        stroke-width="3.19853"
                    />
                    <mask id="path-3-inside-1_5819_11976" fill="white">
                        <path d="M51.0433 67.8541C51.0731 60.7881 56.8253 55.0842 63.8912 55.1139L243.242 55.8693C250.308 55.899 256.012 61.6512 255.983 68.7171L255.528 176.66C255.498 183.726 249.746 189.43 242.68 189.4L63.3289 188.645C56.263 188.615 50.559 182.863 50.5888 175.797L51.0433 67.8541Z" />
                    </mask>
                    <path
                        d="M51.0972 55.0601L256.036 55.9231L51.0972 55.0601ZM257.444 177.308C257.41 185.434 250.795 191.993 242.669 191.959L65.8769 191.215C57.3978 191.179 50.5531 184.276 50.5888 175.797C50.565 181.45 56.2737 186.057 63.3397 186.086L242.691 186.842C248.697 186.867 253.585 182.305 253.609 176.652L257.444 177.308ZM50.5349 188.591L51.0972 55.0601L50.5349 188.591ZM243.242 55.8693C251.368 55.9035 257.928 62.5185 257.894 70.6443L257.444 177.308C257.41 185.434 250.795 191.993 242.669 191.959L242.691 186.842C248.697 186.867 253.585 182.305 253.609 176.652L254.063 68.7091C254.093 61.6431 249.248 55.8945 243.242 55.8693Z"
                        fill="#0092AD"
                        mask="url(#path-3-inside-1_5819_11976)"
                    />
                    <mask id="path-5-inside-2_5819_11976" fill="white">
                        <path d="M255.528 176.66C255.498 183.726 249.746 189.43 242.68 189.4L63.3289 188.645C56.263 188.615 50.559 182.863 50.5888 175.797L51.0434 67.8541C51.0731 60.7882 56.8253 55.0842 63.8913 55.114L243.242 55.8693C250.308 55.899 256.012 61.6512 255.983 68.7172L255.528 176.66Z" />
                    </mask>
                    <path
                        d="M255.474 189.454L50.5349 188.591L255.474 189.454ZM49.127 67.2063C49.1612 59.0805 55.7762 52.5209 63.9021 52.5552L240.694 53.2997C249.174 53.3354 256.018 60.238 255.983 68.7172C256.006 63.0644 250.298 58.4578 243.232 58.4281L63.8805 57.6728C57.8745 57.6475 52.9863 62.2094 52.9625 67.8622L49.127 67.2063ZM256.036 55.9232L255.474 189.454L256.036 55.9232ZM63.3289 188.645C55.2031 188.611 48.6436 181.996 48.6778 173.87L49.127 67.2063C49.1612 59.0805 55.7762 52.5209 63.9021 52.5552L63.8805 57.6728C57.8745 57.6475 52.9863 62.2094 52.9625 67.8622L52.5079 175.805C52.4781 182.871 57.3229 188.62 63.3289 188.645Z"
                        fill="#0092AD"
                        mask="url(#path-5-inside-2_5819_11976)"
                    />
                    <g filter="url(#filter0_f_5819_11976)">
                        <path
                            d="M85.9287 57.1915C77.8213 58.5971 76.8728 58.4982 64.1287 60.215C51.3845 61.9318 51.3846 87.7667 51.3845 79.6835C51.3845 72.9673 50.425 56.1369 64.1287 56.1369C71.721 56.1369 83.1087 54.7311 85.9287 57.1915Z"
                            fill="#0092AD"
                        />
                    </g>
                    <g filter="url(#filter1_f_5819_11976)">
                        <path
                            d="M203.635 187.523C215.946 186.294 227.647 188.058 242.017 185.917C256.387 183.777 254.811 149.134 254.811 167.366C254.811 173.243 256.73 189.756 236.738 188.446C225.234 187.693 207.917 189.676 203.635 187.523Z"
                            fill="#0092AD"
                        />
                    </g>
                    <g filter="url(#filter2_f_5819_11976)">
                        <path
                            d="M220.907 57.1915C229.014 58.5971 229.223 58.3986 242.707 60.215C256.191 62.0314 253.307 91.2404 254.491 83.2444C255.771 74.6084 257.37 56.1369 242.707 56.1369C235.115 56.1369 223.727 54.7311 220.907 57.1915Z"
                            fill="#0092AD"
                        />
                    </g>
                    <g filter="url(#filter3_f_5819_11976)">
                        <path
                            d="M101.971 187.052C89.66 185.822 79.4167 186.192 65.778 183.358C52.1392 180.525 50.0057 148.594 51.3846 155.531C53.1648 164.487 44.3478 191.035 68.8674 187.975C80.3078 186.547 97.689 189.205 101.971 187.052Z"
                            fill="#0092AD"
                        />
                    </g>
                    <path
                        d="M105.864 83.1525C107.2 82.1928 107.167 80.2146 105.653 79.5715C104.204 78.9562 102.603 78.5004 100.892 78.2209C97.9185 77.7349 94.7038 77.7963 91.5253 78.3997C88.3467 79.0031 85.2994 80.1305 82.646 81.6848C81.0757 82.6046 79.6712 83.6576 78.4714 84.8093C77.3076 85.9263 77.9531 87.7619 79.5122 88.1756C80.4468 88.4235 81.4239 88.0508 82.0807 87.3412C83.0628 86.2803 84.2772 85.3183 85.6721 84.5012C87.5958 83.3744 89.8051 82.557 92.1096 82.1196C94.414 81.6821 96.7447 81.6376 98.9007 81.9899C100.413 82.237 101.808 82.6742 103.028 83.2795C103.938 83.731 105.039 83.7451 105.864 83.1525Z"
                        fill="#074955"
                    />
                    <path
                        d="M201.061 83.1525C199.725 82.1928 199.758 80.2146 201.272 79.5715C202.721 78.9562 204.322 78.5004 206.033 78.2209C209.007 77.7349 212.221 77.7963 215.4 78.3997C218.578 79.0031 221.626 80.1305 224.279 81.6848C225.849 82.6046 227.254 83.6576 228.454 84.8093C229.617 85.9263 228.972 87.7619 227.413 88.1756C226.478 88.4235 225.501 88.0508 224.844 87.3412C223.862 86.2803 222.648 85.3183 221.253 84.5012C219.329 83.3744 217.12 82.557 214.815 82.1196C212.511 81.6821 210.18 81.6376 208.024 81.9899C206.512 82.237 205.117 82.6742 203.897 83.2795C202.987 83.731 201.886 83.7451 201.061 83.1525Z"
                        fill="#074955"
                    />
                    <path
                        d="M129.379 126.141C130.07 125.995 130.514 125.314 130.339 124.63C129.557 121.586 128.314 118.688 126.661 116.065C124.785 113.086 122.416 110.52 119.692 108.513C116.967 106.507 113.94 105.1 110.783 104.372C107.625 103.644 104.4 103.609 101.291 104.27C98.1811 104.931 95.2486 106.274 92.6604 108.224C90.0722 110.173 87.8791 112.69 86.2062 115.631C84.5332 118.572 83.4133 121.879 82.9104 125.364C82.4674 128.433 82.5106 131.586 83.035 134.685C83.1529 135.381 83.8351 135.822 84.5263 135.675L106.953 130.908L129.379 126.141Z"
                        fill="#074955"
                    />
                    <path
                        d="M221.324 135.675C222.015 135.822 222.697 135.381 222.815 134.684C223.34 131.586 223.383 128.433 222.94 125.364C222.437 121.879 221.317 118.572 219.644 115.631C217.971 112.69 215.778 110.173 213.19 108.224C210.602 106.274 207.669 104.931 204.56 104.27C201.45 103.609 198.225 103.644 195.067 104.372C191.91 105.1 188.883 106.507 186.158 108.513C183.434 110.52 181.066 113.085 179.189 116.064C177.536 118.688 176.293 121.586 175.512 124.63C175.336 125.314 175.78 125.994 176.471 126.141L198.897 130.908L221.324 135.675Z"
                        fill="#074955"
                    />
                    <path
                        d="M167.106 149.491C167.544 149.488 167.904 149.841 167.889 150.28C167.822 152.229 167.455 154.152 166.803 155.963C166.063 158.02 164.97 159.891 163.586 161.471C162.202 163.051 160.555 164.309 158.738 165.171C156.921 166.034 154.97 166.486 152.997 166.5C151.023 166.515 149.066 166.092 147.237 165.255C145.408 164.419 143.742 163.186 142.336 161.626C140.929 160.067 139.808 158.211 139.038 156.166C138.36 154.364 137.965 152.447 137.87 150.499C137.848 150.06 138.203 149.702 138.641 149.699L152.874 149.595L167.106 149.491Z"
                        fill="#074955"
                    />
                    <path
                        d="M272.031 146.907L252.589 139.131C252.077 138.926 251.513 138.888 250.978 139.023L154.062 163.372C153.625 163.482 153.167 163.477 152.733 163.358L63.7832 139.032C63.2685 138.891 62.7231 138.911 62.22 139.088L40.0659 146.907L143.236 173.464L164.814 173.464L272.031 146.907Z"
                        fill="#CBEDE1"
                    />
                    <path
                        d="M142.291 300.332L142.457 300.377L142.628 300.377L163.775 300.377L163.944 300.377L164.107 300.333L221.113 285.026L221.119 285.024L271.021 271.391C272.716 270.928 273.893 269.387 273.893 267.629L273.893 221.079L273.893 146.956C273.893 144.42 271.511 142.559 269.051 143.172L163.618 169.423L142.787 169.423L38.24 143.18C35.7779 142.562 33.391 144.424 33.391 146.962L33.3911 267.629C33.3911 269.387 34.5676 270.928 36.2638 271.391L142.291 300.332Z"
                        fill="#FDD500"
                        stroke="black"
                        stroke-width="2.55882"
                    />
                    <path
                        d="M142.795 299.096L142.822 170.74L163.893 170.735L163.865 299.092L142.795 299.096Z"
                        fill="#D3B200"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M228.714 159.898C227.197 160.283 227.018 162.366 228.447 163.004C228.745 163.137 229.075 163.178 229.396 163.124L262.808 157.45L266.541 167.113C266.969 168.222 268.616 167.915 268.616 166.726L268.616 153.063C268.616 151.394 267.045 150.172 265.428 150.583L228.714 159.898Z"
                        fill="#F0CA02"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M54.9032 272.647C56.7139 273.012 57.6616 270.577 56.0808 269.622L45.2421 263.071L42.1908 256.773C42.1364 256.661 42.0903 256.545 42.0528 256.426L36.6715 239.333L36.6715 265.522C36.6715 266.665 37.4299 267.67 38.5294 267.983L54.9032 272.647Z"
                        fill="#F0CA02"
                    />
                    <path
                        d="M44.1719 201.091L45.1747 200.131C45.5171 199.804 45.7618 199.388 45.8815 198.929L46.2428 197.547C46.3208 197.248 46.3208 196.935 46.2428 196.637C45.9592 195.551 44.7623 194.987 43.7446 195.459L34.7523 199.63V234.854L45.2116 227.577C45.5318 227.354 45.7969 227.061 45.9865 226.72L47.1046 224.71C47.572 223.869 47.5285 222.837 46.9921 222.039L46.3617 221.101L47.2265 219.502L48.9791 217.499C49.2927 217.14 49.4655 216.68 49.4655 216.204C49.4655 215.655 49.6947 215.132 50.0978 214.76L50.6738 214.228L52.2705 212.3C52.5292 211.988 52.7099 211.619 52.7978 211.223L53.1242 209.754C53.2409 209.229 53.1892 208.68 52.9763 208.186L52.0243 205.977H48.3519L42.0496 203.756L43.9271 201.361C44.0021 201.265 44.084 201.175 44.1719 201.091Z"
                        fill="#D3B200"
                    />
                    <path
                        d="M27.4687 210.219L27.3961 210.213L27.3248 210.197C26.0473 209.911 24.9058 209.499 24.2203 208.723C23.8279 208.279 23.5673 207.696 23.5497 207.002C23.5333 206.355 23.7328 205.774 23.9698 205.304C24.4274 204.395 25.253 203.461 26.1974 202.519C27.1669 201.552 28.3996 200.446 29.7953 199.202C32.479 196.631 35.5033 194.651 37.9529 193.42C39.1777 192.805 40.2969 192.358 41.1862 192.125C41.6244 192.01 42.0611 191.933 42.451 191.936C42.6463 191.937 42.876 191.959 43.1076 192.036C43.3341 192.112 43.6488 192.269 43.8982 192.614C44.3475 193.234 44.2808 193.964 44.1901 194.373C44.095 194.801 43.9068 195.214 43.712 195.573C43.3139 196.306 42.6899 197.169 41.9343 198.098C40.408 199.974 38.162 202.338 35.5624 204.833C32.9899 207.301 31.4153 208.671 30.3043 209.401C29.7321 209.777 29.2307 210.019 28.7367 210.143C28.2328 210.268 27.8145 210.25 27.4687 210.219Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.72263"
                    />
                    <path
                        d="M31.7344 206.025L31.6779 206.01L31.6231 205.989C30.4818 205.541 29.5515 205.033 28.9605 204.366C28.6481 204.014 28.3971 203.579 28.29 203.052C28.1816 202.518 28.2431 202 28.3959 201.534C28.6784 200.671 29.3108 199.877 30.0009 199.151C30.7151 198.399 31.6422 197.57 32.6952 196.655C34.7463 194.693 37.0979 193.385 39.0334 192.679C40.003 192.325 40.9051 192.109 41.6418 192.05C42.0072 192.02 42.3718 192.026 42.7005 192.096C42.9941 192.158 43.4432 192.309 43.7601 192.747C44.0761 193.183 44.1296 193.698 44.0913 194.107C44.0547 194.498 43.9303 194.87 43.7925 195.188C43.5152 195.83 43.0556 196.545 42.5048 197.28C41.3894 198.769 39.7157 200.585 37.7652 202.456C35.8673 204.277 34.7551 205.273 33.9659 205.747C33.5332 206.006 33.1266 206.156 32.6919 206.171C32.3371 206.184 32.0114 206.098 31.7968 206.041C31.7748 206.035 31.754 206.03 31.7344 206.025Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M37.4176 200.729L37.3749 200.708L37.3341 200.682C36.5954 200.227 35.9904 199.771 35.5773 199.272C35.1448 198.75 34.8232 198.051 34.9497 197.202C35.0611 196.454 35.4878 195.879 35.8592 195.47C36.2427 195.047 36.7392 194.62 37.2599 194.19C38.4021 193.109 39.7903 192.565 40.9462 192.372C41.5331 192.275 42.1004 192.261 42.5861 192.335C42.8285 192.372 43.0759 192.435 43.3052 192.538C43.5218 192.635 43.8007 192.802 44.0182 193.103C44.4624 193.716 44.4019 194.451 44.276 194.934C44.1487 195.422 43.9007 195.9 43.6275 196.33C43.0715 197.206 42.2164 198.185 41.2294 199.132C40.7488 199.593 40.3598 199.951 40.0377 200.219C39.724 200.481 39.4199 200.702 39.1146 200.842C38.7715 200.999 38.4294 201.052 38.0763 200.981C37.8197 200.93 37.5818 200.811 37.4547 200.747C37.441 200.74 37.4286 200.734 37.4176 200.729Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M24.9222 214.861L24.8439 214.81L24.7715 214.751C24.0791 214.189 23.4977 213.646 23.0525 213.117C22.6167 212.599 22.2337 212.003 22.07 211.336C21.8906 210.606 22.0006 209.887 22.3972 209.27C22.7579 208.71 23.2926 208.33 23.8 208.061C24.7979 207.531 26.2049 207.165 27.7377 206.846C28.9161 206.601 30.2895 206.361 31.7797 206.1C32.2604 206.016 32.7531 205.929 33.2554 205.84C37.3202 204.956 41.286 204.775 44.3128 204.984C45.8232 205.088 47.1563 205.294 48.1765 205.584C48.6797 205.728 49.1785 205.912 49.6009 206.157C49.9555 206.363 50.6102 206.815 50.8267 207.626C51.0484 208.456 50.6875 209.115 50.3979 209.478C50.103 209.848 49.7089 210.144 49.3334 210.381C48.5674 210.864 47.4958 211.334 46.2574 211.785C43.754 212.698 40.245 213.648 36.2917 214.512C32.3837 215.365 30.0376 215.753 28.4763 215.793C27.6692 215.814 26.9947 215.746 26.3795 215.562C25.7545 215.376 25.2922 215.1 24.9222 214.861Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="3.18941"
                    />
                    <path
                        d="M31.9265 210.925L31.8753 210.882L31.8294 210.833C31.4033 210.382 31.0514 209.953 30.7922 209.542C30.5384 209.14 30.3284 208.687 30.2768 208.198C30.2203 207.663 30.3617 207.153 30.6992 206.733C31.0067 206.35 31.4203 206.112 31.8015 205.954C32.5488 205.643 33.5616 205.482 34.6466 205.364C35.4821 205.273 36.4519 205.2 37.5026 205.12C37.8406 205.095 38.1869 205.068 38.5398 205.041C41.4279 204.7 44.1829 204.854 46.2547 205.22C47.2886 205.402 48.1931 205.645 48.875 205.927C49.2113 206.066 49.5429 206.235 49.8161 206.443C50.0473 206.62 50.4612 206.994 50.5364 207.592C50.6133 208.203 50.3017 208.655 50.0605 208.901C49.8171 209.15 49.5115 209.337 49.2276 209.48C48.6486 209.773 47.8612 210.033 46.965 210.267C45.1522 210.739 42.6442 211.167 39.8406 211.5C37.0714 211.829 35.4127 211.939 34.325 211.856C33.7614 211.813 33.2948 211.715 32.8793 211.536C32.4578 211.356 32.16 211.122 31.9265 210.925Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M39.4903 208.826L39.4579 208.777L39.4308 208.724C39.0495 207.99 38.7593 207.25 38.7791 206.561C38.7901 206.178 38.8977 205.776 39.1623 205.42C39.4208 205.072 39.7585 204.862 40.0706 204.735C40.6427 204.501 41.336 204.464 41.9458 204.462C42.4306 204.46 42.9939 204.484 43.5834 204.509C43.7624 204.517 43.9437 204.524 44.1261 204.531C45.8374 204.507 47.4239 204.869 48.6032 205.347C49.1938 205.587 49.7222 205.872 50.1243 206.183C50.324 206.337 50.5252 206.523 50.6853 206.742C50.8348 206.946 51.0268 207.287 51.0204 207.73C51.0141 208.162 50.8217 208.505 50.6711 208.716C50.5097 208.941 50.3084 209.137 50.1075 209.302C49.7033 209.635 49.1742 209.947 48.5819 210.217C47.392 210.757 45.7904 211.195 44.0586 211.225C42.3445 211.254 41.2182 210.9 40.4659 210.239C40.0996 209.917 39.8782 209.57 39.729 209.293C39.6731 209.189 39.621 209.083 39.5837 209.006C39.5748 208.988 39.5668 208.972 39.5598 208.958C39.5144 208.866 39.4968 208.836 39.4903 208.826Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M38.6924 221.417L38.6973 221.464L38.6991 221.511C38.7786 223.58 38.6953 225.178 38.3919 226.359C38.0826 227.562 37.4735 228.574 36.339 229.016C35.3266 229.411 34.2211 229.203 33.3091 228.944C32.5948 228.74 31.779 228.433 30.945 228.119C30.702 228.027 30.4575 227.935 30.2136 227.845C27.639 227.161 25.56 225.483 24.1911 223.749C23.4968 222.87 22.9551 221.939 22.6169 221.056C22.2952 220.216 22.0903 219.243 22.3081 218.367C22.5392 217.438 23.1659 216.795 23.9049 216.395C24.617 216.009 25.4738 215.82 26.3547 215.746C28.1224 215.599 30.3482 215.888 32.676 216.489C34.9175 217.067 36.4131 217.63 37.3457 218.438C38.4334 219.381 38.5945 220.503 38.6924 221.417Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.72263"
                    />
                    <path
                        d="M24.6884 222.474L24.6267 222.435L24.5707 222.388C23.5687 221.547 22.7442 220.657 22.4929 219.653C22.3489 219.078 22.3864 218.44 22.6908 217.818C22.9745 217.238 23.4194 216.816 23.8462 216.509C24.6704 215.917 25.8333 215.471 27.1052 215.073C28.4108 214.665 30.014 214.255 31.8255 213.798C35.3911 212.761 38.987 212.403 41.7274 212.443C43.0976 212.463 44.2967 212.583 45.1932 212.787C45.6349 212.887 46.0582 213.02 46.403 213.202C46.5756 213.293 46.7697 213.419 46.94 213.594C47.1066 213.765 47.3141 214.05 47.377 214.471C47.4904 215.23 47.0933 215.846 46.825 216.165C46.5432 216.5 46.1862 216.778 45.8482 217.006C45.1573 217.47 44.206 217.946 43.1077 218.42C40.8892 219.375 37.8074 220.431 34.3517 221.439C30.9319 222.436 28.9035 222.923 27.5815 223.057C26.9006 223.126 26.3443 223.109 25.8492 222.99C25.3441 222.869 24.9813 222.66 24.6884 222.474Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.72263"
                    />
                    <path
                        d="M30.4055 220.729L30.3619 220.689L30.323 220.645C29.5166 219.723 28.9239 218.846 28.7064 217.983C28.5913 217.527 28.5685 217.026 28.7167 216.509C28.8666 215.987 29.1602 215.556 29.5106 215.214C30.1587 214.582 31.0853 214.171 32.0321 213.847C33.012 213.512 34.2166 213.205 35.5726 212.881C38.296 212.092 40.985 212.018 43.0279 212.285C44.0513 212.419 44.9516 212.643 45.6326 212.93C45.9704 213.072 46.2914 213.245 46.5512 213.458C46.7831 213.649 47.1134 213.989 47.1934 214.524C47.2731 215.058 47.0826 215.539 46.8603 215.883C46.6473 216.212 46.3657 216.484 46.0967 216.702C45.5554 217.141 44.8184 217.561 43.9908 217.958C42.3152 218.761 39.994 219.595 37.4011 220.351C34.878 221.087 33.4327 221.455 32.5143 221.51C32.0109 221.541 31.5811 221.486 31.1882 221.299C30.8673 221.147 30.6176 220.921 30.4533 220.772C30.4364 220.756 30.4205 220.742 30.4055 220.729Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M37.85 218.541L37.825 218.504L37.8032 218.465C37.3462 217.639 37.0204 216.901 36.8806 216.237C36.7381 215.56 36.7561 214.783 37.2291 214.07C37.6661 213.411 38.3216 213.076 38.8554 212.875C39.3977 212.671 40.0417 212.525 40.7014 212.389C42.2392 211.954 43.7451 212.178 44.8681 212.608C45.4406 212.827 45.9532 213.115 46.35 213.44C46.6925 213.72 47.1424 214.182 47.2389 214.828C47.3463 215.546 47.0133 216.169 46.6699 216.581C46.3339 216.985 45.8905 217.316 45.445 217.587C44.5415 218.137 43.3238 218.623 42.0063 219.008C41.3667 219.194 40.8531 219.332 40.4391 219.419C40.036 219.503 39.6531 219.555 39.3051 219.526C38.9111 219.493 38.5823 219.357 38.3097 219.112C38.113 218.936 37.9617 218.709 37.8757 218.58C37.8664 218.566 37.8578 218.553 37.85 218.541Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M29.045 230.994L28.9742 230.969L28.9066 230.936C27.7873 230.395 26.7888 229.77 26.3001 228.939C26.0189 228.461 25.9082 227.919 26.0324 227.366C26.1478 226.852 26.4403 226.443 26.7393 226.131C27.3159 225.529 28.2081 224.995 29.1497 224.494C29.8796 224.106 30.7424 223.687 31.6711 223.236C31.9666 223.092 32.2688 222.946 32.5756 222.796C35.0963 221.425 37.7188 220.587 39.8093 220.203C40.853 220.012 41.812 219.924 42.5876 219.957C42.9708 219.974 43.3673 220.022 43.7278 220.133C44.0461 220.231 44.5845 220.453 44.91 220.984C45.2004 221.458 45.1922 221.949 45.1352 222.284C45.076 222.631 44.9395 222.965 44.7819 223.264C44.4645 223.867 43.9554 224.528 43.3171 225.198C42.0303 226.548 40.0691 228.089 37.567 229.454C35.0866 230.807 33.3093 231.361 31.9205 231.467C30.5942 231.568 29.7158 231.242 29.1876 231.046C29.1368 231.027 29.0893 231.01 29.045 230.994Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.6374"
                    />
                    <path
                        d="M36.2477 226.223L36.2159 226.197L36.186 226.17C35.343 225.396 34.7211 224.758 34.3762 224.219C34.2021 223.947 34.0211 223.584 34.0051 223.163C33.9865 222.675 34.1942 222.273 34.4978 221.991C34.7576 221.749 35.0692 221.609 35.2948 221.522C35.533 221.431 35.8006 221.353 36.0421 221.284C36.0844 221.272 36.1268 221.26 36.1694 221.248C36.6252 221.119 37.0939 220.987 37.5883 220.783C38.8425 220.108 40.2146 219.701 41.4015 219.596C42.0007 219.542 42.6097 219.561 43.1561 219.699C43.6886 219.834 44.3169 220.126 44.6898 220.734C45.3119 221.75 44.9678 222.92 44.4334 223.773C43.8615 224.687 42.8941 225.569 41.6184 226.265C40.1028 227.092 38.9407 227.487 37.9329 227.28C37.3853 227.168 37.0039 226.903 36.7396 226.676C36.6345 226.585 36.5341 226.489 36.4627 226.42C36.4524 226.41 36.4427 226.4 36.4337 226.392C36.3508 226.312 36.2975 226.263 36.2477 226.223Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M262.341 207.49L261.301 206.528C260.947 206.201 260.693 205.781 260.568 205.317L260.202 203.965C260.118 203.653 260.118 203.325 260.202 203.013C260.495 201.93 261.686 201.366 262.71 201.825L272.084 206.028L272.697 239.822C272.704 240.167 272.238 240.281 272.084 239.973L261.376 233.981C260.973 233.755 260.638 233.424 260.408 233.024L259.326 231.142C258.832 230.284 258.878 229.219 259.443 228.406L260.074 227.498L259.179 225.899L257.359 223.889C257.04 223.536 256.863 223.077 256.863 222.6C256.863 222.054 256.63 221.533 256.222 221.168L255.613 220.625L253.964 218.701C253.695 218.387 253.506 218.012 253.413 217.609L253.084 216.178C252.96 215.636 253.015 215.07 253.241 214.562L254.216 212.374H258.015L264.535 210.153L262.591 207.757C262.514 207.662 262.431 207.573 262.341 207.49Z"
                        fill="#D3B200"
                    />
                    <path
                        d="M278.709 215.552L278.781 215.541L278.851 215.519C280.104 215.141 281.212 214.646 281.838 213.821C282.197 213.349 282.414 212.749 282.381 212.056C282.349 211.409 282.108 210.845 281.837 210.393C281.314 209.521 280.421 208.649 279.41 207.779C278.372 206.886 277.062 205.874 275.578 204.736C272.713 202.37 269.551 200.617 267.018 199.57C265.751 199.046 264.602 198.683 263.698 198.516C263.252 198.434 262.811 198.389 262.422 198.42C262.228 198.436 262 198.475 261.775 198.569C261.555 198.661 261.252 198.841 261.029 199.203C260.627 199.855 260.747 200.578 260.867 200.979C260.994 201.399 261.212 201.797 261.432 202.14C261.883 202.842 262.569 203.657 263.391 204.528C265.051 206.287 267.465 208.479 270.241 210.776C272.988 213.048 274.659 214.299 275.821 214.945C276.419 215.278 276.937 215.483 277.439 215.569C277.95 215.658 278.366 215.608 278.709 215.552Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.72263"
                    />
                    <path
                        d="M274.146 211.683L274.202 211.665L274.255 211.639C275.36 211.108 276.25 210.534 276.791 209.825C277.076 209.451 277.295 208.999 277.363 208.465C277.432 207.925 277.332 207.413 277.146 206.959C276.8 206.119 276.111 205.374 275.37 204.7C274.602 204.003 273.616 203.244 272.499 202.409C270.309 200.604 267.868 199.472 265.886 198.91C264.893 198.629 263.977 198.479 263.238 198.475C262.871 198.472 262.508 198.505 262.186 198.599C261.897 198.682 261.461 198.866 261.177 199.325C260.894 199.784 260.878 200.301 260.946 200.706C261.012 201.094 261.163 201.455 261.324 201.763C261.648 202.382 262.159 203.061 262.762 203.754C263.984 205.158 265.787 206.845 267.869 208.568C269.896 210.244 271.079 211.155 271.9 211.57C272.351 211.797 272.768 211.916 273.202 211.9C273.557 211.886 273.875 211.776 274.085 211.704C274.107 211.697 274.127 211.69 274.146 211.683Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M268.089 206.819L268.13 206.795L268.169 206.767C268.872 206.259 269.442 205.759 269.817 205.231C270.21 204.679 270.479 203.958 270.291 203.121C270.125 202.383 269.657 201.841 269.256 201.46C268.843 201.067 268.316 200.678 267.765 200.287C266.547 199.293 265.122 198.852 263.955 198.745C263.363 198.691 262.796 198.719 262.317 198.829C262.078 198.883 261.836 198.964 261.615 199.084C261.406 199.196 261.14 199.384 260.945 199.699C260.548 200.344 260.662 201.072 260.823 201.545C260.986 202.022 261.268 202.481 261.572 202.89C262.191 203.722 263.116 204.635 264.17 205.507C264.683 205.932 265.098 206.26 265.439 206.504C265.771 206.742 266.09 206.94 266.405 207.057C266.759 207.189 267.104 207.216 267.451 207.12C267.703 207.05 267.931 206.913 268.053 206.84C268.066 206.833 268.078 206.826 268.089 206.819Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M280.662 220.724L280.74 220.673L280.812 220.614C281.505 220.052 282.086 219.509 282.531 218.98C282.967 218.462 283.35 217.866 283.514 217.199C283.693 216.469 283.583 215.75 283.187 215.133C282.826 214.573 282.291 214.193 281.784 213.924C280.786 213.394 279.379 213.029 277.846 212.709C276.668 212.464 275.294 212.224 273.804 211.963C273.324 211.879 272.831 211.792 272.328 211.703C268.264 210.819 264.298 210.638 261.271 210.847C259.761 210.951 258.428 211.157 257.407 211.447C256.904 211.591 256.405 211.775 255.983 212.02C255.628 212.226 254.974 212.678 254.757 213.489C254.536 214.319 254.896 214.978 255.186 215.341C255.481 215.711 255.875 216.007 256.25 216.244C257.016 216.727 258.088 217.197 259.326 217.648C261.83 218.561 265.339 219.511 269.292 220.375C273.2 221.228 275.546 221.616 277.108 221.656C277.915 221.677 278.589 221.609 279.204 221.425C279.829 221.239 280.292 220.963 280.662 220.724Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="3.18941"
                    />
                    <path
                        d="M273.657 216.788L273.708 216.745L273.754 216.696C274.181 216.245 274.532 215.816 274.792 215.405C275.045 215.004 275.255 214.55 275.307 214.061C275.364 213.526 275.222 213.016 274.885 212.596C274.577 212.213 274.164 211.975 273.782 211.817C273.035 211.506 272.022 211.345 270.937 211.227C270.102 211.136 269.132 211.063 268.081 210.983C267.743 210.958 267.397 210.931 267.044 210.904C264.156 210.563 261.401 210.717 259.329 211.083C258.295 211.265 257.391 211.508 256.709 211.79C256.372 211.929 256.041 212.098 255.768 212.306C255.537 212.483 255.123 212.857 255.047 213.455C254.971 214.066 255.282 214.518 255.523 214.764C255.767 215.013 256.072 215.2 256.356 215.343C256.935 215.636 257.723 215.896 258.619 216.13C260.432 216.602 262.94 217.03 265.743 217.363C268.512 217.692 270.171 217.802 271.259 217.719C271.822 217.676 272.289 217.578 272.705 217.399C273.126 217.219 273.424 216.985 273.657 216.788Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M266.094 214.689L266.126 214.64L266.153 214.587C266.535 213.853 266.825 213.113 266.805 212.424C266.794 212.041 266.686 211.639 266.422 211.283C266.163 210.935 265.825 210.725 265.513 210.598C264.941 210.364 264.248 210.327 263.638 210.325C263.153 210.323 262.59 210.347 262.001 210.372C261.822 210.38 261.64 210.387 261.458 210.394C259.747 210.37 258.16 210.732 256.981 211.211C256.39 211.45 255.862 211.735 255.46 212.046C255.26 212.2 255.059 212.386 254.899 212.605C254.749 212.81 254.557 213.15 254.564 213.593C254.57 214.025 254.762 214.368 254.913 214.579C255.074 214.804 255.276 215 255.477 215.165C255.881 215.498 256.41 215.81 257.002 216.08C258.192 216.62 259.794 217.058 261.525 217.088C263.24 217.117 264.366 216.763 265.118 216.102C265.484 215.78 265.706 215.433 265.855 215.156C265.911 215.052 265.963 214.946 266 214.869C266.009 214.851 266.017 214.835 266.024 214.821C266.07 214.729 266.087 214.699 266.094 214.689Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M266.892 227.28L266.887 227.327L266.885 227.374C266.806 229.443 266.889 231.041 267.192 232.222C267.502 233.425 268.111 234.437 269.245 234.879C270.258 235.274 271.363 235.066 272.275 234.807C272.989 234.603 273.805 234.296 274.639 233.982C274.882 233.89 275.127 233.798 275.371 233.708C277.945 233.024 280.024 231.346 281.393 229.612C282.087 228.733 282.629 227.802 282.967 226.919C283.289 226.079 283.494 225.106 283.276 224.23C283.045 223.301 282.418 222.658 281.679 222.258C280.967 221.872 280.111 221.683 279.23 221.609C277.462 221.462 275.236 221.751 272.908 222.352C270.667 222.93 269.171 223.493 268.239 224.301C267.151 225.244 266.99 226.366 266.892 227.28Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.72263"
                    />
                    <path
                        d="M280.896 228.337L280.957 228.298L281.013 228.251C282.015 227.41 282.84 226.52 283.091 225.516C283.235 224.941 283.198 224.304 282.893 223.681C282.61 223.101 282.165 222.679 281.738 222.372C280.914 221.78 279.751 221.334 278.479 220.937C277.173 220.528 275.57 220.118 273.759 219.661C270.193 218.624 266.597 218.266 263.857 218.306C262.486 218.326 261.287 218.446 260.391 218.65C259.949 218.75 259.526 218.883 259.181 219.065C259.009 219.156 258.814 219.282 258.644 219.457C258.478 219.628 258.27 219.913 258.207 220.334C258.094 221.093 258.491 221.709 258.759 222.028C259.041 222.363 259.398 222.641 259.736 222.869C260.427 223.333 261.378 223.809 262.476 224.283C264.695 225.238 267.777 226.294 271.232 227.302C274.652 228.299 276.681 228.786 278.003 228.92C278.683 228.989 279.24 228.972 279.735 228.853C280.24 228.732 280.603 228.523 280.896 228.337Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.72263"
                    />
                    <path
                        d="M275.179 226.592L275.222 226.553L275.261 226.508C276.067 225.587 276.66 224.709 276.878 223.846C276.993 223.39 277.015 222.889 276.867 222.372C276.717 221.85 276.424 221.42 276.073 221.077C275.425 220.445 274.499 220.034 273.552 219.71C272.572 219.375 271.367 219.068 270.011 218.744C267.288 217.955 264.599 217.881 262.556 218.148C261.533 218.282 260.632 218.506 259.951 218.793C259.614 218.935 259.293 219.108 259.033 219.321C258.801 219.512 258.471 219.852 258.391 220.387C258.311 220.921 258.501 221.402 258.724 221.746C258.937 222.075 259.218 222.347 259.487 222.565C260.029 223.004 260.766 223.424 261.593 223.821C263.269 224.624 265.59 225.458 268.183 226.214C270.706 226.95 272.151 227.318 273.07 227.373C273.573 227.404 274.003 227.349 274.396 227.162C274.717 227.01 274.966 226.784 275.131 226.635C275.148 226.619 275.163 226.605 275.179 226.592Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M267.734 224.405L267.759 224.368L267.781 224.328C268.238 223.502 268.564 222.764 268.704 222.1C268.846 221.424 268.828 220.646 268.355 219.933C267.918 219.274 267.263 218.939 266.729 218.739C266.186 218.535 265.542 218.388 264.883 218.253C263.345 217.818 261.839 218.041 260.716 218.471C260.144 218.691 259.631 218.978 259.234 219.303C258.892 219.583 258.442 220.046 258.345 220.691C258.238 221.41 258.571 222.033 258.914 222.445C259.25 222.848 259.694 223.179 260.139 223.45C261.043 224 262.26 224.487 263.578 224.871C264.217 225.058 264.731 225.195 265.145 225.282C265.548 225.366 265.931 225.419 266.279 225.389C266.673 225.356 267.002 225.22 267.274 224.976C267.471 224.799 267.622 224.572 267.708 224.443C267.718 224.429 267.726 224.416 267.734 224.405Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M276.539 236.857L276.61 236.832L276.677 236.799C277.797 236.258 278.795 235.633 279.284 234.802C279.565 234.324 279.676 233.782 279.552 233.229C279.436 232.715 279.144 232.306 278.845 231.994C278.268 231.392 277.376 230.858 276.434 230.357C275.705 229.969 274.842 229.55 273.913 229.099C273.617 228.956 273.315 228.809 273.009 228.659C270.488 227.288 267.865 226.451 265.775 226.066C264.731 225.875 263.772 225.787 262.997 225.82C262.613 225.837 262.217 225.885 261.856 225.996C261.538 226.094 261 226.316 260.674 226.847C260.384 227.321 260.392 227.812 260.449 228.147C260.508 228.494 260.645 228.828 260.802 229.127C261.12 229.73 261.629 230.391 262.267 231.061C263.554 232.411 265.515 233.952 268.017 235.317C270.498 236.67 272.275 237.224 273.664 237.33C274.99 237.431 275.868 237.105 276.397 236.909C276.447 236.89 276.495 236.873 276.539 236.857Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="2.6374"
                    />
                    <path
                        d="M269.336 232.086L269.368 232.06L269.398 232.033C270.241 231.259 270.863 230.621 271.208 230.082C271.382 229.81 271.563 229.447 271.579 229.026C271.598 228.538 271.39 228.137 271.086 227.854C270.826 227.612 270.515 227.472 270.289 227.385C270.051 227.294 269.783 227.216 269.542 227.147C269.5 227.135 269.457 227.123 269.415 227.111C268.959 226.982 268.49 226.85 267.996 226.646C266.742 225.971 265.369 225.564 264.183 225.459C263.583 225.405 262.974 225.424 262.428 225.562C261.895 225.697 261.267 225.989 260.894 226.597C260.272 227.613 260.616 228.783 261.151 229.636C261.723 230.55 262.69 231.432 263.966 232.128C265.481 232.955 266.643 233.35 267.651 233.143C268.199 233.031 268.58 232.766 268.844 232.539C268.95 232.449 269.05 232.352 269.121 232.283C269.132 232.273 269.141 232.263 269.15 232.255C269.233 232.175 269.287 232.126 269.336 232.086Z"
                        stroke="black"
                        stroke-width="2.33946"
                    />
                    <path
                        d="M84.0097 77.1672C51.0649 85.8745 52.0244 79.4062 49.6027 67.2955L135.968 44.4687C139.077 56.2318 123.671 66.6845 84.0097 77.1672Z"
                        fill="#0092AD"
                    />
                    <path
                        d="M48.1863 71.09L44.3481 69.4907L44.3481 74.8683C44.3481 75.1024 44.476 75.3178 44.6815 75.4299L46.9747 76.6808C47.3881 76.9062 47.8951 76.623 47.9199 76.1528L48.1863 71.09Z"
                        fill="#02A608"
                    />
                    <path
                        d="M158.644 16.1121L82.5935 14.2835C82.2192 14.2732 81.8471 14.3436 81.5025 14.49L11.3805 43.984C10.8336 44.2136 10.3792 44.6196 10.0898 45.1374C9.80046 45.6552 9.6927 46.255 9.78374 46.8411C9.87477 47.4273 10.1594 47.9661 10.5922 48.3717C11.025 48.7774 11.5811 49.0265 12.1719 49.0794L39.3778 51.571C38.9634 54.8668 39.0418 58.5669 39.856 62.8685C42.1186 74.8233 60.4633 77.8943 92.9083 71.7536C125.353 65.6129 141.307 56.0504 139.044 44.0956C138.23 39.794 136.951 36.3211 135.361 33.4047L159.78 21.1423C160.31 20.8761 160.736 20.4401 160.989 19.9038C161.243 19.3674 161.31 18.7616 161.179 18.1829C161.049 17.6042 160.728 17.0858 160.268 16.7105C159.809 16.3352 159.237 16.1245 158.644 16.1121Z"
                        fill="#292F33"
                    />
                    <path
                        d="M39.2989 54.0921L39.5503 51.5789L88.4878 56.0568L39.9189 54.7953C39.5471 54.7856 39.2619 54.4621 39.2989 54.0921Z"
                        fill="#1E2124"
                    />
                    <path
                        d="M160.955 17.5797C160.637 16.9516 160.084 16.4745 159.416 16.2525C158.748 16.0304 158.019 16.0815 157.389 16.3945L88.9365 50.769L12.6519 43.7882C12.3044 43.7567 11.9541 43.794 11.621 43.8978C11.2878 44.0017 10.9784 44.1702 10.7104 44.3937C10.1691 44.845 9.82925 45.4928 9.76561 46.1947C9.70197 46.8965 9.91975 47.5949 10.3711 48.1362C10.8223 48.6775 11.4702 49.0174 12.1721 49.081L89.2103 56.13C89.7014 56.177 90.1956 56.0834 90.6355 55.8603L159.769 21.1459C160.082 20.9907 160.362 20.775 160.591 20.5114C160.82 20.2477 160.995 19.9413 161.105 19.6098C161.215 19.2782 161.259 18.9281 161.233 18.5797C161.207 18.2313 161.112 17.8914 160.955 17.5797Z"
                        fill="#66757F"
                    />
                    <path
                        d="M31.634 49.5753L31.8737 45.5017L29.4327 45.5017L26.4362 46.7811L26.4362 50.2995L30.1981 50.7698C30.932 50.8615 31.5906 50.3137 31.634 49.5753Z"
                        fill="#3F484E"
                    />
                    <path
                        d="M21.2348 44.03L82.8346 26.75C83.5047 26.5832 84.2134 26.6838 84.8105 27.0306C85.4077 27.3774 86.3815 27.5645 86.5686 28.2292C86.7558 28.8939 86.1417 29.984 85.8132 30.5914C85.4848 31.1988 84.9327 31.6545 84.274 31.8619L31.5826 45.5744L26.1483 46.5519L25.0163 45.916C24.573 43.6157 22.621 43.6412 21.2348 44.03Z"
                        fill="#1E2124"
                    />
                    <path
                        d="M83.5073 25.5665L21.8501 42.6435C21.7164 42.6796 19.5155 43.3936 19.9512 45.6958L19.9512 80.0461C18.5741 81.3125 15.1954 85.1078 16.8405 93.7998C19.2958 106.773 13.9588 115.456 16.8405 114.91C19.509 114.405 26.0123 107.704 29.2458 96.5556C29.7721 94.7408 29.9943 92.8423 29.8389 90.9591C29.3317 84.8135 27.26 80.6349 25.7394 80.0461L25.6262 47.1091L84.9299 30.683C85.5893 30.4779 86.1429 30.024 86.4733 29.4176C86.8038 28.8113 86.8851 28.1001 86.7001 27.4348C86.5151 26.7695 86.0783 26.2023 85.4823 25.8536C84.8863 25.5048 84.1779 25.4018 83.5073 25.5665Z"
                        fill="#FFCC4D"
                    />
                    <path
                        d="M84.8363 27.2945C84.6666 28.0079 84.0118 28.4266 83.3882 28.8125C82.7406 29.2133 82.0319 29.5058 81.29 29.6785L76.9728 30.6831L71.2154 32.0684L60.3404 35.2669L44.9875 39.1052C59.1524 36.3905 31.9769 41.5545 24.7476 44.8506C23.9728 45.2038 23.5715 46.0126 23.5951 46.8637L24.5168 80.0462C23.1397 81.3127 22.8717 86.3874 24.5168 95.0794C26.9721 108.052 13.9587 115.456 16.8404 114.91C19.509 114.405 26.0123 107.704 29.2457 96.5557C29.7721 94.7409 29.9942 92.8424 29.8388 90.9592C29.3316 84.8136 27.2599 80.635 25.7394 80.0462L25.6261 47.1093L84.9298 30.6831C85.5892 30.478 86.1428 30.0241 86.4732 29.4178C86.8037 28.8114 86.885 28.1002 86.7 27.4349C86.515 26.7696 86.0783 26.2025 85.4823 25.8537C85.1129 25.6376 85.0161 26.5387 84.8363 27.2945Z"
                        fill="#D3B200"
                    />
                    <path
                        d="M221.768 207.15C222.236 204.981 222.172 206.761 222.741 199.404C220.58 196.959 213.276 192.694 211.997 194.165C211.03 195.891 212.908 197.462 214.396 199.362C217.803 203.713 219.877 207.485 221.768 207.15Z"
                        fill="black"
                    />
                    <path d="M222.235 219.604L206.707 223.12L222.168 203.473L222.235 219.604Z" fill="black" />
                    <path
                        d="M209.009 222.656C207.719 223.728 206.71 223.378 201.954 224.174C199.412 222.442 194.406 216.308 195.537 214.92C196.953 213.789 198.685 215.345 200.671 216.466C205.062 218.943 208.973 220.858 209.009 222.656Z"
                        fill="black"
                    />
                    <path
                        d="M203.682 218.167L216.66 201.51L220.576 205.523L208.226 221.198L203.682 218.167Z"
                        fill="black"
                    />
                    <path
                        d="M198.248 215.14L212.767 196.774L216.684 201.562L203.674 218.162L198.248 215.14Z"
                        fill="black"
                    />
                    <path
                        d="M195.555 214.902L212.024 194.122L212.029 195.093L212.159 195.679L212.447 196.327L212.766 196.774L198.31 215.157L197.676 215.008L196.504 214.85L195.555 214.902Z"
                        fill="black"
                    />
                    <path
                        d="M222.819 231.727C222.324 233.896 222.82 231.964 221.779 239.483C223.94 241.913 231.126 246.351 232.422 244.894C233.408 243.178 231.557 241.574 230.098 239.647C226.759 235.234 224.709 231.418 222.819 231.727Z"
                        fill="black"
                    />
                    <path d="M222.235 219.602L237.983 216.004L222.521 235.424L222.235 219.602Z" fill="black" />
                    <path
                        d="M235.713 216.522C237.618 215.823 236.059 216.313 242.61 214.784C245.121 216.558 250.251 222.943 249.104 224.319C247.678 225.432 246.001 224.019 244.045 222.852C239.567 220.18 235.693 218.335 235.713 216.522Z"
                        fill="black"
                    />
                    <rect
                        width="2.32516"
                        height="2.32516"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 223.838 233.547)"
                        fill="black"
                    />
                    <path
                        d="M241.128 221.091L227.94 237.517L224.059 233.458L236.409 217.945L241.128 221.091Z"
                        fill="black"
                    />
                    <path
                        d="M246.405 224.059L231.719 242.292L227.846 237.407L240.978 220.983L246.405 224.059Z"
                        fill="black"
                    />
                    <path
                        d="M249.104 224.319L232.413 244.92L232.424 243.949L232.305 243.361L232.029 242.71L231.72 242.26L246.348 224.037L247 224.19L248.161 224.36L249.104 224.319Z"
                        fill="black"
                    />
                    <path
                        d="M210.212 222.416C208.127 222.729 208.191 223.038 201.954 224.14C199.135 227.03 194.243 235.146 195.394 236.198C196.863 236.858 198.971 234.449 201.154 232.431C206.151 227.811 210.121 224.376 210.212 222.416Z"
                        fill="black"
                    />
                    <path
                        d="M208.444 224.272L220.723 233.898L219.883 234.879L208.287 224.502L208.444 224.272Z"
                        fill="black"
                    />
                    <path d="M222.267 219.589L222.492 235.529L206.806 223.128L222.267 219.589Z" fill="black" />
                    <path
                        d="M222.202 233.138C222.488 235.024 222.549 233.446 222.558 240.264C220.305 243.377 213.122 250.258 212.018 249.346C211.229 248.073 213.022 245.751 214.567 243.376C218.103 237.937 220.66 233.846 222.202 233.138Z"
                        fill="black"
                    />
                    <path
                        d="M216.825 239.986L203.547 229.641L208.376 224.362L220.912 234.209L216.825 239.986Z"
                        fill="black"
                    />
                    <path
                        d="M212.832 246.384L198.104 234.852L203.673 229.547L216.96 239.832L212.832 246.384Z"
                        fill="black"
                    />
                    <path
                        d="M212.018 249.346L195.396 236.211L196.341 235.995L196.94 235.73L197.632 235.282L198.135 234.844L212.872 246.346L212.582 247.054L212.174 248.342L212.018 249.346Z"
                        fill="black"
                    />
                    <rect
                        width="2.32516"
                        height="2.32516"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 224.086 233.462)"
                        fill="black"
                    />
                    <path
                        d="M234.862 216.557C237.245 216.012 235.747 216.592 242.61 214.786C245.427 211.894 250.429 203.744 249.359 202.781C247.968 202.209 246.043 204.42 243.869 206.448C238.892 211.092 235.02 214.671 234.862 216.557Z"
                        fill="#000400"
                    />
                    <path d="M222.234 219.602L222.167 203.375L237.981 216.004L222.234 219.602Z" fill="#000400" />
                    <path
                        d="M222.412 205.723C222.168 203.83 222.106 205.526 222.094 198.745C224.345 195.63 231.507 188.713 232.612 189.623C233.402 190.895 231.442 193.069 229.971 195.484C227.202 200.032 224.345 205.044 222.412 205.723Z"
                        fill="#000400"
                    />
                    <path
                        d="M227.812 198.953L241.1 209.285L236.372 214.727L223.778 204.721L227.812 198.953Z"
                        fill="#000400"
                    />
                    <path
                        d="M231.787 192.59L246.539 204.103L240.973 209.412L227.676 199.14L231.787 192.59Z"
                        fill="#000400"
                    />
                    <path
                        d="M232.612 189.622L249.246 202.742L248.301 202.958L247.703 203.223L247.011 203.672L246.508 204.11L231.755 192.629L232.05 191.914L232.457 190.627L232.612 189.622Z"
                        fill="#000400"
                    />
                    <rect
                        width="2.32516"
                        height="2.32516"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 238.038 215.937)"
                        fill="#000400"
                    />
                    <path
                        d="M206.671 223.23L208.23 221.189L209.877 222.415L208.318 224.456L206.671 223.23Z"
                        fill="#000400"
                    />
                    <rect
                        width="2.32516"
                        height="2.32516"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 223.772 204.732)"
                        fill="#000400"
                    />
                    <path
                        d="M221.785 208.41C222.204 206.469 222.146 208.062 222.656 201.48C220.723 199.293 214.188 195.477 213.044 196.793C212.179 198.337 213.859 199.743 215.19 201.443C218.238 205.335 220.093 208.71 221.785 208.41Z"
                        fill="#0069A6"
                    />
                    <path d="M222.203 219.551L208.312 222.697L222.144 205.121L222.203 219.551Z" fill="#00BAF2" />
                    <path
                        d="M210.37 222.282C209.217 223.241 208.314 222.927 204.059 223.64C201.786 222.09 197.307 216.603 198.319 215.361C199.585 214.349 201.135 215.741 202.912 216.744C206.84 218.96 210.339 220.674 210.37 222.282Z"
                        fill="#0069A6"
                    />
                    <path
                        d="M205.605 218.267L217.215 203.365L220.719 206.955L209.67 220.978L205.605 218.267Z"
                        fill="#00AEEF"
                    />
                    <path
                        d="M200.743 215.558L213.732 199.128L217.236 203.411L205.598 218.262L200.743 215.558Z"
                        fill="#0095DA"
                    />
                    <path
                        d="M198.335 215.345L213.068 196.756L213.072 197.624L213.189 198.149L213.447 198.728L213.732 199.128L200.799 215.573L200.232 215.44L199.184 215.299L198.335 215.345Z"
                        fill="#00AEEF"
                    />
                    <path
                        d="M222.727 230.397C222.284 232.338 222.727 230.61 221.797 237.336C223.73 239.51 230.159 243.48 231.317 242.177C232.2 240.641 230.544 239.207 229.239 237.482C226.252 233.535 224.418 230.121 222.727 230.397Z"
                        fill="#0069A6"
                    />
                    <path d="M222.204 219.551L236.292 216.331L222.46 233.705L222.204 219.551Z" fill="#00BAF2" />
                    <path
                        d="M234.262 216.795C235.967 216.169 234.572 216.608 240.432 215.24C242.678 216.827 247.267 222.539 246.242 223.77C244.966 224.766 243.466 223.501 241.716 222.457C237.71 220.068 234.245 218.417 234.262 216.795Z"
                        fill="#0069A6"
                    />
                    <rect
                        width="2.07994"
                        height="2.07994"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 223.638 232.026)"
                        fill="#F5F5F5"
                    />
                    <path
                        d="M239.107 220.882L227.308 235.577L223.837 231.946L234.885 218.068L239.107 220.882Z"
                        fill="#00AEEF"
                    />
                    <path
                        d="M243.827 223.537L230.69 239.849L227.225 235.479L238.973 220.786L243.827 223.537Z"
                        fill="#0095DA"
                    />
                    <path
                        d="M246.242 223.77L231.31 242.2L231.319 241.331L231.213 240.805L230.966 240.223L230.69 239.82L243.776 223.518L244.36 223.654L245.398 223.807L246.242 223.77Z"
                        fill="#00AEEF"
                    />
                    <path
                        d="M211.447 222.069C209.582 222.348 209.639 222.624 204.059 223.61C201.537 226.196 197.161 233.456 198.191 234.398C199.505 234.988 201.39 232.833 203.343 231.028C207.814 226.894 211.365 223.822 211.447 222.069Z"
                        fill="#0F9647"
                    />
                    <path
                        d="M209.866 223.728L220.85 232.34L220.099 233.217L209.726 223.933L209.866 223.728Z"
                        fill="#DFE9A6"
                    />
                    <path d="M222.232 219.539L222.433 233.798L208.4 222.705L222.232 219.539Z" fill="#9EDD00" />
                    <path
                        d="M222.174 231.661C222.429 233.347 222.485 231.936 222.492 238.035C220.477 240.821 214.051 246.976 213.063 246.161C212.357 245.022 213.962 242.944 215.344 240.819C218.507 235.954 220.794 232.294 222.174 231.661Z"
                        fill="#0B9444"
                    />
                    <path
                        d="M217.363 237.786L205.485 228.532L209.805 223.809L221.02 232.618L217.363 237.786Z"
                        fill="#8CC63F"
                    />
                    <path
                        d="M213.792 243.511L200.615 233.194L205.598 228.448L217.485 237.649L213.792 243.511Z"
                        fill="#39B54A"
                    />
                    <path
                        d="M213.063 246.161L198.194 234.409L199.039 234.217L199.574 233.98L200.194 233.578L200.644 233.187L213.827 243.477L213.568 244.11L213.203 245.262L213.063 246.161Z"
                        fill="#9EDD00"
                    />
                    <rect
                        width="2.07994"
                        height="2.07994"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 223.86 231.95)"
                        fill="#F5F5F5"
                    />
                    <path
                        d="M233.501 216.825C235.633 216.338 234.293 216.857 240.432 215.241C242.953 212.654 247.428 205.363 246.47 204.502C245.226 203.989 243.503 205.968 241.559 207.782C237.106 211.936 233.643 215.138 233.501 216.825Z"
                        fill="#0B9444"
                    />
                    <path d="M222.203 219.551L222.144 205.034L236.291 216.331L222.203 219.551Z" fill="#9EDD01" />
                    <path
                        d="M222.363 207.133C222.145 205.439 222.089 206.956 222.079 200.89C224.092 198.103 230.499 191.915 231.488 192.729C232.195 193.868 230.441 195.812 229.125 197.973C226.648 202.041 224.092 206.525 222.363 207.133Z"
                        fill="#0B9444"
                    />
                    <path
                        d="M227.193 201.077L239.08 210.32L234.851 215.188L223.584 206.237L227.193 201.077Z"
                        fill="#8CC63F"
                    />
                    <path
                        d="M230.749 195.384L243.947 205.684L238.968 210.433L227.072 201.243L230.749 195.384Z"
                        fill="#39B54A"
                    />
                    <path
                        d="M231.488 192.729L246.369 204.466L245.524 204.659L244.989 204.897L244.369 205.298L243.919 205.69L230.721 195.419L230.985 194.78L231.349 193.628L231.488 192.729Z"
                        fill="#9EDD01"
                    />
                    <rect
                        width="2.07994"
                        height="2.07994"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 236.342 216.271)"
                        fill="#F5F5F5"
                    />
                    <path
                        d="M208.28 222.796L209.674 220.97L211.147 222.067L209.753 223.893L208.28 222.796Z"
                        fill="#F5F5F5"
                    />
                    <rect
                        width="2.07994"
                        height="2.07994"
                        transform="matrix(-0.680127 0.870656 -0.699085 -0.539134 223.579 206.247)"
                        fill="#F5F5F5"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f_5819_11976"
                        x="50.7304"
                        y="55.0974"
                        width="35.838"
                        height="26.788"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.319853" result="effect1_foregroundBlur_5819_11976" />
                    </filter>
                    <filter
                        id="filter1_f_5819_11976"
                        x="202.995"
                        y="161.444"
                        width="52.5068"
                        height="27.7587"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.319853" result="effect1_foregroundBlur_5819_11976" />
                    </filter>
                    <filter
                        id="filter2_f_5819_11976"
                        x="220.267"
                        y="55.0974"
                        width="35.6539"
                        height="30.1698"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.319853" result="effect1_foregroundBlur_5819_11976" />
                    </filter>
                    <filter
                        id="filter3_f_5819_11976"
                        x="50.3851"
                        y="153.908"
                        width="52.2257"
                        height="34.9508"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.319853" result="effect1_foregroundBlur_5819_11976" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_5819_11976"
                        x1="47.0322"
                        y1="52.897"
                        x2="260.285"
                        y2="188.563"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A4ED6B" />
                        <stop offset="1" stop-color="#53C201" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_5819_11976"
                        x1="153.356"
                        y1="112.868"
                        x2="153.576"
                        y2="165.323"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.242019" stop-color="#32E1FC" />
                        <stop offset="1" stop-color="#00B7D6" />
                    </linearGradient>
                    <clipPath id="clip0_5819_11976">
                        <rect width="290" height="305" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
