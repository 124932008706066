import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/settings/Settings.module.sass';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { SettingsContext } from '../../context/Settings_context';
import { useContext } from 'react';
import { AccountsContext } from '../../context/Accounts_context';
import { ThemeContext } from '@f_context/Theme_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Tariff } from '@f_qwep/types';
import { Icon1c } from '@f_components/icons/Icon1c';
import { Alert } from '@f_general/alert';

export function MenuSettings() {
    const { t } = useTranslation();
    const { tariff } = useContext(QwepContext);
    const { selectedPageName, navigatinHandler } = useContext(SettingsContext);
    const { fullAccounts } = useContext(AccountsContext);
    const { colors } = useContext(ThemeContext);

    const itemsSettings = [
        {
            index: 1,
            title: t('qwep.settings.suppliers.myAccounts'),
            sysName: 'accounts',
            access: [Tariff.UNLIMIT, Tariff.LITE, Tariff.PRO, Tariff.PROPlus, Tariff.Business],
        },
        {
            index: 2,
            title: t('qwep.settings.suppliers.supplier'),
            sysName: 'suppliers',
            access: [Tariff.UNLIMIT, Tariff.LITE, Tariff.PRO, Tariff.PROPlus, Tariff.Business],
        },
        {
            index: 3,
            title: t('qwep.settings.groups.groupAccounts'),
            sysName: 'group_accounts',
            access: [Tariff.UNLIMIT, Tariff.PRO, Tariff.PROPlus, Tariff.Business],
        },
        {
            index: 4,
            title: t('qwep.settings.margin.margin'),
            sysName: 'margin',
            access: [Tariff.UNLIMIT, Tariff.PRO, Tariff.PROPlus, Tariff.Business],
        },
        {
            index: 5,
            title: 'Доп. настройки',
            sysName: 'addSettings',
            access: [Tariff.UNLIMIT, Tariff.LITE, Tariff.PRO, Tariff.PROPlus, Tariff.Business],
        },
        {
            index: 6,
            title: (
                <div className={stls.transfer1c}>
                    Переезд с <Icon1c classNames={[stls.btn1c]} color={colors.omicron} />
                </div>
            ),
            sysName: 'transfer1c',
            access: [Tariff.UNLIMIT, Tariff.LITE, Tariff.PRO, Tariff.PROPlus, Tariff.Business],
        },
    ];

    return (
        <div className={stls.menu}>
            <List className={stls.listItem}>
                {itemsSettings.map((item: any) => {
                    return (
                        <ListItem disabled={!item.access?.some((t: string) => t === tariff)} key={item.index}>
                            <ListItemButton
                                id={item.sysName}
                                className={stls.listItemButton}
                                selected={selectedPageName === item.sysName}
                                onClick={() => {
                                    if (item.access?.some((t: string) => t === tariff)) {
                                        return navigatinHandler(item.sysName);
                                    } else {
                                        Alert({
                                            type: 'info',
                                            text: 'Этот функционал недоступен на вашем тарифе',
                                        });
                                    }
                                }}>
                                {item.access?.some((t: string) => t === tariff) ? (
                                    <ListItemText>
                                        {item.sysName == 'accounts' ? (
                                            <p>
                                                {item.title}{' '}
                                                <sup style={{ color: colors.gamma }} className={stls.gammaText}>
                                                    {fullAccounts.length}
                                                </sup>{' '}
                                            </p>
                                        ) : (
                                            item.title
                                        )}
                                    </ListItemText>
                                ) : (
                                    <ListItemText>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}>
                                            <span>{item.title} </span>
                                            <span style={{ color: colors.alpha }}>{item.access[1].split('_')[1]}</span>
                                        </div>
                                    </ListItemText>
                                )}
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
}
