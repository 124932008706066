import { FC, useContext, useRef } from 'react';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { TFunction } from 'i18next';
import { FilterLeftPanelTemplateButton } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/template/FilterLeftPanelTemplateButton';
import { observer } from 'mobx-react-lite';
import { useFilterLeftPanelStore } from '@f_qwep/components/filters/filterLeftPanel/context/FilterLeftStore.context';
import { FilterLeftPanelTemplatePopover } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/template/FilterLeftPanelTemplatePopover';
import { SearchContext } from '@f_qwep/context/Search_context';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';
import { FilterContext } from '@f_qwep/context/Filter_context';

interface IFilterLeftPanelTemplateProps {
    colors: any;
    styles: any;
    tooltipTitle: string;
    t: TFunction<'translation', undefined>;
}

export const FilterLeftPanelTemplate: FC<IFilterLeftPanelTemplateProps> = observer(
    ({ colors, styles, t, tooltipTitle }) => {
        const anchorRef = useRef<HTMLDivElement>(null);
        const store = useFilterLeftPanelStore();
        const { handleClose, handleOpen, isOpen, setCurrentTemplateFilter } = store.filterLeftTemplate;
        const { handleTemplateRemove } = store.filterLeftTemplateRemove;
        const { filterType } = store.filterLeft;

        const { draftFilterSearch, updateProperty, workspaceApplication } = useContext(QwepContext);
        const { setFilter, filter } = useContext(SearchContext);
        const { filterBatchPricer, setFilterBatchPricer } = useContext(FilterContext);

        return (
            <>
                <FilterLeftPanelTemplateButton
                    colors={colors}
                    draftFilterSearch={draftFilterSearch}
                    tooltipTitle={tooltipTitle}
                    anchorRef={anchorRef}
                    handleOpen={handleOpen}
                />
                <FilterLeftPanelTemplatePopover
                    colors={colors}
                    tooltipTitle={tooltipTitle}
                    anchorRef={anchorRef}
                    handleClose={handleClose}
                    isOpen={isOpen}
                    setCurrentTemplateFilter={setCurrentTemplateFilter}
                    handleTemplateRemove={handleTemplateRemove}
                    workspaceApplication={workspaceApplication}
                    updateProperty={updateProperty}
                    setFilter={filterType === FilterTypesEnum.SEARCH_PRICER ? setFilter : setFilterBatchPricer}
                    filter={filterType === FilterTypesEnum.SEARCH_PRICER ? filter : filterBatchPricer}
                />
            </>
        );
    },
);
