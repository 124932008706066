import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconUpDownTableProps = TypeClassNames;

export const IconUpDownTable = ({ classNames }: TypeIconUpDownTableProps) => {
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.25 12V0.25H17.75V12V12.25H18H20.4798L17 16.5998L13.5202 12.25H16H16.25V12ZM9.75 14.25V15.75H1.25V14.25H9.75ZM11.75 7.25V8.75H1.25V7.25H11.75ZM11.75 0.25V1.75H1.25V0.25H11.75Z"
                    fill={colors.epsilon}
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M1.31646 0.352169L19.3931 15.5203L18.4289 16.6694L0.352277 1.50124L1.31646 0.352169Z"
                    fill={colors.epsilon}
                    stroke="white"
                    strokeWidth="0.5"
                />
            </svg>
        </div>
    );
};
