import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { UpdateItemOrderVendorProps } from '@f_qwep/context/Orders_context';
import GeneralModal from '@f_general/GeneralModal';
import { isEmpty } from 'lodash';


interface ModalChangeItemProps {
    open: boolean;
    data: any;
    closeHandler: any;
    updateItemOrderVendor: (value: UpdateItemOrderVendorProps) => Promise<any>;
}

export function ModalChangeItem({ open, data, closeHandler, updateItemOrderVendor }: ModalChangeItemProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const submitHandler = async (values: UpdateItemOrderVendorProps) => {
        console.log('Values', values);
        const res = await updateItemOrderVendor(values);
        if (res.isOk) {
            closeHandler();
        }
    };

    return (
        <GeneralModal
            openModal={open}
            closeModal={closeHandler}
            sendDataModalHandler={submitHandler}
            dataModal={
                !isEmpty(data)
                    ? {
                          title: `Изменить`,
                          props: [
                              {
                                  title: 'Количество',
                                  control: 'input',
                                  name: 'quantity',
                                  type: 'number',
                                  defaultValue: data.quantity,
                              },
                              {
                                  title: 'Доставка',
                                  control: 'input',
                                  name: 'delivery',
                                  type: 'text',
                                  defaultValue: data.delivery,
                              },
                              {
                                  title: 'Склад',
                                  control: 'input',
                                  name: 'warehouse',
                                  type: 'text',
                                  defaultValue: data.warehouse,
                              },

                          ],
                          shema: null,
                          type: 'changeItem',
                          data: {
                              id: data.id,
                              quantity: data.quantity,
                              delivery: data.delivery,
                              warehouse: data.warehouse,
                          },
                      }
                    : {}
            }
        />
    );
}
