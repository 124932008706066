import { useContext, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import stls from '@f_qwep/styles/component/searchResult/SearchResult.module.sass';
import { Button, CircularProgress } from '@mui/material';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { SearchContext } from '@f_qwep/context/Search_context';
import { IconButtonSearch } from '../icons/IconButtonSearch';
import { IconCloseNotBG } from '../icons/IconCloseNotBG';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { MiniSearchResultTable } from '../tables/miniSearchResultTable';
import { SearchResultTable } from '../tables/searchResultTable';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { useWindowSize } from '@f_hooks';
import { MobileButtonSearchResult } from './MobileButtonSearchResult';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import { ThemeContext } from '@f_context/Theme_context';
import { FilterLeft } from '@f_qwep/components/filters/filterLeftPanel/ui';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

export function SearchResult() {
    const navigate = useQwepNavigate();
    const { colors } = useContext(ThemeContext);

    const {
        arrSearchResults,
        deleteSearch,
        filter,
        tabId,
        setTabId,
        loadingPriceSearch,
        setCurrentSearchArticle,
        setCurrentSearchBrand,
    } = useContext(SearchContext);
    const { searchFiltersReducer } = useContext(FilterContext);
    const tabIdRef = useRef(null);

    const { miniTableRes } = useContext(QwepContext);
    const [itemsTable, setItemsTable] = useState({
        searchId: '',
        items: [],
        index: -1,
        article: '',
        brand: '',
        finished: false,
    });

    const { width } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 950);
    }, [width]);

    useEffect(() => {
        if (!tabId) {
            navigate();
        }
    }, []);

    // TODO Костыльное решение - разобраться с перерендером компонента и контекстом (видимо баг)
    useEffect(() => {
        if (tabIdRef.current !== null) {
            clickHandler(arrSearchResults[0].tabId);
            tabIdRef.current = null;
        } else {
            clickHandler(tabId);
        }
    }, [arrSearchResults, tabId]);

    const clickHandler = (tadId: number) => {
        const arrSearch = arrSearchResults.find((item: any) => item.tabId === tadId);

        if (arrSearch) {
            setItemsTable(arrSearch);
            setTabId(tadId);
            setCurrentSearchArticle(arrSearch.article);
            setCurrentSearchBrand(arrSearch.brand);
        }
    };

    const deleteSearchHandler = (tabId: number) => {
        const arrResult = deleteSearch(tabId);

        if (arrResult?.length) {
            tabIdRef.current = arrResult[0].tabId;
            clickHandler(arrResult[0].tabId);
        }
    };

    return (
        <LayoutPage classNames={cn(stls.container, miniTableRes && stls.miniWidth)}>
            {width > 950 && (
                <FilterLeft
                    items={itemsTable?.items}
                    filter={filter[tabId]}
                    filtersReducer={searchFiltersReducer}
                    type={FilterTypesEnum.SEARCH_PRICER}
                />
            )}
            <div style={{ width: '100%' }}>
                <div className={stls.topPanel}>
                    {mobile && (
                        <MobileButtonSearchResult
                            filter={filter[tabId]}
                            filtersReducer={searchFiltersReducer}
                            items={itemsTable?.items ?? []}
                        />
                    )}

                    <div style={{ display: 'flex', width: '100%' }}>
                        {width < 700 ? (
                            itemsTable.finished ? null : (
                                <CircularProgress />
                            )
                        ) : arrSearchResults?.length ? (
                            arrSearchResults.map((item: any) => {
                                return (
                                    <div id="searchItem" className={stls.btn} key={item.tabId}>
                                        <Button
                                            variant="outlined"
                                            onClick={() => clickHandler(item.tabId)}
                                            className={item.tabId === tabId ? stls.btnItem : stls.btnItemDisable}
                                            startIcon={<IconButtonSearch />}
                                            endIcon={
                                                item.finished ? (
                                                    <div
                                                        className={stls.iconClose}
                                                        onClick={() => deleteSearchHandler(item.tabId)}>
                                                        <IconCloseNotBG />
                                                    </div>
                                                ) : (
                                                    <CircularProgress className={stls.loadingTable} size={'15px'} />
                                                )
                                            }>
                                            {`${item.article} ${item.brand}`}
                                        </Button>
                                    </div>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                    {loadingPriceSearch && (
                        <div style={{ color: colors.alpha }} className={stls.loadingPriceSerach}>
                            <CircularProgress sx={{ marginRight: '8px' }} size={18} />
                            Поиск по прайсам...
                        </div>
                    )}
                </div>

                {miniTableRes ? (
                    <MiniSearchResultTable
                        filter={filter[tabId]}
                        filtersReducer={searchFiltersReducer}
                        items={itemsTable?.items ?? []}
                        setItemsTable={setItemsTable}
                    />
                ) : (
                    <SearchResultTable
                        filter={filter[tabId]}
                        filtersReducer={searchFiltersReducer}
                        items={itemsTable?.items ?? []}
                        setItemsTable={setItemsTable}
                    />
                )}
            </div>
        </LayoutPage>
    );
}
