import { I18nextProvider } from 'react-i18next';
import { ApplicationProvider } from './Applicatuion_context';
import { AuthProvider } from './Auth_context';
import { GeneralProvider } from './General_context';
import { ThemeProvider } from './Theme_context';
import { UserProvider } from './User_context';
import { WorkspaceProvider } from './Workspace_context';
import i18n from '@f_utils/language/i18n';
import { ManualProvider } from './Manual_context';
import { SupportProvider } from './Support_context';
import {QueryProvider} from "@f_context/Query_context";

function AppProviders({ children }: any) {
    return (
        <I18nextProvider i18n={i18n}>
            <AuthProvider>
                <QueryProvider>
                    <UserProvider>
                        <WorkspaceProvider>
                            <GeneralProvider>
                                <SupportProvider>
                                    <ThemeProvider>
                                        <ManualProvider>
                                            <ApplicationProvider>{children}</ApplicationProvider>
                                        </ManualProvider>
                                    </ThemeProvider>
                                </SupportProvider>
                            </GeneralProvider>
                        </WorkspaceProvider>
                    </UserProvider>
                </QueryProvider>
            </AuthProvider>
        </I18nextProvider>
    );
}
export default AppProviders;
