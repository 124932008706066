import { SettingsContext } from '@f_qwep/context/Settings_context';
import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { Alert } from '@f_general/alert';
import { IconMaskot } from '@f_components/icons/IconMascot';

export function WorkWithResultManual() {
    const { task, dashboardList } = useContext(BatchPricerContext);
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<any>([
        {
            target: '#batch-pricer',
            content: (
                <div>
                    <IconMaskot />
                    <p>Перейдите в раздел "Проценка"</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#cellResults',
            content: 'Далее перейдите в результаты проценки',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#addPosition',
            content: 'Редактируйте список проценки',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#editBatchPricer',
            content: 'Редактируйте список проценки',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#startBatchPricer',
            content: 'Запустите список заново на проценку',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#resultsInResults',
            content: (
                <p>
                    Перейдите в результаты. <br /> Скачайте проценку конкретного товара в файл или весь список
                    результов. <br /> Используйте классические фильтры слева от таблицы для работы с результатами
                </p>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
    ]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            if (dashboardList.length) {
                setRun(true);
                if (!newManualValue['batch-pricer']?.flag) {
                    setNewManualValue({ ...newManualValue, 'batch-pricer': { flag: true } });
                }
            } else {
                Alert({
                    type: 'warning',
                    text: 'Для просмотра мануала сначала загрузите проценку списком!',
                });
            }
        }

        if (window.location.pathname == '/app-qwep/batch-pricer') {
            if (dashboardList.length) {
                setTimeout(() => {
                    setCurrentStep((prevStep) => prevStep + 1);
                }, 1000);
            }
        }
    }, [window.location.pathname, dashboardList.length]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/batch-pricer' && task.length) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [task]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
            setChooseManual();
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
