import ReactDOM from 'react-dom/client';
import '@f_styles/base.sass';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
import AppProviders from '@f_context';
import { ActivationPage } from '@f_components/auth/ActivationPage';
import { PasswordReset } from '@f_components/auth/PasswordReset';
import { Toaster } from 'react-hot-toast';
import { EmailReset } from '@f_components/auth/EmailReset';
import { PiercedCompany } from '@f_components/auth/PiercedCompany';
import { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { PasswordResetOld } from '@f_components/auth/PasswordResetOld';

// class ErrorBoundary extends React.Component {
//   constructor(props :any) {
//     super(props);
//     this.state = { hasError: false } as Readonly<{}>;
//   }

//   static getDerivedStateFromError(error: any) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   render() {
//     if (this.state) {
//       return <h1>Something went wrong.</h1>;
//     }

//     return this.props;
//   }
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const Index = () => {
    return (
        <CookiesProvider>
            <AppProviders>
                <Suspense fallback="Loading...">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/*" element={<App />} />
                            <Route path="auth/confirm" element={<ActivationPage />} />
                            <Route path="password/reset" element={<PasswordReset />} />
                            <Route path="password/reset/old" element={<PasswordResetOld />} />
                            <Route path="email/reset" element={<EmailReset />} />
                            <Route path="add/workspace" element={<PiercedCompany />} />
                        </Routes>
                        <Toaster />
                    </BrowserRouter>
                </Suspense>
            </AppProviders>
        </CookiesProvider>
    );
};
root.render(<Index />);
