import * as XLSX from 'xlsx';

export class ExcelFileUtil {
    private readonly file: File;
    private arrayBuffer: ArrayBuffer | null = null;

    constructor(file: File) {
        this.file = file;
    }

    public async getJson(): Promise<object[] | null> {
        const arrayBuffer = await this.readXlsxFile();

        if (!arrayBuffer) {
            return null;
        }

        const workbook = XLSX.read(arrayBuffer, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        return XLSX.utils.sheet_to_json(sheet, { header: 1 });
    }

    private async readXlsxFile(): Promise<ArrayBuffer | null> {
        if (!this.arrayBuffer) {
            const fileReader = new FileReader();

            try {
                this.arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
                    fileReader.onload = (event) => {
                        const arrayBuffer = event.target?.result as ArrayBuffer;
                        resolve(arrayBuffer);
                    };

                    fileReader.onerror = (event) => {
                        reject(event.target?.error);
                    };

                    fileReader.readAsArrayBuffer(this.file);
                });
            } catch (error) {
                console.error(error);
                return null;
            }
        }

        return this.arrayBuffer;
    }
}
