import React, { useContext, useState } from 'react';
import { Grow, ClickAwayListener, List, Popper, CardContent } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import dayjs from 'dayjs';
import { ErrorsContext } from '../../context/Errors_context';
import { AccountsContext } from '../../context/Accounts_context';
import { IconNotif } from '../icons/IconNotif';
import stls from '@f_qwep/styles/component/header/HeaderComponent.module.sass';

export const ShowErrors = () => {
    const { colors } = React.useContext(ThemeContext);
    const { vendorErrors } = useContext(ErrorsContext);
    const { fullAccounts } = useContext(AccountsContext);

    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button ref={anchorRef} disabled={vendorErrors?.length > 0 ? false : true} onClick={() => setOpen(true)}>
                <IconNotif />
            </button>
            <Popper
                onClick={() => setOpen(false)}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                className={stls.popperErrors}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List
                            className={stls.listErrors}
                            sx={{
                                background: colors.psi,
                                boxShadow:
                                    '0px 4.82353px 24.1177px rgba(156, 172, 185, 0.300953), 0px 2.00345px 10.0172px rgba(156, 172, 185, 0.25), 0px 0.724608px 3.62304px rgba(156, 172, 185, 0.159047)',
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <CardContent style={{ padding: '12px' }}>
                                    {vendorErrors?.map((item: any, idx: any) => (
                                        <div key={idx}>
                                            <div style={{ display: 'flex' }}>
                                                <p
                                                    style={{
                                                        fontSize: '14px',
                                                        color: colors.epsilon,
                                                        marginRight: '8px',
                                                    }}>
                                                    {dayjs(item.date).format('DD.MM.YY HH:mm')}
                                                </p>
                                                <p style={{ fontSize: '14px', color: colors.phi }}>
                                                    {item.title
                                                        ? item.title?.slice(0, 50)
                                                        : fullAccounts
                                                              ?.find((elem: any) => elem.id == item.accountId)
                                                              ?.title.slice(0, 50)}
                                                </p>
                                            </div>
                                            <p style={{ fontSize: '14px' }}>
                                                {item.error.includes('Таймаут запроса search6050')
                                                    ? 'Поставщик не отвечает.'
                                                    : item.error}
                                            </p>
                                        </div>
                                    ))}
                                </CardContent>
                            </ClickAwayListener>
                        </List>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};
