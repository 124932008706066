import { Button, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect, useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '../../../styles/component/basket/MiniBasket.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { useWindowSize } from '@f_hooks';
import { PageNoData } from '@f_qwep/general/PageNoData';
import SearchIcon from '@mui/icons-material/Search';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { BasketContext } from '@f_qwep/context/Basket_context';
import { BasketPanel } from '../BasketPanel';
import { CellGeneralBasketTotal } from './CellDateGeneralBasket';
import { IconBasket } from '../../icons/IconBasket';
import { IconMessage } from '../../icons/IconMessage';
import { MiniBasket } from './MiniBasket';
import { MobileButtonBasket } from '../MobileButtonVasket';
import { CellMobile } from '../GeneralBasketTable/CellMobile';
import { Alert } from '@f_general/alert';
import { IconUpdateBase } from '../../icons/IconUpdateBase';
import { CellBrand } from './CellBrand';

export function GeneralMiniBasket() {
    const { t } = useTranslation();
    const { width, height } = useWindowSize();
    const {
        basket,
        checkedItems,
        setCheckedItems,
        cartTimes,
        setSearchWord,
        getCartVendor,
        setCheckedBasketItems,
        checkedBasketItems,
        deleteCart,
        deleteCartItem,
        setBasketForm,
        setOpenModalForm,
        setCheckedOnlyItem,
    } = useContext(BasketContext);

    const { colors, Search, SearchIconWrapper, StyledInputBase } = useContext(ThemeContext);
    const { setOpenExportModal, setExportData, setTypeExport } = useContext(QwepContext);
    const { filterBasket } = useContext(FilterContext);

    const [basketTable, setBasketTable] = useState(basket);

    const [mobile, setMobile] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const [checked, setChecked] = useState(false);

    const handler = (word: string) => {
        setSearchWord(word);
    };

    useEffect(() => {
        setBasketTable(basket);
    }, [basket]);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    useEffect(() => {
        setChecked(true);
        setCheckedBasketItems(
            basket.reduce((object: any, value: any) => {
                return {
                    ...object,
                    [value.accountId]: {
                        check: !checked,
                        basketId: value.basketId,
                        hasPartOrder: value.hasPartOrder,
                        basketForm: value.basketForm,
                        items: value.basketItems.map((el: any) => +el.basketItemId),
                    },
                };
            }, {}),
        );
    }, []);

    useEffect(() => {
        if (checked) {
            if (Object.keys(checkedBasketItems).length) {
                if (Object.keys(checkedBasketItems).length != basket.length) {
                    setChecked(false);
                } else {
                    for (let key in checkedBasketItems) {
                        if (!checkedBasketItems[key].check) {
                            setChecked(false);
                        }
                    }
                }
            }
        } else if (
            Object.keys(checkedBasketItems).length === basket.length &&
            Object.values(checkedBasketItems).filter((el: any) => el.check === false).length === 0
        ) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [JSON.stringify(checkedBasketItems)]);

    // Фильтруем результаты
    const getFiltredResults = () => {
        let result: any[] = [];

        if (basketTable) {
            result = basketTable;
        }

        // ПОСТАВЩИКИ
        if (filterBasket?.vendorTitle.length > 0) {
            result = result.filter((row) => filterBasket?.vendorTitle.includes(row.vendorTitle));
        }

        return result;
    };

    const data = useMemo(() => getFiltredResults() ?? [], [basketTable, filterBasket]);

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'title',
                          accessorKey: '',
                          cell: (info) => (
                              <CellMobile info={info} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
                          ),
                          footer: (props) => props.column.id,
                      },
                  ]
                : [
                      // {
                      //   id: 'date',
                      //   accessorKey: '',
                      //   cell: (info) => (
                      //     <CellDateGeneralBasket
                      //       info={info}
                      //       checkedItems={checkedItems}
                      //       setCheckedItems={setCheckedItems}
                      //       mobile={mobile}
                      //       cartTimes={cartTimes}
                      //     />
                      //   ),
                      //   footer: (props) => props.column.id,
                      //   size: 1,
                      // },
                      {
                          id: 'Производитель',
                          accessorKey: 'manufacturer',
                          header: `${t('qwep.basket.brand')}`,
                          cell: (info) => <CellBrand info={info} />,
                          footer: (props) => props.column.id,
                          size: 380,
                      },
                      // {
                      //   id: 'Артикул',
                      //   accessorKey: 'pin',
                      //   header: `${t('qwep.basket.article')}`,
                      //   cell: (info) => (
                      //     <CellArticleGeneralBasket
                      //       info={info}
                      //       mobile={mobile}
                      //       cartTimes={cartTimes}
                      //     />
                      //   ),
                      //   footer: (props) => props.column.id,
                      //   size: 150,
                      // },
                      {
                          id: 'Количество',
                          accessorKey: 'quantity',
                          header: () => <IconBasket />,
                          cell: (info) => (
                              <div className={stls.cellDiv}>
                                  <p style={{ background: colors.upsilon }} className={stls.cellBasketButton}>
                                      {info.row.original.basketItems?.length}
                                  </p>
                              </div>
                          ),
                          footer: (props) => props.column.id,
                          size: 100,
                      },
                      {
                          id: 'Цена',
                          accessorKey: 'price',
                          header: `${t('qwep.basket.price')}`,
                          footer: (props) => props.column.id,
                          aggregationFn: 'sum',
                          size: 80,
                      },
                      {
                          id: 'Склад',
                          accessorKey: 'warehouse',
                          header: `${t('qwep.basket.warehouse')}`,
                          footer: (props) => props.column.id,
                          size: 150,
                      },
                      {
                          id: 'comment',
                          accessorKey: 'notes',
                          header: () => <IconMessage />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                      {
                          id: 'Итого',
                          accessorKey: '',
                          header: `${t('qwep.basket.total')}`,
                          cell: (info) => (
                              <CellGeneralBasketTotal
                                  info={info}
                                  checkedItems={checkedItems}
                                  setCheckedItems={setCheckedItems}
                              />
                          ),
                          footer: (props) => props.column.id,
                          size: 80,
                      },
                      {
                          id: 'Удалить',
                          accessorKey: '',
                          header: '',
                          // cell: (info) => <CellGeneralBasketDelete info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                  ],
        [mobile, cartTimes, checkedItems],
    );

    const tableStyles = {
        head: {
            base: false,
            miniFilterInput: true,
            background: colors.beta,
            container: {
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            title: {
                fontSize: '11px',
            },
        },
        body: {
            tableBody: {
                borderLeft: '1px solid #c2c2c2',
                borderRight: '1px solid #c2c2c2',
            },
            row: {
                background: colors.deltaBg,
                height: '20px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: false,
        },
    };

    const renderCustomSubComponent = ({ row }: any) => {
        return (
            <MiniBasket
                basket={row.original.basketItems}
                hasPartOrder={row.original.hasPartOrder}
                accountId={row.original.accountId}
                basketId={row.original.basketId}
                basketForm={row.original.basketForm}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
            />
        );
    };

    const exportExcelHandler = () => {
        setTypeExport('basket');
        setExportData({ filter: data, all: basket });
        setOpenExportModal(true);
    };

    const updateBasket = () => {
        data.map(async (el: any) => {
            setLoading(true);
            await getCartVendor(el.accountId);
            setLoading(false);
        });
    };

    const checkoutBasket = async () => {
        for (let key in checkedBasketItems) {
            await setBasketForm({
                ...checkedBasketItems[key].basketForm,
                accountId: +key,
                basketId: checkedBasketItems[key].basketId,
                hasPartOrder: checkedBasketItems[key].hasPartOrder,
            });
            await setOpenModalForm(true);

            if (checkedBasketItems[key].check) {
                await setCheckedOnlyItem(checkedBasketItems[key].items);
            } else {
                await setCheckedOnlyItem(checkedBasketItems[key].items.map((el: any) => +el));
            }
        }
    };

    // выбраны все элементы корзины
    const handleBasketCheckbox = () => {
        if (checked) {
            setChecked(false);
            setCheckedBasketItems({});
        } else {
            setChecked(true);
            setCheckedBasketItems(
                basket.reduce((object: any, value: any) => {
                    return {
                        ...object,
                        [value.accountId]: {
                            check: !checked,
                            basketId: value.basketId,
                            hasPartOrder: value.hasPartOrder,
                            basketForm: value.basketForm,
                            items: value.basketItems.map((el: any) => +el.basketItemId),
                        },
                    };
                }, {}),
            );
        }
    };
    // удаление из корзины ПРОВЕРИТЬ ТОЖЕ
    const handleDeleteBasketItems = async () => {
        for (let key in checkedBasketItems) {
            if (checkedBasketItems[key].check) {
                setLoadingDelete(true);
                const result = await deleteCart({
                    accountId: +key,
                    basketId: checkedBasketItems[key].basketId,
                });
                if (result.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                } else {
                    if (result.result?.errors?.length) {
                        result.result?.errors.map((item: any) => {
                            Alert({
                                type: 'warning',
                                text: item.message,
                                time: 8000,
                            });
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                }
                setLoadingDelete(false);
            } else {
                setLoadingDelete(true);
                checkedBasketItems[key].items.map(async (el: any) => {
                    const result = await deleteCartItem({
                        accountId: key,
                        basketId: checkedBasketItems[key].basketId,
                        basketItemId: el,
                    });

                    if (result.isOk) {
                        Alert({
                            type: 'success',
                            text: t('general.success'),
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                    setLoadingDelete(false);
                });
            }
        }
    };

    return (
        <div style={{ width: '100%', display: 'flex' }}>
            {width > 1100 && <BasketPanel items={basket} />}
            <div style={{ width: '100%' }}>
                {basket?.length ? (
                    <>
                        <div className={stls.appbarGroup}>
                            <Search className={stls.search}>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder={t('qwep.basket.basketSearch')}
                                    onChange={(e: any) => handler(e.target.value.toLocaleLowerCase())}
                                />
                            </Search>
                            <div style={{ display: 'flex' }}>
                                <div
                                    onClick={updateBasket}
                                    style={{
                                        border: `2px solid ${colors.alpha}`,
                                        borderRadius: '12px',
                                        padding: '8px 16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        height: '50px',
                                    }}>
                                    {loading ? <CircularProgress size={20} /> : <IconUpdateBase />}
                                </div>
                                <Button
                                    sx={{ height: '50px', marginLeft: '16px' }}
                                    variant="contained"
                                    onClick={checkoutBasket}>
                                    Перейти к оформлению
                                </Button>
                            </div>
                            {mobile && <MobileButtonBasket items={basket} />}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                position: 'absolute',
                                marginTop: '8px',
                            }}>
                            <FormControlLabel
                                label="Выбрать все"
                                control={<Checkbox checked={checked} onChange={handleBasketCheckbox} />}
                            />
                            <button onClick={handleDeleteBasketItems} style={{ color: colors.lambda }}>
                                Удалить выбранные
                                {loadingDelete && <CircularProgress size={20} />}
                            </button>
                        </div>
                        <VirtualTable
                            data={data}
                            columnsTable={columns}
                            getRowCanExpand={() => true}
                            topFilterPanel={true}
                            showColumnSearchAction={false}
                            renderCustomSubComponent={renderCustomSubComponent}
                            heightTable={height - 150}
                            configHeader={{
                                fullScrean: true,
                                exportExcel: true,
                                resize: false,
                                grouping: false,
                                columnFilter: false,
                                sorting: false,
                                columnSearch: false,
                                dragging: false,
                            }}
                            styles={tableStyles}
                            exportExcelHandler={exportExcelHandler}
                        />
                    </>
                ) : (
                    <PageNoData type={'basket'} />
                )}
            </div>
        </div>
    );
}
