import { ThemeContext } from '@f_context/Theme_context';
import { MyModal } from '@f_general/MyModal';
import { Box, CardContent, Modal } from '@mui/material';
import { useContext, useEffect } from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { observer } from 'mobx-react-lite';
import { CustomListModalTitle } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal/CustomListModalTitle';
import { CustomListModalName } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal/CustomListModalName';
import { CustomListModalPositions } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal/CustomListModalPositions';
import { CustomListModalActions } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal/CustomListModalActions';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { useCustomListActions } from '@f_qwep/components/purchasingAssistant/model/hooks/useCustomListActions';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';
import { PreviewDownloadedFileTable } from '@f_qwep/general/previewTable/ui/PreviewDownloadedFileTable';

export const CustomListModal = observer(() => {
    const { colors } = useContext(ThemeContext);
    const { mutate: handleSubmit } = useCustomListActions();
    const store = useAssistantStore();

    const { currentAssistantList } = store.assistantList;

    const {
        setTitle,
        setPrivateList,
        handleClose,
        isModalOpen: isModalOpenList,
        currentTypeModal: type,
        isButtonDisabled,
        currentPositionLoadingOption: option,
        setIsShowPreviewTable,
        previewTableStore,
        isShowPreviewTable,
        handleBack,
    } = store.assistantModalList;

    const { getTableData, getHeaders } = previewTableStore;

    useEffect(() => {
        if (type === ConfigPanelModalType.UPDATE && currentAssistantList) {
            setTitle(currentAssistantList?.title);
            setPrivateList(currentAssistantList?.private);
        }
    }, [isModalOpenList]);

    return (
        <Modal open={isModalOpenList} onClose={handleClose}>
            <Box>
                <MyModal className={styles.modal}>
                    <CardContent className={styles.modalContent}>
                        {!isShowPreviewTable && <CustomListModalTitle />}
                        <form
                            className={styles.formContent}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                if (
                                    option === PositionLoadingOptionsEnum.FILE_INPUT &&
                                    !isButtonDisabled &&
                                    !isShowPreviewTable
                                ) {
                                    setIsShowPreviewTable(true);
                                    return;
                                }
                                await handleSubmit();
                            }}>
                            {isShowPreviewTable ? (
                                <PreviewDownloadedFileTable
                                    maxRows={6}
                                    colors={colors}
                                    headers={getHeaders()}
                                    rows={getTableData()}
                                />
                            ) : (
                                <>
                                    <CustomListModalName />
                                    <CustomListModalPositions colors={colors} />
                                </>
                            )}
                            <CustomListModalActions
                                colors={colors}
                                handleClose={handleClose}
                                isButtonDisabled={isButtonDisabled}
                                option={option}
                                isShowPreviewTable={isShowPreviewTable}
                                handleBack={handleBack}
                            />
                        </form>
                    </CardContent>
                </MyModal>
            </Box>
        </Modal>
    );
});
