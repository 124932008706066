import { useMemo } from 'react';
import { getFilteredResults } from '@f_qwep/components/filters/utils/getFilteredResults';

export const useFilteredResults = (items, filter, priceMarginIsActive, priceMarginState, searchItem) => {
    return useMemo(
        () =>
            getFilteredResults({
                items,
                filter,
                priceMarginIsActive,
                priceMarginState,
                searchItem,
            }) ?? [],
        [items, filter, priceMarginIsActive, priceMarginState, searchItem],
    );
};
