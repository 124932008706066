import { Tooltip } from '@mui/material';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';

export function CellWarehouse({ info }: any) {
    const wHouse = info.row.original.warehouseOrder;

    return (
        <div className={stls.cellWarehouse}>
            {wHouse ? (
                <Tooltip title={info.row.original.warehouse}>
                    <p className={stls.text}>{wHouse}</p>
                </Tooltip>
            ) : (
                <Tooltip title={info.row.original.warehouse}>
                    <p className={stls.text}>{info.row.original.warehouse}</p>
                </Tooltip>
            )}
        </div>
    );
}
