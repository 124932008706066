import axios from 'axios';
import { setTokens } from './setToken';

export const getToken = async () => {
    const expiresIn = localStorage.getItem('expiresIn');

    if (!expiresIn) {
        return false;
    }

    if (Date.now() >= +expiresIn) {
        const refreshToken = localStorage.getItem('refreshToken');

        const instance = axios.create({
            baseURL: '/api',
            timeout: 50000,
            headers: { Authorization: `Bearer ${refreshToken}` },
        });
        try {
            const result = await instance({
                method: 'get',
                url: 'auth/refresh',
            });

            console.log('__refresh__');

            setTokens(result.data);
            return result.data.accessToken;
        } catch (err) {
            console.log('__err__', err);
        }
    } else {
        const accessToken = localStorage.getItem('token');
        // console.log('___accessToken___', accessToken);

        return accessToken;
    }
};
