import { ThemeContext } from '@f_context/Theme_context';
import { Tooltip } from '@mui/material';
import React from 'react';
import { IconMessage } from '../../icons/IconMessage';

export function CellComment({ info }: any) {
    const { colors } = React.useContext(ThemeContext);

    const comment = info.row.original.fields?.find((item: any) => item.fieldName === 'Комментарий')?.defaultValue;

    return (
        <Tooltip title={comment}>
            <div style={{ textAlign: 'center', width: '65px' }}>
                <IconMessage color={comment?.length > 0 ? colors.phi : colors.tau} />
            </div>
        </Tooltip>
    );
}
