import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconEditQwepProps = TypeClassNames;

export const IconEditQwep = ({ classNames }: TypeIconEditQwepProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 13.243V19.008C17.9998 19.2712 17.895 19.5235 17.7088 19.7095C17.5226 19.8955 17.2702 20 17.007 20H0.993025C0.861702 19.9991 0.731845 19.9723 0.61087 19.9212C0.489895 19.8701 0.38017 19.7957 0.287961 19.7022C0.195752 19.6087 0.122864 19.4979 0.0734597 19.3762C0.0240555 19.2545 -0.000897804 19.1243 2.4673e-05 18.993V7H6.00002C6.26524 7 6.5196 6.89464 6.70713 6.70711C6.89467 6.51957 7.00002 6.26522 7.00002 6V0H17.002C17.553 0 18 0.455 18 0.992V4.757L9.00103 13.757L8.99502 17.995L13.241 18.001L18 13.243ZM18.778 6.808L20.192 8.222L12.414 16L10.998 15.998L11 14.586L18.778 6.808ZM2.4673e-05 5L5.00002 0.00300002V5H2.4673e-05Z"
                    fill="#F08E13"
                />
            </svg>
        </div>
    );
};
