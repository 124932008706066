import { Tooltip } from '@mui/material';
import cn from 'classnames';
import stls from '../../../styles/component/basket/Basket.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';

export function CellArticle({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <Tooltip placement="top" title={info.row.original.article}>
            <p style={{ color: colors.alpha }} className={cn(stls.cellArticle)}>
                {info.row.original.article}
            </p>
        </Tooltip>
    );
}
