import { IconButton, Tooltip } from '@mui/material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';


export function CellSettings({ info, itemChangeOpenHandler, itemChangeStatusOpenHandler }: any ) {

    const clickHandlerSettings = () => {
        itemChangeOpenHandler({
            open: true,
            data: {
                id: info.row.original.itemOrderId,
                quantity: info.row.original.quantity,
                delivery: info.row.original.delivery,
                warehouse: info.row.original.warehouse,
            },
        });
    };

    const clickHandlerStatusItem = () => {
        itemChangeStatusOpenHandler({
            open: true,
            data: {
                orderId: info.row.original.orderId,
                orderItemId: info.row.original.itemOrderId,
                status: info.row.original.statusItem,
            },
        });
    };           

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={'Сменить параметры позиции'}>
                <IconButton
                    onClick={clickHandlerSettings}
                >
                    <SettingsApplicationsIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title={'Сменить статус позиции'}>
                <IconButton
                    onClick={clickHandlerStatusItem}
                >
                    <PublishedWithChangesIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
}









