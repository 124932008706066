type TypeTokens = {
    accessToken: string;
    refreshToken: string;
    expiresIn: string;
};
export const setTokens = (data: TypeTokens) => {
    localStorage.setItem('token', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('expiresIn', data.expiresIn);
};
