import { IconAddAcc } from '../../icons/IconAddAcc';
import { useTranslation } from 'react-i18next';
import stl from '@f_styles/general/Universal.module.sass';
import stls from '../../../styles/component/settings/Suppliers.module.sass';
import GeneralModal from '@f_general/GeneralModal';
import { useContext, useEffect, useState } from 'react';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { addAccountModalCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { Alert } from '@f_general/alert';
import { ThemeContext } from '@f_context/Theme_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function CellAdd({ info }: any) {
    const { t } = useTranslation();
    const { addVendor } = useContext(AccountsContext);
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});
    const [loadingAdd, setLoadingAdd] = useState(false);
    const { colors } = useContext(ThemeContext);

    const closeHandler = () => {
        setDataModal({});
        setOpen(false);
    };

    const clickModalHandler = () => {
        let props: any;
        if (info.row.original.branches) {
            props = [
                {
                    title: t('qwep.settings.suppliers.modal.add.name'),
                    control: 'input',
                    name: 'title',
                    type: 'text',
                    placeholder: t('qwep.settings.suppliers.modal.add.namePlaceholder'),
                },
                {
                    title: t('qwep.settings.suppliers.modal.add.login'),
                    control: 'input',
                    name: 'login',
                    type: 'text',
                    placeholder: t('qwep.settings.suppliers.modal.add.loginPlaceholder'),
                },
                {
                    title: t('qwep.settings.suppliers.modal.add.password'),
                    control: 'input',
                    name: 'password',
                    type: 'password',
                    placeholder: t('qwep.settings.suppliers.modal.add.passwordPlaceholder'),
                },
                {
                    control: 'select',
                    name: 'branch',
                    title: t('qwep.settings.suppliers.modal.add.branch'),
                    defaultValue: '',
                    options: (() => {
                        let opt: any = [];
                        info.row.original.branches.map((item: any) => {
                            opt.push({
                                key: item.title,
                                value: item,
                            });
                        });
                        return opt;
                    })(),
                },
            ];
        } else {
            props = [
                {
                    title: t('qwep.settings.suppliers.modal.add.name'),
                    control: 'input',
                    name: 'title',
                    type: 'text',
                    placeholder: t('qwep.settings.suppliers.modal.add.namePlaceholder'),
                },
                {
                    title: t('qwep.settings.suppliers.modal.add.login'),
                    control: 'input',
                    name: 'login',
                    type: 'text',
                    placeholder: t('qwep.settings.suppliers.modal.add.loginPlaceholder'),
                },
                {
                    title: t('qwep.settings.suppliers.modal.add.password'),
                    control: 'input',
                    name: 'password',
                    type: 'password',
                    placeholder: t('qwep.settings.suppliers.modal.add.passwordPlaceholder'),
                },
            ];
        }

        setDataModal({
            icon: <IconModalGroup />,
            title: t('qwep.settings.suppliers.modal.add.title'),
            props: props,
            shema: addAccountModalCustomerSchema,
            type: 'addAccount',
            data: {
                title: info.row.original.title,
                login: '',
                password: '',
                vid: info.row.original.id,
                branch: null,
            },
        } as TypeDataModal);
        setOpen(true);
    };

    const sendDataModalHandler = async (values: any) => {
        setLoadingAdd(true);
        const result = await addVendor({
            ...values,
            bid: values.branch?.id,
        });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            closeHandler();
        } else {
            Alert({
                type: 'error',
                text: result.errors,
                position: 'top-center',
            });
            setLoadingAdd(false);
        }
    };

    return (
        <div className={stls.cellAdd}>
            <button className={stl.flexAlignCenter} onClick={clickModalHandler}>
                <IconAddAcc />
                <p style={{ color: colors.omicron }} className={stls.text}>
                    {t('qwep.settings.suppliers.addAccount')}
                </p>
            </button>
            <GeneralModal
                openModal={open}
                closeModal={closeHandler}
                sendDataModalHandler={sendDataModalHandler}
                dataModal={dataModal}
                loading={loadingAdd}
            />
        </div>
    );
}
