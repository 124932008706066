import { action, makeObservable, observable } from 'mobx';
import { FilterLeftTemplateStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftTemplate.store';
import { Alert } from '@f_general/alert';
import { Property } from '@f_qwep/types';
import { IStandardPayload } from '@f_qwep/types/StandardPayload.interface';
import {
    IReqBodyTemplate,
    IResponseResultTemplate,
} from '@f_qwep/components/filters/filterLeftPanel/types/template.interface';

// TODO В принципе как минимум флаг можно удалить из объекта в будущем
// TODO Перевод алертов

export class FilterLeftTemplateRemoveStore {
    filterTemplateStore: FilterLeftTemplateStore;
    removedTemplateTitle: string = '';

    constructor(filterLeftTemplateStore: FilterLeftTemplateStore) {
        this.filterTemplateStore = filterLeftTemplateStore;
        makeObservable(this, {
            removedTemplateTitle: observable,
            handleTemplateRemove: action.bound,
        });
    }

    async handleTemplateRemove({ workspaceApplication, updateProperty, id, searchFiltersReducer }: any) {
        const reqBody = this.generateReqBody({ workspaceApplication, id });
        const response: IStandardPayload<IResponseResultTemplate> = await updateProperty(reqBody);

        if (response?.isOk) {
            Alert({
                type: 'success',
                text: `Шаблон фильтра ${this.removedTemplateTitle} успешно удален.`,
            });
            const { setCurrentTemplateFilters, activeTemplateId, resetCurrentTemplateFilter, handleClose } =
                this.filterTemplateStore;

            setCurrentTemplateFilters(reqBody.value.value);
            if (activeTemplateId === id) {
                resetCurrentTemplateFilter(searchFiltersReducer);
            }
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: `Ошибка удаления шаблона.`,
            });
        }
    }

    private generateReqBody = ({ workspaceApplication, id }: any): IReqBodyTemplate => {
        const { currentTemplateFilters } = this.filterTemplateStore;
        const templatesWithoutRemovedItem = currentTemplateFilters.filter((template) => {
            if (template.id !== id) {
                return true;
            } else {
                this.removedTemplateTitle = template.name;
                return false;
            }
        });

        return {
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.DraftFilterSearch,
            value: { value: templatesWithoutRemovedItem },
        };
    };
}
