import { makeAutoObservable } from 'mobx';
import { TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';
import { AssistantFiltersStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantFilters.store';
import { makePersistable } from 'mobx-persist-store';

const defaultList: TypeAssistantCustomList = {
    refKey: '0',
    title: 'Основной портрет',
    private: false,
    parentId: 0,
    key1c: false,
};

export class AssistantListStore {
    filterStore: AssistantFiltersStore;
    assistantLists: TypeAssistantCustomList[];
    currentAssistantList: TypeAssistantCustomList = defaultList;

    constructor(filtersStore: AssistantFiltersStore) {
        this.filterStore = filtersStore;
        this.assistantLists = [defaultList];
        this.currentAssistantList = defaultList;
        makeAutoObservable(this, {}, { autoBind: true });
        makePersistable(this, {
            name: 'currentAssistantListLS',
            properties: ['currentAssistantList'],
            storage: window.localStorage,
        });
    }

    setAssistantLists(fullCustomList: TypeAssistantCustomList[]) {
        const list = fullCustomList.filter((item: TypeAssistantCustomList) => !item.key1c) ?? [];
        this.assistantLists = [defaultList, ...list];
    }

    setCurrentAssistantList(currentList: TypeAssistantCustomList) {
        this.currentAssistantList = currentList;
        this.filterStore.resetAllFilters();
    }

    setCurrentAssistantListDefault() {
        this.setCurrentAssistantList(defaultList);
    }

    resetCurrentAssistantList() {
        this.currentAssistantList = defaultList;
    }
}
