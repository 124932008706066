import React, { useContext, useEffect, useState } from 'react';
import { LicensesPage } from './LicensesPage';
import stls from '@f_styles/components/licenses/Licenses.module.sass';
import { useTranslation } from 'react-i18next';
import RestoreIcon from '@mui/icons-material/Restore';
import { ThemeContext } from '@f_context/Theme_context';
import { IconQWEP } from '@f_components/icons/IconQwep';
import AppsIcon from '@mui/icons-material/Apps';
import { GeneralContext } from '@f_context/General_context';
import { LayoutMenuPage } from '@f_general/layout/LayoutMenuPage';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LayoutPage } from '@f_general/layout/LayoutPage';

export function AlLicenses() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { drawerPanels } = useContext(GeneralContext);

    const [application, setApplication] = useState<any>([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (drawerPanels?.length) {
            setApplication(drawerPanels);
        }
    }, [drawerPanels]);

    return (
        <LayoutPage title={t('licenses.licensesAndPayment')}>
            {application.length && (
                <>
                    <ToggleButtonGroup
                        className={stls.toggleGroup}
                        value={value}
                        exclusive
                        onChange={(event, newValue) => setValue(newValue)}>
                        {application.map((el: any, idx: any) => (
                            <ToggleButton id={el.url} value={idx} key={idx} className={stls.toggle}>
                                {el.applicationName}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>

                    <LicensesPage application={application.filter((el: any, idx: any) => idx == value)[0]} />
                </>
            )}
        </LayoutPage>
    );
}
