import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { AccountsContext } from './Accounts_context';
import { QwepContext } from './Qwep_context';
import { useLocalStorage } from '@f_hooks';
import { ErrorsContext } from './Errors_context';
import { randomKey } from '@f_utils/randomKey';
import { Alert } from '@f_general/alert';
import useQwepNavigate from '../hooks/useQwepNavigate';
import { PriceContext } from './Price_context';

type TypeGetSearchId = {
    article: string;
    brand: string;
};

type TypeChangeCrosses = {
    rate: string;
    articleSearch: string;
    brandSearch: string;
    articleCross: string;
    brandCross: string;
};

const SearchContext = createContext<any>({});

function SearchProvider(props: any) {
    const { excludePromo, priceCrossesCheck } = useContext(QwepContext);
    const { priceSearch } = useContext(PriceContext);
    const { accounts } = useContext(AccountsContext);
    const { setAllErrors } = useContext(ErrorsContext);

    const navigate = useQwepNavigate();
    //presearch
    const [optionsPriSearch, setOptionsPreSearch] = useState<any>([]);
    const [inputSearch, setInputSearch] = useState('');
    const [preSearchLoading, setPreSearchLoading] = useState(false);

    //search
    const [errors, setErrors] = useState<any[]>([]);
    const [arrSearchResults, setArrSearchResults] = useState<any>([]);
    const [tabId, setTabId] = useState(0);
    const [preSearchHistory, setPreSearchHistory] = useLocalStorage('searchHistory', []);

    //Поисковый стейт и начальное значение
    const searchFiltersDefaultInitialState = {
        searchArticle: false,
        inStock: false,
        approvedCross: false,
        brands: [],
        vendorTitle: [],
        minPrice: 0,
        maxPrice: 0,
        minShipmentDays: 0,
        maxShipmentDays: 0,
        warehouses: [],
        isDealer: false,
        minQuantity: 0,
        maxQuantity: 0,
    };
    const [searchFiltersInitalState, setSearchFiltersInitalState] = useState(searchFiltersDefaultInitialState);

    const [open, setOpen] = useState(false);

    const [filter, setFilter] = useState<any>({});
    const [searchArticle, setSearchArticle] = useState('');
    const [searchBrand, setSearchBrand] = useState('');
    // price search loading
    const [loadingPriceSearch, setLoadingPriceSearch] = useState(false);

    // Текущий поисковый запрос
    const [currentSearchBrand, setCurrentSearchBrand] = useState<string>('');
    const [currentSearchArticle, setCurrentSearchArticle] = useState<string>('');

    useEffect(() => {
        if (priceCrossesCheck && tabId) {
            const currentSerach = arrSearchResults?.find(
                (item: any) => item?.tabId === tabId && item?.finished && !item?.searchPrice,
            );
            if (currentSerach) {
                priceItems(currentSerach.items.map((item: any) => ({ brand: item.brand, article: item.article })));
            }
        }
    }, [priceCrossesCheck, arrSearchResults]);

    const priceItems = async (items: { article: string; brand: string }[]) => {
        setLoadingPriceSearch(true);
        const priceItems = await priceSearch(items);

        const newArrSearchResults = arrSearchResults?.map((item: any) => {
            if (item?.tabId === tabId) {
                return {
                    ...item,
                    searchPrice: true,
                };
            }
            return item;
        });
        if (priceItems.result?.length) {
            // костыль
            setArrSearchResults(
                newArrSearchResults?.map((item: any) => {
                    if (item?.tabId === tabId) {
                        const existingItems = item.items.map((existingItem: any) => ({
                            article: existingItem.article,
                            brand: existingItem.brand,
                            price: existingItem.price.value,
                            quantity: existingItem.quantity.value,
                        }));

                        const uniqueItems = priceItems.result.filter((priceItem: any) => {
                            return !existingItems.some(
                                (existingItem: any) =>
                                    existingItem.article === priceItem.article &&
                                    existingItem.brand === priceItem.brand &&
                                    existingItem.price === priceItem.price.value &&
                                    existingItem.quantity === priceItem.quantity.value,
                            );
                        });

                        return {
                            ...item,
                            items: [...uniqueItems, ...item.items],
                        };
                    }
                    return item;
                }),
            );
        }
        setLoadingPriceSearch(false);
    };

    const getPreSearch = async (article: string) => {
        setPreSearchLoading(true);

        const result = await callPlatformAPI(`qwep/presearch/${article}`, 'get');

        if (result.data.isOk) {
            setOptionsPreSearch(result.data.result ?? []);
        }
        setPreSearchLoading(false);
        return result.data;
    };

    const changeCrosses = async (data: TypeChangeCrosses): Promise<{ changedIsSuccess: boolean }> => {
        const result = await callPlatformAPI('qwep/search/crosses', 'post', {
            rate: data.rate,
            itemArticle: data.articleSearch,
            itemBrand: data.brandSearch,
            crossArticle: data.articleCross,
            crossBrand: data.brandCross,
        });
        if (result?.data?.isOk) {
            Alert({
                type: 'success',
                text: 'Вы успешно проголосовали за кросс!\nВаш голос учтётся при следующем поиске.',
            });

            return { changedIsSuccess: true };
        } else {
            if (result?.data?.result?.errors) {
                setAllErrors([
                    {
                        date: new Date(),
                        error: result?.data?.result?.errors[0]?.message,
                    },
                ]);
            }

            return { changedIsSuccess: false };
        }
    };
    const getSearchId = async (data: TypeGetSearchId) => {
        const accountsSort: any = [];
        accounts.map((item: any) => !item.promo && accountsSort.push({ id: item.id }));
        if (!accountsSort.length && excludePromo) {
            return { status: 'not vendors' };
        }

        setSearchArticle(data.article);
        setSearchBrand(data.brand);
        let tabId: number = randomKey();
        setArrSearchResults((prev: any) => {
            setTabId(tabId);
            if (prev?.length >= 3) {
                return [
                    ...prev.slice(1),
                    {
                        searchId: '',
                        items: [],
                        tabId: tabId,
                        article: data.article,
                        brand: data.brand,
                        finished: false,
                    },
                ];
            } else {
                return [
                    ...prev,
                    {
                        searchId: '',
                        items: [],
                        tabId: tabId,
                        article: data.article,
                        brand: data.brand,
                        finished: false,
                    },
                ];
            }
        });
        setFilter({ [tabId]: searchFiltersInitalState, ...filter });
        navigate('search');

        const result = await callPlatformAPI('qwep/get/searchid', 'post', {
            article: data.article,
            brand: data.brand,
            accounts: accountsSort,
            type: 1,
            excludePromo: excludePromo,
        });

        if (result.data.isOk) {
        } else {
            if (result?.data?.result?.errors) {
                setAllErrors([
                    {
                        date: new Date(),
                        error: result?.data?.result?.errors[0]?.message,
                    },
                ]);
            }
            setArrSearchResults((prev: any) => {
                if (prev.length) {
                    return prev.filter((i: any) => {
                        if (i.tabId !== tabId) {
                            setTabId(i.tabId);
                            return i;
                        }
                    });
                } else {
                    setTabId(0);
                    tabId = 0;
                    navigate();
                    return [];
                }
            });
        }
        return { ...result.data, tabId };
    };

    const searchUpdates = async (
        tabId: number,
        searchId: string,
        article: string,
        brand: string,
        maxCount: number,
        count: number = 1,
    ) => {
        const result = await callPlatformAPI('qwep/search/updates', 'post', {
            searchId: searchId,
        });
        if (result.data.isOk) {
            if (result.data.result?.entity?.flatResults) {
                setItemsUpdate(result.data.result.entity.flatResults, searchId, tabId);
            }
            if (!result.data.result.entity?.finished) {
                if (count < maxCount) {
                    setTimeout(() => {
                        searchUpdates(tabId, searchId, article, brand, maxCount, (count += 1));
                    }, 3000);
                } else {
                    console.log('FINISH timeout', result.data.result.entity);
                    setItemsUpdate(null, searchId, tabId, true);
                    return result.data;
                }
            } else {
                console.log('FINISH', result.data.result.entity);
                setItemsUpdate(null, searchId, tabId, true);
                if (result.data.result?.entity?.flatResults?.errors?.length) {
                    setAllErrors(
                        result.data.result?.entity?.flatResults?.errors?.map((err: any) => {
                            return {
                                date: new Date(),
                                error: err.message + err.code,
                                accountId: err.accountId,
                            };
                        }),
                    );
                }
                return result.data;
            }
            if (result.data.result?.errors) {
                setAllErrors(
                    result.data.result?.errors?.map((err: any) => {
                        return {
                            date: new Date(),
                            error: err.message,
                        };
                    }),
                );
            }
            // if (result.data.result?.entity?.flatResults?.errors?.length) {
            //   setAllErrors({
            //     date: new Date(),
            //     error: result.data.result.entity.flatResults.errors[0].message + result.data.result.entity.flatResults.errors[0].code,
            //     accountId: result.data.result.entity.flatResults.errors[0].accountId
            //   })
            // }
        } else {
            setItemsUpdate(null, searchId, tabId, true);
            console.log('Error', result.data);
            return result.data;
        }
    };

    const setItemsUpdate = (data: any, searchId: string, tabId: number, finished: boolean = false) => {
        const product: any[] = [];
        const errors: any[] = [];

        if (data?.items?.length) {
            data.items.map((item: any) => {
                product.push({
                    ...item,
                    searchId: searchId,
                });
            });
        }

        if (data?.errors?.length) {
            data.errors.map((e: any) => {
                errors.push({ ...e, searchId: searchId });
            });
        }

        if (errors.length) {
            setErrors((prev) => [...prev, ...errors]);
        }

        setArrSearchResults((prev: any) => {
            const historyItemToShowCount = 21;
            const items = prev.map((i: any) => {
                if (i.tabId === tabId) {
                    setPreSearchHistory((prev: any) => [
                        { brand: i.brand, article: i.article, searchId: searchId, dateTime: new Date() },
                        ...prev.slice(0, historyItemToShowCount - 2),
                    ]);
                    return {
                        ...i,
                        searchId: searchId,
                        items: [...i?.items, ...product],
                        finished: finished,
                    };
                } else {
                    return i;
                }
            });

            return items;
        });
    };

    const getClarifications = async (article: string) => {
        const result = await callPlatformAPI('qwep/get/clarifications', 'post', {
            article: article,
            accounts: accounts.map((item: any) => ({ id: item.id })),
            type: 1,
            excludePromo: excludePromo,
        });

        return result.data;
    };

    const searchResults = async (tabId: number, searchId: string, article: string, brand: string) => {
        await searchUpdates(tabId, searchId, article, brand, 10);
    };

    const deleteSearch = (tabId: number) => {
        let arrRes: any[] = [];
        setArrSearchResults((prev: any) => {
            if (prev.length === 1) {
                navigate();
                setFilter({});
                return [];
            } else {
                delete filter[tabId];
                arrRes = prev.filter((item: any) => item.tabId !== tabId);
                return arrRes;
            }
        });
        return arrRes;
    };

    return (
        <SearchContext.Provider
            value={{
                errors,
                arrSearchResults,
                preSearchLoading,
                preSearchHistory,
                setPreSearchHistory,
                optionsPriSearch,
                filter,
                inputSearch,
                tabId,
                searchFiltersInitalState,
                open,
                searchArticle,
                searchBrand,
                loadingPriceSearch,
                searchFiltersDefaultInitialState,
                // Текущий поисковый запрос и методы установки значения
                currentSearchArticle,
                currentSearchBrand,
                setCurrentSearchArticle,
                setCurrentSearchBrand,
                // methods
                getClarifications,
                setOpen,
                setTabId,
                setInputSearch,
                setOptionsPreSearch,
                getPreSearch,
                getSearchId,
                searchUpdates,
                searchResults,
                deleteSearch,
                setSearchFiltersInitalState,
                setFilter,
                changeCrosses,
            }}
            {...props}
        />
    );
}

export { SearchProvider, SearchContext };
