import { observer } from 'mobx-react-lite';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { Button } from '@mui/material';
import { FC } from 'react';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';
import { IColorsProps } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingProps.interface';
import { PositionLoadingManual } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal/positions/PositionLoadingManual';
import { PositionLoadingFile } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal/positions/PositionLoadingFile';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const CustomListModalPositions: FC<IColorsProps> = observer(({ colors }) => {
    const store = useAssistantStore();

    const {
        currentTypeModal,
        currentPositionLoadingOption: currentOption,
        setCurrentPositionLoadingOptions: setCurrentOption,
    } = store.assistantModalList;

    const { MANUAL_INPUT, FILE_INPUT } = PositionLoadingOptionsEnum;

    return currentTypeModal === ConfigPanelModalType.CREATE || currentTypeModal === ConfigPanelModalType.ADD ? (
        <>
            <p className={styles.description}>Позиции</p>
            <div className={styles.choiceBlock}>
                <Button
                    variant={MANUAL_INPUT === currentOption ? 'contained' : 'outlined'}
                    onClick={() => setCurrentOption(MANUAL_INPUT)}
                    className={styles.btnChoiceData}>
                    Ввести позиции вручную
                </Button>
                <Button
                    variant={FILE_INPUT === currentOption ? 'contained' : 'outlined'}
                    onClick={() => setCurrentOption(FILE_INPUT)}
                    className={styles.btnChoiceData}>
                    Загрузить Список
                </Button>
            </div>
            <PositionLoadingManual colors={colors} />
            <PositionLoadingFile colors={colors} />
        </>
    ) : null;
});
