import { useContext, useEffect, useState } from 'react';
import { LayoutPage } from '../../general/layout/LayoutPage';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { BatchPricerTaskList } from './BatchPricerTaskList';
import { BatchPricerTask } from './BatchPricerTask';
import { LoadingSettings } from '../loaders/LoadingSettings';
import { BatchPricerSearchResult } from './BatchPricerSearchResult';
import { Alert } from '@f_general/alert';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';

type TypeTask = {
    info: string;
    lastUpdated: string;
    resultsId: string;
    taskId: string;
    taskName: string;
    taskSize: number;
};

export function BatchPricer() {
    const [navigations, setNavigations] = useState('taskList');
    const [loading, setLoading] = useState(false);
    const { getDashboard, dashboardList, isLoading, getTask } = useContext(BatchPricerContext);
    const { excludePromo } = useContext(QwepContext);
    const { accounts } = useContext(AccountsContext);
    const [taskInfo, setTaskInfo] = useState<any>({});
    const [shownItem, setShownItem] = useState({});
    const [taskResultId, setTaskResultId] = useState<string>('');

    useEffect(() => {
        getDashboard(true);
    }, []);

    const showTask = async (task: TypeTask) => {
        setLoading(true);
        setNavigations('task');
        const result = await getTask(task.taskId);
        setTaskResultId(task.resultsId);
        if (result.isOk) {
            setTaskInfo(task);
        } else {
            setTaskInfo({});
            setNavigations('taskList');
        }
        setLoading(false);
    };

    return (
        <LayoutPage>
            {isLoading ? (
                <LoadingSettings />
            ) : (
                <>
                    {navigations === 'taskList' ? (
                        <BatchPricerTaskList showTask={showTask} dashboardList={dashboardList} />
                    ) : navigations === 'task' ? (
                        loading ? (
                            <LoadingSettings />
                        ) : (
                            <BatchPricerTask
                                setNavigations={setNavigations}
                                taskInfo={taskInfo}
                                shownItem={shownItem}
                                setShownItem={setShownItem}
                            />
                        )
                    ) : (
                        navigations === 'resultItem' && (
                            <BatchPricerSearchResult setNavigations={setNavigations} taskResultId={taskResultId} />
                        )
                    )}
                </>
            )}
        </LayoutPage>
    );
}
