import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/settings/Accounts.module.sass';
import { Checkbox, CircularProgress, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { Alert } from '@f_general/alert';

export function CellVendor({ info }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { updateAccount } = useContext(AccountsContext);
    const [isLoading, setIsLoading] = useState(false);

    const checkedHandler = async (checked: boolean) => {
        setIsLoading(true);
        const result = await updateAccount([
            {
                id: info.row.original.id,
                title: info.row.original.title,
                enabled: checked,
            },
        ]);
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setIsLoading(false);
    };

    return (
        <div className={stls.cellVendor}>
            {isLoading ? (
                <CircularProgress style={{ color: colors.alpha }} size={'20px'} className={stls.loading} />
            ) : (
                <Checkbox
                    checked={info.row.original.enabled}
                    disabled={info.row.original.promo}
                    onChange={(e: any) => checkedHandler(e.target.checked)}
                />
            )}
            <Tooltip title={info.row.original.title}>
                <p className={stls.text}>{info.row.original.title}</p>
            </Tooltip>
        </div>
    );
}

export function HeadVendor() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const { updateAccount, fullAccounts } = useContext(AccountsContext);
    const [active, setActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setActive(
            fullAccounts.some((item: any) => {
                if (item.enabled && !item.promo) {
                    return true;
                }
            }),
        );
    }, [fullAccounts]);

    const checkedHandler = async (checked: boolean) => {
        const accuntsChecked: any = [];
        console.log(checked);

        fullAccounts.filter((item: any) => {
            if (!item.promo) {
                accuntsChecked.push({
                    id: item.id,
                    enabled: checked,
                });
            }
        });
        setIsLoading(true);
        const result = await updateAccount(accuntsChecked);
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setIsLoading(false);
    };

    return (
        <div className={stls.headVendor}>
            {isLoading ? (
                <CircularProgress style={{ color: colors.alpha }} size={'20px'} className={stls.loading} />
            ) : (
                <Checkbox checked={active} onChange={(e: any) => checkedHandler(e.target.checked)} />
            )}
            {t('qwep.settings.suppliers.supplier')}
        </div>
    );
}
