import { Checkbox } from '@mui/material';
import stls from '@f_qwep/styles/component/price/Price.module.sass';
import cn from 'classnames';
import { useContext } from 'react';
import { PriceContext } from '@f_qwep/context/Price_context';
import { ThemeContext } from '@f_context/Theme_context';

export function CellVendor({ info }: any) {
    const { progressList } = useContext(PriceContext);
    const { colors } = useContext(ThemeContext);

    return (
        <div style={{ display: 'flex' }}>
            {/* <Checkbox /> */}
            <p style={{ color: colors.alpha }} className={cn(stls.cellVendor)}>
                {info.row.original.vendorName}
            </p>
            <span
                style={{
                    background: colors.gamma,
                    width: progressList[info.row.original.adminCode]
                        ? `${progressList[info.row.original.adminCode]}%`
                        : `0%`,
                }}
                className={stls.progress}
            />
        </div>
    );
}
