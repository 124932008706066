import stls from '@f_qwep/styles/component/batchPricer/InputGetShortNumbers.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useState } from 'react';
import { Autocomplete, ListItemText, TextField } from '@mui/material';
import { QwepContext } from '@f_qwep/context/Qwep_context';

export function InputGetShortNumbers({ actionHandler, styles, label, defaultValue, inputVal, itemId }: any) {
    const { colors } = useContext(ThemeContext);
    const [inputValue, setInputValue] = useState(inputVal ?? '');
    const [options, setOptions] = useState([]);
    const { getShortNumbers } = useContext(QwepContext);

    const clickHandler = (option: any) => {
        actionHandler({ article: inputValue, ...option }, itemId);
    };

    useEffect(() => {
        if (inputValue.length >= 3) {
            getOptions();
        }
    }, [inputValue]);

    const getOptions = async () => {
        const res = await getShortNumbers(inputValue);
        if (res.result.length) {
            setOptions(res.result);
        }
    };

    const getHighlightedText = (skuInfo: any) => {
        const renderArticle = () => {
            if (!inputValue) return null;
            return <span style={{ color: colors.omicron }}>{`${inputValue}\u00A0`}</span>;
        };
        const renderBrand = (skuInfo: any) => {
            if (!skuInfo?.brand) return null;
            return <span style={{ color: colors.alpha }}>{`– ${skuInfo?.brand} –\u00A0`}</span>;
        };
        const renderPartname = (skuInfo: any) => {
            if (!skuInfo?.partname) return null;
            return <span style={{ color: colors.omicron }}>{`${skuInfo?.partname}`}</span>;
        };
        return (
            <span className={stls.item}>
                {renderArticle()}
                {renderBrand(skuInfo)}
                {renderPartname(skuInfo)}
            </span>
        );
    };

    return (
        <Autocomplete
            sx={{
                ...styles,
            }}
            options={options}
            defaultValue={defaultValue}
            autoHighlight
            freeSolo
            filterSelectedOptions={false}
            filterOptions={(x) => x}
            getOptionLabel={(option: any) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option?.inputValue) {
                    return option?.inputValue;
                }
                return `${inputValue ?? ''} ${option?.brand}`;
            }}
            onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                    setInputValue(value);
                    if (value.length < 3 || !value) {
                    }
                } else if (reason === 'clear') {
                    setInputValue('');
                    setOptions([]);
                }
            }}
            renderOption={(props: any, option: any) => (
                <span onClick={() => clickHandler(option)}>
                    <li {...props} key={option?.brand}>
                        <ListItemText secondary={getHighlightedText(option)} />
                    </li>
                </span>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    focused
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
}
