import { useContext, useRef, useState } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grow, List, Popper } from '@mui/material';
import { IconFilter } from '../icons/IconFilter';
import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/basket/Basket.module.sass';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { BasketPanel } from './BasketPanel';

export function MobileButtonBasket({ items }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { filterBasket } = useContext(FilterContext);

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };

    const getSumFilters = () => {
        let count = 0;
        for (let key in filterBasket) {
            if (Array.isArray(filterBasket[key])) {
                count += filterBasket[key].length;
            }
        }
        return count;
    };

    return (
        <ButtonGroup variant="outlined" ref={anchorRef}>
            <Button
                sx={{ fontSize: '14px' }}
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleToggle}>
                {t('qwep.orders.filters')}
                <IconFilter />
                {getSumFilters() !== 0 && (
                    <sub style={{ color: colors.gamma, marginTop: '-15px' }}>{getSumFilters()}</sub>
                )}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition className={stls.popperList}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List sx={{ background: colors.psi }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <BasketPanel items={items} />
                            </ClickAwayListener>
                        </List>
                    </Grow>
                )}
            </Popper>
        </ButtonGroup>
    );
}
