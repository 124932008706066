import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useState } from 'react';
import { Autocomplete, ListItemText, TextField } from '@mui/material';
import stls from '@f_styles/general/CustomAutocomplete.module.sass';

type TypeActionHandler = {
    inputValue?: string;
    option: any;
    itemId?: any;
};

type TypeCustomAutocomplete = {
    getShortNumbers: (inputValue: string) => { result: any };
    actionHandler: (props: TypeActionHandler) => void;
    styles?: object;
    label?: string;
    defaultValue?: string;
    inputVal?: string;
    itemId?: any;
};

export function CustomAutocomplete({
    getShortNumbers,
    actionHandler,
    styles,
    label,
    defaultValue,
    inputVal,
    itemId,
}: TypeCustomAutocomplete) {
    const { colors } = useContext(ThemeContext);
    const [inputValue, setInputValue] = useState(inputVal ?? '');
    const [options, setOptions] = useState([]);

    const clickHandler = (option: any) => {
        actionHandler({
            inputValue: inputValue,
            option: option,
            itemId: itemId,
        });
    };

    useEffect(() => {
        if (inputValue.length >= 3) {
            getOptions();
        }
    }, [inputValue]);

    const getOptions = async () => {
        const res = await getShortNumbers(inputValue);
        if (res.result) {
            console.log('GFGHFDH', res.result);

            setOptions(res.result);
        }
    };

    const getHighlightedText = (skuInfo: any) => {
        return (
            <>
                <div className={stls.text} style={{ color: colors.omicron }}>{`${
                    skuInfo?.article ? skuInfo?.article : inputValue
                }\u00A0`}</div>
                {skuInfo?.brand && (
                    <div className={stls.text} style={{ color: colors.alpha }}>{`– ${skuInfo?.brand}\u00A0`}</div>
                )}
                {skuInfo?.partname && (
                    <div
                        className={stls.textList}
                        style={{ color: colors.omicron }}>{`– ${skuInfo?.partname}\u00A0`}</div>
                )}
            </>
        );
    };

    return (
        <Autocomplete
            sx={{
                ...styles,
            }}
            options={options}
            defaultValue={defaultValue}
            autoHighlight
            freeSolo
            filterSelectedOptions={false}
            filterOptions={(x) => x}
            getOptionLabel={(option: any) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option?.inputValue) {
                    return option?.inputValue;
                }
                return `${option?.article ? option?.article : inputValue} ${option?.brand}`;
            }}
            onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                    setInputValue(value);
                    if (value.length < 3 || !value) {
                    }
                } else if (reason === 'clear') {
                    setInputValue('');
                    setOptions([]);
                }
            }}
            renderOption={(props: any, option: any) => (
                <div onClick={() => clickHandler(option)}>
                    <li {...props} key={option?.brand} className={stls.itemList}>
                        {getHighlightedText(option)}
                    </li>
                </div>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    focused
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
}
