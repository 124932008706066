import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/home/TaskList.module.sass';
import cn from 'classnames';
import { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { ReportListContext } from '@f_crossdata/context/ReportList_context';
import { Button, CircularProgress, IconButton } from '@mui/material';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { TaskCrossTable } from './TaskCrossTable';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { Alert } from '@f_general/alert';
import { CastomCircularProgress } from '@f_utils/CastomCircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { IconStonks } from '../../../icons/IconStonks';
import { IconStatusOk } from '../../../icons/IconStatusOk';

export function TaskList() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { taskList, deleteTask } = useContext(ReportListContext);
    const { exportExcel, appInfo } = useContext(CrossdataContext);
    const [showCross, setShowCross] = useState({ value: false, refKey: '' });
    const [loadingReportDownload, setLoadingReportDownload] = useState({ refKey: '' });
    const [loadingCrossDownload, setLoadingCrossDownload] = useState(false);

    const showCrossHandler = (refKey: string) => {
        if (refKey === showCross.refKey) {
            setShowCross({ value: !showCross.value, refKey });
        } else {
            setShowCross({ value: true, refKey });
        }
    };

    const reportDowloadHandler = async (refKey: string, title: string) => {
        setLoadingReportDownload({ refKey });
        const result = await exportExcel({
            type: 'reportList',
            data: refKey,
            title: title,
        });
        if (!result) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoadingReportDownload({ refKey: '' });
    };

    const crossDowloadHandler = async (refKey: string, title: string) => {
        setLoadingCrossDownload(true);
        const result = await exportExcel({
            type: 'crossList',
            data: refKey,
            title: title,
        });
        if (!result) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoadingCrossDownload(false);
    };

    const deleteTaskHandler = async (refKey: string) => {
        const result = await deleteTask(refKey);
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    return (
        <>
            <div className={stls.container}>
                {taskList?.map((item: any) => {
                    return (
                        <div className={stls.card} style={{ background: colors.omega }}>
                            <IconButton className={stls.deleteBtn} onClick={() => deleteTaskHandler(item.refKey)}>
                                <CloseIcon sx={{ '&:hover': { color: colors.lambda } }} />
                            </IconButton>
                            <div className={stls.info}>
                                <div className={stls.titleGroup}>
                                    <IconStatusOk />
                                    <span className={stls.title}>
                                        {item.title}{' '}
                                        {!item.finishReport && <CastomCircularProgress progress={item.progress} />}
                                    </span>
                                </div>
                                <div style={{ border: `0.5px solid ${colors.epsilon}` }} className={stls.config}>
                                    <div className={stls.group}>
                                        <p className={stls.text}>
                                            <span>{t('crossdata.listItem.periodStart')}</span>: {item.periodStart}
                                        </p>
                                        <p className={stls.text}>
                                            <span>{t('crossdata.listItem.periodEnd')}</span>: {item.periodEnd}
                                        </p>
                                    </div>
                                    <div style={{ borderLeft: `0.5px solid ${colors.epsilon}` }} className={stls.group}>
                                        <p className={stls.text}>
                                            <span>{t('crossdata.listItem.status')}</span>: {item.status}
                                        </p>
                                        <p className={stls.text}>
                                            <span>{t('crossdata.listItem.currency')}</span>: {item.currency}
                                        </p>
                                    </div>
                                    <div style={{ borderLeft: `0.5px solid ${colors.epsilon}` }} className={stls.group}>
                                        <p className={stls.text}>
                                            <span>{t('crossdata.listItem.minAvgReq')}</span>:{' '}
                                            {item.minAvgReq ? t('general.yes') : t('general.no')}
                                        </p>
                                        <p className={stls.text}>
                                            <span>{t('crossdata.listItem.minAvgBuy')}</span>:{' '}
                                            {item.minAvgBuy ? t('general.yes') : t('general.no')}
                                        </p>
                                    </div>
                                    <div
                                        style={{ borderLeft: `0.5px solid ${colors.epsilon}` }}
                                        className={stls.greenGroup}>
                                        <div className={stls.block}>
                                            {item.cross ? (
                                                <>
                                                    <p
                                                        className={stls.text}
                                                        style={{
                                                            color: item.finishCross ? colors.lightgreen : colors.tau,
                                                        }}>
                                                        {item.finishCross ? (
                                                            <LibraryAddCheckIcon
                                                                style={{
                                                                    color: colors.lightgreen,
                                                                    marginRight: '10px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <AddToPhotosIcon
                                                                style={{
                                                                    color: colors.tau,
                                                                    marginRight: '10px',
                                                                }}
                                                            />
                                                        )}
                                                        {t('crossdata.listPanel.analogs')}
                                                    </p>
                                                    <p style={{ marginLeft: '10px', fontWeight: 800 }}>
                                                        {item.itemsCross.length}
                                                    </p>
                                                    {!item.finishCross && (
                                                        <p className={stls.loading} style={{ color: colors.alpha }}>
                                                            <CircularProgress className={stls.icon} size={20} />
                                                            {t('crossdata.listPanel.searchAnalogs')}
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <p className={stls.text}>
                                                    <AddToPhotosIcon
                                                        style={{ color: colors.tau, marginRight: '10px' }}
                                                    />
                                                    {t('crossdata.listPanel.nonAnalog')}
                                                </p>
                                            )}
                                        </div>
                                        <div className={stls.block}>
                                            <p
                                                className={stls.text}
                                                style={{ color: item.finishReport ? colors.lightgreen : colors.tau }}>
                                                <IconStonks
                                                    style={{
                                                        color: item.finishReport ? colors.lightgreen : colors.tau,
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                {t('crossdata.listPanel.doReport')}
                                            </p>
                                            <p style={{ marginLeft: '10px', fontWeight: 800 }}>{item.reportSize}</p>
                                            {!item.finishReport && (
                                                <p className={stls.loading} style={{ color: colors.alpha }}>
                                                    <CircularProgress className={stls.icon} size={20} />
                                                    {t('crossdata.listPanel.doAnalisys')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={stls.actions}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        style={{ background: colors.crossdata, color: colors.alpha }}
                                        disabled={!item.itemsCross?.length}
                                        className={cn(stls.btn, stls.group)}
                                        onClick={() => showCrossHandler(item.refKey)}
                                        size="small"
                                        variant="contained">
                                        {showCross.refKey === item.refKey && showCross.value
                                            ? 'Скрыть детализацию'
                                            : 'Детализация по аналогам'}
                                    </Button>
                                    {appInfo?.tariff?.workWithFiles && (
                                        <>
                                            <Button
                                                style={{
                                                    background: colors.crossdata,
                                                    color: colors.alpha,
                                                    borderColor: colors.alpha,
                                                }}
                                                disabled={!item.itemsCross?.length && !loadingCrossDownload}
                                                className={cn(stls.btn, stls.group)}
                                                onClick={() => crossDowloadHandler(item.refKey, item.title)}
                                                size="small"
                                                variant="contained">
                                                {t('crossdata.listPanel.saveAnalogs')}
                                            </Button>
                                            <Button
                                                style={{ background: colors.crossdata, color: colors.alpha }}
                                                disabled={item.refKey === loadingReportDownload.refKey}
                                                className={cn(stls.btn, stls.group)}
                                                onClick={() => reportDowloadHandler(item.refKey, item.title)}
                                                size="small"
                                                variant="contained">
                                                {t('crossdata.listPanel.saveReport')}
                                                {item.refKey === loadingReportDownload.refKey && (
                                                    <CircularProgress
                                                        className={stls.loading}
                                                        size={20}
                                                        sx={{ color: colors.omicron }}
                                                    />
                                                )}
                                            </Button>
                                        </>
                                    )}
                                    <div className={stls.greenGroup} />
                                </div>
                            </div>
                            <div className={stls.tableContainer}>
                                {showCross.value && showCross.refKey === item.refKey && (
                                    <div className={stls.table}>
                                        <TaskCrossTable data={item.itemsCross} />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
