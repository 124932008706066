import toast from 'react-hot-toast';
import { IconAlertSuccess } from '@f_components/icons/alert/IconSuccess';
import { IconAlertError } from '@f_components/icons/alert/IconError';
import { IconAlertInfo } from '@f_components/icons/alert/IconInfo';
import { IconAlertWarning } from '@f_components/icons/alert/IconWarning';
import { IconClose } from '@f_components/icons/IconClose';
import stls from '@f_styles/components/alert/Alert.module.sass';

const shownAlerts = new Set();

type TypeAlertProps = {
    type: 'success' | 'error' | 'info' | 'warning' | 'custom';
    text: any;
    time?: number;
    position?: any;
    singleShow?: boolean;
};

export const Alert = ({ type, text, time = 6000, position = 'bottom-left', singleShow = false }: TypeAlertProps) => {
    const alertKey = `${type}-${text}`;

    if (singleShow && shownAlerts.has(alertKey)) {
        return;
    }

    const defStyle = {
        minWidth: '200px',
        maxWidth: '500px',
        color: '#1A242A',
        fontSize: '13px',
    };

    const commonProps = {
        duration: time,
        position,
        ariaProps: {
            role: 'status',
            'aria-live': 'polite',
        },
        style: { ...defStyle },
    };

    switch (type) {
        case 'success':
            toast.success(text, {
                ...commonProps,
                icon: <IconAlertSuccess classNames={[stls.alertIcon]} />,
                style: { ...commonProps.style, background: '#E5F8EB' },
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            break;
        case 'error':
            toast.error(text, {
                ...commonProps,
                icon: <IconAlertError classNames={[stls.alertIcon]} />,
                style: { ...commonProps.style, background: '#ffdcdc' },
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            break;
        case 'info':
            toast.success(text, {
                ...commonProps,
                icon: <IconAlertInfo classNames={[stls.alertIcon]} />,
                style: { ...commonProps.style, background: '#E5F1FF' },
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            break;
        case 'warning':
            toast.success(text, {
                ...commonProps,
                icon: <IconAlertWarning classNames={[stls.alertIcon]} />,
                style: { ...commonProps.style, background: '#FFF6DD' },
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            break;
        case 'custom':
            toast(
                (t) => (
                    <div className={stls.customAlert}>
                        <button className={stls.buttonAlert} onClick={() => toast.dismiss(t.id)}>
                            <IconClose />
                        </button>
                        <span style={{ textAlign: 'center' }}>{text}</span>
                    </div>
                ),
                { duration: 30000 },
            );
            break;
        default:
            throw new Error('Unknown alert type');
    }

    if (singleShow) {
        shownAlerts.add(alertKey);
    }
};
