import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import stls from '@f_admin/styles/components/navbar/Navbar.module.sass';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HouseIcon from '@mui/icons-material/House';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import useAdminNavigate from '@f_admin/hooks/useAdminNavigate';

type typeMenu = {
    id: number;
    url: string;
    lable: string;
    icon: any;
    active: boolean;
}[];

export function Navbar() {
    const { t } = useTranslation();
    const navigate = useAdminNavigate();
    const { colors } = useContext(ThemeContext);

    const menu: typeMenu = [
        {
            id: 1,
            url: '',
            lable: 'Главная',
            icon: <HouseIcon className={stls.icon} />,
            active: false,
        },
        {
            id: 2,
            url: 'user-info',
            lable: 'Пользователь',
            icon: <AccountCircleIcon className={stls.icon} />,
            active: false,
        },
        {
            id: 3,
            url: '',
            lable: 'Статистика',
            icon: <AssessmentIcon className={stls.icon} />,
            active: false,
        },
    ];

    const clickHandler = (id: number, url: string) => {
        navigate(url);
    };

    return (
        <div className={stls.container} style={{ background: colors.iota }}>
            {menu.map((item) => {
                return (
                    <div>
                        <button
                            onClick={() => clickHandler(item.id, item.url)}
                            style={item.id === 1 ? { borderBottom: `2px solid ${colors.alpha}` } : {}}
                            className={stls.btn}
                            key={item.id}>
                            {item.icon}
                            <div className={stls.lable} style={{ color: colors.alpha }}>
                                {item.lable}
                            </div>
                        </button>
                    </div>
                );
            })}
        </div>
    );
}
