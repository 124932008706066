import { MyModal } from '@f_general/MyModal';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, Box, Button, CardContent } from '@mui/material';
import React, { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/searchResult/AddBasketModal.module.sass';
import { isEmpty } from 'lodash';
import { IconImage } from '../icons/IconImage';
import { OrdersContext } from '../../context/Orders_context';
import useWindowSize from '@f_hooks/useWindowSize';
import { IconClose } from '@f_components/icons/IconClose';

export default function OrderCardResult() {
    const { t } = useTranslation();

    const { openCardResult, setOpenCardResult, cardItem } = useContext(OrdersContext);
    const { colors } = React.useContext(ThemeContext);

    const { width, height } = useWindowSize();
    const [mobile, setMobile] = React.useState(false);

    React.useEffect(() => {
        setMobile(width < 750);
    }, [width]);

    const handleClose = () => setOpenCardResult(false);

    const generateFields = (additionalFieldsInput = []) => {
        const fields = {
            numberOrder: t('qwep.search.ordersNumber'),
            datetimeOrder: t('qwep.search.date'),
            brand: t('qwep.search.brand'),
            article: t('qwep.search.article'),
            partname: t('qwep.search.partname'),
            status: t('qwep.search.status'),
            statusOrder: t('qwep.search.ordersStatus'),
            vendorTitle: t('qwep.search.vendor'),
            branchTitle: t('qwep.search.branch'),
            warehouse: t('qwep.search.warehouse'),
            warehouseOrder: t('qwep.search.ordersWarehouse'),
            comment: t('qwep.search.comment'),
            commentOrder: t('qwep.search.ordersComment'),
        };
        const additionalFields = [
            { fieldName: t('qwep.search.quantity'), fieldValue: cardItem?.quantity?.formatted },
            { fieldName: t('qwep.search.price'), fieldValue: cardItem?.price?.formatted },
            ...additionalFieldsInput,
        ];
        const renderMainFields = () =>
            Object.keys(fields).map((key: string) => {
                const fieldValue = cardItem[key as keyof typeof fields];
                const fieldName = fields[key as keyof typeof fields];
                if (isEmpty(fieldValue)) return null;
                return (
                    <Grid container key={fieldName} className={stls.cardItem}>
                        <Grid item xs={6} sm={6}>
                            <p>{`${fieldName}:`}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <p style={{ fontWeight: 'bold' }}>{`${fieldValue}`}</p>
                        </Grid>
                    </Grid>
                );
            });
        const renderAdditionalFields = () =>
            additionalFields.map(({ fieldName, fieldValue }) => {
                if (isEmpty(fieldValue)) return null;
                return (
                    <Grid container key={fieldName} className={stls.cardItem}>
                        <Grid item xs={6} sm={6}>
                            <p>{`${fieldName}:`}</p>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <p style={{ fontWeight: 'bold' }}>{`${fieldValue}`}</p>
                        </Grid>
                    </Grid>
                );
            });
        return (
            <>
                {renderMainFields()}
                {renderAdditionalFields()}
            </>
        );
    };

    return (
        <Modal open={openCardResult} onClose={handleClose}>
            <MyModal className={stls.modalCardResult}>
                <div onClick={handleClose} className={stls.modalClose}>
                    <IconClose />
                </div>
                <CardContent>
                    <Grid container direction={mobile ? 'column' : 'row'} spacing={2}>
                        {!(mobile && !cardItem.photo) && (
                            <Grid item xs={5} container direction="row" justifyContent="center" alignItems="center">
                                <Box className={stls.image} sx={{ background: colors.delta }}>
                                    {cardItem?.photo ? (
                                        <img src={cardItem.photo} alt={cardItem?.partname} />
                                    ) : (
                                        <IconImage />
                                    )}
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Typography variant={cardItem?.partname?.length > 50 ? 'h6' : 'h5'}>
                                {cardItem?.partname}
                            </Typography>
                            <Grid className={stls.cardData}>{generateFields()}</Grid>
                            <Grid container>
                                <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <Button
                                        className={stls.modalButton}
                                        onClick={handleClose}
                                        size="large"
                                        sx={{
                                            background: colors.delta,
                                            color: colors.phi,
                                            '&:hover': { color: colors.alpha },
                                        }}>
                                        {t('qwep.search.close')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </MyModal>
        </Modal>
    );
}
