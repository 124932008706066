import Chip from '@mui/material/Chip';
import React, { useContext } from 'react';
import stls from '@f_qwep/styles/component/searchResult/SearchResult.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { IconInStock } from '../icons/IconInStock';
import { IconTime } from '../icons/IconTime';
import { IconWarehouse } from '../icons/IconWarehouse';
import { IconPriceChip } from '../icons/IconPriceChip';

export function FilterReset({ filtersReducer, filter }: any) {
    const { colors } = useContext(ThemeContext);
    const { miniTableRes } = useContext(QwepContext);

    const filterNow = filter;

    if (!filterNow) {
        return <></>;
    }

    const activeFilter =
        filterNow?.warehouses?.length ||
        filterNow?.brands?.length ||
        filterNow?.inStock ||
        filterNow?.maxPrice > 0 ||
        filterNow.minPrice > 0 ||
        filterNow?.maxShipmentDays > 0 ||
        filterNow.minShipmentDays > 0 ||
        filterNow?.searchArticle ||
        filterNow?.vendorTitle?.length ||
        filterNow.isDealer ||
        filterNow.approvedCross;

    return (
        <div className={stls.filterResetWrapper}>
            {activeFilter > 0 && (
                <div className={stls.chipReset}>
                    <Chip
                        onClick={() => filtersReducer({ type: 'reset_filters' })}
                        className={stls.chip}
                        style={{ background: colors.deltaBg, color: colors.omicron }}
                        size="small"
                        label="Очистить все"
                        onDelete={() => {
                            filtersReducer({ type: 'reset_filters' });
                        }}
                    />
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    zIndex: 1,
                    height: '35px',
                    width: '100%',
                    overflow: 'hidden',
                }}>
                <div className={stls.filterReset} style={{ width: miniTableRes ? '500px' : '100%', display: 'flex' }}>
                    {filter?.brands?.map((data: any, i: number) => {
                        return (
                            <div className={stls.chipList} key={i}>
                                <Chip
                                    style={{ background: colors.alphaOpacity }}
                                    className={stls.chip}
                                    size="small"
                                    label={data}
                                    onDelete={() => {
                                        filtersReducer({ type: 'brands_toggle', payload: { brand: data } });
                                    }}
                                />
                            </div>
                        );
                    })}
                    {filter?.inStock && (
                        <div className={stls.chipList}>
                            <Chip
                                icon={<IconInStock />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label="В наличии"
                                onDelete={() => {
                                    filtersReducer({ type: 'in_stock_toggle' });
                                }}
                            />
                        </div>
                    )}
                    {filter?.maxPrice > 0 && (
                        <div className={stls.chipList}>
                            <Chip
                                icon={<IconPriceChip />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label={` < ${filter?.maxPrice} ₽`}
                                onDelete={() => {
                                    filtersReducer({ type: 'maxPrice_input', payload: { max: '' } });
                                }}
                            />
                        </div>
                    )}
                    {filter?.minPrice > 0 && (
                        <div className={stls.chipList}>
                            <Chip
                                icon={<IconPriceChip />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label={` > ${filter?.minPrice} ₽`}
                                onDelete={() => {
                                    filtersReducer({ type: 'minPrice_input', payload: { min: '' } });
                                }}
                            />
                        </div>
                    )}
                    {filter?.minShipmentDays > 0 && (
                        <div className={stls.chipList}>
                            <Chip
                                icon={<IconTime />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label={` > ${filter?.minShipmentDays}`}
                                onDelete={() => {
                                    filtersReducer({ type: 'minShipmentDays_input', payload: { minShipmentDays: '' } });
                                }}
                            />
                        </div>
                    )}
                    {filter?.maxShipmentDays > 0 && (
                        <div className={stls.chipList}>
                            <Chip
                                icon={<IconTime />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label={` < ${filter?.maxShipmentDays}`}
                                onDelete={() => {
                                    filtersReducer({ type: 'maxShipmentDays_input', payload: { maxShipmentDays: '' } });
                                }}
                            />
                        </div>
                    )}
                    {filter?.maxQuantity > 0 && (
                        <div className={stls.chipList}>
                            <Chip
                                // icon={<IconTime />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label={` < ${filter?.maxQuantity}`}
                                onDelete={() => {
                                    filtersReducer({ type: 'maxQuantity_input', payload: { maxQuantity: '' } });
                                }}
                            />
                        </div>
                    )}
                    {filter?.minQuantity > 0 && (
                        <div className={stls.chipList}>
                            <Chip
                                // icon={<IconTime />}
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label={` < ${filter?.minQuantity}`}
                                onDelete={() => {
                                    filtersReducer({ type: 'minQuantity_input', payload: { minQuantity: '' } });
                                }}
                            />
                        </div>
                    )}
                    {filter?.searchArticle && (
                        <div className={stls.chipList}>
                            <Chip
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label="Артикул поиска"
                                // icon={ <CheckIcon size='small'/> }
                                onDelete={() => {
                                    filtersReducer({ type: 'search_article_toggle' });
                                }}
                            />
                        </div>
                    )}
                    {filter?.approvedCross && (
                        <div className={stls.chipList}>
                            <Chip
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label="Подтвержденные кроссы"
                                // icon={ <CheckIcon size='small'/> }
                                onDelete={() => {
                                    filtersReducer({ type: 'approved_cross_vendor_toggle' });
                                }}
                            />
                        </div>
                    )}
                    {filter?.isDealer && (
                        <div className={stls.chipList}>
                            <Chip
                                style={{ background: colors.alphaOpacity }}
                                className={stls.chip}
                                size="small"
                                label="Дилер"
                                onDelete={() => {
                                    filtersReducer({ type: 'isDealer_toggle' });
                                }}
                            />
                        </div>
                    )}
                    {filter?.vendorTitle?.map((data: any, i: number) => {
                        return (
                            <div className={stls.chipList} key={i}>
                                <Chip
                                    style={{ background: colors.alphaOpacity }}
                                    className={stls.chip}
                                    size="small"
                                    label={data}
                                    onDelete={() => {
                                        filtersReducer({ type: 'vendors_toggle', payload: { vendor: data } });
                                    }}
                                />
                            </div>
                        );
                    })}
                    {filter?.warehouses?.map((data: any, i: number) => {
                        return (
                            <div className={stls.chipList} key={i}>
                                <Chip
                                    icon={<IconWarehouse />}
                                    style={{ background: colors.alphaOpacity }}
                                    className={stls.chip}
                                    size="small"
                                    label={data}
                                    onDelete={() => {
                                        filtersReducer({ type: 'warehouses_toggle', payload: { warehouse: data } });
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
