import React, { useContext, useEffect, useState } from 'react';
import { QwepContext } from './Qwep_context';
import { Property } from '../types';

const ManualContext = React.createContext<any>({});

function ManualProvider(props: any) {
    const { activeApp, workPeriod, manualState, updateProperty, receivedResponse, workspaceApplication } =
        useContext(QwepContext);

    const locationApp = window.location.pathname;
    const locationName = locationApp.split('/')[2];

    const [library, setLibrary] = useState<any>([
        { id: 1, name: 'Главная страница', content: ['Приветствие'] },
        {
            id: 2,
            name: 'Настройка поставщиков и Поиск',
            content: [
                'Авторизация поставщика для поиска',
                'Как выполнить поиск',
                'Работа с таблицей результата поиска',
                'Фильтры для работы с таблицей результатов',
                'Бренды подсказки при поиске',
                'История поиска',
                'Стили таблицы результатов',
                'Шаблоны при поиске',
            ],
        },
        {
            id: 3,
            name: 'Проценка списком',
            content: [
                'Для чего функция "Проценка списком"',
                'Загрузка проценки списком',
                'Работа с результатами проценки',
            ],
        },
        { id: 4, name: 'Корзина', content: ['Шаблоны в корзине'] },
        { id: 5, name: 'Заказы', content: ['Статусы заказов', 'Сменить период заказов'] },
        {
            id: 6,
            name: 'Прайсы',
            content: [
                'Для чего функция "Прайсы"',
                'Загрузка прайса',
                'Работа с прайсом',
                'Удаление прайса',
                'Доп параметры прайсов',
            ],
        },
        {
            id: 7,
            name: 'Группы поставщиков',
            content: [
                // 'Для чего функция Группа поставщиков',
                'Создать себе группу',
                'Редактирование группы поставщиков',
                'Удаление группы поставщиков',
            ],
        },
        { id: 8, name: 'Лицензия', content: ['Какой тариф у меня', 'Как поменять тариф', 'Разницы в функциях'] },
        { id: 9, name: 'Переезд с 1с', content: ['Переезд с 1с'] },
        { id: 10, name: 'Чат поддержки', content: ['Чат поддержки'] },
        // {id: 10, name: 'Кроссы', content:
        //   ['Кроссы QWEP']
        // },
    ]);

    const [manualDefaultValue, setManualDefaultValue] = useState<any>({
        main: { flag: false },
        search: { flag: false },
        'batch-pricer': { flag: false },
        basket: { flag: false },
        orders: { flag: false },
        prices: { flag: false },
        settings: { flag: false },
        basket_template: { flag: false },
        // crosses: {flag: false},
        // leave_1C: {flag: false},
        manual_library: { flag: false },
        prices_options: { flag: false },
        search_template: { flag: false },
        support_chat: { flag: false },
        // groupsAccounts: {flag: false}
    });

    const [newManualValue, setNewManualValue] = useState<any>(manualState);

    const [chooseManual, setChooseManual] = useState<any>();

    useEffect(() => {
        if (receivedResponse) {
            if (manualState) {
                setNewManualValue(manualState);
            } else {
                setNewManualValue(manualDefaultValue);
            }
        }
    }, [manualState, receivedResponse]);

    useEffect(() => {
        if (newManualValue) {
            changeManual();
        }
    }, [locationApp, newManualValue]);

    const changeManual = () => {
        if (!newManualValue?.main?.flag) {
            setChooseManual('Приветствие');
            setNewManualValue({ ...newManualValue, main: { flag: true } });
            const result = updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.QwepManual,
                value: { value: { ...newManualValue, main: { flag: true } } },
            });
        } else if (!newManualValue?.manual_library?.flag) {
            setChooseManual('Библиотека');
            setNewManualValue({ ...newManualValue, manual_library: { flag: true } });
            const result = updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.QwepManual,
                value: { value: { ...newManualValue, manual_library: { flag: true } } },
            });
        } else if (!newManualValue?.support_chat?.flag) {
            setChooseManual('Чат поддержки');
            setNewManualValue({ ...newManualValue, support_chat: { flag: true } });
            const result = updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.QwepManual,
                value: { value: { ...newManualValue, support_chat: { flag: true } } },
            });
        }
        if (manualDefaultValue[locationName] && !newManualValue[locationName]?.flag) {
            switch (locationName) {
                case 'batch-pricer':
                    setChooseManual('Для чего функция "Проценка списком"');
                    break;
                case 'search':
                    setChooseManual('Работа с таблицей результата поиска из поиска');
                    break;
                case 'basket':
                    setChooseManual('');
                    break;
                case 'orders':
                    setChooseManual('');
                    break;
                case 'prices':
                    setChooseManual('Для чего функция "Прайсы"');
                    break;
                case 'settings':
                    setChooseManual('Настройки');
                    break;
            }
            setNewManualValue({ ...newManualValue, [locationName]: { flag: true } });
            const result = updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.QwepManual,
                value: { value: { ...newManualValue, [locationName]: { flag: true } } },
            });
        }
        if (manualDefaultValue[`${locationName}_template`] && !newManualValue[`${locationName}_template`]?.flag) {
            switch (locationName) {
                case 'search':
                    setChooseManual('Работа с таблицей результата поиска из поиска');
                    break;
                case 'basket':
                    setChooseManual('Шаблоны в корзине');
                    break;
            }
            setNewManualValue({ ...newManualValue, [`${locationName}_template`]: { flag: true } });
            const result = updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.QwepManual,
                value: { value: { ...newManualValue, [`${locationName}_template`]: { flag: true } } },
            });
        }
        if (manualDefaultValue[`${locationName}_options`] && !newManualValue[`${locationName}_options`]?.flag) {
            switch (locationName) {
                case 'prices':
                    setChooseManual('Доп параметры прайсов');
                    break;
            }
            setNewManualValue({ ...newManualValue, [`${locationName}_options`]: { flag: true } });
            const result = updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.QwepManual,
                value: { value: { ...newManualValue, [`${locationName}_options`]: { flag: true } } },
            });
        }
    };

    return (
        <ManualContext.Provider
            value={{
                library,
                chooseManual,
                newManualValue,
                setChooseManual,
                setNewManualValue,
            }}
            {...props}
        />
    );
}

export { ManualProvider, ManualContext };
