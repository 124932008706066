import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconModalGroupProps = TypeClassNames;

export const IconModalGroup = ({ classNames }: TypeIconModalGroupProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <path
                    d="M15 16C15 15.4477 15.4477 15 16 15H22C22.5523 15 23 15.4477 23 16V22C23 22.5523 22.5523 23 22 23H16C15.4477 23 15 22.5523 15 22V16ZM15 26C15 25.4477 15.4477 25 16 25H22C22.5523 25 23 25.4477 23 26V32C23 32.5523 22.5523 33 22 33H16C15.4477 33 15 32.5523 15 32V26ZM25 16C25 15.4477 25.4477 15 26 15H32C32.5523 15 33 15.4477 33 16V22C33 22.5523 32.5523 23 32 23H26C25.4477 23 25 22.5523 25 22V16ZM25 26C25 25.4477 25.4477 25 26 25H32C32.5523 25 33 25.4477 33 26V32C33 32.5523 32.5523 33 32 33H26C25.4477 33 25 32.5523 25 32V26ZM28 17C27.4477 17 27 17.4477 27 18V20C27 20.5523 27.4477 21 28 21H30C30.5523 21 31 20.5523 31 20V18C31 17.4477 30.5523 17 30 17H28ZM28 27C27.4477 27 27 27.4477 27 28V30C27 30.5523 27.4477 31 28 31H30C30.5523 31 31 30.5523 31 30V28C31 27.4477 30.5523 27 30 27H28ZM18 17C17.4477 17 17 17.4477 17 18V20C17 20.5523 17.4477 21 18 21H20C20.5523 21 21 20.5523 21 20V18C21 17.4477 20.5523 17 20 17H18ZM18 27C17.4477 27 17 27.4477 17 28V30C17 30.5523 17.4477 31 18 31H20C20.5523 31 21 30.5523 21 30V28C21 27.4477 20.5523 27 20 27H18Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
