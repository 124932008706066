import { useContext } from 'react';
import stls from '@f_qwep/styles/general/Congratulation.module.sass';
import CloseIcon from '@mui/icons-material/Close';
import { QwepContext } from '@f_qwep/context/Qwep_context';

export function Congratulation() {
    const { congratulationOpenModal, setCongratulationOpenModal } = useContext(QwepContext);

    return (
        <>
            {congratulationOpenModal ? (
                <div className={stls.container}>
                    <audio src="./congratulation/imperial-march.mp3" autoPlay={true} loop></audio>
                    <button className={stls.closeBtn} onClick={() => setCongratulationOpenModal(false)}>
                        <CloseIcon style={{ color: 'red', fontSize: '200px' }} />
                    </button>
                    <video className={stls.fone} src="./congratulation/smoke1.mp4" autoPlay loop />

                    <div className={stls.imgBlock}>
                        <img className={stls.img} src="./congratulation/i.jpg" alt="" />
                    </div>

                    <img className={stls.text} src="./congratulation/Happy_birthday!.png" alt="" />

                    <div className={stls.video1}>
                        {' '}
                        <video
                            style={{ width: '300px', height: '300px' }}
                            src="./congratulation/star-wars1.mp4"
                            autoPlay
                            loop
                        />
                    </div>
                    <div className={stls.video2}>
                        {' '}
                        <video
                            style={{ width: '300px', height: '300px' }}
                            src="./congratulation/star-wars3.mp4"
                            autoPlay
                            loop
                        />
                    </div>
                    <div className={stls.video3}>
                        {' '}
                        <video
                            style={{ width: '500px', height: '400px' }}
                            src="./congratulation/star-wars2.mp4"
                            autoPlay
                            loop
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
