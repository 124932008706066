import { ConfigReportProvider } from './ConfigReport_context';
import { CrossdataProvider } from './Crossdata_context';
import { ReportListProvider } from './ReportList_context';

export function CrossdataProviders({ children }: any) {
    return (
        <CrossdataProvider>
            <ConfigReportProvider>
                <ReportListProvider>{children}</ReportListProvider>
            </ConfigReportProvider>
        </CrossdataProvider>
    );
}
