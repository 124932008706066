import { useCollector } from '@f_ucs/context/collector_context';
import stls
    from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/ReportListModal/ReportListModal.module.sass';
import { Button, Popover } from '@mui/material';
import { IconAddCollector } from '@f_ucs/components/icons/IconAddCollector';
import React, { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { ReportList } from '@f_ucs/components/actionPanel/actionButtonHandlers/ReportListModal/ReportList';

interface ReportListModalProps {
    isOpen: any,
    anchorEl: any,
    id: any
    close: any
    setIsOpenAddCollectorModal: any
}

export function ReportListModal({ isOpen, id, anchorEl, close, setIsOpenAddCollectorModal }: ReportListModalProps) {
    const { colors } = useContext(ThemeContext);
    const {
        currentType,
        currentConfigurations,
        checkCollectorsAliasByKey,
    } = useCollector();

    return (
        <Popover id={id} open={isOpen} anchorEl={anchorEl} onClose={close}
                 anchorOrigin={{ vertical: 50, horizontal: -24.434 }}
                 sx={{
                     '.MuiPopover-paper': {
                         borderRadius: '15px',
                     },
                 }}
        >

            <div className={stls.container}>

                <div className={stls.reportList} style={{ background: colors.delta, left: '120vh' }}>
                    {currentConfigurations.length ? (
                        <div>
                            <p className={stls.title} style={{color: colors.omicron}}>Конфигурации</p>
                            <ReportList close={close} currentConfigurations={currentConfigurations}
                                        checkCollectorsAliasByKey={checkCollectorsAliasByKey} />
                        </div>
                    ) : (
                        (currentType === '1c' || currentType === 'web') && (
                            <div className={stls.emptyListItem}>
                                <p className={stls.message}>{'У вас нет коллекторов'}</p>
                                <Button
                                    variant={'contained'}
                                    type="submit"
                                    onClick={() => {
                                        close(false);
                                        setIsOpenAddCollectorModal(true);
                                    }}
                                    style={{ display: 'flex' }}>
                                    <IconAddCollector />
                                    <p>Создать коллектор</p>
                                </Button>
                            </div>
                        )
                    )}
                </div>

            </div>
        </Popover>
    );
}