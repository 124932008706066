export const IconMask = (color: any) => {
    return (
        <div>
            <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 0.5C15.52 0.5 20 4.98 20 10.5C20 16.02 15.52 20.5 10 20.5C4.48 20.5 0 16.02 0 10.5C0 4.98 4.48 0.5 10 0.5ZM10 18.5C14.42 18.5 18 14.92 18 10.5C18 6.08 14.42 2.5 10 2.5C5.58 2.5 2 6.08 2 10.5C2 14.92 5.58 18.5 10 18.5Z"
                    fill={color.color}
                />
                <path d="M11 7.75H8V5.5L5 8.5L8 11.5V9.25H11V7.75Z" fill={color.color} />
                <path d="M9 11.75H12V9.5L15 12.5L12 15.5V13.25H9V11.75Z" fill={color.color} />
            </svg>
        </div>
    );
};
