import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconUCSProps = TypeClassNames;

export const IconUCS = ({ classNames }: TypeIconUCSProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="20" height="20" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 13.062V19H9V13.062C12.946 13.554 16 16.92 16 21H0C3.30739e-05 19.0514 0.711187 17.1698 2 15.7083C3.28882 14.2467 5.0667 13.3058 7 13.062ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12Z"
                    fill={colors.epsilon}
                />
            </svg>
        </div>
    );
};
