




const localeDate = (date: string | Date, lang: 'ru' | 'en') => {
    const d = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
    return d.toLocaleDateString(lang === 'ru' ? 'ru-RU' : 'en-EN', options);
}

const dateToString = (date: string | Date) => {
    return new Date(date).toString()
}



interface DateNormalizerProps {
    date: string | Date;
    format: 'locale' | 'date',
    lang?: 'ru' | 'en'
}

export const dateNormalizer = ({date, format, lang}: DateNormalizerProps) => {
    const dateFormat = {
        locale: localeDate(date, lang),
        date: dateToString(date)
    }
    return dateFormat[format]
}


