import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconCloseNotBGProps = TypeClassNames;

export const IconCloseNotBG = ({ classNames }: TypeIconCloseNotBGProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.29289 2.35375C3.68342 2.74427 4.31658 2.74427 4.70711 2.35375L6.52538 0.535472C6.80433 0.256527 7.25659 0.256527 7.53553 0.535473C7.81448 0.814419 7.81448 1.26668 7.53553 1.54563L5.71726 3.3639C5.32673 3.75442 5.32674 4.38759 5.71726 4.77811L7.53553 6.59639C7.81448 6.87533 7.81448 7.32759 7.53553 7.60654C7.25659 7.88549 6.80433 7.88549 6.52538 7.60654L4.70711 5.78827C4.31658 5.39774 3.68342 5.39774 3.29289 5.78827L1.47462 7.60654C1.19567 7.88549 0.743412 7.88549 0.464466 7.60654C0.18552 7.32759 0.18552 6.87533 0.464465 6.59639L2.28274 4.77811C2.67326 4.38759 2.67326 3.75442 2.28274 3.3639L0.464466 1.54563C0.18552 1.26668 0.18552 0.814419 0.464466 0.535473C0.743412 0.256527 1.19567 0.256527 1.47462 0.535473L3.29289 2.35375Z"
                fill="#A9C1CF"
            />
        </svg>
        // </div>
    );
};
