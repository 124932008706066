import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import {
    Button,
    CardContent,
    CircularProgress,
    InputBase,
    MenuItem,
    Modal,
    Select,
    styled,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/settings/SuppliersSettings.module.sass';
import stl from '@f_styles/general/Universal.module.sass';
import { IconModalSettings } from '../../icons/IconModalSettings';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { Field } from '@f_qwep/types';
import { LoadingSettings } from '../../loaders/LoadingSettings';

type TypeSettingsProps = {
    open: boolean;
    handleClose: any;
    info: any;
};

export function SettingSupplierModal({ open, handleClose, info }: TypeSettingsProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { getAccountParametrs, setAccountParametrs } = useContext(AccountsContext);
    const [isLoading, setIsLoading] = useState(false);
    const [itemIsLoading, setItemIsLoading] = useState({
        name: '',
        value: false,
    });
    const [form, setForm] = useState<any>({});

    const CustomSelect = styled(InputBase)(({ theme }) => ({
        '.MuiSvgIcon-root ': {
            fill: colors.epsilon,
        },
        '& .MuiInputBase-input': {
            borderRadius: 12,
            border: `1px solid ${colors.alpha}`,
            padding: '10px 26px 10px 12px',
            fontFamily: 'Inter',
            fontSize: 14,
            color: colors.omicron,
            '&:focus': {
                borderRadius: 12,
                border: `1px solid ${colors.xi}`,
            },
        },
    }));

    useEffect(() => {
        if (open) {
            getStettings();
        }
    }, [open]);

    const getStettings = async () => {
        setIsLoading(true);
        const result = await getAccountParametrs({
            accountId: info.id,
        });
        if (result?.isOk) {
            setForm(result.result.entity.form);
        } else {
            handleClose();
            setForm({});
        }
        setIsLoading(false);
    };

    const changeHandler = async (e: any, fieldName: string, formId: string) => {
        setItemIsLoading({ name: fieldName, value: true });
        const result = await setAccountParametrs({
            accountId: info.id,
            formId: formId,
            fieldName: fieldName,
            value: e,
        });
        if (result.isOk) {
            setForm(result.result.entity.form);
        }
        setItemIsLoading({ name: '', value: false });
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
                setForm({});
            }}>
            {isLoading ? (
                <LoadingSettings classNames={[stls.isLoading]} />
            ) : (
                <MyModal className={stls.modal}>
                    <CardContent className={stls.modalContent}>
                        <div className={stls.modalTitle}>
                            <IconModalSettings />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {t('qwep.settings.suppliers.options')}
                            </Typography>
                        </div>
                        {form.fields &&
                            form.fields.map((item: any) => {
                                if (item.typeName === Field.SelectField) {
                                    return (
                                        <div key={item.fieldId}>
                                            <Typography className={stls.modalTypography}>{item.title}</Typography>
                                            <div className={stls.selectBox}>
                                                <Select
                                                    id={item.fieldId}
                                                    input={<CustomSelect />}
                                                    className={stls.select}
                                                    autoWidth
                                                    defaultValue={item.defaultValue}
                                                    onChange={(e: any) =>
                                                        changeHandler(e.target.value, item.fieldName, form.formId)
                                                    }
                                                    MenuProps={{
                                                        sx: {
                                                            '&& .MuiMenuItem-root': {
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                color: colors.omicron,
                                                                '&:hover': {
                                                                    background: colors.beta,
                                                                },
                                                            },
                                                        },
                                                    }}>
                                                    {item?.options?.map((option: any) => {
                                                        return (
                                                            <MenuItem
                                                                id={option.value}
                                                                key={option.text}
                                                                value={option.value}>
                                                                {option.text}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {itemIsLoading.name === item.fieldName && itemIsLoading.value && (
                                                    <CircularProgress
                                                        style={{ color: colors.alpha }}
                                                        size={'25px'}
                                                        className={stls.loading}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                } else if (item.typeName === Field.TextField) {
                                    return (
                                        <div key={item.fieldId}>
                                            <Typography sx={{ color: colors.lambda }} className={stls.modalTypography}>
                                                {item.title}
                                            </Typography>
                                            <p>{item.defaultValue}</p>
                                        </div>
                                    );
                                }
                            })}

                        <div className={stl.spaceBetween}>
                            <Button
                                className={stls.modalButton}
                                onClick={() => {
                                    handleClose();
                                    setForm({});
                                }}
                                size="large"
                                sx={{
                                    padding: '13px',
                                    background: colors.delta,
                                    color: colors.phi,
                                    '&:hover': { color: colors.alpha },
                                }}>
                                {t('qwep.settings.suppliers.close')}
                            </Button>
                        </div>
                    </CardContent>
                </MyModal>
            )}
        </Modal>
    );
}
