import { Button, Checkbox, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/group/Group.module.sass';
import { AddGroup } from './AddGroup';
import { AccountsContext } from '../../context/Accounts_context';
import { GroupContext } from '../../context/Group_context';
import { SearchBase } from '@f_general/SearchBase';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { SettingsContext } from '../../context/Settings_context';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export function MyAcconts() {
    const { fullAccounts } = useContext(AccountsContext);
    const navigate = useQwepNavigate();
    const { t } = useTranslation();
    const { colors } = React.useContext(ThemeContext);
    const { newGroup, setNewGroup } = React.useContext(GroupContext);
    const { navigatinHandler } = React.useContext(SettingsContext);
    const [accountTable, setAccountTable] = React.useState(fullAccounts);
    const [openAdd, setOpenAdd] = React.useState(false);

    useEffect(() => {
        setAccountTable(fullAccounts);
        setNewGroup(fullAccounts);
    }, [fullAccounts]);

    const handleOpenAdd = () => setOpenAdd(true);

    const handleAddAccounts = (group: any) => {
        if (newGroup.includes(group)) {
            return setNewGroup(newGroup.filter((item: any) => item !== group));
        }
        return setNewGroup([...newGroup, group]);
    };

    const handleUncheckAccounts = () => {
        if (newGroup.length > 0) {
            return setNewGroup([]);
        } else {
            setNewGroup(fullAccounts);
        }
    };

    const handleNavigate = () => {
        navigate('settings');
        navigatinHandler('group_accounts');
        setNewGroup([]);
    };

    return (
        <LayoutPage classNames={[stls.container]}>
            <div className={stls.appbarAccounts}>
                <div style={{ display: 'flex' }}>
                    <SearchBase
                        data={fullAccounts.filter((acc: any) => !acc.promo)}
                        placeholder="Найти поставщика"
                        setData={setAccountTable}
                        type={'accountsGroup'}
                    />
                </div>
                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button
                        onClick={handleNavigate}
                        className={stls.buttonGroup}
                        sx={{ border: '2px solid !important' }}
                        variant="outlined">
                        <ArrowBackIcon />
                        Назад
                    </Button>
                    <Button
                        id="saveNewGroup"
                        onClick={() => handleOpenAdd()}
                        className={stls.buttonGroup}
                        variant="contained"
                        disabled={!newGroup.length}>
                        {t('groups.saveGroup')}
                    </Button>
                </div>
            </div>
            <div className={stls.listItemHeader}>
                <Grid container className={stls.listItemHeader} sx={{ background: colors.beta }}>
                    <Grid item xs={2}>
                        <Checkbox id="chooseAccounts" checked={newGroup.length} onChange={handleUncheckAccounts} />
                    </Grid>
                    <Grid item xs={5}>
                        <p style={{ color: colors.omicron }}>{t('groups.myAccounts')}</p>
                    </Grid>
                    <Grid item xs={5}>
                        <p style={{ color: colors.omicron }}>{t('groups.myVendors')}</p>
                    </Grid>
                </Grid>
            </div>
            <div className={stls.listItemMyGroup}>
                {accountTable
                    ?.filter((acc: any) => !acc.promo)
                    ?.map((item: any) => (
                        <Grid key={item.id} container spacing={2} className={stls.listItemVendor}>
                            <Grid item xs={2}>
                                <Checkbox checked={newGroup.includes(item)} onChange={() => handleAddAccounts(item)} />
                            </Grid>
                            <Grid item xs={5}>
                                <p style={{ color: colors.omicron, overflow: 'hidden' }}>{item.login}</p>
                            </Grid>
                            <Grid item xs={5}>
                                <p style={{ color: colors.omicron, overflow: 'hidden' }}>{item.title}</p>
                            </Grid>
                        </Grid>
                    ))}
            </div>
            <AddGroup openAdd={openAdd} setOpenAdd={setOpenAdd} />
        </LayoutPage>
    );
}
