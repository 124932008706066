import stls from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/ReportListModal/ReportListModal.module.sass';
import { TypeConfiguration, TypeCurrentType } from '@f_ucs/context/collector_context';
import React, { useContext } from 'react';
import { ReportItem } from '@f_ucs/components/actionPanel/actionButtonHandlers/ReportListModal/ReportItem';
import { ThemeContext } from '@f_context/Theme_context';



interface  ReportListProps {
    close: any
    currentConfigurations: TypeConfiguration[]
    checkCollectorsAliasByKey: (key: string, type: TypeCurrentType ) => string
}

export const ReportList = ({ close, currentConfigurations, checkCollectorsAliasByKey }: ReportListProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div>
            {currentConfigurations.map(item  => {
                return (
                    <div key={item.key} className={stls.configList}>
                        <div className={stls.subTitle} style={{color: colors.omicron}}>{
                            checkCollectorsAliasByKey(item?.key, item.type)
                        }

                        </div>
                        <div className={stls.list}>
                            {item.collectors.map((collector: any) => {
                                return (
                                    <ReportItem close={close} collector={collector}/>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}