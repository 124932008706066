import stls from '@f_styles/components/drawer/DrawerList.module.sass';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Collapse, ListSubheader, Typography } from '@mui/material';
import SupervisedUserCircleSharpIcon from '@mui/icons-material/SupervisedUserCircleSharp';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';
import { useState } from 'react';
import { IconCompany } from '@f_components/icons/IconCompany';
import { useTranslation } from 'react-i18next';

type TypePanels = {
    registerDate: string;
    telegramNickname: string;
    userWorkspaceIsActive: boolean;
    workspaceHash: string | null;
    workspaceId: number;
    workspaceIsActive: boolean;
    workspaceName: string;
};

type TypeCompanyListProps = {
    navigatinHandler: (url: string, panelName: string) => void;
    panels: TypePanels[];
    currentPanel: string;
    currentCompany: TypePanels;
    changeCompany: (workspaceId: number) => void;
};

export function CompanyList({
    navigatinHandler,
    panels,
    currentPanel,
    currentCompany,
    changeCompany,
}: TypeCompanyListProps) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(true);
    const [openId, setOpenId] = useState(null);

    const handleClick = (index: any) => {
        if (openId === index) {
            setOpen(!open);
            setOpenId(index);
        } else {
            setOpenId(index);
            setOpen(true);
        }
    };

    return (
        <List
            id="drawerListCompany"
            subheader={<ListSubheader className={stls.title}>{t('drawer.company')}</ListSubheader>}>
            <ListItem className={stls.listItem} selected={currentPanel === 'company'} disablePadding>
                <ListItemButton className={stls.listItemButton} onClick={() => navigatinHandler('/company', 'company')}>
                    <ListItemIcon className={stls.btnIcon} sx={{ minWidth: 40 }}>
                        <SupervisedUserCircleSharpIcon />
                    </ListItemIcon>
                    <Typography variant="body2" className={stls.companyName}>
                        {currentCompany?.workspaceName}
                    </Typography>
                </ListItemButton>
                <button
                    className={stls.arrow}
                    onClick={() => {
                        handleClick(currentCompany.workspaceId);
                    }}>
                    {panels.length ? (
                        open && currentCompany.workspaceId == openId ? (
                            <ArrowDropUpSharpIcon />
                        ) : (
                            <ArrowDropDownSharpIcon />
                        )
                    ) : (
                        <></>
                    )}
                </button>
            </ListItem>
            {currentCompany.workspaceId == openId && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List className={stls.listPeople} component="div" disablePadding>
                        {panels.map((el) => (
                            <ListItemButton
                                className={stls.listPeopleButton}
                                key={el.workspaceId}
                                sx={{ pl: 4 }}
                                onClick={() => changeCompany(el.workspaceId)}>
                                <ListItemIcon className={stls.listIcon}>
                                    <IconCompany classNames={[stls.iconCompany]} />
                                </ListItemIcon>
                                <Typography variant="body2">{el.workspaceName}</Typography>
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            )}
        </List>
    );
}
