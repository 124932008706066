import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconStopProps = {
    classNames?: string[];
    color?: string;
};

export const IconStop = ({ classNames, color }: TypeIconStopProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                <path d="M13.9999 20.6668C10.3179 20.6668 7.33325 17.6822 7.33325 14.0002C7.33325 10.3182 10.3179 7.3335 13.9999 7.3335C17.6819 7.3335 20.6666 10.3182 20.6666 14.0002C20.6666 17.6822 17.6819 20.6668 13.9999 20.6668ZM13.9999 19.3335C15.4144 19.3335 16.771 18.7716 17.7712 17.7714C18.7713 16.7712 19.3333 15.4147 19.3333 14.0002C19.3333 12.5857 18.7713 11.2291 17.7712 10.2289C16.771 9.22873 15.4144 8.66683 13.9999 8.66683C12.5854 8.66683 11.2289 9.22873 10.2287 10.2289C9.22849 11.2291 8.66659 12.5857 8.66659 14.0002C8.66659 15.4147 9.22849 16.7712 10.2287 17.7714C11.2289 18.7716 12.5854 19.3335 13.9999 19.3335ZM11.9999 12.0002H15.9999V16.0002H11.9999V12.0002Z" />
            </svg>
        </div>
    );
};
