import { observer } from 'mobx-react-lite';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { IconAttach } from '@f_components/icons/IconAttach';
import CircularProgress from '@mui/material/CircularProgress';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';
import { useTranslation } from 'react-i18next';
import { FC, useContext } from 'react';
import { IColorsProps } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingProps.interface';
import { UserContext } from '@f_context/User_context';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const PositionLoadingFile: FC<IColorsProps> = observer(({ colors }) => {
    const { t } = useTranslation();
    const store = useAssistantStore();
    const { currentPositionLoadingOption: currentOption, fileTitle, isFileLoading, setFile } = store.assistantModalList;
    const { FILE_INPUT } = PositionLoadingOptionsEnum;
    const { userInfo } = useContext(UserContext);

    return FILE_INPUT === currentOption ? (
        <div className={styles.fileBlock}>
            <p>
                Укажите файл с колонками A - Артикул, B - Бренд, для автодобавления позиций в список. Поддерживаемые
                форматы xls, xlsx
            </p>
            <p>
                <label className={styles.inputAddFile} htmlFor="file">
                    <IconAttach />
                    <p id="inputAddFile" style={{ color: colors.alpha }} className={styles.text}>
                        {t('qwep.price.modal.updateOrAddPriceModal.labelFile')}
                    </p>{' '}
                    <p className={styles.fileName}>{fileTitle}</p>{' '}
                    {isFileLoading && <CircularProgress size={20} className={styles.loading} />}
                    <input id="file" type="file" onChange={(e) => setFile(e, userInfo)} />
                </label>
            </p>
        </div>
    ) : null;
});
