import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks';
import { Box, Button, Card, Checkbox, Grid, TextField, Tooltip } from '@mui/material';
import stls from '@f_qwep/styles/component/settings/Margin.module.sass';
import { IconClose } from '@f_components/icons/IconClose';
import { QwepContext } from '../../../context/Qwep_context';
import { Property } from '@f_qwep/types';
import { Alert } from '@f_general/alert';
import AddIcon from '@mui/icons-material/Add';
import { IconDelete } from '@f_components/icons/IconDelete';
import GeneralModal from '@f_general/GeneralModal';
import { MobileButtonSettings } from '../MobileButtonSettings';
import { randomKey } from '@f_utils/randomKey';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function Margin() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { updateProperty, priceMarginState, workspaceApplication } = useContext(QwepContext);

    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    const [templateValue, setTemplateValue] = useState({
        id: 1,
        flag: false,
        hideVendor: false,
        name: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
        content: [{ id: 1, from: 0, to: 100000, addPercent: 0 }],
    });

    const [marginTable, setMarginTable] = useState(templateValue);

    const [loading, setLoading] = useState(false);

    const [buttonStyle, setButtonStyle] = useState(false);

    const [newMargin, setNewMargin] = useState(true);

    const [open, setOpen] = useState(false);

    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    useEffect(() => {
        setTemplateValue({
            id:
                priceMarginState?.length > 0
                    ? priceMarginState?.reduce((max: any, item: any) => {
                          return item.id > max ? item.id : max;
                      }, 0) + 1
                    : 1,
            flag: false,
            hideVendor: false,
            name: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`,
            content: [{ id: 1, from: 0, to: 100000, addPercent: 0 }],
        });
        if (priceMarginState?.length > 0) {
            setMarginTable(
                priceMarginState.filter((el: any) => el.id == marginTable.id)?.length > 0
                    ? priceMarginState.filter((el: any) => el.id == marginTable.id)[0]
                    : priceMarginState[0],
            );
            // setMarginTable(priceMarginState[0])
            setNewMargin(false);
        } else {
            setMarginTable(templateValue);
            setNewMargin(true);
        }
    }, [JSON.stringify(priceMarginState)]);

    const handleModalClose = () => {
        setOpen(false);
        setDataModal({});
    };

    const handleChangeName = (event: any) => {
        setMarginTable((prevState) => ({ ...prevState, name: event.target.value }));
        setButtonStyle(true);
    };

    const handleChange = (event: any, id: any, name: any) => {
        setButtonStyle(true);
        const updatedContent = marginTable.content.map((contentItem: any) => {
            if (contentItem.id === id) {
                if (isNaN(parseFloat(event.target.value))) {
                    event.target.value = 0;
                }
                return {
                    ...contentItem,
                    [name]: parseFloat(event.target.value),
                };
            }
            return contentItem;
        });
        setMarginTable({ ...marginTable, content: updatedContent });
    };

    const handleAdd = () => {
        setButtonStyle(true);
        let maxTo = 0;
        marginTable?.content?.forEach((el) => {
            if (el.to > maxTo) {
                maxTo = el.to;
            }
            // marginTable?.content?.length + 1;
            const newId = randomKey();
            const newItem = {
                id: newId,
                from: maxTo,
                to: maxTo < 100000 ? 100000 : maxTo + 1,
                addPercent: 0,
            };
            const updatedMarginTable = {
                ...marginTable,
                content: [...marginTable.content, newItem],
            };
            setMarginTable(updatedMarginTable);
        });
    };

    const handleDelete = (id: any) => {
        setButtonStyle(true);
        if (id > 1) {
            const updatedContent = marginTable.content.filter((contentItem) => contentItem.id !== id);
            setMarginTable({ ...marginTable, content: updatedContent });
        }
    };

    const handleSubmit = async (event: any) => {
        setButtonStyle(false);
        event.preventDefault();
        const isValid = marginTable?.content?.every(
            (item, index, array) => item.to >= item.from && (index === 0 || item.from >= array[index - 1].to),
        );

        if (newMargin && priceMarginState?.filter((el: any) => el.name == marginTable.name).length > 0) {
            Alert({
                type: 'warning',
                text: 'Шаблон наценки с таким названием уже существует',
            });
        } else {
            // была ли добавлена новая наценка
            const marginValue = newMargin
                ? // существует ли старая наценка
                  priceMarginState
                    ? [...priceMarginState, marginTable]
                    : [marginTable]
                : priceMarginState.map((el: any) => {
                      if (el.id == marginTable.id) {
                          return (el = marginTable);
                      } else {
                          return el;
                      }
                  });

            if (isValid) {
                const result = await updateProperty({
                    applicationWorkspaceId: workspaceApplication,
                    propertyName: Property.Margin,
                    value: { value: marginValue },
                });
                if (!result?.isOk) {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                } else {
                    Alert({
                        type: 'success',
                        text: 'Успешно!',
                    });
                }
            } else {
                Alert({
                    type: 'warning',
                    text: 'Значение "от" должно быть меньше или равно значению "до" и больше, чем предыдущее значение "до"',
                });
            }
        }
    };

    const clickModalHandler = (name: any, id: any) => {
        setDataModal({
            icon: <IconDelete />,
            title: `${t('qwep.batchPricer.wantDelete')} ${name} ?`,
            props: [],
            shema: null,
            type: 'deleteMargin',
            data: {
                marginId: id,
            },
        } as TypeDataModal);
        setOpen(true);
    };

    const handleDeleteMargin = async (values: any) => {
        const marginValue = priceMarginState.filter((el: any) => el.id != values.marginId);

        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.Margin,
            value: { value: marginValue },
        });
        if (!result?.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        } else {
            handleModalClose();
            Alert({
                type: 'success',
                text: 'Шаблон наценки удален!',
            });
        }
    };

    const handleHideVendorChange = async () => {
        setMarginTable((prevState) => ({ ...prevState, hideVendor: !prevState.hideVendor }));
        setButtonStyle(true);
    };

    const handleHideFlagChange = async () => {
        if (marginTable.flag == false && priceMarginState?.filter((el: any) => el.flag == true).length > 0) {
            Alert({
                type: 'warning',
                text: 'У вас уже есть используемая наценка',
            });
        } else {
            setMarginTable((prevState) => ({ ...prevState, flag: !prevState.flag }));
            setButtonStyle(true);
        }
    };

    const handleAddNewMargin = () => {
        setMarginTable(templateValue);
        setNewMargin(true);
    };

    const handleChooseMargin = (id: any) => {
        setMarginTable(priceMarginState.filter((el: any) => el.id == id)[0]);
        setNewMargin(false);
    };

    const handleCheckbox = async (id: any, flag: any) => {
        const newValue = priceMarginState.map((item: any) => {
            if (item.id === id && !flag) {
                return {
                    ...item,
                    flag: true,
                };
            }
            return {
                ...item,
                flag: false,
            };
        });
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.Margin,
            value: { value: newValue },
        });
    };

    return (
        <Box className={stls.page}>
            <div className={stls.appbarGroup}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>{mobile && <MobileButtonSettings />}</div>
            </div>
            <div style={{ marginBottom: '24px' }}>
                {priceMarginState && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                        <div style={{ background: colors.beta }} className={stls.title}>
                            Мои шаблоны наценок
                        </div>

                        <div className={stls.myMargin}>
                            {priceMarginState?.map((el: any) => (
                                <Card
                                    onClick={() => handleChooseMargin(el.id)}
                                    className={el?.flag ? stls.chooseMargin : stls.hideMargin}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox checked={el.flag} onChange={() => handleCheckbox(el.id, el.flag)} />
                                        <Tooltip placement="top" title={el.name}>
                                            <p style={{ color: colors.omicron }} className={stls.marginText}>
                                                {el.name}
                                            </p>
                                        </Tooltip>
                                    </div>

                                    <div className={stls.yet}>
                                        <span className={stls.yetText}>Подробнее </span>
                                        <KeyboardArrowUpIcon />
                                    </div>
                                </Card>
                            ))}
                            <Card
                                className={stls.hideMargin}
                                style={{ alignItems: 'center' }}
                                onClick={handleAddNewMargin}>
                                <AddIcon />
                                <span>Добавить наценку</span>
                            </Card>
                        </div>
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit}>
                <Card className={stls.listItemMyGroup}>
                    <Grid
                        container
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        className={stls.listItemButton}
                        sx={{ background: colors.beta }}>
                        <Grid item xs={3.7}>
                            <p style={{ color: colors.omicron }}>{mobile ? 'От' : 'Стоимость товара от'}</p>
                        </Grid>
                        <Grid item xs={3.7}>
                            <p style={{ color: colors.omicron }}>До</p>
                        </Grid>
                        <Grid item xs={3.7}>
                            <p style={{ color: colors.omicron }}>Наценка %</p>
                        </Grid>
                        <Grid item xs={0.5}>
                            <button onClick={() => clickModalHandler(marginTable.name, marginTable.id)}>
                                <IconClose />
                            </button>
                        </Grid>
                    </Grid>
                </Card>
                <Card className={stls.listItemMyGroup}>
                    {marginTable?.content?.map((item: any) => (
                        <Grid
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            container
                            className={stls.listItemVendor}>
                            <Grid item xs={3.7}>
                                <TextField
                                    value={item.from}
                                    name="from"
                                    onChange={(event) => handleChange(event, item.id, event.target.name)}
                                />
                            </Grid>
                            <Grid item xs={3.7}>
                                <TextField
                                    value={item.to}
                                    name="to"
                                    onChange={(event) => handleChange(event, item.id, event.target.name)}
                                />
                            </Grid>
                            <Grid item xs={3.7}>
                                <TextField
                                    value={item.addPercent}
                                    name="addPercent"
                                    onChange={(event) => handleChange(event, item.id, event.target.name)}
                                />
                            </Grid>
                            <Grid item xs={0.5}>
                                <button onClick={() => handleDelete(item.id)}>
                                    <IconClose />
                                </button>
                            </Grid>
                        </Grid>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            container
                            className={stls.listItemVendor}>
                            <Grid item xs={3.7}>
                                <div onClick={handleAdd} className={stls.addButton}>
                                    <AddIcon />
                                    <span>Добавить диапазон</span>
                                </div>
                            </Grid>
                            <Grid item xs={3.7}>
                                {!newMargin ? (
                                    <>
                                        <div className={stls.switchMargin}>
                                            <p style={{ color: colors.omicron }}>Использовать текущую наценку</p>
                                            <Checkbox
                                                disabled={loading}
                                                checked={marginTable?.flag}
                                                onChange={handleHideFlagChange}
                                            />
                                        </div>
                                        <div className={stls.switchMargin}>
                                            <p style={{ color: colors.omicron }}>Cкрыть поставщика при поиске</p>
                                            <Checkbox
                                                disabled={loading}
                                                checked={marginTable?.hideVendor}
                                                onChange={handleHideVendorChange}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <TextField
                                        label="Название шаблона наценки"
                                        value={marginTable?.name}
                                        name="name"
                                        onChange={(event) => handleChangeName(event)}
                                    />
                                )}
                            </Grid>
                            <Grid sx={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }} item xs={4.2}>
                                {!newMargin && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            label="Название шаблона наценки"
                                            value={marginTable.name}
                                            onChange={handleChangeName}
                                        />
                                    </div>
                                )}
                                <Button
                                    sx={{ width: !newMargin ? 'fit-content' : '100%' }}
                                    type={'submit'}
                                    style={{ background: buttonStyle ? colors.alpha : colors.beta }}
                                    className={stls.modalButton}
                                    variant="contained">
                                    Сохранить
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </form>

            <GeneralModal
                openModal={open}
                closeModal={handleModalClose}
                dataModal={dataModal}
                sendDataModalHandler={handleDeleteMargin}
            />
        </Box>
    );
}
