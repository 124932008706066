import { FC, useContext } from 'react';
import { IconMask } from '@f_qwep/components/icons/IconMask';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Property } from '@f_qwep/types';
import { Alert } from '@f_general/alert';
import Tooltip from '@mui/material/Tooltip';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';

interface IFilterLeftPanelCrossesProps {
    colors: any;
    tooltipTitle: string;
    tooltipCrossTurnOn: string;
    tooltipCrossTurnOff: string;
    tooltipCrossSwitchError: string;
}

export const FilterLeftPanelCrosses: FC<IFilterLeftPanelCrossesProps> = ({
    colors,
    tooltipTitle,
    tooltipCrossTurnOn,
    tooltipCrossTurnOff,
    tooltipCrossSwitchError,
}) => {
    const { qwepCrosses, updateProperty, workspaceApplication } = useContext(QwepContext);

    const handleCrosses = async () => {
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.QwepCrosses,
            value: { value: !qwepCrosses },
        });
        if (result?.isOk) {
            Alert({
                type: 'success',
                text: !qwepCrosses ? tooltipCrossTurnOn : tooltipCrossTurnOff,
            });
        } else {
            Alert({
                type: 'error',
                text: tooltipCrossSwitchError,
            });
        }
    };

    return (
        <Tooltip className={styles.cursorPointer} title={tooltipTitle}>
            <div className={styles.actionIconWrapper} id="crosses" onClick={handleCrosses}>
                <IconMask color={qwepCrosses ? colors.alpha : colors.sigma} />
            </div>
        </Tooltip>
    );
};
