import React, { FC, useEffect, useRef, useState } from 'react';
import styles from '@f_qwep/styles/component/searchResult/FilterLeftPanelOverflow.module.sass';

interface IFilterPopUpOverflowProps {
    children: React.ReactNode;
    verticalAlign: 'top' | 'middle' | 'bottom';
    listLength: number;
    inputWord: string;
    setInputWord: (arg: string) => void;
    currentTheme: string;
}

export const FilterPopUpOverflow: FC<IFilterPopUpOverflowProps> = ({
    children,
    verticalAlign,
    listLength,
    inputWord,
    setInputWord,
    currentTheme,
}) => {
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
    const lengthLimit = 5;
    const containerRef = useRef<HTMLDivElement | null>(null);

    const verticalAlignStyles = {
        top: { top: '160px' },
        middle: { top: '320px' },
        bottom: { bottom: '0' },
    };

    useEffect(() => {
        if (!isInputFocused) {
            setIsShow(false);
            setInputWord('');
        }
    }, [isInputFocused]);

    const handleClickOutside = (e: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
            setIsInputFocused(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            onMouseLeave={() => {
                if (!isInputFocused) {
                    setIsShow(false);
                    setInputWord('');
                }
            }}
            onMouseEnter={() => {
                if (listLength > lengthLimit) setIsShow(true);
            }}
            onFocus={() => {
                setIsInputFocused(true);
            }}>
            {isShow ? (
                <div
                    className={styles.container}
                    style={{
                        ...verticalAlignStyles[verticalAlign],
                        background: currentTheme !== 'dark' ? '#e5f1ff' : '#21222d',
                    }}>
                    <input
                        placeholder="Поиск..."
                        value={inputWord}
                        onChange={(e) => setInputWord(e.currentTarget.value)}
                        className={styles.input}
                        style={{
                            background: currentTheme !== 'dark' ? 'white' : '#22232e',
                            color: currentTheme === 'dark' ? '#f0f4fe' : '#1a242a',
                        }}
                    />
                    {children}
                </div>
            ) : null}
            <div className={styles.containerNotShow}>
                {children}
                <div className={styles.listLength}>{`Всего: ${listLength}`}</div>
            </div>
        </div>
    );
};
