export const IconDealerStock = ({ color }: any) => {
    return (
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.30505 10C2.54355 10 0.305054 7.7615 0.305054 5C0.305054 2.2385 2.54355 0 5.30505 0C8.06655 0 10.3051 2.2385 10.3051 5C10.3051 7.7615 8.06655 10 5.30505 10ZM8.34155 3.4645L4.80655 7L2.68505 4.8785L3.39205 4.1715L4.80655 5.586L7.63455 2.7575L8.34155 3.4645Z"
                fill={color}
            />
            <path
                d="M4.80655 7L8.34155 3.4645L7.63455 2.7575L4.80655 5.586L3.39205 4.1715L2.68505 4.8785L4.80655 7Z"
                fill="white"
            />
        </svg>
    );
};
