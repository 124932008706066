import React, { FC, RefObject, useContext } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { IconNotSave } from '@f_components/icons/IconNotSave';
import { Popover } from '@mui/material';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';
import { FilterLeftPanelTemplateRemove } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/template/templateActions/FilterLeftPanelTemplateRemove';
import { FilterLeftPanelTemplateInfo } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/actions/template/templateActions/FilterLeftPanelTemplateInfo';
import { observer } from 'mobx-react-lite';
import { useFilterLeftPanelStore } from '@f_qwep/components/filters/filterLeftPanel/context/FilterLeftStore.context';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

interface IFilterLeftPanelTemplatePopoverProps {
    tooltipTitle: string;
    isOpen: boolean;
    anchorRef: RefObject<HTMLDivElement>;
    handleClose: () => void;
    colors: any;
    handleTemplateRemove: (object: any) => Promise<void>;
    setCurrentTemplateFilter: (id: number, setFilter: any, filter: any, filterType: FilterTypesEnum) => void;
    updateProperty: any;
    workspaceApplication: any;
    setFilter: any;
    filter: any;
}

export const FilterLeftPanelTemplatePopover: FC<IFilterLeftPanelTemplatePopoverProps> = observer(
    ({
        tooltipTitle,
        isOpen,
        anchorRef,
        handleClose,
        colors,
        handleTemplateRemove,
        setCurrentTemplateFilter,
        updateProperty,
        workspaceApplication,
        setFilter,
        filter,
    }) => {
        const store = useFilterLeftPanelStore();
        const { filterType } = store.filterLeft;
        const { currentTemplateFilters } = store.filterLeftTemplate;
        const { searchFiltersReducer, batchPricerFiltersReducer } = useContext(FilterContext);

        return (
            <>
                <Popover
                    id={tooltipTitle}
                    sx={{
                        '& .MuiPopover-paper': {
                            borderRadius: '12px',
                            minWidth: 'fit-content',
                        },
                    }}
                    open={isOpen}
                    anchorEl={anchorRef.current}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <List className={styles.templateListWrapper} sx={{ background: colors.psi }}>
                        {currentTemplateFilters?.length ? (
                            <>
                                {currentTemplateFilters?.map((item: any, index) => (
                                    <div
                                        key={index}
                                        className={styles.templateItemWrapper}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setCurrentTemplateFilter(item.id, setFilter, filter, filterType);
                                        }}>
                                        <ListItemButton
                                            classes={styles.templateItem}
                                            key={item.id}
                                            sx={{ pointerEvents: 'none' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <p style={{ fontSize: '13px', marginLeft: '15px' }}>{item?.name}</p>
                                            </div>
                                        </ListItemButton>
                                        <div className={styles.templateItemActions}>
                                            <FilterLeftPanelTemplateInfo item={item} />
                                            <FilterLeftPanelTemplateRemove
                                                handleTemplateRemove={handleTemplateRemove}
                                                id={item.id}
                                                updateProperty={updateProperty}
                                                workspaceApplication={workspaceApplication}
                                                searchFiltersReducer={
                                                    filterType === FilterTypesEnum.SEARCH_PRICER
                                                        ? searchFiltersReducer
                                                        : batchPricerFiltersReducer
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div className={styles.templateItemWrapper}>
                                <IconNotSave />
                                <span style={{ marginLeft: '16px', fontSize: 'small' }}>
                                    У вас пока нет сохраненных профилей поиска
                                </span>
                            </div>
                        )}
                    </List>
                </Popover>
            </>
        );
    },
);
