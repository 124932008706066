import React, { useState, useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface OrderChatTabsProps {
    tabOrderItem: React.ReactNode
    tabOrder: React.ReactNode
}

export const OrderChatTabs = ({tabOrderItem, tabOrder }: OrderChatTabsProps) => {
    const [tabValue, setTabValue] = useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
                <div style={{ width: '400px', padding: '8px', borderRadius: '12px', minHeight: '400px' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" sx={{
                        width: '100%',
                    }}>
                        <Tab label="Чат позиции"
                             sx={{
                                 fontSize: '12px',
                                 padding: '4px',
                                 color: '#3B82F6 !important'
                             }}
                        />
                        <Tab
                            label="Чат заказа"
                            sx={{
                                fontSize: '12px',
                                padding: '4px',
                                color: '#3B82F6 !important'
                            }}
                        />
                    </Tabs>
                    {tabValue === 0 && (
                        tabOrderItem
                    )}
                    {tabValue === 1 && (
                        tabOrder
                    )}
                </div>
    );
};