import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useState } from 'react';
import { IconMenuPrice } from '../icons/IconMenuPrice';
import { IconUpdatePrice } from '../icons/IconUpdatePrice';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/price/Price.module.sass';
import { ClassificationSettingsModal } from './ClassificationSettingsModal';
import { IconClose } from '@f_components/icons/IconClose';
import GeneralModal from '@f_general/GeneralModal';
import { IconDelete } from '@f_components/icons/IconDelete';
import { PriceContext } from '@f_qwep/context/Price_context';
import { Alert } from '@f_general/alert';
import { UpdateOrAddPriceModal } from './priceModal/updateOrAdd/UpdateOrAddPriceModal';
import { ConfigurationModule } from './ConfigurationModule';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function CellSettings({ info }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { cleanPrice, deletePrice, getConfigPrice } = useContext(PriceContext);
    const [open, setOpen] = useState(false);
    //delete
    const [openDel, setOpenDel] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});
    //update
    const [openUpdate, setOpenUpdate] = useState(false);
    // config
    const [openConfigPrice, setOpenConfigPrice] = useState(false);

    const handleModalDeleteClose = () => {
        setDataModal({});
        setOpenDel(false);
    };

    const clickModalDeleteHandler = () => {
        let props: any = [];
        if (info.row.original.status !== 'UNCLASSIFIED') {
            props = [
                {
                    control: 'select',
                    name: 'delete',
                    title: '',
                    defaultValue: false,
                    options: [
                        { key: 'Снять с публикации', value: false },
                        { key: 'Удалить прайс полностью', value: true },
                    ],
                    castomStyle: {
                        marginLeft: '18px',
                    },
                },
            ];
        }
        setDataModal({
            icon: <IconDelete classNames={[stls.iconDel]} />,
            title: `${t('qwep.prices.deletePrice')} ${info.row.original.vendorName}`,
            props: props,
            shema: null,
            type: 'deletePrice',
            data: {
                delete: props.length ? false : true,
            },
        } as TypeDataModal);
        setOpenDel(true);
    };

    const sendDataModalDeleteHandler = async (values: any) => {
        if (values.delete) {
            const result = await deletePrice({
                codes: [info.row.original.adminCode],
            });
            if (result.isOk) {
                Alert({
                    type: 'success',
                    text: t('general.success'),
                });
                handleModalDeleteClose();
            } else {
                Alert({
                    type: 'error',
                    text: t('qwep.price.modal.classificationModal.settingsHandlerError'),
                    time: 8000,
                });
            }
        } else {
            const result = await cleanPrice({ codes: [info.row.original.adminCode] });
            if (result.isOk) {
                Alert({
                    type: 'success',
                    text: t('general.success'),
                });
                handleModalDeleteClose();
            } else {
                Alert({
                    type: 'error',
                    text: t('qwep.price.modal.classificationModal.settingsHandlerError'),
                    time: 8000,
                });
            }
        }
    };

    return (
        <div className={stls.cellSettings}>
            {info.row.original.status === 'UNCLASSIFIED' ? (
                <button id="mapPrice" style={{ margin: '0 5px' }} onClick={() => setOpen(true)}>
                    <IconMenuPrice />
                </button>
            ) : (
                <>
                    <button className={stls.configSettings} onClick={() => setOpenConfigPrice(true)}>
                        <IconEditSmall />
                    </button>
                    <button id="updatePrice" style={{ margin: '0 5px' }} onClick={() => setOpenUpdate(true)}>
                        <IconUpdatePrice />
                    </button>
                </>
            )}
            <button id={'deletePrice' + info.row.id} style={{ margin: '0 5px' }} onClick={clickModalDeleteHandler}>
                <IconClose />
            </button>
            <ClassificationSettingsModal
                adminCode={info.row.original.adminCode}
                open={open}
                setOpen={setOpen}
                price={info.row.original.priceName}
            />
            <GeneralModal
                openModal={openDel}
                closeModal={handleModalDeleteClose}
                dataModal={dataModal}
                sendDataModalHandler={sendDataModalDeleteHandler}
            />
            <UpdateOrAddPriceModal
                open={openUpdate}
                handleClose={() => setOpenUpdate(false)}
                adminCode={info.row.original.adminCode}
                email={info.row.original.senderEmail}
                type={'update'}
            />
            <ConfigurationModule
                open={openConfigPrice}
                setOpen={setOpenConfigPrice}
                adminCode={info.row.original.adminCode as string}
                vendor={info.row.original.vendorName}
            />
        </div>
    );
}
