import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconEyeProps = TypeClassNames;

export const IconEye = ({ classNames }: TypeIconEyeProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.3484 6.25384C26.3701 7.2294 26.3701 8.7706 25.3484 9.74616C22.8971 12.0866 18.6934 15.25 14 15.25C9.30658 15.25 5.10289 12.0866 2.65164 9.74617C1.62989 8.7706 1.62989 7.2294 2.65164 6.25384C5.10289 3.9134 9.30658 0.75 14 0.75C18.6934 0.75 22.8971 3.9134 25.3484 6.25384Z"
                stroke={colors.epsilon}
                strokeWidth="1.5"
            />
            <circle cx="14" cy="8" r="3.25" stroke={colors.epsilon} strokeWidth="1.5" />
        </svg>
        // </div>
    );
};
