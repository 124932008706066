import React, { useState } from 'react';
import { Grow, ClickAwayListener, List, Popper, CardContent } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { ManualContext } from '@f_context/Manual_context';
import { Manual } from './Manual';
import { IconLibrary } from '@f_components/icons/IconLibrary';

export const Library = () => {
    const navigate = useNavigate();

    const { colors } = React.useContext(ThemeContext);
    const { library } = React.useContext(ManualContext);

    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [chooseManual, setChooseManual] = useState<any>();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickLibrary = (content: any) => {
        setChooseManual(content);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button ref={anchorRef} onClick={() => setOpen(true)}>
                <IconLibrary />
            </button>
            <Popper
                onClick={() => setOpen(false)}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                style={{ maxHeight: '150px', zIndex: 1000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List sx={{ background: colors.psi }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <CardContent style={{ padding: '12px' }}>
                                    {library?.map((item: any, idx: any) => (
                                        <div key={idx}>
                                            <div style={{ display: 'flex' }}>
                                                <p
                                                    style={{
                                                        fontSize: '14px',
                                                        color: colors.epsilon,
                                                        marginRight: '8px',
                                                    }}>
                                                    {item.name}
                                                </p>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {item.content.map((el: any) => (
                                                    <button
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            fontSize: '14px',
                                                        }}
                                                        onClick={() => handleClickLibrary(el)}>
                                                        <p style={{ color: colors.omicron }}>{el}</p>
                                                        <PlayArrowIcon fontSize="small" />
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </CardContent>
                            </ClickAwayListener>
                        </List>
                    </Grow>
                )}
            </Popper>
            <Manual chooseManual={chooseManual} />
        </div>
    );
};
