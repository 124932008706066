import { makeAutoObservable } from 'mobx';
import {
    IFetchRemoveListArgs,
    IFetchRemoveListItemArgs,
} from '@f_qwep/components/purchasingAssistant/types/assistantApi.type';
import { TFunction } from 'i18next';
import { assistantApi } from '@f_qwep/components/purchasingAssistant/api/assistant.api';
import { Alert } from '@f_general/alert';
import { TDeals } from '@f_qwep/components/purchasingAssistant/types/Deals.type';
import { UseMutateFunction } from '@tanstack/react-query';
import { AssistantDealsStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantDeals.store';
import { AlertStatusEnum } from '@f_qwep/components/purchasingAssistant/types/AlertStatusEnum.enum';

interface IHandleRemoveCustomListArgs {
    users: number[];
    workspaceApplication: number;
    refKey: string;
    title: string;
    t: TFunction<'translation', undefined>;
    setCurrentAssistantListDefault: () => void;
    switchOnDefaultList: (status: AlertStatusEnum) => void;
}

interface IHandleRemoveCustomListItemArgs extends IFetchRemoveListItemArgs {
    t: TFunction<'translation', undefined>;
    getDeals: UseMutateFunction<TDeals[], Error, AlertStatusEnum, unknown>;
}

export class AssistantRemoveListStore {
    isShowCurrentListActions: boolean = false;
    assistantDealsStore: AssistantDealsStore;

    constructor(assistantDealsStore: AssistantDealsStore) {
        this.assistantDealsStore = assistantDealsStore;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    async handleRemoveCustomListItem(args: IHandleRemoveCustomListItemArgs) {
        const { refKey, article, brand, foundItem, t, getDeals } = args;

        const reqBody: IFetchRemoveListItemArgs = {
            refKey,
            article,
            foundItem,
            brand,
        };

        const data = await assistantApi.fetchRemoveListItem(reqBody);

        if (data.isOk) {
            Alert({
                type: 'success',
                text: t('qwep.assistant.popUp.positionRemoveSuccess', { article }),
            });
            getDeals(AlertStatusEnum.BASE);
        } else {
            Alert({
                type: 'error',
                text: t('qwep.assistant.popUp.positionRemoveError', { article }),
            });
        }

        return data;
    }

    async handleRemoveCustomList(args: IHandleRemoveCustomListArgs) {
        const { users, workspaceApplication, refKey, title, t, setCurrentAssistantListDefault, switchOnDefaultList } =
            args;

        const reqBody: IFetchRemoveListArgs = {
            users,
            workspaceApplicationId: workspaceApplication,
            refKey,
        };

        const data = await assistantApi.fetchRemoveList(reqBody);

        if (data.isOk) {
            Alert({
                type: 'success',
                text: t('qwep.assistant.popUp.removeSuccess', { title }),
            });
            setCurrentAssistantListDefault();
            switchOnDefaultList(AlertStatusEnum.BASE);
        } else {
            Alert({
                type: 'error',
                text: t('qwep.assistant.popUp.removeError', { title }),
            });
        }

        return data;
    }

    setIsShowCurrentListActions(value: boolean) {
        this.isShowCurrentListActions = value;
    }
}
