import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/user-table/UserTable.module.sass';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UserRoleChip } from '@f_general/UserRoleChip';
import { Role } from '@f_types/TypeRole';
import { IconButton } from '@mui/material';
import { UserContext } from '@f_context/User_context';
import { IconClose } from '@f_components/icons/IconClose';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';

export const CellRole = ({ info, clickModalHandler, currentRole }: any) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(UserContext);
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.cellRole}>
            <div style={{ minWidth: '150px' }}>
                <UserRoleChip
                    color="#8CDCE1"
                    classNames={[stls.roleBox]}
                    sysName={info.row.original.role.map((item: any) => item.sysName)}
                />
            </div>
            {currentRole.some(
                (item: any) =>
                    item.sysName === Role.Admin || item.sysName === Role.Manager || item.sysName === Role.SUPER_ADMIN,
            ) && (
                <div className={stls.actions}>
                    <button id="editRoleUser" onClick={() => clickModalHandler('changeUser')} className={stls.button}>
                        <IconEditSmall />
                    </button>
                    {info.row.original.userId !== userInfo.userId && (
                        <button id="deleteUser" onClick={() => clickModalHandler('deleteUser')} className={stls.button}>
                            <IconClose />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};
