import { action, makeObservable, observable } from 'mobx';
import { ModalStore } from '@f_store/Modal.store';
import { IBaseTemplateFilter, IFilter } from '@f_qwep/components/filters/filterLeftPanel/types/filters.interface';
import { FilterStatusEnum } from '@f_qwep/components/filters/filterLeftPanel/types/filterStatus.enum';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';
import { changeFieldFilterStorage } from '@f_qwep/utils/changeFieldFilterStorage';

interface ITemplateLocalStorage {
    id: number;
    name: string;
}

export class FilterLeftTemplateStore extends ModalStore {
    currentTemplateFilters: IBaseTemplateFilter[] = [];
    currentFilter: IFilter | null = null;
    activeTemplateId: number | null = null;
    currentTemplateName: string = '';
    filterStatus: FilterStatusEnum = FilterStatusEnum.INITIAL;

    constructor() {
        super();
        makeObservable(this, {
            filterStatus: observable,
            currentTemplateFilters: observable,
            activeTemplateId: observable,
            currentTemplateName: observable,
            setActiveTemplateId: action.bound,
            setCurrentTemplateFilters: action.bound,
            setCurrentFilter: action.bound,
            setCurrentTemplateName: action.bound,
            resetCurrentTemplateFilter: action.bound,
            setCurrentTemplateFilter: action.bound,
            setFilterStatus: action.bound,
            resetLocalStorage: action.bound,
        });
        this.loadActiveTemplateId();
        document.addEventListener('resetStores', () => this.resetLocalStorage());
    }

    resetLocalStorage(): void {
        this.saveActiveTemplateId(null);
    }

    setCurrentTemplateFilter(id: number, setFilter: any, filter: any, filterType: FilterTypesEnum): void {
        const selectedTemplate = this.currentTemplateFilters.find((filter) => filter.id === id);
        if (selectedTemplate) {
            this.currentTemplateName = selectedTemplate.name;
            this.setActiveTemplateId({ id: selectedTemplate.id, name: selectedTemplate.name });

            const templateFilter = {
                approvedCross: selectedTemplate.filters[0].approvedCross,
                inStock: selectedTemplate.filters[0].inStock,
                isDealer: selectedTemplate.filters[0].isDealer,
                searchArticle: selectedTemplate.filters[0].searchArticle,
                brands: selectedTemplate.filters[0].brands,
                maxPrice: selectedTemplate.filters[0].maxPrice,
                maxQuantity: selectedTemplate.filters[0].maxQuantity,
                maxShipmentDays: selectedTemplate.filters[0].maxShipmentDays,
                minPrice: selectedTemplate.filters[0].minPrice,
                minQuantity: selectedTemplate.filters[0].minQuantity,
                minShipmentDays: selectedTemplate.filters[0].minShipmentDays,
                vendorTitle: selectedTemplate.filters[0].vendorTitle,
                warehouses: selectedTemplate.filters[0].warehouses,
            };

            const currentTemplateFilter =
                filterType === FilterTypesEnum.SEARCH_PRICER
                    ? changeFieldFilterStorage(filter, templateFilter)
                    : templateFilter;
            this.setCurrentFilter(Object.values(currentTemplateFilter)[0] as IFilter);
            setFilter(currentTemplateFilter);
            this.handleClose();
        }
    }

    loadActiveTemplateId() {
        const template = localStorage.getItem('activeTemplate');
        if (template) {
            const { id, name } = JSON.parse(template);
            this.activeTemplateId = id;
            this.currentTemplateName = name;
        }
    }

    saveActiveTemplateId(template: ITemplateLocalStorage | null) {
        if (template === null) {
            localStorage.removeItem('activeTemplate');
            this.activeTemplateId = null;
            this.currentTemplateName = '';
        } else {
            localStorage.setItem('activeTemplate', JSON.stringify(template));
        }
    }

    setActiveTemplateId(arg: ITemplateLocalStorage | null) {
        if (arg) {
            const { id, name } = arg;
            this.activeTemplateId = id;
            this.saveActiveTemplateId({ id, name });
        }
    }

    setCurrentTemplateFilters(templateFilters: IBaseTemplateFilter[]) {
        this.currentTemplateFilters = templateFilters;
    }

    setCurrentFilter(filter: IFilter) {
        this.currentFilter = filter;
    }

    setCurrentTemplateName(templateName: string) {
        this.currentTemplateName = templateName;
    }

    setFilterStatus(status: FilterStatusEnum) {
        this.filterStatus = status;
    }

    resetCurrentTemplateFilter(setFilter: any) {
        this.resetCurrentTemplateFilterWithoutReducer();
        setFilter({ type: 'reset_filters' });
        this.saveActiveTemplateId(null);
    }

    resetCurrentTemplateFilterWithoutReducer = () => {
        this.currentFilter = null;
        this.activeTemplateId = null;
        this.currentTemplateName = '';
        this.saveActiveTemplateId(null);
    };
}
