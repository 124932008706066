import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import cn from 'classnames';
import { SearchResultsContext } from '@f_qwep/context/SearchResult_context';
import { ThemeContext } from '@f_context/Theme_context';

import { SearchContext } from '@f_qwep/context/Search_context';
import { useWindowSize } from '@f_hooks';
import { AddToBasket } from './addToBasket';
import { AddPositionItemAssistant } from '@f_qwep/components/purchasingAssistant/ui/addPositionItem';

// Кнопка ассистента временно закомменчена

export function CellQuantity({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { filter } = useContext(SearchContext);
    const { quantityCart, setQuantityCart } = useContext(SearchResultsContext);
    const quantity = quantityCart[info.row.original.itemId] ? quantityCart[info.row.original.itemId] : 0;
    const { width } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    const [style, setStyle] = useState({
        basketButtonColor: {
            background: colors.delta,
            border: `1px solid ${info.row.original.status === 'В наличии' ? colors.lightgreen : colors.phi}`,
            color: currentTheme == 'light' ? colors.omicron : colors.phi,
        },
        basketColor: colors.phi,
    });
    useEffect(() => {
        setStyle({
            basketButtonColor: {
                background:
                    quantity > 0
                        ? colors.pi
                        : info.row.original.status === 'В наличии'
                        ? currentTheme === 'dark'
                            ? colors.ucsGamma
                            : colors.pi
                        : currentTheme === 'dark'
                        ? '#47556933'
                        : colors.delta,
                border: `1px solid ${info.row.original.status === 'В наличии' ? colors.lightgreen : colors.phi}`,
                color: currentTheme == 'light' ? colors.omicron : colors.phi,
            },
            basketColor: info.row.original.status === 'В наличии' ? colors.lightgreen : colors.phi,
        });
    }, [filter, quantity]);

    // useMemo(() => {
    //     if (info.row.original.status === 'В наличии') {
    //         setStyle({
    //             basketButtonColor: {
    //                 background: colors.pi,
    //                 border: `1px solid ${colors.gamma}`,
    //                 color: currentTheme == 'light' ? colors.omicron : colors.gamma
    //             },
    //             basketColor: colors.gamma
    //         })
    //     }
    // }, []);

    const [border, setBorder] = useState(false);

    const addBasket = () => {
        info.row.original.quantity.sign == '='
            ? setQuantityCart((prev: any) => ({
                  ...prev,
                  [info.row.original.itemId]:
                      quantity < +info.row.original.quantity.value
                          ? quantity + +info.row.original.quantity.multiplicity
                          : quantity,
              }))
            : setQuantityCart((prev: any) => ({
                  ...prev,
                  [info.row.original.itemId]: quantity < 0 ? 0 : quantity + +info.row.original.quantity.multiplicity,
              }));
    };

    const deleteBasket = () => {
        setQuantityCart((prev: any) => ({
            ...prev,
            [info.row.original.itemId]: quantity <= 0 ? 0 : quantity - +info.row.original.quantity.multiplicity,
        }));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value;
        info.row.original.quantity.sign == '='
            ? setQuantityCart((prev: any) => ({
                  ...prev,
                  [info.row.original.itemId]:
                      quantity < 0
                          ? 0
                          : +value > +info.row.original.quantity.value
                          ? +info.row.original.quantity.value
                          : +value,
              }))
            : setQuantityCart((prev: any) => ({
                  ...prev,
                  [info.row.original.itemId]: quantity < 0 ? 0 : +value,
              }));
    };

    const focusHandler = () => {
        setBorder(false);
        setQuantityCart((prev: any) => ({
            ...prev,
            [info.row.original.itemId]:
                Math.ceil(+quantity / +info.row.original.quantity.multiplicity) *
                +info.row.original.quantity.multiplicity,
        }));
    };

    return (
        <div>
            <div className={cn(stls.cellBasket)}>
                <div style={{ display: 'flex', flexShrink: 0 }}>
                    <button onClick={deleteBasket} style={style.basketButtonColor} className={stls.cellBasketButton}>
                        -
                    </button>
                    <input
                        style={{ color: quantity > 0 ? colors.gamma : colors.omicron }}
                        className={stls.cellBasketInput}
                        onChange={handleChange}
                        onBlur={focusHandler}
                        onFocus={() => setBorder(true)}
                        value={quantity || 0}
                    />
                    <button onClick={addBasket} style={style.basketButtonColor} className={stls.cellBasketButton}>
                        +
                    </button>
                </div>
                {!mobile && (info.row.original.searchId !== null) ?
                    <AddToBasket info={info} />
                    :
                    <></>}

                {/*<AddPositionItemAssistant
                    className={stls.addItemPosition}
                    article={info.row.original.article}
                    brand={info.row.original.brand}
                />*/}
            </div>
        </div>
    );
}
