import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { useWindowSize } from '@f_hooks';
import { ThemeContext } from '@f_context/Theme_context';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { IDefaultPage, NavbarView } from '@f_qwep/components/navbar/NavbarView';
import { Tariff } from '@f_qwep/types';

export default function Navbar() {
    const { t } = useTranslation();
    const [needAccessTariffPro, setNeedAccessTariffPro] = useState<Tariff | string>('');
    const [needAccessTariffProPlus, setNeedAccessTariffProPlus] = useState<Tariff | string>('');

    const { activeApp, currentLocation } = useContext(QwepContext);
    const navigate = useQwepNavigate();
    const { currentTheme } = useContext(ThemeContext);

    const { isAccess: accessPrices, currentTariff } = useAccess(QwepProductEnum.PRICE);
    const { isAccess: accessOrders } = useAccess(QwepProductEnum.ORDERS);
    const { isAccess: accessAssistant } = useAccess(QwepProductEnum.ASSISTANT);
    const { isAccess: accessBatchPrice } = useAccess(QwepProductEnum.BATCH_PRICE);

    const [pages, setPage] = useState<IDefaultPage[]>([]);

    // TODO рендерится два раза - разобраться почему переключается язык
    const getPage = (): IDefaultPage[] => {
        return [
            {
                name: `${t('common.pages.batchPricer')}`,
                url: 'batch-pricer',
                access: accessBatchPrice,
            },
            {
                name: `${t('common.pages.prices')}`,
                url: 'prices',
                access: accessPrices,
            },
            {
                name: `${t('common.pages.orders')}`,
                url: 'orders',
                access: accessOrders,
            },
            {
                name: `${t('common.pages.settings')}`,
                url: 'settings',
                access: true,
            },
            ...(accessAssistant
                ? [
                      {
                          name: `${t('common.pages.purchasingAssistant')}`,
                          url: 'purchasing-assistant',
                          access: accessAssistant,
                      },
                  ]
                : []),
        ];
    };

    useEffect(() => {
        if (currentTariff) {
            if (!accessPrices) setNeedAccessTariffProPlus('PRO+');
            if (!accessOrders) setNeedAccessTariffPro('PRO');

            setPage(getPage());
        }
    }, [currentTariff, t]);

    const [alignment, setAlignment] = useState<string>('');

    const [mobile, setMobile] = useState<boolean>(false);

    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 480);
    }, [width]);

    useEffect(() => {
        switch (currentLocation.pathname) {
            case '/app-qwep/settings':
                setAlignment('Настройки');
                break;
            case '/app-qwep/prices':
                setAlignment('Прайсы');
                break;
            case '/app-qwep/batch-pricer':
                setAlignment('Проценка');
                break;
            case '/app-qwep/orders':
                setAlignment('Заказы');
                break;
            case '/app-qwep/purchasing-assistant':
                setAlignment('Ассистент');
                break;
            default:
                setAlignment('');
                break;
        }
    }, [currentLocation.pathname]);

    const handleChange = (event: any, newAlignment: string) => {
        setAlignment(newAlignment);
    };

    return (
        <>
            <NavbarView
                activeApp={activeApp}
                navigate={navigate}
                alignment={alignment}
                handleChange={handleChange}
                currentTheme={currentTheme}
                pages={pages}
                mobile={mobile}
                needAccessTariffPro={needAccessTariffPro}
                needAccessTariffProPlus={needAccessTariffProPlus}
            />
        </>
    );
}
