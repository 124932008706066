export const IconVinPin = ({ width, height }: any) => {
    return (
        <svg width={width} height={height} viewBox="0 0 1520 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3_2)">
                <path
                    d="M1466.68 3.61344C1479.81 3.61338 1492.41 8.69112 1501.71 17.7336C1511.01 26.7762 1516.26 39.0457 1516.29 51.8529V146.706C1516.3 153.063 1515.02 159.358 1512.53 165.233C1510.04 171.107 1506.38 176.445 1501.77 180.942C1497.17 185.438 1491.69 189.006 1485.67 191.439C1479.65 193.873 1473.19 195.126 1466.68 195.126H53.3619C46.8396 195.131 40.3803 193.881 34.3536 191.45C28.3268 189.018 22.8508 185.452 18.2388 180.954C13.6268 176.457 9.96936 171.118 7.47562 165.241C4.98189 159.364 3.70082 153.066 3.70569 146.706V51.9613C3.71055 45.6074 4.99881 39.3167 7.4969 33.4483C9.995 27.5799 13.654 22.2487 18.265 17.7592C22.876 13.2697 28.3487 9.70976 34.3707 7.28262C40.3926 4.85548 46.8459 3.60869 53.3619 3.61344H1466.68ZM1466.68 0H53.3619C39.2283 0.0190619 25.6781 5.49736 15.6771 15.2358C5.67618 24.9742 0.0391922 38.1795 0 51.9613V146.706C0.019607 160.5 5.64795 173.724 15.651 183.478C25.6541 193.232 39.2155 198.72 53.3619 198.739H1466.68C1480.82 198.711 1494.37 193.218 1504.36 183.465C1514.36 173.712 1519.98 160.494 1520 146.706V51.9613C1519.96 38.1858 1514.33 24.9859 1504.34 15.2485C1494.34 5.51112 1480.8 0.0286064 1466.68 0Z"
                    fill="#E30613"
                />
                <path
                    d="M570.829 0H52.5527C38.6297 0.0489227 25.2907 5.6774 15.4456 15.6576C5.60054 25.6378 0.0482604 39.1598 0 53.2739L0 150.615C0.0482604 164.729 5.60054 178.251 15.4456 188.231C25.2907 198.211 38.6297 203.84 52.5527 203.889H570.829C584.752 203.84 598.091 198.211 607.936 188.231C617.781 178.251 623.333 164.729 623.381 150.615V53.2739C623.333 39.1598 617.781 25.6378 607.936 15.6576C598.091 5.6774 584.752 0.0489227 570.829 0ZM111.588 154.29H82.6194L44.2029 51.4177H72.4751L97.1218 123.439L121.732 51.4177H150.151L111.588 154.29ZM205.084 71.9847H192.046V133.649H205.084V154.216H152.897V133.649H165.971V71.9847H152.897V51.4177H205.084V71.9847ZM306.564 154.29H279.024L244.233 92.5518V154.29H218.121V51.4177H245.661L280.452 113.119V51.4177H306.564V154.29ZM409.472 95.5217C409.472 105.793 407.177 113.218 402.587 117.797C398.009 122.437 390.611 124.776 380.613 124.776H345.713V154.179H319.601V51.4177H380.467C390.611 51.4177 398.009 53.7565 402.44 58.3971C406.871 63.0377 409.325 70.4997 409.325 80.6719L409.472 95.5217ZM470.338 71.9847H457.3V133.649H470.338V154.253H418.151V133.649H431.225V71.9847H418.151V51.4177H470.338V71.9847ZM571.817 154.29H544.278L509.487 92.5518V154.29H483.412V51.4177H510.951L545.669 113.119V51.4177H571.781L571.817 154.29Z"
                    fill="#E30613"
                />
                <path
                    d="M374.681 71.9847H345.713V104.32H374.681C375.862 104.493 377.066 104.385 378.199 104.005C379.332 103.625 380.362 102.982 381.207 102.129C382.053 101.275 382.691 100.234 383.071 99.087C383.451 97.9403 383.562 96.7197 383.397 95.5218V80.6719C383.544 79.4825 383.418 78.2747 383.03 77.1425C382.641 76.0103 382.001 74.9841 381.157 74.1437C380.314 73.3033 379.291 72.6713 378.168 72.2968C377.045 71.9224 375.851 71.8156 374.681 71.9847Z"
                    fill="#E30613"
                />
                <path
                    d="M759.908 152.842H734.639V73.1356H699.482V152.842H674.213V53.2368H759.908V152.842Z"
                    fill="#E30613"
                />
                <path
                    d="M776.755 53.2368H835.716C845.531 53.2368 852.672 55.4642 857.14 59.9934C861.608 64.5226 863.805 71.7248 863.805 81.6742V95.9301C863.805 105.83 861.584 113.057 857.14 117.611C852.672 122.14 845.531 124.368 835.716 124.368H802.024V152.842H776.755V53.2368ZM838.646 81.6742C838.836 80.5095 838.75 79.3155 838.394 78.1913C838.038 77.0672 837.423 76.0453 836.599 75.2104C835.776 74.3756 834.768 73.7519 833.659 73.391C832.55 73.0302 831.372 72.9426 830.223 73.1356H802.024V104.469H830.113C831.262 104.662 832.44 104.574 833.549 104.213C834.658 103.852 835.666 103.229 836.489 102.394C837.313 101.559 837.928 100.537 838.284 99.413C838.64 98.2889 838.726 97.0949 838.536 95.9301L838.646 81.6742Z"
                    fill="#E30613"
                />
                <path
                    d="M964.882 125.815C964.882 135.715 962.661 142.942 958.217 147.496C953.774 152.05 946.632 154.315 936.793 154.29H901.709C891.943 154.29 884.802 152.025 880.285 147.496C875.769 142.967 873.547 135.74 873.62 125.815V80.2635C873.62 70.3636 875.842 63.1367 880.285 58.5827C884.729 54.0288 891.87 51.826 901.709 51.9745H936.793C946.559 51.9745 953.7 54.2391 958.217 58.7683C962.734 63.2976 964.956 70.5245 964.882 80.4491V125.815ZM939.613 80.2635C939.804 79.0988 939.717 77.9048 939.361 76.7806C939.005 75.6565 938.39 74.6346 937.566 73.7997C936.743 72.9649 935.735 72.3411 934.626 71.9803C933.517 71.6194 932.339 71.5319 931.19 71.7249H907.312C906.163 71.5319 904.986 71.6194 903.877 71.9803C902.768 72.3411 901.76 72.9649 900.936 73.7997C900.113 74.6346 899.497 75.6565 899.141 76.7806C898.785 77.9048 898.699 79.0988 898.889 80.2635V125.815C898.699 126.98 898.785 128.174 899.141 129.298C899.497 130.423 900.113 131.444 900.936 132.279C901.76 133.114 902.768 133.738 903.877 134.099C904.986 134.46 906.163 134.547 907.312 134.354H931.19C932.339 134.547 933.517 134.46 934.626 134.099C935.735 133.738 936.743 133.114 937.566 132.279C938.39 131.444 939.005 130.423 939.361 129.298C939.717 128.174 939.804 126.98 939.613 125.815V80.2635Z"
                    fill="#E30613"
                />
                <path
                    d="M978.909 152.842V53.2368H1004.18V132.943H1039.3V53.2368H1064.57V132.943H1075.08V171.367H1051.05L1049.66 152.805L978.909 152.842Z"
                    fill="#E30613"
                />
                <path
                    d="M1164.22 152.842H1086.32V53.2368H1164.22V73.1356H1111.59V92.3661H1153.01V112.302H1111.59V132.943H1164.22V152.842Z"
                    fill="#E30613"
                />
                <path
                    d="M1261.08 152.842H1235.81V112.302H1200.73V152.842H1175.57V53.2368H1200.84V92.3661H1235.92V53.2368H1261.19L1261.08 152.842Z"
                    fill="#E30613"
                />
                <path
                    d="M1336.93 102.316L1364.28 152.842H1337.62L1315.83 112.302H1303.2V152.842H1278.11V53.2368H1303.38V92.3662H1316.01L1330.08 65.3394C1331.9 61.6441 1334.44 58.3607 1337.55 55.687C1341 53.0529 1345.25 51.738 1349.56 51.9745C1351.12 51.9745 1352.66 52.0488 1354.18 52.1973C1355.68 52.1973 1357.03 52.4943 1358.24 52.717L1362.2 53.4224V72.6159H1356.56C1355.21 72.6266 1353.92 73.1125 1352.89 73.9895C1351.86 74.7634 1351 75.7502 1350.37 76.8852L1336.93 102.316Z"
                    fill="#E30613"
                />
                <path
                    d="M1390.25 152.842H1362.89L1400.06 53.2368H1428.26L1465.47 152.842H1438.11L1431.78 135.059H1396.69L1390.25 152.842ZM1402.88 116.571H1425.37L1414.13 83.1592L1402.88 116.571Z"
                    fill="#E30613"
                />
            </g>
            <defs>
                <clipPath id="clip0_3_2">
                    <rect width="1520" height="204" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
