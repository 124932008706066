import { XMLParser } from 'fast-xml-parser';

export class XmlFileUtil {
    private readonly file: File;

    constructor(file: File) {
        this.file = file;
    }

    public async getJson(): Promise<object[] | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const xmlData = event.target?.result as string;

                const parser = new XMLParser({
                    attributeNamePrefix: '@',
                    textNodeName: 'text',
                    ignoreAttributes: false,
                    allowBooleanAttributes: false,
                    parseAttributeValue: false,
                    trimValues: true,
                });

                const jsonData = parser.parse(xmlData);

                resolve([jsonData]);
            };

            reader.onerror = (error) => {
                console.error(error);
                resolve(null);
            };

            reader.readAsText(this.file);
        });
    }
}
