import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';

type TypeIconBasketProps = TypeClassNames & {
    color?: any;
};

export const IconMarkdown = ({ classNames, color }: TypeIconBasketProps) => {
    return (
        <div className={cn(classNames)}>
            <svg width="12" height="13" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4336_8902)">
                    <path
                        d="M6.5 12C9.812 12 12.5 9.312 12.5 6C12.5 2.688 9.812 0 6.5 0C3.188 0 0.5 2.688 0.5 6C0.5 9.312 3.188 12 6.5 12ZM7.1 6H8.9L6.5 8.4L4.1 6H5.9V3.6H7.1V6Z"
                        fill={color}
                    />
                    <path d="M7.1 6H8.9L6.5 8.4L4.1 6H5.9V3.6H7.1V6Z" fill="white" />
                </g>
            </svg>
        </div>
    );
};
