import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconUpTableProps = TypeClassNames;

export const IconUpTable = ({ classNames }: TypeIconUpTableProps) => {
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.5202 4.75L16 0.400195L19.4798 4.75H17H16.75V5V16.75H15.25V5V4.75H15H12.5202ZM10.75 15.25V16.75H0.25V15.25H10.75ZM10.75 8.25V9.75H0.25V8.25H10.75ZM8.75 1.25V2.75H0.25V1.25H8.75Z"
                    fill={colors.epsilon}
                    stroke="white"
                    strokeWidth="0.5"
                />
            </svg>
        </div>
    );
};
