import { IconAuthLoading } from '@f_components/icons/IconAuthLoading';
import stls from '@f_styles/components/loadings/AuthLoading.module.sass';

export function AuthLoading() {
    return (
        <div className={stls.container}>
            <IconAuthLoading classNames={[stls.icon]} />
        </div>
    );
}
