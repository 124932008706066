import { action, makeObservable, observable, runInAction } from 'mobx';
import { AssistantModalControlStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/AssistantModalControl.store';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';
import { ChangeEvent } from 'react';
import { Alert } from '@f_general/alert';
import { TypeDataList } from '@f_crossdata/type';
import { CustomListInputPositionsModalStore } from '@f_qwep/components/purchasingAssistant/model/stores/modals/CustomListInputPositionsModal.store';
import { TFunction } from 'i18next';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { assistantApi } from '@f_qwep/components/purchasingAssistant/api/assistant.api';
import { TCustomDeals } from '@f_qwep/types/CustomDeals.type';
import { AlertStatusEnum } from '@f_qwep/components/purchasingAssistant/types/AlertStatusEnum.enum';
import { TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';
import { randomKey } from '@f_utils/randomKey';
import { FileUtil } from '@f_qwep/utils/file/file.util';
import { FileValidationComponentEnum } from '@f_qwep/types/file/validation/FileValidationComponent.enum';
import { PreviewTableStore } from '@f_qwep/general/previewTable/model/store/PreviewTable.store';

interface IHandleFetchCustomListActionsArgs {
    users: number[];
    workspaceApplication: number;
    t: TFunction<'translation', undefined>;
    getDeals: (status: AlertStatusEnum) => void;
    setAssistantCustomList: (lists: TCustomDeals[]) => void;
    setCurrentAssistantList: (currentList: TCustomDeals) => void;
    refKey: string;
    title: string;
}

interface IRequestBodyBaseCustomList {
    privateList: boolean;
    title: string;
    type: ConfigPanelModalType;
    users: number[];
    workspaceApplicationId: number;
    isDeficit: false;
}

interface IRequestBodyCreateCustomList extends IRequestBodyBaseCustomList {
    items: TypeDataList[];
}

interface IRequestBodyUpdateCustomList extends IRequestBodyBaseCustomList {
    refKey: string;
}

interface IRequestBodyAddPositionsCustomList extends IRequestBodyCreateCustomList, IRequestBodyUpdateCustomList {}

interface IHandleRequestArgs<T>
    extends Pick<
        IHandleFetchCustomListActionsArgs,
        'title' | 't' | 'setAssistantCustomList' | 'setCurrentAssistantList' | 'getDeals'
    > {
    reqBody: T;
}

interface IGetIsValidationException {
    email: string;
    userId: number;
}

export class CustomListModalStore extends AssistantModalControlStore {
    title: string = '';
    privateList: boolean = false;
    currentPositionLoadingOption: PositionLoadingOptionsEnum = PositionLoadingOptionsEnum.NULL;
    fileTitle: string = '';
    isFileLoading: boolean = false;
    fileJson: TypeDataList[] = [];
    inputPositionStore: CustomListInputPositionsModalStore;
    previewTableStore: PreviewTableStore;
    isButtonDisabled: boolean = false;
    isShowPreviewTable: boolean = false;

    constructor(
        customListInputPositionsModalStore: CustomListInputPositionsModalStore,
        previewTableStore: PreviewTableStore,
    ) {
        super();

        this.previewTableStore = previewTableStore;
        this.inputPositionStore = customListInputPositionsModalStore;
        makeObservable(this, {
            title: observable,
            privateList: observable,
            currentPositionLoadingOption: observable,
            fileTitle: observable,
            isFileLoading: observable,
            isButtonDisabled: observable,
            isShowPreviewTable: observable,
            setPrivateList: action.bound,
            setCurrentPositionLoadingOptions: action.bound,
            setTitle: action.bound,
            setFile: action.bound,
            handleFetchCustomListActions: action.bound,
            handleAddPositionInCustomList: action.bound,
            setIsShowPreviewTable: action.bound,
            handleBack: action.bound,
        });
    }

    setIsShowPreviewTable(bool: boolean) {
        this.isShowPreviewTable = bool;
    }

    setTitle(currentTitle: string) {
        this.title = currentTitle;
    }

    setPrivateList(privateList: boolean) {
        this.privateList = privateList;
    }

    setCurrentPositionLoadingOptions(option: PositionLoadingOptionsEnum) {
        this.currentPositionLoadingOption = option;
        this.setIsButtonDisabled(option === PositionLoadingOptionsEnum.FILE_INPUT);
        if (option === PositionLoadingOptionsEnum.MANUAL_INPUT) {
            this.fileTitle = '';
        }
    }

    async setFile(e: ChangeEvent<HTMLInputElement>, userInfo: any): Promise<void> {
        this.setIsFileLoading(true);
        if (e.target.files) {
            const file = e.target.files[0];
            const isValidationException = this.getIsValidationException({
                email: userInfo?.email,
                userId: userInfo.userId,
            });

            const { setHeaders, setTableData, resetStore } = this.previewTableStore;
            const fileUtil = new FileUtil(file, FileValidationComponentEnum.ASSISTANT, isValidationException);
            const { title, dataJSON, isValid, tableHeaders } = await fileUtil.getFileData();

            //нормализация для отправки json
            const items = dataJSON.slice(1).map((item) => ({
                article: item[0],
                brand: item[1],
            }));

            if (isValid) {
                this.setFileJson(items);
                this.setFileTitle(title);
                this.setIsButtonDisabled(false);
                setHeaders(tableHeaders);
                setTableData(dataJSON);
                this.setIsShowPreviewTable(false);
            } else {
                this.setFileJson([]);
                this.setFileTitle('');
                this.setIsButtonDisabled(true);
                resetStore();
            }
        }
        this.setIsFileLoading(false);
    }

    async handleFetchCustomListActions({
        users,
        workspaceApplication,
        t,
        getDeals,
        setCurrentAssistantList,
        setAssistantCustomList,
        refKey,
        title,
    }: IHandleFetchCustomListActionsArgs) {
        // Запрос на создание нового портрета
        if (this.currentTypeModal === ConfigPanelModalType.CREATE && this.validateCreateOrAddPositionList(t)) {
            const reqBody = this.getRequestBody<IRequestBodyCreateCustomList>({ users, workspaceApplication, refKey });
            return await this.handleRequest<IRequestBodyCreateCustomList>({
                t,
                getDeals,
                title,
                setAssistantCustomList,
                setCurrentAssistantList,
                reqBody,
            });
        }

        // Запрос на обновление портрета
        if (this.currentTypeModal === ConfigPanelModalType.UPDATE) {
            const reqBody = this.getRequestBody<IRequestBodyUpdateCustomList>({ users, workspaceApplication, refKey });
            return await this.handleRequest<IRequestBodyUpdateCustomList>({
                t,
                getDeals,
                title,
                setAssistantCustomList,
                setCurrentAssistantList,
                reqBody,
            });
        }

        // запрос на добавление позиций в список
        if (this.currentTypeModal === ConfigPanelModalType.ADD && this.validateCreateOrAddPositionList(t)) {
            const reqBody = this.getRequestBody<IRequestBodyAddPositionsCustomList>({
                users,
                workspaceApplication,
                refKey,
            });

            return await this.handleRequest<IRequestBodyAddPositionsCustomList>({
                t,
                getDeals,
                title,
                setAssistantCustomList,
                setCurrentAssistantList,
                reqBody,
            });
        }
    }

    handleClose = () => {
        this.closeModal();
        this.resetStates();
    };

    handleBack = () => {
        this.setIsShowPreviewTable(false);
    };

    private getIsValidationException({ userId, email }: IGetIsValidationException): boolean {
        return email === 'a.sergeev@tmtr.ru' || userId === 1;
    }

    private async handleRequest<T>({
        reqBody,
        t,
        title,
        setAssistantCustomList,
        setCurrentAssistantList,
        getDeals,
    }: IHandleRequestArgs<T>) {
        const data = await assistantApi.fetchCreateList<T>(reqBody);

        const messages = {
            create: {
                success: t('qwep.assistant.popUp.createSuccess', { title }),
                error: t('qwep.assistant.popUp.createError', { title }),
            },
            update: {
                success: t('qwep.assistant.popUp.updateSuccess'),
                error: t('qwep.assistant.popUp.updateError'),
            },
            add: {
                success: t('qwep.assistant.popUp.positionAddSuccess', { title }),
                error: t('qwep.assistant.popUp.positionAddError', { title }),
            },
            null: {
                success: '',
                error: '',
            },
        };

        if (data.isOk) {
            const property = data.result?.property ?? [];
            const refKey = data.result?.refKey ?? '';
            const currentList = property.find((list) => list.refKey === refKey);

            if (currentList) setCurrentAssistantList(currentList);
            setAssistantCustomList(property);
            getDeals(AlertStatusEnum.BASE);

            Alert({
                type: 'success',
                text: messages[this.currentTypeModal].success,
            });

            this.handleClose();

            return data;
        }

        Alert({
            type: 'error',
            text: messages[this.currentTypeModal].error,
        });

        return data;
    }

    private getRequestBody<T>({
        users,
        workspaceApplication,
        refKey,
    }: {
        users: number[];
        workspaceApplication: number;
        refKey: string;
    }): T {
        const reqBody: IRequestBodyBaseCustomList = {
            privateList: this.privateList,
            title: this.title,
            users: users,
            workspaceApplicationId: workspaceApplication,
            type: this.currentTypeModal,
            isDeficit: false,
        };

        switch (this.currentTypeModal) {
            case ConfigPanelModalType.CREATE:
            case ConfigPanelModalType.ADD:
                let items: TypeDataList[] = [];

                if (PositionLoadingOptionsEnum.MANUAL_INPUT === this.currentPositionLoadingOption) {
                    items = this.inputPositionStore.position.map((item) => ({
                        article: item.article,
                        brand: item.brand,
                    }));
                } else {
                    items = this.fileJson;
                }

                if (this.currentTypeModal === ConfigPanelModalType.CREATE) {
                    return { ...reqBody, items } as T;
                } else {
                    return { ...reqBody, items, refKey } as T;
                }
            case ConfigPanelModalType.UPDATE:
                return { ...reqBody, refKey } as T;
            default:
                throw new Error('Err');
        }
    }

    private validateCreateOrAddPositionList = (t: TFunction<'translation', undefined>): boolean => {
        const { position } = this.inputPositionStore;

        switch (this.currentPositionLoadingOption) {
            case PositionLoadingOptionsEnum.MANUAL_INPUT:
                if (position.some((item) => !item.article.trim() || !item.brand.trim())) {
                    Alert({
                        type: 'warning',
                        text: t('qwep.assistant.popUp.validationPositionFailed'),
                    });
                    return false;
                }
                return true;
            case PositionLoadingOptionsEnum.FILE_INPUT:
                if (!this.fileJson.length) {
                    Alert({
                        type: 'warning',
                        text: t('qwep.assistant.popUp.validationFileFailed'),
                    });
                    return false;
                }
                return true;
            default:
                Alert({
                    type: 'warning',
                    text: t('qwep.assistant.popUp.validationInputModeFailed'),
                });
                return false;
        }
    };

    handleAddPositionInCustomList({
        item,
        brand,
        article,
        setCurrentAssistantList,
        updateCustomList,
    }: {
        item: TypeAssistantCustomList;
        brand: string;
        article: string;
        setCurrentAssistantList: (item: TypeAssistantCustomList) => void;
        updateCustomList: () => void;
    }) {
        setCurrentAssistantList(item);
        this.setTitle(item.title);
        this.setCurrentTypeModal(ConfigPanelModalType.ADD);
        this.setCurrentPositionLoadingOptions(PositionLoadingOptionsEnum.MANUAL_INPUT);
        this.inputPositionStore.setPosition([
            {
                id: randomKey(),
                autoInput: true,
                brand: brand,
                article: article,
            },
        ]);
        updateCustomList();
    }

    private resetStates() {
        runInAction(() => {
            this.title = '';
            this.privateList = false;
            this.currentPositionLoadingOption = PositionLoadingOptionsEnum.NULL;
            this.fileTitle = '';
            this.isFileLoading = false;
            this.fileJson = [];
            this.inputPositionStore.resetPosition();
            this.isButtonDisabled = true;
            this.setIsShowPreviewTable(false);
        });
    }

    private setFileTitle(fileTitle: string) {
        this.fileTitle = fileTitle;
    }

    private setIsFileLoading(isLoading: boolean) {
        this.isFileLoading = isLoading;
    }

    private setFileJson(json: TypeDataList[]) {
        this.fileJson = json;
    }

    private setIsButtonDisabled(bool: boolean) {
        this.isButtonDisabled = bool;
    }
}
