import stls
    from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/ReportListModal/ReportListModal.module.sass';
import { t } from 'i18next';
import { TypeCollector } from '@f_ucs/context/collector_context';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { display } from '@mui/system';

export function ReportTooltip({ collector }: { collector: TypeCollector }) {

    const { colors } = useContext(ThemeContext);

    const tooltipSettings = (
        <div key={collector.name} className={stls.tooltip}>
            <p
                className={stls.titleTooltip}>
                {collector.name}
            </p>
            <p className={stls.subTitleTooltip}>Подробности</p>
            <div className={stls.tooltipContent}>
                <div
                    className={stls.infoTooltip}
                    style={{ borderColor: colors.sigma }}>
                    <p className={stls.textTooltip}>
                        <span>По наличию: </span>
                        <span>
                            {collector.sameQuantity
                                ? t('general.yes')
                                : t('general.no')}
                        </span>
                    </p>
                    <p className={stls.textTooltip}>
                        <span>По статусу: </span>
                        <span>
                            {collector.sameStatus
                                ? t('general.yes')
                                : t('general.no')}
                        </span>
                    </p>
                    <p className={stls.textTooltip}>
                        <span>По складу: </span>
                        <span>
                            {collector.sameWarehouse
                                ? t('general.yes')
                                : t('general.no')}
                        </span>
                    </p>
                </div>
                <div className={stls.usersTooltip}>
                    {collector.tokens.map((u) => {
                        return (
                            <p key={u.firstName}>
                                {u.firstName}
                            </p>
                        );
                    })}
                </div>
            </div>
        </div>
    );

    return (
        tooltipSettings
    );
}