import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconMenuProps = {
    id?: any;
    background: string;
} & TypeClassNames;

export const IconMenu = ({ classNames, background, id }: TypeIconMenuProps) => {
    const { colors, currentTheme } = useContext(ThemeContext);

    return (
        <div id={id} className={cn(classNames)} style={{ background: background }}>
            <svg viewBox="1 2 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.4375 25.3125H28.5625M15.4375 20.3125H28.5625M15.4375 15.3125H28.5625"
                    stroke={currentTheme === 'vinpin' ? 'white' : colors.alpha}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
