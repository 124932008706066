import { AssistantFiltersStore } from '@f_qwep/components/purchasingAssistant/model/stores/AssistantFilters.store';
import { makeAutoObservable } from 'mobx';
import { TDeals } from '@f_qwep/components/purchasingAssistant/types/Deals.type';
import { assistantApi } from '@f_qwep/components/purchasingAssistant/api/assistant.api';
import { Alert } from '@f_general/alert';
import { AlertStatusEnum } from '@f_qwep/components/purchasingAssistant/types/AlertStatusEnum.enum';

interface IHandlerFetchDealsArgs {
    users: number[];
    refKey: string;
    status: AlertStatusEnum;
}

export class AssistantDealsStore {
    filtersStore: AssistantFiltersStore;
    locations: string[];
    deals: TDeals[];
    isDataLoading: boolean;
    isDataLoaded: boolean;
    filteredDeals: TDeals[];
    isAllDealsIsTemplate: boolean = true;
    isHasAssistantData: boolean = false;

    constructor(filtersStore: AssistantFiltersStore) {
        this.filtersStore = filtersStore;
        this.locations = [];
        this.deals = [];
        this.isDataLoading = false;
        this.filteredDeals = [];
        this.isDataLoaded = false;
        makeAutoObservable(this, {}, { autoBind: true });
        document.addEventListener('resetStores', () => this.resetDeals());
    }

    setCurrentStoreData(deals: TDeals[]): void {
        [this.setLocations, this.setDeals].forEach((fc) => {
            fc(deals);
        });
    }

    setIsDataLoading(isDataLoading: boolean): void {
        this.isDataLoading = isDataLoading;
    }

    async filterDeals(): Promise<void> {
        if (!this.deals?.length) {
            return;
        }

        const { isActiveLow, isActiveMedium, isActiveHard, isVendorsForDating } = this.filtersStore;

        let filteredDeals = this.deals;
        filteredDeals = filteredDeals.filter((item) => item.foundItem && item);

        if (isActiveLow) {
            filteredDeals = filteredDeals.filter((item: TDeals) => Math.abs(+item.deviationPercentage) < 35 && item);
        }

        if (isActiveMedium) {
            filteredDeals = filteredDeals.filter(
                (item: TDeals) => Math.abs(+item.deviationPercentage) >= 35 && Math.abs(+item.deviationPercentage) < 45,
            );
        }
        if (isActiveHard) {
            filteredDeals = filteredDeals.filter((item: TDeals) => Math.abs(+item.deviationPercentage) >= 45 && item);
        }

        if (isVendorsForDating) {
            filteredDeals = filteredDeals.filter((item) => !item.vendorAuthorized && item);
        }

        this.filteredDeals = filteredDeals.sort((a: TDeals, b: TDeals) => {
            if (Math.abs(+a.deviationPercentage) > Math.abs(+b.deviationPercentage)) return -1;
            if (Math.abs(+a.deviationPercentage) < Math.abs(+b.deviationPercentage)) return 1;
            return 0;
        });
    }

    async handlerFetchDeals(args: IHandlerFetchDealsArgs): Promise<TDeals[]> {
        const { users, refKey, status } = args;
        this.setIsDataLoading(true);
        this.setIsDataLoaded(false);
        const data = await assistantApi.fetchDeals(users, refKey);
        const deals: TDeals[] = data?.result ?? [];

        this.setCurrentStoreData(deals);
        const statusNumber = data?.statusNumber ?? null;
        this.showErrorsAlert(data.isOk, status, statusNumber);
        this.isHasAssistantData = Boolean(statusNumber && statusNumber === 1);
        this.setIsDataLoading(false);
        this.setIsDataLoaded(true);

        return deals;
    }

    private showErrorsAlert(reqIsOk: boolean, status: AlertStatusEnum, statusNumber: number | null) {
        const updateError = 'Запрос на обновления списка завершился с ошибкой.\n Обратитесь в техническую поддержку.';
        const loadError = 'Запрос на получение списка завершился с ошибкой.\n Обратитесь в техническую поддержку.';

        if (!reqIsOk && !statusNumber) {
            Alert({
                type: 'error',
                text: status === AlertStatusEnum.BASE ? loadError : updateError,
            });
        }
    }

    private setLocations(deals: TDeals[]): void {
        if (deals) {
            const allWarehouses = deals.reduce((result: string[], deal) => {
                if (deal.warehouse) {
                    result.push(deal.warehouse);
                }
                return result;
            }, []);

            this.locations = Array.from(new Set(allWarehouses));
        } else {
            this.locations = [];
        }
    }

    private setDeals(deals: TDeals[]): void {
        this.deals = deals;
        this.setIsAllDealsIsTemplate(deals);
        this.filteredDeals = deals;
    }

    private setIsAllDealsIsTemplate(deals: TDeals[]) {
        this.isAllDealsIsTemplate = !deals.some((deal) => !deal.isTemplateItem);
    }

    private setIsDataLoaded(bool: boolean) {
        this.isDataLoaded = bool;
    }

    public resetDeals() {
        this.deals = [];
        this.filteredDeals = [];
    }
}
