import { ThemeContext } from '@f_context/Theme_context';
import {
    Avatar,
    CircularProgress,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    ListSubheader,
    Modal,
    Popper,
    TextField,
    Typography,
} from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { useSSR, useTranslation } from 'react-i18next';
import stls from '@f_styles/components/support/Chat.module.sass';
import { IconChatBot } from '@f_qwep/components/icons/IconChatBot';
import { IconChatWithSupport } from '@f_components/icons/IconChatWithSupport';
import { IconAttach } from '@f_components/icons/IconAttach';
import CancelIcon from '@mui/icons-material/Cancel';
import { ChatProperties } from '@f_types/enamChat';
import SendIcon from '@mui/icons-material/Send';
import cn from 'classnames';
import { SupportContext } from '@f_context/Support_context';
import { ImportantMessage } from '@f_general/ImportantMessage';
import { IconChatTariff } from '@f_components/icons/IconChatTariff';

export function Chat({
    chatId,
    currentChat,
    types,
    title,
    currentType,
    userInfo,
    messageInChat,
    message,
    setMessageInChat,
    setMessage,
    handleSendChatMessageSubmit,
    handleChange,
    handleChangeTitle,
    currentTitle,
    handleSendChatSubmit,
    handleFileChange,
    files,
    setFiles,
    loadingFile,
    styles,
    completed,
}: any) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const [loadingChat, setLoadingChat] = useState(false);
    const { supportTasks } = useContext(SupportContext);
    const deleteFileHandler = (url: any, index: number) => {
        const filesFilrer = files.filter((i: any, idx: number) => i.imagePreviewUrl !== url && idx !== index);
        setFiles(filesFilrer);
    };

    const saveFileHandler = (url: string) => {
        let fileName = `${new Date().toLocaleDateString('ru')}`;
        let link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        link.href = url;
        link.click();
        document.body.removeChild(link);
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoadingChat(true);
        if (chatId) {
            await handleSendChatMessageSubmit();
        } else {
            await handleSendChatSubmit();
        }
        setLoadingChat(false);
    };

    function createMarkup(text: string) {
        return { __html: text };
    }

    return (
        <>
            <div>
                <div>
                    {chatId ? (
                        <>
                            <div className={stls.title} style={{ background: colors.chi, height: '150px' }}>
                                <div style={{ display: 'flex', marginLeft: '10px', paddingTop: '5px' }}>
                                    <IconChatWithSupport />
                                </div>
                                <div>
                                    <p
                                        style={{
                                            fontSize: '20px',
                                            color: colors.omicron,
                                            marginLeft: '12px',
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                            display: 'flex',
                                        }}>
                                        {supportTasks[0].title}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: '14px',
                                            color: colors.omicron,
                                            marginLeft: '12px',
                                            width: '90%',
                                            borderBottom: '0.4px solid rgba(71, 85, 105, 1)',
                                            paddingBottom: '20px',
                                        }}>
                                        {'Чат с поддержкой QWEP, он виден только вам и поддержке QWEP.'}
                                    </p>
                                </div>
                            </div>

                            {currentChat?.length < 1 && (
                                <p className={stls.subtitle} style={{ color: colors.omicron }}>
                                    Пожалуйста, подождите. Сейчас мы работаем над вашей заявкой, но скоро мы ответим
                                    вам!
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <div style={{ display: 'flex', alignItems: 'start' }}>
                                <IconChatBot />
                                <div
                                    style={{
                                        width: '100%',
                                        marginLeft: '8px',
                                        borderRadius: '30px 12px 12px 0px',
                                        padding: '16px 28px',
                                        backgroundColor: colors.psi,
                                    }}>
                                    <p style={{ fontSize: '14px', fontFamily: 'Inter-Regular', color: colors.omicron }}>
                                        Здравствуйте! 👋
                                        <br />
                                        Какая информация вас интересует?
                                    </p>
                                </div>
                            </div>
                            <div className={stls.chipType}>
                                {types?.map((el: any) => (
                                    <p
                                        style={{
                                            background: colors.psi,
                                            color: currentTheme == 'dark' ? '#218EE1' : colors.alpha,
                                            fontSize: '14px',
                                        }}
                                        className={el.value == currentType ? stls.active : 'none'}
                                        onClick={() => handleChange(el.value)}>
                                        {el.smile} {el.key}
                                    </p>
                                ))}
                            </div>
                            <div className={stls.chipType}>
                                {title &&
                                    title[currentType]?.map((el: any) => (
                                        <p
                                            style={{
                                                background: colors.psi,
                                                color: currentTheme == 'dark' ? '#218EE1' : colors.alpha,
                                                fontSize: '14px',
                                            }}
                                            className={el.value == currentTitle ? stls.active : 'none'}
                                            onClick={() => handleChangeTitle(el.value)}>
                                            {el.smile} {el.key}
                                        </p>
                                    ))}
                            </div>
                        </>
                    )}

                    <div
                        className={stls.chatContent}
                        style={{
                            height: styles.chatHeight,
                            display: chatId ? 'block' : 'none',
                            background: currentTheme == 'light' ? colors.epsilonHover : colors.psi,
                        }}>
                        {currentChat?.map((el: any) => {
                            let name = el.realName.charAt(0).toUpperCase() + el.realName.slice(1);
                            let text = el.text;
                            let filesChat: string[] = [];

                            if (el.text.indexOf('/root/#file:') !== -1) {
                                text = '';
                                el.text.split(' ').map((item: string) => {
                                    if (item.indexOf('/root/#file:') !== -1) {
                                        let exe = decodeURI(item)
                                            .match(/\.([^.]+)$/)?.[0]
                                            .replace(/\%.*/, '');
                                        let url = decodeURI(item)
                                            .split(`${exe}`)[0]
                                            .replace('/root/#file:/user-data/', '');
                                        filesChat.push(`https://yougile.com/user-data/${url}${exe}`);
                                    } else {
                                        text += `${item} `;
                                    }
                                });
                            }

                            return (
                                <div
                                    className={stls.chatItem}
                                    style={{
                                        flexDirection:
                                            el.fromUserId == ChatProperties.AssistantId ? 'row-reverse' : 'row',
                                    }}>
                                    {el.fromUserId == ChatProperties.AssistantId ? (
                                        <Avatar
                                            className={stls.ava}
                                            alt={userInfo?.firstName}
                                            src={userInfo?.photo ? userInfo?.photo : '/'}
                                        />
                                    ) : (
                                        <Avatar className={stls.ava} alt={name} src={'/'} />
                                    )}
                                    <div
                                        className={stls.text}
                                        style={{
                                            background:
                                                el.fromUserId === ChatProperties.AssistantId ? '#001835' : colors.omega,
                                            borderRadius:
                                                el.fromUserId === ChatProperties.AssistantId
                                                    ? '12px 30px 0px 12px'
                                                    : '30px 12px 12px 0px',
                                        }}>
                                        <p
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color:
                                                    el.fromUserId === ChatProperties.AssistantId
                                                        ? 'white'
                                                        : colors.omicron,
                                            }}>
                                            {el.fromUserId === ChatProperties.AssistantId ? userInfo.firstName : name}
                                        </p>

                                        <p
                                            dangerouslySetInnerHTML={createMarkup(text)}
                                            style={{
                                                fontSize: '14px',
                                                color:
                                                    el.fromUserId === ChatProperties.AssistantId
                                                        ? 'white'
                                                        : colors.omicron,
                                            }}
                                        />

                                        {filesChat.length ? (
                                            filesChat.map((i: string, idx: number) => {
                                                if (
                                                    i.toLowerCase().includes('.png') ||
                                                    i.toLowerCase().includes('.jpg') ||
                                                    i.toLowerCase().includes('.jpeg') ||
                                                    i.toLowerCase().includes('.svg') ||
                                                    i.toLowerCase().includes('.gif')
                                                ) {
                                                    return (
                                                        <img
                                                            key={idx}
                                                            className={stls.chatPreview}
                                                            loading="lazy"
                                                            onClick={() => saveFileHandler(i)}
                                                            src={i}
                                                            alt="/1200x600wa.png"
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <IconButton
                                                            sx={{ color: colors.gamma }}
                                                            className={stls.chatFileDownload}
                                                            onClick={() => saveFileHandler(i)}
                                                            key={idx}>
                                                            <img className={stls.img} src={'./file.png'} />
                                                            <p style={{ color: colors.gamma }}>Файл для скачивания</p>
                                                        </IconButton>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {completed && (
                            <p className={stls.completed} style={{ color: colors.alphaOpacity }}>
                                Задача была завершена администратором
                            </p>
                        )}
                    </div>
                </div>

                <div className={cn(stls.formChat, completed && stls.hide)}>
                    <div className={stls.rowPreview}>
                        {files.map((item: any, idx: number) => (
                            <div key={item.imagePreviewUrl} className={stls.filesPreview}>
                                <img
                                    className={stls.img}
                                    src={`${item.imagePreviewUrl}`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    subtitle={item.title}
                                    sx={{ borderRadius: '12px', margin: '0 3px' }}
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                            aria-label={`info about ${item.title}`}></IconButton>
                                    }
                                />
                                <IconButton className={stls.deleteFile} onClick={() => deleteFileHandler(item, idx)}>
                                    <CancelIcon />
                                </IconButton>
                            </div>
                        ))}
                    </div>

                    <Typography className={stls.attach}>
                        {files.length ? (
                            <p style={{ background: colors.gamma }} className={stls.coutnFile}>
                                <span className={stls.count}> {files.length}</span>
                            </p>
                        ) : (
                            <></>
                        )}
                        <label htmlFor="fileSendSupport" className={stls.link}>
                            <IconAttach />
                            <input
                                id="fileSendSupport"
                                type="file"
                                disabled={loadingFile || loadingChat}
                                onChange={handleFileChange}
                            />
                            {loadingFile && (
                                <CircularProgress size={20} sx={{ marginLeft: '12px', marginBottom: '5px' }} />
                            )}
                        </label>
                    </Typography>
                    <form onSubmit={submitHandler}>
                        <TextField
                            sx={{
                                height: '50px !important',
                                '& .MuiInputBase-root': {
                                    background: colors.psi,
                                    borderRadius: '12px',
                                    padding: '0 30px',
                                },
                            }}
                            disabled={loadingChat}
                            placeholder="Напишите сообщение..."
                            value={chatId ? messageInChat : message}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                if (chatId) {
                                    setMessageInChat(e.target.value);
                                } else {
                                    setMessage(e.target.value);
                                }
                            }}
                        />
                        {loadingChat ? (
                            <CircularProgress size={20} className={stls.sendMessageLoading} />
                        ) : (
                            <IconButton disabled={loadingChat} className={stls.sendMessage} type="submit">
                                <SendIcon />
                            </IconButton>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
