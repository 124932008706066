import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconOrderProps = TypeClassNames;

export const IconOrder = ({ classNames }: TypeIconOrderProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <path
                    d="M3.5 0H14.5C14.6552 0 14.8084 0.036145 14.9472 0.105573C15.0861 0.175 15.2069 0.275804 15.3 0.4L18 4V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V4L2.7 0.4C2.79315 0.275804 2.91393 0.175 3.05279 0.105573C3.19164 0.036145 3.34475 0 3.5 0ZM16 6H2V18H16V6ZM15.5 4L14 2H4L2.5 4H15.5ZM6 8V10C6 10.7956 6.31607 11.5587 6.87868 12.1213C7.44129 12.6839 8.20435 13 9 13C9.79565 13 10.5587 12.6839 11.1213 12.1213C11.6839 11.5587 12 10.7956 12 10V8H14V10C14 11.3261 13.4732 12.5979 12.5355 13.5355C11.5979 14.4732 10.3261 15 9 15C7.67392 15 6.40215 14.4732 5.46447 13.5355C4.52678 12.5979 4 11.3261 4 10V8H6Z"
                    fill={colors.alpha}
                    transform="translate(14 13)"
                />
            </svg>
        </div>
    );
};
