import { FileValidator } from '@f_qwep/utils/file/validation/fileValidator.util';
import { ExcelFileUtil } from '@f_qwep/utils/file/exel/excelFile.util';
import { IFileValidatorOptions } from '@f_qwep/types/file/validation/FileValidation.interface';
import { FileExtension } from '@f_types/FileExtension.enum';
import { FileValidationComponentEnum } from '@f_qwep/types/file/validation/FileValidationComponent.enum';
import { fileValidationDefaultOptions } from '@f_qwep/utils/file/validation/fileValidation.config';
import { IGetFileUtilDataReturn } from '@f_qwep/types/file/FileUtil.interface';
import { CsvFileUtil } from '@f_qwep/utils/file/csv/csvFile.util';
import { XmlFileUtil } from '@f_qwep/utils/file/xml/xmlFile.util';

export class FileUtil {
    private readonly file: File;
    private readonly fileTitle: string | null;
    private readonly fileSize: number | null;
    private readonly validationOption: IFileValidatorOptions;
    private readonly tableHeaders: string[];
    private readonly isExceptionLimitJsonSize: boolean;
    private extension: FileExtension | null = null;
    private fileValidator: FileValidator;

    constructor(
        file: File,
        options: IFileValidatorOptions | FileValidationComponentEnum,
        isExceptionLimitJsonSize: boolean = false,
    ) {
        this.file = file;
        this.fileTitle = file?.name ?? null;
        this.fileSize = file?.size ?? null;
        this.isExceptionLimitJsonSize = isExceptionLimitJsonSize;

        if (typeof options === 'object') {
            this.validationOption = options;
            this.tableHeaders = options?.requiredHeaders ?? [];
        } else {
            this.validationOption = fileValidationDefaultOptions[options];
            this.tableHeaders = fileValidationDefaultOptions[options]?.requiredHeaders ?? [];
        }
        this.validationOption = typeof options === 'object' ? options : fileValidationDefaultOptions[options];
        this.determinateFileExtension();
        this.fileValidator = new FileValidator({
            options: this.validationOption,
            size: this.fileSize,
            extension: this.extension,
        });
    }

    public async getFileData(): Promise<IGetFileUtilDataReturn> {
        let isValid: boolean = false;
        let dataJson: object[] | null = null;
        const { isValid: isValidFile } = this.fileValidator.validateFile();

        if (isValidFile) {
            isValid = isValidFile;
            const data = await this.getFileJson();
            dataJson = data;
            const { isValid: isValidJson } = this.fileValidator.validateJson(data, this.isExceptionLimitJsonSize);
            isValid = isValidJson;
        }

        return {
            extension: this.extension,
            dataJSON: dataJson,
            title: this.fileTitle,
            size: this.fileSize,
            isValid: isValid,
            tableHeaders: this.tableHeaders,
        };
    }

    private async getFileJson(): Promise<object[] | null> {
        const getJson = {
            [FileExtension.XLSX]: async () => {
                const exelUtil = new ExcelFileUtil(this.file);
                return await exelUtil.getJson();
            },
            [FileExtension.XLS]: async () => {
                const exelUtil = new ExcelFileUtil(this.file);
                return await exelUtil.getJson();
            },
            [FileExtension.CSV]: async () => {
                const csvUtil = new CsvFileUtil(this.file);
                return await csvUtil.getJson();
            },
            [FileExtension.XML]: async () => {
                const xmlUtil = new XmlFileUtil(this.file);
                return await xmlUtil.getJson();
            },
        };

        if (!getJson[this.extension]) {
            return null;
        }

        return await getJson[this.extension]();
    }

    private determinateFileExtension() {
        const mimeType = this.file?.type;
        const mimeTypeToFileExtensionMap: { [mimeType: string]: FileExtension } = {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileExtension.XLSX,
            'application/vnd.ms-excel': FileExtension.XLS,
            'text/csv': FileExtension.CSV,
            'application/xml': FileExtension.XML,
            'text/xml': FileExtension.XML,
        };
        this.extension = mimeTypeToFileExtensionMap[mimeType] ?? null;
    }
}
