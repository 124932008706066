import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { IconList } from '../../icons/IconList';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';

export function HeadProducts() {
    return (
        <div className={stls.headProducts}>
            <IconList />
        </div>
    );
}

export function CellProducts({ info }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <div style={{ background: colors.deltaBg }} className={stls.cellProducts}>
            <p>{info.row.original.taskSize}</p>
        </div>
    );
}
