import { useContext, useEffect } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { QwepContext, TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';
import { useGetDeals } from '@f_qwep/components/purchasingAssistant/model/hooks/useGetDeals';
import { IStandardPayload } from '@f_qwep/types/StandardPayload.interface';
import { RootAssistantStore } from '@f_qwep/components/purchasingAssistant/model/stores/index.store';
import { useTranslation } from 'react-i18next';

interface IUseCurrentListActionsReturn {
    mutate: UseMutateFunction<IStandardPayload<TypeAssistantCustomList[]>, Error, void, unknown>;
}

interface IUseRemoveCustomListArgs {
    store: RootAssistantStore;
}

export const useRemoveCustomList = (args: IUseRemoveCustomListArgs): IUseCurrentListActionsReturn => {
    const { store } = args;
    const { t } = useTranslation();
    const { getUsersId, workspaceApplication } = useContext(QwepContext);
    const users: number[] = getUsersId();

    const { currentAssistantList, setCurrentAssistantListDefault } = store.assistantList;
    const { title, refKey } = currentAssistantList;
    const { handleRemoveCustomList } = store.assistantRemoveList;
    /*const { deals, isDataLoaded, isHasAssistantData } = store.assistantDeals;*/

    const { mutate: switchOnDefaultList } = useGetDeals(store);

    const { mutate } = useMutation<IStandardPayload<TypeAssistantCustomList[]>>({
        mutationFn: async () => {
            return await handleRemoveCustomList({
                users,
                refKey,
                workspaceApplication,
                title,
                t,
                switchOnDefaultList,
                setCurrentAssistantListDefault,
            });
        },
    });

    // Отключил (побочное действие для удаления списка если он пустой)
    //В новой версии реализую через модалку
    /*useEffect(() => {
        if (!deals.length && isDataLoaded && refKey !== '0' && !isHasAssistantData) {
            mutate();
        }
    }, [deals, isDataLoaded]);*/

    return { mutate };
};
