import { ChangeEvent, useContext } from 'react';
import { MyModal } from '@f_general/MyModal';
import {
    Button,
    CircularProgress,
    Modal,
    CardContent,
    TextField,
} from '@mui/material';
import stls
    from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/doughnut/tokenEditor.module.sass';
import { t } from 'i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { useCollector } from '@f_ucs/context/collector_context';
import { useDoughnut } from '@f_ucs/context/doughnut_context';
import { Alert } from '@f_general/alert';
import { IconCopy } from '@f_ucs/components/icons/IconCopy';


interface User {
    key: string;
    alias: string;
}

interface TokenEditorProps {
    isOpenEditor: boolean;
    editorHandleClose: () => void;
}

export function TokenEditor({ isOpenEditor, editorHandleClose }: TokenEditorProps) {
    const { colors } = useContext(ThemeContext);
    const { loading, currentCollector, updateNameToken } = useCollector();
    const { currentUsers, setCurrentUsers } = useDoughnut();


    const changeRowHandle = (key: string, alias: string) => {
        setCurrentUsers(
            currentUsers.map((el: User) => {
                if (el.key === key) {
                    return { ...el, alias: alias };
                }
                return el;
            }),
        );
    };

    const changeTokens = async () => {
        try {
            const data = {
                authCode: currentCollector.authorizationCode,
                tokens: currentUsers,
            };
            await updateNameToken(data);

            Alert({
                type: 'success',
                text: 'Имя токена успешно обновлено',
            });
        } catch (error) {
            console.error('Ошибка:', error);
            Alert({
                type: 'error',
                text: 'Ошибка',
            });
        } finally {
            editorHandleClose();
        }
    };

    const handleCopyTokens = async () => {
        try {
            const tokensAndAliases = currentUsers
                .map(user => `${user.key}\t${user.alias}`)
                .join('\n');
            await navigator.clipboard.writeText(tokensAndAliases);
            Alert({
                type: 'success',
                text: `Токены с псевдонимами скопированы в буфер обмена!`,
            });
        } catch (err) {
            Alert({
                type: 'error',
                text: `Не удалось скопировать токены с псевдонимами.`,
            });
        }
    };

    return (
        <Modal open={isOpenEditor} onClose={editorHandleClose}>
            <MyModal>
                <CardContent>
                    <div className={stls.title}>
                        Присвоить псевдонимы токенам пользователей
                    </div>
                    <div className={stls.rowContainer}>
                        {currentUsers.map((user: User, id: number) => (
                            <div key={id} className={stls.row}>
                                <TextField
                                    label={'Токен'}
                                    type="text"
                                    name="token"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{
                                        minWidth: '100px',
                                    }}
                                    value={user.key}
                                    focused
                                />
                                <TextField
                                    label={'Псевдоним'}
                                    type="text"
                                    name="alias"
                                    sx={{
                                        minWidth: '0px',
                                    }}
                                    value={user.alias}
                                    focused
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        changeRowHandle(user.key, e.target.value)
                                    }
                                />
                            </div>
                        ))}
                    </div>
                    <div className={stls.modalCopyButton}>
                        <Button onClick={handleCopyTokens}
                                sx={{
                                    background: colors.delta,
                                    color: colors.phi,
                                    '&:hover': { color: colors.alpha },
                                    minWidth: 35,
                                    width: '100%',
                                    height: 35
                                }}>
                            <IconCopy/> {'\u00A0'} Скопировать токены и псевдонимы{'\u00A0'}
                        </Button>
                    </div>
                    <div className={stls.btnContainer}>
                        <Button
                            className={stls.modalDeleteButton}
                            onClick={editorHandleClose}
                            size="large"
                            sx={{
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {t('qwep.settings.suppliers.canceled')}
                        </Button>
                        <Button
                            disabled={loading}
                            className={stls.modalButton}
                            size={'large'}
                            variant="contained"
                            type="submit"
                            onClick={changeTokens}
                        >
                            {t('general.confirm')}
                            {loading && (
                                <CircularProgress
                                    size={'25px'}
                                    sx={{ color: colors.omicron }}
                                />
                            )}
                        </Button>
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
}