import { action, makeObservable } from 'mobx';
import { FilterLeftTemplateStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/template/FilterLeftTemplate.store';
import { Alert } from '@f_general/alert';
import { Property } from '@f_qwep/types';
import { IStandardPayload } from '@f_qwep/types/StandardPayload.interface';
import {
    IReqBodyTemplate,
    IResponseResultTemplate,
} from '@f_qwep/components/filters/filterLeftPanel/types/template.interface';
import { IBaseTemplateFilter } from '@f_qwep/components/filters/filterLeftPanel/types/filters.interface';
import { changeFieldFilterStorage } from '@f_qwep/utils/changeFieldFilterStorage';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

// TODO не забыть добавить перевод для алертов

export class FilterLeftTemplateUpdateStore {
    filterTemplateStore: FilterLeftTemplateStore;

    constructor(filterLeftTemplateStore: FilterLeftTemplateStore) {
        this.filterTemplateStore = filterLeftTemplateStore;
        makeObservable(this, {
            handleTemplateUpdate: action.bound,
        });
    }

    async handleTemplateUpdate({ workspaceApplication, updateProperty, filter, filterType, setFilter }: any) {
        let response: IStandardPayload<IResponseResultTemplate> | null = null;

        const reqBody: IReqBodyTemplate | null = this.generateReqBody({
            workspaceApplication,
            id: this.filterTemplateStore.activeTemplateId,
        });

        if (reqBody) {
            response = await updateProperty(reqBody);

            if (response?.isOk) {
                Alert({
                    type: 'success',
                    text: `Шаблон успешно обновлен.`,
                });

                if (this.filterTemplateStore.currentFilter) {
                    const newFilter =
                        filterType === FilterTypesEnum.SEARCH_PRICER
                            ? changeFieldFilterStorage(filter, this.filterTemplateStore.currentFilter)
                            : this.filterTemplateStore.currentFilter;
                    setFilter(newFilter);
                }
            } else {
                Alert({
                    type: 'error',
                    text: `Ошибка сохранения шаблона.`,
                });
            }
        } else {
            Alert({
                type: 'error',
                text: `Ошибка обновления шаблона.`,
            });
        }
    }

    private generateReqBody = ({ workspaceApplication, id }: any): IReqBodyTemplate | null => {
        const { currentFilter, currentTemplateFilters } = this.filterTemplateStore;

        if (currentTemplateFilters.length && currentFilter) {
            const { found, rest } = currentTemplateFilters.reduce(
                (acc, curr) => {
                    curr.id === id ? (acc.found = curr) : acc.rest.push(curr);
                    return acc;
                },
                { found: {} as IBaseTemplateFilter, rest: [] as IBaseTemplateFilter[] },
            );

            if (found) {
                const reqBodyValue = [
                    ...rest,
                    {
                        filters: [
                            {
                                minPrice: currentFilter.minPrice,
                                maxPrice: currentFilter.maxPrice,
                                brands: currentFilter.brands,
                                minShipmentDays: currentFilter.minShipmentDays,
                                maxShipmentDays: currentFilter.maxShipmentDays,
                                vendorTitle: currentFilter.vendorTitle,
                                warehouses: currentFilter.warehouses,
                                minQuantity: currentFilter.minQuantity,
                                maxQuantity: currentFilter.maxQuantity,
                                inStock: currentFilter.inStock,
                                isDealer: currentFilter.isDealer,
                                approvedCross: currentFilter.approvedCross,
                                searchArticle: currentFilter.searchArticle,
                            },
                        ],
                        flag: found.flag,
                        name: found.name,
                        id: found.id,
                    },
                ];

                return {
                    applicationWorkspaceId: workspaceApplication,
                    propertyName: Property.DraftFilterSearch,
                    value: { value: reqBodyValue },
                };
            }

            return null;
        }

        return null;
    };
}
