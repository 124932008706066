//Бек для ConfigEmailListModal не готов
/*
import { ConfigEmailListModal } from './modals/ConfigEmailListModal';
*/

import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { observer } from 'mobx-react-lite';
import { ConfigPanelActions } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelActions/ConfigPanelActions';
import { ConfigPanelFilters } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelFilters/ConfigPanelFilters';
import { CustomListModal } from '@f_qwep/components/purchasingAssistant/ui/configPanel/modals/customListModal';

export const ConfigPanel = observer(() => {
    return (
        <div className={styles.panel}>
            <ConfigPanelActions />
            <ConfigPanelFilters />
            <CustomListModal />
            {/*<ConfigEmailListModal />*/}
        </div>
    );
});
