import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconListProps = TypeClassNames;

export const IconList = ({ classNames }: TypeIconListProps) => {
    const { currentTheme, colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM5 5V7H13V5H5ZM5 9V11H13V9H5ZM5 13V15H13V13H5Z"
                    fill={currentTheme === 'vinpin' ? 'white' : colors.phi}
                />
            </svg>
        </div>
    );
};
