import React, { FC, useEffect } from 'react';
import { Slider } from '@mui/material';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import { FilterLeftStore } from '@f_qwep/components/filters/filterLeftPanel/model/store/FilterLeft.store';

interface IFilterLeftShipmentDaysProps {
    filter: any;
    currentTheme: any;
    styles: any;
    t: TFunction<'translation', undefined>;
    colors: any
    store: FilterLeftStore;
    filtersReducer: any;
}

export const FilterLeftShipmentDays: FC<IFilterLeftShipmentDaysProps> = observer(({styles, t, filter, colors, currentTheme, store, filtersReducer}) => {
    const { minShipmentDays, maxShipmentDays, setMinShipmentDays, setMaxShipmentDays, handleChangeShipmentDays } = store;

    const sliderMinCount = 0;
    const sliderMaxCount = 30;

    useEffect(() => {
        filtersReducer({ type: 'minShipmentDays_input', payload: { minDays: minShipmentDays } });
    }, [minShipmentDays]);

    useEffect(() => {
        filtersReducer({ type: 'maxShipmentDays_input', payload: { maxDays: maxShipmentDays } });
    }, [maxShipmentDays]);

    return (
        <>
            <div className={styles.listItemNav}>
                <h5 className={styles.subtitle}>{t('qwep.search.deliveryTimeFilter')}</h5>
            </div>
            <div id="searchDelivery" className={styles.listItemCheck}>
                <Slider
                    className={styles.slider}
                    size="small"
                    value={[filter?.minShipmentDays ?? 0, filter?.maxShipmentDays ?? 30]}
                    min={sliderMinCount}
                    max={sliderMaxCount}
                    onChange={(_, value) => handleChangeShipmentDays(value)}
                />
                <div className={styles.listItemDiv}>
                    <input
                        style={{ color: colors.omicron }}
                        placeholder={`${t('qwep.search.from')}`}
                        className={currentTheme === 'vinpin' ? styles.textvinpin : styles.text}
                        value={filter?.minShipmentDays}
                        onChange={setMinShipmentDays}
                        type="number"
                        min={sliderMinCount}
                    />
                    <input
                        style={{ color: colors.omicron }}
                        placeholder={`${t('qwep.search.to')}`}
                        className={currentTheme === 'vinpin' ? styles.textvinpin : styles.text}
                        value={filter?.maxShipmentDays > 0 ? filter?.maxShipmentDays : ''}
                        onChange={setMaxShipmentDays}
                        type="number"
                        min={sliderMinCount}
                    />
                </div>
            </div>
        </>
    );
});