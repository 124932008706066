import { TypeActivityReport } from '@f_ucs/context/report_context';
import { TypeWebUser, Type1cUser } from '@f_ucs/context/doughnut_context';

export const mergeActivityReports = (activities: TypeActivityReport[], type: 'web' | '1c'): TypeActivityReport[] => {
    const mergedActivities: { [key: string]: TypeActivityReport } = {};

    activities.forEach((activity, index) => {
        if (!activity.user) return;

        let userKey: string | number | undefined;

        if (type === 'web') {
            const user = activity.user as TypeWebUser;
            userKey = user.userId;
        } else if (type === '1c') {
            const user = activity.user as Type1cUser;
            userKey = user.key;
        }

        if (!userKey) return;

        if (mergedActivities[userKey]) {
            mergedActivities[userKey].addBasket += activity.addBasket;
            mergedActivities[userKey].orders += activity.orders;
            mergedActivities[userKey].searches += activity.searches;
        } else {
            mergedActivities[userKey] = { ...activity };
        }
    });

    return Object.values(mergedActivities);
};
