import { useContext, useRef } from 'react';
import { Button, ButtonGroup, ClickAwayListener, Grow, List, Popper } from '@mui/material';
import { IconFilter } from '../icons/IconFilter';
import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTasks.module.sass';
import { SearchContext } from '@f_qwep/context/Search_context';
import { FilterLeft } from '@f_qwep/components/filters/filterLeftPanel/ui';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

export function MobileButtonSearchResult({ items, filter, filtersReducer }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const { open, setOpen } = useContext(SearchContext);

    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen: any) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };

    const getSumFilters = () => {
        let count = 0;
        for (let key in filter) {
            if (Array.isArray(filter[key])) {
                count += filter[key].length;
            } else if (filter[key]) {
                count++;
            } else if (typeof filter[key] == 'number') {
                count++;
            }
        }
        return count;
    };

    return (
        <ButtonGroup variant="outlined" ref={anchorRef}>
            <Button
                id="buttonFilters"
                sx={{ fontSize: '14px', marginLeft: '10px' }}
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleToggle}>
                {t('qwep.orders.filters')}
                <IconFilter />
                {getSumFilters() !== 0 && (
                    <sub style={{ color: colors.gamma, marginTop: '-15px' }}>{getSumFilters()}</sub>
                )}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition className={stls.popperList}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List sx={{ background: colors.psi }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <FilterLeft
                                    items={items}
                                    filter={filter}
                                    filtersReducer={filtersReducer}
                                    type={FilterTypesEnum.SEARCH_PRICER}
                                />
                            </ClickAwayListener>
                        </List>
                    </Grow>
                )}
            </Popper>
        </ButtonGroup>
    );
}
