import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconStartJobProps = TypeClassNames;

export const IconStartJob = ({ classNames }: TypeIconStartJobProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <path
                    d="M28.002 32.0001C27.3404 33.5824 26.0823 34.8405 24.5 35.5021C22.9177 34.8405 21.6596 33.5824 20.998 32.0001H23.258C23.584 32.4891 24.005 32.9121 24.5 33.2431C24.995 32.9131 25.416 32.4891 25.743 32.0001H28.002ZM30.5 26.8051L32.5 29.0731V31.0001H16.5V29.0731L18.5 26.8051V21.0001C18.5 17.5171 21.004 14.5531 24.5 13.4551C27.996 14.5531 30.5 17.5171 30.5 21.0001V26.8051ZM29.77 29.0001L28.5 27.5601V21.0001C28.5 18.6821 26.93 16.5701 24.5 15.5801C22.07 16.5701 20.5 18.6811 20.5 21.0001V27.5601L19.23 29.0001H29.77ZM24.5 23.0001C23.9696 23.0001 23.4609 22.7894 23.0858 22.4143C22.7107 22.0392 22.5 21.5305 22.5 21.0001C22.5 20.4696 22.7107 19.9609 23.0858 19.5859C23.4609 19.2108 23.9696 19.0001 24.5 19.0001C25.0304 19.0001 25.5391 19.2108 25.9142 19.5859C26.2893 19.9609 26.5 20.4696 26.5 21.0001C26.5 21.5305 26.2893 22.0392 25.9142 22.4143C25.5391 22.7894 25.0304 23.0001 24.5 23.0001Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
