import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import cn from 'classnames';
import { CrossVoteButton } from '@f_qwep/components/tables/crossVoteButton/CrossVoteButton';

export function CellArticle({ info, setItemsTable }: any) {
    const { colors } = useContext(ThemeContext);

    return (
        <>
            {info.row.original.isCross ? (
                <div
                    style={{ color: colors.alpha, display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                    className={cn(stls.cellPin)}>
                    <CrossVoteButton
                        approvedVoted={info.row.original?.approvedVoted}
                        articleCross={info.row.original.article}
                        brandCross={info.row.original.brand}
                        colors={colors}
                        setItemsTable={setItemsTable}
                        itemId={info.row.original.itemId}
                    />
                    <div>{info.row.original.article}</div>
                </div>
            ) : (
                <div
                    style={{ color: colors.alpha, display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                    className={cn(stls.cellPin)}>
                    <div>{info.row.original.article}</div>
                </div>
            )}
        </>
    );
}
