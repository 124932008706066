import stls from '@f_styles/components/drawer/DrawerList.module.sass';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import { ListSubheader, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { UserContext } from '@f_context/User_context';
import { useTranslation } from 'react-i18next';
import { GeneralContext } from '@f_context/General_context';
import { ApplicationList } from './ApplicationList';
import { CompanyList } from './CompanyList';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Role } from '@f_types/TypeRole';
import { SupportContext } from '@f_context/Support_context';

export function DrawerList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);
    const { countNewMessage } = useContext(SupportContext);
    const {
        drawerPanels,
        currentPanel,
        setCurrentPanel,
        changeCompany,
        drawerWorkspaces,
        currentDrawerWorkspace,
        setActive,
        active,
    } = useContext(GeneralContext);

    const itemsSettings: any = [
        {
            index: 1,
            title: 'Лицензии и оплата',
            sysName: 'licenses',
            url: '/licenses',
            icon: <AssignmentSharpIcon fontSize={'small'} />,
        },
        {
            index: 2,
            title: 'Тех. поддержка',
            sysName: 'support',
            url: '/support',
            icon: <HelpRoundedIcon fontSize={'small'} />,
        },
    ];

    const navigatinHandler = (url: string, panelName?: string) => {
        setActive(false);
        navigate(url);
        panelName ? setCurrentPanel(panelName) : setCurrentPanel('');
    };

    return (
        <div id="drawerList" className={stls.container}>
            {currentDrawerWorkspace && active && (
                <>
                    <div className={stls.listItemBox} onKeyDown={() => setActive(false)}>
                        <div className={stls.avaPanel}>
                            <div className={stls.ava}>
                                <Avatar
                                    alt={userInfo?.firstName}
                                    src={userInfo?.photo ? userInfo?.photo : '/static/images/avatar/1.jpg'}
                                    className={stls.avatar}
                                />
                                <div>
                                    <p className={stls.name} style={{ color: colors.omicron }}>
                                        {`${userInfo?.firstName} ${userInfo.lastName && userInfo?.lastName[0]}.`}
                                    </p>
                                    <p className={stls.company}>{currentDrawerWorkspace?.workspaceName}</p>
                                </div>
                            </div>

                            <button className={stls.buttonSettings} onClick={() => navigatinHandler('/settings')}>
                                <SettingsIcon fontSize={'small'} />
                            </button>
                        </div>
                        <ApplicationList
                            navigatinHandler={navigatinHandler}
                            panels={drawerPanels}
                            currentPanel={currentPanel}
                            setCurentPanel={setCurrentPanel}
                        />
                        <CompanyList
                            navigatinHandler={navigatinHandler}
                            panels={drawerWorkspaces}
                            currentCompany={currentDrawerWorkspace}
                            currentPanel={currentPanel}
                            changeCompany={changeCompany}
                        />
                    </div>
                    <List subheader={<ListSubheader className={stls.title}>{t('drawer.general')}</ListSubheader>}>
                        {itemsSettings.map((item: any) => (
                            <ListItem
                                key={item.index}
                                className={stls.listItem}
                                selected={item.sysName === currentPanel}
                                disablePadding>
                                <ListItemButton
                                    className={stls.listItemButton}
                                    onClick={() => navigatinHandler(item.url, item.sysName)}>
                                    <ListItemIcon className={stls.btnIcon} sx={{ minWidth: 40 }}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <Typography variant="body2">{item.title}</Typography>
                                    {item.index === 2 && countNewMessage !== 0 && (
                                        <p style={{ color: colors.lambda }} className={stls.countNewMessage}>
                                            +{countNewMessage}
                                        </p>
                                    )}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        </div>
    );
}
