import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconModalEditProps = TypeClassNames;

export const IconModalEdit = ({ classNames }: TypeIconModalEditProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <path
                    d="M27.728 21.686L26.314 20.272L17 29.586V31H18.414L27.728 21.686ZM29.142 20.272L30.556 18.858L29.142 17.444L27.728 18.858L29.142 20.272ZM19.242 33H15V28.757L28.435 15.322C28.6225 15.1345 28.8768 15.0292 29.142 15.0292C29.4072 15.0292 29.6615 15.1345 29.849 15.322L32.678 18.151C32.8655 18.3385 32.9708 18.5928 32.9708 18.858C32.9708 19.1232 32.8655 19.3775 32.678 19.565L19.243 33H19.242Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
