import { Card, Radio } from '@mui/material';
import React from 'react';
import stls from '@f_styles/components/settings/Settings.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import SearchIcon from '@mui/icons-material/Search';
import { GeneralContext } from '@f_context/General_context';

type TypeInterfaceProps = {
    style: string;
};

export function InterfaceTheme({ style }: TypeInterfaceProps) {
    const { Search, SearchIconWrapper, StyledInputBase } = React.useContext(ThemeContext);
    const { colorTheme } = React.useContext(GeneralContext);
    const { colors } = React.useContext(ThemeContext);

    const controlProps = (item: string) => ({
        checked: colorTheme === item,
        value: item,
    });

    return (
        <div>
            <Card
                className={stls.cardTheme}
                sx={{
                    backgroundColor: style == 'dark' ? '#171821' : 'white',
                    boxShadow:
                        style == 'light'
                            ? '0px 4.82353px 24.1177px rgba(156, 172, 185, 0.390953), 0px 2.00345px 10.0172px rgba(156, 172, 185, 0.3), 0px 0.724608px 3.62304px rgba(156, 172, 185, 0.209047)'
                            : '',
                }}>
                {style === 'light' ? (
                    <Radio className={stls.radio} {...controlProps('light')} />
                ) : style === 'dark' ? (
                    <Radio className={stls.radio} {...controlProps('dark')} />
                ) : style === 'oneC' ? (
                    <Radio className={stls.radio} {...controlProps('oneC')} />
                ) : null}
                <Search
                    className={stls.search}
                    disabled
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border:
                            style == 'oneC'
                                ? `1px solid #50525b`
                                : style == 'dark'
                                ? `1px solid #3B82F6`
                                : `1px solid #218EE1`,
                        '& .MuiSvgIcon-root': {
                            color: style == 'oneC' ? `#50525b` : '#218EE1',
                        },
                    }}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase disabled placeholder="Найти" />
                </Search>
                <div className={stls.divCard}>
                    <Card
                        className={stls.smallCard}
                        sx={{ backgroundColor: style == 'oneC' ? '#ffdd00' : style == 'dark' ? '#3B82F6' : '#218EE1' }}
                    />
                    <Card
                        className={stls.smallCard}
                        sx={{
                            backgroundColor:
                                style == 'light'
                                    ? '#E5F1FF'
                                    : style == 'dark'
                                    ? '#21222D'
                                    : style == 'oneC'
                                    ? '#ffe8a6'
                                    : null,
                        }}
                    />
                    <Card className={stls.smallCard} sx={{ backgroundColor: style == 'dark' ? '#21222D' : 'white' }}>
                        <div
                            className={stls.cardItem}
                            style={{
                                backgroundColor: style == 'light' ? '#EDF0F5' : style == 'dark' ? '#EDF0F5' : '#E5E5E5',
                            }}></div>
                    </Card>
                </div>
            </Card>
        </div>
    );
}
