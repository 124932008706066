import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { useWindowSize } from '@f_hooks';
import { useContext } from 'react';
import { Orders } from './Orders';
import { OrdersContext } from '@f_qwep/context/Orders_context';
import { LoadingSettings } from '../loaders/LoadingSettings';
import OrderCardResult from './OrderCardResult';



export function IndexOrders() {
    const { isLoading } = useContext(OrdersContext);

   

    return (
        <LayoutPage>
            {isLoading ? <LoadingSettings id="ordersLoading" /> : <Orders />}
            <OrderCardResult />
        </LayoutPage>
    );
}
