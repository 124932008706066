import { TypeCollector, useCollector } from '@f_ucs/context/collector_context';
import { IconList } from '@f_ucs/components/icons/iconList';
import stls from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/ReportListModal/ReportListModal.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import {
    ReportTooltip,
} from '@f_ucs/components/actionPanel/actionButtonHandlers/ReportListModal/ReportTooltip';
import { Tooltip } from '@mui/material';
import { IconClose } from '@f_components/icons/IconClose';


export function ReportItem({ close, collector }: { collector: TypeCollector, close: any }) {

    const { colors } = useContext(ThemeContext);
    const {
        getCollectorReport,
        deleteCollector,
        currentCollector,
        setCurrentCollector,
        currentType,
        setCurrentCollectorWeb,
        setCurrentCollector1c,
    } = useCollector();

    return (
        <div className={stls.itemContainer}>
            <Tooltip
                title={
                    <ReportTooltip collector={collector} />
                }
                placement="right-start"
            >
                <div
                    className={stls.item}>
                    <IconList />
                    <button
                        onClick={() => {
                            getCollectorReport(
                                collector.name,
                                collector.authorizationCode,
                                collector.type,
                            );
                            if (
                                currentCollector.authorizationCode !==
                                collector.authorizationCode ||
                                currentCollector.name !== collector.name
                            ) {
                                setCurrentCollector(collector);
                                if (currentType === 'web') {
                                    setCurrentCollectorWeb(collector);
                                }
                                if (currentType === '1c') {
                                    setCurrentCollector1c(collector);

                                }
                            }
                            close(false);
                        }}
                        className={stls.itemBtn}
                        style={{
                            color: colors.omicron,
                            background: colors.delta,
                        }}>
                        {collector.name}
                    </button>
                </div>
            </Tooltip>
            <Tooltip title="Удалить коллектор">
                <button
                    onClick={() =>
                        deleteCollector(
                            collector.name,
                            collector.authorizationCode,
                        )
                    }>
                    <IconClose />
                </button>
            </Tooltip>
        </div>
    );
}