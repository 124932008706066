import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/home/Home.module.sass';
import { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { LayoutPage } from '@f_crossdata/general/LayoutPage';
import { TableCross } from './tableCross';
import { CircularProgress } from '@mui/material';
import { ListItems } from './ListItems';
import { ConfigReportContext } from '@f_crossdata/context/ConfigReport_context';
import { SettingsPanel } from './settingsListPanel/SettingsPanel';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { Navbar } from '../navbar';

export function Home() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { appInfo } = useContext(CrossdataContext);
    const { getCrossPartname, crossesPartname, currentArticleBrand } = useContext(ConfigReportContext);
    const [loading, setLoading] = useState(false);

    const getCrossHandler = async (brand: string, article: string, show: boolean) => {
        setLoading(true);
        await getCrossPartname(article, brand, show);
        setLoading(false);
    };

    return (
        <LayoutPage classNames={stls.container}>
            <div className={stls.menu}>
                <div className={stls.nav}>
                    <Navbar />
                    <div className={stls.panel}></div>
                </div>
                <ListItems getCrossHandler={getCrossHandler} />
            </div>

            <div className={stls.content}>
                {crossesPartname.length ? (
                    loading ? (
                        <CircularProgress />
                    ) : (
                        <div className={stls.table}>
                            <TableCross data={crossesPartname} currentArticleBrand={currentArticleBrand} />
                        </div>
                    )
                ) : (
                    appInfo?.tariff?.workWithLists && <SettingsPanel />
                )}
            </div>
        </LayoutPage>
    );
}
