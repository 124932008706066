import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconResultsProps = TypeClassNames;

export const IconResults = ({ classNames }: TypeIconResultsProps) => {
    return (
        <div className={cn(stls.iconHover, classNames)}>
            <svg width="27" height="27" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2372 2.17678L10.3104 2.25H10.414H19C19.1989 2.25 19.3897 2.32902 19.5303 2.46967C19.671 2.61032 19.75 2.80109 19.75 3V17C19.75 17.1989 19.671 17.3897 19.5303 17.5303C19.3897 17.671 19.1989 17.75 19 17.75H1C0.801087 17.75 0.610322 17.671 0.46967 17.5303C0.329018 17.3897 0.25 17.1989 0.25 17V1C0.25 0.801088 0.329018 0.610322 0.46967 0.46967C0.610322 0.329018 0.801088 0.25 1 0.25H8.31045L10.2372 2.17678ZM2 1.75H1.75V2V16V16.25H2H18H18.25V16V4V3.75H18H9.68955L7.76278 1.82322L7.68955 1.75H7.586H2ZM9.25 6.25H10.75V13.75H9.25V6.25ZM13.25 9.25H14.75V13.75H13.25V9.25ZM5.25 11.25H6.75V13.75H5.25V11.25Z"
                    strokeWidth="0.5"
                />
            </svg>
        </div>
    );
};
