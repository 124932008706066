import { Grid, Box } from '@mui/material';
import { CellArticle } from './CellArticle';
import { CellManufacturer } from './CellManufacturer';
import { CellTitle } from './CellTitle';
import { CellTotal } from './CellTotal';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { CellQuantity } from './CellQuantity';
import { CellPrice } from './CellPrice';
import { CellWarehouse } from './CellWarehouse';
import { CellComment } from './CellComment';
import { CellDelete } from './CellDelete';
import { useTranslation } from 'react-i18next';

export function CellMobile({ info, accountId, basketId, hasPartOrder, handleOpenDelete, basketForm }: any) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', padding: '12px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CellManufacturer
                    info={info}
                    accountId={accountId}
                    basketId={basketId}
                    hasPartOrder={hasPartOrder}
                    basketForm={basketForm}
                />
                <div style={{ display: 'flex' }}>
                    <CellComment info={info} />
                    <CellDelete info={info} handleOpenDelete={handleOpenDelete} />
                </div>
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid
                    item
                    xs={5}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}>
                    <div>
                        <p className={stls.title}>{t('qwep.basket.name')}:</p>
                        <CellTitle info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.basket.quantity')}:</p>
                        <CellQuantity info={info} accountId={accountId} basketId={basketId} />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}>
                    <div>
                        <p className={stls.title}>{t('qwep.basket.article')}:</p>
                        <CellArticle info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.basket.warehouse')}:</p>
                        <CellWarehouse info={info} />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}>
                    <div>
                        <p className={stls.title}>{t('qwep.basket.price')}:</p>
                        <CellPrice info={info} />
                    </div>
                    <div>
                        <div>
                            <p className={stls.title}>{t('qwep.basket.total')}:</p>
                            <CellTotal info={info} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
