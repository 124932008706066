import React, { ChangeEvent } from 'react';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { observer } from 'mobx-react-lite';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';

export const CustomListModalName = observer(() => {
    const store = useAssistantStore();
    const { title, setTitle, currentTypeModal, setPrivateList, privateList } = store.assistantModalList;

    return currentTypeModal === ConfigPanelModalType.CREATE || currentTypeModal === ConfigPanelModalType.UPDATE ? (
        <>
            <TextField
                type="text"
                name="title"
                placeholder="Введите название списка"
                required
                value={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
            <FormControlLabel
                className={styles.privateCheck}
                control={
                    <Checkbox
                        checked={privateList}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPrivateList(e.target.checked)}
                    />
                }
                label={'Виден только вам'}
            />
        </>
    ) : null;
});
