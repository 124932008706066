import stls from '@f_ucs/styles/components/versionPanel/versionPanel.module.sass';
import { Button, CircularProgress } from '@mui/material';
import { IconWeb } from '@f_ucs/components/icons/IconWeb';
import { Icon1c } from '@f_components/icons/Icon1c';
import { useContext } from 'react';
import { useCollector, TypeCurrentType } from '@f_ucs/context/collector_context';
import { ThemeContext } from '@f_context/Theme_context';

export function VersionPanel() {
    const { colors, currentTheme } = useContext(ThemeContext);
    const {
        setCurrentType,
        currentType,
        loading,
        changeCurrentCollector,
    } = useCollector();

    const changeCurrentTypeHandler = (type: TypeCurrentType) => {
        setCurrentType(type)
        changeCurrentCollector(type)
    }

    return (
        <>
            <div className={stls.menu} style={{ background: colors.beta, marginBottom: '20px' }}>
                <Button
                    variant={currentType === 'web' ? 'contained' : 'text'}
                    className={stls.btn}
                    style={{
                        color:  currentType === '1c' ? 'rgba(33, 142, 225, 1)' : colors.E5F1FF,
                    }}
                    onClick={() => changeCurrentTypeHandler('web')}
                    disabled={loading}
                >
                    <IconWeb
                        classNames={[stls.icon]}
                        color={currentType === '1c' ? 'rgba(33, 142, 225, 1)' : colors.E5F1FF}
                    />
                    WEB-версия QWEP
                </Button>
                <Button
                    variant={currentType === '1c' ? 'contained' : 'text'}
                    className={stls.btn}
                    style={{
                        color:  currentType === 'web' ? 'rgba(33, 142, 225, 1)' : colors.E5F1FF,
                    }}
                    onClick={() => changeCurrentTypeHandler('1c')}
                    disabled={loading}
                >
                    <Icon1c
                        classNames={[stls.icon]}
                        color={ currentType === 'web' ? 'rgba(33, 142, 225, 1)' : colors.E5F1FF}
                    />{' '}
                    1C-версия QWEP
                </Button>
            </div>
        </>
    );

}