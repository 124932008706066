import { createContext, useContext, useEffect, useState } from 'react';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { AuthContext } from './Auth_context';
import { TypePoles } from '@f_types';
import { WorkspaceContext } from './Workspace_context';

type TypeUserContext = {
    userInfo: any;
    userRole: TypePoles;
    updateUser: any;
    users: any;
    addUser: any;
    addExistingUser: any;
    deleteUser: any;
    updateUserInCompany: any;
    getUsersToWorkspace: any;
    setUserRole: any;
    findUser: any;
};

type TypeAddUser = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    position: string;
    role: string;
    notMainWorkspaceId: any;
};

type TypeAddExistingUser = {
    email: string;
    role: string;
    notMainWorkspaceId: any;
};

type TypeUpdateUserInCompany = {
    userId: number;
    workspaceId: number;
    role: {
        sysName: string;
        roleValueId: number;
    };
    applications: {
        applicationId: number;
        isActive: boolean;
    }[];
};

type TypeDeleteUser = {
    userId: number;
    workspaceId: number;
    onlyWorkspace: boolean;
};

const UserContext = createContext<TypeUserContext>({
    userInfo: null,
    userRole: [],
    updateUser: null,
    users: [],
    addUser: null,
    addExistingUser: null,
    deleteUser: null,
    updateUserInCompany: null,
    getUsersToWorkspace: null,
    setUserRole: null,
    findUser: null,
});

function UserProvider(props: any) {
    const { checkAuth, accountInfo } = useContext(AuthContext);
    const [userInfo, setUserInfo] = useState({});
    const [userRole, setUserRole] = useState<TypePoles>([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (checkAuth && accountInfo?.user) {
            setUserInfo(accountInfo.user);
            setUserRole(accountInfo.role);
            getUsersToWorkspace();
        }
    }, [checkAuth]);

    const getUsersToWorkspace = async (workspaceId?: number) => {
        const result = await callPlatformAPI(`user/get/all/${workspaceId}`, 'get');
        if (result.data.isOk) {
            setUsers(result.data.result);
        }
    };

    const findUser = async (email: string, workspaceId: number) => {
        console.log('findUser', email);
        const result = await callPlatformAPI(`user/find/${email}/${workspaceId}`, 'get');
        console.log('findUser', result);
        return result.data;
    };

    const addUser = async (data: TypeAddUser) => {
        const result = await callPlatformAPI('user/add', 'post', data);
        console.log('addUser', result);
        if (result.data.isOk) {
            if (data.notMainWorkspaceId) {
                getUsersToWorkspace();
            } else {
                getUsersToWorkspace(data.notMainWorkspaceId);
            }
        }
        return result.data;
    };

    const addExistingUser = async (data: TypeAddExistingUser) => {
        const result = await callPlatformAPI('user/existing/add', 'post', data);
        console.log('addExistingUser', result);
        if (result.data.isOk) {
            if (data.notMainWorkspaceId) {
                getUsersToWorkspace();
            } else {
                getUsersToWorkspace(data.notMainWorkspaceId);
            }
        }
        return result.data;
    };

    const updateUserInCompany = async (data: TypeUpdateUserInCompany) => {
        const result = await callPlatformAPI(`user/update/${data.userId}`, 'put', data);
        console.log('updateUserInCompany', result);
        if (result.data.isOk) {
            await getUsersToWorkspace(data.workspaceId);
        }
        return result.data;
    };

    const deleteUser = async (data: TypeDeleteUser) => {
        const result = await callPlatformAPI(
            `user/delete/${data.userId}/${data.workspaceId}/${data.onlyWorkspace}`,
            'delete',
        );
        console.log('deleteUser', result);
        if (result.data.isOk) {
            getUsersToWorkspace(data.workspaceId);
        }
        return result.data;
    };

    const updateUser = async (data: any) => {
        const result = await callPlatformAPI('user/update', 'put', data);
        console.log('updateUser', result);
        if (result.data?.isOk) {
            if (!data.newEmail) {
                setUserInfo(result.data.result);
            }
        }
        return result.data;
    };

    return (
        <UserContext.Provider
            value={{
                userInfo,
                userRole,
                users,

                //methods
                updateUser,
                setUserRole,
                addUser,
                addExistingUser,
                deleteUser,
                updateUserInCompany,
                getUsersToWorkspace,
                findUser,
            }}
            {...props}
        />
    );
}

export { UserProvider, UserContext };
