import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { Tooltip } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import { OrderStatusColorEnum } from '@f_qwep/components/orders/types/statusColor.enum';
import { getNormalizedOrderStatusColor } from '@f_qwep/components/orders/utils/orderStatuses.util';

export function CellStatus({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);

    const statusColor = getNormalizedOrderStatusColor(info.row.original.status);
    const origStatus = info.row.original.origStatus;

    let color;
    let bordercolor;

    if (statusColor === OrderStatusColorEnum.RED) {
        color = currentTheme == 'light' ? '#FFEBED' : 'rgb(232, 87, 100, 0.2)';
        bordercolor = '#E85764';
    }

    if (statusColor === OrderStatusColorEnum.BLACK) {
        color = currentTheme == 'light' ? '#F0F4FE' : 'rgb(148, 163, 184, 0.2)';
        bordercolor = colors.omicron;
    }

    if (statusColor === OrderStatusColorEnum.BLUE) {
        color = currentTheme == 'light' ? colors.beta : 'rgb(59, 130, 246, 0.2)';
        bordercolor = colors.alpha;
    }

    if (statusColor === OrderStatusColorEnum.YELLOW) {
        color = currentTheme == 'light' ? '#FFFBE1' : 'rgb(240, 212, 17, 0.2)';
        bordercolor = '#F0D411';
    }

    if (statusColor === OrderStatusColorEnum.GREEN) {
        color = currentTheme == 'light' ? '#E5F8EB' : 'rgb(110, 186, 110, 0.2)';
        bordercolor = '#69DE94';
    }

    if (statusColor === OrderStatusColorEnum.ORANGE) {
        color = currentTheme == 'light' ? colors.zeta : 'rgb(240, 142, 19, 0.2)';
        bordercolor = '#F08E13';
    }

    if (!statusColor) {
        color = currentTheme == 'light' ? '#FFFBE1' : 'rgb(240, 212, 17, 0.2)';
        bordercolor = '#F0D411';
    }

    return (
        <Tooltip title={origStatus ?? info.row.original.status}>
            <div
                style={{ background: color, color: bordercolor, border: `0.5px solid ${bordercolor}` }}
                className={stls.cellStatus}>
                {info.row.original.status}
            </div>
        </Tooltip>
    );
}
