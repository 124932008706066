import Papa from 'papaparse';

export class CsvFileUtil {
    private readonly file: File;

    constructor(file: File) {
        this.file = file;
    }

    public async getJson(): Promise<Array<object[]> | null> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const csvData = event.target?.result as string;

                Papa.parse(csvData, {
                    header: true,
                    complete: (results) => {
                        const normalizedData = this.normalizeData(results.data as object[]);
                        resolve(normalizedData);
                    },
                    error: (error) => {
                        console.error(error);
                        resolve(null);
                    },
                });
            };

            reader.onerror = (error) => {
                console.error(error);
                resolve(null);
            };

            reader.readAsText(this.file);
        });
    }

    private normalizeData(data: object[]): Array<object[]> {
        const headers = Object.keys(data[0]);
        const values = data.map((item) => Object.values(item));
        return [headers, ...values];
    }
}
