import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { TypeClassNames } from '@f_types/index';

type TypeIconDownTableProps = TypeClassNames;

export const IconDownTable = ({ classNames }: TypeIconDownTableProps) => {
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.25 12V0.25H16.75V12V12.25H17H19.4798L16 16.5998L12.5202 12.25H15H15.25V12ZM8.75 14.25V15.75H0.25V14.25H8.75ZM10.75 7.25V8.75H0.25V7.25H10.75ZM10.75 0.25V1.75H0.25V0.25H10.75Z"
                    fill={colors.epsilon}
                    stroke="white"
                    strokeWidth="0.5"
                />
            </svg>
        </div>
    );
};
