import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';

type TypeIconReuseProps = TypeClassNames & {
    colorReuse: any;
};

export const IconReuse = ({ classNames, colorReuse }: TypeIconReuseProps) => {
    return (
        <div className={cn(classNames)}>
            <svg width="21" height="16.5" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.75 0.75H2.25C1.85218 0.75 1.47064 0.908035 1.18934 1.18934C0.908035 1.47064 0.75 1.85218 0.75 2.25V15.75C0.75 16.1478 0.908035 16.5294 1.18934 16.8107C1.47064 17.092 1.85218 17.25 2.25 17.25H18.75C19.1478 17.25 19.5294 17.092 19.8107 16.8107C20.092 16.5294 20.25 16.1478 20.25 15.75V2.25C20.25 1.85218 20.092 1.47064 19.8107 1.18934C19.5294 0.908035 19.1478 0.75 18.75 0.75ZM15.75 9.75C15.75 9.94891 15.671 10.1397 15.5303 10.2803C15.3897 10.421 15.1989 10.5 15 10.5H7.81031L8.78063 11.4694C8.85031 11.5391 8.90558 11.6218 8.9433 11.7128C8.98101 11.8039 9.00042 11.9015 9.00042 12C9.00042 12.0985 8.98101 12.1961 8.9433 12.2872C8.90558 12.3782 8.85031 12.4609 8.78063 12.5306C8.71094 12.6003 8.62822 12.6556 8.53717 12.6933C8.44613 12.731 8.34855 12.7504 8.25 12.7504C8.15145 12.7504 8.05387 12.731 7.96283 12.6933C7.87178 12.6556 7.78906 12.6003 7.71937 12.5306L5.46937 10.2806C5.39964 10.211 5.34432 10.1283 5.30658 10.0372C5.26884 9.94616 5.24941 9.84856 5.24941 9.75C5.24941 9.65144 5.26884 9.55384 5.30658 9.4628C5.34432 9.37175 5.39964 9.28903 5.46937 9.21937L7.71937 6.96937C7.86011 6.82864 8.05098 6.74958 8.25 6.74958C8.44902 6.74958 8.63989 6.82864 8.78063 6.96937C8.92136 7.11011 9.00042 7.30098 9.00042 7.5C9.00042 7.69902 8.92136 7.88989 8.78063 8.03063L7.81031 9H14.25V6.75C14.25 6.55109 14.329 6.36032 14.4697 6.21967C14.6103 6.07902 14.8011 6 15 6C15.1989 6 15.3897 6.07902 15.5303 6.21967C15.671 6.36032 15.75 6.55109 15.75 6.75V9.75Z"
                    fill={colorReuse}
                />
            </svg>
        </div>
    );
};
