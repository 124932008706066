import { callSendFormData, callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useEffect, useState } from 'react';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { useAccess } from '@f_qwep/hooks/useAccess';

type TypeCreatePrice = {
    vendor: string;
    email: string;
    currency: string;
    offsting?: string;
    link?: string;
    file?: any;
};
type TypeXMLParse = {
    link: string;
};
type TypeUpdatePrice = {
    adminCode: string;
    email: string;
    currency: string;
    offsting?: string;
    link?: string;
    file?: any;
};

type TypeUpdateClassification = {
    adminCode: string;
    property: {
        priceHeader: string;
        category: string;
    }[];
};

type TypeDeletePrice = {
    codes: string[];
};

type TypeUpdateConfigPrice = {
    content: {
        type: string;
        value: string;
        category?: string;
    }[];
    adminCode: string;
};

type TypeBody = {
    link: string;
    offsting?: string;
    vendorEmail: string;
    currency: string;
    vendorName?: string;
};

const PriceContext = createContext<any>({});

function PriceProvider(props: any) {
    const { isAccess: access } = useAccess(QwepProductEnum.PRICE);
    const [prices, setPrices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progressList, setProgressList] = useState<any>({});

    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [offsting, setOffsting] = useState<string>('[0,0]');

    const resetOffsting = () => {
        setOffsting('[0,0]');
    };

    useEffect(() => {
        if (access) {
            getDashboard(true);
        }
    }, [access]);

    const getDashboard = async (loading: boolean = false) => {
        loading && setIsLoading(true);
        const result = await callPlatformAPI('qwep/price/get/dashboard', 'get');
        console.log('getDashboardPrice', result);
        if (result.data.isOk) {
            setPrices(result.data.result ?? []);
        } else {
            setPrices([]);
        }
        loading && setIsLoading(false);
    };

    const getProgressPrice = async (adminCode: string) => {
        const result = await callPlatformAPI('qwep/price/progress', 'post', {
            adminCode,
        });
        console.log('getProgressPrice', result);
        if (result.data.result !== 1) {
            setProgressList((prev: any) => ({
                ...prev,
                [adminCode]: Math.ceil(result.data.result * 100),
            }));
            setTimeout(async () => {
                await getProgressPrice(adminCode);
            }, 3000);
        } else {
            setProgressList((prev: any) => ({
                ...prev,
                [adminCode]: 0,
            }));
            setTimeout(async () => {
                await getDashboard();
            }, 1500);
        }
        return result.data;
    };
    const xmlParse = async (data: TypeXMLParse) => {
        const result = await callPlatformAPI('qwep/xml/parse', 'post', data);
        console.log('parseXML', result);
        if (result.data.isOk) {
        }
        return result.data;
    };
    const createPrice = async (data: TypeCreatePrice) => {
        console.log('gERGERGRE', data);

        let formdata = new FormData();
        let body: TypeBody = {
            link: '',
            offsting: '',
            vendorEmail: '',
            currency: '',
            vendorName: '',
        };
        if (data.link) {
            body = {
                link: data.link,
                offsting: data.offsting,
                vendorEmail: data.email,
                currency: data.currency,
                vendorName: data.vendor,
            };
        } else {
            formdata.append('price-list-file', data.file, data.file.name);
            formdata.append('vendor-email', data.email);
            formdata.append('price-currency', data.currency);
            formdata.append('vendor-name', data.vendor);
        }

        const result = await callPlatformAPI('qwep/price/create', 'post', data.link ? body : formdata);
        console.log('createPrice', result);
        if (result.data.isOk) {
            setTimeout(async () => {
                await getDashboard();
            }, 500);
        }
        return result.data;
    };

    const getClassification = async (adminCode: string) => {
        const result = await callPlatformAPI(`qwep/price/get/classification/${adminCode}`, 'get');
        return result.data;
    };

    const updateClassification = async (data: TypeUpdateClassification) => {
        const result = await callPlatformAPI(
            `qwep/price/update/classification/${data.adminCode}`,
            'put',
            data.property,
        );
        console.log('updateClassification', result);
        if (result.data.isOk) {
            await getProgressPrice(data.adminCode);
        }
        return result.data;
    };
    const postXMLClassification = async (data: TypeUpdateClassification) => {
        const result = await callPlatformAPI(`qwep/price/post/classification/${data.adminCode}`, 'post', data.property);
        console.log('postXMLClassification', result);
        if (result.data.isOk) {
            await getProgressPrice(data.adminCode);
        }
        return result.data;
    };
    const cleanPrice = async (data: TypeDeletePrice) => {
        const result = await callPlatformAPI('qwep/price/clean', 'put', data);
        console.log('cleanPrice', result);
        if (result.data.isOk) {
            await getDashboard();
        }
        return result.data;
    };

    const deletePrice = async (data: TypeDeletePrice) => {
        const result = await callPlatformAPI('qwep/price/delete', 'put', data);
        console.log('deletePrice', result);
        if (result.data.isOk) {
            await getDashboard();
        }
        return result.data;
    };

    const getConfigPrice = async (adminCode: string) => {
        const result = await callPlatformAPI(`qwep/price/get/config/${adminCode}`, 'get');
        console.log('getConfigPrice', result);
        return result.data;
    };

    const updateConfigPrice = async (data: TypeUpdateConfigPrice) => {
        const result = await callPlatformAPI('qwep/price/update/config', 'put', data);
        console.log('updateConfigPrice', result);
        return result.data;
    };

    const priceSearch = async (items: { article: string; brand: string }[]) => {
        const result = await callPlatformAPI('qwep/price/get/search', 'post', {
            items: items,
            vids: prices.map((item: any) => ({ vid: item.vendorId, vTitle: item.vendorName })),
        });
        console.log('getPriceSearch', result);
        return result.data;
    };

    const updatePrice = async (data: TypeUpdatePrice) => {
        let formdata = new FormData();
        const body = {
            'X-Admin-Code': data.adminCode,
        };
        // if (data.link && data.offsting) {

        // } else {
        formdata.append('price-list-file', data.file, data.file.name);
        formdata.append('X-Admin-Code', data.adminCode);
        formdata.append('vendor-email', data.email);
        formdata.append('price-currency', data.currency);
        // }

        const result = await callSendFormData('qwep/price/update', 'put', data.link ? body : formdata);
        console.log('updatePrice', result);
        if (result.data.isOk) {
            await getDashboard();
        }

        return result.data;
    };

    return (
        <PriceContext.Provider
            value={{
                prices,
                isLoading,
                progressList,
                openAdd,
                offsting,

                //methods
                updateConfigPrice,
                getConfigPrice,
                setOpenAdd,
                createPrice,
                getClassification,
                updateClassification,
                cleanPrice,
                deletePrice,
                updatePrice,
                priceSearch,
                setOffsting,
                xmlParse,
                postXMLClassification,
                resetOffsting,
            }}
            {...props}
        />
    );
}

export { PriceProvider, PriceContext };
