import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { IconMaskot } from '@f_components/icons/IconMascot';

export function AddBatchPricerManual() {
    const { openAdd, final } = useContext(BatchPricerContext);
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<any>([
        {
            target: '#batch-pricer',
            content: (
                <div>
                    {' '}
                    <IconMaskot />
                    <p>Перейдите в раздел "Проценка"</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#createBatchPricer',
            content: 'Нажмите на кнопку для загрузки новой проценки',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#inputAddFile',
            content: 'Загрузите файл для проценки',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#downloadFile',
            content: 'Используйте пример-шаблон, который можно скачать тут.',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#nameBatchPricer',
            content: 'Назовите проценку для быстрого поиска в будущем.',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#root',
            content: (
                <p>
                    Статус процесса проценки будет в реальном времени отражаться по каждому товару. <br /> Когда процесс
                    завершится, вы увидите статус на файле о готовности.
                </p>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'center',
        },
    ]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            setRun(true);
            if (!newManualValue['batch-pricer']?.flag) {
                setNewManualValue({ ...newManualValue, 'batch-pricer': { flag: true } });
            }
        }
        if (window.location.pathname == '/app-qwep/batch-pricer') {
            if (final) {
                setTimeout(() => {
                    setCurrentStep((prevStep) => prevStep + 1);
                }, 200);
            }
        }
    }, [window.location.pathname, final]);

    useEffect(() => {
        if (openAdd) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [openAdd]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
            setChooseManual();
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
