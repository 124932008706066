import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconProgressItemsProps = TypeClassNames;

export const IconProgressItems = ({ classNames }: TypeIconProgressItemsProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill={colors.iota} />
                <path
                    d="M8 13.9999C8 14.7333 8.6 15.3333 9.33333 15.3333C10.0667 15.3333 10.6667 14.7333 10.6667 13.9999C10.6667 13.2666 10.0667 12.6666 9.33333 12.6666C8.6 12.6666 8 13.2666 8 13.9999ZM17.3333 13.9999C17.3333 14.7333 17.9333 15.3333 18.6667 15.3333C19.4 15.3333 20 14.7333 20 13.9999C20 13.2666 19.4 12.6666 18.6667 12.6666C17.9333 12.6666 17.3333 13.2666 17.3333 13.9999ZM12.6667 13.9999C12.6667 14.7333 13.2667 15.3333 14 15.3333C14.7333 15.3333 15.3333 14.7333 15.3333 13.9999C15.3333 13.2666 14.7333 12.6666 14 12.6666C13.2667 12.6666 12.6667 13.2666 12.6667 13.9999Z"
                    fill="#218EE1"
                />
            </svg>
        </div>
    );
};
