import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { useContext } from 'react';
import { RootAssistantStore } from '@f_qwep/components/purchasingAssistant/model/stores/index.store';
import { TDeals } from '@f_qwep/components/purchasingAssistant/types/Deals.type';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { AlertStatusEnum } from '@f_qwep/components/purchasingAssistant/types/AlertStatusEnum.enum';

export function useGetDeals(store: RootAssistantStore): {
    mutate: UseMutateFunction<TDeals[], Error, AlertStatusEnum, unknown>;
} {
    const { getUsersId } = useContext(QwepContext);
    const users: number[] = getUsersId();
    const { currentAssistantList } = store.assistantList;
    const { handlerFetchDeals } = store.assistantDeals;
    const { refKey } = currentAssistantList;
    const { mutate } = useMutation<TDeals[], Error, AlertStatusEnum>({
        mutationFn: async (status = AlertStatusEnum.BASE) => {
            return await handlerFetchDeals({ users, refKey, status });
        },
    });

    return { mutate };
}
