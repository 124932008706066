// Глобальный стор контроля инпута - можно только экстендить или через композицию

import { action, makeObservable, observable } from 'mobx';
import { Alert } from '@f_general/alert';

// TODO не забыть добавить перевод для алерта

export class InputControlStore {
    input: string = '';

    constructor() {
        makeObservable(this, {
            input: observable,
            setInput: action.bound,
            resetInput: action.bound,
        });
    }

    setInput(value: string, title: string = 'Заголовок', limitValue: number = 20,) {
        if (value.length > limitValue) {
            Alert({
                type: 'warning',
                text: `${title} не может быть более ${limitValue} символов`,
                singleShow: true,
            });
            return;
        }
        this.input = value;
    }

    resetInput() {
        this.input = '';
    }
}