import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconAlertErrorProps = TypeClassNames;

export const IconAlertError = ({ classNames }: TypeIconAlertErrorProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.8661 1L21.3921 17.5C21.4799 17.652 21.5261 17.8245 21.5261 18C21.5261 18.1755 21.4799 18.348 21.3921 18.5C21.3043 18.652 21.1781 18.7782 21.0261 18.866C20.8741 18.9538 20.7016 19 20.5261 19H1.4741C1.29856 19 1.12612 18.9538 0.974105 18.866C0.822089 18.7782 0.695855 18.652 0.608089 18.5C0.520324 18.348 0.47412 18.1755 0.474121 18C0.474122 17.8245 0.520329 17.652 0.608096 17.5L10.1341 1C10.2219 0.847994 10.3481 0.721768 10.5001 0.634007C10.6521 0.546247 10.8246 0.500046 11.0001 0.500046C11.1756 0.500046 11.3481 0.546247 11.5001 0.634007C11.6521 0.721768 11.7783 0.847994 11.8661 1ZM10.0001 14V16H12.0001V14H10.0001ZM10.0001 7V12H12.0001V7H10.0001Z"
                    fill="#D01F1F"
                />
            </svg>
        </div>
    );
};
