import { ThemeContext } from '@f_context/Theme_context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { SearchContext } from '@f_qwep/context/Search_context';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { IconMaskotThinking } from '@f_components/icons/IconMascotThinking';

export function SearchResultManual() {
    const { inputSearch, preSearchLoading } = useContext(SearchContext);
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const [targetLoaded, setTargetLoaded] = useState(false);

    const [run, setRun] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);

    const [steps, setSteps] = useState<any>([
        {
            target: '#searchResultInput',
            title: 'Как выполнить поиск',
            content: (
                <div>
                    <IconMaskotThinking />
                    <p>Чтобы начать поиск введите артикул и выберите бренд из подсказок, либо нажмите enter</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#helpBrand',
            content: 'Это подсказка, нажмите на искомый бренд, чтобы совершить поиск по нему',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#historySearch',
            title: 'История поиска',
            content: 'Теперь Ваши артикул и бренд сохранены в историю поиска, и вы можете совершить по ним поиск',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: false,
        },
    ]);

    useEffect(() => {
        const target = document.querySelector('#helpBrand');
        if (target) {
            setTargetLoaded(true);
        }
    }, [preSearchLoading]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            setRun(true);
            if (!newManualValue.search?.flag) {
                setNewManualValue({ ...newManualValue, search: { flag: true } });
            }
        }
    }, []);

    useEffect(() => {
        if (inputSearch.length > 2) {
            if (!preSearchLoading && targetLoaded) {
                setCurrentStep((prevStep) => prevStep + 1);
            }
        }
    }, [inputSearch, preSearchLoading, targetLoaded]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/search') {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [window.location.pathname]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setChooseManual();
            setRun(false);
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
