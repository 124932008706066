import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconMaskotProps = TypeClassNames;

export const IconMaskot = ({ classNames }: TypeIconMaskotProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div style={{ position: 'fixed', marginLeft: '-200px' }} className={cn(classNames)}>
            <svg width="200" height="200" viewBox="0 0 432 429" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask
                    id="path-1-outside-1_3379_17863"
                    maskUnits="userSpaceOnUse"
                    x="200"
                    y="257"
                    width="32"
                    height="40"
                    fill="black">
                    <rect fill="white" x="200" y="257" width="32" height="40" />
                    <path d="M203 257H229V297H203V257Z" />
                </mask>
                <path d="M203 257H229V297H203V257Z" fill="#A2DC05" />
                <path
                    d="M226.366 257V297H231.634V257H226.366ZM205.634 297V257H200.366V297H205.634Z"
                    fill="black"
                    mask="url(#path-1-outside-1_3379_17863)"
                />
                <path d="M203 257H228V297H203V257Z" fill="#51C407" />
                <path
                    d="M203 257.878C203 257.393 203.393 257 203.878 257H227.122C227.607 257 228 257.393 228 257.878V259.645C228 260.085 227.673 260.458 227.237 260.515L210.229 262.753C209.792 262.811 209.466 263.183 209.466 263.624V287.244V296.122C209.466 296.607 209.072 297 208.587 297H203.878C203.393 297 203 296.607 203 296.122V257.878Z"
                    fill="#17A61A"
                />
                <path
                    d="M146.071 305.976L146.843 305.205L147.815 305.702L167.542 315.788C168.69 316.374 169.204 317.738 168.728 318.936L146.026 376.091L145.991 376.18L145.944 376.263L143.944 379.877L143.842 380.062L143.693 380.212L141.193 382.712L141.088 382.817L140.964 382.899L139.464 383.899L139.386 383.951L139.303 383.993L137.303 384.993L137.156 385.066L136.996 385.106L134.996 385.606L133.953 385.867L133.371 384.964L121.371 366.35L120.89 365.604L121.313 364.823L127.755 352.93L135.706 330.07L139.698 317.096L139.722 317.02L139.753 316.946L142.753 309.946L142.824 309.781L142.932 309.637L144.432 307.637L144.496 307.552L144.571 307.477L146.071 305.976Z"
                    fill="#02A608"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M162.099 316.468L147.076 306.616L145.121 309.133L143.653 311.145L140.669 318.151L137.011 330.992L128.887 353.946L122.81 366.104L134.918 381.277L137.544 377.887L137.011 373.651L162.099 316.468Z"
                    fill="#8CEC40"
                />
                <path
                    d="M162.238 317.763L159.29 320.009L157.02 320.953C156.901 321.003 156.773 321.029 156.645 321.03L154.399 321.05L152.45 320.569L150.99 320.084L149.53 319.599L148.074 318.616L146.623 317.134C146.128 316.61 145.256 316.776 144.989 317.445L144.281 319.214L140.02 332.116L131.84 355.049L125.955 367.279L135.842 381.622L138.298 378.169L140.115 374.351L139.17 368.651L142.67 362.651L148.17 350.151L162.238 317.763Z"
                    fill="#63D116"
                />
                <circle
                    cx="160.271"
                    cy="305.837"
                    r="12.8294"
                    transform="rotate(177.375 160.271 305.837)"
                    fill="url(#paint0_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M159.986 299.622C153.811 299.905 148.293 304.09 148.55 309.682C148.806 315.273 154.684 318.935 160.859 318.652C167.034 318.369 172.552 314.184 172.295 308.593C172.039 303.002 166.161 299.339 159.986 299.622Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M150.358 311.137C156.602 313.02 154.964 313.095 161.044 312.817C167.028 311.13 165.368 312.085 170.657 307.832C172.073 312.081 167 317.127 161.04 317.143C154.961 317.421 150.836 313.969 150.358 311.137Z"
                    fill="#A8A7A8"
                />
                <path
                    d="M261.093 347.631L259.617 347.361L261.093 347.631L266.307 319.187C266.452 318.399 266.449 317.592 266.301 316.806L263.601 302.533C263.108 299.931 261.086 297.887 258.489 297.368L249.78 295.626C249.36 295.542 248.933 295.5 248.505 295.5H225.5H178.72C178.099 295.5 177.482 295.589 176.886 295.764L172.01 297.198L172.434 298.637L172.01 297.198C169.669 297.887 167.911 299.828 167.458 302.226L164.696 316.823C164.55 317.595 164.545 318.387 164.682 319.161L166.023 326.761L166.041 326.863L166.073 326.961L175.454 355.998C176.321 358.682 178.819 360.5 181.639 360.5H249.704C250.809 360.5 251.895 360.218 252.861 359.682L254.819 358.594C256.01 357.932 256.963 356.913 257.544 355.68L260.58 349.227C260.819 348.719 260.991 348.183 261.093 347.631Z"
                    fill="#00A804"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M245.336 355.736L246.449 356.741C247.019 356.109 247.46 355.372 247.746 354.571L249.733 349.007C249.888 348.573 249.997 348.123 250.057 347.666L248.569 347.471L250.057 347.666L253.916 318.239C253.978 317.769 253.988 317.294 253.946 316.822L252.781 303.624C252.555 301.052 250.828 298.859 248.382 298.035L247.903 299.457L248.382 298.035L241.867 295.84C241.198 295.615 240.497 295.5 239.792 295.5H219.722H178.68C177.671 295.5 176.676 295.735 175.773 296.186L171.461 298.342C170.091 299.027 169.007 300.175 168.4 301.581L165.865 307.458C165.514 308.271 165.333 309.147 165.333 310.032V317.61C165.333 317.948 165.36 318.284 165.412 318.618L166.685 326.732L166.699 326.824L166.725 326.914L175.019 355.794C175.819 358.58 178.367 360.5 181.266 360.5H240.167C242.006 360.5 243.759 359.721 244.991 358.356L246.449 356.741L245.336 355.736Z"
                    fill="url(#paint1_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M230 297H238.5H242.5L249.5 300L250.5 301L251 302.5L251.5 304V306V307L252 310.5L252.5 316.5V318V319L251.5 325.5L249.5 340.5L248.5 348L247.5 350.5L245.5 356L243 358.5L240 359H234.5H225.5H180.5H179L177 357.5L176 354L174 346.5L177 349.5L181 352.5L184.5 354.5L187.5 355.5H231L235 353L237.5 349.5L247.5 312L247 303.5L243.5 300.5L239 299L234.5 298.5L228.5 297.5L224.5 297H230Z"
                    fill="#00A907"
                />
                <path
                    d="M245.336 355.736L246.449 356.741C247.019 356.109 247.46 355.372 247.746 354.571L249.733 349.007C249.888 348.573 249.997 348.123 250.057 347.666L248.569 347.471L250.057 347.666L253.916 318.239C253.978 317.769 253.988 317.294 253.946 316.822L252.781 303.624C252.555 301.052 250.828 298.859 248.382 298.035L247.903 299.457L248.382 298.035L241.867 295.84C241.198 295.615 240.497 295.5 239.792 295.5H219.722H178.68C177.671 295.5 176.676 295.735 175.773 296.186L171.461 298.342C170.091 299.027 169.007 300.175 168.4 301.581L165.865 307.458C165.514 308.271 165.333 309.147 165.333 310.032V317.61C165.333 317.948 165.36 318.284 165.412 318.618L166.685 326.732L166.699 326.824L166.725 326.914L175.019 355.794C175.819 358.58 178.367 360.5 181.266 360.5H240.167C242.006 360.5 243.759 359.721 244.991 358.356L246.449 356.741L245.336 355.736Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M203.808 317.031C204.266 315.063 204.203 316.747 204.76 309.854C202.647 307.056 195.506 301.413 194.254 302.539C193.309 303.977 195.145 305.881 196.6 308.014C199.932 312.898 201.959 316.942 203.808 317.031Z"
                    fill="black"
                />
                <path d="M204.264 329.007L189.083 329.009L204.199 313.61L204.264 329.007Z" fill="black" />
                <path
                    d="M191.333 329.064C190.073 329.808 189.086 329.256 184.436 328.989C181.951 326.789 177.056 319.86 178.162 318.779C179.546 318.007 181.24 319.864 183.182 321.361C187.475 324.671 191.299 327.342 191.333 329.064Z"
                    fill="black"
                />
                <path
                    d="M186.126 323.634L198.814 310.55L202.643 315.222L190.568 327.504L186.126 323.634Z"
                    fill="black"
                />
                <path
                    d="M180.812 319.574L195.007 305.194L198.837 310.605L186.118 323.627L180.812 319.574Z"
                    fill="black"
                />
                <path
                    d="M178.18 318.766L194.281 302.505L194.286 303.432L194.413 304.019L194.695 304.699L195.007 305.194L180.873 319.604L180.253 319.325L179.108 318.922L178.18 318.766Z"
                    fill="black"
                />
                <path
                    d="M204.836 340.694C204.352 342.655 204.836 340.92 203.819 347.865C205.932 350.649 212.958 356.431 214.224 355.322C215.189 353.898 213.378 351.969 211.953 349.816C208.688 344.888 206.684 340.807 204.836 340.694Z"
                    fill="black"
                />
                <path d="M204.265 329.006L219.661 328.972L204.545 344.155L204.265 329.006Z" fill="black" />
                <path
                    d="M217.442 328.976C219.305 328.721 217.78 328.852 224.185 328.807C226.64 331.041 231.655 338.236 230.535 339.301C229.14 340.055 227.501 338.345 225.588 336.81C221.21 333.297 217.423 330.701 217.442 328.976Z"
                    fill="black"
                />
                <rect
                    x="205.832"
                    y="342.649"
                    width="2.21725"
                    height="2.21725"
                    transform="rotate(134.212 205.832 342.649)"
                    fill="black"
                />
                <path
                    d="M222.737 334.502L209.842 347.32L206.048 342.612L218.123 330.484L222.737 334.502Z"
                    fill="black"
                />
                <path
                    d="M227.896 338.471L213.538 352.689L209.751 347.195L222.59 334.367L227.896 338.471Z"
                    fill="black"
                />
                <path
                    d="M230.534 339.301L214.216 355.345L214.226 354.421L214.11 353.835L213.84 353.154L213.538 352.658L227.84 338.438L228.478 338.724L229.612 339.137L230.534 339.301Z"
                    fill="black"
                />
                <path
                    d="M192.51 329.095C190.471 328.943 190.533 329.251 184.436 328.956C181.68 331.104 176.897 337.788 178.022 339.04C179.459 339.986 181.519 338.143 183.653 336.69C188.54 333.363 192.42 330.944 192.51 329.095Z"
                    fill="black"
                />
                <path
                    d="M190.781 330.483L202.786 342.312L201.965 343.065L190.628 330.668L190.781 330.483Z"
                    fill="black"
                />
                <path d="M204.296 329L204.516 344.249L189.179 329.039L204.296 329Z" fill="black" />
                <path
                    d="M204.232 341.906C204.511 343.766 204.572 342.275 204.58 348.778C202.378 351.261 195.355 356.272 194.275 355.165C193.504 353.78 195.257 351.953 196.768 350.021C200.225 345.598 202.724 342.248 204.232 341.906Z"
                    fill="black"
                />
                <path
                    d="M198.975 347.276L185.993 334.546L190.715 330.554L202.971 342.649L198.975 347.276Z"
                    fill="black"
                />
                <path
                    d="M195.072 352.516L180.672 338.341L186.117 334.484L199.108 347.158L195.072 352.516Z"
                    fill="black"
                />
                <path
                    d="M194.275 355.164L178.025 339.052L178.948 339.051L179.533 338.927L180.21 338.649L180.703 338.34L195.11 352.488L194.827 353.1L194.428 354.24L194.275 355.164Z"
                    fill="black"
                />
                <rect
                    x="206.075"
                    y="342.621"
                    width="2.21725"
                    height="2.21725"
                    transform="rotate(134.212 206.075 342.621)"
                    fill="black"
                />
                <path
                    d="M216.61 328.826C218.94 328.821 217.476 329.051 224.185 328.809C226.94 326.659 231.83 319.967 230.784 318.818C229.424 317.972 227.542 319.665 225.416 321.13C220.55 324.484 216.765 327.062 216.61 328.826Z"
                    fill="#000400"
                />
                <path d="M204.264 329.005L204.199 313.517L219.66 328.972L204.264 329.005Z" fill="#000400" />
                <path
                    d="M204.438 315.809C204.2 313.951 204.139 315.554 204.127 309.086C206.328 306.601 213.33 301.55 214.41 302.656C215.183 304.04 213.267 305.69 211.828 307.676C209.121 311.415 206.328 315.578 204.438 315.809Z"
                    fill="#000400"
                />
                <path
                    d="M209.717 310.518L222.709 323.238L218.087 327.407L205.773 315.148L209.717 310.518Z"
                    fill="#000400"
                />
                <path
                    d="M213.603 305.308L228.027 319.47L222.585 323.331L209.584 310.667L213.603 305.308Z"
                    fill="#000400"
                />
                <path
                    d="M214.411 302.656L230.674 318.756L229.75 318.758L229.165 318.882L228.488 319.161L227.996 319.47L213.573 305.339L213.861 304.72L214.259 303.58L214.411 302.656Z"
                    fill="#000400"
                />
                <rect
                    x="219.716"
                    y="328.921"
                    width="2.21725"
                    height="2.21725"
                    transform="rotate(134.212 219.716 328.921)"
                    fill="#000400"
                />
                <path
                    d="M189.048 329.107L190.573 327.497L192.183 329.021L190.658 330.631L189.048 329.107Z"
                    fill="#000400"
                />
                <rect
                    x="205.768"
                    y="315.157"
                    width="2.21725"
                    height="2.21725"
                    transform="rotate(134.212 205.768 315.157)"
                    fill="#000400"
                />
                <path
                    d="M203.825 318.236C204.234 316.475 204.178 317.982 204.676 311.816C202.786 309.312 196.398 304.264 195.278 305.272C194.433 306.557 196.076 308.261 197.377 310.169C200.357 314.538 202.171 318.157 203.825 318.236Z"
                    fill="#0069A6"
                />
                <path d="M204.234 328.95L190.653 328.952L204.176 315.177L204.234 328.95Z" fill="#00BAF2" />
                <path
                    d="M192.665 329.001C191.537 329.667 190.654 329.173 186.494 328.934C184.271 326.966 179.892 320.767 180.882 319.801C182.12 319.109 183.635 320.771 185.372 322.11C189.213 325.071 192.634 327.46 192.665 329.001Z"
                    fill="#0069A6"
                />
                <path
                    d="M188.006 324.144L199.357 312.439L202.782 316.618L191.98 327.606L188.006 324.144Z"
                    fill="#00AEEF"
                />
                <path
                    d="M183.253 320.512L195.951 307.647L199.378 312.487L187.999 324.137L183.253 320.512Z"
                    fill="#0095DA"
                />
                <path
                    d="M180.897 319.789L195.302 305.241L195.306 306.07L195.42 306.596L195.672 307.204L195.951 307.647L183.307 320.538L182.753 320.289L181.728 319.928L180.897 319.789Z"
                    fill="#00AEEF"
                />
                <path
                    d="M204.745 339.406C204.312 341.161 204.746 339.609 203.836 345.822C205.726 348.312 212.011 353.485 213.144 352.493C214.007 351.218 212.388 349.493 211.112 347.567C208.191 343.158 206.398 339.507 204.745 339.406Z"
                    fill="#0069A6"
                />
                <path d="M204.234 328.95L218.008 328.919L204.484 342.502L204.234 328.95Z" fill="#00BAF2" />
                <path
                    d="M216.023 328.923C217.69 328.695 216.326 328.812 222.056 328.772C224.252 330.771 228.739 337.207 227.736 338.16C226.489 338.835 225.022 337.305 223.311 335.932C219.394 332.788 216.006 330.466 216.023 328.923Z"
                    fill="#0069A6"
                />
                <rect
                    x="205.636"
                    y="341.155"
                    width="1.98341"
                    height="1.98341"
                    transform="rotate(134.212 205.636 341.155)"
                    fill="#F5F5F5"
                />
                <path
                    d="M220.76 333.866L209.224 345.333L205.83 341.122L216.632 330.272L220.76 333.866Z"
                    fill="#00AEEF"
                />
                <path
                    d="M225.375 337.417L212.531 350.137L209.143 345.222L220.629 333.746L225.375 337.417Z"
                    fill="#0095DA"
                />
                <path
                    d="M227.736 338.16L213.137 352.513L213.147 351.686L213.042 351.162L212.801 350.553L212.531 350.109L225.325 337.388L225.896 337.643L226.911 338.013L227.736 338.16Z"
                    fill="#00AEEF"
                />
                <path
                    d="M193.718 329.03C191.893 328.894 191.949 329.169 186.494 328.906C184.028 330.827 179.75 336.806 180.757 337.927C182.042 338.773 183.885 337.124 185.794 335.824C190.166 332.848 193.637 330.684 193.718 329.03Z"
                    fill="#0F9647"
                />
                <path
                    d="M192.171 330.271L202.911 340.853L202.176 341.528L192.034 330.437L192.171 330.271Z"
                    fill="#DFE9A6"
                />
                <path d="M204.262 328.944L204.458 342.586L190.738 328.979L204.262 328.944Z" fill="#9EDD00" />
                <path
                    d="M204.205 340.491C204.454 342.155 204.508 340.821 204.516 346.639C202.546 348.86 196.263 353.343 195.297 352.353C194.607 351.114 196.176 349.479 197.527 347.751C200.62 343.794 202.856 340.797 204.205 340.491Z"
                    fill="#0B9444"
                />
                <path
                    d="M199.502 345.295L187.888 333.907L192.112 330.335L203.077 341.155L199.502 345.295Z"
                    fill="#8CC63F"
                />
                <path
                    d="M196.01 349.983L183.127 337.302L187.998 333.851L199.62 345.19L196.01 349.983Z"
                    fill="#39B54A"
                />
                <path
                    d="M195.297 352.352L180.759 337.938L181.586 337.937L182.109 337.826L182.715 337.577L183.155 337.301L196.044 349.958L195.79 350.506L195.434 351.526L195.297 352.352Z"
                    fill="#9EDD00"
                />
                <rect
                    x="205.853"
                    y="341.131"
                    width="1.98341"
                    height="1.98341"
                    transform="rotate(134.212 205.853 341.131)"
                    fill="#F5F5F5"
                />
                <path
                    d="M215.279 328.788C217.364 328.784 216.053 328.99 222.056 328.773C224.52 326.85 228.895 320.863 227.959 319.835C226.742 319.078 225.058 320.593 223.157 321.904C218.804 324.904 215.418 327.21 215.279 328.788Z"
                    fill="#0B9444"
                />
                <path d="M204.234 328.95L204.175 315.093L218.007 328.919L204.234 328.95Z" fill="#9EDD01" />
                <path
                    d="M204.389 317.142C204.176 315.48 204.122 316.915 204.112 311.128C206.08 308.905 212.345 304.387 213.311 305.376C214.002 306.614 212.288 308.09 211.001 309.867C208.579 313.212 206.08 316.936 204.389 317.142Z"
                    fill="#0B9444"
                />
                <path
                    d="M209.112 312.41L220.734 323.789L216.599 327.518L205.584 316.551L209.112 312.41Z"
                    fill="#8CC63F"
                />
                <path
                    d="M212.589 307.748L225.492 320.418L220.624 323.872L208.993 312.542L212.589 307.748Z"
                    fill="#39B54A"
                />
                <path
                    d="M213.311 305.376L227.86 319.779L227.034 319.781L226.511 319.892L225.905 320.141L225.465 320.418L212.561 307.776L212.819 307.223L213.175 306.203L213.311 305.376Z"
                    fill="#9EDD01"
                />
                <rect
                    x="218.057"
                    y="328.873"
                    width="1.98341"
                    height="1.98341"
                    transform="rotate(134.212 218.057 328.873)"
                    fill="#F5F5F5"
                />
                <path
                    d="M190.621 329.04L191.984 327.6L193.425 328.963L192.061 330.404L190.621 329.04Z"
                    fill="#F5F5F5"
                />
                <rect
                    x="205.579"
                    y="316.56"
                    width="1.98341"
                    height="1.98341"
                    transform="rotate(134.212 205.579 316.56)"
                    fill="#F5F5F5"
                />
                <path
                    d="M281.037 309.325L280.265 308.554L279.293 309.05L259.566 319.136C258.418 319.723 257.904 321.087 258.38 322.285L281.082 379.44L281.117 379.529L281.164 379.612L283.164 383.226L283.266 383.411L283.415 383.561L285.915 386.061L286.021 386.166L286.144 386.248L287.644 387.248L287.722 387.3L287.805 387.342L289.805 388.342L289.953 388.415L290.112 388.455L292.112 388.955L293.155 389.216L293.737 388.313L305.737 369.699L306.218 368.952L305.795 368.172L299.353 356.279L291.402 333.419L287.41 320.445L287.386 320.369L287.355 320.295L284.355 313.295L284.284 313.13L284.176 312.986L282.676 310.986L282.612 310.901L282.537 310.825L281.037 309.325Z"
                    fill="#02A608"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M265.009 319.817L280.032 309.965L281.987 312.482L283.455 314.494L286.439 321.5L290.098 334.341L298.221 357.295L304.298 369.453L292.19 384.626L289.564 381.236L290.098 377L265.009 319.817Z"
                    fill="#8CEC40"
                />
                <path
                    d="M264.87 321.112L267.819 323.358L270.088 324.302C270.207 324.352 270.335 324.378 270.463 324.379L272.71 324.399L274.658 323.918L276.118 323.433L277.578 322.948L279.034 321.964L280.486 320.483C280.98 319.959 281.852 320.124 282.12 320.794L282.828 322.563L287.088 335.465L295.268 358.398L301.153 370.628L291.266 384.971L288.81 381.518L286.993 377.7L287.939 372L284.439 366L278.939 353.5L264.87 321.112Z"
                    fill="#63D116"
                />
                <path
                    d="M320.299 380.207L320.29 380.222L320.28 380.237L318.336 383.153L317.032 382.416L318.336 383.153C318.243 383.293 318.135 383.424 318.014 383.545L316.161 385.397C316.038 385.521 315.901 385.633 315.753 385.732L311.447 388.602C311.273 388.719 311.084 388.815 310.886 388.889L307.006 390.345L305.724 390.825L305.1 389.71L293.931 369.758C293.465 368.925 293.575 367.895 294.213 367.129L307.643 351.004L307.661 350.983L307.68 350.962L327.749 328.541L337.608 314.739C337.761 314.525 337.948 314.334 338.162 314.172L339.106 315.289L338.162 314.172L340.532 312.383L340.6 312.332L340.672 312.288L343.489 310.587L343.489 310.587C344.495 309.98 345.787 310.074 346.63 310.816L357.936 320.766C358.834 321.555 358.972 322.856 358.265 323.849L337.778 352.613L320.299 380.207Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M344.604 311.54L354.815 320.317C355.192 320.642 355.241 321.189 354.93 321.591L332.937 349.969L313.439 378L305.742 383.5L305.742 387.716L295.977 367.388L308.505 351.686L328.476 329L333.476 322L338.476 315L343.279 311.507C343.682 311.214 344.241 311.228 344.604 311.54Z"
                    fill="#8CEC40"
                />
                <path
                    d="M356.712 321.164L357.457 321.951C357.763 322.274 357.789 322.759 357.522 323.129L336.738 351.933L319.177 379.972L315.976 384L309.476 388.5L311.939 373.5L320.564 364.269L336.738 341.886L349.976 326.386L352.476 323.886L355.189 321.173C355.612 320.75 356.317 320.746 356.712 321.164Z"
                    fill="#02A608"
                />
                <path
                    d="M306.886 362.838L304.854 362.344C304.167 362.177 303.891 361.413 304.317 360.859L310.464 352.879L330.194 330.042L338.374 318.192C338.873 317.47 340.029 317.624 340.238 318.44L340.698 320.236L341.645 321.708L343.087 322.677L345.015 323.638L347.925 324.09L354.439 323.638L333.344 351.546L314.147 377L314.461 374.609L314.035 371.149L313.097 369.182L311.681 366.727L309.292 364.287L306.886 362.838Z"
                    fill="#63D116"
                />
                <circle
                    cx="11.3294"
                    cy="11.3294"
                    r="12.8294"
                    transform="matrix(0.998951 0.0458011 0.0458011 -0.998951 255 319.984)"
                    fill="url(#paint2_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M267.122 302.971C273.297 303.254 278.815 307.439 278.558 313.031C278.302 318.622 272.424 322.284 266.249 322.001C260.074 321.718 254.556 317.533 254.813 311.942C255.069 306.351 260.947 302.688 267.122 302.971Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M276.75 314.485C270.506 316.369 272.144 316.444 266.065 316.166C260.08 314.479 261.74 315.434 256.451 311.181C255.035 315.43 260.108 320.476 266.068 320.492C272.147 320.77 276.272 317.318 276.75 314.485Z"
                    fill="#A8A7A8"
                />
                <circle
                    cx="300.717"
                    cy="380.043"
                    r="12.783"
                    fill="url(#paint3_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M302.472 386.094C308.254 384.681 312.534 379.543 311.224 374.184C309.914 368.824 303.746 366.24 297.965 367.653C292.183 369.067 287.903 374.204 289.213 379.564C290.523 384.924 296.691 387.508 302.472 386.094Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <circle
                    cx="350.804"
                    cy="313.366"
                    r="8.95447"
                    transform="rotate(146.564 350.804 313.366)"
                    fill="url(#paint4_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M348.418 311.396C347.018 312.907 346.128 314.713 345.876 316.465C345.626 318.21 346.004 320.059 347.377 321.332C348.751 322.604 350.623 322.842 352.344 322.46C354.072 322.076 355.806 321.052 357.206 319.541C358.606 318.031 359.496 316.225 359.747 314.473C359.997 312.728 359.619 310.879 358.246 309.606C356.873 308.333 355.001 308.096 353.28 308.478C351.551 308.861 349.818 309.886 348.418 311.396Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M356.101 290.137C356.942 290.176 357.933 289.925 359.026 289.497C359.446 289.598 359.87 289.604 360.26 289.562C360.905 289.493 361.574 289.281 362.233 288.999C363.551 288.435 365.077 287.479 366.709 286.277C369.979 283.867 373.916 280.286 377.873 276.237C384.689 270.116 388.122 266.103 389.571 263.284C390.31 261.847 390.612 260.572 390.444 259.408C390.271 258.215 389.642 257.393 389.049 256.814C388.217 256.002 387.118 255.682 385.885 255.798C384.715 255.909 383.395 256.41 381.906 257.238C378.925 258.896 374.818 262.128 369.128 267.489C364.477 271.459 359.06 277.465 356.061 281.983C355.307 283.119 354.678 284.202 354.254 285.162C353.859 286.054 353.521 287.096 353.662 288.044C353.742 288.578 353.986 289.144 354.5 289.566C354.995 289.972 355.581 290.113 356.101 290.137Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M372.361 274.337C373.427 275.243 374.62 275.646 375.883 275.529C377.075 275.418 378.166 274.858 379.129 274.18C380.618 273.13 382.183 271.501 383.721 269.899C384.119 269.484 384.515 269.071 384.908 268.671C387.007 266.53 388.642 264.241 389.564 262.226C390.023 261.225 390.34 260.215 390.405 259.279C390.469 258.351 390.291 257.285 389.488 256.498C388.685 255.711 387.617 255.553 386.69 255.635C385.756 255.718 384.752 256.055 383.76 256.532C381.763 257.493 379.505 259.172 377.406 261.313C376.492 262.244 375.406 263.07 374.284 263.912C374.235 263.949 374.185 263.987 374.135 264.024C373.106 264.796 372.003 265.624 371.187 266.5C370.323 267.427 369.541 268.662 369.703 270.215C369.859 271.711 370.85 273.054 372.361 274.337Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M379.508 267.012C380.378 267.778 381.271 268.3 382.215 268.499C383.193 268.705 384.08 268.533 384.855 268.164C385.593 267.813 386.227 267.285 386.765 266.775C387.123 266.436 387.501 266.042 387.858 265.67C388.024 265.498 388.185 265.33 388.337 265.174C389.585 263.903 390.41 262.362 390.682 260.842C390.952 259.33 390.686 257.673 389.488 256.498C388.29 255.323 386.628 255.09 385.122 255.389C383.608 255.69 382.082 256.545 380.835 257.816C380.663 257.991 380.443 258.208 380.209 258.436C379.869 258.769 379.502 259.128 379.221 259.425C378.693 259.983 378.127 260.655 377.766 261.436C377.384 262.266 377.23 263.229 377.551 264.27C377.856 265.261 378.542 266.161 379.508 267.012Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M408.024 284.361C413.5 282.672 417.627 280.121 420.331 277.679C421.679 276.461 422.705 275.243 423.363 274.139C423.692 273.588 423.95 273.029 424.098 272.485C424.242 271.961 424.317 271.325 424.124 270.7C423.895 269.958 423.357 269.47 422.738 269.22C422.184 268.996 421.58 268.962 421.045 268.987C419.967 269.039 418.598 269.371 417.075 269.836C414.694 270.564 411.604 271.725 408.044 273.063C407.02 273.447 405.958 273.847 404.862 274.255C402.257 275.057 399.689 275.784 397.298 276.456C397.136 276.502 396.975 276.547 396.814 276.593C394.603 277.214 392.544 277.793 390.787 278.339C388.925 278.918 387.303 279.489 386.168 280.075C385.613 280.361 385.05 280.712 384.639 281.159C384.205 281.63 383.773 282.418 384.067 283.37C384.312 284.167 384.957 284.653 385.44 284.934C385.972 285.243 386.626 285.485 387.331 285.678C388.751 286.066 390.624 286.318 392.757 286.399C397.033 286.561 402.569 286.044 408.024 284.361Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M422.465 270.405C423.026 272.227 418.137 279.672 407.582 282.928C397.027 286.183 388.751 286.331 387.251 285.332C386.751 283.332 395.25 279.332 405.547 276.33C415.501 272.684 421.25 269.832 422.465 270.405Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M422.919 271.279C423.327 272.602 418.298 279.622 407.732 282.881C397.389 286.289 388.251 287.331 387.251 285.331C387.252 280.883 399.75 281.332 410.251 277.832C420.251 274.498 421.751 271.832 422.919 271.279Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M400.154 278.161L400.131 278.239L400.116 278.319C399.797 280.043 399.709 281.569 400.171 282.714C400.429 283.351 400.863 283.889 401.494 284.23C402.094 284.555 402.743 284.631 403.329 284.608C404.453 284.562 405.772 284.121 407.118 283.585C408.158 283.171 409.342 282.644 410.621 282.076C411.029 281.894 411.448 281.708 411.874 281.52C415.495 280.121 418.599 278.108 420.751 276.24C421.826 275.306 422.706 274.372 423.295 273.53C423.587 273.112 423.843 272.667 424.002 272.217C424.148 271.803 424.292 271.16 424.037 270.498C423.779 269.829 423.25 269.452 422.785 269.256C422.337 269.068 421.848 269 421.402 268.98C420.503 268.94 419.386 269.085 418.17 269.337C415.709 269.846 412.478 270.87 408.978 272.216C405.526 273.544 403.5 274.47 402.277 275.306C401.638 275.743 401.159 276.194 400.803 276.717C400.449 277.239 400.279 277.746 400.154 278.161Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M409.164 275.034L409.161 275.093L409.163 275.152C409.216 276.753 409.387 278.048 409.768 279.01C410.18 280.053 410.925 280.881 412.104 281.087C413.076 281.257 414.063 280.932 414.844 280.612C415.465 280.358 416.171 280.01 416.891 279.656C417.1 279.553 417.31 279.449 417.52 279.347C419.762 278.468 421.522 276.894 422.656 275.348C423.23 274.567 423.675 273.751 423.939 272.982C424.179 272.282 424.364 271.347 424.037 270.498C423.681 269.572 422.896 269.098 422.176 268.883C421.474 268.673 420.675 268.651 419.891 268.717C418.306 268.85 416.348 269.38 414.322 270.16C412.377 270.908 411.074 271.547 410.278 272.309C409.835 272.733 409.539 273.201 409.367 273.728C409.208 274.214 409.179 274.685 409.164 275.034Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M414.024 289.361C419.501 287.672 423.627 285.121 426.331 282.679C427.679 281.461 428.705 280.243 429.363 279.139C429.692 278.588 429.95 278.029 430.098 277.486C430.242 276.961 430.317 276.325 430.124 275.7C429.895 274.958 429.357 274.47 428.738 274.22C428.184 273.996 427.58 273.962 427.045 273.987C425.967 274.039 424.598 274.371 423.075 274.836C420.694 275.564 417.604 276.725 414.045 278.063C413.02 278.447 411.958 278.847 410.862 279.255C405.539 280.899 400.81 282.806 397.457 284.58C395.787 285.463 394.39 286.35 393.428 287.195C392.951 287.614 392.512 288.082 392.216 288.595C391.92 289.107 391.671 289.838 391.918 290.639C392.165 291.439 392.783 291.903 393.315 292.159C393.849 292.416 394.476 292.556 395.106 292.633C396.376 292.79 398.03 292.735 399.907 292.525C403.687 292.101 408.685 291.008 414.024 289.361Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M428.465 275.405C429.027 277.227 424.137 284.672 413.582 287.928C403.027 291.183 394.751 291.331 393.251 290.332C392.751 288.332 401.251 284.332 411.547 281.33C421.501 277.684 427.25 274.832 428.465 275.405Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M428.919 276.279C429.327 277.602 424.298 284.622 413.732 287.881C403.389 291.289 394.251 292.331 393.251 290.331C393.252 285.883 405.751 286.332 416.251 282.832C426.251 279.498 427.751 276.832 428.919 276.279Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M406.154 283.161L406.131 283.239L406.116 283.319C405.797 285.043 405.709 286.569 406.171 287.714C406.429 288.351 406.863 288.889 407.494 289.23C408.094 289.555 408.743 289.631 409.329 289.608C410.453 289.562 411.772 289.121 413.119 288.585C414.158 288.171 415.342 287.644 416.621 287.076C417.029 286.894 417.448 286.708 417.874 286.52C421.495 285.121 424.599 283.108 426.751 281.24C427.826 280.306 428.706 279.372 429.295 278.53C429.587 278.112 429.843 277.667 430.002 277.217C430.148 276.803 430.292 276.16 430.037 275.498C429.779 274.829 429.25 274.452 428.785 274.256C428.337 274.068 427.848 274 427.402 273.98C426.503 273.94 425.386 274.085 424.17 274.337C421.71 274.846 418.478 275.87 414.978 277.216C411.526 278.544 409.501 279.47 408.277 280.306C407.638 280.743 407.159 281.194 406.803 281.717C406.449 282.239 406.279 282.746 406.154 283.161Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M415.164 280.034L415.161 280.093L415.163 280.152C415.217 281.753 415.387 283.048 415.768 284.01C416.18 285.053 416.925 285.881 418.104 286.087C419.076 286.257 420.063 285.932 420.844 285.612C421.465 285.358 422.171 285.01 422.891 284.656C423.1 284.553 423.31 284.449 423.52 284.347C425.762 283.468 427.522 281.894 428.656 280.348C429.23 279.567 429.675 278.751 429.939 277.982C430.179 277.282 430.364 276.347 430.037 275.498C429.681 274.572 428.897 274.098 428.176 273.883C427.474 273.673 426.675 273.651 425.891 273.717C424.306 273.85 422.348 274.38 420.322 275.16C418.377 275.908 417.074 276.547 416.279 277.309C415.836 277.733 415.539 278.201 415.367 278.728C415.209 279.214 415.179 279.685 415.164 280.034Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M414.024 295.361C419.501 293.672 423.627 291.121 426.331 288.679C427.679 287.461 428.705 286.243 429.363 285.139C429.692 284.588 429.95 284.029 430.098 283.486C430.242 282.961 430.317 282.325 430.124 281.7C429.895 280.958 429.357 280.47 428.738 280.22C428.184 279.996 427.58 279.962 427.045 279.987C425.967 280.039 424.598 280.371 423.075 280.836C420.694 281.564 417.604 282.725 414.045 284.063C413.02 284.447 411.958 284.847 410.862 285.255C405.539 286.899 400.81 288.806 397.457 290.58C395.787 291.463 394.39 292.35 393.428 293.195C392.951 293.614 392.512 294.082 392.216 294.595C391.92 295.107 391.671 295.838 391.918 296.639C392.165 297.439 392.783 297.903 393.315 298.159C393.849 298.416 394.476 298.556 395.106 298.633C396.376 298.79 398.03 298.735 399.907 298.525C403.687 298.101 408.685 297.008 414.024 295.361Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M428.465 281.405C429.027 283.227 424.137 290.672 413.582 293.928C403.027 297.183 394.751 297.331 393.251 296.332C392.751 294.332 401.251 290.332 411.547 287.33C421.501 283.684 427.25 280.832 428.465 281.405Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M428.919 282.279C429.327 283.602 424.298 290.622 413.732 293.881C403.389 297.289 394.251 298.331 393.251 296.331C393.252 291.883 405.751 292.332 416.251 288.832C426.251 285.498 427.751 282.832 428.919 282.279Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M406.154 289.161L406.131 289.239L406.116 289.319C405.797 291.043 405.709 292.569 406.171 293.714C406.429 294.351 406.863 294.889 407.494 295.23C408.094 295.555 408.743 295.631 409.329 295.608C410.453 295.562 411.772 295.121 413.119 294.585C414.158 294.171 415.342 293.644 416.621 293.076C417.029 292.894 417.448 292.708 417.874 292.52C421.495 291.121 424.599 289.108 426.751 287.24C427.826 286.306 428.706 285.372 429.295 284.53C429.587 284.112 429.843 283.667 430.002 283.217C430.148 282.803 430.292 282.16 430.037 281.498C429.779 280.829 429.25 280.452 428.785 280.256C428.337 280.068 427.848 280 427.402 279.98C426.503 279.94 425.386 280.085 424.17 280.337C421.71 280.846 418.478 281.87 414.978 283.216C411.526 284.544 409.501 285.47 408.277 286.306C407.638 286.743 407.159 287.194 406.803 287.717C406.449 288.239 406.279 288.746 406.154 289.161Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M415.164 286.034L415.161 286.093L415.163 286.152C415.217 287.753 415.387 289.048 415.768 290.01C416.18 291.053 416.925 291.881 418.104 292.087C419.076 292.257 420.063 291.932 420.844 291.612C421.465 291.358 422.171 291.01 422.891 290.655C423.1 290.553 423.31 290.449 423.52 290.347C425.762 289.468 427.522 287.894 428.656 286.348C429.23 285.567 429.675 284.751 429.939 283.982C430.179 283.282 430.364 282.347 430.037 281.498C429.681 280.572 428.897 280.098 428.176 279.883C427.474 279.673 426.675 279.651 425.891 279.717C424.306 279.85 422.348 280.38 420.322 281.16C418.377 281.908 417.074 282.547 416.279 283.309C415.836 283.733 415.539 284.201 415.367 284.728C415.209 285.214 415.179 285.685 415.164 286.034Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M375.107 278.882L375.134 278.88L375.161 278.877C384.151 277.905 388.953 279.885 391.694 282.95C394.529 286.12 395.508 290.8 396.007 296.044C396.277 298.882 395.732 301.184 394.626 303.064C393.512 304.958 391.771 306.521 389.503 307.769C384.933 310.284 378.427 311.402 371.5 311.402C364.713 311.402 358.637 309.565 354.301 306.674C349.954 303.777 347.5 299.938 347.5 295.902C347.5 292.022 350.653 288.035 356.027 284.781C361.312 281.581 368.33 279.366 375.107 278.882Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M394.832 298.488C393.804 306.536 381.708 311.133 368.5 310C355.76 308.043 349.004 302.544 349.004 296.516C349.003 292.573 348.5 297.502 359.579 290.108C370.89 282.56 397.35 278.772 394.832 298.488Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M394.5 294.692C393.5 301.886 386.5 303.386 373.306 303.886C360.46 303.886 349 299.77 349 294.692C351.614 288.102 359.455 283.886 373.306 282.886C389.773 282.886 395.284 289.053 394.5 294.692Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M409.273 304.416C414.75 302.727 418.876 300.175 421.581 297.733C422.928 296.516 423.954 295.298 424.613 294.193C424.941 293.642 425.199 293.084 425.348 292.54C425.491 292.015 425.566 291.38 425.373 290.755C425.144 290.012 424.607 289.525 423.987 289.274C423.434 289.05 422.83 289.016 422.295 289.042C421.216 289.094 419.847 289.426 418.324 289.891C415.943 290.618 412.853 291.779 409.294 293.117C408.27 293.502 407.207 293.901 406.111 294.309C400.789 295.953 396.059 297.86 392.706 299.634C391.037 300.518 389.639 301.404 388.678 302.249C388.201 302.668 387.762 303.136 387.465 303.65C387.17 304.161 386.921 304.893 387.168 305.693C387.415 306.493 388.032 306.958 388.564 307.214C389.099 307.471 389.725 307.61 390.355 307.688C391.626 307.844 393.279 307.79 395.157 307.579C398.936 307.156 403.934 306.063 409.273 304.416Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M423.714 290.459C424.276 292.281 419.386 299.727 408.831 302.982C398.276 306.238 390 306.386 388.5 305.386C388 303.386 396.5 299.386 406.796 296.384C416.75 292.739 422.5 289.886 423.714 290.459Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M424.169 291.334C424.577 292.656 419.548 299.677 408.981 302.936C398.639 306.343 389.5 307.386 388.5 305.386C388.501 300.938 401 301.386 411.5 297.886C421.5 294.553 423 291.886 424.169 291.334Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M401.404 298.215L401.38 298.293L401.366 298.373C401.047 300.098 400.958 301.624 401.421 302.769C401.678 303.406 402.113 303.944 402.744 304.285C403.344 304.609 403.993 304.686 404.579 304.662C405.702 304.616 407.021 304.176 408.368 303.64C409.408 303.225 410.592 302.699 411.87 302.13C412.279 301.948 412.697 301.762 413.123 301.574C416.744 300.176 419.848 298.163 422 296.294C423.076 295.361 423.955 294.427 424.544 293.585C424.837 293.167 425.093 292.722 425.252 292.272C425.398 291.858 425.541 291.215 425.287 290.553C425.029 289.883 424.5 289.506 424.034 289.311C423.586 289.123 423.097 289.054 422.651 289.034C421.752 288.995 420.635 289.14 419.419 289.391C416.959 289.9 413.727 290.924 410.227 292.271C406.776 293.599 404.75 294.524 403.527 295.361C402.888 295.797 402.408 296.248 402.053 296.772C401.699 297.293 401.528 297.801 401.404 298.215Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M410.413 295.088L410.41 295.147L410.412 295.206C410.466 296.807 410.637 298.102 411.017 299.065C411.43 300.107 412.175 300.936 413.354 301.142C414.326 301.311 415.312 300.986 416.093 300.667C416.715 300.413 417.42 300.065 418.14 299.71C418.349 299.607 418.56 299.504 418.769 299.401C421.011 298.523 422.771 296.948 423.906 295.403C424.48 294.621 424.924 293.806 425.188 293.037C425.429 292.336 425.613 291.402 425.287 290.553C424.93 289.627 424.146 289.152 423.426 288.937C422.724 288.728 421.925 288.706 421.141 288.772C419.556 288.904 417.597 289.435 415.572 290.214C413.627 290.963 412.324 291.601 411.528 292.363C411.085 292.788 410.789 293.255 410.617 293.782C410.458 294.268 410.429 294.74 410.413 295.088Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M174.818 288.289L176.317 288.348L174.818 288.289L174.415 298.487C174.208 303.724 179.966 307.04 184.391 304.233L210.907 287.416C215.372 284.584 214.767 277.889 209.867 275.903L179.026 263.401C174.041 261.38 168.899 265.943 170.313 271.133L174.698 287.231C174.791 287.576 174.832 287.932 174.818 288.289Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M207.639 287.4C211.697 284.828 211.236 278.767 206.836 276.838L180.268 265.191C175.449 263.078 170.437 267.751 172.21 272.707L176.157 283.742C176.306 284.157 176.385 284.595 176.391 285.036L176.483 291.925L176.298 296.973C175.756 301.424 180.638 304.519 184.437 302.11L207.639 287.4Z"
                    fill="#A2DC05"
                    stroke="#02AA09"
                    strokeWidth="2"
                />
                <path
                    d="M249.787 277.751L251.862 275.399L254.42 276.836L252.153 284.144C252.021 284.569 251.971 285.015 252.007 285.459L252.631 293.281C253.015 298.087 248.215 301.624 243.738 299.834L233.134 295.593C232.611 295.384 232.117 295.108 231.666 294.772L221.251 287.02C220.773 286.664 220.346 286.244 219.982 285.771L217.436 282.464C214.846 279.098 216.125 274.194 220.03 272.523L225.81 270.049L238.676 263.697L247.045 259.1C252.61 256.043 258.807 261.961 256.009 267.661L254.583 270.567C254.421 270.896 254.232 271.211 254.017 271.509L249.848 277.28L249.787 277.751ZM249.758 277.405C249.758 277.404 249.758 277.404 249.759 277.403L249.758 277.405Z"
                    fill="url(#paint5_linear_3379_17863)"
                    stroke="#0482B2"
                    strokeWidth="3"
                />
                <path
                    d="M214.952 285.744C210.846 282.986 211.203 276.833 215.601 274.569L248.528 257.615C253.188 255.216 258.626 259.036 257.949 264.233L256.539 275.07C256.455 275.716 256.275 276.346 256.003 276.939L253.601 282.182C253.347 282.736 253.242 283.346 253.297 283.952L254.267 294.788C254.638 298.929 251.078 302.35 246.954 301.813L243.305 301.338C242.802 301.273 242.309 301.149 241.835 300.969L232.83 297.547C232.367 297.371 231.926 297.143 231.515 296.867L214.952 285.744Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path d="M227.844 290.353L220.665 286.426L219.839 281.203L227.844 290.353Z" fill="#0086B8" />
                <path d="M193.367 294.123L205.437 281.257L208.098 284.417L193.367 294.123Z" fill="#159C00" />
                <path d="M196.287 290.265L204.902 281.99L205.906 283.183L196.287 290.265Z" fill="black" />
                <path
                    d="M231.262 286.901L225.536 282.618L219.365 281.031L218.645 282.801L223.299 283.076L231.262 286.901Z"
                    fill="#0086B8"
                />
                <path
                    d="M230.25 285.907L225.034 282.192L219.502 280.694L218.915 282.138L223.059 282.504L230.25 285.907Z"
                    fill="black"
                />
                <path
                    d="M205.36 280.05L206.396 281.234L206.663 279.683C206.766 279.087 206.579 278.555 206.142 278.141C205.757 277.776 205.2 277.522 204.569 277.314C203.296 276.896 201.313 276.542 198.618 276.077C196.051 275.633 193.251 274.469 190.96 273.517C190.914 273.497 190.867 273.478 190.821 273.459C189.69 272.988 188.658 272.562 187.892 272.347C187.522 272.242 187.122 272.159 186.771 272.191C186.588 272.207 186.354 272.26 186.143 272.416C185.913 272.588 185.782 272.83 185.738 273.085C185.664 273.511 185.823 273.905 185.995 274.198C186.177 274.506 186.44 274.814 186.746 275.11C187.359 275.705 188.234 276.341 189.264 276.949C191.325 278.164 194.116 279.326 196.939 279.814L197.019 279.828L197.099 279.824C199.218 279.732 200.744 279.61 201.864 279.505C202.29 279.465 202.648 279.428 202.954 279.397C203.472 279.345 203.84 279.307 204.133 279.294C204.578 279.273 204.657 279.324 204.7 279.35L204.7 279.351C204.742 279.377 204.805 279.428 204.918 279.548C205.002 279.638 205.09 279.74 205.206 279.873C205.252 279.927 205.303 279.985 205.36 280.05Z"
                    fill="#1F920C"
                    stroke="#07A506"
                    strokeWidth="1.5"
                />
                <path
                    d="M205.203 280.664L206.246 282.098L206.548 280.35C206.654 279.739 206.521 279.166 206.098 278.691C205.717 278.261 205.15 277.969 204.508 277.738C203.223 277.274 201.218 276.915 198.514 276.448C195.95 276.005 193.169 274.709 190.881 273.642C190.836 273.621 190.792 273.6 190.748 273.58C189.624 273.056 188.587 272.575 187.81 272.34C187.437 272.226 187.014 272.133 186.634 272.186C186.428 272.214 186.19 272.291 185.985 272.474C185.774 272.663 185.665 272.905 185.624 273.143C185.551 273.562 185.669 273.97 185.832 274.312C186 274.663 186.251 275.018 186.55 275.363C187.15 276.055 188.016 276.791 189.04 277.487C191.09 278.879 193.886 280.187 196.744 280.68L196.839 280.697L196.935 280.688C199.082 280.507 200.63 280.31 201.768 280.146C202.203 280.084 202.568 280.028 202.879 279.981C203.4 279.901 203.769 279.844 204.062 279.819C204.481 279.782 204.542 279.833 204.559 279.847C204.56 279.848 204.561 279.849 204.562 279.85C204.598 279.876 204.659 279.933 204.772 280.077C204.854 280.181 204.941 280.302 205.054 280.458C205.099 280.521 205.148 280.589 205.203 280.664Z"
                    fill="#1F920C"
                    stroke="black"
                    strokeWidth="1.5"
                />
                <path
                    d="M216.232 278.321C216.249 278.315 216.312 278.289 216.379 278.215C216.465 278.119 216.497 278.013 216.505 277.933L215.874 278.265L215.21 278.614C215.119 278.441 215.031 278.229 215.011 277.997C214.988 277.738 215.051 277.448 215.265 277.21C215.457 276.998 215.699 276.907 215.877 276.864C216.053 276.82 216.241 276.806 216.417 276.803C217.02 276.564 217.858 276.322 218.778 276.056L218.788 276.053L218.805 276.048C220.841 275.459 223.452 274.705 225.76 273.49C228.15 272.231 230.535 271.013 232.395 270.238C233.319 269.853 234.157 269.558 234.821 269.432C235.149 269.369 235.49 269.336 235.802 269.385C236.121 269.436 236.516 269.595 236.734 270.008C237.033 270.577 236.875 271.258 236.65 271.794C236.404 272.382 235.978 273.038 235.403 273.713C234.249 275.07 232.421 276.591 229.953 277.89L229.893 277.921L229.827 277.942C225.756 279.212 221.916 278.926 219.294 278.612C218.948 278.571 218.583 278.524 218.252 278.481C217.998 278.448 217.764 278.418 217.572 278.394C217.222 278.352 216.938 278.323 216.71 278.31C216.66 278.334 216.617 278.356 216.581 278.376C216.511 278.416 216.486 278.439 216.485 278.44L216.485 278.44C216.487 278.438 216.5 278.425 216.518 278.398C216.532 278.377 216.549 278.345 216.563 278.304C216.525 278.303 216.488 278.302 216.454 278.303C216.326 278.304 216.259 278.314 216.234 278.32C216.233 278.321 216.232 278.321 216.232 278.321ZM216.232 278.321C216.229 278.322 216.227 278.322 216.227 278.322C216.227 278.322 216.229 278.322 216.232 278.321Z"
                    fill="#0085B7"
                    stroke="black"
                    strokeWidth="1.5"
                />
                <path
                    d="M206.344 276.365C206.42 275.513 207.03 274.802 207.861 274.599L211.391 273.735C211.494 273.71 211.599 273.693 211.705 273.684L215.504 273.377C216.476 273.298 217.362 273.931 217.603 274.875L219.386 281.848C219.421 281.984 219.441 282.123 219.447 282.263L219.666 287.709C219.702 288.609 219.132 289.423 218.274 289.696L214.702 290.832C214.449 290.913 214.183 290.942 213.919 290.919L209.999 290.572C209.318 290.512 208.715 290.108 208.4 289.501L205.92 284.721C205.745 284.383 205.669 284.002 205.703 283.623L206.344 276.365Z"
                    fill="url(#paint6_linear_3379_17863)"
                    stroke="#35A716"
                    strokeWidth="2"
                />
                <path
                    d="M204.824 276.511C204.71 275.451 205.448 274.488 206.5 274.322L211.054 273.602L215.494 273.221C216.463 273.138 217.352 273.765 217.599 274.706L219.422 281.657C219.46 281.802 219.482 281.95 219.487 282.1L219.668 287.691C219.696 288.541 219.182 289.316 218.389 289.622L214.277 291.206C214.022 291.305 213.75 291.35 213.477 291.339L208.769 291.148C207.86 291.111 207.091 290.465 206.896 289.577L205.582 283.574L204.824 276.511Z"
                    stroke="black"
                    strokeWidth="2"
                />
                <path
                    d="M173.877 275.601L173.487 273.131L180.143 283.215L181.627 292.599L179.105 289.454L176.688 293.38L176.921 285.244L173.877 275.601Z"
                    fill="url(#paint7_linear_3379_17863)"
                />
                <path
                    d="M207.045 283.595L216.139 274.282L216.794 274.898L217.879 278.523L218.463 282.218L218.854 287.903L218.381 288.48L207.045 283.595Z"
                    fill="#8CE2D5"
                />
                <path
                    d="M207.021 283.621L216.917 275.882L217.115 276.688L218.026 279.34L218.436 282.067L218.562 286.285L218.713 287.241L207.021 283.621Z"
                    fill="url(#paint8_linear_3379_17863)"
                />
                <path
                    d="M214.129 282.124L218.745 287.236L207.021 283.521L214.129 282.124Z"
                    fill="url(#paint9_linear_3379_17863)"
                />
                <path
                    d="M214.429 282.35L216.972 275.873L217.21 276.765L217.833 278.268L218.536 282.713L218.92 286.865L218.775 287.274L214.429 282.35Z"
                    fill="url(#paint10_linear_3379_17863)"
                />
                <path
                    d="M249.893 274.37L249.893 274.37L249.898 274.357L251.698 269.565C251.787 269.33 251.839 269.083 251.854 268.833L252.031 265.833C252.064 265.266 251.904 264.704 251.575 264.24L250.78 263.119C250.385 262.56 250.417 261.805 250.859 261.283L250.993 261.125C251.174 260.911 251.496 260.887 251.706 261.073L252.489 261.767C252.711 261.963 252.87 262.221 252.945 262.508L253.815 265.813C253.852 265.955 253.869 266.101 253.863 266.248L253.815 267.613C253.808 267.824 253.756 268.031 253.664 268.22L252.266 271.075L252.253 271.103L252.243 271.133L251.16 274.282L248.19 280.81C248.068 281.078 247.995 281.366 247.973 281.66L247.789 284.124L247.705 280.062C247.701 279.858 247.739 279.655 247.816 279.465L249.893 274.37Z"
                    fill="black"
                    stroke="black"
                />
                <rect
                    x="21.2989"
                    y="58.5126"
                    width="347.745"
                    height="231.344"
                    rx="22.5"
                    transform="rotate(-9.20782 21.2989 58.5126)"
                    fill="#00A806"
                    stroke="black"
                    strokeWidth="5"
                />
                <rect
                    x="2.86783"
                    y="62.0225"
                    width="347.745"
                    height="231.452"
                    rx="27.5"
                    transform="rotate(-9.20782 2.86783 62.0225)"
                    fill="url(#paint11_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="5"
                />
                <rect
                    x="14.5428"
                    y="68.5456"
                    width="324.844"
                    height="213.217"
                    rx="22.5"
                    transform="rotate(-9.20782 14.5428 68.5456)"
                    fill="url(#paint12_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="5"
                />
                <mask id="path-138-inside-2_3379_17863" fill="white">
                    <path d="M20.4031 90.2793C18.6356 79.3759 26.0417 69.1042 36.9451 67.3367L313.7 22.4734C324.603 20.7059 334.875 28.112 336.642 39.0154L363.644 205.581C365.411 216.485 358.005 226.756 347.102 228.524L70.3468 273.387C59.4435 275.155 49.1717 267.749 47.4042 256.845L20.4031 90.2793Z" />
                </mask>
                <path
                    d="M17.2028 70.537L333.442 19.2731L17.2028 70.537ZM366.765 206.088C368.798 218.627 360.281 230.44 347.742 232.472L74.9353 276.696C61.8513 278.816 49.5252 269.929 47.4042 256.845C48.8182 265.568 58.8034 271.206 69.7068 269.439L346.462 224.575C355.729 223.073 362.096 214.784 360.682 206.061L366.765 206.088ZM50.6045 276.587L17.2028 70.537L50.6045 276.587ZM313.7 22.4734C326.239 20.4408 338.051 28.9578 340.084 41.4967L366.765 206.088C368.798 218.627 360.281 230.44 347.742 232.472L346.462 224.575C355.729 223.073 362.096 214.784 360.682 206.061L333.681 39.4954C331.914 28.5921 322.968 20.971 313.7 22.4734Z"
                    fill="#0092AD"
                    mask="url(#path-138-inside-2_3379_17863)"
                />
                <path
                    d="M84.0777 119.792C82.0058 119.776 80.2628 118.138 80.5267 116.083C80.8753 113.369 81.7715 110.735 83.189 108.29C85.2404 104.751 88.3241 101.71 92.183 99.4193C96.042 97.129 100.565 95.6561 105.374 95.1232C109.042 94.7168 112.795 94.8663 116.451 95.5561C118.293 95.9035 119.274 97.8555 118.623 99.6124C118.038 101.19 116.338 102.031 114.681 101.743C112.085 101.291 109.429 101.204 106.831 101.492C103.253 101.888 99.888 102.984 97.0168 104.688C94.1456 106.392 91.8512 108.655 90.3249 111.288C89.5157 112.684 88.9348 114.163 88.5911 115.688C88.0946 117.891 86.3357 119.81 84.0777 119.792Z"
                    fill="#094C57"
                />
                <path
                    d="M266.163 89.3839C268.042 88.5107 268.95 86.2979 267.858 84.5369C266.416 82.2109 264.51 80.185 262.206 78.5468C258.873 76.1759 254.807 74.6855 250.345 74.1998C245.884 73.7141 241.158 74.2472 236.56 75.7547C233.053 76.9045 229.699 78.5958 226.657 80.7383C225.125 81.8173 225.041 84.0004 226.362 85.3297C227.547 86.5236 229.443 86.5848 230.832 85.6354C233.008 84.1488 235.389 82.9693 237.872 82.1549C241.293 81.0333 244.81 80.6366 248.129 80.998C251.448 81.3594 254.474 82.4683 256.954 84.2323C258.269 85.1676 259.41 86.2727 260.355 87.5182C261.719 89.3174 264.115 90.3355 266.163 89.3839Z"
                    fill="#094C57"
                />
                <path
                    d="M162.544 148.624C168.305 166.496 147.204 153.569 128.28 159.67C109.357 165.77 99.7783 188.587 94.0168 170.716C88.2553 152.844 98.925 133.41 117.848 127.31C136.772 121.209 156.782 130.752 162.544 148.624Z"
                    fill="#084953"
                />
                <path
                    d="M222.851 183.816C219.527 169.686 211.531 189.95 196.287 193.536C181.042 197.122 164.321 182.672 167.645 196.802C170.968 210.931 186.021 219.479 201.266 215.893C216.511 212.307 226.175 197.946 222.851 183.816Z"
                    fill="#084A56"
                />
                <path
                    d="M279.346 141.743C279.441 160.52 263.229 141.824 243.347 141.925C223.465 142.025 207.442 160.884 207.347 142.107C207.252 123.329 223.293 108.026 243.175 107.925C263.057 107.825 279.251 122.965 279.346 141.743Z"
                    fill="#094C57"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M294.602 164.226C292.96 160.273 286.277 159.151 278.97 160.701C272.314 162.113 266.78 166.305 263.945 170.73C260.845 175.57 259.643 180.873 264.945 182.591C270.622 184.431 279.398 182.077 286.119 177.915C292.657 173.866 296.306 168.33 294.602 164.226Z"
                    fill="#2DE3FE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M126.934 205.195C127.494 201.448 122.949 197.548 116.712 195.389C111.032 193.423 104.933 194.076 100.77 196.138C96.2167 198.392 92.8894 201.865 96.1177 205.586C99.5749 209.572 107.289 211.792 114.273 211.703C121.067 211.616 126.353 209.083 126.934 205.195Z"
                    fill="#33E2FE"
                />
                <path
                    d="M135.924 392.94L135.907 392.938L135.889 392.936L132.414 392.484L132.498 390.988L132.414 392.484C132.248 392.462 132.082 392.423 131.921 392.369L129.439 391.527C129.273 391.471 129.112 391.398 128.959 391.309L124.479 388.719C124.297 388.614 124.127 388.487 123.973 388.343L120.95 385.507L119.952 384.571L120.676 383.518L133.623 364.671C134.164 363.884 135.136 363.527 136.105 363.76L156.509 368.666L156.536 368.672L156.563 368.68L185.551 376.752L202.291 379.483C202.551 379.526 202.805 379.609 203.045 379.729L202.461 381.07L203.045 379.729L205.699 381.063L205.774 381.1L205.845 381.146L208.617 382.919L208.617 382.919C209.607 383.553 210.095 384.753 209.803 385.837L205.885 400.379C205.575 401.533 204.469 402.233 203.266 402.039L168.405 396.4L135.924 392.94Z"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M208.257 384.341L204.905 397.382C204.781 397.864 204.313 398.15 203.814 398.049L168.633 390.888L134.867 385.811L126.529 381.344L122.748 383.21L136.654 365.456L156.279 369.74L185.462 377.607L193.952 378.992L202.442 380.377L207.699 383.138C208.141 383.369 208.376 383.877 208.257 384.341Z"
                    fill="#8CEC40"
                />
                <path
                    d="M204.986 399.458L204.61 400.474C204.456 400.891 204.033 401.13 203.582 401.054L168.554 395.166L135.639 391.829L130.61 390.742L123.698 386.906L138.238 382.474L150.334 386.123L177.563 390.719L197.322 395.729L200.67 396.864L204.304 398.096C204.87 398.288 205.186 398.919 204.986 399.458Z"
                    fill="#02A608"
                />
                <path
                    d="M145.563 373.224L145.106 371.184C144.952 370.493 145.514 369.908 146.2 370.045L156.076 372.025L185.288 379.609L199.534 381.699C200.402 381.826 200.776 382.931 200.137 383.479L198.73 384.687L197.829 386.188L197.599 387.909L197.59 390.064L198.473 392.873L201.761 398.515L167.399 391.951L136.077 386.004L138.36 385.228L141.275 383.314L142.623 381.602L144.197 379.246L145.328 376.024L145.563 373.224Z"
                    fill="#63D116"
                />
                <circle
                    cx="127.404"
                    cy="375.308"
                    r="12.783"
                    transform="rotate(63.7285 127.404 375.308)"
                    fill="url(#paint13_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M126.64 382.848C132.529 383.717 138.429 380.569 139.234 375.111C140.039 369.653 135.299 364.936 129.41 364.067C123.522 363.199 117.622 366.346 116.817 371.805C116.012 377.263 120.752 381.98 126.64 382.848Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <circle
                    cx="209.363"
                    cy="391.709"
                    r="8.95447"
                    transform="rotate(-149.708 209.363 391.709)"
                    fill="url(#paint14_linear_3379_17863)"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M210.074 388.697C208.1 388.11 206.086 388.111 204.404 388.661C202.728 389.209 201.238 390.367 200.704 392.162C200.171 393.956 200.787 395.74 201.891 397.114C203 398.494 204.686 399.595 206.66 400.182C208.634 400.769 210.647 400.768 212.33 400.218C214.005 399.67 215.496 398.512 216.03 396.717C216.563 394.923 215.947 393.139 214.843 391.765C213.734 390.385 212.048 389.284 210.074 388.697Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M224.74 390.961C225.552 391.184 226.574 391.157 227.734 390.98C228.122 391.171 228.534 391.27 228.924 391.315C229.568 391.389 230.267 391.33 230.972 391.2C232.382 390.94 234.081 390.343 235.938 389.529C239.658 387.899 244.287 385.272 249.038 382.193C257.033 377.722 261.265 374.563 263.3 372.132C264.337 370.893 264.912 369.716 265.004 368.544C265.098 367.342 264.665 366.401 264.214 365.706C263.582 364.731 262.58 364.177 261.352 364.019C260.186 363.87 258.788 364.068 257.153 364.548C253.881 365.509 249.162 367.758 242.433 371.735C237.022 374.584 230.415 379.25 226.496 382.997C225.51 383.94 224.659 384.858 224.033 385.701C223.452 386.484 222.892 387.426 222.822 388.382C222.782 388.921 222.895 389.526 223.305 390.051C223.698 390.556 224.238 390.822 224.74 390.961Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M244.078 379.127C244.919 380.245 245.994 380.901 247.252 381.065C248.44 381.219 249.627 380.913 250.715 380.463C252.399 379.767 254.284 378.522 256.137 377.298C256.617 376.981 257.094 376.665 257.565 376.361C260.084 374.735 262.183 372.861 263.526 371.099C264.193 370.223 264.726 369.308 264.995 368.409C265.262 367.518 265.322 366.439 264.712 365.495C264.103 364.55 263.095 364.16 262.173 364.037C261.243 363.912 260.19 364.02 259.117 364.267C256.958 364.765 254.386 365.906 251.867 367.532C250.77 368.24 249.529 368.806 248.249 369.381C248.193 369.406 248.137 369.432 248.08 369.457C246.905 369.984 245.647 370.548 244.659 371.223C243.612 371.938 242.577 372.97 242.393 374.521C242.216 376.014 242.887 377.542 244.078 379.127Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M252.663 373.554C253.343 374.493 254.099 375.199 254.976 375.6C255.885 376.016 256.788 376.044 257.625 375.855C258.422 375.675 259.157 375.3 259.795 374.92C260.218 374.669 260.674 374.367 261.104 374.083C261.304 373.951 261.498 373.823 261.68 373.705C263.177 372.739 264.321 371.417 264.921 369.994C265.517 368.58 265.622 366.904 264.712 365.495C263.803 364.085 262.232 363.491 260.698 363.452C259.154 363.412 257.478 363.91 255.982 364.876C255.775 365.009 255.512 365.172 255.234 365.343C254.829 365.593 254.392 365.863 254.053 366.09C253.415 366.518 252.715 367.049 252.191 367.732C251.636 368.457 251.273 369.362 251.357 370.449C251.437 371.483 251.908 372.511 252.663 373.554Z"
                    fill="#D9D9D9"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M275.835 378.862L275.82 378.923L275.799 378.982C275.791 379.005 275.786 379.023 275.783 379.036C275.788 379.045 275.792 379.05 275.8 379.06C275.813 379.077 275.837 379.11 275.898 379.201L275.9 379.204C275.933 379.254 276.038 379.409 276.104 379.616C276.18 379.855 276.259 380.34 275.93 380.871C275.64 381.339 275.209 381.543 275.034 381.617C274.832 381.701 274.641 381.745 274.517 381.771C274.259 381.824 273.93 381.864 273.565 381.905C273.265 381.938 272.89 381.975 272.437 382.021C272.314 382.034 272.186 382.047 272.051 382.06C271.416 382.125 270.634 382.205 269.674 382.31L269.562 382.323L269.45 382.32C265.7 382.218 261.693 382.077 258.708 381.686C257.236 381.493 255.878 381.225 254.904 380.809C254.424 380.604 253.9 380.307 253.538 379.849C253.127 379.33 252.957 378.635 253.222 377.874C253.429 377.281 253.846 376.77 254.256 376.369C254.685 375.948 255.217 375.541 255.808 375.159C256.992 374.394 258.534 373.653 260.284 373.053C263.777 371.857 268.291 371.164 272.698 372.138C274.709 372.582 276.078 372.966 276.901 373.38C277.3 373.581 277.795 373.896 278.06 374.427C278.388 375.083 278.219 375.744 277.949 376.215C277.726 376.603 277.402 376.927 277.204 377.12C277.097 377.225 277.009 377.308 276.927 377.386C276.805 377.501 276.698 377.604 276.563 377.742C276.174 378.14 275.932 378.482 275.835 378.862ZM275.779 379.062C275.779 379.062 275.779 379.061 275.779 379.059C275.779 379.061 275.778 379.062 275.779 379.062ZM273.236 380.92C273.234 380.917 273.232 380.915 273.23 380.912C273.239 380.926 273.242 380.93 273.236 380.92Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M274.008 379.596C274.584 377.129 281.313 376.654 273.204 374.862C265.096 373.07 254.899 375.037 254.611 378.416C254.023 380.104 262.097 380.729 269.531 381.204C277.096 380.83 273.42 381.284 274.008 379.596Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M273.401 381.339C273.397 378.385 280.279 378.428 272.556 376.725C266.632 375.419 257.591 373.903 254.588 378.432C253.902 380.317 262.163 380.731 269.527 381.359C272.975 381.273 272.666 381.333 273.401 381.339Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M274.53 380.62L274.525 380.559L274.526 380.497C274.555 378.908 274.702 377.596 275.053 376.572C275.414 375.52 276.081 374.54 277.285 374.046C278.362 373.603 279.499 373.719 280.451 373.923C281.432 374.134 282.549 374.519 283.747 374.954C285.01 375.315 285.975 375.758 286.634 376.332C287.35 376.954 287.68 377.714 287.648 378.543C287.62 379.293 287.296 379.961 287.052 380.41C286.961 380.578 286.858 380.755 286.773 380.9C286.744 380.949 286.718 380.994 286.695 381.034C286.588 381.219 286.523 381.34 286.482 381.434C286.482 381.433 286.482 381.433 286.482 381.433C286.483 381.433 286.465 381.479 286.45 381.603C286.434 381.736 286.428 381.89 286.422 382.108C286.421 382.134 286.421 382.163 286.42 382.194C286.413 382.548 286.399 383.196 286.162 383.776C286.011 384.143 285.75 384.553 285.302 384.893C284.856 385.23 284.35 385.404 283.859 385.478C282.954 385.615 281.882 385.443 280.647 385.095C278.275 384.426 276.767 383.862 275.859 383.189C275.362 382.821 275.015 382.402 274.803 381.904C274.604 381.436 274.557 380.971 274.53 380.62Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M284.811 381.542C285.333 380.356 287.365 379.383 282.333 377.725C277.146 375.903 276.182 374.734 275.976 380.626C276.158 381.778 276.479 382.376 281.251 383.722C286.023 385.067 284.289 382.728 284.811 381.542Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M284.347 382.598C284.674 381.854 285.948 380.834 281.364 379.106C275.305 376.675 276.177 377.284 275.742 381.16C276.084 381.947 276.48 382.376 281.251 383.722C286.023 385.068 284.02 383.342 284.347 382.598Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M285.297 384.448L285.311 384.391L285.33 384.334C285.862 382.775 286.456 381.542 287.225 380.694C287.626 380.252 288.12 379.864 288.737 379.61C289.371 379.348 290.021 379.276 290.637 379.331C291.764 379.433 292.804 379.97 293.684 380.504C294.377 380.924 295.145 381.456 295.947 382.012C296.19 382.181 296.436 382.351 296.684 382.522C299.164 383.978 300.879 385.973 301.84 387.782C302.323 388.692 302.639 389.598 302.745 390.408C302.84 391.133 302.813 392.105 302.14 392.863C301.399 393.696 300.281 393.905 299.413 393.908C298.538 393.91 297.586 393.716 296.65 393.434C294.756 392.863 292.487 391.798 290.179 390.455C287.938 389.151 286.567 388.191 285.849 387.29C285.456 386.798 285.236 386.301 285.176 385.765C285.119 385.262 285.215 384.8 285.297 384.448Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M300.652 392.289C301.516 391.317 299.168 387.334 294.662 384.712C289.087 380.809 288.849 379.242 286.641 384.917C286.507 386.056 286.669 386.711 291.175 389.334C295.682 391.956 299.788 393.261 300.652 392.289Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M300.652 392.289C301.32 391.537 298.287 388.327 293.78 385.704C287.259 381.739 288.606 380.225 286.406 385.182C286.432 386.14 286.669 386.711 291.175 389.334C296.812 392.614 299.984 393.041 300.652 392.289Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M279.835 381.862L279.82 381.923L279.799 381.982C279.791 382.005 279.786 382.023 279.783 382.036C279.788 382.045 279.792 382.05 279.8 382.06C279.813 382.077 279.837 382.11 279.898 382.201L279.9 382.204C279.933 382.254 280.038 382.409 280.104 382.616C280.18 382.855 280.259 383.34 279.93 383.871C279.64 384.339 279.209 384.543 279.034 384.617C278.832 384.701 278.641 384.745 278.517 384.771C278.259 384.824 277.93 384.864 277.565 384.905C277.265 384.938 276.89 384.975 276.437 385.021C276.314 385.034 276.186 385.047 276.051 385.06C275.416 385.125 274.634 385.205 273.674 385.31L273.562 385.323L273.45 385.32C269.7 385.218 265.693 385.077 262.708 384.686C261.236 384.493 259.878 384.225 258.904 383.809C258.424 383.604 257.9 383.307 257.538 382.849C257.127 382.33 256.957 381.635 257.222 380.874C257.429 380.281 257.846 379.77 258.256 379.369C258.685 378.948 259.217 378.541 259.808 378.159C260.992 377.394 262.534 376.653 264.284 376.053C267.777 374.857 272.291 374.164 276.698 375.138C278.709 375.582 280.078 375.966 280.901 376.38C281.3 376.581 281.795 376.896 282.06 377.427C282.388 378.083 282.219 378.744 281.949 379.215C281.726 379.603 281.402 379.927 281.204 380.12C281.097 380.225 281.009 380.308 280.927 380.386C280.805 380.501 280.698 380.604 280.563 380.742C280.174 381.14 279.932 381.482 279.835 381.862ZM279.779 382.062C279.779 382.062 279.779 382.061 279.779 382.059C279.779 382.061 279.778 382.062 279.779 382.062ZM277.236 383.92C277.234 383.917 277.232 383.915 277.23 383.912C277.239 383.926 277.242 383.93 277.236 383.92Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M278.008 382.596C278.584 380.129 285.313 379.654 277.204 377.862C269.096 376.07 258.899 378.037 258.611 381.416C258.023 383.104 266.097 383.729 273.531 384.204C281.096 383.83 277.42 384.284 278.008 382.596Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M277.401 384.339C277.397 381.385 284.279 381.428 276.556 379.725C270.632 378.419 261.591 376.903 258.588 381.432C257.902 383.317 266.163 383.731 273.527 384.359C276.975 384.273 276.666 384.333 277.401 384.339Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M278.53 383.62L278.525 383.559L278.526 383.497C278.555 381.908 278.702 380.596 279.053 379.572C279.414 378.52 280.081 377.54 281.285 377.046C282.362 376.603 283.499 376.719 284.451 376.923C285.432 377.134 286.549 377.519 287.747 377.954C289.01 378.315 289.975 378.758 290.634 379.332C291.35 379.954 291.68 380.714 291.648 381.543C291.62 382.293 291.296 382.961 291.052 383.41C290.961 383.578 290.858 383.755 290.773 383.9C290.744 383.949 290.718 383.994 290.695 384.034C290.588 384.219 290.523 384.34 290.482 384.434C290.482 384.433 290.482 384.433 290.482 384.433C290.483 384.433 290.465 384.479 290.45 384.603C290.434 384.736 290.428 384.89 290.422 385.108C290.421 385.134 290.421 385.163 290.42 385.194C290.413 385.548 290.399 386.196 290.162 386.776C290.011 387.143 289.75 387.553 289.302 387.893C288.856 388.23 288.35 388.404 287.859 388.478C286.954 388.615 285.882 388.443 284.647 388.095C282.275 387.426 280.767 386.862 279.859 386.189C279.362 385.821 279.015 385.402 278.803 384.904C278.604 384.436 278.557 383.971 278.53 383.62Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M288.811 384.542C289.333 383.356 291.365 382.383 286.333 380.725C281.146 378.903 280.182 377.734 279.976 383.626C280.158 384.778 280.479 385.376 285.251 386.722C290.023 388.067 288.289 385.728 288.811 384.542Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M288.347 385.598C288.674 384.854 289.948 383.834 285.364 382.106C279.305 379.675 280.177 380.284 279.742 384.16C280.084 384.947 280.48 385.376 285.251 386.722C290.023 388.068 288.02 386.342 288.347 385.598Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M289.297 387.448L289.311 387.391L289.33 387.334C289.862 385.775 290.456 384.542 291.225 383.694C291.626 383.252 292.12 382.864 292.737 382.61C293.371 382.348 294.021 382.276 294.637 382.331C295.764 382.433 296.804 382.97 297.684 383.504C298.377 383.924 299.145 384.456 299.947 385.012C300.19 385.181 300.436 385.351 300.684 385.522C303.164 386.978 304.879 388.973 305.84 390.782C306.323 391.692 306.639 392.598 306.745 393.408C306.84 394.133 306.813 395.105 306.14 395.863C305.399 396.696 304.281 396.905 303.413 396.908C302.538 396.91 301.586 396.716 300.65 396.434C298.756 395.863 296.487 394.798 294.179 393.455C291.938 392.151 290.567 391.191 289.849 390.29C289.456 389.798 289.236 389.301 289.176 388.765C289.119 388.262 289.215 387.8 289.297 387.448Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M304.652 395.289C305.516 394.317 303.168 390.334 298.662 387.712C293.087 383.809 292.849 382.242 290.641 387.917C290.507 389.056 290.669 389.711 295.175 392.334C299.682 394.956 303.788 396.261 304.652 395.289Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M304.652 395.289C305.32 394.537 302.287 391.327 297.78 388.704C291.259 384.739 292.606 383.225 290.406 388.182C290.432 389.14 290.669 389.711 295.175 392.334C300.812 395.614 303.984 396.041 304.652 395.289Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M241.073 374.253L241.1 374.25L241.127 374.247C250.095 373.087 254.937 374.966 257.741 377.974C260.642 381.084 261.718 385.742 262.326 390.974C262.655 393.806 262.159 396.119 261.092 398.022C260.018 399.939 258.31 401.538 256.069 402.833C251.552 405.442 245.071 406.696 238.146 406.84C231.36 406.982 225.247 405.273 220.851 402.473C216.445 399.667 213.911 395.881 213.827 391.845C213.746 387.966 216.815 383.914 222.12 380.549C227.337 377.239 234.308 374.878 241.073 374.253Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M261.203 393.442C260.343 401.51 248.346 406.359 235.117 405.502C222.339 403.811 215.469 398.454 215.343 392.428C215.261 388.486 214.86 393.424 225.783 385.801C236.934 378.018 263.309 373.678 261.203 393.442Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M260.792 389.654C259.942 396.868 252.975 398.513 239.794 399.289C226.951 399.557 215.408 395.681 215.302 390.604C217.777 383.961 225.528 379.582 239.356 378.293C255.819 377.95 261.457 384 260.792 389.654Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M275.869 388.598L275.859 388.653L275.846 388.708C275.831 388.77 275.826 388.811 275.824 388.835C275.823 388.851 275.824 388.861 275.824 388.867C275.824 388.867 275.824 388.867 275.825 388.868C275.839 388.894 275.842 388.899 275.853 388.915C275.866 388.933 275.89 388.966 275.953 389.062L275.955 389.065C275.989 389.118 276.094 389.279 276.165 389.49C276.246 389.735 276.325 390.181 276.09 390.673C275.875 391.124 275.518 391.352 275.338 391.449C275.148 391.552 274.963 391.61 274.842 391.644C274.596 391.714 274.283 391.772 273.946 391.83C273.667 391.878 273.319 391.934 272.9 392.001C272.786 392.019 272.668 392.038 272.544 392.058C271.956 392.153 271.234 392.271 270.347 392.422L270.227 392.442L270.105 392.444C266.613 392.486 262.862 392.498 260.046 392.198C258.661 392.051 257.351 391.816 256.388 391.395C255.907 391.185 255.378 390.872 255.002 390.379C254.579 389.824 254.419 389.128 254.6 388.398C254.746 387.811 255.08 387.279 255.435 386.839C255.803 386.382 256.269 385.93 256.797 385.498C257.854 384.633 259.249 383.775 260.853 383.06C264.047 381.633 268.256 380.691 272.476 381.569C274.37 381.963 275.696 382.323 276.515 382.75C276.924 382.963 277.423 383.299 277.703 383.864C278.028 384.521 277.907 385.163 277.71 385.615C277.538 386.007 277.272 386.346 277.096 386.564C277.005 386.676 276.929 386.768 276.857 386.855C276.748 386.986 276.65 387.103 276.531 387.259C276.186 387.707 275.95 388.121 275.869 388.598ZM273.389 390.726C273.387 390.723 273.385 390.72 273.384 390.718C273.392 390.731 273.394 390.734 273.389 390.726Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M274.125 389.419C274.525 386.755 280.762 385.975 273.117 384.384C265.473 382.794 256.092 385.312 256.01 388.941C255.556 390.771 263.103 391.114 270.048 391.321C277.067 390.615 273.671 391.249 274.125 389.419Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M273.656 391.309C273.49 388.148 279.897 387.916 272.616 386.405C267.032 385.246 258.535 383.989 255.99 388.959C255.455 391.004 263.166 391.114 270.052 391.487C273.256 391.256 272.972 391.333 273.656 391.309Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M274.597 390.385L274.59 390.326L274.588 390.266C274.528 388.567 274.594 387.179 274.857 386.101C275.121 385.015 275.654 383.985 276.729 383.417C277.736 382.884 278.847 382.965 279.772 383.15C280.712 383.34 281.786 383.711 282.92 384.127C284.13 384.467 285.083 384.914 285.752 385.524C286.484 386.19 286.815 386.995 286.83 387.834C286.843 388.595 286.594 389.286 286.395 389.768C286.285 390.036 286.199 390.225 286.129 390.379C286.038 390.579 285.974 390.722 285.915 390.904C285.885 390.996 285.87 391.188 285.885 391.701C285.885 391.729 285.886 391.76 285.887 391.792C285.9 392.178 285.921 392.837 285.744 393.43C285.634 393.795 285.432 394.21 285.057 394.569C284.676 394.934 284.213 395.148 283.734 395.253C282.856 395.446 281.808 395.295 280.628 394.969C278.384 394.349 276.912 393.797 275.998 393.089C275.493 392.698 275.142 392.25 274.917 391.727C274.709 391.24 274.641 390.754 274.597 390.385Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M284.286 391.065C284.706 389.775 286.544 388.651 281.77 387.08C276.842 385.34 275.881 384.127 276.013 390.443C276.246 391.668 276.578 392.295 281.092 393.542C285.607 394.79 283.865 392.356 284.286 391.065Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M283.912 392.215C284.176 391.405 285.305 390.262 280.944 388.597C275.172 386.24 276.017 386.857 275.825 391.023C276.187 391.852 276.578 392.295 281.093 393.543C285.607 394.79 283.648 393.024 283.912 392.215Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M283.136 392.704L283.145 392.644L283.159 392.584C283.569 390.893 284.051 389.559 284.712 388.631C285.056 388.15 285.488 387.72 286.041 387.427C286.61 387.125 287.214 387.017 287.803 387.053C288.878 387.117 289.892 387.661 290.743 388.198C291.414 388.622 292.161 389.163 292.939 389.726C293.173 389.896 293.409 390.067 293.648 390.238C296.05 391.706 297.764 393.784 298.76 395.688C299.262 396.647 299.607 397.607 299.751 398.472C299.88 399.251 299.903 400.279 299.332 401.097C298.707 401.992 297.695 402.268 296.869 402.306C296.046 402.345 295.141 402.173 294.249 401.907C292.444 401.37 290.266 400.317 288.041 398.971C285.884 397.666 284.542 396.686 283.814 395.738C283.415 395.218 283.181 394.687 283.095 394.115C283.014 393.579 283.078 393.083 283.136 392.704Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M297.881 400.52C298.631 399.445 296.228 395.277 291.89 392.652C286.488 388.7 286.18 387.032 284.438 393.196C284.375 394.421 284.562 395.116 288.9 397.74C293.238 400.365 297.131 401.596 297.881 400.52Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M297.881 400.521C298.461 399.689 295.462 396.375 291.124 393.75C284.838 389.769 286.008 388.094 284.233 393.49C284.31 394.514 284.562 395.116 288.9 397.741C294.325 401.024 297.3 401.353 297.881 400.521Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M269.155 398.005L269.145 398.058L269.132 398.109C269.115 398.172 269.111 398.212 269.11 398.232C269.112 398.236 269.114 398.239 269.117 398.244C269.125 398.256 269.143 398.282 269.203 398.389L269.204 398.391C269.235 398.445 269.328 398.609 269.388 398.822C269.457 399.068 269.513 399.496 269.292 399.955C269.086 400.382 268.753 400.597 268.572 400.693C268.387 400.79 268.209 400.843 268.096 400.873C267.866 400.933 267.58 400.978 267.285 401.021C267.04 401.057 266.733 401.097 266.366 401.146C266.266 401.159 266.163 401.173 266.055 401.188C265.541 401.256 264.909 401.342 264.133 401.453L264.008 401.471L263.882 401.468C260.84 401.392 257.555 401.277 255.089 400.902C253.88 400.718 252.71 400.45 251.844 400.007C251.408 399.784 250.92 399.452 250.581 398.944C250.202 398.375 250.086 397.698 250.264 397.02C250.407 396.472 250.716 395.982 251.041 395.579C251.378 395.16 251.801 394.749 252.277 394.36C253.23 393.582 254.48 392.821 255.91 392.201C258.754 390.968 262.501 390.212 266.23 391.19C267.867 391.619 269.048 392.007 269.786 392.453C270.16 392.679 270.623 393.037 270.87 393.615C271.15 394.27 271.019 394.878 270.835 395.293C270.673 395.658 270.428 395.97 270.269 396.168C270.185 396.272 270.116 396.356 270.051 396.434C269.953 396.551 269.866 396.656 269.759 396.796C269.451 397.197 269.237 397.57 269.155 398.005Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M267.435 398.668C267.853 396.198 273.309 395.678 266.687 393.941C260.066 392.205 251.823 394.24 251.657 397.62C251.214 399.311 257.784 399.881 263.831 400.306C269.967 399.881 266.991 400.359 267.435 398.668Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M266.977 400.414C266.915 397.462 272.505 397.458 266.198 395.808C261.361 394.542 253.987 393.088 251.639 397.636C251.12 399.525 257.838 399.883 263.831 400.46C266.629 400.351 266.38 400.414 266.977 400.414Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M264.695 399.014L264.69 398.955L264.69 398.897C264.682 397.309 264.775 396.012 265.034 395.009C265.292 394.008 265.786 393.04 266.78 392.524C267.73 392.031 268.761 392.154 269.586 392.365C270.42 392.577 271.36 392.965 272.334 393.388C273.395 393.752 274.249 394.216 274.849 394.84C275.509 395.526 275.781 396.325 275.772 397.121C275.764 397.841 275.526 398.484 275.339 398.927C275.235 399.176 275.155 399.35 275.09 399.492C275.007 399.673 274.948 399.801 274.892 399.967C274.866 400.044 274.848 400.214 274.848 400.693C274.847 400.72 274.848 400.748 274.848 400.778C274.849 401.138 274.85 401.761 274.677 402.315C274.571 402.655 274.381 403.045 274.033 403.379C273.676 403.72 273.242 403.919 272.787 404.008C271.959 404.169 271.001 403.977 269.97 403.631C268.031 402.978 266.719 402.403 265.902 401.677C265.446 401.273 265.135 400.818 264.945 400.301C264.77 399.825 264.724 399.358 264.695 399.014Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M273.296 400.006C273.696 398.817 275.327 397.83 271.207 396.207C266.957 394.421 266.151 393.259 266.102 399.15C266.273 400.301 266.546 400.896 270.448 402.209C274.351 403.522 272.896 401.195 273.296 400.006Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M272.94 401.065C273.191 400.319 274.206 399.291 270.448 397.594C265.478 395.205 266.198 395.808 265.922 399.685C266.216 400.469 266.546 400.896 270.449 402.209C274.351 403.522 272.69 401.81 272.94 401.065Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M273.578 402.821L273.588 402.76L273.603 402.699C274.005 401.133 274.465 399.888 275.078 399.028C275.397 398.581 275.797 398.181 276.309 397.914C276.837 397.639 277.395 397.553 277.938 397.609C278.924 397.71 279.828 398.277 280.558 398.808C281.137 399.229 281.781 399.764 282.445 400.316C282.642 400.479 282.841 400.644 283.04 400.808C285.124 402.279 286.58 404.297 287.409 406.126C287.827 407.048 288.107 407.97 288.212 408.799C288.306 409.542 288.303 410.54 287.759 411.319C287.162 412.174 286.226 412.408 285.474 412.417C284.727 412.426 283.92 412.228 283.14 411.947C281.56 411.379 279.674 410.316 277.763 408.982C275.918 407.695 274.747 406.719 274.116 405.78C273.767 405.261 273.567 404.734 273.504 404.172C273.446 403.649 273.518 403.173 273.578 402.821Z"
                    fill="#FEFEFE"
                    stroke="black"
                    strokeWidth="3"
                />
                <path
                    d="M286.377 410.642C287.059 409.664 285.073 405.699 281.36 403.108C276.754 399.244 276.53 397.679 274.85 403.367C274.764 404.507 274.909 405.161 278.621 407.752C282.334 410.343 285.695 411.619 286.377 410.642Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M286.377 410.642C286.905 409.886 284.377 406.697 280.664 404.106C275.289 400.186 276.352 398.663 274.664 403.634C274.705 404.591 274.909 405.161 278.621 407.752C283.265 410.992 285.85 411.398 286.377 410.642Z"
                    fill="#A1A1A1"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_3379_17863"
                        x1="171.601"
                        y1="309.141"
                        x2="147.432"
                        y2="317.899"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_3379_17863"
                        x1="173.5"
                        y1="299"
                        x2="241"
                        y2="359"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#58C317" />
                        <stop offset="0.0001" stop-color="#69D51C" />
                        <stop offset="1" stop-color="#58C317" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_3379_17863"
                        x1="22.6588"
                        y1="14.6338"
                        x2="-1.51023"
                        y2="23.3922"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_3379_17863"
                        x1="312"
                        y1="383.334"
                        x2="303.068"
                        y2="389.445"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_3379_17863"
                        x1="358.259"
                        y1="315.541"
                        x2="352.357"
                        y2="319.578"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_3379_17863"
                        x1="238.88"
                        y1="267.386"
                        x2="244.353"
                        y2="280.514"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#88DDF0" />
                        <stop offset="1" stop-color="#01B5D1" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_3379_17863"
                        x1="208.11"
                        y1="271.2"
                        x2="215.237"
                        y2="289.407"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D5FE73" />
                        <stop offset="1" stop-color="#9DE00A" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_3379_17863"
                        x1="175.646"
                        y1="280.383"
                        x2="190.671"
                        y2="282.563"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#02AA09" />
                        <stop offset="1" stop-color="#149A00" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_3379_17863"
                        x1="212.219"
                        y1="281.181"
                        x2="214.741"
                        y2="284.325"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6DE1D5" />
                        <stop offset="1" stop-color="#10BAD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_3379_17863"
                        x1="210.478"
                        y1="282.975"
                        x2="218.329"
                        y2="287.808"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#25C0C9" />
                        <stop offset="1" stop-color="#8CE2D5" />
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_3379_17863"
                        x1="216.924"
                        y1="278.918"
                        x2="218.979"
                        y2="288.717"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#028AB5" />
                        <stop offset="1" stop-color="#12ADC4" />
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_3379_17863"
                        x1="8.87775"
                        y1="69.8363"
                        x2="342.238"
                        y2="281.912"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A4ED6B" />
                        <stop offset="1" stop-color="#53C201" />
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_3379_17863"
                        x1="22.7715"
                        y1="77.2022"
                        x2="328.598"
                        y2="271.645"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#32E1FC" />
                        <stop offset="1" stop-color="#00B7D6" />
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_3379_17863"
                        x1="138.687"
                        y1="378.599"
                        x2="129.755"
                        y2="384.71"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint14_linear_3379_17863"
                        x1="216.818"
                        y1="393.883"
                        x2="210.916"
                        y2="397.921"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
