import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import { Button, CardContent, IconButton, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import stls from '@f_admin/styles/components/userInfo/EditApplicationUCSModal.module.sass';
import { isEmpty } from 'lodash';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { Alert } from '@f_general/alert';
import GeneralForm from '@f_general/GeneralForm';
import { IconAddModal } from '../icons/IconAddModal';
import AddIcon from '@mui/icons-material/Add';
import { randomKey } from '@f_utils/randomKey';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

type TypeEditProps = {
    open: boolean;
    handleClose: any;
    UCSAppInfo: any;
    currentApplication: any;
    currentWorkspace: any;
};

type TypeKeysArray = {
    key: string;
    alias: string;
};

type TypeKeyType = 'web' | '1c';

export function EditApplicationUCSModal({
    open,
    handleClose,
    UCSAppInfo,
    currentApplication,
    currentWorkspace,
}: TypeEditProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { updateUCSApp } = useContext(UserAccountContext);

    const [loading, setLoading] = useState(false);

    //1c
    const [open1cKeys, setOpen1cKeys] = useState(false);
    const [keys1c, setKeys1c] = useState([{ id: randomKey(), key: '', alias: '' }]);
    //web
    const [openWebKeys, setOpenWebKeys] = useState(false);
    const [webKeys, setWebKeys] = useState([{ id: randomKey(), key: '', alias: '' }]);

    useEffect(() => {
        if (open) {
            if (UCSAppInfo.keys1c.length) {
                setOpen1cKeys(true);
                setKeys1c(UCSAppInfo.keys1c?.map((item: TypeKeysArray) => ({ ...item, id: randomKey() })));
            }
            if (UCSAppInfo.keysWeb.length) {
                setOpenWebKeys(true);
                setWebKeys(UCSAppInfo.keysWeb?.map((item: TypeKeysArray) => ({ ...item, id: randomKey() })));
            }
        }
    }, [open]);

    const closeModal = () => {
        handleClose();
        setKeys1c([{ id: randomKey(), key: '', alias: '' }]);
        setWebKeys([{ id: randomKey(), key: '', alias: '' }]);
    };

    const changeRowHandle = (e: string, id: number, alias: boolean, type: TypeKeyType) => {
        if (type === '1c') {
            setKeys1c(
                keys1c.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              ...(alias ? { alias: e } : { key: e }),
                          }
                        : item,
                ),
            );
        }
        if (type === 'web') {
            setWebKeys(
                webKeys.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              ...(alias ? { alias: e } : { key: e }),
                          }
                        : item,
                ),
            );
        }
    };

    const addRowHandler = (type: TypeKeyType) => {
        if (type === 'web') {
            setWebKeys([
                ...webKeys,
                {
                    id: randomKey(),
                    key: '',
                    alias: '',
                },
            ]);
        }
        if (type === '1c') {
            setKeys1c([
                ...keys1c,
                {
                    id: randomKey(),
                    key: '',
                    alias: '',
                },
            ]);
        }
    };

    const deleteRowHandler = (id: number, type: TypeKeyType) => {
        if (type === '1c') {
            setKeys1c(keys1c.filter((item) => item.id !== id));
        }
        if (type === 'web') {
            setWebKeys(webKeys.filter((item) => item.id !== id));
        }
    };

    const keysHandler = (value: boolean, type: TypeKeyType) => {
        if (value) {
            if (type === '1c') {
                setOpen1cKeys(true);
            }
            if (type === 'web') {
                setOpenWebKeys(true);
            }
        } else {
            if (type === '1c') {
                setOpen1cKeys(false);
                setKeys1c([{ id: randomKey(), key: '', alias: '' }]);
            }
            if (type === 'web') {
                setOpenWebKeys(false);
                setWebKeys([{ id: randomKey(), key: '', alias: '' }]);
            }
        }
    };

    const sendDataHandler = async (values: any) => {
        setLoading(true);
        let keys1cArray: TypeKeysArray[] = [];
        let keysWebArray: TypeKeysArray[] = [];
        if (open1cKeys) {
            if (keys1c.some((item) => !item.key || !item.alias)) {
                return Alert({
                    type: 'warning',
                    text: 'Заполните все ключи или псевдонимы 1с',
                });
            } else {
                keys1cArray = keys1c.map((item) => ({ key: item.key, alias: item.alias }));
            }
        }
        if (openWebKeys) {
            if (webKeys.some((item) => !item.key || !item.alias)) {
                return Alert({
                    type: 'warning',
                    text: 'Заполните все ключи или псевдонимы WEB',
                });
            } else {
                keysWebArray = webKeys.map((item) => ({ key: item.key, alias: item.alias }));
            }
        }

        const resultEditUCSApp = await updateUCSApp({
            applicationWorkspaceId: currentApplication.applicationWorkspaceId,
            workspaceId: currentWorkspace.workspaceId,
            workspaceName: currentWorkspace.workspaceName,
            paidTime: values.paidTime,
            authorizationCode: values.authorizationCode,
            keys1c: keys1cArray,
            keysWeb: keysWebArray,
        });
        if (resultEditUCSApp.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });

            closeModal();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoading(false);
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <div className={stls.modalTitle}>
                            <IconAddModal />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {t('adminPanel.changeTariff')}
                            </Typography>
                        </div>
                    </div>

                    <div className={stls.contentUCSModel}>
                        <GeneralForm
                            sendDataHandler={sendDataHandler}
                            close={closeModal}
                            actions={
                                <div className={stls.action}>
                                    <Button className={stls.modalButton} onClick={closeModal}>
                                        {t('modal.cancel')}
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        className={stls.modalButton}
                                        variant={'contained'}
                                        type="submit">
                                        {t('modal.success')}
                                    </Button>
                                </div>
                            }
                            mainData={{
                                icon: '',
                                title: t('adminPanel.changePeriod'),
                                props: [
                                    {
                                        title: t('adminPanel.periodEnd'),
                                        control: 'inputDate',
                                        name: 'paidTime',
                                        type: 'data',
                                        defaultValue: UCSAppInfo?.paidTime,
                                    },
                                    {
                                        title: t('adminPanel.authCode'),
                                        control: 'input',
                                        name: 'authorizationCode',
                                        type: 'text',
                                        defaultValue: UCSAppInfo?.authorizationCode,
                                    },
                                ],
                                shema: null,
                                type: 'editApp',
                                data: {
                                    paidTime: UCSAppInfo?.paidTime,
                                    authorizationCode: UCSAppInfo?.authorizationCode,
                                    type: 'editUCS',
                                },
                            }}
                            loading={false}
                        />
                        <Button
                            variant={'contained'}
                            className={stls.addKeyBtn}
                            onClick={() => keysHandler(!open1cKeys, '1c')}>
                            {open1cKeys ? 'Удалить 1c ключи' : 'Добавить 1с ключи'}
                        </Button>
                        {open1cKeys && (
                            <div className={stls.keys1c}>
                                {keys1c.map((item) => {
                                    return (
                                        <div key={item.id} className={stls.rowAddKey}>
                                            <TextField
                                                label={'Ключ'}
                                                type="text"
                                                name="key"
                                                sx={{ minWidth: '300px' }}
                                                value={item.key}
                                                focused
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    changeRowHandle(e.target.value, item.id, false, '1c')
                                                }
                                            />
                                            <TextField
                                                label={'Псевдоним'}
                                                type="text"
                                                name="alias"
                                                sx={{ minWidth: '200px' }}
                                                value={item.alias}
                                                focused
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    changeRowHandle(e.target.value, item.id, true, '1c')
                                                }
                                            />
                                            <IconButton
                                                className={stls.btnDeleteKey}
                                                onClick={() => deleteRowHandler(item.id, '1c')}
                                                disabled={keys1c.length === 1}>
                                                <DoDisturbOnIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <Button
                                    className={stls.addBtn}
                                    sx={{ background: colors.gamma }}
                                    onClick={() => addRowHandler('1c')}>
                                    <AddIcon sx={{ color: colors.tau }} />
                                </Button>
                            </div>
                        )}
                        <Button
                            variant={'contained'}
                            className={stls.addKeyBtn}
                            onClick={() => keysHandler(!openWebKeys, 'web')}>
                            {openWebKeys ? 'Удалить web ключи' : 'Добавить web ключи'}
                        </Button>

                        {openWebKeys && (
                            <div className={stls.keys1c}>
                                {webKeys.map((item) => {
                                    return (
                                        <div key={item.id} className={stls.rowAddKey}>
                                            <TextField
                                                label={'Ключ'}
                                                type="text"
                                                name="key"
                                                sx={{ minWidth: '300px' }}
                                                value={item.key}
                                                focused
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    changeRowHandle(e.target.value, item.id, false, 'web')
                                                }
                                            />
                                            <TextField
                                                label={'Псевдоним'}
                                                type="text"
                                                name="alias"
                                                sx={{ minWidth: '200px' }}
                                                value={item.alias}
                                                focused
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    changeRowHandle(e.target.value, item.id, true, 'web')
                                                }
                                            />
                                            <IconButton
                                                className={stls.btnDeleteKey}
                                                onClick={() => deleteRowHandler(item.id, 'web')}
                                                disabled={webKeys.length === 1}>
                                                <DoDisturbOnIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                                <Button
                                    className={stls.addBtn}
                                    sx={{ background: colors.gamma }}
                                    onClick={() => addRowHandler('web')}>
                                    <AddIcon sx={{ color: colors.tau }} />
                                </Button>
                            </div>
                        )}
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
}
