import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconWebProps = {
    color?: string;
} & TypeClassNames;

export const IconAddCollector = ({ classNames, color }: TypeIconWebProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)} style={{ display: 'flex' }}>
            <svg width="18" height="21" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 0H10.6406L15.4286 5.10026V10.0435H13.7143V7.30435H8.57143V1.82609H1.71429V18.2609H7.71429V20.087H0V0ZM10.2857 2.20409V5.47826H13.3594L10.2857 2.20409ZM14.5714 11.8696V15.5217H18V17.3478H14.5714V21H12.8571V17.3478H9.42857V15.5217H12.8571V11.8696H14.5714Z"
                    fill={color}
                />
                <path d="M12 9H4V11H12V9Z" fill={color} />
                <path d="M9.5 13H4V15H9.5V13Z" fill={color} />
            </svg>
        </div>
    );
};
