import { ThemeContext } from '@f_context/Theme_context';
import styles from '@f_qwep/styles/component/purchasingAssistant/TablePurchasingAssistant.module.sass';
import { IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useContext } from 'react';
import { IconAuthAssistantTable } from '../../../../icons/IconAuthAssistantTable';
import { IconErrorAssistantTable } from '../../../../icons/IconErrorAssistantTable';
import { IconSuccessAssistantTable } from '../../../../icons/IconSuccessAssistantTable';
import { SearchContext } from '@f_qwep/context/Search_context';
import HelpIcon from '@mui/icons-material/Help';
import { Alert } from '@f_general/alert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SettingsContext } from '@f_qwep/context/Settings_context';
import { GeneralContext } from '@f_context/General_context';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserContext } from '@f_context/User_context';
import { CustomTooltip } from '@f_general/CustomTooltip';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { useGetDeals } from '@f_qwep/components/purchasingAssistant/model/hooks/useGetDeals';

type TypeCellConfirmationProps = {
    article: string;
    brand: string;
    confirmedVendor: string;
    confirmedWarehouse: string;
    confirmedAvailability: string;
    authVendor: boolean;
    vendor: string;
    confirmed: boolean;
    type: 'custom' | 'default';
    foundItem: boolean;
    isTemplateItem: boolean;
};

export function AssistantConfirmationColumn({
    confirmedVendor,
    confirmedWarehouse,
    confirmedAvailability,
    authVendor,
    article,
    brand,
    vendor,
    confirmed,
    type,
    foundItem,
    isTemplateItem,
}: TypeCellConfirmationProps) {
    const { colors } = useContext(ThemeContext);
    const navigation = useNavigate();
    const { t } = useTranslation();
    const { userInfo } = useContext(UserContext);
    const { navigatinHandler } = useContext(SettingsContext);
    const { getSearchId, searchResults } = useContext(SearchContext);
    const store = useAssistantStore();
    const { currentAssistantList } = store.assistantList;
    const { handleRemoveCustomListItem } = store.assistantRemoveList;
    const { parentId } = currentAssistantList;

    const { seIsRunAssistantVendorSearch, setAssistantVendor } = useContext(GeneralContext);

    const { mutate: getDeals } = useGetDeals(store);

    const searchHandler = async () => {
        const resultSearchId = await getSearchId({ article, brand });
        if (resultSearchId.result.entity?.searchId) {
            await searchResults(resultSearchId.tabId, resultSearchId.result.entity?.searchId, article, brand);
        } else if (resultSearchId.result.errors) {
            if (resultSearchId.result.errors)
                resultSearchId.result.errors.map((item: any) => {
                    Alert({
                        type: 'warning',
                        text: item.message,
                        time: 8000,
                    });
                });
        }
    };

    const infoHandler = () => {
        navigation('/app-qwep/settings');
        navigatinHandler('suppliers');
        seIsRunAssistantVendorSearch(true);
        setAssistantVendor(vendor);
    };

    return (
        <div className={styles.cellConfirmation}>
            <div className={styles.info}>
                <CustomTooltip
                    customStyle={!isTemplateItem ? { bottom: '-60px', left: '-10%' } : {}}
                    componetTooltip={
                        <div>
                            {foundItem && !isTemplateItem ? (
                                <>
                                    <p>Подтвержденный поставщик: {confirmedVendor}</p>
                                    <p>Подтвержденный склад: {confirmedWarehouse}</p>
                                    <p>Подтвержденный наличие: {confirmedAvailability}</p>
                                </>
                            ) : (
                                <p>{t('qwep.assistant.table.isTemplateItem')}</p>
                            )}
                        </div>
                    }>
                    <p>
                        {confirmed ? (
                            <IconSuccessAssistantTable
                                classNames={[styles.icon]}
                                color={Boolean(+confirmedAvailability) ? colors.lightgreen : colors.eta}
                            />
                        ) : (
                            <IconErrorAssistantTable classNames={[styles.icon]} color={colors.lambda} />
                        )}
                    </p>
                </CustomTooltip>

                {isTemplateItem ? null : (
                    <CustomTooltip componetTooltip={authVendor ? 'Поставщик авторизован' : 'Поставщик не авторизован'}>
                        <p>
                            <IconAuthAssistantTable
                                classNames={[styles.icon]}
                                color={authVendor ? colors.lightgreen : colors.lambda}
                            />
                        </p>
                    </CustomTooltip>
                )}

                {parentId === userInfo.userId && type === 'custom' && (
                    <CustomTooltip wrapperStyle={styles.customTooltipWrapper} componetTooltip={'Удалить позицию'}>
                        <IconButton
                            className={styles.deleteBtn}
                            onClick={() => {
                                handleRemoveCustomListItem({
                                    article,
                                    brand,
                                    refKey: currentAssistantList.refKey,
                                    foundItem,
                                    t,
                                    getDeals,
                                });
                            }}>
                            <CancelIcon className={styles.icon} />
                        </IconButton>
                    </CustomTooltip>
                )}
            </div>
            {foundItem &&
                !isTemplateItem &&
                (authVendor ? (
                    <CustomTooltip wrapperStyle={styles.customTooltipWrapper} componetTooltip={'Поиск'}>
                        <IconButton className={styles.sendBtn} onClick={() => searchHandler()}>
                            <SendIcon className={styles.icon} />
                        </IconButton>
                    </CustomTooltip>
                ) : (
                    <CustomTooltip
                        componetTooltip={'Перейти к информации о поставщике'}
                        customStyle={{ left: '-60px' }}
                        wrapperStyle={styles.customTooltipWrapper}>
                        <IconButton className={styles.sendBtn} onClick={() => infoHandler()}>
                            <HelpIcon className={styles.icon} />
                        </IconButton>
                    </CustomTooltip>
                ))}
        </div>
    );
}
