export const getColorLessBetter = (value: number | string) => {
    if (typeof value !== 'number') {
        return `green`;
    }
    return value <= 0 ? 'green' : 'red';
};

export const getColorMoreBetter = (value: number | string) => {
    if (typeof value !== 'number') {
        return `green`;
    }
    return value < 0 ? 'red' : 'green';
};