import { useContext, useEffect, useState } from 'react';
import stls from '@f_styles/components/header/Header.module.sass';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import { useWindowSize } from '@f_hooks';
import { IconCallMe } from '@f_components/icons/IconCallMe';
import { IconQWEP } from '@f_components/icons/IconQwep';
import { IconVinPin } from '@f_qwep/components/icons/IconVinPin';
import { useLocation, useNavigate } from 'react-router-dom';
import { CallMeModal } from './CallMeModal';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { Library } from '@f_components/manual/Library';
import { SnowfallFone } from '@f_general/Snowfall';

export function Header() {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const [mobile, setMobile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <header style={{ background: colors.betaHeader }} className={stls.container}>
            {/* {currentTheme !== 'vinpin'  &&  <SnowfallFone/>} */}
            <Divider orientation="vertical" flexItem />
            <button style={{ display: 'flex', marginLeft: '55px' }} onClick={() => navigate('/app-qwep')}>
                {currentTheme == 'vinpin' ? (
                    <div>
                        <IconVinPin width={'304px'} height={'41px'} />
                    </div>
                ) : (
                    <IconQWEP width={'130px'} height={'45px'} />
                )}
            </button>
            <div className={stls.buttonCallMe}>
                {window.location.pathname == '/company' && (
                    <div style={{ margin: '0 15px' }}>
                        <Library />
                    </div>
                )}
                {mobile ? (
                    <button
                        style={{ border: `1.5px solid ${colors.alpha}` }}
                        className={stls.mobileButton}
                        onClick={handleOpen}>
                        <IconCallMe />
                    </button>
                ) : (
                    <Button className={stls.siteButton}
                            onClick={handleOpen}
                            variant="outlined"
                            sx={{
                                '&.MuiButton-outlined': {border: `1px solid rgba(33, 142, 225, 0.5)`},
                                '&.MuiButton-outlined:hover': {border: `1px solid #3F8DC8`},
                            }}
                    >
                        {t('common.header.callMe')}
                    </Button>
                )}
            </div>
            <CallMeModal handleClose={handleClose} open={open} />
        </header>
    );
}
