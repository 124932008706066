import stls from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export function ColumnPerDeviations({ suitItemsLength, suitItems, index, suitItemsPrice, price }: any, idRow: any, isOpenDetails: any) {
    const { colors } = useContext(ThemeContext);

    const effectiveSuitItemsPrice = suitItemsPrice || 0;

    const percentDifference = (price && effectiveSuitItemsPrice)
        ? ((price - effectiveSuitItemsPrice) / price * 100).toFixed(2)
        : null;

    return (
        <div
            style={
                suitItemsLength
                    ? {
                        border: `0.5px solid ${colors.eta}`,
                        overflow: 'hidden',
                        background: 'rgba(254, 205, 82, 0.2)',
                        color:
                            idRow !== index && isOpenDetails
                                ? colors.blurColor
                                : colors.omicron,
                    }
                    : {}
            }
            className={stls.cellPrice}>
            {suitItems && price ? (
                percentDifference === '0.00'
                    ? '<0.01 %'
                    : `${percentDifference} %`
            ) : ''}
        </div>
    )
}
