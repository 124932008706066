import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { IconMaskotBook } from '@f_components/icons/IconMascotBook';

export function PricesManual() {
    const { setChooseManual } = useContext(ManualContext);

    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<any>([
        {
            target: '#root',
            title: 'Для чего функция "Прайсы"',
            content: (
                <div>
                    {' '}
                    <IconMaskotBook />{' '}
                    <p>
                        Если вы используете в работе поставщиков, которые имеют только прайс, то данный функционал
                        позволяет подгружать и автоматически обновлять неограниченное к-во прайсов, при проценке все
                        предложения из прайсов будут также отражаться с учетом кроссов QWEP, тем самым, вы всегда будете
                        видеть полную и актуальную картину от своих поставщиков.
                        <br />
                        Кроме этого, вы можете делиться прайсами с другими компаниями, чтобы они могли тоже видеть эти
                        предложения.
                    </p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: false,
            placement: 'center',
        },
    ]);

    useEffect(() => {
        setRun(true);
        setCurrentStep(0);
    }, []);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setChooseManual();

            setRun(false);
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
