import React, { FC, useContext } from 'react';
import { flexRender } from '@tanstack/react-table';

// import './index.css'

import stls from '@f_styles/general/table/TableRow.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { QwepContext } from '../../applications/qwep/context/Qwep_context';

export const TableRow: FC<{
    row: any;
    cell: any;
    styles?: any;
}> = ({ cell, row, styles }) => {
    const { colors } = useContext(ThemeContext);
    const { qwepCrosses } = useContext(QwepContext);

    return (
        <td
            style={{
                color: qwepCrosses && row.original.color ? row.original.color : colors.omicron,
            }}
            className={styles.body.cellRadius ? stls.b_cell_r : stls.b_cell}
            key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
            {cell.getIsGrouped() ? (
                <>
                    <button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: {
                                cursor: row.getCanExpand() ? 'pointer' : 'normal',
                            },
                        }}>
                        {row.getIsExpanded() ? '👇' : '👉'} {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                        ({row.subRows.length})
                    </button>
                </>
            ) : cell.getIsAggregated() ? (
                flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
            ) : (
                cell.getIsPlaceholder() ?? null
            )}
        </td>
    );
};
