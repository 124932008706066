import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconDeleteProps = TypeClassNames;

export const IconDelete = ({ classNames }: TypeIconDeleteProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1313_4198)">
                    <rect width="24" height="24" rx="12" fill={'#ffd9d9'} />
                    <path
                        d="M11.2929 10.7477C11.6834 11.1382 12.3166 11.1382 12.7071 10.7477L14.2728 9.18198C14.5239 8.93093 14.9309 8.93093 15.182 9.18198C15.433 9.43303 15.433 9.84007 15.182 10.0911L13.6162 11.6569C13.2257 12.0474 13.2257 12.6805 13.6162 13.0711L15.182 14.6368C15.433 14.8879 15.433 15.2949 15.182 15.5459C14.9309 15.797 14.5239 15.797 14.2728 15.5459L12.7071 13.9802C12.3166 13.5897 11.6834 13.5897 11.2929 13.9802L9.72716 15.5459C9.47611 15.797 9.06907 15.797 8.81802 15.5459C8.56697 15.2949 8.56697 14.8879 8.81802 14.6368L10.3838 13.0711C10.7743 12.6805 10.7743 12.0474 10.3838 11.6569L8.81802 10.0911C8.56697 9.84007 8.56697 9.43303 8.81802 9.18198C9.06907 8.93093 9.47611 8.93093 9.72716 9.18198L11.2929 10.7477Z"
                        fill={colors.lambda}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1313_4198">
                        <rect width="24" height="24" rx="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
