import { GeneralContext } from '@f_context/General_context';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Property } from '../type';

const ConfigReportContext = createContext<any>({});

type TypeEnteredData = {
    article: string;
    brand: string;
}[];

function ConfigReportProvider(props: any) {
    //table crosses
    const [crossesPartname, setCrossesPartname] = useState([]);
    const [currentArticleBrand, setCurrentArticleBrand] = useState({
        article: '',
        brand: '',
    });
    const [openSettings, setOpenSettings] = useState<number>(-1);

    const getBrands = async (article: string) => {
        const result = await callPlatformAPI(`crossdata/get/brands/${article}`, 'get');
        console.log('getBrands', result);
        return result.data;
    };

    const getCrossPartname = async (article: string, brand: string, show: boolean) => {
        if (show) {
            const result = await callPlatformAPI('crossdata/get/cross/partname', 'post', {
                article,
                brand,
            });
            console.log('getCrossPartname', result);
            if (result.data.isOk) {
                setCurrentArticleBrand({ article, brand });
                setCrossesPartname(result.data.result);
            }
        } else {
            setCrossesPartname([]);
        }
    };

    const getCross = async (article: string, brand: string) => {
        const result = await callPlatformAPI('crossdata/get/cross/items', 'post', {
            article,
            brand,
        });
        console.log('getCross', result);
        return result.data;
    };

    return (
        <ConfigReportContext.Provider
            value={{
                crossesPartname,

                currentArticleBrand,
                openSettings,

                //methods
                setOpenSettings,
                getBrands,
                getCrossPartname,
                setCrossesPartname,
                getCross,
            }}
            {...props}
        />
    );
}

export { ConfigReportProvider, ConfigReportContext };
