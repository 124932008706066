import { ManualContext } from '@f_qwep/context/Manual_context';
import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { Alert } from '@f_general/alert';
import { PriceContext } from '@f_qwep/context/Price_context';
import { IconMaskot } from '@f_components/icons/IconMascot';

export function DeletePriceManual() {
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);
    const { prices } = useContext(PriceContext);

    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<any>([
        {
            target: '#prices',
            content: (
                <div>
                    <IconMaskot />
                    <p>Перейдите в раздел "Прайсы"</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#deletePrice0',
            content: 'Для удаления прайса нажмите на иконку удаления',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
    ]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            if (prices.length) {
                setRun(true);
                if (!newManualValue.prices?.flag) {
                    setNewManualValue({ ...newManualValue, prices: { flag: true } });
                }
            } else {
                Alert({
                    type: 'warning',
                    text: 'Для просмотра мануала сначала загрузите прайслист!',
                });
            }
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/prices') {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [window.location.pathname]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
            setChooseManual();
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
