import { UCSProvider } from './UCS_context';
import { CollectorProvider } from './collector_context';
import 'dayjs/locale/ru';
import { DatePickerProvider } from '@f_ucs/context/date_picker_context';
import { ReportProvider } from '@f_ucs/context/report_context';
import { DoughnutProvider } from '@f_ucs/context/doughnut_context';


export function UCSProviders({ children }: any) {
    return (
        <UCSProvider>
            <CollectorProvider>
                <ReportProvider>
                    <DatePickerProvider>
                        <DoughnutProvider>
                                {children}
                        </DoughnutProvider>
                    </DatePickerProvider>
                </ReportProvider>
            </CollectorProvider>
        </UCSProvider>
    );
}
