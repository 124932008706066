import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { CellDate } from './CellDate';
import { CellPrices } from './CellPrices';
import { CellSettings } from './CellSettings';
import { CellStatus } from './CellStatus';
import { CellType } from './CellType';
import { CellUpdate } from './CellUpdate';
import { CellVendor } from './CellVendor';
import stls from '@f_qwep/styles/component/price/Price.module.sass';
import { CellAdminCode } from './CellAdminCode';

export function CellMobileCart({ info }: any) {
    const { t } = useTranslation();

    return (
        <Box sx={{ width: '100%', padding: '12px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CellVendor info={info} />
                <CellSettings info={info} />
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.prices.date')}:</p>
                        <CellDate info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.prices.title')}:</p>
                        <CellPrices info={info} />
                    </div>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.prices.adminCode')}:</p>
                        <CellAdminCode info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.prices.status')}:</p>
                        <CellStatus info={info} />
                    </div>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.prices.update')}:</p>
                        <CellUpdate info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.prices.type')}:</p>
                        <CellType info={info} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
