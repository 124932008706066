import { useContext, useMemo, useCallback } from 'react';
import { SearchContext } from '@f_qwep/context/Search_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';

interface IGetIsOriginalPositionArgs {
    currentSearchBrand: string;
    currentSearchArticle: string;
    article: string;
    brand: string;
}

const approvedCrossCount = 1;

export const useMaskCrossTransformItems = (items: any[]): any[] => {
    const { currentSearchBrand, currentSearchArticle } = useContext(SearchContext);
    const { qwepCrosses } = useContext(QwepContext);
    const { colors } = useContext(ThemeContext);

    const getIsOriginalPosition = useCallback(
        ({ article, brand, currentSearchBrand, currentSearchArticle }: IGetIsOriginalPositionArgs) =>
            article === currentSearchArticle && brand === currentSearchBrand,
        [],
    );

    return useMemo(() => {
        if (qwepCrosses) {
            return items.map((item: any) => {
                const isOriginPosition = getIsOriginalPosition({
                    currentSearchBrand,
                    currentSearchArticle,
                    article: item.article,
                    brand: item.brand,
                });

                if (isOriginPosition) {
                    return { ...item, isOriginPosition: true };
                }

                if (approvedCrossCount === Number(item?.qwepCross)) {
                    return { ...item, color: colors.omicron, isCross: true, approvedVoted: false };
                } else {
                    return { ...item, color: colors.blurColor, isCross: true, approvedVoted: true };
                }
            });
        }

        return items;
    }, [items, currentSearchArticle, currentSearchBrand, qwepCrosses, colors]);
};
