import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useState } from 'react';
import { IconClose } from '@f_components/icons/IconClose';
import { IconEditSmall } from '@f_components/icons/IconEditSmall';
import { IconSettingsSmall } from '@f_components/icons/IconSettingsSmall';
import stls from '@f_qwep/styles/component/settings/Accounts.module.sass';
import GeneralModal from '@f_general/GeneralModal';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { SettingSupplierModal } from './SettingSuppliersModal';
import { AccountsContext } from '@f_qwep/context/Accounts_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

type TypeCellSettingsProps = {
    info: any;
};

export function CellSettings({ info }: TypeCellSettingsProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { updateAccount, deleteAccount, fullAccounts } = useContext(AccountsContext);

    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    const [openSettings, setOpenSettings] = useState(false);

    const closeSettingsHandler = () => {
        setOpenSettings(false);
    };

    const clickModalHandler = (type: string) => {
        switch (type) {
            case 'edit':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('qwep.settings.suppliers.modal.edit.title')}: ${info.row.original.title}`,
                    props: [
                        {
                            title: t('qwep.settings.suppliers.modal.edit.name'),
                            control: 'input',
                            name: 'title',
                            type: 'text',
                            defaultValue: info.row.original.title,
                            placeholder: t('qwep.settings.suppliers.modal.edit.namePlaceholder'),
                        },
                    ],
                    shema: null,
                    type: 'editAccount',
                    data: {
                        title: info.row.original.title,
                        id: info.row.original.id,
                        type: 'editAccount',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;

            case 'delete':
                setDataModal({
                    icon: '',
                    title: `${t('qwep.settings.suppliers.modal.delete.title')}: ${info.row.original.title}`,
                    props: [],
                    shema: null,
                    type: 'deleteAccount',
                    data: {
                        id: info.row.original.id,
                        type: 'deleteAccount',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
        }
    };

    const sendDataModalHandler = async (values: any) => {
        console.log('VALUES', values);

        switch (values.type) {
            case 'editAccount':
                const resultUpdateAccount = await updateAccount([
                    {
                        id: values.id,
                        title: values.title,
                    },
                ]);
                if (resultUpdateAccount.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'deleteAccount':
                const resultDeleteAccount = await deleteAccount({
                    id: values.id,
                });
                if (resultDeleteAccount.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    const closeHandler = () => {
        setDataModal({});
        setOpen(false);
    };

    const clickModalSettingsHandler = async () => {
        setOpenSettings(true);
    };

    return (
        <div className={stls.cellSettings}>
            {!info.row.original.promo && (
                <>
                    {fullAccounts.find((el: any) => el.id == info.row.original.id)?.hasAuthParameters && (
                        <button className={stls.btn} onClick={clickModalSettingsHandler}>
                            <IconSettingsSmall />
                        </button>
                    )}

                    <button className={stls.btn} onClick={() => clickModalHandler('edit')}>
                        <IconEditSmall />
                    </button>

                    <button className={stls.btn} onClick={() => clickModalHandler('delete')}>
                        <IconClose />
                    </button>

                    <GeneralModal
                        openModal={open}
                        closeModal={closeHandler}
                        sendDataModalHandler={sendDataModalHandler}
                        dataModal={dataModal}
                    />
                    <SettingSupplierModal
                        info={info.row.original}
                        handleClose={closeSettingsHandler}
                        open={openSettings}
                    />
                </>
            )}
        </div>
    );
}
