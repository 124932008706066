import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconCheckProps = TypeClassNames;

export const IconCheck = ({ classNames }: TypeIconCheckProps) => {
    const { colors, currentTheme } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill="#6EBA6E" fillOpacity={0.2} />
                <path
                    d="M12.6684 16.1147L18.7964 9.98599L19.7398 10.9287L12.6684 18L8.42578 13.7573L9.36845 12.8147L12.6684 16.1147Z"
                    fill="#6EBA6E"
                />
            </svg>
        </div>
    );
};
