import stls from '@f_crossdata/styles/general/LayoutPage.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { Wrapper } from '@f_general/Wrapper';
import cn from 'classnames';
import { useContext } from 'react';

type TypeLayoutPageProps = {
    children: any;
    classNames?: any;
};

export function LayoutPage({ classNames, children }: TypeLayoutPageProps) {
    const { colors } = useContext(ThemeContext);
    return (
        <div className={stls.container}>
            <Wrapper classNames={[classNames, stls.wrapper]}>{children}</Wrapper>
        </div>
    );
}
