import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconUsersCurrentTariffProps = TypeClassNames & {
    rectFill?: string;
    pathFill?: string;
};

export const IconUsersCurrentTariff = ({ classNames, rectFill, pathFill }: TypeIconUsersCurrentTariffProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3595_18817)">
                    <g opacity="0.2">
                        <rect width="48" height="48" rx="24" fill="#FECD52" />
                    </g>
                    <path
                        d="M14 34C14 31.8783 14.8429 29.8434 16.3431 28.3431C17.8434 26.8429 19.8783 26 22 26C24.1217 26 26.1566 26.8429 27.6569 28.3431C29.1571 29.8434 30 31.8783 30 34H28C28 32.4087 27.3679 30.8826 26.2426 29.7574C25.1174 28.6321 23.5913 28 22 28C20.4087 28 18.8826 28.6321 17.7574 29.7574C16.6321 30.8826 16 32.4087 16 34H14ZM22 25C18.685 25 16 22.315 16 19C16 15.685 18.685 13 22 13C25.315 13 28 15.685 28 19C28 22.315 25.315 25 22 25ZM22 23C24.21 23 26 21.21 26 19C26 16.79 24.21 15 22 15C19.79 15 18 16.79 18 19C18 21.21 19.79 23 22 23ZM30.284 26.703C31.6893 27.3359 32.882 28.3612 33.7186 29.6557C34.5552 30.9502 35.0002 32.4587 35 34H33C33.0002 32.844 32.6666 31.7125 32.0391 30.7416C31.4116 29.7707 30.5171 29.0017 29.463 28.527L30.283 26.703H30.284ZM29.596 15.413C30.6035 15.8283 31.465 16.5335 32.071 17.4392C32.6771 18.3449 33.0004 19.4102 33 20.5C33.0004 21.8723 32.4877 23.1952 31.5625 24.2088C30.6374 25.2224 29.3667 25.8535 28 25.978V23.965C28.7409 23.8589 29.4283 23.518 29.9613 22.9925C30.4943 22.4669 30.8447 21.7843 30.9612 21.0449C31.0776 20.3055 30.954 19.5483 30.6084 18.8844C30.2628 18.2204 29.7134 17.6847 29.041 17.356L29.596 15.413Z"
                        fill="#FECD52"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3595_18817">
                        <rect width="48" height="48" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
