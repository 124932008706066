import { PriceContext } from '@f_qwep/context/Price_context';
import { ThemeContext } from '@f_context/Theme_context';
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/price/ClassificationSettingsModal.module.sass';
import stl from '@f_styles/general/Universal.module.sass';
import { Alert } from '@f_general/alert';
import { Classifications } from '@f_qwep/types';
import { Button, CardContent, InputBase, MenuItem, Modal, Select, styled, Typography } from '@mui/material';
import { MyModal } from '@f_general/MyModal';
import { IconAddPrice } from '../icons/IconAddPrice';
import { UpdateConfiguration } from './UpdateConfiguration';
import cn from 'classnames';

export const ClassificationSettingsModal = ({ adminCode, open, setOpen, price, vendor }: any) => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const CustomSelect = styled(InputBase)(() => ({
        '.MuiSvgIcon-root ': {
            fill: colors.epsilon,
        },
        '& .MuiInputBase-input': {
            borderRadius: 12,
            border: `1px solid ${colors.alpha}`,
            padding: '10px 26px 10px 12px',
            fontFamily: 'Inter',
            fontSize: 14,
            color: colors.omicron,
            '&:focus': {
                borderRadius: 12,
                border: `1px solid ${colors.xi}`,
            },
        },
    }));
    const menuProps = {
        sx: {
            '&& .MuiMenuItem-root': {
                fontSize: 14,
                fontFamily: 'Inter',
                color: colors.omicron,
                '&:hover': {
                    background: colors.beta,
                },
            },
        },
    };

    const { getClassification, updateClassification, postXMLClassification } = useContext(PriceContext);
    const [dataModal, setDataModal] = useState([]);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<any>({});
    const [showConfigPanel, setShowConfigPanel] = useState(false);

    useEffect(() => {
        if (open) {
            setOptions([
                {
                    key: t('qwep.price.modal.classificationModal.brand'),
                    value: Classifications.BRAND,
                    disabled: false,
                    id: 0,
                    main: true,
                },
                {
                    key: t('qwep.price.modal.classificationModal.article'),
                    value: Classifications.ARTICLE,
                    disabled: false,
                    id: 0,
                    main: true,
                },
                {
                    key: t('qwep.price.modal.classificationModal.partname'),
                    value: Classifications.PARTNAME,
                    disabled: false,
                    id: 0,
                    main: true,
                },
                {
                    key: t('qwep.price.modal.classificationModal.quantity'),
                    value: Classifications.QUANTITY,
                    disabled: false,
                    id: 0,
                    main: true,
                },
                {
                    key: t('qwep.price.modal.classificationModal.multiplicity'),
                    value: Classifications.MULTIPLICITY,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.delivery'),
                    value: Classifications.DELIVERY,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.status'),
                    value: Classifications.STATUS,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.warehouse'),
                    value: Classifications.WAREHOUSE,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.price'),
                    value: Classifications.PRICE,
                    disabled: false,
                    id: 0,
                    main: true,
                },
                {
                    key: t('qwep.price.modal.classificationModal.notes'),
                    value: Classifications.NOTES,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.photo'),
                    value: Classifications.PHOTO,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.currency'),
                    value: Classifications.CURENCY,
                    disabled: false,
                    id: 0,
                    main: false,
                },
                {
                    key: t('qwep.price.modal.classificationModal.trash'),
                    value: Classifications.TRASH,
                    disabled: false,
                    id: 0,
                    main: false,
                },
            ]);
            settingsHandler();
        }
    }, [open]);

    const settingsHandler = async () => {
        const result = await getClassification(adminCode);
        if (result.isOk) {
            console.log(result.result);
            const defaultValues: any = {};
            setDataModal(
                result.result.map((item: any, idx: number) => {
                    defaultValues[idx] = {
                        priceHeader: item.priceHeader,
                        category: item.category ? item.category : Classifications.TRASH,
                    };
                    setOptions((prev: any) => {
                        return prev.map((opt: any) => {
                            if (item.category === opt.value && item.category !== Classifications.TRASH) {
                                return {
                                    ...opt,
                                    disabled: true,
                                    id: idx,
                                };
                            } else {
                                return opt;
                            }
                        });
                    });

                    return {
                        id: idx,
                        name: item.priceHeader
                            ? item.priceHeader
                            : t('qwep.price.modal.classificationModal.defaultField'),
                        title: item.priceHeader ? item.priceHeader : '',
                        defaultValue: Classifications.TRASH,
                    };
                }),
            );
            setValues(defaultValues);
        } else {
            Alert({
                type: 'error',
                text: t('qwep.price.modal.classificationModal.settingsHandlerError'),
                time: 8000,
            });
        }
    };

    const handleClose = () => {
        setOptions([]);
        setDataModal([]);
        setValues({});
        setOpen(false);
        setShowConfigPanel(false);
    };

    const disabledSelectItem = () => {
        options.length &&
            setOptions(
                options.map((item: any) => {
                    if (
                        values[item.id]?.category === item.value &&
                        values[item.id]?.category !== Classifications.TRASH
                    ) {
                        return {
                            ...item,
                            disabled: true,
                        };
                    } else {
                        return {
                            ...item,
                            disabled: false,
                        };
                    }
                }),
            );
    };

    useEffect(() => {
        disabledSelectItem();
    }, [values]);

    const selectHandler = (option: any, id: number, title: string) => {
        setValues((prev: any) => ({
            ...prev,
            [id]: {
                priceHeader: title,
                category: option.value,
            },
        }));
        setOptions(
            options.map((item: any) => {
                if (item.value === option.value) {
                    return { ...item, id: id };
                }
                return item;
            }),
        );
    };

    const submitHandler = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const result = price?.startsWith('http')
            ? await postXMLClassification({
                  adminCode,
                  property: Object.keys(values).map((item: any) => values[item]),
              })
            : await updateClassification({
                  adminCode,
                  property: Object.keys(values).map((item: any) => values[item]),
              });
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            handleClose();
        } else {
            Alert({
                type: 'error',
                text: t('qwep.price.modal.classificationModal.submitHandlerError'),
                time: 8000,
            });
        }
    };

    return (
        <>
            {dataModal.length ? (
                <Modal open={open} onClose={handleClose}>
                    <MyModal className={cn(stls.modal, showConfigPanel && stls.molalFill)}>
                        <CardContent className={stls.modalContent}>
                            <div className={stls.modalTitle}>
                                <IconAddPrice />
                                <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                    {t('qwep.price.modal.classificationModal.title')}
                                </Typography>
                            </div>

                            <div className={cn(showConfigPanel ? stls.flex : stls.block)}>
                                <div>
                                    <button
                                        className={stls.configBtn}
                                        onClick={() => setShowConfigPanel(!showConfigPanel)}
                                        style={{ color: colors.alpha }}>
                                        {showConfigPanel ? 'Свернуть' : 'Дополнительные настройки'}
                                    </button>

                                    {showConfigPanel && (
                                        <div className={stls.updateConfigPanel}>
                                            <UpdateConfiguration
                                                adminCode={adminCode}
                                                handleCloseModal={() => setShowConfigPanel(false)}
                                                type={'block'}
                                                open={showConfigPanel}
                                                vendor={vendor}
                                            />
                                        </div>
                                    )}
                                </div>

                                <form onSubmit={submitHandler} className={stls.form}>
                                    {dataModal.length && (
                                        <div className={stls.modalMapContent}>
                                            {dataModal.map((item: any) => {
                                                return (
                                                    <div key={item.id}>
                                                        <Typography className={stls.text}>{item.title}</Typography>
                                                        <Select
                                                            input={<CustomSelect />}
                                                            className={stls.select}
                                                            autoWidth
                                                            value={
                                                                values[item.id]
                                                                    ? values[item.id].category
                                                                    : item.defaultValue
                                                            }
                                                            sx={{
                                                                maxWidth: '380px',
                                                                '& .MuiSelect-select': {
                                                                    padding: '8px',
                                                                    width: '380px',
                                                                },
                                                            }}
                                                            defaultValue={item.defaultValue}
                                                            MenuProps={menuProps}>
                                                            {options?.length ? (
                                                                options.map((option: any) => {
                                                                    return (
                                                                        <MenuItem
                                                                            className={stls.option}
                                                                            key={option.key}
                                                                            disabled={option.disabled}
                                                                            onClick={() =>
                                                                                selectHandler(
                                                                                    option,
                                                                                    item.id,
                                                                                    item.title,
                                                                                )
                                                                            }
                                                                            value={option.value}
                                                                            style={
                                                                                option.main
                                                                                    ? { fontWeight: '800' }
                                                                                    : { fontWeight: '400' }
                                                                            }>
                                                                            {option.key}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Select>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <div className={stl.flexEnd}>
                                        <Button
                                            className={stls.modalDeleteButton}
                                            onClick={handleClose}
                                            size="large"
                                            sx={{
                                                padding: '13px',
                                                background: colors.delta,
                                                color: colors.phi,
                                                '&:hover': { color: colors.alpha },
                                            }}>
                                            {t('qwep.settings.suppliers.canceled')}
                                        </Button>
                                        <Button
                                            className={stls.modalButton}
                                            size="large"
                                            variant="contained"
                                            type="submit">
                                            {t('qwep.price.modal.classificationModal.confirm')}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </CardContent>
                    </MyModal>
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};
