import { useContext, useEffect, useState } from 'react';
import { QwepContext } from '../../context/Qwep_context';
import { Tariff } from '@f_qwep/types';
import { IconTariffLite } from '@f_components/icons/IconTariffLite';
import { IconTariffProPlus } from '@f_components/icons/IconTariffProPlus';
import { IconTariffPro } from '@f_components/icons/IconTariffPro';
import { IconTariffBusiness } from '@f_components/icons/IconTariffBusiness';
import { ThemeContext } from '@f_context/Theme_context';

export const TariffIcon = () => {
    const { tariff } = useContext(QwepContext);
    const [icon, setIcon] = useState(<></>);
    const { currentTheme } = useContext(ThemeContext);

    useEffect(() => {
        switch (tariff) {
            case Tariff.LITE:
                setIcon(<IconTariffLite />);
                break;
            case Tariff.PRO:
                setIcon(<IconTariffPro />);
                break;
            case Tariff.PROPlus:
                setIcon(<IconTariffProPlus />);
                break;
            case Tariff.Business:
                setIcon(<IconTariffBusiness />);
                break;
        }
    }, [tariff]);

    return (
        <div
            style={{
                position: 'absolute',
                top: '6px',
                left: `${currentTheme === 'vinpin' ? '360px' : '180px'}`,
                zIndex: '10',
            }}>
            {icon}
        </div>
    );
};
