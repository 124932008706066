import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import stls from '../../../styles/component/basket/Basket.module.sass';
import { QwepContext } from '../../../context/Qwep_context';

export function CellPrice({ info }: any) {
    const { priceMarginIsActive } = useContext(QwepContext);
    const price = priceMarginIsActive ? +info.row.original.priceWithMargin : +info.row.original.price;

    return (
        <Tooltip placement="top" title={price}>
            <p className={stls.cellTitle}>{(+price).toLocaleString()} ₽</p>
        </Tooltip>
    );
}
