import stls from '@f_styles/general/Universal.module.sass';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

export const IconUp = () => {
    const { colors } = useContext(ThemeContext);
    return (
        <div className={stls.iconClose}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="18" fill={colors.epsilon} fillOpacity={0.2} />
                <path d="M18.0001 17.0234L13.8751 21.1484L12.6968 19.97L18.0001 14.6667L23.3034 19.97L22.1251 21.1484L18.0001 17.0234Z" />
            </svg>
        </div>
    );
};
