import stls from '@f_qwep/styles/component/price/Price.module.sass';
import { useTranslation } from 'react-i18next';

export function CellStatus({ info }: any) {
    const { t } = useTranslation();

    const getStatus = (status: any) => {
        switch (status) {
            case 'PUBLISHED':
                return t('qwep.prices.published');
            case 'UNCLASSIFIED':
                return t('qwep.prices.unclassified');
            case 'EMPTY':
                return t('qwep.prices.notPublished');
            case 'OLD':
                return t('qwep.prices.outdated');
        }
    };

    return (
        <p id="statusPrice" className={stls.cellStatus}>
            {getStatus(info.row.original.status)}
        </p>
    );
}
