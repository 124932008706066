import { createContext, useEffect, useState } from 'react';

const ErrorsContext = createContext<any>({});

function ErrorsProvider(props: any) {
    const [allErrors, setAllErrors] = useState([]);

    const [vendorErrors, setVendorErrors] = useState<any>([]);

    useEffect(() => {
        if (allErrors) {
            setVendorErrors([...allErrors]);
        }
    }, [allErrors]);

    return (
        <ErrorsContext.Provider
            value={{
                allErrors,
                setAllErrors,
                vendorErrors,
            }}
            {...props}
        />
    );
}

export { ErrorsProvider, ErrorsContext };
