import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { AccountsContext } from './Accounts_context';
import { StatusOrder } from '../types/enumStatusOrder';
import { useAccess } from '../hooks/useAccess';
import { QwepProductEnum } from '../types/QwepProductEnum.type';
import { UserContext } from '@f_context/User_context';
import { OrderItemStatusEnum } from '@f_qwep/types/OrderItemStatus.enum';

export enum OrderStatus {
    OPENED = 'OPENED',
    CLOSED = 'CLOSED',
}

export type SetStatusOrderItemVendorProps = {
    orderId: string;
    orderItemId: string;
    status: OrderItemStatusEnum;
};

export type UpdateItemOrderVendorProps = {
    id: string;
    quantity: number;
    delivery: string;
    warehouse: string;
};

export type AddCommentOrderProps = {
    id: string;
    type: 'BUYER' | 'SELLER';
    text: string;
};

const OrdersContext = createContext<any>({});

function OrdersProvider(props: any) {
    const { accounts } = useContext(AccountsContext);
    const { userInfo } = useContext(UserContext);
    const { isAccess: isAccessOrder } = useAccess(QwepProductEnum.ORDERS);
    const { isAccess: isAccessPrice } = useAccess(QwepProductEnum.PRICE);
    const [openDelete, setOpenDelete] = useState(false);

    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // показать инфо карту
    const [openCardResult, setOpenCardResult] = useState(false);
    const [cardItem, setCardItem] = useState({});
    //info
    const [excludedVendors, setExcludedVendors] = useState();

    const [open, setOpen] = useState(false);

    const [ordersVendor, setOrderVendor] = useState([]);

    useEffect(() => {
        if (accounts.length && isAccessOrder) {
            getOrders();
        }
    }, [accounts, isAccessOrder]);

    useEffect(() => {
        if (isAccessPrice) {
            getOrdersVendors();
        }
    }, [isAccessPrice]);

    const getOrders = async () => {
        setIsLoading(true);
        const result = await callPlatformAPI('qwep/get/order', 'post', {
            accounts: accounts.filter(
                (item: any) => item.enabled && { id: item.id, title: item.title, vid: item.vid, limit: 50 },
            ),
        });
        console.log('getOrders', result);
        if (result.data.isOk && result.data.result.entity) {
            const ordersWithNormalizedBrands = result.data.result.entity.map((item: any) =>
                item.brand ? item : { ...item, brand: 'Без бренда' },
            );
            setOrders(ordersWithNormalizedBrands);
        }
        setExcludedVendors(result.data.result?.excludedVendors);
        setIsLoading(false);
    };

    const setStatusOrderItemVendor = async (data: SetStatusOrderItemVendorProps) => {
        const result = await callPlatformAPI('qwep/set/status/order-item-vendors', 'put', data);
        console.log('setStatusOrderItemVendor', result);
        if (result.data.isOk) {
            setOrderVendor((prev) =>
                prev.map((item: any) => {
                    if (
                        item.itemOrderId === result.data.result.orderItemId &&
                        item.orderId === result.data.result.orderId
                    ) {
                        return {
                            ...item,
                            statusItem: result.data.result.status,
                        };
                    }
                    return item;
                }),
            );
        }
        return result.data;
    };

    const getOrdersVendors = async () => {
        const result = await callPlatformAPI('qwep/get/order-vendors', 'get');
        console.log('getOrdersVendors', result);
        if (result.data.isOk) {
            setOrderVendor(result.data.result);
        }
    };

    const updateItemOrderVendor = async (data: UpdateItemOrderVendorProps) => {
        const result = await callPlatformAPI('qwep/update/item/order-vendors', 'put', data);
        console.log('updateItemStatusOrderVendor', result);
        if (result.data.isOk) {
            await getOrdersVendors();
        }
        return result.data;
    };

    const addCommentItemOrder = async (data: AddCommentOrderProps) => {
        const result = await callPlatformAPI('qwep/add/comment/order-item', 'post', data);
        console.log('addCommentItemOrder', result);
        return result.data;
    };

    const addCommentOrder = async (data: AddCommentOrderProps) => {
        const result = await callPlatformAPI('qwep/add/comment/order', 'post', data);
        console.log('addCommentOrder', result);
        return result.data;
    };

    return (
        <OrdersContext.Provider
            value={{
                openDelete,
                orders,
                isLoading,
                openCardResult,
                cardItem,
                excludedVendors,
                ordersVendor,
                //methods

                open,
                setOpen,
                getOrders,
                setCardItem,
                setOpenDelete,
                setOpenCardResult,
                getOrdersVendors,
                updateItemOrderVendor,
                addCommentItemOrder,
                addCommentOrder,
                setStatusOrderItemVendor,
            }}
            {...props}
        />
    );
}

export { OrdersProvider, OrdersContext };
