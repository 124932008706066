import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconButtonSearchProps = TypeClassNames;

export const IconButtonSearch = ({ classNames }: TypeIconButtonSearchProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.16667 0.666687C12.3067 0.666687 15.6667 4.02669 15.6667 8.16669C15.6667 12.3067 12.3067 15.6667 8.16667 15.6667C4.02667 15.6667 0.666672 12.3067 0.666672 8.16669C0.666672 4.02669 4.02667 0.666687 8.16667 0.666687ZM8.16667 14C11.3892 14 14 11.3892 14 8.16669C14 4.94335 11.3892 2.33335 8.16667 2.33335C4.94334 2.33335 2.33334 4.94335 2.33334 8.16669C2.33334 11.3892 4.94334 14 8.16667 14ZM15.2375 14.0592L17.595 16.4159L16.4158 17.595L14.0592 15.2375L15.2375 14.0592Z"
                fill={colors.alpha}
            />
        </svg>
        // </div>
    );
};
