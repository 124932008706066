import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import cn from 'classnames';
import stls from '@f_qwep/styles/component/tables/resultTableBatchPricer/ColumnStyle.module.sass';
import { CellVendor } from './ColumnVendor';
import { CellAvailability } from './ColumnAvailability';
import { CellTitle } from './ColumnTitle';
import { CellPrice } from './ColumnPrice';
import { CellQuantity } from './ColumnQuantity';
import { AddToBasket } from '../searchResultTable/addToBasket';
import { CellInfo } from '../searchResultTable/columnInfo';

export function CellMobile({ info }: any) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);

    return (
        <Box sx={{ width: '100%', padding: '8px' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.vendor')}</p>
                        <CellVendor info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.title')}</p>
                        <CellTitle info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.time')}</p>
                        {info.row.original.delivery && (
                            <p
                                style={{
                                    background: colors.zeta,
                                    color: currentTheme == 'light' ? colors.omicron : colors.eta,
                                }}
                                className={stls.cellDelivery}>
                                {info.row.original.delivery}
                            </p>
                        )}
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.total')}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.brand')}</p>
                        <p style={{ color: colors.alpha }} className={cn(stls.cellBrand)}>
                            {info.row.original.brand}
                        </p>
                    </div>
                    <div>
                        <CellInfo info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.avaibility')}</p>
                        <CellAvailability info={info} />
                    </div>
                    <div>
                        <CellQuantity info={info} />
                    </div>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.article')}</p>
                        <p style={{ color: colors.alpha }} className={cn(stls.cellPin)}>
                            {info.row.original.article}
                        </p>
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.price')}</p>
                        <CellPrice info={info} />
                    </div>
                    <div>
                        <p className={stls.title}>{t('qwep.orders.warehouse')}</p>
                        <p className={stls.cellWarehouse}>{info.row.original.warehouse}</p>
                    </div>
                    <div>
                        <AddToBasket info={info} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}
