import '@f_admin/styles/baseAdmin.sass';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '@f_context/General_context';
import { AdminProviders } from './context';
import { Navbar } from './components/navbar';
import { UserInfo } from './components/userInfo';
import { Route, Routes } from 'react-router-dom';
import { Home } from '@f_admin/components/home';

export function AppAdmin() {
    const { reloadQwep } = useContext(GeneralContext);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (reloadQwep) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [reloadQwep]);

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <AdminProviders>
                    <div>
                        <Routes>
                            <Route path={'/*'} element={<Home />} />
                            <Route path={'user-info'} element={<UserInfo />} />
                        </Routes>
                    </div>
                    <Navbar />
                </AdminProviders>
            )}
        </>
    );
}
