export const IconMediumAssistant = (color: any) => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.75 2.5C7.75 3.05228 7.30228 3.5 6.75 3.5C6.19772 3.5 5.75 3.05228 5.75 2.5C5.75 1.94772 6.19772 1.5 6.75 1.5C7.30228 1.5 7.75 1.94772 7.75 2.5ZM5.775 4.79505L3.765 2.77505H3.76L3.605 2.61505L2.895 3.32005L4.905 5.34505L4.91 5.34005L5.065 5.50005L5.775 4.79505ZM7.7215 4.80855L9.924 2.61255L10.63 3.32005L8.4275 5.51655L7.7215 4.80855ZM5.755 8.18005L5.045 7.47505L3.03 9.48005L2.87 9.64505L3.575 10.35L5.59 8.34005L5.755 8.18005ZM10.63 9.65005L8.635 7.64505L8.455 7.47005L7.75 8.17505L9.745 10.18L9.92 10.355L10.63 9.65005ZM2.75 7.5C3.30228 7.5 3.75 7.05228 3.75 6.5C3.75 5.94772 3.30228 5.5 2.75 5.5C2.19772 5.5 1.75 5.94772 1.75 6.5C1.75 7.05228 2.19772 7.5 2.75 7.5ZM10.75 7.5C11.3023 7.5 11.75 7.05228 11.75 6.5C11.75 5.94772 11.3023 5.5 10.75 5.5C10.1977 5.5 9.75 5.94772 9.75 6.5C9.75 7.05228 10.1977 7.5 10.75 7.5ZM7.75 10.5C7.75 11.0523 7.30228 11.5 6.75 11.5C6.19772 11.5 5.75 11.0523 5.75 10.5C5.75 9.94771 6.19772 9.5 6.75 9.5C7.30228 9.5 7.75 9.94771 7.75 10.5Z"
                fill={color.color}
            />
        </svg>
    );
};
