import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTasks.module.sass';
import { IconCheck } from '../../icons/IconCheck';
import { IconFail } from '../../icons/IconFail';
import cn from 'classnames';
import { IconResults } from '../../icons/IconResults';
import { IconNotResult } from '../../icons/IconNotResult';
import { IconProgressItems } from '../../icons/IconProgressItems';
import { Tooltip } from '@mui/material';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { ThemeContext } from '@f_context/Theme_context';

export function HeadStatus() {
    const { t } = useTranslation();

    return (
        <p className={stls.headStatus}>
            <span>{t('qwep.batchPricer.status')}</span>
            <span style={{ marginRight: '20px' }}>{t('qwep.batchPricer.results')}</span>
        </p>
    );
}

export function CellStatus({ info, showResultItem, shownItem, setShownItem, statusItems }: any) {
    const { t } = useTranslation();
    const { batchPricerFiltersReducer } = useContext(FilterContext);
    const [status, setStatus] = useState<any>();
    const { colors, currentTheme } = useContext(ThemeContext);

    useMemo(() => {
        if (statusItems.length) {
            let num = statusItems[info.row.original.id - 1];
            switch (num) {
                case '1':
                    setStatus('success');
                    break;
                case '0':
                    setStatus('noResult');
                    break;
                case '-1':
                    setStatus('error');
                    break;
                case '2':
                    setStatus('work');
                    break;
                default:
                    setStatus('work');
                    break;
            }
        }
    }, []);

    const clickHandler = () => {
        showResultItem(info.row.original.id);
        setShownItem((prev: any) => ({
            ...prev,
            [info.row.original.id]: new Date().toLocaleTimeString(),
        }));
        batchPricerFiltersReducer({ type: 'reset_filters' });
    };

    return (
        <div className={stls.sellStatus}>
            {status === 'noResult' ? (
                <>
                    <div className={stls.status}>
                        <Tooltip title={t('qwep.batchPricer.searchNotResults')}>
                            <div>
                                <IconNotResult />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={cn(stls.cellResultsList, stls.noResult)}>
                        <IconResults classNames={[stls.cursor]} />
                        {t('qwep.batchPricer.notResults')}
                    </div>
                </>
            ) : status === 'work' ? (
                <>
                    <div className={stls.status}>
                        <Tooltip title={t('qwep.batchPricer.inJob')}>
                            <div>
                                <IconProgressItems />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={cn(stls.cellResultsList, stls.work)}>
                        <IconResults classNames={[stls.cursor]} />
                        {t('qwep.batchPricer.search')}
                    </div>
                </>
            ) : status === 'error' ? (
                <>
                    <div className={stls.status}>
                        <IconFail />
                    </div>
                    <div className={cn(stls.cellResultsList, stls.error)}>
                        <IconResults classNames={[stls.cursor]} />
                        {t('qwep.batchPricer.error')}
                    </div>
                </>
            ) : status === 'success' ? (
                <>
                    <div className={stls.status}>
                        <IconCheck />
                    </div>
                    {shownItem[info.row.original.id] && (
                        <div className={stls.shown}>
                            {t('qwep.batchPricer.shown')} {shownItem[info.row.original.id]}
                        </div>
                    )}
                    <button
                        id="resultsInResults"
                        style={{ color: colors.alpha }}
                        className={cn(
                            stls.cellResultsList,
                            currentTheme === 'vinpin' ? stls.success_vinpin : stls.success,
                        )}
                        onClick={clickHandler}>
                        <IconResults />
                        <p>{t('qwep.batchPricer.results')}</p>
                    </button>
                </>
            ) : (
                <>
                    <div className={stls.status}>
                        <Tooltip title={t('qwep.batchPricer.inJob')}>
                            <div>
                                <IconProgressItems />
                            </div>
                        </Tooltip>
                    </div>
                    <div className={cn(stls.cellResultsList, stls.work)}>
                        <IconResults classNames={[stls.cursor]} />
                        {t('qwep.batchPricer.search')}
                    </div>
                </>
            )}
        </div>
    );
}
