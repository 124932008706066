import React, { useContext, useRef, useState } from 'react';
import stls from '@f_qwep/styles/component/settings/Settings.module.sass';
import { Button, ButtonGroup, ClickAwayListener, Grow, List, Popper } from '@mui/material';
import { MenuSettings } from './MenuSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeContext } from '@f_context/Theme_context';
import { SettingsContext } from '@f_qwep/context/Settings_context';

export function MobileButtonSettings() {
    const { colors } = useContext(ThemeContext);
    const { setOpen, open } = useContext(SettingsContext);

    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen: any) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };

    return (
        <div>
            <ButtonGroup id="menuButtonSettings" className={stls.buttonGroup} variant="outlined" ref={anchorRef}>
                <Button
                    className={stls.buttonGroup}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleToggle}>
                    Меню
                    <ExpandMoreIcon sx={{ color: colors.alpha }} />
                </Button>
            </ButtonGroup>
            <Popper
                onClick={() => setOpen(false)}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                className={stls.popperList}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List style={{ paddingTop: '24px' }} className={stls.listGroup} sx={{ background: colors.psi }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuSettings />
                            </ClickAwayListener>
                        </List>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
