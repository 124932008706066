export class ConvertBytes {
    static bytesToMegabytes(bytes: number): number {
        return bytes / (1024 * 1024);
    }

    static megabytesToBytes(megabytes: number): number {
        const bytes = megabytes * (1024 * 1024);
        return Math.round(bytes);
    }
}
