import { useEffect, useState } from 'react';
import GeneralModal from '@f_general/GeneralModal';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { useUCSContext } from '../context/UCS_context';
import { useReport } from '@f_ucs/context/report_context';
import { useCollector } from '@f_ucs/context/collector_context';

type TypeDataModal =
    | {
    icon: any;
    title: string;
    props: Array<any>;
    shema: any;
    type: string;
    data: object;
}
    | null;

export function ExportExcelModal() {
    const { t } = useTranslation();
    const {
        isOpenExportModal,
        setIsOpenExportModal,
        typeExport,
        setTypeExport,
        exportData,
        setExportData,
        exportExcel,
    } =
        useUCSContext();
    const [dataModal, setDataModal] = useState<TypeDataModal>(null);

    useEffect(() => {

        switch (typeExport) {
            case 'UCS':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel:`,
                    props: [
                        {
                            control: 'select',
                            name: 'value',
                            title: '',
                            defaultValue: false,
                            options: [
                                {
                                    key: 'Отчёт по нарушениям',
                                    value: 'suspiciousBuys',
                                },
                                {
                                    key: 'Отчёт по активности',
                                    value: 'activityEmployees',
                                }],
                        },
                    ],
                    shema: null,
                    type: 'select',
                    data: {
                        type: 'UCS',
                        value: 'suspiciousBuys'
                    },
                });
                break;

            case 'suspiciousBuys':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel отчёт по нарушениям`,
                    props: [],
                    shema: null,
                    type: 'select',
                    data: {
                        type: 'suspiciousBuys',
                        value: 'suspiciousBuys'
                    },
                });
                break;

            case 'activityEmployees':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `Экспортировать в Excel отчёт по активности`,
                    props: [],
                    shema: null,
                    type: 'select',
                    data: {
                        type: 'activityEmployees',
                        value: 'activityEmployees'
                    },
                });
                break;
        }

    }, [isOpenExportModal]);

    const sendDataModalHandler = async (values: any) => {
        const data: any = {
            'suspiciousBuys': {
                title: 'Отчёт по нарушениям',
                data: exportData[values.value],
                type: values.type,
            },
            'activityEmployees': {
                title: 'Отчёт по активности сотрудников',
                data: exportData[values.value],
                type: values.type,
            },
            'UCS': {
                title: 'Отчёт по активности сотрудников',
                data: exportData[values.type][values.value],
                type: values.value,
            },
        }
        const result = await exportExcel(data[values.type])
        if (result.isOk) {
            closeHandler();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const closeHandler = () => {
        setDataModal(null);
        setTypeExport();
        setExportData();
        setIsOpenExportModal(false);
    };

    return (
        <>
            {dataModal && dataModal.type && (
                <GeneralModal
                    openModal={isOpenExportModal}
                    closeModal={closeHandler}
                    sendDataModalHandler={sendDataModalHandler}
                    dataModal={dataModal}
                />
            )}
        </>
    );
}
