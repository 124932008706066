import { AddVendorManual } from './main/AddVendorManual';
import { SearchResultManual } from './main/SearchResultManual';
import { WorkWithSearchResultManual } from './main/WorkWithSearchResult';
import { FiltersSearchResultManual } from './main/FiltersSearchResultManual';
import { StyleTableManual } from './main/StyleTableManual';
import { OrdersFiltertManual } from './orders/OrdersFilterManual';
import { AddPriceManual } from './prices/AddPriceManual';
import { DeletePriceManual } from './prices/DeletePriceManual';
import { AddGroupsAccountsManual } from './groups_accounts/AddGroupsAccounts';
import { EditGroupsAccountsManual } from './groups_accounts/EditGroupsAccounts';
import { DeleteGroupsAccountsManual } from './groups_accounts/DeleteGroupsAccounts';
import { LicensesManual } from './licenses/LicensesManual';
import { useContext, useEffect } from 'react';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { WelcomePage } from './main/WelcomPage';
import { BatchPricerManual } from './batch_pricer/BatchPricerManual';
import { PricesManual } from './prices/PricesManual';
import { SearchResultTableManual } from './search/SearchManual';
import { SettingsManual } from './settings/SettingsManual';
import { AddBatchPricerManual } from './batch_pricer/AddBatchPricer';
import { WorkWithResultManual } from './batch_pricer/WorkWithResult';
import { WorkWithPriceManual } from './prices/WorkWithPrice';
import { LibraryManual } from './main/LibraryManual';
import { SupportChatManual } from './support_chat/SupportChatManual';
import { BasketTemplateManual } from './basket/BasketTemplate';
import { PricesOptionsManual } from './prices/PricesOptionsManual';
import { OneSManual } from './oneS/oneSManual';

export function Manual() {
    const { chooseManual } = useContext(ManualContext);

    const renderManual = () => {
        switch (chooseManual) {
            case 'Приветствие':
                return <WelcomePage />;
            case 'Авторизация поставщика для поиска':
                return <AddVendorManual />;
            case 'Как выполнить поиск':
                return <SearchResultManual />;
            case 'Работа с таблицей результата поиска':
                return <WorkWithSearchResultManual />;
            case 'Работа с таблицей результата поиска из поиска':
                return <SearchResultTableManual />;
            case 'Фильтры для работы с таблицей результатов':
                return <FiltersSearchResultManual />;
            case 'Бренды подсказки при поиске':
                return <SearchResultManual />;
            case 'История поиска':
                return <SearchResultManual />;
            case 'Стили таблицы результатов':
                return <StyleTableManual />;
            case 'Статусы заказов':
                return <OrdersFiltertManual />;
            case 'Сменить период заказов':
                return <OrdersFiltertManual />;
            case 'Для чего функция "Прайсы"':
                return <PricesManual />;
            case 'Загрузка прайса':
                return <AddPriceManual />;
            case 'Работа с прайсом':
                return <WorkWithPriceManual />;
            case 'Удаление прайса':
                return <DeletePriceManual />;
            case 'Создать себе группу':
                return <AddGroupsAccountsManual />;
            case 'Редактирование группы поставщиков':
                return <EditGroupsAccountsManual />;
            case 'Удаление группы поставщиков':
                return <DeleteGroupsAccountsManual />;
            case 'Какой тариф у меня':
                return <LicensesManual />;
            case 'Как поменять тариф':
                return <LicensesManual />;
            case 'Разницы в функциях':
                return <LicensesManual />;
            case 'Для чего функция "Проценка списком"':
                return <BatchPricerManual />;
            case 'Загрузка проценки списком':
                return <AddBatchPricerManual />;
            case 'Работа с результатами проценки':
                return <WorkWithResultManual />;
            case 'Настройки':
                return <SettingsManual />;
        }
    };
    return <div>{renderManual()}</div>;
}
