import {VirtualTable} from '@f_general/stackTable/VirtualTable';
import {useContext, useEffect, useMemo, useState} from 'react';
import {ThemeContext} from '@f_context/Theme_context';
import {useWindowSize} from '@f_hooks';
import stls
    from '@f_ucs/styles/components/report/violationStatisticsModule/purchaseReport/reportTable/reportTable.module.sass';
import {ColumnDef} from '@tanstack/react-table';
import {
    ColumnTime,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnTime';
import {
    ColumnUser,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnUser';
import {
    ColumnBrand,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnBrand';
import {
    ColumnArticle,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnArticle';
import {
    ColumnTitle,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnTitle';
import {
    ColumnAvarageBill,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnAvarageBill';
import {
    ColumnPerfectPrice,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnPerfectPrice';
import {
    ColumnPerDeviations,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnPerDeviations';
import {
    ColumnVendor,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnVendor';
import {
    ColumnQuantity,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnQuantity';
import {
    ColumnDelivery,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnDelivery';
import {
    ColumnWarehouse,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnWarehouse';
import {
    ColumnMore,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/ReportTableColumns/ColumnMore';
import {
    SuitItems,
} from '@f_ucs/components/report/ViolationStatisticsModule/PurchaseReport/ReportTable/SuitItemsTable';
import {useReport} from '@f_ucs/context/report_context';

export function ReportTable() {

    const {setBestOffersForSusBuys, bestOffersForSusBuys, timeCutReports, susBuys, exportExcelHandler} = useReport();
    const [data, setData] = useState<object>({})

    const normalizeData = (data: any[]) => {
        return data.map((item) => {

            const newItem = {
                ...item,
                ...item.user,
            };


            delete newItem.user;

            if (item.user?.firstName && item.user?.lastName) {
                newItem.employee = `${item.user.firstName} ${item.user.lastName}`;
            } else if (item.user?.alias) {
                newItem.employee = `${item.user.alias}`;
            } else if (item.user?.key) {
                newItem.employee = `${item.user.key}`;
            } else {
                newItem.employee = `Неизвестный пользователь`;
            }

            return newItem;
        });
    };


    useEffect(() => {
        if (susBuys) setData(normalizeData(susBuys))
    }, [susBuys]);


    const {colors} = useContext(ThemeContext);
    const {width} = useWindowSize();
    const [mobile, setMobile] = useState(false);
    const [idRow, setIdRow] = useState<number>(-1);
    const [isOpenDetails, setIsOpenDetails] = useState<boolean>(false);

    useEffect(() => {
        setIsOpenDetails(!isOpenDetails);
    }, [timeCutReports]);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    const handleShowDetails = (
        date: string,
        brand: string,
        article: string,
        title: string,
        price: number,
        vendor: string,
    ) => {
        setIsOpenDetails(!isOpenDetails);
        setBestOffersForSusBuys(
            susBuys.find(
                (el: any) =>
                    el.buyTime === date &&
                    el.brand === brand &&
                    el.article === article &&
                    el.partName === title &&
                    el.price === price &&
                    el.vendor === vendor,
            ),
        );
    };

    const renderCustomSubComponent = ({row}: any) => {
        return (
            <SuitItems
                suitItems={
                    isOpenDetails && JSON.stringify(row.original?.suitableItems) == JSON.stringify(bestOffersForSusBuys.suitableItems)
                        ? row.original?.suitableItems.map((obj: any, index: number) => {
                            return {
                                ...obj,
                                time: `${row.original?.buyTime + ' | ' + row.original?.time?.slice(11, 16)}`,
                            };
                        })
                        : []
                }
                setIsOpenDetails={setIsOpenDetails}
            />
        );
    };

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            [
                {
                    id: 'Срок',
                    accessorKey: 'time',
                    header: 'Дата',
                    cell: (info) => <ColumnTime buyTime={info.row.original.buyTime} index={info.row.index} idRow={idRow}
                                                isOpenDetails={isOpenDetails}/>,
                    size: 70,
                    footer: (props) => props.column.id,
                    sortingFn: (a, b) => {
                        const dateA = new Date(b.original.buyTime);
                        const dateB = new Date(a.original.buyTime);
                        return +dateA - +dateB;
                    },
                },
                {
                    id: 'User',
                    accessorKey: 'employee',
                    header: 'Сотрудник',
                    cell: (info) => <ColumnUser employee={info.row.original.employee}
                                                index={info.row.index} idRow={idRow} isOpenDetails={isOpenDetails}/>,

                    size: 100,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'Brand',
                    accessorKey: 'brand',
                    header: 'Бренд',
                    cell: (info) => <ColumnBrand index={info.row.index} brand={info.row.original?.brand} idRow={idRow}
                                                 isOpenDetails={isOpenDetails}/>,
                    size: 100,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'Article',
                    accessorKey: 'article',
                    header: `Артикул`,
                    cell: (info) => <ColumnArticle index={info.row.index} article={info.row.original?.article}
                                                   idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 130,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'Title',
                    accessorKey: 'partName',
                    header: `Название`,
                    cell: (info) => <ColumnTitle partName={info.row.original.partName} index={info.row.index}
                                                 idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 250,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'AvarageBill',
                    accessorKey: 'price',
                    header: `Цена покупки`,
                    cell: (info) => <ColumnAvarageBill suitItemsLength={info.row.original.suitableItems?.length}
                                                       price={info.row.original.price} index={info.row.index}
                                                       idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 100,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'PerfectPrice',
                    accessorKey: 'PerfectPrice',
                    header: `Лучшая цена`,
                    cell: (info) => <ColumnPerfectPrice original={info.row.original} index={info.row.index}
                                                        idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 100,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'PerDeviations',
                    accessorKey: 'PerDeviations',
                    header: `% отклонений`,
                    cell: (info) => (
                        <ColumnPerDeviations
                            suitItemsLength={info.row.original.suitableItems?.length}
                            suitItems={info.row.original.suitableItems}
                            index={info.row.index}
                            suitItemsPrice={
                                info.row.original.suitableItems.length > 0
                                    ? Math.min(...info.row.original.suitableItems.map(item => item.price))
                                    : 0
                            }
                            price={info.row.original.price}
                            idRow={idRow}
                            isOpenDetails={isOpenDetails}
                        />
                    ),
                    size: 100,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'Vendor',
                    accessorKey: 'vendor',
                    header: `Поставщик`,
                    cell: (info) => <ColumnVendor vendorLength={info.row.original.vendor.length} index={info.row.index}
                                                  vendor={info.row.original.vendor} idRow={idRow}
                                                  isOpenDetails={isOpenDetails}/>,
                    size: 150,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'Quantity',
                    accessorKey: 'quantity',
                    header: `Количество`,
                    cell: (info) => <ColumnQuantity index={info.row.index} quantity={info.row.original.quantity}
                                                    idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 100,
                    footer: (props) => props.column.id,
                    sortingFn: (a: any, b: any) => b.original.quantity - a.original.quantity,
                },
                {
                    id: 'Delivery',
                    accessorKey: 'delivery',
                    header: 'Срок',
                    cell: (info) => <ColumnDelivery deliveryLength={info.row.original.delivery?.length}
                                                    delivery={info.row.original.delivery} index={info.row.index}
                                                    idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 120,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'Warehouse',
                    accessorKey: 'warehouse',
                    header: `Склад`,
                    cell: (info) => <ColumnWarehouse index={info.row.index} warehouse={info.row.original.warehouse}
                                                     idRow={idRow} isOpenDetails={isOpenDetails}/>,
                    size: 120,
                    footer: (props) => props.column.id,
                },
                {
                    id: 'more',
                    accessorKey: 'more',
                    header: '',
                    cell: (info) => <ColumnMore index={info.row.index} buyTime={info.row.original.buyTime}
                                                brand={info.row.original.brand} article={info.row.original.article}
                                                partName={info.row.original.partName} price={info.row.original.price}
                                                vendor={info.row.original.vendor} setIdRow={setIdRow}
                                                isOpenDetails={isOpenDetails} handleShowDetails={handleShowDetails}/>,
                    size: 60,
                    footer: (props) => props.column.id,
                },
            ],

        [data, isOpenDetails, bestOffersForSusBuys, idRow],
    );

    const tableStyles = {
        head: {
            // background: colors.beta,
            container: {
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                // justifyContent: 'center',
            },
            base: true,
            title: {
                fontWeight: 'bold',
                fontSize: '14px',
                textWrap: 'balance',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center',
            },
            inputFilter: {
                borderRadius: '7px'
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '50px',
                maxHeight: '50px',
                fontSize: '16px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div className={stls.container} style={{background: colors.theta}}>
            <div className={stls.head}>
                <p className={stls.title}>Отчет активности</p>
            </div>
            <VirtualTable
                // searchId={tabId}
                data={data}
                columnsTable={columns}
                topFilterPanel={true}
                showColumnSearchAction={true}
                renderCustomSubComponent={isOpenDetails ? renderCustomSubComponent : false}
                configHeader={{
                    fullScrean: true,
                    exportExcel: true,
                    resize: true,
                    grouping: false,
                    columnFilter: false,
                    sorting: true,
                    columnSearch: true,
                    dragging: true,
                }}
                styles={tableStyles}
                overscan={2}
                estimateSize={mobile ? 191 : 39}
                exportExcelHandler={() => exportExcelHandler('suspiciousBuys')}
            />
        </div>
    );
}
