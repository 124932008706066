import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconMenuPriceProps = TypeClassNames;

export const IconMenuPrice = ({ classNames }: TypeIconMenuPriceProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                <path d="M11.3333 8.66668H20V10H11.3333V8.66668ZM8 8.33334H10V10.3333H8V8.33334ZM8 13H10V15H8V13ZM8 17.6667H10V19.6667H8V17.6667ZM11.3333 13.3333H20V14.6667H11.3333V13.3333ZM11.3333 18H20V19.3333H11.3333V18Z" />
            </svg>
        </div>
    );
};
