import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import { useWindowSize } from '@f_hooks';
import { ThemeContext } from '@f_context/Theme_context';

export function CellTime({ info }: any) {
    const { width, height } = useWindowSize();
    const { colors, currentTheme } = useContext(ThemeContext);
    const deliveryEnd =
        new Date(info.row.original.deliveryEnd).getFullYear() == 2001 ? '' : info.row.original.deliveryEnd;
    return (
        <>
            {info.row.original.delivery ? (
                info.row.original.deliveryStart?.length > 15 ? (
                    <Tooltip title={`${info.row.original.deliveryStart} - ${deliveryEnd}`}>
                        <p
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                background: colors.zeta,
                                borderColor: colors.eta,
                                color: colors.omicron,
                            }}
                            className={stls.cellDelivery}>
                            {info.row.original.delivery}
                        </p>
                    </Tooltip>
                ) : (
                    <p
                        style={{
                            background: colors.zeta,
                            borderColor: colors.eta,
                            color: colors.omicron,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '90px',
                        }}
                        className={stls.cellDelivery}>
                        {info.row.original.delivery}
                    </p>
                )
            ) : (
                <></>
            )}
        </>
    );
}
