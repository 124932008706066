import { useContext } from 'react';
import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';

type TypeIconAuthLoadingProps = TypeClassNames;

export const IconAuthLoading = ({ classNames }: TypeIconAuthLoadingProps) => {
    const { colors } = useContext(ThemeContext);
    return (
        <div className={cn(classNames)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(28.166666666666664,28.166666666666664)">
                    <rect x="-15.5" y="-15.5" width="31" height="31" fill={colors.alpha}>
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            repeatCount="indefinite"
                            dur="1.4705882352941175s"
                            keyTimes="0;1"
                            values="1.37;1"
                            begin="-0.4411764705882352s"></animateTransform>
                    </rect>
                </g>
                <g transform="translate(71.83333333333333,28.166666666666664)">
                    <rect x="-15.5" y="-15.5" width="31" height="31" fill={colors.phi}>
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            repeatCount="indefinite"
                            dur="1.4705882352941175s"
                            keyTimes="0;1"
                            values="1.37;1"
                            begin="-0.29411764705882354s"></animateTransform>
                    </rect>
                </g>
                <g transform="translate(28.166666666666664,71.83333333333333)">
                    <rect x="-15.5" y="-15.5" width="31" height="31" fill={colors.phi}>
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            repeatCount="indefinite"
                            dur="1.4705882352941175s"
                            keyTimes="0;1"
                            values="1.37;1"
                            begin="0s"></animateTransform>
                    </rect>
                </g>
                <g transform="translate(71.83333333333333,71.83333333333333)">
                    <rect x="-15.5" y="-15.5" width="31" height="31" fill={colors.alpha}>
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            repeatCount="indefinite"
                            dur="1.4705882352941175s"
                            keyTimes="0;1"
                            values="1.37;1"
                            begin="-0.14705882352941177s"></animateTransform>
                    </rect>
                </g>
            </svg>
        </div>
    );
};
