export const getBestPrice = (row: { suitableItems: any[]; price: number; }) => {
    if (row.suitableItems && row.suitableItems.length > 0) {
        const minPriceItem = row.suitableItems.reduce((minItem: { price: number; }, item: { price: number; }) =>
            item.price < minItem.price ? item : minItem, row.suitableItems[0],
        );
        return minPriceItem.price.toFixed(2) + ' ₽';
    } else if (row.price) {
        return row.price.toFixed(2) + ' ₽';
    } else {
        return '';
    }
};