import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconLoadingSettingsProps = TypeClassNames & {
    id?: any;
};

export const IconLoadingSettings = ({ classNames, id }: TypeIconLoadingSettingsProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <circle id={id} cx="84" cy="50" r="10" fill={colors.load}>
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="0.25s"
                        calcMode="spline"
                        keyTimes="0;1"
                        values="10;0"
                        keySplines="0 0.5 0.5 1"
                        begin="0s"></animate>
                    <animate
                        attributeName="fill"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="discrete"
                        keyTimes="0;0.25;0.5;0.75;1"
                        // values="#91bcc6;#194645;#0a69aa;#07abcc;#91bcc6"
                        begin="0s"></animate>
                </circle>
                <circle cx="16" cy="50" r="10" fill={colors.load}>
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="0;0;10;10;10"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="0s"></animate>
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="16;16;16;50;84"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="0s"></animate>
                </circle>
                <circle cx="50" cy="50" r="10" fill={colors.alpha}>
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="0;0;10;10;10"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.25s"></animate>
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="16;16;16;50;84"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.25s"></animate>
                </circle>
                <circle cx="84" cy="50" r="10" fill={colors.xi}>
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="0;0;10;10;10"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.5s"></animate>
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="16;16;16;50;84"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.5s"></animate>
                </circle>
                <circle cx="16" cy="50" r="10" fill={colors.kappa}>
                    <animate
                        attributeName="r"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="0;0;10;10;10"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.75s"></animate>
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="1s"
                        calcMode="spline"
                        keyTimes="0;0.25;0.5;0.75;1"
                        values="16;16;16;50;84"
                        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                        begin="-0.75s"></animate>
                </circle>
            </svg>
        </div>
    );
};
