import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconChatWithSupportProps = TypeClassNames;

export const IconChatWithSupport = ({ classNames }: TypeIconChatWithSupportProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
                <g clip-path="url(#clip0_3751_18699)">
                    <path
                        d="M32 22.5C32 20.3783 31.1571 18.3434 29.6569 16.8431C28.1566 15.3429 26.1217 14.5 24 14.5C21.8783 14.5 19.8434 15.3429 18.3431 16.8431C16.8429 18.3434 16 20.3783 16 22.5"
                        stroke={colors.alpha}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M32 29.5V30C32 30.5304 31.7893 31.0391 31.4142 31.4142C31.0391 31.7893 30.5304 32 30 32H26.5M14 26.938V25.062C14.0001 24.616 14.1492 24.1828 14.4237 23.8313C14.6982 23.4798 15.0823 23.2302 15.515 23.122L17.255 22.686C17.3434 22.664 17.4357 22.6624 17.5248 22.6813C17.6139 22.7003 17.6976 22.7393 17.7694 22.7954C17.8412 22.8515 17.8993 22.9232 17.9392 23.0051C17.9792 23.087 18 23.1769 18 23.268V28.731C18.0002 28.8223 17.9795 28.9125 17.9395 28.9946C17.8996 29.0767 17.8414 29.1486 17.7695 29.2048C17.6975 29.261 17.6137 29.3001 17.5244 29.319C17.435 29.3379 17.3426 29.3362 17.254 29.314L15.514 28.879C15.0815 28.7707 14.6976 28.5209 14.4233 28.1694C14.149 27.8179 14 27.3849 14 26.939V26.938ZM34 26.938V25.062C33.9999 24.616 33.8508 24.1828 33.5763 23.8313C33.3018 23.4798 32.9177 23.2302 32.485 23.122L30.745 22.686C30.6566 22.664 30.5643 22.6624 30.4752 22.6813C30.3861 22.7003 30.3024 22.7393 30.2306 22.7954C30.1588 22.8515 30.1007 22.9232 30.0608 23.0051C30.0208 23.087 30 23.1769 30 23.268V28.731C29.9999 28.8222 30.0206 28.9122 30.0604 28.9942C30.1003 29.0763 30.1584 29.1481 30.2302 29.2043C30.3021 29.2605 30.3857 29.2996 30.4749 29.3186C30.5641 29.3376 30.6565 29.336 30.745 29.314L32.485 28.879C32.9177 28.7708 33.3018 28.5212 33.5763 28.1697C33.8508 27.8182 33.9999 27.385 34 26.939V26.938Z"
                        stroke={colors.alpha}
                        stroke-width="1.5"
                    />
                    <path
                        d="M25.5 33.5H22.5C22.1022 33.5 21.7206 33.342 21.4393 33.0607C21.158 32.7794 21 32.3978 21 32C21 31.6022 21.158 31.2206 21.4393 30.9393C21.7206 30.658 22.1022 30.5 22.5 30.5H25.5C25.8978 30.5 26.2794 30.658 26.5607 30.9393C26.842 31.2206 27 31.6022 27 32C27 32.3978 26.842 32.7794 26.5607 33.0607C26.2794 33.342 25.8978 33.5 25.5 33.5Z"
                        stroke={colors.alpha}
                        stroke-width="1.5"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3751_18699">
                        <rect width="22" height="21" fill="white" transform="translate(13 13.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
