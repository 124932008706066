import { Tooltip } from '@mui/material';
import { IconChein } from '@f_ucs/components/icons/IconChein';


export function ColumnMore ({ index, buyTime, brand, article, partName, price, vendor,setIdRow,handleShowDetails }:any, isOpenDetails:any,)  {

    return (
        <Tooltip title="Посмотреть детализацию">
            <div
                onClick={() => {
                    !isOpenDetails ? setIdRow(index) : setIdRow(-1);
                    return handleShowDetails(
                        buyTime,
                        brand,
                        article,
                        partName,
                        price,
                        vendor,
                    );
                }}
                style={{ cursor: 'pointer' }}>
                {buyTime ? <IconChein /> : ''}
            </div>
        </Tooltip>
    )
}