import stls from '@f_ucs/styles/components/report/employeeStatisticsModule/employeeStats.module.sass';
import { ActivityReportTable } from '@f_ucs/components/report/EmployeeStatisticsModule/ActivityReport/ActivityReportTable/ActivityReportTable';
import { EmployeeReport } from '@f_ucs/components/report/EmployeeStatisticsModule/EmployeeReport/EmployeeReport';


export function EmployeeStatisticsModule() {
    return (
        <div className={stls.violation}>
            <EmployeeReport/>
        </div>
    );
}