import { ThemeContext } from '@f_context/Theme_context';
import React, { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTaskList.module.sass';
import { IconCheck } from '../../icons/IconCheck';
import { IconFail } from '../../icons/IconFail';
import { useTranslation } from 'react-i18next';
import { IconProgressItems } from '../../icons/IconProgressItems';

export function HeadStatus() {
    const { t } = useTranslation();

    return <p className={stls.headStatus}>{t('qwep.batchPricer.status')}</p>;
}

export function CellStatus({ info }: any) {
    const { colors } = useContext(ThemeContext);

    const [status, setStatus] = useState<any>();

    useEffect(() => {
        switch (info.row.original.info) {
            case 'warn: Проценка списка остановлена!':
                setStatus(<IconCheck />);
                break;
            case 'info: Проценка списка выполняется.':
                setStatus(<IconProgressItems />);
                break;
            default:
                setStatus(<IconFail />);
                break;
        }
    }, [info.row.original.info]);

    return <div className={stls.cellStatus}>{status}</div>;
}
