import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import { Button, CardContent, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricer.module.sass';
import { IconAddPrice } from '../icons/IconAddPrice';
import { IconAttach } from '@f_components/icons/IconAttach';
import { FileUtil } from '@f_qwep/utils/file/file.util';
import { FileValidationComponentEnum } from '@f_qwep/types/file/validation/FileValidationComponent.enum';
import { observer } from 'mobx-react-lite';
import { usePreviewTableStore } from '@f_qwep/general/previewTable/hook/usePreviewTable.store';
import { PreviewDownloadedFileTable } from '@f_qwep/general/previewTable/ui/PreviewDownloadedFileTable';

type TypeEditProps = {
    open: boolean;
    handleClose: any;
    sendData: any;
};

export const AddTaskModal = observer(({ open, handleClose, sendData }: TypeEditProps) => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const [file, setFile] = useState<File | null>();
    const [title, setTitle] = useState(`${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()}`);
    const [disable, setDisable] = useState(true);
    const [titleFile, setTitleFile] = useState('');
    const [loading, setLoading] = useState(false);

    const {
        handleClose: closePreviewTable,
        handleOpen: showPreviewTable,
        setTableData,
        setHeaders,
        getTableData,
        getHeaders,
        isOpen: isShowPreview,
        resetStore,
    } = usePreviewTableStore();

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setDisable(true);
            const file = e.target.files[0];
            const fileUtil = new FileUtil(file, FileValidationComponentEnum.BATCH_PRICER);
            const { title, dataJSON, isValid, tableHeaders } = await fileUtil.getFileData();

            if (isValid) {
                setFile(file);
                setTitleFile(title);
                setDisable(false);
                setHeaders(tableHeaders);
                setTableData(dataJSON);
            } else {
                setFile(null);
                setTitleFile('');
                setDisable(true);
                resetStore();
            }
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        await sendData(title, file);
        setDisable(true);
        setLoading(false);
        resetStore();
        closePreviewTable();
        setTitleFile('');
    };

    const closeModalHandler = () => {
        handleClose();
        setFile(null);
        setTitleFile('');
        setDisable(true);
    };

    return (
        <Modal open={open} onClose={closeModalHandler}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    {isShowPreview ? (
                        <PreviewDownloadedFileTable
                            maxRows={6}
                            colors={colors}
                            headers={getHeaders()}
                            rows={getTableData()}
                        />
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <div className={stls.modalTitle}>
                                    <IconAddPrice />
                                    <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                        {t('qwep.batchPricer.addBatchPricer')}
                                    </Typography>
                                </div>
                            </div>
                            <Typography className={stls.info}>{t('qwep.batchPricer.infoAddBatchPricer')}</Typography>
                            <Typography className={stls.info}>
                                {t('qwep.batchPricer.infoAddBatchPricerLimit')}
                            </Typography>
                            <Typography className={stls.downloadFile}>
                                <a id="downloadFile" style={{ color: colors.alpha }} href="/files/list.xlsx" download>
                                    {t('qwep.batchPricer.download')}
                                </a>
                                {''} {t('qwep.batchPricer.exsampleFile')}
                            </Typography>
                            <TextField
                                id="nameBatchPricer"
                                className={stls.modalInput}
                                type="text"
                                name="supplier"
                                placeholder="Введите название проценки"
                                required
                                value={title}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                            />
                            <div className={stls.file}>
                                <Typography>
                                    <label className={stls.inputAddFile} htmlFor="file">
                                        <IconAttach />
                                        <p id="inputAddFile" style={{ color: colors.alpha }} className={stls.text}>
                                            {t('qwep.price.modal.updateOrAddPriceModal.labelFile')}
                                        </p>{' '}
                                        <p className={stls.fileName}>{titleFile}</p>
                                        <input id="file" type="file" onChange={handleFileChange} />
                                    </label>
                                </Typography>
                            </div>
                        </>
                    )}
                    <div className={stls.buttonsContainer}>
                        <Button
                            className={stls.modalDeleteButton}
                            onClick={isShowPreview ? closePreviewTable : closeModalHandler}
                            size="large"
                            sx={{
                                padding: '13px',
                                background: colors.delta,
                                color: colors.phi,
                                '&:hover': { color: colors.alpha },
                            }}>
                            {isShowPreview ? t('qwep.batchPricer.back') : t('qwep.batchPricer.cancel')}
                        </Button>
                        <Button
                            id="nextBatchPricer"
                            disabled={disable}
                            className={stls.modalButton}
                            size="large"
                            variant="contained"
                            onClick={isShowPreview ? handleSubmit : showPreviewTable}>
                            {!isShowPreview ? t('qwep.batchPricer.next') : t('general.confirm')}
                            {loading && (
                                <CircularProgress className={stls.loading} size={'25px'} sx={{ color: colors.psi }} />
                            )}
                        </Button>
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
});
