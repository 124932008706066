import { GeneralDrawer } from '@f_components/drawer';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SettingsPage } from '@f_components/settings';
import { SupportPage } from '@f_components/support';
import { AlLicenses } from '@f_components/licenses';
import { AppQwep } from '@f_qwep/index';
import { Header } from '@f_components/header';
import { MyCompany } from '@f_components/my-company';
import stls from '@f_styles/components/main/Main.module.sass';
import { useContext, useEffect } from 'react';
import { GeneralContext } from '@f_context/General_context';
import { AppAdmin } from '@f_admin/index';
import { AppCrossdata } from '@f_crossdata/index';
import { ChatPopper } from '@f_components/support/chat/ChatPopper';
import { AppUCS } from '@f_ucs/index';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryComponent } from '@f_general/pageError/PageError';

export function Main() {
    const { drawerPanels } = useContext(GeneralContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (drawerPanels.length && window.location.pathname == '/') {
            if (drawerPanels.find((item: any) => item.applicationSysName === 'qwep')) {
                navigate('/app-qwep');
            } else {
                navigate(`/app-${drawerPanels[0].applicationSysName}`);
            }
        }
    }, [drawerPanels.length]);

    return (
        <>
            <Header />
            <ErrorBoundary fallbackRender={() => <ErrorBoundaryComponent />}>
                <main className={stls.main}>
                    <GeneralDrawer />
                    <Routes>
                        <Route path={'settings'} element={<SettingsPage />} />
                        <Route path={'company'} element={<MyCompany />} />
                        <Route path={'support'} element={<SupportPage />} />
                        <Route path={'licenses'} element={<AlLicenses />} />
                        <Route path={'app-qwep/*'} element={<AppQwep />} />
                        <Route path={'app-admin/*'} element={<AppAdmin />} />
                        <Route path={'app-crossdata/*'} element={<AppCrossdata />} />
                        <Route path={'app-ucs/*'} element={<AppUCS />} />
                    </Routes>
                    {window.location.pathname !== '/support' && <ChatPopper />}
                </main>
            </ErrorBoundary>
        </>
    );
}
