import React from 'react';
import Modal from '@mui/material/Modal';
import { MyModal } from '@f_general/MyModal';
import { Button, CardContent, TextField, Typography } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/group/Group.module.sass';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { useTranslation } from 'react-i18next';
import { GroupContext } from '../../context/Group_context';
import { Property } from '@f_qwep/types';
import { Alert } from '@f_general/alert';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { SettingsContext } from '../../context/Settings_context';
import { IconClose } from '@f_components/icons/IconClose';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';

type TypeDeleteProps = {
    openAdd: boolean;
    setOpenAdd?: any;
};

export function AddGroup({ openAdd, setOpenAdd }: TypeDeleteProps) {
    const { t } = useTranslation();

    const navigate = useQwepNavigate();

    const [openNext, setOpenNext] = React.useState(false);
    const { colors } = React.useContext(ThemeContext);
    const { newGroup, setNewGroup } = React.useContext(GroupContext);
    const { updateProperty, groupsAccountsState, workspaceApplication } = React.useContext(QwepContext);
    const { navigatinHandler } = React.useContext(SettingsContext);

    const handleCloseAdd = () => setOpenAdd(false);
    const handleOpenNext = () => setOpenNext(true);
    const handleCloseNext = () => setOpenNext(false);

    const [templateValue, setTemplateValue] = React.useState({
        id: 1,
        name: '',
        flag: false,
        group: [],
    });

    React.useEffect(() => {
        setTemplateValue({
            id:
                groupsAccountsState?.length > 0
                    ? groupsAccountsState?.reduce((max: any, item: any) => {
                          return item.id > max ? item.id : max;
                      }, 0) + 1
                    : 1,
            flag: false,
            name: '',
            group: newGroup.map((item: any) => ({ ...item, flag: true })),
        });
    }, [newGroup, JSON.stringify(groupsAccountsState)]);

    const handleChangeName = (name: any) => {
        setTemplateValue((prevState) => ({ ...prevState, name: name }));
    };

    const handleSubmit = async () => {
        if (templateValue && groupsAccountsState?.filter((el: any) => el.name == templateValue.name).length > 0) {
            Alert({
                type: 'warning',
                text: 'Группа аккаунтов с таким названием уже существует',
            });
        } else {
            const result = await updateProperty({
                applicationWorkspaceId: workspaceApplication,
                propertyName: Property.GroupsAccounts,
                value: { value: groupsAccountsState ? [...groupsAccountsState, templateValue] : [templateValue] },
            });
            if (!result?.isOk) {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            } else {
                Alert({
                    type: 'success',
                    text: 'Успешно!',
                });
                handleCloseAdd();
                handleOpenNext();
            }
        }
    };

    const handleNavigate = () => {
        navigate('settings');
        navigatinHandler('group_accounts');
        setNewGroup([]);
    };

    return (
        <>
            <Modal open={openAdd} onClose={handleCloseAdd}>
                <MyModal className={stls.modalAdd}>
                    <div onClick={handleCloseAdd} className={stls.modalTitle}>
                        <IconClose />
                    </div>
                    <CardContent className={stls.modalContent}>
                        <IconModalGroup />
                        <Typography className={stls.modalText} variant="h6">
                            {t('groups.groupName')}
                        </Typography>
                        <TextField
                            type="text"
                            name="groupName"
                            placeholder="Введите название группы"
                            onChange={(event) => handleChangeName(event.target.value)}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                className={stls.modalButton}
                                onClick={handleCloseAdd}
                                size="large"
                                sx={{
                                    background: colors.delta,
                                    color: colors.phi,
                                    '&:hover': { color: colors.alpha },
                                }}>
                                {t('groups.cancel')}
                            </Button>
                            <Button
                                className={stls.modalButton}
                                onClick={(e) => {
                                    handleSubmit();
                                }}
                                size="large"
                                variant="contained"
                                type="submit">
                                {t('groups.save')}
                            </Button>
                        </div>
                    </CardContent>
                </MyModal>
            </Modal>
            <Modal open={openNext} onClose={handleCloseNext}>
                <MyModal className={stls.modalNext}>
                    <CardContent className={stls.modalContent}>
                        <IconModalGroup />
                        <Typography className={stls.modalText} variant="h6">
                            {t('groups.createdGroup')}
                        </Typography>
                        <div className={stls.modalButton}>
                            <Button
                                className={stls.modalButton}
                                onClick={handleNavigate}
                                size="large"
                                variant="contained"
                                type="submit">
                                {t('groups.goToMyGroup')}
                            </Button>
                            <Button
                                className={stls.modalButton}
                                onClick={(e) => {
                                    handleCloseNext();
                                }}
                                size="large"
                                sx={{
                                    background: colors.delta,
                                    color: colors.phi,
                                    '&:hover': { color: colors.alpha },
                                }}>
                                {t('groups.createNewGroup')}
                            </Button>
                        </div>
                    </CardContent>
                </MyModal>
            </Modal>
        </>
    );
}
