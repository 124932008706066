import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import React from 'react';

type TypeIconNotSaveProps = TypeClassNames;

export const IconNotSave = ({ classNames }: TypeIconNotSaveProps) => {
    return (
        <div className={cn(classNames)}>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.414 2.5H19C19.2652 2.5 19.5196 2.60536 19.7071 2.79289C19.8946 2.98043 20 3.23478 20 3.5V17.5C20 17.7652 19.8946 18.0196 19.7071 18.2071C19.5196 18.3946 19.2652 18.5 19 18.5H1C0.734784 18.5 0.48043 18.3946 0.292893 18.2071C0.105357 18.0196 0 17.7652 0 17.5V1.5C0 1.23478 0.105357 0.98043 0.292893 0.792893C0.48043 0.605357 0.734784 0.5 1 0.5H8.414L10.414 2.5ZM2 2.5V16.5H18V4.5H9.586L7.586 2.5H2ZM6 9.5H14V11.5H6V9.5Z"
                    fill="#218EE1"
                />
            </svg>
        </div>
    );
};
