import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/home/Home.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Alert } from '@f_general/alert';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { LayoutPage } from '@f_admin/general/LayoutPage';
import { UserSearch } from '../userSearch';
import { AdminContext } from '@f_admin/context/Admin_context';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Loading } from '@f_admin/general/loadding';
import { PanelUsersGeneral } from './PanelUsersGeneral';
import { Content } from './Content';



export function Home() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { allUserInfo, getAllInfo, isLoading } = useContext(AdminContext);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <LayoutPage
                    header={
                        <div className={stls.header}>
                            <UserSearch />
                        </div>
                    }
                    leftPanel={
                        <div className={stls.panel}>
                            <div>
                                
                            </div>
                        </div>
                    }>
                    <Content allUserInfo={allUserInfo} getAllInfo={getAllInfo} />
                </LayoutPage>
            )}
        </>
    );
}
