import { ManualContext } from '@f_qwep/context/Manual_context';
import { OrdersContext } from '@f_qwep/context/Orders_context';
import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { useWindowSize } from '@f_hooks';
import { IconMaskot } from '@f_components/icons/IconMascot';

export function OrdersFiltertManual() {
    const { isLoading, open } = useContext(OrdersContext);
    const { setChooseManual } = useContext(ManualContext);

    const [run, setRun] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);

    const [steps, setSteps] = useState<any>([
        {
            target: '#orders',
            content: (
                <div>
                    <IconMaskot />
                    <p>Зайдите в раздел "Заказы"</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#ordersLoading',
            content: 'Дождитесь загрузки заказов',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: true,
        },
        {
            target: '#mobileButtonOrder',
            content: 'Откройте фильтры',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#filterOrderPanel',
            title: 'Фильтры для работы с таблицей заказов',
            content: 'Фильтры помогут Вам быстрее и качественнее найти необходимый заказ',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#ordersBrands',
            content: 'Отметьте искомые бренды',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#ordersVendors',
            content: 'Отметьте необходимых поставщиков',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#ordersStatus',
            content: 'Выберите требуемый статус',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#ordersDate',
            content: 'Изначально заказы отображаются за последний месяц, но вы можете задать произвольный интервал',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
    ]);

    const { width } = useWindowSize();

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            setRun(true);
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/orders') {
            if (isLoading) {
                setCurrentStep((prevStep) => prevStep + 1);
            } else {
                setTimeout(() => {
                    setCurrentStep((prevStep) => (width > 950 ? prevStep + 3 : prevStep + 2));
                }, 1000);
            }
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (width) {
            if (currentStep == 2) {
                const target = document.querySelector('#buttonFilters');
                if (!target && width > 950) {
                    setCurrentStep((prevStep) => prevStep + 1);
                } else {
                    if (open) {
                        setCurrentStep((prevStep) => prevStep + 1);
                    }
                }
            }
        }
    }, [currentStep, width, open]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
            setChooseManual();
        } else if (type === 'step:after' && (action === 'back' || action === 'prev')) {
            setCurrentStep((prevStep) => prevStep - 1);
        } else if (status === 'finished') {
            setCurrentStep(0);
            setChooseManual();
        }
    };

    return (
        <div>
            <GeneralJoyride
                steps={steps}
                run={run}
                callback={handleJoyrideCallback}
                stepIndex={currentStep}
                disabledButton={isLoading}
            />
        </div>
    );
}
