import stls from '@f_qwep/styles/component/tables/searchResultTable/ColumnStyle.module.sass';
import DeliveryChanceTooltip from './deliveryChanceTolltip';
import { IconInfoTable } from '@f_qwep/components/icons/IconInfoTable';
import { IconReuse } from '@f_qwep/components/icons/IconReuse';
import { IconMarkdown } from '@f_qwep/components/icons/IconMarkdown';
import { IconStatusTable } from '@f_qwep/components/icons/IconStatusTable';
import { SearchResultsContext } from '../../../context/SearchResult_context';
import { useContext, useEffect, useState } from 'react';
import { useWindowSize } from '@f_hooks';
import { ThemeContext } from '@f_context/Theme_context';
import { IconDiscont } from '../../icons/IconDiscont';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function CellInfo({ info }: any) {
    const { t } = useTranslation();
    const { setOpenCardResult, setCardItem } = useContext(SearchResultsContext);
    const { colors, currentTheme } = useContext(ThemeContext);
    let chanceDelivery: number = Number(info.row.original.info?.supplyPercent);
    let color;
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);
    let icon = <div style={{ width: '19.5px' }}></div>;
    const isRefund = info.row.original.info?.isRefundAvailable;
    let isRefundText = '';
    if (isRefund === 'undefined') {
        icon = <div style={{ width: '19.5px' }}></div>;
    } else if (isRefund === 'true') {
        icon = <IconReuse classNames={[stls.iconReuse]} colorReuse={colors.green} />;
        isRefundText = t('qwep.search.refundAvalible.can');
    } else if (isRefund === 'false') {
        icon = <IconReuse classNames={[stls.iconReuse]} colorReuse={colors.red} />;
        isRefundText = t('qwep.search.refundAvalible.cant');
    }
    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    if (chanceDelivery > 66) {
        color = colors.green;
    }
    if (chanceDelivery <= 33) {
        color = colors.red;
    }
    if (chanceDelivery > 33 && chanceDelivery <= 66) {
        color = colors.yellow;
    }
    const numberCell = info.cell.id.split('')[0];
    const isMarkdown = info.row.original.info?.isMarkdown;
    const isUsed = info.row.original.info?.isUsed;

    return (
        <div className={mobile ? stls.mobCellinfo : stls.cellInfo}>
            <Tooltip title={isRefundText}>
                <div>{icon}</div>
            </Tooltip>
            <div className={stls.groupInfo}>
                <button
                    onClick={() => {
                        setOpenCardResult(true);
                        setCardItem(info.row.original);
                    }}
                    className={stls.btn}>
                    <IconInfoTable classNames={[stls.icon]} />
                </button>
                {isMarkdown || isUsed ? (
                    <div className={stls.miniIcons}>
                        {isMarkdown ? <IconMarkdown classNames={[stls.iconMarkdown]} color={colors.green} /> : <></>}
                        {isUsed ? <IconDiscont classNames={[stls.iconDiscont]} color={colors.mu} /> : <></>}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {info.row.original.info?.supplyPercent ? (
                <DeliveryChanceTooltip
                    colorGreen={colors.green}
                    colorRed={colors.red}
                    colorBackground={colors.grey}
                    info={info}
                    numberCell={numberCell}>
                    <button className={stls.btn}>
                        <IconStatusTable classNames={[stls.icon]} color={color} />
                    </button>
                </DeliveryChanceTooltip>
            ) : (
                <div style={{ width: '16px' }}></div>
            )}
            {/* <button className={stls.btn}><IconPhotoTable classNames={[stls.icon]}/></button> */}
        </div>
    );
}
