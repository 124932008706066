import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import { Button, Modal } from '@mui/material';
import { useContext } from 'react';
import stls from '@f_qwep/styles/component/settings/InfoModal.module.sass';
import { IconModalEdit } from '../../icons/IconModalEdit';
import React from 'react';

type TypeEditProps = {
    open: boolean;
    handleClose: () => void;
    dataModal: any;
};

export function InfoModal({ open, handleClose, dataModal }: TypeEditProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const extractIframeOrFullObject = (element: React.ReactNode): React.ReactNode => {
        if (React.isValidElement(element)) {
            const traverse = (node: React.ReactNode): React.ReactNode => {
                if (React.isValidElement(node)) {
                    if (node.type === 'iframe') {
                        return (
                            <>
                                <a
                                    className={stls.iframeLink}
                                    href={node.props.src}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Развернуть инструкцию
                                </a>
                                <iframe className={stls.iframe} src={node.props.src} />
                            </>
                        );
                    }
                    if (node.props && node.props.children) {
                        return React.Children.map(node.props.children, (child) => traverse(child)).find((x: any) => x);
                    }
                }
                return null;
            };
            const iframe = traverse(element);
            return iframe || element;
        }
        return element;
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <MyModal className={stls.modal}>
                <div className={stls.modalContent}>
                    <div className={stls.modalTitle}>
                        <IconModalEdit />
                        <p className={stls.title}>{t('qwep.settings.suppliers.modal.info.infoVendor')}</p>
                    </div>
                    <div>
                        <div className={stls.item}>
                            <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.title')}</p>
                            <p style={{ color: colors.alpha }}>{dataModal.title}</p>
                        </div>
                        {dataModal.juristTitle && (
                            <div className={stls.item}>
                                <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.juristTitle')}</p>
                                <p style={{ color: colors.alpha }}>{dataModal.juristTitle}</p>
                            </div>
                        )}
                        {dataModal.description && (
                            <div className={stls.item}>
                                <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.description')}</p>
                                <p style={{ color: colors.alpha }}>{dataModal.description}</p>
                            </div>
                        )}

                        {dataModal.phone && (
                            <div className={stls.item}>
                                <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.phone')}</p>
                                <p style={{ color: colors.alpha }}>{dataModal.phone}</p>
                            </div>
                        )}

                        {dataModal.schedule && (
                            <div className={stls.item}>
                                <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.schedule')}</p>
                                <p style={{ color: colors.alpha }}>{dataModal.schedule}</p>
                            </div>
                        )}

                        {dataModal.officeAddress && (
                            <div className={stls.item}>
                                <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.officeAddress')}</p>
                                <p style={{ color: colors.alpha }}>{dataModal.officeAddress}</p>
                            </div>
                        )}

                        {dataModal.delivery && (
                            <div className={stls.item}>
                                <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.delivery')}</p>
                                <p style={{ color: colors.alpha }}>{dataModal.delivery}</p>
                            </div>
                        )}

                        <div className={stls.item}>
                            <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.hasTruckProgram')}</p>
                            <p style={{ color: colors.alpha }}>
                                {dataModal.hasTruckProgram ? t('general.yes') : t('general.no')}
                            </p>
                        </div>

                        <div className={stls.item}>
                            <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.hasPassengerProgram')}</p>
                            <p style={{ color: colors.alpha }}>
                                {dataModal.hasPassengerProgram ? t('general.yes') : t('general.no')}
                            </p>
                        </div>

                        <div className={stls.item}>
                            <p className={stls.text}>{t('qwep.settings.suppliers.modal.info.forPhysicFaces')}</p>
                            <p style={{ color: colors.alpha }}>
                                {dataModal.forPhysicFaces ? t('general.yes') : t('general.no')}
                            </p>
                        </div>
                    </div>

                    <div className={stls.instruction}>{extractIframeOrFullObject(dataModal.tutorial)}</div>
                    <Button className={stls.btn} onClick={handleClose}>
                        {t('qwep.settings.suppliers.modal.info.close')}
                    </Button>
                </div>
            </MyModal>
        </Modal>
    );
}
