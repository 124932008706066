import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { IconSettings } from '@f_components/icons/IconSettings';
import { IconFullScrean } from '@f_components/icons/IconFullScrean';
import { IconFilter } from '@f_components/icons/IconFilter';
import { IconExportExcel } from '@f_components/icons/IconExportExcel';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import stls from '@f_styles/general/table/TableFiltersTopPanel.module.sass';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';

export function TableFiltersTopPanel({
    table,
    columnFilter,
    fullScrean,
    exportExcel,
    columnSearch,
    chipFilters,

    fullScreanAction,
    setFullScreanAction,
    columnSearchAction,
    setColumnSearchAction,
    exportExcelAction,
    setExportExcelAction,
}: any) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { t } = useTranslation();
    const [stylesBtn, setStylesBtn] = useState({
        active: stls.active,
        btn: stls.btn,
    });

    useEffect(() => {
        if (currentTheme === 'vinpin') {
            setStylesBtn({
                active: stls.active_vinpin,
                btn: stls.btn_vinpin,
            });
        } else {
            setStylesBtn({
                active: stls.active,
                btn: stls.btn,
            });
        }
    }, [currentTheme]);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id: string = open ? 'popover_TableColumnVisibility' : '';

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filteredHandler = () => {
        setColumnSearchAction(!columnSearchAction);
    };

    const fullScreanHandler = () => {
        setFullScreanAction(!fullScreanAction);
    };

    const exportExcelHandler = () => {
        console.log('Export EXcel');
        setExportExcelAction(true);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', gap: '20px' }}>
            {chipFilters}
            <div className={stls.container}>
                {columnSearch && (
                    <button className={stylesBtn.btn} onClick={() => filteredHandler()}>
                        <IconFilter classNames={[stls.icon, columnSearchAction && stylesBtn.active]} />
                        <span className={cn(stls.text, columnSearchAction && stylesBtn.active, stls.textAdaptive)}>
                            - {t('general.table.filters')}
                        </span>
                    </button>
                )}
                {columnFilter && (
                    <button
                        id="tableSettings"
                        className={stylesBtn.btn}
                        aria-describedby={id}
                        onClick={(e) => handleClick(e)}>
                        <IconSettings classNames={[stls.icon, id && stylesBtn.active]} />
                        <span className={cn(stls.text, id && stylesBtn.active, stls.textAdaptive)}>
                            - {t('general.table.settings')}
                        </span>
                    </button>
                )}
                {exportExcel && (
                    <button className={stylesBtn.btn} onClick={() => exportExcelHandler()}>
                        <IconExportExcel classNames={[stls.icon, exportExcelAction && stylesBtn.active]} />
                        <span className={cn(stls.text, exportExcelAction && stylesBtn.active, stls.textAdaptive)}>
                            - {t('general.table.export')}
                        </span>
                    </button>
                )}
                {fullScrean && (
                    <button className={stylesBtn.btn} onClick={() => fullScreanHandler()}>
                        <IconFullScrean classNames={[stls.icon, fullScreanAction && stylesBtn.active]} />
                    </button>
                )}

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div className={stls.content}>
                        <div className={stls.input}>
                            <Switch
                                {...{
                                    checked: table.getIsAllColumnsVisible(),
                                    onChange: table.getToggleAllColumnsVisibilityHandler(),
                                }}
                            />
                            <span className={stls.inputText} style={{ color: colors.omicron }}>
                                {t('general.table.chooseAll')}
                            </span>
                        </div>
                        <Divider />
                        {table.getAllLeafColumns().map((column: any) => {
                            return (
                                <div className={stls.inputItem} key={column.id}>
                                    <Switch
                                        {...{
                                            checked: column.getIsVisible(),
                                            onChange: column.getToggleVisibilityHandler(),
                                        }}
                                    />
                                    <span style={{ color: colors.omicron }} className={stls.inputText}>
                                        {column.columnDef.header
                                            ? column.columnDef.header
                                            : column.columnDef.accessorKey}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </Popover>
            </div>
        </div>
    );
}
