import * as yup from 'yup';
import { azEmail, azPhoneNumber, azString } from './FormRules';

export const addUserCustomerSchema = yup.object().shape({
    firstName: azString({ required: true, fieldName: 'Имя' }),
    groupName: azString({ required: true, fieldName: 'Название группы' }),
    lastName: azString({ required: true, fieldName: 'Фамилия' }),
    organization: azString({ required: true, fieldName: 'Организация' }),
    email: azEmail({ required: false, fieldName: 'E-mail' }),
    password: azString({ required: true, fieldName: 'Пароль' }),
    confirmPassword: azString({ required: true, fieldName: 'Пароль' }),
    phoneNumber: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
});

export const CreateUserCustomerSchema = yup.object().shape({
    firstName: azString({ required: true, fieldName: 'Имя' }),
    lastName: azString({ required: true, fieldName: 'Фамилия' }),
    organization: azString({ required: true, fieldName: 'Организация' }),
    email: azEmail({ required: false, fieldName: 'E-mail' }),
    password: azString({ required: true, fieldName: 'Пароль' }),
    confirmPassword: azString({ required: true, fieldName: 'Пароль' }),
    phoneNumber: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
    position: azString({ required: true, fieldName: 'Должность' }),
});

export const loginUserCustomerSchema = yup.object().shape({
    email: azEmail({ required: true, fieldName: 'E-mail' }),
    password: azString({ required: true, fieldName: 'Пароль' }),
});

export const forgetPasswordUserCustomerSchema = yup.object().shape({
    email: azEmail({ required: true, fieldName: 'E-mail' }),
});

export const forgetPassworResetUserCustomerSchema = yup.object().shape({
    password: azString({ required: true, fieldName: 'Пароль' }),
    confirmPassword: azString({ required: true, fieldName: 'Пароль' }),
});

//Profile
export const UpdateUserProfileCustomerSchema = yup.object().shape({
    firstName: azString({ required: true, fieldName: 'Имя' }),
    lastName: azString({ required: true, fieldName: 'Фамилия' }),
    position: azString({ required: true, fieldName: 'Должность' }),
});

export const languageCustomerSchema = yup.object().shape({
    language: azString({ required: true, fieldName: 'Язык интерфейса' }),
});

//Modal
export const ChangePasswordUserProfileCustomerSchema = yup.object().shape({
    password: azString({ required: true, fieldName: 'Пароль' }),
    new_password: azString({ required: true, fieldName: 'Пароль' }),
    repeatNew_password: azString({ required: true, fieldName: 'Пароль' }),
});

export const ChangeEmailUserProfileCustomerSchema = yup.object().shape({
    email: azEmail({ required: true, fieldName: 'E-mail' }),
    new_email: azEmail({ required: true, fieldName: 'E-mail' }),
});

export const ChangePhoneUserProfileCustomerSchema = yup.object().shape({
    tel: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
    new_tel: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
});

//ModalUser
export const ChangeUserModalCustomerSchema = yup.object().shape({
    role: azString({ required: true, fieldName: 'Роль' }),
    isActive: azString({ required: true, fieldName: 'Активный' }),
});

export const DeleteUserModalCustomerSchema = yup.object().shape({
    userName: azString({ required: true, fieldName: 'Имя пользователя' }),
});

export const addUserToCompanyModalCustomerSchema = yup.object().shape({
    firstName: azString({ required: true, fieldName: 'Имя' }),
    lastName: azString({ required: true, fieldName: 'Фамилия' }),
    email: azEmail({ required: true, fieldName: 'E-mail' }),
    position: azString({ required: true, fieldName: 'Должность' }),
    phoneNumber: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
    role: azString({ required: true, fieldName: 'Роль' }),
});

export const addExistingUserToCompanyModalCustomerSchema = yup.object().shape({
    email: azEmail({ required: true, fieldName: 'E-mail' }),
    role: azString({ required: true, fieldName: 'Роль' }),
});

export const addCompanyModalCustomerSchema = yup.object().shape({
    organization: azString({ required: true, fieldName: 'Название компании' }),
    telegramNickname: azString({ required: false, fieldName: 'Телеграм @' }),
});

//Call Me

export const CallMeModalCustomerSchema = yup.object().shape({
    phone: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
    name: azString({ required: true, fieldName: 'Имя' }),
    comment: azString({ required: true, fieldName: 'Комментарий' }),
});

//send Support

export const SendSupportModalCustomerSchema = yup.object().shape({
    phone: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
    name: azString({ required: true, fieldName: 'Имя' }),
    title: azString({ required: true, fieldName: 'Тема обращения' }),
    description: azString({ required: true, fieldName: 'Текст обращения' }),
    product: azString({ required: true, fieldName: 'Продукт' }),
    // type: azString({ required: true, fieldName: "Тип обращения" })
});

//QWEP

//Account
export const addAccountModalCustomerSchema = yup.object().shape({
    login: azString({ required: true, fieldName: 'Логин аккаунта' }),
    password: azString({ required: false, fieldName: 'Пароль' }),
});

//add price
export const addPriceModalCustomerSchema = yup.object().shape({
    email: azEmail({ required: true, fieldName: 'E-mail' }),
    vendor: azString({ required: true, fieldName: 'Поставщик' }),
    currency: azString({ required: false, fieldName: 'Валюта' }),
    link: azString({ required: false, fieldName: 'Ссылка' }),
});

//update price
export const updatePriceModalCustomerSchema = yup.object().shape({
    email: azEmail({ required: true, fieldName: 'E-mail' }),
    currency: azString({ required: false, fieldName: 'Валюта' }),
    link: azString({ required: false, fieldName: 'Ссылка' }),
});

//tariff
export const choosenTariffQwepCustomerSchema = yup.object().shape({
    phone: azPhoneNumber({ required: true, fieldName: 'Номер телефона' }),
    name: azString({ required: true, fieldName: 'Имя пользователя' }),
});

//ucs

export const report1cUCSCustomerSchema = yup.object().shape({
    title: azString({ required: true, fieldName: 'Название' }),
    timeStart: azString({ required: true, fieldName: 'Начало среза' }),
    timeFinish: azString({ required: true, fieldName: 'Конец среза' }),
});
