import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/userInfo/UserInfo.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { LayoutPage } from '@f_admin/general/LayoutPage';
import { Avatar, Button, Tab, Tabs } from '@mui/material';
import { UserSearch } from '../userSearch';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { LeftPanel } from './LeftPanel';
import { ApplicationsInfo } from './ApplicationsInfo';
import { UserRoleChip } from '@f_general/UserRoleChip';
import { Alert } from '@f_general/alert';
import { TableUsersToWorkspace } from './TableUsersToWorkspace';
import { AccountModal } from './AccountModal';
import { IconSettings } from '../icons/IconSettings';
import { IconEmail } from '../icons/IconEmail';
import { IconPhone } from '../icons/IconPhone';
import { IconStatusOk } from '../icons/IconStatusOk';
import { IconCaseCompany } from '../icons/IconCaseCompany';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconStatusNotOk } from '../icons/IconStatusNotOk';
import useAdminNavigate from '@f_admin/hooks/useAdminNavigate';

export function UserInfo() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const navigate = useAdminNavigate();
    const {
        userInfo,
        workspacesInfo,
        setCurrentApplication,
        getUsersToWorkspace,
        setQwepAppInfo,
        setCurrentWorkspace,
    } = useContext(UserAccountContext);
    const [createDate, setCreateDate] = useState('');
    const [lastOnlineDate, setLastOnlineDate] = useState('');
    //users
    const [openTable, setOpenTable] = useState(false);
    const [users, setUsers] = useState([]);
    //modal
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('');

    useEffect(() => {
        if (userInfo) {
            setCreateDate(new Date(userInfo?.createDate).toLocaleDateString());
            setLastOnlineDate(new Date(userInfo?.lastOnlineDate).toLocaleDateString());
        } else {
            navigate();
        }
    }, [userInfo]);

    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setUsers([]);
        setOpenTable(false);
    };

    const getUsers = async (workspaceId: number) => {
        if (openTable) {
            setOpenTable(false);
            return;
        }
        const result = await getUsersToWorkspace(workspaceId);
        if (result.isOk) {
            setUsers(result.result);
            setOpenTable(true);
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
            setOpenTable(false);
        }
    };

    const clickModalHandler = (type: string) => {
        setOpen(true);
        setType(type);
    };

    return (
        <>
            {userInfo && (
                <LayoutPage
                    header={
                        <div className={stls.header}>
                            <UserSearch />
                            <div className={stls.info}>
                                {userInfo && (
                                    <>
                                        <div className={stls.mainBlock}>
                                            <div className={stls.content}>
                                                <div className={stls.active}>
                                                    {userInfo?.isActive ? <IconStatusOk /> : <IconStatusNotOk />}
                                                </div>
                                                <div className={stls.row}>
                                                    <Avatar
                                                        alt={userInfo?.firstName}
                                                        className={stls.avatar}
                                                        src={
                                                            userInfo.photo
                                                                ? userInfo.photo
                                                                : '/static/images/avatar/1.jpg'
                                                        }
                                                        sx={{ width: 60, height: 60 }}
                                                    />
                                                    <p className={stls.name}>{userInfo?.firstName}</p>
                                                    <p className={stls.name}>{userInfo?.lastName}</p>
                                                </div>

                                                <div
                                                    className={stls.row}
                                                    style={{ marginTop: '6px', display: 'flex', flexWrap: 'wrap' }}>
                                                    <a
                                                        href={`mailto: ${userInfo?.email}`}
                                                        className={stls.text}
                                                        style={{ color: colors.alpha }}>
                                                        <IconEmail />
                                                        {userInfo?.email}
                                                    </a>
                                                    <a
                                                        href={`tel:${userInfo?.phoneNumber}`}
                                                        className={stls.text}
                                                        style={{ color: colors.alpha, marginLeft: '30px' }}>
                                                        <IconPhone />
                                                        {userInfo?.phoneNumber}
                                                    </a>
                                                </div>
                                            </div>

                                            <div className={stls.subContent}>
                                                <div className={stls.subText}>
                                                    <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                        {t('adminPanel.createDate')}:
                                                    </p>
                                                    {createDate}
                                                </div>
                                                <div className={stls.subText}>
                                                    <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                        {t('adminPanel.lastOnlineDate')}:
                                                    </p>
                                                    {lastOnlineDate}
                                                </div>
                                                <div className={stls.subText}>
                                                    <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                        {t('adminPanel.parentUser')}:
                                                    </p>
                                                    {userInfo?.createBy ? userInfo?.createBy : '-'}
                                                </div>

                                                <div className={stls.subText}>
                                                    <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                        {t('adminPanel.position')}:
                                                    </p>
                                                    {userInfo?.position}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={stls.techComponent} style={{ borderColor: colors.eta }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className={stls.subText}>
                                                    <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                        {t('adminPanel.userId')}:
                                                    </p>
                                                    {userInfo?.userId}
                                                </div>
                                                <IconSettings />
                                            </div>
                                            <div className={stls.subText}>
                                                <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                    {t('adminPanel.status')}:
                                                </p>
                                                {userInfo?.statusUser}
                                            </div>
                                            <div className={stls.subText}>
                                                <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                    {t('adminPanel.updated')}:
                                                </p>
                                                {new Date(userInfo?.updated).toLocaleDateString()}
                                            </div>
                                            <div className={stls.subText}>
                                                <p style={{ color: colors.omicronTitle }} className={stls.span}>
                                                    {t('adminPanel.key')}:
                                                </p>
                                                {userInfo?.activationKey}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    }
                    leftPanel={<div className={stls.panel}>{workspacesInfo?.length && <LeftPanel />}</div>}>
                    <div className={stls.container}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            className={stls.tabs}
                            sx={{ background: colors.iota }}>
                            {' '}
                            {t('adminPanel.panel')}
                            <IconCaseCompany />
                            {workspacesInfo?.map((item: any, idx: number) => {
                                return (
                                    <Tab
                                        className={stls.tab}
                                        sx={{ color: `${colors.omicron} !important` }}
                                        key={item.workspaceId}
                                        label={
                                            <p>
                                                {item.workspaceName}{' '}
                                                {item.userWorkspaceIsActive && (
                                                    <div className={stls.markContainer}>
                                                        <span className={stls.userNumber}>
                                                            {item.usersToWorkspace?.length}
                                                        </span>
                                                        <span className={stls.mark} />
                                                    </div>
                                                )}
                                            </p>
                                        }
                                        value={idx}
                                    />
                                );
                            })}
                            <Button
                                className={stls.btn}
                                onClick={() => clickModalHandler('company')}
                                variant="contained">
                                {t('adminPanel.createCompany')}
                            </Button>
                        </Tabs>
                        {workspacesInfo?.map((item: any, idx: number) => {
                            if (value === idx) {
                                setCurrentWorkspace(item);
                                if (!item.applicationWorkspce?.length) {
                                    setCurrentApplication();
                                    setQwepAppInfo('');
                                }
                            }
                            return (
                                <div
                                    key={item.workspaceId}
                                    role="tabpanel"
                                    hidden={value !== idx}
                                    id={`tabpanel-${idx}`}
                                    aria-labelledby={`tab-${idx}`}>
                                    {value === idx && (
                                        <>
                                            <div className={stls.content}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '100%',
                                                        flexWrap: 'wrap',
                                                    }}>
                                                    <div className={stls.mainBlock}>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {t('adminPanel.createDateCompany')}:{' '}
                                                            </p>
                                                            {new Date(item.registerDate).toLocaleDateString()}
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {t('adminPanel.companyId')}:{' '}
                                                            </p>
                                                            {item.workspaceId}
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {t('adminPanel.active')}:{' '}
                                                            </p>
                                                            {item.isActive ? t('adminPanel.yes') : t('adminPanel.no')}
                                                        </div>
                                                    </div>
                                                    <div className={stls.techInfo}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}>
                                                            <div className={stls.text}>
                                                                <p style={{ color: colors.omicronTitle }}>
                                                                    {t('adminPanel.telegramNickname')}:{' '}
                                                                </p>
                                                                {item.telegramNickname ? item.telegramNickname : '-'}
                                                            </div>
                                                            <IconSettings />
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {t('adminPanel.updated')}:{' '}
                                                            </p>
                                                            {new Date(item.updated).toLocaleDateString()}
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {t('adminPanel.parentCompany')}:{' '}
                                                            </p>
                                                            {item.createBy ? item.createBy : '-'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={stls.activeBlock}>
                                                    <UserRoleChip
                                                        classNames={[stls.roleBox]}
                                                        sysName={item.role.map((item: any) => item.userRole)}
                                                    />
                                                    <button
                                                        style={{ color: colors.alpha }}
                                                        className={stls.btn}
                                                        onClick={() => getUsers(item.workspaceId)}>
                                                        {openTable ? (
                                                            <>
                                                                {t('adminPanel.hide')}{' '}
                                                                <ArrowUpwardIcon
                                                                    sx={{ color: colors.alpha }}
                                                                    fontSize="small"
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {t('adminPanel.showUsers')}{' '}
                                                                <ArrowDownwardIcon
                                                                    sx={{ color: colors.alpha }}
                                                                    fontSize="small"
                                                                />
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {openTable && (
                                                <TableUsersToWorkspace
                                                    users={users}
                                                    appsToWorkspace={item.applicationWorkspce}
                                                    getUsers={getUsers}
                                                />
                                            )}
                                            <ApplicationsInfo
                                                usersToWorkspace={item.usersToWorkspace}
                                                applications={item.applicationWorkspce}
                                                setCurrentApplication={setCurrentApplication}
                                                workspaceId={item.workspaceId}
                                                clickModalHandler={clickModalHandler}
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <AccountModal
                        open={open}
                        handleClose={() => setOpen(false)}
                        type={type}
                        workspacesInfo={workspacesInfo}
                    />
                </LayoutPage>
            )}
        </>
    );
}
