import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import stls from '@f_admin/styles/components/userInfo/LeftPanel.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { CircularProgress } from '@mui/material';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import GeneralModal from '@f_general/GeneralModal';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { Alert } from '@f_general/alert';
import { EditApplicationQwepModal } from './EditApplicationQwepModal';
import GeneraCreateUserModal from '@f_general/GeneraCreateUserModal';
import { IconInfoQwep } from '../icons/IconInfoQwep';
import { IconEditQwep } from '../icons/IconEditQwep';
import { IconEditCompany } from '../icons/IconEditCompany';
import { IconAddUser } from '../icons/IconAddUser';
import { IconDeleteCompany } from '../icons/IconDeleteCompany';
import { IconDeleteApp } from '../icons/IconDeleteApp';
import { IconEditModal } from '../icons/IconEditModal';
import { Apps } from '@f_types';
import { Limits } from '@f_crossdata/type';
import { EditApplicationCrossdataModal } from './EditApplicationCrossdataModal';
import { IconAddModal } from '../icons/IconAddModal';
import { EditApplicationUCSModal } from './EditApplicationUCSModal';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function LeftPanel() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const {
        currentApplication,
        currentWorkspace,
        deleteApplications,
        setQwepAppInfo,
        userInfo,
        deleteWorkspace,
        qwepAppInfo,
        getQwepAppInfo,
        updateWorkspace,
        addExistingUser,
        getUCSAppInfo,
        addNewUser,
        getCrossdataAppInfo,
        crossdataAppInfo,
        setCrossdataAppInfo,
        UCSAppInfo,
        setUCSAppInfo,
        updateUCSApp,
    } = useContext(UserAccountContext);
    const [loading, setLoading] = useState(false);
    //modal
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});
    //modal edit qwep
    const [openEditQwep, setOpenEditQwep] = useState(false);
    //modal edit crossdata
    const [openEditCrossdata, setOpenEditCrossdata] = useState(false);
    //modal edit ucs
    const [openEditUCS, setOpenEditUCS] = useState(false);

    //madal add user
    const [openAddUser, setOpenAddUser] = useState(false);

    useEffect(() => {
        if (currentApplication) {
            switch (currentApplication.propertyApplication.sysNameApplication) {
                case Apps.Qwep:
                    getQwepInfo(currentApplication.propertyApplication.property.authorizationCode);
                    setCrossdataAppInfo();
                    setUCSAppInfo();
                    break;
                case Apps['Admin Panel']:
                    setQwepAppInfo();
                    setCrossdataAppInfo();
                    setUCSAppInfo();
                    break;
                case Apps.Data:
                    setQwepAppInfo();
                    setUCSAppInfo();
                    getCrossdataInfo(currentApplication.propertyApplication.authToken);
                    break;
                case Apps.UCS:
                    getUCSInfo(currentApplication.propertyApplication.qwepAuthCode);
                    setQwepAppInfo();
                    setCrossdataAppInfo();
                    break;
            }
        }
    }, [currentApplication, currentWorkspace]);

    const getQwepInfo = async (authorizationCode: string) => {
        setLoading(true);
        await getQwepAppInfo(authorizationCode);
        setLoading(false);
    };

    const getCrossdataInfo = async (authToken: string) => {
        setLoading(true);
        await getCrossdataAppInfo(authToken);
        setLoading(false);
    };

    const getUCSInfo = async (authCode: string) => {
        setLoading(true);
        await getUCSAppInfo(authCode);
        setLoading(false);
    };

    const clickModalHandler = (type: string) => {
        switch (type) {
            case 'editCompany':
                setDataModal({
                    icon: <IconEditModal />,
                    title: `${t('adminPanel.editingCompany')} ${currentWorkspace.workspaceName}`,
                    props: [
                        {
                            title: t('adminPanel.title'),
                            control: 'input',
                            name: 'workspaceName',
                            type: 'text',
                            defaultValue: currentWorkspace.workspaceName,
                        },
                        {
                            title: t('adminPanel.telegramNickname'),
                            control: 'input',
                            name: 'telegramNickname',
                            type: 'text',
                            defaultValue: currentWorkspace.telegramNickname,
                        },
                        {
                            title: t('adminPanel.parentCompanyId'),
                            control: 'input',
                            name: 'createBy',
                            type: 'text',
                            defaultValue: currentWorkspace.createBy,
                        },
                        {
                            control: 'checkbox',
                            title: t('adminPanel.companyActive'),
                            name: 'isActive',
                            type: 'checkbox',
                            options: [
                                {
                                    key: 'Active',
                                    value: false,
                                },
                            ],
                        },
                        ...currentWorkspace?.applicationWorkspce.map((item: any) => ({
                            control: 'checkbox',
                            title: t('adminPanel.application'),
                            name: item.propertyApplication.sysNameApplication,
                            type: 'checkbox',
                            options: [
                                {
                                    key: item.application.applicationName,
                                    value: item.isActive,
                                },
                            ],
                        })),
                    ],
                    shema: null,
                    type: 'editAccount',
                    data: {
                        workspaceName: currentWorkspace.workspaceName,
                        isActive: currentWorkspace.isActive,
                        telegramNickname: currentWorkspace.telegramNickname,
                        createBy: currentWorkspace.createBy,
                        ...(() => {
                            let apps = {};
                            currentWorkspace?.applicationWorkspce.map((item: any) => {
                                apps = {
                                    ...apps,
                                    [item.propertyApplication.sysNameApplication]: item.isActive,
                                };
                            });
                            return apps;
                        })(),
                        type: 'editCompany',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
            case 'deleteCompany':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('adminPanel.deleteCompany')} ${currentWorkspace.workspaceName}`,
                    props: [],
                    shema: null,
                    type: 'deleteCompany',
                    data: {
                        workspaceId: currentWorkspace.workspaceId,
                        type: 'deleteCompany',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;

            case 'deleteApp':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('adminPanel.deleteApp')} ${currentApplication.application.applicationName}`,
                    props: [],
                    shema: null,
                    type: 'deleteApp',
                    data: {
                        workspaceId: currentWorkspace.workspaceId,
                        applicationSysName: currentApplication.application.applicationSysName,
                        applicationId: currentApplication.application.applicationId,
                        applicationWorkspaceId: currentApplication.applicationWorkspaceId,
                        type: 'deleteApp',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;

            case 'addUser':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: t('adminPanel.createUser'),
                    props: [
                        {
                            title: 'Email',
                            control: 'input',
                            name: 'workspaceName',
                            type: 'text',
                            defaultValue: currentWorkspace.workspaceName,
                        },
                    ],
                    shema: null,
                    type: 'addUser',
                    data: {
                        type: 'addUser',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
        }
    };

    const closeHandler = () => {
        setDataModal({});
        setOpen(false);
    };

    const sendDataModalHandler = async (values: any) => {
        switch (values.type) {
            case 'editCompany':
                let apps: any = [];
                Object.keys(values).map((key: any) => {
                    currentWorkspace.applicationWorkspce.map((app: any) => {
                        if (app.propertyApplication.sysNameApplication === key) {
                            apps.push({
                                applicationWorkspaceId: app.applicationWorkspaceId,
                                sysName: app.propertyApplication.sysNameApplication,
                                isActive: values[key],
                            });
                        }
                    });
                });

                const resultEditCompany = await updateWorkspace({
                    createBy: values.createBy,
                    workspaceName: values.workspaceName,
                    isActive: values.isActive,
                    telegramNickname: values.telegramNickname,
                    apps: apps,
                    workspaceId: currentWorkspace.workspaceId,
                    email: userInfo.email,
                });
                if (resultEditCompany.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'deleteCompany':
                const resultDeleteCompany = await deleteWorkspace(values.workspaceId, userInfo.email);
                if (resultDeleteCompany.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
            case 'deleteApp':
                const resultDeleteApp = await deleteApplications({
                    workspaceId: values.workspaceId,
                    applicationSysName: values.applicationSysName,
                    applicationId: values.applicationId,
                    applicationWorkspaceId: values.applicationWorkspaceId,
                    email: userInfo.email,
                });
                if (resultDeleteApp.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    return (
        <div className={stls.container}>
            {loading ? (
                <div className={stls.loading}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {qwepAppInfo && (
                        <div className={stls.appQwep}>
                            <div className={stls.info}>
                                <div className={stls.header}>
                                    <IconInfoQwep />
                                    <p className={stls.title}>{t('adminPanel.infoFromAppQwep')} </p>
                                </div>
                                <p className={stls.text}>
                                    <p className={stls.span} style={{ color: colors.omicronTitle }}>
                                        {t('adminPanel.client')}:
                                    </p>{' '}
                                    {qwepAppInfo.clientName.replace('companyName: ', '')}
                                </p>
                                <p className={stls.text}>
                                    <p className={stls.span} style={{ color: colors.alpha }}>
                                        {t('adminPanel.periodEnd')}:
                                    </p>{' '}
                                    {qwepAppInfo.paidTill}
                                </p>
                                <p className={stls.text}>
                                    <p className={stls.span} style={{ color: colors.omicronTitle }}>
                                        {t('adminPanel.billingPeriod')}:
                                    </p>{' '}
                                    {qwepAppInfo.periodEnd}
                                </p>
                                <p className={stls.text}>
                                    <p className={stls.span} style={{ color: colors.omicronTitle }}>
                                        {t('adminPanel.packadeName')}:
                                    </p>{' '}
                                    {qwepAppInfo.packageName}
                                </p>
                                <p className={stls.text}>
                                    <p className={stls.span} style={{ color: colors.omicronTitle }}>
                                        {t('adminPanel.limit')}:
                                    </p>{' '}
                                    {qwepAppInfo.requestLimit}
                                </p>
                                <p className={stls.text}>
                                    <p className={stls.span} style={{ color: colors.omicronTitle }}>
                                        {t('adminPanel.number')}:
                                    </p>{' '}
                                    {qwepAppInfo.applicationNum}
                                </p>
                            </div>
                            <div className={stls.itemMenu} onClick={() => setOpenEditQwep(true)}>
                                <div className={stls.header}>
                                    <IconEditQwep />
                                    <p className={stls.title}>{t('adminPanel.editAppQwep')}</p>
                                </div>
                                <p style={{ color: colors.omicronTitle }} className={stls.text}>
                                    {t('adminPanel.editingOrExtanding')}
                                </p>
                            </div>
                        </div>
                    )}
                    {crossdataAppInfo && (
                        <div className={stls.appQwep}>
                            <div className={stls.info}>
                                <div className={stls.header}>
                                    <IconInfoQwep />
                                    <p className={stls.title}>Информация из проложения DATA</p>
                                </div>
                                <p className={stls.text}>
                                    <span style={{ color: colors.omicronTitle }}>{t('adminPanel.client')}:</span>{' '}
                                    {crossdataAppInfo.name.replace('&workspaceId=', ' / companyId: ')}
                                </p>
                                <p className={stls.text}>
                                    <span style={{ color: colors.alpha }}>{t('adminPanel.periodEnd')}:</span>{' '}
                                    {crossdataAppInfo.expire}
                                </p>
                                <p className={stls.text} style={{ color: colors.gamma }}>
                                    Лимиты:
                                </p>
                                {crossdataAppInfo.limits.map((item: any) => {
                                    let interval;
                                    switch (item.interval) {
                                        case Limits.SECOND:
                                            interval = 'Секунда';
                                            break;
                                        case Limits.MINUTE:
                                            interval = 'Минута';
                                            break;
                                        case Limits.HOUR:
                                            interval = 'Час';
                                            break;
                                        case Limits.DAY:
                                            interval = 'День';
                                            break;
                                        case Limits.MONTH:
                                            interval = 'Месяц';
                                            break;
                                        case Limits.YEAR:
                                            interval = 'Год';
                                            break;
                                    }
                                    return (
                                        <p className={stls.text}>
                                            <span style={{ color: colors.omicronTitle }}>{interval}:</span> {item.limit}
                                        </p>
                                    );
                                })}
                            </div>
                            <div className={stls.itemMenu} onClick={() => setOpenEditCrossdata(true)}>
                                <div className={stls.header}>
                                    <IconEditQwep />
                                    <p className={stls.title}>{'Редактировать приложение DATA'}</p>
                                </div>
                                <p className={stls.text}>{t('adminPanel.editingOrExtanding')}</p>
                            </div>
                        </div>
                    )}
                    {UCSAppInfo && (
                        <div className={stls.appQwep}>
                            <div className={stls.info}>
                                <div className={stls.header}>
                                    <IconInfoQwep />
                                    <p className={stls.title}>{t('adminPanel.infoFromAppUCS')} </p>
                                </div>
                                <p className={stls.text}>
                                    <span style={{ color: colors.omicronTitle }}>{t('adminPanel.authCode')}:</span>{' '}
                                    {UCSAppInfo.authorizationCode}
                                </p>

                                <p className={stls.text}>
                                    <span style={{ color: colors.alpha }}>{t('adminPanel.periodEnd')}:</span>{' '}
                                    {UCSAppInfo.paidTime}
                                </p>
                                {UCSAppInfo.keys1c?.length ? (
                                    <div>
                                        <p className={stls.text}>
                                            <span style={{ color: colors.alpha }}>{'Ключи 1C'}</span>
                                        </p>
                                        {UCSAppInfo.keys1c.map((item: { key: string; alias: string }) => {
                                            return (
                                                <p className={stls.text} key={item.key}>
                                                    <span style={{ color: colors.omicronTitle }}>{item.alias}:</span>{' '}
                                                    {item.key}
                                                </p>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {UCSAppInfo.keysWeb?.length ? (
                                    <div>
                                        <p className={stls.text}>
                                            <span style={{ color: colors.alpha }}>{'Ключи WEB'}</span>
                                        </p>
                                        {UCSAppInfo.keysWeb.map((item: { key: string; alias: string }) => {
                                            return (
                                                <p className={stls.text} key={item.key}>
                                                    <span style={{ color: colors.omicronTitle }}>{item.alias}:</span>{' '}
                                                    {item.key}
                                                </p>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className={stls.itemMenu} onClick={() => setOpenEditUCS(true)}>
                                <div className={stls.header}>
                                    <IconEditQwep />
                                    <p className={stls.title}>{t('adminPanel.editAppUCS')}</p>
                                </div>
                                <p style={{ color: colors.omicronTitle }} className={stls.text}>
                                    {t('adminPanel.editingOrExtanding')}
                                </p>
                            </div>
                        </div>
                    )}

                    <div className={stls.itemMenu} onClick={() => clickModalHandler('editCompany')}>
                        <div className={stls.header}>
                            <IconEditCompany />
                            <p className={stls.title}>{t('adminPanel.editCompany')}</p>
                        </div>
                        <p className={stls.text} style={{ color: colors.omicronTitle }}>
                            {t('adminPanel.editingCompanySetting')}
                        </p>
                    </div>
                    <div className={stls.itemMenu} onClick={() => setOpenAddUser(true)}>
                        <div className={stls.header}>
                            <IconAddUser />
                            <p className={stls.title}>{t('adminPanel.addUser')}</p>
                        </div>
                        <p className={stls.text} style={{ color: colors.omicronTitle }}>
                            {t('adminPanel.editingCompanySetting')}
                        </p>
                    </div>
                    <div className={stls.itemMenu} onClick={() => clickModalHandler('deleteCompany')}>
                        <div className={stls.header}>
                            <IconDeleteCompany />
                            <p className={stls.title}>{t('adminPanel.deleteCompany')}</p>
                        </div>
                        <p className={stls.text} style={{ color: colors.omicronTitle }}>
                            {t('adminPanel.editingCompanySetting')}
                        </p>
                    </div>
                    {currentApplication && (
                        <div className={stls.itemMenu} onClick={() => clickModalHandler('deleteApp')}>
                            <div className={stls.header}>
                                <IconDeleteApp />
                                <p className={stls.title}>{t('adminPanel.deleteApp')}</p>
                            </div>
                            <p className={stls.text} style={{ color: colors.omicronTitle }}>
                                {t('adminPanel.editingCompanySetting')}
                            </p>
                        </div>
                    )}
                </>
            )}
            <GeneraCreateUserModal
                open={openAddUser}
                closeModal={() => setOpenAddUser(false)}
                type={'admin'}
                applications={currentWorkspace?.applicationWorkspce}
                addExistingUser={addExistingUser}
                workspaceId={currentWorkspace?.workspaceId}
                addNewUser={addNewUser}
                workspaceName={currentWorkspace?.workspaceName}
            />
            <GeneralModal
                openModal={open}
                closeModal={closeHandler}
                sendDataModalHandler={sendDataModalHandler}
                dataModal={dataModal}
            />
            <EditApplicationQwepModal
                open={openEditQwep}
                handleClose={() => setOpenEditQwep(false)}
                qwepAppInfo={qwepAppInfo}
                currentApplication={currentApplication}
                userInfo={userInfo}
                currentWorkspace={currentWorkspace}
            />
            <EditApplicationUCSModal
                open={openEditUCS}
                handleClose={() => setOpenEditUCS(false)}
                UCSAppInfo={UCSAppInfo}
                currentApplication={currentApplication}
                currentWorkspace={currentWorkspace}
            />
            <EditApplicationCrossdataModal
                open={openEditCrossdata}
                handleClose={() => setOpenEditCrossdata(false)}
                crossdataAppInfo={crossdataAppInfo}
                currentApplication={currentApplication}
                userInfo={userInfo}
                currentWorkspace={currentWorkspace}
            />
        </div>
    );
}
