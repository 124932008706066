import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconChatBotProps = TypeClassNames;

export const IconChatBot = ({ classNames }: TypeIconChatBotProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="60" height="146" viewBox="0 0 60 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M59.2692 84.8089C59.2692 98.5808 45.8693 118.765 29.5578 118.765C13.2464 118.765 0.730713 98.5808 0.730713 84.8089C0.730713 71.0369 14.6612 68.8921 30.9726 68.8921C47.2841 68.8921 59.2692 71.0369 59.2692 84.8089Z"
                    fill="#5FCB19"
                />
                <rect x="6.21301" y="22.5566" width="47.3967" height="41.3837" rx="17.6853" fill="#63D116" />
                <rect x="29.0267" y="17.6045" width="1.76853" height="5.12875" fill="#63D116" />
                <circle cx="29.912" cy="13.8908" r="3.89077" fill="#63D116" />
                <rect x="11.6956" y="32.814" width="36.6087" height="12.3797" rx="3.53707" fill="#1A242A" />
                <path
                    d="M32.3876 32.814H44.7674C46.7208 32.814 48.3044 34.3976 48.3044 36.351V41.6566C48.3044 43.6101 46.7208 45.1937 44.7674 45.1937H27.7894L32.3876 32.814Z"
                    fill="#151515"
                />
                <g filter="url(#filter0_d_5819_14717)">
                    <ellipse cx="21.0688" cy="38.8998" rx="4.42133" ry="5.20157" fill="#D9D9D9" />
                </g>
                <g filter="url(#filter1_d_5819_14717)">
                    <ellipse cx="39.1077" cy="39.0038" rx="4.42133" ry="5.3056" fill="#D9D9D9" />
                </g>
                <ellipse
                    cx="20.2538"
                    cy="40.6842"
                    rx="1.3264"
                    ry="1.59168"
                    transform="rotate(4 20.2538 40.6842)"
                    fill="#1A242A"
                />
                <ellipse
                    cx="38.2062"
                    cy="40.6842"
                    rx="1.3264"
                    ry="1.59168"
                    transform="rotate(4 38.2062 40.6842)"
                    fill="#1A242A"
                />
                <rect x="52.9027" y="35.8208" width="4.59819" height="10.6112" rx="2.29909" fill="#0069A6" />
                <rect x="2.14587" y="35.8208" width="4.59819" height="10.6112" rx="2.29909" fill="#0069A6" />
                <path
                    d="M28.946 60.7523C25.7732 61.1359 22.5447 60.9093 19.4789 60.0878C16.413 59.2664 13.5811 57.8693 11.1746 55.9908C8.76808 54.1124 6.84301 51.7964 5.52949 49.1994C4.21596 46.6024 3.54457 43.7849 3.56071 40.9373C3.57684 38.0898 4.28012 35.2784 5.62301 32.6934C6.9659 30.1083 8.91712 27.8097 11.3448 25.9529C13.7725 24.0961 16.62 22.7244 19.6951 21.9305C22.7701 21.1366 26.0009 20.9389 29.1692 21.3509L29.0897 21.8344C25.9992 21.4326 22.8478 21.6253 19.8482 22.3998C16.8487 23.1742 14.0711 24.5122 11.7031 26.3234C9.335 28.1345 7.43171 30.3767 6.12179 32.8983C4.81188 35.4199 4.12588 38.1622 4.11014 40.9398C4.0944 43.7175 4.7493 46.4657 6.03057 48.999C7.31183 51.5322 9.18962 53.7913 11.537 55.6236C13.8844 57.4559 16.6468 58.8187 19.6374 59.62C22.6279 60.4212 25.7771 60.6423 28.872 60.2681L28.946 60.7523Z"
                    fill="#151515"
                />
                <rect
                    x="28.3198"
                    y="62.8789"
                    width="4.95189"
                    height="6.72043"
                    rx="2.47595"
                    transform="rotate(-90 28.3198 62.8789)"
                    fill="#282828"
                />
                <path
                    d="M30.1874 87.6608C30.3698 86.8761 30.3447 87.5477 30.5668 84.799C29.7243 83.6833 26.8766 81.4329 26.3776 81.8821C26.0007 82.4552 26.7329 83.2144 27.3132 84.0651C28.6415 86.0126 29.45 87.6255 30.1874 87.6608Z"
                    fill="#1A242A"
                />
                <path d="M30.3692 92.4368L24.3155 92.4379L30.3434 86.2972L30.3692 92.4368Z" fill="#1A242A" />
                <path
                    d="M25.2129 92.4593C24.7101 92.7559 24.3167 92.5357 22.4623 92.4293C21.4714 91.5519 19.5195 88.7888 19.9606 88.3581C20.5125 88.0499 21.1879 88.7907 21.9623 89.3877C23.6741 90.7075 25.1991 91.7725 25.2129 92.4593Z"
                    fill="#1A242A"
                />
                <path
                    d="M23.136 90.2944L28.1959 85.0769L29.7226 86.94L24.9076 91.8377L23.136 90.2944Z"
                    fill="#1A242A"
                />
                <path
                    d="M21.0173 88.6753L26.6778 82.9407L28.2052 85.0985L23.1329 90.2915L21.0173 88.6753Z"
                    fill="#1A242A"
                />
                <path
                    d="M19.9674 88.353L26.3882 81.8683L26.3903 82.238L26.441 82.4721L26.5534 82.7433L26.6778 82.9406L21.0415 88.6871L20.7944 88.5759L20.3375 88.4151L19.9674 88.353Z"
                    fill="#1A242A"
                />
                <path
                    d="M30.5973 97.097C30.4043 97.8791 30.5975 97.1873 30.1919 99.9569C31.0343 101.067 33.8361 103.373 34.3412 102.93C34.7258 102.362 34.0039 101.593 33.4353 100.735C32.1334 98.7695 31.3342 97.1422 30.5973 97.097Z"
                    fill="#1A242A"
                />
                <path d="M30.37 92.4362L36.5096 92.4227L30.4815 98.4774L30.37 92.4362Z" fill="#1A242A" />
                <path
                    d="M35.6244 92.4243C36.3673 92.3225 35.7593 92.3749 38.3134 92.357C39.2922 93.2477 41.2923 96.1169 40.8453 96.5416C40.2893 96.8423 39.6355 96.1604 38.8729 95.5484C37.127 94.1472 35.6167 93.1121 35.6244 92.4243Z"
                    fill="#1A242A"
                />
                <rect
                    x="30.995"
                    y="97.877"
                    width="0.88415"
                    height="0.88415"
                    transform="rotate(134.212 30.995 97.877)"
                    fill="#1A242A"
                />
                <path
                    d="M37.7357 94.6279L32.5937 99.7394L31.0807 97.8622L35.8959 93.0259L37.7357 94.6279Z"
                    fill="#1A242A"
                />
                <path
                    d="M39.7931 96.2104L34.0675 101.88L32.5574 99.6895L37.6775 94.574L39.7931 96.2104Z"
                    fill="#1A242A"
                />
                <path
                    d="M40.845 96.5415L34.3375 102.939L34.3417 102.571L34.2952 102.337L34.1878 102.066L34.0672 101.868L39.7704 96.1971L40.0248 96.3111L40.4773 96.4759L40.845 96.5415Z"
                    fill="#1A242A"
                />
                <path
                    d="M25.6817 92.4717C24.8686 92.4113 24.8934 92.534 22.4619 92.4165C21.3628 93.2731 19.4556 95.9382 19.9043 96.4375C20.4771 96.8149 21.2988 96.08 22.1498 95.5004C24.0984 94.1736 25.6459 93.2091 25.6817 92.4717Z"
                    fill="#1A242A"
                />
                <path
                    d="M24.9924 93.0256L29.7795 97.7426L29.4521 98.0432L24.9312 93.0994L24.9924 93.0256Z"
                    fill="#1A242A"
                />
                <path d="M30.3819 92.4342L30.4694 98.5149L24.3537 92.4496L30.3819 92.4342Z" fill="#1A242A" />
                <path
                    d="M30.3564 97.5806C30.4677 98.3221 30.4918 97.7276 30.495 100.321C29.6168 101.311 26.8162 103.309 26.3858 102.868C26.078 102.316 26.7774 101.587 27.3796 100.817C28.7584 99.0529 29.755 97.717 30.3564 97.5806Z"
                    fill="#1A242A"
                />
                <path
                    d="M28.2609 99.7222L23.0841 94.6459L24.9668 93.0539L29.8544 97.877L28.2609 99.7222Z"
                    fill="#1A242A"
                />
                <path
                    d="M26.7041 101.812L20.9617 96.1589L23.1329 94.6209L28.3134 99.6752L26.7041 101.812Z"
                    fill="#1A242A"
                />
                <path
                    d="M26.3862 102.868L19.9059 96.4425L20.2743 96.442L20.5076 96.3925L20.7775 96.2816L20.9738 96.1585L26.7191 101.8L26.6061 102.045L26.4471 102.499L26.3862 102.868Z"
                    fill="#1A242A"
                />
                <rect
                    x="31.092"
                    y="97.8657"
                    width="0.884149"
                    height="0.884149"
                    transform="rotate(134.212 31.092 97.8657)"
                    fill="#1A242A"
                />
                <path
                    d="M35.2925 92.3642C36.2216 92.3622 35.6375 92.454 38.3132 92.3576C39.4116 91.5002 41.3619 88.8315 40.9445 88.3733C40.4022 88.0359 39.6516 88.7111 38.8041 89.2954C36.8637 90.6329 35.3542 91.6608 35.2925 92.3642Z"
                    fill="#1A242A"
                />
                <path d="M30.3695 92.4362L30.3436 86.2597L36.5091 92.4227L30.3695 92.4362Z" fill="#1A242A" />
                <path
                    d="M30.4393 87.1735C30.3443 86.4326 30.3201 87.0721 30.3155 84.4925C31.1929 83.5017 33.9853 81.4876 34.4161 81.9287C34.7243 82.4806 33.9599 83.1384 33.3864 83.9304C32.3068 85.4214 31.193 87.0815 30.4393 87.1735Z"
                    fill="#1A242A"
                />
                <path
                    d="M32.5433 85.064L37.7241 90.1362L35.8809 91.7987L30.9707 86.91L32.5433 85.064Z"
                    fill="#1A242A"
                />
                <path
                    d="M34.0931 82.9858L39.845 88.6333L37.6749 90.173L32.4905 85.1227L34.0931 82.9858Z"
                    fill="#1A242A"
                />
                <path
                    d="M34.415 81.9287L40.9004 88.3488L40.532 88.3496L40.2988 88.3993L40.0289 88.5104L39.8327 88.6336L34.0809 82.9985L34.1958 82.7519L34.3545 82.2972L34.415 81.9287Z"
                    fill="#1A242A"
                />
                <rect
                    x="36.5303"
                    y="92.4023"
                    width="0.88415"
                    height="0.88415"
                    transform="rotate(134.212 36.5303 92.4023)"
                    fill="#1A242A"
                />
                <path
                    d="M24.3015 92.4766L24.9094 91.8345L25.5514 92.4424L24.9435 93.0845L24.3015 92.4766Z"
                    fill="#1A242A"
                />
                <rect
                    x="30.9691"
                    y="86.9136"
                    width="0.88415"
                    height="0.88415"
                    transform="rotate(134.212 30.9691 86.9136)"
                    fill="#1A242A"
                />
                <path
                    d="M30.1136 87.7287C30.2902 86.9687 30.2659 87.6192 30.481 84.9571C29.6651 83.8765 26.9071 81.697 26.4238 82.132C26.0588 82.6871 26.768 83.4225 27.3299 84.2463C28.6165 86.1324 29.3994 87.6946 30.1136 87.7287Z"
                    fill="#0069A6"
                />
                <path d="M30.2901 92.3545L24.4271 92.3555L30.2651 86.4083L30.2901 92.3545Z" fill="#00BAF2" />
                <path
                    d="M25.2952 92.3768C24.8082 92.6641 24.4272 92.4509 22.6312 92.3478C21.6716 91.4981 19.7812 88.822 20.2084 88.4048C20.7429 88.1063 21.397 88.8238 22.147 89.402C23.8049 90.6803 25.2818 91.7117 25.2952 92.3768Z"
                    fill="#0069A6"
                />
                <path
                    d="M23.2849 90.2793L28.1854 85.2261L29.6641 87.0305L25.0007 91.774L23.2849 90.2793Z"
                    fill="#00AEEF"
                />
                <path
                    d="M21.2325 88.7119L26.7147 83.1579L28.1939 85.2478L23.2815 90.2772L21.2325 88.7119Z"
                    fill="#0095DA"
                />
                <path
                    d="M20.2153 88.3994L26.4339 82.119L26.4359 82.477L26.4849 82.7038L26.5939 82.9665L26.7143 83.1575L21.2556 88.723L21.0163 88.6153L20.5738 88.4595L20.2153 88.3994Z"
                    fill="#00AEEF"
                />
                <path
                    d="M30.5102 96.8685C30.3233 97.626 30.5104 96.956 30.1176 99.6384C30.9334 100.713 33.647 102.947 34.1361 102.518C34.5086 101.968 33.8095 101.223 33.2588 100.392C31.9979 98.4884 31.2238 96.9123 30.5102 96.8685Z"
                    fill="#0069A6"
                />
                <path d="M30.29 92.3543L36.2362 92.3413L30.398 98.2052L30.29 92.3543Z" fill="#00BAF2" />
                <path
                    d="M35.38 92.3431C36.0995 92.2445 35.5107 92.2953 37.9844 92.2779C38.9323 93.1406 40.8694 95.9194 40.4365 96.3308C39.898 96.622 39.2648 95.9616 38.5263 95.3688C36.8353 94.0117 35.3726 93.0093 35.38 92.3431Z"
                    fill="#0069A6"
                />
                <rect
                    x="30.8948"
                    y="97.6235"
                    width="0.856259"
                    height="0.856259"
                    transform="rotate(134.212 30.8948 97.6235)"
                    fill="#F5F5F5"
                />
                <path
                    d="M37.4243 94.4766L32.4443 99.4271L30.979 97.609L35.6424 92.925L37.4243 94.4766Z"
                    fill="#00AEEF"
                />
                <path
                    d="M39.4167 96.0098L33.8716 101.501L32.409 99.3792L37.3678 94.4249L39.4167 96.0098Z"
                    fill="#0095DA"
                />
                <path
                    d="M40.4359 96.3306L34.1335 102.527L34.1375 102.17L34.0925 101.943L33.9885 101.681L33.8717 101.489L39.3952 95.997L39.6416 96.1075L40.0798 96.2671L40.4359 96.3306Z"
                    fill="#00AEEF"
                />
                <path
                    d="M25.7505 92.389C24.963 92.3304 24.9871 92.4493 22.6321 92.3355C21.5676 93.1651 19.7205 95.7462 20.1551 96.2298C20.7099 96.5953 21.5056 95.8835 22.3299 95.3223C24.2171 94.0372 25.7159 93.1032 25.7505 92.389Z"
                    fill="#0F9647"
                />
                <path
                    d="M25.0827 92.925L29.7189 97.4934L29.4018 97.7845L25.0234 92.9964L25.0827 92.925Z"
                    fill="#DFE9A6"
                />
                <path d="M30.302 92.3518L30.3868 98.2409L24.4637 92.3667L30.302 92.3518Z" fill="#9EDD00" />
                <path
                    d="M30.2778 97.3371C30.3856 98.0553 30.409 97.4795 30.412 99.991C29.5615 100.95 26.8492 102.885 26.4324 102.458C26.1343 101.923 26.8116 101.217 27.3948 100.471C28.7302 98.763 29.6954 97.4692 30.2778 97.3371Z"
                    fill="#0B9444"
                />
                <path
                    d="M28.2473 99.4106L23.2336 94.4943L25.0571 92.9524L29.7906 97.6236L28.2473 99.4106Z"
                    fill="#8CC63F"
                />
                <path
                    d="M26.7394 101.435L21.1778 95.96L23.2807 94.4705L28.298 99.3655L26.7394 101.435Z"
                    fill="#39B54A"
                />
                <path
                    d="M26.4326 102.458L20.1565 96.2347L20.5132 96.2342L20.7391 96.1863L21.0006 96.0789L21.1907 95.9597L26.755 101.424L26.6456 101.66L26.4916 102.101L26.4326 102.458Z"
                    fill="#9EDD00"
                />
                <rect
                    x="30.9893"
                    y="97.6128"
                    width="0.856259"
                    height="0.856259"
                    transform="rotate(134.212 30.9893 97.6128)"
                    fill="#F5F5F5"
                />
                <path
                    d="M35.0585 92.2848C35.9583 92.2828 35.3926 92.3718 37.984 92.2784C39.0478 91.448 40.9366 88.8634 40.5324 88.4196C40.0072 88.0928 39.2802 88.7468 38.4595 89.3126C36.5802 90.6081 35.1183 91.6036 35.0585 92.2848Z"
                    fill="#0B9444"
                />
                <path d="M30.2897 92.3543L30.2646 86.3725L36.2359 92.3413L30.2897 92.3543Z" fill="#9EDD01" />
                <path
                    d="M30.3576 87.2572C30.2656 86.5396 30.2422 87.159 30.2377 84.6607C31.0875 83.7011 33.7919 81.7504 34.2091 82.1776C34.5076 82.7121 33.7673 83.3492 33.2119 84.1162C32.1663 85.5602 31.0876 87.1681 30.3576 87.2572Z"
                    fill="#0B9444"
                />
                <path
                    d="M32.3959 85.2139L37.4134 90.1263L35.6283 91.7364L30.8728 87.0018L32.3959 85.2139Z"
                    fill="#8CC63F"
                />
                <path
                    d="M33.8969 83.2017L39.4675 88.6712L37.3658 90.1624L32.3447 85.2712L33.8969 83.2017Z"
                    fill="#39B54A"
                />
                <path
                    d="M34.2084 82.1777L40.4894 88.3956L40.1326 88.3964L39.9068 88.4445L39.6454 88.5521L39.4554 88.6714L33.8848 83.2138L33.9961 82.975L34.1497 82.5346L34.2084 82.1777Z"
                    fill="#9EDD01"
                />
                <rect
                    x="36.2574"
                    y="92.3218"
                    width="0.856259"
                    height="0.856259"
                    transform="rotate(134.212 36.2574 92.3218)"
                    fill="#F5F5F5"
                />
                <path
                    d="M24.4135 92.3936L25.0022 91.7718L25.6239 92.3605L25.0352 92.9823L24.4135 92.3936Z"
                    fill="#F5F5F5"
                />
                <rect
                    x="30.8705"
                    y="87.0059"
                    width="0.856259"
                    height="0.856259"
                    transform="rotate(134.212 30.8705 87.0059)"
                    fill="#F5F5F5"
                />
                <path
                    d="M27.3916 54.538C27.9364 54.991 28.5956 55.2708 29.3142 55.3541C30.0328 55.4374 30.79 55.3217 31.5226 55.0166C32.2553 54.7116 32.9422 54.2262 33.5262 53.6007C34.1101 52.9752 34.5741 52.2278 34.8796 51.4208L33.7842 51.0989C33.557 51.6993 33.2118 52.2554 32.7773 52.7208C32.3428 53.1861 31.8317 53.5474 31.2866 53.7743C30.7415 54.0012 30.1781 54.0873 29.6434 54.0254C29.1088 53.9634 28.6183 53.7552 28.2129 53.4182L27.3916 54.538Z"
                    fill="#252626"
                />
                <g filter="url(#filter2_f_5819_14717)">
                    <ellipse cx="29.9999" cy="132.704" rx="20.5994" ry="3.2959" fill="#6D98BB" fill-opacity="0.4" />
                </g>
                <defs>
                    <filter
                        id="filter0_d_5819_14717"
                        x="13.1104"
                        y="30.1612"
                        width="15.9168"
                        height="17.4775"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="1.76853" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.516563 0 0 0 0 0.659212 0 0 0 0 0.7125 0 0 0 0.79 0"
                        />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5819_14717" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5819_14717" result="shape" />
                    </filter>
                    <filter
                        id="filter1_d_5819_14717"
                        x="31.1493"
                        y="30.1612"
                        width="15.9168"
                        height="17.6855"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="1.76853" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.516563 0 0 0 0 0.659212 0 0 0 0 0.7125 0 0 0 0.79 0"
                        />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5819_14717" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5819_14717" result="shape" />
                    </filter>
                    <filter
                        id="filter2_f_5819_14717"
                        x="1.16075"
                        y="121.168"
                        width="57.6784"
                        height="23.0713"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="4.11988" result="effect1_foregroundBlur_5819_14717" />
                    </filter>
                </defs>
            </svg>
        </div>
    );
};
