import { Avatar, Button, Card, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_styles/components/my-company/MyCompany.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useState } from 'react';
import { IconCompany } from '@f_components/icons/IconCompany';
import { UserRoleChip } from '@f_general/UserRoleChip';
import { Role } from '@f_types/TypeRole';
import { WorkspaceContext } from '@f_context/Workspace_context';
import { UserContext } from '@f_context/User_context';
import GeneraCreateUserModal from '@f_general/GeneraCreateUserModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export function ChildCompanyList({ open, getUsersHandler }: any) {
    const { t } = useTranslation();
    const { colors, StyledAvatarGroup, StyledBadge } = useContext(ThemeContext);

    const { childWorkspaces } = useContext(WorkspaceContext);
    const { addUser, addExistingUser } = useContext(UserContext);

    const [openAddUser, setOpenAddUser] = useState(false);
    const [workspaceInfo, setWorkspaceInfo] = useState<any>({});

    const addUserHandler = (workspace: any) => {
        setWorkspaceInfo(workspace);
        setOpenAddUser(true);
    };

    return (
        <>
            {open && childWorkspaces.length ? (
                <div className={stls.cildContent}>
                    <span className={stls.docor} style={{ background: colors.chi }} />
                    <Card className={stls.childCards} sx={{ background: colors.chi }}>
                        {childWorkspaces.map((item: any) => (
                            <Card className={stls.smallCard}>
                                <div className={stls.smallCardCardContent}>
                                    <button
                                        className={stls.cardContentDivItem}
                                        onClick={() => getUsersHandler(item.workspaceId)}>
                                        <div className={stls.cildTitleBox}>
                                            <IconCompany
                                                color="#8CDCE1"
                                                role={item.mainUserRole}
                                                classNames={[stls.iconCompany]}
                                            />
                                            <Typography variant="h6" className={stls.cildTitle}>
                                                {item.workspaceName}
                                            </Typography>
                                        </div>
                                        <UserRoleChip
                                            color="#8CDCE1"
                                            classNames={[stls.roleBox]}
                                            sysName={item.mainUserRole}
                                        />
                                    </button>

                                    <div className={stls.users}>
                                        <p className={stls.countUsers} style={{ color: colors.omicron }}>
                                            {item.users.length} {t('myCompany.users')}
                                        </p>
                                        <div className={stls.apps}>
                                            {item.applications.map((item: any) => {
                                                return (
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                <p className={stls.tariff}>
                                                                    {t('myCompany.tariff')}: {item.tariff}
                                                                </p>
                                                                <p>
                                                                    {t('myCompany.currentUsers')}: {item.currentLimit}
                                                                </p>
                                                                <p>
                                                                    {t('myCompany.maxUsers')}: {item.maxLimit}
                                                                </p>
                                                            </div>
                                                        }>
                                                        <div
                                                            key={item.applicationWorkspaceId}
                                                            className={stls.itemApp}
                                                            style={{ color: colors.alpha }}>
                                                            {item.applicationName}
                                                        </div>
                                                    </Tooltip>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className={stls.divider} style={{ background: colors.chi }} />
                                </div>
                                <div className={stls.companyActionsItemSmall}>
                                    <StyledAvatarGroup total={item.users.length} id="basic-button">
                                        {item.users.map((usr: any) => {
                                            if (usr.online) {
                                                return (
                                                    <Tooltip
                                                        key={usr.userId}
                                                        title={`${usr.firstName} ${usr.lastName}`}>
                                                        <StyledBadge
                                                            overlap="circular"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            variant="dot">
                                                            <Avatar
                                                                className={stls.avatar}
                                                                alt={usr.firstName}
                                                                style={{ width: '23px', height: '23px' }}
                                                                src={
                                                                    usr.photo
                                                                        ? usr.photo
                                                                        : '/static/images/avatar/1.jpg'
                                                                }
                                                            />
                                                        </StyledBadge>
                                                    </Tooltip>
                                                );
                                            } else {
                                                return (
                                                    <Tooltip
                                                        key={usr.userId}
                                                        title={`${usr.firstName} ${usr.lastName}`}>
                                                        <Avatar
                                                            className={stls.avatar}
                                                            alt={usr.firstName}
                                                            style={{ width: '23px', height: '23px' }}
                                                            src={usr.photo ? usr.photo : '/static/images/avatar/1.jpg'}
                                                        />
                                                    </Tooltip>
                                                );
                                            }
                                        })}
                                    </StyledAvatarGroup>
                                    {item.mainUserRole.some(
                                        (item: any) =>
                                            item === Role.Admin || item === Role.Manager || item === Role.SUPER_ADMIN,
                                    ) && (
                                        <div
                                            className={stls.buttonAdd}
                                            onClick={() => addUserHandler(item)}
                                            style={{ border: `1px solid ${colors.alpha}` }}>
                                            <AddCircleIcon
                                                style={{ width: '14px', height: '14px', color: colors.alpha }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        ))}
                    </Card>
                    <GeneraCreateUserModal
                        open={openAddUser}
                        closeModal={() => setOpenAddUser(false)}
                        type={'user'}
                        applications={workspaceInfo.applications}
                        workspaceId={workspaceInfo.workspaceId}
                        addExistingUser={addExistingUser}
                        addNewUser={addUser}
                        role={workspaceInfo.mainUserRole}
                        workspaceName={workspaceInfo.workspaceName}
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
