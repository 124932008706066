import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconCloseEyeProps = TypeClassNames;

export const IconCloseEye = ({ classNames }: TypeIconCloseEyeProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0322 11.8536C9.89967 12.2775 9.46323 12.5279 9.03046 12.4283L8.73229 12.3598C8.24974 12.2488 7.96379 11.7506 8.11143 11.2779L8.41039 10.321C8.60008 9.71382 8.25967 9.08108 7.68068 8.85189C6.91961 8.55062 6.19542 8.18546 5.51877 7.76185C5.07763 7.48567 4.49847 7.50358 4.09522 7.84939L2.82924 8.93502C2.51093 9.20798 2.04112 9.20804 1.72275 8.93515L1.60886 8.83753C1.21303 8.49825 1.2131 7.88586 1.60901 7.54667L2.42628 6.84648C2.93312 6.41224 2.95519 5.65318 2.54792 5.14757C1.75865 4.16771 1.1645 3.08376 0.788664 1.93998C0.642168 1.49414 0.950751 1.03033 1.43717 0.954269L1.75389 0.904748C2.19683 0.83549 2.62578 1.12767 2.77724 1.57276C4.07444 5.38481 8.17028 8.14999 13.0001 8.14999C17.8289 8.14999 21.9258 5.38462 23.223 1.57237C23.3744 1.12741 23.8031 0.835249 24.246 0.904298L24.5628 0.953702C25.0493 1.02956 25.3581 1.49331 25.2117 1.93922C24.8361 3.08332 24.2421 4.16762 23.4529 5.14781C23.0458 5.65334 23.0679 6.41211 23.5745 6.84633L24.3915 7.54662C24.7873 7.88585 24.7873 8.49812 24.3915 8.83735L24.2774 8.93515C23.959 9.20804 23.4892 9.20798 23.1709 8.93502L21.9049 7.84939C21.5017 7.50358 20.9225 7.48567 20.4814 7.76185C19.8047 8.18548 19.0805 8.55065 18.3194 8.85192C17.7404 9.08109 17.4 9.71377 17.5896 10.3209L17.8887 11.2786C18.0363 11.7513 17.7502 12.2496 17.2675 12.3604L16.9694 12.4288C16.5368 12.5281 16.1005 12.2777 15.9681 11.854L15.5798 10.6122C15.4104 10.0706 14.8755 9.74308 14.3205 9.79246C13.4426 9.87057 12.5576 9.87057 11.6797 9.79246C11.1247 9.74308 10.5897 10.0706 10.4204 10.6122L10.0322 11.8536Z"
                fill={colors.epsilon}
                stroke="white"
                strokeWidth="0.3"
            />
        </svg>

        // </div>
    );
};
