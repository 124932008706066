import { AddUsersManual } from '@f_components/manual/company/AddUsersManual';
import { DeleteUsersManual } from './company/DeleteUsersManual';
import { EditUsersRoleManual } from './company/EditUsersRoleManual';

export function Manual({ chooseManual }: any) {
    const renderManual = () => {
        switch (chooseManual) {
            case 'Добавить коллегу/пользователя в приложение':
                return <AddUsersManual />;
            case 'Удалить пользователя':
                return <DeleteUsersManual />;
            case 'Сменить Роль пользователя':
                return <EditUsersRoleManual />;
        }
    };
    return <div>{renderManual()}</div>;
}
