import React, { ChangeEvent, FC } from 'react';
import styles from '@f_qwep/styles/component/price/PriceModal.module.sass';
import { TextField, Typography } from '@mui/material';
import { IconAttach } from '@f_components/icons/IconAttach';
import { useTranslation } from 'react-i18next';

interface IPriceAddFileOrLinkProps {
    isShowPreview: boolean;
    colors: any;
    checkXLS: boolean;
    titleFile: string;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleLinkChange: (e: ChangeEvent<HTMLInputElement>) => void;
    errorSendFile: boolean;
    link: string;
}

export const AddFileOrLinkPrice: FC<IPriceAddFileOrLinkProps> = ({
    isShowPreview,
    colors,
    checkXLS,
    titleFile,
    handleFileChange,
    errorSendFile,
    handleLinkChange,
    link,
}) => {
    const { t } = useTranslation();

    if (!isShowPreview) {
        return (
            <>
                {checkXLS ? (
                    <div className={styles.file}>
                        <Typography>
                            <label className={styles.inputAddFile} htmlFor="file">
                                <IconAttach />
                                <p id="priceFileInput" style={{ color: colors.alpha }} className={styles.text}>
                                    {t('qwep.price.modal.updateOrAddPriceModal.labelFile')}
                                </p>{' '}
                                <p className={styles.fileName}>{titleFile}</p>
                                <input id="file" type="file" onChange={handleFileChange} />
                            </label>
                        </Typography>
                        {errorSendFile && (
                            <p className={styles.errorFile}>{t('qwep.price.modal.updateOrAddPriceModal.fileError')}</p>
                        )}
                    </div>
                ) : (
                    <>
                        <Typography>{t('qwep.price.modal.updateOrAddPriceModal.link')}</Typography>
                        <TextField
                            className={styles.modalInput}
                            placeholder="Вставьте ссылку"
                            type="text"
                            name="link"
                            required
                            value={link}
                            focused
                            onChange={handleLinkChange}
                            sx={{
                                input: {
                                    '&:-webkit-autofill': {
                                        '-webkit-text-fill-color': `${colors.omicron}`,
                                    },
                                },
                                '& .MuiInputBase-root': {
                                    '& fieldset': {
                                        borderColor: colors.alpha,
                                        borderRadius: '12px',
                                    },
                                    '&:hover fieldset': {
                                        border: `1px solid ${colors.xi} !important`,
                                    },
                                    '&:Mui-hover + fieldset': {
                                        border: `1px solid ${colors.xi} !important`,
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: `1px solid ${colors.xi}`,
                                    },
                                    '&.Mui-disabled fieldset': {
                                        border: `1px solid ${colors.pi}`,
                                    },
                                },
                                '& .MuiInputBase-input': {
                                    fontFamily: 'Inter-Regular',
                                    fontSize: 14,
                                    padding: '0px 15px',
                                    height: '50px',
                                    width: '100%',
                                    color: colors.omicron,
                                },
                            }}
                        />
                    </>
                )}
            </>
        );
    }

    return null;
};
