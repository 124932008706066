import { TypeClassNames } from '@f_types/index';
import stls from '@f_qwep/styles/component/tables/searchResultTable/TooltipStyle.module.sass';

type TypeIconStatusTableProps = TypeClassNames & {
    colorGreen: string;
    colorRed: string;
    probability: number;
};

export const IconStatusModal = ({ colorGreen, colorRed, probability }: TypeIconStatusTableProps) => {
    const greenDasharray = Math.round(2 * Math.PI * 85 * (probability / 100));
    const greenspace = Math.round(2 * Math.PI * 85 - greenDasharray);

    return (
        <div className={stls.circle}>
            <svg width="64" height="64" viewBox="0 0 200 200">
                <circle cx="90" cy="90" r="85" fill="none" stroke={colorRed} stroke-width="10" />
                <circle
                    cx="90"
                    cy="90"
                    r="85"
                    fill="none"
                    stroke={colorGreen}
                    stroke-width="10"
                    stroke-dasharray={`${greenDasharray} ${greenspace}`}
                />
                <text x="100" y="105" text-anchor="middle" fill={colorGreen} font-size="40">
                    {probability} %
                </text>
            </svg>
        </div>
    );
};
