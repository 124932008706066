import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconDeleteUserProps = TypeClassNames;

export const IconDeleteUser = ({ classNames }: TypeIconDeleteUserProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 13.252V21H2.58457e-07C-0.000310114 19.7789 0.278921 18.5739 0.816299 17.4774C1.35368 16.3809 2.13494 15.4219 3.10022 14.674C4.0655 13.9261 5.18918 13.4091 6.38515 13.1626C7.58113 12.9162 8.81766 12.9467 10 13.252ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM15 15.586L17.121 13.464L18.536 14.879L16.414 17L18.536 19.121L17.121 20.536L15 18.414L12.879 20.536L11.464 19.121L13.586 17L11.464 14.879L12.879 13.464L15 15.586Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
