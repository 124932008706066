import { createContext, useContext, useEffect, useReducer, useState } from 'react';

const SearchResultsContext = createContext<any>({});

function SearchResultsProvider(props: any) {
    const [openCardResult, setOpenCardResult] = useState(false);

    const [openAddBasket, setOpenAddBasket] = useState(false);
    const [cardItem, setCardItem] = useState({});
    const [activeFilterId, setActiveFilterId] = useState(0);
    const [quantityCart, setQuantityCart] = useState({});

    const [curentSearchIndex, setCurentSearchIndex] = useState(-1);

    return (
        <SearchResultsContext.Provider
            value={{
                openCardResult,
                setOpenCardResult,
                openAddBasket,
                setOpenAddBasket,
                cardItem,
                setCardItem,
                activeFilterId,
                setActiveFilterId,
                // curentSearchIndex,
                quantityCart,

                //methods
                // setCurentSearchIndex,
                setQuantityCart,
            }}
            {...props}
        />
    );
}

export { SearchResultsProvider, SearchResultsContext };
