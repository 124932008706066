import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { useGetDeals } from '@f_qwep/components/purchasingAssistant/model/hooks/useGetDeals';
import { IStandardPayload } from '@f_qwep/types/StandardPayload.interface';
import { useTranslation } from 'react-i18next';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { IReturnDataCreateCustomList } from '@f_qwep/components/purchasingAssistant/types/assistantApi.type';
import { ConfigPanelModalType } from '@f_qwep/components/purchasingAssistant/types/ConfigPanelModalTypesEnum';

interface IUseCreateCustomScanReturnValue {
    mutate: UseMutateFunction;
}

export const useCustomListActions = (): IUseCreateCustomScanReturnValue => {
    const { workspaceApplication, getUsersId } = useContext(QwepContext);
    const users: number[] = getUsersId();

    const { setAssistantCustomList } = useContext(QwepContext);

    const store = useAssistantStore();
    const { handleFetchCustomListActions, title, currentTypeModal } = store.assistantModalList;
    const { setCurrentAssistantList, currentAssistantList } = store.assistantList;
    const { t } = useTranslation();

    const { mutate: getDeals } = useGetDeals(store);

    const { mutate } = useMutation<IStandardPayload<IReturnDataCreateCustomList> | undefined>({
        mutationFn: async () => {
            return await handleFetchCustomListActions({
                t,
                users,
                workspaceApplication,
                getDeals,
                setAssistantCustomList,
                setCurrentAssistantList,
                refKey: currentAssistantList.refKey,
                title: currentTypeModal === ConfigPanelModalType.CREATE ? title : currentAssistantList.title,
            });
        },
    });

    return { mutate };
};
