import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { IBaseTemplateFilter } from '@f_qwep/components/filters/filterLeftPanel/types/filters.interface';
import { isArray } from 'lodash';
import styles from '@f_qwep/components/filters/filterLeftPanel/styles/filterLeftPanelTemplates.module.sass';

interface IFilterLeftPanelTemplateInfo {
    item: IBaseTemplateFilter;
}

export const FilterLeftPanelTemplateInfo: FC<IFilterLeftPanelTemplateInfo> = observer(({ item }) => {
    const filter = item.filters[0];

    // TODO Добавить перевод и вынести логику
    const templateListTitleObject = {
        brands: 'Производитель:',
        vendorTitle: 'Поставщики:',
        warehouses: 'Склады:',
    };

    const templateNumberTitleObject = {
        minPrice: 'Минимальная цена:',
        maxPrice: 'Максимальная цена:',
        minShipmentDays: 'Минимальный день поставки:',
        maxShipmentDays: 'Максимальный день поставки:',
        minQuantity: 'Минимальное количество:',
        maxQuantity: 'Максимальное количество:',
    };

    const templateBooleanValueObject = {
        inStock: 'В наличиии',
        searchArticle: 'Артикул поиска',
        approvedCross: 'Подтвержденные кроссы',
        isDealer: 'Дилер',
    };

    const filterElements = Object.entries(filter).map(([key, value]) => {
        if (typeof value === 'number' && !value) {
            return null;
        }

        if (isArray(value) && value.length && key in templateListTitleObject) {
            const templateKey = key as keyof typeof templateListTitleObject;
            return (
                <>
                    <h3>{templateListTitleObject[templateKey]}</h3>
                    <ul>
                        {value.map((item: string, index: number) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <br />
                </>
            );
        }

        if (key in templateBooleanValueObject) {
            const templateKey = key as keyof typeof templateBooleanValueObject;
            return (
                <>
                    <h3>{templateBooleanValueObject[templateKey]}</h3>
                    <p>{value ? 'Да' : 'Нет'}</p>
                    <br />
                </>
            );
        }

        if (key in templateNumberTitleObject) {
            const templateKey = key as keyof typeof templateNumberTitleObject;
            return (
                <>
                    <h3>{templateNumberTitleObject[templateKey]}</h3>
                    <p>{value}</p>
                    <br />
                </>
            );
        }

        return null;
    });

    return (
        <Tooltip
            sx={{
                zIndex: 2000,
            }}
            PopperProps={{
                sx: {
                    zIndex: 2000,
                },
            }}
            title={<div className={styles.tooltip}>{filterElements}</div>}>
            <InfoIcon />
        </Tooltip>
    );
});
