import { Autocomplete, ListItemText, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/userSearch/UserSearch.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { useLocation } from 'react-router-dom';
import { AdminContext } from '@f_admin/context/Admin_context';
import { BotPanel } from './BotPanel';
import useAdminNavigate from '@f_admin/hooks/useAdminNavigate';

export function UserSearch() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const location = useLocation();
    const navigate = useAdminNavigate();
    const { getUserInfo } = useContext(UserAccountContext);
    const { allUserInfo } = useContext(AdminContext);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const clickHandler = async (email: string) => {
        const result = await getUserInfo(email);
        if (result.isOk && location.pathname !== '/app-admin/user-info') {
            setOptions([]);
            setInputValue('');
            navigate('user-info');
        }
    };

    useEffect(() => {
        if (inputValue.length >= 2) {
            setOptions(
                allUserInfo.filter((item: any) => {
                    if (item.email.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) {
                        return item;
                    }
                }),
            );
        } else {
            setOptions([]);
        }
    }, [inputValue]);

    const getHighlightedText = (userInfo: any) => {
        const renderEmail = (userInfo: any) => {
            if (!userInfo?.email) return null;
            return <span style={{ color: colors.omicron }}>{`${userInfo?.email}\u00A0`}</span>;
        };
        const renderFirstName = (userInfo: any) => {
            if (!userInfo?.firstName) return null;
            return <span style={{ color: colors.alpha }}>{`– ${userInfo?.firstName} –\u00A0`}</span>;
        };
        const renderLastName = (userInfo: any) => {
            if (!userInfo?.lastName) return null;
            return <span style={{ color: colors.omicron }}>{`${userInfo?.lastName}`}</span>;
        };
        return (
            <span className={stls.item}>
                {renderEmail(userInfo)}
                {renderFirstName(userInfo)}
                {renderLastName(userInfo)}
            </span>
        );
    };

    return (
        <div className={stls.component}>
            <Autocomplete
                // sx={{
                //   width:'400px',
                // }}
                options={options}
                autoHighlight
                freeSolo
                filterSelectedOptions={false}
                filterOptions={(x) => x}
                getOptionLabel={(option: any) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option?.inputValue) {
                        return option?.inputValue;
                    }
                    return `${option.firstName} ${option.lastName} ${option?.email}`;
                }}
                onInputChange={(event, value, reason) => {
                    if (reason === 'input') {
                        setInputValue(value);
                        if (value.length < 4 || !value) {
                        }
                    } else if (reason === 'clear') {
                        setInputValue('');
                        setOptions([]);
                    }
                }}
                renderOption={(props: any, option: any) => (
                    <span onClick={() => clickHandler(option.email)}>
                        <li {...props} key={option?.email}>
                            <ListItemText secondary={getHighlightedText(option)} />
                        </li>
                    </span>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('adminPanel.enterEmail')}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                    />
                )}
            />
            <BotPanel />
        </div>
    );
}
