import cn from 'classnames';
import React from 'react';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';

type TypeIconFullScreanProps = TypeClassNames;

export const IconFullScrean = ({ classNames }: TypeIconFullScreanProps) => {
    const { colors } = React.useContext(ThemeContext);
    return (
        <div className={cn(classNames)}>
            <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.1 0C17.3387 0 17.5676 0.0936505 17.7364 0.260349C17.9052 0.427048 18 0.653141 18 0.888889V15.1111C18 15.3469 17.9052 15.573 17.7364 15.7397C17.5676 15.9064 17.3387 16 17.1 16H0.9C0.661305 16 0.432387 15.9064 0.263604 15.7397C0.0948211 15.573 0 15.3469 0 15.1111V0.888889C0 0.653141 0.0948211 0.427048 0.263604 0.260349C0.432387 0.0936505 0.661305 0 0.9 0H17.1ZM14.4 8H12.6V10.6667H9.9V12.4444H14.4V8ZM8.1 3.55556H3.6V8H5.4V5.33333H8.1V3.55556Z"
                    fill={colors.epsilon}
                />
            </svg>
        </div>
    );
};
