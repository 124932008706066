import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import stls from '@f_styles/components/my-company/MyCompany.module.sass';
import { Company } from './Company';
import { LayoutMenuPage } from '@f_general/layout/LayoutMenuPage';
import { useContext, useEffect, useState } from 'react';
import { WorkspaceContext } from '@f_context/Workspace_context';
import { UserList } from './UserList';
import { AuthContext } from '@f_context/Auth_context';
import { isEmpty } from 'lodash';
import { LoadingPage } from '@f_components/loadings/LoadingPage';
import { UserContext } from '@f_context/User_context';

export function MyCompany() {
    const { t } = useTranslation();
    const { checkAuth } = useContext(AuthContext);

    const { childWorkspaces, workspaces, getInfoWorkspaces, currentWorkspace, indexWorkspace, setIndexWorkspace } =
        useContext(WorkspaceContext);
    const { getUsersToWorkspace } = useContext(UserContext);

    const [dataUserList, setDataUserList] = useState(currentWorkspace);

    useEffect(() => {
        if (checkAuth) {
            getInfoWorkspaces();
            getUsersToWorkspace();
        }
    }, [checkAuth]);

    useEffect(() => {
        if (currentWorkspace) {
            getUsersHandler(indexWorkspace);
        }
    }, [currentWorkspace]);

    const getUsersHandler = (workspaceId?: number) => {
        if (workspaceId) {
            const workspace = [...workspaces, ...childWorkspaces, currentWorkspace].find(
                (item: any) => item.workspaceId === workspaceId,
            );
            if (workspace) {
                getUsersToWorkspace(workspaceId);
                setDataUserList(workspace);
                setIndexWorkspace(workspaceId);
            } else {
                setDataUserList(currentWorkspace);
                getUsersToWorkspace();
                setIndexWorkspace(currentWorkspace.workspaceId);
            }
        } else {
            setDataUserList(currentWorkspace);
            getUsersToWorkspace();
            setIndexWorkspace(currentWorkspace.workspaceId);
        }
    };

    return (
        <>
            {isEmpty(dataUserList) ? (
                <LoadingPage />
            ) : (
                <LayoutMenuPage title={t('myCompany.companies')}>
                    <Grid className={stls.grid}>
                        <Company getUsersHandler={getUsersHandler} currentWorkspace={currentWorkspace} />
                    </Grid>
                    <Grid className={stls.grid}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '32px',
                                marginTop: '12px',
                            }}>
                            {t('myCompany.employees')}
                        </Typography>
                        <UserList workspace={dataUserList} />
                    </Grid>
                </LayoutMenuPage>
            )}
        </>
    );
}
