import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import {
    Button,
    CardContent,
    CircularProgress,
    InputBase,
    MenuItem,
    Modal,
    Select,
    styled,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricer.module.sass';
import { Apps } from '@f_types';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { Alert } from '@f_general/alert';
import GeneralForm from '@f_general/GeneralForm';
import { Role } from '@f_types/TypeRole';
import { IconAddCompanyModal } from '../icons/IconAddCompanyModal';
import { IconAddModal } from '../icons/IconAddModal';

type TypeEditProps = {
    open: boolean;
    handleClose: any;
    workspacesInfo?: any;

    type: string;
};

export function AccountModal({ open, handleClose, workspacesInfo, type }: TypeEditProps) {
    const { t } = useTranslation();
    const { addAppsToWorkspace, userInfo, addWorkspace } = useContext(UserAccountContext);
    const { colors } = useContext(ThemeContext);

    const [tabs, setTabs] = useState<any>([]);
    const [value, setValue] = useState(0);

    const closeModal = () => {
        setValue(0);
        handleClose();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        switch (type) {
            case 'company':
                setTabs([
                    {
                        title: t('adminPanel.addNewCompany'),
                        id: 1,
                        content: {
                            icon: '',
                            title: '',
                            props: [
                                {
                                    control: 'input',
                                    name: 'title',
                                    title: t('adminPanel.titleCompany'),
                                    defaultValue: '',
                                },
                                {
                                    control: 'input',
                                    name: 'telegramNikname',
                                    title: 'Telegram Nikname',
                                    defaultValue: '',
                                },
                                {
                                    control: 'select',
                                    name: 'companyParent',
                                    title: t('adminPanel.setCompanyParent'),
                                    defaultValue: 0,
                                    options: [
                                        { key: 'Не устанавливать родительскую компанию', value: 0 },
                                        ...workspacesInfo.map((item: any) => ({
                                            key: item.workspaceName,
                                            value: item.workspaceId,
                                        })),
                                    ],
                                },
                                {
                                    control: 'select',
                                    name: 'roleSysName',
                                    title: t('adminPanel.userRole'),
                                    defaultValue: 0,
                                    options: [
                                        { key: 'User', value: Role.User },
                                        { key: 'Admin', value: Role.Admin },
                                        { key: 'Manager', value: Role.Manager },
                                        { key: 'SUPER ADMIN', value: Role.SUPER_ADMIN },
                                    ],
                                },
                                ...Object.entries(Apps).map((item: any) => ({
                                    control: 'checkbox',
                                    title: '',
                                    name: item[1],
                                    type: 'checkbox',
                                    options: [
                                        {
                                            key: item[0],
                                            value: false,
                                        },
                                    ],
                                })),
                            ],
                            shema: null,
                            type: 'addComapny',
                            data: {
                                title: '',
                                telegramNikname: '',
                                companyParent: 0,
                                roleSysName: '',
                                type: 'addComapny',
                            },
                        },
                    },
                    {
                        title: t('adminPanel.AttachCompanyUser'),
                        id: 2,
                        content: {
                            icon: '',
                            title: '',
                            props: [
                                {
                                    control: 'input',
                                    name: 'workspaceId',
                                    title: t('adminPanel.companyId'),
                                    defaultValue: '',
                                },
                                {
                                    control: 'select',
                                    name: 'roleSysName',
                                    title: t('adminPanel.userRole'),
                                    defaultValue: 0,
                                    options: [
                                        { key: 'User', value: Role.User },
                                        { key: 'Admin', value: Role.Admin },
                                        { key: 'Manager', value: Role.Manager },
                                        { key: 'SUPER ADMIN', value: Role.SUPER_ADMIN },
                                    ],
                                },
                            ],
                            shema: null,
                            type: 'attachCompany',
                            data: {
                                workspaceId: '',
                                roleSysName: '',
                                type: 'attachCompany',
                            },
                        },
                    },
                ]);
                break;
            case 'applacations':
                setTabs(
                    (() => {
                        return Object.entries(Apps).map((app: any, idx: number) => {
                            return {
                                title: app[0],
                                id: idx,
                                content: {
                                    icon: '',
                                    title: '',
                                    props: [
                                        {
                                            control: 'select',
                                            name: 'workspace',
                                            title: t('adminPanel.company'),
                                            defaultValue: '',
                                            options: [
                                                ...workspacesInfo.map((item: any) => ({
                                                    key: item.workspaceName,
                                                    value: {
                                                        workspaceId: item.workspaceId,
                                                        workspaceName: item.workspaceName,
                                                    },
                                                })),
                                            ],
                                        },
                                    ],
                                    shema: null,
                                    type: app[1],
                                    data: {
                                        workspace: '',
                                        type: app[1],
                                    },
                                },
                            };
                        });
                    })(),
                );
                break;
        }
    }, [type]);

    const sendDataHandler = async (values: any) => {
        switch (type) {
            case 'applacations':
                const resultApps = await addAppsToWorkspace({
                    userId: userInfo.userId,
                    ...values,
                });
                if (resultApps.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeModal();
                } else {
                    if (resultApps.statusNumber === 1) {
                        Alert({
                            type: 'warning',
                            text: 'Это приложение уже есть в этой компании',
                            time: 8000,
                        });
                    } else if (resultApps.statusNumber === 2) {
                        Alert({
                            type: 'error',
                            text: 'userapi не создал токен',
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                }
                break;
            case 'company':
                const resultWorkspace = await addWorkspace({
                    userId: userInfo.userId,
                    email: userInfo.email,
                    data: values,
                });
                if (resultWorkspace.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeModal();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;
        }
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <div className={stls.modalTitle}>
                            {type == 'company' ? <IconAddCompanyModal /> : <IconAddModal />}
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {type == 'company'
                                    ? t('adminPanel.createCompanyAndAttach')
                                    : t('adminPanel.createApps')}
                            </Typography>
                        </div>
                    </div>

                    <div className={stls.content}>
                        <Tabs value={value} onChange={handleChange} variant="scrollable" className={stls.tabs}>
                            {tabs?.map((item: any, idx: number) => {
                                return (
                                    <Tab
                                        className={stls.tab}
                                        sx={{ color: colors.omicron }}
                                        key={item.id}
                                        label={item.title}
                                        value={idx}
                                    />
                                );
                            })}
                        </Tabs>
                        {tabs?.map((item: any, idx: number) => {
                            return (
                                <div
                                    key={item.workspaceId}
                                    role="tabpanel"
                                    hidden={value !== idx}
                                    id={`tabpanel-${idx}`}
                                    aria-labelledby={`tab-${idx}`}>
                                    {value === idx && (
                                        <div>
                                            <GeneralForm
                                                sendDataHandler={sendDataHandler}
                                                close={closeModal}
                                                mainData={item.content}
                                                loading={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
}
