import { IconInstruction } from '../../icons/IconInstruction';
import { useTranslation } from 'react-i18next';
import stl from '@f_styles/general/Universal.module.sass';
import stls from '../../../styles/component/settings/Suppliers.module.sass';
import { useContext, useEffect, useState } from 'react';
import { InfoModal } from '@f_qwep/components/settings/suppliers/InfoModal';
import parse from 'html-react-parser';
import { ThemeContext } from '@f_context/Theme_context';

export function CellInstruction({ info }: any) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { colors } = useContext(ThemeContext);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={stls.cellInstruction}>
            <button className={stl.flexAlignCenter} onClick={() => setOpen(true)}>
                <IconInstruction />
                <p style={{ color: colors.omicron }} className={stls.text}>
                    {t('qwep.settings.suppliers.instruction')}
                </p>
            </button>
            <InfoModal
                open={open}
                handleClose={handleClose}
                dataModal={{
                    title: info.row.original?.title,
                    description: info.row.original.data?.description,
                    phone: info.row.original.data?.phone,
                    officeAddress: info.row.original.data?.officeAddress,
                    payment: info.row.original.data?.payment,
                    regions: info.row.original.data?.regions,
                    forPhysicFaces: info.row.original.data?.forPhysicFaces,
                    hasPassengerProgram: info.row.original?.carProgram,
                    hasTruckProgram: info.row.original.truckProgram,
                    schedule: info.row.original.data?.schedule,
                    delivery: info.row.original.data?.delivery,
                    juristTitle: info.row.original.data?.juristTitle,
                    tutorial: info.row.original.data?.tutorial ? parse(info.row.original.data?.tutorial) : <div />,
                }}
            />
        </div>
    );
}
