import stls from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/createCollectorModal/CreateCollectorModal.module.sass';
import { Button } from '@mui/material';
import GeneralForm from '@f_general/GeneralForm';
import { t } from 'i18next';

export interface CollectorPartOfModalProps {
    sendDataHandler: (values: any) => Promise<void>;
    closeModal: () => void;
    mobile: boolean;
    loading: boolean;
}

export function CollectorPartOfModal ({sendDataHandler, closeModal, mobile, loading}:CollectorPartOfModalProps) {

    return(
        <GeneralForm
            sendDataHandler={sendDataHandler}
            close={closeModal}
            actions={
                <div className={stls.action} style={mobile ? {} : { display: 'flex' }}>
                    <Button className={stls.modalButton} onClick={closeModal}>
                        {t('modal.cancel')}
                    </Button>
                    <Button
                        disabled={loading}
                        className={stls.modalButton}
                        variant={'contained'}
                        type="submit">
                        {t('modal.success')}
                    </Button>
                </div>
            }
            mainData={{
                icon: '',
                title: '',
                props: [
                    {
                        title: 'Введите название отчета:',
                        control: 'input',
                        name: 'title',
                        type: 'text',
                        placeholder: 'Введите название конфигурации',
                        castomStyle: {
                            width: '325px',
                            '& .MuiInputBase-input': {
                                padding: '12px',
                            },
                        },
                    },
                    {
                        control: 'checkbox',
                        title: 'Выберите параметры отчета:',
                        name: 'sameWarehouse',
                        type: 'checkbox',
                        options: [
                            {
                                key: 'По складу',
                                value: false,
                            },
                        ],
                    },
                    {
                        control: 'checkbox',
                        title: '',
                        name: 'sameStatus',
                        type: 'checkbox',
                        options: [
                            {
                                key: 'По статусу',
                                value: false,
                            },
                        ],
                    },
                    {
                        control: 'checkbox',
                        title: '',
                        name: 'sameQuantity',
                        type: 'checkbox',
                        options: [
                            {
                                key: 'По количеству',
                                value: false,
                            },
                        ],
                    },
                ],
                shema: null,
                type: 'editApp',
                data: {
                    title: '',
                    sameWarehouse: false,
                    sameStatus: false,
                    sameQuantity: false,
                    type: 'createReport',
                },
            }}
            loading={false}
        />
    )
}