import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconFeatherProps = TypeClassNames;

export const IconFeather = ({ classNames }: TypeIconFeatherProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        // <div className={cn(classNames)}>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.2} />
            <path
                d="M18.44 26.036C18.207 26.66 18.01 27.236 17.834 27.819C18.794 27.122 19.935 26.68 21.252 26.515C23.765 26.201 25.998 24.542 27.128 22.457L25.672 21.002L27.085 19.587L28.085 18.586C28.515 18.156 29 17.362 29.513 16.218C23.92 17.085 20.495 20.51 18.439 26.036H18.44ZM28.5 21.001L29.5 22C28.5 25 25.5 28 21.5 28.5C18.831 28.834 17.164 30.667 16.498 34H14.5C15.5 28 17.5 14 32.5 14C31.5 16.997 30.502 18.996 29.503 19.997L28.5 21.001Z"
                fill={colors.alpha}
            />
        </svg>
        // </div>
    );
};
