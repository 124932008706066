import React, { FC } from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IColorsProps } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingProps.interface';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { PositionLoadingOptionsEnum } from '@f_qwep/components/purchasingAssistant/types/PositionLoadingOptionsEnum';

interface ICustomModalListActionsProps extends IColorsProps {
    handleClose: () => void;
    handleBack: () => void;
    isButtonDisabled: boolean;
    option: PositionLoadingOptionsEnum;
    isShowPreviewTable: boolean;
}

export const CustomListModalActions: FC<ICustomModalListActionsProps> = observer(
    ({ colors, handleClose, isButtonDisabled, option, isShowPreviewTable, handleBack }) => {
        const { t } = useTranslation();
        const loading = false;

        const isOptionFileInput = option === PositionLoadingOptionsEnum.FILE_INPUT;

        return (
            <div className={styles.actions}>
                <Button
                    className={styles.modalButton}
                    onClick={() => {
                        isShowPreviewTable ? handleBack() : handleClose();
                    }}
                    sx={{
                        background: colors.delta,
                        color: colors.phi,
                        '&:hover': { color: colors.alpha },
                    }}>
                    {isOptionFileInput && isShowPreviewTable ? t('general.back') : t('modal.cancel')}{' '}
                </Button>
                <Button
                    className={styles.modalButton}
                    variant={'contained'}
                    type={'submit'}
                    disabled={isButtonDisabled}>
                    {isOptionFileInput && !isShowPreviewTable ? t('general.next') : t('modal.success')}{' '}
                    {loading && <CircularProgress size={20} className={styles.loading} />}
                </Button>
            </div>
        );
    },
);
