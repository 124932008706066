import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { Box, Button, CardContent, CircularProgress, InputAdornment, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/general/GeneralModal.module.sass';
import { useTranslation } from 'react-i18next';
import { IconCloseEye } from '@f_components/icons/IconCloseEye';
import { IconEye } from '@f_components/icons/IconEye';
import { IconClose } from '@f_components/icons/IconClose';

type TypeDataProps = {
    openModal: boolean;
    closeModal: any;

    sendDataModalHandler: any;
    dataModal: any;
    loading?: any;
};

export default function GeneralModal({
    openModal,
    sendDataModalHandler,
    closeModal,
    dataModal,
    loading,
}: TypeDataProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const data = dataModal.data;
    const [castom, setCastom] = useState<any>({});

    const [showPass, setShowPass] = useState(false);

    const handleClick = (index: any) => {
        setShowPass((prev) => !prev);
    };

    useEffect(() => {
        if (
            dataModal.type === 'deleteAccount' ||
            dataModal.type === 'deleteUser' ||
            dataModal.type === 'deleteUser' ||
            dataModal.type === 'deleteCompany' ||
            dataModal.type === 'deleteApp' ||
            dataModal.type === 'deleteTasks' ||
            dataModal.type === 'deletePrice' ||
            dataModal.type === 'deleteMargin' ||
            dataModal.type === 'deleteGroup'
        ) {
            setCastom({
                textButtonTrue: t('general.yes'),
                textButtonFalse: t('general.no'),
                closeIcon: (
                    <button
                        style={{
                            marginTop: '-15px',
                            marginRight: '-5px',
                            float: 'right',
                        }}
                        onClick={() => {
                            closeModal();
                            setShowPass(false);
                        }}>
                        <IconClose />
                    </button>
                ),

                buttonTrue: {
                    background: colors.lambda,
                    maxWidth: '82px',
                    color: '#fff',
                    '&:hover': { background: '#ee654d', color: '#fff' },
                },
                buttonFalse: {
                    maxWidth: '82px',
                    marginLeft: '12px',
                },
                buttonActions: {
                    justifyContent: 'flex-start',
                    flexDirection: 'row-reverse',
                },
                card: {
                    padding: '50px 40px 16px 40px',
                },
            });
        } else {
            setCastom({});
        }
    }, [dataModal.type]);

    return (
        <>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={() => {
                        closeModal();
                        setShowPass(false);
                    }}>
                    <Box className={stls.modal} style={{ background: colors.theta }} sx={{ ...castom.card }}>
                        {castom.closeIcon}
                        <CardContent className={stls.modalContent}>
                            <FormikWrapper
                                icon={dataModal.icon}
                                title={
                                    <div className={stls.genetalTitle}>
                                        {dataModal.title}
                                        {loading && <CircularProgress sx={{ marginLeft: '10px' }} size={'20px'} />}
                                    </div>
                                }
                                onSubmit={sendDataModalHandler}
                                initialValues={data}
                                validShema={dataModal?.shema}>
                                <div className={stls.formContent}>
                                    {dataModal?.props.map((item: any) => {
                                        if (item?.control === 'select') {
                                            return (
                                                <div key={item.name}>
                                                    <Typography className={stls.modalTypography}>
                                                        {item.title}
                                                    </Typography>
                                                    <FormikController
                                                        className={stls.select}
                                                        name={item.name}
                                                        control="select"
                                                        castomStyle={item.castomStyle}
                                                        defaultValue={item.defaultValue}
                                                        options={item.options}
                                                    />
                                                </div>
                                            );
                                        }
                                        if (item?.control === 'input') {
                                            return (
                                                <div key={item.name}>
                                                    <Typography className={stls.modalTypography}>
                                                        {item.title}
                                                    </Typography>
                                                    <FormikController
                                                        className={stls.inputModal}
                                                        control="input"
                                                        type={showPass ? 'text' : item.type}
                                                        name={item.name}
                                                        defaultValue={item.defaultValue}
                                                        placeholder={item.placeholder}
                                                        inputProps={{
                                                            autoComplete: 'new-password',
                                                        }}
                                                        required
                                                        InputProps={
                                                            item.type === 'password' && {
                                                                endAdornment: (
                                                                    <InputAdornment
                                                                        onClick={handleClick}
                                                                        position="start">
                                                                        {showPass ? <IconEye /> : <IconCloseEye />}
                                                                    </InputAdornment>
                                                                ),
                                                            }
                                                        }
                                                    />
                                                </div>
                                            );
                                        }

                                        if (item?.control === 'text') {
                                            return (
                                                <div key={item.name}>
                                                    <Typography className={stls.modalTypography}>
                                                        {item.title}
                                                    </Typography>
                                                    <p>{item.content}</p>
                                                </div>
                                            );
                                        }

                                        if (item?.control === 'inputDate') {
                                            return (
                                                <div key={item.name}>
                                                    <Typography className={stls.modalTypography}>
                                                        {item.title}
                                                    </Typography>
                                                    <FormikController
                                                        className={stls.inputModal}
                                                        control="input"
                                                        type={'date'}
                                                        name={item.name}
                                                    />
                                                </div>
                                            );
                                        }

                                        if (item?.control === 'checkbox') {
                                            return (
                                                <div key={item.name}>
                                                    <Typography className={stls.modalTypography}>
                                                        {item.title}
                                                    </Typography>
                                                    <FormikController
                                                        className={stls.checkbox}
                                                        type="checkbox"
                                                        control="checkbox"
                                                        name={item.name}
                                                        disabled={item.disabled}
                                                        options={item.options}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        ...castom.buttonActions,
                                    }}>
                                    <Button
                                        className={stls.modalButton}
                                        onClick={() => {
                                            closeModal();
                                            setShowPass(false);
                                        }}
                                        sx={{
                                            background: colors.delta,
                                            color: colors.phi,
                                            '&:hover': { color: colors.alpha },
                                            ...castom.buttonFalse,
                                        }}>
                                        {castom.textButtonFalse ? castom.textButtonFalse : t('modal.cancel')}
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        className={stls.modalButton}
                                        {...{
                                            variant: !castom?.buttonTrue ? 'contained' : 'text',
                                        }}
                                        type="submit"
                                        sx={{
                                            ...castom.buttonTrue,
                                        }}>
                                        {castom.textButtonTrue ? castom.textButtonTrue : t('modal.success')}
                                    </Button>
                                </div>
                            </FormikWrapper>
                        </CardContent>
                    </Box>
                </Modal>
            )}
        </>
    );
}
