import React, { useState, useContext } from 'react';
import { OrdersContext } from '@f_qwep/context/Orders_context';
import { OrderChatPopup } from '@f_qwep/components/orders/orderChat/OrderChatPopup';
import { OrderChatTabs } from '@f_qwep/components/orders/orderChat/OrderChatTabs';
import { OrderChatTab } from '@f_qwep/components/orders/orderChat/OrderChatTab';
import { UserContext } from '@f_context/User_context';
import { format } from 'date-fns';



export type TypeComment = {
    date?: string
    firstName: string
    lastName: string
    message: string
    type?: 'BUYER' | 'SELLER'
}

interface OrderChatProps {
    itemOrderId: string
    orderId: string
    commentOrder: TypeComment[]
    commentItem: TypeComment[]
    type: 'BUYER' | 'SELLER'
}

export const OrderChat = ({itemOrderId, orderId, type, commentOrder, commentItem}: OrderChatProps) => {
    const { addCommentItemOrder, addCommentOrder } = useContext(OrdersContext);
    const { userInfo } = useContext(UserContext);

    const [itemCommentList, setItemCommentList] = useState<TypeComment[]>(commentItem ?? []);
    const [itemInput, setItemInput] = useState<string>('');

    const [orderCommentList, setOrderCommentList] = useState<TypeComment[]>(commentOrder ?? []);
    const [orderInput, setOrderInput] = useState<string>('');


    const handleSendItemMessage = async () => {
        if (itemInput.trim()) {
            setItemCommentList([...itemCommentList, {
                date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                message: itemInput,
                type
            }]);
            setItemInput('');
            const res = await addCommentItemOrder({
                id: itemOrderId,
                type,
                text: JSON.stringify({
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    message: itemInput,
                })
            })
        }
    };

    const handleSendOrderMessage = async () => {
        if (orderInput.trim()) {
            setOrderCommentList([...orderCommentList, {
                date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSSS"),
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                message: orderInput,
                type
            }]);
            setOrderInput('');
            const res = await addCommentOrder({
                id: orderId,
                type,
                text: JSON.stringify({
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    message: orderInput,
                })
            })
        }

    };

    return (
        <OrderChatPopup>
            <OrderChatTabs
                tabOrderItem={
                <OrderChatTab
                    value={itemInput}
                    setValue={setItemInput}
                    handleSubmit={handleSendItemMessage}
                    commentList={itemCommentList}
                />
                }
                tabOrder={
                    <OrderChatTab
                        value={orderInput}
                        setValue={setOrderInput}
                        handleSubmit={handleSendOrderMessage}
                        commentList={orderCommentList}
                    />
                }
            />
        </OrderChatPopup>
    );
};