import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_ucs/styles/components/licenses/Subscription.module.sass';
import { LayoutPage } from '@f_crossdata/general/LayoutPage';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { FormikController } from '@f_general/formik/FormikController';
import { UserContext } from '@f_context/User_context';
import { isEmpty } from 'lodash';
import { Alert } from '@f_general/alert';
import { choosenTariffQwepCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { useUCSContext } from '../../context/UCS_context';

export function Subscription() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);
    const { changeTariffRequest, isActiveApp, blocked } = useUCSContext();
    const [data, setData] = useState({
        phone: userInfo.phoneNumber ?? '',
        name: userInfo.firstName ?? '',
        comment: '',
    });

    const submitHandler = async (values: any) => {
        const result = await changeTariffRequest(values);
        if (result.isOk) {
            setData({
                phone: userInfo.phoneNumber ?? '',
                name: userInfo.firstName ?? '',
                comment: '',
            });
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    return (
        <LayoutPage>
            <Box className={stls.subscription}>
                <Card className={stls.subscriptionCard}>
                    <CardContent className={stls.licensesContent}>
                        {!isEmpty(userInfo) && (
                            <FormikWrapper
                                onSubmit={submitHandler}
                                initialValues={data}
                                validShema={choosenTariffQwepCustomerSchema}>
                                <div className={stls.title}>{t('ucs.subscription.title')}</div>
                                <div className={stls.description}>{t('ucs.subscription.description')}</div>
                                <div className={stls.fillForm}>{t('ucs.subscription.fillForm')}</div>
                                <div className={stls.textField}>
                                    <Typography sx={{ color: '#fff', fontWeight: 800 }}>{t('auth.phone')}*</Typography>
                                    <FormikController
                                        className={stls.input}
                                        type="tel"
                                        castomStyle={{
                                            '& .MuiInputBase-input': {
                                                background: colors.beta,
                                                borderRadius: '12px',
                                                padding: '12px',
                                                color: colors.omicron,
                                            },
                                        }}
                                        control="input"
                                        name="phone"
                                        placeholder="+7 (999) 000-00-00"
                                    />
                                </div>
                                <div className={stls.textField}>
                                    <Typography sx={{ color: '#fff', fontWeight: 800 }}>{t('auth.name')}*</Typography>
                                    <FormikController
                                        className={stls.input}
                                        castomStyle={{
                                            '& .MuiInputBase-input': {
                                                background: colors.beta,
                                                borderRadius: '12px',
                                                color: colors.omicron,
                                            },
                                        }}
                                        type="text"
                                        control="input"
                                        name="name"
                                        placeholder={t('ucs.subscription.name')}
                                    />
                                </div>
                                <div className={stls.textField}>
                                    <Typography sx={{ color: '#fff', fontWeight: 800 }}>
                                        {t('licenses.comment')}
                                    </Typography>
                                    <FormikController
                                        className={stls.input}
                                        type="text"
                                        control="input"
                                        rows={4}
                                        multiline
                                        castomStyle={{
                                            '& .MuiInputBase-root': {
                                                background: colors.beta,
                                                borderRadius: '12px',
                                                color: colors.omicron,
                                                paddind: '0px',
                                                fontWeight: 400,
                                                fontFamily: 'Inter',
                                            },
                                            '& .MuiInputBase-input': {
                                                color: colors.omicron,
                                                padding: '0px',
                                            },
                                        }}
                                        name="comment"
                                        placeholder={t('licenses.enterComment')}
                                    />
                                </div>
                                <Button
                                    className={stls.button}
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    style={{ background: '#ffff', color: '#3B82F6' }}>
                                    {t('licenses.confirm')}
                                </Button>
                            </FormikWrapper>
                        )}
                    </CardContent>
                </Card>
                <div>
                    {blocked && <p style={{ color: colors.lambda }}>{t('ucs.subscription.appBlock')}</p>}
                    {!isActiveApp && <p style={{ color: colors.lambda }}>{t('ucs.subscription.subscPeriodEnd')}</p>}
                </div>
            </Box>
        </LayoutPage>
    );
}
