import { ThemeContext } from '@f_context/Theme_context';
import { TypeChildren } from '@f_types';
import { Box } from '@mui/material';
import React from 'react';

type TypeMyModalProps = TypeChildren & TypeClassName;

type TypeClassName = {
    className?: any;
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: '12px',
};

export function MyModal({ className, children }: TypeMyModalProps) {
    const { colors } = React.useContext(ThemeContext);

    return (
        <Box className={className} sx={style} style={{ background: colors.theta }}>
            {children}
        </Box>
    );
}
