import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconAddPriceProps = TypeClassNames;

export const IconAddPrice = ({ classNames }: TypeIconAddPriceProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="24" fill={colors.alpha} fillOpacity={0.1} />
                <path
                    d="M27 16H17V32H31V20H27V16ZM15 14.992C15 14.444 15.447 14 15.999 14H28L33 19V32.993C33.0009 33.1243 32.976 33.2545 32.9266 33.3762C32.8772 33.4979 32.8043 33.6087 32.7121 33.7022C32.6199 33.7957 32.5101 33.8701 32.3892 33.9212C32.2682 33.9723 32.1383 33.9991 32.007 34H15.993C15.7304 33.9982 15.479 33.8931 15.2932 33.7075C15.1074 33.5219 15.0021 33.2706 15 33.008V14.992ZM25 24V28H23V24H20L24 20L28 24H25Z"
                    fill={colors.alpha}
                />
            </svg>
        </div>
    );
};
