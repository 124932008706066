import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/home/TaskList.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { ThemeContext } from '@f_context/Theme_context';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';

export function TaskCrossTable({ data }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'brand',
                accessorKey: 'brand',
                header: `${t('crossdata.crossTable.brand')}`,
                cell: (info) => <p className={stls.cell}>{info.row.original.brand}</p>,
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'article',
                accessorKey: 'article',
                header: `${t('crossdata.crossTable.article')}`,
                cell: (info) => (
                    <p className={stls.cell} style={{ color: colors.alpha, fontWeight: 800 }}>
                        {info.row.original.article}
                    </p>
                ),
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'crossSize',
                accessorKey: 'crossSize',
                header: `${t('crossdata.crossTable.crossSize')}`,
                cell: (info) => (
                    <p
                        className={stls.cell}
                        style={{
                            color: colors.lightgreen,
                            background: colors.kappa,
                            maxWidth: '30px',
                            marginLeft: '43%',
                        }}>
                        {info.row.original.crossSize}
                    </p>
                ),
                footer: (props) => props.column.id,
                size: 50,
            },
        ],
        [data],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                border: `0.5px solid ${colors.phi}`,
                borderRadius: '7px',
                background: colors.psi,
                maxWidth: '230px',
                fontSize: '14px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '30px',
                textAlign: 'center',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div style={{ marginBottom: '50px' }}>
            <VirtualTable
                data={data}
                columnsTable={columns}
                topFilterPanel={false}
                showColumnSearchAction={true}
                heightTable={300}
                configHeader={{
                    fullScrean: true,
                    exportExcel: false,
                    resize: false,
                    grouping: false,
                    columnFilter: false,
                    sorting: true,
                    columnSearch: true,
                    dragging: false,
                }}
                styles={tableStyles}
                overscan={5}
                estimateSize={25}
            />
        </div>
    );
}
