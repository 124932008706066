import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/settings/Settings.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { CellSite } from './CellSite';
import { CellInstruction } from './CellInstruction';
import { CellAdd } from './CellAdd';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { SearchBase } from '@f_general/SearchBase';
import { useWindowSize } from '@f_hooks';
import { LoadingSettings } from '@f_qwep/components/loaders/LoadingSettings';
import { CellTitle } from './CellTitle';
import { MobileCart } from './MobileCart';
import { MobileButtonSettings } from '../MobileButtonSettings';
import { SettingSupplierModal } from '../accounts/SettingSuppliersModal';
import { GeneralContext } from '@f_context/General_context';

export function Suppliers() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { width, height } = useWindowSize();

    const [mobile, setMobile] = useState(false);

    const [openSettings, setOpenSettings] = useState(false);

    const { getVendors, vendors, isLoadingSuppliers, addCurrentAcc, setAddCurrentAcc } = useContext(AccountsContext);
    useEffect(() => {
        getVendors();
    }, []);

    useEffect(() => {
        if (addCurrentAcc?.hasAuthParameters) {
            setOpenSettings(true);
        }
    }, [addCurrentAcc?.id]);

    const closeSettingsHandler = () => {
        setOpenSettings(false);
        setAddCurrentAcc([]);
    };

    const [vendorTable, setVendorTable] = useState(vendors);

    useEffect(() => {
        setVendorTable(vendors);
    }, [vendors]);

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          header: `${t('qwep.settings.suppliers.supplier')}`,
                          cell: (info) => <MobileCart info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                  ]
                : [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          header: `${t('qwep.settings.suppliers.supplier')}`,
                          cell: (info) => <CellTitle info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                      {
                          id: 'site',
                          accessorKey: 'site',
                          header: `${t('qwep.settings.suppliers.site')}`,
                          cell: (info) => <CellSite info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                      {
                          id: 'instruction',
                          accessorKey: '',
                          header: `${t('qwep.settings.suppliers.info')}`,
                          cell: (info) => <CellInstruction info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                      {
                          id: 'settings',
                          accessorKey: '',
                          header: `${t('qwep.settings.suppliers.settings')}`,
                          cell: (info) => <CellAdd info={info} />,
                          footer: (props) => props.column.id,
                          size: 1,
                      },
                  ],

        [vendorTable, mobile],
    );

    const tableStyles = {
        head: {
            // background: colors.beta,
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '40px',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    //
    const { seIsRunAssistantVendorSearch } = useContext(GeneralContext);

    // Поиск поставщика ассистента сброс настроек
    useEffect(() => {
        return () => {
            seIsRunAssistantVendorSearch(false);
        };
    }, []);

    return (
        <div className={stls.page}>
            {isLoadingSuppliers ? (
                <LoadingSettings />
            ) : (
                <>
                    <div className={stls.appbarGroup}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <SearchBase
                                id="searchAccount"
                                data={vendors}
                                placeholder={t('qwep.settings.suppliers.searchAccount')}
                                setData={setVendorTable}
                                type={'vendors'}
                            />
                            {mobile && <MobileButtonSettings />}
                        </div>
                    </div>

                    <VirtualTable
                        data={vendorTable}
                        columnsTable={columns}
                        topFilterPanel={false}
                        showColumnSearchAction={false}
                        heightTable={800}
                        configHeader={{
                            fullScrean: false,
                            exportExcel: false,
                            resize: false,
                            grouping: false,
                            columnFilter: false,
                            sorting: false,
                            columnSearch: false,
                            dragging: false,
                        }}
                        styles={tableStyles}
                        estimateSize={mobile ? 126 : 48}
                    />
                </>
            )}

            <SettingSupplierModal info={addCurrentAcc} handleClose={closeSettingsHandler} open={openSettings} />
        </div>
    );
}
