import React from 'react';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { CreateList } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelActions/actions/CreateList';
import { WatchEducationVideo } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelActions/actions/WatchEducationVideo';
import { CurrentListActions } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelActions/actions/CurrentListActions';
import { AssistantLists } from '@f_qwep/components/purchasingAssistant/ui/configPanel/configPanelActions/actions/AssistantLists';

export const ConfigPanelActions = () => {
    return (
        <div className={styles.config}>
            <div className={styles.nav}>
                <CreateList />
                <AssistantLists />
                <CurrentListActions />
            </div>
            <WatchEducationVideo />
        </div>
    );
};
