import { FilterLeftPanel } from '@f_qwep/components/filters/filterLeftPanel/ui/FilterLeftPanel';
import { observer } from 'mobx-react-lite';
import { FilterLeftPanelProvider } from '@f_qwep/components/filters/filterLeftPanel/context/FilterLeftStore.context';
import { FilterTemplateProvider } from '@f_qwep/components/filters/filterLeftPanel/context/FilterTemplate.context';

export const FilterLeft = observer(({ items, filter, filtersReducer, type, taskResultId = '' }: any) => {
    return (
        <FilterLeftPanelProvider>
            <FilterTemplateProvider type={type} taskResultId={taskResultId} filtersReducer={filtersReducer}>
                <FilterLeftPanel items={items} filtersReducer={filtersReducer} filter={filter} />
            </FilterTemplateProvider>
        </FilterLeftPanelProvider>
    );
});
