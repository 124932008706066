import React, { useEffect, useState } from 'react';
import { Grow, ClickAwayListener, List, Popper, CardContent } from '@mui/material';
import { ThemeContext } from '@f_context/Theme_context';
import { ManualContext } from '@f_qwep/context/Manual_context';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SearchBase } from '@f_general/SearchBase';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import { IconLibraryNew } from '@f_components/icons/IconLibraryNew';

export const Library = () => {
    const navigate = useQwepNavigate();
    const { colors } = React.useContext(ThemeContext);
    const { library, setChooseManual } = React.useContext(ManualContext);
    const startManual = [
        'Как выполнить поиск',
        'Как поменять тариф',
        'История поиска',
        'Статусы заказов',
        'Загрузка прайса',
        'Удаление прайса',
        'Для чего функция "Прайсы"',
        'Стили таблицы результатов',
        'Работа с прайсом',
        'Для чего функция "Проценка списком"',
        'Сменить период заказов',
        'Создать себе группу',
        'Разницы в функциях',
        'Какой тариф у меня',
        'Загрузка проценки списком',
        'Удаление группы поставщиков',
        'Бренды подсказки при поиске',
        'Работа с результатами проценки',
        'Работа с таблицей результата поиска',
        'Редактирование группы поставщиков',
        'Авторизация поставщика для поиска',
        'Фильтры для работы с таблицей результатов',
        'Как выполнить поиск',
        'Как поменять тариф',
        'История поиска',
        'Статусы заказов',
        'Загрузка прайса',
        'Удаление прайса',
        'Стили таблицы результатов',
        'Работа с прайсом',
        'Для чего функция "Проценка списком"',
        'Сменить период заказов',
        'Создать себе группу',
        'Разницы в функциях',
        'Какой тариф у меня',
        'Загрузка проценки списком',
        'Удаление группы поставщиков',
        'Бренды подсказки при поиске',
        'Работа с результатами проценки',
        'Работа с таблицей результата поиска',
        'Редактирование группы поставщиков',
        'Авторизация поставщика для поиска',
        'Фильтры для работы с таблицей результатов',
    ];
    const uniqueManual = Array.from(new Set(startManual));
    const [librarySearch, setLibrarySearch] = useState(uniqueManual);

    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickLibrary = (content: any) => {
        navigate();
        setChooseManual(content);
        handleClose();
    };

    useEffect(() => {
        setLibrarySearch(uniqueManual);
    }, [open]);

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
                id="libraryManual"
                style={{ display: 'flex', alignItems: 'center' }}
                ref={anchorRef}
                onClick={() => setOpen(true)}>
                <IconLibraryNew />
            </button>
            <Popper
                // onClick={() => setOpen(false)}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                style={{ maxHeight: '150px', zIndex: 1000 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <List
                            sx={{
                                background: colors.psi,
                                boxShadow:
                                    '0px 4.82353px 24.1177px rgba(156, 172, 185, 0.290953), 0px 2.00345px 10.0172px rgba(156, 172, 185, 0.2), 0px 0.724608px 3.62304px rgba(156, 172, 185, 0.109047)',
                            }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <CardContent style={{ padding: '12px' }}>
                                    <SearchBase
                                        data={library.map((el: any) => el.content).flat()}
                                        placeholder={`Введите слово, например "прайс"`}
                                        setData={setLibrarySearch}
                                        type={'qwepManual'}
                                    />
                                    <div style={{ maxHeight: '200px', overflow: 'auto', padding: '12px' }}>
                                        {librarySearch?.map((item: any, idx: any) => (
                                            <div key={idx}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <button
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            fontSize: '14px',
                                                        }}
                                                        onClick={() => handleClickLibrary(item)}>
                                                        <p style={{ color: colors.omicron }}>{item}</p>
                                                        <PlayArrowIcon fontSize="small" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CardContent>
                            </ClickAwayListener>
                        </List>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};
