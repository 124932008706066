export const IconSimpleXML = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="580.000000pt"
            height="500.000000pt"
            viewBox="0 0 1280.000000 1052.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1052.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path
                    d="M5090 9300 c0 -739 0 -751 19 -740 24 12 34 13 27 1 -3 -5 3 -11 13
-14 12 -3 22 1 25 9 5 12 138 14 858 12 l853 -3 -847 -2 c-586 -2 -848 -6
-848 -14 0 -5 3 -8 8 -5 4 2 547 6 1207 8 660 2 1164 6 1120 9 -44 3 26 4 155
2 365 -7 1055 -9 960 -2 -52 3 -5 5 120 5 135 0 176 -2 120 -6 -63 -4 -43 -6
80 -5 91 0 145 2 120 5 -25 3 122 4 325 3 323 -1 1126 -3 1270 -3 28 0 444 -1
925 -3 l875 -3 -800 6 -800 5 813 3 c540 1 811 -1 807 -8 -4 -6 6 -10 24 -10
16 0 38 -3 48 -6 16 -6 16 -5 -2 9 l-20 17 20 5 c20 6 20 14 18 693 -1 378 -2
708 -2 735 l-1 47 -3745 0 -3745 0 0 -750z m29 0 c2 399 3 728 2 733 0 4 4 7
9 7 6 0 10 -4 10 -10 0 -7 1243 -10 3710 -10 l3710 0 2 -716 c2 -471 0 -718
-7 -720 -5 -2 -1685 -5 -3733 -7 l-3722 -2 2 735 3 735 5 -735 5 -735 4 725z
m6268 734 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m56 -1 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m110 0 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m70 0 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5
17 0 24 -2 18 -5z m-4440 -1470 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35
-2 23 -4z m880 0 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z
m320 0 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m2120 0 c-35
-2 -91 -2 -125 0 -35 2 -7 3 62 3 69 0 97 -1 63 -3z"
                />
                <path
                    d="M11463 9903 c-3 -10 -13 -26 -21 -35 -13 -16 -13 -18 2 -24 12 -5 16
-20 16 -65 0 -46 3 -59 15 -59 12 0 15 13 15 59 0 46 4 60 17 65 14 6 14 8 -3
21 -15 11 -17 16 -6 26 18 19 15 29 -8 29 -11 0 -23 -8 -27 -17z"
                />
                <path
                    d="M11563 9908 c-25 -40 -34 -63 -24 -66 6 -2 11 -30 11 -63 0 -46 3
-59 15 -59 12 0 15 13 15 60 0 47 3 60 15 60 20 0 19 10 -2 26 -17 13 -17 15
0 25 9 5 17 14 17 19 0 14 -37 12 -47 -2z"
                />
                <path
                    d="M5569 9863 c-1 -5 -2 -38 -3 -75 l-1 -68 43 0 c69 0 80 11 80 76 -1
49 -3 57 -25 65 -29 11 -92 12 -94 2z m91 -38 c0 -19 -50 -26 -57 -8 -3 8 0
19 7 23 17 10 50 1 50 -15z m-5 -60 c0 -14 -8 -21 -27 -23 -24 -3 -28 0 -28
23 0 23 4 26 28 23 19 -2 27 -9 27 -23z"
                />
                <path
                    d="M5837 9819 c-4 -29 -14 -60 -22 -70 -9 -9 -15 -27 -13 -40 4 -29 28
-38 28 -10 0 19 6 21 55 21 48 0 55 -2 55 -20 0 -12 7 -20 16 -20 13 0 15 7 9
38 -3 20 -9 63 -12 95 l-5 57 -52 0 -53 0 -6 -51z m83 -24 c0 -32 -4 -45 -14
-45 -8 0 -22 -3 -31 -6 -15 -6 -17 -1 -12 37 3 24 6 47 6 52 1 4 12 7 26 7 23
0 25 -4 25 -45z"
                />
                <path
                    d="M6006 9851 c-22 -25 -4 -38 25 -17 24 16 49 11 49 -10 0 -8 -16 -16
-40 -20 -31 -5 -42 -12 -46 -30 -10 -38 10 -53 72 -51 l54 1 0 51 c0 65 -20
95 -64 95 -20 0 -40 -8 -50 -19z m72 -83 c-3 -25 -34 -36 -51 -19 -14 14 9 41
35 41 14 0 19 -6 16 -22z"
                />
                <path
                    d="M6160 9796 c0 -59 3 -76 14 -76 9 0 16 12 18 33 3 28 7 32 36 35 31
3 32 2 32 -32 0 -25 4 -36 15 -36 12 0 15 15 15 75 0 60 -3 75 -15 75 -10 0
-15 -10 -15 -31 0 -28 -2 -30 -32 -27 -25 2 -34 9 -38 27 -3 14 -11 26 -18 29
-9 3 -12 -17 -12 -72z"
                />
                <path
                    d="M6339 9868 c-1 -1 -2 -36 -4 -75 -2 -65 0 -73 16 -73 15 0 19 8 19
35 0 32 2 35 30 35 28 0 30 -3 30 -35 0 -29 4 -35 20 -35 19 0 21 5 18 73 -2
56 -6 72 -18 72 -9 0 -16 -11 -18 -27 -3 -23 -8 -28 -32 -28 -24 0 -29 5 -32
27 -2 14 -9 28 -15 31 -7 2 -14 2 -14 0z"
                />
                <path
                    d="M6525 9845 c-30 -30 -32 -64 -4 -99 20 -26 69 -36 95 -18 16 11 34
48 34 72 0 29 -44 70 -75 70 -14 0 -37 -11 -50 -25z m80 -49 c0 -46 -23 -68
-49 -47 -16 14 -22 72 -9 85 4 4 19 6 33 4 22 -3 25 -8 25 -42z"
                />
                <path
                    d="M6690 9795 c0 -59 3 -75 15 -75 10 0 15 15 17 58 l3 57 14 -57 c10
-42 19 -58 31 -58 12 0 21 16 31 58 l14 57 3 -57 c2 -43 7 -58 17 -58 12 0 15
16 15 75 0 73 -1 75 -24 75 -25 0 -29 -6 -41 -67 -6 -32 -25 -47 -25 -20 0 6
-5 29 -11 50 -8 30 -15 37 -34 37 -24 0 -25 -2 -25 -75z"
                />
                <path
                    d="M6990 9850 c-24 -24 -26 -77 -4 -108 20 -29 74 -30 99 -2 26 28 10
37 -27 15 -30 -17 -31 -17 -45 0 -14 20 -17 44 -7 69 7 18 50 22 56 5 2 -6 12
-10 21 -8 16 4 16 6 2 27 -20 28 -68 29 -95 2z"
                />
                <path
                    d="M7156 9848 c-3 -13 -6 -40 -6 -61 0 -24 -5 -40 -15 -43 -21 -8 -19
-24 4 -24 27 0 39 20 43 70 3 41 6 45 31 48 27 3 27 3 27 -57 0 -48 3 -61 15
-61 12 0 15 15 15 75 l0 75 -54 0 c-47 0 -54 -3 -60 -22z"
                />
                <path
                    d="M7320 9851 c0 -11 9 -43 20 -71 24 -61 25 -86 5 -94 -8 -3 -15 -10
-15 -16 0 -14 35 -13 48 3 20 24 72 181 63 190 -15 15 -29 -1 -36 -41 -11 -65
-20 -68 -41 -11 -20 54 -44 77 -44 40z"
                />
                <path
                    d="M7477 9863 c-11 -11 -8 -58 5 -71 7 -7 27 -12 45 -12 30 0 33 -3 33
-30 0 -23 5 -30 19 -30 17 0 19 7 18 73 -2 57 -5 72 -17 72 -10 0 -16 -12 -18
-32 -3 -27 -8 -33 -27 -33 -23 0 -35 17 -35 51 0 19 -11 25 -23 12z"
                />
                <path
                    d="M7658 9859 c-22 -12 -24 -39 -3 -39 8 0 15 4 15 9 0 16 32 22 47 9
13 -10 12 -14 -3 -25 -10 -7 -28 -13 -41 -13 -15 0 -27 -9 -35 -25 -17 -39 3
-54 70 -53 l57 1 -3 61 c-2 41 -9 65 -20 74 -19 14 -60 15 -84 1z m72 -82 c0
-19 -21 -37 -42 -37 -21 0 -25 35 -5 43 23 10 47 7 47 -6z"
                />
                <path
                    d="M7818 9853 c-9 -10 -19 -28 -22 -39 -7 -24 9 -74 27 -86 22 -14 75
-8 93 10 17 16 17 17 0 20 -9 2 -23 -2 -31 -8 -19 -16 -55 -3 -55 21 0 16 7
19 50 19 57 0 63 11 29 54 -24 31 -68 35 -91 9z m72 -34 c0 -14 -7 -19 -30
-19 -34 0 -41 23 -13 38 23 13 43 4 43 -19z"
                />
                <path
                    d="M8047 9864 c-4 -4 -7 -38 -7 -76 0 -61 2 -68 20 -68 16 0 20 7 20 35
0 32 2 35 30 35 28 0 30 -3 30 -35 0 -28 4 -35 20 -35 17 0 19 7 17 73 -2 56
-5 72 -17 72 -9 0 -16 -11 -18 -27 -3 -24 -8 -28 -33 -28 -19 0 -29 5 -30 15
-3 35 -4 38 -15 42 -5 2 -13 0 -17 -3z"
                />
                <path
                    d="M8210 9862 c0 -4 11 -42 25 -83 l26 -74 -22 -17 c-18 -15 -19 -19 -5
-24 9 -3 22 -4 30 -1 13 5 76 164 76 193 0 28 -25 8 -38 -32 -8 -21 -17 -46
-20 -54 -3 -9 -12 8 -22 40 -11 33 -24 56 -34 58 -9 2 -16 -1 -16 -6z"
                />
                <path
                    d="M8380 9864 c0 -3 8 -19 17 -35 17 -29 17 -31 -5 -69 -21 -36 -21 -40
-5 -40 10 0 29 17 43 38 l25 37 3 -37 c4 -47 32 -54 32 -8 0 17 4 30 9 30 5 0
19 -13 31 -30 12 -16 30 -30 41 -30 19 0 18 2 -5 33 -31 40 -31 45 -6 77 23
30 25 40 6 40 -8 0 -24 -13 -36 -30 -27 -38 -40 -38 -40 1 0 41 -23 33 -30 -9
l-5 -32 -28 35 c-25 31 -47 45 -47 29z"
                />
                <path
                    d="M8620 9796 c0 -59 3 -76 14 -76 9 0 16 12 18 33 3 28 6 32 33 32 27
0 30 -4 33 -32 2 -21 9 -33 18 -33 11 0 14 16 14 75 0 60 -3 75 -15 75 -10 0
-15 -10 -15 -31 0 -28 -2 -30 -32 -27 -25 2 -34 9 -38 27 -3 14 -11 26 -18 29
-9 3 -12 -17 -12 -72z"
                />
                <path
                    d="M8813 9850 c-27 -25 -30 -75 -7 -108 11 -16 25 -22 54 -22 45 0 70
26 70 75 0 43 -19 64 -60 71 -27 4 -40 1 -57 -16z m80 -31 c5 -9 7 -27 4 -41
-14 -54 -77 -41 -77 16 0 18 3 36 7 40 12 12 55 2 66 -15z"
                />
                <path
                    d="M9050 9795 c0 -59 3 -75 14 -75 9 0 16 12 18 33 3 28 7 32 36 35 31
3 32 2 32 -32 0 -25 4 -36 15 -36 12 0 15 15 15 75 0 60 -3 75 -15 75 -10 0
-15 -10 -15 -31 0 -28 -2 -30 -32 -27 -28 2 -34 8 -36 31 -2 15 -9 27 -18 27
-11 0 -14 -17 -14 -75z"
                />
                <path
                    d="M9236 9854 c-16 -15 -21 -34 -11 -34 4 0 19 7 34 15 25 12 31 12 40
1 14 -17 3 -26 -43 -36 -27 -5 -31 -10 -31 -40 l0 -35 60 -2 60 -1 -3 61 c-2
34 -9 68 -14 75 -15 17 -74 15 -92 -4z m74 -77 c0 -19 -21 -37 -42 -37 -18 0
-25 29 -11 43 12 12 53 7 53 -6z"
                />
                <path
                    d="M9387 9864 c-3 -4 3 -18 13 -32 25 -31 25 -40 0 -72 -23 -30 -25 -40
-6 -40 8 0 24 14 36 30 12 17 26 30 31 30 5 0 9 -13 9 -30 0 -46 28 -39 32 8
l3 37 25 -37 c14 -21 33 -38 43 -38 17 0 17 3 -4 39 -22 40 -22 49 7 89 15 20
15 22 1 22 -9 0 -26 -13 -39 -30 -12 -16 -25 -30 -30 -30 -4 0 -8 14 -8 30 0
20 -5 30 -15 30 -10 0 -15 -10 -15 -30 0 -16 -4 -30 -8 -30 -5 0 -18 14 -30
30 -23 30 -33 36 -45 24z"
                />
                <path
                    d="M9635 9850 c-24 -27 -14 -35 22 -17 31 16 53 12 53 -9 0 -8 -15 -16
-40 -20 -31 -5 -42 -12 -46 -30 -11 -44 0 -53 63 -52 l58 1 -3 61 c-2 33 -9
67 -14 74 -16 19 -73 15 -93 -8z m75 -69 c0 -25 -14 -41 -36 -41 -17 0 -24 6
-24 19 0 11 6 22 13 24 21 9 47 7 47 -2z"
                />
                <path
                    d="M9777 9864 c-10 -11 3 -24 24 -24 17 0 19 -7 19 -60 0 -47 3 -60 15
-60 12 0 15 13 15 60 0 57 1 60 25 60 16 0 25 6 25 15 0 12 -13 15 -58 15 -32
0 -62 -3 -65 -6z"
                />
                <path
                    d="M9937 9863 c-4 -3 -7 -37 -7 -75 l0 -68 46 0 c61 0 84 14 84 50 0 30
-8 35 -70 42 -19 2 -26 10 -28 31 -3 26 -12 34 -25 20z m91 -95 c2 -12 -5 -19
-23 -24 -31 -8 -50 7 -41 31 8 22 60 16 64 -7z"
                />
                <path
                    d="M10180 9796 c0 -61 3 -76 15 -76 10 0 15 11 15 35 0 32 2 35 30 35
28 0 30 -3 30 -35 0 -28 4 -35 19 -35 16 0 18 8 17 73 -1 57 -4 72 -16 72 -9
0 -16 -11 -18 -27 -3 -23 -8 -28 -32 -28 -24 0 -29 5 -32 27 -2 14 -9 28 -15
31 -10 3 -13 -16 -13 -72z"
                />
                <path
                    d="M10368 9859 c-18 -10 -25 -39 -10 -39 4 0 15 7 25 15 11 7 25 11 33
8 26 -10 14 -31 -22 -40 -45 -10 -64 -35 -48 -60 8 -14 25 -18 68 -19 l56 -1
0 58 c0 39 -5 63 -16 73 -17 18 -60 20 -86 5z m60 -91 c-3 -25 -34 -36 -51
-19 -14 14 9 41 35 41 14 0 19 -6 16 -22z"
                />
                <path
                    d="M10580 9855 c0 -8 9 -15 20 -15 18 0 20 -7 20 -60 0 -47 3 -60 15
-60 12 0 15 13 15 60 0 57 1 60 25 60 16 0 25 6 25 15 0 12 -13 15 -60 15 -47
0 -60 -3 -60 -15z"
                />
                <path
                    d="M10741 9844 c-12 -15 -21 -33 -21 -40 0 -21 20 -68 33 -76 21 -13 74
-9 87 7 18 22 4 31 -24 15 -31 -16 -56 -7 -56 20 0 17 6 20 51 20 51 0 51 0
44 27 -14 56 -79 72 -114 27z m67 -6 c21 -21 13 -38 -18 -38 -18 0 -30 5 -30
13 0 13 19 37 30 37 3 0 11 -5 18 -12z"
                />
                <path
                    d="M10898 9858 c-1 -7 -2 -41 -3 -75 -2 -55 1 -63 17 -63 15 0 18 8 18
60 l0 60 30 0 c20 0 30 5 30 15 0 19 -89 22 -92 3z"
                />
                <path
                    d="M11165 9839 c-91 -35 -93 -54 -10 -86 89 -34 95 -36 95 -21 0 7 -24
22 -52 33 -29 12 -53 25 -53 29 0 5 22 17 49 26 47 15 67 33 49 42 -4 3 -39
-8 -78 -23z"
                />
                <path
                    d="M11281 9844 c-42 -54 -12 -124 52 -124 45 0 66 18 73 62 5 30 2 41
-19 62 -35 35 -79 35 -106 0z m84 -48 c0 -46 -23 -68 -49 -47 -16 14 -22 72
-9 85 4 4 19 6 33 4 22 -3 25 -8 25 -42z"
                />
                <path
                    d="M11652 9850 c-29 -27 -29 -83 -2 -110 23 -23 67 -26 94 -5 25 19 12
33 -14 17 -28 -17 -48 -15 -59 7 -14 26 -5 31 51 31 47 0 49 1 42 23 -17 57
-71 75 -112 37z m73 -30 c0 -9 -11 -16 -27 -18 -23 -3 -28 1 -28 18 0 17 5 21
28 18 16 -2 27 -9 27 -18z"
                />
                <path
                    d="M11800 9795 c0 -60 3 -75 15 -75 11 0 15 12 15 50 0 45 3 52 25 60
14 5 25 16 25 25 0 17 -21 20 -37 4 -8 -8 -13 -8 -17 0 -17 27 -26 5 -26 -64z"
                />
                <path
                    d="M11896 9861 c-9 -14 10 -28 53 -43 25 -8 46 -19 48 -25 2 -5 -17 -16
-41 -23 -43 -12 -75 -35 -64 -45 3 -3 40 7 82 22 101 35 100 53 -2 93 -39 15
-74 25 -76 21z"
                />
                <path
                    d="M12077 9743 c-12 -11 -8 -23 8 -23 18 0 19 -12 3 -28 -8 -8 -8 -12
-1 -12 13 0 23 23 23 51 0 18 -20 26 -33 12z"
                />
                <path
                    d="M8612 9533 c-21 -49 -20 -128 2 -175 25 -52 51 -52 29 -1 -18 44 -14
173 7 198 10 12 10 15 -4 15 -10 0 -24 -16 -34 -37z"
                />
                <path
                    d="M12345 9548 c16 -47 17 -154 1 -191 -17 -41 -6 -59 16 -24 43 70 48
121 18 191 -20 47 -49 67 -35 24z"
                />
                <path
                    d="M5197 9514 c-10 -11 2 -24 24 -24 17 0 19 -7 19 -60 0 -47 3 -60 15
-60 12 0 15 13 15 60 0 58 1 60 26 60 16 0 24 5 22 13 -5 13 -109 23 -121 11z"
                />
                <path
                    d="M5360 9505 c-18 -22 -4 -31 24 -16 26 15 46 8 46 -15 0 -8 -10 -14
-23 -14 -50 0 -84 -49 -55 -78 15 -15 50 -16 65 -1 8 8 13 8 17 0 4 -6 14 -11
22 -11 23 0 19 112 -4 133 -23 21 -75 22 -92 2z m68 -87 c-3 -25 -34 -36 -51
-19 -14 14 9 41 35 41 14 0 19 -6 16 -22z"
                />
                <path
                    d="M5510 9445 c0 -60 3 -75 15 -75 9 0 15 9 15 24 0 39 20 42 47 7 13
-17 33 -31 44 -31 16 0 15 5 -13 45 l-32 45 29 30 c26 27 27 30 9 30 -11 0
-31 -11 -44 -25 -13 -14 -27 -25 -32 -25 -4 0 -8 11 -8 25 0 16 -6 25 -15 25
-12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M5769 9508 c-1 -7 -2 -41 -4 -75 -2 -56 0 -63 16 -63 14 0 19 7 19
30 0 19 5 30 14 30 8 0 23 -13 33 -30 9 -16 25 -30 35 -30 24 0 23 7 -7 46
-30 39 -31 46 -5 69 11 10 20 22 20 27 0 16 -27 7 -50 -17 -29 -31 -38 -31
-42 -3 -3 24 -27 37 -29 16z"
                />
                <path
                    d="M5945 9500 c-18 -19 -17 -20 1 -20 11 0 25 5 30 10 13 13 44 3 44
-14 0 -7 -19 -16 -42 -22 -34 -7 -44 -14 -46 -32 -2 -13 2 -30 8 -38 15 -17
51 -19 67 -3 8 8 13 8 17 0 4 -6 14 -11 23 -11 13 0 15 11 11 66 -3 42 -9 69
-19 75 -25 16 -75 10 -94 -11z m73 -82 c-4 -28 -45 -38 -55 -13 -7 19 12 35
40 35 13 0 18 -6 15 -22z"
                />
                <path
                    d="M6100 9445 c0 -60 3 -75 15 -75 9 0 15 9 15 24 0 40 21 42 50 6 16
-19 33 -31 44 -29 15 4 13 9 -13 44 l-30 40 26 27 c24 25 25 28 8 34 -14 6
-26 -1 -45 -24 -28 -34 -40 -33 -40 5 0 14 -6 23 -15 23 -12 0 -15 -15 -15
-75z"
                />
                <path
                    d="M6360 9445 l0 -75 49 0 c32 0 55 6 65 16 18 17 21 40 6 49 -5 3 -7
19 -4 34 7 36 -15 51 -75 51 l-41 0 0 -75z m88 33 c3 -14 -3 -18 -27 -18 -25
0 -31 4 -31 21 0 16 5 20 27 17 17 -2 29 -10 31 -20z m0 -60 c-2 -17 -10 -23
-28 -23 -18 0 -26 6 -28 23 -3 19 1 22 28 22 27 0 31 -3 28 -22z"
                />
                <path
                    d="M6540 9500 c-12 -12 -20 -33 -20 -55 0 -65 74 -101 115 -55 25 28 11
38 -25 17 -26 -16 -30 -16 -44 -1 -17 17 -19 27 -10 62 7 25 33 29 64 10 27
-17 37 -2 15 22 -23 26 -69 26 -95 0z"
                />
                <path
                    d="M6700 9500 c-29 -29 -27 -94 2 -114 29 -21 72 -20 93 0 22 23 11 29
-25 14 -24 -10 -33 -10 -45 0 -30 25 -17 40 35 40 46 0 50 2 50 23 0 52 -71
76 -110 37z m70 -15 c16 -19 4 -35 -25 -35 -13 0 -27 5 -30 10 -8 13 13 40 30
40 7 0 18 -7 25 -15z"
                />
                <path
                    d="M6930 9445 c0 -60 3 -75 15 -75 12 0 15 13 15 60 l0 60 35 0 35 0 0
-60 c0 -47 3 -60 15 -60 12 0 15 15 15 75 l0 75 -65 0 -65 0 0 -75z"
                />
                <path
                    d="M7114 9506 c-26 -19 -10 -33 20 -17 26 14 46 8 46 -13 0 -8 -15 -17
-34 -20 -46 -9 -62 -29 -48 -61 10 -21 17 -23 66 -22 l56 1 0 61 c0 34 -5 66
-12 73 -16 16 -70 15 -94 -2z m71 -76 c9 -15 -14 -40 -36 -40 -24 0 -33 18
-19 35 14 17 46 20 55 5z"
                />
                <path
                    d="M7267 9513 c-12 -11 -8 -191 4 -199 15 -9 29 15 29 51 0 22 3 26 12
17 34 -34 88 2 88 59 0 61 -53 102 -87 68 -8 -8 -13 -8 -17 0 -8 12 -20 14
-29 4z m93 -44 c23 -42 -13 -95 -46 -68 -14 12 -19 70 -7 82 13 14 43 6 53
-14z"
                />
                <path
                    d="M7445 9500 c-18 -19 -17 -20 1 -20 11 0 25 5 31 11 15 15 43 4 43
-15 0 -9 -15 -17 -40 -21 -31 -5 -41 -12 -46 -31 -10 -38 10 -52 71 -52 l53 1
-2 58 c-2 32 -9 65 -16 74 -18 22 -73 19 -95 -5z m73 -82 c-3 -25 -34 -36 -51
-19 -14 14 9 41 35 41 14 0 19 -6 16 -22z"
                />
                <path
                    d="M7600 9446 c0 -61 3 -76 15 -76 12 0 15 13 15 57 0 65 7 64 24 -4 13
-52 46 -74 46 -31 1 13 7 37 15 53 l13 30 1 -52 c1 -40 5 -53 16 -53 12 0 15
15 15 75 0 73 -1 75 -24 75 -21 0 -26 -7 -36 -50 -7 -27 -16 -50 -20 -50 -4 0
-12 21 -16 48 -8 41 -12 47 -37 50 l-27 3 0 -75z"
                />
                <path
                    d="M7820 9500 c-12 -12 -20 -33 -20 -55 0 -63 66 -97 116 -61 27 21 10
31 -26 16 -27 -11 -34 -11 -47 3 -23 23 -7 37 43 37 40 0 44 2 44 23 0 52 -71
76 -110 37z m70 -15 c16 -19 4 -35 -25 -35 -31 0 -39 14 -19 34 19 20 28 20
44 1z"
                />
                <path
                    d="M7957 9514 c-10 -11 2 -24 24 -24 17 0 19 -7 19 -60 0 -47 3 -60 15
-60 12 0 15 13 15 60 0 58 1 60 26 60 16 0 24 5 22 13 -5 13 -109 23 -121 11z"
                />
                <path
                    d="M8110 9415 c0 -87 3 -105 15 -105 10 0 15 10 15 34 0 34 1 35 34 29
43 -7 65 12 73 62 7 47 -17 85 -55 85 -16 0 -33 -5 -39 -11 -8 -8 -13 -8 -17
0 -19 29 -26 2 -26 -94z m98 35 c3 -23 -2 -38 -14 -47 -25 -18 -54 1 -54 35 0
34 18 54 45 50 15 -2 21 -12 23 -38z"
                />
                <path
                    d="M8290 9445 l0 -75 45 0 c56 0 89 27 76 62 -6 17 -18 24 -47 28 -34 4
-39 9 -42 33 -2 15 -9 27 -18 27 -11 0 -14 -17 -14 -75z m90 -24 c0 -20 -32
-37 -50 -26 -5 3 -10 15 -10 26 0 14 7 19 30 19 23 0 30 -5 30 -19z"
                />
                <path
                    d="M8440 9445 c0 -60 3 -75 15 -75 12 0 15 15 15 75 0 60 -3 75 -15 75
-12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M8696 9511 c-4 -6 -6 -40 -5 -76 l2 -65 59 0 c43 0 60 -4 64 -15 10
-25 24 -17 24 15 0 17 -4 30 -10 30 -6 0 -10 27 -10 60 0 47 -3 60 -15 60 -12
0 -15 -13 -15 -60 0 -60 0 -60 -30 -60 -28 0 -29 1 -32 57 -3 53 -18 78 -32
54z"
                />
                <path
                    d="M8880 9500 c-29 -29 -27 -94 2 -114 31 -22 71 -20 93 4 16 18 17 20
1 20 -9 0 -25 -5 -35 -12 -15 -9 -23 -9 -35 1 -31 26 -19 41 34 41 43 0 50 3
50 20 0 28 -35 60 -65 60 -14 0 -34 -9 -45 -20z m70 -15 c16 -20 4 -36 -25
-32 -33 4 -40 14 -25 32 16 19 34 19 50 0z"
                />
                <path
                    d="M9030 9445 c0 -59 3 -75 14 -75 9 0 16 12 18 33 3 28 6 32 33 32 27
0 30 -4 33 -32 2 -21 9 -33 18 -33 11 0 14 16 14 75 0 58 -3 75 -14 75 -9 0
-16 -12 -18 -27 -3 -24 -8 -28 -33 -28 -25 0 -30 4 -33 28 -2 15 -9 27 -18 27
-11 0 -14 -17 -14 -75z"
                />
                <path
                    d="M9216 9505 c-28 -21 -12 -30 25 -15 26 11 32 11 39 0 12 -20 5 -27
-34 -34 -46 -9 -62 -29 -48 -61 12 -27 40 -32 73 -15 13 7 19 7 19 0 0 -5 8
-10 18 -10 15 0 17 8 14 62 -4 69 -17 88 -61 88 -14 0 -35 -7 -45 -15z m74
-71 c0 -18 -23 -44 -40 -44 -27 0 -36 21 -15 37 19 13 55 18 55 7z"
                />
                <path
                    d="M9528 9445 c-3 -66 -1 -75 15 -75 13 0 17 8 17 36 0 34 1 35 33 32
28 -3 32 -7 35 -35 2 -21 9 -33 18 -33 11 0 14 16 14 75 0 58 -3 75 -14 75 -9
0 -16 -12 -18 -27 -2 -23 -8 -29 -35 -31 -31 -3 -33 -1 -33 27 0 20 -5 31 -14
31 -11 0 -15 -18 -18 -75z"
                />
                <path
                    d="M9714 9506 c-24 -18 -10 -34 16 -18 22 14 50 6 50 -14 0 -8 -10 -14
-23 -14 -50 0 -84 -49 -55 -78 15 -15 55 -16 64 -1 4 8 9 8 17 0 6 -6 17 -11
24 -11 20 0 18 114 -3 134 -19 20 -64 21 -90 2z m64 -88 c-3 -25 -34 -36 -51
-19 -14 14 9 41 35 41 14 0 19 -6 16 -22z"
                />
                <path
                    d="M9867 9513 c-4 -3 -7 -37 -7 -75 0 -60 2 -68 19 -68 12 0 30 17 48
48 l28 47 3 -47 c2 -34 7 -48 18 -48 11 0 14 16 14 75 0 91 -15 99 -60 28 -21
-32 -31 -42 -35 -31 -3 8 -5 19 -4 24 7 38 -7 65 -24 47z"
                />
                <path
                    d="M10040 9445 c0 -60 3 -75 15 -75 11 0 15 13 16 58 l1 57 15 -58 c19
-73 41 -74 59 -4 17 68 24 69 24 4 0 -44 3 -57 15 -57 12 0 15 15 15 76 l0 75
-27 -3 c-25 -3 -29 -9 -37 -50 -4 -27 -12 -48 -16 -48 -4 0 -13 23 -20 50 -10
43 -15 50 -36 50 -23 0 -24 -2 -24 -75z"
                />
                <path
                    d="M10257 9502 c-23 -26 -22 -94 1 -115 25 -23 75 -21 97 3 16 18 17 20
1 20 -9 0 -25 -5 -35 -12 -15 -9 -23 -9 -35 1 -31 26 -19 41 34 41 43 0 50 3
50 20 0 50 -79 79 -113 42z m67 -18 c20 -20 13 -31 -19 -31 -31 0 -41 13 -25
32 16 19 25 19 44 -1z"
                />
                <path
                    d="M10407 9514 c-4 -4 -7 -38 -7 -76 0 -61 2 -69 18 -66 11 2 17 13 17
33 0 27 4 30 33 33 31 3 32 2 32 -32 0 -25 4 -36 15 -36 12 0 15 15 15 75 0
60 -3 75 -15 75 -10 0 -15 -10 -15 -30 0 -27 -3 -30 -29 -30 -25 0 -30 5 -33
27 -3 26 -18 39 -31 27z"
                />
                <path
                    d="M10595 9500 c-11 -12 -19 -36 -19 -55 0 -44 28 -75 69 -75 40 0 75
35 75 76 0 63 -85 99 -125 54z m85 -31 c24 -44 -13 -90 -54 -67 -16 9 -23 68
-9 81 14 15 53 6 63 -14z"
                />
                <path
                    d="M10760 9445 l0 -75 38 0 c20 0 47 3 59 6 26 7 39 39 24 58 -5 6 -7
23 -4 36 8 31 -19 50 -74 50 l-43 0 0 -75z m74 49 c28 -11 18 -34 -14 -34 -23
0 -30 4 -30 20 0 20 15 25 44 14z m11 -79 c0 -14 -8 -21 -27 -23 -24 -3 -28 0
-28 23 0 23 4 26 28 23 19 -2 27 -9 27 -23z"
                />
                <path
                    d="M10936 9504 c-24 -24 -8 -38 21 -19 26 18 57 13 51 -7 -3 -7 -22 -18
-44 -24 -36 -10 -39 -14 -39 -45 0 -30 3 -34 28 -37 16 -2 35 2 43 9 10 8 14
8 14 0 0 -6 8 -11 18 -11 15 0 17 8 14 62 -2 35 -9 69 -14 76 -15 17 -74 15
-92 -4z m74 -77 c0 -19 -21 -37 -42 -37 -18 0 -25 29 -11 43 12 12 53 7 53 -6z"
                />
                <path
                    d="M11090 9445 c0 -60 3 -75 15 -75 11 0 15 11 15 36 0 34 1 35 33 32
28 -3 32 -7 35 -35 2 -21 9 -33 18 -33 12 0 14 14 12 72 -2 43 -8 73 -15 75
-7 3 -13 -8 -15 -24 -2 -23 -8 -29 -35 -31 -31 -3 -33 -1 -33 27 0 21 -5 31
-15 31 -12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M11260 9445 c0 -68 2 -75 20 -75 13 0 27 14 40 39 29 58 40 59 40 7
0 -34 4 -46 15 -46 12 0 15 15 15 75 0 91 -15 99 -58 30 -32 -51 -42 -49 -42
7 0 27 -4 38 -15 38 -12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M11452 9500 c-29 -27 -29 -83 -2 -110 23 -23 67 -26 94 -5 26 20 12
31 -20 17 -18 -9 -31 -9 -45 -1 -32 17 -16 34 34 37 41 3 47 6 47 26 0 51 -67
73 -108 36z m68 -15 c17 -20 4 -34 -28 -30 -27 3 -32 22 -9 37 17 11 23 10 37
-7z"
                />
                <path
                    d="M11680 9445 c0 -66 2 -75 18 -75 11 0 27 16 41 40 12 22 26 40 32 40
5 0 9 -18 9 -40 0 -29 4 -40 15 -40 12 0 15 15 15 76 0 66 -2 75 -17 72 -10
-2 -31 -23 -48 -48 l-30 -45 -3 48 c-2 33 -7 47 -18 47 -11 0 -14 -16 -14 -75z"
                />
                <path
                    d="M11920 9505 c0 -8 9 -15 20 -15 16 0 20 -7 20 -37 0 -53 10 -83 26
-83 10 0 14 16 14 60 0 53 2 60 20 60 11 0 20 7 20 15 0 12 -13 15 -60 15 -47
0 -60 -3 -60 -15z"
                />
                <path
                    d="M12187 9513 c-4 -3 -7 -20 -7 -37 0 -17 -7 -44 -15 -60 -18 -33 -20
-72 -4 -81 6 -4 13 2 16 14 4 18 13 21 54 21 37 0 49 -4 49 -15 0 -8 7 -15 15
-15 10 0 15 10 15 30 0 17 -4 30 -10 30 -6 0 -10 27 -10 60 l0 60 -48 0 c-27
0 -52 -3 -55 -7z m73 -68 c0 -44 -1 -45 -31 -45 -30 0 -31 2 -25 31 3 17 6 38
6 45 0 8 10 14 25 14 23 0 25 -4 25 -45z"
                />
                <path
                    d="M9364 9395 c-4 -10 -1 -21 6 -25 7 -5 10 -15 6 -25 -5 -15 -4 -16 9
-5 18 15 16 61 -2 68 -7 2 -16 -4 -19 -13z"
                />
                <path
                    d="M12437 9390 c-3 -13 0 -20 9 -20 17 0 18 -12 2 -28 -8 -8 -8 -12 -1
-12 13 0 23 23 23 56 0 29 -25 32 -33 4z"
                />
                <path
                    d="M9102 9218 c7 -19 53 -21 68 -4 15 19 0 22 -18 4 -9 -9 -15 -9 -24 0
-16 16 -31 15 -26 0z"
                />
                <path
                    d="M11140 9221 c0 -20 64 -29 75 -10 10 15 -3 24 -18 11 -8 -7 -18 -7
-27 -2 -19 12 -30 12 -30 1z"
                />
                <path
                    d="M6120 9148 c-27 -30 -26 -79 3 -106 60 -57 146 5 117 84 -14 35 -26
44 -67 44 -20 0 -40 -8 -53 -22z m88 -56 c-3 -34 -6 -37 -33 -37 -27 0 -30 4
-33 35 -4 42 20 65 50 49 15 -8 18 -19 16 -47z"
                />
                <path
                    d="M6270 9156 c0 -8 10 -16 23 -18 20 -3 22 -9 23 -58 1 -43 5 -56 18
-58 13 -3 16 6 16 57 0 54 2 60 22 63 12 2 23 9 26 16 3 9 -14 12 -62 12 -51
0 -66 -3 -66 -14z"
                />
                <path
                    d="M6430 9095 c0 -60 3 -75 15 -75 10 0 15 11 15 35 0 32 2 35 29 35 26
0 30 -4 33 -32 2 -22 9 -34 21 -36 15 -3 17 6 17 73 0 67 -2 76 -17 73 -11 -2
-19 -14 -21 -31 -3 -23 -8 -27 -33 -27 -26 0 -29 3 -29 30 0 20 -5 30 -15 30
-12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M6620 9150 c-60 -60 14 -160 90 -120 25 13 41 65 30 95 -18 46 -85
60 -120 25z m88 -52 c2 -28 -1 -39 -16 -47 -24 -13 -62 11 -62 39 0 38 12 51
44 48 28 -3 31 -7 34 -40z"
                />
                <path
                    d="M6797 9157 c-10 -6 -20 -28 -23 -47 -12 -70 66 -119 111 -70 23 26
12 37 -19 20 -36 -19 -56 -7 -56 34 0 42 30 66 50 41 14 -17 40 -20 40 -6 0
35 -69 53 -103 28z"
                />
                <path
                    d="M6958 9159 c-21 -12 -24 -55 -5 -62 10 -4 10 -7 0 -18 -17 -18 -16
-59 1 -59 7 0 16 14 20 30 5 25 10 30 36 30 27 0 30 -3 30 -30 0 -20 5 -30 15
-30 12 0 15 15 15 75 l0 75 -47 0 c-27 0 -56 -5 -65 -11z m80 -37 c3 -19 -1
-22 -27 -22 -37 0 -50 19 -25 37 26 19 48 12 52 -15z"
                />
                <path
                    d="M7120 9095 l0 -75 90 0 c73 0 90 -3 90 -15 0 -8 7 -15 15 -15 10 0
15 10 15 30 0 17 -4 30 -10 30 -6 0 -10 27 -10 60 0 47 -3 60 -15 60 -12 0
-15 -13 -15 -60 0 -57 -1 -60 -25 -60 -24 0 -25 3 -25 60 0 46 -3 60 -15 60
-10 0 -15 -15 -17 -57 -3 -51 -6 -58 -25 -61 -22 -3 -23 0 -23 57 0 48 -3 61
-15 61 -12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M7359 9128 c0 -23 -2 -52 -4 -63 -6 -27 10 -49 29 -42 8 3 25 25 38
49 l23 43 3 -47 c3 -40 6 -48 23 -48 18 0 20 6 17 72 -2 59 -6 73 -20 76 -11
2 -25 -11 -40 -37 -13 -23 -26 -41 -30 -41 -4 0 -8 17 -10 37 -4 54 -28 55
-29 1z"
                />
                <path
                    d="M7540 9150 c-29 -29 -27 -94 2 -114 29 -20 72 -20 92 0 24 23 20 26
-25 19 -30 -5 -44 -3 -50 6 -13 21 -1 29 47 29 40 0 44 2 44 23 0 52 -71 76
-110 37z m68 -12 c7 -7 12 -15 12 -19 0 -14 -32 -19 -51 -8 -16 8 -18 13 -8
25 14 17 31 18 47 2z"
                />
                <path
                    d="M7700 9148 c-27 -30 -26 -79 3 -106 29 -27 68 -28 92 -2 23 26 12 37
-19 20 -14 -7 -32 -10 -40 -7 -20 8 -21 70 -2 86 14 12 46 5 46 -10 0 -5 7 -9
15 -9 19 0 19 14 -1 34 -22 23 -70 20 -94 -6z"
                />
                <path
                    d="M7865 9157 c-14 -11 -16 -20 -10 -43 5 -16 8 -31 7 -34 0 -3 -4 -18
-7 -33 -6 -21 -4 -27 8 -27 9 0 18 13 22 30 5 25 11 30 36 30 26 0 29 -4 29
-30 0 -20 5 -30 15 -30 12 0 15 15 15 75 l0 75 -48 0 c-27 0 -56 -6 -67 -13z
m83 -35 c3 -17 -1 -22 -20 -22 -13 0 -30 5 -38 10 -13 9 -12 12 5 25 26 21 49
15 53 -13z"
                />
                <path
                    d="M8110 9095 c0 -60 3 -75 15 -75 10 0 15 10 15 30 0 19 5 30 14 30 8
0 23 -13 33 -30 9 -16 25 -30 35 -30 24 0 23 7 -7 46 -31 41 -31 42 2 76 25
26 25 28 7 28 -11 0 -31 -11 -44 -25 -13 -14 -27 -25 -32 -25 -4 0 -8 11 -8
25 0 16 -6 25 -15 25 -12 0 -15 -15 -15 -75z"
                />
                <path
                    d="M8379 9151 c-31 -25 -30 -88 1 -113 49 -39 120 -5 120 58 0 63 -71
96 -121 55z m87 -38 c9 -32 -6 -57 -36 -61 -26 -4 -40 12 -40 49 0 33 7 39 41
39 23 0 30 -6 35 -27z"
                />
                <path
                    d="M8557 9119 c-4 -29 -13 -59 -22 -69 -19 -22 -20 -60 0 -60 8 0 15 7
15 15 0 12 13 15 55 15 42 0 55 -3 55 -15 0 -8 7 -15 15 -15 10 0 15 10 15 30
0 17 -4 30 -10 30 -6 0 -10 27 -10 60 l0 60 -54 0 -53 0 -6 -51z m83 -24 c0
-43 -1 -45 -30 -45 -32 0 -34 6 -24 59 5 24 12 31 30 31 22 0 24 -4 24 -45z"
                />
                <path
                    d="M8720 9095 c0 -59 3 -75 14 -75 9 0 16 12 18 33 3 28 6 32 33 32 27
0 30 -4 33 -32 2 -21 9 -33 18 -33 11 0 14 16 14 75 0 58 -3 75 -14 75 -9 0
-16 -12 -18 -27 -3 -24 -8 -28 -33 -28 -25 0 -30 4 -33 28 -2 15 -9 27 -18 27
-11 0 -14 -17 -14 -75z"
                />
                <path
                    d="M8924 9162 c-16 -10 -34 -48 -34 -70 0 -11 9 -31 21 -46 55 -70 160
7 118 87 -16 29 -28 37 -64 37 -16 0 -35 -4 -41 -8z m74 -70 c-3 -34 -6 -37
-33 -37 -27 0 -30 4 -33 35 -4 42 20 65 50 49 15 -8 18 -19 16 -47z"
                />
                <path
                    d="M9070 9096 c0 -68 2 -76 19 -76 12 0 30 17 48 48 l28 47 3 -48 c3
-38 7 -48 19 -45 9 2 16 6 15 11 -1 4 -2 36 -2 72 0 80 -15 85 -56 21 -32 -51
-47 -50 -42 2 2 29 -1 38 -15 40 -15 3 -17 -6 -17 -72z"
                />
                <path
                    d="M9330 9095 c0 -60 3 -75 15 -75 12 0 15 13 15 60 l0 60 35 0 35 0 0
-60 c0 -47 3 -60 15 -60 12 0 15 15 15 75 l0 75 -65 0 -65 0 0 -75z"
                />
                <path
                    d="M9520 9150 c-45 -45 -13 -130 50 -130 41 0 70 31 70 75 0 44 -29 75
-70 75 -17 0 -39 -9 -50 -20z m86 -34 c10 -41 -4 -68 -33 -64 -33 4 -48 27
-39 61 5 22 12 27 36 27 23 0 31 -5 36 -24z"
                />
                <path
                    d="M9693 9163 c-7 -2 -13 -11 -13 -19 0 -17 5 -17 29 -2 20 12 51 5 51
-13 0 -6 -9 -13 -20 -16 -11 -3 -20 -9 -20 -13 0 -4 9 -10 20 -13 24 -6 26
-35 4 -44 -9 -3 -25 -1 -35 5 -30 19 -44 14 -24 -8 20 -22 74 -27 93 -8 14 14
16 54 2 63 -6 4 -5 13 2 25 23 36 -37 65 -89 43z"
                />
                <path
                    d="M9830 9095 c0 -66 2 -75 18 -75 12 0 27 17 43 48 l24 47 3 -48 c3
-39 6 -48 20 -45 14 3 17 15 17 73 0 90 -19 98 -61 28 l-29 -48 -3 48 c-2 33
-7 47 -18 47 -11 0 -14 -16 -14 -75z"
                />
                <path
                    d="M10000 9095 l0 -75 60 0 c47 0 60 -3 60 -15 0 -8 7 -15 15 -15 10 0
15 10 15 30 0 17 -4 30 -10 30 -6 0 -10 27 -10 60 0 47 -3 60 -15 60 -12 0
-15 -13 -15 -61 l0 -60 -32 3 c-33 3 -33 4 -36 61 -2 42 -7 57 -17 57 -12 0
-15 -16 -15 -75z"
                />
                <path
                    d="M10180 9095 c0 -66 2 -75 18 -75 11 0 27 17 42 48 l24 47 6 -45 c3
-31 10 -45 20 -45 23 0 22 138 -2 143 -11 2 -26 -13 -45 -45 l-28 -48 -3 48
c-2 33 -7 47 -18 47 -11 0 -14 -16 -14 -75z"
                />
                <path
                    d="M10357 9163 c-4 -3 -7 -37 -7 -75 0 -58 3 -68 18 -68 9 0 30 20 47
48 l30 47 3 -47 c2 -34 7 -48 18 -48 11 0 14 16 14 75 0 91 -14 97 -62 28
l-33 -48 -3 48 c-3 44 -10 56 -25 40z"
                />
                <path
                    d="M10610 9095 c0 -60 3 -75 15 -75 12 0 15 13 15 60 l0 60 35 0 35 0 0
-60 c0 -47 3 -60 15 -60 12 0 15 15 15 75 l0 75 -65 0 -65 0 0 -75z"
                />
                <path
                    d="M10782 9068 c2 -60 7 -103 13 -103 6 0 11 15 13 33 3 31 4 32 41 26
33 -5 41 -3 55 17 22 32 21 92 -2 112 -22 20 -63 22 -80 5 -9 -9 -12 -9 -12 0
0 7 -7 12 -15 12 -13 0 -15 -16 -13 -102z m98 52 c23 -43 -23 -96 -54 -64 -17
16 -20 41 -10 68 9 23 51 20 64 -4z"
                />
                <path
                    d="M10970 9155 c-18 -22 -4 -31 24 -16 26 15 46 8 46 -15 0 -8 -10 -14
-23 -14 -50 0 -86 -52 -54 -78 6 -5 36 -10 65 -9 l52 1 0 54 c0 74 -12 92 -59
92 -22 0 -44 -6 -51 -15z m70 -84 c0 -23 -37 -38 -53 -22 -7 7 -6 15 3 26 18
22 50 19 50 -4z"
                />
                <path
                    d="M11120 9095 c0 -65 2 -75 18 -75 10 0 27 18 42 45 13 24 26 42 29 40
3 -4 6 -21 10 -72 1 -7 8 -13 16 -13 12 0 15 15 15 75 0 68 -2 75 -20 75 -13
0 -27 -14 -40 -39 -29 -58 -40 -59 -40 -7 0 34 -4 46 -15 46 -12 0 -15 -15
-15 -75z"
                />
                <path
                    d="M11306 9148 c-22 -31 -20 -91 2 -111 25 -22 75 -21 95 1 22 24 21 32
-3 32 -11 0 -20 -4 -20 -10 0 -20 -48 -11 -55 11 -16 50 21 94 55 64 18 -16
40 -20 40 -7 0 19 -38 42 -68 42 -21 0 -36 -7 -46 -22z"
                />
                <path
                    d="M11464 9156 c-25 -18 -10 -34 17 -18 23 14 62 6 56 -11 -2 -6 -19
-15 -38 -20 -50 -12 -65 -31 -51 -61 9 -21 17 -24 69 -23 l58 0 -3 61 c-2 34
-9 67 -14 74 -14 16 -70 15 -94 -2z m76 -79 c0 -16 -20 -37 -34 -37 -6 0 -17
4 -25 9 -12 8 -12 12 -2 25 16 19 61 21 61 3z"
                />
                <path
                    d="M10418 8869 c-10 -5 -18 -16 -18 -23 0 -8 -7 -19 -16 -27 -14 -11
-14 -13 1 -22 12 -6 15 -22 14 -63 -1 -30 0 -57 4 -60 15 -16 27 13 27 66 0
37 4 60 13 63 9 4 8 8 -3 16 -12 8 -12 13 -2 23 13 13 16 38 5 38 -5 0 -16 -5
-25 -11z"
                />
                <path
                    d="M10518 8873 c-9 -2 -20 -14 -24 -26 -4 -12 -12 -25 -18 -29 -8 -5 -7
-11 2 -20 7 -7 12 -37 12 -70 0 -45 3 -58 15 -58 12 0 15 13 15 60 0 45 4 62
15 66 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 5 -15 10 0 6 7 13 15 16 8 4
15 12 15 20 0 14 -3 15 -32 7z"
                />
                <path
                    d="M7082 8822 c-27 -4 -52 -42 -27 -42 8 0 24 6 35 12 15 9 22 10 31 1
17 -17 2 -33 -30 -33 -33 0 -51 -16 -51 -46 0 -34 26 -50 61 -37 17 7 31 7 35
2 3 -5 11 -9 18 -9 14 0 10 117 -6 136 -11 15 -36 21 -66 16z m46 -98 c-5 -25
-35 -41 -49 -27 -18 18 -3 43 26 43 19 0 26 -5 23 -16z"
                />
                <path
                    d="M7399 8812 c-29 -15 -34 -23 -37 -59 -4 -49 25 -83 69 -83 74 0 96
110 29 145 -24 12 -31 12 -61 -3z m56 -28 c34 -35 -3 -103 -42 -78 -34 21 -24
94 13 94 7 0 20 -7 29 -16z"
                />
                <path
                    d="M8095 8822 c-37 -8 -55 -33 -55 -77 0 -70 64 -101 112 -53 26 26 9
37 -27 18 -13 -7 -31 -10 -39 -7 -20 8 -21 70 -2 86 14 12 46 5 46 -10 0 -5 9
-9 20 -9 18 0 19 3 10 20 -13 25 -39 38 -65 32z"
                />
                <path
                    d="M9175 8820 c-11 -5 -30 -6 -43 -3 -23 5 -23 5 -20 -96 2 -57 7 -102
13 -104 6 -1 11 9 13 23 5 44 11 55 24 42 18 -18 46 -14 68 8 49 49 5 153 -55
130z m35 -46 c21 -24 1 -69 -30 -69 -18 0 -27 8 -34 27 -11 31 5 58 34 58 10
0 23 -7 30 -16z"
                />
                <path
                    d="M10008 8818 c-14 -5 -29 -16 -32 -24 -7 -17 8 -18 33 -2 22 13 55 4
49 -15 -3 -6 -18 -14 -34 -15 -39 -5 -54 -19 -54 -53 0 -22 6 -30 24 -35 13
-3 33 -1 44 5 14 7 23 8 27 1 16 -25 25 -5 25 54 0 39 -5 67 -12 73 -22 17
-42 20 -70 11z m46 -106 c-6 -9 -21 -18 -33 -20 -30 -5 -29 36 2 47 29 11 45
-3 31 -27z"
                />
                <path
                    d="M6870 8744 c0 -56 3 -75 13 -72 6 3 13 19 15 37 3 30 6 32 35 29 28
-3 32 -7 35 -35 2 -21 9 -33 18 -33 11 0 14 16 14 75 0 57 -3 75 -14 75 -7 0
-16 -14 -20 -31 -5 -26 -10 -30 -33 -27 -22 2 -29 9 -31 31 -2 15 -9 27 -18
27 -11 0 -14 -17 -14 -76z"
                />
                <path
                    d="M7222 8782 l22 -39 -22 -30 c-26 -37 -27 -43 -7 -43 8 0 20 9 27 20
7 11 18 20 25 20 6 0 15 -9 18 -20 4 -11 15 -20 26 -20 20 0 20 1 -2 39 l-22
39 22 36 c22 36 22 36 2 36 -11 0 -26 -10 -32 -22 l-12 -22 -15 22 c-8 12 -23
22 -34 22 -18 0 -18 -2 4 -38z"
                />
                <path
                    d="M7561 8760 c-7 -33 -16 -60 -22 -60 -5 0 -9 -13 -9 -30 0 -20 5 -30
15 -30 8 0 15 7 15 15 0 12 13 15 55 15 42 0 55 -3 55 -15 0 -8 7 -15 15 -15
10 0 15 10 15 30 0 17 -4 30 -10 30 -6 0 -10 27 -10 60 l0 60 -54 0 -53 0 -12
-60z m87 -14 c-3 -38 -5 -41 -35 -44 l-31 -3 5 43 c7 48 16 58 45 50 16 -4 18
-12 16 -46z"
                />
                <path
                    d="M7742 8812 c-7 -5 -13 -17 -12 -28 0 -10 0 -41 0 -67 -1 -36 3 -48
12 -45 7 3 15 15 18 29 4 17 14 25 32 27 23 3 27 -1 30 -25 2 -17 9 -28 18
-28 12 0 15 14 15 70 l0 70 -50 3 c-27 1 -56 -2 -63 -6z m78 -37 c0 -20 -5
-25 -24 -25 -14 0 -28 5 -31 10 -12 19 4 40 30 40 20 0 25 -5 25 -25z"
                />
                <path
                    d="M7890 8805 c0 -8 3 -14 8 -14 27 5 32 -4 32 -62 0 -52 2 -60 18 -57
13 2 18 17 22 66 3 34 8 62 13 62 23 0 27 1 27 10 0 6 -27 10 -60 10 -47 0
-60 -3 -60 -15z"
                />
                <path
                    d="M8216 8805 c-18 -19 -20 -37 -6 -51 8 -8 7 -19 0 -39 -7 -20 -8 -31
0 -39 12 -12 27 1 32 29 2 13 12 21 30 23 23 3 27 -1 30 -25 2 -17 9 -28 18
-28 12 0 15 14 15 70 l0 70 -51 3 c-37 2 -56 -2 -68 -13z m84 -30 c0 -20 -5
-25 -24 -25 -14 0 -28 5 -31 10 -12 19 4 40 30 40 20 0 25 -5 25 -25z"
                />
                <path
                    d="M8460 8745 l0 -75 44 0 c60 0 95 28 79 62 -7 15 -7 30 -2 40 15 27
-16 48 -72 48 l-49 0 0 -75z m85 35 c0 -9 -11 -16 -27 -18 -23 -3 -28 1 -28
18 0 17 5 21 28 18 16 -2 27 -9 27 -18z m8 -54 c8 -19 -1 -26 -35 -26 -21 0
-28 5 -28 20 0 15 7 20 29 20 16 0 31 -6 34 -14z"
                />
                <path
                    d="M8632 8748 c3 -75 24 -108 30 -46 3 29 7 33 36 36 31 3 32 2 32 -32
0 -25 4 -36 15 -36 12 0 15 15 15 75 0 58 -3 75 -14 75 -9 0 -16 -12 -18 -27
-3 -24 -8 -28 -33 -28 -25 0 -30 4 -33 28 -2 16 -9 27 -18 27 -12 0 -14 -14
-12 -72z"
                />
                <path
                    d="M8800 8812 c0 -4 11 -41 24 -80 22 -62 23 -74 10 -86 -15 -16 -19
-36 -6 -36 24 0 47 35 73 110 33 96 34 100 15 100 -14 0 -36 -49 -36 -81 0
-36 -19 -19 -33 29 -9 28 -21 48 -31 50 -9 2 -16 -1 -16 -6z"
                />
                <path
                    d="M8955 8810 c-6 -10 -2 -12 25 -14 11 -1 16 -16 18 -63 2 -47 7 -63
17 -63 12 0 15 14 15 59 0 54 2 60 22 63 12 2 23 9 26 16 5 15 -113 17 -123 2z"
                />
                <path
                    d="M9290 8745 c0 -58 3 -75 14 -75 15 0 45 38 61 75 7 16 10 11 14 -25
4 -32 11 -46 24 -48 16 -3 18 4 15 70 -4 92 -22 101 -64 31 l-29 -48 -3 48
c-2 33 -7 47 -18 47 -11 0 -14 -16 -14 -75z"
                />
                <path
                    d="M9530 8811 c0 -5 11 -12 25 -15 23 -6 25 -11 25 -67 0 -80 24 -77 28
4 3 48 6 56 24 59 12 2 23 9 26 16 3 9 -14 12 -62 12 -36 0 -66 -4 -66 -9z"
                />
                <path
                    d="M9697 8802 c-24 -26 -22 -83 3 -110 26 -28 71 -28 95 -2 18 19 17 20
-1 20 -11 0 -24 -4 -29 -9 -14 -13 -55 5 -55 24 0 11 12 15 50 15 55 0 64 15
34 58 -19 28 -74 30 -97 4z m73 -22 c10 -18 8 -20 -25 -20 -37 0 -43 8 -23 28
18 18 36 15 48 -8z"
                />
                <path
                    d="M9850 8745 c0 -59 3 -75 15 -75 10 0 15 16 17 63 3 54 6 62 23 63 38
2 45 4 45 14 0 6 -22 10 -50 10 l-50 0 0 -75z"
                />
                <path
                    d="M10226 8798 c-23 -33 -20 -83 6 -108 47 -43 118 -12 118 53 0 51 -24
77 -70 77 -29 0 -43 -6 -54 -22z m78 -14 c17 -16 20 -41 10 -68 -7 -19 -45
-21 -62 -4 -18 18 -15 65 6 77 23 14 28 14 46 -5z"
                />
                <path
                    d="M10584 8795 c-20 -31 -13 -94 13 -112 25 -19 68 -16 88 7 18 19 17
20 -3 20 -11 0 -23 -4 -27 -10 -10 -17 -54 9 -55 33 0 4 25 7 55 7 58 0 63 6
44 43 -16 30 -28 37 -66 37 -24 0 -37 -7 -49 -25z m74 -7 c19 -19 14 -28 -18
-28 -18 0 -30 5 -30 13 0 28 27 36 48 15z"
                />
                <path
                    d="M10740 8751 c0 -63 11 -93 27 -77 3 3 8 29 11 57 4 39 9 53 23 56 11
3 19 12 19 19 0 17 -12 18 -27 3 -8 -8 -13 -8 -17 0 -4 6 -14 11 -22 11 -11 0
-14 -16 -14 -69z"
                />
                <path
                    d="M2690 9470 c0 -72 4 -109 10 -105 6 3 10 50 10 105 0 55 -4 102 -10
105 -6 4 -10 -33 -10 -105z"
                />
                <path d="M2280 9554 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M2760 9555 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"
                />
                <path
                    d="M2982 9532 c-8 -17 -9 -28 -2 -30 5 -2 10 -28 10 -57 0 -35 5 -59 16
-69 20 -20 44 -21 44 -1 0 8 -7 15 -15 15 -12 0 -15 13 -15 55 0 42 3 55 15
55 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 25 -24 17 -38 -13z"
                />
                <path
                    d="M2000 9420 c0 -67 4 -110 10 -110 6 0 10 15 10 34 0 34 0 34 30 23
26 -10 32 -8 55 13 22 20 25 32 23 69 -2 35 -9 48 -31 65 -24 18 -30 19 -49 7
-16 -10 -23 -10 -26 -2 -2 6 -8 11 -13 11 -5 0 -9 -49 -9 -110z m102 55 c18
-41 0 -80 -37 -80 -23 0 -32 6 -39 27 -14 39 6 78 39 78 18 0 29 -8 37 -25z"
                />
                <path
                    d="M2216 9521 c-3 -5 -15 -7 -26 -4 -20 5 -21 2 -18 -73 4 -94 21 -102
28 -12 4 56 8 63 28 66 12 2 22 10 22 18 0 15 -26 19 -34 5z"
                />
                <path
                    d="M2282 9448 c4 -105 23 -107 23 -1 0 53 -4 78 -13 81 -10 3 -12 -15
-10 -80z"
                />
                <path
                    d="M2376 9509 c-21 -17 -26 -29 -26 -65 0 -75 64 -111 109 -62 24 25 17
46 -8 24 -50 -46 -104 12 -71 75 13 23 58 26 66 4 7 -16 34 -21 34 -6 0 13
-49 51 -65 51 -7 0 -24 -9 -39 -21z"
                />
                <path
                    d="M2535 9505 c-17 -16 -25 -35 -25 -60 0 -40 36 -85 68 -85 24 0 70 35
60 46 -4 4 -14 1 -22 -5 -23 -19 -70 -10 -74 15 -3 18 2 20 48 19 28 -1 53 1
55 5 8 14 -17 70 -35 80 -29 16 -49 12 -75 -15z m73 -17 c21 -21 13 -28 -28
-28 -22 0 -40 4 -40 8 0 14 23 32 40 32 9 0 21 -5 28 -12z"
                />
                <path
                    d="M2760 9451 c0 -44 5 -83 10 -86 6 -4 10 25 10 79 0 51 -4 86 -10 86
-6 0 -10 -33 -10 -79z"
                />
                <path
                    d="M2852 9514 c-34 -23 -25 -55 21 -77 46 -21 50 -47 7 -47 -16 0 -30 5
-30 10 0 6 -4 10 -10 10 -18 0 -10 -29 11 -40 31 -17 78 -3 85 26 8 30 -9 52
-50 62 -17 5 -31 15 -31 23 0 18 45 25 55 9 4 -7 14 -10 21 -8 9 3 7 10 -9 26
-26 26 -40 27 -70 6z"
                />
                <path
                    d="M1868 9487 c-32 -18 -56 -36 -55 -40 1 -5 31 -25 65 -44 48 -27 62
-31 62 -19 0 9 -18 25 -40 36 -22 11 -40 24 -40 29 0 4 18 16 40 26 22 10 40
24 40 32 0 18 -7 16 -72 -20z"
                />
                <path
                    d="M3100 9507 c0 -8 18 -22 40 -32 22 -10 40 -22 40 -26 0 -5 -18 -18
-40 -29 -22 -11 -40 -27 -40 -35 0 -11 11 -8 48 12 90 50 89 48 46 76 -80 49
-94 55 -94 34z"
                />
                <path d="M1315 9390 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />
                <path
                    d="M1396 9392 c-2 -4 -1 -14 4 -22 7 -11 12 -12 21 -3 6 6 8 16 5 22 -8
13 -23 14 -30 3z"
                />
                <path
                    d="M1485 9390 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M1565 9390 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"
                />
                <path d="M1644 9389 c-3 -6 0 -15 7 -20 14 -8 32 9 23 23 -7 11 -22 10 -30 -3z" />
                <path
                    d="M1732 9386 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path
                    d="M12795 7305 c0 -935 1 -1319 2 -853 2 465 2 1230 0 1700 -1 469 -2
88 -2 -847z"
                />
                <path
                    d="M3225 8870 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M2500 8845 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 5 -15 10
-15 6 0 10 -24 10 -54 0 -36 5 -60 16 -70 20 -20 44 -21 44 -1 0 8 -7 15 -15
15 -17 0 -16 -2 -19 62 -1 38 2 48 16 51 24 7 23 23 -3 30 -12 3 -18 10 -14
16 3 6 -1 11 -9 11 -9 0 -16 -7 -16 -15z"
                />
                <path
                    d="M2042 8794 c-35 -20 -62 -40 -60 -45 2 -6 31 -26 66 -45 48 -27 62
-32 62 -19 0 8 -20 25 -45 37 -25 12 -45 25 -45 28 0 3 20 16 45 28 25 12 45
29 45 37 0 19 6 21 -68 -21z"
                />
                <path
                    d="M2188 8820 c-9 -6 -21 -25 -28 -44 -13 -41 -1 -78 35 -102 21 -14 30
-14 55 -4 19 8 30 20 30 33 0 19 -1 19 -19 3 -45 -40 -99 11 -73 69 14 29 46
33 74 8 17 -15 18 -15 18 5 0 35 -54 54 -92 32z"
                />
                <path
                    d="M2348 8819 c-10 -5 -18 -17 -18 -24 0 -18 12 -19 27 -4 14 14 55 8
60 -8 3 -7 -8 -15 -24 -19 -57 -12 -63 -16 -69 -41 -9 -33 28 -68 60 -58 12 4
29 7 39 6 26 0 25 119 -1 142 -21 19 -49 21 -74 6z m72 -92 c0 -20 -22 -37
-47 -37 -16 0 -23 6 -23 19 0 11 6 22 13 24 24 10 57 7 57 -6z"
                />
                <path
                    d="M2611 8804 c-29 -37 -27 -89 4 -119 28 -29 51 -31 83 -9 25 17 29 34
7 34 -8 0 -15 -4 -15 -10 0 -18 -59 -11 -69 9 -14 26 -5 31 51 31 45 0 48 2
48 25 0 32 -33 65 -64 65 -15 0 -33 -11 -45 -26z m78 -17 c15 -19 4 -27 -36
-27 -31 0 -44 16 -26 33 11 12 50 8 62 -6z"
                />
                <path
                    d="M2782 8807 c-26 -28 -29 -82 -6 -115 17 -24 62 -30 82 -10 9 9 12 8
12 -4 0 -37 -52 -62 -77 -37 -6 6 -17 8 -24 4 -18 -12 18 -35 54 -35 61 0 67
11 67 120 0 60 -4 100 -10 100 -5 0 -10 -5 -10 -10 0 -7 -6 -7 -19 0 -30 15
-45 12 -69 -13z m80 -32 c13 -29 -1 -72 -27 -81 -20 -8 -55 23 -55 48 0 57 60
81 82 33z"
                />
                <path
                    d="M2960 8810 c-28 -28 -34 -62 -17 -102 31 -75 119 -52 125 32 6 74
-61 117 -108 70z m82 -35 c24 -53 -26 -109 -68 -75 -17 15 -19 71 -2 88 21 21
57 14 70 -13z"
                />
                <path
                    d="M3110 8749 c0 -62 3 -79 15 -79 10 0 15 16 17 63 3 56 5 62 26 65 40
6 25 29 -18 29 l-40 0 0 -78z"
                />
                <path
                    d="M3226 8823 c-3 -3 -6 -39 -6 -80 0 -58 3 -73 15 -73 12 0 15 15 15
74 0 69 -8 95 -24 79z"
                />
                <path
                    d="M3311 8804 c-29 -37 -27 -89 5 -120 27 -28 64 -27 93 1 20 20 5 32
-19 17 -29 -18 -58 -15 -69 7 -14 26 -5 31 55 31 l53 0 -10 33 c-17 60 -72 76
-108 31z m78 -17 c15 -19 4 -27 -34 -27 -17 0 -35 3 -38 7 -11 11 14 33 38 33
12 0 28 -6 34 -13z"
                />
                <path
                    d="M3480 8812 c-32 -26 -19 -56 31 -76 23 -9 39 -22 36 -29 -5 -17 -45
-21 -63 -6 -24 19 -30 0 -7 -22 16 -16 28 -20 52 -14 64 14 61 73 -4 95 -40
13 -47 40 -11 40 14 0 28 -4 31 -10 9 -15 25 -12 25 5 0 15 -30 35 -53 35 -8
0 -25 -8 -37 -18z"
                />
                <path
                    d="M3620 8815 c0 -8 20 -25 45 -37 25 -12 45 -25 45 -28 0 -3 -20 -16
-45 -28 -25 -12 -45 -29 -45 -37 0 -13 14 -8 63 19 34 19 62 40 62 46 0 6 -28
27 -62 46 -49 27 -63 32 -63 19z"
                />
                <path d="M1317 8693 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                <path d="M1395 8690 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z" />
                <path
                    d="M1480 8685 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1560 8685 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1645 8691 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"
                />
                <path d="M1730 8686 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
                <path
                    d="M1810 8685 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1895 8689 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13 -30
-1z"
                />
                <path
                    d="M4800 8174 c0 -12 -30 -129 -56 -216 -3 -11 0 -18 8 -18 9 0 24 39
42 113 16 61 31 118 33 125 3 6 -2 12 -11 12 -9 0 -16 -7 -16 -16z"
                />
                <path
                    d="M3747 8173 c-4 -3 -7 -51 -7 -105 l0 -98 110 0 110 0 0 105 c0 87 -3
105 -15 105 -12 0 -15 -17 -15 -96 l0 -95 -32 3 -33 3 -3 93 c-1 50 -7 92 -12
92 -5 0 -11 -42 -12 -92 l-3 -93 -30 0 -30 0 -3 93 c-3 85 -8 103 -25 85z"
                />
                <path
                    d="M5205 8155 c-5 -11 -13 -23 -19 -27 -8 -5 -7 -11 2 -20 6 -6 12 -33
12 -59 0 -63 7 -79 36 -79 29 0 32 16 4 23 -17 4 -20 14 -20 57 0 43 3 53 20
57 26 7 26 23 1 23 -12 0 -21 8 -24 23 -3 21 -4 21 -12 2z"
                />
                <path
                    d="M2666 8145 c4 -9 0 -15 -10 -15 -20 0 -21 -16 -1 -23 10 -4 15 -25
17 -69 3 -62 3 -63 33 -66 l30 -2 -22 16 c-19 13 -23 26 -23 66 0 34 4 50 15
54 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"
                />
                <path
                    d="M2208 8097 c-32 -18 -58 -37 -58 -44 0 -18 111 -76 123 -64 7 7 -5
17 -37 32 -25 13 -46 26 -46 31 0 4 18 17 39 28 22 11 42 24 45 29 14 22 -13
17 -66 -12z"
                />
                <path
                    d="M2341 8104 c-12 -15 -21 -38 -21 -51 0 -33 29 -80 53 -87 26 -6 63 9
71 30 9 25 -1 30 -22 11 -40 -36 -75 -11 -70 50 3 35 6 38 36 41 18 2 32 -1
32 -7 0 -6 7 -11 15 -11 20 0 19 13 -3 33 -28 25 -67 21 -91 -9z"
                />
                <path
                    d="M2504 8116 c-26 -19 -12 -31 23 -19 15 6 34 8 41 6 22 -8 13 -33 -15
-38 -46 -10 -58 -20 -61 -54 -3 -26 1 -33 24 -41 15 -6 35 -8 43 -4 9 4 24 6
34 5 14 -2 17 6 17 56 0 70 -19 103 -59 103 -15 0 -36 -6 -47 -14z m76 -89 c0
-19 -21 -37 -42 -37 -24 0 -35 29 -17 41 21 13 59 11 59 -4z"
                />
                <path
                    d="M2784 8115 c-47 -32 -31 -136 21 -149 27 -7 71 10 79 30 6 16 -20 19
-29 4 -9 -14 -48 -13 -63 2 -26 26 -13 38 43 38 51 0 55 2 55 23 0 30 -35 67
-63 67 -12 0 -32 -7 -43 -15z m74 -37 c3 -15 -4 -18 -37 -18 -43 0 -50 7 -29
28 18 18 62 11 66 -10z"
                />
                <path
                    d="M2940 8103 c-28 -36 -26 -78 6 -110 20 -20 32 -24 55 -19 24 5 29 2
29 -14 0 -28 -45 -38 -69 -16 -14 13 -21 14 -27 5 -10 -17 24 -39 62 -39 50 0
64 28 64 131 l0 86 -50 1 c-42 1 -52 -3 -70 -25z m88 -50 c2 -33 -1 -44 -17
-52 -40 -22 -71 18 -57 75 6 21 11 25 39 22 30 -3 32 -6 35 -45z"
                />
                <path
                    d="M3121 8104 c-41 -52 -19 -125 41 -140 38 -8 78 32 78 79 0 46 -33 87
-70 87 -19 0 -36 -9 -49 -26z m86 -17 c10 -16 5 -67 -8 -83 -17 -21 -47 -17
-64 10 -18 27 -12 71 13 85 17 10 49 3 59 -12z"
                />
                <path
                    d="M3280 8048 c0 -45 4 -78 10 -78 6 0 10 24 10 53 0 59 6 71 38 81 36
12 25 25 -18 23 l-40 -1 0 -78z"
                />
                <path
                    d="M3380 8126 c0 -3 13 -40 30 -82 30 -78 28 -104 -6 -104 -8 0 -14 -7
-14 -15 0 -34 47 -11 65 32 45 108 64 173 51 173 -8 0 -22 -22 -31 -52 -10
-29 -20 -56 -24 -60 -3 -4 -15 20 -25 52 -11 34 -25 60 -33 60 -7 0 -13 -2
-13 -4z"
                />
                <path
                    d="M3560 8117 c0 -8 17 -24 37 -34 20 -11 38 -24 40 -30 2 -6 -14 -19
-36 -29 -23 -10 -41 -24 -41 -31 0 -7 3 -13 6 -13 14 0 123 66 121 73 -1 4
-30 24 -65 43 -45 26 -62 31 -62 21z"
                />
                <path
                    d="M4010 8050 c0 -98 13 -104 61 -28 l34 53 3 -53 c2 -37 7 -52 18 -52
11 0 14 17 14 81 0 70 -2 80 -17 77 -9 -2 -32 -28 -50 -58 l-33 -55 0 58 c0
44 -3 57 -15 57 -12 0 -15 -16 -15 -80z"
                />
                <path
                    d="M4190 8050 c0 -47 4 -80 10 -80 6 0 10 16 10 35 0 33 2 35 35 35 33
0 35 -2 35 -35 0 -24 5 -35 15 -35 12 0 15 16 15 80 0 64 -3 80 -15 80 -10 0
-15 -11 -15 -35 0 -33 -2 -35 -35 -35 -33 0 -35 2 -35 35 0 19 -4 35 -10 35
-6 0 -10 -33 -10 -80z"
                />
                <path
                    d="M4360 8050 l0 -80 44 0 c34 0 48 5 60 21 25 37 -4 79 -56 79 -24 0
-28 4 -28 30 0 17 -4 30 -10 30 -6 0 -10 -33 -10 -80z m87 -26 c7 -19 -14 -35
-42 -32 -12 2 -21 12 -23 26 -3 19 1 22 28 22 19 0 33 -6 37 -16z"
                />
                <path
                    d="M4490 8050 c0 -64 3 -80 15 -80 12 0 15 16 15 80 0 64 -3 80 -15 80
-12 0 -15 -16 -15 -80z"
                />
                <path
                    d="M4633 8094 c-35 -20 -63 -39 -63 -43 0 -7 113 -71 124 -71 4 0 6 6 6
13 0 7 -21 23 -47 36 l-48 23 48 24 c26 14 47 31 47 39 0 19 6 21 -67 -21z"
                />
                <path
                    d="M4888 8120 c-9 -6 -22 -26 -28 -45 -14 -43 3 -84 43 -103 24 -12 31
-11 53 2 13 9 24 23 24 31 0 19 -14 19 -30 0 -17 -21 -47 -19 -60 4 -14 26
-13 64 2 79 15 15 50 16 65 1 6 -6 17 -9 24 -7 20 7 -27 48 -53 48 -13 0 -31
-5 -40 -10z"
                />
                <path
                    d="M5039 8110 l-24 -20 47 3 c69 3 66 -18 -7 -40 -22 -6 -31 -15 -33
-35 -4 -34 36 -64 70 -52 13 4 29 7 36 6 19 -4 16 122 -4 142 -21 22 -57 20
-85 -4z m81 -76 c0 -20 -24 -44 -45 -44 -29 0 -41 16 -25 35 12 14 70 22 70 9z"
                />
                <path
                    d="M5310 8110 c-42 -42 -32 -112 22 -138 30 -14 72 -3 81 22 7 18 -18
23 -28 6 -9 -15 -42 -12 -59 6 -27 26 -18 34 39 34 52 0 55 1 55 25 0 56 -70
85 -110 45z m79 -23 c6 -8 8 -17 4 -20 -9 -10 -83 -9 -83 1 0 29 59 43 79 19z"
                />
                <path
                    d="M5486 8117 c-26 -19 -36 -69 -21 -104 15 -35 41 -47 78 -37 22 5 25
3 20 -15 -6 -25 -36 -36 -62 -22 -33 18 -46 13 -26 -9 23 -25 76 -26 98 -2 13
14 17 39 17 108 l0 89 -44 2 c-24 1 -51 -4 -60 -10z m74 -47 c14 -55 -19 -91
-58 -64 -25 17 -30 62 -10 82 21 21 61 10 68 -18z"
                />
                <path
                    d="M5655 8105 c-57 -56 -2 -163 69 -135 43 16 60 76 35 123 -24 45 -67
50 -104 12z m85 -26 c15 -28 12 -54 -9 -73 -39 -36 -82 8 -67 68 9 38 57 42
76 5z"
                />
                <path
                    d="M5810 8048 c0 -63 3 -78 15 -78 12 0 15 13 15 60 0 55 2 61 25 70 38
14 30 29 -15 27 l-40 -1 0 -78z"
                />
                <path
                    d="M5940 8050 c31 -84 31 -105 -1 -113 -20 -5 -27 -27 -8 -27 29 0 49
26 74 92 14 40 31 83 36 96 12 30 12 32 -4 32 -7 0 -21 -25 -31 -55 -9 -30
-20 -55 -25 -55 -4 0 -15 25 -25 55 -10 33 -23 55 -31 55 -11 0 -8 -18 15 -80z"
                />
                <path
                    d="M6090 8116 c0 -8 20 -25 45 -37 24 -12 42 -26 39 -31 -3 -4 -23 -17
-45 -28 -33 -17 -52 -40 -33 -40 17 0 124 66 121 75 -3 8 -112 75 -123 75 -2
0 -4 -6 -4 -14z"
                />
                <path
                    d="M1310 7985 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path d="M1400 7986 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                <path
                    d="M1480 7985 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1560 7985 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"
                />
                <path
                    d="M1645 7991 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"
                />
                <path
                    d="M1730 7985 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1810 7985 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1890 7985 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"
                />
                <path
                    d="M1975 7991 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"
                />
                <path
                    d="M2060 7985 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M4856 7482 c-10 -16 -65 -236 -61 -241 11 -11 24 19 50 114 14 55 28
108 31 118 5 17 -10 24 -20 9z"
                />
                <path
                    d="M3770 7427 c0 -64 -14 -115 -35 -131 -11 -8 -15 -23 -13 -41 4 -37
28 -46 28 -11 0 25 1 26 70 26 68 0 70 -1 70 -25 0 -16 6 -25 15 -25 11 0 15
11 15 35 0 22 -6 38 -15 41 -12 5 -15 25 -15 95 l0 89 -60 0 -60 0 0 -53z m98
-48 l3 -77 -45 -4 -46 -3 6 70 c8 95 8 97 46 93 l33 -3 3 -76z"
                />
                <path
                    d="M2670 7450 c0 -11 -7 -20 -15 -20 -20 0 -19 -16 1 -24 11 -4 15 -17
13 -48 -3 -62 8 -88 37 -88 29 0 32 16 4 23 -17 4 -20 14 -20 61 0 43 3 56 15
56 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0 11 -4 20 -10 20 -5 0
-10 -9 -10 -20z"
                />
                <path
                    d="M5250 7451 c0 -12 -6 -21 -15 -21 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 12 0 15 -13 15 -58 0 -32 5 -63 12 -70 15 -15 48 -16 48 -2 0 6 -6 10 -14
10 -16 0 -26 30 -26 78 0 26 5 35 20 39 26 7 26 19 0 26 -11 3 -19 11 -18 17
2 7 -3 15 -9 17 -8 3 -13 -4 -13 -16z"
                />
                <path
                    d="M2208 7398 c-32 -17 -58 -37 -58 -44 0 -11 103 -74 121 -74 14 0 -2
29 -19 35 -10 3 -28 14 -41 24 l-23 18 46 27 c25 15 46 31 46 36 0 15 -10 12
-72 -22z"
                />
                <path
                    d="M2341 7404 c-43 -55 -11 -134 54 -134 25 0 38 6 45 21 15 28 5 36
-18 16 -38 -34 -72 -14 -72 43 0 57 34 77 72 43 25 -22 36 -8 13 17 -26 29
-69 26 -94 -6z"
                />
                <path
                    d="M2504 7416 c-26 -19 -10 -33 20 -16 28 14 56 8 56 -13 0 -9 -18 -19
-45 -26 -42 -11 -45 -13 -45 -45 0 -39 11 -46 78 -44 l42 0 0 63 c0 45 -5 68
-16 79 -19 20 -64 21 -90 2z m65 -112 c-17 -21 -54 -14 -54 11 0 14 9 21 30
25 34 6 45 -11 24 -36z"
                />
                <path
                    d="M2774 7409 c-24 -26 -23 -92 1 -119 24 -26 76 -26 100 0 20 22 7 27
-27 9 -25 -14 -68 3 -68 26 0 12 12 15 55 15 52 0 55 1 55 25 0 57 -78 86
-116 44z m76 -14 c17 -21 4 -34 -31 -30 -40 5 -45 11 -29 30 16 19 44 19 60 0z"
                />
                <path
                    d="M2940 7403 c-11 -14 -20 -34 -20 -44 0 -58 52 -105 91 -81 16 11 19
9 19 -8 0 -28 -38 -42 -67 -24 -28 18 -43 -1 -17 -21 27 -21 71 -18 94 5 17
17 20 33 20 108 l0 89 -50 1 c-42 1 -52 -3 -70 -25z m78 -5 c13 -13 16 -56 6
-82 -4 -9 -18 -16 -34 -16 -28 0 -40 15 -40 51 0 49 39 76 68 47z"
                />
                <path
                    d="M3122 7410 c-29 -27 -30 -88 -2 -118 29 -31 73 -29 99 4 59 75 -28
178 -97 114z m86 -32 c16 -33 2 -73 -29 -83 -28 -9 -52 15 -52 54 0 61 56 82
81 29z"
                />
                <path
                    d="M3280 7348 c0 -45 4 -78 10 -78 6 0 10 23 10 51 0 60 13 89 40 89 11
0 20 4 20 9 0 6 -18 9 -40 8 l-40 -1 0 -78z"
                />
                <path
                    d="M3380 7426 c0 -3 13 -38 29 -79 29 -76 27 -107 -4 -107 -8 0 -15 -7
-15 -16 0 -11 5 -14 16 -10 9 3 19 6 23 6 10 0 25 33 62 133 21 57 25 77 15
77 -7 0 -21 -21 -31 -47 -10 -27 -21 -51 -24 -55 -4 -5 -15 17 -26 47 -10 30
-24 55 -32 55 -7 0 -13 -2 -13 -4z"
                />
                <path
                    d="M3560 7418 c0 -7 18 -23 40 -36 22 -12 40 -26 40 -31 0 -4 -18 -16
-40 -26 -22 -10 -40 -24 -40 -32 0 -7 3 -13 8 -13 15 1 115 65 115 75 0 8
-106 75 -119 75 -2 0 -4 -6 -4 -12z"
                />
                <path
                    d="M3952 7353 c2 -48 7 -78 15 -80 7 -3 27 18 45 46 18 28 35 51 38 51
3 0 5 -21 5 -47 0 -28 5 -48 13 -50 9 -4 12 16 12 76 0 98 -11 103 -60 29
l-35 -53 -3 53 c-2 38 -7 52 -18 52 -12 0 -14 -14 -12 -77z"
                />
                <path
                    d="M4141 7404 c-43 -55 -11 -134 54 -134 25 0 38 6 45 21 15 28 5 36
-18 16 -38 -34 -72 -10 -72 50 0 48 43 71 70 38 16 -19 30 -19 30 0 0 8 -9 19
-19 25 -34 18 -68 12 -90 -16z"
                />
                <path
                    d="M4290 7350 c0 -64 3 -80 15 -80 10 0 15 11 15 35 0 44 8 44 40 0 14
-19 31 -35 38 -35 18 0 15 9 -13 46 -14 18 -25 36 -25 39 0 3 12 22 26 41 37
48 8 45 -33 -3 l-32 -38 -1 38 c0 26 -4 37 -15 37 -12 0 -15 -16 -15 -80z"
                />
                <path
                    d="M4450 7350 c0 -46 4 -80 10 -80 5 0 25 22 42 50 18 27 36 50 41 50 4
0 7 -22 7 -50 0 -27 5 -50 10 -50 6 0 10 33 10 80 0 97 -12 103 -61 28 l-34
-53 -3 53 c-5 82 -22 61 -22 -28z"
                />
                <path
                    d="M4681 7397 c-30 -18 -55 -36 -55 -42 1 -8 107 -75 120 -75 2 0 4 6 4
13 0 8 -18 22 -40 33 -22 10 -40 22 -40 26 0 4 18 18 40 30 22 13 40 29 40 36
0 17 -7 15 -69 -21z"
                />
                <path
                    d="M4924 7409 c-25 -28 -25 -91 1 -119 23 -25 82 -24 99 2 14 24 1 34
-19 14 -45 -45 -98 23 -65 85 13 23 56 25 68 3 5 -9 14 -15 21 -13 8 3 7 11
-4 27 -21 29 -75 30 -101 1z"
                />
                <path
                    d="M5085 7410 c-20 -22 -6 -27 24 -8 10 6 26 8 35 5 26 -10 19 -36 -11
-42 -43 -9 -58 -20 -61 -44 -6 -37 15 -51 69 -49 l49 2 0 62 c0 71 -15 94 -59
94 -15 0 -36 -9 -46 -20z m78 -87 c-13 -35 -56 -45 -67 -17 -9 24 2 34 39 34
29 0 33 -3 28 -17z"
                />
                <path
                    d="M5364 7416 c-14 -11 -19 -26 -19 -66 0 -58 18 -80 65 -80 35 0 69 25
54 40 -8 8 -14 7 -24 -5 -18 -22 -46 -19 -65 8 -16 22 -15 22 42 27 54 5 58 7
55 29 -6 54 -64 79 -108 47z m76 -21 c17 -20 7 -28 -35 -30 -36 -1 -44 12 -23
33 17 17 43 15 58 -3z"
                />
                <path
                    d="M5525 7406 c-40 -61 11 -155 70 -128 22 10 25 9 25 -8 0 -31 -47 -46
-72 -24 -13 12 -22 14 -29 7 -14 -14 27 -43 62 -43 47 0 59 26 59 126 l0 90
-50 2 c-43 2 -52 -1 -65 -22z m79 -12 c20 -19 21 -68 2 -84 -43 -35 -94 26
-62 75 19 29 38 32 60 9z"
                />
                <path
                    d="M5704 7409 c-11 -13 -19 -35 -19 -59 0 -47 27 -80 67 -80 41 0 68 32
68 80 0 71 -72 108 -116 59z m80 -21 c21 -30 20 -51 -3 -79 -16 -21 -22 -22
-45 -11 -21 9 -26 18 -26 50 0 61 43 84 74 40z"
                />
                <path
                    d="M5862 7352 c2 -49 7 -77 16 -80 9 -3 12 14 12 62 0 62 2 66 25 72 39
10 30 23 -15 22 l-41 0 3 -76z"
                />
                <path
                    d="M5985 7364 c13 -36 27 -69 30 -75 11 -17 -6 -49 -26 -49 -10 0 -19
-7 -19 -15 0 -8 6 -15 13 -15 27 0 49 28 73 95 41 110 44 125 30 125 -7 0 -21
-25 -31 -55 -11 -30 -22 -52 -26 -47 -3 4 -14 28 -24 55 -10 26 -24 47 -31 47
-11 0 -8 -16 11 -66z"
                />
                <path
                    d="M6142 7418 c3 -7 23 -23 46 -36 47 -27 52 -37 20 -46 -30 -8 -75 -43
-66 -51 9 -9 128 56 128 70 0 10 -109 75 -125 75 -3 0 -5 -6 -3 -12z"
                />
                <path d="M1486 7303 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
                <path d="M1816 7303 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
                <path d="M2064 7296 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />
                <path
                    d="M1312 7288 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path d="M1400 7284 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M1560 7285 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1642 7288 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path d="M1730 7284 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M1892 7288 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
-10z"
                />
                <path
                    d="M1973 7288 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12 -13
0 -20 -5 -17 -12z"
                />
                <path
                    d="M5661 6733 c-48 -162 -57 -215 -32 -187 4 5 19 52 34 104 35 131 36
139 27 145 -5 3 -18 -25 -29 -62z"
                />
                <path
                    d="M3793 6763 c-3 -10 -19 -52 -35 -93 -15 -41 -28 -80 -28 -87 0 -24
21 -13 31 17 6 17 16 30 22 31 7 0 24 2 40 4 23 3 29 -2 42 -31 13 -31 43 -51
33 -21 -3 6 -20 54 -38 105 -32 90 -53 113 -67 75z m47 -94 c0 -5 -13 -9 -30
-9 -19 0 -28 4 -25 13 2 6 9 26 15 42 l12 30 14 -34 c8 -18 14 -37 14 -42z"
                />
                <path
                    d="M2670 6755 c0 -9 -7 -18 -15 -21 -8 -4 -15 -10 -15 -15 0 -5 7 -9 15
-9 12 0 15 -13 15 -59 0 -62 16 -89 46 -77 18 7 11 21 -9 21 -8 0 -13 22 -15
58 -2 46 0 57 13 57 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0 11
-4 20 -10 20 -5 0 -10 -7 -10 -15z"
                />
                <path
                    d="M6070 6750 c0 -12 -7 -20 -17 -20 -17 -1 -17 -1 0 -14 12 -10 17 -28
17 -68 0 -56 12 -78 42 -78 24 0 23 16 -2 23 -17 4 -20 14 -20 60 0 48 3 56
23 62 l22 8 -22 4 c-15 3 -23 12 -23 24 0 10 -4 19 -10 19 -5 0 -10 -9 -10
-20z"
                />
                <path
                    d="M2203 6698 c-29 -16 -53 -35 -53 -42 0 -10 107 -76 124 -76 19 0 0
23 -39 46 -25 14 -45 29 -45 32 0 4 19 16 42 28 42 21 61 44 36 43 -7 0 -37
-14 -65 -31z"
                />
                <path
                    d="M2336 6701 c-22 -41 -20 -73 5 -105 24 -31 68 -35 92 -8 10 10 17 22
17 25 0 12 -21 7 -36 -8 -19 -19 -29 -19 -48 1 -19 18 -21 75 -4 92 17 17 49
15 64 -5 16 -22 32 -8 18 15 -7 10 -27 18 -52 20 -37 3 -43 0 -56 -27z"
                />
                <path
                    d="M2500 6715 c-18 -22 -4 -31 24 -15 28 14 56 8 56 -13 0 -9 -17 -18
-42 -23 -37 -8 -43 -13 -46 -37 -5 -43 13 -58 67 -53 l46 4 3 55 c4 76 -8 97
-57 97 -22 0 -44 -6 -51 -15z m80 -84 c0 -26 -25 -44 -50 -36 -25 8 -26 30 -2
44 28 17 52 13 52 -8z"
                />
                <path
                    d="M2774 6709 c-28 -31 -25 -96 5 -120 28 -23 59 -24 90 -3 15 11 19 20
12 27 -6 6 -15 4 -26 -7 -21 -20 -39 -20 -59 0 -27 26 -18 34 39 34 51 0 55 2
55 23 0 61 -75 91 -116 46z m84 -21 c3 -15 -4 -18 -37 -18 -43 0 -50 7 -29 28
18 18 62 11 66 -10z"
                />
                <path
                    d="M2954 6722 c-22 -14 -36 -54 -29 -88 7 -39 35 -58 77 -52 25 4 29 2
26 -16 -3 -24 -51 -35 -73 -16 -8 6 -16 7 -19 2 -10 -16 24 -36 61 -36 52 0
63 22 63 125 l0 86 -47 1 c-27 1 -53 -2 -59 -6z m65 -26 c17 -20 10 -77 -11
-90 -26 -16 -52 4 -56 43 -4 36 12 61 38 61 10 0 23 -6 29 -14z"
                />
                <path
                    d="M3116 6708 c-37 -52 -3 -138 54 -138 60 0 93 92 50 138 -28 30 -83
30 -104 0z m90 -27 c14 -28 1 -76 -22 -85 -43 -17 -77 47 -49 91 20 30 55 28
71 -6z"
                />
                <path
                    d="M3280 6650 c0 -47 4 -80 10 -80 6 0 10 26 10 58 0 60 11 82 42 82 10
0 18 5 18 10 0 6 -18 10 -40 10 l-40 0 0 -80z"
                />
                <path
                    d="M3385 6713 c44 -114 52 -141 43 -156 -5 -9 -16 -17 -23 -17 -8 0 -15
-5 -15 -11 0 -25 46 -2 62 31 39 81 67 170 54 170 -7 0 -21 -21 -31 -47 -10
-27 -21 -51 -24 -56 -4 -4 -15 18 -25 48 -11 36 -22 55 -33 55 -10 0 -13 -5
-8 -17z"
                />
                <path
                    d="M3560 6717 c0 -8 18 -22 40 -32 22 -10 40 -22 40 -26 0 -5 -18 -19
-40 -31 -22 -13 -40 -29 -40 -36 0 -14 122 48 127 65 2 7 -107 73 -121 73 -3
0 -6 -6 -6 -13z"
                />
                <path
                    d="M3932 6653 l3 -78 49 1 c57 1 80 21 66 59 -5 13 -6 32 -3 44 9 34
-14 51 -69 51 l-49 0 3 -77z m91 43 c8 -19 -1 -26 -35 -26 -21 0 -28 5 -28 20
0 15 7 20 29 20 16 0 31 -6 34 -14z m7 -76 c0 -16 -7 -20 -35 -20 -28 0 -35 4
-35 20 0 16 7 20 35 20 28 0 35 -4 35 -20z"
                />
                <path
                    d="M4090 6720 c0 -5 11 -10 25 -10 24 0 25 -2 25 -70 0 -40 4 -70 10
-70 6 0 10 30 10 70 0 68 1 70 25 70 14 0 25 5 25 10 0 6 -27 10 -60 10 -33 0
-60 -4 -60 -10z"
                />
                <path
                    d="M4256 6711 c-20 -22 -24 -78 -7 -109 26 -48 95 -40 117 14 25 59 -5
114 -61 114 -19 0 -39 -8 -49 -19z m86 -26 c14 -30 2 -72 -24 -89 -39 -24 -80
68 -46 102 21 21 57 14 70 -13z"
                />
                <path
                    d="M4390 6720 c0 -5 14 -10 30 -10 l30 0 0 -70 c0 -40 4 -70 10 -70 6 0
10 30 10 70 0 68 1 70 25 70 14 0 25 5 25 10 0 6 -28 10 -65 10 -37 0 -65 -4
-65 -10z"
                />
                <path
                    d="M4564 6709 c-28 -31 -25 -96 5 -120 51 -41 111 -9 111 59 0 21 -7 48
-16 60 -20 29 -74 30 -100 1z m86 -18 c15 -29 12 -62 -7 -83 -20 -22 -31 -23
-55 -1 -35 31 -16 103 27 103 14 0 29 -8 35 -19z"
                />
                <path
                    d="M4725 6653 l1 -78 47 1 c60 0 86 22 69 58 -6 13 -8 33 -5 45 9 33
-14 51 -66 51 l-47 0 1 -77z m90 37 c0 -10 -12 -16 -32 -18 -28 -3 -33 0 -33
18 0 18 5 21 33 18 20 -2 32 -8 32 -18z m5 -70 c0 -16 -7 -20 -35 -20 -28 0
-35 4 -35 20 0 16 7 20 35 20 28 0 35 -4 35 -20z"
                />
                <path
                    d="M4903 6723 c-14 -5 -18 -33 -5 -33 4 0 13 5 19 11 15 15 57 7 61 -12
2 -12 -7 -18 -36 -23 -48 -7 -66 -27 -58 -61 7 -29 50 -44 78 -26 11 7 18 7
18 1 0 -5 5 -10 10 -10 14 0 13 133 -2 148 -12 12 -62 15 -85 5z m77 -87 c0
-21 -30 -48 -49 -44 -23 5 -27 45 -4 51 35 9 53 7 53 -7z"
                />
                <path
                    d="M5050 6623 c0 -62 4 -103 10 -103 6 0 10 14 10 31 0 29 2 30 30 24
62 -14 102 43 80 111 -13 37 -27 44 -87 42 l-43 -2 0 -103z m98 75 c15 -15 16
-63 2 -89 -23 -41 -73 -13 -73 41 0 50 41 78 71 48z"
                />
                <path
                    d="M5220 6655 c0 -85 6 -92 65 -81 45 9 68 32 59 60 -9 28 -9 28 -51 35
-32 5 -38 11 -41 34 -2 15 -9 27 -18 27 -11 0 -14 -17 -14 -75z m95 -30 c0
-15 -8 -21 -32 -23 -30 -3 -33 -1 -33 23 0 24 3 26 33 23 24 -2 32 -8 32 -23z"
                />
                <path
                    d="M5362 6653 c2 -44 7 -78 13 -78 6 0 11 34 13 78 2 65 0 77 -13 77
-13 0 -15 -12 -13 -77z"
                />
                <path
                    d="M5500 6697 c-30 -18 -54 -37 -53 -43 2 -16 123 -77 123 -62 0 7 -18
23 -40 36 -22 12 -40 26 -40 31 0 4 18 16 40 26 22 10 40 24 40 32 0 19 -9 17
-70 -20z"
                />
                <path
                    d="M5745 6710 c-44 -49 -11 -140 50 -140 24 0 55 23 55 41 0 13 -7 11
-36 -7 -23 -16 -26 -15 -44 1 -22 20 -27 74 -8 93 18 18 56 15 67 -5 5 -10 13
-14 17 -10 15 15 -13 42 -48 45 -25 2 -39 -3 -53 -18z"
                />
                <path
                    d="M5900 6715 c-17 -21 -4 -32 20 -17 30 18 60 15 60 -7 0 -14 -10 -21
-37 -26 -42 -8 -53 -19 -53 -52 0 -29 28 -43 76 -39 l39 4 3 62 c2 37 -2 67
-9 76 -15 19 -83 18 -99 -1z m80 -84 c0 -27 -28 -47 -48 -35 -25 16 -26 33 0
43 36 15 48 13 48 -8z"
                />
                <path
                    d="M6176 6708 c-24 -34 -20 -90 7 -116 13 -12 32 -22 43 -22 26 0 66 29
58 41 -4 6 -13 4 -24 -6 -22 -20 -55 -12 -70 16 -10 18 -7 19 44 19 30 0 57 4
60 9 8 12 -12 63 -27 73 -25 16 -76 8 -91 -14z m84 -13 c18 -22 9 -27 -41 -23
-41 3 -42 4 -25 21 22 21 49 22 66 2z"
                />
                <path
                    d="M6346 6712 c-9 -11 -16 -35 -16 -59 0 -53 26 -78 75 -70 38 6 40 5
25 -24 -12 -21 -50 -25 -68 -7 -8 8 -15 9 -22 2 -28 -28 72 -52 104 -25 13 11
16 34 16 105 l0 92 -49 2 c-36 1 -52 -3 -65 -16z m85 -29 c15 -41 -1 -78 -36
-78 -22 0 -30 6 -37 26 -13 39 6 79 37 79 18 0 28 -7 36 -27z"
                />
                <path
                    d="M6534 6722 c-18 -11 -34 -49 -34 -77 0 -32 41 -75 73 -75 55 0 88 86
51 138 -15 22 -66 30 -90 14z m66 -21 c21 -14 18 -80 -5 -97 -24 -18 -30 -18
-49 2 -19 18 -21 75 -4 92 14 14 37 15 58 3z"
                />
                <path
                    d="M6682 6653 c4 -94 21 -100 28 -11 4 56 8 63 28 66 38 6 24 22 -18 22
l-41 0 3 -77z"
                />
                <path
                    d="M6807 6663 c33 -86 34 -105 6 -118 -31 -13 -29 -27 4 -23 23 3 31 13
58 84 42 108 46 124 30 124 -7 0 -21 -22 -30 -50 -16 -48 -35 -74 -35 -48 0
27 -33 98 -46 98 -10 0 -6 -18 13 -67z"
                />
                <path
                    d="M6960 6720 c0 -5 21 -21 46 -36 50 -30 50 -30 -15 -66 -28 -16 -42
-38 -23 -38 13 0 115 65 119 75 2 6 -22 25 -53 43 -59 34 -74 38 -74 22z"
                />
                <path d="M1315 6600 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
                <path
                    d="M1400 6590 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"
                />
                <path
                    d="M1485 6600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path d="M1565 6600 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
                <path
                    d="M1644 6599 c-3 -6 -1 -16 5 -22 9 -9 14 -8 21 3 9 15 4 30 -10 30 -5
0 -12 -5 -16 -11z"
                />
                <path
                    d="M1735 6600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M1815 6600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M1894 6599 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"
                />
                <path
                    d="M1974 6599 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
-11z"
                />
                <path
                    d="M2065 6600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M5956 6092 c-8 -13 -66 -223 -66 -239 0 -59 55 93 76 210 6 35 2 47
-10 29z"
                />
                <path
                    d="M3792 6063 c-36 -95 -62 -169 -62 -176 0 -18 21 -4 35 23 13 25 22
30 51 30 30 0 37 -4 45 -30 6 -16 17 -30 25 -30 8 0 14 1 14 3 0 16 -78 201
-86 204 -6 2 -16 -9 -22 -24z m48 -90 c0 -7 -11 -13 -25 -13 -28 0 -31 13 -14
57 l11 28 14 -30 c7 -16 13 -36 14 -42z"
                />
                <path
                    d="M2670 6056 c0 -8 -7 -16 -15 -20 -8 -3 -15 -10 -15 -16 0 -5 7 -10
15 -10 12 0 15 -13 15 -60 0 -50 3 -61 21 -71 16 -8 24 -8 32 0 9 9 6 13 -11
17 -19 6 -22 13 -22 60 0 41 4 54 15 54 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 -6 -10 -14z"
                />
                <path
                    d="M6340 6055 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 5 -15 10
-15 6 0 10 -24 10 -53 0 -40 5 -58 18 -70 23 -21 42 -22 42 -2 0 8 -7 15 -15
15 -12 0 -15 13 -15 55 0 42 3 55 15 55 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 7 -15 15 0 8 -7 15 -15 15 -8 0 -15 -7 -15 -15z"
                />
                <path
                    d="M2208 6002 c-32 -19 -58 -40 -58 -46 0 -9 51 -40 123 -74 4 -2 7 1 7
7 0 7 -20 24 -45 39 -41 25 -57 42 -37 42 19 0 81 46 74 56 -5 7 -27 -1 -64
-24z"
                />
                <path
                    d="M2362 6033 c-24 -9 -44 -64 -37 -99 11 -63 87 -83 119 -32 14 23 -2
37 -18 15 -15 -20 -47 -22 -64 -5 -7 7 -12 26 -12 43 0 17 5 36 12 43 17 17
55 15 62 -3 7 -19 26 -20 26 -1 0 30 -53 53 -88 39z"
                />
                <path
                    d="M2509 6021 c-31 -25 -21 -34 19 -19 24 9 35 9 43 1 15 -15 3 -33 -22
-33 -37 0 -59 -19 -59 -51 0 -34 36 -57 67 -44 10 5 26 7 36 6 27 -3 25 111
-3 139 -25 25 -51 25 -81 1z m69 -93 c-3 -26 -43 -37 -62 -18 -8 8 -6 15 8 26
31 22 58 18 54 -8z"
                />
                <path
                    d="M2787 6028 c-43 -33 -40 -125 4 -149 24 -12 73 -4 89 16 14 17 -7 32
-23 16 -15 -15 -50 -14 -65 1 -20 20 -14 40 11 38 62 -5 87 0 87 19 0 28 -39
71 -64 71 -12 0 -30 -6 -39 -12z m71 -40 c3 -15 -4 -18 -37 -18 -43 0 -50 7
-29 28 18 18 62 11 66 -10z"
                />
                <path
                    d="M2951 6023 c-33 -27 -38 -82 -10 -116 18 -24 28 -28 56 -25 29 4 33
1 31 -16 -4 -25 -51 -34 -74 -15 -19 16 -30 4 -14 -16 18 -21 83 -19 103 3 13
15 17 39 17 110 0 83 -8 110 -25 82 -4 -7 -12 -7 -25 0 -26 14 -34 13 -59 -7z
m67 -25 c17 -17 15 -73 -3 -88 -12 -10 -21 -10 -40 -2 -43 20 -30 102 15 102
9 0 21 -5 28 -12z"
                />
                <path
                    d="M3125 6015 c-17 -16 -25 -35 -25 -60 0 -41 36 -85 70 -85 34 0 70 44
70 85 0 41 -36 85 -70 85 -11 0 -32 -11 -45 -25z m78 -25 c29 -41 -2 -97 -47
-86 -48 12 -41 106 8 106 14 0 31 -9 39 -20z"
                />
                <path
                    d="M3280 5958 c0 -45 4 -78 10 -78 6 0 10 24 10 53 0 56 12 77 43 77 35
0 11 24 -26 26 l-37 1 0 -79z"
                />
                <path
                    d="M3380 6033 c0 -4 13 -42 29 -83 30 -76 29 -110 -4 -110 -8 0 -15 -4
-15 -10 0 -14 36 -12 48 3 15 18 72 168 72 189 0 35 -19 17 -36 -34 -24 -77
-25 -77 -48 -13 -12 32 -27 60 -33 63 -7 2 -13 0 -13 -5z"
                />
                <path
                    d="M3560 6024 c0 -7 18 -24 40 -36 22 -13 40 -26 40 -30 0 -4 -18 -17
-40 -29 -22 -13 -40 -29 -40 -36 0 -14 122 49 128 66 2 5 -26 25 -62 44 -45
24 -66 30 -66 21z"
                />
                <path
                    d="M3937 6033 c-4 -3 -7 -39 -7 -80 l0 -73 55 0 c44 0 56 4 65 20 7 13
7 26 2 38 -5 9 -7 32 -5 49 5 28 2 34 -22 43 -30 11 -78 13 -88 3z m88 -43 c0
-10 -12 -16 -32 -18 -28 -3 -33 0 -33 18 0 18 5 21 33 18 20 -2 32 -8 32 -18z
m-6 -49 c23 -15 5 -41 -29 -41 -25 0 -30 4 -30 25 0 19 5 25 23 25 12 0 28 -4
36 -9z"
                />
                <path
                    d="M4090 6025 c0 -9 9 -15 25 -15 24 0 25 -2 25 -65 0 -37 4 -65 10 -65
6 0 10 28 10 65 0 63 1 65 25 65 16 0 25 6 25 15 0 12 -13 15 -60 15 -47 0
-60 -3 -60 -15z"
                />
                <path
                    d="M4260 6015 c-62 -66 21 -192 85 -128 40 39 41 88 2 131 -27 29 -58
28 -87 -3z m82 -30 c24 -53 -26 -109 -68 -75 -17 15 -19 71 -2 88 21 21 57 14
70 -13z"
                />
                <path
                    d="M4432 6024 c-25 -17 -29 -34 -7 -34 8 0 15 5 15 10 0 11 43 14 53 4
12 -12 -6 -34 -29 -34 -33 0 -30 -13 7 -27 18 -7 29 -18 27 -25 -6 -17 -47
-23 -61 -9 -15 15 -27 14 -27 -4 0 -21 43 -39 75 -31 28 7 47 42 35 66 -5 8
-6 27 -3 41 4 20 -1 31 -18 43 -29 20 -39 20 -67 0z"
                />
                <path
                    d="M4579 6021 c-13 -11 -21 -23 -17 -27 4 -4 14 -1 22 5 19 16 61 10 61
-9 0 -8 -14 -17 -34 -21 -49 -10 -67 -32 -53 -63 12 -27 52 -43 74 -30 7 5 21
7 31 6 17 -3 18 3 14 64 -3 50 -9 70 -22 81 -26 18 -49 16 -76 -6z m69 -93
c-3 -25 -43 -37 -61 -19 -15 15 10 41 40 41 20 0 24 -4 21 -22z"
                />
                <path
                    d="M4729 6030 c-1 -5 -1 -17 -1 -25 0 -83 4 -125 12 -125 6 0 10 28 10
65 l0 65 35 0 35 0 0 -65 c0 -51 3 -65 15 -65 12 0 15 16 15 80 l0 80 -60 0
c-33 0 -61 -4 -61 -10z"
                />
                <path
                    d="M4897 6033 c-11 -10 -8 -49 5 -78 9 -20 18 -25 50 -25 34 0 38 -3 38
-25 0 -14 5 -25 10 -25 6 0 10 33 10 80 0 47 -4 80 -10 80 -5 0 -10 -21 -10
-46 0 -45 0 -45 -32 -42 -31 3 -33 6 -36 46 -3 40 -10 50 -25 35z"
                />
                <path
                    d="M5079 6021 c-13 -11 -21 -23 -17 -27 4 -4 14 -1 22 5 18 15 58 11 64
-6 4 -13 -3 -17 -50 -27 -35 -8 -53 -39 -38 -66 12 -23 52 -36 72 -24 7 5 21
7 31 6 27 -5 25 110 -3 138 -25 25 -51 25 -81 1z m71 -80 c0 -24 -14 -41 -35
-41 -32 0 -42 20 -20 37 21 15 55 18 55 4z"
                />
                <path
                    d="M5244 6015 c-53 -53 -8 -158 60 -141 25 6 53 42 42 53 -3 4 -14 -1
-24 -10 -42 -39 -90 -5 -77 54 8 36 47 50 74 25 22 -20 31 -20 31 -2 0 18 -38
46 -63 46 -10 0 -29 -11 -43 -25z"
                />
                <path
                    d="M5377 6034 c-11 -12 3 -24 27 -24 23 0 24 -2 18 -65 -4 -56 -2 -65
12 -65 13 0 16 11 16 65 0 63 1 65 25 65 16 0 25 6 25 15 0 12 -13 15 -58 15
-32 0 -62 -3 -65 -6z"
                />
                <path
                    d="M5540 5960 c0 -98 13 -104 61 -28 l34 53 3 -53 c2 -38 7 -52 18 -52
12 0 14 14 12 77 -2 47 -7 78 -15 80 -6 2 -29 -22 -50 -54 l-38 -58 -3 58 c-5
86 -22 68 -22 -23z"
                />
                <path
                    d="M5785 6005 c-33 -19 -60 -40 -60 -46 0 -7 28 -28 63 -46 45 -25 62
-31 62 -20 0 8 -20 25 -45 38 -25 13 -45 26 -45 29 0 4 20 16 45 28 25 12 45
29 45 37 0 8 -1 15 -2 14 -2 0 -30 -16 -63 -34z"
                />
                <path
                    d="M6033 6025 c-45 -32 -38 -124 12 -147 29 -13 71 1 82 27 9 24 -5 30
-25 12 -36 -33 -72 -14 -72 38 0 50 42 74 70 40 16 -19 30 -19 30 -1 0 17 -38
46 -60 46 -9 0 -25 -7 -37 -15z"
                />
                <path
                    d="M6189 6021 c-13 -11 -21 -23 -17 -27 4 -4 14 -1 22 5 19 16 61 10 61
-9 0 -8 -14 -17 -34 -21 -49 -10 -67 -32 -53 -63 12 -27 52 -43 74 -30 7 5 21
7 31 6 27 -5 25 110 -3 138 -25 25 -51 25 -81 1z m69 -93 c-3 -25 -43 -37 -61
-19 -15 15 10 41 40 41 20 0 24 -4 21 -22z"
                />
                <path
                    d="M6455 6015 c-17 -16 -25 -35 -25 -60 0 -38 35 -85 64 -85 29 0 66 20
66 35 0 17 -16 20 -25 5 -9 -14 -48 -13 -63 2 -26 26 -13 38 43 38 49 0 55 2
55 20 0 27 -43 70 -70 70 -11 0 -32 -11 -45 -25z m83 -27 c3 -15 -4 -18 -37
-18 -43 0 -50 7 -29 28 18 18 62 11 66 -10z"
                />
                <path
                    d="M6621 6014 c-50 -63 -3 -150 70 -131 16 4 20 1 17 -16 -4 -26 -50
-36 -74 -16 -19 16 -30 4 -14 -16 7 -9 29 -15 51 -15 55 0 69 25 69 127 0 77
-12 112 -29 85 -4 -7 -12 -8 -21 -2 -26 16 -48 11 -69 -16z m77 -16 c17 -17
15 -73 -3 -88 -12 -10 -21 -10 -40 -2 -43 20 -30 102 15 102 9 0 21 -5 28 -12z"
                />
                <path
                    d="M6805 6015 c-17 -16 -25 -35 -25 -60 0 -25 8 -44 25 -60 29 -30 55
-32 86 -6 19 15 24 29 24 66 0 37 -5 51 -24 66 -31 26 -57 24 -86 -6z m73 -17
c17 -17 15 -73 -3 -88 -40 -33 -86 20 -65 74 10 28 47 35 68 14z"
                />
                <path
                    d="M6960 5953 c0 -54 4 -82 10 -78 6 3 10 28 10 55 0 64 7 80 37 80 40
0 21 24 -20 26 l-37 1 0 -84z"
                />
                <path
                    d="M7060 6032 c0 -5 12 -40 26 -78 28 -75 26 -108 -8 -119 -19 -6 -20
-8 -6 -12 34 -9 53 15 86 109 35 101 37 108 23 108 -6 0 -20 -27 -32 -60 -12
-33 -23 -59 -25 -57 -2 2 -12 28 -22 57 -16 47 -42 79 -42 52z"
                />
                <path
                    d="M7240 6025 c0 -8 18 -25 40 -37 22 -13 40 -26 40 -30 0 -4 -18 -17
-40 -28 -22 -11 -40 -27 -40 -35 0 -11 17 -6 62 20 68 37 80 51 51 62 -10 4
-39 19 -65 35 -36 20 -48 24 -48 13z"
                />
                <path d="M1317 5903 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                <path
                    d="M1402 5896 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path
                    d="M1482 5894 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"
                />
                <path
                    d="M1562 5894 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"
                />
                <path d="M1644 5899 c-3 -6 0 -15 7 -20 14 -8 32 9 23 23 -7 11 -22 10 -30 -3z" />
                <path
                    d="M1732 5896 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path
                    d="M1812 5894 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"
                />
                <path d="M1894 5899 c-3 -6 0 -15 7 -20 14 -8 32 9 23 23 -7 11 -22 10 -30 -3z" />
                <path d="M1976 5901 c-3 -5 1 -14 8 -20 16 -13 32 2 21 19 -8 12 -22 13 -29 1z" />
                <path
                    d="M2062 5896 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path
                    d="M2182 5283 c-36 -130 -36 -133 -22 -133 9 0 21 34 58 178 13 49 16
72 8 72 -6 0 -26 -53 -44 -117z"
                />
                <path d="M3327 5383 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                <path
                    d="M2604 5355 c3 -9 0 -15 -9 -15 -19 0 -20 -19 -1 -26 9 -3 13 -20 11
-48 -1 -24 2 -52 6 -63 8 -22 46 -37 55 -21 3 5 -3 12 -15 15 -18 5 -21 13
-21 58 0 38 4 55 15 59 8 3 15 10 15 16 0 5 -7 10 -15 10 -8 0 -15 7 -15 15 0
8 -7 15 -16 15 -10 0 -14 -6 -10 -15z"
                />
                <path
                    d="M2045 5307 c-33 -18 -60 -39 -60 -45 0 -7 28 -28 63 -47 46 -26 62
-31 62 -20 0 8 -20 25 -45 37 -25 12 -45 24 -45 28 0 3 20 16 45 29 25 13 45
30 45 37 0 17 5 19 -65 -19z"
                />
                <path
                    d="M2281 5314 c-25 -31 -27 -64 -6 -104 24 -47 83 -52 106 -8 15 27 3
35 -20 14 -25 -23 -52 -17 -69 14 -21 39 -2 80 38 80 16 0 30 -4 30 -10 0 -5
7 -10 15 -10 22 0 18 17 -7 34 -33 23 -64 19 -87 -10z"
                />
                <path
                    d="M2446 5325 c-14 -11 -17 -18 -9 -26 8 -8 13 -8 17 0 11 16 66 14 66
-3 0 -15 -21 -26 -49 -26 -27 0 -54 -35 -46 -60 8 -25 50 -44 72 -31 9 4 24 6
34 4 17 -5 19 1 19 56 0 48 -4 65 -20 81 -23 23 -57 25 -84 5z m74 -88 c0 -19
-21 -37 -42 -37 -24 0 -35 29 -17 41 21 13 59 11 59 -4z"
                />
                <path
                    d="M2715 5315 c-62 -61 3 -168 79 -133 14 6 26 17 26 25 0 15 -16 17
-25 3 -9 -14 -48 -13 -63 2 -26 26 -13 38 43 38 47 0 55 3 55 19 0 29 -39 71
-66 71 -14 0 -36 -11 -49 -25z m83 -27 c3 -15 -4 -18 -37 -18 -44 0 -44 0 -35
25 9 22 68 17 72 -7z"
                />
                <path
                    d="M2881 5314 c-12 -15 -21 -39 -21 -54 0 -57 64 -101 99 -67 10 10 12
7 9 -12 -5 -35 -31 -48 -62 -31 -32 16 -49 4 -22 -16 28 -20 73 -17 96 6 17
17 20 33 20 110 0 73 -3 90 -15 90 -8 0 -15 -5 -15 -12 0 -9 -3 -9 -12 0 -20
20 -55 13 -77 -14z m77 -16 c17 -17 15 -64 -4 -82 -18 -19 -20 -19 -45 -6 -19
11 -26 51 -13 84 7 19 45 21 62 4z"
                />
                <path
                    d="M3061 5314 c-58 -73 20 -182 90 -125 34 28 35 104 0 132 -33 27 -66
24 -90 -7z m83 -20 c29 -74 -36 -129 -73 -62 -22 38 -2 78 38 78 17 0 31 -6
35 -16z"
                />
                <path
                    d="M3220 5259 c0 -46 4 -79 10 -79 6 0 10 24 10 53 0 58 7 73 33 78 33
7 22 24 -15 25 l-38 1 0 -78z"
                />
                <path
                    d="M3327 5333 c-4 -3 -7 -39 -7 -80 0 -58 3 -73 15 -73 12 0 15 16 15
80 0 73 -6 91 -23 73z"
                />
                <path
                    d="M3415 5315 c-58 -57 -3 -163 70 -135 25 10 40 28 31 37 -4 4 -12 1
-18 -5 -25 -25 -78 -8 -78 25 0 9 17 13 55 13 47 0 55 3 55 19 0 29 -39 71
-66 71 -14 0 -36 -11 -49 -25z m83 -27 c3 -15 -4 -18 -37 -18 -44 0 -44 0 -35
25 9 22 68 17 72 -7z"
                />
                <path
                    d="M3588 5329 c-21 -12 -24 -52 -5 -67 6 -6 25 -14 40 -17 18 -5 27 -13
25 -24 -4 -22 -36 -26 -58 -6 -26 23 -36 1 -10 -25 24 -24 48 -25 78 -4 42 29
15 71 -63 97 -11 4 12 37 26 37 4 0 15 -7 23 -16 23 -22 42 -7 20 17 -19 21
-49 24 -76 8z"
                />
                <path
                    d="M3725 5330 c-3 -6 14 -22 39 -37 26 -15 46 -31 46 -34 0 -3 -20 -16
-45 -29 -29 -15 -42 -27 -36 -33 11 -11 121 49 121 66 0 16 -118 79 -125 67z"
                />
                <path
                    d="M1310 5195 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1400 5195 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"
                />
                <path
                    d="M1480 5195 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1560 5195 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1645 5201 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"
                />
                <path
                    d="M1730 5195 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1810 5195 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1890 5195 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"
                />
                <path
                    d="M7513 5125 c-39 -17 -53 -42 -53 -95 0 -43 33 -90 64 -90 53 0 89 47
76 100 -8 33 -52 55 -81 40 -11 -7 -23 -9 -26 -6 -3 3 6 10 21 16 14 5 37 14
51 20 14 5 25 14 25 20 0 14 -39 12 -77 -5z m47 -80 c15 -19 6 -61 -16 -69
-35 -13 -60 44 -32 72 16 16 33 15 48 -3z"
                />
                <path
                    d="M8753 5133 c-61 -12 -86 -121 -38 -168 13 -14 31 -25 39 -25 74 0
110 97 50 137 -20 13 -30 14 -51 4 -14 -6 -28 -9 -31 -7 -2 3 7 10 22 16 14 5
37 14 51 20 52 20 23 36 -42 23z m37 -88 c15 -19 6 -61 -16 -69 -35 -13 -60
44 -32 72 16 16 33 15 48 -3z"
                />
                <path
                    d="M6276 5068 c-20 -28 -20 -77 -1 -101 24 -33 115 -21 115 14 0 12 -34
11 -46 -1 -14 -14 -44 -2 -44 16 0 10 13 14 45 14 41 0 45 2 45 23 0 54 -84
79 -114 35z m78 -23 c4 -11 -2 -15 -24 -15 -29 0 -38 9 -23 23 12 12 41 7 47
-8z"
                />
                <path
                    d="M6443 5082 c-6 -4 -16 -19 -22 -34 -24 -63 30 -123 86 -96 29 14 44
48 22 48 -7 0 -20 -7 -30 -15 -15 -14 -18 -14 -33 0 -17 17 -20 34 -10 59 7
17 44 22 44 6 0 -5 9 -10 20 -10 22 0 26 20 8 38 -13 13 -66 16 -85 4z"
                />
                <path
                    d="M6590 5043 c0 -28 -6 -53 -16 -64 -9 -10 -13 -23 -9 -28 18 -30 51 5
59 64 6 36 11 45 27 45 16 0 19 -7 19 -55 0 -48 2 -55 20 -55 18 0 20 7 20 70
l0 70 -60 0 -60 0 0 -47z"
                />
                <path
                    d="M6750 5021 c0 -68 1 -71 23 -71 17 0 29 10 42 38 l20 37 3 -37 c2
-30 7 -38 23 -38 17 0 19 7 19 70 0 64 -2 70 -20 70 -13 0 -28 -13 -41 -35
-26 -43 -34 -44 -34 -3 0 23 -5 34 -17 36 -16 3 -18 -5 -18 -67z"
                />
                <path
                    d="M6980 5075 c0 -8 9 -15 20 -15 18 0 20 -7 20 -55 0 -48 2 -55 20 -55
18 0 20 7 20 55 0 48 2 55 20 55 11 0 20 7 20 15 0 12 -13 15 -60 15 -47 0
-60 -3 -60 -15z"
                />
                <path
                    d="M7136 5074 c-55 -55 -5 -147 70 -128 29 8 54 44 29 44 -8 0 -24 -5
-34 -12 -15 -9 -23 -9 -35 1 -27 23 -18 31 34 31 55 0 64 15 34 58 -18 26 -74
30 -98 6z m72 -31 c2 -9 -7 -13 -27 -13 -23 0 -29 4 -24 16 6 17 44 15 51 -3z"
                />
                <path
                    d="M7270 5020 c0 -63 2 -70 20 -70 18 0 20 7 20 55 0 54 0 55 30 55 20
0 30 5 30 15 0 11 -12 15 -50 15 l-50 0 0 -70z"
                />
                <path
                    d="M7646 5018 c28 -83 29 -98 9 -98 -8 0 -15 -7 -15 -15 0 -24 45 -18
58 8 10 19 62 165 62 174 0 2 -8 3 -19 3 -13 0 -22 -13 -33 -52 l-14 -53 -18
53 c-13 38 -23 52 -36 52 -18 0 -17 -4 6 -72z"
                />
                <path
                    d="M7806 5048 c-4 -24 -14 -53 -22 -66 -19 -29 -15 -66 9 -70 12 -2 17
3 17 17 0 18 6 21 44 21 35 0 45 -4 49 -20 8 -32 37 -25 37 9 0 17 -4 33 -10
36 -5 3 -10 31 -10 61 l0 54 -54 0 -53 0 -7 -42z m74 -28 c0 -36 -2 -40 -26
-40 -20 0 -25 4 -20 16 3 9 6 27 6 40 0 17 6 24 20 24 17 0 20 -7 20 -40z"
                />
                <path
                    d="M7967 5072 c-20 -22 -22 -81 -4 -105 25 -33 102 -26 115 11 5 15 -30
16 -45 1 -15 -15 -43 -4 -43 16 0 11 11 15 45 15 50 0 58 16 29 58 -19 28 -74
30 -97 4z m71 -29 c2 -8 -6 -13 -22 -13 -25 0 -33 10 -19 24 10 10 36 3 41
-11z"
                />
                <path
                    d="M8100 5075 c0 -8 9 -15 20 -15 18 0 20 -7 20 -55 0 -48 2 -55 20 -55
18 0 20 7 20 55 0 48 2 55 20 55 11 0 20 7 20 15 0 12 -13 15 -60 15 -47 0
-60 -3 -60 -15z"
                />
                <path
                    d="M8320 5020 l0 -71 56 3 c62 3 84 20 69 54 -4 11 -7 30 -6 43 4 31
-13 41 -71 41 l-48 0 0 -70z m88 28 c-7 -20 -48 -23 -48 -4 0 11 9 16 26 16
16 0 24 -5 22 -12z m-3 -58 c0 -8 -10 -16 -22 -18 -18 -3 -23 2 -23 18 0 16 5
21 23 18 12 -2 22 -10 22 -18z"
                />
                <path
                    d="M8480 5020 l0 -70 53 0 c55 0 77 12 77 40 0 25 -33 50 -63 50 -22 0
-27 5 -27 25 0 18 -5 25 -20 25 -18 0 -20 -7 -20 -70z m85 -30 c0 -8 -10 -16
-22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z"
                />
                <path
                    d="M8627 5083 c-4 -3 -7 -35 -7 -70 0 -56 2 -63 20 -63 18 0 20 7 20 70
0 52 -3 70 -13 70 -8 0 -17 -3 -20 -7z"
                />
                <path
                    d="M8860 4989 c0 -92 1 -99 20 -99 15 0 20 7 20 28 0 26 3 28 36 28 44
0 64 23 64 74 0 51 -23 69 -88 68 l-52 0 0 -99z m98 35 c4 -38 -20 -63 -43
-44 -15 13 -21 61 -8 74 4 4 16 6 28 4 14 -2 21 -12 23 -34z"
                />
                <path
                    d="M9032 5078 c-7 -7 -12 -18 -12 -25 0 -15 26 -18 35 -3 8 13 45 13 45
0 0 -11 -7 -14 -52 -24 -24 -5 -28 -11 -28 -40 0 -41 13 -47 77 -39 l48 5 -3
57 c-2 31 -9 62 -14 69 -13 15 -80 16 -96 0z m68 -83 c0 -9 -7 -18 -16 -22
-18 -7 -39 11 -30 26 11 17 46 13 46 -4z"
                />
                <path
                    d="M9180 5021 c0 -64 2 -71 20 -71 16 0 20 7 20 30 0 25 4 30 25 30 21
0 25 -5 25 -30 0 -24 4 -30 20 -30 19 0 21 5 18 67 -2 55 -6 68 -20 71 -13 3
-18 -3 -18 -22 0 -21 -5 -26 -24 -26 -18 0 -25 6 -28 22 -2 13 -11 24 -20 26
-16 3 -18 -5 -18 -67z"
                />
                <path
                    d="M9420 5020 c0 -54 3 -70 14 -70 9 0 16 12 18 28 2 21 9 28 31 30 24
3 27 0 27 -27 0 -24 4 -31 20 -31 18 0 20 7 20 70 0 63 -2 70 -20 70 -15 0
-20 -7 -20 -25 0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0 16 -6 25 -15 25
-12 0 -15 -14 -15 -70z"
                />
                <path
                    d="M9591 5077 c-36 -44 -17 -118 33 -131 31 -8 76 9 76 29 0 18 -14 19
-41 4 -15 -7 -24 -7 -35 2 -25 21 -15 29 36 29 50 0 50 0 44 28 -4 15 -11 33
-17 40 -13 16 -82 15 -96 -1z m77 -34 c2 -9 -7 -13 -27 -13 -30 0 -39 9 -24
24 11 10 46 3 51 -11z"
                />
                <path
                    d="M9730 5020 c0 -63 2 -70 20 -70 18 0 20 7 20 55 0 52 1 55 25 55 24
0 25 -3 25 -55 0 -48 2 -55 20 -55 18 0 20 7 20 70 l0 70 -65 0 -65 0 0 -70z"
                />
                <path
                    d="M9900 4989 c0 -81 3 -99 15 -99 11 0 15 11 15 35 0 32 2 35 19 25 22
-11 64 0 76 21 12 19 9 87 -5 104 -8 10 -29 14 -66 14 l-54 -2 0 -98z m89 49
c15 -41 -9 -74 -41 -57 -32 17 -17 79 19 79 8 0 17 -10 22 -22z"
                />
                <path
                    d="M10072 5078 c-7 -7 -12 -18 -12 -25 0 -15 26 -18 35 -3 8 13 45 13
45 0 0 -11 -7 -15 -51 -24 -29 -6 -43 -29 -34 -56 8 -25 28 -31 80 -23 l45 6
0 56 c0 66 -11 81 -60 81 -20 0 -41 -5 -48 -12z m64 -93 c-7 -20 -46 -19 -46
0 0 9 10 19 23 24 24 9 33 0 23 -24z"
                />
                <path
                    d="M10220 5020 l0 -71 58 3 57 3 2 55 c1 30 -3 60 -9 67 -6 7 -32 13
-59 13 l-49 0 0 -70z m78 28 c-3 -7 -13 -13 -23 -13 -10 0 -20 6 -22 13 -3 7
5 12 22 12 17 0 25 -5 23 -12z m7 -58 c0 -9 -11 -16 -27 -18 -23 -3 -28 1 -28
18 0 17 5 21 28 18 16 -2 27 -9 27 -18z"
                />
                <path
                    d="M10380 5020 c0 -62 2 -70 19 -70 12 0 29 15 43 38 l23 37 3 -37 c3
-30 7 -38 23 -38 18 0 20 6 17 68 -3 58 -6 67 -23 70 -14 2 -27 -10 -45 -40
l-25 -43 -3 43 c-2 28 -8 42 -18 42 -11 0 -14 -16 -14 -70z"
                />
                <path
                    d="M10560 5049 c0 -22 -8 -54 -17 -70 -19 -32 -14 -44 15 -35 22 7 32
33 32 84 0 27 3 32 25 32 24 0 25 -3 25 -55 0 -42 3 -55 15 -55 12 0 15 14 15
70 l0 70 -55 0 -55 0 0 -41z"
                />
                <path
                    d="M10720 5020 l0 -70 53 0 c29 0 58 5 65 12 31 31 -3 78 -56 78 -27 0
-32 4 -32 25 0 16 -6 25 -15 25 -12 0 -15 -14 -15 -70z m85 -30 c0 -23 -49
-20 -53 3 -3 15 2 18 25 15 17 -2 28 -9 28 -18z"
                />
                <path
                    d="M10870 5020 c0 -63 2 -70 20 -70 16 0 20 7 20 31 0 27 3 30 28 27 22
-2 27 -8 27 -30 -1 -20 4 -28 17 -28 16 0 18 9 18 70 0 54 -3 70 -14 70 -8 0
-16 -10 -18 -22 -2 -17 -11 -24 -30 -26 -24 -3 -28 0 -28 22 0 19 -5 26 -20
26 -18 0 -20 -7 -20 -70z"
                />
                <path
                    d="M11056 5074 c-38 -38 -28 -106 18 -124 77 -29 135 60 79 123 -20 21
-76 22 -97 1z m72 -26 c30 -30 -3 -87 -39 -68 -19 10 -27 59 -12 73 10 11 38
8 51 -5z"
                />
                <path
                    d="M11342 5023 c2 -13 12 -18 38 -18 51 0 48 29 -3 33 -33 3 -38 1 -35
-15z"
                />
                <path
                    d="M11872 4813 c4 -24 66 -31 74 -8 9 22 -3 30 -21 15 -12 -10 -19 -10
-27 -2 -17 17 -30 15 -26 -5z"
                />
                <path
                    d="M9635 4812 c-52 -17 -75 -50 -75 -105 0 -49 33 -87 74 -87 43 0 70
30 70 77 0 43 -26 66 -71 61 -25 -3 -30 -1 -21 9 6 7 19 13 28 13 29 0 50 11
50 26 0 15 -21 17 -55 6z m25 -86 c28 -34 -11 -94 -44 -67 -21 18 -20 57 2 70
23 14 28 13 42 -3z"
                />
                <path
                    d="M10720 4720 l0 -100 49 0 c27 0 61 4 75 10 29 11 44 54 29 79 -6 9
-8 32 -5 50 6 46 -16 61 -90 61 l-58 0 0 -100z m113 53 c13 -12 -15 -33 -44
-33 -22 0 -29 5 -29 20 0 16 7 20 33 20 19 0 37 -3 40 -7z m2 -88 c0 -16 -8
-21 -37 -23 -35 -3 -38 -1 -38 23 0 24 3 26 38 23 29 -2 37 -7 37 -23z"
                />
                <path
                    d="M5554 4756 c-3 -7 -4 -40 -2 -72 2 -47 7 -59 21 -62 14 -3 17 5 18
50 1 57 2 56 19 -9 14 -57 35 -55 53 5 l15 47 1 -47 c1 -36 5 -48 16 -48 12 0
15 15 15 75 0 73 -1 75 -25 75 -19 0 -26 -7 -31 -27 -3 -16 -9 -39 -13 -53 -8
-24 -8 -24 -15 10 -14 65 -17 70 -42 70 -14 0 -27 -6 -30 -14z"
                />
                <path
                    d="M5757 4764 c-4 -4 -7 -38 -7 -76 l0 -68 43 0 c48 0 87 22 87 50 0 23
-36 50 -67 50 -15 0 -23 7 -25 22 -3 22 -19 33 -31 22z m78 -94 c0 -8 -10 -16
-22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z"
                />
                <path
                    d="M5894 4756 c-3 -7 -4 -40 -2 -72 2 -47 7 -59 21 -62 15 -3 17 6 17
72 0 62 -3 76 -15 76 -9 0 -18 -6 -21 -14z"
                />
                <path
                    d="M6047 4763 c-4 -3 -7 -37 -7 -75 0 -61 2 -68 20 -68 16 0 20 7 20 30
0 27 3 30 30 30 27 0 30 -3 30 -30 0 -20 5 -30 15 -30 12 0 15 15 15 75 0 60
-3 75 -15 75 -9 0 -15 -9 -15 -25 0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0
24 -18 34 -33 18z"
                />
                <path
                    d="M6215 4750 c-25 -27 -24 -83 0 -110 23 -25 82 -24 99 2 15 24 0 34
-29 18 -22 -12 -28 -12 -42 3 -16 16 -14 17 36 17 l54 0 -7 31 c-11 57 -75 79
-111 39z m73 -27 c2 -9 -7 -13 -27 -13 -23 0 -29 4 -24 16 6 17 44 15 51 -3z"
                />
                <path
                    d="M6445 4750 c-24 -26 -23 -84 1 -111 22 -24 66 -25 87 -1 33 36 12 58
-25 26 -14 -14 -20 -14 -33 -4 -19 16 -19 54 0 70 12 10 19 10 27 2 17 -17 48
-15 48 3 0 36 -76 47 -105 15z"
                />
                <path
                    d="M6587 4763 c-4 -3 -7 -37 -7 -75 0 -61 2 -68 20 -68 17 0 20 7 21 53
l1 52 12 -50 c16 -69 40 -72 59 -8 l15 48 1 -47 c1 -36 5 -48 16 -48 12 0 15
15 15 75 0 73 -1 75 -25 75 -20 0 -26 -7 -35 -42 -6 -24 -12 -47 -14 -53 -1
-5 -8 14 -15 43 -11 43 -17 52 -35 52 -13 0 -26 -3 -29 -7z"
                />
                <path
                    d="M6792 4750 c-29 -27 -29 -83 -2 -110 45 -45 120 -11 120 55 0 66 -70
99 -118 55z m76 -51 c2 -26 -2 -38 -12 -42 -28 -11 -46 4 -46 37 0 39 7 48 35
44 15 -2 21 -12 23 -39z"
                />
                <path
                    d="M6940 4763 c0 -5 9 -21 19 -37 20 -29 20 -29 -5 -61 -29 -39 -30 -45
-5 -45 11 0 28 13 38 30 9 17 21 30 25 30 4 0 8 -13 8 -30 0 -20 5 -30 15 -30
10 0 15 10 15 30 0 17 4 30 8 30 4 0 16 -13 25 -30 10 -17 27 -30 38 -30 24 0
24 1 -2 44 l-21 36 22 35 c20 32 20 35 4 35 -10 0 -30 -15 -45 -32 l-28 -33
-1 33 c0 22 -5 32 -15 32 -10 0 -15 -10 -15 -32 l-1 -33 -27 33 c-25 30 -52
43 -52 25z"
                />
                <path
                    d="M7180 4750 c-45 -45 -13 -130 48 -130 26 0 62 25 62 43 0 11 -29 8
-50 -5 -16 -10 -23 -10 -37 5 -16 16 -15 17 35 17 49 0 52 2 52 25 0 32 -33
65 -65 65 -14 0 -34 -9 -45 -20z m68 -27 c2 -8 -6 -13 -22 -13 -25 0 -33 10
-19 24 10 10 36 3 41 -11z"
                />
                <path
                    d="M7320 4694 c0 -67 2 -75 18 -72 12 3 17 13 17 41 0 47 9 47 21 0 13
-53 40 -56 49 -6 11 66 25 74 25 16 0 -40 4 -53 15 -53 12 0 15 15 15 76 l0
75 -27 -3 c-24 -3 -29 -9 -37 -45 -10 -47 -26 -58 -26 -17 0 39 -18 64 -45 64
-24 0 -25 -2 -25 -76z"
                />
                <path
                    d="M7597 4763 c-4 -3 -7 -37 -7 -75 0 -61 2 -68 20 -68 16 0 20 7 20 30
0 17 4 30 9 30 6 0 18 -13 28 -30 11 -19 25 -30 40 -30 29 0 29 6 -2 46 -30
39 -31 46 -5 69 11 10 20 22 20 27 0 17 -35 7 -62 -19 l-28 -27 0 27 c0 25
-18 36 -33 20z"
                />
                <path
                    d="M7769 4751 c-17 -13 -23 -29 -23 -56 0 -70 81 -102 123 -49 28 35 26
69 -4 99 -29 30 -64 32 -96 6z m69 -23 c25 -25 8 -78 -25 -78 -22 0 -37 44
-24 69 13 24 31 27 49 9z"
                />
                <path
                    d="M7920 4670 c0 -82 3 -100 15 -100 10 0 15 10 15 29 0 28 2 29 33 24
41 -6 71 23 71 72 0 58 -58 97 -92 63 -9 -9 -12 -9 -12 0 0 7 -7 12 -15 12
-12 0 -15 -18 -15 -100z m90 44 c20 -51 -29 -89 -51 -40 -8 17 -8 29 0 45 16
29 39 27 51 -5z"
                />
                <path
                    d="M8087 4763 c-4 -3 -7 -48 -7 -100 0 -86 1 -93 20 -93 15 0 20 7 20
29 0 28 2 29 34 24 27 -4 36 -1 50 18 20 30 21 78 0 107 -16 23 -53 29 -71 11
-8 -8 -13 -8 -17 0 -8 12 -20 14 -29 4z m83 -37 c16 -20 9 -56 -13 -70 -14 -8
-20 -8 -27 4 -13 21 -12 54 2 68 16 16 23 15 38 -2z"
                />
                <path
                    d="M8260 4750 c-12 -12 -20 -33 -20 -55 0 -65 74 -101 115 -55 24 26 13
36 -24 20 -30 -12 -51 -8 -51 11 0 5 20 9 45 9 41 0 45 2 45 25 0 14 -9 34
-20 45 -11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z m68 -27 c2 -8 -6 -13 -22
-13 -25 0 -33 10 -19 24 10 10 36 3 41 -11z"
                />
                <path
                    d="M8404 4756 c-3 -7 -4 -40 -2 -72 2 -47 7 -59 21 -62 13 -3 17 3 17
27 0 39 14 39 37 1 11 -19 25 -30 40 -30 29 0 29 6 -2 46 -30 39 -31 46 -5 69
11 10 20 22 20 27 0 17 -35 7 -62 -19 l-28 -27 0 27 c0 29 -26 38 -36 13z"
                />
                <path
                    d="M8557 4764 c-13 -13 -7 -24 13 -24 16 0 19 -8 22 -57 2 -46 7 -59 21
-61 15 -3 17 5 17 57 0 59 1 61 26 61 16 0 24 5 22 13 -5 13 -109 23 -121 11z"
                />
                <path
                    d="M8700 4695 c0 -67 2 -76 18 -73 10 2 18 14 20 31 3 21 9 27 28 27 20
0 24 -5 24 -31 0 -24 4 -30 18 -27 14 3 17 15 17 73 0 58 -3 70 -17 73 -13 3
-18 -3 -18 -21 0 -13 -3 -26 -6 -29 -12 -12 -43 5 -46 25 -2 12 -11 23 -20 25
-16 3 -18 -6 -18 -73z"
                />
                <path
                    d="M8880 4750 c-44 -44 -13 -130 48 -130 40 0 72 33 72 75 0 65 -76 99
-120 55z m76 -37 c13 -51 -36 -85 -57 -40 -8 18 -8 30 0 46 17 30 49 27 57 -6z"
                />
                <path
                    d="M9121 4752 c-41 -32 -25 -116 24 -128 55 -14 95 20 95 81 0 54 -75
84 -119 47z m77 -48 c3 -34 -17 -58 -41 -48 -40 15 -25 88 16 82 16 -2 23 -11
25 -34z"
                />
                <path
                    d="M9260 4755 c0 -8 9 -15 20 -15 18 0 20 -7 20 -60 0 -53 2 -60 20 -60
18 0 20 7 20 60 0 53 2 60 20 60 11 0 20 7 20 15 0 12 -13 15 -60 15 -47 0
-60 -3 -60 -15z"
                />
                <path
                    d="M9422 4750 c-29 -27 -29 -83 -2 -110 45 -45 120 -11 120 55 0 41 -32
75 -70 75 -14 0 -36 -9 -48 -20z m62 -16 c21 -8 22 -70 1 -78 -37 -14 -65 26
-45 64 11 21 19 24 44 14z"
                />
                <path
                    d="M9735 4762 c-3 -3 -5 -47 -5 -99 0 -86 1 -93 20 -93 16 0 20 7 20 30
0 28 2 29 30 23 44 -8 70 18 70 72 0 55 -21 74 -82 73 -26 -1 -50 -3 -53 -6z
m89 -38 c20 -19 20 -35 2 -60 -12 -15 -18 -17 -33 -8 -22 14 -29 50 -13 70 16
18 25 18 44 -2z"
                />
                <path
                    d="M9918 4759 c-19 -11 -25 -39 -8 -39 6 0 19 6 30 12 15 10 22 10 31 1
13 -13 9 -16 -43 -27 -31 -7 -44 -36 -29 -65 9 -16 21 -19 66 -19 l55 1 0 58
c0 39 -5 63 -16 73 -17 18 -60 20 -86 5z m60 -86 c-4 -23 -48 -31 -48 -9 0 13
14 21 43 25 5 0 7 -7 5 -16z"
                />
                <path
                    d="M10060 4755 c-18 -21 -3 -32 22 -16 13 8 24 9 30 3 13 -13 2 -32 -17
-32 -9 0 -12 -6 -9 -15 4 -8 12 -15 20 -15 8 0 14 -7 14 -15 0 -8 -9 -15 -20
-15 -11 0 -20 5 -20 10 0 6 -9 10 -20 10 -24 0 -26 -13 -4 -34 25 -26 81 -20
100 9 12 18 13 29 6 38 -6 7 -8 24 -5 37 11 42 -67 71 -97 35z"
                />
                <path
                    d="M10190 4696 c0 -68 2 -76 19 -76 12 0 29 16 45 43 l25 42 1 -43 c0
-36 3 -43 18 -40 14 3 17 15 17 73 0 87 -20 99 -58 34 l-26 -42 -3 39 c-2 27
-8 40 -20 42 -16 3 -18 -6 -18 -72z"
                />
                <path
                    d="M10340 4755 c0 -8 9 -15 20 -15 18 0 20 -7 20 -60 0 -53 2 -60 20
-60 18 0 20 7 20 60 0 53 2 60 20 60 11 0 20 7 20 15 0 12 -13 15 -60 15 -47
0 -60 -3 -60 -15z"
                />
                <path
                    d="M10487 4763 c-4 -7 -2 -108 2 -135 0 -5 21 -8 46 -8 44 0 85 24 85
50 0 21 -41 50 -70 50 -25 0 -30 4 -30 25 0 23 -23 35 -33 18z m88 -93 c0 -9
-11 -16 -27 -18 -23 -3 -28 1 -28 18 0 17 5 21 28 18 16 -2 27 -9 27 -18z"
                />
                <path
                    d="M10928 4759 c-10 -5 -18 -17 -18 -24 0 -17 26 -20 35 -5 3 6 15 10
26 10 30 0 18 -24 -13 -28 -14 -2 -34 -12 -44 -23 -14 -15 -15 -24 -6 -43 10
-22 17 -24 66 -23 l56 1 0 63 c0 51 -3 64 -19 73 -25 13 -61 12 -83 -1z m60
-86 c-4 -23 -48 -31 -48 -9 0 13 14 21 43 25 5 0 7 -7 5 -16z"
                />
                <path
                    d="M11064 4756 c-3 -7 -4 -40 -2 -72 l3 -59 95 0 95 0 0 70 c0 58 -3 70
-17 73 -14 3 -17 -6 -20 -55 -2 -46 -7 -59 -20 -61 -16 -3 -18 5 -18 57 0 54
-2 61 -20 61 -18 0 -20 -7 -20 -61 0 -52 -2 -60 -17 -57 -14 2 -19 15 -21 61
-2 42 -7 57 -18 57 -8 0 -17 -6 -20 -14z"
                />
                <path
                    d="M11370 4695 c0 -60 3 -75 15 -75 12 0 15 13 15 60 l0 60 30 0 30 0 0
-60 c0 -47 3 -60 15 -60 12 0 15 15 15 75 l0 75 -60 0 -60 0 0 -75z"
                />
                <path
                    d="M11535 4762 c-3 -3 -5 -47 -5 -99 0 -86 1 -93 20 -93 15 0 20 7 20
28 0 26 3 28 29 24 39 -5 71 27 71 71 0 53 -20 72 -79 73 -28 0 -53 -2 -56 -4z
m83 -34 c28 -28 2 -88 -33 -74 -15 5 -22 66 -8 79 10 11 28 8 41 -5z"
                />
                <path
                    d="M11708 4759 c-10 -5 -18 -17 -18 -24 0 -17 26 -20 35 -5 3 6 15 10
26 10 30 0 18 -24 -13 -28 -14 -2 -34 -12 -44 -23 -14 -16 -15 -24 -6 -44 10
-22 17 -24 67 -23 l55 2 0 62 c0 52 -3 65 -19 74 -25 13 -61 12 -83 -1z m60
-86 c-4 -23 -48 -31 -48 -9 0 13 14 21 43 25 5 0 7 -7 5 -16z"
                />
                <path
                    d="M11849 4768 c-1 -4 -1 -100 0 -125 2 -36 28 -27 58 19 l28 42 3 -42
c2 -29 8 -42 18 -42 11 0 14 16 14 75 0 90 -15 98 -57 33 l-28 -42 -3 42 c-2
29 -8 42 -18 42 -8 0 -14 -1 -15 -2z"
                />
                <path
                    d="M12028 4753 c-9 -10 -19 -28 -22 -39 -7 -24 9 -74 27 -86 22 -14 64
-8 80 10 33 36 12 58 -25 26 -14 -14 -20 -14 -33 -4 -19 16 -19 54 0 70 12 10
19 10 27 2 17 -17 48 -15 48 3 0 34 -76 47 -102 18z"
                />
                <path
                    d="M2362 4688 c-7 -7 -12 -20 -12 -30 0 -10 -7 -18 -15 -18 -8 0 -15 -4
-15 -9 0 -5 7 -11 15 -15 11 -4 15 -22 15 -71 0 -37 4 -65 10 -65 6 0 10 29
10 66 0 57 3 67 20 71 11 3 20 9 20 14 0 5 -9 9 -20 9 -12 0 -20 7 -20 16 0 9
9 18 20 21 11 3 20 9 20 14 0 13 -34 11 -48 -3z"
                />
                <path
                    d="M2467 4682 c-10 -10 -17 -23 -17 -29 0 -6 -6 -14 -12 -17 -10 -5 -10
-7 0 -12 8 -4 12 -29 12 -75 0 -55 3 -69 15 -69 12 0 15 14 15 70 0 56 3 70
15 70 8 0 15 5 15 10 0 6 -7 10 -15 10 -22 0 -18 31 5 37 11 3 20 9 20 14 0
16 -36 9 -53 -9z"
                />
                <path
                    d="M2041 4607 c-30 -18 -56 -37 -58 -44 -4 -10 102 -73 122 -73 19 0 -2
23 -41 46 l-46 27 38 24 c22 12 42 23 47 23 4 0 7 7 7 15 0 20 -6 18 -69 -18z"
                />
                <path
                    d="M2187 4628 c-59 -45 -32 -148 39 -148 41 0 64 30 64 82 0 63 -58 100
-103 66z m67 -24 c30 -30 11 -104 -26 -104 -22 0 -48 33 -48 61 0 29 23 59 45
59 7 0 21 -7 29 -16z"
                />
                <path
                    d="M2554 4619 c-11 -13 -19 -35 -19 -59 0 -24 8 -46 19 -59 25 -27 77
-28 101 -1 20 22 6 27 -24 8 -24 -15 -71 3 -71 27 0 12 12 15 55 15 52 0 55 1
55 25 0 57 -78 86 -116 44z m67 -9 c34 -19 23 -40 -21 -40 -43 0 -55 20 -22
39 21 13 20 13 43 1z"
                />
                <path
                    d="M2710 4557 c0 -58 3 -78 13 -75 7 3 13 28 15 66 3 56 5 62 27 68 39
10 29 23 -15 21 l-40 -1 0 -79z"
                />
                <path
                    d="M2830 4628 c0 -8 18 -24 40 -37 22 -12 40 -26 40 -30 0 -4 -18 -16
-40 -26 -22 -10 -40 -24 -40 -32 0 -7 2 -13 4 -13 13 0 119 67 119 74 -1 5
-29 25 -62 44 -43 24 -61 30 -61 20z"
                />
                <path d="M1895 4510 c-10 -17 12 -37 25 -24 8 8 6 15 -4 23 -10 9 -16 9 -21 1z" />
                <path
                    d="M1312 4498 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path d="M1400 4494 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M1480 4495 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1560 4495 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1642 4498 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path
                    d="M1730 4495 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1810 4495 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M140 4328 c-11 -4 -25 -18 -30 -30 -10 -23 -27 -1842 -25 -2603 1
-378 19 -1039 28 -1065 3 -8 11 -89 17 -180 6 -91 14 -173 16 -183 11 -37 55
-46 251 -53 103 -3 225 -12 271 -19 76 -11 82 -11 82 5 0 27 -91 41 -325 49
-116 4 -218 11 -227 15 -18 8 -23 39 -33 211 -4 55 -11 129 -16 165 -28 183
-36 1431 -19 2670 6 393 8 765 4 825 -4 71 -2 120 5 138 l11 28 225 -3 c170
-2 226 0 229 9 3 7 0 15 -5 19 -15 9 -434 11 -459 2z"
                />
                <path
                    d="M1086 4304 c-3 -8 -6 -18 -6 -20 0 -2 493 -4 1095 -4 929 0 1095 2
1095 14 0 8 -7 17 -15 20 -9 3 -499 6 -1089 6 -972 0 -1074 -1 -1080 -16z"
                />
                <path
                    d="M5914 3955 c-27 -72 -5 -215 33 -215 11 0 11 7 -2 36 -19 46 -19 144
-1 189 13 29 13 35 1 35 -7 0 -21 -20 -31 -45z"
                />
                <path
                    d="M8765 3983 c25 -57 29 -115 13 -176 -17 -66 -15 -84 7 -53 36 48 38
165 4 227 -13 24 -34 25 -24 2z"
                />
                <path
                    d="M9105 3988 c-3 -7 -16 -56 -29 -108 -14 -52 -28 -103 -31 -112 -5
-12 -2 -18 7 -18 10 0 23 32 42 103 14 56 29 112 32 125 7 23 -13 32 -21 10z"
                />
                <path
                    d="M3230 3968 c-25 -23 -11 -40 15 -18 12 10 15 -1 17 -74 4 -120 22
-110 26 14 2 92 1 100 -16 100 -10 0 -29 -10 -42 -22z"
                />
                <path
                    d="M3902 3888 c3 -106 20 -143 26 -55 2 26 7 47 12 47 5 0 26 -22 47
-50 21 -27 44 -49 51 -50 13 0 17 16 4 24 -4 2 -22 25 -40 49 l-32 45 36 43
c52 62 22 64 -32 3 l-43 -49 -1 48 c0 35 -4 47 -15 47 -13 0 -15 -16 -13 -102z"
                />
                <path
                    d="M5307 3972 c-21 -23 -22 -42 -3 -42 7 0 16 9 19 21 4 16 12 20 34 17
48 -5 43 -41 -16 -108 -28 -32 -51 -63 -51 -69 0 -7 25 -11 65 -11 51 0 65 3
65 15 0 11 -11 15 -40 15 -22 0 -40 3 -40 7 0 4 14 21 30 38 62 64 55 135 -14
135 -19 0 -40 -8 -49 -18z"
                />
                <path
                    d="M5480 3963 c0 -16 -3 -38 -6 -51 -6 -22 -4 -23 35 -20 44 2 61 -10
61 -44 0 -18 -25 -48 -41 -48 -5 0 -18 9 -29 20 -23 23 -30 25 -30 7 0 -20 33
-47 59 -47 27 0 71 42 71 68 0 33 -38 72 -71 72 -26 0 -30 3 -27 23 2 18 10
23 46 25 61 5 52 22 -13 22 -53 0 -55 -1 -55 -27z"
                />
                <path
                    d="M6740 3884 c0 -80 3 -105 13 -102 7 3 13 25 15 52 l3 47 47 -2 47 -3
5 -45 c3 -24 11 -46 18 -49 9 -3 12 22 12 102 0 88 -3 106 -15 106 -11 0 -15
-12 -15 -45 l0 -45 -50 0 -50 0 0 45 c0 33 -4 45 -15 45 -12 0 -15 -18 -15
-106z"
                />
                <path
                    d="M8620 3974 c0 -18 39 -28 55 -14 23 19 18 26 -20 26 -21 0 -35 -5
-35 -12z"
                />
                <path
                    d="M2490 3961 c0 -11 -6 -22 -12 -25 -10 -5 -10 -7 0 -12 7 -4 12 -27
12 -63 0 -59 11 -81 42 -81 24 0 23 12 -2 28 -16 9 -20 23 -20 62 0 43 3 50
20 50 11 0 20 5 20 10 0 6 -7 10 -16 10 -9 0 -18 9 -21 20 -7 25 -23 26 -23 1z"
                />
                <path
                    d="M9320 3961 c0 -11 -6 -22 -12 -25 -10 -5 -10 -7 0 -12 7 -3 12 -24
12 -47 0 -60 9 -85 32 -93 26 -8 38 5 15 17 -23 13 -25 119 -2 119 8 0 15 5
15 10 0 6 -7 10 -15 10 -8 0 -15 9 -15 20 0 11 -7 20 -15 20 -8 0 -15 -9 -15
-19z"
                />
                <path
                    d="M2210 3911 c-30 -16 -56 -35 -57 -43 -3 -12 97 -78 118 -78 22 0 5
21 -36 46 -25 14 -45 28 -45 30 0 2 20 15 45 30 25 14 45 30 45 35 0 14 -11
11 -70 -20z"
                />
                <path
                    d="M2340 3926 c-21 -27 -8 -58 30 -71 24 -8 35 -18 35 -31 0 -24 -28
-30 -48 -9 -17 16 -37 20 -37 7 0 -17 37 -42 63 -42 27 0 57 23 57 43 0 21
-14 34 -52 50 -21 9 -38 21 -38 27 0 17 31 24 51 12 29 -19 41 -15 29 8 -13
24 -71 28 -90 6z"
                />
                <path
                    d="M2600 3920 c-27 -27 -27 -89 0 -118 29 -31 73 -29 99 4 59 75 -33
180 -99 114z m78 -12 c7 -7 12 -29 12 -50 0 -31 -5 -41 -25 -50 -29 -13 -50 0
-59 38 -13 50 40 94 72 62z"
                />
                <path
                    d="M2760 3858 c0 -45 4 -78 10 -78 6 0 10 23 10 51 0 56 13 89 35 89 8
0 15 4 15 9 0 5 -16 9 -35 8 l-35 -1 0 -78z"
                />
                <path
                    d="M2870 3913 c-38 -49 -6 -133 52 -133 28 0 58 18 58 35 0 19 -8 19
-26 0 -20 -19 -39 -19 -58 1 -27 26 -18 34 39 34 51 0 55 2 55 23 0 66 -79 92
-120 40z m88 -15 c3 -15 -4 -18 -37 -18 -43 0 -50 7 -29 28 18 18 62 11 66
-10z"
                />
                <path
                    d="M3040 3928 c0 -7 17 -22 37 -33 20 -11 36 -24 36 -30 0 -5 -16 -19
-36 -30 -20 -11 -37 -26 -37 -32 0 -18 1 -17 70 23 33 19 58 39 55 44 -5 8
-110 70 -120 70 -3 0 -5 -6 -5 -12z"
                />
                <path
                    d="M3500 3925 c-16 -20 -5 -32 14 -16 20 17 60 10 64 -10 2 -12 -7 -18
-33 -22 -44 -7 -65 -23 -65 -48 0 -31 28 -49 75 -47 l45 2 0 66 c0 38 -5 71
-12 78 -17 17 -73 15 -88 -3z m80 -83 c0 -23 -19 -42 -42 -42 -27 0 -35 30
-13 46 27 20 55 18 55 -4z"
                />
                <path
                    d="M3668 3929 c-21 -12 -23 -41 -6 -68 9 -15 8 -24 -5 -45 -20 -29 -21
-36 -7 -36 5 0 20 16 32 35 31 47 58 47 58 -1 0 -24 4 -34 13 -32 8 3 13 31
15 81 l3 77 -43 0 c-24 0 -51 -5 -60 -11z m72 -34 c0 -20 -5 -25 -24 -25 -14
0 -28 5 -31 10 -12 19 4 40 30 40 20 0 25 -5 25 -25z"
                />
                <path
                    d="M4082 3831 c2 -62 7 -106 13 -106 6 0 11 14 13 32 3 30 5 31 36 26
27 -4 37 0 54 22 25 31 28 62 10 99 -13 29 -26 35 -86 34 l-43 -1 3 -106z m96
77 c16 -16 16 -80 0 -96 -41 -41 -88 32 -58 89 12 21 40 25 58 7z"
                />
                <path
                    d="M4269 3862 c29 -80 28 -112 -4 -112 -8 0 -15 -7 -15 -16 0 -11 6 -14
23 -9 32 10 32 11 71 118 25 70 31 97 22 97 -14 0 -30 -32 -42 -82 -9 -41 -18
-34 -38 27 -10 33 -23 55 -31 55 -12 0 -9 -17 14 -78z"
                />
                <path
                    d="M4412 3863 c4 -96 22 -103 26 -10 l3 67 34 0 c19 0 35 5 35 10 0 6
-23 10 -50 10 l-51 0 3 -77z"
                />
                <path
                    d="M4550 3913 c-57 -73 36 -177 100 -113 13 13 20 33 20 60 0 76 -76
109 -120 53z m84 -9 c29 -29 9 -104 -27 -104 -20 0 -47 33 -47 57 0 56 40 81
74 47z"
                />
                <path
                    d="M4712 3863 l3 -78 41 -3 c29 -2 48 3 64 16 21 17 23 23 13 45 -6 14
-9 34 -6 46 9 34 -14 51 -69 51 l-49 0 3 -77z m87 44 c14 -18 4 -27 -31 -27
-21 0 -28 5 -28 20 0 24 41 28 59 7z m9 -79 c3 -15 -4 -18 -32 -18 -30 0 -36
4 -36 21 0 17 5 20 32 17 22 -2 34 -9 36 -20z"
                />
                <path
                    d="M4885 3924 c-22 -23 -10 -30 21 -14 18 10 30 11 42 4 29 -18 24 -31
-15 -39 -48 -9 -63 -21 -63 -51 0 -30 26 -44 78 -41 l42 1 0 66 c0 38 -5 71
-12 78 -17 17 -74 15 -93 -4z m79 -73 c20 -31 -36 -64 -63 -36 -15 15 -15 17
3 31 22 16 52 19 60 5z"
                />
                <path
                    d="M5058 3929 c-21 -12 -23 -41 -6 -68 9 -14 8 -23 -5 -42 -19 -27 -21
-39 -7 -39 6 0 20 16 31 35 27 46 57 47 61 3 5 -59 20 -25 22 50 l1 72 -40 0
c-22 0 -48 -5 -57 -11z m72 -34 c0 -21 -5 -25 -29 -25 -16 0 -31 6 -34 13 -8
23 5 37 34 37 24 0 29 -4 29 -25z"
                />
                <path
                    d="M5654 3926 c-26 -19 -10 -33 20 -16 14 7 32 10 40 7 30 -12 16 -33
-28 -45 -50 -13 -63 -35 -41 -69 14 -20 22 -23 63 -19 l47 4 0 66 c0 48 -4 69
-15 76 -22 14 -63 12 -86 -4z m76 -85 c0 -26 -25 -44 -50 -36 -25 8 -26 30 -2
44 28 17 52 13 52 -8z"
                />
                <path
                    d="M6000 3828 c0 -65 4 -108 10 -108 6 0 10 15 10 34 0 34 1 35 34 29
47 -7 76 22 76 77 0 56 -26 80 -85 78 l-45 -2 0 -108z m99 64 c14 -33 1 -79
-25 -89 -18 -7 -54 28 -54 52 0 63 57 90 79 37z"
                />
                <path
                    d="M6320 3920 c-45 -45 -12 -140 49 -140 67 0 95 114 37 152 -21 14 -66
8 -86 -12z m78 -12 c20 -20 15 -86 -8 -98 -45 -24 -86 39 -55 86 17 26 44 31
63 12z"
                />
                <path
                    d="M6480 3860 c0 -47 4 -80 10 -80 6 0 10 16 10 36 0 35 1 35 38 32 34
-3 37 -6 40 -35 6 -65 22 -31 22 47 0 58 -4 80 -13 80 -7 0 -12 -13 -12 -32 0
-31 -2 -33 -35 -33 -32 0 -35 3 -38 33 -6 64 -22 30 -22 -48z"
                />
                <path
                    d="M6966 3924 c-58 -57 -3 -164 71 -138 15 5 29 18 31 28 4 17 3 18 -19
2 -29 -20 -43 -20 -63 0 -27 26 -18 34 40 34 l57 0 -7 33 c-3 17 -14 38 -23
45 -23 17 -68 15 -87 -4z m68 -10 c33 -13 17 -34 -24 -34 -41 0 -49 7 -28 28
13 13 28 15 52 6z"
                />
                <path
                    d="M7120 3860 c0 -47 4 -80 10 -80 6 0 10 13 10 29 0 43 13 45 48 6 29
-34 52 -45 52 -26 0 6 -4 12 -10 16 -5 3 -18 18 -29 34 l-20 28 30 36 c21 26
26 37 16 37 -9 0 -27 -13 -40 -30 -14 -16 -30 -30 -36 -30 -6 0 -11 14 -11 30
0 17 -4 30 -10 30 -6 0 -10 -33 -10 -80z"
                />
                <path
                    d="M7272 3831 c2 -62 7 -106 13 -106 6 0 11 14 13 32 3 30 5 32 33 26
55 -11 90 48 69 113 -12 37 -25 43 -88 42 l-43 -1 3 -106z m96 77 c15 -15 16
-63 2 -89 -24 -43 -70 -12 -70 48 0 32 16 53 40 53 9 0 21 -5 28 -12z"
                />
                <path
                    d="M7463 3933 c-15 -5 -18 -33 -4 -33 6 0 13 5 16 10 3 6 17 10 31 10
38 0 32 -36 -8 -44 -41 -8 -58 -23 -58 -52 0 -30 26 -44 78 -41 l42 1 0 66 c0
38 -5 71 -12 78 -12 12 -62 15 -85 5z m71 -82 c3 -5 -3 -20 -13 -32 -16 -19
-23 -22 -37 -13 -27 16 -28 33 -2 43 30 13 46 13 52 2z"
                />
                <path
                    d="M7621 3914 c-54 -69 15 -164 90 -124 10 6 19 17 19 25 0 19 -10 18
-34 0 -25 -19 -31 -19 -50 1 -19 18 -21 75 -4 92 17 17 55 15 62 -3 7 -19 26
-20 26 0 0 8 -9 19 -19 25 -34 18 -68 12 -90 -16z"
                />
                <path
                    d="M7786 3918 c-9 -12 -16 -39 -16 -60 0 -68 60 -100 111 -59 30 24 33
89 5 120 -26 29 -80 28 -100 -1z m84 -17 c15 -29 12 -62 -7 -83 -20 -22 -31
-23 -55 -1 -35 31 -16 103 27 103 14 0 29 -8 35 -19z"
                />
                <path
                    d="M7950 3860 l0 -80 34 0 c55 0 86 16 86 44 0 14 -5 28 -10 31 -7 4 -7
12 0 25 13 24 13 26 -6 44 -9 10 -33 16 -60 16 l-44 0 0 -80z m88 38 c3 -15
-4 -18 -32 -18 -30 0 -36 4 -36 21 0 17 5 20 32 17 22 -2 34 -9 36 -20z m7
-68 c0 -10 -12 -16 -37 -18 -33 -3 -38 0 -38 18 0 18 5 21 38 18 25 -2 37 -8
37 -18z"
                />
                <path
                    d="M8121 3914 c-12 -15 -21 -39 -21 -54 0 -39 35 -80 69 -80 25 0 61 26
61 43 0 13 -23 7 -35 -10 -11 -15 -17 -15 -39 -5 -21 9 -26 18 -26 50 0 56 40
83 70 47 16 -19 30 -19 30 0 0 8 -9 19 -19 25 -34 18 -68 12 -90 -16z"
                />
                <path
                    d="M8272 3863 c3 -78 20 -107 26 -45 2 17 6 32 9 32 3 0 21 -17 38 -37
40 -47 58 -37 21 11 -14 19 -26 38 -26 41 0 3 12 22 26 41 37 48 8 45 -33 -3
l-32 -38 -1 38 c0 26 -4 37 -15 37 -13 0 -15 -13 -13 -77z"
                />
                <path
                    d="M8444 3932 c-37 -24 -43 -87 -13 -126 35 -44 97 -31 114 25 10 32 -4
88 -25 99 -22 12 -60 13 -76 2z m70 -34 c21 -30 20 -51 -3 -79 -16 -21 -22
-22 -45 -11 -21 9 -26 18 -26 50 0 61 43 84 74 40z"
                />
                <path
                    d="M8590 3860 c0 -97 11 -102 60 -25 l35 55 5 -53 c7 -76 25 -59 28 28
4 93 -12 99 -60 25 -18 -27 -36 -50 -40 -50 -4 0 -6 12 -6 28 1 53 -2 72 -12
72 -6 0 -10 -33 -10 -80z"
                />
                <path
                    d="M8938 3909 c-32 -16 -58 -35 -58 -42 0 -11 106 -77 124 -77 19 0 0
23 -39 46 -25 14 -45 28 -45 30 0 2 20 15 45 30 25 14 45 30 45 35 0 14 -10
11 -72 -22z"
                />
                <path
                    d="M9178 3929 c-21 -12 -24 -42 -5 -56 6 -6 26 -15 42 -20 17 -5 31 -17
33 -25 5 -24 -36 -33 -57 -14 -22 20 -31 20 -31 1 0 -35 69 -46 100 -15 27 27
13 54 -35 71 -41 15 -49 49 -12 49 13 0 28 -5 35 -12 17 -17 28 -2 12 17 -14
17 -56 19 -82 4z"
                />
                <path
                    d="M9443 3928 c-29 -36 -34 -59 -23 -94 28 -84 130 -66 130 24 0 53 -23
82 -64 82 -19 0 -38 -6 -43 -12z m69 -25 c22 -20 23 -51 2 -81 -28 -41 -74
-16 -74 42 0 53 35 72 72 39z"
                />
                <path
                    d="M9592 3862 c2 -49 7 -77 16 -80 8 -3 12 12 12 51 0 68 7 87 31 87 10
0 19 4 19 9 0 6 -18 9 -40 9 l-41 0 3 -76z"
                />
                <path
                    d="M9716 3927 c-9 -7 -20 -31 -23 -52 -9 -73 57 -121 112 -82 10 8 15
19 11 26 -6 9 -13 8 -27 -5 -16 -14 -24 -15 -44 -6 -44 20 -31 41 28 44 51 3
52 4 50 33 -1 16 -10 36 -20 42 -22 17 -64 17 -87 0z m72 -19 c21 -21 13 -28
-28 -28 -22 0 -40 4 -40 8 0 14 23 32 40 32 9 0 21 -5 28 -12z"
                />
                <path
                    d="M9870 3930 c0 -5 20 -21 45 -36 25 -14 45 -27 45 -29 0 -2 -20 -15
-45 -29 -39 -23 -58 -46 -39 -46 18 0 124 66 124 77 0 7 -27 26 -60 43 -65 33
-70 34 -70 20z"
                />
                <path
                    d="M3350 3870 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10
-25 0 -45 -4 -45 -10z"
                />
                <path
                    d="M6170 3870 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10
-25 0 -45 -4 -45 -10z"
                />
                <path d="M1404 3806 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />
                <path
                    d="M1485 3810 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path d="M1565 3810 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
                <path
                    d="M1650 3810 c-12 -7 -12 -12 -2 -22 14 -14 35 1 24 19 -5 8 -13 9 -22
3z"
                />
                <path d="M1815 3810 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
                <path
                    d="M1894 3809 c-8 -14 11 -33 25 -25 11 7 4 36 -9 36 -5 0 -12 -5 -16
-11z"
                />
                <path
                    d="M1981 3806 c-9 -10 -9 -16 -1 -21 17 -10 37 12 24 25 -8 8 -15 6 -23
-4z"
                />
                <path d="M2064 3806 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />
                <path
                    d="M1312 3798 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path d="M1730 3794 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M4980 3268 c-7 -24 -20 -74 -31 -113 -21 -76 -25 -115 -10 -100 10
10 71 218 71 242 0 29 -20 10 -30 -29z"
                />
                <path
                    d="M3560 3191 c0 -67 4 -111 10 -111 6 0 10 41 10 104 0 59 -4 108 -10
111 -6 4 -10 -33 -10 -104z"
                />
                <path
                    d="M3640 3191 l0 -108 48 3 c79 6 120 56 107 130 -8 47 -43 74 -103 79
l-52 5 0 -109z m110 59 c13 -13 20 -33 20 -60 0 -51 -27 -80 -75 -80 l-35 0 0
80 0 80 35 0 c22 0 43 -8 55 -20z"
                />
                <path
                    d="M4052 3273 c-19 -19 -23 -28 -13 -31 8 -3 18 3 23 12 11 20 51 21 67
2 21 -25 -3 -53 -41 -47 -5 0 -8 -5 -8 -13 0 -8 10 -16 21 -18 27 -4 44 -30
36 -52 -8 -21 -65 -22 -73 -1 -4 8 -12 15 -20 15 -35 0 0 -48 41 -56 22 -4 38
0 58 16 31 24 34 46 12 77 -10 14 -12 24 -5 28 19 12 11 63 -12 79 -32 23 -55
20 -86 -11z"
                />
                <path
                    d="M4257 3293 c-42 -11 -62 -115 -32 -173 41 -79 125 -32 125 70 0 42
-23 100 -39 100 -5 0 -15 2 -23 4 -7 2 -21 2 -31 -1z m61 -53 c14 -28 10 -93
-8 -115 -16 -19 -44 -19 -60 1 -17 19 -15 110 1 130 18 21 51 13 67 -16z"
                />
                <path
                    d="M4415 3275 c-21 -20 -25 -34 -25 -85 0 -51 4 -65 25 -85 32 -33 58
-31 89 6 44 52 32 154 -21 178 -34 16 -41 14 -68 -14z m73 -17 c16 -16 15
-110 -1 -132 -31 -43 -81 9 -73 77 7 60 43 86 74 55z"
                />
                <path
                    d="M4613 3290 c-31 -13 -43 -41 -43 -105 0 -46 4 -60 25 -80 30 -31 47
-31 82 -1 25 22 28 31 28 86 0 55 -3 64 -28 86 -31 26 -32 27 -64 14z m57 -39
c14 -28 13 -104 -3 -125 -8 -11 -23 -17 -37 -14 -32 4 -46 58 -30 117 9 34 15
41 35 41 14 0 29 -8 35 -19z"
                />
                <path
                    d="M6275 3288 c-10 -25 -1 -203 10 -201 13 4 19 193 6 206 -7 7 -12 5
-16 -5z"
                />
                <path
                    d="M6355 3277 c-3 -12 -5 -60 -3 -107 3 -78 5 -85 23 -84 11 1 35 2 53
2 50 3 82 40 82 97 0 77 -25 103 -105 111 -40 3 -45 1 -50 -19z m115 -27 c13
-13 20 -33 20 -58 0 -50 -29 -82 -75 -82 l-35 0 0 80 0 80 35 0 c22 0 43 -8
55 -20z"
                />
                <path
                    d="M2670 3266 c0 -8 -7 -16 -15 -20 -19 -7 -20 -26 0 -26 10 0 15 -16
17 -61 2 -45 8 -65 20 -73 13 -8 21 -7 30 2 10 10 8 14 -10 18 -19 6 -22 13
-22 60 0 41 4 54 15 54 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0
8 -4 15 -10 15 -5 0 -10 -6 -10 -14z"
                />
                <path
                    d="M5380 3265 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 5 -15 10
-15 6 0 10 -24 10 -54 0 -36 5 -60 16 -70 20 -20 44 -21 44 -1 0 8 -7 15 -15
15 -12 0 -15 13 -15 55 0 42 3 55 15 55 8 0 15 5 15 10 0 6 -7 13 -15 16 -8 4
-15 12 -15 20 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -15z"
                />
                <path
                    d="M2362 3243 c-18 -7 -42 -57 -42 -88 0 -32 41 -75 73 -75 28 0 62 33
51 51 -5 9 -10 8 -20 -5 -8 -11 -22 -16 -41 -14 -25 3 -28 8 -31 41 -2 21 2
45 9 53 15 18 55 18 63 -1 7 -19 26 -20 26 -1 0 30 -53 53 -88 39z"
                />
                <path
                    d="M2509 3231 c-29 -24 -21 -36 14 -20 30 14 57 6 57 -16 0 -9 -9 -15
-26 -15 -42 0 -64 -17 -64 -51 0 -37 40 -60 70 -41 12 7 21 8 25 2 14 -23 25
5 25 61 0 46 -4 63 -20 79 -25 25 -51 25 -81 1z m69 -93 c-2 -18 -11 -24 -35
-26 -37 -4 -46 19 -15 37 31 19 54 13 50 -11z"
                />
                <path
                    d="M2787 3238 c-41 -32 -43 -116 -3 -143 27 -19 56 -19 85 1 17 13 20
19 11 28 -9 8 -14 8 -17 -1 -6 -15 -72 -18 -74 -3 -5 31 -2 34 46 34 44 1 50
4 53 23 4 27 -38 73 -66 73 -10 0 -26 -6 -35 -12z m71 -40 c3 -15 -4 -18 -37
-18 -43 0 -50 7 -29 28 18 18 62 11 66 -10z"
                />
                <path
                    d="M2951 3233 c-33 -27 -38 -82 -10 -116 18 -24 28 -28 56 -25 29 4 33
1 31 -16 -4 -25 -56 -35 -69 -14 -6 9 -11 10 -19 2 -13 -13 5 -31 35 -34 72
-8 85 10 85 119 0 91 -5 108 -26 90 -8 -7 -14 -7 -19 1 -10 15 -40 12 -64 -7z
m68 -27 c17 -20 10 -77 -11 -90 -26 -16 -52 4 -56 43 -4 36 12 61 38 61 10 0
23 -6 29 -14z"
                />
                <path
                    d="M3125 3225 c-17 -16 -25 -35 -25 -60 0 -41 36 -85 70 -85 34 0 70 44
70 85 0 25 -8 44 -25 60 -31 32 -59 32 -90 0z m81 -34 c19 -38 0 -81 -37 -81
-23 0 -32 6 -40 26 -25 67 46 118 77 55z"
                />
                <path
                    d="M3280 3169 c0 -43 5 -81 10 -84 6 -4 10 16 10 52 0 62 11 83 43 83
35 0 11 24 -26 26 l-37 1 0 -78z"
                />
                <path
                    d="M3386 3242 c-3 -5 7 -42 23 -83 29 -76 28 -109 -4 -109 -8 0 -15 -4
-15 -10 0 -19 40 -10 54 13 24 39 68 166 63 184 -4 17 -26 -23 -52 -92 -6 -16
-11 -10 -26 35 -21 59 -33 77 -43 62z"
                />
                <path
                    d="M5065 3225 c-34 -33 -34 -93 -2 -123 29 -27 61 -28 87 -2 26 26 16
48 -10 25 -44 -40 -98 13 -72 70 13 28 59 35 68 10 7 -16 34 -21 34 -6 0 13
-50 51 -67 51 -8 0 -25 -11 -38 -25z"
                />
                <path
                    d="M5233 3240 c-13 -5 -23 -16 -23 -25 0 -18 11 -20 27 -4 15 15 58 7
58 -11 0 -8 -14 -17 -32 -21 -62 -12 -81 -49 -44 -83 15 -14 29 -16 60 -11
l41 7 0 63 c0 55 -3 66 -22 79 -25 18 -33 19 -65 6z m67 -89 c0 -27 -15 -41
-41 -41 -22 0 -29 5 -29 19 0 11 6 22 13 24 21 9 57 7 57 -2z"
                />
                <path
                    d="M5500 3232 c-13 -10 -23 -33 -27 -59 -5 -35 -2 -45 20 -67 14 -14 36
-26 47 -26 30 0 60 18 60 35 0 17 -16 20 -25 5 -10 -17 -62 -12 -69 7 -8 21 8
31 42 27 64 -8 82 27 37 71 -29 29 -55 32 -85 7z m70 -16 c0 -2 3 -11 6 -20 5
-13 -2 -16 -35 -16 -42 0 -50 7 -29 28 11 11 58 17 58 8z"
                />
                <path
                    d="M5665 3225 c-54 -53 -19 -141 51 -133 31 4 35 1 32 -16 -3 -24 -53
-35 -71 -17 -15 15 -27 14 -27 -4 0 -16 42 -31 75 -26 41 5 55 37 55 127 0 77
-10 109 -25 84 -4 -7 -12 -7 -25 0 -28 15 -38 13 -65 -15z m74 -19 c17 -20 10
-77 -11 -90 -26 -16 -52 4 -56 43 -4 36 12 61 38 61 10 0 23 -6 29 -14z"
                />
                <path
                    d="M5846 3229 c-51 -40 -26 -149 35 -149 34 0 57 18 68 53 14 41 2 78
-31 101 -29 21 -40 20 -72 -5z m76 -34 c16 -35 0 -79 -30 -83 -45 -7 -67 41
-42 89 16 28 58 25 72 -6z"
                />
                <path
                    d="M6000 3168 c0 -42 5 -80 10 -83 6 -4 10 15 10 49 0 67 7 86 31 86 10
0 19 6 19 13 0 9 -12 13 -35 13 l-35 -1 0 -77z"
                />
                <path
                    d="M6100 3235 c0 -9 11 -43 25 -78 29 -69 26 -102 -7 -110 -12 -4 -19
-9 -16 -13 4 -3 18 -4 32 0 20 5 31 23 60 98 38 99 43 122 24 116 -6 -2 -20
-28 -30 -58 -10 -29 -20 -55 -22 -57 -2 -2 -13 24 -25 57 -21 56 -41 79 -41
45z"
                />
                <path
                    d="M2203 3209 c-29 -17 -53 -36 -53 -43 0 -15 117 -79 124 -67 7 10 -7
22 -51 45 -18 9 -33 20 -33 24 0 5 20 18 45 30 25 12 45 26 45 32 0 17 -23 11
-77 -21z"
                />
                <path
                    d="M3850 3227 c0 -7 21 -23 47 -36 l47 -23 -47 -26 c-26 -14 -47 -32
-47 -40 0 -10 18 -4 62 21 34 19 64 39 65 44 3 7 -105 73 -121 73 -3 0 -6 -6
-6 -13z"
                />
                <path
                    d="M4813 3208 c-29 -16 -53 -35 -53 -41 0 -16 117 -80 124 -68 7 10 -7
22 -51 45 -18 9 -33 20 -33 25 0 4 18 16 39 26 39 17 63 46 38 44 -7 0 -36
-14 -64 -31z"
                />
                <path
                    d="M6566 3232 c-6 -10 55 -52 75 -52 20 0 7 -20 -23 -36 -44 -22 -59
-35 -53 -44 7 -11 117 50 122 67 3 9 -46 41 -109 71 -3 2 -9 -1 -12 -6z"
                />
                <path
                    d="M1315 3110 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"
                />
                <path
                    d="M1396 3112 c-2 -4 -1 -14 4 -22 7 -11 12 -12 21 -3 6 6 8 16 5 22 -8
13 -23 14 -30 3z"
                />
                <path
                    d="M1482 3106 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path d="M1565 3110 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />
                <path d="M1645 3110 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />
                <path
                    d="M1732 3106 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path
                    d="M1815 3110 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M1894 3109 c-3 -6 -1 -16 5 -22 9 -9 14 -8 21 3 9 15 4 30 -10 30 -5
0 -12 -5 -16 -11z"
                />
                <path
                    d="M1974 3109 c-8 -14 18 -31 30 -19 9 9 0 30 -14 30 -5 0 -12 -5 -16
-11z"
                />
                <path
                    d="M2065 3110 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M3626 2602 c-3 -5 -17 -53 -31 -108 -14 -54 -28 -107 -31 -118 -4
-12 -2 -17 7 -14 19 6 86 248 69 248 -5 0 -11 -4 -14 -8z"
                />
                <path
                    d="M2615 2590 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M3248 2593 c-21 -5 -48 -62 -48 -103 0 -62 47 -118 87 -104 36 13 53
46 53 107 0 48 -4 62 -25 82 -24 25 -33 27 -67 18z m62 -54 c14 -32 8 -93 -11
-115 -16 -20 -47 -17 -59 5 -18 34 -12 109 10 131 24 24 44 17 60 -21z"
                />
                <path
                    d="M3965 2590 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M2330 2440 c0 -67 4 -110 10 -110 6 0 10 16 10 35 0 34 12 46 25 25
11 -17 41 -11 63 13 30 32 30 99 0 127 -24 22 -40 25 -69 10 -13 -7 -19 -7
-19 0 0 5 -4 10 -10 10 -6 0 -10 -43 -10 -110z m102 55 c18 -41 0 -80 -37 -80
-23 0 -32 6 -39 27 -14 39 6 78 39 78 18 0 29 -8 37 -25z"
                />
                <path
                    d="M2500 2469 c0 -62 3 -79 15 -79 10 0 15 16 17 63 3 56 5 62 26 65 40
6 25 29 -18 29 l-40 0 0 -78z"
                />
                <path
                    d="M2617 2543 c-4 -3 -7 -39 -7 -80 0 -58 3 -73 15 -73 12 0 15 16 15
80 0 73 -6 91 -23 73z"
                />
                <path
                    d="M2706 2535 c-11 -8 -20 -31 -23 -58 -4 -36 -1 -48 19 -71 21 -24 29
-27 56 -21 18 3 37 16 43 27 14 27 3 35 -19 15 -43 -39 -96 4 -76 61 11 31 37
39 70 21 33 -17 40 -6 13 20 -26 24 -56 26 -83 6z"
                />
                <path
                    d="M2861 2524 c-29 -37 -27 -89 4 -119 13 -14 33 -25 43 -25 24 0 70 35
59 46 -4 4 -13 2 -19 -4 -25 -25 -78 -8 -78 25 0 9 17 13 56 13 l56 0 -6 28
c-15 61 -80 81 -115 36z m87 -26 c3 -15 -4 -18 -37 -18 -44 0 -44 0 -35 25 9
22 68 17 72 -7z"
                />
                <path
                    d="M3032 2534 c2 -10 20 -27 41 -38 20 -11 37 -23 37 -27 0 -4 -18 -16
-40 -26 -39 -18 -61 -43 -37 -43 21 1 112 58 112 70 0 7 -9 18 -20 24 -10 6
-37 21 -58 34 -34 21 -38 21 -35 6z"
                />
                <path
                    d="M3453 2514 c-35 -20 -63 -40 -63 -44 0 -5 29 -24 65 -43 45 -24 65
-30 65 -21 0 7 -18 23 -40 34 -22 11 -40 25 -40 30 0 5 18 19 40 30 22 11 40
27 40 35 0 19 6 21 -67 -21z"
                />
                <path
                    d="M3680 2438 c0 -89 3 -108 15 -108 10 0 15 11 15 35 0 33 1 34 25 23
22 -10 29 -8 49 11 31 29 38 67 21 108 -15 35 -16 35 -82 38 l-43 1 0 -108z
m98 70 c16 -16 15 -60 -1 -82 -8 -11 -23 -17 -38 -14 -20 2 -25 9 -27 42 -2
21 -1 44 2 52 7 17 48 18 64 2z"
                />
                <path
                    d="M3855 2541 c-3 -2 -5 -37 -5 -78 0 -57 3 -73 15 -73 10 0 15 16 17
63 3 56 5 62 26 65 37 6 26 30 -13 29 -20 -1 -38 -3 -40 -6z"
                />
                <path
                    d="M3966 2543 c-3 -3 -6 -39 -6 -80 0 -58 3 -73 15 -73 12 0 15 15 15
74 0 69 -8 95 -24 79z"
                />
                <path
                    d="M4051 2524 c-29 -37 -27 -89 4 -119 13 -14 32 -25 43 -25 19 0 62 34
62 50 0 15 -16 12 -30 -5 -36 -43 -94 6 -74 63 11 31 37 39 70 21 32 -17 41
-6 15 20 -29 29 -65 27 -90 -5z"
                />
                <path
                    d="M4215 2525 c-17 -16 -25 -35 -25 -60 0 -38 35 -85 64 -85 25 0 66 22
66 37 0 15 -16 17 -25 3 -9 -14 -48 -13 -63 2 -26 26 -13 38 43 38 49 0 55 2
55 20 0 27 -43 70 -70 70 -11 0 -32 -11 -45 -25z m83 -27 c3 -15 -4 -18 -37
-18 -43 0 -50 7 -29 28 18 18 62 11 66 -10z"
                />
                <path
                    d="M4382 2533 c2 -10 20 -26 41 -37 20 -11 37 -22 37 -26 0 -3 -18 -16
-40 -29 -22 -12 -40 -29 -40 -36 0 -10 17 -4 58 19 31 19 57 40 57 47 0 7 -26
28 -58 46 -46 26 -58 29 -55 16z"
                />
                <path
                    d="M2205 2512 c-27 -15 -50 -34 -50 -42 0 -8 26 -29 59 -47 66 -37 66
-37 66 -20 0 6 -20 23 -45 36 -25 13 -45 27 -45 31 0 3 18 16 40 29 22 12 42
26 45 31 11 18 -19 10 -70 -18z"
                />
                <path d="M1317 2413 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                <path d="M1396 2411 c-3 -5 1 -14 8 -20 16 -13 32 2 21 19 -8 12 -22 13 -29 1z" />
                <path
                    d="M1482 2404 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"
                />
                <path d="M1560 2406 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33 -7z" />
                <path d="M1644 2409 c-3 -6 0 -15 7 -20 14 -8 32 9 23 23 -7 11 -22 10 -30 -3z" />
                <path
                    d="M1732 2406 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
                />
                <path
                    d="M1810 2405 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1895 2409 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13 -30
-1z"
                />
                <path d="M1975 2410 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z" />
                <path
                    d="M2062 2404 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"
                />
                <path
                    d="M8465 1898 c-19 -53 -64 -232 -60 -237 12 -11 25 20 51 119 14 58 29
111 31 118 3 6 0 12 -6 12 -7 0 -14 -6 -16 -12z"
                />
                <path
                    d="M3312 1890 c-31 -13 -55 -74 -47 -118 14 -77 99 -109 150 -57 26 25
32 45 15 45 -5 0 -21 -12 -35 -26 -20 -20 -32 -24 -53 -19 -15 4 -30 12 -34
18 -29 44 -19 119 18 135 37 17 50 15 72 -13 20 -26 42 -33 42 -15 0 6 -12 22
-26 35 -28 26 -62 31 -102 15z"
                />
                <path
                    d="M5837 1893 c-4 -3 -7 -51 -7 -105 l0 -98 45 0 c26 0 59 7 75 15 62
32 62 148 0 180 -29 15 -101 21 -113 8z m116 -44 c41 -50 9 -129 -53 -132
l-35 -2 -3 84 -3 84 38 -6 c21 -4 46 -16 56 -28z"
                />
                <path
                    d="M6040 1795 l0 -105 65 0 c37 0 65 4 65 10 0 6 -22 10 -50 10 l-50 0
0 35 c0 35 0 35 45 35 33 0 45 4 45 15 0 11 -12 15 -45 15 -45 0 -45 0 -45 35
l0 35 51 0 c31 0 48 4 44 10 -3 6 -33 10 -66 10 l-59 0 0 -105z"
                />
                <path
                    d="M6220 1795 c0 -63 4 -105 10 -105 6 0 10 32 10 75 0 41 3 75 8 75 4
-1 30 -34 57 -75 28 -41 56 -75 63 -75 9 0 12 28 12 105 0 63 -4 105 -10 105
-6 0 -10 -32 -10 -75 0 -41 -3 -75 -7 -75 -5 0 -32 34 -61 75 -29 41 -57 75
-63 75 -5 0 -9 -46 -9 -105z"
                />
                <path
                    d="M6450 1880 c-37 -37 -20 -68 53 -99 47 -20 57 -37 35 -59 -19 -19
-55 -14 -78 10 -11 12 -23 18 -27 14 -11 -11 11 -39 42 -55 24 -12 32 -11 61
3 27 12 34 22 34 43 0 39 -11 52 -56 68 -47 16 -63 37 -45 59 18 22 48 20 68
-4 20 -23 33 -26 33 -7 0 44 -84 63 -120 27z"
                />
                <path
                    d="M6660 1887 c-35 -18 -52 -55 -47 -105 8 -73 66 -111 132 -86 23 9 45
57 45 98 0 84 -61 128 -130 93z m80 -25 c13 -10 23 -33 27 -59 5 -35 2 -45
-20 -67 -51 -51 -107 -20 -107 59 0 73 50 107 100 67z"
                />
                <path
                    d="M6920 1795 c0 -87 3 -105 15 -105 12 0 15 18 15 105 0 87 -3 105 -15
105 -12 0 -15 -18 -15 -105z"
                />
                <path
                    d="M7044 1889 c-29 -15 -44 -51 -44 -105 0 -60 32 -94 89 -94 22 0 43
-4 46 -10 3 -5 13 -10 21 -10 11 0 12 4 5 19 -8 14 -6 28 5 51 20 39 13 105
-13 132 -23 23 -80 32 -109 17z m83 -31 c17 -16 23 -32 23 -64 0 -45 -14 -64
-33 -45 -6 6 -17 11 -24 11 -10 0 -10 -6 3 -25 16 -24 15 -25 -4 -25 -11 0
-30 9 -43 19 -19 15 -24 29 -24 66 0 37 5 51 24 66 30 25 49 24 78 -3z"
                />
                <path
                    d="M7236 1879 c-33 -26 -34 -47 -1 -25 l25 16 0 -90 c0 -73 3 -90 15
-90 12 0 15 18 15 105 0 115 -5 123 -54 84z"
                />
                <path
                    d="M7392 1893 c-36 -14 -54 -115 -30 -168 21 -46 95 -46 116 0 25 55
-21 115 -73 95 -20 -8 -25 -6 -25 6 0 48 49 72 70 34 12 -23 30 -27 30 -7 0
32 -50 55 -88 40z m56 -105 c28 -28 3 -84 -34 -76 -43 8 -44 88 -1 88 13 0 28
-5 35 -12z"
                />
                <path
                    d="M7620 1795 c0 -87 3 -105 15 -105 12 0 15 18 15 105 0 87 -3 105 -15
105 -12 0 -15 -18 -15 -105z"
                />
                <path
                    d="M7743 1890 c-26 -11 -53 -59 -53 -95 0 -34 25 -82 49 -95 11 -5 35
-10 55 -10 19 0 38 -4 41 -10 11 -18 22 -10 23 18 0 15 3 48 7 74 15 95 -42
150 -122 118z m82 -35 c27 -26 33 -72 15 -106 -10 -17 -13 -18 -26 -4 -22 22
-37 19 -24 -4 14 -27 13 -31 -10 -31 -11 0 -30 10 -41 21 -18 18 -21 30 -16
72 7 77 54 101 102 52z"
                />
                <path
                    d="M7938 1884 c-16 -8 -28 -21 -28 -30 0 -11 4 -12 19 -4 11 5 22 10 25
10 3 0 6 -38 6 -85 0 -69 3 -85 15 -85 12 0 15 18 15 105 0 113 -2 117 -52 89z"
                />
                <path
                    d="M8076 1881 c-37 -41 -42 -118 -10 -168 18 -29 73 -31 98 -4 43 47 -9
134 -67 112 -33 -13 -37 5 -9 39 13 17 24 21 40 16 12 -4 22 -14 22 -22 0 -8
7 -14 15 -14 22 0 18 27 -7 44 -30 21 -61 20 -82 -3z m74 -102 c9 -16 8 -26
-5 -45 -8 -13 -22 -24 -30 -24 -19 0 -45 28 -45 49 0 41 61 57 80 20z"
                />
                <path
                    d="M2208 1817 c-32 -18 -58 -37 -58 -44 0 -11 101 -73 120 -73 25 0 6
22 -35 42 -25 12 -45 25 -45 29 0 4 19 18 42 31 38 21 62 49 41 48 -4 -1 -34
-15 -65 -33z"
                />
                <path
                    d="M2330 1768 c0 -45 4 -78 10 -78 6 0 10 24 10 54 0 45 4 58 21 70 35
24 48 10 51 -57 2 -37 8 -62 16 -65 9 -3 12 14 12 61 0 81 -13 97 -75 95 l-45
-2 0 -78z"
                />
                <path
                    d="M2514 1836 c-25 -19 -11 -32 20 -18 19 8 28 7 43 -3 16 -13 16 -16 3
-25 -8 -5 -23 -10 -33 -10 -32 0 -57 -20 -57 -44 0 -30 22 -48 57 -47 79 1 72
-5 67 67 -2 37 -9 73 -15 80 -15 18 -60 18 -85 0z m76 -89 c0 -18 -21 -37 -41
-37 -22 0 -33 16 -25 35 7 19 66 20 66 2z"
                />
                <path
                    d="M2660 1769 c0 -64 3 -79 15 -79 12 0 15 14 15 66 l0 65 28 -3 c26 -3
27 -6 30 -65 3 -74 22 -85 22 -14 0 64 7 81 35 81 28 0 35 -17 35 -81 0 -27 5
-49 10 -49 6 0 10 28 10 64 0 85 -27 114 -80 86 -12 -6 -21 -7 -25 0 -3 5 -26
9 -50 8 l-45 -1 0 -78z"
                />
                <path
                    d="M2921 1824 c-30 -38 -27 -80 9 -115 23 -23 35 -29 52 -24 29 9 54 32
45 42 -4 3 -13 1 -19 -5 -25 -25 -78 -8 -78 25 0 9 17 13 55 13 47 0 55 3 55
19 0 32 -39 71 -70 71 -19 0 -36 -9 -49 -26z m70 -4 c34 -19 23 -40 -21 -40
-43 0 -55 20 -22 39 21 13 20 13 43 1z"
                />
                <path
                    d="M3090 1836 c0 -8 18 -25 40 -37 22 -13 40 -26 40 -29 0 -4 -18 -15
-40 -25 -22 -10 -40 -24 -40 -32 0 -7 2 -13 5 -13 16 0 115 64 115 73 0 7 -27
27 -60 45 -45 24 -60 29 -60 18z"
                />
                <path
                    d="M3480 1770 l0 -80 48 0 c26 0 53 5 60 12 15 15 16 50 1 65 -8 8 -8
13 0 17 16 10 14 32 -5 50 -9 10 -33 16 -60 16 l-44 0 0 -80z m94 41 c9 -14
-21 -33 -46 -29 -12 2 -24 11 -26 21 -3 13 4 17 32 17 19 0 37 -4 40 -9z m-10
-57 c35 -13 11 -44 -34 -44 -23 0 -34 16 -26 35 6 16 33 19 60 9z"
                />
                <path
                    d="M3660 1830 c-28 -28 -28 -100 0 -125 25 -23 76 -24 97 -2 21 21 8 35
-17 19 -26 -16 -45 -15 -64 4 -27 26 -18 34 39 34 52 0 55 1 55 25 0 32 -33
65 -65 65 -14 0 -34 -9 -45 -20z m85 -36 c0 -11 -51 -18 -73 -9 -15 6 -14 9 7
26 21 17 26 18 45 6 12 -8 21 -18 21 -23z"
                />
                <path
                    d="M3810 1815 c0 -44 29 -75 70 -75 25 0 30 -4 30 -25 0 -14 5 -25 10
-25 6 0 10 33 10 80 0 47 -4 80 -10 80 -5 0 -10 -18 -10 -39 0 -30 -5 -41 -19
-46 -34 -11 -51 4 -51 46 0 28 -4 39 -15 39 -10 0 -15 -11 -15 -35z"
                />
                <path
                    d="M3994 1836 c-25 -19 -11 -32 20 -18 19 8 28 7 43 -3 16 -13 16 -16 3
-25 -8 -5 -25 -10 -37 -10 -24 0 -53 -24 -53 -44 0 -25 27 -48 56 -47 68 1 64
-3 64 71 0 58 -3 71 -19 80 -27 14 -54 13 -77 -4z m76 -89 c0 -33 -48 -49 -69
-23 -10 11 -8 17 6 25 25 15 63 14 63 -2z"
                />
                <path
                    d="M4236 1828 c-20 -28 -13 -35 18 -18 26 13 56 8 56 -9 0 -6 -11 -13
-25 -17 -31 -8 -32 -22 -2 -26 14 -2 22 -10 22 -23 0 -26 -31 -31 -56 -9 -21
19 -35 14 -25 -11 8 -23 51 -36 81 -25 23 9 31 28 25 55 -1 6 -2 25 -1 44 0
19 -4 40 -10 48 -18 21 -66 16 -83 -9z"
                />
                <path
                    d="M4385 1830 c-18 -20 -18 -20 29 -14 44 6 48 5 44 -12 -2 -13 -13 -20
-33 -22 -39 -5 -55 -19 -55 -48 0 -30 42 -60 66 -46 9 5 25 7 35 5 17 -5 19 1
19 54 0 70 -19 103 -59 103 -15 0 -36 -9 -46 -20z m73 -92 c-4 -29 -55 -39
-65 -13 -7 20 12 35 45 35 19 0 23 -5 20 -22z"
                />
                <path
                    d="M4530 1845 c0 -2 14 -20 31 -40 l31 -35 -32 -37 c-23 -26 -29 -38
-20 -41 8 -3 26 9 40 27 33 39 40 39 40 1 0 -16 5 -30 10 -30 6 0 10 14 10 30
0 17 4 30 8 30 5 0 18 -13 30 -30 13 -16 29 -30 37 -30 22 0 18 11 -16 47
l-32 32 33 38 c22 24 29 38 20 41 -7 3 -24 -10 -37 -27 -31 -39 -43 -39 -43
-1 0 17 -4 30 -10 30 -5 0 -10 -13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -19 14
-32 30 -18 25 -48 41 -48 25z"
                />
                <path
                    d="M4770 1770 c0 -98 13 -104 61 -28 l34 53 3 -53 c2 -37 7 -52 18 -52
11 0 14 17 14 80 0 98 -13 104 -61 28 l-34 -53 -3 53 c-2 37 -7 52 -18 52 -11
0 -14 -17 -14 -80z"
                />
                <path
                    d="M4948 1838 c-9 -28 -1 -142 10 -146 8 -2 12 17 12 62 l0 65 38 3 c52
4 46 22 -9 26 -32 2 -48 -1 -51 -10z"
                />
                <path
                    d="M5084 1836 c-26 -19 -11 -32 21 -18 14 6 32 9 40 6 25 -10 17 -33
-12 -39 -46 -10 -58 -20 -61 -54 -3 -26 1 -33 23 -41 16 -6 33 -7 42 -1 9 4
24 6 34 4 17 -5 19 1 19 60 0 46 -5 70 -16 81 -19 20 -64 21 -90 2z m76 -89
c0 -19 -21 -37 -42 -37 -24 0 -35 29 -17 41 21 13 59 11 59 -4z"
                />
                <path
                    d="M5240 1770 c0 -78 16 -112 22 -47 3 29 6 32 38 32 24 0 35 -5 36 -15
1 -8 2 -23 3 -32 0 -10 6 -18 11 -18 6 0 10 34 10 81 0 59 -3 80 -12 77 -7 -3
-12 -18 -13 -34 0 -26 -4 -29 -37 -32 -37 -3 -38 -3 -38 32 0 20 -4 36 -10 36
-6 0 -10 -33 -10 -80z"
                />
                <path
                    d="M5410 1770 c0 -96 12 -103 55 -30 17 27 33 50 38 50 4 0 7 -22 7 -50
0 -27 5 -50 10 -50 6 0 10 33 10 80 0 98 -12 103 -60 23 l-35 -58 -3 58 c-5
86 -22 68 -22 -23z"
                />
                <path
                    d="M5598 1839 c-22 -12 -23 -52 -4 -68 13 -11 12 -17 -6 -47 -16 -28
-17 -34 -5 -34 9 0 21 14 29 30 19 43 58 42 58 -1 0 -20 4 -29 13 -27 8 3 13
30 14 81 l1 77 -41 0 c-23 0 -50 -5 -59 -11z m72 -39 c0 -16 -7 -20 -30 -20
-23 0 -30 4 -30 20 0 16 7 20 30 20 23 0 30 -4 30 -20z"
                />
                <path
                    d="M8296 1817 c-33 -18 -61 -38 -63 -43 -4 -11 116 -79 124 -70 9 9 -15
33 -47 46 -40 16 -38 26 10 50 22 11 40 27 40 35 0 19 5 20 -64 -18z"
                />
                <path
                    d="M8520 1768 c0 -89 17 -106 22 -22 2 45 8 60 24 71 34 22 54 -5 54
-73 0 -30 5 -54 10 -54 6 0 10 28 10 64 0 80 -13 96 -75 94 l-45 -2 0 -78z"
                />
                <path
                    d="M8704 1836 c-26 -19 -12 -31 23 -19 33 12 53 7 53 -12 0 -14 -21 -25
-49 -25 -27 0 -54 -35 -46 -60 8 -25 50 -44 72 -31 9 4 24 6 34 4 17 -5 19 1
19 59 0 50 -4 69 -18 81 -23 21 -62 22 -88 3z m76 -89 c0 -19 -21 -37 -42 -37
-24 0 -35 29 -17 41 21 13 59 11 59 -4z"
                />
                <path
                    d="M8854 1835 c-10 -24 1 -138 13 -142 8 -3 13 15 15 59 3 60 4 63 31
66 l27 3 0 -65 c0 -52 3 -66 15 -66 12 0 15 14 15 66 l0 65 28 -3 c26 -3 27
-6 30 -66 2 -38 7 -62 13 -60 6 2 10 7 10 11 -1 4 -1 34 -1 67 0 55 -2 61 -26
72 -20 9 -30 9 -46 -1 -13 -8 -23 -9 -32 -2 -17 14 -87 11 -92 -4z"
                />
                <path
                    d="M9132 1843 c-17 -6 -42 -57 -42 -83 0 -33 55 -83 82 -75 29 9 54 32
45 42 -4 3 -13 1 -19 -5 -25 -25 -78 -8 -78 25 0 9 17 13 56 13 l57 0 -7 33
c-9 48 -48 69 -94 50z m66 -47 c3 -13 -5 -16 -37 -16 -45 0 -49 5 -25 31 19
21 57 12 62 -15z"
                />
                <path
                    d="M9280 1836 c0 -9 18 -25 40 -36 22 -11 40 -24 40 -29 0 -4 -18 -16
-40 -26 -22 -10 -40 -24 -40 -32 0 -7 3 -13 8 -13 16 0 115 66 112 75 -1 6
-29 25 -61 43 -45 26 -59 30 -59 18z"
                />
                <path
                    d="M1312 1708 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path d="M1395 1711 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />
                <path
                    d="M1480 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1560 1705 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"
                />
                <path
                    d="M1642 1708 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
-10z"
                />
                <path
                    d="M1730 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1810 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M1890 1705 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"
                />
                <path d="M1975 1711 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />
                <path
                    d="M2060 1705 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M3746 1194 c-9 -8 -16 -21 -16 -29 0 -7 -6 -16 -12 -18 -10 -4 -10
-7 -1 -14 7 -5 13 -37 15 -72 2 -40 8 -66 16 -69 9 -3 12 15 12 67 0 56 3 71
15 71 8 0 15 5 15 10 0 6 -7 10 -15 10 -22 0 -18 31 5 37 11 3 20 9 20 14 0
15 -37 10 -54 -7z"
                />
                <path
                    d="M4617 1198 c-2 -7 -17 -62 -32 -121 -24 -95 -27 -133 -10 -115 6 6
65 224 65 239 0 14 -17 11 -23 -3z"
                />
                <path
                    d="M2800 1185 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M2880 1095 c0 -63 4 -105 10 -105 6 0 10 42 10 105 0 63 -4 105 -10
105 -6 0 -10 -42 -10 -105z"
                />
                <path
                    d="M3120 1097 l0 -103 49 -2 c42 -2 53 2 65 20 21 29 20 87 0 117 -14
20 -23 22 -55 18 -38 -5 -39 -4 -39 24 0 16 -4 29 -10 29 -6 0 -10 -41 -10
-103z m92 16 c22 -20 23 -51 2 -81 -20 -29 -40 -28 -60 3 -24 37 -6 94 30 95
5 0 18 -7 28 -17z"
                />
                <path
                    d="M3290 1094 c0 -80 3 -105 13 -102 8 3 13 36 15 106 2 86 0 102 -13
102 -12 0 -15 -17 -15 -106z"
                />
                <path
                    d="M3990 1094 c0 -71 4 -104 11 -102 6 2 10 7 10 11 -1 4 -1 50 -1 102
0 57 -4 95 -10 95 -6 0 -10 -42 -10 -106z"
                />
                <path
                    d="M5150 1185 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
                />
                <path
                    d="M5222 1098 c2 -70 7 -103 16 -106 9 -3 12 22 12 102 0 89 -3 106 -15
106 -13 0 -15 -16 -13 -102z"
                />
                <path
                    d="M5463 1098 l2 -102 47 -3 c37 -3 52 1 68 17 27 27 26 80 -2 115 -18
23 -27 26 -55 22 -31 -5 -33 -4 -33 24 0 19 -5 29 -15 29 -13 0 -15 -17 -12
-102z m95 20 c7 -7 12 -28 12 -48 0 -55 -32 -77 -64 -44 -30 29 -13 104 22
104 10 0 23 -5 30 -12z"
                />
                <path
                    d="M5640 1096 c0 -64 4 -106 10 -106 17 0 19 202 2 208 -9 3 -12 -26
-12 -102z"
                />
                <path
                    d="M2208 1118 c-32 -17 -58 -36 -58 -42 0 -12 103 -76 121 -76 22 0 6
20 -36 44 -25 15 -45 29 -45 31 0 2 18 14 40 26 35 20 61 49 42 49 -4 0 -33
-15 -64 -32z"
                />
                <path
                    d="M2340 1135 c-18 -22 -7 -28 23 -14 14 6 33 9 41 6 30 -12 16 -33 -29
-45 -51 -13 -63 -31 -47 -68 10 -21 17 -24 62 -22 l50 2 0 66 c0 38 -5 71 -12
78 -17 17 -73 15 -88 -3z m78 -91 c-8 -40 -68 -44 -68 -4 0 16 6 20 36 20 28
0 35 -4 32 -16z"
                />
                <path
                    d="M2500 1073 c15 -43 34 -79 41 -81 9 -1 22 20 36 60 12 35 25 71 28
81 5 11 2 17 -8 17 -9 0 -21 -21 -32 -56 -9 -31 -21 -52 -25 -47 -4 4 -15 29
-25 56 -10 26 -23 47 -30 47 -8 0 -3 -25 15 -77z"
                />
                <path
                    d="M2654 1136 c-26 -19 -10 -33 20 -16 28 15 56 8 56 -14 0 -9 -14 -18
-34 -22 -19 -3 -42 -14 -52 -25 -15 -17 -15 -20 0 -44 14 -21 23 -24 66 -24
l50 2 0 63 c0 44 -5 67 -16 78 -19 20 -64 21 -90 2z m76 -90 c0 -21 -27 -38
-50 -31 -20 6 -26 25 -13 38 12 13 63 7 63 -7z"
                />
                <path
                    d="M2802 1073 c2 -50 7 -78 16 -81 9 -3 12 17 12 77 0 66 -3 81 -15 81
-13 0 -15 -13 -13 -77z"
                />
                <path
                    d="M2964 1136 c-26 -19 -13 -30 19 -15 14 6 33 9 41 6 30 -12 16 -33
-28 -45 -49 -13 -65 -37 -46 -72 10 -18 19 -21 63 -18 l52 3 0 61 c0 37 -5 68
-14 78 -17 20 -60 21 -87 2z m76 -82 c0 -3 -5 -14 -10 -25 -19 -34 -70 -19
-56 16 5 14 66 22 66 9z"
                />
                <path
                    d="M3380 1130 c-13 -13 -20 -33 -20 -60 0 -48 27 -80 68 -80 37 0 71 24
56 40 -8 8 -14 7 -24 -5 -7 -8 -20 -15 -30 -15 -16 0 -50 29 -50 43 0 3 26 7
58 9 54 3 57 4 54 28 -8 57 -72 80 -112 40z m80 -20 c6 -11 7 -20 3 -21 -77
-8 -94 -2 -67 25 21 22 51 20 64 -4z"
                />
                <path
                    d="M3540 1137 c0 -7 20 -23 46 -35 25 -12 43 -26 40 -30 -3 -5 -23 -17
-46 -27 -22 -10 -40 -24 -40 -32 0 -7 3 -13 6 -13 14 0 124 66 124 75 0 9
-110 75 -124 75 -3 0 -6 -6 -6 -13z"
                />
                <path
                    d="M3840 1135 c-18 -21 -6 -29 19 -13 15 9 26 10 38 2 30 -18 25 -31
-14 -39 -47 -9 -63 -21 -63 -50 0 -33 21 -45 73 -43 l47 2 0 59 c0 76 -12 97
-54 97 -19 0 -39 -7 -46 -15z m80 -84 c0 -17 -32 -42 -49 -39 -9 2 -17 13 -19
26 -3 19 1 22 32 22 20 0 36 -4 36 -9z"
                />
                <path
                    d="M4070 1136 c-20 -25 -8 -60 25 -71 49 -16 58 -25 44 -41 -15 -18 -34
-18 -54 2 -20 20 -33 10 -19 -14 13 -21 70 -28 90 -11 8 6 14 22 14 36 0 19
-9 28 -45 43 -32 14 -43 23 -39 35 7 16 54 21 54 5 0 -5 7 -10 15 -10 18 0 19
12 3 28 -17 17 -73 15 -88 -2z"
                />
                <path
                    d="M4224 1129 c-11 -13 -19 -35 -19 -59 0 -50 27 -80 71 -80 18 0 40 7
48 16 22 21 9 40 -13 20 -22 -20 -45 -20 -65 0 -27 26 -18 34 39 34 52 0 55 1
55 25 0 57 -78 86 -116 44z m85 -18 c9 -15 7 -19 -12 -23 -12 -2 -33 -1 -46 2
-23 5 -23 6 -7 23 23 22 53 22 65 -2z"
                />
                <path
                    d="M4448 1117 c-32 -17 -58 -36 -58 -42 0 -9 109 -75 124 -75 20 0 0 23
-40 46 -50 30 -50 30 15 66 17 9 31 22 31 28 0 15 -10 12 -72 -23z"
                />
                <path
                    d="M4685 1130 c-20 -23 -9 -26 30 -8 21 10 29 9 42 -5 15 -15 14 -17
-11 -27 -15 -5 -33 -10 -40 -10 -32 0 -49 -57 -23 -79 8 -6 35 -10 60 -9 l47
2 0 68 c0 56 -3 69 -19 78 -31 16 -66 12 -86 -10z m85 -79 c0 -14 -29 -41 -44
-41 -18 0 -35 21 -29 36 6 16 73 20 73 5z"
                />
                <path
                    d="M4820 1147 c0 -20 61 -152 70 -152 8 0 26 35 42 78 22 60 25 77 13
77 -8 0 -21 -22 -31 -55 -10 -30 -21 -55 -25 -55 -4 0 -15 25 -24 55 -10 35
-21 55 -30 55 -8 0 -15 -1 -15 -3z"
                />
                <path
                    d="M5004 1136 c-26 -19 -10 -33 20 -16 28 14 56 8 56 -13 0 -9 -18 -19
-44 -26 -45 -11 -61 -30 -51 -62 7 -21 34 -30 85 -26 l35 3 0 60 c0 37 -6 68
-14 78 -17 20 -60 21 -87 2z m76 -89 c0 -18 -21 -37 -41 -37 -22 0 -33 16 -25
35 7 19 66 20 66 2z"
                />
                <path
                    d="M5150 1070 c0 -64 3 -80 15 -80 12 0 15 16 15 80 0 64 -3 80 -15 80
-12 0 -15 -16 -15 -80z"
                />
                <path
                    d="M5305 1130 c-21 -23 -14 -25 32 -8 29 10 36 10 43 -2 15 -24 12 -27
-27 -35 -47 -9 -63 -21 -63 -50 0 -33 21 -45 73 -43 l47 2 0 66 c0 38 -5 71
-12 78 -19 19 -73 14 -93 -8z m85 -76 c0 -3 -5 -14 -10 -25 -19 -34 -70 -19
-56 16 5 14 66 22 66 9z"
                />
                <path
                    d="M5729 1131 c-30 -24 -33 -89 -5 -120 25 -27 77 -28 101 -1 16 17 16
20 2 20 -9 0 -19 -4 -22 -10 -9 -15 -42 -12 -59 6 -27 26 -18 34 39 34 52 0
55 1 55 25 0 32 -33 65 -64 65 -13 0 -34 -9 -47 -19z m80 -20 c14 -25 9 -27
-38 -23 -36 4 -40 6 -30 23 13 24 55 25 68 0z"
                />
                <path
                    d="M5890 1138 c0 -7 21 -25 47 -40 l47 -26 -47 -23 c-26 -13 -47 -29
-47 -36 0 -19 6 -17 71 23 33 19 57 40 53 45 -11 18 -124 70 -124 57z"
                />
                <path d="M1486 1023 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
                <path d="M1816 1023 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
                <path
                    d="M1980 1020 c-12 -7 -12 -12 -2 -22 9 -9 15 -9 24 0 16 16 -2 34 -22
22z"
                />
                <path d="M2064 1016 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />
                <path
                    d="M1312 1008 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path d="M1400 1004 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M1562 1008 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
                />
                <path
                    d="M1642 1008 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
-10z"
                />
                <path d="M1730 1004 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                <path
                    d="M1892 1008 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
-10z"
                />
                <path
                    d="M2212 497 c-22 -52 -65 -227 -59 -234 12 -11 17 2 48 116 30 109 36
179 11 118z"
                />
                <path
                    d="M2472 504 c-12 -8 -22 -24 -22 -34 0 -10 -6 -21 -12 -23 -10 -4 -10
-7 -1 -14 7 -5 13 -38 15 -73 4 -85 22 -84 26 3 2 51 6 67 17 67 8 0 15 5 15
10 0 6 -7 10 -15 10 -21 0 -19 27 3 39 12 7 14 13 7 20 -8 8 -17 6 -33 -5z"
                />
                <path
                    d="M2583 507 c-15 -6 -23 -19 -23 -34 0 -15 -6 -23 -17 -23 -17 -1 -17
-1 0 -14 13 -10 17 -28 17 -80 0 -37 4 -66 10 -66 6 0 10 30 10 70 0 63 2 70
20 70 11 0 20 5 20 10 0 6 -9 10 -20 10 -12 0 -20 7 -20 16 0 9 9 18 20 21 20
5 26 18 13 26 -5 3 -18 0 -30 -6z"
                />
                <path
                    d="M2032 417 c-29 -17 -51 -36 -49 -41 4 -11 106 -75 120 -76 4 0 7 7 7
15 0 8 -3 15 -7 15 -5 0 -25 11 -47 23 l-38 24 46 27 c44 26 60 47 34 45 -7 0
-37 -15 -66 -32z"
                />
                <path
                    d="M2275 420 c-8 -16 -15 -41 -15 -55 0 -33 41 -75 74 -75 53 0 85 88
50 138 -11 16 -25 22 -54 22 -34 0 -42 -4 -55 -30z m91 -18 c24 -49 -13 -105
-55 -83 -27 15 -35 58 -16 88 20 30 55 28 71 -5z"
                />
                <path
                    d="M2656 428 c-26 -37 -21 -93 11 -118 29 -23 80 -22 94 2 13 21 -2 30
-21 13 -23 -21 -48 -16 -68 13 -14 22 -14 22 42 22 55 0 56 0 56 29 0 39 -24
61 -65 61 -24 0 -39 -7 -49 -22z m82 -10 c21 -21 13 -28 -28 -28 -28 0 -40 4
-40 14 0 25 48 34 68 14z"
                />
                <path
                    d="M2817 398 c1 -29 2 -66 3 -82 0 -54 19 -14 22 49 3 54 5 60 26 63 36
5 25 22 -16 22 l-37 0 2 -52z"
                />
                <path
                    d="M2930 440 c0 -5 21 -21 46 -36 l46 -27 -38 -24 c-22 -12 -42 -23 -46
-23 -5 0 -8 -7 -8 -16 0 -12 13 -9 62 18 34 19 63 38 65 44 3 9 -100 74 -118
74 -5 0 -9 -4 -9 -10z"
                />
                <path d="M1315 320 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />
                <path
                    d="M1400 310 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"
                />
                <path
                    d="M1485 320 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path d="M1565 320 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
                <path d="M1645 320 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z" />
                <path
                    d="M1730 310 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"
                />
                <path
                    d="M1815 320 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
                />
                <path
                    d="M1894 319 c-3 -6 -1 -16 5 -22 9 -9 14 -8 21 3 9 15 4 30 -10 30 -5
0 -12 -5 -16 -11z"
                />
                <path
                    d="M1260 150 c0 -19 10 -20 920 -20 915 0 920 0 920 20 0 20 -5 20 -920
20 -910 0 -920 0 -920 -20z"
                />
                <path
                    d="M0 15 c0 -13 724 -15 6390 -15 5672 0 6390 2 6390 15 0 13 -718 15
-6390 15 -5666 0 -6390 -2 -6390 -15z"
                />
            </g>
        </svg>
    );
};
