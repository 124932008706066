import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconFilterTableProps = {
    classNames?: string[];
    color?: string;
};

export const IconFilterTable = ({ classNames, color }: TypeIconFilterTableProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5819_15010)">
                    <rect x="0.75" y="0.5" width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                    <path d="M20.75 9.1665V10.4998H20.0833L16.75 15.4998V21.1665H12.75V15.4998L9.41667 10.4998H8.75V9.1665H20.75ZM11.0193 10.4998L14.0833 15.0958V19.8332H15.4167V15.0958L18.4807 10.4998H11.0193Z" />
                </g>
                <defs>
                    <clipPath id="clip0_5819_15010">
                        <rect x="0.75" y="0.5" width="28" height="28" rx="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
