import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/home/ListItems.module.sass';
import { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import { Button, Checkbox, CircularProgress, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import GeneralForm from '@f_general/GeneralForm';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Alert } from '@f_general/alert';
import { Property } from '@f_crossdata/type';
import { ConfigReportContext } from '@f_crossdata/context/ConfigReport_context';
// import CloseIcon from '@mui/icons-material/Close';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { IconGreenCheck } from '../../icons/IconGreenCheck';
import { IconAnalysis } from '../../icons/IconAnalysis';
import { IconSettings } from '../../icons/IconSettings';
import { IconUp } from '../../icons/IconUp';

export function ListItems({ getCrossHandler }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { workspaceApplicationId, updateProperty, enteredData, setEnteredData, getReport, appInfo } =
        useContext(CrossdataContext);
    const { openSettings, crossesPartname, setOpenSettings, getCross } = useContext(ConfigReportContext);
    const [loadingSettingsReport, setLoadingSettingsReport] = useState(false);
    const [loadingReport, setLoadingReport] = useState({ value: false, refKey: '' });
    const [crossChecked, setCrossChecked] = useState(false);

    const showSettings = async (index: number, cross: boolean, brand: string, article: string) => {
        setOpenSettings(index);
        setCrossChecked(cross);
        if (cross && appInfo?.tariff?.cross) {
            getCrossHandler(brand, article, true);
        } else {
            getCrossHandler('', '', false);
        }
    };

    const closeSettings = async () => {
        setOpenSettings(-1);
        getCrossHandler('', '', false);
    };

    const showSettingsReportHandler = async (values: any) => {
        setLoadingSettingsReport(true);
        if (values.periodEnd !== values.periodStart) {
            let save = values.save;
            if (
                (!save && values.item.periodEnd !== values.periodEnd) ||
                values.item.periodStart !== values.periodStart ||
                values.item.status !== values.status ||
                values.item.currency !== values.currency ||
                values.item.minAvgReq !== values.minAvgReq ||
                values.item.minAvgBuy !== values.minAvgBuy ||
                values.item.cross !== crossChecked
            ) {
                save = true;
            }

            let items: any = [];
            if (crossesPartname.length) {
                crossesPartname.filter(
                    (item: any) => item.active && items.push({ article: item.article, brand: item.brand }),
                );
            }

            const result = await getReport({
                minAvgReq: values.minAvgReq,
                minAvgBuy: values.minAvgBuy,
                currency: values.currency,
                periodEnd: values.periodEnd,
                periodStart: values.periodStart,
                save: save,
                status: values.status,
                workspaceApplicationId: workspaceApplicationId,
                refKey: values.refKey,
                article: values.article,
                brand: values.brand,
                cross: crossChecked,
                data: items.length
                    ? items
                    : [
                          {
                              article: values.article,
                              brand: values.brand,
                          },
                      ],
            });

            if (result.isOk) {
                setOpenSettings(-1);
                getCrossHandler('', '', false);
                if (result.statusNumber === 1) {
                    Alert({
                        type: 'success',
                        text: t('crossdata.listItem.configSave'),
                    });
                } else if (result.statusNumber === 2) {
                    Alert({
                        type: 'success',
                        text: t('crossdata.listItem.configChange'),
                    });
                }
                setEnteredData((prev: any) => {
                    return prev?.map((item: any) => {
                        if (item.refKey === values.refKey) {
                            return {
                                ...item,
                                currency: values.currency,
                                periodEnd: values.periodEnd,
                                periodStart: values.periodStart,
                                status: values.status,
                                cross: crossChecked,
                            };
                        } else {
                            return item;
                        }
                    });
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        } else {
            Alert({
                type: 'warning',
                text: t('crossdata.listItem.configWarning'),
            });
        }
        setLoadingSettingsReport(false);
    };

    const deleteConfigReport = async (refKey: string, idx: number) => {
        if (refKey) {
            let values = enteredData.filter((item: any) => item.refKey !== refKey);
            const result = await updateProperty({
                applicationWorkspaceId: workspaceApplicationId,
                propertyName: Property.ItemSettingReport,
                value: values,
            });
            if (result.isOk) {
                setEnteredData(values);
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        } else {
            setEnteredData((prev: any) => prev.filter((item: any, index: number) => index !== idx));
        }
    };

    const crossHandler = (brand: string, article: string, checked: boolean) => {
        getCrossHandler(brand, article, checked);
        setCrossChecked(checked);
    };

    const showRoportHandler = async (item: any) => {
        setLoadingReport({ value: true, refKey: item.refKey });
        setOpenSettings(-1);
        getCrossHandler('', '', false);
        let items = [{ article: item.article, brand: item.brand }];
        if (item.cross) {
            const result = await getCross(item.article, item.brand);
            if (result.isOk) items = result.result;
        }
        const result = await getReport({
            minAvgReq: item.minAvgReq,
            minAvgBuy: item.minAvgBuy,
            currency: item.currency,
            periodEnd: item.periodEnd,
            periodStart: item.periodStart,
            save: false,
            status: item.status,
            workspaceApplicationId: workspaceApplicationId,
            refKey: item.refKey,
            article: item.article,
            brand: item.brand,
            cross: appInfo?.tariff?.cross ? item.cross : false,
            data: items,
        });
        if (result.isOk) {
            if (!result.result.content?.length) {
                Alert({
                    type: 'warning',
                    text: t('crossdata.listItem.reportWarning'),
                });
            }
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoadingReport({ value: false, refKey: item.refKey });
    };

    return (
        <div className={stls.container}>
            {enteredData?.length ? (
                enteredData.map((item: any, idx: number) => {
                    return (
                        <div
                            className={stls.item}
                            key={item.idx}
                            style={{
                                background: colors.omega,
                                boxShadow: '0px 3px 10px 0px rgba(0, 43, 77, 0.18)',
                                height: '66px',
                            }}>
                            <div className={stls.title}>
                                <div style={{ display: 'flex' }}>
                                    <div className={stls.check}>
                                        <IconGreenCheck style={{ color: colors.lightgreen, background: colors.psi }} />
                                    </div>
                                    <div>{`${item.brand} ${item.article}`}</div>
                                </div>
                                {item.refKey && (
                                    <Tooltip
                                        title={
                                            <div>
                                                <p style={{ fontSize: '16px' }}>
                                                    {t('crossdata.listItem.goToAnalysis')}
                                                </p>
                                                <p style={{ color: colors.alpha, fontSize: '13px' }}>
                                                    {t('crossdata.listItem.config')}
                                                </p>
                                                <p>
                                                    {t('crossdata.listItem.analogs')}:{' '}
                                                    <span style={{ color: colors.gamma, fontWeight: 800 }}>
                                                        {item.cross ? t('general.yes') : t('general.no')}
                                                    </span>
                                                </p>
                                                <p>
                                                    {t('crossdata.listItem.periodStart')}:{' '}
                                                    <span style={{ color: colors.gamma, fontWeight: 800 }}>
                                                        {item.periodStart}
                                                    </span>
                                                </p>
                                                <p>
                                                    {t('crossdata.listItem.periodEnd')}:{' '}
                                                    <span style={{ color: colors.gamma, fontWeight: 800 }}>
                                                        {item.periodEnd}
                                                    </span>
                                                </p>
                                                <p>
                                                    {t('crossdata.listItem.status')}:{' '}
                                                    <span style={{ color: colors.gamma, fontWeight: 800 }}>
                                                        {item.status === 'In stock'
                                                            ? t('crossdata.listItem.statusInStock')
                                                            : t('crossdata.listItem.statusOrder')}
                                                    </span>
                                                </p>
                                                <p>
                                                    {t('crossdata.listItem.currency')}:{' '}
                                                    <span style={{ color: colors.gamma, fontWeight: 800 }}>
                                                        {item.currency}
                                                    </span>
                                                </p>
                                            </div>
                                        }>
                                        <IconButton
                                            onClick={() => showRoportHandler(item)}
                                            disabled={loadingReport.value}>
                                            <IconAnalysis />
                                            {loadingReport.value && loadingReport.refKey === item.refKey && (
                                                <CircularProgress
                                                    className={stls.loading}
                                                    size={20}
                                                    sx={{ color: colors.omicron }}
                                                />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>

                            <Tooltip title={t('crossdata.listItem.goToSettings')}>
                                <IconButton
                                    onClick={() => {
                                        openSettings === idx
                                            ? closeSettings()
                                            : showSettings(idx, item.cross, item.brand, item.article);
                                    }}>
                                    {openSettings === idx ? <IconUp /> : <IconSettings />}
                                </IconButton>
                            </Tooltip>

                            {openSettings === idx && (
                                <div
                                    className={stls.settingsForm}
                                    style={{
                                        background: colors.omega,
                                        boxShadow: '0px 3px 10px 0px rgba(0, 43, 77, 0.18)',
                                    }}>
                                    <GeneralForm
                                        sendDataHandler={showSettingsReportHandler}
                                        close={() => {}}
                                        classNames={[stls.contains]}
                                        actions={
                                            <div className={stls.actions}>
                                                {item.refKey && (
                                                    <Button
                                                        variant="contained"
                                                        className={stls.btnDel}
                                                        style={{ background: colors.chi, color: colors.phi }}
                                                        onClick={() => deleteConfigReport(item.refKey, idx)}>
                                                        {' '}
                                                        Удалить
                                                    </Button>
                                                )}

                                                <Button
                                                    disabled={loadingSettingsReport}
                                                    type={'submit'}
                                                    variant="contained"
                                                    className={stls.btn}>
                                                    {t('crossdata.listItem.goToAnalysis')}
                                                    {loadingSettingsReport && (
                                                        <CircularProgress
                                                            className={stls.loading}
                                                            size={20}
                                                            sx={{ color: colors.omicron }}
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                        }
                                        mainData={{
                                            icon: '',
                                            title: '',
                                            props: [
                                                {
                                                    title: t('crossdata.listItem.periodStart'),
                                                    control: 'inputDate',
                                                    name: 'periodStart',
                                                    defaultValue: item.periodStart,
                                                    castomStyle: {
                                                        minWidth: '165px',
                                                        height: '50px',
                                                    },
                                                },
                                                {
                                                    title: t('crossdata.listItem.periodEnd'),
                                                    control: 'inputDate',
                                                    name: 'periodEnd',
                                                    defaultValue: item.periodEnd,
                                                    castomStyle: {
                                                        minWidth: '165px',
                                                        height: '50px',
                                                    },
                                                },
                                                {
                                                    control: 'select',
                                                    name: 'status',
                                                    title: t('crossdata.listItem.status'),
                                                    defaultValue: 'In stock',
                                                    castomStyle: {
                                                        width: '165px',
                                                        minWidth: '165px !important',
                                                        height: '50px',
                                                    },
                                                    options: [
                                                        {
                                                            value: 'In stock',
                                                            key: t('crossdata.listItem.statusInStock'),
                                                        },
                                                        {
                                                            value: 'Order',
                                                            key: t('crossdata.listItem.statusOrder'),
                                                        },
                                                    ],
                                                },
                                                {
                                                    control: 'select',
                                                    name: 'currency',
                                                    title: t('crossdata.listItem.currency'),
                                                    defaultValue: 'RUB',
                                                    castomStyle: {
                                                        width: '165px',
                                                        minWidth: '165px !important',
                                                        height: '50px',
                                                    },
                                                    options: [
                                                        {
                                                            value: 'RUB',
                                                            key: 'RUB',
                                                        },
                                                        {
                                                            value: 'EUR',
                                                            key: 'EUR',
                                                        },
                                                    ],
                                                },
                                                {
                                                    control: 'checkbox',
                                                    title: '',
                                                    name: 'minAvgReq',
                                                    type: 'checkbox',
                                                    castomStyle: {
                                                        marginRight: '20px',
                                                    },
                                                    options: [
                                                        {
                                                            key: 'Доля запросов',
                                                            value: false,
                                                        },
                                                    ],
                                                },
                                                {
                                                    control: 'checkbox',
                                                    title: '',
                                                    name: 'minAvgBuy',
                                                    type: 'checkbox',
                                                    options: [
                                                        {
                                                            key: 'Доля заказов',
                                                            value: false,
                                                        },
                                                    ],
                                                },
                                                !item.refKey && {
                                                    control: 'checkbox',
                                                    title: '',
                                                    name: 'save',
                                                    type: 'checkbox',
                                                    options: [
                                                        {
                                                            key: t('crossdata.listItem.save'),
                                                            value: false,
                                                        },
                                                    ],
                                                },
                                            ],
                                            shema: null,
                                            type: 'report',
                                            data: {
                                                minAvgBuy: item.minAvgBuy ? item.minAvgBuy : false,
                                                minAvgReq: item.minAvgReq ? item.minAvgReq : false,
                                                status: item.status ? item.status : 'In stock',
                                                currency: item.currency ? item.currency : 'RUB',
                                                periodStart: item.periodStart,
                                                periodEnd: item.periodEnd,
                                                cross: item.cross,
                                                save: false,
                                                refKey: item.refKey,
                                                article: item.article,
                                                brand: item.brand,
                                                item: item,
                                            },
                                        }}
                                        loading={false}
                                    />
                                    <div className={stls.settingsPanel}>
                                        {appInfo?.tariff?.cross && (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{ marginTop: '5px' }}
                                                        checked={crossChecked}
                                                        onChange={({ target }) =>
                                                            crossHandler(item.brand, item.article, target.checked)
                                                        }
                                                    />
                                                }
                                                label={t('crossdata.listItem.analogs')}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })
            ) : (
                <p className={stls.text}>{t('crossdata.listItem.noSavedConfigurations')}</p>
            )}
        </div>
    );
}
