import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { CellVendor } from './CellVendor';
import stls from '@f_qwep/styles/component/settings/Accounts.module.sass';
import { CellLogin } from './CellLogin';
import { CellPromo } from './CellPromo';
import { CellSettings } from './CellSettings';

export function MobileCart({ info }: any) {
    return (
        <div className={stls.mobileCart}>
            <div className={stls.settings}>
                <CellPromo info={info} />
            </div>
            <div className={stls.settings}>
                <CellSettings info={info} />
            </div>

            <div className={stls.title}>
                <CellVendor info={info} />
            </div>
            <div className={stls.login}>
                <CellLogin info={info} />
            </div>
        </div>
    );
}

export function MobileHeader() {
    const { t } = useTranslation();
    return (
        <div style={{ display: 'flex' }}>
            <Checkbox defaultChecked />
            <p>{t('qwep.settings.suppliers.supplier')}</p>
        </div>
    );
}
