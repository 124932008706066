import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext } from 'react';
import stls from '@f_styles/general/Universal.module.sass';

type TypeIconStartProps = {
    classNames?: string[];
    color?: string;
};

export const IconStart = ({ classNames, color }: TypeIconStartProps) => {
    const { colors } = useContext(ThemeContext);

    return (
        <div className={stls.iconClose}>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5819_15004)">
                    <rect x="0.75" y="0.5" width="28" height="28" rx="14" fill={colors.epsilon} fillOpacity={0.2} />
                    <path d="M17.5314 13.8331L13.9554 10.2571L14.8981 9.31445L20.0834 14.4998L14.8981 19.6851L13.9554 18.7425L17.5314 15.1665H9.41675V13.8331H17.5314Z" />
                </g>
                <defs>
                    <clipPath id="clip0_5819_15004">
                        <rect x="0.75" y="0.5" width="28" height="28" rx="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
