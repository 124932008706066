import { ThemeContext } from '@f_context/Theme_context';
import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import { CardContent, MenuItem, Modal, Select, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricer.module.sass';
import { isEmpty } from 'lodash';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { Alert } from '@f_general/alert';
import GeneralForm from '@f_general/GeneralForm';
import { IconAddModal } from '../icons/IconAddModal';
import { Limits } from '@f_crossdata/type';

type TypeEditProps = {
    open: boolean;
    handleClose: any;
    crossdataAppInfo: any;
    currentApplication: any;
    userInfo: any;
    currentWorkspace: any;
};

export function EditApplicationCrossdataModal({
    open,
    handleClose,
    crossdataAppInfo,
    currentApplication,
    userInfo,
    currentWorkspace,
}: TypeEditProps) {
    const { t } = useTranslation();
    const { colors, CustomSelect } = useContext(ThemeContext);
    const { updateCrossdataApp } = useContext(UserAccountContext);
    const [options, setOptions] = useState([
        {
            value: 1,
            key: 'Продлить ключ',
        },
        {
            value: 2,
            key: 'Сменить ключ',
        },
    ]);

    const [form, setForm] = useState<any>();

    const closeModal = () => {
        setForm({});
        handleClose();
    };

    useEffect(() => {
        if (open) {
            changeHandler(1);
        }
    }, [open]);

    const changeHandler = (value: any) => {
        switch (value) {
            case 1:
                setForm({
                    icon: '',
                    title: t('adminPanel.changePeriod'),
                    props: [
                        {
                            title: t('adminPanel.periodEnd'),
                            control: 'inputDate',
                            name: 'expire',
                            type: 'data',
                            defaultValue: crossdataAppInfo.expire,
                        },
                        ...(() => {
                            return Object.entries(Limits).map((item: any) => {
                                let defaultValue =
                                    crossdataAppInfo.limits.find((limit: any) => limit.interval === item[0])?.limit ??
                                    '';
                                return {
                                    control: 'input',
                                    name: item[0],
                                    title: item[0],
                                    type: 'number',
                                    defaultValue: defaultValue,
                                };
                            });
                        })(),
                    ],
                    shema: null,
                    type: 'editApp',
                    data: {
                        name: crossdataAppInfo.name,
                        expire: crossdataAppInfo.expire,
                        ...(() => {
                            let limit: any = {};
                            crossdataAppInfo.limits.map((item: any) => {
                                limit[item.interval] = item.limit;
                            });
                            return limit;
                        })(),
                        type: 1,
                    },
                });
                break;
            case 2:
                setForm({
                    icon: '',
                    title: 'Сменить DATA токен',
                    props: [
                        {
                            control: 'input',
                            name: 'authToken',
                            title: 'Токен приложения',
                            type: 'text',
                            defaultValue: currentApplication.propertyApplication.authToken,
                        },
                    ],
                    shema: null,
                    type: 'changeToken',
                    data: {
                        authToken: currentApplication.propertyApplication.authToken,
                        type: 2,
                    },
                });

                break;
        }
    };

    const sendDataHandler = async (values: any) => {
        let content: any = {};
        let authToken: string = currentApplication.propertyApplication.authToken;
        if (values.type === 1) {
            let limits: any = [];
            Object.entries(values).map((item: any) => {
                if (Object.entries(Limits).some((l: any) => l[0] === item[0])) {
                    limits.push({ interval: item[0], limit: item[1] });
                }
            });
            content.limits = limits;
            content.name = values.name;
            content.expire = values.expire;
        } else if (values.type === 2) {
            authToken = values.authToken;
        }

        const resultEditCrossdataApp = await updateCrossdataApp({
            ...(!isEmpty(content) && { content }),
            type: values.type,
            authToken: authToken,
            applicationWorkspaceId: currentApplication.applicationWorkspaceId,
            userId: userInfo.userId,
            workspaceId: currentWorkspace.workspaceId,
        });
        if (resultEditCrossdataApp.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });

            closeModal();
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <div className={stls.modalTitle}>
                            <IconAddModal />
                            <Typography sx={{ marginLeft: '16px' }} variant="h6">
                                {t('adminPanel.changeTariff')}
                            </Typography>
                        </div>
                    </div>

                    <div className={stls.content}>
                        <Select
                            input={<CustomSelect />}
                            className={stls.select}
                            autoWidth
                            defaultValue={options[0].value}
                            onChange={(e: any) => changeHandler(e.target.value)}
                            MenuProps={{
                                sx: {
                                    '&& .MuiMenuItem-root': {
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        color: colors.omicron,
                                        '&:hover': {
                                            background: colors.beta,
                                        },
                                    },
                                },
                            }}>
                            {options.map((option: any) => {
                                return (
                                    <MenuItem id={option.value} key={option.key} value={option.value}>
                                        {option.key}
                                    </MenuItem>
                                );
                            })}
                        </Select>

                        <div>
                            {form?.type === 'editApp' && (
                                <GeneralForm
                                    sendDataHandler={sendDataHandler}
                                    close={closeModal}
                                    mainData={form}
                                    loading={false}
                                />
                            )}
                            {form?.type === 'changeToken' && (
                                <GeneralForm
                                    sendDataHandler={sendDataHandler}
                                    close={closeModal}
                                    mainData={form}
                                    loading={false}
                                />
                            )}
                        </div>
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
}
