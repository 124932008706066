import { useContext } from 'react';
import { PreviewTableContext } from '@f_qwep/general/previewTable/context/PreviewTable.context';

export const usePreviewTableStore = () => {
    const context = useContext(PreviewTableContext);
    if (context === null) {
        throw new Error('You have forgotten to wrap your root component with RootStoreProvider');
    }
    return context;
};
