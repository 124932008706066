import { createContext, useState } from 'react';

const SettingsContext = createContext<any>({});

function SettingsProvider(props: any) {
    const [open, setOpen] = useState(false);

    const [selectedPageName, setSelectedPageName] = useState('accounts');

    const navigatinHandler = (pageName: string) => {
        console.log(pageName, 'pageName');
        setSelectedPageName(pageName);
    };

    return (
        <SettingsContext.Provider
            value={{
                selectedPageName,
                open,
                setOpen,
                setSelectedPageName,
                navigatinHandler,
            }}
            {...props}
        />
    );
}

export { SettingsProvider, SettingsContext };
