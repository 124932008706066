import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { IconNotGroup } from '@f_components/icons/IconNotGroup';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { useContext, useEffect, useState } from 'react';

type TypeProps = {
    type: string;
    content?: any;
};

export function PageNoData({ type, content }: TypeProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        switch (type) {
            case 'price':
                setTitle(`${t('qwep.pageNoData.price.title')}`);
                setDescription(`${t('qwep.pageNoData.price.description')}`);
                break;
            case 'batchPricer':
                setTitle(`${t('qwep.pageNoData.batchPricer.title')}`);
                setDescription(`${t('qwep.pageNoData.batchPricer.description')}`);
                break;
            case 'basket':
                setTitle(`${t('qwep.pageNoData.basket.title')}`);
                setDescription(`${t('qwep.pageNoData.basket.description')}`);
                break;
            case 'orders':
                setTitle(`${t('qwep.pageNoData.orders.title')}`);
                setDescription(`${t('qwep.pageNoData.orders.description')}`);
                break;
            case 'ordersFiltered':
                setTitle(`${t('qwep.pageNoData.ordersFiltered.title')}`);
                setDescription(`${t('qwep.pageNoData.ordersFiltered.description')}`);
                break;
            case 'accounts':
                setTitle(`${t('qwep.pageNoData.accounts.title')}`);
                setDescription(`${t('qwep.pageNoData.accounts.description')}`);
                break;
            case 'search':
                setTitle(`${t('qwep.pageNoData.search.title')}`);
                setDescription(`${t('qwep.pageNoData.search.description')}`);
                break;
            case 'assistant':
                setTitle(`${t('qwep.pageNoData.assistant.title')}`);
                setDescription(`${t('qwep.pageNoData.assistant.description')}`);
                break;
        }
    }, []);

    return (
        <Card sx={{ minHeight: '100%' }}>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '600px',
                }}>
                <IconNotGroup />
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" sx={{ color: colors.phi, marginRight: '8px' }}>
                        {title}
                    </Typography>
                    <Tooltip title={description}>
                        <div>
                            <ErrorOutlineOutlinedIcon fontSize="medium" sx={{ color: colors.phi, cursor: 'pointer' }} />
                        </div>
                    </Tooltip>
                </div>
                {content}
            </CardContent>
        </Card>
    );
}
