import React, { useContext } from 'react';
import { MenuItem, Select, Tooltip } from '@mui/material';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { TypeAssistantCustomList } from '@f_qwep/context/Qwep_context';
import ShieldIcon from '@mui/icons-material/Shield';
import { ThemeContext } from '@f_context/Theme_context';
import { observer } from 'mobx-react-lite';
import { useGetDeals } from '@f_qwep/components/purchasingAssistant/model/hooks/useGetDeals';
import { useAssistantStore } from '@f_qwep/context/AssistantStore.context';
import { AlertStatusEnum } from '@f_qwep/components/purchasingAssistant/types/AlertStatusEnum.enum';

export const AssistantLists = observer(() => {
    const store = useAssistantStore();
    const { assistantLists, currentAssistantList, setCurrentAssistantList } = store.assistantList;
    const { isDataLoading } = store.assistantDeals;
    const { colors, CustomSelect } = useContext(ThemeContext);

    const { mutate: getDeals } = useGetDeals(store);

    return assistantLists.length ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                disabled={isDataLoading}
                input={<CustomSelect />}
                className={styles.select}
                sx={{
                    '& .MuiSelect-select': {
                        padding: '8px',
                        width: '170px',
                        display: 'flex',
                    },
                }}
                autoWidth
                value={currentAssistantList.refKey}
                MenuProps={{
                    sx: {
                        '&& .MuiMenuItem-root': {
                            fontSize: 14,
                            fontFamily: 'Inter',
                            color: colors.omicron,
                            '&:hover': {
                                background: colors.beta,
                            },
                        },
                    },
                }}>
                {assistantLists.map((option: TypeAssistantCustomList) => {
                    return (
                        <MenuItem
                            onClick={() => {
                                setCurrentAssistantList(option);
                                getDeals(AlertStatusEnum.BASE);
                            }}
                            key={option.refKey}
                            value={option.refKey}>
                            {option.title}
                            {option.private && (
                                <Tooltip title={'Приватный список'}>
                                    <ShieldIcon style={{ width: '15px', marginLeft: '10px' }} />
                                </Tooltip>
                            )}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    ) : (
        <></>
    );
});
