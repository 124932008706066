import cn from 'classnames';
import { ThemeContext } from '@f_context/Theme_context';
import { TypeClassNames } from '@f_types/index';
import { useContext } from 'react';

type TypeIconFailProps = TypeClassNames;

export const IconFail = ({ classNames }: TypeIconFailProps) => {
    const { colors, currentTheme } = useContext(ThemeContext);

    return (
        <div className={cn(classNames)}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill="#EF907F" fillOpacity={0.2} />
                <path
                    d="M13.2929 12.2327C13.6834 12.6232 14.3166 12.6232 14.7071 12.2327L17.0305 9.90933C17.3652 9.57459 17.9079 9.57459 18.2426 9.90933C18.5774 10.2441 18.5774 10.7868 18.2426 11.1215L15.9193 13.4449C15.5288 13.8354 15.5288 14.4686 15.9193 14.8591L18.2426 17.1824C18.5774 17.5172 18.5774 18.0599 18.2426 18.3946C17.9079 18.7293 17.3652 18.7293 17.0305 18.3946L14.7071 16.0713C14.3166 15.6807 13.6834 15.6807 13.2929 16.0713L10.9695 18.3946C10.6348 18.7293 10.0921 18.7293 9.75736 18.3946C9.42262 18.0599 9.42262 17.5172 9.75736 17.1824L12.0807 14.8591C12.4712 14.4686 12.4712 13.8354 12.0807 13.4449L9.75736 11.1215C9.42262 10.7868 9.42262 10.2441 9.75736 9.90933C10.0921 9.57459 10.6348 9.57459 10.9695 9.90933L13.2929 12.2327Z"
                    fill="#EF907F"
                />
            </svg>
        </div>
    );
};
