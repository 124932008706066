import cn from 'classnames';
import { TypeClassNames } from '@f_types/index';
import { ThemeContext } from '@f_context/Theme_context';
import React from 'react';

type TypeIconMaskotHappyProps = TypeClassNames;

export const IconMaskotHappy = ({ classNames }: TypeIconMaskotHappyProps) => {
    const { colors } = React.useContext(ThemeContext);

    return (
        <div style={{ position: 'fixed', marginLeft: '-200px' }} className={cn(classNames)}>
            <svg width="200" height="200" viewBox="0 0 567 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask
                    id="path-1-outside-1_5819_14461"
                    maskUnits="userSpaceOnUse"
                    x="265.808"
                    y="319.826"
                    width="36"
                    height="45"
                    fill="black">
                    <rect fill="white" x="265.808" y="319.826" width="36" height="45" />
                    <path d="M268.808 319.826H297.974V364.697H268.808V319.826Z" />
                </mask>
                <path d="M268.808 319.826H297.974V364.697H268.808V319.826Z" fill="#A2DC05" />
                <path
                    d="M295.019 319.826V364.697H300.929V319.826H295.019ZM271.763 364.697V319.826H265.853V364.697H271.763Z"
                    fill="black"
                    mask="url(#path-1-outside-1_5819_14461)"
                />
                <path d="M268.808 319.826H296.852V364.697H268.808V319.826Z" fill="#51C407" />
                <path
                    d="M268.808 320.811C268.808 320.267 269.249 319.826 269.793 319.826H295.867C296.411 319.826 296.852 320.267 296.852 320.811V322.793C296.852 323.287 296.486 323.705 295.996 323.77L276.917 326.28C276.427 326.345 276.061 326.762 276.061 327.257V353.753V363.712C276.061 364.256 275.62 364.697 275.076 364.697H269.793C269.249 364.697 268.808 364.256 268.808 363.712V320.811Z"
                    fill="#17A61A"
                />
                <rect
                    x="85.7435"
                    y="65.2752"
                    width="390.09"
                    height="259.636"
                    rx="30.8487"
                    transform="rotate(0.241293 85.7435 65.2752)"
                    fill="url(#paint0_linear_5819_14461)"
                    stroke="black"
                    stroke-width="5.60885"
                />
                <rect
                    x="97.4613"
                    y="74.6436"
                    width="364.401"
                    height="239.181"
                    rx="25.2398"
                    transform="rotate(0.241293 97.4613 74.6436)"
                    fill="url(#paint1_linear_5819_14461)"
                    stroke="black"
                    stroke-width="5.60885"
                />
                <mask id="path-7-inside-2_5819_14461" fill="white">
                    <path d="M99.9434 99.7721C99.9955 87.3815 110.082 77.3792 122.473 77.4314L436.978 78.7559C449.369 78.8081 459.371 88.895 459.319 101.286L458.522 290.572C458.47 302.962 448.383 312.965 435.992 312.912L121.487 311.588C109.096 311.536 99.094 301.449 99.1462 289.058L99.9434 99.7721Z" />
                </mask>
                <path
                    d="M100.038 77.3369L459.413 78.8504L100.038 77.3369ZM461.882 291.708C461.822 305.957 450.222 317.459 435.973 317.399L125.955 316.094C111.086 316.031 99.0836 303.927 99.1462 289.058C99.1045 298.971 109.115 307.049 121.506 307.101L436.011 308.425C446.543 308.47 455.115 300.47 455.156 290.557L461.882 291.708ZM99.0517 311.493L100.038 77.3369L99.0517 311.493ZM436.978 78.7559C451.227 78.8159 462.73 90.4158 462.67 104.665L461.882 291.708C461.822 305.957 450.222 317.459 435.973 317.399L436.011 308.425C446.543 308.47 455.115 300.47 455.156 290.557L455.954 101.271C456.006 88.8808 447.51 78.8003 436.978 78.7559Z"
                    fill="#0092AD"
                    mask="url(#path-7-inside-2_5819_14461)"
                />
                <mask id="path-9-inside-3_5819_14461" fill="white">
                    <path d="M458.522 290.572C458.47 302.962 448.383 312.965 435.992 312.912L121.487 311.588C109.096 311.536 99.094 301.449 99.1462 289.058L99.9433 99.7721C99.9955 87.3815 110.082 77.3792 122.473 77.4314L436.978 78.7559C449.369 78.8081 459.371 88.895 459.319 101.286L458.522 290.572Z" />
                </mask>
                <path
                    d="M458.427 313.007L99.0517 311.493L458.427 313.007ZM96.5828 98.6362C96.6428 84.387 108.243 72.8843 122.492 72.9444L432.51 74.25C447.379 74.3126 459.382 86.4168 459.319 101.286C459.361 91.3731 449.35 83.2951 436.959 83.2429L122.454 81.9184C111.922 81.8741 103.35 89.8738 103.309 99.7863L96.5828 98.6362ZM459.413 78.8504L458.427 313.007L459.413 78.8504ZM121.487 311.588C107.238 311.528 95.7351 299.928 95.7951 285.679L96.5828 98.6362C96.6428 84.387 108.243 72.8843 122.492 72.9444L122.454 81.9184C111.922 81.8741 103.35 89.8738 103.309 99.7863L102.511 289.072C102.459 301.463 110.955 311.543 121.487 311.588Z"
                    fill="#0092AD"
                    mask="url(#path-9-inside-3_5819_14461)"
                />
                <g filter="url(#filter0_f_5819_14461)">
                    <path
                        d="M161.117 81.0744C146.901 83.5393 145.237 83.3659 122.89 86.3763C100.542 89.3868 100.542 134.69 100.542 120.516C100.542 108.738 98.8592 79.225 122.89 79.225C136.203 79.225 156.172 76.76 161.117 81.0744Z"
                        fill="#0092AD"
                    />
                </g>
                <g filter="url(#filter1_f_5819_14461)">
                    <path
                        d="M367.523 309.621C389.112 307.464 409.631 310.557 434.829 306.804C460.028 303.051 457.265 242.302 457.265 274.273C457.265 284.578 460.63 313.535 425.573 311.239C405.399 309.918 375.032 313.396 367.523 309.621Z"
                        fill="#0092AD"
                    />
                </g>
                <g filter="url(#filter2_f_5819_14461)">
                    <path
                        d="M397.811 81.0744C412.028 83.5393 412.394 83.1911 436.039 86.3763C459.684 89.5615 454.626 140.782 456.704 126.76C458.947 111.616 461.752 79.225 436.039 79.225C422.725 79.225 402.756 76.76 397.811 81.0744Z"
                        fill="#0092AD"
                    />
                </g>
                <g filter="url(#filter3_f_5819_14461)">
                    <path
                        d="M189.249 308.794C167.66 306.637 149.698 307.285 125.782 302.317C101.865 297.349 98.1238 241.356 100.542 253.52C103.663 269.225 88.2023 315.778 131.199 310.412C151.261 307.909 181.74 312.569 189.249 308.794Z"
                        fill="#0092AD"
                    />
                </g>
                <path
                    d="M186.37 123.043C188.633 121.254 188.417 117.791 185.712 116.787C183.125 115.826 180.283 115.156 177.264 114.805C172.015 114.193 166.389 114.56 160.87 115.874C155.351 117.187 150.104 119.408 145.581 122.344C142.904 124.082 140.529 126.04 138.52 128.154C136.572 130.205 137.85 133.368 140.615 133.967C142.272 134.326 143.954 133.595 145.047 132.299C146.682 130.361 148.732 128.578 151.109 127.034C154.388 124.905 158.192 123.295 162.193 122.343C166.195 121.39 170.274 121.125 174.079 121.568C176.748 121.879 179.227 122.532 181.413 123.494C183.043 124.211 184.973 124.147 186.37 123.043Z"
                    fill="#074955"
                />
                <path
                    d="M370.854 124.627C368.756 122.647 369.273 119.217 372.054 118.452C374.716 117.72 377.605 117.301 380.644 117.214C385.925 117.062 391.498 117.918 396.882 119.708C402.266 121.497 407.299 124.167 411.549 127.486C414.064 129.451 416.259 131.608 418.076 133.889C419.839 136.102 418.289 139.142 415.483 139.498C413.801 139.711 412.19 138.835 411.213 137.449C409.754 135.376 407.867 133.421 405.633 131.676C402.552 129.269 398.903 127.334 395 126.037C391.096 124.739 387.056 124.119 383.227 124.229C380.541 124.306 378.015 124.741 375.753 125.508C374.067 126.081 372.15 125.849 370.854 124.627Z"
                    fill="#074955"
                />
                <circle cx="197.014" cy="195.188" r="40.3837" fill="#074955" />
                <circle cx="359.671" cy="195.188" r="40.3837" fill="#074955" />
                <ellipse
                    cx="178.949"
                    cy="186.968"
                    rx="11.2177"
                    ry="19.0701"
                    transform="rotate(26.345 178.949 186.968)"
                    fill="#FAFAFA"
                />
                <ellipse
                    cx="341.168"
                    cy="186.968"
                    rx="11.2177"
                    ry="19.0701"
                    transform="rotate(26.345 341.168 186.968)"
                    fill="#FAFAFA"
                />
                <circle cx="207.671" cy="180.044" r="7.29151" fill="#D9D9D9" />
                <circle cx="369.206" cy="180.044" r="7.29151" fill="#D9D9D9" />
                <path
                    d="M209.31 199.561C208.116 200 206.848 200.2 205.577 200.149C204.306 200.098 203.058 199.796 201.904 199.262C200.748 198.729 199.712 197.971 198.85 197.036C197.989 196.099 197.319 195.003 196.883 193.808C197.323 195 197.525 196.268 197.474 197.541C197.423 198.811 197.123 200.059 196.589 201.213C196.055 202.367 195.297 203.406 194.362 204.267C193.427 205.128 192.331 205.796 191.136 206.233C192.329 205.794 193.599 205.594 194.868 205.645C196.138 205.696 197.387 205.998 198.54 206.532C199.694 207.066 200.733 207.823 201.592 208.759C202.454 209.696 203.121 210.792 203.557 211.987C203.117 210.793 202.918 209.525 202.969 208.254C203.019 206.983 203.322 205.735 203.855 204.581C204.389 203.425 205.147 202.389 206.082 201.528C207.019 200.666 208.115 199.997 209.31 199.561Z"
                    fill="#FFEA87"
                    stroke="#FFEA87"
                    stroke-width="1.41553"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M354.911 207.557C356.087 207.073 357.156 206.361 358.057 205.464C358.958 204.566 359.672 203.499 360.16 202.325C360.65 201.15 360.9 199.89 360.901 198.619C360.901 197.346 360.652 196.086 360.164 194.911C360.648 196.087 361.357 197.156 362.256 198.059C363.153 198.96 364.219 199.675 365.393 200.163C366.567 200.651 367.828 200.903 369.099 200.904C370.37 200.905 371.629 200.654 372.805 200.168C371.63 200.652 370.559 201.364 369.66 202.26C368.759 203.158 368.045 204.225 367.557 205.399C367.069 206.573 366.817 207.834 366.818 209.104C366.818 210.377 367.068 211.636 367.556 212.811C367.072 211.634 366.36 210.565 365.463 209.664C364.565 208.763 363.498 208.049 362.324 207.561C361.149 207.071 359.889 206.821 358.618 206.82C357.345 206.82 356.085 207.069 354.911 207.557Z"
                    fill="#FFEA87"
                    stroke="#FFEA87"
                    stroke-width="1.41553"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M303.467 242.929C304.236 242.923 304.867 243.542 304.84 244.311C304.722 247.73 304.079 251.102 302.936 254.278C301.638 257.884 299.721 261.166 297.294 263.936C294.868 266.707 291.979 268.912 288.793 270.425C285.607 271.938 282.186 272.73 278.726 272.755C275.265 272.78 271.833 272.038 268.626 270.572C265.418 269.105 262.497 266.943 260.03 264.208C257.564 261.473 255.599 258.22 254.248 254.633C253.059 251.474 252.367 248.111 252.199 244.695C252.162 243.927 252.784 243.298 253.553 243.293L278.51 243.111L303.467 242.929Z"
                    fill="#074955"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M178.403 265.3C179.446 261.18 174.806 256.32 168.086 253.215C161.965 250.387 155.084 250.437 150.207 252.274C144.873 254.284 140.772 257.789 143.961 262.303C147.376 267.136 155.74 270.474 163.545 271.153C171.138 271.814 177.32 269.576 178.403 265.3Z"
                    fill="#52E7FF"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M378.961 265.3C377.918 261.18 382.557 256.32 389.278 253.215C395.399 250.387 402.28 250.437 407.156 252.274C412.49 254.284 416.591 257.789 413.402 262.303C409.987 267.136 401.624 270.474 393.818 271.153C386.226 271.814 380.043 269.576 378.961 265.3Z"
                    fill="#4BE6FF"
                />
                <path
                    d="M357.232 367.722L356.022 367.532L355.47 368.625L344.257 390.807C343.605 392.097 344.041 393.673 345.264 394.444L403.619 431.239L403.71 431.296L403.807 431.341L408.009 433.294L408.224 433.394L408.458 433.431L412.377 434.044L412.541 434.07L412.708 434.063L414.728 433.978L414.833 433.973L414.936 433.956L417.41 433.54L417.592 433.51L417.763 433.44L419.906 432.572L421.023 432.119L420.954 430.915L419.526 406.113L419.469 405.118L418.569 404.69L404.869 398.17L382.552 382.707L370.358 373.587L370.286 373.533L370.209 373.488L362.863 369.126L362.69 369.023L362.497 368.964L359.815 368.143L359.701 368.108L359.583 368.09L357.232 367.722Z"
                    fill="#02A608"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M349.641 387.825L356.744 368.966L360.179 369.955L362.84 370.808L370.175 375.185L381.979 384.405L404.515 399.839L418.059 406.84L417.115 428.596L412.494 427.26L410.178 423.068L349.641 387.825Z"
                    fill="#8CEC40"
                />
                <path
                    d="M350.37 389.091L354.528 389.178L357.209 388.533C357.349 388.499 357.482 388.438 357.599 388.354L359.649 386.888L361.097 385.164L362.099 383.759L363.102 382.354L363.772 380.501L364.108 378.198C364.21 377.396 365.109 376.971 365.794 377.4L367.605 378.535L379.995 387.413L402.568 402.791L415.986 409.985L416.506 429.52L411.998 428.014L407.827 425.754L404.916 419.963L397.778 416.839L384.53 409.145L350.37 389.091Z"
                    fill="#63D116"
                />
                <circle
                    cx="12.709"
                    cy="12.709"
                    r="14.3917"
                    transform="matrix(0.834086 -0.551635 -0.551635 -0.834086 340.68 394.592)"
                    fill="url(#paint2_linear_5819_14461)"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M340.42 371.161C346.204 367.336 353.971 367.481 357.435 372.718C360.898 377.955 357.992 385.16 352.208 388.985C346.425 392.81 338.657 392.665 335.194 387.428C331.73 382.191 334.637 374.986 340.42 371.161Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M356.757 375.232C352.343 381.067 353.877 380.052 348.183 383.818C341.645 386.245 343.78 386.014 336.176 385.655C337.701 390.442 345.634 391.662 351.046 387.737C356.74 383.971 358.196 378.115 356.757 375.232Z"
                    fill="#A8A7A8"
                />
                <path
                    d="M447.858 421.912L447.849 421.93L447.84 421.947L445.994 425.418C445.905 425.585 445.8 425.743 445.678 425.891L443.816 428.166C443.692 428.318 443.552 428.458 443.398 428.584L442.301 427.372L443.398 428.584L438.91 432.267L437.813 431.055L438.91 432.267C438.728 432.417 438.529 432.545 438.316 432.65L434.146 434.706L432.769 435.385L431.949 434.21L417.262 413.181C416.649 412.303 416.658 411.14 417.285 410.215L430.483 390.722L430.501 390.696L430.519 390.671L450.428 363.412L459.898 346.909C460.045 346.653 460.232 346.419 460.453 346.214L462.9 343.953L462.969 343.889L463.045 343.832L466.001 341.62C467.056 340.831 468.509 340.792 469.532 341.526L483.259 351.375C484.349 352.156 484.648 353.593 483.969 354.78L464.299 389.167L447.858 421.912Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M467.351 342.56L479.725 351.222C480.182 351.542 480.298 352.147 479.995 352.631L458.601 386.753L439.954 420.211L431.974 427.206L432.443 431.912L419.282 410.308L431.52 391.387L451.29 363.843L456.093 355.473L460.896 347.104L465.868 342.671C466.286 342.298 466.911 342.252 467.351 342.56Z"
                    fill="#8CEC40"
                />
                <path
                    d="M481.938 351.956L482.856 352.751C483.234 353.078 483.317 353.616 483.06 354.06L463.063 388.523L446.579 421.774L443.454 426.626L436.699 432.372L437.78 415.355L446.381 404.091L461.946 377.308L474.999 358.534L477.512 355.465L480.239 352.135C480.663 351.616 481.45 351.534 481.938 351.956Z"
                    fill="#02A608"
                />
                <path
                    d="M430.954 404.015L428.63 403.69C427.845 403.58 427.452 402.758 427.866 402.092L433.84 392.501L453.324 364.815L461.137 350.678C461.613 349.817 462.921 349.859 463.245 350.747L463.958 352.701L465.179 354.239L466.896 355.16L469.155 356.019L472.454 356.199L479.675 354.971L459.231 388.468L440.634 419.016L440.719 416.312L439.858 412.497L438.592 410.406L436.739 407.824L433.801 405.365L430.954 404.015Z"
                    fill="#63D116"
                />
                <circle
                    cx="425.98"
                    cy="423.906"
                    r="14.3395"
                    transform="rotate(-5.68953 425.98 423.906)"
                    fill="url(#paint3_linear_5819_14461)"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M428.613 430.466C434.909 428.246 439.115 422.035 437.057 416.198C434.998 410.361 427.827 408.162 421.53 410.383C415.233 412.603 411.027 418.814 413.086 424.651C415.144 430.488 422.316 432.687 428.613 430.466Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <circle
                    cx="474.475"
                    cy="343.909"
                    r="10.0449"
                    transform="rotate(140.874 474.475 343.909)"
                    fill="url(#paint4_linear_5819_14461)"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M471.592 341.975C470.197 343.817 469.404 345.932 469.319 347.916C469.233 349.891 469.861 351.913 471.535 353.181C473.21 354.449 475.326 354.506 477.205 353.888C479.091 353.268 480.912 351.931 482.307 350.09C483.702 348.248 484.495 346.133 484.58 344.149C484.666 342.173 484.038 340.151 482.364 338.883C480.689 337.615 478.573 337.559 476.694 338.177C474.808 338.797 472.987 340.133 471.592 341.975Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M481.734 320.755C482.561 320.299 483.361 319.477 484.154 318.426C484.616 318.276 485.024 318.033 485.372 317.764C485.948 317.319 486.463 316.724 486.927 316.068C487.856 314.754 488.754 312.944 489.608 310.837C491.319 306.615 492.98 300.88 494.385 294.686C497.306 284.834 498.232 278.983 497.962 275.437C497.825 273.63 497.365 272.233 496.521 271.22C495.656 270.181 494.572 269.764 493.665 269.559C492.393 269.271 491.155 269.61 490.044 270.446C488.991 271.238 488.023 272.492 487.086 274.158C485.21 277.493 483.181 282.993 480.891 291.458C478.775 297.983 477.123 306.905 476.909 312.984C476.855 314.513 476.89 315.917 477.048 317.084C477.194 318.168 477.482 319.363 478.174 320.186C478.564 320.65 479.129 321.047 479.869 321.149C480.58 321.246 481.223 321.037 481.734 320.755Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M488 296.107C489.551 296.347 490.928 296.032 492.067 295.178C493.142 294.372 493.856 293.196 494.378 291.983C495.185 290.105 495.724 287.629 496.254 285.195C496.391 284.565 496.527 283.937 496.668 283.324C497.418 280.046 497.636 276.897 497.335 274.43C497.185 273.204 496.896 272.051 496.408 271.119C495.925 270.194 495.129 269.281 493.899 268.999C492.67 268.718 491.555 269.194 490.717 269.817C489.873 270.445 489.112 271.356 488.444 272.395C487.099 274.486 485.927 277.416 485.177 280.695C484.85 282.122 484.297 283.549 483.719 285.013C483.693 285.077 483.668 285.142 483.642 285.206C483.111 286.549 482.543 287.988 482.277 289.304C481.996 290.698 481.974 292.337 483.04 293.727C484.067 295.065 485.802 295.767 488 296.107Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M490.532 284.908C491.814 285.13 492.973 285.105 493.992 284.74C495.048 284.363 495.795 283.678 496.319 282.871C496.819 282.102 497.115 281.225 497.33 280.421C497.473 279.887 497.603 279.288 497.726 278.723C497.783 278.461 497.839 278.206 497.893 277.968C498.339 276.021 498.223 274.063 497.59 272.45C496.961 270.847 495.734 269.419 493.899 268.999C492.064 268.579 490.338 269.332 489.075 270.502C487.804 271.679 486.848 273.392 486.402 275.339C486.341 275.608 486.256 275.944 486.167 276.3C486.038 276.818 485.898 277.376 485.804 277.824C485.626 278.668 485.479 279.642 485.594 280.601C485.715 281.618 486.133 282.63 487.051 283.437C487.925 284.205 489.109 284.661 490.532 284.908Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M527.975 284.751C532.218 279.921 534.664 275.06 535.816 271.138C536.39 269.183 536.655 267.416 536.636 265.974C536.626 265.255 536.545 264.569 536.368 263.962C536.197 263.376 535.896 262.725 535.344 262.241C534.69 261.666 533.889 261.515 533.15 261.639C532.49 261.75 531.892 262.072 531.396 262.411C530.396 263.093 529.282 264.215 528.099 265.554C526.25 267.646 523.978 270.571 521.361 273.939C520.608 274.908 519.827 275.914 519.019 276.947C517 279.243 514.972 281.445 513.082 283.492C512.953 283.631 512.826 283.769 512.699 283.906C510.95 285.799 509.322 287.561 507.963 289.114C506.523 290.761 505.308 292.259 504.567 293.485C504.204 294.085 503.872 294.751 503.742 295.419C503.604 296.124 503.653 297.131 504.493 297.869C505.196 298.486 506.097 298.572 506.723 298.557C507.414 298.54 508.181 298.388 508.968 298.158C510.553 297.695 512.492 296.836 514.578 295.661C518.76 293.307 523.748 289.562 527.975 284.751Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M533.585 262.932C535.192 264.344 534.889 274.332 526.711 283.641C518.533 292.949 510.709 297.949 508.688 297.874C507.036 296.256 512.812 287.442 520.892 278.528C528.267 269.199 532.088 263.097 533.585 262.932Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M534.533 263.501C535.699 264.526 535.014 274.189 526.827 283.508C518.941 292.837 510.818 299.198 508.688 297.874C506.078 293.621 518.288 286.712 526.27 277.202C533.872 268.145 533.74 264.715 534.533 263.501Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M516.813 283.444L516.836 283.532L516.869 283.618C517.576 285.453 518.388 286.964 519.502 287.787C520.122 288.245 520.853 288.504 521.656 288.459C522.42 288.417 523.086 288.109 523.632 287.743C524.679 287.039 525.682 285.844 526.654 284.541C527.404 283.534 528.227 282.336 529.115 281.042C529.399 280.628 529.69 280.204 529.987 279.775C532.627 276.312 534.412 272.565 535.372 269.516C535.852 267.992 536.145 266.584 536.213 265.432C536.247 264.861 536.231 264.285 536.119 263.762C536.015 263.281 535.775 262.582 535.143 262.099C534.503 261.61 533.776 261.56 533.217 261.647C532.678 261.73 532.17 261.951 531.732 262.194C530.85 262.684 529.867 263.478 528.852 264.433C526.8 266.364 524.312 269.24 521.757 272.582C519.237 275.878 517.844 277.952 517.166 279.469C516.811 280.262 516.618 280.974 516.585 281.684C516.553 282.39 516.688 282.975 516.813 283.444Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M523.588 275.166L523.621 275.224L523.657 275.279C524.648 276.778 525.572 277.916 526.501 278.612C527.507 279.367 528.705 279.721 529.953 279.226C530.982 278.817 531.734 277.927 532.293 277.164C532.738 276.556 533.208 275.809 533.688 275.047C533.828 274.826 533.968 274.604 534.108 274.383C535.735 272.227 536.493 269.689 536.671 267.545C536.76 266.461 536.707 265.421 536.507 264.531C536.326 263.72 535.954 262.718 535.143 262.099C534.259 261.423 533.231 261.43 532.416 261.647C531.622 261.858 530.845 262.307 530.134 262.83C528.697 263.887 527.137 265.544 525.658 267.478C524.238 269.336 523.368 270.711 523.054 271.907C522.88 272.572 522.872 273.193 523.016 273.798C523.15 274.356 523.399 274.824 523.588 275.166Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M536.646 286.008C540.889 281.178 543.336 276.317 544.487 272.395C545.061 270.44 545.326 268.673 545.307 267.231C545.297 266.511 545.216 265.826 545.039 265.219C544.868 264.633 544.567 263.982 544.015 263.497C543.361 262.922 542.56 262.772 541.821 262.896C541.161 263.007 540.563 263.329 540.067 263.668C539.067 264.35 537.953 265.472 536.77 266.81C534.921 268.903 532.649 271.827 530.032 275.196C529.279 276.165 528.498 277.171 527.69 278.204C523.567 282.9 520.166 287.499 518.003 291.164C516.926 292.988 516.11 294.656 515.687 296.028C515.477 296.709 515.333 297.414 515.35 298.079C515.368 298.741 515.56 299.586 516.266 300.207C516.971 300.827 517.834 300.908 518.493 300.84C519.155 300.772 519.836 300.538 520.483 300.242C521.79 299.646 523.339 298.623 525.009 297.319C528.373 294.695 532.509 290.717 536.646 286.008Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M542.256 264.189C543.862 265.601 543.56 275.589 535.382 284.897C527.203 294.206 519.38 299.206 517.359 299.131C515.707 297.513 521.483 288.699 529.563 279.785C536.937 270.456 540.759 264.354 542.256 264.189Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M543.204 264.758C544.37 265.783 543.685 275.446 535.498 284.765C527.612 294.094 519.489 300.455 517.359 299.131C514.749 294.878 526.959 287.969 534.941 278.459C542.543 269.402 542.411 265.972 543.204 264.758Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M525.484 284.701L525.507 284.789L525.54 284.874C526.247 286.71 527.059 288.221 528.173 289.044C528.793 289.502 529.524 289.76 530.327 289.716C531.092 289.674 531.757 289.366 532.303 288.999C533.35 288.296 534.353 287.101 535.325 285.797C536.076 284.791 536.898 283.592 537.786 282.298C538.07 281.885 538.361 281.461 538.658 281.031C541.298 277.569 543.083 273.822 544.043 270.773C544.523 269.249 544.816 267.84 544.885 266.689C544.919 266.118 544.902 265.542 544.79 265.019C544.687 264.538 544.446 263.839 543.814 263.356C543.175 262.867 542.447 262.817 541.888 262.903C541.349 262.987 540.841 263.208 540.403 263.451C539.521 263.941 538.538 264.735 537.524 265.69C535.471 267.62 532.983 270.496 530.428 273.839C527.908 277.134 526.515 279.209 525.837 280.726C525.483 281.519 525.289 282.231 525.256 282.94C525.224 283.646 525.359 284.232 525.484 284.701Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M532.26 276.423L532.292 276.481L532.328 276.536C533.32 278.035 534.243 279.172 535.172 279.869C536.178 280.623 537.377 280.978 538.625 280.483C539.653 280.074 540.405 279.184 540.964 278.421C541.409 277.813 541.879 277.066 542.359 276.304C542.499 276.083 542.639 275.86 542.779 275.64C544.407 273.483 545.164 270.945 545.342 268.802C545.431 267.718 545.378 266.678 545.179 265.787C544.997 264.977 544.625 263.975 543.814 263.355C542.93 262.679 541.902 262.686 541.087 262.904C540.293 263.115 539.517 263.564 538.806 264.087C537.369 265.144 535.808 266.801 534.329 268.735C532.909 270.593 532.039 271.968 531.726 273.163C531.551 273.829 531.543 274.45 531.688 275.055C531.821 275.612 532.07 276.081 532.26 276.423Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M540.168 291.744C544.412 286.914 546.858 282.052 548.009 278.13C548.583 276.175 548.849 274.408 548.829 272.966C548.82 272.247 548.739 271.561 548.562 270.954C548.391 270.368 548.089 269.717 547.538 269.233C546.883 268.658 546.083 268.507 545.344 268.632C544.683 268.742 544.086 269.064 543.589 269.403C542.589 270.086 541.475 271.207 540.293 272.546C538.444 274.638 536.172 277.563 533.555 280.931C532.802 281.9 532.02 282.906 531.212 283.939C527.09 288.635 523.689 293.235 521.525 296.899C520.448 298.724 519.633 300.391 519.209 301.764C519 302.444 518.855 303.149 518.873 303.814C518.891 304.477 519.082 305.322 519.788 305.942C520.494 306.562 521.356 306.643 522.016 306.576C522.678 306.508 523.358 306.273 524.006 305.978C525.312 305.381 526.861 304.358 528.532 303.055C531.896 300.431 536.032 296.452 540.168 291.744Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M545.778 269.924C547.385 271.336 547.082 281.324 538.904 290.633C530.726 299.941 522.902 304.942 520.881 304.866C519.229 303.248 525.006 294.435 533.085 285.521C540.46 276.192 544.281 270.09 545.778 269.924Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M546.727 270.493C547.893 271.518 547.207 281.181 539.02 290.5C531.134 299.829 523.011 306.19 520.881 304.866C518.271 300.614 530.481 293.704 538.463 284.194C546.065 275.137 545.934 271.708 546.727 270.493Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M529.006 290.436L529.03 290.525L529.062 290.61C529.77 292.445 530.581 293.956 531.696 294.779C532.315 295.237 533.047 295.496 533.85 295.451C534.614 295.409 535.279 295.102 535.825 294.735C536.873 294.032 537.875 292.836 538.847 291.533C539.598 290.526 540.421 289.328 541.309 288.034C541.593 287.62 541.883 287.197 542.18 286.767C544.821 283.304 546.606 279.558 547.566 276.508C548.046 274.985 548.338 273.576 548.407 272.425C548.441 271.854 548.425 271.278 548.312 270.755C548.209 270.273 547.968 269.574 547.337 269.091C546.697 268.602 545.97 268.553 545.41 268.639C544.872 268.722 544.364 268.944 543.926 269.187C543.043 269.676 542.061 270.471 541.046 271.425C538.993 273.356 536.505 276.232 533.95 279.574C531.431 282.87 530.038 284.944 529.359 286.461C529.005 287.254 528.811 287.967 528.779 288.676C528.747 289.382 528.882 289.967 529.006 290.436Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M535.782 282.158L535.814 282.216L535.851 282.271C536.842 283.77 537.765 284.908 538.694 285.604C539.701 286.359 540.899 286.714 542.147 286.218C543.176 285.81 543.928 284.92 544.487 284.156C544.931 283.548 545.402 282.801 545.882 282.039C546.021 281.818 546.161 281.596 546.302 281.375C547.929 279.219 548.687 276.681 548.864 274.538C548.954 273.454 548.9 272.413 548.701 271.523C548.52 270.712 548.147 269.71 547.337 269.091C546.452 268.415 545.424 268.422 544.609 268.639C543.815 268.851 543.039 269.299 542.328 269.822C540.891 270.879 539.33 272.537 537.852 274.471C536.432 276.328 535.561 277.703 535.248 278.899C535.074 279.564 535.065 280.185 535.21 280.79C535.343 281.348 535.593 281.816 535.782 282.158Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M493.294 298.839L493.319 298.821L493.343 298.802C501.366 292.595 507.118 291.668 511.538 292.989C516.108 294.355 519.792 298.254 523.347 302.973C525.271 305.528 526.102 308.048 526.148 310.495C526.196 312.959 525.449 315.475 524.014 317.999C521.122 323.086 515.559 327.974 508.938 332.041C502.45 336.025 495.564 337.837 489.722 337.619C483.866 337.401 479.267 335.173 476.897 331.315C474.619 327.607 475.293 321.944 478.519 315.679C481.692 309.517 487.101 303.28 493.294 298.839Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M523.659 305.999C527.401 314.296 518.537 325.791 505.247 332.463C491.92 338.072 482.233 336.781 478.695 331.02C476.38 327.251 478.792 332.258 485.042 318.686C491.422 304.83 514.491 285.675 523.659 305.999Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M521.113 302.565C524.381 310.029 518.57 315.573 506.252 323.797C493.972 331.339 480.602 334.132 477.62 329.278C476.25 321.445 481.27 312.811 493.923 303.723C509.663 294.056 518.552 296.716 521.113 302.565Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M540.943 303.187C545.186 298.357 547.633 293.496 548.784 289.574C549.358 287.619 549.623 285.852 549.604 284.41C549.594 283.691 549.513 283.005 549.336 282.398C549.165 281.812 548.864 281.161 548.312 280.677C547.658 280.102 546.858 279.951 546.118 280.075C545.458 280.186 544.86 280.508 544.364 280.847C543.364 281.529 542.25 282.651 541.067 283.99C539.219 286.082 536.946 289.007 534.33 292.375C533.577 293.344 532.795 294.35 531.987 295.383C527.865 300.079 524.463 304.679 522.3 308.343C521.223 310.168 520.407 311.835 519.984 313.208C519.774 313.888 519.63 314.593 519.647 315.258C519.665 315.921 519.857 316.766 520.563 317.386C521.269 318.006 522.131 318.087 522.79 318.019C523.452 317.951 524.133 317.717 524.781 317.422C526.087 316.825 527.636 315.802 529.307 314.499C532.671 311.875 536.806 307.896 540.943 303.187Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M546.553 281.368C548.16 282.78 547.857 292.768 539.679 302.077C531.501 311.385 523.677 316.385 521.656 316.31C520.004 314.692 525.78 305.878 533.86 296.964C541.235 287.635 545.056 281.534 546.553 281.368Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M547.501 281.937C548.667 282.962 547.982 292.625 539.795 301.944C531.909 311.273 523.786 317.634 521.656 316.31C519.046 312.057 531.256 305.148 539.238 295.638C546.84 286.581 546.708 283.152 547.501 281.937Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M529.781 301.88L529.804 301.968L529.837 302.054C530.544 303.889 531.356 305.4 532.47 306.223C533.09 306.681 533.821 306.94 534.625 306.895C535.389 306.853 536.054 306.545 536.6 306.179C537.647 305.475 538.65 304.28 539.622 302.977C540.373 301.97 541.195 300.772 542.083 299.478C542.367 299.064 542.658 298.641 542.955 298.211C545.595 294.748 547.381 291.001 548.341 287.952C548.82 286.428 549.113 285.02 549.182 283.868C549.216 283.297 549.199 282.721 549.087 282.198C548.984 281.717 548.743 281.018 548.111 280.535C547.472 280.046 546.745 279.996 546.185 280.083C545.646 280.166 545.139 280.388 544.701 280.63C543.818 281.12 542.836 281.914 541.821 282.869C539.768 284.8 537.28 287.676 534.725 291.018C532.205 294.314 530.812 296.388 530.134 297.905C529.78 298.698 529.586 299.41 529.553 300.12C529.521 300.826 529.656 301.411 529.781 301.88Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M536.556 293.602L536.589 293.66L536.625 293.715C537.616 295.214 538.54 296.352 539.469 297.048C540.475 297.803 541.673 298.158 542.921 297.662C543.95 297.254 544.702 296.364 545.261 295.6C545.706 294.992 546.176 294.245 546.656 293.483C546.796 293.262 546.936 293.04 547.076 292.819C548.703 290.663 549.461 288.125 549.639 285.982C549.728 284.898 549.675 283.857 549.475 282.967C549.294 282.156 548.922 281.155 548.111 280.535C547.227 279.859 546.199 279.866 545.384 280.083C544.59 280.295 543.813 280.743 543.102 281.266C541.665 282.324 540.105 283.981 538.626 285.915C537.206 287.772 536.336 289.147 536.022 290.343C535.848 291.008 535.84 291.63 535.984 292.234C536.118 292.792 536.367 293.26 536.556 293.602Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M200.423 368.258L201.63 368.058L202.193 369.145L213.612 391.221C214.277 392.505 213.855 394.085 212.639 394.867L154.63 432.205L154.54 432.264L154.443 432.31L150.26 434.302L150.046 434.404L149.812 434.443L145.9 435.093L145.735 435.12L145.569 435.115L143.547 435.048L143.443 435.045L143.339 435.029L140.862 434.636L140.679 434.607L140.508 434.539L138.356 433.691L137.235 433.248L137.293 432.044L138.49 407.229L138.538 406.235L139.433 405.798L153.072 399.15L175.243 383.479L187.352 374.246L187.423 374.192L187.5 374.145L194.805 369.716L194.977 369.611L195.169 369.55L197.843 368.704L197.957 368.668L198.075 368.648L200.423 368.258Z"
                    fill="#02A608"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M208.201 388.29L200.922 369.498L197.496 370.519L194.843 371.397L187.549 375.843L175.832 385.172L153.441 400.816L139.963 407.944L141.111 429.689L145.718 428.31L147.996 424.097L208.201 388.29Z"
                    fill="#8CEC40"
                />
                <path
                    d="M207.483 389.562L203.327 389.688L200.64 389.068C200.499 389.036 200.366 388.976 200.247 388.893L198.185 387.446L196.721 385.736L195.705 384.341L194.689 382.945L194.002 381.098L193.645 378.799C193.535 377.998 192.632 377.58 191.951 378.016L190.151 379.169L177.844 388.162L155.416 403.75L142.066 411.068L141.728 430.608L146.222 429.059L150.372 426.761L153.228 420.943L160.337 417.752L173.512 409.935L207.483 389.562Z"
                    fill="#63D116"
                />
                <circle
                    cx="212.471"
                    cy="377.397"
                    r="14.3917"
                    transform="rotate(-147.056 212.471 377.397)"
                    fill="url(#paint5_linear_5819_14461)"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M216.266 371.54C210.446 367.769 202.681 367.987 199.266 373.257C195.852 378.526 198.825 385.703 204.644 389.474C210.463 393.245 218.229 393.027 221.644 387.758C225.058 382.488 222.085 375.311 216.266 371.54Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M199.967 375.764C204.436 381.557 202.892 380.557 208.621 384.27C215.182 386.635 213.044 386.424 220.645 385.994C219.165 390.795 211.243 392.09 205.795 388.215C200.066 384.502 198.555 378.66 199.967 375.764Z"
                    fill="#A8A7A8"
                />
                <path
                    d="M219.887 379.6C219.15 370.392 226.395 362.509 235.618 362.454L275.227 366.008C278.047 366.262 280.885 366.26 283.704 366.004L322.751 362.454C332.458 362.511 340.005 370.942 338.974 380.608L336.691 402.006L332.392 433.531C331.115 442.895 323.514 450.121 314.098 450.922L283.002 453.569C280.645 453.769 278.276 453.773 275.919 453.581L243.276 450.916C233.577 450.124 225.822 442.527 224.832 432.846L221.684 402.073L219.887 379.6Z"
                    fill="#65D018"
                    stroke="black"
                    stroke-width="4.48708"
                />
                <path
                    d="M223.937 407.885L225.514 430.283C226.223 440.342 234.091 448.419 244.129 449.391L263.199 450.512C263.392 450.512 263.48 450.273 263.334 450.147L248.252 437.22C245.587 434.935 243.8 431.794 243.198 428.336L239.642 407.885L236.837 365.258C236.837 364.948 236.586 364.697 236.276 364.697H234.274C234.114 364.697 233.954 364.712 233.796 364.742L232.632 364.963C231.597 365.159 230.59 365.477 229.63 365.91C224.527 368.213 221.406 373.459 221.816 379.043L223.937 407.885Z"
                    fill="#00A708"
                />
                <path
                    d="M324.353 364.72L323.292 364.803C319.991 365.062 319.887 369.861 323.173 370.263C323.4 370.291 323.617 370.376 323.804 370.509L326.482 372.422C327.123 372.88 327.726 373.39 328.283 373.948L328.393 374.057C329.623 375.288 330.647 376.708 331.425 378.264L331.479 378.373C332.153 379.72 332.643 381.15 332.938 382.626L333.268 384.276C333.499 385.43 333.639 386.602 333.686 387.779L334.176 400.033L334.737 394.424L336.194 384.224C336.345 383.173 336.42 382.113 336.42 381.051V378.719L336.137 376.458C335.953 374.985 335.52 373.554 334.856 372.226C334.404 371.323 333.85 370.475 333.203 369.699L333.17 369.659C331.984 368.236 330.494 367.063 328.838 366.234C328.289 365.96 327.715 365.721 327.133 365.528L324.915 364.788C324.735 364.728 324.543 364.705 324.353 364.72Z"
                    fill="#8CEC40"
                />
                <path
                    d="M279.249 393.897C279.762 391.69 279.692 393.579 280.317 385.847C277.947 382.708 269.936 376.378 268.532 377.642C267.472 379.254 269.532 381.39 271.164 383.783C274.901 389.261 277.175 393.798 279.249 393.897Z"
                    fill="black"
                />
                <path d="M279.761 407.332L262.731 407.335L279.688 390.061L279.761 407.332Z" fill="black" />
                <path
                    d="M265.256 407.396C263.841 408.231 262.734 407.611 257.518 407.312C254.731 404.844 249.24 397.071 250.481 395.859C252.033 394.992 253.933 397.076 256.111 398.756C260.927 402.469 265.217 405.464 265.256 407.396Z"
                    fill="black"
                />
                <path
                    d="M259.414 401.305L273.648 386.628L277.942 391.869L264.397 405.647L259.414 401.305Z"
                    fill="black"
                />
                <path
                    d="M253.453 396.751L269.377 380.619L273.673 386.689L259.405 401.297L253.453 396.751Z"
                    fill="black"
                />
                <path
                    d="M250.5 395.844L268.562 377.603L268.568 378.643L268.71 379.301L269.027 380.064L269.376 380.619L253.521 396.784L252.826 396.471L251.541 396.019L250.5 395.844Z"
                    fill="black"
                />
                <path
                    d="M280.402 420.442C279.859 422.642 280.403 420.696 279.262 428.487C281.631 431.609 289.513 438.096 290.934 436.851C292.016 435.254 289.985 433.09 288.385 430.675C284.723 425.147 282.475 420.569 280.402 420.442Z"
                    fill="black"
                />
                <path d="M279.762 407.331L297.033 407.293L280.076 424.325L279.762 407.331Z" fill="black" />
                <path
                    d="M294.543 407.298C296.633 407.011 294.923 407.159 302.108 407.108C304.861 409.614 310.487 417.685 309.23 418.88C307.666 419.726 305.827 417.808 303.682 416.086C298.77 412.144 294.522 409.233 294.543 407.298Z"
                    fill="black"
                />
                <rect
                    x="281.52"
                    y="422.635"
                    width="2.48724"
                    height="2.48724"
                    transform="rotate(134.212 281.52 422.635)"
                    fill="black"
                />
                <path
                    d="M300.483 413.496L286.018 427.875L281.762 422.594L295.307 408.989L300.483 413.496Z"
                    fill="black"
                />
                <path
                    d="M306.27 417.948L290.164 433.898L285.915 427.735L300.318 413.345L306.27 417.948Z"
                    fill="black"
                />
                <path
                    d="M309.23 418.88L290.924 436.877L290.936 435.841L290.805 435.183L290.503 434.42L290.164 433.863L306.207 417.911L306.923 418.232L308.196 418.696L309.23 418.88Z"
                    fill="black"
                />
                <path
                    d="M266.576 407.431C264.289 407.261 264.358 407.606 257.518 407.275C254.426 409.685 249.061 417.182 250.324 418.587C251.935 419.648 254.247 417.581 256.641 415.951C262.122 412.218 266.475 409.505 266.576 407.431Z"
                    fill="black"
                />
                <path
                    d="M264.637 408.988L278.103 422.257L277.182 423.103L264.464 409.195L264.637 408.988Z"
                    fill="black"
                />
                <path d="M279.797 407.325L280.043 424.43L262.839 407.368L279.797 407.325Z" fill="black" />
                <path
                    d="M279.725 421.802C280.038 423.888 280.106 422.216 280.115 429.511C277.645 432.296 269.767 437.917 268.556 436.675C267.69 435.122 269.657 433.072 271.351 430.905C275.23 425.944 278.034 422.186 279.725 421.802Z"
                    fill="black"
                />
                <path
                    d="M273.828 427.826L259.265 413.546L264.562 409.067L278.31 422.635L273.828 427.826Z"
                    fill="black"
                />
                <path
                    d="M269.449 433.704L253.296 417.802L259.404 413.476L273.976 427.694L269.449 433.704Z"
                    fill="black"
                />
                <path
                    d="M268.556 436.675L250.326 418.6L251.363 418.599L252.019 418.46L252.778 418.148L253.33 417.802L269.492 433.672L269.174 434.36L268.727 435.638L268.556 436.675Z"
                    fill="black"
                />
                <rect
                    x="281.792"
                    y="422.604"
                    width="2.48724"
                    height="2.48724"
                    transform="rotate(134.212 281.792 422.604)"
                    fill="black"
                />
                <path
                    d="M293.61 407.129C296.224 407.123 294.581 407.381 302.108 407.11C305.198 404.698 310.684 397.191 309.51 395.902C307.984 394.953 305.873 396.853 303.489 398.496C298.03 402.259 293.784 405.15 293.61 407.129Z"
                    fill="#000400"
                />
                <path d="M279.761 407.33L279.688 389.956L297.032 407.292L279.761 407.33Z" fill="#000400" />
                <path
                    d="M279.956 392.526C279.689 390.442 279.621 392.241 279.608 384.985C282.076 382.198 289.931 376.532 291.143 377.773C292.01 379.325 289.86 381.175 288.246 383.403C285.209 387.598 282.076 392.268 279.956 392.526Z"
                    fill="#000400"
                />
                <path
                    d="M285.878 386.592L300.452 400.861L295.267 405.537L281.454 391.785L285.878 386.592Z"
                    fill="#000400"
                />
                <path
                    d="M290.237 380.747L306.417 396.634L300.313 400.965L285.729 386.759L290.237 380.747Z"
                    fill="#000400"
                />
                <path
                    d="M291.143 377.772L309.386 395.833L308.35 395.835L307.694 395.974L306.935 396.287L306.383 396.634L290.203 380.782L290.526 380.088L290.972 378.809L291.143 377.772Z"
                    fill="#000400"
                />
                <rect
                    x="297.094"
                    y="407.235"
                    width="2.48724"
                    height="2.48724"
                    transform="rotate(134.212 297.094 407.235)"
                    fill="#000400"
                />
                <path
                    d="M262.692 407.444L264.402 405.638L266.208 407.348L264.498 409.154L262.692 407.444Z"
                    fill="#000400"
                />
                <rect
                    x="281.448"
                    y="391.795"
                    width="2.48724"
                    height="2.48724"
                    transform="rotate(134.212 281.448 391.795)"
                    fill="#000400"
                />
                <path
                    d="M279.269 395.249C279.728 393.274 279.664 394.965 280.223 388.047C278.103 385.24 270.937 379.576 269.681 380.707C268.733 382.149 270.575 384.06 272.035 386.2C275.378 391.101 277.413 395.161 279.269 395.249Z"
                    fill="#0069A6"
                />
                <path d="M279.727 407.268L264.492 407.271L279.662 391.817L279.727 407.268Z" fill="#00BAF2" />
                <path
                    d="M266.749 407.326C265.484 408.072 264.494 407.518 259.827 407.25C257.334 405.042 252.422 398.089 253.532 397.005C254.92 396.229 256.62 398.093 258.569 399.596C262.877 402.917 266.715 405.597 266.749 407.326Z"
                    fill="#0069A6"
                />
                <path
                    d="M261.523 401.877L274.257 388.746L278.099 393.435L265.981 405.761L261.523 401.877Z"
                    fill="#00AEEF"
                />
                <path
                    d="M256.191 397.802L270.436 383.371L274.28 388.801L261.515 401.87L256.191 397.802Z"
                    fill="#0095DA"
                />
                <path
                    d="M253.549 396.991L269.707 380.672L269.713 381.602L269.84 382.192L270.123 382.874L270.436 383.371L256.252 397.832L255.63 397.552L254.48 397.148L253.549 396.991Z"
                    fill="#00AEEF"
                />
                <path
                    d="M280.301 418.997C279.815 420.966 280.301 419.225 279.28 426.195C281.4 428.988 288.451 434.791 289.722 433.677C290.69 432.248 288.874 430.313 287.443 428.152C284.166 423.206 282.155 419.111 280.301 418.997Z"
                    fill="#0069A6"
                />
                <path d="M279.727 407.267L295.178 407.233L280.008 422.471L279.727 407.267Z" fill="#00BAF2" />
                <path
                    d="M292.952 407.238C294.821 406.982 293.292 407.114 299.719 407.069C302.182 409.31 307.216 416.531 306.091 417.6C304.692 418.356 303.046 416.64 301.127 415.1C296.733 411.574 292.933 408.969 292.952 407.238Z"
                    fill="#0069A6"
                />
                <rect
                    x="281.299"
                    y="420.959"
                    width="2.22493"
                    height="2.22493"
                    transform="rotate(134.212 281.299 420.959)"
                    fill="#F5F5F5"
                />
                <path
                    d="M298.265 412.783L285.325 425.646L281.517 420.922L293.635 408.751L298.265 412.783Z"
                    fill="#00AEEF"
                />
                <path
                    d="M303.443 416.766L289.034 431.034L285.234 425.521L298.119 412.648L303.443 416.766Z"
                    fill="#0095DA"
                />
                <path
                    d="M306.091 417.6L289.714 433.7L289.725 432.773L289.608 432.185L289.338 431.502L289.034 431.004L303.387 416.733L304.027 417.02L305.166 417.435L306.091 417.6Z"
                    fill="#00AEEF"
                />
                <path
                    d="M267.93 407.358C265.884 407.205 265.946 407.514 259.827 407.219C257.061 409.374 252.261 416.081 253.391 417.338C254.832 418.287 256.9 416.438 259.042 414.979C263.945 411.64 267.84 409.213 267.93 407.358Z"
                    fill="#0F9647"
                />
                <path
                    d="M266.195 408.75L278.242 420.621L277.419 421.377L266.041 408.936L266.195 408.75Z"
                    fill="#DFE9A6"
                />
                <path d="M279.758 407.262L279.978 422.564L264.588 407.3L279.758 407.262Z" fill="#9EDD00" />
                <path
                    d="M279.694 420.215C279.974 422.081 280.035 420.585 280.043 427.111C277.833 429.603 270.785 434.631 269.702 433.52C268.927 432.131 270.687 430.297 272.203 428.359C275.673 423.92 278.181 420.558 279.694 420.215Z"
                    fill="#0B9444"
                />
                <path
                    d="M274.418 425.603L261.39 412.828L266.129 408.822L278.428 420.96L274.418 425.603Z"
                    fill="#8CC63F"
                />
                <path
                    d="M270.501 430.862L256.05 416.637L261.514 412.766L274.551 425.486L270.501 430.862Z"
                    fill="#39B54A"
                />
                <path
                    d="M269.702 433.52L253.394 417.351L254.321 417.349L254.908 417.225L255.587 416.946L256.081 416.636L270.54 430.834L270.255 431.449L269.855 432.593L269.702 433.52Z"
                    fill="#9EDD00"
                />
                <rect
                    x="281.543"
                    y="420.932"
                    width="2.22493"
                    height="2.22493"
                    transform="rotate(134.212 281.543 420.932)"
                    fill="#F5F5F5"
                />
                <path
                    d="M292.117 407.087C294.455 407.082 292.985 407.313 299.719 407.07C302.483 404.912 307.391 398.196 306.341 397.043C304.976 396.194 303.087 397.894 300.954 399.364C296.071 402.73 292.272 405.317 292.117 407.087Z"
                    fill="#0B9444"
                />
                <path d="M279.726 407.268L279.661 391.724L295.177 407.234L279.726 407.268Z" fill="#9EDD01" />
                <path
                    d="M279.901 394.022C279.662 392.158 279.602 393.767 279.59 387.276C281.798 384.782 288.825 379.713 289.909 380.824C290.685 382.212 288.761 383.868 287.318 385.861C284.601 389.613 281.798 393.791 279.901 394.022Z"
                    fill="#0B9444"
                />
                <path
                    d="M285.199 388.714L298.236 401.479L293.598 405.662L281.241 393.36L285.199 388.714Z"
                    fill="#8CC63F"
                />
                <path
                    d="M289.099 383.485L303.574 397.697L298.113 401.572L285.066 388.862L289.099 383.485Z"
                    fill="#39B54A"
                />
                <path
                    d="M289.909 380.823L306.23 396.98L305.303 396.982L304.716 397.107L304.037 397.387L303.543 397.697L289.069 383.516L289.358 382.895L289.757 381.751L289.909 380.823Z"
                    fill="#9EDD01"
                />
                <rect
                    x="295.233"
                    y="407.182"
                    width="2.22493"
                    height="2.22493"
                    transform="rotate(134.212 295.233 407.182)"
                    fill="#F5F5F5"
                />
                <path
                    d="M264.456 407.369L265.986 405.753L267.601 407.283L266.071 408.899L264.456 407.369Z"
                    fill="#F5F5F5"
                />
                <rect
                    x="281.236"
                    y="393.369"
                    width="2.22493"
                    height="2.22493"
                    transform="rotate(134.212 281.236 393.369)"
                    fill="#F5F5F5"
                />
                <path
                    d="M275.418 352.854C280.789 350.337 281.067 342.801 275.896 339.895L243.352 321.605C238.092 318.649 231.723 322.996 232.559 328.972L235.153 347.508C235.208 347.904 235.203 348.307 235.136 348.702L233.24 359.993C232.267 365.79 238.203 370.298 243.526 367.802L275.418 352.854Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M271.784 352.373C276.665 350.086 277.012 343.277 272.39 340.505L244.481 323.773C239.417 320.737 233.178 325.227 234.447 330.992L237.273 343.832C237.379 344.316 237.405 344.813 237.349 345.305L236.473 352.984L235.55 358.575C234.316 363.451 239.308 367.588 243.878 365.447L271.784 352.373Z"
                    fill="#A2DC05"
                    stroke="#02AA09"
                    stroke-width="2.24354"
                />
                <path
                    d="M322.698 345.297L325.339 347.258L321.78 355.068C321.572 355.522 321.454 356.012 321.43 356.511L321.014 365.304C320.759 370.707 314.917 373.96 310.189 371.333L298.99 365.109C298.438 364.802 297.929 364.425 297.474 363.986L286.986 353.882C286.504 353.418 286.088 352.889 285.75 352.312L283.387 348.27C280.982 344.158 283.103 338.882 287.684 337.577L294.468 335.645L309.687 330.403L319.651 326.476C326.278 323.864 332.334 331.329 328.411 337.275L326.411 340.305C326.185 340.649 325.929 340.973 325.648 341.273L320.189 347.103L320.054 347.619L322.698 345.297ZM320.071 347.23C320.071 347.229 320.072 347.229 320.072 347.229L320.071 347.23ZM319.165 351.013L319.253 351.059L319.165 351.013Z"
                    fill="url(#paint6_linear_5819_14461)"
                    stroke="#0482B2"
                    stroke-width="3.36531"
                />
                <path
                    d="M307.909 372.326L308.701 370.841L307.909 372.326L298.375 367.24C297.885 366.978 297.426 366.662 297.008 366.296L280.157 351.568C275.979 347.915 277.25 341.12 282.466 339.225L321.512 325.035C327.038 323.026 332.547 328.048 331.056 333.736L327.949 345.594C327.763 346.302 327.473 346.977 327.087 347.598L323.669 353.092C323.308 353.671 323.105 354.335 323.079 355.018L322.621 367.213C322.445 371.874 317.998 375.174 313.486 373.992L309.493 372.946C308.943 372.801 308.411 372.593 307.909 372.326Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path d="M293.848 358.527L286.417 353.138L286.24 347.208L293.848 358.527Z" fill="#0086B8" />
                <path d="M254.949 357.827L270.206 345.224L272.719 349.118L254.949 357.827Z" fill="#159C00" />
                <path d="M258.745 353.949L269.507 345.964L270.455 347.434L258.745 353.949Z" fill="black" />
                <path
                    d="M298.142 355.171L292.379 349.593L285.737 346.95L284.685 348.817L289.824 349.784L298.142 355.171Z"
                    fill="#0086B8"
                />
                <path
                    d="M297.157 353.921L291.88 349.046L285.937 346.594L285.079 348.118L289.638 349.113L297.157 353.921Z"
                    fill="black"
                />
                <path
                    d="M270.292 343.87L271.276 345.335L271.794 343.647C271.993 342.998 271.861 342.379 271.434 341.856C271.057 341.396 270.473 341.034 269.8 340.714C268.443 340.067 266.286 339.392 263.354 338.492C260.56 337.634 257.61 335.941 255.196 334.556C255.147 334.527 255.098 334.499 255.049 334.471C253.857 333.788 252.769 333.167 251.948 332.818C251.551 332.65 251.118 332.5 250.723 332.485C250.516 332.478 250.249 332.503 249.992 332.647C249.711 332.805 249.531 333.056 249.446 333.334C249.304 333.798 249.424 334.259 249.575 334.609C249.733 334.978 249.982 335.357 250.28 335.73C250.878 336.479 251.761 337.312 252.821 338.134C254.942 339.779 257.883 341.469 260.955 342.412L261.042 342.438L261.132 342.446C263.502 342.644 265.218 342.725 266.479 342.767C266.959 342.783 267.363 342.793 267.708 342.802C268.291 342.817 268.706 342.828 269.033 342.854C269.531 342.895 269.613 342.962 269.656 342.998L269.657 342.998C269.7 343.033 269.762 343.099 269.871 343.249C269.952 343.36 270.036 343.487 270.146 343.651C270.19 343.718 270.238 343.79 270.292 343.87Z"
                    fill="#1F920C"
                    stroke="#07A506"
                    stroke-width="1.68266"
                />
                <path
                    d="M270.03 344.53L270.987 346.274L271.571 344.372C271.775 343.707 271.709 343.051 271.306 342.462C270.943 341.93 270.353 341.525 269.672 341.176C268.308 340.478 266.127 339.793 263.185 338.89C260.395 338.033 257.485 336.196 255.09 334.683C255.043 334.654 254.997 334.625 254.951 334.596C253.774 333.853 252.688 333.171 251.858 332.799C251.458 332.62 251.001 332.456 250.571 332.461C250.338 332.463 250.061 332.515 249.808 332.689C249.546 332.869 249.39 333.123 249.311 333.382C249.171 333.838 249.244 334.309 249.377 334.713C249.513 335.127 249.742 335.557 250.027 335.984C250.596 336.84 251.454 337.781 252.496 338.702C254.579 340.542 257.505 342.393 260.615 343.348L260.718 343.38L260.826 343.384C263.241 343.488 264.992 343.488 266.281 343.467C266.774 343.459 267.188 343.449 267.541 343.44C268.132 343.426 268.55 343.415 268.881 343.428C269.351 343.447 269.412 343.512 269.429 343.531C269.43 343.532 269.431 343.533 269.432 343.534C269.469 343.568 269.529 343.641 269.633 343.816C269.71 343.944 269.79 344.091 269.893 344.281C269.934 344.356 269.979 344.439 270.03 344.53Z"
                    fill="#1F920C"
                    stroke="black"
                    stroke-width="1.68266"
                />
                <path
                    d="M282.635 343.489C282.655 343.485 282.729 343.465 282.814 343.392C282.923 343.298 282.974 343.184 282.994 343.096L282.245 343.376L281.456 343.671C281.38 343.465 281.312 343.217 281.323 342.955C281.334 342.664 281.445 342.35 281.717 342.116C281.961 341.907 282.243 341.841 282.447 341.817C282.65 341.794 282.86 341.805 283.057 341.827C283.762 341.647 284.728 341.496 285.79 341.331L285.801 341.329L285.822 341.326C288.17 340.96 291.182 340.49 293.923 339.466C296.762 338.405 299.588 337.388 301.769 336.79C302.851 336.492 303.826 336.283 304.582 336.237C304.956 336.214 305.341 336.226 305.68 336.325C306.029 336.426 306.445 336.659 306.629 337.15C306.881 337.825 306.608 338.56 306.282 339.126C305.925 339.744 305.358 340.414 304.622 341.084C303.146 342.43 300.895 343.862 297.965 344.957L297.893 344.984L297.817 344.998C293.107 345.833 288.875 344.97 286.001 344.248C285.622 344.153 285.223 344.049 284.86 343.954C284.582 343.881 284.326 343.814 284.116 343.761C283.733 343.664 283.42 343.591 283.169 343.545C283.11 343.564 283.059 343.583 283.016 343.601C282.933 343.635 282.901 343.657 282.9 343.657L282.9 343.657C282.902 343.656 282.919 343.643 282.942 343.616C282.961 343.594 282.985 343.561 283.006 343.517C282.964 343.511 282.923 343.505 282.885 343.501C282.742 343.484 282.667 343.486 282.638 343.489C282.637 343.489 282.636 343.489 282.635 343.489ZM282.635 343.489C282.632 343.49 282.63 343.49 282.63 343.49C282.63 343.49 282.631 343.49 282.635 343.489Z"
                    fill="#0085B7"
                    stroke="black"
                    stroke-width="1.68266"
                />
                <path
                    d="M271.91 339.91C272.115 338.971 272.895 338.268 273.849 338.159L277.899 337.699C278.018 337.686 278.137 337.682 278.256 337.688L282.527 337.885C283.619 337.935 284.516 338.765 284.651 339.85L285.644 347.862C285.664 348.018 285.667 348.176 285.653 348.333L285.123 354.424C285.036 355.431 284.286 356.255 283.292 356.437L279.156 357.194C278.864 357.248 278.564 357.243 278.273 357.179L273.96 356.237C273.211 356.073 272.597 355.538 272.333 354.818L270.252 349.147C270.105 348.746 270.075 348.312 270.166 347.895L271.91 339.91Z"
                    fill="url(#paint7_linear_5819_14461)"
                    stroke="#35A716"
                    stroke-width="2.24354"
                />
                <path
                    d="M270.198 339.855C270.222 338.66 271.179 337.694 272.374 337.658L277.544 337.503L282.538 337.71C283.628 337.755 284.528 338.579 284.67 339.662L285.711 347.655C285.733 347.821 285.736 347.99 285.72 348.157L285.128 354.403C285.038 355.353 284.357 356.143 283.43 356.37L278.63 357.55C278.333 357.623 278.023 357.635 277.721 357.584L277.534 358.69L277.721 357.584L272.509 356.703C271.504 356.533 270.739 355.705 270.649 354.689L270.038 347.823L270.198 339.855Z"
                    stroke="black"
                    stroke-width="2.24354"
                />
                <path
                    d="M235.891 334.449L235.807 331.646L241.783 343.812L242.102 354.464L239.742 350.607L236.495 354.632L237.909 345.612L235.891 334.449Z"
                    fill="url(#paint8_linear_5819_14461)"
                />
                <path
                    d="M271.663 348.054L283.105 338.982L283.747 339.76L284.439 343.948L284.565 348.143L284.193 354.524L283.585 355.099L271.663 348.054Z"
                    fill="#8CE2D5"
                />
                <path
                    d="M271.633 348.079L283.744 340.873L283.849 341.798L284.486 344.878L284.556 347.971L284.097 352.683L284.13 353.767L271.633 348.079Z"
                    fill="url(#paint9_linear_5819_14461)"
                />
                <path
                    d="M279.755 347.423L284.166 353.766L271.647 347.969L279.755 347.423Z"
                    fill="url(#paint10_linear_5819_14461)"
                />
                <path
                    d="M280.057 347.717L283.806 340.871L283.944 341.897L284.424 343.658L284.575 348.704L284.413 353.378L284.194 353.812L280.057 347.717Z"
                    fill="url(#paint11_linear_5819_14461)"
                />
                <path
                    d="M320.652 343.872L320.656 343.865L320.659 343.858L323.344 338.781C323.475 338.532 323.568 338.265 323.62 337.989L324.243 334.676C324.361 334.049 324.262 333.401 323.962 332.839L323.237 331.478C322.876 330.801 323.019 329.965 323.586 329.446L323.207 329.032L323.586 329.446L323.757 329.289C323.989 329.077 324.35 329.097 324.558 329.333L324.978 328.965L324.558 329.333L325.33 330.216L325.752 329.847L325.33 330.216C325.549 330.466 325.689 330.776 325.733 331.106L326.231 334.907C326.253 335.07 326.25 335.235 326.224 335.398L325.976 336.91C325.938 337.144 325.851 337.366 325.721 337.564L323.761 340.543L323.742 340.572L323.727 340.603L322.075 343.954L317.844 350.796C317.67 351.077 317.547 351.387 317.481 351.711L316.927 354.427L317.41 349.896C317.434 349.667 317.505 349.447 317.618 349.247L320.652 343.872Z"
                    fill="black"
                    stroke="black"
                    stroke-width="1.12177"
                />
                <path
                    d="M116.639 418.502L116.647 418.52L116.656 418.538L118.389 422.066C118.472 422.236 118.573 422.398 118.689 422.549L120.477 424.883C120.597 425.039 120.732 425.183 120.882 425.314L122.017 424.138L120.882 425.314L125.248 429.14L126.384 427.964L125.248 429.14C125.425 429.295 125.621 429.43 125.83 429.542L129.932 431.731L131.286 432.454L132.144 431.306L147.5 410.76C148.141 409.902 148.169 408.741 147.573 407.795L135.009 387.887L134.992 387.861L134.975 387.835L115.953 359.949L107.02 343.15C106.881 342.89 106.701 342.649 106.487 342.438L104.114 340.099L104.047 340.033L103.973 339.974L101.09 337.668C100.061 336.845 98.6099 336.759 97.5636 337.46L83.5264 346.862C82.4126 347.608 82.0673 349.034 82.7079 350.242L101.26 385.244L116.639 418.502Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M99.7102 338.563L87.0644 346.823C86.5968 347.128 86.4614 347.729 86.749 348.222L107.033 383.015L124.593 417.056L132.344 424.305L131.724 428.993L145.573 407.824L133.951 388.519L115.078 360.353L110.547 351.833L106.016 343.312L101.189 338.722C100.784 338.336 100.16 338.269 99.7102 338.563Z"
                    fill="#8CEC40"
                />
                <path
                    d="M84.8289 347.485L83.8856 348.251C83.4978 348.565 83.3966 349.1 83.6396 349.551L102.517 384.641L117.922 418.405L120.889 423.356L127.455 429.316L126.923 412.273L118.688 400.738L103.995 373.468L91.5522 354.283L89.1397 351.135L86.5215 347.719C86.1139 347.187 85.3301 347.079 84.8289 347.485Z"
                    fill="#02A608"
                />
                <path
                    d="M134.11 401.158L136.443 400.908C137.232 400.824 137.651 400.015 137.258 399.336L131.596 389.558L113.014 361.259L105.66 346.878C105.212 346.001 103.903 346.002 103.551 346.878L102.775 348.809L101.506 350.306L99.7595 351.171L97.4735 351.957L94.1711 352.031L86.9937 350.571L106.348 384.709L123.952 415.84L123.955 413.135L124.937 409.35L126.27 407.301L128.206 404.779L131.221 402.416L134.11 401.158Z"
                    fill="#63D116"
                />
                <circle
                    cx="12.6569"
                    cy="12.6569"
                    r="14.3395"
                    transform="matrix(-0.991366 -0.131122 -0.131122 0.991366 152.648 410.312)"
                    fill="url(#paint12_linear_5819_14461)"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M135.599 427.672C129.377 425.249 125.373 418.906 127.618 413.138C129.863 407.371 137.102 405.404 143.324 407.826C149.546 410.249 153.55 416.592 151.305 422.36C149.059 428.127 141.821 430.094 135.599 427.672Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <circle
                    cx="8.36221"
                    cy="8.36221"
                    r="10.0449"
                    transform="matrix(0.755044 0.655674 0.655674 -0.755044 80.7498 340.513)"
                    fill="url(#paint13_linear_5819_14461)"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M95.4902 337.842C96.825 339.728 97.5494 341.867 97.5712 343.853C97.5929 345.831 96.9003 347.831 95.186 349.045C93.4718 350.258 91.3547 350.246 89.4969 349.568C87.6313 348.888 85.8544 347.494 84.5196 345.608C83.1849 343.722 82.4604 341.583 82.4386 339.597C82.417 337.62 83.1096 335.619 84.8238 334.406C86.5381 333.192 88.6551 333.204 90.513 333.882C92.3785 334.562 94.1555 335.957 95.4902 337.842Z"
                    fill="#D9D9D9"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M86.9908 314.549C86.1133 314.201 85.2154 313.487 84.2955 312.545C83.8188 312.455 83.3827 312.265 83.0035 312.042C82.3763 311.674 81.7896 311.149 81.2461 310.557C80.1583 309.372 79.0386 307.69 77.9247 305.708C75.6926 301.736 73.3184 296.258 71.1403 290.292C66.9946 280.889 65.3354 275.202 65.1539 271.651C65.0613 269.841 65.3408 268.398 66.0494 267.285C66.7757 266.145 67.7987 265.594 68.6723 265.276C69.8968 264.829 71.1686 265.009 72.3757 265.697C73.5209 266.35 74.6402 267.471 75.7804 269.005C78.0633 272.075 80.7729 277.275 84.117 285.381C87.0421 291.586 89.8112 300.226 90.7933 306.229C91.0404 307.739 91.1836 309.136 91.1749 310.314C91.1668 311.407 91.0324 312.63 90.4502 313.534C90.122 314.043 89.6122 314.509 88.8912 314.703C88.1982 314.889 87.5343 314.764 86.9908 314.549Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M78.8177 286.54C80.2246 298.017 80.4194 308.327 88.3416 309.715C94.3546 319.799 87.8535 298.4 82.729 286.006C76.4136 269.666 71.7561 265.611 70.4976 266.07C71.3206 267.641 71.7246 268.03 78.8177 286.54Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M80.389 285.717C85.4227 299.896 81.9158 303.538 88.3361 309.721C94.3491 319.804 87.848 298.406 82.7235 286.012C74.9419 270.349 74.1085 265.362 72.4005 265.711C68.5225 266.504 71.6945 266.444 80.389 285.717Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M77.6534 290.893C76.1455 291.327 74.7394 291.189 73.5018 290.486C72.3339 289.823 71.4764 288.747 70.8052 287.61C69.7661 285.849 68.9183 283.462 68.0846 281.114C67.8688 280.506 67.654 279.901 67.4371 279.31C66.2777 276.153 65.6623 273.058 65.6487 270.572C65.642 269.337 65.783 268.158 66.1483 267.171C66.5107 266.192 67.185 265.185 68.369 264.75C69.5529 264.316 70.7188 264.647 71.6285 265.159C72.5455 265.674 73.4164 266.482 74.2107 267.428C75.8088 269.332 77.3431 272.09 78.5025 275.247C79.0071 276.621 79.7368 277.967 80.4954 279.345C80.5287 279.406 80.5623 279.467 80.5959 279.528C81.2926 280.793 82.0388 282.148 82.4688 283.419C82.9242 284.767 83.1543 286.39 82.2725 287.904C81.4232 289.361 79.7906 290.277 77.6534 290.893Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M73.7235 280.105C72.4804 280.487 71.327 280.609 70.2701 280.377C69.1749 280.136 68.3471 279.551 67.7249 278.817C67.1322 278.117 66.7277 277.286 66.4122 276.515C66.2027 276.004 65.9976 275.426 65.8041 274.881C65.7143 274.628 65.627 274.382 65.5429 274.153C64.8543 272.278 64.7213 270.321 65.1448 268.641C65.5658 266.972 66.602 265.399 68.3687 264.751C70.1354 264.102 71.943 264.63 73.3446 265.63C74.7545 266.637 75.9197 268.215 76.6083 270.09C76.7033 270.348 76.8295 270.671 76.9628 271.013C77.157 271.51 77.3665 272.047 77.5168 272.479C77.7998 273.294 78.0686 274.242 78.0766 275.207C78.0851 276.232 77.7988 277.288 76.99 278.205C76.2205 279.077 75.1038 279.68 73.7235 280.105Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M37.095 283.429C32.4096 279.027 29.5102 274.422 27.9893 270.627C27.2314 268.736 26.7984 267.003 26.6798 265.565C26.6206 264.848 26.6357 264.158 26.7541 263.537C26.8683 262.937 27.1062 262.261 27.6089 261.726C28.2056 261.091 28.9877 260.864 29.7354 260.917C30.4035 260.965 31.0289 261.228 31.5554 261.518C32.6162 262.102 33.8322 263.112 35.1374 264.331C37.1776 266.238 39.7186 268.932 42.6453 272.034C43.4874 272.927 44.3613 273.854 45.2644 274.805C47.4933 276.898 49.7222 278.896 51.7999 280.753C51.9408 280.879 52.081 281.004 52.2205 281.129C54.142 282.845 55.9314 284.444 57.4317 285.86C59.0232 287.362 60.3758 288.737 61.2303 289.886C61.6485 290.449 62.0427 291.081 62.2366 291.733C62.441 292.421 62.4883 293.428 61.7228 294.243C61.0822 294.925 60.1935 295.097 59.5682 295.141C58.8793 295.191 58.1009 295.112 57.2956 294.959C55.6734 294.649 53.6619 293.98 51.4727 293.01C47.085 291.065 41.7617 287.814 37.095 283.429Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M29.4264 262.246C27.9618 263.805 29.2174 273.718 38.2474 282.203C47.2774 290.687 55.5432 294.917 57.5476 294.649C59.0376 292.881 52.4453 284.659 43.5512 276.558C35.3189 267.976 30.9322 262.267 29.4264 262.246Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M28.5367 262.903C27.4736 264.034 29.0791 273.588 38.119 282.082C46.8601 290.614 55.5536 296.171 57.5476 294.649C59.7394 290.167 46.925 284.456 38.071 275.752C29.6386 267.462 29.4421 264.036 28.5367 262.903Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M48.0813 281.061L48.0664 281.151L48.0418 281.239C47.5132 283.134 46.8495 284.716 45.8189 285.641C45.2457 286.156 44.5426 286.484 43.7387 286.516C42.974 286.547 42.2824 286.305 41.7037 285.992C40.5941 285.392 39.482 284.298 38.3898 283.093C37.5464 282.163 36.613 281.048 35.6053 279.845C35.2832 279.461 34.9534 279.067 34.6166 278.667C31.6576 275.473 29.5226 271.914 28.2757 268.97C27.6526 267.499 27.2268 266.125 27.0484 264.986C26.9599 264.42 26.9212 263.846 26.9829 263.314C27.0398 262.825 27.2125 262.106 27.7954 261.565C28.3852 261.017 29.1043 260.898 29.6695 260.931C30.2139 260.962 30.7404 261.134 31.1996 261.334C32.125 261.737 33.1787 262.434 34.2801 263.287C36.5081 265.013 39.2593 267.638 42.1221 270.721C44.9448 273.761 46.5297 275.693 47.3498 277.138C47.7783 277.893 48.0393 278.584 48.1393 279.287C48.2389 279.987 48.1603 280.583 48.0813 281.061Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M40.5457 273.469L40.5193 273.529L40.4883 273.588C39.6447 275.175 38.8342 276.395 37.976 277.177C37.0464 278.024 35.8874 278.492 34.5978 278.118C33.5348 277.81 32.7012 276.996 32.0719 276.289C31.571 275.726 31.0313 275.028 30.4807 274.315C30.3209 274.108 30.1602 273.9 29.9992 273.694C28.1735 271.703 27.1767 269.249 26.7954 267.133C26.6025 266.062 26.5564 265.021 26.6697 264.116C26.7729 263.292 27.048 262.259 27.7956 261.565C28.6113 260.808 29.6353 260.716 30.4672 260.855C31.2777 260.989 32.0934 261.362 32.8511 261.814C34.3826 262.73 36.0944 264.23 37.7511 266.014C39.3419 267.727 40.3399 269.013 40.7659 270.173C41.0031 270.819 41.0707 271.437 40.9844 272.052C40.9049 272.62 40.7016 273.11 40.5457 273.469Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M28.5838 285.509C23.8984 281.106 20.999 276.501 19.4781 272.706C18.7202 270.815 18.2872 269.082 18.1686 267.645C18.1094 266.927 18.1245 266.237 18.2428 265.616C18.3571 265.017 18.595 264.34 19.0977 263.805C19.6944 263.17 20.4765 262.944 21.2241 262.997C21.8922 263.044 22.5177 263.307 23.0442 263.597C24.105 264.181 25.3209 265.191 26.6261 266.41C28.6663 268.317 31.2074 271.011 34.1341 274.114C34.9762 275.006 35.8502 275.933 36.7534 276.884C41.3055 281.165 45.1306 285.418 47.6342 288.859C48.881 290.573 49.8518 292.155 50.4042 293.481C50.6781 294.138 50.8894 294.826 50.9352 295.49C50.9808 296.151 50.8709 297.01 50.2275 297.695C49.5841 298.38 48.7332 298.543 48.0705 298.538C47.4052 298.534 46.7054 298.366 46.0324 298.134C44.6748 297.665 43.0355 296.794 41.2477 295.656C37.6485 293.366 33.1514 289.8 28.5838 285.509Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M20.9154 264.325C19.4508 265.884 20.7065 275.797 29.7364 284.282C38.7664 292.767 47.0322 296.997 49.0366 296.729C50.5266 294.96 43.9344 286.739 35.0403 278.637C26.808 270.056 22.4213 264.347 20.9154 264.325Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M20.0254 264.982C18.9624 266.114 20.5679 275.667 29.6078 284.161C38.3489 292.694 47.0424 298.25 49.0364 296.729C51.2282 292.246 38.4137 286.535 29.5597 277.831C21.1273 269.542 20.9309 266.115 20.0254 264.982Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M39.5703 283.141L39.5554 283.231L39.5309 283.319C39.0022 285.214 38.3385 286.795 37.308 287.721C36.7347 288.236 36.0316 288.563 35.2277 288.596C34.463 288.627 33.7714 288.384 33.1927 288.071C32.0831 287.471 30.971 286.377 29.8788 285.173C29.0354 284.243 28.102 283.128 27.0943 281.925C26.7722 281.54 26.4424 281.146 26.1056 280.747C23.1466 277.552 21.0117 273.993 19.7647 271.05C19.1416 269.579 18.7158 268.205 18.5374 267.065C18.4489 266.5 18.4102 265.925 18.4719 265.394C18.5288 264.904 18.7015 264.186 19.2844 263.644C19.8742 263.097 20.5933 262.978 21.1585 263.01C21.7029 263.042 22.2295 263.214 22.6886 263.414C23.614 263.817 24.6677 264.514 25.7691 265.367C27.9971 267.093 30.7483 269.718 33.6111 272.801C36.4338 275.841 38.0187 277.772 38.8388 279.218C39.2673 279.973 39.5283 280.664 39.6283 281.367C39.7279 282.066 39.6493 282.662 39.5703 283.141Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M32.0345 275.548L32.0081 275.609L31.977 275.667C31.1335 277.254 30.323 278.474 29.4647 279.257C28.5351 280.104 27.3762 280.572 26.0866 280.197C25.0236 279.889 24.19 279.075 23.5607 278.368C23.0597 277.806 22.5201 277.107 21.9695 276.395C21.8097 276.188 21.6489 275.98 21.4879 275.774C19.6623 273.782 18.6655 271.329 18.2841 269.212C18.0913 268.142 18.0452 267.101 18.1585 266.196C18.2617 265.371 18.5367 264.339 19.2844 263.644C20.1 262.887 21.1241 262.796 21.956 262.934C22.7665 263.069 23.5822 263.441 24.3399 263.894C25.8714 264.809 27.5832 266.309 29.2398 268.093C30.8307 269.807 31.8287 271.092 32.2547 272.253C32.4919 272.898 32.5595 273.516 32.4732 274.132C32.3936 274.699 32.1904 275.189 32.0345 275.548Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M25.6253 291.554C20.9399 287.152 18.0405 282.546 16.5196 278.752C15.7617 276.861 15.3287 275.128 15.2101 273.69C15.1509 272.973 15.166 272.283 15.2843 271.662C15.3986 271.062 15.6365 270.385 16.1392 269.851C16.7359 269.215 17.518 268.989 18.2657 269.042C18.9338 269.09 19.5592 269.353 20.0857 269.643C21.1465 270.227 22.3624 271.236 23.6676 272.456C25.7079 274.363 28.2489 277.057 31.1756 280.159C32.0177 281.052 32.8917 281.979 33.7949 282.93C38.347 287.211 42.1721 291.464 44.6757 294.905C45.9225 296.619 46.8933 298.201 47.4457 299.526C47.7196 300.184 47.931 300.872 47.9767 301.535C48.0223 302.196 47.9124 303.056 47.269 303.741C46.6256 304.425 45.7747 304.589 45.112 304.584C44.4467 304.58 43.7469 304.412 43.0739 304.179C41.7163 303.71 40.077 302.84 38.2892 301.702C34.69 299.411 30.1929 295.846 25.6253 291.554Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M17.9569 270.371C16.4923 271.93 17.748 281.843 26.7779 290.328C35.8079 298.812 44.0737 303.042 46.0781 302.774C47.5681 301.006 40.9759 292.784 32.0818 284.683C23.8495 276.101 19.4628 270.392 17.9569 270.371Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M17.0669 271.028C16.0039 272.159 17.6094 281.713 26.6493 290.207C35.3904 298.74 44.0839 304.296 46.0779 302.774C48.2697 298.292 35.4552 292.581 26.6012 283.877C18.1688 275.588 17.9724 272.161 17.0669 271.028Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M36.6118 289.187L36.5969 289.277L36.5724 289.365C36.0437 291.259 35.38 292.841 34.3495 293.767C33.7762 294.282 33.0731 294.609 32.2692 294.642C31.5045 294.673 30.8129 294.43 30.2342 294.117C29.1246 293.517 28.0125 292.423 26.9203 291.218C26.0769 290.288 25.1435 289.174 24.1358 287.971C23.8137 287.586 23.4839 287.192 23.1471 286.792C20.1881 283.598 18.0532 280.039 16.8062 277.095C16.1831 275.625 15.7573 274.25 15.5789 273.111C15.4904 272.546 15.4517 271.971 15.5134 271.439C15.5703 270.95 15.7431 270.231 16.3259 269.69C16.9157 269.142 17.6348 269.024 18.2 269.056C18.7444 269.087 19.271 269.26 19.7301 269.46C20.6555 269.863 21.7092 270.559 22.8106 271.413C25.0386 273.138 27.7898 275.764 30.6526 278.847C33.4753 281.886 35.0602 283.818 35.8803 285.263C36.3088 286.019 36.5698 286.71 36.6698 287.413C36.7694 288.112 36.6908 288.708 36.6118 289.187Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M29.076 281.594L29.0496 281.654L29.0185 281.713C28.175 283.3 27.3645 284.52 26.5062 285.302C25.5766 286.149 24.4177 286.617 23.1281 286.243C22.0651 285.935 21.2315 285.121 20.6022 284.414C20.1012 283.851 19.5616 283.153 19.011 282.44C18.8512 282.233 18.6904 282.025 18.5294 281.819C16.7038 279.828 15.707 277.374 15.3256 275.258C15.1328 274.187 15.0867 273.146 15.2 272.241C15.3032 271.417 15.5782 270.384 16.3259 269.69C17.1415 268.933 18.1656 268.841 18.9975 268.98C19.808 269.114 20.6237 269.487 21.3814 269.939C22.9129 270.855 24.6247 272.355 26.2813 274.139C27.8722 275.852 28.8702 277.138 29.2962 278.298C29.5334 278.944 29.601 279.562 29.5147 280.177C29.4351 280.745 29.2319 281.235 29.076 281.594Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M72.9636 294.139L72.9372 294.123L72.9113 294.107C64.3322 288.695 58.5178 288.322 54.2442 290.059C49.8251 291.855 46.5308 296.088 43.4432 301.125C41.7717 303.852 41.1856 306.44 41.3728 308.88C41.5614 311.338 42.545 313.771 44.2144 316.146C47.5797 320.934 53.5836 325.268 60.5634 328.684C67.4017 332.03 74.4299 333.176 80.2241 332.401C86.0322 331.624 90.3978 328.967 92.3883 324.9C94.301 320.991 93.09 315.418 89.2798 309.49C85.5323 303.66 79.5528 297.968 72.9636 294.139Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M43.4216 304.167C40.489 312.783 50.4107 323.38 64.2773 328.751C78.079 333.061 87.5985 330.851 90.5704 324.778C92.5148 320.805 90.592 326.019 83.0741 313.107C75.3988 299.924 50.606 283.06 43.4216 304.167Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M45.6281 300.506C43.0883 308.248 49.4019 313.211 62.4496 320.221C75.3935 326.555 88.9698 328.058 91.4737 322.941C92.0892 315.013 86.2677 306.899 72.8042 299.061C56.2123 290.942 47.6186 294.439 45.6281 300.506Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M25.9473 303.02C21.2619 298.617 18.3625 294.012 16.8417 290.218C16.0837 288.327 15.6507 286.593 15.5321 285.156C15.4729 284.439 15.488 283.749 15.6064 283.128C15.7206 282.528 15.9585 281.851 16.4612 281.316C17.0579 280.681 17.8401 280.455 18.5877 280.508C19.2558 280.555 19.8812 280.819 20.4077 281.108C21.4685 281.692 22.6845 282.702 23.9897 283.922C26.0299 285.828 28.571 288.522 31.4976 291.625C32.3397 292.518 33.2137 293.444 34.1169 294.396C38.6691 298.676 42.4942 302.93 44.9978 306.371C46.2445 308.084 47.2153 309.666 47.7677 310.992C48.0416 311.649 48.253 312.337 48.2987 313.001C48.3443 313.662 48.2344 314.521 47.591 315.206C46.9476 315.891 46.0967 316.054 45.434 316.05C44.7687 316.045 44.0689 315.877 43.3959 315.645C42.0383 315.176 40.399 314.305 38.6113 313.168C35.012 310.877 30.5149 307.312 25.9473 303.02Z"
                    fill="#FEFEFE"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M18.2789 281.837C16.8143 283.395 18.07 293.309 27.1 301.793C36.1299 310.278 44.3957 314.508 46.4001 314.24C47.8901 312.471 41.2979 304.25 32.4038 296.149C24.1715 287.567 19.7848 281.858 18.2789 281.837Z"
                    fill="#D9D9D9"
                />
                <path
                    d="M17.389 282.494C16.3259 283.625 17.9314 293.178 26.9713 301.672C35.7124 310.205 44.4059 315.761 46.3999 314.24C48.5917 309.757 35.7773 304.046 26.9233 295.342C18.4909 287.053 18.2944 283.626 17.389 282.494Z"
                    fill="#A1A1A1"
                />
                <path
                    d="M36.9338 300.652L36.9189 300.742L36.8944 300.83C36.3657 302.725 35.702 304.307 34.6715 305.232C34.0982 305.747 33.3951 306.075 32.5912 306.107C31.8265 306.138 31.1349 305.895 30.5563 305.583C29.4466 304.983 28.3345 303.888 27.2423 302.684C26.399 301.754 25.4655 300.639 24.4579 299.436C24.1357 299.051 23.8059 298.658 23.4691 298.258C20.5101 295.064 18.3752 291.505 17.1282 288.561C16.5051 287.09 16.0793 285.716 15.9009 284.576C15.8124 284.011 15.7737 283.436 15.8354 282.905C15.8923 282.416 16.0651 281.697 16.6479 281.156C17.2377 280.608 17.9568 280.489 18.522 280.522C19.0664 280.553 19.593 280.725 20.0521 280.925C20.9775 281.328 22.0312 282.025 23.1326 282.878C25.3606 284.604 28.1118 287.229 30.9747 290.312C33.7973 293.352 35.3822 295.283 36.2023 296.729C36.6308 297.484 36.8918 298.175 36.9918 298.878C37.0914 299.578 37.0128 300.174 36.9338 300.652Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <path
                    d="M29.3983 293.059L29.3719 293.12L29.3408 293.178C28.4972 294.765 27.6868 295.986 26.8285 296.768C25.8989 297.615 24.74 298.083 23.4503 297.709C22.3873 297.4 21.5538 296.586 20.9245 295.879C20.4235 295.317 19.8838 294.618 19.3332 293.906C19.1734 293.699 19.0127 293.491 18.8517 293.285C17.026 291.294 16.0292 288.84 15.6479 286.723C15.4551 285.653 15.409 284.612 15.5223 283.707C15.6255 282.882 15.9005 281.85 16.6481 281.156C17.4638 280.398 18.4879 280.307 19.3197 280.445C20.1303 280.58 20.946 280.952 21.7037 281.405C23.2351 282.32 24.947 283.821 26.6036 285.605C28.1944 287.318 29.1924 288.604 29.6185 289.764C29.8556 290.41 29.9233 291.027 29.837 291.643C29.7574 292.211 29.5542 292.7 29.3983 293.059Z"
                    stroke="black"
                    stroke-width="3.36531"
                />
                <defs>
                    <filter
                        id="filter0_f_5819_14461"
                        x="99.3946"
                        y="77.4022"
                        width="62.8446"
                        height="46.9745"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.560885" result="effect1_foregroundBlur_5819_14461" />
                    </filter>
                    <filter
                        id="filter1_f_5819_14461"
                        x="366.401"
                        y="263.888"
                        width="92.0746"
                        height="48.6769"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.560885" result="effect1_foregroundBlur_5819_14461" />
                    </filter>
                    <filter
                        id="filter2_f_5819_14461"
                        x="396.69"
                        y="77.4022"
                        width="62.5216"
                        height="52.9047"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.560885" result="effect1_foregroundBlur_5819_14461" />
                    </filter>
                    <filter
                        id="filter3_f_5819_14461"
                        x="98.7891"
                        y="250.674"
                        width="91.5814"
                        height="61.2885"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="0.560885" result="effect1_foregroundBlur_5819_14461" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_5819_14461"
                        x1="92.9097"
                        y1="73.5438"
                        x2="466.863"
                        y2="311.444"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A4ED6B" />
                        <stop offset="1" stop-color="#53C201" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_5819_14461"
                        x1="107.116"
                        y1="83.8577"
                        x2="450.184"
                        y2="301.978"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.242019" stop-color="#32E1FC" />
                        <stop offset="1" stop-color="#00B7D6" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_5819_14461"
                        x1="25.418"
                        y1="16.4158"
                        x2="-1.69413"
                        y2="26.2406"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_5819_14461"
                        x1="438.637"
                        y1="427.598"
                        x2="428.617"
                        y2="434.454"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_5819_14461"
                        x1="482.837"
                        y1="346.348"
                        x2="476.217"
                        y2="350.877"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_5819_14461"
                        x1="225.18"
                        y1="381.103"
                        x2="198.068"
                        y2="390.928"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_5819_14461"
                        x1="309.39"
                        y1="334.536"
                        x2="313.615"
                        y2="349.922"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#88DDF0" />
                        <stop offset="1" stop-color="#01B5D1" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_5819_14461"
                        x1="274.608"
                        y1="334.413"
                        x2="279.954"
                        y2="355.685"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D5FE73" />
                        <stop offset="1" stop-color="#9DE00A" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_5819_14461"
                        x1="237.18"
                        y1="340.022"
                        x2="253.59"
                        y2="344.581"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#02AA09" />
                        <stop offset="1" stop-color="#149A00" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_5819_14461"
                        x1="277.764"
                        y1="346.102"
                        x2="280.124"
                        y2="349.959"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6DE1D5" />
                        <stop offset="1" stop-color="#10BAD9" />
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_5819_14461"
                        x1="275.572"
                        y1="347.851"
                        x2="283.622"
                        y2="354.344"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#25C0C9" />
                        <stop offset="1" stop-color="#8CE2D5" />
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_5819_14461"
                        x1="283.32"
                        y1="344.252"
                        x2="284.216"
                        y2="355.448"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#028AB5" />
                        <stop offset="1" stop-color="#12ADC4" />
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_5819_14461"
                        x1="25.3138"
                        y1="16.3485"
                        x2="15.2937"
                        y2="23.2043"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_5819_14461"
                        x1="16.7244"
                        y1="10.8012"
                        x2="10.1043"
                        y2="15.3307"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#DCDCDC" />
                        <stop offset="1" stop-color="#A8A7A8" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
