import { useContext, useEffect, useMemo, useState } from 'react';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { useTranslation } from 'react-i18next';
import styles from '@f_qwep/styles/component/purchasingAssistant/TablePurchasingAssistant.module.sass';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { getAssistantTableConfig } from '@f_qwep/components/purchasingAssistant/ui/table/AssistantColumn.config';
import { TDeals } from '@f_qwep/components/purchasingAssistant/types/Deals.type';

type TablePurchasingAssistantProps = {
    items: TDeals[];
};

export function TableAssistant({ items }: TablePurchasingAssistantProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);
    const { setOpenExportModal, setExportData, setTypeExport } = useContext(QwepContext);

    const columns = useMemo(() => getAssistantTableConfig({ t, styles, colors }), [t, styles, colors, items, width]);

    const tableStyles = {
        head: {
            container: {
                height: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: mobile ? 'center' : null,
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: mobile ? '190px' : '28px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    const exportExcelHandler = () => {
        setOpenExportModal(true);
        setTypeExport('assistant');
        setExportData({ filter: items, all: items });
    };

    return (
        <div>
            <VirtualTable
                data={items}
                columnsTable={columns}
                topFilterPanel={width > 700}
                showColumnSearchAction={true}
                heightTable={height - 97}
                configHeader={{
                    fullScrean: true,
                    exportExcel: true,
                    resize: true,
                    grouping: false,
                    columnFilter: true,
                    sorting: width > 700,
                    columnSearch: true,
                    dragging: true,
                }}
                styles={tableStyles}
                overscan={2}
                estimateSize={mobile ? 191 : 28}
                exportExcelHandler={exportExcelHandler}
                tableBottomIndent={70}
                isTableAssistant={true}
            />
        </div>
    );
}
